import { FC } from 'react';

export const TransferBonusIcon: FC<{ color?: string }> = ({
  color = '#AA7F66',
}) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3112 4.57972C20.989 3.1735 18.332 2.45388 15.6341 2.50229C12.9363 2.5507 10.3055 3.36521 8.03285 4.85367C6.28244 6.00008 4.79744 7.5115 3.66893 9.28069L2.29783 8.4255C2.14717 8.33152 1.96238 8.46859 2.00401 8.64343L2.8428 12.1663C2.86893 12.276 2.97756 12.3438 3.08431 12.3169L6.51102 11.4546C6.68109 11.4118 6.71399 11.18 6.56333 11.086L4.94172 10.0746C5.95667 8.48734 7.28881 7.13405 8.85469 6.1085C10.8928 4.77364 13.2484 4.04534 15.6611 4.00205C18.0737 3.95876 20.4523 4.60209 22.5342 5.8628C24.6163 7.12364 26.3215 8.95359 27.4588 11.1526C27.6491 11.5205 28.1016 11.6645 28.4695 11.4742C28.8374 11.2839 28.9814 10.8314 28.7911 10.4635C27.5289 8.0229 25.6332 5.98582 23.3112 4.57972ZM8.68772 28.4189C11.0099 29.8251 13.6669 30.5448 16.3648 30.4963C19.0626 30.4479 21.6934 29.6334 23.9661 28.145C25.7165 26.9986 27.2015 25.4871 28.33 23.718L29.7011 24.5731C29.8517 24.6671 30.0365 24.53 29.9949 24.3552L29.1561 20.8324C29.13 20.7226 29.0214 20.6549 28.9146 20.6817L25.4879 21.5441C25.3178 21.5869 25.2849 21.8187 25.4356 21.9126L27.0572 22.9241C26.0422 24.5113 24.7101 25.8646 23.1442 26.8901C21.1061 28.225 18.7505 28.9533 16.3379 28.9966C13.9252 29.0399 11.5466 28.3966 9.46471 27.1358C7.38262 25.875 5.67745 24.0451 4.54012 21.8461C4.34983 21.4781 3.89732 21.3341 3.5294 21.5244C3.16148 21.7147 3.01749 22.1672 3.20778 22.5352C4.47006 24.9757 6.36576 27.0128 8.68772 28.4189Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3902 10.5C11.1608 10.5 10.9608 10.6561 10.9052 10.8787L10.0003 14.4981H9C8.44772 14.4981 8 14.9458 8 15.4981V20.4981C8 21.0504 8.44772 21.4981 9 21.4981H23C23.5523 21.4981 24 21.0504 24 20.4981V15.4981C24 14.9458 23.5523 14.4981 23 14.4981H21.9993L21.0945 10.8787C21.0389 10.6561 20.8389 10.5 20.6094 10.5H11.3902ZM11.428 19.1654C12.0592 19.1654 12.5709 18.6431 12.5709 17.9987C12.5709 17.3544 12.0592 16.8321 11.428 16.8321C10.7968 16.8321 10.2852 17.3544 10.2852 17.9987C10.2852 18.6431 10.7968 19.1654 11.428 19.1654ZM20.5706 19.1654C21.2018 19.1654 21.7134 18.6431 21.7134 17.9987C21.7134 17.3544 21.2018 16.8321 20.5706 16.8321C19.9394 16.8321 19.4277 17.3544 19.4277 17.9987C19.4277 18.6431 19.9394 19.1654 20.5706 19.1654ZM13 21.5H11V22.5C11 23.0523 11.4477 23.5 12 23.5C12.5523 23.5 13 23.0523 13 22.5V21.5ZM19 21.5H21V22.5C21 23.0523 20.5523 23.5 20 23.5C19.4477 23.5 19 23.0523 19 22.5V21.5Z"
        fill={color}
      />
    </svg>
  );
};
