export const accidentImpact = {
  OTHER: 0,
  HUGE: 1,
  NORMAL: 2,
} as const;

export const operationCd = {
  TEL: 1,
  CANCEL: 2,
} as const;
