import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchReservationHistories } from '@/api/reservationApi';
import {
  ReservationHistoriesParams,
  ReservationHistoriesResponse,
  Reservation,
} from '@/types/api/reservationApi';
import { formatDateISO } from '@/utils/date';

type UseFetchReservationHistories = {
  t_stock_car_id?: number;
} & ReservationHistoriesParams;

export const useFetchReservationHistories = ({
  m_customer_id,
  t_stock_car_id,
}: UseFetchReservationHistories) => {
  const [data, setData] =
    useState<ReservationHistoriesResponse['data']['reservation_histories']>();
  const [error, setError] = useState<AxiosError>();
  const [newestReservation, setNewestReservation] = useState<Reservation>();

  useEffect(() => {
    f7.preloader.show();

    const fn = async () => {
      try {
        const res = await fetchReservationHistories({ m_customer_id });
        const data = res.data;

        const reservation_histories = data.reservation_histories
          .filter(
            (history) =>
              t_stock_car_id == null ||
              history.t_stock_car_id == null ||
              t_stock_car_id === history.t_stock_car_id,
          )
          .sort(function (carWashFirst: Reservation, carWashLast: Reservation) {
            const orderDateFirst = formatDateISO(carWashFirst.order_date);
            const orderDateLast = formatDateISO(carWashLast.order_date);

            return orderDateLast.getTime() - orderDateFirst.getTime();
          });

        setData(reservation_histories);
        setNewestReservation(reservation_histories[0]);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };

    fn();
  }, []);

  return {
    data,
    error,
    newestReservation,
  };
};
