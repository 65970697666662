import { Router } from 'framework7/types';
import { FC } from 'react';
import { AccountForgotten } from './AccountForgotten/AccountForgotten';

interface AccountForgottenPageProps {
  f7router: Router.Router;
}
export const AccountForgottenPage: FC<AccountForgottenPageProps> = ({
  f7router,
}) => {
  return <AccountForgotten f7router={f7router} />;
};
