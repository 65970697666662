/* eslint-disable no-magic-numbers */
import { yupResolver } from '@hookform/resolvers/yup';
import { Block, f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ReservingsStep1 } from '../ReservingsStep1/ReservingsStep1';
import { ReservingsStep2Page } from '../ReservingsStep2/ReservingsStep2Page';
import { ReservingsStep3 } from '../ReservingsStep3/ReservingsStep3';
import { ReservingsStep4 } from '../ReservingsStep4/ReservingsStep4';
import style from './ReservingsStyle.module.scss';
import { Dialog } from '@/components/uiParts/Dialog/Dialog';
import { Stepper } from '@/components/uiParts/Stepper/Stepper';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { Houseprepaid } from '@/types/api/prepaidApi';
import { GmoRedirectInfo, PrepaidData } from '@/types/payment';
import { generatePrepaidName } from '@/utils/shop';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

type ReservingsProps = {
  f7router: Router.Router;
  setPrepaidData?: Dispatch<SetStateAction<PrepaidData>>;
  gmoRedirectInfo?: GmoRedirectInfo;
  isBackShowPrepaidSummary?: boolean;
};

interface TermParams {
  Term1: boolean;
  Term2: boolean;
  Term3: boolean;
  Term4: boolean;
  Term5: boolean;
  Term6: boolean;
  Term7: boolean;
  Term8: boolean;
  Term9: boolean;
  Term10: boolean;
  Term11: boolean;
  Term12: boolean;
}
export interface ConfirmInput extends TermParams {
  currentCheckbox: number;
  targetedCheckox: number;
}
export const ConfirmSchema = yup.object().shape({
  currentCheckbox: yup.number().oneOf([yup.ref('targetedCheckox'), null], ''),
});

export const Reservings: FC<ReservingsProps> = ({
  f7router,
  setPrepaidData,
  gmoRedirectInfo,
  isBackShowPrepaidSummary,
}) => {
  const prepaidName = store.state.customerInfo?.affiliate_prepaid_name;
  const planList = useStore(store, 'getPrepaidPlanList') as Houseprepaid[]; // store.state.prepaidPlanList;
  const m_prepaid_plan_id = useStore(store, 'getPrepaidPlanId') as number;

  const [currentStep, setCurrentStep] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const defaultValues: ConfirmInput = useMemo(() => {
    const termListCheckboxStore = JSON.parse(
      window.localStorage.getItem('termListCheckbox') || '{}',
    ) as TermParams;
    window.localStorage.removeItem('termListCheckbox');

    const termListCheckbox: TermParams = Object.keys(termListCheckboxStore)
      .length
      ? termListCheckboxStore
      : {
          Term1: false,
          Term2: false,
          Term3: false,
          Term4: false,
          Term5: false,
          Term6: false,
          Term7: false,
          Term8: false,
          Term9: false,
          Term10: false,
          Term11: false,
          Term12: false,
        };
    return {
      currentCheckbox: 0,
      targetedCheckox: 12,
      ...termListCheckbox,
    };
  }, []);

  const formMethods = useForm<ConfirmInput>({
    defaultValues,
    resolver: yupResolver(ConfirmSchema),
  });

  const selectedPlanInfo = useMemo(() => {
    return planList.length === 0
      ? undefined
      : planList.find((pl) => pl.m_prepaid_plan_id === m_prepaid_plan_id);
  }, [m_prepaid_plan_id, planList]);

  const reserveAmount = window.localStorage.getItem('reserve_amount');

  const tabs = [
    {
      id: 0,
      label: '積立金額設定',
    },
    {
      id: 1,
      label: '決済方法登録',
    },
    {
      id: 2,
      label: '重要事項',
    },
    {
      id: 3,
      label: '内容確認',
    },
  ];

  const handleBack = () => {
    f7CustomBack(['#Reservings']);
    window.localStorage.removeItem('termListCheckbox');
    if (isBackShowPrepaidSummary) {
      f7.view.main.router.navigate(paths.prepaidSummary);
    }
  };

  const handleChange = (step: number) => {
    setCurrentStep(step);
  };

  const handleClickBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      setShowDialog(true);
    }
  };

  const handleClickDialogClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    if (
      ['gmo_cancel', 'gmo_success'].includes(gmoRedirectInfo?.status as string)
    ) {
      f7.preloader.show();
    }
    clearReserveAmount();
    window.localStorage.removeItem('source_page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // デフォルトではplanListの0番目の要素を選択する
  useEffect(() => {
    if (!planList) return;
    if (reserveAmount || planList.length === 0 || !planList) return;

    const { m_prepaid_plan_id, name } = planList[0];
    storeDispatch('setMPrepaidPlanId', 'mPrepaidPlanId', m_prepaid_plan_id);
    storeDispatch('setPrepaidPlanName', 'prepaidPlanName', name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planList]);

  const clearReserveAmount = () => {
    if (reserveAmount && Number(reserveAmount) > 0) {
      window.localStorage.removeItem('reserve_amount');
      window.localStorage.removeItem('termListCheckbox');
      setCurrentStep(1);
    }
  };
  const handlePopupClosed = () => {
    storeDispatch('setReserveAmount', 'reserveAmount', 0);
    clearReserveAmount();
  };
  return (
    <PopupPageTemplate
      id="Reservings"
      pageName="Reservings"
      containerClass={style['popup-container']}
      closeWrapperClass={style['popup-wrapper-class']}
      title={`${generatePrepaidName(prepaidName)}のお申し込み`}
      iconType={!currentStep ? 'close' : 'left'}
      handleBack={handleClickBack}
      onPopupClosed={handlePopupClosed}
      closeByBackdropClick={false}
    >
      <div className={style['container']} id="ReservingsStepper">
        <Stepper
          className={style['stepper-wrapper']}
          activeClassName={style['stepper-active']}
          activeTabId={currentStep}
          tabs={tabs.map(({ label }) => label)}
        />
        <Block style={{ marginTop: '30px' }}>
          <form>
            <FormProvider {...formMethods}>
              <div>
                {currentStep == 0 && (
                  <ReservingsStep1
                    changeStep={handleChange}
                    f7router={f7router}
                    planList={planList}
                    selectedPlanInfo={selectedPlanInfo}
                  />
                )}
                {currentStep == 1 && (
                  <ReservingsStep2Page
                    changeStep={handleChange}
                    handleCancel={() => setShowDialog(true)}
                    f7router={f7router}
                  />
                )}
                {currentStep == 2 && (
                  <ReservingsStep3
                    changeStep={handleChange}
                    f7router={f7router}
                    defaultValues={defaultValues}
                  />
                )}
                {currentStep == 3 && (
                  <ReservingsStep4
                    selectedPlanInfo={selectedPlanInfo}
                    changeStep={handleChange}
                    f7router={f7router}
                    setPrepaidData={setPrepaidData}
                    handleCancel={() => setShowDialog(true)}
                  />
                )}
              </div>
            </FormProvider>
          </form>
        </Block>
        {showDialog && (
          <Dialog
            title="申し込みを中止します"
            subTitle="入力した内容が破棄されます。<br>本当に中止しますか？"
            handleCancel={handleBack}
            handleOk={handleClickDialogClose}
            buttonOkText="キャンセル"
            buttonCancelText="申し込みを中止する"
            className={style['confirm-dialog']}
            btnOkClassName={style['btn-ok']}
            btnCancelClassName={style['btn-cancel']}
          />
        )}
      </div>
    </PopupPageTemplate>
  );
};
