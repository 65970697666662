import { Dispatch, FC, SetStateAction, useState } from 'react';
import Calendar from 'react-calendar';
import './CalenderLinkDriveStyle.scss';
import { store } from '@/config/store';
import { DATE_MONTH_INCREMENTER, dateToHyphenConnection } from '@/utils/date';
// eslint-disable-next-line import/order
import { useStore } from 'framework7-react';

interface CalenderAdContractorProps {
  value: Date;
  onChange: Dispatch<SetStateAction<Date>>;
  clickDay: (value: Date) => void;
  clickMonth: (value: Date) => void;
  clickNextMonth: (param: { activeStartDate?: Date | undefined }) => void;
  data: any;
}

export type DateClass = {
  date: Date;
};

export const CalenderLinkDrive: FC<CalenderAdContractorProps> = ({
  value,
  onChange,
  clickDay,
  clickNextMonth,
  clickMonth,
  data,
}) => {
  const [view, setView] = useState<'month' | 'year'>('month');
  const diagnosisDate = useStore(store, 'getDiagnosticDate') as string;

  const handleViewChange = () => {
    if (view === 'month') {
      setView('year');
    }
  };

  const makeNavigationLabel = ({ date }: DateClass) => {
    const year = date.getFullYear();
    const month = date.getMonth() + DATE_MONTH_INCREMENTER;
    const text = view === 'month' ? `${year}年${month}月` : `${year}年`;
    return view === 'month' ? (
      <div className="calender_title" onClick={handleViewChange}>
        {text} <i className="icon-down" />
      </div>
    ) : (
      <div onClick={handleViewChange}>{text}</div>
    );
  };

  const getClassDayOptions = ({ date }: DateClass) => {
    let className = 'diferrenceTile';

    if (value.getMonth() == date.getMonth()) {
      className = data?.day?.includes(String(date.getDate()))
        ? dateToHyphenConnection(date) === diagnosisDate
          ? 'selectedTile'
          : 'tileClass'
        : 'noneTileClass';
    }
    return `${className} tile`;
  };

  const handleClickMonth = (value: Date) => {
    clickMonth(value);
    setView('month');
  };

  return (
    <Calendar
      onChange={onChange}
      className={'calender_ld'}
      prev2Label={null}
      next2Label={null}
      value={value}
      onClickDay={clickDay}
      minDetail={'year'}
      onClickMonth={handleClickMonth}
      nextLabel={<i className="icon-right" />}
      prevLabel={<i className="icon-left" />}
      locale="ja-JP"
      navigationLabel={makeNavigationLabel}
      calendarType="Hebrew"
      formatShortWeekday={(locale, date) => {
        const day = date.getDay();
        const weekday = ['日', '月', '火', '水', '木', '金', '土'];
        return weekday[day];
      }}
      formatDay={(_, date) => String(date.getDate())}
      formatMonth={(_, date) => String(date.getMonth() + 1 + '月')}
      tileClassName={view === 'month' ? getClassDayOptions : 'monnth-options'}
      view={view}
      onActiveStartDateChange={clickNextMonth}
      maxDate={new Date()}
    />
  );
};
