import Popup from 'framework7-react/components/popup';
import { FC } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import style from './Upload.module.css';
import { Button } from '@/components/uiParts/Button';

interface ImageZoomProps {
  src: string;
  onClose: () => void;
}

// If used globaly define it in src/components/uiParts/ImageZoom.tsx
export const ImageZoom: FC<ImageZoomProps> = ({ src, onClose }) => {
  return (
    <Popup
      opened={!!src}
      onPopupClosed={onClose}
      animate={false}
      className={style['zoom-backdrop']}
    >
      <div className={style['zoom']}>
        <div className={style['zoom-container']}>
          <TransformWrapper>
            <TransformComponent>
              <img src={src} className={style['zoom-image']} />
            </TransformComponent>
          </TransformWrapper>

          <div className={style['zoom-close']}>
            <p>
              ダブルタップやピンチアウトで
              <br />
              画像を拡大表示することができます。
            </p>
            <Button
              onClick={onClose}
              style={{
                backgroundColor: '#fff',
                height: '40px',
                color: '#323232',
                width: 'auto',
              }}
            >
              閉じる
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  );
};
