import { useStore } from 'framework7-react';
import { FC } from 'react';
import { PrepaidHistory } from './PrepaidHistory/PrepaidHistory';
import { store } from '@/config/store';
import { Prepaid } from '@/types/api/customerApi';

type PrepaidHistoryPageProps = {
  selected?: string;
};

export const PrepaidHistoryPage: FC<PrepaidHistoryPageProps> = ({
  selected = 'inquery',
}) => {
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Required<Prepaid>
    | undefined;
  return <PrepaidHistory selected={selected} prepaid={prepaid} />;
};
