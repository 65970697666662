import { Link, Popup } from 'framework7-react';
import { FC } from 'react';
import CarRepairRequestSuccessImage from 'src/assets/images/car-repair-request-success.png';
import style from './RepairConfirmationStyle.module.scss';

interface SuccessPopupProps {
  isModalOpen: boolean;
  onPopupClose: (isConfirmed: boolean) => void;
}

export const SuccessPopup: FC<SuccessPopupProps> = ({
  isModalOpen = false,
  onPopupClose,
}) => {
  return (
    <Popup
      className={style['success-popup']}
      opened={isModalOpen}
      onPopupClosed={() => onPopupClose(false)}
      backdrop
      closeByBackdropClick
    >
      <div
        className={`${style['popup-container']} ${style['success-container']}`}
      >
        <div className={style['close-wrapper']}>
          <Link
            onClick={() => onPopupClose(false)}
            animate
            className={style['close']}
          >
            <i className="icon-close" style={{ color: '#707070' }} />
          </Link>
        </div>
        <img
          className={style['success-image']}
          src={CarRepairRequestSuccessImage}
        />
        <div className={style['success-title']}>修理依頼を受け付けました。</div>
        <div className={style['success-content']}>
          「作業依頼」より依頼内容を
          <br />
          ご確認いただくことができます
        </div>
      </div>
    </Popup>
  );
};
