import { Page } from 'framework7-react';
import { FC } from 'react';
import style from './MaintenanceGroupHelpPopup.module.css';
import CarWashIcon from '@/assets/images/maintenance-group/car_wash.svg';
import WarrantyRepairIcon from '@/assets/images/maintenance-group/warranty_repair.svg';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { store } from '@/config/store';
import { carWashServiceFlag } from '@/consts/carWash';

interface MaintenanceGroupHelpPopupProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

export const MaintenanceGroupHelpPopup: FC<MaintenanceGroupHelpPopupProps> = ({
  opened,
  setOpened,
}) => {
  const isShowCarWashService =
    store.state.customerInfo.carwash_service_flg ===
    carWashServiceFlag.INSTALLED;

  return (
    <PopupTemplate
      title="各メンテナンスに関する説明"
      opened={opened}
      onPopupClosed={() => setOpened(false)}
      handleClose={() => setOpened(false)}
    >
      <Page name="maintenanceGroupHelpPopup">
        <div className={style['help-popup']}>
          {isShowCarWashService && (
            <div className={style['help-section']}>
              <div className={style['help-title']}>
                <img src={CarWashIcon} />
                <p className={style['help-title__text']}>
                  洗車/エアコンクリーニング
                </p>
              </div>
              <p className={style['help-content']}>
                「水なし手洗い洗車」「カーエアコンクリーニングのご用命はこちらから。
              </p>
            </div>
          )}

          <div className={style['help-section']}>
            <div className={style['help-title']}>
              <img src={WarrantyRepairIcon} />
              <p className={style['help-title__text']}> 保証修理 </p>
            </div>
            <p className={style['help-content']}>
              ご契約中の保証を適用してお車を修理いたします。
            </p>
            <p className={style['help-content']}>
              （修理内容によっては保証が適用できない場合もございます。）
            </p>
          </div>
        </div>
      </Page>
    </PopupTemplate>
  );
};
