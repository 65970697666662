import { AccordionContent, List, ListItem } from 'framework7-react';
import { FC, useState } from 'react';
import { useBankList } from '@/hooks/api/prepaid/useBankList';
import './RegisterBankStyle.scss';

type RegisterBankStep2Props = {
  handleClickStep: (step: number) => void;
  setBankInfo: (
    code: string,
    name: string,
    branchCodeRequiredFlg: string,
    accountTypeRequiredFlg: string,
    accountNumberRequiredFlg: string,
    accountNameKanaRequiredFlg: string,
  ) => void;
};

export const RegisterBankStep2: FC<RegisterBankStep2Props> = ({
  handleClickStep,
  setBankInfo,
}) => {
  const [inputKeyword, setInputKeyword] = useState('');
  const [banklistSelected, setBanklistSelected] = useState<any>();

  const titleList = [
    { head: 'あ', title: 'あ行' },
    { head: 'か', title: 'か行' },
    { head: 'さ', title: 'さ行' },
    { head: 'た', title: 'た行' },
    { head: 'な', title: 'な行' },
    { head: 'は', title: 'は行' },
    { head: 'ま', title: 'ま行' },
    { head: 'や', title: 'や行' },
    { head: 'ら', title: 'ら行' },
    { head: 'わ', title: 'わ行' },
  ];

  const { data } = useBankList();
  const bankList = data?.banks;

  const [isInput, setIsInput] = useState(false);

  const updateKeywordValue = (e: any) => {
    setInputKeyword(e.target.value);
    if (e.target.value == '') {
      setIsInput(false);
      return;
    }
    if (inputKeyword != '') {
      const initialBank = [];
      for (const i in bankList) {
        const bank = bankList[Number(i)];
        if (bank.name.indexOf(e.target.value) != -1) {
          initialBank.push(bank);
        }
      }
      setBanklistSelected(initialBank);
      setIsInput(true);
    }
  };

  const handleClickSelectedBank = (
    code: string,
    name: string,
    branchCodeRequiredFlg: string,
    accountTypeRequiredFlg: string,
    accountNumberRequiredFlg: string,
    accountNameKanaRequiredFlg: string,
  ) => {
    setBankInfo(
      code,
      name,
      branchCodeRequiredFlg,
      accountTypeRequiredFlg,
      accountNumberRequiredFlg,
      accountNameKanaRequiredFlg,
    );
    handleClickStep(2);
  };

  return (
    <div className="page-block">
      <div className="u-margin-bottom-sm input-wrap search">
        <i className="icon icon-search" />
        <input
          className="bank-search"
          type="text"
          placeholder="金融機関名で検索"
          value={inputKeyword}
          onChange={(e) => updateKeywordValue(e)}
        />
      </div>
      {!isInput && bankList && (
        <div className="u-margin-bottom-xs accordion-list">
          <List accordion-list className="u-text-left">
            {titleList.map((item, index) => (
              <ListItem accordionItem key={index} title={item.title}>
                {bankList.map((item2, index2) => (
                  <div key={index2}>
                    {item.head === item2.search_mark && (
                      <AccordionContent>
                        <List simple-list className="mypage-list">
                          <ListItem
                            onClick={() =>
                              handleClickSelectedBank(
                                item2.code,
                                item2.name,
                                item2.branch_code_required_flg.toString(),
                                item2.account_type_required_flg.toString(),
                                item2.account_number_required_flg.toString(),
                                item2.account_name_kana_required_flg.toString(),
                              )
                            }
                          >
                            <div className="item-term">{item2.name}</div>
                            <div>
                              <i className="icon-next" />
                            </div>
                          </ListItem>
                        </List>
                      </AccordionContent>
                    )}
                  </div>
                ))}
              </ListItem>
            ))}
          </List>
        </div>
      )}
      {isInput &&
        banklistSelected.map((item3: any) => (
          <List simple-list className="mypage-list" key={item3.id}>
            <ListItem
              onClick={() =>
                handleClickSelectedBank(
                  item3.code,
                  item3.name,
                  item3.branch_code_required_flg.toString(),
                  item3.account_type_required_flg.toString(),
                  item3.account_number_required_flg.toString(),
                  item3.account_name_kana_required_flg.toString(),
                )
              }
            >
              <div className="item-term">{item3.name}</div>
              <div>
                <i className="icon-next" />
              </div>
            </ListItem>
          </List>
        ))}
    </div>
  );
};
