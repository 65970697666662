import { FC } from 'react';

export const PrepaidChargeIcon: FC<{ color?: string }> = ({
  color = '#AA7F66',
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6965 3.06569C18.2257 2.00607 15.5069 1.72882 12.8839 2.26901C10.2609 2.80921 7.85155 4.14258 5.96048 6.10051C4.06941 8.05845 2.78157 10.553 2.25983 13.2687C1.73808 15.9845 2.00586 18.7994 3.0293 21.3576C4.05274 23.9157 5.78588 26.1022 8.00954 27.6406C10.2332 29.1789 12.8475 30 15.5219 30C15.9292 30 16.2595 29.6581 16.2595 29.2364C16.2595 28.8146 15.9292 28.4727 15.5219 28.4727C13.1393 28.4727 10.8102 27.7412 8.82907 26.3707C6.84799 25.0002 5.30392 23.0522 4.39213 20.7731C3.48034 18.494 3.24177 15.9862 3.7066 13.5667C4.17143 11.1472 5.31877 8.9248 7.00354 7.18046C8.68832 5.43611 10.8348 4.2482 13.1717 3.76694C15.5085 3.28568 17.9308 3.53268 20.132 4.47671C22.3333 5.42074 24.2147 7.0194 25.5384 9.07053C26.8622 11.1217 27.5687 13.5331 27.5687 16C27.5687 16.0002 27.5687 16.0004 27.5687 16.0006H26.814C26.6388 16.0006 26.5562 16.2199 26.6832 16.3481L28.2118 17.8924C28.2905 17.9719 28.415 17.9719 28.4899 17.8924L29.9453 16.3481C30.0662 16.2199 29.9732 16.0006 29.798 16.0006H29.0438C29.0438 16.0004 29.0438 16.0002 29.0438 16C29.0438 13.2311 28.2508 10.5243 26.765 8.22202C25.2792 5.91973 23.1673 4.12532 20.6965 3.06569Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10C7.44772 10 7 10.4477 7 11V21C7 21.5523 7.44772 22 8 22H24C24.5523 22 25 21.5523 25 21V11C25 10.4477 24.5523 10 24 10H8ZM9 12H23V15H9V12ZM9 18H15V20H9V18Z"
        fill={color}
      />
    </svg>
  );
};
