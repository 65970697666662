import { formatISO9075 } from 'date-fns';
import { Card, Icon, Tab, Tabs } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { TroubleTabBarText } from './TabBarText/TroubleTabBarText';
import { TroubleGuide } from './Tabs/TroubleGuide/TroubleGuide';
import { TroubleService } from './Tabs/TroubleService/TroubleService';
import { TroubleTerms } from './Tabs/TroubleTerms/TroubleTerms';
import './TroubleStyle.scss';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { isApp } from '@/config/device';
import { store } from '@/config/store';
import { callCenterDivision } from '@/consts/callCenter';
import { url } from '@/consts/url';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';
import { useGps } from '@/hooks/api/other/useGps';
import { tabsTrouble } from '@/routes/tabsTrouble';
import { getRandomNumbericString } from '@/utils/utils';

type TroubleProps = {
  f7router: Router.Router;
  pageContent?: boolean;
  roadServiceTel: string;
};

// 「ご利用可能なサービス」ページが見切れない高さ
const tabsHeight = '200rem';

export const Trouble: FC<TroubleProps> = ({ f7router, pageContent = true }) => {
  const { customerInfo, authInfo, currentWarranty } = store.state;
  const mAffiliateId = customerInfo.m_affiliate_id;
  const phoneNumber = customerInfo.phone_no1 ?? customerInfo.phone_no2 ?? '';
  const { data } = useMAffiliateSettings({ m_affiliate_id: mAffiliateId });
  const callCenterTel = data?.data.callcenter_tel;
  const { sendGps } = useGps(true, callCenterDivision.ROAD_SERVICE);

  const handleCallRoadService = () => {
    if (isApp) {
      window.location.href = `${url.NATIVE_BASE_URL}/request_location_info`;
    } else {
      window.location.href = 'tel://' + data?.data.callcenter_tel;
    }
  };

  const sendGpsInfo = (
    longitude: number,
    latitude: number,
    gps_time: string,
  ) => {
    const nowTime = formatISO9075(new Date(), {
      format: 'basic',
    }).replace(' ', '');
    gps_time = nowTime; // Androidから連携されるlocation.timeはdatetime型ではないため、フロント側の時間を利用する
    const params = {
      m_customer_id: authInfo.m_customer_id,
      warranty_no: currentWarranty?.warranty_no ?? '',
      branch_no: currentWarranty?.branch_no ?? undefined,
      service_queue: getRandomNumbericString(7),
      tel_no: phoneNumber.replace(/-/g, ''),
      service_tel_no: callCenterTel?.replace(/-/g, '') ?? '',
      longitude,
      latitude,
      gps_time,
    };
    sendGps(params);
  };

  window['onGetGps'] = (
    longitude: number,
    latitude: number,
    gps_time: string,
  ) => {
    sendGpsInfo(longitude, latitude, gps_time);
  };

  // ネイティブアプリの位置情報を許可していない状態でロードサービスを押した場合に呼ばれる
  window['onRequestRoadServiceWithoutGps'] = () => {
    window.location.href = 'tel://' + data?.data.callcenter_tel;
  };

  return (
    <PageTemplate pageName="Trouble" title="車が動かなくなった" showNavBack>
      <div className="u-text-center u-margin-bottom-xs">
        <Card>
          <Icon className="icon-large" icon="icon-road-service" />
          <div className="u-font-size-lg u-margin-bottom-xxl u-margin-top-sm margin-set">
            自走が困難なトラブルが発生したら、こちらからロードサービスにお電話ください。
          </div>
          <div className="u-margin-bottom-0 u-text-center phone-bottom">
            <div className="u-padding-right-sm u-padding-right-sm u-text-center u-margin-bottom-md">
              <Button
                icon="icon-telephone"
                handleClickAction={handleCallRoadService}
              >
                ロードサービスに電話する
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <div
        className="u-margin-top-0 u-margin-bottom-sm u-text-center"
        style={{
          height: '0px',
          display: 'flex',
          alignItems: 'center',
          top: '-8px',
        }}
      >
        <TroubleTabBarText
          tabs={tabsTrouble}
          style={{ borderRadius: '25px', marginTop: '50px' }}
        />
      </div>
      <Tabs routable swipeable style={{ height: tabsHeight }}>
        {tabsTrouble.map((item) => (
          <Tab key={item.id} id={item.id}>
            {item.id === 'trouble-guide' && (
              <TroubleGuide f7router={f7router} />
            )}
            {item.id === 'trouble-service' && (
              <TroubleService f7router={f7router} pageContent={pageContent} />
            )}
            {item.id === 'trouble-terms' && <TroubleTerms />}
          </Tab>
        ))}
      </Tabs>
    </PageTemplate>
  );
};
