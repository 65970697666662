import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './accountForgottenStyle.module.css';
import { ReactComponent as DownArrowIcon } from '@/assets/images/login/icon_prev.svg';
import { ReactComponent as SupportIcon } from '@/assets/images/login/icon_support.svg';
import { HelpButtonsBox } from '@/components/projects/HelpButtonsBox/HelpButtonsBox';
import { RequestCustomerList } from '@/components/projects/RequestCustomerList/RequestCustomerList';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';

interface AccountForgottenProps {
  f7router: Router.Router;
}

export const AccountForgotten: FC<AccountForgottenProps> = ({ f7router }) => {
  const title = '事前に下記の情報をご準備いただくと\n問い合わせがスムーズです';
  const items = [
    'ご利用いただいたマイくる加盟店の店舗名',
    'お客様のお名前（ご本人）',
    'お客様のお電話番号',
  ];
  return (
    <PopupPageTemplate
      pageName="PhoneChanged"
      title=""
      className={style['page']}
    >
      <div className={style['container']}>
        <h3>{`なにでログインしていたかわからない\nアカウントを持っていたかわからない`}</h3>
        <div className={style['down-arrow-icon-wrapper']}>
          <DownArrowIcon />
        </div>
        <div className={style['support-container']}>
          <div>
            <SupportIcon width="80px" height="80px" />
          </div>
          <p>{`サポートセンターに\n問い合わせをしてください`}</p>
        </div>
        <RequestCustomerList items={items} title={title} />
        <HelpButtonsBox
          title={`「アカウント有無の問い合わせ」と\nお伝えください`}
          isSecondButton
          path={paths.contactLogin}
        />
      </div>
    </PopupPageTemplate>
  );
};
