import { useEffect, useRef, useState } from 'react';

const closeKeyframes = (elementHeight: number) => {
  return [
    {
      height: 'auto',
      offset: 0,
    },
    {
      height: `${elementHeight}px`,
      offset: 0.001,
    },
    {
      height: '0px',
      offset: 1,
    },
  ];
};
const openKeyframes = (elementHeight: number) => {
  return [
    {
      height: '0px',
      offset: 0,
    },
    {
      height: `${elementHeight}px`,
      offset: 0.999,
    },
    {
      height: 'auto',
      offset: 1,
    },
  ];
};

const option: KeyframeAnimationOptions = {
  duration: 200,
  easing: 'ease-out',
  fill: 'forwards',
};

export const useAccordion = () => {
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const divElement = accordionRef.current;
    if (!divElement || !divElement.firstElementChild) return;
    const divHeight = divElement.clientHeight;
    const divChildHeight = divElement.firstElementChild.clientHeight;

    if (isOpen) {
      divElement.animate(openKeyframes(divChildHeight), option);
    } else {
      if (divHeight > 0)
        divElement.animate(closeKeyframes(divChildHeight), option);
    }
  }, [isOpen]);

  return {
    isOpen,
    setIsOpen,
    accordionRef,
  };
};
