import { Router } from 'framework7/types';
import { FC } from 'react';
import { ImpactInfo } from './ImpactInfo/ImpactInfo';
import { store } from '@/config/store';
import { useImpactInfo } from '@/hooks/api/other/useImpactInfo';

interface ImpactInfoPageProps {
  f7router: Router.Router;
}

export const ImpactInfoPage: FC<ImpactInfoPageProps> = () => {
  const customerId = store.state.authInfo.m_customer_id;
  const { data } = useImpactInfo(customerId);
  return <ImpactInfo impactData={data?.data} customerId={customerId} />;
};
