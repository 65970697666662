import { Popup } from 'framework7-react';
import parse from 'html-react-parser';
import { FC } from 'react';
import style from './SuccessDialogStyle.module.css';

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
}

export const SuccessDialog: FC<SuccessDialogProps> = ({
  open,
  onClose,
  title,
  content,
}) => {
  return (
    <Popup
      opened={open}
      onPopupClosed={onClose}
      closeByBackdropClick
      animate={false}
      className={style['popup']}
    >
      <div className={style['container']}>
        <div className={style['close-icon']}>
          <i className="icon-close" onClick={onClose} />
        </div>
        <div className={style['success-icon']}>
          <i className="icon-success" />
        </div>
        <div className={style['title']}>
          {title || '書類のアップロードが完了しました'}
        </div>
        {content && <div className={style['content']}>{parse(content)}</div>}
      </div>
    </Popup>
  );
};
