import { FC } from 'react';
import style from './requestMaintenanceButtonStyle.module.css';

interface RequestMaintenanceButtonProps {
  handleClickButton: () => void;
}

export const RequestMaintenanceButton: FC<RequestMaintenanceButtonProps> = ({
  handleClickButton,
}) => {
  return (
    <div onClick={handleClickButton} className={style['container']}>
      <div className={style['upper']}>
        <div className={style['text-box']}>
          <div className={style['text-main']}>
            車の整備はいつでもお申し付けください
          </div>
          <ul className={style['text-list']}>
            <li>キズ・へこみ・パンクをなおしたい</li>
            <li>ナビやレコーダーなどの取り付け</li>
            <li>洗車・コーティング</li>
          </ul>
        </div>
        <span className={style['icon']}>
          <i className="icon-maintenance" />
        </span>
      </div>
      <div className={style['bottom']}>
        <span>
          詳細はこちら
          <i className="icon icon-right" style={{ color: '#efefef' }} />
        </span>
      </div>
    </div>
  );
};
