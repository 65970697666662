import { FC, useMemo } from 'react';
import styles from './WarrantyClaimStyle.module.scss';
import { ReactComponent as ClaimIcon } from '@/assets/images/warranty/claim.svg';
import { Button } from '@/components/uiParts/Button';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { paths } from '@/config/paths';
import { handleClickLink } from '@/utils/utils';

type WarrantyClaimProps = {
  isDisabled?: boolean;
  hasDeal: boolean;
};

export const WarrantyClaim: FC<WarrantyClaimProps> = ({
  isDisabled,
  hasDeal = false,
}) => {
  const { btnText, btnPath } = useMemo(() => {
    let btnText = '保証申請をする';
    let btnPath = paths.tradeGuarantee;
    if (hasDeal) {
      btnText = '申請中の保証を確認する';
      btnPath = paths.tradeGuaranteeApplication;
    }
    return { btnText, btnPath };
  }, [hasDeal]);
  return (
    <RoundSheet>
      <div className={styles.wrapper}>
        <ClaimIcon />
        <div className={styles.content}>
          取引保証の利用申請につきましては、以下よりお進みください。
        </div>
        <Button
          text={btnText}
          onClick={() => handleClickLink(btnPath)}
          disabled={isDisabled}
        />
      </div>
    </RoundSheet>
  );
};
