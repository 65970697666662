import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './unRegisteredStyle.module.css';
import { TextLink } from '@/components/uiParts/Button/TextLink/TextLink';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';

interface UnRegisteredProps {
  f7router: Router.Router;
}
export const UnRegistered: FC<UnRegisteredProps> = ({ f7router }) => {
  return (
    <PopupPageTemplate pageName="UnRegistered" title="">
      <div className={`${style['container']}`}>
        <h2>
          {`このアプリはマイくる加盟店で\n車のご購入、もしくは整備の実施を\nしていただいた方のためのものです`}
        </h2>
        <div>
          {`ご購入・整備実施の商談時に\nお客様・お車の情報をご登録いただくと、\nアカウントが発行されます`}
        </div>
        <div className={`${style['text-link-wrapper']} textLink`}>
          <TextLink path={paths.accountForgotten} f7router={f7router}>
            アカウントを持っているのかわからない方
          </TextLink>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
