import {
  DetailedHTMLProps,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
} from 'react';
import { useFormContext } from 'react-hook-form';

interface RhfInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  onChangeOverride?: boolean;
}

const CustomRhfInput: ForwardRefRenderFunction<
  HTMLInputElement,
  RhfInputProps
> = ({ name, onChangeOverride = false, onChange, ...rest }, ref) => {
  const { register, setValue, trigger, getValues } = useFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChangeOverride) {
      const integerRegex = /^-?\d+$/;
      if (rest.type !== 'number') {
        const value = event.target.value === '' ? '' : event.target.value;
        setValue(name, value, { shouldDirty: true });
        trigger(name);
      } else if (
        integerRegex.test(event.target.value) ||
        event.target.value === ''
      ) {
        console.log('Incoming: ', event.target.value);
        let value =
          event.target.value === '' ? undefined : parseInt(event.target.value);

        if (typeof value === 'undefined') {
          setValue(name, '', { shouldDirty: true });
          trigger(name);
          return;
        }
        if (rest.min && value) value = Math.max(value, Number(rest.min));
        if (rest.max && value) value = Math.min(value, Number(rest.max));

        setValue(name, value, { shouldDirty: true });
        trigger(name);
      }
    } else if (onChange) onChange(event);
  };

  useEffect(() => {
    if (name !== 'day') return;
    const values = getValues();
    let value;
    if (rest.max && values.day) value = Math.min(values.day, Number(rest.max));
    setValue(name, value, { shouldDirty: true });
    trigger(name);
  }, [rest.max]);

  return (
    <input {...register(name)} {...rest} ref={ref} onChange={handleChange} />
  );
};

CustomRhfInput.displayName = 'NumberInput';

export const RhfInput = forwardRef(CustomRhfInput);
