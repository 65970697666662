import { Router } from 'framework7/types';
import React, { FC } from 'react';
import { ScanHistoryDetail } from './ScanHistoryDetail/ScanHistoryDetail';
import { ErrorType } from '@/types/api/linkDriveApi';

interface ScanHistoryDetailPageProps {
  id: number;
  errors: ErrorType[];
  f7router: Router.Router;
}

export const ScanHistoryDetailPage: FC<ScanHistoryDetailPageProps> = ({
  id,
  f7router,
}) => {
  return <ScanHistoryDetail id={id} f7router={f7router} />;
};
