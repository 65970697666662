import { countLoginApi } from '@/api/authApi';
import { Car, CustomerInfoApiParams } from '@/types/api/customerApi';

export const useLoginCount = (
  mCustomerId: CustomerInfoApiParams['m_customer_id'],
) => {
  const callCountLoginApi = async (car: Car) => {
    try {
      const { data } = await countLoginApi({
        m_customer_id: mCustomerId,
        t_stock_car_id: car.t_stock_car_id,
      });
      if (!data.success) {
        throw new Error(
          data?.error?.message || 'ログインカウントに失敗しました',
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    callCountLoginApi,
  };
};
