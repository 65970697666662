//====== コネクタ状態取得API =====//
// /v1/linkdrive/connector_status

// レスポンス
export const mode = {
  sleep: '2',
  launch: '3',
  ecuCommunication: '4',
};

export const eventStatusLaunch = {
  CONNECTOR_INSERTION_WITHOUT_ECU: '0',
  CONNECTOR_INSERTION_WITH_ECU: '1',
  NORMAL_REBOOT: '2',
  SLEEP_INTERRUPTION: '3',
  CONNECTOR_STANDBY: '4',
  OPTIMIZATION_REBOOT: '5',
  REFRESH_REBOOT: '6',
  BATTERY_ALERT_REBOOT: '7',
  MODE_UPDATE_REBOOT: '8',
  VOLTAGE_RE_SENSING_REBOOT: '9',
  COMPLETE_STANDBY: '10',
};

//======= コネクタJOB依頼API =======//

// job
export const connectorJob = {
  INITIALIZE: '1',
  FIRMWARE_UPDATE: '2',
  ECU_LIST_UPDATE: '3',
  IMPACT_JUDGEMENT_UPDATE: '4',
  CLOUD_DIAGNOSIS: '5',
  MODE_RESERVATION: '9',
  CALIBRATION: '11',
} as const;

export const processingClassification = {
  FIRMWARE_UPDATE: 1,
  SET_UP: 2,
} as const;

// リクエスト
export const linkDriveConnectorJobMode = {
  monitor: 1,
  simple: 3,
};

// ecu タイプ
export const ecuTypes = [
  { type: 1, text: 'スタンダード' },
  { type: 2, text: 'スタンダード' },
  { type: 3, text: 'ノーマル' },
];

// 回線状態
export const simStatus = [
  { type: 1, text: '未開通' },
  { type: 2, text: '開通済' },
  { type: 3, text: '一時停止' },
  { type: 4, text: '解約済' },
  { type: 5, text: '開通手続中' },
];

// セットアップステータス
export const linkDriveSetUpMode = {
  LINK_DRIVE_SETUP: 1,
  CALIBRATION_SETTING: 2,
  FIRMWARE_UPDATE: 3,
};

// キャリブレーション
export const calibrationSteps = {
  ENGINE_START: 1,
  CHECK_POSITION: 2,
  IN_PREPARATION: 3,
  STATUS_CHECK_ERROR: 3.5,
  IN_PROGRESS: 4,
  PROGRESS_ERROR: 4.5,
  COMPLETED: 5,
};

export const connectorStatusApiError = {
  NO_STATUS_DATA: 'LinkDriveConnectorStatus情報なし',
} as const;

export const linkDriveStatus = {
  NOT_ADDED: 0, // 未加入
  TEMPORARY_REGISTRATION: 1, // 仮登録
  AGREEMENT: 2, // 本契約計上
  FORMAL_REGISTRATION: 3, // 本登録
};

export const linkDriveJobExecStatus = {
  SUCCESSFUL_END: 0,
  PROCESSING_RECEPTION: 1,
  PROCESSING_START: 2,
  PROCESSING: 3,
  INTERUPTED: 4,
  RESUME: 5,
  ABNORMAL_TERMINATION: 9,
};

export const drivelogSendFlg = {
  NOTIFY_OFF: 0,
  NOTIFY_ON: 1,
};

export const delFlg = {
  NOT_DELETE: 0,
  IS_DELETED: 1,
};

export const linkDriveSetupStatus = {
  INITIAL: 0, // 初期状態
  MODE_RESERVATION_COMPLETE: 1, // モード更新完了
  CALIBRATION_FARMWARE_UPDATE_COMPLETE: 2, // キャリブレーション正常終了、単独FW更新正常完了時
};

export const linkDriveInitStatus = {
  INITIAL: 0, // 初期状態
  COMPLETE: 9, // セットアップ完了
  CALIBRATION_IN_PROGRESS: 6, // キャリブレーション中
};

export const timeOutJob: any = {
  [connectorJob.FIRMWARE_UPDATE]: 300, // 300s
  [connectorJob.ECU_LIST_UPDATE]: 480, // 8m
  [connectorJob.IMPACT_JUDGEMENT_UPDATE]: 300, // 300s
  [connectorJob.MODE_RESERVATION]: 300, // 300s
};

export const updatePromiseResolveFlg = {
  SUCCESS: 0,
  FAILLED: 1,
  ENDED: 2,
};

export const driveExisted = {
  NOT_EXISTED: 0,
};

export const jobTerminateStatus = {
  CONNECTOR_DISCONNECT: '0001', // コネクタ切断通知
  NO_MODE: '0002', // FW1.0で簡易モード予約 or モード設定なし。
  INTERUPTED: '0003', // 自動リフレッシュ処理を優先した為、jobを中断した
  NOT_AUTO_SCAN: '0004', // オートスキャン対象外の車
  TIMEOUT_30_MINS: '0005', // 30分経過したため、キャリブレーションを中断した。
  TIMEOUT_5_MINS: '0006', // 速度が5分間取得できなかったため、キャリブレーションを中断した。
  CANCELED: '0007', // キャリブレーションがキャンセルされた。
  IMPACT_MISSING: '0008', // 衝撃データがコネクタにないため、キャリブレーションが失敗した。
  MODE_TO_SIMPLE: '0009', // 簡易モードに変更したため、オートスキャンは実施しないようになった。
  NOT_CONNECTED: '8888', // コネクタが接続していない。
  NO_RESPONSE: '9000', // コネクタから一定時間応答がないため、FW更新を終了しました。コネクタを抜き差ししてから、FW更新を再度実施してください。
  CAR_CLOUD_ERROR: '9999', // カークラウドでエラー
  UNABLE_DETECT_ECU: '02002', // 使用可能なＥＣＵを検出できません対象外の車種です
};

export const setupProcessingStatus = {
  INACTIVE: 1,
  PROCESSING: 2,
  SUCCESS: 3,
};

export const jobUpdatingLabel: any = {
  [connectorJob.INITIALIZE]: '初期化実施中...',
  [connectorJob.FIRMWARE_UPDATE]: 'FW更新実施中...',
  [connectorJob.ECU_LIST_UPDATE]: '実ECUリスト更新実施中...',
  [connectorJob.IMPACT_JUDGEMENT_UPDATE]: '衝撃判定データ更新実施中...',
  [connectorJob.CLOUD_DIAGNOSIS]: 'クラウド診断実施中...',
  [connectorJob.MODE_RESERVATION]: 'モード更新実施中...',
  [connectorJob.CALIBRATION]: 'キャリブレーション実施中...',
};
