import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { RegisterPaymentList } from './RegisterPaymentList/RegisterPaymentList';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useGmo } from '@/hooks/useGmo';
import { Car } from '@/types/api/customerApi';
import { f7CustomBack } from '@/utils/utils';

type RegisterPaymentListPageProps = {
  redirectPage: string;
  f7router: Router.Router;
  currentCarId?: string;
};

export const RegisterPaymentListPage: FC<RegisterPaymentListPageProps> = ({
  redirectPage = 'prepaid',
  f7router,
  currentCarId,
}) => {
  const { m_car_id, t_order_id } =
    (useStore(store, 'carItemInfo') as Car) || {};
  const [actionUrl, setActionUrl] = useState('');
  const { addCreditCardPaymentMethod, GMOParameters } = useGmo(redirectPage);

  const handleClickWithdrawFromBank = () => {
    const {
      globalTheme,
      isLoggedInToMaikuru,
      paymentMethodsInfo,
      loginInfo: { loginType },
    } = store.state;
    const payments = paymentMethodsInfo.map(
      ({ m_payment_method_id, m_payment_type_id }) => ({
        m_payment_method_id,
        m_payment_type_id,
      }),
    );
    window.localStorage.setItem('payment_methods', JSON.stringify(payments));
    window.localStorage.setItem('m_car_id', String(m_car_id));
    window.localStorage.setItem('loginType', String(loginType));
    window.localStorage.setItem('t_order_id', String(t_order_id));
    currentCarId && window.localStorage.setItem('currentCarId', currentCarId);
    window.localStorage.setItem('globalTheme', `${globalTheme}`);
    window.localStorage.setItem(
      'isLoggedInToMaikuru',
      `${isLoggedInToMaikuru}`,
    );
    window.localStorage.setItem('source_page', paths.chargePrepaid);
    f7.view.main.router.navigate(paths.registerBank, {
      props: {
        redirectPage: redirectPage,
      },
    });
  };

  const handleClickCreditCardPayment = async () => {
    const { token, m_customer_id } = store.state.authInfo;
    const {
      globalTheme,
      isLoggedInToMaikuru,
      paymentMethodsInfo,
      loginInfo: { loginType },
    } = store.state;
    const payments = paymentMethodsInfo.map(
      ({ m_payment_method_id, m_payment_type_id }) => ({
        m_payment_method_id,
        m_payment_type_id,
      }),
    );
    window.localStorage.setItem('payment_methods', JSON.stringify(payments));
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('m_customer_id', m_customer_id.toString());
    window.localStorage.setItem('m_car_id', String(m_car_id));
    window.localStorage.setItem('t_order_id', String(t_order_id));
    window.localStorage.setItem('loginType', String(loginType));
    currentCarId && window.localStorage.setItem('currentCarId', currentCarId);
    window.localStorage.setItem('globalTheme', `${globalTheme}`);
    window.localStorage.setItem(
      'isLoggedInToMaikuru',
      `${isLoggedInToMaikuru}`,
    );
    window.localStorage.setItem('destination_step', '2');
    window.localStorage.setItem('destination_page', paths.reservings);
    window.localStorage.setItem('source_page', paths.chargePrepaid);
    await addCreditCardPaymentMethod(setActionUrl);
    // 一回目のクリック時点ではsetActionUrlでactionUrlが更新されるが
    // 非同期処理のためから文字のまま
    document.getElementById('submit')?.click();
  };

  const handleClickPopupClose = () => {
    f7CustomBack(['#RegisterPaymentList']);
  };

  // 一回目のクリックでactionUrlが更新されたらもう一度クリック
  useEffect(() => {
    document.getElementById('submit')?.click();
  }, [actionUrl]);

  return (
    <RegisterPaymentList
      handleClickWithdrawFromBank={handleClickWithdrawFromBank}
      handleClickCreditCardPayment={handleClickCreditCardPayment}
      GMOParameters={GMOParameters}
      actionUrl={actionUrl}
      redirectPage={redirectPage}
      handleClickPopupClose={handleClickPopupClose}
    />
  );
};
