import { FC } from 'react';
import style from './customerInquiryBoxStyle.module.css';

interface CustomerInquiryBoxProps {
  infoList: string[];
}
export const CustomerInquiryBox: FC<CustomerInquiryBoxProps> = ({
  infoList,
}) => {
  return (
    <div className={style['confirmation-box']}>
      <div>
        <h3>{`お客様照会のため、下記の情報を\n確認させていただきます`}</h3>
        <div className={style['confirmation-list-wrapper']}>
          {infoList.map((el) => (
            <span key={el}>{el}</span>
          ))}
        </div>
      </div>
    </div>
  );
};
