import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { getGoogleGeocode } from '@/api/prepaidApi';
import { GOOGLE_MAP_FALLBACK_LAT_LNG } from '@/consts/googleMap';
import { GoogleGeocodeParams } from '@/types/api/payment';

type LatLng = {
  lat?: number;
  lng?: number;
};

// NOTE: 緯度経度を直接使うケースと、住所から逆引きするケースどちらも対応する。緯度経度優先。
export const useGoogleGeocode = (
  { address, key }: GoogleGeocodeParams,
  { lat, lng }: LatLng,
) => {
  const initialLatLng =
    lat == null || lng == null ? GOOGLE_MAP_FALLBACK_LAT_LNG : { lat, lng };

  const [data, setData] = useState<google.maps.LatLngLiteral>(initialLatLng);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    // 緯度経度が既に指定されている場合はAPIコールをスキップ
    if (lat != null && lng != null) {
      return;
    }

    const fn = async () => {
      f7.preloader.show();
      try {
        const res = await getGoogleGeocode({ address, key });
        setData({
          lat: res.data?.results[0]?.geometry?.location?.lat,
          lng: res.data?.results[0]?.geometry?.location?.lng,
        });
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };
    fn();
  }, [address, key, lat, lng]);

  return {
    data,
    error,
  };
};
