import { Router } from 'framework7/types';
import { FC } from 'react';
import { Accident } from './Accident/Accident';

type AccidentPageProps = {
  f7router: Router.Router;
};

export const AccidentPage: FC<AccidentPageProps> = ({ f7router }) => {
  return <Accident f7router={f7router} />;
};
