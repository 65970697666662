import { Router } from 'framework7/types';
import {
  AbnormalAlertPage,
  AlertPreventionPage,
  ScanHistoryPage,
  ScanHistoryDetailPage,
  SelfScanPage,
  SelfScanStepsPage,
  SelfScanResultPage,
  VehicleConditionPage,
} from '@/components/pages';
import { SelfScanStartPage } from '@/components/pages/SelfScanStart/SelfScanStartPage';

export const selfScanRoutes: Router.RouteParameters[] = [
  {
    path: '/self_scan/',
    popup: {
      component: SelfScanPage,
    },
    options: {
      animate: true,
    },
    name: 'セルフスキャン',
  },
  {
    path: '/self_scan_start/',
    component: SelfScanStartPage,
    name: '車両状態・セルフスキャン',
  },
  {
    path: '/self_scan_steps/',
    popup: {
      component: SelfScanStepsPage,
    },
    options: {
      animate: true,
    },
    name: 'セルフスキャン開始',
  },
  {
    path: '/self_scan_result/',
    popup: {
      component: SelfScanResultPage,
    },
    options: {
      animate: true,
    },
    name: 'セルフスキャン結果',
  },
  //   {
  //     path: '/self_scan_alert_detail/:id/',
  //     popup: {
  //       component: SelfScanAlertDetailPage
  //     },
  //     options: {
  //       animate: true
  //     },
  //     name: 'セルフスキャンアラートの詳細'
  //   },
  {
    path: '/scan_history/',
    component: ScanHistoryPage,
    name: 'スキャン履歴',
  },
  {
    path: '/scan_history/:id/',
    component: ScanHistoryDetailPage,
    name: 'スキャン履歴詳細',
  },
  //   {
  //     path: '/scan_history_detail/',
  //     component: ScanHistoryDetailPage,
  //     name: 'スキャン詳細'
  //   },
  {
    path: '/alert_prevention/',
    component: AlertPreventionPage,
    name: 'アラート予防',
  },
  {
    path: '/abnormal_alert/:id/',
    component: AbnormalAlertPage,
    name: '異常アラート',
  },
  {
    path: '/vehicle_condition/',
    component: VehicleConditionPage,
    name: '車両状態',
  },
];
