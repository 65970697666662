import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { DriveInsurance } from './DriveInsurance/DriveInsurance';
import { paths } from '@/config/paths';

interface DriveInsurancePageProps {
  f7router: Router.Router;
}
export const DriveInsurancePage: FC<DriveInsurancePageProps> = ({
  f7router,
}) => {
  const handleClickCalculateButton = () => {
    f7.view.main.router.navigate(paths.totalScore);
  };
  return (
    <DriveInsurance handleClickCalculateButton={handleClickCalculateButton} />
  );
};
