import { Router } from 'framework7/types';
import { FC } from 'react';
import { DeletePayment } from './DeletePayment/DeletePayment';
import { store } from '@/config/store';
import { PaymentTypeId } from '@/types/payment';

interface DeletePaymentPageProps {
  f7router: Router.Router;
  paymentMethodId: string;
  paymentTypeId: PaymentTypeId;
}

export const DeletePaymentPage: FC<DeletePaymentPageProps> = ({
  paymentMethodId,
  paymentTypeId,
}) => {
  const paymentMethods = store.state.paymentMethodsInfo;
  const paymentMethod = paymentMethods.find(
    (method) =>
      method.m_payment_method_id === Number(paymentMethodId) &&
      method.m_payment_type_id === Number(paymentTypeId),
  );
  return <DeletePayment paymentMethod={paymentMethod} />;
};
