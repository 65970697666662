import * as yup from 'yup';

const regExp =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$%-^&*])[A-Za-z0-9._~!@#$^&*]{6,32}$/;
export const setPasswordSchema = yup.object().shape({
  password: yup.string().matches(regExp),
  confirmation: yup
    .string()
    .matches(regExp)
    .required()
    .oneOf(
      [yup.ref('password')],
      '入力した内容は一致してないので、ご確認ください',
    ),
});
