import { Router } from 'framework7/types';
import { FC } from 'react';
import { UnRegistered } from './UnRegistered/UnRegistered';

interface UnRegisteredPageProps {
  f7router: Router.Router;
}
export const UnRegisteredPage: FC<UnRegisteredPageProps> = ({ f7router }) => {
  return <UnRegistered f7router={f7router} />;
};
