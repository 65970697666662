import { parseISO } from 'date-fns';
import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useMemo } from 'react';
import style from './DriveShockHistoryStyles.module.scss';
import {
  DriveShockItem,
  DriveShockItemProps,
} from '@/components/projects/DriveShockItem/DriveShockItem';
import { NoticeSettings } from '@/components/projects/NoticeSettings/NoticeSettings';
import { Paper } from '@/components/uiParts/Card';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { JaDateTime } from '@/consts/day';
import { useImpactHistory } from '@/hooks/api/driveRecord/useImpactHistory';
import { formatToJaDateTime } from '@/utils/date';
interface DriveShockHistoryProps {
  f7router: Router.Router;
  title: string;
}

export const DriveShockHistory: FC<DriveShockHistoryProps> = ({ title }) => {
  const ownerId = useStore(store, 'ownerId') as number;
  const impactHistoryParams = useMemo(
    () => ({
      owner_id: ownerId,
    }),
    [ownerId],
  );
  const { data: impactHistory, isNoData } =
    useImpactHistory(impactHistoryParams);

  const listDriveShockHistory = useMemo<DriveShockItemProps[]>(
    () =>
      impactHistory?.success
        ? impactHistory.impact_histories?.map(
            ({ impact_level, accident_time }) => ({
              level: impact_level,
              time: formatToJaDateTime(parseISO(accident_time), JaDateTime),
            }),
          )
        : [],
    [impactHistory?.impact_histories, impactHistory?.success],
  );

  return (
    <PageTemplate pageName="ShockHistory" title={title} showNavBack>
      <Paper path={paths.driveShockHistory} cardCN="u-margin-top-0">
        <div>
          {!isNoData && (
            <div className={style.box}>
              {listDriveShockHistory.map((item, index) => (
                <DriveShockItem
                  key={`${item.level}-${index}`}
                  level={item.level}
                  time={item.time}
                />
              ))}
            </div>
          )}
          <div className={style.setting}>
            <NoticeSettings
              f7router={f7.views.main.router}
              isHideWatchingSetting
            />
          </div>
        </div>
      </Paper>
    </PageTemplate>
  );
};
