import { f7 } from 'framework7-react';
import React, { FC } from 'react';

interface LinkedCardProps {
  cardHead: string;
  cardBodys: {
    label: string;
    remark?: string;
    props: string;
    path: string;
    cancelable: boolean;
  }[];
}

export const LinkedCard: FC<LinkedCardProps> = ({ cardHead, cardBodys }) => {
  const handleClickNavigation = (path: string) => {
    f7.view.main.router.navigate(path, { animate: true });
  };
  return (
    <div className="u-margin-bottom-md info-card info-card-elevated">
      <div
        className="head display-flex u-font-bold u-font-size-xxl"
        style={{ margin: '10px 0 15px' }}
      >
        {cardHead}
      </div>
      <div>
        {cardBodys.map((cardBody, index) => (
          <div
            className={`${
              cardBody.cancelable && 'fontred'
            } other-info display-flex`}
            key={index}
            onClick={() => handleClickNavigation(cardBody.path)}
          >
            <div className="u-font-bold">
              {cardBody.label}
              {cardBody.remark && (
                <div
                  className={`${cardBody.cancelable && 'fontred'} remark-words`}
                >
                  {cardBody.remark}
                </div>
              )}
            </div>
            <div>
              <i className={`icon-right u-font-size-xxl`} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
