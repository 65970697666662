import { FC, useEffect, useState } from 'react';
import style from './CarWashHandPageStyle.module.css';
import { CarWashHandStep1 } from './CarWashHandStep1/CarWashHandStep1';
import { CarWashHandStep2 } from './CarWashHandStep2/CarWashHandStep2';
import { CarWashHandStep3 } from './CarWashHandStep3/CarWashHandStep3';
import { CarWashHandStep4 } from './CarWashHandStep4/CarWashHandStep4';
import { PopupCancel } from './PopupCancel/PopupCancel';
import { CustomBgPageTemplate } from '@/components/uiParts/Template/CustomBgPageTemplate/CustomBgPageTemplate';
import { steps } from '@/consts/carWash';
import { useCarWashHand } from '@/hooks/api/carWash/useCarWashHand';

type CarWashHandPageProp = {
  status?: string;
};

export const CarWashHandPage: FC<CarWashHandPageProp> = ({ status }) => {
  const [opened, setOpened] = useState(false);
  const storageStep = localStorage.getItem('step') || '';
  const [initialStatus, setInitialStatus] = useState(!!status || !!storageStep);
  const {
    step,
    step1Data,
    step2Data,
    step3Data,
    courseItems,
    optionItems,
    optionItem,
    courseItem,
    setStep,
    submitStep1,
    submitStep2,
    submitStep3,
    submitStep4,
  } = useCarWashHand(status || storageStep);

  const handleClickBack = () => {
    if (step === steps.PRE_SELECTION) {
      setOpened(true);
      return;
    }
    setStep(step - 1);
  };

  useEffect(() => {
    document.querySelector('.page-content > div > div')?.scrollTo(0, 0);
  }, [step]);

  return (
    <CustomBgPageTemplate
      pageName="CarWashHand"
      title={step === steps.REVIEW ? '予約内容の確認' : '出張水なし手洗い洗車'}
      className={style['page']}
      handleClickBack={handleClickBack}
      background={{ background: '#fff' }}
      showNavBack
    >
      <PopupCancel
        closeOnEscape={false}
        closeByBackdropClick={false}
        status={status}
        opened={opened}
        onPopupClosed={() => setOpened(false)}
      />
      {step === steps.PRE_SELECTION && (
        <CarWashHandStep1
          step1Data={step1Data}
          courseItems={courseItems}
          optionItems={optionItems}
          onSubmit={submitStep1}
          onClickCancel={() => setOpened(true)}
        />
      )}
      {step === steps.REGISTATION && (
        <CarWashHandStep2
          step2Data={step2Data}
          onSubmit={submitStep2}
          onClickCancel={() => setOpened(true)}
        />
      )}
      {step === steps.ADDITIONAL_INFO && (
        <CarWashHandStep3
          courseItem={courseItem}
          step3Data={step3Data}
          onSubmit={submitStep3}
          onClickCancel={() => setOpened(true)}
        />
      )}
      {step === steps.REVIEW && (
        <CarWashHandStep4
          initialStatus={initialStatus}
          step1Data={step1Data}
          step2Data={step2Data}
          step3Data={step3Data}
          optionItem={optionItem}
          courseItem={courseItem}
          onSubmit={submitStep4}
          setInitialStatus={setInitialStatus}
          onClickCancel={() => setOpened(true)}
        />
      )}
    </CustomBgPageTemplate>
  );
};
