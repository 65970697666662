import { FC, useEffect, useState } from 'react';
import { Rank } from './Rank/Rank';
import { RANK_THEMES, Rank as RankEnum } from '@/consts/rank';
import { useRank } from '@/hooks/api/rank/useRank';
import { RankTheme } from '@/types/api/rank';

type RankPageProps = {
  redirectPage: string;
};

export const RankPage: FC<RankPageProps> = ({ redirectPage }) => {
  const { rankTheme, userRank, rankBenefits } = useRank();
  const [currentTheme, setCurrentTheme] = useState<RankTheme>();

  useEffect(() => {
    setCurrentTheme(rankTheme ? rankTheme : RANK_THEMES[RankEnum.BRONZE]);
  }, [rankTheme]);

  return (
    <Rank
      userRank={userRank}
      currentTheme={currentTheme}
      rankBenefits={rankBenefits}
      redirectPage={redirectPage}
    />
  );
};
