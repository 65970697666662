import { FC } from 'react';
import './officeFeeInfoStyle.scss';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

interface OfficeFeeInfoProps {}

export const OfficeFeeInfo: FC<OfficeFeeInfoProps> = ({}) => {
  return (
    <RoundSheet py="6px">
      <div className="office-fee-box list">
        <div className="accordion-item">
          <a className="item-content item-link" href="#">
            <div className="item-inner">
              <div className="item-title">
                <i className={`icon left left-alert icon-bulb`} />
                事務管理手数料につきまして
              </div>
            </div>
          </a>
          <div className="accordion-item-content">
            <div className="content">
              <p>
                ご納車後にございます数百円のご請求は、保証に関する事務管理手数料でございます。
              </p>
              <p>
                保証加入後に1度だけお引落（もしくはクレジット決済）させていただいております。
              </p>
            </div>
            <div className="note">
              <p>※ 以下のケースもございます</p>
              <p>・事務管理手数料が発生しない</p>
              <p>・複数年プランにおいて、年一回ご請求がある</p>
            </div>
          </div>
        </div>
      </div>
    </RoundSheet>
  );
};
