import perse from 'html-react-parser';
import { FC } from 'react';
import style from './TradeAssuranceTerms.module.css';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const TradeAssuranceTerms: FC = () => {
  const { data } = useTerm(termType.TRADE_GUARANTEE);
  const warrantyTerms = (data?.term ?? '').replaceAll('\n', '<br />');

  const wrappedTable =
    `<div className="${style['scrollable-table']}">` + warrantyTerms + '</div>';

  return (
    <PopupPageTemplate
      id="TradeAssuranceTerms"
      pageName="TradeAssuranceTerms"
      containerClass={style['popup-container']}
      title="取引保証プラン利用規約"
      isCloseIcon={true}
    >
      <div className={style['trade-assurance-terms']}>
        <div className={style.container}>{perse(wrappedTable)}</div>
      </div>
    </PopupPageTemplate>
  );
};
