import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { getDriveRecordData } from './DriveRecordDataHelper';
import { TransitionChart } from '@/components/projects/TransitionChart/TransitionChart';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { AuthInfoState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useDriveRecordData } from '@/hooks/api/driveRecord/useDriveRecordData';
import { LinkDriveOwner } from '@/types/api/infoApi';
import './DriveRecordDataStyle.scss';

interface DriveRecordDataProps {
  f7router: Router.Router;
}

export const DriveRecordData: FC<DriveRecordDataProps> = ({ f7router }) => {
  const linkDriveOwnerInfo = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const ownerId = linkDriveOwnerInfo.owner_id;
  const mCustomerId = authInfo.m_customer_id;

  const { data, historyData, isLoading } = useDriveRecordData(
    ownerId,
    mCustomerId,
  );
  const driveRecordData = getDriveRecordData(data, historyData);
  const handleClickDrivingMonthlyData = () => {
    f7.view.main.router.navigate(paths.driveScore, {
      props: { f7router: f7router },
    });
  };

  const handleClickBrakesData = () => {
    f7.view.main.router.navigate(paths.brakesData, {
      props: { f7router: f7router },
    });
  };

  const handleClickAccelerationData = () => {
    f7.view.main.router.navigate(paths.accelerationData, {
      props: { f7router: f7router },
    });
  };

  const pieces = [
    // A
    {
      gt: 80,
      lte: 100,
      color: '#1E55E6',
    },
    // B
    {
      gt: 60,
      lte: 80,
      color: '#00dcfa',
    },
    // C
    {
      gt: 0,
      lte: 60,
      color: '#FFBF40',
    },
  ];

  const maxCountOfRapidAcceleration = Math.max(
    ...driveRecordData?.drive_rapid_acceleration.yearScoreList,
  );
  const minChartHeightRem = 24;
  const heightPerChartBarRem = 6; // 視覚的に6remが最適だと判断
  // Note: 平均急発進回数チャートのみheightが可変
  const rapidAccelerationChartHeightRem =
    maxCountOfRapidAcceleration * heightPerChartBarRem;

  return (
    <PageTemplate pageName="Data" title="データ" showNavBack>
      <div>
        {!isLoading && (
          <div>
            <div
              className="drive-data-card_graph info-card info-card-elevated u-padding-top-sm u-padding-bottom-sm u-margin-bottom-md"
              style={{ width: '100 %', height: '24rem' }}
            >
              <TransitionChart
                id="daytimeDrivingDurationTranstion"
                title="一日の運転時間"
                yearScoreList={
                  driveRecordData?.drive_total_trip_time.yearScoreList
                }
                createMonthly={
                  driveRecordData?.drive_total_trip_time.createMonthly
                }
                showLabel={false}
                showSplitLineX={false}
                axisLabelUnit="時間"
                maxNumber={driveRecordData?.driveDurationScale.maxNumber}
                axisLabelIntervalY={0.5}
                gridLeft="14%"
                seriesType="bar"
                axisLabelInterval={0}
              />
            </div>
            <div
              className="drive-data-card_graph info-card info-card-elevated u-padding-top-sm u-padding-bottom-sm u-margin-bottom-md"
              style={{ width: '100 %', height: '24rem' }}
            >
              <TransitionChart
                id="daytimeDrivingDurationTranstion"
                title="一日の平均燃費"
                yearScoreList={driveRecordData?.drive_fuel.yearScoreList}
                createMonthly={driveRecordData?.drive_fuel.createMonthly}
                showLabel={false}
                showSplitLineX={false}
                axisLabelUnit="km/L"
                minNumber={0}
                maxNumber={30}
                axisLabelIntervalY={10}
                gridLeft="14%"
                seriesType="bar"
                axisLabelInterval={0}
              />
            </div>
            <div className="drive-data-card_graph info-card info-card-elevated u-padding-top-sm u-padding-bottom-sm u-margin-bottom-md">
              <div
                style={{
                  width: '100 %',
                  height: '24rem',
                  position: 'relative',
                }}
                onClick={handleClickDrivingMonthlyData}
              >
                <div className="icon-container">
                  <i className="icon-right" style={{ fontSize: '3.4rem' }} />
                </div>
                <TransitionChart
                  id="daytimeDrivingDurationTranstion"
                  title="安全運転スコア"
                  yearScoreList={
                    driveRecordData?.drive_phyd_score.yearScoreList
                  }
                  createMonthly={
                    driveRecordData?.drive_phyd_score.createMonthly
                  }
                  showLabel={false}
                  showSplitLineX={false}
                  minNumber={0}
                  maxNumber={100}
                  axisLabelIntervalY={20}
                  gridLeft="14%"
                  seriesType="bar"
                  axisLabelInterval={0}
                  pieces={pieces}
                />
              </div>
              <div className="level-desc-bar u-margin-top-sm">
                <div>判定</div>
                <div>
                  <span>A</span>
                </div>
                <div>
                  <span>B</span>
                </div>
                <div>
                  <span>C</span>
                </div>
              </div>
            </div>
            <div
              className="drive-data-card_graph info-card info-card-elevated u-padding-top-sm u-padding-bottom-sm u-margin-bottom-md"
              style={{ width: '100 %', height: '24rem', position: 'relative' }}
              onClick={handleClickBrakesData}
            >
              <div className="icon-container">
                <i className="icon-right" style={{ fontSize: '3.4rem' }} />
              </div>
              <TransitionChart
                id="daytimeDrivingDurationTranstion"
                title="平均急ブレーキ回数"
                yearScoreList={
                  driveRecordData?.drive_rapid_deceleration.yearScoreList
                }
                createMonthly={
                  driveRecordData?.drive_rapid_deceleration.createMonthly
                }
                showLabel={false}
                showSplitLineX={false}
                axisLabelUnit="回"
                axisLabelIntervalY={0.5}
                gridLeft="14%"
                seriesType="bar"
                axisLabelInterval={0}
                pieces={[{ gt: 0, lte: 100, color: '#FF4655' }]}
              />
            </div>
            <div
              className="drive-data-card_graph info-card info-card-elevated u-padding-top-sm u-padding-bottom-sm u-margin-bottom-md"
              style={{
                width: '100%',
                height: `${Math.max(
                  rapidAccelerationChartHeightRem,
                  minChartHeightRem,
                )}rem`,
                position: 'relative',
              }}
              onClick={handleClickAccelerationData}
            >
              <div className="icon-container">
                <i className="icon-right" style={{ fontSize: '3.4rem' }} />
              </div>
              <TransitionChart
                id="daytimeDrivingDurationTranstion"
                title="平均急発進回数"
                yearScoreList={
                  driveRecordData?.drive_rapid_acceleration.yearScoreList
                }
                createMonthly={
                  driveRecordData?.drive_rapid_acceleration.createMonthly
                }
                showLabel={false}
                showSplitLineX={false}
                axisLabelUnit="回"
                axisLabelIntervalY={0.5}
                gridLeft="14%"
                seriesType="bar"
                axisLabelInterval={0}
                pieces={[{ gt: 0, lte: 100, color: '#FF4655' }]}
                chartTopPosition="60px" // titleのheight(28px) + titleの上下margin(32px)
              />
            </div>
          </div>
        )}
      </div>
    </PageTemplate>
  );
};
