import { FC } from 'react';
import style from './DrivingScoreTotalStyle.module.css';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { shortTermFlg } from '@/consts/monthlyReport';
import { MonthlyPhydReportResponse } from '@/types/api/driveRecordApi';
import { hyphenDate } from '@/utils/date';

type DrivingScoreTotalProps = {
  monthlyReport: MonthlyPhydReportResponse['data']['data'][0]['monthly_report'];
};
export const DrivingScoreTotal: FC<DrivingScoreTotalProps> = ({
  monthlyReport,
}) => {
  const linkDriveOwnerInfo = store.state.linkDriveOwnerInfo;

  return (
    <PopupPageTemplate
      pageName="DrivingScoreTotal"
      title="安全運転スコア（累計）"
    >
      {!!monthlyReport ? (
        <div className={style.container}>
          <div className={`${style.list} ${style['judgement-period']}`}>
            <h3 className={style.head}>判定期間</h3>
            <p className={style['list-text']}>
              {`${hyphenDate(monthlyReport.total_from_date ?? '')}~${hyphenDate(
                monthlyReport.total_to_date ?? '',
              )}`}
            </p>
          </div>
          <div
            className={`${style.list} ${style['cumulative-total-container']}`}
          >
            <div className={style['cumulative-total']}>
              <h3 className={style.head}>お客様の累計スコア</h3>
              <p>
                <span className={style['score-text']}>
                  {monthlyReport.total_score ?? '-'}
                </span>
                点
              </p>
            </div>
            <div className={style['cumulative-total']}>
              <h3 className={style.head}>お客様の累計走行距離</h3>
              <p>
                <span className={style['score-text']}>
                  {monthlyReport.total_driving_distance ?? '-'}
                </span>
                km
              </p>
            </div>
          </div>
          {monthlyReport.short_term_flg !== shortTermFlg.shortTerm && (
            <div
              className={`${style.list} ${style['operational-characteristics']}`}
            >
              <h3 className={style.head}>運転特性割引</h3>
              <span className={style['score-text']}>
                {monthlyReport.discount_rate ?? '8%'}
              </span>
            </div>
          )}

          <div className={`${style.list} ${style['vehicle-container']}`}>
            <div className={style['vehicle-properties']}>
              <div className={style['vehicle-property']}>
                <h3 className={style.head}>車台番号</h3>
                <p className={style['vehicle-symbol']}>
                  {linkDriveOwnerInfo.vin}
                </p>
              </div>
              <div className={style['vehicle-property']}>
                <h3 className={style.head}>デバイス区分</h3>
                <p className={style['vehicle-symbol']}>LD</p>
              </div>
            </div>
            <div className={style['vehicle-property']}>
              <h3 className={style.head}>デバイスキー</h3>
              <p className={style['vehicle-symbol']}>
                {linkDriveOwnerInfo.device_key}
              </p>
            </div>
          </div>

          {monthlyReport.advice_message && (
            <div className={`${style.list} ${style['advice-container']}`}>
              <p className={style.advice}>
                {monthlyReport.total_safety_driving_advice}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="no-data u-text-center u-font-size-xxl">No Data</div>
        </div>
      )}
    </PopupPageTemplate>
  );
};
