import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { AmountPay } from './AmountPay/AmountPay';
import { CarInfo } from './CarInfo/CarInfo';
import { PaymentInfo } from './PaymentInfo/PaymentInfo';
import { SuccessDialog } from './SuccessDialog/SuccessDialog';
import { useContactConfirm } from './useContactConfirm';
import { Button } from '@/components/uiParts/Button';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { BlankPageTemplate } from '@/components/uiParts/Template/BlankPageTemplate/BlankPageTemplate';
import { paths } from '@/config/paths';
import './ContractConfirmStyles.scss';
import { store } from '@/config/store';

interface ContractConfirmPageProps {
  f7router?: Router.Router;
  accessToken: string;
  websocketToken: string;
}

export const ContractConfirmPage: FC<ContractConfirmPageProps> = ({
  accessToken,
  websocketToken,
}) => {
  const { isSuccess, qrAuthInfo, submitSignature } = useContactConfirm(
    accessToken,
    websocketToken,
  );
  const contractSignature = useStore(store, 'getContractSignature');

  const handleClickAction = () => {
    f7.view.main.router.navigate(paths.contractSignature, {
      animate: false,
      props: {
        event: submitSignature,
      },
    });
  };

  return (
    <BlankPageTemplate pageName="ContractConfirm" title="契約内容の確認">
      <div className="container">
        <RoundSheet>
          <div className="heading-with-button u-font-size-xl u-font-bold">
            車両情報
          </div>
          <CarInfo
            mkNm={qrAuthInfo.mkNm}
            carNm={qrAuthInfo.carNm}
            carPhotoUrl={qrAuthInfo.carPhotoUrl}
            gradeNm={qrAuthInfo.gradeNm}
            colorNm={qrAuthInfo.colorNm}
          />
        </RoundSheet>
        <RoundSheet>
          <PaymentInfo
            title="お支払い情報"
            paymentMethod={qrAuthInfo.paymentMethodName}
            paymentNumber={qrAuthInfo.paymentTimes}
          />
        </RoundSheet>
        <RoundSheet>
          <AmountPay
            title="お支払い金額"
            bodyPrice={qrAuthInfo.carPrice}
            totalOptionCost={qrAuthInfo.optionTotalPrice}
            totalExpenses={qrAuthInfo.otherOptionTotalPrice}
            totalTrade={qrAuthInfo.buyingPrice}
            totalPayment={qrAuthInfo.totalPrice}
          />
        </RoundSheet>
        {!contractSignature ? (
          <Button
            text="署名する"
            handleClickAction={handleClickAction}
            style={{ width: '48%', margin: '0.5rem auto 0' }}
          />
        ) : (
          <RoundSheet>
            <div className="display-flex-sb u-font-size-xl u-font-bold">
              <span>ご署名</span>
              <img src={contractSignature} className="signature" />
            </div>
          </RoundSheet>
        )}
        {isSuccess && <SuccessDialog />}
      </div>
    </BlankPageTemplate>
  );
};
