import { List, ListItem } from 'framework7-react';
import { FC, useState } from 'react';
import { ContactPopup } from '../RepairStatusBlock';
import style from './RepairResultStyle.module.scss';
import { MAffiliateSettingsApiResponse } from '@/types/api/infoApi';

type ResultFooterProps = {
  data?: MAffiliateSettingsApiResponse['data']['data'];
  isShowRecommend?: boolean;
};

export const ResultFooter: FC<ResultFooterProps> = ({
  data,
  isShowRecommend = true,
}) => {
  const [isContactPopupOpened, setIsContactPopupOpened] = useState(false);

  return (
    <div className={style['common-part']}>
      {isShowRecommend ? (
        <>
          <div className={style['header']}>この後の流れ</div>
          <div className={style['footer-wrraper']}>
            <List menuList mediaList className={style['list']}>
              <ListItem title="現在お車がお手元にある場合">
                作業予定が決まりましたら、ご入庫先工場よりご連絡がございますので、入庫のお打合せをお願いいたします。
              </ListItem>
              <ListItem title="現在お車が工場にある場合">
                修理完了次第、ご入庫先工場よりご連絡がありますので、お車のお引き取りをお願いいたします。
              </ListItem>
            </List>
          </div>
        </>
      ) : null}
      <div className={`${style['desc']} u-padding-left-0`}>
        ご不明なことがございましたら、
        <span onClick={() => setIsContactPopupOpened(true)}>
          サポートセンター
        </span>
        までお問い合わせください。
      </div>
      {data && (
        <ContactPopup
          opened={isContactPopupOpened}
          handleClose={() => setIsContactPopupOpened(false)}
          data={data}
        />
      )}
    </div>
  );
};
