import { Router } from 'framework7/types';
import React, { FC } from 'react';
import { SelfScanFailure } from '../SelfScanFailure/SelfScanFailure';
import { SelfScanSuccess } from '../SelfScanSuccess/SelfScanSuccess';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';

type SelfScanResultProps = {
  f7router: Router.Router;
};

export const SelfScanResult: FC<SelfScanResultProps> = ({ f7router }) => {
  return (
    <PopupPageTemplate pageName="SelfScanResult" title="セルフスキャン">
      <div style={{ width: '100%', height: '32px' }} />
      <SelfScanSuccess />
      <SelfScanFailure f7router={f7router} />
    </PopupPageTemplate>
  );
};
