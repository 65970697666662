import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchCalcScoreApi } from '@/api/driveRecordApi';
import { AdInsuranceDiscount, CalcScoreParams } from '@/types/api/linkDriveApi';

export const useCalcScore = (params: CalcScoreParams) => {
  const [data, setData] = useState<AdInsuranceDiscount>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    const fn = async () => {
      try {
        f7.preloader.show();
        const res = await fetchCalcScoreApi(params);
        if (!res.data.success || Array.isArray(res.data.data)) {
          setData(undefined);
          return;
        }
        setData(res.data?.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };
    fn();
  }, [params]);

  return {
    data,
    error,
  };
};
