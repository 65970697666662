import { FC } from 'react';
import { AboutContent } from './AboutContent';
import style from './CarWashHandAboutPageStyle.module.css';
import { BlankPageTemplate } from '@/components/uiParts/Template/BlankPageTemplate/BlankPageTemplate';

type CarWashHandAboutPageProps = {
  showLink?: boolean;
};

export const CarWashHandAboutPage: FC<CarWashHandAboutPageProps> = ({
  showLink = true,
}) => {
  return (
    <BlankPageTemplate
      title="出張水なし手洗い洗車"
      pageName="CarWashHandAbout"
      showNavBack={showLink}
    >
      <div className={style['full-page']}>
        <AboutContent showLink={showLink} />
      </div>
    </BlankPageTemplate>
  );
};
