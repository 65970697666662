import { Col, f7, Row } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import './LinkDriveCalibrationConfirmStyle.scss';
import { f7CustomBack } from '@/utils/utils';

interface LinkDriveCalibrationConfirmProps {
  f7router: Router.Router;
}

export const LinkDriveCalibrationConfirm: FC<
  LinkDriveCalibrationConfirmProps
> = ({ f7router }) => {
  // TODO: キャンセル処理と中止処理未完成
  const handleClickInterruptionCalibration = () => {
    f7.view.main.router.navigate(paths.connectorSetting);
    f7CustomBack(['#LinkDriveCalibrationConfirm']);
  };

  return (
    <PopupPageTemplate
      id="LinkDriveCalibrationConfirm"
      pageName="LinkDriveCalibrationConfirm"
      title=""
    >
      <div className="container">
        <div className="container-title">
          <i className="icon-info" />
          <h2>キャリブレーションを中止しますか？</h2>
        </div>
        <div className="u-margin-top-md">
          キャリブレーションは完了していません。
          <br />
          本当に中止しますか。
        </div>
        <Row style={{ marginTop: '3rem' }}>
          <Col width="10" />
          <Col width="80">
            <Button
              style={{ backgroundColor: '#ff4655' }}
              text="キャリブレーションを中止する"
              handleClickAction={handleClickInterruptionCalibration}
            />
          </Col>
          <Col width="10" />
        </Row>
        <Row style={{ marginTop: '1.8rem' }}>
          <Col width="10" />
          <Col width="80">
            <Button
              style={{ color: '#232323', backgroundColor: '#efefef' }}
              text="キャンセル"
              handleClickAction={() =>
                f7CustomBack(['#LinkDriveCalibrationConfirm'])
              }
            />
          </Col>
          <Col width="10" />
        </Row>
      </div>
    </PopupPageTemplate>
  );
};
