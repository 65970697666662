import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { multipleAffiliateInfo } from '@/api/authApi';
import { customerInfoApi } from '@/api/customerApi';
import { store } from '@/config/store';
import {
  CustomerInfoApiParams,
  CustomerInfoApiResponse,
} from '@/types/api/customerApi';

export const useBasicInfo = (
  customerId: CustomerInfoApiParams['m_customer_id'],
) => {
  const [data, setData] = useState<CustomerInfoApiResponse['data']>();
  const [isMultiAffiliate, setIsMultiAffiliate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const { data: customerInfo } = await customerInfoApi({
          m_customer_id: customerId,
        });
        if (!customerInfo.success) {
          throw new Error('基礎情報取得に失敗');
        }
        let parameter = '';
        if (store.state.loginInfo?.loginType === 'email') {
          parameter = customerInfo.customer?.email || '';
        }
        if (store.state.loginInfo?.loginType === 'telephone') {
          parameter = customerInfo.customer?.phone_no1 || '';
        }
        if (store.state.loginInfo?.loginType === 'admin') {
          parameter =
            customerInfo?.customer?.email ||
            customerInfo?.customer?.phone_no1 ||
            '';
        }
        if (parameter) {
          const { data: affiliatesInfo } = await multipleAffiliateInfo({
            parameter,
          });
          setIsMultiAffiliate(affiliatesInfo.m_customers.length > 1);
        } else {
          setIsMultiAffiliate(false);
        }
        setData(customerInfo);
      } catch (e) {
        if (e instanceof Error) {
          console.error(e.message);
        }
        setError(e as AxiosError);
        f7.views.main.router.navigate('/');
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [customerId]);

  return {
    data,
    isLoading,
    error,
    isMultiAffiliate,
  };
};
