import { yupResolver } from '@hookform/resolvers/yup';
import { f7 } from 'framework7-react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { validationSchema } from '../validationSchema';
import style from './contactPhoneStyle.module.css';
import { BirthdayInput } from '@/components/uiParts/BirthdayInput/BirthdayInput';
import { Button } from '@/components/uiParts/Button';
import { ParallelContactInput } from '@/components/uiParts/ParallelInput/ParallelContactInput';
import { RhfTextareaWithLabel } from '@/components/uiParts/ReactHookForm/RhfTextareaWithLabel/RhfTextareaWithLabel';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { RhfTextInput } from '@/components/uiParts/TextInput/RhfTextInput';
import { paths } from '@/config/paths';
import { useSendInquiryAboutTel } from '@/hooks/api/inquiry/useSendInquiryAboutTel';

interface FormInput {
  lastName: string;
  firstName: string;
  kanaLastName: string;
  kanaFirstName: string;
  oldPhoneNumber: string;
  newPhoneNumber: string;
  email: string;
  other: string;
  birthYear: number;
  birthMonth: number;
  birthDay: number;
}

export const ContactPhone = () => {
  // hook
  const formMethods = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
  });
  const { sendInquiry } = useSendInquiryAboutTel();
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const params = {
      birthday_day: Number(data.birthDay),
      birthday_month: Number(data.birthMonth),
      birthday_year: Number(data.birthYear),
      family_name: data.lastName,
      family_name_kana: data.kanaLastName,
      first_name: data.firstName,
      first_name_kana: data.kanaFirstName,
      mail_address: data.email,
      phone_no: data.newPhoneNumber,
      phone_no_before: data.oldPhoneNumber,
      supplements_item: data.other,
    };
    await sendInquiry(params);
    f7.views.main.router.navigate(paths.contactComplete, {
      props: { email: data.email },
    });
  };
  return (
    <PopupPageTemplate pageName="ContactNumber" title="問い合わせ">
      <div className={style['container']}>
        <div className={style['sub-title-wrapper']}>
          <h3>下記の情報を入力の上、送信してください</h3>
          <h3>
            {`お客様情報を確認の上\nお電話・メールにて連絡させていただきます`}
          </h3>
        </div>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`list no-hairlines ${style['form-wrapper']}`}>
              <ul>
                <ParallelContactInput
                  label="お名前"
                  firstInput={{
                    name: 'lastName',
                    placeholder: '姓',
                  }}
                  secondInput={{
                    name: 'firstName',
                    placeholder: '名',
                  }}
                  required
                  error={errors.firstName?.message || errors.lastName?.message}
                />
                <ParallelContactInput
                  label="フリガナ"
                  firstInput={{
                    name: 'kanaLastName',
                    placeholder: 'セイ',
                  }}
                  secondInput={{
                    name: 'kanaFirstName',
                    placeholder: 'メイ',
                  }}
                  required
                  error={
                    errors.kanaFirstName?.message ||
                    errors.kanaLastName?.message
                  }
                />
                <RhfTextInput
                  name="newPhoneNumber"
                  label="現在ご利用中の電話番号"
                  placeholder="09000000000"
                  required
                  annotation="ハイフンなし"
                  error={errors.newPhoneNumber?.message}
                />
                <RhfTextInput
                  name="oldPhoneNumber"
                  label="以前利用されていた電話番号"
                  placeholder="09000000000"
                  required
                  annotation="ハイフンなし"
                  error={errors.oldPhoneNumber?.message}
                />
                <BirthdayInput
                  required
                  error={
                    errors.birthDay?.message ||
                    errors.birthMonth?.message ||
                    errors.birthYear?.message
                  }
                />
                <RhfTextInput
                  name="email"
                  label="メールアドレス"
                  placeholder="mailaddress@example.com"
                  required
                  annotation="連絡・返信先として使用します"
                  error={errors.email?.message}
                />
                <RhfTextareaWithLabel
                  name="other"
                  label="その他補足事項"
                  placeholder="補足事項"
                />
              </ul>
              <div className={style['button-container']}>
                <div className={style['button-wrapper']}>
                  <Button type="submit">送信する</Button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </PopupPageTemplate>
  );
};
