import { FC } from 'react';
import style from './ProblemGuideStyles.module.css';

interface ChildProblemGuideProps {
  icon: JSX.Element;
  title: string;
  content: string | JSX.Element | JSX.Element[];
  order: number;
}

export const ChildProblemGuide: FC<ChildProblemGuideProps> = ({
  icon,
  title,
  content,
  order,
}) => {
  return (
    <div className="display-flex u-margin-bottom-0">
      <div className={style['icon']}>
        <div>{order}</div>
        {icon}
      </div>
      <div style={{ flex: 1 }}>
        <div>{title}</div>
        <div className={style['content']}>{content}</div>
      </div>
    </div>
  );
};
