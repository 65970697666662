import { FC } from 'react';
import { store } from '@/config/store';
import { storeDispatch } from '@/utils/store';
import './UpdateWarrantyStep4Page.scss';

interface Props {
  setStep: (n: number) => void;
}
export const UpdateWarrantyStep4Page: FC<Props> = ({ setStep }) => {
  const warranty = store.state.updateWarrantyInfo;
  const paymentList = [
    {
      loan_num: warranty.loan1_num_continuous,
      loan_num_f: warranty.loan1_num_f_continuous,
      loan_num_s: warranty.loan1_num_s_continuous,
    },
    {
      loan_num: warranty.loan2_num_continuous,
      loan_num_f: warranty.loan2_num_f_continuous,
      loan_num_s: warranty.loan2_num_s_continuous,
    },
  ];

  const execEmit = async (
    n: number,
    loan_num: number,
    loan_num_f: number,
    loan_num_s: number,
  ) => {
    await storeDispatch('setUpdateLoan', 'updateLoan', {
      loan_num: loan_num,
      loan_num_f: loan_num_f,
      loan_num_s: loan_num_s,
    });
    setStep(n);
  };

  const amountTotal = (x: {
    loan_num: number;
    loan_num_f: number;
    loan_num_s: number;
  }) => {
    if (x) {
      return x.loan_num_f + x.loan_num_s * (x.loan_num - 1);
    } else {
      return 0;
    }
  };

  return (
    <div data-name="UpdateWarrantyStep4">
      <table className="menu-table u-margin-bottom-lg">
        <tr className="menu-tr-1">
          <td>●</td>
          <td>●</td>
          <td>●</td>
          <td className="select-number">
            <div>4</div>
          </td>
          <td>●</td>
          <td>●</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }} className="menu-table-item">
            プラン選択
          </td>
          <td className="menu-table-item">
            契約情報
            <br />
            確認
          </td>
          <td className="menu-table-item">
            重要事項
            <br />
            確認
          </td>
          <td className="menu-falling-under menu-table-item">
            支払回数
            <br />
            選択
          </td>
          <td className="menu-table-item">
            決済方法
            <br />
            選択
          </td>
          <td style={{ verticalAlign: 'top' }} className="menu-table-item">
            最終確認
          </td>
        </tr>
      </table>

      <div>
        <div className="step-title u-margin-bottom-sm">お支払い回数の選択</div>
        <div>お支払い回数を選択してください</div>
        {paymentList.map((item, index) => (
          <div className="card" key={index}>
            {item.loan_num === 1 ? (
              <>
                <div className="number-payments">一括払い</div>
                <div className="card-content">
                  <div className="list no-ios-edges">
                    <ul style={{ paddingBottom: '1em' }}>
                      <li className="plan-description">
                        <div className="item1">お支払額</div>
                        <div className="amount-paid">
                          {item.loan_num_f.toLocaleString()}円
                        </div>
                      </li>
                    </ul>
                    <div className="btn-div">
                      <button
                        className="btn"
                        onClick={() =>
                          execEmit(
                            5,
                            item.loan_num,
                            item.loan_num_f,
                            item.loan_num_s,
                          )
                        }
                      >
                        一括で支払う
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : item.loan_num > 1 ? (
              <>
                <div className="number-payments">
                  分割払い ({item.loan_num}回)
                </div>
                <div className="card-content">
                  <div className="list no-ios-edges">
                    <ul>
                      <li className="plan-description">
                        <div className="item1">初回お支払い</div>
                        <div className="amount-paid">
                          {item.loan_num_s.toLocaleString()} 円
                        </div>
                      </li>
                      <li className="plan-description">
                        <div className="item1">2回目以降月額</div>
                        <div className="amount-paid">
                          {item.loan_num_f.toLocaleString()} 円
                        </div>
                      </li>
                      {/* <li className="plan-description">
                        <div className="item1">月額</div>
                        <div className="amount-paid">
                          {item.loan_num_f.toLocaleString()}円
                        </div>
                      </li>
                      <li className="plan-description2">
                        <div className="item1" />
                        <div style={{ color: 'gray' }}>
                          ※初月のみ
                          {item.loan_num_s.toLocaleString()}円
                        </div>
                      </li> */}
                      <li className="plan-description">
                        <div className="item1">合計</div>
                        <div className="total-amount">
                          {amountTotal(item).toLocaleString()} 円
                        </div>
                      </li>
                    </ul>

                    <div className="btn-div">
                      <button
                        className="btn"
                        onClick={() =>
                          execEmit(
                            5,
                            item.loan_num,
                            item.loan_num_f,
                            item.loan_num_s,
                          )
                        }
                      >
                        {item.loan_num}回払いにする
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
