import { Router } from 'framework7/types';
import { AppSettingsPage } from '@/components/pages/AppSettings/AppSettingsPage';
import { PushSettingsPage } from '@/components/pages/PushSettings/PushSettingsPage';
import { paths } from '@/config/paths';

export const settingRoutes: Router.RouteParameters[] = [
  {
    path: paths.appSettings,
    component: AppSettingsPage,
    name: 'アプリ関連設定',
  },
  {
    path: paths.pushSettings,
    component: PushSettingsPage,
    name: '通知設定',
  },
];
