import { URLSearchParams } from 'url';
import { f7 } from 'framework7-react';
import { autoLoginHelper } from './autoLoginHelper';
import { fetchCarRepairInspection } from '@/api/carRepairApi';
import { fetchInquiryListApi } from '@/api/inquiryApi';
import { fetchReservationDetail } from '@/api/reservationApi';
import { getDealWarrantyInfoApi } from '@/api/warrantyApi';
import { findRelevantNextWarranty } from '@/components/pages/WarrantyManagement/WarrantyManagementHelper';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { caller } from '@/consts/reservation';
import { STATUS_TRADE_GUARANTEE, warrantyType } from '@/consts/warranty';
import { Car, Warranty } from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';

export interface DeepLinkURL {
  protocol: string;
  host: string;
  hostname: string;
  pathname: string;
  href: string;
  port: string;
  search: string;
  searchParams: URLSearchParams;
}

(<any>window).onDeeplink = async (
  url: string,
  token: string,
  id: string,
  type?: string,
) => {
  const parsedUrl = parseURL(url);
  const protocol = parsedUrl.protocol;

  if (protocol === 'maikuru:') {
    const isSameCustomer =
      !!id &&
      (!parsedUrl.searchParams.get('m_customer_id') ||
        parsedUrl.searchParams.get('m_customer_id') === id);
    if (token && isSameCustomer) {
      await autoLoginHelper({
        token,
        id: Number(id),
        type,
        f7router: f7.views.main.router,
      });
      await handleMaikuruProtocolDeeplink(parsedUrl);
    } else {
      storeDispatch('setDeeplink', 'deeplink', url);
    }
  }
  if (protocol === 'http:' || protocol === 'https:') {
    window.open(url, '_blank');
  }
};

export const handleMaikuruProtocolDeeplink = async (parsedUrl: DeepLinkURL) => {
  f7.preloader.show();
  const pathname = parsedUrl.pathname;
  const search = parsedUrl.search;
  const searchParams = parsedUrl.searchParams;

  if (pathname === '/news/') {
    f7.view.main.router.navigate('/news/');
    f7.preloader.hide();
    return;
  }
  if (pathname === '/tasks/') {
    f7.view.main.router.navigate('/news/todo_list/');
    f7.preloader.hide();
    return;
  }
  if (pathname === '/inquiry/') {
    const inquiryId = searchParams.get('t_customer_inquiry_header_id');
    if (inquiryId) {
      await navigateToInquiryDetail(inquiryId);
    } else {
      f7.view.main.router.navigate('/news/consulting/');
    }
    f7.preloader.hide();
    return;
  }
  if (pathname === '/reservation/') {
    const serviceType = searchParams.get('service_type');
    const reservationId = searchParams.get('reservation_id');
    const repairId = searchParams.get('t_repair_id');
    const mCustomerId = searchParams.get('m_customer_id');
    if (reservationId && serviceType) {
      await navigateToCarwashDetail(serviceType, Number(reservationId));
    } else if (repairId && serviceType) {
      await navigateToCarRepair(Number(repairId), Number(mCustomerId));
      return;
    } else {
      f7.view.main.router.navigate(paths.reservationHistory);
    }
    f7.preloader.hide();
    return;
  }
  if (pathname === '/deal_warranty/') {
    const repairReceptId = searchParams.get('t_repair_recept_id');
    if (repairReceptId) {
      await navigateToDealWarrantyDetail(repairReceptId);
    } else {
      f7.view.main.router.navigate(paths.warranty('false'));
    }
    f7.preloader.hide();
    return;
  }
  f7.view.main.router.navigate(pathname + search);
  f7.preloader.hide();
};

const navigateToDealWarrantyDetail = async (repairReceptId: string) => {
  try {
    const res = await getDealWarrantyInfoApi({
      t_repair_recept_id: Number(repairReceptId),
      m_customer_id: store.state.authInfo.m_customer_id,
    });
    if (!res?.data?.success) {
      throw new Error();
    }
    const { data } = res;
    const car = store.state.carsInfo.find(
      (c) => c.t_stock_car_id === data.t_stock_car_id,
    );
    // This is not user owned car or the deal warranty was withdrawned

    if (!car || data.status === STATUS_TRADE_GUARANTEE.WITHDRAWN_APPLICATION) {
      return f7.view.main.router.navigate(paths.home);
    }
    const dealWarranty = car.warranties.find(
      (warranty) =>
        !!warranty.append_warranties?.find(
          (append) => append.warranty_type === warrantyType.DEAL,
        ),
    );
    findAndSetCar({ t_stock_car_id: data.t_stock_car_id }, dealWarranty);
    f7.view.main.router.navigate(paths.tradeGuaranteeApplication, {
      props: {
        isFromDeepLink: true,
        initialData: data,
      },
    });
  } catch {
    f7.dialog.alert('エラーが発生しました。', 'エラー', () => {
      f7.view.main.router.navigate(paths.home);
    });
  }
};

const navigateToInquiryDetail = async (inquiryId: string) => {
  const m_customer_id = store.state.authInfo.m_customer_id;
  const res = await fetchInquiryListApi({ m_customer_id });
  if (res.data.success) {
    const consultingHistoryList = res.data.data.inquiry_header.reverse();
    storeDispatch(
      'setConsultingHistoryList',
      'consultingHistoryList',
      consultingHistoryList,
    );
    const displayNews = consultingHistoryList.find(
      (item) => item.t_customer_inquiry_header_id === Number(inquiryId),
    );
    if (displayNews) {
      f7.view.main.router.navigate(paths.consultingHistory(inquiryId));
      return;
    }
  }
  f7.view.main.router.navigate('/news/consulting/');
};

const navigateToCarRepair = async (
  t_repair_id: number,
  m_customer_id: number,
) => {
  const res = await fetchCarRepairInspection({
    m_customer_id,
    t_repair_id,
    call_kbn: caller.MAIKURU,
  });
  if (res.data.success && res.data.repair_info?.[0]) {
    f7.view.main.router.navigate(paths.repairDetail(String(t_repair_id)));
    findAndSetCar({ t_stock_car_id: res.data.repair_info[0].t_stock_car_id });
    return;
  }
  f7.view.main.router.navigate(paths.reservationHistory);
};

const navigateToCarwashDetail = async (
  service_type: string,
  reservation_id: number,
) => {
  const res = await fetchReservationDetail({ service_type, reservation_id });
  if (res.data.success && res.data.data?.service_type) {
    store.dispatch('setReservationHistory', {
      reservation: {
        reservation_id,
        service_type,
        status: res.data.data.reservation_status,
        order_date: res.data.data.order_date,
      },
    });
    f7.view.main.router.navigate(paths.carwashDetail(String(reservation_id)));
    return;
  }
  f7.view.main.router.navigate(paths.reservationHistory);
};

async function findAndSetCar(
  { t_stock_car_id }: Partial<Car>,
  warranty?: Warranty,
) {
  const cars = store.state.carsInfo;
  const carInfo = cars.find((car) => car.t_stock_car_id === t_stock_car_id);

  if (!carInfo) {
    return;
  }

  await storeDispatch('setCarItemInfo', 'carItemInfo', carInfo);
  await storeDispatch('setWarranties', 'warranties', carInfo?.warranties || []);
  await storeDispatch(
    'setNextWarranties',
    'nextWarranties',
    carInfo?.next_warranty || [],
  );
  await storeDispatch(
    'setCurrentWarranty',
    'currentWarranty',
    warranty || carInfo?.warranties?.[0],
  );
  await storeDispatch(
    'setNextWarranty',
    'nextWarranty',
    findRelevantNextWarranty(
      warranty || carInfo?.warranties?.[0],
      carInfo?.next_warranty,
    ),
  );
}

export function parseURL(url: string): DeepLinkURL {
  const tempURL = new URL(url);
  let parsedURL: DeepLinkURL = {
    protocol: tempURL.protocol,
    host: '',
    hostname: '',
    pathname: '',
    href: tempURL.href,
    port: tempURL.port,
    search: tempURL.search,
    searchParams: tempURL.searchParams,
  };
  if (['http:', 'https:', 'file:'].includes(tempURL.protocol)) {
    parsedURL.host = tempURL.host;
    parsedURL.hostname = tempURL.hostname;
    parsedURL.pathname = tempURL.pathname;
  } else {
    parsedURL.pathname = `/${url.split('//')?.[1]?.split('?')[0] || ''}`;
  }
  return parsedURL;
}

export default {};
