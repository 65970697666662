import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { addressSchema } from '../validationSchema';
import style from './EditAddressStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { TextInput } from '@/components/uiParts/Input/TextInput';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { useUpdateCustomer } from '@/hooks/api/customer/useUpdateCustomer';
import { useSearchAddress } from '@/hooks/api/yahoo/useSearchAddress';
import { Customer } from '@/types/api/customerApi';
import { f7CustomBack } from '@/utils/utils';

interface FormInput {
  zipCode: string;
  prefectures: string;
  municipalities: string;
  street: string;
  buildingRoomName: string;
}

interface EditAddressProps {
  initialSetCustomer: (customer: Customer) => void;
}

// TODO: カスタムフックにする
export const EditAddress: FC<EditAddressProps> = ({ initialSetCustomer }) => {
  const customer = store.state.customerInfo;
  const { searchAddressByZip, data, setData } = useSearchAddress();
  const { updateCustomer } = useUpdateCustomer();
  const formMethods = useForm<FormInput>({
    defaultValues: {
      zipCode: `${customer.zip_code1}-${customer.zip_code2}`,
      prefectures: customer.address1,
      municipalities: customer.address2,
      street: customer.address3,
      buildingRoomName: '',
    },
    resolver: yupResolver(addressSchema),
  });
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;
  const onSubmit: SubmitHandler<FormInput> = (data) => {
    // TODO: 処理は別ファイル
    const zipCode1 = data.zipCode.split('-')[0];
    const zipCode2 = data.zipCode.split('-')[1];
    updateCustomer({
      params: {
        customer: {
          ...customer,
          zip_code1: zipCode1,
          zip_code2: zipCode2,
          address1: data.prefectures,
          address2: data.municipalities,
          address3: data.street,
        },
      },
      onSuccess: () => {
        initialSetCustomer({
          ...customer,
          zip_code1: zipCode1,
          zip_code2: zipCode2,
          address1: data.prefectures,
          address2: data.municipalities,
          address3: data.street,
        });
        f7CustomBack(['#EditAddress']);
      },
    });
  };
  const onBlurSearchAddress = async (e: { target: HTMLInputElement }) => {
    if ('zipCode' in errors) return;
    await searchAddressByZip({
      zipCode: e.target.value,
      onSuccess: (prefectures, municipalities, street) => {
        setValue('prefectures', prefectures);
        setValue('municipalities', municipalities);
        setValue('street', street);
      },
    });
  };
  return (
    <PopupPageTemplate
      id="EditAddress"
      pageName="EditAddress"
      title="お住まい情報の変更"
    >
      <FormProvider {...formMethods}>
        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={style['input-wrap']}>
            <TextInput
              label={'郵便番号'}
              inputName={'zipCode'}
              placeholder={'000-0000'}
              isCheckIcon
              isCheckIconValid={
                !('zipCode' in errors) && !errors.zipCode?.message
              }
              error={errors.zipCode?.message}
              onBlur={(e) => onBlurSearchAddress(e)}
            />
            <TextInput
              label={'都道府県'}
              inputName={'prefectures'}
              placeholder={'東京都'}
              isCheckIcon
              isCheckIconValid={!errors.prefectures?.message}
              error={errors.prefectures?.message}
              value={watch('prefectures')}
              onChange={(e) => {
                setValue('prefectures', e.target.value);
              }}
            />
            <TextInput
              label={'市区町村'}
              inputName={'municipalities'}
              placeholder={'新宿区'}
              isCheckIcon
              isCheckIconValid={!errors.municipalities?.message}
              error={errors.municipalities?.message}
              value={watch('municipalities')}
              onChange={(e) => {
                setValue('municipalities', e.target.value);
              }}
            />
            <TextInput
              label={'町名番地・建物名・部屋番号'}
              inputName={'street'}
              placeholder={'1-2-3'}
              isCheckIcon
              isCheckIconValid={!errors.street?.message}
              error={errors.street?.message}
              value={watch('street')}
              onChange={(e) => {
                setValue('street', e.target.value);
              }}
            />
            <Button text="変更する" type="submit" />
          </div>
        </form>
      </FormProvider>
    </PopupPageTemplate>
  );
};
