import { FC } from 'react';

export const ReferralBenefitIcon: FC<{ color?: string }> = ({
  color = '#AA7F66',
}) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 15.5C13.2091 15.5 15 13.7091 15 11.5C15 9.29086 13.2091 7.5 11 7.5C8.79086 7.5 7 9.29086 7 11.5C7 13.7091 8.79086 15.5 11 15.5ZM21 15.5C23.2091 15.5 25 13.7091 25 11.5C25 9.29086 23.2091 7.5 21 7.5C18.7909 7.5 17 9.29086 17 11.5C17 13.7091 18.7909 15.5 21 15.5ZM3 23.5C3 19.634 6.13401 16.5 10 16.5H12C15.866 16.5 19 19.634 19 23.5V25.5H3V23.5ZM21 22.5C21 20.0571 19.7486 17.9064 17.8519 16.6541C18.3597 16.553 18.8849 16.5 19.4225 16.5H23C26.3137 16.5 29 19.1863 29 22.5V25.5H21V22.5Z"
        fill={color}
      />
    </svg>
  );
};
