import { f7 } from 'framework7-react';
import { FC, ReactNode } from 'react';
import style from './DrivingDataServiceSettingsBoxStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';

interface DrivingDataServiceSettingsBoxProps {
  children?: ReactNode;
}

export const DrivingDataServiceSettingsBox: FC<
  DrivingDataServiceSettingsBoxProps
> = ({ children }) => {
  const handleClickAction = () => {
    f7.views.main.router.navigate(paths.drivingAnalysisServiceAgree);
  };

  return (
    <div className={`${style['terms-item']}`}>
      <div className={`${style['text-link-block-fl-column-center']}`}>
        <div className={`${style['sub-item']}`}>
          <div className={style['content']}>
            <div className={style['content-text']}>{children}</div>
          </div>
          <div className={style['bottom-box']}>
            <div className={style['opacity-box']} />
            <Button
              className={`${style['link-btn']}`}
              handleClickAction={handleClickAction}
            >
              続きを読む
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
