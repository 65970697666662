import { f7 } from 'framework7-react';
import { fetchImpactInfoApi } from '@/api/infoApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { ReportToSentry } from '@/sentry';

export const checkImpactInfo = async (id: number) => {
  try {
    const { data } = await fetchImpactInfoApi({
      m_customer_id: id,
    });
    store.dispatch('setIsImpactInfoChecked', { isImpactInfoChecked: true });
    if (data?.success) {
      f7.view.main.router.navigate(paths.impactInfo);
    }
  } catch (e) {
    ReportToSentry(e, {
      api: 'v1/customer/impact_info',
    });
  }
};

// Global checkECall configuration
declare global {
  interface Window {
    checkECall: (id: number) => void;
  }
}

(<any>window).checkECall = (id: number) => {
  checkImpactInfo(id);
};
