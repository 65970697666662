import { FC } from 'react';
import { CarIconProps } from './CarIcon';

export const CarIcon1: FC<CarIconProps> = ({ color, reference }) => {
  return (
    <svg
      ref={reference}
      width="728"
      height="536"
      viewBox="0 0 91 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.77565 40.7374C6.90541 36.8447 9.81977 33.6111 13.6781 33.0789L21.5002 32L28.5641 26.1646C31.4316 23.7958 35.0348 22.5 38.7542 22.5H58.306C60.3568 22.5 62.3292 23.2875 63.816 24.7L71.5002 32L76.035 32.5915C80.0195 33.1112 83.0002 36.506 83.0002 40.5243V44.9998C83.0002 47.2089 81.2094 48.9998 79.0002 48.9998H10.6358C8.37442 48.9998 6.56267 47.1266 6.63801 44.8665L6.77565 40.7374Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4556 33.1564C22.2409 33.3338 21.981 33.4479 21.7052 33.4859L13.8831 34.5648C10.7482 34.9972 8.38025 37.6245 8.27482 40.7873L8.13718 44.9165C8.09009 46.329 9.22243 47.4998 10.6358 47.4998H79.0002C80.381 47.4998 81.5002 46.3805 81.5002 44.9998V40.5243C81.5002 37.2594 79.0784 34.5012 75.841 34.0789L71.3062 33.4874C70.9912 33.4463 70.6974 33.3063 70.4671 33.0875L62.7829 25.7875C61.5749 24.6399 59.9723 24 58.306 24H38.7542C35.3835 24 32.1181 25.1743 29.5194 27.3211L22.4556 33.1564ZM71.5002 32L63.816 24.7C62.3292 23.2875 60.3568 22.5 58.306 22.5H38.7542C35.0348 22.5 31.4316 23.7958 28.5641 26.1646L21.5002 32L13.6781 33.0789C9.81977 33.6111 6.90541 36.8447 6.77565 40.7374L6.63801 44.8665C6.56267 47.1266 8.37442 48.9998 10.6358 48.9998H79.0002C81.2094 48.9998 83.0002 47.2089 83.0002 44.9998V40.5243C83.0002 36.506 80.0195 33.1112 76.035 32.5915L71.5002 32Z"
        fill="#323232"
      />
      <rect
        x="1"
        y="0.5"
        width="89"
        height="66"
        rx="15.5"
        fill="#F3F5F6"
        stroke="#EFEFEF"
      />
      <path
        d="M6.77565 41.2374C6.90541 37.3447 9.81977 34.1111 13.6781 33.5789L21.5002 32.5L28.5641 26.6646C31.4316 24.2958 35.0348 23 38.7542 23H58.306C60.3568 23 62.3292 23.7875 63.816 25.2L71.5002 32.5L76.035 33.0915C80.0195 33.6112 83.0002 37.006 83.0002 41.0243V45.4998C83.0002 47.7089 81.2094 49.4998 79.0002 49.4998H10.6358C8.37442 49.4998 6.56267 47.6266 6.63801 45.3665L6.77565 41.2374Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0924 34.4274C22.7346 34.723 22.3016 34.9131 21.8418 34.9766L14.0197 36.0555C11.3671 36.4213 9.36347 38.6444 9.27426 41.3207L9.13662 45.4498C9.10837 46.2973 9.78778 46.9998 10.6358 46.9998H79.0002C79.8287 46.9998 80.5002 46.3282 80.5002 45.4998V41.0243C80.5002 38.2617 78.451 35.9278 75.7116 35.5705L71.1769 34.979C70.6519 34.9105 70.1622 34.6771 69.7784 34.3125L62.0942 27.0125C61.072 26.0414 59.7159 25.5 58.306 25.5H38.7542C35.616 25.5 32.5758 26.5933 30.1563 28.592L23.0924 34.4274ZM71.5002 32.5L63.816 25.2C62.3292 23.7875 60.3568 23 58.306 23H38.7542C35.0348 23 31.4316 24.2958 28.5641 26.6646L21.5002 32.5L13.6781 33.5789C9.81977 34.1111 6.90541 37.3447 6.77565 41.2374L6.63801 45.3665C6.56267 47.6266 8.37442 49.4998 10.6358 49.4998H79.0002C81.2094 49.4998 83.0002 47.7089 83.0002 45.4998V41.0243C83.0002 37.006 80.0195 33.6112 76.035 33.0915L71.5002 32.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4556 33.6564C22.2409 33.8338 21.981 33.9479 21.7052 33.9859L13.8831 35.0648C10.7482 35.4972 8.38025 38.1245 8.27482 41.2873L8.13718 45.4165C8.09009 46.829 9.22243 47.9998 10.6358 47.9998H79.0002C80.381 47.9998 81.5002 46.8805 81.5002 45.4998V41.0243C81.5002 37.7594 79.0784 35.0012 75.841 34.5789L71.3062 33.9874C70.9912 33.9463 70.6974 33.8063 70.4671 33.5875L62.7829 26.2875C61.5749 25.1399 59.9723 24.5 58.306 24.5H38.7542C35.3835 24.5 32.1181 25.6743 29.5194 27.8211L22.4556 33.6564ZM71.5002 32.5L63.816 25.2C62.3292 23.7875 60.3568 23 58.306 23H38.7542C35.0348 23 31.4316 24.2958 28.5641 26.6646L21.5002 32.5L13.6781 33.5789C9.81977 34.1111 6.90541 37.3447 6.77565 41.2374L6.63801 45.3665C6.56267 47.6266 8.37442 49.4998 10.6358 49.4998H79.0002C81.2094 49.4998 83.0002 47.7089 83.0002 45.4998V41.0243C83.0002 37.006 80.0195 33.6112 76.035 33.0915L71.5002 32.5Z"
        fill="#323232"
      />
      <path
        d="M32.5 46.5C32.5 50.6421 29.1421 54 25 54C20.8579 54 17.5 50.6421 17.5 46.5C17.5 42.3579 20.8579 39 25 39C29.1421 39 32.5 42.3579 32.5 46.5Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 52.5C28.3137 52.5 31 49.8137 31 46.5C31 43.1863 28.3137 40.5 25 40.5C21.6863 40.5 19 43.1863 19 46.5C19 49.8137 21.6863 52.5 25 52.5ZM25 54C29.1421 54 32.5 50.6421 32.5 46.5C32.5 42.3579 29.1421 39 25 39C20.8579 39 17.5 42.3579 17.5 46.5C17.5 50.6421 20.8579 54 25 54Z"
        fill="#323232"
      />
      <path
        d="M73.5 46.5C73.5 50.6421 70.1421 54 66 54C61.8579 54 58.5 50.6421 58.5 46.5C58.5 42.3579 61.8579 39 66 39C70.1421 39 73.5 42.3579 73.5 46.5Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 52.5C69.3137 52.5 72 49.8137 72 46.5C72 43.1863 69.3137 40.5 66 40.5C62.6863 40.5 60 43.1863 60 46.5C60 49.8137 62.6863 52.5 66 52.5ZM66 54C70.1421 54 73.5 50.6421 73.5 46.5C73.5 42.3579 70.1421 39 66 39C61.8579 39 58.5 42.3579 58.5 46.5C58.5 50.6421 61.8579 54 66 54Z"
        fill="#323232"
      />
      <path
        d="M28.5 46.5C28.5 48.433 26.933 50 25 50C23.067 50 21.5 48.433 21.5 46.5C21.5 44.567 23.067 43 25 43C26.933 43 28.5 44.567 28.5 46.5Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 48.5C26.1046 48.5 27 47.6046 27 46.5C27 45.3954 26.1046 44.5 25 44.5C23.8954 44.5 23 45.3954 23 46.5C23 47.6046 23.8954 48.5 25 48.5ZM25 50C26.933 50 28.5 48.433 28.5 46.5C28.5 44.567 26.933 43 25 43C23.067 43 21.5 44.567 21.5 46.5C21.5 48.433 23.067 50 25 50Z"
        fill="#323232"
      />
      <path
        d="M69.5 46.5C69.5 48.433 67.933 50 66 50C64.067 50 62.5 48.433 62.5 46.5C62.5 44.567 64.067 43 66 43C67.933 43 69.5 44.567 69.5 46.5Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 48.5C67.1046 48.5 68 47.6046 68 46.5C68 45.3954 67.1046 44.5 66 44.5C64.8954 44.5 64 45.3954 64 46.5C64 47.6046 64.8954 48.5 66 48.5ZM66 50C67.933 50 69.5 48.433 69.5 46.5C69.5 44.567 67.933 43 66 43C64.067 43 62.5 44.567 62.5 46.5C62.5 48.433 64.067 50 66 50Z"
        fill="#323232"
      />
      <path
        d="M60.5922 28.1321C60.2141 27.7288 59.686 27.5 59.1331 27.5H48.4996C47.9473 27.5 47.4996 27.9477 47.4996 28.5V34.5C47.4996 35.0523 47.9473 35.5 48.4996 35.5H65.1914C66.0668 35.5 66.5197 34.4547 65.9209 33.8161L60.5922 28.1321Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9996 29H59.1331C59.2713 29 59.4034 29.0572 59.4979 29.158L64.0373 34H48.9996V29ZM59.1331 27.5C59.686 27.5 60.2141 27.7288 60.5922 28.1321L65.9209 33.8161C66.5197 34.4547 66.0668 35.5 65.1914 35.5H48.4996C47.9473 35.5 47.4996 35.0523 47.4996 34.5V28.5C47.4996 27.9477 47.9473 27.5 48.4996 27.5H59.1331Z"
        fill="#323232"
      />
      <path
        d="M34.5 44.75C34.5 44.3358 34.8358 44 35.25 44H47.75C48.1642 44 48.5 44.3358 48.5 44.75C48.5 45.1642 48.1642 45.5 47.75 45.5H35.25C34.8358 45.5 34.5 45.1642 34.5 44.75Z"
        fill="#323232"
      />
      <path
        d="M32.8524 29.1365C34.2457 28.0749 35.949 27.5 37.7007 27.5H44.5003C45.0526 27.5 45.5003 27.9477 45.5003 28.5V34.5C45.5003 35.0523 45.0526 35.5 44.5003 35.5H27.4629C26.5057 35.5 26.0955 34.2847 26.8568 33.7046L32.8524 29.1365Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0003 29H37.7007C36.2775 29 34.8935 29.4671 33.7614 30.3297L28.9442 34H44.0003V29ZM37.7007 27.5C35.949 27.5 34.2457 28.0749 32.8524 29.1365L26.8568 33.7046C26.0955 34.2847 26.5057 35.5 27.4629 35.5H44.5003C45.0526 35.5 45.5003 35.0523 45.5003 34.5V28.5C45.5003 27.9477 45.0526 27.5 44.5003 27.5H37.7007Z"
        fill="#323232"
      />
    </svg>
  );
};
