import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Contact, FormInput } from './Contact.tsx/Contact';
import { validationSchema } from './validationSchema';
import { store } from '@/config/store';
import { useContact } from '@/hooks/api/inquiry/useContact';
import { ContactApiParams } from '@/types/api/queryApi';
import { InquiryType } from '@/types/inquiry';
import { trigger } from '@/utils/eventHelper';
import { f7CustomBack } from '@/utils/utils';

interface ContactPageProps {
  previousPage: string;
}

export const ContactPage: FC<ContactPageProps> = ({ previousPage = '' }) => {
  const { contact } = useContact();
  const { m_affiliate_id, m_unit_id } = store.state.customerInfo;
  const formMethods = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const params: ContactApiParams = {
      m_customer_id: store.state.authInfo.m_customer_id,
      inquiry_type: Number(data.contact) as InquiryType,
      inquiry_detail: data.detail,
      m_affiliate_id,
      m_unit_id,
      t_stock_car_id: Number(data.t_stock_car_id) || undefined,
    };
    await contact(params);

    //re-call api to get lastest contact list data
    if (previousPage == 'news_consulting') {
      trigger('fetchInquiryListApi', {});
    }

    f7CustomBack(['#Contact']);
  };
  return <Contact onSubmit={onSubmit} formMethods={formMethods} />;
};
