import { f7 } from 'framework7-react';
import style from './TradeGuaranteeDocumentsStyle.module.css';
import IconCheckGreen from '@/assets/images/menu/icon-check-green.svg';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';
import { GetDealWarrantyInfoApiResponse } from '@/types/api/warrantyApi';

interface TradeGuaranteeDocumentsProps {
  data?: GetDealWarrantyInfoApiResponse['data'];
}

export const TradeGuaranteeDocuments = ({
  data,
}: TradeGuaranteeDocumentsProps) => {
  const handleRedirectDocumentsPage = () => {
    f7.view.main.router.navigate(paths.tradeGuaranteeDocumentsPreview, {
      props: {
        uploadedDocument: data?.documents || [],
      },
    });
  };

  return (
    <div className={`${style['block']}`}>
      <h4 className={style['header']}>各種書類</h4>
      {Object.entries(data?.documents || {})?.map(([key, document]) => (
        <div className={style['document-item']} key={key}>
          <img src={IconCheckGreen} alt="icon-check" width={24} height={24} />
          <span>{document.name}</span>
        </div>
      ))}
      <Button
        outline
        text="アップロードした書類を確認する"
        className={style['check-document']}
        onClick={handleRedirectDocumentsPage}
      />
    </div>
  );
};
