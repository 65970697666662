import { Popup } from 'framework7-react';
import { PopupProps } from 'framework7-react/components/popup';
import { FC } from 'react';
import style from './TradeConfirmApplicationDetailPage.module.css';
import CheckIcon from '@/assets/images/reservation-status/status11.svg';

export const PopupSuccess: FC<PopupProps> = ({ ...props }) => {
  return (
    <Popup className={style['popup-success-container']} {...props}>
      <div className={style['popup-success']}>
        <img src={CheckIcon} alt="Check" />
        <h4>取引保証を申請しました</h4>
        <p>
          テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキス。
        </p>
      </div>
    </Popup>
  );
};
