import { f7 } from 'framework7-react';
import { NavTitleProps } from 'framework7-react/components/nav-title';
import { FC } from 'react';

type BgColor = 'success' | 'warning' | 'danger' | 'muted';
type IconType =
  | 'bulb'
  | 'calendar-schedule'
  | 'confirm-notification'
  | 'file'
  | 'exclamationmark_circle'
  | 'check'
  | 'help'
  | '';

interface InfoCardProps extends NavTitleProps {
  text: string;
  textSub?: string;
  url?: string;
  iconType?: IconType;
  isIconRight?: boolean;
  bgColor: BgColor;
  isElevated?: Boolean;
  onClick?: () => void;
}

export const InfoCard: FC<InfoCardProps> = ({
  text,
  textSub = null,
  iconType = 'bulb',
  bgColor = 'success',
  url = 'null',
  isIconRight = false,
  isElevated = false,
  onClick,
  ...rest
}) => {
  return (
    <a
      className={`info-card info-card-${bgColor} ${rest.className} ${
        isElevated ? 'info-card-elevated' : ''
      }`}
      style={{
        cursor: url === 'null' ? 'default' : 'pointer',
      }}
      onClick={() => {
        if (onClick) onClick();
        f7.views.main.router.navigate(url);
      }}
    >
      <div className="info-card-wrapper">
        <i
          className={`icon left left-alert icon-${iconType}`}
          style={{ fontSize: '3rem', display: 'flex', alignItems: 'center' }}
        />

        <div className="text-box">
          <span className="text">{text}</span>
          {textSub && <span>{textSub}</span>}
        </div>

        {url && isIconRight && <i className="icon right icon-right" />}
      </div>
    </a>
  );
};
