import React, { FC } from 'react';
import style from './BannerTemplateStyle.module.css';

interface BannerTemplate {
  imagePath: string;
  responsiveImagePath: string;
  head: string;
  text: string;
  handleClickNavigation: () => void;
  isBrainTraining?: boolean;
  actionUrl?: string;
}

export const BannerTemplate: FC<BannerTemplate> = ({
  imagePath,
  responsiveImagePath,
  head,
  text,
  handleClickNavigation,
  isBrainTraining = true,
  actionUrl = '',
}) => {
  return (
    <div onClick={handleClickNavigation} className={style.banner}>
      <div className={style['image-wrap']}>
        <img
          src={imagePath}
          srcSet={`${imagePath} 1x, ${responsiveImagePath} 2x`}
          alt=""
        />
      </div>
      <div className={style['text-wrap']}>
        <p className={style.head}>{head}</p>
        <p>{text}</p>
      </div>
      {!isBrainTraining && (
        <form
          action={actionUrl}
          method="POST"
          target="_blank"
          name="telema_form"
        >
          <input value="t" type="hidden" name="XmlTelemaData" />
        </form>
      )}
    </div>
  );
};
