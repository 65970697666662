import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { MonthlyDriveReport } from './MonthlyDriveReport/MonthlyDriveReport';
import { fetchMonthlyCountApi } from '@/api/driveRecordApi';
import { store } from '@/config/store';
import { useDriveScore } from '@/hooks/api/driveRecord/useDriveScore';
import { useMonthlyScore } from '@/hooks/api/driveRecord/useMonthlyScore';
import { getAddMonth } from '@/utils/date';

interface MonthlyDriveReportPageProps {
  f7router: Router.Router;
}

export const MonthlyDriveReportPage: FC<MonthlyDriveReportPageProps> = ({
  f7router,
}) => {
  const specifiedYm = store.state.monthlyReportDate;
  const vehicleId = store.state.linkDriveOwnerInfo.vehicle_id;
  const ownerId = store.state.linkDriveOwnerInfo.owner_id;
  const mCustomerId = store.state.authInfo.m_customer_id;
  const getScoreParams = {
    owner_id: Number(ownerId),
    vehicle_id: vehicleId,
    m_customer_id: mCustomerId,
  };

  const [monthlyDriveRecordParams, setMonthlyDriveRecordParams] = useState({
    owner_id: Number(ownerId),
    vehicle_id: vehicleId,
    m_customer_id: mCustomerId,
    analysis_from_date: specifiedYm,
  });
  const [prevMonthCount, setPrevMonthCount] = useState<number>(0);
  const [nextMonthCount, setNextMonthCount] = useState<number>(0);

  const { data: scoreData } = useDriveScore(getScoreParams);
  const { data, isLoading } = useMonthlyScore(monthlyDriveRecordParams);

  useEffect(() => {
    data?.diagnosis_report_id &&
      countMonthlyRead(Number(data?.diagnosis_report_id));
  }, [data]);

  useEffect(() => {
    //月間安全レポートの総数から-1したものを格納
    setPrevMonthCount((scoreData?.data?.phyd_score_all?.length ?? 0) - 1);
  }, [scoreData]);

  const clickPrevMonth = () => {
    if (prevMonthCount > 0) {
      setNextMonthCount(nextMonthCount + 1);
      setPrevMonthCount(prevMonthCount - 1);
      const newAnalysisFromDate = getAddMonth(
        monthlyDriveRecordParams.analysis_from_date,
        -1,
      );
      setMonthlyDriveRecordParams({
        ...monthlyDriveRecordParams,
        analysis_from_date: newAnalysisFromDate,
      });
    }
  };

  const clickNextMonth = () => {
    if (nextMonthCount > 0) {
      setNextMonthCount(nextMonthCount - 1);
      setPrevMonthCount(prevMonthCount + 1);
      const newAnalysisFromDate = getAddMonth(
        monthlyDriveRecordParams.analysis_from_date,
        1,
      );
      setMonthlyDriveRecordParams({
        ...monthlyDriveRecordParams,
        analysis_from_date: newAnalysisFromDate,
      });
    }
  };

  const countMonthlyRead = (diagnosisReportId: number) => {
    const { m_customer_id } = store.state.authInfo;
    const { t_stock_car_id } = store.state.carItemInfo;
    let params = {
      m_customer_id,
      t_stock_car_id,
      t_linkdrive_driving_diagnosis_report_id: diagnosisReportId,
    };
    fetchMonthlyCountApi(params);
  };

  return (
    <MonthlyDriveReport
      f7router={f7router}
      data={data}
      isPrevMonthBtnDisabled={prevMonthCount <= 0}
      isNextMonthBtnDisabled={nextMonthCount <= 0}
      isLoading={isLoading}
      clickNextMonth={clickNextMonth}
      clickPrevMonth={clickPrevMonth}
    />
  );
};
