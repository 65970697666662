import { FC, ReactNode } from 'react';
import styles from './AccordionItem.module.css';

interface AccordionItemProps {
  title: string;
  children: ReactNode;
}

const AccordionItem: FC<AccordionItemProps> = ({ title, children }) => (
  <li className="accordion-item">
    <a className="item-content item-link" href="#">
      <div className="item-inner">
        <div className="item-title">{title}</div>
      </div>
    </a>
    <div className="accordion-item-content">
      <div className={styles['block']}>{children}</div>
    </div>
  </li>
);

export default AccordionItem;
