import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useMemo, useState } from 'react';
import { getImpactHistories } from '@/api/linkDriveApi';
import {
  ImpactHistoriesApiParams,
  ImpactHistoriesApiResponse,
} from '@/types/api/linkDriveApi';

// react custom hook receives HistoryParams as parameter
// and returns FetchDriveHistoryResult
export const useImpactHistory = (params: ImpactHistoriesApiParams) => {
  const [responseData, setResponseData] =
    useState<ImpactHistoriesApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(true);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await getImpactHistories(params);

        if (res.data.success) {
          setResponseData(res.data);
          setIsNoData(false);
        }
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [params]);

  return useMemo(
    () => ({
      data: responseData,
      isLoading,
      isNoData,
      error,
    }),
    [responseData, isLoading, isNoData, error],
  );
};
