import * as yup from 'yup';

export const TEL1_MAX_LENGTH = 3;
export const TEL2_MAX_LENGTH = 4;
export const TEL3_MAX_LENGTH = 4;

export const loginTelephoneSchema = yup.object().shape({
  tel1: yup.string().required().max(TEL1_MAX_LENGTH),
  tel2: yup.string().required().max(TEL2_MAX_LENGTH),
  tel3: yup.string().required().max(TEL3_MAX_LENGTH),
});
