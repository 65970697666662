import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { PushSettings } from './PushSettings/PushSettings';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { url } from '@/consts/url';

interface PushSettingsPageProps {
  f7router: Router.Router;
}
export const PushSettingsPage: FC<PushSettingsPageProps> = ({ f7router }) => {
  const handleClickButton = () => {
    window.open(url.NATIVE_BASE_URL + '/on_go_app_setting');
    f7.view.main.router.navigate(paths.account, {
      props: { fromPage: 'pushSettings' },
    });
  };
  const { pushPermission } = store.state.permission;
  return (
    <PushSettings
      handleClickButton={handleClickButton}
      pushPermission={pushPermission}
    />
  );
};
