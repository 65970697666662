import { FC } from 'react';
import style from './TextImageStyle.module.css';

interface DataType {
  imgUrl: string;
  title: string;
  description: string;
}

interface Props {
  data: DataType;
}

export const TextImage: FC<Props> = ({ data }) => {
  return (
    <div className="u-padding-bottom-xs">
      <div
        style={{ display: 'flex', borderBottom: '2px solid #dee0e2' }}
        className="u-padding-bottom-xs"
      >
        <div className={style['image-box']}>
          <img src={data.imgUrl} alt="" />
        </div>
        <div style={{ width: '78%' }} className="u-padding-top-xs">
          <h3 className={style.heading}>{data.title}</h3>
          <div
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              overflow: 'hidden',
            }}
          >
            {data.description}
          </div>
        </div>
      </div>
    </div>
  );
};
