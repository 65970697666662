import { FC } from 'react';
import style from './PrepaidServiceStyle.module.css';

export const PrepaidService: FC = () => {
  return (
    <div className={style['container']}>
      <h4>第1条 （適用範囲等）</h4>
      <p>
        <span>
          1.
          本規約は、当社が提供するプリペイドサービス（第2条第1号に定めます。）およびプリペイドサービスアカウント（第2条第2号に定めます。）の利用に関する取扱いについて定めるものです。利用者（第2条第3号に定めます。）は、本規約に同意した上でプリペイドサービスおよびプリペイドサービスアカウントを利用するものとします。
        </span>
        <span>
          2.
          利用者が未成年である場合、利用者は、法定代理人の同意を得た上で、プリペイドサービスおよびプリペイドサービスアカウントを利用するものとします。
        </span>
      </p>
    </div>
  );
};
