import React, { FC } from 'react';
import style from './AlertResultStyle.module.css';
import { AlertItem } from '@/components/projects/AlertItem/AlertItem';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';

interface AlertResultProps {
  alertResult: {
    dayList: string[];
    speedExceededTimes: number;
    averageSpeedExceededTimes: number;
    acceleratedTimesPerDay: number[];
    acceleratedTimes: number;
    averageAcceleratedTimes: number;
    deceleratedTimesPerDay: number[];
    deceleratedTimes: number;
    averageDeceleratedTimes: number;
  };
}

export const AlertResult: FC<AlertResultProps> = ({ alertResult }) => {
  return (
    <div className={style.card}>
      <h3 className={style.heading}>アラート実績</h3>
      <div className={style['alert-wrap']}>
        <AlertItem
          title="速度超過"
          total={alertResult.speedExceededTimes}
          average={alertResult.averageSpeedExceededTimes}
        />
        <AlertItem
          title="急アクセル"
          dayList={alertResult.dayList}
          serialDataList={alertResult.acceleratedTimesPerDay}
          total={alertResult.acceleratedTimes}
          average={alertResult.averageAcceleratedTimes}
          isGraph
        />
        <AlertItem
          title="急ブレーキ"
          dayList={alertResult.dayList}
          serialDataList={alertResult.deceleratedTimesPerDay}
          total={alertResult.deceleratedTimes}
          average={alertResult.averageDeceleratedTimes}
          isGraph
        />
      </div>
      <IconCard
        heading={'アラートの判定基準'}
        iconType="help"
        isTransition
        path=""
        color="gray"
      />
    </div>
  );
};
