import { f7 } from 'framework7-react';
import { useState } from 'react';
import { useCarChange } from './useCarChange';
import { putGuaranteeUpdateTargetApi } from '@/api/warrantyApi';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import { PutGuaranteeUpdateTargetParams } from '@/types/api/warrantyApi';

export const usePutGuaranteeUpdateTarget = () => {
  const [error, setError] = useState<Error>();
  const { openToast } = useToast();
  const { changeCarStore } = useCarChange();

  const putGuaranteeUpdateTarget = async (
    params: PutGuaranteeUpdateTargetParams,
  ) => {
    try {
      f7.preloader.show();
      const res = await putGuaranteeUpdateTargetApi(params);
      if (!res.data.success) {
        throw new Error(res.data.error?.code + ':' + res.data.error?.message);
      }
      await changeCarStore({
        m_customer_id: store.state.authInfo.m_customer_id,
      });
      openToast('プランの変更が完了しました');
    } catch (err) {
      if (err instanceof Error) {
        f7.dialog.alert(err.message, '  エラー', () => {
          f7.dialog.close();
        });
        setError(err);
      }
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    putGuaranteeUpdateTarget,
    error,
  };
};
