import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './TradeWarrantyDetailStyle.module.css';
import { ReactComponent as IconFileColorInside } from '@/assets/images/menu/icon-file-color-inside.svg';
import { ReactComponent as IconHelp } from '@/assets/images/menu/icon-help.svg';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { paths } from '@/config/paths';
type WarrantyNotesProps = {
  f7router: Router.Router;
};

export const WarrantyNotes: FC<WarrantyNotesProps> = ({ f7router }) => {
  return (
    <div className={`${style['block']} ${style['last-block']}`}>
      <div className={style['block-header']}>
        <div className={style['title']}>保証に関するヘルプ・ご注意事項</div>
      </div>
      <CustomList
        icon={<IconFileColorInside />}
        fontSize="14px"
        path={paths.dealWarrantyTerms}
        text="保証規約"
        f7router={f7router}
        isTopBorder={false}
      />
      <CustomList
        icon={<IconHelp />}
        fontSize="14px"
        path={paths.warrantyFaq}
        text="よくあるご質問"
        f7router={f7router}
      />
    </div>
  );
};
