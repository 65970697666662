import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchDriveDataApi, fetchDriveHistoryApi } from '@/api/driveRecordApi';
import { DataResponse, HistoryResponse } from '@/types/api/driveRecordApi';

export const useDriveRecordData = (
  ownerId: number,
  mCustomerId: number,
  diagnosisDate?: string,
) => {
  const [data, setData] = useState<DataResponse['data']>();
  const [historyData, setHistoryData] =
    useState<HistoryResponse['data']['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchDriveDataApi({ owner_id: ownerId });
        const historyRes = await fetchDriveHistoryApi({
          owner_id: ownerId,
          m_customer_id: mCustomerId,
          diagnosis_date: diagnosisDate,
        });
        if (!res.data.success || !historyRes.data.success) {
          f7.dialog.alert(res.data.error?.message ?? '', 'エラー', () => {});
        }
        setData(res.data);
        setHistoryData(historyRes.data.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [ownerId, mCustomerId, diagnosisDate]);

  return {
    data,
    historyData,
    isLoading,
    error,
  };
};
