import { f7 } from 'framework7-react';
import { getAffiliateIdFromThemeId } from './shop';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { affiliateId } from '@/consts/shop';

/**
 * @param token logged in user token
 * @param id logged in user id
 * @param type login type
 */
interface AutoLoginParams {
  token: string;
  id: string;
  type?: string;
}

export const autoLoginNativeHelper = ({ token, id, type }: AutoLoginParams) => {
  if (token) {
    const affiliateIdFromThemeId = getAffiliateIdFromThemeId(
      store.state.themeId,
    );
    if (affiliateIdFromThemeId === affiliateId.DEFAULT) {
      store.dispatch('setIsLoggedInToMaikuru', {
        isLoggedInToMaikuru: true,
      });
    }
    store.dispatch('setAuthInfo', {
      authInfo: {
        m_customer_id: Number(id),
        token: decodeURI(token),
        isLogin: true,
      },
    });
    store.dispatch('setLoginInfo', {
      loginInfo: { loginType: type },
    });
    f7.views.main.router.navigate(paths.home);
  } else {
    f7.views.main.router.navigate(paths.loginTutorial);
  }
};
