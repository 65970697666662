import { useStore } from 'framework7-react';
import { FC } from 'react';
import { formModes } from '../PromptConfirmDetail';
import style from './PromptConfirmStyle.module.css';
import prepaidCheckImg from '@/assets/images/prepaid-check.png';
import carCheckImg from '@/assets/images/vehicle_check.png';
import { Button } from '@/components/uiParts/Button';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { store } from '@/config/store';
import { Prepaid } from '@/types/api/customerApi';

interface Props {
  formMode: number;
  opened: boolean;
  onPopupClose: () => void;
}

export const ConfirmSuccess: FC<Props> = ({
  formMode,
  opened,
  onPopupClose,
}) => {
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Prepaid
    | undefined;
  return (
    <PopupTemplate
      title=""
      opened={opened}
      isCloseIcon={formMode === formModes.DEFAULT}
      className={style['popup-success']}
      onPopupClosed={onPopupClose}
      handleClose={onPopupClose}
    >
      {formMode === formModes.DEFAULT ? (
        <div className={style['popup-success-default']}>
          <i className="icon-success" />
          <h2>チェックが完了しました。</h2>
          <p>
            ご確認ありがとうございます。 <br />
            おつかれさまでした！
          </p>
        </div>
      ) : (
        <div>
          {formMode === formModes.BOTH && (
            <div className={style['popup-success-head']}>
              <img style={{ width: '52%' }} src={carCheckImg} alt="" />
              <h2>確認が完了しました</h2>
              <p>
                {
                  '修理保証は、納車日より\n開始いたします。\n安心・快適なカーライフに\n各サービスをご活用ください。'
                }
              </p>
            </div>
          )}
          {formMode === formModes.PREPAID && (
            <div className={style['popup-success-head']}>
              <img style={{ width: '52%' }} src={prepaidCheckImg} alt="" />
              <h2>{`${prepaid?.name || ''}の利用が\n開始されました`}</h2>
              <p>{'おトクに積み立て、ご活用ください。'}</p>
            </div>
          )}
          {formMode === formModes.WARRANTY && (
            <div className={style['popup-success-head']}>
              <img style={{ width: '52%' }} src={carCheckImg} alt="" />
              <h2>納車おめでとうございます</h2>
              <p>
                {'これで納車が完了しました。\n納車日から保証が開始されます。'}
              </p>
            </div>
          )}
          <Button onClick={onPopupClose}>閉じる</Button>
        </div>
      )}
    </PopupTemplate>
  );
};
