import { DetailedHTMLProps, FC, InputHTMLAttributes, useRef } from 'react';
import { CheckIcon } from '../Check/Check';
import { RhfInput } from '../ReactHookForm/RhfInput/RhfInput';
import style from './TextInputStyle.module.css';

type InputType = 'text' | 'email' | 'password';

interface TextInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  inputName: string;
  required?: boolean;
  placeholder?: string;
  isCheckIcon?: boolean;
  isCheckIconValid?: boolean;
  inputType?: InputType;
  supplement?: string;
  error?: string;
}

export const TextInput: FC<TextInputProps> = ({
  label,
  inputName,
  required = true,
  placeholder = '',
  isCheckIcon = false,
  isCheckIconValid = false,
  inputType = 'text',
  supplement = '',
  error = '',
  ...rest
}) => {
  return (
    <div>
      <div className={style['label-wrap']}>
        <p className={style.label}>{label}</p>
        {required && (
          <span className={`${style['label-tips']} ${style.required}`}>
            必須
          </span>
        )}
        {!required && (
          <span className={`${style['label-tips']} ${style.optional}`}>
            任意
          </span>
        )}
      </div>
      <div className={style['input-wrap']}>
        <RhfInput
          {...rest}
          className={`${style.input} ${error && style['error-input']}`}
          name={inputName}
          type={inputType}
          placeholder={placeholder}
          ref={useRef<HTMLInputElement>(null)}
        />
        {isCheckIcon && (
          <div>
            <CheckIcon isValid={isCheckIconValid} />
          </div>
        )}
      </div>
      {supplement && <span className={style.supplement}>{supplement}</span>}
      {error && <span className={style.error}>{error}</span>}
    </div>
  );
};
