import { Router } from 'framework7/types';
import React, { useEffect } from 'react';
import { LoginPageTemplate } from '@/components/uiParts/Template/LoginPageTemplate/LoginPageTemplate';
import { paths } from '@/config/paths';
import { useAdminAuth } from '@/hooks/api/auth/useAdminAuth';
import { useToast } from '@/hooks/useToast';
import { AdminAuthApiParams } from '@/types/api/authApi';

interface AdminAuthProps {
  f7route: Router.Route;
}
const FAIL_REDIRECT_PATH = paths.loginTutorial;

export const AdminAuth: React.FC<AdminAuthProps> = ({ f7route }) => {
  const { openToast } = useToast();
  const { adminAuth } = useAdminAuth();

  useEffect(() => {
    const params: AdminAuthApiParams = {
      onetime_password: f7route.query.onetime_password || '',
      token_abilities: [],
    };
    adminAuth(params);
  }, []);

  return (
    <LoginPageTemplate pageName="adminAuth">
      <></>
    </LoginPageTemplate>
  );
};
