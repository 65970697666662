import { DetailedHTMLProps, FC, TextareaHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

interface RhfTextareaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string;
}
export const RhfTextarea: FC<RhfTextareaProps> = ({ name, ...rest }) => {
  const { register } = useFormContext();
  return <textarea {...register(name)} {...rest} />;
};
