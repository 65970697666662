import { yupResolver } from '@hookform/resolvers/yup';
import { f7 } from 'framework7-react';
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import style from './WatchingServiceSettingsStyle.module.css';
import { watchingFormSchema } from './validationSchema';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { TextInput } from '@/components/uiParts/Input/TextInput';
import { RhfParallelInput } from '@/components/uiParts/ParallelInput/RhfParallelInput/RhfParallelInput';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { useDeleteWatcher } from '@/hooks/api/linkDrive/useDeleteWatcher';
import { useRegisterWatcher } from '@/hooks/api/linkDrive/useRegisterWatcher';
import { useUpdateWatcher } from '@/hooks/api/linkDrive/useUpdateWatcher';
import { f7CustomBack } from '@/utils/utils';

interface FormInput {
  familyName: string;
  firstName: string;
  familyNameKana: string;
  firstNameKana: string;
  mailAddress: string;
}

interface Watcher {
  short_user_id: number;
  vehicle_id: string;
  watch_user_id: number;
  mailaddress: string;
  first_name: string;
  family_name: string;
  first_name_kana: string;
  family_name_kana: string;
}

interface WatchingServiceSettingsProps {
  watcher: Watcher | undefined;
  initialFetchWatchers: () => void;
}

export const WatchingServiceSettings: FC<WatchingServiceSettingsProps> = ({
  watcher,
  initialFetchWatchers,
}) => {
  const { deleteWatcher } = useDeleteWatcher();
  const { registerWatcher, isLoading } = useRegisterWatcher();
  const { updateWatcher } = useUpdateWatcher();
  const formMethods = useForm<FormInput>({
    defaultValues: {
      familyName: watcher?.family_name,
      firstName: watcher?.first_name,
      familyNameKana: watcher?.family_name_kana,
      firstNameKana: watcher?.first_name_kana,
      mailAddress: watcher?.mailaddress,
    },
    resolver: yupResolver(watchingFormSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  // 各フィールドのエラー判定
  const isNameFieldError = !!errors.firstName || !!errors.familyName;
  const isKanaFieldError = !!errors.firstNameKana || !!errors.familyNameKana;
  const isMailAddress = !!errors.mailAddress;

  const mCustomerId = store.state.authInfo.m_customer_id;
  const vehicleId = store.state.linkDriveOwnerInfo.vehicle_id;

  // propsのwatcherがundefinedの場合は新規追加
  const onSubmit: SubmitHandler<FormInput> = ({
    familyName,
    firstName,
    familyNameKana,
    firstNameKana,
    mailAddress,
  }) => {
    const params = {
      short_user_id: mCustomerId,
      vehicle_id: vehicleId,
      mailaddress: mailAddress,
      first_name: firstName,
      family_name: familyName,
      first_name_kana: firstNameKana,
      family_name_kana: familyNameKana,
    };
    const updateParams = {
      ...params,
      watch_user_id: watcher?.watch_user_id ?? 0,
    };
    const onSuccess = () => initialFetchWatchers();
    if (!watcher) registerWatcher({ params, onSuccess });
    if (watcher) updateWatcher({ params: updateParams, onSuccess });
  };

  const handleClickDeleteWatcher = () => {
    const params = {
      short_user_id: mCustomerId,
      vehicle_id: vehicleId,
      watch_user_id: watcher?.watch_user_id ?? 0,
    };
    deleteWatcher({
      params,
      onSuccess: () => {
        initialFetchWatchers();
        f7CustomBack(['#WatchingServiceSettings']);
      },
    });
  };

  useEffect(() => {
    if (isLoading) f7.preloader.show();
    if (!isLoading) f7.preloader.hide();
  }, [isLoading]);
  return (
    <PopupPageTemplate
      id="WatchingServiceSettings"
      pageName="WatchingServiceSettings"
      title="見守りサービス設定"
    >
      <div className={style.description}>
        <p>
          事故であると思われる強い衝撃を受けた際に、大切な家族や友人に知らせることができます。
        </p>
        <p>※ご家族やご友人の連絡先の入力が必要です。</p>
      </div>
      <div className="list no-hairlines">
        <FormProvider {...formMethods}>
          <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
            <RhfParallelInput
              firstName="firstName"
              secondName="familyName"
              firstPlaceHolder="名"
              secondPlaceHolder="姓"
              label="お名前"
              isCheckIcon
              isCheckIconValid={!isNameFieldError}
              firstError={errors.firstName?.message}
              secondError={errors.familyName?.message}
            />
            <RhfParallelInput
              firstName="firstNameKana"
              secondName="familyNameKana"
              firstPlaceHolder="メイ"
              secondPlaceHolder="セイ"
              label="フリガナ"
              isCheckIcon
              isCheckIconValid={!isKanaFieldError}
              firstError={errors.firstNameKana?.message}
              secondError={errors.familyNameKana?.message}
            />
            <TextInput
              label={'メールアドレス'}
              inputName={'mailAddress'}
              isCheckIcon
              isCheckIconValid={!isMailAddress}
              error={errors.mailAddress?.message}
              placeholder="mailaddress@example.com"
            />
            {!watcher && (
              <div className={style['button-wrap']}>
                <Button text="追加する" type="submit" />
              </div>
            )}
            {watcher && (
              <div className={style['buttons-wrap']}>
                <Button
                  text="削除する"
                  colorTheme="pink"
                  className={style.button}
                  onClick={handleClickDeleteWatcher}
                />
                <Button
                  className={style.button}
                  text="変更する"
                  type="submit"
                />
              </div>
            )}
          </form>
        </FormProvider>
      </div>
    </PopupPageTemplate>
  );
};
