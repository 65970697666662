import { List, ListInput, f7 } from 'framework7-react';
import { ListInputProps } from 'framework7-react/components/list-input';
import { FC, ReactNode, useEffect, useState } from 'react';
import style from './Picker.module.css';

interface PickerProps extends ListInputProps {
  id: string;
  label: string;
  options: {
    label: string;
    value: number;
  }[];
  initValue?: number;
  required?: boolean;
  children?: ReactNode;
  onChange?: (data: { label: string; value: number }) => void;
}

export const Picker: FC<PickerProps> = ({
  id,
  label,
  options,
  children,
  initValue,
  required = false,
  onChange,
  ...listInputProps
}) => {
  const [init, setInit] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (init) return;
    const value = options.find((o) => o.value === initValue)?.label || '';
    setValue(value);
    setInit(true);
    const picker = f7.picker.create({
      backdrop: true,
      inputEl: `#picker-${id}`,
      rotateEffect: true,
      toolbarCloseText: '完了',
      cssClass: style['picker'],
      cols: [
        {
          textAlign: 'center',
          values: options.map((option) => option.label),
          onChange(picker, value) {
            setValue(value);
            const item = options.find((o) => o.label === value);
            if (item && onChange) {
              onChange?.(item);
            }
          },
        },
      ],
    });
    return () => {
      f7.picker.destroy(picker);
    };
  }, []);

  return (
    <div className={style['input-text']}>
      <label>
        {label}
        {required && (
          <span className={`${style['input-label-tips']} ${style['required']}`}>
            必須
          </span>
        )}
      </label>
      <List className={style['input-picker']}>
        <ListInput
          value={value}
          {...listInputProps}
          type="text"
          readonly
          inputId={`picker-${id}`}
        />
      </List>
      {children}
    </div>
  );
};
