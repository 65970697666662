import { differenceInMonths } from 'date-fns';
import { delFlg, driveExisted, linkDriveStatus } from '@/consts/linkDrive';

type IsShowDriveRecordParams = {
  deviceDelDateStr?: string;
  serviceEndDateStr?: string;
  delFlg?: number;
  driveLogSendFlg?: number;
};

// Home画面メニューの運転記録ボタンを非表示にする条件
// ① del_flg：1 かつ device_del_date <= 現在日付の18カ月前 または
// ② driving_send_flg かつ service_end_date <= 現在日付の18カ月前
export const isShowDriveRecordButton = (
  params: IsShowDriveRecordParams,
): boolean => {
  const currentDate = new Date();
  const deviceDelDate =
    params.deviceDelDateStr && new Date(params.deviceDelDateStr);
  const serviceEndDate =
    params.serviceEndDateStr && new Date(params.serviceEndDateStr);
  const diffDeviceDelDate = deviceDelDate
    ? differenceInMonths(currentDate, deviceDelDate)
    : 0;
  const diffServiceEndDate = serviceEndDate
    ? differenceInMonths(currentDate, serviceEndDate)
    : 0;
  return !(
    (params?.delFlg === 1 && diffDeviceDelDate >= 18) ||
    (params?.driveLogSendFlg === 0 && diffServiceEndDate >= 18)
  );
};

export const carNameTitle = (
  isBasicInfoLoaded: boolean,
  isDelivered: boolean,
  carName: string,
) => {
  let title = carName;
  if (!isBasicInfoLoaded) {
    title = '';
  }
  return title;
};

type IsAdContractorParams = {
  linkDriveStatus?: number;
};

export const isAdContractorData = (params: IsAdContractorParams): boolean => {
  return params.linkDriveStatus !== linkDriveStatus.NOT_ADDED;
};

export const isAdTempContractorData = (
  params: IsAdContractorParams,
): boolean => {
  return params.linkDriveStatus == linkDriveStatus.TEMPORARY_REGISTRATION;
};

type IsLinkDriveOwnerParams = {
  id?: number;
  delFlg?: number;
};

export const isLinkDriveOwnerData = (
  params: IsLinkDriveOwnerParams,
): boolean => {
  return (
    params.id !== driveExisted.NOT_EXISTED &&
    params.delFlg === delFlg.NOT_DELETE
  );
};
