import React, { FC } from 'react';
import { Label } from '../Label/Label';

interface TextInputProps {
  label: string;
  required?: boolean;
  placeholder: string;
  annotation?: string;
  error?: string;
  type?: string;
  val?: string;
  index?: number;
  changeText?: (e: any) => void;
  changeTextArea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string | number | undefined;
}

export const TextInput: FC<TextInputProps> = ({
  label = null,
  required = false,
  annotation = null,
  error = null,
  placeholder = '',
  type = 'text',
  changeText,
  changeTextArea,
  value = '',
}) => {
  return (
    <li>
      <div
        className={`item-content item-input item-input-outline 
          ${error && 'item-input-invalid item-input-with-error-message'}
          ${annotation && 'item-input-with-info'}
          `}
      >
        <div className="item-inner">
          <div className="item-title item-label">
            {label}
            <Label required={required} />
          </div>
          <div className="item-input-wrap">
            {type !== 'textarea' ? (
              <input
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={changeText}
              />
            ) : (
              <textarea
                placeholder={placeholder}
                onChange={changeTextArea}
                rows={10}
                value={value}
              />
            )}

            {error && <div className="item-input-error-message">{error}</div>}

            {annotation && <div className="item-input-info">{annotation}</div>}
          </div>
        </div>
      </div>
    </li>
  );
};
