import { addHours, format, isValid, parse, set } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Picker } from '../Picker/Picker';
import { SelectCalendar } from '../SelectCalendar/SelectCalendar';
import style from './DateTimeSelect.module.css';

const timeOptions = [
  {
    label: '9:00～11:00',
    value: 0,
  },
  {
    label: '12:00～14:00',
    value: 1,
  },
  {
    label: '15:00～17:00',
    value: 2,
  },
];

interface Props {
  name: string;
  label: string;
  showError?: boolean;
  required?: boolean;
}

export const DateTimeSelect: FC<Props> = ({
  name,
  label,
  showError = false,
  required = false,
}) => {
  let minDate = new Date();
  minDate.setDate(minDate.getDate() + 7);
  let maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 3);

  const { watch, setValue, trigger, getFieldState } = useFormContext();
  const { error } = getFieldState(name);
  const [date, setDate] = useState(minDate);
  const [time, setTime] = useState<string>();
  const formValue = watch(name);
  const id = name.split('.').slice(-1)[0];

  useEffect(() => {
    if (formValue) {
      const dateValue = parse(formValue, 'yyyy-MM-dd HH:mm:ss', minDate);
      if (isValid(dateValue)) {
        setDate(dateValue);
        const startTime = format(dateValue, 'H:mm');
        const endTime = format(addHours(dateValue, 2), 'H:mm');
        setTime(`${startTime}～${endTime}`);
      }
    }
  }, []);

  useEffect(() => {
    if (date && time) {
      const start = time.split('～')[0].split(':');
      const hours = Number(start[0]);
      const datetime = set(date, {
        hours,
        minutes: 0,
        seconds: 0,
      });
      const value = format(datetime, 'yyyy-MM-dd HH:mm:ss');
      setValue(name, value);
      trigger('preferredDatetimes.datetime1');
      trigger('preferredDatetimes.datetime2');
      trigger('preferredDatetimes.datetime3');
    }
  }, [date, time]);

  return (
    <div
      className={`${style['input-group']}  ${
        !!error && formValue ? style['input-text-error'] : ''
      }`}
    >
      <h2 className={style['input-label']}>
        {label}
        <span
          className={`${style['input-label-tips']} ${
            required && style['required']
          }`}
        >
          {required ? '必須' : '任意'}
        </span>
      </h2>
      <div className={style['input-text']}>
        <label>日付</label>
        <SelectCalendar
          name="date-input"
          value={date}
          minDate={minDate}
          maxDate={maxDate}
          onChange={setDate}
        />
      </div>
      <div className={style['input-text']}>
        <Picker
          label="開始時間"
          id={id}
          value={time}
          options={timeOptions}
          onChange={(data) => {
            setTime(data.label);
          }}
        />
      </div>
      {showError && formValue && (
        <div className={style['error-text']}>選択した時間が重複しています</div>
      )}
    </div>
  );
};
