import { Block, Col, Navbar, Page, Row } from 'framework7-react';
import React, { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import { CommonpageTitle } from '@/components/uiParts/CommonpageTitle/CommonpageTitle';

export const ScoreSendDataApprovalPage: FC = () => {
  return (
    <Page name="ScoreSendDataApproval">
      <Navbar>
        <CommonpageTitle navTitle="データ送信同意" leftIcon="left" />
      </Navbar>
      <Block>
        <div>
          <h2>service サービス</h2>
          <p>00サービス</p>
          <p>サービス概要・割引概要などを説明</p>
        </div>

        <div className="u-margin-top-lg">
          <h3>
            00サービスをご利用になるためには、以下の同意が必要となります。
          </h3>
          <h3>ご確認いただき、同意の上次へ進んでください。</h3>
        </div>
        {/* 
            <TextRead 
                data="data1"
                title="`${service}ービス関する確認事項`"
            /> */}

        <Row className="u-margin-top-xl">
          <Col width="10" />
          <Col width="80">
            <Button text="同意して利用開始する" handleClickAction={() => {}} />
          </Col>
          <Col width="10" />
        </Row>
        <Row className="u-margin-top-xl">
          <Col width="10" />
          <Col width="80">
            <Button text="同意しない" handleClickAction={() => {}} />
          </Col>
          <Col width="10" />
        </Row>
        <p className="u-font-size-lg u-line-height-20">
          ※「同意しない」を選択した場合、00サービスはご利用できませんので、ご注意ください。
        </p>
      </Block>
    </Page>
  );
};
