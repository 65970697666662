import { AccordionContent, List, ListItem } from 'framework7-react';
import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Checkbox,
  Radio,
  SelectCalendar,
  TextInput,
  Picker,
} from 'src/components/pages/CarRepair/Partials';
import styles from './CarRepairCondition.module.scss';
import { Button } from '@/components/uiParts/Button';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { paths } from '@/config/paths';
import {
  CAN_DRIVE_TYPES,
  CanDriveType,
  DEFECT_CATEGORIES,
} from '@/consts/carRepair';
import { Defect, DefectOccurrenceFrequency } from '@/types/api/carRepairApi';

interface CarRepairConditionProps {
  onSubmit: () => void;
  defects: Defect[];
  defectOccurrenceFrequencies: DefectOccurrenceFrequency[];
}

export const CarRepairCondition: FC<CarRepairConditionProps> = ({
  defects,
  defectOccurrenceFrequencies,
  onSubmit,
}) => {
  const maxDate = new Date();
  const { formState, control, setValue } = useFormContext();
  const { errors } = formState;
  const canDrive = useWatch({ control, name: 'canDrive' });
  const openedDefectCategory = useWatch({
    control,
    name: 'openedDefectCategory',
  });
  const confirmWarrantyPlan = useWatch({
    control,
    name: 'confirm_warranty_plan',
  });
  const isGrayOut = canDrive === CanDriveType.CANNOT || !confirmWarrantyPlan;
  useEffect(() => {
    document.querySelector('.page-content > div > div')?.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles['container']}>
      <p className={styles['repair-comment']}>
        お車の状態を可能な限り詳細にご入力ください。
        <br />
        ご入力いただいた内容をもとに、2営業日以内にお電話にてサポートセンターの担当者が詳細な内容をお伺いします。必要に応じて、点検入庫のご案内をさせていただきます。
      </p>
      <div
        className={`${styles['warranty-plan']} ${styles['repair-request-block']}`}
      >
        <h3 className={styles['input-group-label']}>
          保証の適用について
          <span
            className={`${styles['input-group-label-tips']} ${styles['required']}`}
          >
            必須
          </span>
        </h3>
        <p>
          ご契約中の保証を適用して修理を行います。保証プランをご確認ください。
        </p>
        <IconCard
          iconType="file-color-inside"
          color="gray"
          headingFontSize="14px"
          iconFontSize="25px"
          iconColor="#1e55e6"
          isTransition
          heading={'ご契約中の保証プラン'}
          path={paths.warrantyParts}
        />
        <Checkbox
          name="confirm_warranty_plan"
          value={false}
          className="u-margin-top-sm"
        >
          ご契約中の保証プランを確認しました。
        </Checkbox>
        <p className={styles['checkbox-description']}>
          ご契約中の保証プランをご確認ください。
          <br />
          確認後、保証適用の希望有無を選択できます。
        </p>
      </div>
      <div
        className={`${styles['input-group']} ${
          !confirmWarrantyPlan ? 'disabled' : ''
        }`}
      >
        <h3 className={styles['input-group-label']}>
          自走はできますか
          <span
            className={`${styles['input-group-label-tips']} ${styles['required']}`}
          >
            必須
          </span>
        </h3>
        <div className={styles['checkbox-radio-items']}>
          {CAN_DRIVE_TYPES.map((item) => (
            <Radio
              key={`can-drive-${item.value}`}
              id={`canDrive__${item.value}`}
              name="canDrive"
              checked={canDrive === item.value}
              value={item.value}
              className={styles['input-radio']}
            >
              {item.text}
            </Radio>
          ))}
        </div>
      </div>

      <div
        className={`${styles['wrap-all-input']} ${isGrayOut ? 'disabled' : ''}`}
      >
        <div className={styles['input-group']}>
          <h3 className={styles['input-group-label']}>
            お車の不具合はどのような症状ですか
            <span
              className={`${styles['input-group-label-tips']} ${styles['required']}`}
            >
              必須
            </span>
          </h3>
          <div className={styles['accordion-group']}>
            {DEFECT_CATEGORIES.map((defectCategory, index) => (
              <List accordionList key={`defect-category-${defectCategory.id}`}>
                <ListItem
                  className={styles['accordion-container']}
                  accordionItem
                  accordionItemOpened={openedDefectCategory[index]}
                  onAccordionOpened={() =>
                    setValue(`openedDefectCategory.${index}`, true)
                  }
                  onAccordionClosed={() =>
                    setValue(`openedDefectCategory.${index}`, false)
                  }
                  disabled={isGrayOut}
                  title={defectCategory.name}
                >
                  <AccordionContent
                    className={`${styles['accordion-content']} ${styles['checkbox-radio-items']}`}
                  >
                    {defects.map(
                      (defect, index) =>
                        defect.m_defect_category_id === defectCategory.id && (
                          <Checkbox
                            isBoolean={false}
                            key={`defect-checkbox-${defect.id}`}
                            name={`m_defect_ids.${index}`}
                            value={defect.id}
                          >
                            {defect.name_for_customer}
                          </Checkbox>
                        ),
                    )}
                  </AccordionContent>
                </ListItem>
              </List>
            ))}
          </div>
        </div>

        <div className={styles['input-group']}>
          <h3 className={styles['input-group-label']}>
            発生頻度はどれくらいですか
            <span
              className={`${styles['input-group-label-tips']} ${styles['required']}`}
            >
              必須
            </span>
          </h3>
          {defectOccurrenceFrequencies.length && (
            <Picker
              id="m_defect_occurrence_frequency_id"
              name="m_defect_occurrence_frequency_id"
              required={canDrive != CanDriveType.CANNOT}
              options={defectOccurrenceFrequencies.map((dof) => ({
                value: dof.id,
                label: dof.name,
              }))}
              placeholder="選択してください"
            />
          )}
        </div>

        <div className={styles['input-group']}>
          <h3 className={styles['input-group-label']}>
            症状に気づかれたのはいつですか
            <span
              className={`${styles['input-group-label-tips']} ${styles['required']}`}
            >
              必須
            </span>
          </h3>
          <SelectCalendar
            placeholder="年/月/日"
            name="occur_date"
            maxDate={maxDate}
            hasError={errors?.occur_date?.type === 'required'}
          />
          {errors?.occur_date?.type === 'required' && (
            <p className={styles['error-message']}>
              発生日付が指定されていません
            </p>
          )}
        </div>

        <div className={`${styles['input-group']} ${styles['no-border']}`}>
          <h3 className={styles['input-group-label']}>
            その他の不具合症状などご入力ください
            <span className={`${styles['input-group-label-tips']}`}>任意</span>
          </h3>
          <TextInput type="textarea" name="symptom_etc" />
        </div>
      </div>

      <Button
        disabled={!formState.isValid || !confirmWarrantyPlan}
        type="button"
        className={styles['submit-button']}
        text="次へ"
        onClick={onSubmit}
      />
    </div>
  );
};
