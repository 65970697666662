import { Router } from 'framework7/types';
import { FC } from 'react';
import { AvailableBankList } from './AvailableBankList/AvailableBankList';

type AvailableBankListPageProps = {
  f7router: Router.Router;
};

export const AvailableBankListPage: FC<AvailableBankListPageProps> = ({
  f7router,
}) => {
  return <AvailableBankList f7router={f7router} />;
};
