import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './WatchingServiceSettingsCardStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';

interface Watcher {
  short_user_id: number;
  vehicle_id: string;
  watch_user_id: number;
  mailaddress: string;
  first_name: string;
  family_name: string;
  first_name_kana: string;
  family_name_kana: string;
}
interface WatchingServiceSettingsProps {
  watchers: Watcher[] | undefined;
  f7router: Router.Router;
  initialFetchWatchers: () => void;
}

export const WatchingServiceSettingsCard: FC<WatchingServiceSettingsProps> = ({
  watchers,
  f7router,
  initialFetchWatchers,
}) => {
  const maxWatcherCount = 5;
  const ableToAddWatcher = watchers?.length
    ? watchers.length < maxWatcherCount
    : true;
  const handleClickNavigateWatchingServiceSettings = (watcher?: Watcher) => {
    f7.view.main.router.navigate(paths.watchingServiceSettings, {
      props: { watcher, initialFetchWatchers },
      animate: true,
    });
  };
  return (
    <div className={style.card}>
      <div className={style.head}>
        <h3 className={style.heading3}>見守りサービス設定</h3>
        <span className={ableToAddWatcher ? '' : style.disabled}>
          <Button
            height="36px"
            colorTheme="gray"
            onClick={() => handleClickNavigateWatchingServiceSettings()}
          >
            追加する
          </Button>
        </span>
      </div>
      <div className={style.description}>
        事故であると思われる強い衝撃を受けた際に、大切な家族や友人に知らせることができます。
        <br />
        ※ご家族やご友人の連絡先の入力が必要です
      </div>
      {watchers &&
        watchers?.map((watcher, idx) => (
          <div className={style['watcher-wrap']} key={idx}>
            <div className={style['change-button-wrap']}>
              <Button
                height="36px"
                colorTheme="gray"
                onClick={() =>
                  handleClickNavigateWatchingServiceSettings(watcher)
                }
              >
                変更する
              </Button>
            </div>
            <div className={style.list}>
              <div className={style['list-label']}>通知する方のお名前</div>
              <div className={style['list-content']}>
                {watcher.family_name + watcher.first_name}
              </div>
            </div>
            <div className={style.list}>
              <div className={style['list-label']}>
                通知する方のメールアドレス
              </div>
              <div className={style['list-content']}>{watcher.mailaddress}</div>
            </div>
          </div>
        ))}
    </div>
  );
};
