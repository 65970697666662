import { Link, Popup } from 'framework7-react';
import { PopupProps } from 'framework7-react/components/popup';
import { Popup as PopupType } from 'framework7/types';
import { FC, ReactNode } from 'react';
import style from './popupTemplateStyle.module.css';
import { isIPhone } from '@/config/device';
import { sleep } from '@/utils/utils';

export interface PopupTemplateProps extends PopupProps {
  title: string;
  children: ReactNode;
  opened: boolean;
  isCloseIcon?: boolean;
  onPopupClosed?: () => void;
  handleClose?: () => void;
  id?: string;
}

export const PopupTemplate: FC<PopupTemplateProps> = ({
  title,
  children,
  opened,
  isCloseIcon = true,
  onPopupClosed = undefined,
  handleClose = undefined,
  id,
  ...props
}) => {
  const handleOnClosed = async (popup?: PopupType.Popup) => {
    onPopupClosed && onPopupClosed();

    // fix {display: none} when close popup at IOS 17.4
    if (isIPhone) {
      await sleep(0);
      popup?.$el.css('display', 'block');
      await sleep(0);
      popup?.$el.css('display', 'none');
    }
  };

  return (
    <Popup {...props} id={id} opened={opened} onPopupClosed={handleOnClosed}>
      {isCloseIcon && (
        <div className={style['close-wrapper']}>
          <Link
            popupClose={!handleClose}
            onClick={handleClose}
            animate
            className={style['close']}
          >
            <i className="icon-close" style={{ color: '#232323' }} />
          </Link>
        </div>
      )}
      <div className={style['popup-container']}>
        {title && <h1 className={style['title']}>{title}</h1>}
        {children}
      </div>
    </Popup>
  );
};
