import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { changePrepaidMoneyApi } from '@/api/prepaidApi';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import {
  ChangePrepaidMoneyParams,
  ChangePrepaidMoneyResponse,
} from '@/types/api/prepaidApi';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

export const useChangePrepaidMoney = () => {
  const [data, setData] = useState<ChangePrepaidMoneyResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const changePrepaid = async (params: ChangePrepaidMoneyParams) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const data = await changePrepaidMoneyApi(params);
      setData(data.data);
      if (data.data.success) {
        const newAdvancePaymentsInfo = store.state.advancePaymentsInfo;
        if (newAdvancePaymentsInfo.length) {
          newAdvancePaymentsInfo[0].monthly_charge = params.prepaid_fee;
          await storeDispatch(
            'setAdvancePaymentsInfo',
            'advancePaymentsInfo',
            newAdvancePaymentsInfo,
          );
        }
        openToast(
          '積み立て金額の変更が完了しました',
          'toast-success',
          3000,
          true,
          'top',
        );
        f7CustomBack(['#PrepaidChangeMoney']);
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    changePrepaid,
    data,
    setData,
    error,
  };
};
