import { baseApi, baseFormDataApi } from '@/config/axios';

import {
  FetchCarOrderParams,
  FetchCarOrderResponse,
  FetchTransportResponse,
  UpdateCarParams,
  UpdateCarResponse,
  UploadCarPhotoResponse,
} from '@/types/api/carApi';
import { CancelReasonResponse } from '@/types/api/warrantyApi';

//車両情報
export const updateCarApi = (
  t_stock_car_id: string,
  params: UpdateCarParams,
): Promise<UpdateCarResponse> =>
  baseApi.post(`/v1/customer/cars/${t_stock_car_id}`, params);

// 運輸支局取得
export const fetchTransportApi = (): Promise<FetchTransportResponse> =>
  baseApi.get('v1/code-map/transport_bu');

// 保証解約理由取得
export const fetchWarrantyCancelReasonApi = (): Promise<CancelReasonResponse> =>
  baseApi.get('v1/code-map/cancel_reason');

export const postUploadCarPhoto = (
  m_customer_id: number,
  t_stock_car_id: number,
  upload_file: string | Blob | File,
) => {
  const formData = new FormData();
  formData.append('m_customer_id', String(m_customer_id));
  formData.append('t_stock_car_id', String(t_stock_car_id));
  formData.append('upload_file', upload_file);

  return baseFormDataApi.post<UploadCarPhotoResponse>(
    'v1/customer/car_image_upload',
    formData,
  );
};

// 納車前注文書_情報取得
export const fetchCarOrderApi = (
  params: FetchCarOrderParams,
): Promise<FetchCarOrderResponse> =>
  baseApi.get('v1/customer/purchase-order', { params });
