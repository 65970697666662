import { useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { DriveRecordHighLight } from '../DriveRecordHighLight/DriveRecordHighLight';
import styles from './DriveRecordStyle.module.css';
import { ButtonIconVertical } from '@/components/projects/ButtonIconVertical/ButtonIconVertical';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { Car } from '@/types/api/customerApi';
import { isAdContractorData } from '@/utils/home';
import { f7CustomBack } from '@/utils/utils';
interface DriveRecordProps {
  f7router: Router.Router;
}

export const DriveRecord: FC<DriveRecordProps> = () => {
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;

  const isAdContractor = isAdContractorData({
    linkDriveStatus: selectedCar?.linkdrive_status,
  });

  return (
    <PageTemplate
      pageName="HistoryRecord"
      title="運転記録"
      showNavBack
      handleClickBack={() => {
        store.dispatch('setDiagnosticDate', { diagnosticDate: '' });
        store.dispatch('setSelectedDay', { selectedDay: undefined });
        f7CustomBack();
      }}
    >
      {isAdContractor && (
        <div className={styles['link-wrapper']}>
          <h3 className="u-margin-top-0 u-margin-bottom-sm">メニュー</h3>
          <div className={styles['menu-buttons-wrapper']}>
            <div className={styles['button-wrapper']}>
              <ButtonIconVertical
                label="運転履歴"
                iconType="driving-history"
                path={`.${paths.driveHistory}`}
              />
            </div>
            <div className={styles['button-wrapper']}>
              <ButtonIconVertical
                label="データ"
                iconType="data"
                path={`.${paths.driveData}`}
              />
            </div>
            <div className={styles['button-wrapper']}>
              <ButtonIconVertical
                label="衝撃履歴"
                iconType="car-impact"
                path={`.${paths.driveShockHistory}`}
              />
            </div>
          </div>
        </div>
      )}
      <DriveRecordHighLight />
    </PageTemplate>
  );
};
