import { yupResolver } from '@hookform/resolvers/yup';
import { Page } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { loginEmailSchema } from '../loginEmailSchema';
import style from './loginEmailStyle.module.css';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { RhfInput } from '@/components/uiParts/ReactHookForm/RhfInput/RhfInput';
import { UnderLineLink } from '@/components/uiParts/UnderLineLink/UnderLineLink';
import { paths } from '@/config/paths';
import { AffiliateId } from '@/consts/shop';
import { useEmailFirstLoginDecision } from '@/hooks/api/auth';
import { EmailFirstLoginDecisionApiParams } from '@/types/api/authApi';
import { addAffiliateIdToRequestParams } from '@/utils/shop';

interface LoginEmailProps {
  f7router: Router.Router;
  affiliateId: AffiliateId;
}

interface FormInput {
  email: string;
}

export const LoginEmail: FC<LoginEmailProps> = ({ f7router, affiliateId }) => {
  const formMethods = useForm<FormInput>({
    mode: 'onChange',
    defaultValues: { email: '' },
    resolver: yupResolver(loginEmailSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;
  const { emailFirstLoginDecision } = useEmailFirstLoginDecision(affiliateId);
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const params: EmailFirstLoginDecisionApiParams = {
      email: data.email,
    };
    if (!data.email) return;
    addAffiliateIdToRequestParams(affiliateId, params);
    await emailFirstLoginDecision(params);
  };
  return (
    <Page name="LoginEmail" pageContent={false}>
      <div className={style['container']}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style['email-input-label-wrapper']}>
              <span>ご登録済みのメールアドレスを入力してください</span>
              <div className={style['form-check-icon-wrapper']}>
                <RhfInput
                  className={style['input']}
                  name="email"
                  type="email"
                  placeholder="メールアドレス"
                />
                <div>
                  <CheckIcon isValid={isValid} />
                </div>
              </div>
              <span>初回ログイン時は、認証用のメールが送信されます</span>
            </div>
            <div className={style['button-container']}>
              <div className={style['button-wrapper']}>
                <Button type="submit" disabled={!isValid}>
                  次へ
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
        <div className={style['text-link']}>
          <UnderLineLink
            text="ログイン方法がわからなくなった方"
            path={paths.accountForgotten}
            f7router={f7router}
          />
        </div>
      </div>
    </Page>
  );
};
