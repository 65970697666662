import { f7 } from 'framework7-react';
import { FC } from 'react';
import ContactImageAvatar from 'src/assets/images/repair-contact-image.jpeg';
import style from './CarRepairContactRoadService.module.scss';
import { isApp } from '@/config/device';
import { url } from '@/consts/url';

interface CarRepairContactRoadServiceProps {
  callCenterTel?: string;
  callCenterName?: string;
}

export const CarRepairContactRoadService: FC<
  CarRepairContactRoadServiceProps
> = ({ callCenterTel = null, callCenterName }) => {
  const handleCallSupportCenter = () => {
    if (callCenterTel) {
      if (isApp) {
        window.location.href = `${url.NATIVE_BASE_URL}/request_location_info`;
      } else {
        window.location.href = 'tel://' + callCenterTel;
      }
    } else {
      openAlertNoWarranty();
    }
  };

  const openAlertNoWarranty = () => {
    f7.dialog.alert(
      '発信可能な電話番号がありません。\n お手数ですがお問い合わせフォームからご連絡ください。',
      ' エラー',
      () => {
        f7.dialog.close();
      },
    );
  };

  return (
    <div className={style['car-repair-contact-road-service']}>
      <h3>自走ができない方</h3>
      <p>ロードサービス窓口へご連絡ください</p>
      <div
        className={style['contact-road-service-info']}
        onClick={() => handleCallSupportCenter()}
      >
        <img className={style['contact-avatar']} src={ContactImageAvatar} />
        <div className={style['contact-road-service-info-text']}>
          <label>{callCenterName}</label>
          {/* <h4 className={style['contact-name']}>{unit_name}</h4> */}
        </div>
        <div className={style['contact-call-button']}>
          <i className="icon-telephone" />
        </div>
      </div>
    </div>
  );
};
