import { useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect } from 'react';
import { getPaymentList } from '../ReservingsHelper';
import { ReservingsStep2 } from './ReservingsStep2/ReservingsStep2';
import { store } from '@/config/store';
import { useGmo } from '@/hooks/useGmo';
import {
  BillInformation,
  PaymentMethod,
  Prepaid,
} from '@/types/api/customerApi';

interface ReservingsPageProps {
  f7router: Router.Router;
  changeStep: (step: number) => void;
  handleCancel?: () => void;
}
export const ReservingsStep2Page: FC<ReservingsPageProps> = ({
  f7router,
  changeStep,
  handleCancel,
}) => {
  const paymentMethods = useStore(
    store,
    'getPaymentMethodsInfo',
  ) as PaymentMethod[];
  const car = useStore(store, 'carItemInfo');
  const advancePayments = useStore(
    store,
    'getAdvancePaymentsInfo',
  ) as Prepaid[]; //store.state.advancePaymentsInfo;
  const bills = useStore(store, 'getBillInfo') as BillInformation[]; //store.state.billsInfo;
  const paymentListPrevPage = useStore(
    store,
    'getPaymentListPreviousPage',
  ) as string; // store.state.paymentListPrevPage;
  const { paymentList, nextBill, closestMonthBill } = getPaymentList(
    paymentMethods,
    bills,
    car,
    advancePayments,
  );
  const { addCreditCardPaymentMethod } = useGmo(paymentListPrevPage);

  useEffect(() => {
    addCreditCardPaymentMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReservingsStep2
      f7router={f7router}
      changeStep={changeStep}
      handleCancel={handleCancel}
      paymentList={paymentList}
      nextBillObj={{ nextBill, closestMonthBill }}
    />
  );
};
