import './TodoDetailStyle.scss';
import { store } from '@/config/store';

export const ShopInfo = () => {
  const { customerInfo } = store.state;

  const handleCallShop = () => {
    window.location.href = 'tel://' + customerInfo.unit_tel;
  };

  return (
    <div className="shop-info">
      <h4>購入店舗</h4>
      <span>{customerInfo.unit_name}</span>
      <span onClick={() => handleCallShop()} className="phone-number">
        <i className="icon-telephone" />
      </span>
    </div>
  );
};
