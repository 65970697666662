import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { CreditCardPayInfo } from 'src/components/pages/PaymentsSchedule/CreditCardPayInfo/CreditCardPayInfo';
import { OfficeFeeInfo } from 'src/components/pages/PaymentsSchedule/OfficeFeeInfo/OfficeFeeInfo';
import { Button } from '@/components/uiParts/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { OUTPUT_FLAG, noPayFlg, paymentTypeId } from '@/consts/payment';
import { BillInformationDetail } from '@/types/api/customerApi';
import './PaymentsHistoryStyle.scss';
import { PaymentTypeId } from '@/types/payment';
import { formatYearMonthToJa, hyphenToYear } from '@/utils/date';
import { switchPaymentIcon } from '@/utils/payment';

interface PaymentsHistoryProps {
  billDetails: BillInformationDetail[];
  f7router: Router.Router;
}

export const PaymentsHistory: FC<PaymentsHistoryProps> = ({ billDetails }) => {
  return (
    <PageTemplate pageName="PaymentsHistory" title="お支払い履歴" showNavBack>
      <CreditCardPayInfo textContent="各履歴に記載されている請求日とクレジットカードの引き落とし日は異なる場合があります。" />
      <div className="u-margin-bottom-md" />

      <OfficeFeeInfo />
      <div className="u-margin-bottom-md" />

      {billDetails.length === 0 ? (
        <div
          id="empty_history_container"
          className="info-card info-card-elevated"
        >
          お支払い履歴はありません
        </div>
      ) : (
        <div>
          {billDetails.map((item, index) => (
            <div
              className="info-card info-card-elevated u-margin-bottom-md"
              key={index}
            >
              <div className="content">
                <div className="head display-flex">
                  <h2>{formatYearMonthToJa(item.bill_month)}</h2>
                  {item.no_pay_flg != noPayFlg.BEFORE_PAYMENT && (
                    <Button
                      className="u-padding-left-sm u-padding-right-sm"
                      height="32px"
                      colorTheme="gray"
                      onClick={() =>
                        f7.view.main.router.navigate(paths.paymentReceipt, {
                          props: {
                            billDetail: item,
                          },
                        })
                      }
                    >
                      領収書を発行
                    </Button>
                  )}
                </div>
                {item.m_payment_type_id && (
                  <div
                    className="list-item display-flex"
                    style={{ borderTop: 'none', justifyContent: 'flex-start' }}
                  >
                    <span className="label-item">支払方法</span>
                    <i
                      className={`building-icon ${switchPaymentIcon(
                        item.m_payment_type_id as PaymentTypeId,
                      )}`}
                    />
                    {item.m_payment_type_id == paymentTypeId.BANK ? (
                      <span className="text-item u-padding-left-sm">
                        <ul>{item.payment_type_name}</ul>
                        {item.bank_name && <ul>{item.bank_name}</ul>}
                      </span>
                    ) : (
                      <span className="text-item u-padding-left-sm">
                        <ul>{item.payment_type_name}</ul>
                        {item.card_no && <ul>{item.card_no}</ul>}
                      </span>
                    )}
                  </div>
                )}
                {item.bill_price && (
                  <div className="list-item display-flex">
                    <span className="label-item">
                      請求金額
                      <br />
                      （消費税10%込）
                    </span>
                    <span className="text-item">
                      {item.bill_price?.toLocaleString()}円
                    </span>
                  </div>
                )}
                {item.billing_date && (
                  <div className="list-item display-flex">
                    <span className="label-item">請求日</span>
                    <span className="text-item">
                      {hyphenToYear(item.billing_date, false)}
                    </span>
                  </div>
                )}
                <div className="list-item display-flex">
                  <span className="label-item">入金結果</span>
                  <span
                    className={`text-item ${
                      item.no_pay_flg == noPayFlg.PAYMENT_CONFIRMED
                        ? ''
                        : 'purple'
                    }`}
                  >
                    {item.no_pay_flg == noPayFlg.PAYMENT_CONFIRMED
                      ? '入金確認済み'
                      : ''}
                    {(item.output_flg == OUTPUT_FLAG.invoice ||
                      item.output_flg == OUTPUT_FLAG.noSettlement) &&
                      item.no_pay_flg == noPayFlg.BEFORE_PAYMENT &&
                      '引き落としができませんでした'}
                  </span>
                </div>
                {item.no_pay_flg != noPayFlg.PAYMENT_CONFIRMED && (
                  <div className="list-item display-flex">
                    <span className="label-item">備考</span>
                    <span
                      className={`text-item ${
                        item.no_pay_flg != noPayFlg.PAYMENT_CONFIRMED &&
                        item.m_payment_type_id != paymentTypeId.BANK
                          ? 'purple'
                          : ''
                      }`}
                    >
                      {item.memo}
                    </span>
                  </div>
                )}
                {item.no_pay_flg == noPayFlg.BEFORE_PAYMENT &&
                  item.m_payment_type_id == paymentTypeId.BANK && (
                    <div className="payments_schedule">
                      <a
                        onClick={() =>
                          f7.views.main.router.navigate(paths.paymentsSchedule)
                        }
                        className="options-item"
                      >
                        <span style={{ width: '90%' }} className="u-font-bold">
                          請求予定を確認する
                        </span>
                        <span style={{ width: '10%' }} className="right-sent">
                          <i className="icon-right" />
                        </span>
                      </a>
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
      )}
    </PageTemplate>
  );
};
