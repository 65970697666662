import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect } from 'react';
import style from './NoticeSettingsStyle.module.css';
import { WatchingServiceSettingsCard } from './WatchingServiceSettingsCard/WatchingServiceSettingsCard';
import { TableList } from '@/components/uiParts/List/TableList/TableList';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { linkDriveStatus as LINK_DRIVE_STATUS } from '@/consts/linkDrive';
import {
  NotificationSettings,
  useFetchVehicleStrikeSettings,
} from '@/hooks/api/driveRecord/useFetchVehicleStrikeSettings';
import { useFetchWatcherAll } from '@/hooks/api/linkDrive/useFetchWatcherAll';
import { off, on } from '@/utils/eventHelper';
import { isAdContractorData } from '@/utils/home';
interface NoticeSettingsProps {
  f7router: Router.Router;
  isHideWatchingSetting?: boolean;
}

export type Options = {
  options_title: string;
  type: number;
  props: string;
  status: number;
};

export const NoticeSettings: FC<NoticeSettingsProps> = ({
  f7router,
  isHideWatchingSetting = false,
}) => {
  const {
    linkDriveOwnerInfo: { vehicle_id },
    authInfo: { m_customer_id },
    carItemInfo: { linkdrive_status: linkDriveStatus },
  } = store.state;
  const { fetchWatcherAll, data: watchersData } = useFetchWatcherAll();
  const { fetchVehicleStrikeSettings, data: strikeNoticeSettings } =
    useFetchVehicleStrikeSettings();
  const watchers = watchersData?.data?.watchers;
  const isAdContractor = isAdContractorData({
    linkDriveStatus,
  });
  const isSubscribeInsurance =
    linkDriveStatus === LINK_DRIVE_STATUS.FORMAL_REGISTRATION;
  const targetNotificationCategory = isSubscribeInsurance ? 6 : 1;
  const eCallList =
    strikeNoticeSettings?.filter((setting) => {
      return setting.notification_category === targetNotificationCategory;
    }) ?? [];

  const options: Options[] = isSubscribeInsurance
    ? [
        {
          options_title: '大きな衝撃の通知',
          type: 3,
          props: 'ecall_big',
          status: 0,
        },
        {
          options_title: '中程度の衝撃の通知',
          type: 2,
          props: 'ecall_middle',
          status: 0,
        },
      ]
    : [
        {
          options_title: '大きな衝撃の通知',
          type: 3,
          props: 'ecall_big',
          status: 0,
        },
        {
          options_title: '中度の衝撃の通知',
          type: 2,
          props: 'ecall_middle',
          status: 0,
        },
        {
          options_title: '小さな衝撃の通知',
          type: 1,
          props: 'ecall_small',
          status: 0,
        },
      ];

  const handleClickNavigateStrikeNoticeSettings = () => {
    f7.view.main.router.navigate(paths.strikeNoticeSettings, {
      props: {
        settings: options,
      },
      animate: true,
    });
  };

  const initialFetchWatchers = () => {
    fetchWatcherAll({ short_user_id: m_customer_id, vehicle_id });
  };

  const setDefaultValue = () => {
    options.forEach((e: Options) => {
      eCallList.forEach((i: NotificationSettings) => {
        if (e.type === i.notification_type) {
          e.status = i.target_flg;
        }
      });
    });
    return options;
  };

  useEffect(() => {
    fetchWatcherAll({ short_user_id: m_customer_id, vehicle_id });
    fetchVehicleStrikeSettings({ m_customer_id });
    const eventListener = () => {
      fetchVehicleStrikeSettings({ m_customer_id });
    };
    on('fetchVehicleStrikeSettings', eventListener);

    return () => {
      off('fetchVehicleStrikeSettings', eventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDefaultValue();
    const eventListener = () => {
      setDefaultValue();
    };
    on('setDefaultValue', eventListener);
    return () => {
      off('setDefaultValue', eventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strikeNoticeSettings]);

  return (
    <div className={style.container}>
      {/* TODO: コンポーネント分割 */}
      <div className={style.card}>
        <div className={style.head}>
          <h3 className={style.heading3}>衝撃通知設定</h3>
          <div
            className={style['change-button']}
            onClick={handleClickNavigateStrikeNoticeSettings}
          >
            変更する
          </div>
        </div>
        <div className={style.description}>
          車をぶつけるなどして衝撃を検知した際に通知を送信するための機能です。
          衝撃の大きさに応じて、通知の有無を設定することができます。
        </div>
        <ul className={style.list}>
          {setDefaultValue()?.map((option) =>
            eCallList?.map(
              (settings) =>
                option.type == settings?.notification_type && (
                  <TableList
                    key={option.type}
                    head={option.options_title}
                    body={settings.target_flg ? '通知する' : '通知しない'}
                    bodyColor={option.status ? '#1E55E6' : '#898989'}
                  />
                ),
            ),
          )}
        </ul>

        {eCallList.map((settings, idx) => (
          <p key={settings?.notification_type}>{settings?.m_customer_id}</p>
        ))}
      </div>
      {isSubscribeInsurance && !isHideWatchingSetting && (
        <WatchingServiceSettingsCard
          initialFetchWatchers={initialFetchWatchers}
          watchers={watchers}
          f7router={f7router}
        />
      )}
    </div>
  );
};
