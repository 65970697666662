import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { dealWarrantyRefundAccountApi } from '@/api/warrantyApi';
import { DealWarrantyRefundAccountApiParams } from '@/types/api/warrantyApi';

export const usePostDealWarrantyRefundAccount = (callback?: Function) => {
  const [error, setError] = useState<Error>();

  const dealWarrantyRefundAccount = async (
    params: DealWarrantyRefundAccountApiParams,
  ) => {
    try {
      f7.preloader.show();
      const res = await dealWarrantyRefundAccountApi(params);

      if (res?.data?.success) {
        callback?.();
      } else {
        f7.dialog.alert('', res.data.error_message || '');
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    dealWarrantyRefundAccount,
    error,
  };
};
