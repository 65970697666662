/* eslint-disable no-magic-numbers */
import { Page, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { ConfirmInput } from '../Reservings/Reservings';
import { Checkbox } from './Checkbox/Checkbox';
import { Button } from '@/components/uiParts/Button';
import './ReservingsStep3Style.scss';
import { ConfirmTerm } from '@/components/uiParts/ConfirmTerm/ConfirmTerm';
import { termType } from '@/consts/terms';
export interface ReservingsStep3Props {
  changeStep: (step: number) => void;
  f7router: Router.Router;
  defaultValues?: ConfirmInput;
}

export const ConfirmSchema = yup.object().shape({
  currentCheckbox: yup.number().oneOf([yup.ref('targetedCheckox'), null], ''),
});

export const ReservingsStep3: FC<ReservingsStep3Props> = ({
  changeStep,
  f7router,
  defaultValues,
}) => {
  let countTermItem = 0;
  const [popupOpened, setPopupOpened] = useState(false);
  const {
    watch,
    setValue,
    formState: { isValid },
    formState,
  } = useFormContext<ConfirmInput>();
  const m_prepaid_plan_id = useStore('getPrepaidPlanId');
  const currentCheckbox = watch('currentCheckbox');

  const targetedCheckox = watch('targetedCheckox');
  const handleChange = (event: BaseSyntheticEvent, name: string) => {
    const termListCheckboxStore = JSON.parse(
      window.localStorage.getItem('termListCheckbox') || '{}',
    );
    const oldTermList = Object.keys(termListCheckboxStore).length
      ? termListCheckboxStore
      : defaultValues;
    const newValue = Object.fromEntries([[name, event.target.checked]]);

    if (event.target.checked) {
      window.localStorage.setItem(
        'termListCheckbox',
        JSON.stringify({
          ...oldTermList,
          ...newValue,
          currentCheckbox: currentCheckbox + 1,
        }),
      );
      setValue('currentCheckbox', currentCheckbox + 1, {
        shouldValidate: true,
      });
    } else {
      window.localStorage.setItem(
        'termListCheckbox',
        JSON.stringify({
          ...oldTermList,
          ...newValue,
          currentCheckbox: currentCheckbox - 1,
        }),
      );
      setValue('currentCheckbox', currentCheckbox - 1, {
        shouldValidate: true,
      });
    }
  };
  const handleClickContinue = async () => {
    changeStep(3);
  };
  const termsList = [
    {
      title: '払い戻し等について',
      option: [
        'プリペイドサービスは、原則といたしまして、法令に定める場合を除き払い戻すことはできません。',
        'プリペイドサービスは、第三者に利用させることはできません。',
      ],
    },
    {
      title: 'プリペイドサービスの積立について',
      option: [
        'プリペイドサービスのお申込みならびに決済方法登録の翌月よりお引落（あるいはクレジットカード決済）が開始されます。残高への反映はさらに翌月の15日です。  例）1月に申込みと決済登録→2月に引落→3月15日に残高へ反映',
      ],
    },
    {
      title: 'プリペイドサービスによる決済について',
      option: [
        'プリペイドサービスによる決済を取り消す必要が生じた場合、対象商品等の代金に相当する金額を利用者のプリペイドサービスアカウントに加算する方法によるご返金となります。',
      ],
    },
    {
      title: 'プリペイドサービスボーナスの取扱いについて',
      option: [
        `プリペイドサービスをご利用のお客様に対し、プリペイドサービスへのご入金額に応じたプリペイドサービスボーナス（当社が無償で付与するプリペイドサービスのことをいいます）を付与します。
        プリペイドサービスボーナスが付与された決済の取引が取り消された場合には、付与されたプリペイドサービスボーナスも同時に取り消されます。`,
        'プリペイドサービスボーナスの残高がなくなるまで、自動的にプリペイドサービス残高に優先して、支払いに充当されます。',
        'プリペイドサービスボーナスの払戻しや譲渡はできません。',
        'お客様がプリペイドサービスのご利用を終了（プリペイドサービスアカウントの停止等）された場合には、プリペイドサービスボーナスはすべて消滅します。',
      ],
    },
    {
      title: 'プリペイドサービスの有効期間について',
      option: [
        'プリペイドサービスの有効期間は、最後に入金または利用した日より2年後です。',
      ],
    },
    {
      title: '入金遅滞の場合のお取り扱いについて',
      option: [
        '残高不足等の理由により、指定口座からお支払い日に指定金額の引落ができないときは、該当月は未払い扱いといたします。次月以降の合算引落はございません。',
        'クレジットカード会社からのチャージバックが発生した際には、チャージバック相当額を該当のハウスプリペイドアカウントの残高より相殺いたします。',
        '理由の如何を問わず、未払い扱いの月が連続で2ヵ月発生した場合、引落継続の意思が無いものとみなし引落を停止いたします。引落を再開する場合は、当社指定の手続きを行うことにより引落を再開することができます。',
      ],
    },
  ];
  useEffect(() => {
    document.querySelector('#ReservingsStepper')?.scrollTo(0, 0);
  }, []);
  return (
    <Page name="ReservingsStep3" pageContent={false}>
      <div className="content">
        <div className="heading-top">プリペイドサービス　重要事項説明書</div>
        <span className="required">必須</span>
        <div className="text-group">
          <p>
            当サービスのお申込みおよびご利用開始にあたりまして、
            <a onClick={() => setPopupOpened(true)}>
              プリペイドサービス利用規約
            </a>
            より重要な項目を抜粋しております。
          </p>
          <p>すべての項目をご確認いただき、チェックを入れてください。</p>
        </div>
        {!!termsList.length && (
          <div className="terms-of-you">
            {termsList.map((item, index) => (
              <div className="terms-box" key={`terms-${index}`}>
                <div className="title">{item.title}</div>
                <div className="item-list">
                  {item.option.map((option, optionIndex) => {
                    countTermItem++;
                    return (
                      <label
                        key={`label-${index}-${optionIndex}`}
                        className="input-checkbox input-radio"
                      >
                        <Checkbox
                          name={`Term${countTermItem}`}
                          handleOnChange={handleChange}
                        />
                        <div>
                          <p>{option}</p>
                        </div>
                      </label>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="button-wrap">
          <p>
            確認する項目：残り{' '}
            <span>{targetedCheckox - currentCheckbox}件</span> (全
            {targetedCheckox}件)
          </p>
          <Button
            text="次へ"
            disabled={!isValid}
            handleClickAction={handleClickContinue}
          />
        </div>
        <ConfirmTerm
          type={termType.PREPAID}
          prepaidId={m_prepaid_plan_id}
          opened={popupOpened}
          title="プリペイドサービス利用規約"
          onPopupClosed={() => setPopupOpened(false)}
        />
      </div>
    </Page>
  );
};
