import { HistoryType } from '@/types/payment';

export const paths = {
  // login
  root: '/',
  login: '/login/',
  loginTutorial: '/login_tutorial/',
  loginId: '/login_id/',
  loginEmail: '/email/',
  loginOld: (params: {
    authCallback: string;
    provider: string;
    providerId: string;
    message: string;
  }) => {
    return `/old/${params.authCallback}/${params.provider}/${params.providerId}/${params.message}`;
  },
  accountForgotten: '/account_forgotten/',
  passwordForgotten: '/password_forgotten/',
  emailDisable: '/email_disable/',
  emailComplete: '/email_complete/',
  unRegistered: '/unregistered/',
  password: '/password/',
  resetPasswordId: '/reset_password/id/',
  resetPasswordComplete: '/reset_password_complete/',
  resendEmail: '/resend_email/',
  setPassword: '/set_password/',
  privacy: '/privacy/',
  privacyPc: '/privacy_pc/',
  contact: '/contact/',
  phoneChanged: '/phone_changed/',
  phoneDuplicated: '/phone_duplicated/',
  contactNumber: '/contact_number/',
  contactLogin: '/contact_login/',
  confirmTerms: '/confirm_terms/',
  contactPhone: '/contact_phone/',
  contactPc: '/contact_pc/',
  contactComplete: '/contact_complete/',
  terms: '/terms/',
  termsOfUse: '/terms_of_use/',
  pinCode: '/pin_code/',
  resendSms: '/resend_sms/',
  smsDisable: '/sms_disable/',
  smsDisable2: '/sms_disable_2/',
  resetPassword: `/reset_password/`,
  drivingScoreTermsDetail: (from: string) => {
    return `/driving_score_terms_detail/${from}/`;
  },
  logout: '/logout',
  createAccountByEmail: '/create_account_by_email',
  adminAuth: '/admin_auth',
  emailDuplicated: '/email_duplicated',

  // home
  home: '/home/',
  homeStatus: (status: string) => {
    return `/home/${status}`;
  },

  // car commisioned work
  commisionedWork: '/commisioned_work/',
  carWash: '/car_wash/',
  carWashStatus: (status: string) => {
    return `/car_wash/${status}`;
  },
  carWashHand: '/car_wash_hand/',
  carWashHandStatus: (status: string) => `/car_wash_hand/${status}`,
  carWashHandAbout: '/car_wash_hand/about/',
  carWashHandAboutFull: '/car_wash_hand/about_pc/',
  carWashConditioner: '/car_wash_conditioner/',
  carWashConditionerStatus: (status: string) =>
    `/car_wash_conditioner/${status}`,
  carWashConditionerAbout: '/car_wash_conditioner/about/',
  carWashConditionerAboutFull: '/car_wash_conditioner/about_pc/',

  //car repair
  carRepair: '/car_repair/',
  repairDetail: (id: string) => `/repair/${id}`,

  // car maintenance
  maintenance: '/maintenance/',
  maintenanceGuide: '/maintenance_guide/',

  // prepaid
  prepaid: '/prepaid/',
  prepaidStatus: (status?: string) => {
    return status ? `/prepaid/${status}/` : '/prepaid/';
  },
  stopReservings: '/stop_reservings/',
  restartReservings: '/restart_reservings/',
  stopReservingsConfirm: '/stop_reservings_confirm/',
  stopReservingsComplete: '/stop_reservings_complete/',

  // connector
  linkDriveSetupList: '/linkdrive_setup_list/',
  connectorSetting: '/connector_setting/',
  linkDriveCalibration: '/link_drive_calibration/',
  vehicleNoticeSettings: '/vehicle_notice_settings/',
  strikeNoticeSettings: '/strike_notice_settings/',
  watchingServiceSettings: '/watchingService_settings/',
  preventTheftSettings: '/prevent_theft_settings/',
  drivingDataAnalysisSettings: '/driving_data_analysis_settings/',
  connecterTerms: '/connecter_terms/',
  linkDriveTerm: '/linkdrive_terms/',
  linkDriveSetupSteps: '/linkdrive_setup_steps/',
  linkDriveSetupSteps2: '/linkdrive_setup_steps/setup_step2/',
  linkDriveSetupSteps3: '/linkdrive_setup_steps/setup_step3/',
  serialNumberInput: '/serial_number_input/',
  calibrationConfirm: '/calibration_confirm/',

  // driving report
  accelerationData: '/acceleration_data/',
  brakesData: '/brakes_data/',
  dataUsingSetting: '/data_using_setting/',
  driveCalender: '/drive_calender/',
  monthlyDriveReport: '/monthly_drive_report/',
  driveHistory: '/drive_history/',
  driveData: '/drive_data/',
  driveRecord: '/drive_record/',
  drivingMonthlyData: '/driving_monthly_data/',
  drivingDailyData: '/driving_daily_data/',
  drivingScoreList: '/driving_score_list/',
  drivingScoreTotal: '/driving_score_total/',
  drivingReportList: '/driving_report_list/',
  drivingAnalysisServiceAgree: '/driving-analysis-service-agree/',
  driveScore: '/drive_score/',
  totalScore: '/total_score/',
  drivingRecordByDayOrTime: '/driving_record_by_day_or_time/',
  evaluationDetails: '/evaluation_details/',
  driveHistoryDetail: '/drive_history_detail/',
  driveShockHistory: '/drive_shock_history/',
  co2Information: '/co2_information/',
  watchingMonthlyDriveReport:
    '/watching_service/monthly_drive_report/:ownerId/:vehicleId/:customerId/:analysisFromDate',
  watchingServiceMonthlyDriveReport: (
    ownerId: string,
    vehicleId: string,
    customerId: string,
    analysisFromDate: string,
  ) => {
    return `/watching_service/monthly_drive_report/${ownerId}/${vehicleId}/${customerId}/${analysisFromDate}`;
  },
  totalScoreList: '/total_score_list/',

  // insurance
  driveInsurance: '/drive_insurance/',
  fuelEfficiency: '/fuel_efficiency/',
  positionDetecting: '/position_detecting/',
  scoreSendDataApproval: '/score_send_data_approval/',

  //car information
  car: (carId: string) => {
    const id = carId || 'null';
    return `/car/${id}`;
  },
  updateNumber: '/update_number/',
  updateInspection: (id: string) => {
    return `/update_inspection/${id}/`;
  },
  problem: '/problem/',
  trouble: '/trouble/',
  problemInsurance: '/problem_insurance',
  problemTerms: '/problem_terms',
  troubleService: '/trouble_service',
  troubleTerms: '/trouble_terms',
  carDetail: '/car_detail/:id/',
  carOrder: (id: string) => `/car_order/${id}/`,
  carOrderDetail: (id: string) => `/car_order/${id}/detail/`,

  // road service
  troubleShooting: '/trouble_shooting/',
  roadserviceGuide: '/roadservice_guide/',
  roadserviceTerms: '/roadservice_terms/',
  roadservice: '/roadservice/',
  accident: '/accident/',
  roadserviceTermsPopup: '/roadservice_terms_popup/',

  // coupon
  coupons: '/coupons/',
  usedCoupon: '/used_coupons/',
  coupon: (couponId: string) => {
    return `/coupons/${couponId}/`;
  },

  //warranty
  warranty: (status: string) => {
    return `/warranty/${status}`;
  },
  updateWarrantyCustomer: '/update_warranty_edit/customer/',
  warrantyCancelable: (warrantyNo: string) => {
    return `/warranty_cancelable/${warrantyNo}/`;
  },
  warrantyDetail: '/warranty_detail/',
  updateWarranty: (warrantyNo: string) => {
    return `/update_warranty/${warrantyNo}/`;
  },
  cancelWarranty: '/cancel_warranty/',
  cancelWarrantyDetail: (warrantyNo: string) => {
    return `/cancel_warranty_detail/${warrantyNo}/`;
  },
  updateWarrantyDetail: (warrantyNo: string) => {
    return `/update_warranty_detail/${warrantyNo}/`;
  },
  updateWarrantyComplete: (warrantyNo: string) => {
    return `/update_warranty_complete/${warrantyNo}/`;
  },
  warrantyAfterCancelled: '/warranty_after_cancelled/',
  warrantyTerms: '/warranty_terms/',
  warrantyParts: '/warranty_parts/',
  warrantyParts2: (warrantyNo: string) => {
    return `/warranty_parts_2/${warrantyNo}/`;
  },
  warrantyGuide: `/warranty_guide/`,
  warrantyDuplicated: '/warranty_duplicated/',
  warrantyFaq: '/warranty_faq/',

  // self scan
  selfScan: '/self_scan/',
  selfScanStart: '/self_scan_start/',
  selfScanSteps: '/self_scan_steps/',
  selfScanResult: '/self_scan_result/',
  scanHistory: '/scan_history/',
  abnormalAlert: '/abnormal_alert/',

  // account
  account: '/account/',
  accountRegistPayment: (status: string = '') => {
    return `/account_payment_regist/${status}`;
  },
  editSignIn: '/edit_signin',
  customer: '/customer/',
  editCustomer: '/edit_customer/',
  editEmail: '/edit_email/',
  editPhone: '/edit_phone/',
  singIn: '/signin/',
  registerPhone: '/register_phone/',
  editAddress: '/edit_address/',
  updatePassword: '/update_password/',

  //notice
  reservationHistory: '/reservation/history/',
  carwashDetail: (id: string) => `/carwash/${id}/`,
  carwashEdit: (id: string) => `/carwash/${id}/edit/`,
  carwashEditSuccess: (id: string) => `/carwash/${id}/edit_success/`,
  carwashCancelReason: (id: string) => `/carwash/${id}/cancel_reason/`,

  registerWash: '/register_wash/',
  washHistoryDetail: '/wash_history_detail/',

  newsDetail: '/news/:id/',
  news: '/news/',
  newsList: '/',
  todoList: '/todo_list/',
  todoDetail: (id: string) => {
    return `/todo_detail/${id}/`;
  },
  todoInstruction: (id: string) => {
    return `/todo_instruction/${id}/`;
  },
  consulting: '/consulting/',
  noticedetaildummy1: '/notice_detail_dummy1/',
  noticedetaildummy2: '/notice_detail_dummy2/',
  noticeWarrantyDetail: '/notice_warranty_detail/',
  noticeDetail: (id: string) => {
    return `/notice_detail/${id}/`;
  },
  consultingHistory: (id: string) => {
    return `/consulting_history/${id}/`;
  },
  deliveryCheckList: '/delivery_check_list/',
  userTasks: '/user_tasks',
  //prepaid
  availableBankList: '/available_bank_list/',
  chargePrepaid: '/charge_prepaid/',
  prepaidSummary: '/prepaid_summary/' /* TODO: 影響範囲 */,
  usePrepaid: '/use_prepaid/',
  usePrepaidConfirm: '/use_prepaid_confirm/',
  prepaidChangeMoney: '/prepaid_change_money/',
  prepaidHistory: '/prepaid_history/',
  prepaidSetting: '/prepaid_settings/',
  prepaidSettingStatus: (status?: string) => {
    return status ? `/prepaid_settings/${status}` : '/prepaid_settings/';
  },
  prepaidLaws: '/prepaid_laws/',
  prepaidTerms: '/prepaid_terms/',
  reservings: '/reservings/',
  reservingsStep3: '/step3/',
  reservingsStep4: '/step4/',
  vehicleCondition: '/vehicle_condition/',
  updateReservings: '/update_reservings/',
  usePrepaidComplete: '/use_prepaid_complete/',
  prepaidHistoryDetail: '/prepaid_history_detail/',
  shopmap: '/shopmap/',
  prepaidStop: '/prepaid_stop/',
  prepaidRestart: '/prepaid_restart/',

  //payment
  registerPayment: (type: string, no: string) => {
    return `/register_payments/${type}/${no}`;
  },
  registerPaymentList: '/register_payment_list/',
  registerBank: '/register_bank/',
  deletePaymentsDisabled: '/delete_payments_disabled/',
  payments: '/payments/',
  scheduledRefundDetail: (warrantyNo: string, no: String) => {
    return `/scheduled_refund_detail/${warrantyNo}/${no}/`;
  },
  paymentsSchedule: '/payments_schedule/',
  payments2Schedule: (warrantyNo: string) => {
    return `/payments_schedule2/${warrantyNo}/`;
  },
  paymentsHistory: (warrantyNo: string, historyType?: HistoryType) => {
    return `/payments_history/${warrantyNo}/`;
  },
  payments2History: '/payments2_history/',
  paymentReceipt: '/payment_receipt/',
  deletePayments: (
    paymentMethodId?: string,
    paymentTypeId?: string,
    accountOrCardNumber?: string,
  ) => {
    const url = `/delete_payments/${paymentMethodId}/${paymentTypeId}/${
      accountOrCardNumber || 'null'
    }`;
    return url;
  },
  billingScheduleDetail: `/billing_schedule_detail/`,

  // setting
  appSettings: '/app_settings/',
  pushSettings: '/push_settings/',
  version: '/version/',

  // help
  helpTop: '/help_top/',

  // impact
  impactInfo: '/impact_info/',

  // contract
  contractConfirm: (accessToken: string, websocketToken: string) =>
    `/contract_confirm/${accessToken}/${websocketToken}`,
  contractSignature: '/contract_signature/',
  contractComplete: '/contract_complete/',
  rank: `/rank/`,

  // trade warranty
  tradeWarrantyDetail: '/trade_warranty_detail/',
  tradeSurvey: '/trade_survey/',
  tradeConfirmAppDetail: '/trade_confirm_app_detail/',
  tradeBookOnlineConsultation: '/trade_book_online_consultation/',
  tradeGuarantee: '/trade_guarantee/',
  tradeGuaranteeUpdate: '/trade_guarantee_update/',
  tradeGuaranteeApplication: '/trade_guarantee_application/',
  tradeReservation: '/trade_reservation/',
  tradeAssuranceTerms: '/trade_assurance_terms/',
  dealWarrantyTerms: '/deal_warranty_terms/',
  tradeGuaranteePlan: '/trade_guarantee_plan/',
  tradeGuaranteeDocuments: '/trade_guarantee_documents/',
  tradeGuaranteeDocumentsPreview: '/trade_guarantee_documents_preview/',
};
