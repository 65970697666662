import parse from 'html-react-parser';
import { FC } from 'react';
import style from './TermsOfUseStyle.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

interface TermsOfUseProps {
  html?: string;
}

export const TermsOfUse: FC<TermsOfUseProps> = ({ html }) => {
  if (!html) return <></>;
  return (
    <RoundSheet>
      <div className={style['container']}>{parse(html)}</div>
    </RoundSheet>
  );
};
