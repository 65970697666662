import { Block, Link, Navbar, NavLeft, NavTitle, Page } from 'framework7-react';
import React, { FC } from 'react';
import style from './DrivingRecordByDayOrTimeStyle.module.css';

export const DrivingRecordByDayOrTime: FC = () => {
  const colorBlockList = [
    { level: 'A', color: '#339CD1' },
    { level: 'B', color: '#FE8463' },
    { level: 'C', color: '#9BCA63' },
  ];
  return (
    <Page name="DrivingRecordByDayOrTime">
      <Navbar>
        <NavLeft>
          <Link back animate>
            <i className="icon-left" />
          </Link>
        </NavLeft>
        <NavTitle>時間帯・曜日別</NavTitle>
      </Navbar>
      <Block>
        <div>
          <h2 className={`${style['sub-title']}`}>時間帯・曜日別</h2>
          <div className="info-card info-card-elevated u-margin-top-sm">
            <div className="u-font-size-xxl u-font-bold inner-score-color-b">
              時間帯別 評価
            </div>
            <div className={`${style['e-charts-blocks']}`}>
              {colorBlockList.map((item, index) => (
                <div className={`${style['e-charts-blocks-in']}`} key={index}>
                  <div
                    className="u-padding-right-xs"
                    style={{ color: item.color }}
                  >
                    {item.level}
                  </div>
                  <div
                    className={`${style['color-block']}`}
                    style={{ backgroundColor: item.color }}
                  />
                </div>
              ))}
            </div>
            <div className={`${style['time-blocks']} u-margin-top-xs`}>
              <div className={`${style['e-charts-blocks-in-col']}`}>
                <div className="u-font-bold u-font-size-xxl">朝</div>
                <div className="time-block u-margin-top-xs">5:00 ~ 11:00</div>
              </div>
              <div className={`${style['e-charts-blocks-in-col']}`}>
                <div className="u-font-bold u-font-size-xxl">昼</div>
                <div className="time-block u-margin-top-xs">11:00 ~ 18:00</div>
              </div>
              <div className={`${style['e-charts-blocks-in-col']}`}>
                <div className="u-font-bold u-font-size-xxl">夜</div>
                <div className="time-block u-margin-top-xs">18:00 ~ 5:00</div>
              </div>
            </div>

            <div id="time" className={`${style['pies']}`} />
            <div className="remark-words">
              ※グラフの数値は、各時間帯の走行点数です。
            </div>
          </div>

          <div className="info-card info-card-elevated u-margin-top-sm">
            <div className="u-font-size-xxl u-font-bold inner-score-color-b">
              曜日別 評価
            </div>
            <div className={`${style['e-charts-blocks']}`}>
              {colorBlockList.map((item, index) => (
                <div className={`${style['e-charts-blocks-in']}`} key={index}>
                  <div
                    className="u-padding-right-xs"
                    style={{ color: item.color }}
                  >
                    {item.level}
                  </div>
                  <div
                    className={`${style['color-block']}`}
                    style={{ backgroundColor: item.color }}
                  />
                </div>
              ))}
            </div>
            <div className={`${style['time-blocks']} u-margin-top-xs`}>
              <div className={`${style['e-charts-blocks-in-col']}`}>
                <div className="u-font-bold u-font-size-xxl">平日</div>
              </div>
              <div className={`${style['e-charts-blocks-in-col']}`}>
                <div className="u-font-bold u-font-size-xxl">土日</div>
              </div>
            </div>
            <div id="day" className={`${style['pies']}`} />
            <div className="remark-words">
              ※グラフの数値は、各時間帯の走行点数です。
            </div>
            <div className="remark-words">
              ※平日の祝日の場合、平日に累計されます。
            </div>
          </div>
        </div>
      </Block>
    </Page>
  );
};
