import { FC } from 'react';
import style from './TextLinkCardStyle.module.css';

interface LinkDriveMenuType {
  id: number;
  title: string;
  path?: string;
  titleIconType?: string;
  disaled?: boolean;
  latestVer?: boolean;
}

interface Props {
  iconType: string;
  isEleveted: boolean;
  linkDriveMenus: LinkDriveMenuType[];
  handleClick: () => void;
  cardTitle?: string;
}

export const TextLinkCard: FC<Props> = ({
  cardTitle = '',
  iconType = 'right',
  isEleveted = false,
  linkDriveMenus,
  handleClick,
}) => {
  return (
    <div className="info-card info-card-elevated">
      <div className="card-container">
        <div className="text-link-title u-font-size-xl u-font-bold u-padding-bottom-sm">
          {cardTitle}
        </div>
        <div>
          <slot name="description" />
        </div>
        <div className="text-link-card-list">
          {linkDriveMenus.map((linkDriveMenu, index) => (
            <div
              key={index}
              className={`${style['list-item']} ${
                index === linkDriveMenus.length - 1 ? style['no-border'] : ''
              }`}
            >
              {linkDriveMenu.titleIconType && (
                <div className={`${style['item-icon']} u-margin-right-sm`}>
                  <i className={`u-font-size-xxl icon-${iconType}`} />
                </div>
              )}
              <div className={`${style['item-content']} u-font-size-xl`}>
                {linkDriveMenu.title}
              </div>
              <div
                className={`${style['link-icon']} display-flex flex-column-center-center`}
              >
                <i className={`icon-${iconType} u-font-size-xxl`} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
