import { AccordionContent, List, ListItem, f7 } from 'framework7-react';
import { Dispatch, FC, SetStateAction } from 'react';
import style from './PrepaidSummaryStyle.module.scss';
import creditAmex from '@/assets/images/menu/icon-credit-amex.svg';
import creditDiners from '@/assets/images/menu/icon-credit-diners.svg';
import creditJcb from '@/assets/images/menu/icon-credit-jcb.svg';
import creditMaster from '@/assets/images/menu/icon-credit-master.svg';
import creditVisa from '@/assets/images/menu/icon-credit-visa.svg';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { Houseprepaid } from '@/types/api/prepaidApi';
import { PrepaidData } from '@/types/payment';
import { generatePrepaidName } from '@/utils/shop';
import { storeDispatch } from '@/utils/store';

type PrepaidSummaryProps = {
  setPrepaidData?: Dispatch<SetStateAction<PrepaidData>>;
  housePrepaid: Houseprepaid[] | undefined;
};

export const PrepaidSummary: FC<PrepaidSummaryProps> = ({
  setPrepaidData,
  housePrepaid,
}) => {
  const prepaidName = store.state.customerInfo?.affiliate_prepaid_name;
  const description = {
    paragraph1: `いずれ必ず費用がかかる車検などに備えて、毎月定額で積立を行うことができます。さらに、積立金額やその他条件に応じて、ボーナスも付与されます。`,
    paragraph2: `おトクで賢いカーライフにピッタリなプリペイドサービスをぜひご活用ください。`,
    emphasis: '積立金額を自由に設定可能',
    supplement1: '※最低積立金額は1,000/月です',
    supplement2: '※1,000円単位で設定できます',
  };
  const questionList = [
    {
      title: '毎月の引落は何日ですか？',
      content:
        '口座引落の場合、毎月27日がお引落日です。\n' +
        '銀行休業日にあたる場合は翌営業日のお引落となります。\n' +
        'クレジットカードをご利用の場合は、お客様とクレジットカード会社とのご契約に準じます。',
    },
    {
      title: '積立はいつから開始されますか？',
      content:
        'お申込みの翌月よりご請求が開始されます。\n' +
        '残高への反映は、さらに翌月15日となります。\n\n' +
        '例）1月にプリペイドサービス申込完了\n' +
        '　　→ 2月よりご請求開始\n' +
        '　　3月15日に残高へ反映',
    },
    {
      title: 'なんらかの事情により引落ができなかった場合はどうなりますか？',
      content:
        '2ヶ月続けてお引落ができなかった場合は、そのあとのご請求および積立を停止いたします。\n\n' +
        '例）月々3,000円の積立プランに加入\n　　1月の引落ができなかった場合\n' +
        '　　→ 1月：お引落なし（2月15日の残高加算は0円）\n' +
        '　　2月：3,000円ご請求\n' +
        '　　→ 引落ができた場合、3月15日に残高へ加算\n　　引落ができなかった場合、次月から請求（積立）停止',
    },
  ];

  const creditCardList = [
    creditVisa,
    creditMaster,
    creditJcb,
    creditAmex,
    creditDiners,
  ];

  const handleShowBankList = () => {
    f7.view.main.router.navigate(paths.availableBankList);
  };

  const handleClickReservings = () => {
    f7.view.main.router.navigate(paths.reservings, {
      props: { setPrepaidData },
    });
  };

  const removeRedirectPage = () => {
    window.localStorage.removeItem('paymentRedirectPage');
    window.localStorage.removeItem('reserve_amount');
    window.localStorage.removeItem('termListCheckbox');
    window.localStorage.removeItem('redirectCarWashAfterPayment');
    storeDispatch('setReserveAmount', 'reserveAmount', 0);
  };

  return (
    <PopupPageTemplate
      id="prepaid-summary"
      pageName="PrepaidSummary"
      containerClass={style['popup-container']}
      title={`${generatePrepaidName(prepaidName)}`}
      onPopupClose={removeRedirectPage}
      closeByBackdropClick={false}
    >
      <div className={style.container}>
        <div className={style.description}>
          <p className={style['description-p']}>{description.paragraph1}</p>
          <p className={style['description-p']}>{description.paragraph2}</p>
        </div>

        <div className={style['prepaid-guide']}>
          <h2 className={style.title}>積み立て金額を自由に設定可能</h2>
          <p>※ 最低積み立て金額は1,000円/月です。</p>
          <p>※ 1,000円単位で設定できます。</p>
        </div>
        {/* <div className={style['plan-preview']}>
          <div className={style['plan-preview-block']}>
            <h2 className={style.title}>所要時間の目安</h2>
            <p>
              例：
              <br />
              5,000 円／月を積み立ての場合
            </p>
            <p className={style['color-danger']}>年 20,000 円おトク！</p>
          </div>
          <div className={style.divider} />
          <div className={style['plan-preview-block']}>
            <h2 className={style.title}>
              さらに！20,000円／月以上の積み立てで
            </h2>
            <p>リース残価生産時の実質負担を</p>
            <p className={style['color-danger']}>最大50万円サポート！</p>
          </div>
        </div> */}
        {/* <div className={style['plan-group']}>
          <h2 className={style.title}>選べる積み立てプラン</h2>
          {housePrepaid?.map((item, index) => (
            <Card key={index}>
              <CardContent>
                <List simple-list className="mypage-list">
                  <ListItem>
                    <div>
                      <span className="plan-title">{item.name}</span>
                    </div>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          ))}
          {(!housePrepaid || !housePrepaid?.length) && <Card />}
        </div> */}

        {/* 決済方法 */}
        <div className={style['settlement-method']}>
          <h3 className={style.title}>ご登録が可能な決済方法</h3>
          <p className={style.method}>クレジットカード</p>
          <div className={style['images-wrap']}>
            {creditCardList.map((creditCard, index) => (
              <div key={index}>
                <img className={style.image} src={creditCard} />
              </div>
            ))}
          </div>
          <p className={style.method}>銀行口座引落</p>
          <a className={style['gray-button']} onClick={handleShowBankList}>
            ご利用可能な銀行はこちら
          </a>
        </div>

        {/* よくある質問 */}
        <div className={style.question}>
          <h3 className={style.title}>よくある質問</h3>
          {/* accordion */}
          <List className={style['list-wrap']} accordion-list>
            {questionList.map((item, index) => (
              <ListItem
                className={style.list}
                accordionItem
                key={index}
                title={item.title}
              >
                <AccordionContent className={style['accordion-content']}>
                  <div className={style['accordion-block']}>{item.content}</div>
                </AccordionContent>
              </ListItem>
            ))}
          </List>
        </div>

        {/* 始めるボタン */}
        <div className={style['button-wrap']}>
          <Button
            text={`${generatePrepaidName(prepaidName)}をはじめる`}
            handleClickAction={handleClickReservings}
          />
        </div>
      </div>
    </PopupPageTemplate>
  );
};
