import { Router } from 'framework7/types';
import { FC } from 'react';
import { LinkDriveCalibrationConfirm } from './LinkDriveCalibrationConfirm/LinkDriveCalibrationConfirm';

interface LinkDriveCalibrationConfirmPageProps {
  f7router: Router.Router;
}

export const LinkDriveCalibrationConfirmPage: FC<
  LinkDriveCalibrationConfirmPageProps
> = ({ f7router }) => {
  return <LinkDriveCalibrationConfirm f7router={f7router} />;
};
