import { IconProps } from './type';

export const RepairReceptionIcon = ({
  width = '56',
  height = '57',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28.5" r="28" fill={color} fillOpacity="0.1" />
      <rect
        x="12.4976"
        y="15.0039"
        width="31"
        height="31"
        rx="1.5"
        stroke="#323232"
      />
      <path
        d="M14.0024 17.5C14.0024 16.9477 14.4502 16.5 15.0024 16.5H41.0024C41.5547 16.5 42.0024 16.9477 42.0024 17.5V22.5H14.0024V17.5Z"
        fill="#B1C7FC"
      />
      <path
        d="M14.0024 25.5H42.0024V43.5C42.0024 44.0523 41.5547 44.5 41.0024 44.5H15.0024C14.4502 44.5 14.0024 44.0523 14.0024 43.5V25.5Z"
        fill="#B1C7FC"
      />
      <rect
        x="19.5"
        y="11"
        width="4"
        height="8"
        rx="0.5"
        fill="#B1C7FC"
        stroke="#323232"
      />
      <rect
        x="32.5024"
        y="11"
        width="4"
        height="8"
        rx="0.5"
        fill="#B1C7FC"
        stroke="#323232"
      />
      <line
        x1="11.9976"
        y1="23.9961"
        x2="43.9976"
        y2="23.9961"
        stroke="#323232"
      />
      <circle cx="28.0024" cy="35" r="7.5" fill="white" stroke="#323232" />
      <path
        d="M24.5024 34L26.1999 36.5462C26.3626 36.7902 26.6993 36.8425 26.9283 36.6593L31.5024 33"
        stroke="#15A380"
        strokeLinecap="round"
      />
    </svg>
  );
};
