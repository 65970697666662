import { format } from 'date-fns';
import { Block, Col, Row } from 'framework7-react';
import parse from 'html-react-parser';
import { FC, useEffect, useState } from 'react';
import { DrivingDataServiceSettingsBox } from '@/components/projects/DrivingDataServiceSettingsBox/DrivingDataServiceSettingsBox';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { useServiceStart } from '@/hooks/api/linkDrive/useServiceStart';
import { useServiceStop } from '@/hooks/api/linkDrive/useServiceStop';
import {
  ServiceStartApiParams,
  ServiceStopApiParams,
} from '@/types/api/linkDriveApi';
import './DrivingDataAnalysisSettingsStyle.scss';
import { f7CustomBack } from '@/utils/utils';

interface DrivingDataAnalysisSettingProps {
  drivingAnalysisServiceAgree?: string;
}

export const DrivingDataAnalysisSettings: FC<
  DrivingDataAnalysisSettingProps
> = ({ drivingAnalysisServiceAgree }) => {
  const usableList = [
    {
      title: '安全運転スコアの受け取り',
      content:
        '運転記録画面に、お客様の運転データをもとにした安全運転スコアが表示されるようになります',
    },
    {
      title: '運転スコアを使った保険への加入',
      content:
        '安全運転スコアが高いほど保険料が安くなる保険に加入することができます',
    },
  ];
  // format → YYYYMMDD
  const formatedCurrentDate = format(new Date(), 'yyyyMMdd');
  const ownerId = store.state.ownerId;
  const stopServiceParams: ServiceStopApiParams = {
    owner_id: String(ownerId),
    device_cancel_date: formatedCurrentDate,
  };
  const startServiceParams: ServiceStartApiParams = {
    owner_id: String(ownerId),
  };

  const [isChecked, setIsChecked] = useState(false);
  const { stopService } = useServiceStop();
  const { startService } = useServiceStart();
  const handleClickCheckbox = () => setIsChecked((prevStatus) => !prevStatus);
  const handleClickChangeService = () => {
    if (isChecked)
      startService({
        params: startServiceParams,
        resolve: () => f7CustomBack(['#DrivingDataAnalysisSettings']),
      });

    if (!isChecked)
      stopService({
        params: stopServiceParams,
        resolve: () => f7CustomBack(['#DrivingDataAnalysisSettings']),
      });
  };

  useEffect(() => {
    const drivelogSendFlg = store.state.linkDriveOwnerInfo.drivelog_send_flg;
    if (drivelogSendFlg == 1) setIsChecked(true);
  }, []);

  return (
    <PopupPageTemplate
      pageName="DrivingDataAnalysisSettings"
      id="DrivingDataAnalysisSettings"
      title="安全運転分析サービス設定"
    >
      <Block className="last-block">
        <h2 className="u-font-size-xl u-padding-bottom-xs">
          安全運転分析サービスとは
        </h2>
        <div>
          運転ログを利用して安全運転診断を行い、スコアをお客様にフィードバックするサービスです。
        </div>
        <div>スコアを利用した保険にもご加入いただけます。</div>

        <h2 className="u-font-size-xl u-padding-bottom-xs u-padding-top-sm">
          分析をするとできるようになること
        </h2>
        <div className="ad-card_usable_list">
          <ul className="u-padding-left-sm">
            {usableList.map((item, index) => (
              <li key={index} className="u-padding-top-xs u-padding-bottom-xs">
                <div className="u-font-bold u-font-size-lg">{item.title}</div>
                <div>{item.content}</div>
              </li>
            ))}
          </ul>
        </div>

        <h2 className="u-font-size-xl u-padding-bottom-xs u-padding-top-sm">
          分析の際に送るデータ
        </h2>
        <div>お客様の運転ログをあいおいニッセイ同和損保に共有します。</div>
        <div>
          共有される運転ログには時間情報、位置情報、運転速度情報、急ブレーキ情報、急加速情報が含まれます。
        </div>

        <div
          className="checkbox-list u-margin-top-md u-margin-bottom-xs"
          id="checkbox-list01"
        >
          <div
            onClick={handleClickCheckbox}
            className={`${
              isChecked && 'isChecked'
            } u-margin-bottom-sm list-item list-item01`}
          >
            <div className="list-item-checkbox">
              <input
                id="checkbox"
                type="checkbox"
                checked={isChecked}
                disabled
              />
              <label htmlFor="checkbox" aria-disabled />
            </div>
            <div className="list-item-content">
              <div className="u-font-size-lg">
                安全運転分析サービスを利用する
              </div>
              <div>あいおいニッセイ同和損保にデータを送る</div>
            </div>
          </div>
          <DrivingDataServiceSettingsBox>
            {!!drivingAnalysisServiceAgree && (
              <div className="container">
                {parse(drivingAnalysisServiceAgree)}
              </div>
            )}
          </DrivingDataServiceSettingsBox>
        </div>

        <Row style={{ marginTop: '28rem' }}>
          <Col width="25" />
          <Col width="50">
            <Button
              text="変更する"
              handleClickAction={handleClickChangeService}
              style={{
                minWidth: '200px',
                transform: 'translateX(-12%)',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                marginTop: '0',
              }}
            />
          </Col>
          <Col width="25" />
        </Row>
      </Block>
    </PopupPageTemplate>
  );
};
