import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { updateEmailApi } from '@/api/customerApi';
import { useToast } from '@/hooks/useToast';
import {
  UpdateEmailApiParams,
  UpdateEmailApiResponse,
} from '@/types/api/customerApi';

interface Args {
  params: UpdateEmailApiParams;
  onSuccess?: (data: UpdateEmailApiResponse['data']) => void;
  onFailure?: () => void;
}

export const useUpdateEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [data, setData] = useState<UpdateEmailApiResponse['data']>();
  const { openToast } = useToast();
  const updateEmail = async (args: Args) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const { data } = await updateEmailApi(args.params);
      if (data.success) args.onSuccess?.(data);
      if (!data.success) args.onFailure?.();
    } catch (error) {
      setError(error as AxiosError);
      openToast('エラーが発生しました', 'toast-failed');
    } finally {
      f7.preloader.hide();
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    updateEmail,
  };
};
