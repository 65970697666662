import { NavTitle, Navbar, Page } from 'framework7-react';
import parse from 'html-react-parser';
import { FC } from 'react';
import style from './PrivacyPolicyPcStyle.module.css';

interface PrivacyPolicyPcProps {
  html?: string;
}
export const PrivacyPolicyPc: FC<PrivacyPolicyPcProps> = ({ html }) => {
  if (!html) return <></>;
  return (
    <Page name="PrivacyPC">
      <Navbar className={style['navbar']}>
        <NavTitle className={style['nav-title']}>プライバシーポリシー</NavTitle>
      </Navbar>
      <div className={style['content']}>
        <div className="content-box">
          <div className={style['text-html']}>{parse(html)}</div>
        </div>
      </div>
    </Page>
  );
};
