import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchHouseprepaidApplication } from '@/api/prepaidApi';
import {
  HouseprepaidApplicationParams,
  HouseprepaidApplicationResponse,
} from '@/types/api/prepaidApi';
import { storeDispatch } from '@/utils/store';

export const useHouseprepaidApplication = (
  params: HouseprepaidApplicationParams,
) => {
  // const [params] = useState(initialParams);
  const [data, setData] = useState<HouseprepaidApplicationResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchHouseprepaidApplication(params);
        setData(res.data);

        if (res.data.success) {
          const houseprepaids = res.data.houseprepaids;
          if (houseprepaids && houseprepaids.length > 0) {
            await storeDispatch(
              'setPrepaidPlanList',
              'prepaidPlanList',
              houseprepaids,
            );
          } else {
            storeDispatch('setPrepaidPlanList', 'prepaidPlanList', []);
          }
        }
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [params]);

  return {
    data,
    isLoading,
    error,
  };
};
