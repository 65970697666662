import { Router } from 'framework7/types';
import { ImpactInfoPage } from '@/components/pages/ImpactInfo/ImpactInfoPage';
import { paths } from '@/config/paths';

export const otherRoutes: Router.RouteParameters[] = [
  //　衝撃検知
  {
    path: paths.impactInfo,
    popup: {
      component: ImpactInfoPage,
    },
    name: '衝撃検知',
  },
];
