import { Block } from 'framework7-react';
import React, { FC } from 'react';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';

export const EvaluationDetails: FC = () => {
  return (
    <PopupPageTemplate pageName="evaluation" title="アラートの判定基準">
      <Block>
        <h3>判定条件は以下の通りです。</h3>
        <p>
          速度超過：一定区間の平均速度が道路種別ごとに設定された速度基準を超えた場合
        </p>
        <p>
          急アクセル・急ブレーキ :
          「1秒間に約10km/ｈ以上の加速・減速」を基準として急アクセル・急ブレーキを検知した場合
        </p>
      </Block>
    </PopupPageTemplate>
  );
};
