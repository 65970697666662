import { Router } from 'framework7/types';
import { Dispatch, FC, SetStateAction } from 'react';
import { LinkDriveCalibration } from './LinkDriveCalibration/LinkDriveCalibration';

interface LinkDriveCalibrationProps {
  f7router: Router.Router;
  setIsCalibrationCompleted: Dispatch<SetStateAction<boolean>>;
}

export const LinkDriveCalibrationPage: FC<LinkDriveCalibrationProps> = ({
  f7router,
  setIsCalibrationCompleted,
}) => {
  return (
    <LinkDriveCalibration
      f7router={f7router}
      setIsCalibrationCompleted={setIsCalibrationCompleted}
    />
  );
};
