export const latestResult = {
  // TODO: 他のフラグ確認
  SUCCESS: '1',
} as const;

export const warrantyType = {
  // TODO: 値の意味が不明なため一旦命名は適当
  DEFECT: 1, // 瑕疵保証
  DEAL: 4, // 取引保証
} as const;

export const applyStatus = {
  NORMAL: 0,
  MIDTERM_CANCELLATION: 1,
  DURING_CANCELLATION_APPLICATION: 2,
  OUTSIDE_CANCELLATION_APPLICATION: 3,
  ALTERNATIVE: 4,
  REINSTATEMENT_APPLICATION: 5,
} as const;

export const warrantyCoverageType = {
  NOT_COVERED: 0,
  COVERED: 1,
};

export const PLAN_CHANGE_MONTH_BEFORE_EXPIRE = 2;

export const WARRANTY_STATUS = {
  EXPIRED: 1,
  VALID: 0,
};

export const UPLOADED_BY = {
  RETAILER: 1,
  CUSTOMER: 2,
};

export const STATUS_TRADE_GUARANTEE = {
  IN_PROGRESS: 1, // 申請中
  INCOMPLETE_DOCUMENT: 2, // 書類不備
  WITHDRAWN_APPLICATION: 3, // 申請取下げ
  UNDER_REVIEW: 4, // 審査中
  REVIEW_COMPLETED: 5, // 審査完了
  CONTINUE_USE_AFTER_REVIEW: 6, // 一次審査後利用継続
  REFUNDS_PROCESSED: 7, // 返車・返金手配中
  COMPLETED: 8, // 終了
};

// トラブル区分（０：なし（事前アップロード用）、１：車両諸元など提示情報不備、２：ボディ損傷、３：装備品取付不備、４：付属品不備、５：対象外）
export const ISSUE_CATEGORIES = {
  NONE: 0,
  VEHICLE_SPECIFICATION: 1,
  BODY_DAMAGE: 2,
  EQUIPMENT_INSTALLATION: 3,
  ACCESSORY_DEFICIENCY: 4,
  OTHER: 5,
};

export const DEAL_WARRANTY_RESULT = {
  PASSED: 1,
  REJECTED: 2,
};

export const PROCESS_KB = {
  RE_REGISTER_APPLICATION_INFORMATION: 1,
  WITHDRAW: 2,
  REQUEST_TO_RETURN: 3,
  CONTINUE_USE_AFTER_REVIEW: 4,
  CONFIRM_DELIVERY: 5,
};

export const SCREENING_RESULT_TYPE = {
  MAINTENANCE_DELIVERY: 1, // 整備入庫
  ACCESSORIES_DELIVERY: 2, // 付属品送付
  MAINTENANCE_DELIVERY_AND_ACCESSORIES_DELIVERY: 3, // 整備入庫＋付属品送付
  RETURN_CAR_SUPPORT: 4, // 返車対応
  OUT_OF_SCOPE: 5, // 対象外
};

export const WARRANTY_DOCUMENT_TYPE = {
  CONTRACT_PHOTO: 1, // 売買契約書
  CAR_INSPECTION_PHOTO: 2, // 車検証
  CONDITION_SHEET_PHOTO: 3, // コンディションシート
  EXTERIOR_PHOTO: 4, // 車両の外装写真
  INTERIOR_PHOTO: 5, // 車両の内装写真
  METER_PHOTO: 6, // メーター写真
};
