import perse from 'html-react-parser';
import { FC } from 'react';
import { TasksOptionType } from '../mockData';
import style from './TaskItemStyle.module.scss';

export const TaskItem: FC<TasksOptionType> = ({
  title,
  description,
  remark,
  listChecked,
  listImage,
}) => {
  return (
    <div className={style.container}>
      <h3 className="u-margin-top-0">{title}</h3>
      <p className={style.descreption}>{perse(description)}</p>
      {remark && <p className={style.remark}>{perse(remark)}</p>}
      <div className={style['check-list-wrapper']}>
        {listChecked.map((item, idx) => (
          <div key={idx} className={style['check-list']}>
            <i className={`icon-status-complete`} />
            <p>{item}</p>
          </div>
        ))}
      </div>
      <div className={style['img-wrapper']}>
        {listImage.map((srcImg, idx) => (
          <div key={idx} className={style['task-img']}>
            <img src={srcImg} alt="Car image" />
          </div>
        ))}
      </div>
    </div>
  );
};
