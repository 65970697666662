import parse from 'html-react-parser';
import { FC } from 'react';

import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import './DrivingAnalysisServiceAgreeStyle.scss';
import { f7CustomBack } from '@/utils/utils';
interface DrivingAnalysisServiceAgreeProps {
  html?: string;
}

const handleClickChangeService = () => {
  f7CustomBack(['#DrivingAnalysisServiceAgree']);
};

export const DrivingAnalysisServiceAgree: FC<
  DrivingAnalysisServiceAgreeProps
> = ({ html }) => {
  return (
    <PopupPageTemplate
      id="DrivingAnalysisServiceAgree"
      pageName="DrivingAnalysisServiceAgree"
      title="安全運転分析サービス設定"
    >
      <div className={`${'content'}`}>
        {!!html && <div className={`${'text-html'}`}>{parse(html)}</div>}

        <div className={`${'close-btn'}`}>
          <Button
            text="とじる"
            handleClickAction={handleClickChangeService}
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              marginTop: '0',
            }}
          />
        </div>
      </div>
    </PopupPageTemplate>
  );
};
