import { f7 } from 'framework7-react';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import { Paper } from '@/components/uiParts/Card';
import './TodoDetailStyle.scss';
import { paths } from '@/config/paths';

interface PromptCarCheckDeliveryProps {
  item?: any;
}

export const PromptCarCheckDelivery: FC<PromptCarCheckDeliveryProps> = ({
  item,
}) => {
  const handleClickNavigation = () => {
    f7.view.main.router.navigate(paths.deliveryCheckList, {
      props: { item },
    });
  };
  return (
    <div className="car-check">
      <Paper
        cardHeader="納車時チェックを行ってください。"
        wrapperCN="notice-title"
      >
        <p className="notice-date">2022年11月20日(金)</p>
        <p className="notice-content">
          実際の走行を開始される前にお車の状態を確認しましょう。
          <br />
          <br />
          お車のご利用にあたって最低限の重要な箇所をピックアップしております。
          <br />
          点検箇所や点検内容を記載しておりますので、ご参照いただきながらチェックを行ってください。
          <br />
          <br />
          ご不明なことや正常に作動しない箇所がございましたら、お車をご購入いただきました販売店までご連絡くださいませ。
        </p>
        <Button
          className="u-margin-top-lg"
          text="はじめる"
          onClick={handleClickNavigation}
        />
      </Paper>
    </div>
  );
};
