import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchInquiryListApi } from '@/api/inquiryApi';
import {
  FetchContactApiParams,
  FetchContactApiResponse,
} from '@/types/api/queryApi';
import { off, on } from '@/utils/eventHelper';
import { storeDispatch } from '@/utils/store';

export const useFetchContactList = ({
  m_customer_id,
}: FetchContactApiParams) => {
  const [data, setData] = useState<FetchContactApiResponse['data']>();
  const [error, setError] = useState<AxiosError>();

  const fn = async () => {
    f7.preloader.show();
    try {
      const res = await fetchInquiryListApi({ m_customer_id });
      setData(res.data);
      if (res.data.success) {
        const consultingHistoryList = res.data.data.inquiry_header.reverse();
        storeDispatch(
          'setConsultingHistoryList',
          'consultingHistoryList',
          consultingHistoryList,
        );
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  useEffect(() => {
    fn();

    //re-call by trigger
    const eventListListener = () => {
      fn();
    };
    on('fetchInquiryListApi', eventListListener);
    return () => {
      off('fetchInquiryListApi', eventListListener);
    };
  }, [m_customer_id]);

  return {
    data,
    error,
  };
};
