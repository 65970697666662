import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { UpdateCampaignParamsResponse } from '../../../types/api/prepaidApi.js';
import { customerInfoApi } from '@/api/customerApi';
import { updateCampaign } from '@/api/prepaidApi';
import { storeDispatch } from '@/utils/store';

export const useUpdateCampaign = (customerId: number) => {
  const [data, setData] = useState<UpdateCampaignParamsResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const updateCampaidnInformation = async (t_campaign_id: number) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const data = await updateCampaign({ t_campaign_id });
      const { data: customerInfo } = await customerInfoApi({
        m_customer_id: customerId,
      });
      if (!customerInfo.success) {
        throw new Error('基礎情報取得に失敗');
      }
      await storeDispatch(
        'setCampaignsInfo',
        'campaignsInfo',
        customerInfo.campaigns ?? [],
      );
      await storeDispatch(
        'setAdvancePaymentsInfo',
        'advancePaymentsInfo',
        customerInfo.prepaids,
      );
      setData(data.data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    updateCampaidnInformation,
    data,
    isLoading,
    error,
  };
};
