import { Router } from 'framework7/types';
import { FC } from 'react';
import { ConfirmTerms } from './ConfirmTerms/ConfirmTerms';
import { store } from '@/config/store';
import { useBasicInfo } from '@/hooks/api/info/useBasicInfo';
import { switchPrepaidName } from '@/utils/shop';

interface ConfirmTermsPageProps {
  f7router: Router.Router;
}

export const ConfirmTermsPage: FC<ConfirmTermsPageProps> = ({ f7router }) => {
  const customerId = store.state.authInfo.m_customer_id;
  const themeId = store.state.themeId;
  const prepaidName = switchPrepaidName(themeId);
  const { data, isMultiAffiliate } = useBasicInfo(customerId);
  return (
    <ConfirmTerms
      f7router={f7router}
      basicInfo={data}
      isMultiAffiliate={isMultiAffiliate}
      prepaidName={prepaidName}
    />
  );
};
