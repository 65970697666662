import { storeDispatch } from './store';
import { url } from '@/consts/url';

declare global {
  interface Window {
    onPushEnabled: (value: boolean) => void;
    onCameraPermissionEnabled: (value: boolean) => void;
    onGpsPermissionEnabled: (value: boolean) => void;
  }
}
/**
 * @description onPushEnabled implementation
 * @params value is the state of the push notification permission
 * Warning: onPushNotification uses storeDispatch,
 * when you import the file make sure your file has access to store.
 */
(<any>window).onPushEnabled = (value: boolean) => {
  storeDispatch('setPermission', 'permission', {
    pushPermission: value,
  });
  window.location.href = url.NATIVE_BASE_URL + '/permission/camera';
};

/**
 * @description onCameraPermission implementation
 * @params value is the state of the camera permission
 * Warning: onCameraPermissionEnabled uses storeDispatch,
 * when you import the file make sure your file has access to store.
 */
(<any>window).onCameraPermissionEnabled = (value: boolean) => {
  storeDispatch('setPermission', 'permission', {
    cameraPermission: value,
  });
  window.location.href = url.NATIVE_BASE_URL + '/permission/gps';
};

/**
 * @description onGpsPermissionEnabled implementation
 * @params value is the state of the gps permission
 * Warning: onGpsPermissionEnabled uses storeDispatch,
 * when you import the file make sure your file has access to store.
 */
(<any>window).onGpsPermissionEnabled = (value: boolean) => {
  storeDispatch('setPermission', 'permission', {
    gpsPermission: value,
  });
};

export default {};
