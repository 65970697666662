import { FC } from 'react';
import style from './DrivingAnalysisUsageAgreementStyle.module.css';

export const DrivingAnalysisUsageAgreement: FC = () => {
  return (
    <div className={style['container']}>
      <p>
        安全運転分析サービス（以下「本サービス」といいます）のご利用にあたっては、下記の注意事項をご確認いただき、お申し込みください。
      </p>
      <p>
        お客様に関する以下の個人情報およびLINKDrive
        Airの利用実績を、あいおいニッセイ同和損害保険株式会社（以下「提携保険会社」）へ提供・送信します。
      </p>
      <h4>１．送信情報の内容・項目</h4>
      <p>
        <span>・ 個人情報（氏名/車台番号/住所（都道府県・市区町村））</span>
      </p>
    </div>
  );
};
