import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useCallback, useEffect, useState } from 'react';
import { AccountRegistPayment } from './AccountRegistPayment/AccountRegistPayment';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useGmo } from '@/hooks/useGmo';
import { useLoadCustomer } from '@/hooks/useLoadCustomer';
import { Car } from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';

interface AccountRegistPaymentPageProps {
  f7router: Router.Router;
  status?: string;
  currentCarId: string;
  fromPage?: string;
}
export const AccountRegistPaymentPage: FC<AccountRegistPaymentPageProps> = ({
  f7router,
  status,
  currentCarId,
  fromPage = paths.account,
}) => {
  const router = f7.views.main.router;
  const { m_car_id, t_order_id } =
    (useStore(store, 'carItemInfo') as Car) || {};
  const [actionUrl, setActionUrl] = useState<string>('');
  const [fromPagePath, setFromPagePath] = useState<string>(fromPage);
  const [currentStatus, setCurrentStatus] = useState(status);
  const isInfoLoaded = useStore(store, 'getIsInfoLoaded') as boolean;
  const customerId = window.localStorage.getItem('m_customer_id');
  const token =
    window.localStorage.getItem('token') === null
      ? undefined
      : window.localStorage.getItem('token');

  const { addCreditCardPaymentMethod, GMOParameters } = useGmo(
    'account_payment_regist',
  );

  const { isLoggedIn } = useLoadCustomer(
    router,
    token === null ? undefined : token,
    Number(customerId),
    currentStatus,
    !status,
  );

  const handleClickBack = () => {
    router.navigate(fromPagePath, {
      transition: 'push-reverse',
    });
  };

  const handleClickWithdrawFromBank = () => {
    const {
      globalTheme,
      isLoggedInToMaikuru,
      loginInfo: { loginType },
    } = store.state;
    window.localStorage.setItem('m_car_id', String(m_car_id));
    currentCarId && window.localStorage.setItem('currentCarId', currentCarId);
    window.localStorage.setItem('loginType', String(loginType));
    window.localStorage.setItem('t_order_id', String(t_order_id));
    window.localStorage.setItem('globalTheme', `${globalTheme}`);
    window.localStorage.setItem('fromPage', fromPage);
    window.localStorage.setItem(
      'isLoggedInToMaikuru',
      `${isLoggedInToMaikuru}`,
    );
    router.navigate(paths.registerBank, {
      props: {
        redirectPage: 'account_payment_regist',
      },
    });
  };

  const handleClickCreditCardPayment = async () => {
    const { token, m_customer_id } = store.state.authInfo;
    const {
      globalTheme,
      isLoggedInToMaikuru,
      loginInfo: { loginType },
    } = store.state;
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('m_customer_id', m_customer_id.toString());
    window.localStorage.setItem('m_car_id', String(m_car_id));
    window.localStorage.setItem('loginType', String(loginType));
    window.localStorage.setItem('t_order_id', String(t_order_id));
    currentCarId && window.localStorage.setItem('currentCarId', currentCarId);
    window.localStorage.setItem('globalTheme', `${globalTheme}`);
    window.localStorage.setItem('fromPage', fromPage);
    window.localStorage.setItem(
      'isLoggedInToMaikuru',
      `${isLoggedInToMaikuru}`,
    );
    await addCreditCardPaymentMethod(setActionUrl);
    document.getElementById('submit')?.click();
  };

  // 一回目のクリックでactionUrlが更新されたらもう一度クリック
  useEffect(() => {
    document.getElementById('submit')?.click();
  }, [actionUrl]);

  const afterGmoRedirect = useCallback(async () => {
    // GMOからのリダイレクトに必要なパラメータ
    window.localStorage.removeItem('prepaid_registration_info');
    if (currentStatus) {
      storeDispatch('setMenu', 'menu', 'prepaid');
    }

    switch (currentStatus) {
      // GMOペイメント post →　maikuru バックエンド（api.mobicon.net）からのリダイレクト
      case 'gmo_success':
        if (isLoggedIn && isInfoLoaded) {
          setCurrentStatus(undefined);
          router.navigate(paths.payments, {
            props: {
              popupOpened: true,
            },
          });
        }
        break;
      case 'gmo_cancel':
        if (isLoggedIn && isInfoLoaded) {
          setCurrentStatus(undefined);
        }
        break;
      default:
        break;
    }
  }, [currentStatus, isLoggedIn, isInfoLoaded]);

  useEffect(() => {
    afterGmoRedirect();
  }, [afterGmoRedirect]);

  useEffect(() => {
    const storedFromPage = window.localStorage.getItem('fromPage');

    if (storedFromPage) {
      setFromPagePath(storedFromPage);
    }

    return () => {
      window.localStorage.removeItem('fromPage');
    };
  }, []);

  return (
    <AccountRegistPayment
      actionUrl={actionUrl}
      status={status}
      GMOParameters={GMOParameters}
      handleClickBack={handleClickBack}
      handleClickCreditCardPayment={handleClickCreditCardPayment}
      handleClickWithdrawFromBank={handleClickWithdrawFromBank}
    />
  );
};
