import { yupResolver } from '@hookform/resolvers/yup';
import { f7 } from 'framework7-react';
import { Router } from 'framework7/framework7-types';
import React, { FC } from 'react';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import style from './CarwashEditPageStyle.module.css';
import { DateTimeSelect } from './DateTimeSelect';
import { changePrefferedDateCarWash } from '@/api/reservationApi';
import { updateCarwash } from '@/components/pages/CarwashEdit/updateCarwash';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { f7CustomBack } from '@/utils/utils';

type CarwashEditPageProps = {
  f7router: Router.Router;
  id?: string;
  type?: string;
};

type FormInput = {
  datetime1?: string;
  datetime2?: string;
  datetime3?: string;
};

export const CarwashEditPage: FC<CarwashEditPageProps> = ({
  f7router,
  id,
  type = 'car_wash',
}) => {
  const formMethods = useForm<FormInput>({
    mode: 'onBlur',
    resolver: yupResolver(updateCarwash),
  });

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = formMethods;

  const handleSubmitEdit: SubmitHandler<FormInput> = async (data) => {
    f7.preloader.show();
    if (!data.datetime1) return;
    const rs = await changePrefferedDateCarWash({
      reservation_id: Number(id),
      service_type: type,
      preferred_datetimes: {
        datetime1: data.datetime1,
        datetime2: data.datetime2,
        datetime3: data.datetime3,
      },
    });

    if (rs.data.success) {
      f7CustomBack(['#carwashEdit']);
      f7.views.main.router.navigate(paths.carwashEditSuccess('edit'));
    } else {
      f7.dialog.alert(
        '',
        rs.data.error_message || '希望日の変更に失敗しました',
      );
    }
    f7.preloader.hide();
  };

  const datetime1 = watch('datetime1');

  return (
    <PopupPageTemplate id="carwashEdit" pageName="carwashEdit" title="日程変更">
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(handleSubmitEdit)}
          className={style['content-block']}
        >
          <div className={style['label']}>
            ご予約のご希望のお日にち・お時間を選択してください
          </div>
          <DateTimeSelect
            label="第一希望"
            name="datetime1"
            showError={!!datetime1 && !!errors.datetime1}
            required
          />
          <DateTimeSelect
            label="第二希望"
            name="datetime2"
            showError={!!errors.datetime2 && (!errors.datetime1 || !datetime1)}
          />
          <DateTimeSelect label="第三希望" name="datetime3" />
          <ul className={style['infor']}>
            <p>所要時間の目安</p>
            <li>1〜２時間ほど</li>
          </ul>
          <div className={style['btn-submit']}>
            <Button
              text="上記内容で再予約する"
              type="submit"
              disabled={!isValid}
            />
          </div>
        </form>
      </FormProvider>
    </PopupPageTemplate>
  );
};
