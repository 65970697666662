import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './AccidentStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import { affiliateId } from '@/consts/shop';

type AccidentProps = {
  f7router: Router.Router;
};

export const Accident: FC<AccidentProps> = ({ f7router }) => {
  const isrRoyalAutoId =
    store.state.customerInfo.m_affiliate_id === affiliateId.ROYAL;
  const handleClickCheckSolutions = () => {
    f7.view.main.router.navigate('');
  };

  const handleClickCallAmbulance = () => {
    window.location.href = 'tel://' + '119';
  };

  //Todo: vueで実装され次第更新
  const handleClickOpenMap = () => {};

  const handleClickCallPolice = () => {
    window.location.href = 'tel://' + '110';
  };

  // Todo: vueで実装され次第更新
  const handleClickCallInsuranceCompany = () => {
    window.open(
      'https://royal-auto-service.localinfo.jp/pages/4506652/shop',
      '_blank',
    );
  };
  const handleRedirectToContactInquiry = () => {
    window.open(
      'https://kobac-security.com/modolly.royal-auto/contact_inquiry.php',
      '_blank',
    );
  };

  const handleClickProvideQuote = () => {
    f7.dialog
      .create({
        title: '確認',
        text: 'このページでLINEを開きますか？',
        buttons: [
          {
            text: 'キャンセル',
          },
          {
            text: '開く',
            onClick: () => {
              window.open(
                'https://liff.line.me/1645278921-kWRPP32q/?accountId=726eafgj',
                '_blank',
              );
            },
          },
        ],
      })
      .open();
  };

  return (
    <PageTemplate
      pageName="Accident"
      title="事故を起こしてしまった"
      showNavBack
    >
      <div className={style.card}>
        <h3 className={style.heading}>
          私たちがサポートいたします。
          <br />
          安心してください。
        </h3>
        <p className={style.paragraph}>
          下記の手順に従って、
          <br />
          落ち着いて対応してください。
        </p>

        <ul className={style['list-wrap']}>
          <li className={style.list}>
            <h3 className={style['list-heading']}>
              <span className={style['heading-mark']}>1</span>安全の確保
            </h3>
            <p>
              二重事故や交通渋滞を避けるため、発煙筒や三角表示板を用いた危険防止阻止をしてください。
            </p>
            {/* <div className={style['gray-button-wrap']}>
              <Button
                className={style['gray-button']}
                text="方法を確認する"
                handleClickAction={handleClickCheckSolutions}
              />
            </div> */}
          </li>
          <li className={style.list}>
            <h3 className={style['list-heading']}>
              <span className={style['heading-mark']}>2</span>けが人の有無の確認
            </h3>
            <p>
              けが人がいる場合は、道路脇など安全な場所に移動して、救急車を呼んでください。
            </p>
            <div
              className={`${style['button-wrap']} ${style['button-bottom']}`}
            >
              <Button
                className={style.button}
                icon="icon-telephone"
                text="救急車を呼ぶ"
                handleClickAction={handleClickCallAmbulance}
              />
            </div>
            <p>
              ※現在地がわからない場合は、あらかじめマップアプリで確認しておくことをおすすめします
            </p>
            {/* <div className={style['gray-button-wrap']}>
              <Button
                className={style['gray-button']}
                text="マップアプリを開く"
                handleClickAction={handleClickOpenMap}
              />
            </div> */}
          </li>
          <li className={style.list}>
            <h3 className={style['list-heading']}>
              <span className={style['heading-mark']}>3</span>警察への連絡
            </h3>
            <p>
              あとでトラブルにならないよう、警察に連絡しましょう。自損事故や物損事故の場合も、車をぶつけたら警察に連絡が必要です。
            </p>
            <div className={style['button-wrap']}>
              <Button
                className={style.button}
                icon="icon-telephone"
                text="警察に電話する"
                handleClickAction={handleClickCallPolice}
              />
            </div>
          </li>
          <li className={style['list-button']}>
            <h3 className={style['list-heading']}>
              <span className={style['heading-mark']}>4</span>
              事故現場の記録と保存
            </h3>
            <p>
              写真や動画、メモでできる限り状況を記録し、保存しておきましょう。
            </p>
          </li>
          <li className={style['list-button']}>
            <h3 className={style['list-heading']}>
              <span className={style['heading-mark']}>5</span>
              相手との連絡先を交換
            </h3>
            <p>
              電話番号を聞き、必ずその場でつながるかの確認を行なっておきましょう。
              交渉は決して自分では行わず保険会社に任せましょう。
            </p>
          </li>
          <li className={style['list-button']}>
            <h3 className={style['list-heading']}>
              <span className={style['heading-mark']}>6</span>
              保険会社への連絡
            </h3>
            <p>
              保険会社のオペレーターが必要な対応策についてアドバイスをしてくれます。
              ロードサービスの手配もしてくれるので、必要に応じて依頼しましょう。
            </p>
            {isrRoyalAutoId && (
              <div className={style['button-wrap']}>
                <Button
                  className={style.button}
                  icon="icon-telephone"
                  text="保険会社に電話する"
                  handleClickAction={handleClickCallInsuranceCompany}
                />
              </div>
            )}
          </li>
          {isrRoyalAutoId && (
            <li className={style['list-button']}>
              <h3 className={style['list-heading']}>
                <span className={style['heading-mark']}>7</span>
                キズ・凹みの相談、簡単見積もり
              </h3>
              <p>
                事故ついたキズや凹みのご相談を承ります。お気軽にご相談ください。
              </p>
              <div className={style['button-wrap']}>
                <Button
                  className={style['gray-button']}
                  text="キズ・凹みの相談する"
                  handleClickAction={handleRedirectToContactInquiry}
                />
                <p>また、LINEで簡単にお見積もりをいたします。</p>
                <Button
                  className={style['gray-button']}
                  text="簡単見積もりをする"
                  handleClickAction={handleClickProvideQuote}
                />
              </div>
            </li>
          )}
        </ul>
      </div>
    </PageTemplate>
  );
};
