import { FC, useMemo } from 'react';
import { Confirmation } from './Confirmation/Confirmation';
import { PreStartConfirm } from './PreStartConfirmation/PreStartConfirmation';
import { TradeGuaranteeForm } from './TradeGuaranteeForm/TradeGuaranteeForm';
import { Upload } from './Upload/Upload';
import { UploadDocuments } from './UploadDocuments/UploadDocuments';
import {
  TRADE_GUARANTEE_STEPS,
  useTradeGuarantee,
} from './hooks/useTradeGuarantee';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';

export const TradeGuaranteePage: FC = () => {
  const {
    step,
    isConfirmed,
    detailData,
    warrantyDocument,
    uploadedDocument,
    selectedDocument,
    setStep,
    handleUpload,
    handleClickDocument,
    handleBack,
    handleSubmitStep1,
    handleSubmitStep2,
    handleSubmitStep3,
    handleSubmitStep4,
  } = useTradeGuarantee();

  const title = useMemo(() => {
    if (step === TRADE_GUARANTEE_STEPS.PRE_START_CONFIRMATION)
      return '取引保証申請';
    if (step === TRADE_GUARANTEE_STEPS.DETAIL_FORM) return '取引保証申請';
    if (step === TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS) return '取引保証申請';
    if (step === TRADE_GUARANTEE_STEPS.UPLOAD)
      return selectedDocument?.name ?? '';
    if (step === TRADE_GUARANTEE_STEPS.CONFIRMATION) return '申請内容の確認';
    return '';
  }, [step, selectedDocument]);

  return (
    <PageTemplateV2
      pageName="TradeGuarantee"
      title={title}
      showNavBack={true}
      handleClickBack={handleBack}
      containerBackgroundColor={
        [
          TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS,
          TRADE_GUARANTEE_STEPS.UPLOAD,
        ].includes(step)
          ? '#efefef'
          : '#FFFFFF'
      }
    >
      {step === TRADE_GUARANTEE_STEPS.PRE_START_CONFIRMATION && (
        <PreStartConfirm
          isConfirmed={isConfirmed}
          onSubmit={handleSubmitStep1}
        />
      )}
      {step === TRADE_GUARANTEE_STEPS.DETAIL_FORM && (
        <TradeGuaranteeForm
          handleBack={handleBack}
          onSubmit={handleSubmitStep2}
          defaultValues={detailData}
        />
      )}
      {step === TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS && (
        <UploadDocuments
          warrantyDocument={warrantyDocument}
          uploadDocument={uploadedDocument}
          onSubmit={handleSubmitStep3}
          handleClickDocument={handleClickDocument}
        />
      )}
      {step === TRADE_GUARANTEE_STEPS.UPLOAD && (
        <Upload
          handleUpload={handleUpload}
          setStep={setStep}
          uploadedDocument={uploadedDocument}
          selectedDocument={selectedDocument}
        />
      )}
      {step === TRADE_GUARANTEE_STEPS.CONFIRMATION && (
        <Confirmation
          detailData={detailData}
          warrantyDocument={warrantyDocument}
          uploadedDocument={uploadedDocument}
          setStep={setStep}
          onSubmit={handleSubmitStep4}
        />
      )}
    </PageTemplateV2>
  );
};
