import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { customerInfoApi } from '@/api/customerApi';
import {
  stopRestartHouseprepaidApi,
  changePrepaidMoneyApi,
} from '@/api/prepaidApi';
import { useToast } from '@/hooks/useToast';

import {
  StopRestartHouseprepaidParams,
  StopRestartHouseprepaidResponse,
  ChangePrepaidMoneyParams,
} from '@/types/api/prepaidApi';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

export const useHouseprepaidRestart = () => {
  const [data, setDate] = useState<StopRestartHouseprepaidResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const restartHouseprepaid = async (
    params: StopRestartHouseprepaidParams,
    chargeParams: ChangePrepaidMoneyParams,
  ) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const data = await stopRestartHouseprepaidApi(params);
      setDate(data.data);
      if (data.data.success) {
        await changePrepaidMoneyApi(chargeParams);
        const { data: basicInfo } = await customerInfoApi({
          m_customer_id: params.m_customer_id,
        });
        const { prepaids } = basicInfo;
        await storeDispatch(
          'setAdvancePaymentsInfo',
          'advancePaymentsInfo',
          prepaids,
        );
        openToast('積み立てを再開しました', 'toast-success', 3000, true, 'top');
        f7CustomBack(['#PrepaidRestart']);
      } else {
        openToast('失敗しました', 'toast-failed', 3000, true, 'top');
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    restartHouseprepaid,
    data,
    isLoading,
    error,
  };
};
