export const statusReservation = {
  TENTATIVE: 0,
  COMPLETED: 1,
  CONFIRMED: 2,
  CANCEL: 9,
  DELETE: 99,
  NOT_ACCEPT: 3,
} as const;

export const serviceType = {
  carwash: 'carwash',
  airconditionerCleaning: 'airconditioner_cleaning',
  repairWarranty: 'repair_warranty',
  maintenance: 'maintenance',
};

export const repairStatus = {
  ACCEPTED: '修理受付完了',
  ROAD_SERVICE_IN_ARRANGED: 'ロードサービス手配中',
  TRANSPORTATION_COMPLETED: '搬送完了',
  FACTORY_ARRIVAL_COMPLETED: '工場入庫完了',
  INSPECTION_UNDERWAY: '審査中',
  REPAIR_REQUEST_COMPLETED: '審査完了',
  COMPLETED: '修理完了',
  RESPONSE_COMPLETED: '対応完了',
};

export const identificationSubscribers: Record<number, string> = {
  1: '本人',
  2: '代理店',
  3: '販売店',
  4: '工場',
};

export const caller = {
  MOBICON: 1,
  MAIKURU: 2,
};

export const DEAL_WARRANTY_SYS_NO = {
  // 審査
  APPLICATION_RECEPTION: 'Dw_ApplicationReception', // 申請受付
  FIRST_SCREENING: 'Dw_FirstScreening', // 一次審査
  FACTORY_RECEPTION: 'Dw_FactoryReception', // 工場受付
  QUOTATION_INPUT: 'Dw_QuotationInput', // 見積入力
  SECOND_SCREENING: 'Dw_SecondScreening', // 二次審査
  DISCOUNT_METHOD_ASSESSMENT: 'Dw_DiscountMethodAssessment', // 減額方法判定
  SELECTION_OF_PARTS_TO_BE_USED: 'Dw_SelectionofPartstobeUsed', // 使用部品選定
  SCREENING_RESULTS_REPORT_FACTORY: 'Dw_ScreeningResultsReportFactory', // 審査結果報告・工場
  // 修理
  PARTS_ORDERING: 'Dw_PartsOrder', // 部品注文
  PARTS_DELIVERY_CONFIRMATION: 'Dw_PartsDeliveryConfirmation', // 部品納品確認
  // 支払
  COMPLETION_REPORT_CONFIRMATION: 'Dw_CompletionReportConfirmation', // 完了報告確認
  INVOICE_CONFIRMATION: 'Dw_InvoiceConfirmation', // 請求書確認
  // 完了
  COMPLETION: 'Dw_Completion', // 完了
};

// DEAL WARRANTY 保証対応中 group
export const DW_UNDER_WARRANTY_GROUP = [
  DEAL_WARRANTY_SYS_NO.QUOTATION_INPUT,
  DEAL_WARRANTY_SYS_NO.SECOND_SCREENING,
  DEAL_WARRANTY_SYS_NO.DISCOUNT_METHOD_ASSESSMENT,
  DEAL_WARRANTY_SYS_NO.SELECTION_OF_PARTS_TO_BE_USED,
  DEAL_WARRANTY_SYS_NO.SCREENING_RESULTS_REPORT_FACTORY,
  DEAL_WARRANTY_SYS_NO.PARTS_ORDERING,
  DEAL_WARRANTY_SYS_NO.PARTS_DELIVERY_CONFIRMATION,
];

// DEAL WARRANTY 修理完了 group
export const DW_UNDER_REPAIR_COMPLETED_GROUP = [
  DEAL_WARRANTY_SYS_NO.COMPLETION_REPORT_CONFIRMATION,
  DEAL_WARRANTY_SYS_NO.INVOICE_CONFIRMATION,
];
