import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Select, {
  CSSObjectWithLabel,
  GroupBase,
  SelectInstance,
  StylesConfig,
} from 'react-select';
import { Radio } from '../Partials/Radio/Radio';
import {
  BankAccountType,
  BankAccountTypeOptions,
  FormRegisterBankAccount,
  validationSchema,
} from '../Partials/validateSchema';
import style from './RegisterBankAccountPopUpStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { store } from '@/config/store';
import { useGetBanks } from '@/hooks/api/warranty/useGetBanks';
import { useGetBranches } from '@/hooks/api/warranty/useGetBranches';
import { DealWarrantyRefundAccountApiParams } from '@/types/api/warrantyApi';

interface RegisterBankAccountPopUp {
  opened: boolean;
  handleClose: () => void;
  repairReceptId?: number;
  dealWarrantyRefundAccount: (
    params: DealWarrantyRefundAccountApiParams,
  ) => Promise<void>;
}

interface Option {
  value: string;
  label: string;
  code: string;
  name: string;
}

const styles: StylesConfig<Option, false, GroupBase<Option>> = {
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    height: 44,
    borderRadius: 8,
    fontSize: 14,
    borderColor: '#C9C9C9',
    boxShadow: 'none',
    textAlign: 'left',
    '&:hover': {
      borderColor: '#C9C9C9',
    },
  }),
  placeholder: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: '#C9C9C9',
    fontSize: 14,
  }),
  valueContainer: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    paddingLeft: 12,
  }),
};

export const RegisterBankAccountPopUp: FC<RegisterBankAccountPopUp> = ({
  opened = false,
  handleClose,
  repairReceptId,
  dealWarrantyRefundAccount,
}) => {
  const { m_customer_id } = store.state.authInfo;
  const formMethods = useForm<FormRegisterBankAccount>({
    mode: 'onChange',
    defaultValues: {
      account_type: BankAccountType.NORMAL,
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setValue,
    watch,
    register,
    reset,
    formState: { isValid, errors },
  } = formMethods;
  const bank_code = watch('bank_code');
  const account_type = watch('account_type');
  const selectBankRef = useRef<SelectInstance<Option> | null>(null);
  const selectBranchRef = useRef<SelectInstance<Option> | null>(null);

  const { banks } = useGetBanks(opened);
  const { branches } = useGetBranches(bank_code, opened);

  const banksOptions =
    banks?.map((el) => {
      return { ...el, value: el.code, label: el.name };
    }) || [];

  const branchesOptions =
    branches?.map((el) => {
      return { ...el, value: el.code, label: `(${el.code}) ${el.name}` };
    }) || [];

  const handleClosePopUp = () => {
    reset();
    selectBankRef?.current?.clearValue();
    selectBranchRef?.current?.clearValue();
    handleClose();
  };

  const onSubmit = (data: FormRegisterBankAccount) => {
    const params = {
      ...data,
      m_customer_id,
      t_repair_recept_id: Number(repairReceptId),
    };
    dealWarrantyRefundAccount(params).then(() => {
      handleClosePopUp();
    });
  };

  return (
    <PopupTemplate
      opened={opened}
      title="銀行口座を登録"
      onPopupClose={handleClosePopUp}
      handleClose={handleClosePopUp}
      isCloseIcon={false}
      className={style['register-bank-account-container']}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={style['form-container']}
        >
          <i
            className={`${style['icon-close']} icon-close`}
            onClick={handleClosePopUp}
          />
          <h2 className={style['title-register']}>
            口座情報を選択してください
          </h2>

          <div className={style['input-group']}>
            <h4>
              金融機関名 <span className={style['input-label-tips']}>必須</span>
            </h4>

            <Select
              options={banksOptions}
              placeholder="銀行名を検索してください"
              noOptionsMessage={() => '一致する結果はありません'}
              isSearchable
              isClearable
              ref={selectBankRef}
              onChange={(el) => {
                setValue('bank_code', el?.code as string);
                setValue('bank_name', el?.name as string);
                setValue('branch_code', '');
                setValue('branch_name', '');
                selectBranchRef?.current?.clearValue();
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={styles}
            />
          </div>

          <div className={style['input-group']}>
            <h4>
              支店コード <span className={style['input-label-tips']}>必須</span>
            </h4>

            <Select
              options={branchesOptions}
              placeholder="支店コードを検索してください"
              noOptionsMessage={() => '一致する結果はありません'}
              isSearchable
              isClearable
              ref={selectBranchRef}
              onChange={(el) => {
                setValue('branch_code', el?.code as string);
                setValue('branch_name', el?.name as string);
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={styles}
            />
          </div>

          <div className={style['input-group']}>
            <h4>
              支店名 <span className={style['input-label-tips']}>必須</span>
            </h4>
            <input
              placeholder="支店名を検索してください"
              {...register('branch_name')}
              className={`${style['input-text']} ${
                errors?.branch_name?.message ? style['input-text-error'] : ''
              }`}
            />
            <div className={style['error-text']}>
              {errors?.branch_name?.message}
            </div>
          </div>

          <div className={style['input-group']}>
            <h4>
              口座種別 <span className={style['input-label-tips']}>必須</span>
            </h4>

            <div className={style['checkbox-radio-items']}>
              {BankAccountTypeOptions.map((item) => (
                <Radio
                  key={item.value}
                  id={`accountType-${item.value}`}
                  name="account_type"
                  checked={account_type === item.value}
                  value={item.value}
                  className={`${style['input-radio']} ${
                    account_type === item.value
                      ? style['input-radio-active']
                      : ''
                  }`}
                >
                  {item.text}
                </Radio>
              ))}
            </div>
          </div>

          <div className={style['input-group']}>
            <h4>
              口座番号 <span className={style['input-label-tips']}>必須</span>
            </h4>
            <input
              placeholder="例：123456789011"
              {...register('account_number')}
              className={`${style['input-text']} ${
                errors?.account_number?.message ? style['input-text-error'] : ''
              }`}
            />
            <div className={style['error-text']}>
              {errors?.account_number?.message}
            </div>
          </div>

          <div className={style['input-group']}>
            <h4>
              口座名義（カタカナ）
              <span className={style['input-label-tips']}>必須</span>
            </h4>
            <input
              placeholder="例：ヤマダ タロウ"
              {...register('account_holder')}
              className={`${style['input-text']} ${
                errors?.account_holder?.message ? style['input-text-error'] : ''
              }`}
            />
            <div className={style['error-text']}>
              {errors?.account_holder?.message}
            </div>
          </div>

          <Button
            type="submit"
            disabled={!isValid}
            className={style['register-bank-account-button']}
            text="追加する"
          />
        </form>
      </FormProvider>
    </PopupTemplate>
  );
};
