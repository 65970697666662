import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './RankBanner.module.scss';
import { paths } from '@/config/paths';
import { MAP_RANK_NAMES, Rank } from '@/consts/rank';
import { useRank } from '@/hooks/api/rank/useRank';

interface RankBannerProps {
  className?: string;
  redirectPage?: string;
}

export const RankBanner: FC<RankBannerProps> = ({
  className,
  redirectPage,
}) => {
  const { rankTheme, userRank } = useRank();

  const toRankPage = () => {
    f7.view.main.router.navigate(paths.rank, {
      props: { redirectPage },
    });
  };

  return rankTheme ? (
    <div className={`${style.banner} ${className}`} onClick={toRankPage}>
      <div className={style['text-wrap']}>
        <div className={style.text}>現在の会員ランク</div>
        <i className="icon-help-gray" />
      </div>
      <div className={style.rank} style={{ background: rankTheme.primary }}>
        <i className="icon-medal" />
        <span>{MAP_RANK_NAMES[userRank as Rank]}</span>
      </div>
    </div>
  ) : (
    <></>
  );
};
