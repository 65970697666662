import { FC } from 'react';
import { RoadServiceTerms } from './RoadserviceTerms/RoadserviceTerms';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const RoadServiceTermsPage: FC = () => {
  const { data } = useTerm(termType.ROAD_SERVICE);
  const html = data?.term;
  return (
    <PageTemplate
      pageName="RoadServiceTermsPage"
      title="ロードサービスご利用規約"
      showNavBack
    >
      <RoadServiceTerms html={html} />
    </PageTemplate>
  );
};
