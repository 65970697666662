import { f7 } from 'framework7-react';
import { FC } from 'react';
import { RankStepper } from 'src/components/pages/Rank/RankStepper/RankStepper';
import style from './Rank.module.scss';
import { BenefitIcon } from '@/components/pages/Rank/BenefitIcon/BenefitIcon';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { MAP_RANK_NAMES, Rank as RankEnum } from '@/consts/rank';
import { RankBenefit, RankTheme } from '@/types/api/rank';

interface RankProps {
  userRank?: RankEnum;
  currentTheme?: RankTheme;
  rankBenefits: RankBenefit[];
  redirectPage: string;
}

export const Rank: FC<RankProps> = ({
  currentTheme,
  userRank,
  rankBenefits,
  redirectPage,
}) => {
  return (
    <PopupPageTemplate
      id="Ranks"
      pageName="会員ランクについて"
      containerClass={style['popup-container']}
      title="会員ランクについて"
      iconType="close"
      closeByBackdropClick={false}
    >
      <div className={style.container}>
        <div
          className={style['rank-and-benefits']}
          style={{ background: currentTheme?.secondary }}
        >
          <div className={style['current-rank']}>
            <h3 className={style['current-rank-title']}>今月の会員ランク</h3>
            <div
              className={style['current-rank-medal']}
              style={{ background: currentTheme?.primary }}
            >
              <div className={style['icon-medal-custom']} />
              <span>{MAP_RANK_NAMES[userRank as RankEnum]}</span>
            </div>
            <p className={style['text-hint-small']}>
              <span>※&nbsp;</span>
              会員ランクは月1回、月初の3〜4営業日の間に更新されます。
            </p>
            {/* <div className={style['up-to-next-rank']}>
              <h3 className={style['up-to-next-rank-title']}>次のランクまで</h3>
              <div className={style['next-rank-progress']}>
                <div className={style['next-rank-progress-label']}>
                  来店回数あと
                  <span className={style['progress-number']}>0</span>回
                </div>
                <Progressbar
                  progress={30}
                  className={style['next-rank-progressbar']}
                />
              </div>
              <div className={style['next-rank-progress']}>
                <div className={style['next-rank-progress-label']}>
                  ご利用金額あと
                  <span className={style['progress-number']}>2</span>万円
                </div>
                <Progressbar
                  progress={70}
                  className={style['next-rank-progressbar']}
                />
              </div>
            </div> */}
          </div>
          {rankBenefits?.length > 0 && (
            <div className={style['rank-benefits']}>
              <div className={style['rank-benefits-title']}>ランク特典</div>
              {rankBenefits.map((rankBenefit, index) => (
                <div key={`benefit-${index}`} className={style.benefit}>
                  <div className={style['benefit-wrapper']}>
                    <div className="display-flex align-items-center">
                      <div className={style['benefit-icon']}>
                        <BenefitIcon
                          type={rankBenefit.type}
                          color={currentTheme?.primary}
                        />
                      </div>
                      <div>
                        <div className={style['benefit-title']}>
                          {rankBenefit.title}
                        </div>
                        <div className={style['benefit-content']}>
                          {rankBenefit.content}
                        </div>
                      </div>
                    </div>
                    {rankBenefit.annotation && (
                      <div className={style['benefit-annotation']}>
                        <span className={style['annotation-padding-right']}>
                          ※
                        </span>
                        {rankBenefit.annotation}
                      </div>
                    )}
                    {rankBenefit.links.length > 0 &&
                      rankBenefit.links.map((link, linkIndex) => (
                        <a
                          key={`link-${index}-${linkIndex}`}
                          onClick={() =>
                            f7.view.main.router.navigate(link.path, {
                              props: { redirectPage },
                            })
                          }
                          className={style['benefit-link']}
                        >
                          {link.text}
                        </a>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={style['current-rank-step']}>
          <h3 className={style['current-rank-step-title']}>お客様のランク</h3>
          <RankStepper currentRank={currentTheme} />
        </div>
        <div className={style['rank-note']}>
          <span>※&nbsp;</span>
          <span>
            お客様のご利用状況に応じて会員ランクが上がります。年一回(1月1日)ランクダウン判定を実施し、条件をクリアしていない場合ランクがダウンします。
          </span>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
