import { baseApi } from '@/config/axios';
import {
  CustomerInfoApiParams,
  CustomerInfoApiResponse,
  UpdateEmailApiParams,
  UpdateEmailApiResponse,
  UpdatePasswordApiParams,
  UpdatePasswordApiResponse,
} from '@/types/api/customerApi';
import {
  UpdateWarrantyCustomerParams,
  UpdateWarrantyCustomerResponse,
} from '@/types/api/warrantyApi';

// 5.基礎情報取得
export const customerInfoApi = (
  params: CustomerInfoApiParams,
): Promise<CustomerInfoApiResponse> =>
  baseApi.get('v1/customer/info', { params });

// 26. パスワード変更
export const updatePasswordApi = (
  params: UpdatePasswordApiParams,
): Promise<UpdatePasswordApiResponse> =>
  baseApi.put('v1/customer/password-change', { ...params });

// 75. メールアドレス変更処理
export const updateEmailApi = (
  params: UpdateEmailApiParams,
): Promise<UpdateEmailApiResponse> =>
  baseApi.put('v1/customer/email-change', { ...params });

// 21. お客様情報更新
export const updateCustomerApi = (
  params: UpdateWarrantyCustomerParams,
): Promise<UpdateWarrantyCustomerResponse> =>
  baseApi.put('v1/customer', params);
