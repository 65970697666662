import { f7 } from 'framework7-react';
import { FC, useState, useEffect, useRef } from 'react';
import { PopupLandscape } from '../PopupLandscape/PopupLandscape';
import { Button } from '@/components/uiParts/Button';
import { ContractDialog } from '@/components/uiParts/ContractDialog/ContractDialog';
import './ContractSignatureStyle.scss';
import { storeDispatch } from '@/utils/store';

export interface SignFormData {
  target: string;
  param: string;
  strokes: string;
  signText: string;
  imageData: string;
}

interface ContractSignatureProps {
  event?: (data: SignFormData) => void;
}

export const ContractSignature: FC<ContractSignatureProps> = ({ event }) => {
  const [showDialog, setShowDialog] = useState(false);
  const signIframeRef = useRef<HTMLIFrameElement>(null);

  const rotateBase64Image90Degree = (base64data: string) => {};
  const handleClear = () => {
    signIframeRef.current?.contentWindow?.sign?.clearAll?.();
  };

  const handleClickDialogClose = () => {
    setShowDialog(false);
  };

  const handleClickAction = () => {
    const data = signIframeRef.current?.contentWindow?.submit?.() as
      | SignFormData
      | undefined;
    if (data) {
      storeDispatch(
        'setContractSignature',
        'contractSignature',
        `data:image/png;base64,${data.imageData}`,
      );
      event?.(data);
      f7.popup.close('#ContractSignature');
    }
  };

  useEffect(() => {
    if (!window.screen?.orientation?.type?.includes('landscape')) {
      setShowDialog(true);
    }
  }, []);

  return (
    <>
      <PopupLandscape
        id="ContractSignature"
        title="電子署名_端末回転モーダル"
        pageName="ContractSignature"
      >
        <div className="signature-box">
          <h1 className="title">枠の中に署名をして、送信してください</h1>
          <div className="content">
            <iframe
              ref={signIframeRef}
              title="signForm"
              src="/files/sign.html"
            />
          </div>
          <div className="footer">
            <span className="footer-note" onClick={handleClear}>
              書き直す
            </span>
            <Button
              text="送信する"
              handleClickAction={handleClickAction}
              style={{ width: '20%' }}
            />
          </div>
        </div>
      </PopupLandscape>
      {showDialog && (
        <ContractDialog
          title="スマートフォンを横向きにして署名をしてください"
          handleClose={handleClickDialogClose}
        />
      )}
    </>
  );
};
