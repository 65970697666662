import { PaymentDetail } from '@/config/initialState';
import { paymentFlg as PAYMENT_FLG } from '@/consts/payment';
import {
  BillInformation,
  PaymentMethod,
  Prepaid,
  Warranty,
} from '@/types/api/customerApi';

export const getRegisterPaymentList = (
  paymentMethod: PaymentMethod[],
  advancePaymentsInfo: Prepaid[],
  billsInfo: BillInformation[],
  currentWarranty: Warranty | undefined,
  type: string,
  no: string,
  chargeFlg: string,
  isGmoSuccess?: boolean,
) => {
  const lastRegisteredPayment = paymentMethod.reduce((max, cur) => {
    const maxDate = new Date(max.register ?? '');
    const curDate = new Date(cur.register ?? '');
    return curDate > maxDate ? cur : max;
  }, paymentMethod[0]);
  const customPrepaid = {
    ...lastRegisteredPayment,
    payment_type_id: lastRegisteredPayment?.m_payment_type_id,
    payment_method_id: lastRegisteredPayment?.m_payment_method_id,
  };

  const paymentList: PaymentDetail[] = [];
  paymentMethod.forEach((el: PaymentMethod) => {
    let isSelected = false;
    let payment: PaymentDetail | undefined = undefined;
    const mPaymentTypeId = el.m_payment_type_id;
    const paymentTypeName = el.payment_type_name;
    const mPaymentMethodId = el.m_payment_method_id;
    const cardNo = el.card_no;
    const bankName = el.bank_name;
    const accountNumber = el.account_number;
    const register = el.register;
    let warrantyNo;
    let noPayFlg;
    let paySubject;
    let billInfo: BillInformation | undefined;
    let paymentFlg: number | undefined = undefined;
    switch (type) {
      case 'warranty':
        billInfo = billsInfo.find((x: BillInformation) => x.warranty_no == no);
        paymentFlg = PAYMENT_FLG.WARRANTY;
        break;
      case 'prepaid':
        billInfo = billsInfo.find(
          (x: BillInformation) => x.m_prepaid_id == Number(no),
        );
        paymentFlg = PAYMENT_FLG.PREPAID;
        break;
      default:
        break;
    }

    if (billInfo) {
      const today = new Date();
      const year = today.getFullYear().toString();
      const m = today.getMonth() + 1;
      const month = m.toString();
      const todayYM = Number(year + month);
      const billInformationDetails =
        billInfo.bill_infomation_details?.slice(-1)[0];
      if (Number(billInformationDetails?.bill_month) > todayYM) {
        isSelected = !(
          billInformationDetails?.m_payment_type_id === mPaymentTypeId &&
          billInformationDetails?.m_payment_method_id === mPaymentMethodId &&
          paymentFlg !== undefined &&
          billInformationDetails?.prepaid_flg === paymentFlg
        );
      }
      warrantyNo = billInfo.warranty_no;
      noPayFlg = billInformationDetails?.no_pay_flg;
      paySubject = billInformationDetails?.pay_subject;
    }

    isSelected = isSelectedPayment(
      isSelected,
      type,
      currentWarranty,
      isGmoSuccess ? customPrepaid : advancePaymentsInfo?.[0],
      mPaymentTypeId,
      mPaymentMethodId,
      chargeFlg,
    );

    payment = {
      paymentTypeId: mPaymentTypeId ?? 0,
      paymentTypeName: paymentTypeName ?? '',
      paymentMethodId: mPaymentMethodId ?? 0,
      bankName: bankName ?? '',
      accountNumber: accountNumber ?? '',
      cardNo: cardNo ?? '',
      warrantyNo: warrantyNo ?? '',
      noPayFlg: noPayFlg ?? 0,
      isSelected,
      paySubject: paySubject ?? '',
      register: register ?? '',
    };
    paymentList.push(payment);
  });
  return paymentList;
};

const isSelectedPayment = (
  isSelected: boolean,
  type: string,
  warranty: Warranty | undefined,
  prepaid: Prepaid | undefined,
  paymentTypeId: number | undefined,
  paymentMethodId: number | undefined,
  chargeFlg: string,
) => {
  if (!!chargeFlg) return false;
  let result = isSelected;
  switch (type) {
    case 'warranty':
      if (warranty) {
        result =
          warranty.payment_type_id === paymentTypeId &&
          warranty.payment_method_id === paymentMethodId;
      }
      break;
    case 'prepaid':
      if (prepaid) {
        result =
          prepaid.payment_type_id === paymentTypeId &&
          prepaid.payment_method_id === paymentMethodId;
      }
      break;
    default:
      break;
  }
  return result;
};
