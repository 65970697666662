import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './emailCompleteStyle.module.css';
import { ReactComponent as EmailIcon } from '@/assets/images/login/icon-email.svg';
import { LoginPageTemplate } from '@/components/uiParts/Template/LoginPageTemplate/LoginPageTemplate';
import { UnderLineLink } from '@/components/uiParts/UnderLineLink/UnderLineLink';
import { paths } from '@/config/paths';

interface EmailCompleteProps {
  f7router: Router.Router;
  email: string;
}
export const EmailComplete: FC<EmailCompleteProps> = ({ f7router, email }) => {
  return (
    <LoginPageTemplate
      pageName="EmailComplete"
      title="認証用のメールを送信しました"
      showNavBack
    >
      <div className={style['container']}>
        <div className={style['done-tips']}>
          {`${email}\nにメールを送信しました`}
        </div>
        <div className={style['icon-box']}>
          <EmailIcon />
        </div>
        <div className={style['desc-box']}>
          <h3 className={style['desc']}>
            23時間以内に、メールに記載されたURLを開いてメールアドレスの認証を完了させてください
          </h3>
          <p>
            {`※認証が完了するまで、メールアドレスは\n連絡先としてご利用いただけません`}
          </p>
        </div>
        <UnderLineLink
          f7router={f7router}
          path={paths.resendEmail}
          text="メールが届かない"
        />
      </div>
    </LoginPageTemplate>
  );
};
