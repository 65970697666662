import { Router } from 'framework7/types';
import { FC } from 'react';
import { DriveRecord } from './DriveRecord/DriveRecord';

interface DriveRecordPageProps {
  f7router: Router.Router;
}

export const DriveRecordPage: FC<DriveRecordPageProps> = ({ f7router }) => {
  return <DriveRecord f7router={f7router} />;
};
