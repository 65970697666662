import { FC } from 'react';
import { BillingScheduleDetail } from './BillingScheduleDetail/BillingScheduleDetail';
import { paymentTypeId } from '@/consts/payment';
import { BillInformationDetail } from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';
import { hyphenToYear } from '@/utils/date';
import {
  createPaymentMethodText,
  isBankOrPayEasy,
  switchPaymentIcon,
} from '@/utils/payment';

interface BillingScheduleDetailPageProps {
  billDetail: BillInformationDetail;
}
export const BillingScheduleDetailPage: FC<BillingScheduleDetailPageProps> = ({
  billDetail,
}) => {
  const billingDate = hyphenToYear(billDetail.billing_date || '', false);
  const paymentIconClass = switchPaymentIcon(
    billDetail.m_payment_type_id as PaymentTypeId,
  );
  const list = [
    {
      label: '請求対象',
      value: billDetail.pay_subject,
    },
    {
      label: '請求金額',
      value: `${billDetail.bill_price?.toLocaleString()} 円`,
    },
    {
      label: '決済方法',
      value: (
        <div style={{ display: 'flex', gap: '4px' }}>
          <i className={paymentIconClass} />
          <p>
            {createPaymentMethodText(
              billDetail.m_payment_type_id,
              billDetail.bank_name,
              billDetail.account_number,
              billDetail.card_no,
            )}
          </p>
        </div>
      ),
    },
    {
      label: isBankOrPayEasy(billDetail.m_payment_type_id)
        ? '振込期限'
        : '請求予定日',
      value: billingDate,
    },
    {
      label: '備考',
      value: billDetail.memo,
    },
  ];
  const isShowCard = billDetail.m_payment_type_id === paymentTypeId.BANK;
  return (
    <BillingScheduleDetail
      list={list}
      billingDate={billingDate}
      isShowCard={isShowCard}
    />
  );
};
