import { f7 } from 'framework7-react';
import { useState } from 'react';
import { fetchMAffiliateSettingsApi } from '@/api/infoApi';
import { getPrepaidChargeParameter } from '@/api/prepaidApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { endpoint } from '@/consts/api';

export const useChargePrepaid = (previousPage?: string) => {
  const authInfo = store.state.authInfo;
  const displayPayment = store.state.prepaidChargePayment;
  const mAffiliateId = store.state.customerInfo.m_affiliate_id;
  const prepaidsInfo = store.state.advancePaymentsInfo[0];

  const [GMOParameters, setGMOParameters] = useState({
    ShopID: '',
    OrderID: '',
    Amount: 0,
    Tax: 0,
    SiteID: '',
    SitePass: '',
    ShopPass: '',
    DateTime: '',
    ShopPassString: '',
    JobCd: 'CAPTURE',
    MemberID: '',
    MemberPassString: '',
    UseCredit: '1',
    RetURL: `${endpoint.MAIKURU_API}/v1/gmo-payment/cardresult/${
      previousPage || 'charge_prepaid'
    }`,
    CancelURL: `${endpoint.MAIKURU_API}/${
      previousPage || 'prepaid'
    }/charge_prepaid_cancel/`,
  });

  const generateGMOParameters = async (amount: number) => {
    try {
      const { data: affiliateData } = await fetchMAffiliateSettingsApi({
        m_affiliate_id: mAffiliateId,
      });

      if (!affiliateData.success) {
        console.log('maffili api failed');
        return;
      }

      const { gmopg_site_id, gmopg_site_pass, gmopg_shop_id, gmopg_shop_pass } =
        affiliateData.data;

      const params = {
        m_payment_method_id: displayPayment.paymentMethodId,
        m_prepaid_id: prepaidsInfo.id,
        m_customer_id: authInfo.m_customer_id,
        amount,
        siteID: gmopg_site_id,
        sitePass: gmopg_site_pass,
        ShopID: gmopg_shop_id,
        ShopPass: gmopg_shop_pass,
      };

      const { data: chargeData } = await getPrepaidChargeParameter(params);

      const { MemberPassString, ShopPassString, DateTime, OrderID, MemberID } =
        chargeData.data;

      const missingParams = [
        !amount && '決済金額',
        !gmopg_site_id && 'サイトID',
        !gmopg_site_pass && 'サイトパス',
        !gmopg_shop_id && 'ショップID',
        !gmopg_shop_pass && 'ショップパス',
        !MemberPassString && '会員情報確認用パス',
        !ShopPassString && 'ショップ情報確認パス',
        !DateTime && '日時情報',
        !OrderID && 'オーダーID',
        !MemberID && '会員ID',
      ].filter((v) => v);

      if (missingParams.length > 0) {
        f7.dialog.alert(
          '',
          `請求情報に不備があります: ${missingParams.join(', ')}`,
        );
        return;
      }

      // GMOからのリダイレクト場所を設定
      const token = authInfo.token;
      const mCustomerId = authInfo.m_customer_id;
      window.localStorage.setItem('token', token);
      window.localStorage.setItem('m_customer_id', mCustomerId.toString());
      window.localStorage.setItem('destination_step', '2');
      window.localStorage.setItem('destination_page', paths.chargePrepaid);
      window.localStorage.setItem('charge_amount', amount.toString());
      window.localStorage.setItem('m_prepaid_id', prepaidsInfo?.id.toString());

      setGMOParameters((prev) => ({
        ...prev,
        Amount: amount,
        SiteID: gmopg_site_id,
        SitePass: gmopg_site_pass,
        ShopID: gmopg_shop_id,
        ShopPass: gmopg_shop_pass,
        MemberPassString,
        ShopPassString,
        DateTime,
        OrderID,
        MemberID,
      }));
    } catch (error) {
      console.error('GMOパラメータの生成に失敗しました', error);
    }
  };

  return {
    GMOParameters,
    generateGMOParameters,
  };
};
