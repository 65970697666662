import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { customerInfoApi } from '@/api/customerApi';
import { paymentMethodChangeApi } from '@/api/warrantyApi';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import { PaymentMethodChangeParams } from '@/types/api/warrantyApi';
import { storeDispatch } from '@/utils/store';

export const usePaymentMethodChange = () => {
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const changePaymentMethod = async (params: PaymentMethodChangeParams) => {
    try {
      f7.preloader.show();
      const res = await paymentMethodChangeApi(params);
      if (res.data.success) {
        const params2 = { m_customer_id: store.state.customerInfo.id };
        const customerInfoResponse = await customerInfoApi(params2);
        if (params.warranty_no) {
          const carId = store.state.carItemInfo.m_car_id;
          const car = customerInfoResponse.data.cars?.find(
            (c) => c.m_car_id === carId,
          );
          if (car) {
            storeDispatch(
              'setCarsInfo',
              'carsInfo',
              customerInfoResponse.data.cars,
            ),
              storeDispatch('setCarItemInfo', 'carItemInfo', car),
              storeDispatch('setWarranties', 'warranties', car?.warranties);
            storeDispatch(
              'setNextWarranties',
              'nextWarranties',
              car?.next_warranty,
            );
            storeDispatch(
              'setCurrentWarranty',
              'currentWarranty',
              car?.warranties?.[0],
            );
            storeDispatch(
              'setNextWarranty',
              'nextWarranty',
              car?.next_warranty?.[0],
            );
          }
        }
        await storeDispatch(
          'setPaymentMethodsInfo',
          'paymentMethodsInfo',
          customerInfoResponse.data.payment_methods,
        );
        await storeDispatch(
          'setBillsInfo',
          'billsInfo',
          customerInfoResponse.data.bill_infomations,
        );
        await storeDispatch(
          'setAdvancePaymentsInfo',
          'advancePaymentsInfo',
          customerInfoResponse.data.prepaids,
        );
        openToast('決済方法を変更しました。');
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    changePaymentMethod,
    error,
  };
};
