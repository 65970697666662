import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export const TEL1_MAX_LENGTH = 3;
export const TEL2_MAX_LENGTH = 4;
export const TEL3_MAX_LENGTH = 4;

const uniqueDateCheck: yup.TestFunction<string | undefined, {}> = (
  value,
  context,
) => {
  const { datetime1, datetime2, datetime3 } = context.parent;
  switch (context.path) {
    case 'preferredDatetimes.datetime1': {
      if (value === datetime2 || value === datetime3) {
        return !value;
      }
      return true;
    }
    case 'preferredDatetimes.datetime2': {
      if (value === datetime1 || value === datetime3) {
        return !value;
      }
      return true;
    }
    case 'preferredDatetimes.datetime3': {
      if (value === datetime1 || value === datetime2) {
        return !value;
      }
      return true;
    }
  }
  return false;
};

const validationSchema = yup.object().shape({
  preferredDatetimes: yup.object().shape({
    datetime1: yup.string().required().test(uniqueDateCheck),
    datetime2: yup.string().required().test(uniqueDateCheck),
    datetime3: yup.string().required().test(uniqueDateCheck),
  }),
  reservationHolder: yup
    .object()
    .shape({
      familyName: yup.string().required(),
      firstName: yup.string().required(),
      familyNameKana: yup
        .string()
        .required('フリガナは必須です')
        .matches(/^([ァ-ン]|ー)+$/, {
          message: '全角カタカナで入力してください',
        }),
      firstNameKana: yup
        .string()
        .required('フリガナは必須です')
        .matches(/^([ァ-ン]|ー)+$/, {
          message: '全角カタカナで入力してください',
        }),
      tel1: yup.string().required(),
      tel2: yup.string().required(),
      tel3: yup.string().required(),
    })
    .test('phoneNo', ({ tel1, tel2, tel3 }) => {
      const fixedRegex = /^0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}$/;
      const mobileRegex = /^(050|070|080|090)-\d{4}-\d{4}$/;
      if (fixedRegex.test(`${tel1}-${tel2}-${tel3}`)) return true;
      if (mobileRegex.test(`${tel1}-${tel2}-${tel3}`)) return true;
      return false;
    }),
  workspace: yup.object().shape({
    zipCode: yup
      .string()
      .required('郵便番号は必須です')
      .matches(/^[0-9]{3}-[0-9]{4}$/, {
        message: '郵便番号を正しい形式で入力して下さい',
      }),
    prefectures: yup.string().required('都道府県は必須です'),
    municipalities: yup.string().required('市区町村は必須です'),
    street: yup.string().required('町名番地は必須です'),
    workspaceMemo: yup.string(),
    workspaceType: yup.number().required(),
    parkingType: yup.number().required(),
  }),
});

export interface Step2FormData {
  preferredDatetimes: {
    datetime1?: string;
    datetime2?: string;
    datetime3?: string;
  };
  reservationHolder: {
    familyName?: string;
    firstName?: string;
    familyNameKana?: string;
    firstNameKana?: string;
    tel1?: string;
    tel2?: string;
    tel3?: string;
  };
  workspace: {
    zipCode?: string;
    prefectures?: string;
    municipalities?: string;
    street?: string;
    workspaceMemo?: string;
    workspaceType?: number;
    parkingType?: number;
  };
}

export const useCarWashConditionerStep2 = (initialState: Step2FormData) => {
  const formMethods = useForm<Step2FormData>({
    defaultValues: initialState,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  return {
    formMethods,
  };
};
