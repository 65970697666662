import { getMonth, getYear } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ListBox } from '../ListBox/ListBox';
import style from './totalScoreStyle.module.css';
import linkDriverAirImage from '@/assets/images/accumulate_driving_data_link_drive_air_img.png';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { State } from '@/config/initialState';
import { specialContractType } from '@/consts/contract';
import { AdInsuranceDiscount } from '@/types/api/linkDriveApi';
import {
  formatToJaDate,
  getEndDateSomeMonthsAgo,
  getStartDateSomeMonthsAgo,
} from '@/utils/date';

interface TotalScoreProps {
  linkDriveOwner: State['linkDriveOwnerInfo'];
  isPopupOpened: boolean;
  insuredDate: Date;
  calcScoreData?: AdInsuranceDiscount;
  contractionData?: string;
  handleChangeInsuredDate: (date: Date) => void;
  handlePopup: () => void;
}

export const TotalScore: FC<TotalScoreProps> = ({
  linkDriveOwner,
  isPopupOpened,
  insuredDate,
  calcScoreData,
  contractionData,
  handleChangeInsuredDate,
  handlePopup,
}) => {
  const initialTotalScoreInfo = useMemo(() => {
    return {
      total_driving_distance: 0,
      total_from_date: formatToJaDate(
        getStartDateSomeMonthsAgo(15, insuredDate),
      ),
      total_to_date: formatToJaDate(getEndDateSomeMonthsAgo(4, insuredDate)),
      total_score: 0,
      discount_rate: '0%',
    };
  }, [insuredDate]);
  const specialContract = contractionData;
  const [isShowWarningMessage, setShowWarningMessage] = useState(false);
  const [date, setDate] = useState({
    year: getYear(insuredDate),
    month: getMonth(insuredDate) + 1,
  });
  const [totalScoreInfo, setTotalScoreInfo] = useState(initialTotalScoreInfo);

  const getWarningMessage = (): string => {
    let message = '';
    if (!calcScoreData) {
      message =
        '※保険契約に割引適用が可能なお客さまの走行データは存在しませんでした。';
    } else if (specialContract && specialContract != specialContractType.a) {
      message =
        '※表示されているデバイスキーで蓄積したお客さまの累計スコアデータは、既に保険契約へ割引適用されています。';
    } else if (totalScoreInfo.total_driving_distance < 500) {
      message =
        '※上記判定期間内に累計走行距離500㎞を満たすお客さまの走行データは存在しませんでした。';
    }
    return message;
  };

  const getDriveScore = (): string => {
    let score = '-';
    if (isShowWarningMessage) {
      // do nothing
    } else if (
      totalScoreInfo.total_score &&
      totalScoreInfo.total_driving_distance >= 500
    ) {
      score = String(totalScoreInfo.total_score);
    }
    return score;
  };

  const getDriveDistance = (): string => {
    return String(totalScoreInfo.total_driving_distance);
  };

  const getDriveDiscount = (): string => {
    let discount = '-%';
    if (isShowWarningMessage) {
      discount = '-%';
    } else if (
      totalScoreInfo.discount_rate &&
      totalScoreInfo.total_driving_distance >= 500
    ) {
      discount = String(totalScoreInfo.discount_rate);
    }
    return discount;
  };

  /**
   * @param month - 0~11
   */
  const handleClickMonthButton = (month: number) => {
    setDate({ ...date, month: month + 1 });
    handleChangeInsuredDate(new Date(date.year, month));
    handlePopup();
  };

  const shouldDisplayWarningMessage = useCallback(() => {
    const isDisplay =
      (specialContract && specialContract != specialContract) ||
      !calcScoreData ||
      calcScoreData.total_driving_distance < 500;
    setShowWarningMessage(isDisplay);
  }, [calcScoreData, specialContract]);

  useEffect(() => {
    shouldDisplayWarningMessage();
    if (!calcScoreData) {
      setTotalScoreInfo(initialTotalScoreInfo);
      return;
    }
    setTotalScoreInfo(calcScoreData);
  }, [
    calcScoreData,
    insuredDate,
    shouldDisplayWarningMessage,
    initialTotalScoreInfo,
  ]);

  return (
    <PopupPageTemplate pageName="TotalScore" title="累計スコア判定">
      <div className={style['container']}>
        <div className={style['my-16']}>
          <div className={`${style['flex-end']} ${style['bold']}`}>
            <span className={style['font-lg']}>
              {date.year}年{date.month}月
            </span>
            <span className={style['case']}>に保険に加入する場合</span>
            <i className="icon-help-blue" />
          </div>
          <div className={style['button-wrapper']}>
            <div className={style['button-box']}>
              <div className={style['box-icon-title-wrapper']}>
                <div className={style['icon-wrapper']}>
                  <i className="icon-calendar-schedule-blue" />
                </div>
                <div className={style['button-title']} onClick={handlePopup}>
                  加入年月を変更する
                </div>
              </div>
            </div>
          </div>
        </div>
        {isShowWarningMessage && (
          <div className={style['line-container']}>
            <span className={style['warning-message']}>
              {getWarningMessage()}
            </span>
          </div>
        )}
        <div
          className={`${style['line-container']} ${style['line-container-last']}`}
        >
          <h4>判定期間</h4>
          <span>
            {totalScoreInfo.total_from_date}〜{totalScoreInfo.total_to_date}
          </span>
        </div>
        <div className={style['result-container']}>
          <div>
            <h4 className="u-padding-bottom-xs u-font-bold">
              お客様の累積スコア
            </h4>
            <div className="u-padding-bottom-xs u-font-bold">
              <span className={style['blue-lg-text']}>{getDriveScore()}</span>点
            </div>
          </div>
          <div>
            <h4 className="u-padding-bottom-xs u-font-bold">
              お客様の累積走行距離
            </h4>
            <div className="u-padding-bottom-xs u-font-bold">
              <span className={style['blue-lg-text']}>
                {getDriveDistance()}
              </span>
              Km
            </div>
          </div>
        </div>
        <div className={style['line-container']}>
          <h4>運転特性割引</h4>
          <span className={style['blue-lg-text']}>{getDriveDiscount()}</span>
        </div>
        <div className={style['flex-box']}>
          <div>
            <h4 className="u-padding-top-xs u-padding-bottom-xs u-font-size-md u-font-bold">
              車台番号
            </h4>
            <span className={style['blue-md-text']}>
              {linkDriveOwner.vin || '-'}
            </span>
          </div>
          <div>
            <h4 className="u-padding-top-xs us-padding-bottom-xs u-font-size-md u-font-bold">
              デバイス区分
            </h4>
            <span className={style['blue-md-text']}>LD</span>
          </div>
        </div>
        <div className={style['mt-8']}>
          <h4 className="u-padding-top-xs u-padding-bottom-xs u-font-size-md u-font-bold">
            デバイスキー
          </h4>
          <span className={style['blue-md-text']}>
            {linkDriveOwner.device_key || '-'}
          </span>
        </div>
        <p className="u-font-bold u-font-size-xl u-padding-top-xs">注意事項</p>
        <div>
          <h3 className="u-padding-top-xs u-padding-bottom-xs u-font-size-md u-font-bold">
            【運転特性割引を適用するにあたって】
          </h3>
          <div className={style['list-wrapper']}>
            <ListBox>
              <div>
                <span>
                  提携保険会社（あいおいニッセイ同和損害保険株式会社）のテレマティクス自動車保険契約
                  <sup>（注1）</sup>
                  に加入する際には、本画面に表示されている「累計スコア」を告知いただく必要があります。本告知が漏れた場合、割引適用ができないため、忘れずに提携保険会社にお知らせください。合わせて、本画面に表示されている「デバイス区分」「デバイスキー」を提携保険会社にお知らせください。
                </span>
                <br />
                <span>
                  （注1）対象となるテレマティクス自動車保険については提携保険会社までお問合わせください。
                </span>
              </div>
            </ListBox>
            <ListBox>
              <div>
                既に同一のデバイスキーで蓄積した累計スコアに基づく運転特性割引を提携保険会社のテレマティクス自動車保険契約に適用している場合は、重複して他のテレマティクス自動車保険契約に割引適用を行うことはできません。
              </div>
            </ListBox>
          </div>
        </div>
        <div>
          <h3 className="u-padding-bottom-xs u-font-size-md u-font-bold">
            【注意事項等】
          </h3>
          <div className={style['list-wrapper']}>
            <ListBox>
              運転特性計測期間中の安全運転スコア（累計スコア）に応じて、提携保険会社のテレマティクス自動車保険契約に加入する際の、初年度契約（1年目）に運転特性割引を適用します。お車に装着したLink
              Drive
              Airで計測された運転特性情報をもとに安全運転スコアを算定し、初年度契約に適用する「運転特性割引」の割引率を決定します。
            </ListBox>
            <div className={style['score-image-wrapper']}>
              <div className={`${style['row']} ${style['row-title']}`}>
                <div className={style['row-div']}>安全運転スコア</div>
                <div className={style['row-div']}>区分</div>
                <div className={style['row-div']}>運転特性割引</div>
              </div>
              <div className={style['row']}>
                <div className={style['row-div']}>
                  <span className={style['row-a-item']}>80</span>
                  点以上
                </div>
                <div className={style['row-div']}>
                  <span className={`${style['row-a-item']}`}>A</span>
                  区分
                </div>
                <div className={`${style['row-div']}`}>
                  <span className={`${style['row-a-item']} row-a-item`}>8</span>
                  %割引
                </div>
              </div>
              <div className={style['row']}>
                <div className={style['row-div']}>
                  <span className={style['row-b-item']}>60~79</span>点
                </div>
                <div className={style['row-div']}>
                  <span className={style['row-b-item']}>B</span>
                  区分
                </div>
                <div className={style['row-div']}>
                  <span className={style['row-b-item']}>4</span>
                  %割引
                </div>
              </div>
              <div className={style['row']}>
                <div className={style['row-div']}>
                  <span className={style['row-c-item']}>59</span>
                  点以下
                </div>
                <div className={style['row-div']}>
                  <span className={style['row-c-item']}>C</span>
                  区分
                </div>
                <div className={style['row-div']}>割引なし</div>
              </div>
            </div>
            <div>※加入する保険商品によっては適用される割引率が異なります。</div>
            <div>
              安全運転スコアの区分がCの場合と比較した割引率です（割引率は現時点での内容であり、将来変更となる場合があります）。なお、一部の特約には割引が適用されないため、保険料全体に対する割引率とは一致しません。
            </div>
            <ListBox>
              <div>
                <div>
                  <span>
                    運転特性計測期間は、提携保険会社が運転特性割引を算出するために基準とする期間をいい、加入する保険契約の始期日が属する月の前月末日の3か月前から過去1年間
                  </span>
                  <sup>（注2）</sup>とします。
                </div>
                <span>
                  （注2）利用期間が1年に満たない場合もその他条件を満たす場合は割引適用が可能です。
                </span>
                <br />
                <span>
                  ※テレマティクス自動車保険に加入された後の次契約に適用される割引条件は異なります。詳細は提携保険会社にお問合わせください。
                </span>
              </div>
            </ListBox>
            <div>
              <div>
                {`< 運転特性計測期間イメージ（保険始期日が2023年10月の例）>`}
              </div>
              <div className={style['image-wrapper']}>
                <img
                  style={{ width: '100%' }}
                  src={linkDriverAirImage}
                  alt=""
                />
              </div>
            </div>
            <ListBox>
              <div>
                運転特性計測期間中にLink Drive
                Airで計測された有効走行距離が500km未満の場合、割引は適用されません。この場合、平均的な安全運転スコアの区分であるB区分と同等の保険料水準が適用されます
              </div>
            </ListBox>
            <ListBox>
              <div>
                <span>その他割引適用の条件</span>
                <div>
                  ・デバイス契約会員（データ送信の同意者）と割引適用をする保険契約の記名被保険者が同一であること。
                </div>
                <div>
                  ・デバイス契約の対象のお車と割引適用をする保険契約のお車が同一であること。（お車が異なる場合であっても割引適用が可能なケースがあります。詳細は提携保険会社にお問合わせください。）
                </div>
                <div>
                  ・その他の割引適用条件は提携保険会社にお問合わせください。
                </div>
              </div>
            </ListBox>
          </div>
        </div>
      </div>
      <PopupTemplate opened={isPopupOpened} title="カレンダー">
        <div className={style['picker-container']}>
          <div className={style['year-picker-container']}>
            <span
              className={style['year-change']}
              id="prev-year"
              onClick={() => {
                setDate({ ...date, year: date.year - 1 });
              }}
            >
              <i className="icon-left" />
            </span>
            <span className={style['year']}>{date.year}年</span>
            <span
              className={style['year-change']}
              id="next-year"
              onClick={() => {
                setDate({ ...date, year: date.year + 1 });
              }}
            >
              <i className="icon-right" />
            </span>
          </div>
          <div className={style['month-list']}>
            {[...Array(12)].map((_, i) => (
              <div key={i} onClick={() => handleClickMonthButton(i)}>
                {i + 1}月
              </div>
            ))}
          </div>
        </div>
      </PopupTemplate>
    </PopupPageTemplate>
  );
};
