import { f7 } from 'framework7-react';
import { useEffect } from 'react';
import { useWatchingServiceDriveMonthlyScore } from './api/driveRecord/useWatchingServiceDriveMonthlyScore';
import { useWatchingServiceEmailFirstLoginDecision } from './api/driveRecord/useWatchingServiceEmailFirstLoginDecision';
import { AffiliateId } from '@/consts/shop';
import {
  EmailFirstLoginDecisionApiParams,
  LoginApiParams,
} from '@/types/api/authApi';
import { addAffiliateIdToRequestParams } from '@/utils/shop';

export const useWatchingServiceMonthlyDriveReport = (
  ownerId: string,
  vehicleId: string,
  customerId: string,
  analysisFromDate: string,
  affiliateId: AffiliateId,
) => {
  // 認証処理
  // TODO: emailFirstLoginDecisionとloginを合わせたhookなので名前変える
  const { emailFirstLoginDecision } =
    useWatchingServiceEmailFirstLoginDecision();
  const { data, isNoData, isLoading, fetchMonthlyDriveReport } =
    useWatchingServiceDriveMonthlyScore();

  const monthlyDriveReportParams = {
    owner_id: Number(ownerId),
    vehicle_id: vehicleId,
    m_customer_id: Number(customerId),
    // 受け取った日付をハイフン区切りにしないとエラーが返ってくる
    analysis_from_date: analysisFromDate.replaceAll('-', '/'),
  };

  const initialLogin = () => {
    const login_id = 'monthly-report@aioinissaydowa.co.jp';
    const login_pass = 'B-3z%wt5PQ%4';
    const params: EmailFirstLoginDecisionApiParams = {
      email: login_id,
    };
    const loginParam: LoginApiParams = {
      login_id,
      login_pass,
    };
    addAffiliateIdToRequestParams(affiliateId, params);
    addAffiliateIdToRequestParams(affiliateId, loginParam);
    return emailFirstLoginDecision({
      params,
      loginParam,
    });
  };

  // 認証 + 月間安全運転レポート情報取得
  const getMonthlyDriveReport = async () => {
    f7.preloader.show();
    const isLogin = await initialLogin();
    if (!isLogin) return;
    await fetchMonthlyDriveReport(monthlyDriveReportParams);
  };

  useEffect(() => {
    void getMonthlyDriveReport();
  }, [getMonthlyDriveReport]);

  return {
    isLoading,
    isNoData,
    data,
  };
};
