import { AxiosError } from 'axios';
import { useState } from 'react';
import { changeVehicleApi } from '@/api/linkDriveApi';
import {
  ChangeVehicleApiParams,
  ChangeVehicleApiResponse,
} from '@/types/api/linkDriveApi';

export const useChangeVehicle = () => {
  const [data, setData] = useState<ChangeVehicleApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const changeVehicle = async (params: ChangeVehicleApiParams) => {
    try {
      setIsLoading(true);
      const { data } = await changeVehicleApi(params);
      setData(data);
      return data;
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    changeVehicle,
  };
};
