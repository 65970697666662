import { baseApi } from '@/config/axios';
import {
  DataParams,
  DataResponse,
  DrivingDayParams,
  DrivingDayResponse,
  DrivingRouteParams,
  DrivingRouteResponse,
  FetchStrikeSettingsApiParams,
  FetchStrikeSettingsApiResponse,
  HighlightParams,
  HighlightResponse,
  HistoryDetailParams,
  HistoryDetailResponse,
  HistoryParams,
  HistoryResponse,
  MonthlyCountParams,
  MonthlyCountResponse,
  MonthlyPhydReportParams,
  MonthlyPhydReportResponse,
  MonthlyReportListAPIParams,
  MonthlyReportListAPIResponse,
  PhydScoreParams,
  PhydScoreResponse,
  RapidAccelerationParams,
  RapidAccelerationResponse,
  RapidDecelerationParams,
  RapidDecelerationResponse,
  StrikeSettingApiParams,
  StrikeSettingApiResponse,
} from '@/types/api/driveRecordApi';
import { CalcScoreParams, CalcScoreResponse } from '@/types/api/linkDriveApi';
const API_V1 = 'v1/';

// 運転記録
export const fetchDriveRecordInfo = (
  params: HighlightParams,
): Promise<HighlightResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/highlight`, { params });

// 運転履歴
export const fetchDriveHistoryApi = (
  params: HistoryParams,
): Promise<HistoryResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/driving_history`, { params });

// 運転履歴詳細
export const fetchDriveHistoryDetailApi = (
  params: HistoryDetailParams,
): Promise<HistoryDetailResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/driving_history_detail`, {
    params,
  });

// 運転データ
export const fetchDriveDataApi = (params: DataParams): Promise<DataResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/data`, { params });

// 急ブレーキ回数
export const fetchDriveRapidDecelerationApi = (
  params: RapidDecelerationParams,
): Promise<RapidDecelerationResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/rapid_deceleration`, {
    params,
  });

// 安全運転スコア
export const fetchDriveScoreApi = (
  params: PhydScoreParams,
): Promise<PhydScoreResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/phyd_score`, { params });

export const fetchDriveMonthlyScoreApi = (
  params: MonthlyPhydReportParams,
): Promise<MonthlyPhydReportResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/monthly_phyd_report`, {
    params,
  });

// 衝撃通知設定
// export const updateVehicleStrikeSettingApi = (
//   params: StrikeSettingApiParams,
// ): Promise<StrikeSettingApiResponse> =>
//   baseApi.put(`${API_V1}customer/notification-settings/ecall`, params);

// 衝撃通知設定
export const updateVehicleStrikeSettingApi = (
  params: StrikeSettingApiParams,
): Promise<StrikeSettingApiResponse> =>
  baseApi.put(`${API_V1}customer/notification-settings`, params);

// 衝撃通知設定取得
export const fetchVehicleStrikeSettingsApi = (
  params: FetchStrikeSettingsApiParams,
): Promise<FetchStrikeSettingsApiResponse> =>
  baseApi.get(`${API_V1}customer/notification-settings`, { params });

// 走行日のデータ取得
export const fetchDriveDaysApi = (
  params: DrivingDayParams,
): Promise<DrivingDayResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/driving_day`, { params });

// 急アクセル回数
export const fetchDriveRapidAccelerationApi = (
  params: RapidAccelerationParams,
): Promise<RapidAccelerationResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/rapid_acceleration`, {
    params,
  });

// 累計スコア計算
export const fetchCalcScoreApi = (
  params: CalcScoreParams,
): Promise<CalcScoreResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/ad_insurance_discount`, {
    params,
  });

// 走行ルート
export const fetchDrivingRouteDataApi = (
  params: DrivingRouteParams,
): Promise<DrivingRouteResponse> =>
  baseApi.get(`${API_V1}customer/driving_record/driving_route`, { params });

// マンスリーレポート情報取得
export const fetchMonthlyReportListAPI = (
  params: MonthlyReportListAPIParams,
): Promise<MonthlyReportListAPIResponse> =>
  baseApi.get(`${API_V1}linkdrive/monthly_report_list`, { params });

// マンスリーデータ閲覧カウント
export const fetchMonthlyCountApi = (
  params: MonthlyCountParams,
): Promise<MonthlyCountResponse> =>
  baseApi.post(`${API_V1}/customer/monthly-count`, params);
