import { Router } from 'framework7/types';
import { FC } from 'react';
import { DecelerationData } from './DecelerationData/DecelerationData';

interface DelerationDataPageProps {
  f7router: Router.Router;
}

export const DecelerationDataPage: FC<DelerationDataPageProps> = ({
  f7router,
}) => {
  return <DecelerationData f7router={f7router} />;
};
