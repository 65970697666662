import { Icon } from 'framework7-react';
import { FC, useMemo } from 'react';
import style from './UploadDocuments.module.css';
import { ReactComponent as IconCheckGreen } from '@/assets/images/menu/icon-check-green.svg';
import { ReactComponent as IconCheckGrey } from '@/assets/images/menu/icon-check-grey.svg';
import { Documents, WarrantyDocument } from '@/types/api/warrantyApi';

interface UploadDocumentsProps {
  uploadDocument: Record<number, Documents>;
  warrantyDocument: WarrantyDocument[];
  handleClickDocument: (document: WarrantyDocument) => void;
}

export const UploadDocuments: FC<UploadDocumentsProps> = ({
  warrantyDocument,
  uploadDocument,
  handleClickDocument,
}) => {
  const uploadedDocs = useMemo(
    () =>
      warrantyDocument.filter((doc) => {
        const upload = uploadDocument[doc.m_warranty_document_property_id];
        return upload && upload.files.length > 0;
      }),
    [uploadDocument, warrantyDocument],
  );

  const missingDocs = useMemo(
    () =>
      warrantyDocument.filter((doc) => {
        const upload = uploadedDocs.find(
          (upload) =>
            doc.m_warranty_document_property_id ===
            upload.m_warranty_document_property_id,
        );
        return !upload;
      }),
    [uploadedDocs, warrantyDocument],
  );

  return (
    <div className={style['container']}>
      <div>
        <p className={style['content']}>
          取引保証のお手続きを進める場合は、全ての項目でファイルをアップロードする必要があります。
        </p>
        <p className={style['sub-title']}>
          ※コンディションシートのみ任意の項目となります。
        </p>
      </div>
      <div className={style['upload-group']}>
        <h3>未アップロードの書類</h3>
        <div className={style['upload-list']}>
          {missingDocs.length === 0 && <p>未アップロードの書類はありません</p>}
          {missingDocs
            .sort((a, b) => a.document_type - b.document_type)
            .map((doc) => (
              <div
                key={doc.m_warranty_document_property_id}
                className={style['upload-item']}
                onClick={() => handleClickDocument(doc)}
              >
                <IconCheckGrey />
                <p>{doc.name}</p>
                {!!doc.required_flg && (
                  <span className={style['required']}>必須</span>
                )}
                <Icon f7="chevron_right" size={24} />
              </div>
            ))}
        </div>
      </div>
      {uploadedDocs.length > 0 && (
        <div className={style['upload-group']}>
          <h3>アップロード済みの書類</h3>
          <div className={style['upload-list']}>
            {uploadedDocs
              .sort((a, b) => a.document_type - b.document_type)
              .map((doc) => (
                <div
                  key={doc.m_warranty_document_property_id}
                  className={style['upload-item']}
                  onClick={() => handleClickDocument(doc)}
                >
                  <IconCheckGreen />
                  <p>{doc.name}</p>
                  {!!doc.required_flg && (
                    <span className={style['required']}>必須</span>
                  )}
                  <Icon f7="chevron_right" size={24} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
