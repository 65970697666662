import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchContractInformationApi } from '@/api/linkDriveApi';
import {
  ContractInformationParams,
  ContractInformationResponse,
} from '@/types/api/linkDriveApi';

type ContractionInformation = {
  specialContract: string;
  cancelKbn: string | null | undefined;
  expireDate: string;
  issueDate: string;
  phydScoreKind: string;
  contractExpireDate: string;
  contractIssueDate: string;
  isShowJoinedInsuranceBtn: boolean;
  isShowScoreBtn: boolean;
};

const initialData: ContractionInformation = {
  specialContract: '',
  cancelKbn: null,
  expireDate: '',
  issueDate: '',
  phydScoreKind: '',
  contractExpireDate: '',
  contractIssueDate: '',
  isShowJoinedInsuranceBtn: false,
  isShowScoreBtn: false,
};

export const useContractInformation = ({
  owner_id,
  device_key,
  period_of_insurance,
}: ContractInformationParams) => {
  const [data, setData] = useState<ContractInformationResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchContractInformationApi({
          owner_id,
          device_key,
          period_of_insurance,
        });
        setData(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [owner_id, device_key, period_of_insurance]);

  return {
    data,
    isLoading,
    error,
  };
};
