import { Router } from 'framework7/types';
import { FC } from 'react';
import { TradeWarrantyDetail } from './TradeWarrantyDetail';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { store } from '@/config/store';
import { STATUS_TRADE_GUARANTEE } from '@/consts/warranty';
import { useGetDealWarrantyInfo } from '@/hooks/api/warranty/useGetDealWarrantyInfo';

interface TradeWarrantyDetailPagerops {
  f7router: Router.Router;
}
export const TradeWarrantyDetailPage: FC<TradeWarrantyDetailPagerops> = ({
  f7router,
}) => {
  const {
    authInfo: { m_customer_id },
    carItemInfo: { t_stock_car_id },
  } = store.state;
  const warranty_no = store.state.currentWarranty?.warranty_no as string;
  const branch_no = store.state.currentWarranty?.branch_no as number;
  const { data } = useGetDealWarrantyInfo({
    m_customer_id,
    t_stock_car_id,
    warranty_no,
    branch_no,
  });
  const noDealStatus = [
    STATUS_TRADE_GUARANTEE.WITHDRAWN_APPLICATION,
    STATUS_TRADE_GUARANTEE.COMPLETED,
  ];
  const hasDeal = !!(
    data?.success &&
    data?.status &&
    !noDealStatus.includes(data.status)
  );

  return (
    <PageTemplateV2
      pageName="TradeWarrantyDetailPage"
      title="取引保証プラン"
      showNavBack={true}
    >
      <TradeWarrantyDetail f7router={f7router} hasDeal={hasDeal} />
    </PageTemplateV2>
  );
};
