import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useCallback, useState } from 'react';
import { customerInfoApi } from '@/api/customerApi';
import {
  BillInformation,
  Car,
  CustomerInfoApiParams,
  NextWarranty,
  PaymentMethod,
  Warranty,
} from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';

export const useWarrantyManagement = (
  customerId: CustomerInfoApiParams['m_customer_id'],
  initWarranties: Warranty[],
  initNextWarranties: NextWarranty[],
  initCurrentWarranty: Warranty | undefined,
  initNextWarranty: NextWarranty | undefined,
  initBills: BillInformation[] | undefined,
  initPaymentMethods: PaymentMethod[] | undefined,
  selectedCar?: Required<Car>,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [warranties, setWarranties] = useState(initWarranties);
  const [nextWarranties, setNextWarranties] = useState(initNextWarranties);
  const [currentWarranty, setCurrentWarranty] = useState(initCurrentWarranty);
  const [nextWarranty, setNextWarranty] = useState(initNextWarranty);
  const [bills, setBills] = useState(initBills);
  const [paymentMethods, setPaymentMethods] = useState(initPaymentMethods);

  const callCustomerInfoApi = useCallback(async () => {
    try {
      if (!selectedCar) return;
      f7.preloader.show();
      setIsLoading(true);
      const { data: customerInfo } = await customerInfoApi({
        m_customer_id: customerId,
      });
      if (!customerInfo.success) {
        throw new Error('基礎情報取得に失敗');
      }
      const {
        cars,
        bill_infomations: billsRes,
        payment_methods: paymentMethodsRes,
        prepaids,
      } = customerInfo;
      const car =
        cars?.find((car) =>
          !car.m_car_id
            ? car.t_order_id === selectedCar.t_order_id
            : car.m_car_id === selectedCar.m_car_id,
        ) || cars?.[0];
      if (!car) {
        throw new Error('基礎情報取得に失敗');
      }
      const warranties = car?.warranties || [];
      const nextWarranties = car?.next_warranty || [];
      const currentWarrantyRes = car?.warranties?.[0];
      const nextWarrantyRes = car?.next_warranty?.[0];
      setWarranties(warranties);
      setNextWarranties(nextWarranties);
      setCurrentWarranty(currentWarrantyRes);
      setNextWarranty(nextWarrantyRes);
      setBills(billsRes);
      setPaymentMethods(paymentMethodsRes);
      await Promise.all([
        storeDispatch('setCarItemInfo', 'carItemInfo', car),
        storeDispatch('setCarsInfo', 'carsInfo', cars),
        storeDispatch('setBillsInfo', 'billsInfo', billsRes),
        storeDispatch(
          'setPaymentMethodsInfo',
          'paymentMethodsInfo',
          paymentMethodsRes,
        ),
        storeDispatch(
          'setAdvancePaymentsInfo',
          'advancePaymentsInfo',
          prepaids,
        ),
        await storeDispatch('setBillsInfo', 'billsInfo', billsRes),
        storeDispatch(
          'setPaymentMethodsInfo',
          'paymentMethodsInfo',
          paymentMethodsRes,
        ),
        storeDispatch('setWarranties', 'warranties', warranties),
        storeDispatch('setNextWarranties', 'nextWarranties', nextWarranties),
        storeDispatch(
          'setCurrentWarranty',
          'currentWarranty',
          currentWarrantyRes,
        ),
        storeDispatch('setNextWarranty', 'nextWarranty', nextWarrantyRes),
      ]);
      setIsLoading(false);
      f7.preloader.hide();
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
      }
      setError(e as AxiosError);
      f7.views.main.router.navigate('/');
    }
  }, [customerId, selectedCar]);

  const updateUserData = useCallback(() => {
    setWarranties(initWarranties);
    setNextWarranties(initNextWarranties);
    setCurrentWarranty(initCurrentWarranty);
    setNextWarranty(initNextWarranty);
    setBills(initBills);
    setPaymentMethods(initPaymentMethods);
  }, [
    initBills,
    initCurrentWarranty,
    initNextWarranties,
    initNextWarranty,
    initPaymentMethods,
    initWarranties,
  ]);

  return {
    isLoading,
    error,
    warranties,
    nextWarranties,
    currentWarranty,
    nextWarranty,
    bills,
    paymentMethods,
    callCustomerInfoApi,
    updateUserData,
  };
};
