import { Block, Button, f7 } from 'framework7-react';
import { FC } from 'react';
import { deletePaymentOption } from '@/api/payment';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { useCustomerInfo } from '@/hooks/api/customer/useCustomerInfo';
import { useToast } from '@/hooks/useToast';
import { PaymentMethod } from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';
import { isBankOrPayEasy, switchPaymentIcon } from '@/utils/payment';
import './DeletePaymentStyle.scss';
import { f7CustomBack } from '@/utils/utils';

interface DeletePaymentProps {
  paymentMethod: Required<PaymentMethod> | undefined;
}

const TOAST_TIMEOUT = 2000;

export const DeletePayment: FC<DeletePaymentProps> = ({ paymentMethod }) => {
  const { openToast } = useToast();
  const { setCustomerInfo } = useCustomerInfo({
    m_customer_id: store.state.authInfo.m_customer_id,
  });

  const handlePayments = async () => {
    f7.preloader.show();

    try {
      const result = await deletePaymentOption({
        paymentTypeId: paymentMethod?.m_payment_type_id as PaymentTypeId,
        paymentMethodId: String(paymentMethod?.m_payment_method_id),
      });
      if (result.data.success) {
        // Update the customer info
        setCustomerInfo();

        // Show success message via Toast
        openToast(
          '支払方法の削除が完了しました',
          'toast-success',
          TOAST_TIMEOUT,
        );
        f7CustomBack(['#DeletePayment']);
      } else {
        f7.dialog.alert(
          result.data.error.message + '再試行してください',
          '  エラー',
          () => {
            f7.dialog.close();
          },
        );
      }
    } catch (e: any) {
      console.error('Handle payment delettion error: ', e);
      f7.dialog.alert(e.message, '  エラー', () => {
        f7.dialog.close();
      });
    }
    f7.preloader.hide();
  };

  return (
    <PopupPageTemplate
      id="DeletePayment"
      title="お支払い方法の削除"
      pageName="DeletePayment"
    >
      <Block>
        <div className="u-font-size-xl u-font-bold u-text-center u-white-space">
          こちらの支払方法を削除しますか？
        </div>
        <div
          className="display-flex"
          style={{
            textAlign: 'left',
            justifyContent: 'center',
            padding: '35px',
          }}
        >
          <span style={{ marginRight: '12px' }}>
            <i
              className={`${switchPaymentIcon(
                paymentMethod?.m_payment_type_id as PaymentTypeId,
              )} building-icon`}
            />
          </span>
          <span style={{ width: 'initial' }}>
            <ul style={{ fontWeight: 'normal', fontSize: '1.4rem' }}>
              {isBankOrPayEasy(paymentMethod?.m_payment_type_id)
                ? '口座引落'
                : 'クレジットカード'}
            </ul>
            <ul style={{ marginTop: '5px', opacity: '0.3' }}>
              {isBankOrPayEasy(paymentMethod?.m_payment_type_id)
                ? paymentMethod?.account_number
                : paymentMethod?.card_no}
            </ul>
          </span>
        </div>
        <div
          className="u-font-size-lg u-font-bold u-margin-top-xs u-white-space"
          style={{ padding: 0 }}
        >
          注意事項・連絡事項
        </div>
        <div className="description-wrapper">
          <p>現在、お支払いに利用されている決済情報の削除はできません。</p>
          <p>別のお支払い方法に変更後、削除を行ってください。</p>
          <p>一度削除された情報を、元に戻すことは出来ません。</p>
          <p>再度必要になった際は、改めてご登録ください。</p>
        </div>
      </Block>
      <Block>
        <div className="display-flex button-container">
          <span>
            <Button
              onClick={handlePayments}
              large
              fill
              color="red"
              outline
              round
            >
              削除する
            </Button>
          </span>
        </div>
      </Block>
    </PopupPageTemplate>
  );
};
