import { Router } from 'framework7/types';
import {
  CouponDetailPage,
  CouponsPage,
  UsedCouponPage,
} from '@/components/pages';

export const couponRoutes: Router.RouteParameters[] = [
  {
    path: '/coupons/',
    component: CouponsPage,
    name: 'クーポン一覧',
  },
  {
    path: '/coupons/:id/',
    component: CouponDetailPage,
    name: 'クーポン詳細',
  },
  // {
  //   path: '/coupons_history/',
  //   component: CouponsHistoryPage,
  //   name: 'クーポン利用履歴'
  // },
  {
    path: '/used_coupons/',
    component: UsedCouponPage,
    name: '利用済みクーポン一覧',
  },
];
