import style from './editSignInStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { CheckBoxWithLabel } from '@/components/uiParts/CheckBox/CheckBoxWithLabel';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
export const EditSignIn = () => {
  const handleCheckboxChange = (index: number) => {
    console.log(index);
  };
  return (
    <PopupPageTemplate title="ログイン手段の変更" pageName="EditSignIn">
      <div className={style['content']}>
        <div className={style['subtitle']}>
          チェックを外すと、該当の方法ではログインできなくなります。
        </div>
        <div className={style['box-wrapper']}>
          <CheckBoxWithLabel
            name="telephone"
            label="電話番号でのログインを許可する"
          />
          <CheckBoxWithLabel
            name="mailAddress"
            label={`メールアドレス・パスワードでの\nログインを許可する`}
          />
          <div className={style['alert-box']}>
            <div className={style['icon-wrapper']}>
              <i className={`${style['icon-alert']} icon-alert`} />
            </div>
            <div className={style['alert-box-desc']}>
              <span className={style['bold']}>全てを外すことはできません</span>
              <span className={style['semi-bold']}>
                いずれかを選択してください
              </span>
            </div>
          </div>
        </div>
        <div className={style['button-box']}>
          <div className={style['button-wrapper']}>
            <Button>変更する</Button>
          </div>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
