import { format } from 'date-fns';
import {
  BillInformation,
  Car,
  PaymentMethod,
  Prepaid,
} from '@/types/api/customerApi';
import {
  PaymentMethod as PaymentMethodCamel,
  PaymentMethodWithItems,
} from '@/types/payment';
import { snakeToCamel } from '@/utils/utils';

export const makePayment = (
  paymentMethods: PaymentMethod[],
  carsInfo: Required<Car>[],
  bills: BillInformation[],
  prepaid: Prepaid,
) => {
  const payments: PaymentMethodWithItems[] = [];
  const billItems = findBillUsePayment(bills, carsInfo, prepaid);
  paymentMethods.forEach((method) => {
    const payment = snakeToCamel(method) as PaymentMethodCamel;
    const warrantyItems = findWarrantyUsePayment(method, carsInfo);
    const prepaidItems = findPrepaidUsePayment(method, prepaid);
    const items: {
      prepaidId?: number;
      prepaidName?: string;
      mCarId?: number;
      warrantyNo?: string;
      carNm?: string;
    }[] = [...warrantyItems, ...prepaidItems];
    billItems.forEach(({ mPaymentMethodId, mPaymentTypeId, ...bill }) => {
      if (
        mPaymentMethodId !== payment.mPaymentMethodId ||
        mPaymentTypeId !== payment.mPaymentTypeId
      ) {
        return;
      }
      const currentItem = items.find((item) =>
        item.mCarId
          ? item.mCarId === bill.mCarId
          : item.prepaidId === bill.prepaidId,
      );
      if (!currentItem) {
        items.push({
          ...bill,
        });
      }
    });
    payments.push({
      ...payment,
      items,
    });
  });
  return payments;
};

const findPrepaidUsePayment = (
  paymentMethod: PaymentMethod,
  prepaid?: Prepaid,
) => {
  const items: {
    prepaidId: number;
    prepaidName: string;
  }[] = [];
  if (
    paymentMethod.m_payment_method_id === prepaid?.payment_method_id &&
    paymentMethod.m_payment_type_id === prepaid?.payment_type_id
  ) {
    items.push({
      prepaidId: prepaid?.id || 0,
      prepaidName: prepaid?.name || '',
    });
  }
  return items;
};
const findBillUsePayment = (
  bills: BillInformation[],
  carsInfo: Required<Car>[],
  prepaid: Prepaid,
) => {
  const items: {
    mPaymentMethodId?: number;
    mPaymentTypeId?: number;
    prepaidId?: number;
    prepaidName?: string;
    mCarId?: number;
    warrantyNo?: string;
    carNm?: string;
  }[] = [];
  const isSamePrepaidId = (prepaidId: number | undefined) => {
    return prepaidId != null && prepaidId === prepaid?.id;
  };
  carsInfo.forEach((car) => {
    const isSameWarrantyNo = (warrantyNo: string | undefined) => {
      return (
        !!car.warranties &&
        car.warranties?.find((warranty) => warranty?.warranty_no === warrantyNo)
      );
    };
    bills.forEach((bill) => {
      const billDetail =
        bill.bill_infomation_details?.filter(
          (detail) =>
            detail.bill_month &&
            detail.bill_month >= format(Date.now(), 'yyyyMM'),
        ) || [];
      if (isSameWarrantyNo(bill.warranty_no) && billDetail.length) {
        billDetail.forEach((detail) => {
          items.push({
            mPaymentMethodId: detail.m_payment_method_id,
            mPaymentTypeId: detail.m_payment_type_id,
            carNm: car.car_nm,
            mCarId: car.m_car_id,
            warrantyNo: bill.warranty_no,
          });
        });
      }
      if (isSamePrepaidId(bill.m_prepaid_id) && billDetail.length) {
        if (items.find((item) => item.prepaidId === bill.m_prepaid_id)) return;
        billDetail.forEach((detail) => {
          items.push({
            mPaymentMethodId: detail.m_payment_method_id,
            mPaymentTypeId: detail.m_payment_type_id,
            prepaidId: prepaid.id,
            prepaidName: prepaid.name,
          });
        });
      }
    });
  });
  return items;
};
const findWarrantyUsePayment = (
  paymentMethod: PaymentMethod,
  carsInfo: Required<Car>[],
) => {
  const items: {
    mCarId: number;
    warrantyNo: string;
    carNm: string;
  }[] = [];
  carsInfo.forEach((car) => {
    car?.warranties?.every((warranty) => {
      if (
        paymentMethod.m_payment_method_id === warranty.payment_method_id &&
        paymentMethod.m_payment_type_id === warranty.payment_type_id
      ) {
        items.push({
          mCarId: car.m_car_id,
          carNm: car.car_nm,
          warrantyNo: warranty.warranty_no,
        });
        return false;
      }
      return true;
    });
  });
  return items;
};
