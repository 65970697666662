import { Col, f7, Row } from 'framework7-react';
import { Router } from 'framework7/types';
import React, { FC } from 'react';
import style from './VehicleConditionStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { UncompletedCard } from '@/components/uiParts/Card';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';

type VehicleConditionProps = {
  f7router: Router.Router;
};

export const VehicleCondition: FC<VehicleConditionProps> = ({ f7router }) => {
  const handleClickNavigationSelfScan = () => {
    f7.view.main.router.navigate(paths.selfScan, { animate: true });
  };
  return (
    <PageTemplate pageName="VehicleCondition" title="車両状態" showNavBack>
      <div className={style.card}>
        <h2 className={style.heading}>異常診断</h2>
        <p className={style.description}>
          LinkDriveを通して車から異常信号が発せられた場合通知します。
          <br />
          異常信号は、下記の場合に車から取得します。
        </p>
        <ul className={style.lists}>
          <li>走行時</li>
          <li>セルフスキャン</li>
        </ul>
        <div style={{ width: '100%', height: '16px' }} />
        <UncompletedCard
          cardHead={`LinkDriveのセットアップが\n完了していません`}
          cardBody="セットアップ完了後に運転記録や異常診断が使用できるようになります。"
          buttonText="セットアップする"
          handleClickAction={() => {}}
          isInner
        />
        <div style={{ width: '100%', height: '24px' }} />
        <UncompletedCard
          cardHead={`LinkDriveのキャリブレーションが\n完了していません`}
          cardBody="キャリブレーション完了後に運転記録や異常診断が使用できるようになります。"
          buttonText="キャリブレーションする"
          handleClickAction={() => {}}
          isInner
        />
        <div style={{ width: '100%', height: '24px' }} />
        <div className={style['alert-notification']}>
          <h3 className={style['sub-heading']}>異常アラート通知</h3>
          <p>異常アラート通知はありません</p>
          <p>最終更新日時:2020年10月10日</p>
        </div>

        <div style={{ width: '100%', height: '24px' }} />
        <div className={style['self-scan']}>
          <h3 className={style['sub-heading']}>セルフスキャン</h3>
          <p>
            セルフスキャンすると、最新の情報を使って異常診断をすることができます。
          </p>
          <p>
            ※セルフスキャンは、LinkDriveを装着した車のエンジンをかけて行う必要があります
          </p>
        </div>
        <div style={{ width: '100%', height: '24px' }} />
        <Row>
          <Col width="10" />
          <Col width="60">
            <Button handleClickAction={handleClickNavigationSelfScan}>
              スキャンする
            </Button>
          </Col>
          <Col width="10" />
        </Row>
        <div style={{ width: '100%', height: '16px' }} />
        <ul className={style['link-lists']}>
          <CustomList
            text="スキャン履歴"
            path=""
            subText="最後のスキャン: 2020年10月10日"
            isTopBorder={false}
            f7router={f7router}
          />
          <CustomList text="LinkDrive情報" path="" f7router={f7router} />
        </ul>
      </div>
      <div style={{ width: '100%', height: '48px' }} />
    </PageTemplate>
  );
};
