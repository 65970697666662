import { Col, Row } from 'framework7-react';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { PaymentDetail } from '@/config/initialState';
import { BillInformationDetail } from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';
import {
  formatDateISO,
  formatToJaDate,
  hyphenToDayNoPadding,
} from '@/utils/date';
import {
  isBankOrPayEasy,
  switchPaymentIcon,
  switchPaymentMethodDesc,
} from '@/utils/payment';
import './registerPaymentStyle.scss';

type RegisterPaymentProps = {
  paymentList: PaymentDetail[];
  nextBill?: BillInformationDetail;
  handleClickChangePaymentMethod: (item: PaymentDetail) => void;
  handleClickAddMethod: () => void;
  handleClickPopupClose: () => void;
};

export const RegisterPayment: FC<RegisterPaymentProps> = ({
  paymentList,
  nextBill,
  handleClickChangePaymentMethod,
  handleClickAddMethod,
  handleClickPopupClose,
}) => {
  const errorMessage = '';

  return (
    <PopupPageTemplate
      id="RegisterPayment"
      pageName="RegisterPayment"
      title={'決済方法の追加・変更'}
      handleBack={handleClickPopupClose}
    >
      <div className="last-block" style={{ marginTop: '40px' }}>
        <p className="u-font-bold u-font-size-lg">決済方法を選択してください</p>
        {errorMessage && (
          <div className="u-margin-top-sm info-card info-card-elevated">
            {errorMessage}
          </div>
        )}
        <div className="u-margin-top-sm">
          <InfoCard
            bgColor="success"
            text="変更の適用は次回請求時からになります"
            textSub={
              nextBill?.billing_date
                ? `適用予定日：${hyphenToDayNoPadding(
                    nextBill?.billing_date,
                    true,
                  )}`
                : ''
            }
            iconType="bulb"
          />
        </div>
        {/* 支払い方法 */}
        {paymentList.map((item, index: number) => (
          <div
            className="u-margin-top-sm info-card info-card-elevated register-payment__wrap"
            key={index}
          >
            <div className="pay-content display-flex">
              <div className="u-font-bold u-font-size-lg">
                <i
                  className={`icon ${switchPaymentIcon(
                    item.paymentTypeId as PaymentTypeId,
                  )}`}
                />
              </div>
              <div className="u-padding-left-sm u-padding-right-sm register-payment__wrap01">
                <p>
                  {switchPaymentMethodDesc(
                    item.paymentTypeId,
                    item.bankName,
                    item.paymentTypeName,
                  )}
                </p>
                <p className="number">
                  {isBankOrPayEasy(item.paymentTypeId as PaymentTypeId)
                    ? '口座番号：' + item.accountNumber
                    : item.cardNo}
                </p>
                <p className="number">
                  {`登録日：${formatToJaDate(
                    formatDateISO(item?.register || new Date().toISOString()),
                  )}`}
                </p>
              </div>
            </div>

            <div
              className="select-btn u-margin-top-xs u-margin-bottom-xs"
              id="select-btn01"
            >
              <Row>
                <Col width={item.isSelected ? 35 : 40} />
                <Col width={item.isSelected ? 65 : 60}>
                  <Button
                    text={''}
                    isActive={item.isSelected}
                    handleClickAction={() =>
                      handleClickChangePaymentMethod(item)
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>
        ))}

        {/* 決済方追加 */}
        <div
          className="u-margin-top-sm info-card info-card-elevated register-payment__wrap02"
          onClick={handleClickAddMethod}
        >
          <div className="pay-content display-flex">
            <div className="u-font-bold u-font-size-lg">
              <i className="icon-plus" />
            </div>
            <div className="u-padding-left-sm u-padding-right-sm">
              <p>新しい決済方法を追加する</p>
            </div>
          </div>
          <div
            className="select-btn u-margin-top-xs u-margin-bottom-xs"
            id="select-btn02"
          >
            <Row>
              <Col width="40" />
              <Col width="60">
                <Button
                  text=""
                  outline
                  handleClickAction={handleClickAddMethod}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
