import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import CouponGrayType2 from '@/assets/images/menu/icon-coupon-gray-type-2.svg';
import CouponGray from '@/assets/images/menu/icon-coupon-gray.svg';
import CouponRedType2 from '@/assets/images/menu/icon-coupon-red-type-2.svg';
import CouponRed from '@/assets/images/menu/icon-coupon-red.svg';
import { paths } from '@/config/paths';
import { useRank } from '@/hooks/api/rank/useRank';
import { hyphenToYear } from '@/utils/date';

type PrepaidCouponProps = {
  f7router: Router.Router;
  couponInfo: any; //Couponのstore実装時にCampaignに合わせる
  showArrow?: boolean;
};

export const PrepaidTicketCoupon: FC<PrepaidCouponProps> = ({
  f7router,
  couponInfo,
  showArrow = true,
}) => {
  const { rankTheme } = useRank();

  const startDate = new Date(couponInfo?.t_start_date);
  const endDate = new Date(couponInfo?.t_end_date);
  const now = new Date();

  const isShownCoupon = startDate <= now && now <= endDate;

  const handleClickCouponDetail = () => {
    f7.view.main.router.navigate(paths.coupon(couponInfo.t_campaigns_id));
  };

  return (
    <>
      {isShownCoupon && (
        <div className="coupon-box" onClick={handleClickCouponDetail}>
          {couponInfo.status ? (
            rankTheme ? (
              <img src={CouponGrayType2} alt="Coupon-Gray" />
            ) : (
              <img src={CouponGray} alt="Coupon-Gray" />
            )
          ) : rankTheme ? (
            <img src={CouponRedType2} alt="Coupon-Red" />
          ) : (
            <img src={CouponRed} alt="Coupon-Red" />
          )}
          <div className="content">
            <div className="content-left">
              <div className="coupon-name">{couponInfo.title}</div>
              <div className="coupon-deadline">
                {couponInfo.status
                  ? '利用済み'
                  : '有効期限: ' + hyphenToYear(couponInfo.t_end_date, false)}
              </div>
            </div>
            <div className="content-right">
              {showArrow && <i className="icon-right" />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
