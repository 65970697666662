import { Router } from 'framework7/types';
import { FC } from 'react';
import { DriveHistory } from './DriveHistory';

interface DriveHistoryPageProps {
  f7router: Router.Router;
  f7route: Router.Route;
}

export const DriveHistoryPage: FC<DriveHistoryPageProps> = ({
  f7router,
  f7route,
}) => {
  return <DriveHistory f7router={f7router} title={f7route.name} />;
};
