import { FC } from 'react';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import './version.scss';

export const Version: FC = () => {
  const version = 'Ver0.00';

  return (
    <PageTemplate pageName="version" showNavBack title="このアプリについて">
      <div className="home__wrapper">
        <div style={{ marginTop: '30px' }}>
          <div
            className="info-card u-text-center"
            style={{ paddingTop: '2rem', paddingBottom: '2rem' }}
          >
            <div className="block-item u-font-size-xxl u-font-bold u-padding-bottom-sm">
              バージョン情報
            </div>
            <div className="block-item">{version}</div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};
