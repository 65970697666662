import { FC } from 'react';
import { WarrantyCancelable } from './WarrantyCancelable/WarrantyCancelable';
import { makeCancelableWarrantyObj } from './warrantyCancelableHelper';
import { store } from '@/config/store';

export const WarrantyCancelablePage: FC = () => {
  const { customerInfo, currentWarranty } = store.state;
  const appendWarranty =
    currentWarranty?.append_warranties?.[
      currentWarranty?.append_warranties?.length - 1
    ];
  const cancelableTermObj = {
    firstName: customerInfo.first_name,
    familyName: customerInfo.family_name,
    ...makeCancelableWarrantyObj(appendWarranty?.warranty_end_date),
  };
  return <WarrantyCancelable cancelableTermObj={cancelableTermObj} />;
};
