import { Router } from 'framework7/types';
import { FC } from 'react';
import { SmsDisable } from './SmsDisable/SmsDisable';

interface SmsDisablePageProps {
  f7router: Router.Router;
}
export const SmsDisablePage: FC<SmsDisablePageProps> = ({ f7router }) => {
  return <SmsDisable f7router={f7router} />;
};
