import perse from 'html-react-parser';
import { FC } from 'react';
import style from './DealWarrantyTermsStyle.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { CustomBgPageTemplate } from '@/components/uiParts/Template/CustomBgPageTemplate/CustomBgPageTemplate';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';
type DealWarrantyTermsProps = {};

export const DealWarrantyTerms: FC<DealWarrantyTermsProps> = () => {
  const { data } = useTerm(termType.TRADE_GUARANTEE);
  const warrantyTerms = (data?.term ?? '').replaceAll('\n', '<br />');

  const wrappedTable =
    `<div className="${style['scrollable-table']}">` + warrantyTerms + '</div>';

  return (
    <CustomBgPageTemplate
      pageName="DealWarrantyTerms"
      title="取引保証利用規約"
      showNavBack
      background={{ background: '#efefef' }}
    >
      {warrantyTerms ? (
        <RoundSheet>
          <div className={style.container}>{perse(wrappedTable)}</div>
        </RoundSheet>
      ) : null}
    </CustomBgPageTemplate>
  );
};
