import { FC } from 'react';
import style from './LabelStyle.module.css';
export interface RequiredLabelProps {
  required?: boolean;
  m?: string;
}
export const Label: FC<RequiredLabelProps> = ({
  m = '0 0 0 4px',
  required = false,
}) => {
  return (
    <span
      className={`label ${style['label']} ${
        required ? style['required'] : style['optional']
      }`}
      style={{ margin: m }}
    >
      {required ? '必須' : '任意'}
    </span>
  );
};
