import { f7, useStore } from 'framework7-react';
import { FC, useCallback, useEffect } from 'react';
import { BannerTemplate } from '../BannerTemplate/BannerTemplate';
import adTelemileageImage from '@/assets/images/ad-telemileage.png';
import adTelemileageImageResponsive from '@/assets/images/ad-telemileage@2x.png';
import { baseApi } from '@/config/axios';
import { isApp } from '@/config/device';
import { State } from '@/config/initialState';
import { store } from '@/config/store';
import { endpoint } from '@/consts/api';
import { mileage } from '@/consts/mileage';
import { url } from '@/consts/url';
import { Car } from '@/types/api/customerApi';
import { compareDate } from '@/utils/date';
import { storeDispatch } from '@/utils/store';

export const AdTelemileage: FC = () => {
  const { content, m_car_id } = useStore(
    store,
    'adTelemileageContent',
  ) as State['adTelemileageContent'];
  const carItemInfo = useStore(store, 'carItemInfo') as Required<Car>;
  const getLink = useCallback(() => {
    const baseUrl = url.MAIKURU_BASE_URL;
    const kanriNo = carItemInfo.before_contract_id;
    const polNo = carItemInfo.before_contract_number;
    const polNoEda = carItemInfo.before_contract_number_sub;
    const syuryobi = carItemInfo.before_contract_expire_date.replace(/-/g, '');
    const sysId = '20';
    const date = new Date();
    const mukouFlg =
      syuryobi && compareDate(new Date(syuryobi), date)
        ? mileage.invalid
        : mileage.valid;
    return `${baseUrl}/v1/telema/entertainment?kanri_no=${kanriNo}&sys_id=${sysId}&mukou_flg=${mukouFlg}&syuryobi=${syuryobi}&pol_no=${polNo}&pol_no_eda=${polNoEda}`;
  }, [carItemInfo]);
  useEffect(() => {
    const shouldUpdate = !content || carItemInfo.m_car_id !== m_car_id;
    if (!isApp && shouldUpdate) {
      f7.preloader.show();
      const link = getLink();
      baseApi.get(link).then((rs) => {
        storeDispatch('setAdTelemileageContent', 'adTelemileageContent', {
          content: rs.data,
          m_car_id: carItemInfo.m_car_id,
        });
        f7.preloader.hide();
      });
    }
  }, [carItemInfo, content, getLink, m_car_id]);
  const handleClickGoLink = async () => {
    const link = getLink();
    if (isApp) {
      window.open(link);
    } else {
      const newWindow = window.open('', '_blank', 'resizable=yes');
      newWindow?.document?.write(content);
    }
  };
  return (
    <BannerTemplate
      handleClickNavigation={handleClickGoLink}
      imagePath={adTelemileageImage}
      responsiveImagePath={adTelemileageImageResponsive}
      head="ADテレマイレージでポイントを貯める！"
      text="ポイントをお得なクーポンに交換しましょう！"
      isBrainTraining={false}
      actionUrl={endpoint.VITE_TELE_MILEAGE_LINK}
    />
  );
};
