import { yupResolver } from '@hookform/resolvers/yup';
import { Block, Button, f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ShopInfo } from './ShopInfo';
import './TodoDetailStyle.scss';
import {
  formatDate,
  DepositDayInput,
  deopsitImperfectConfirmation,
} from './const';
import { mailDaySchema } from './validationSchema';
import { inputCustomerApi } from '@/api/todoListApi';
import { ReactComponent as ExclamationmarkIcon } from '@/assets/images/icon-exclamationmark_circle.svg';
import { DepositDayForm } from '@/components/pages/TodoDetail/TodoDetail/DepositDayInput';
import { useToast } from '@/hooks/useToast';
import { formatDateISO, formatDateWithDay } from '@/utils/date';

interface PromptDepositDetailProps {
  f7router: Router.Router;
  item: any;
  handleClickCompleted: () => void;
  isMarkTodoCompleted: boolean;
}

export const PromptDepositDetail: FC<PromptDepositDetailProps> = ({
  f7router,
  item,
  handleClickCompleted,
  isMarkTodoCompleted,
}) => {
  const { openToast } = useToast();
  const transferInfo = {
    transfer_title: '振込期日',
    transfer_date: item.end_date
      ? formatDateWithDay(new Date(formatDateISO(item.end_date)))
      : '',
    transfer_sub_title: '振込情報',
    transfer_list: [
      { label: '金融機関名', value: item?.bank_name },
      { label: '支店名', value: item?.branch_name },
      { label: '口座種別', value: item?.bank_account_type },
      { label: '口座番号', value: item?.account_number },
      { label: '口座名義人', value: item?.holder_name },
      { label: '振込金額', value: `${item?.payment_cash?.toLocaleString()}円` },
    ],
  };

  const formMethods = useForm<DepositDayInput>({
    defaultValues: {
      year: new Date().getFullYear().toString(),
      month: '',
      day: '',
    },
    resolver: yupResolver(mailDaySchema),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, errors },
  } = formMethods;

  const onSubmit: SubmitHandler<DepositDayInput> = async (data) => {
    f7.preloader.show();

    const payment_day =
      data.year && data.month && data.day
        ? formatDate(`${data.year}-${data.month}-${data.day}`)
        : undefined;
    const dataSubmit = {
      task_id: item.task_id,
      t_wf_instance_task_id: item.t_wf_instance_task_id,
      data: {
        id: item.id,
        payment_day: payment_day,
      },
    };

    const res = await inputCustomerApi(dataSubmit);

    f7.preloader.hide();

    if (res.data.success) {
      handleClickCompleted();
      openToast('送信に成功しました');
    } else {
      openToast('エラーが発生しました', 'toast-failed');
    }
  };

  return (
    <>
      <div className="home__wrapper">
        <Block style={{ marginTop: 0, padding: 0 }}>
          <div className="info-card">
            {isMarkTodoCompleted && (
              <div className="info-card-confirm">確認中</div>
            )}

            <h2 className="notice-title u-text-center">ご入金をお願いします</h2>
            <div className="notice-content">
              <p>{item?.holder_name}</p>
              <p>
                下記の情報をご確認の上、期日までにお振り込みいただきますようお願いいたします。
              </p>
            </div>
          </div>
          {item.is_payment_amount_correct ===
            deopsitImperfectConfirmation.DEFECTIVE && (
            <div className="transfer-info-card">
              <div className="transfer-info-card-header">
                <ExclamationmarkIcon width={22} height={22} />
                <span>{item.imperfect_content_detail}</span>
              </div>
              <p>速やかにお手続きくださいませ。</p>
            </div>
          )}
          <div className="info-card">
            <h2 className="notice-title-2">{transferInfo.transfer_title}</h2>
            <p className="notice-date">
              {transferInfo.transfer_date.substring(0, 14)}
            </p>
            <div className="notice-content">
              ※指定日までの送付が難しい場合は、購入店舗にご相談ください
            </div>
            <ShopInfo />
          </div>
          <div className="info-card">
            <h2 className="notice-title-2">
              {transferInfo.transfer_sub_title}
            </h2>
            <br />
            <div className="transfer-list">
              {transferInfo.transfer_list.map((item, index) => (
                <div className="file-list" key={index}>
                  <div className="item-desc1 u-font-bold">{item.label}</div>
                  <div className="item-desc2">{item.value}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="info-card">
            <h2 className="notice-title-2">振り込み後の流れ</h2>
            <p />
            <div className="notice-content">
              <p>
                入金が完了しましたら、「完了済みにする」ボタンを押してください。
              </p>
              <br />
              {item.is_payment_amount_correct !==
                deopsitImperfectConfirmation.DEFECTIVE && (
                <>
                  <p>
                    店舗で入金の確認をさせていただき、こちらのアプリで受け取確認完了の通知をさせていただきます。
                  </p>
                  <br />
                </>
              )}
              <p className="notice-bold">
                ※入金確認が取れましたら、やることリストからこちらのタスクがなくなります。
              </p>
            </div>
          </div>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="info-card">
                <DepositDayForm
                  watch={watch}
                  setValue={setValue}
                  isValid={isValid}
                  error={
                    errors.year?.message ||
                    errors.month?.message ||
                    errors.day?.message
                  }
                />
              </div>
              <Button
                className="completed-btn"
                disabled={isMarkTodoCompleted || !isValid}
                type="submit"
              >
                完了済みにする <br />
                <span>(送金完了後に押してください)</span>
              </Button>
            </form>
          </FormProvider>
        </Block>
      </div>
    </>
  );
};
