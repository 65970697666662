import { format } from 'date-fns';
import {
  DataContent,
  DataResponse,
  HistoryContent,
} from '@/types/api/driveRecordApi';
import { getXAxis } from '@/utils/date';
import { echartsScale } from '@/utils/echartsScail';

export const getDriveRecordData = (
  data: DataResponse['data'] | undefined,
  historyData: HistoryContent[] | undefined,
) => {
  const initialData = {
    // 一日の運転時間
    drive_total_trip_time: {
      yearScoreList: [0, 0, 0, 0, 0, 0, 0],
      createMonthly: [''],
    },
    driveDurationScale: {
      minNumber: 0,
      maxNumber: 24,
      YaxisLabelInterval: 0,
    },
    // 一日の平均燃費
    drive_fuel: {
      yearScoreList: [0, 0, 0, 0, 0, 0, 0],
      createMonthly: [''],
    },
    drivingFuelScale: {
      minNumber: 0,
      maxNumber: 100,
      YaxisLabelInterval: 0,
    },
    // 安全運転スコア
    drive_phyd_score: {
      yearScoreList: [0, 0, 0, 0, 0, 0, 0],
      createMonthly: [''],
    },
    pieces: [
      // A
      {
        gt: 80,
        lte: 100,
        color: '#1E55E6',
      },
      // B
      {
        gt: 60,
        lte: 80,
        color: '#00DCFA',
      },
      // C
      {
        gt: 0,
        lte: 60,
        color: '#FFBF40',
      },
    ],
    // 平均スピード超過回数
    drive_speed_over: {
      yearScoreList: [0, 0, 0, 0, 0, 0, 0],
      createMonthly: [''],
    },
    speedOverScale: {
      minNumber: 0,
      maxNumber: 10,
      YaxisLabelInterval: 0,
    },
    // 平均急ブレーキ回数
    drive_rapid_deceleration: {
      yearScoreList: [0, 0, 0, 0, 0, 0, 0],
      createMonthly: [''],
    },
    rapidDecelerationScale: {
      minNumber: 0,
      maxNumber: 10,
      YaxisLabelInterval: 0,
    },
    // 平均急アクセル回数
    drive_rapid_acceleration: {
      yearScoreList: [0, 0, 0, 0, 0, 0, 0],
      createMonthly: [''],
    },
    rapidAccelerationScale: {
      minNumber: 0,
      maxNumber: 10,
      YaxisLabelInterval: 0,
    },
    fullDateList: [''], // 1週間分のYYYY-MM-dd
  };

  //x軸
  const currentDate = format(new Date(), 'yyyy/MM/dd');
  const dateList = [];
  const fullDateList = [];
  const week = 7;
  const year = getXAxis(currentDate, 0).substring(8, 12);
  const secondsPerHour = 3600; // 秒から時間に変更の際に使用
  for (let i = 0; i < week; i++) {
    //0番目じゃないかつ同じ年なら月日曜日のみ
    if (getXAxis(currentDate, -i).substring(8, 12) === year) {
      dateList.unshift(getXAxis(currentDate, -i).substring(0, 7));
    }
    const fullData =
      year + '-' + getXAxis(currentDate, -i).substring(2, 7).replace('/', '-');
    fullDateList.unshift(fullData);
  }
  initialData.drive_total_trip_time.createMonthly = dateList;
  initialData.drive_fuel.createMonthly = dateList;
  initialData.drive_phyd_score.createMonthly = dateList;
  initialData.drive_rapid_acceleration.createMonthly = dateList;
  initialData.drive_rapid_deceleration.createMonthly = dateList;
  initialData.drive_speed_over.createMonthly = dateList;
  initialData.drive_total_trip_time.createMonthly = dateList;
  initialData.fullDateList = fullDateList;

  //y軸
  dateList.forEach((item: string, index: number) => {
    data?.data?.find((n: DataContent) => {
      let idx = item.indexOf('/');
      if (n.phyd_score_avg ?? 0 < 0) n.phyd_score_avg = 0;
      //同じ日にちのデータを格納する
      if (
        n.diagnosis_date?.substring(6, 8) === item.substring(idx + 1, idx + 3)
      ) {
        // 運転時間
        initialData.drive_total_trip_time.yearScoreList[index] =
          (n.total_trip_time_sum ?? 0) / secondsPerHour;
        // 平均燃費
        initialData.drive_fuel.yearScoreList[index] =
          n.fuel_efficiency_avg ?? 0;
        // 速度超過
        initialData.drive_speed_over.yearScoreList[index] = n.speed ?? 0;
        // 急ブレーキ
        initialData.drive_rapid_deceleration.yearScoreList[index] =
          n.rapid_deceleration_times_avg ?? 0;
        // 急発信
        initialData.drive_rapid_acceleration.yearScoreList[index] =
          n.rapid_acceleration_times_avg ?? 0;
      }
    });

    initialData.fullDateList.forEach((date, index) => {
      makeDrivingHistoryObj(historyData)?.forEach((data) => {
        if (data.dDate.substring(0, 10) === date) {
          initialData.drive_phyd_score.yearScoreList[index] =
            data.avg_score ?? 0;
        }
      });
    });

    initialData.driveDurationScale =
      echartsScale(
        'drivingDuration',
        initialData.drive_total_trip_time.yearScoreList,
      ) ?? initialData.driveDurationScale;
    initialData.drivingFuelScale =
      echartsScale('drivingFuel', initialData.drive_fuel.yearScoreList) ??
      initialData.drivingFuelScale;
    initialData.rapidDecelerationScale =
      echartsScale(
        'rapidDeceleration',
        initialData.drive_rapid_deceleration.yearScoreList,
      ) ?? initialData.rapidDecelerationScale;
    initialData.rapidAccelerationScale =
      echartsScale(
        'rapidAcceleration',
        initialData.drive_rapid_acceleration.yearScoreList,
      ) ?? initialData.rapidAccelerationScale;
    initialData.speedOverScale =
      echartsScale('speedOver', initialData.drive_speed_over.yearScoreList) ??
      initialData.speedOverScale;
  });
  return initialData;
};

// TODO: リファクタリング
type DrivingHistoryObj = {
  dDate: string;
  data: number[];
  avg_score: number | undefined;
  total_score: number;
};

const makeDrivingHistoryObj = (histories: HistoryContent[] | undefined) => {
  if (!histories) return;
  const newDataList: HistoryContent[][] = [];
  let tempDataList: HistoryContent[] = [];
  let dateKey = '';
  if (histories.length) {
    dateKey = histories?.[0]?.diagnosis_date?.substring(0, 10) ?? '';
  }
  histories.forEach((history) => {
    if (!!dateKey && history?.diagnosis_date?.substring(0, 10) === dateKey) {
      tempDataList.push(history);
    } else {
      dateKey = history?.diagnosis_date?.substring(0, 10) ?? '';
      newDataList.push(tempDataList);
      tempDataList = [history];
    }
  });
  newDataList.push(tempDataList);

  let drivingHistoryObjList: DrivingHistoryObj[] = [];
  newDataList.forEach((items: HistoryContent[]) => {
    let obj: DrivingHistoryObj = {
      dDate: '',
      data: [],
      avg_score: undefined,
      total_score: 0,
    };
    items.forEach((item: HistoryContent) => {
      if (obj.dDate !== item.diagnosis_date) {
        obj.dDate = item.diagnosis_date ?? '';
        if (item.phyd_score != null && item.phyd_score > 0) {
          obj.data.push(item.phyd_score);
          obj.total_score += item.phyd_score;
        }
      } else {
        if (item.phyd_score != null && item.phyd_score > 0) {
          obj.data.push(item.phyd_score);
          obj.total_score += item.phyd_score;
        }
      }
    });
    obj.avg_score = obj.data.length ? obj.total_score / obj.data.length : 0;
    drivingHistoryObjList.push(obj);
  });
  return drivingHistoryObjList;
};
