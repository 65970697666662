import { FC } from 'react';
import { CallSupportButton } from '../CallSupportButton/CallSupportButton';
import { ContactByFormButton } from '../ContactByFormButton/ContactByFormButton';
import style from './helpButtonsBoxStyle.module.css';
interface HelpButtonsBoxProps {
  title: string;
  isSecondButton?: boolean;
  path: string;
  isPopupPage?: boolean;
}

export const HelpButtonsBox: FC<HelpButtonsBoxProps> = ({
  title,
  isSecondButton = false,
  path,
  isPopupPage = false,
}) => {
  return (
    <div className={style['consultant-container']}>
      <h3>{title}</h3>
      <div className={style['buttons-wrapper']}>
        <CallSupportButton />
        {isSecondButton && (
          <>
            <p>または</p>
            <ContactByFormButton path={path} isPopupPage={isPopupPage} />
          </>
        )}
      </div>
    </div>
  );
};
