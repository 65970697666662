import { FC } from 'react';
import style from './warrantyPartsListStyle.module.css';
import { WarrantyPartsType } from '@/utils/warranty';

type WarrantyPartsListProps = {
  warrantPartsList?: WarrantyPartsType;
};

export const WarrantyPartsList: FC<WarrantyPartsListProps> = ({
  warrantPartsList,
}) => {
  return (
    <div className="WarrantyPartsList-content">
      <div className="list accordion-list">
        <ul className={style['item-wrapper']}>
          {warrantPartsList &&
            warrantPartsList.map((parts, index: number) => (
              <li className="accordion-item" key={index}>
                <a
                  className={`item-content item-link  ${style['item-title']}`}
                  href="#"
                >
                  <div className="item-inner">
                    <div className={`${style['category-name']}`}>
                      {parts.m_accessory_category_name}
                    </div>
                  </div>
                </a>
                <div className="accordion-item-content">
                  <div>
                    <ul>
                      {parts.subItems.map((item, index2: number) => (
                        <li className={style['list-items']} key={index2}>
                          {item.parts_name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
