import { Router } from 'framework7/types';
import { CommisionedWorkPage } from '@/components/pages/CommisionedWork/CommisionedWorkPage';
import { paths } from '@/config/paths';

export const carCommisionedWorkRoutes: Router.RouteParameters[] = [
  {
    path: paths.commisionedWork,
    component: CommisionedWorkPage,
    name: '作業依頼',
  },
];
