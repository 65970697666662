import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  parseISO,
} from 'date-fns';
import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/framework7-types';
import { FC, useState } from 'react';
import carwashCancel from '../../../assets/images/carwashCancel.svg';
import carwashComplete from '../../../assets/images/carwashComplete.png';
import carwashCurrent from '../../../assets/images/carwashCurrent.svg';
import carwashDone from '../../../assets/images/carwashDone.svg';
import style from './CarwashDetailPageStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  cancellationFrom,
  cancellationType,
  marginTypeOption,
  parkingTypeOption,
  scratchedCheckOption,
  securitySystemOption,
  workspaceTypeOption,
} from '@/consts/carWash';
import { serviceType, statusReservation } from '@/consts/reservation';
import { useFetchReservationDetail } from '@/hooks/api/reservation/useFetchReservationDetail';
import { Customer } from '@/types/api/customerApi';
import { Reservation } from '@/types/api/reservationApi';
import {
  convertUtcJp,
  formatDate,
  formatDateISO,
  formatToJaDate,
} from '@/utils/date';

type CarwashDetailPageProps = {
  f7router: Router.Router;
  fromPage?: string;
};

export const CarwashDetailPage: FC<CarwashDetailPageProps> = ({ fromPage }) => {
  const [isShowContent, setIsShowContent] = useState(true);
  const [isShowPlace, setIsShowPlace] = useState(true);
  const [isShowWorkspace, setIsShowWorkspace] = useState(true);
  const reservation = useStore(store, 'getReservation') as Reservation;
  const {
    affiliate_name: affiliateName,
    affiliate_invoice_registration_number: invoiceNumber,
  } = useStore(store, 'customerInfo') as Customer;

  // 予約確定後、キャンセル可能期間を過ぎた後、利用者が直接受付に連絡して予約をキャンセルする場合。
  const cancellationByUser = [
    cancellationType.NO_TARGET_VEHICLE,
    cancellationType.ANOTHER_APPOINTMENT,
    cancellationType.NO_NEED,
    cancellationType.MISTAKE,
  ] as number[];

  const { data } = useFetchReservationDetail({
    reservation_id: reservation.reservation_id,
    service_type: reservation.service_type,
  });
  const isCancellationByUser = cancellationByUser.includes(
    data?.cancellation?.cancellation_type ?? 0,
  );
  const toggleContent = () => setIsShowContent(!isShowContent);

  const togglePlace = () => setIsShowPlace(!isShowPlace);

  const toggleWorkspace = () => setIsShowWorkspace(!isShowWorkspace);

  const handleClickCancel = () => {
    f7.views.main.router.navigate(
      paths.carwashCancelReason(reservation.reservation_id.toString()),
    );
  };

  const handleClickChangeSchedule = () => {
    f7.views.main.router.navigate(
      paths.carwashEdit(reservation.reservation_id.toString()),
      {
        props: { type: reservation.service_type },
      },
    );
  };

  const handleReSchedule = () => {
    f7.views.main.router.navigate(
      paths.carwashEdit(reservation.reservation_id.toString()),
      {
        props: { type: reservation.service_type },
      },
    );
  };

  const getTitle = () => {
    const carwashItemName = data?.carwash_item.name || '';
    const carwashOptionName =
      data?.carwash_option
        .map((option) => option.carwash_option_name)
        .join('、') || '';

    return `${carwashItemName} ${
      carwashOptionName ? '、' + carwashOptionName : ''
    }`;
  };

  const getServiceTypeText = (): string => {
    return reservation.service_type === serviceType.carwash
      ? '出張水なし手洗い洗車'
      : '出張カーエアコンクリーニング';
  };

  const parkingTypeText = (parkingType: number) => {
    return parkingTypeOption.find((item) => item.value === parkingType)?.label;
  };

  const workspaceTypeText = (workspaceType: number) => {
    return workspaceTypeOption.find((item) => item.value === workspaceType)
      ?.label;
  };

  const marginTypeText = (marginType: number) => {
    return marginTypeOption.find((item) => item.value === marginType)?.label;
  };

  const securitySystemText = (securitySystem: number) => {
    return securitySystemOption.find((item) => item.value === securitySystem)
      ?.label;
  };

  const scratchedCheckText = (scratchedCheck: number) => {
    return scratchedCheckOption.find((item) => item.value === scratchedCheck)
      ?.label;
  };

  const lastChangeDate = () => {
    if (!data?.confirmed_datetime) {
      return null;
    }

    const confirmedDate = parseISO(data?.confirmed_datetime); // fix IOS15: '2024-03-06 01:01:01' => '2024-03-06T01:01:01'
    const confirmedDateUtc = addMinutes(
      confirmedDate,
      confirmedDate.getTimezoneOffset(),
    );
    const confirmedDateJp = addHours(confirmedDateUtc, 9);
    return addDays(confirmedDateJp, -4);
  };

  const isShowBtn = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const lastChangedDate = lastChangeDate();
    return (
      data?.reservation_status === statusReservation.TENTATIVE ||
      data?.reservation_status === statusReservation.NOT_ACCEPT ||
      (lastChangedDate &&
        differenceInDays(lastChangedDate, currentDate) > 0 &&
        data?.reservation_status === statusReservation.CONFIRMED)
    );
  };

  const isLastChangeDatePassed = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const lastChangedDate = lastChangeDate();

    return (
      lastChangedDate && differenceInDays(lastChangedDate, currentDate) <= 0
    );
  };

  const handleClickMap = () => {
    const parkingAddr = `${data?.workspace.address1} ${data?.workspace.address2} ${data?.workspace.address3}`;
    f7.view.main.router.navigate('/shopmap/', {
      props: {
        pageTitle: '位置情報',
        parkingAddr: parkingAddr,
        isIcon: true,
      },
    });
  };

  const bookingTime = () => {
    const bookingTimeStr = data?.confirmed_datetime
      ? convertUtcJp(data?.confirmed_datetime)
      : data?.order_date
      ? formatDate(
          // fix IOS15: '2024-03-06 01:01:01' => '2024-03-06T01:01:01'
          parseISO(data?.order_date),
        )
      : '';

    return bookingTimeStr ? `${bookingTimeStr}〜` : '';
  };

  const completionTime = () => {
    return data?.completion_datetime
      ? convertUtcJp(data?.completion_datetime)
      : '';
  };

  const showAcceptancePeriod = () => {
    return (
      (data?.reservation_status === statusReservation.CONFIRMED ||
        data?.reservation_status === statusReservation.CANCEL) &&
      data?.confirmed_datetime
    );
  };

  const handleClickContact = () => {
    window.location.href = 'tel://' + '05020186478';
  };

  const handleClickBack = () => {
    f7.view.main.router.navigate(fromPage || paths.reservationHistory, {
      transition: 'push-reverse',
    });
  };

  const cancelReason = () => {
    if (isCancellationByUser) {
      return (
        <>
          <p>
            キャンセル料金は
            {Number(
              data?.cancellation_charge?.charge_price ?? 0,
            )?.toLocaleString()}
            円です。
          </p>
          <p>プリペイド残高よりお支払いいただきました料金</p>
          <p>とキャンセル料金の差額につきまして、</p>
          <p>プリペイド残高へお戻しいたします。</p>
        </>
      );
    }
    return (
      <>
        <p>誠に申し訳ございません。</p>
        <p>都合により、ご希望の日時でご予約を承ることが</p>
        <p>できずキャンセルとなりました。</p>
        <p>ご予約の再調整につきましては、受付窓口</p>
        <p>
          （
          <a className={style['text-blue']} onClick={handleClickContact}>
            050-2018-6478
          </a>
          ）に電話でご相談ください。
        </p>
        <div className={style['reason-wrapper']}>
          <p className={style['title']}>
            キャンセルには以下のような理由がございます
          </p>
          <ul>
            <li>天候不良</li>
            <li>事故・修理等</li>
            <li>日程調整の不調　など</li>
          </ul>
        </div>
      </>
    );
  };

  const cancelTitle = () => {
    if (data?.cancellation?.cancellation_from !== cancellationFrom.USER) {
      if (isCancellationByUser) {
        return '予約を取り消しました。';
      }
      return '予約がキャンセルされました';
    }

    return '予約を取り消しました';
  };
  return (
    <PageTemplate
      showNavBack={true}
      pageName="WashHistory"
      title="予約詳細"
      handleClickBack={handleClickBack}
    >
      {data && (
        <>
          {data?.reservation_status === statusReservation.TENTATIVE && (
            <div className={`${style['block']} ${style['block-doc']}`}>
              <img src={carwashCurrent} alt="" />
              <div className={style['title']}>予約申請中です</div>
              <div>
                <p className={style['text']}>
                  担当者から予約内容についてのメッセージを
                </p>
                <p className={style['text']}>
                  お送りします。しばらくお待ち下さい。
                </p>
                {isShowBtn() && (
                  <Button
                    onClick={handleClickChangeSchedule}
                    text="日程を変更する"
                    type="button"
                    className={style['btn-change']}
                  />
                )}
              </div>
            </div>
          )}
          {data?.reservation_status === statusReservation.CONFIRMED && (
            <div className={`${style['block']} ${style['block-doc']}`}>
              <img src={carwashDone} alt="" />
              <div className={style['title']}>予約が確定しました</div>
              <div>
                <p className={style['text']}>当日までお待ちください。</p>
                <p className={style['text']}>
                  予約日時：
                  {convertUtcJp(data?.confirmed_datetime)}〜
                </p>
                {isLastChangeDatePassed() && (
                  <div className={style['outdate-update-notice']}>
                    <p className={style['outdate-update-title']}>
                      予約の変更・キャンセルしたい
                    </p>
                    <p className={style['text-dim-gray']}>
                      予約の変更・キャンセル可能期間を過ぎているため、受付窓口（
                      <a
                        className={style['text-blue']}
                        onClick={handleClickContact}
                      >
                        050-2018-6478
                      </a>
                      ）に電話でご相談ください。
                    </p>
                  </div>
                )}
                {isShowBtn() && (
                  <Button
                    onClick={handleClickChangeSchedule}
                    text="日程を変更する"
                    type="button"
                    className={style['btn-change']}
                  />
                )}
              </div>
            </div>
          )}
          {data?.reservation_status === statusReservation.CANCEL && (
            <div className={`${style['block']} ${style['block-doc']}`}>
              <img src={carwashCancel} alt="" />
              <div className={style['title']}>{cancelTitle()}</div>
              <div>
                {data.cancellation?.cancellation_from ===
                cancellationFrom.USER ? (
                  <p className={style['text']}>
                    またのご利用をお待ちしております。
                  </p>
                ) : (
                  <div className={style['textReason']}>{cancelReason()}</div>
                )}
              </div>
            </div>
          )}
          {data?.reservation_status === statusReservation.COMPLETED && (
            <div className={`${style['block']} ${style['block-doc']}`}>
              <img src={carwashComplete} alt="" />
              <div className={style['title']}>作業が完了しました</div>
              <div>
                <p className={style['text']}>
                  お待たせいたしました。作業が完了しました。
                </p>
              </div>
            </div>
          )}
          {data?.reservation_status === statusReservation.NOT_ACCEPT && (
            <div className={`${style['block']} ${style['block-doc']}`}>
              <img src={carwashCancel} alt="" />
              <div className={style['title']}>
                予約を受け付けることができませんでした
              </div>
              <div>
                <p className={style['text']}>
                  ご希望のお日にちでのご予約をお取りすることができませんでした。
                  <br />
                  大変恐縮ではございますが、別の候補日のご入力をお願いいたします。
                </p>
                <Button
                  onClick={handleReSchedule}
                  text="日程を再調整する"
                  type="button"
                  className={style['btn-change']}
                />
              </div>
            </div>
          )}

          {data?.reservation_status === statusReservation.COMPLETED && (
            <div className={style['block']}>
              <div className={style['block-header-border']}>
                <div className={style['title']}>領収書</div>
              </div>
              <div className={style['flex']}>
                <span
                  className={`${style['text-gray-normal']} ${style['title-affiliate-name']}`}
                >
                  {affiliateName}
                </span>
                <span
                  className={`${style['text-gray-normal']} ${style['title-invoice-number']}`}
                >
                  {invoiceNumber}
                </span>
              </div>
              <p
                className={`${style['text-gray-normal']} ${style['order-date-info']}`}
              >
                {formatToJaDate(
                  formatDateISO(data?.order_date || new Date().toISOString()),
                )}
              </p>
              <p className={style['title']}>{getServiceTypeText()}</p>
              <div
                className={`${style['receipt-box']} ${style['receipt-info']}`}
              >
                <h4 className={style['receipt-box-title']}>お支払額</h4>
                <p
                  className={`${style['text-gray-small']} ${style['receipt-tax']}`}
                >
                  (消費税{data?.tax}%込)
                </p>
                <p className={style['receipt-detail']}>
                  <span className={style['receipt-amount']}>
                    {data?.total_price.toLocaleString()}
                  </span>
                  <span className={style['receipt-unit']}>円</span>
                </p>
              </div>
              <p
                className={`${style['text-gray-small']} ${style['publication-date-info']}`}
              >
                発行日付：
                {formatToJaDate(formatDateISO(new Date().toISOString()))}
              </p>
            </div>
          )}

          <div className={`${style['block']}`}>
            <div className={style['block-header']} onClick={toggleContent}>
              <div className={style['title']}>洗車情報</div>
              {isShowContent ? (
                <i className="icon-up" />
              ) : (
                <i className="icon-down" />
              )}
            </div>
            {isShowContent && (
              <div className={style['divide']}>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>希望コース</p>
                  <p className={style['text']}>{getTitle()}</p>
                </div>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>ご利用料金</p>
                  <p className={style['text']}>
                    ￥{data?.total_price.toLocaleString()}
                  </p>
                </div>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>車のサイズ</p>
                  <p className={style['text']}>
                    {data.car_info.car_size_value}
                  </p>
                </div>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>対象車両</p>
                  <p className={style['text']}>{data.car_info.car_name}</p>
                </div>

                {data?.reservation_status === statusReservation.TENTATIVE ? (
                  <>
                    <div className={style['content-line']}>
                      <p className={style['text-label']}>第一希望日</p>
                      <p className={style['text']}>
                        {convertUtcJp(data?.preferred_datetime.datetime1)}〜
                      </p>
                    </div>
                    <div className={style['content-line']}>
                      <p className={style['text-label']}>第二希望日</p>
                      <p className={style['text']}>
                        {convertUtcJp(data?.preferred_datetime.datetime2 || '')}
                        〜
                      </p>
                    </div>
                    <div className={style['content-line']}>
                      <p className={style['text-label']}>第三希望日</p>
                      <p className={style['text']}>
                        {convertUtcJp(data?.preferred_datetime.datetime3 || '')}
                        〜
                      </p>
                    </div>
                  </>
                ) : (
                  <div className={style['content-line']}>
                    <p className={style['text-label']}>予約日時</p>
                    <p className={style['text']}>{bookingTime()}</p>
                  </div>
                )}
                {/* <div className={style['content-line']}>
                  <p className={style['text-label']}>詳細</p>
                  <p className={style['text']}>{data.request || 'なし'}</p>
                </div> */}
                {data?.reservation_status === statusReservation.COMPLETED && (
                  <div className={style['content-line']}>
                    <p className={style['text-label']}>実施日時</p>
                    <p className={style['text']}>{completionTime()}</p>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={`${style['block']}`}>
            <div className={style['block-header']} onClick={togglePlace}>
              <div className={style['title']}>洗車場所</div>
              {isShowPlace ? (
                <i className="icon-up" />
              ) : (
                <i className="icon-down" />
              )}
            </div>
            {isShowPlace && (
              <div className={style['divide']}>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>洗車場所</p>
                  <p className={style['text']}>
                    {workspaceTypeText(data.workspace.workspace_type)}
                  </p>
                </div>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>駐車場タイプ</p>
                  <p className={style['text']}>
                    {parkingTypeText(data.workspace.parking_type)}
                  </p>
                </div>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>ご住所</p>
                  <p className={style['text']}>
                    <p>
                      〒{data.workspace.zip_code1}-{data.workspace.zip_code2}
                    </p>
                    <p>
                      {data.workspace.address1}
                      {data.workspace.address2}
                      {data.workspace.address3}
                    </p>
                    <a className={style['text-blue']} onClick={handleClickMap}>
                      地図でみる
                    </a>
                  </p>
                </div>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>目印・駐車位置など</p>
                  <p className={style['text']}>
                    {data.workspace.workspace_memo}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className={`${style['block']}`}>
            <div className={style['block-header']} onClick={toggleWorkspace}>
              <div className={style['title']}>作業環境</div>
              {isShowWorkspace ? (
                <i className="icon-up" />
              ) : (
                <i className="icon-down" />
              )}
            </div>
            {isShowWorkspace && (
              <div className={style['divide']}>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>
                    作業環境についてお車の前後左右に50~60cm程度の間隔はございますか？
                  </p>
                  <p className={style['text']}>
                    {marginTypeText(data.workspace.margin_type)}
                  </p>
                </div>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>
                    後付けのセキュリティシステムが付いていますか？
                  </p>
                  <p className={style['text']}>
                    {securitySystemText(data.workspace.security_system)}
                  </p>
                </div>
                <div className={style['content-line']}>
                  <p className={style['text-label']}>
                    車の傷の確認に同席できますか？
                  </p>
                  <p className={style['text']}>
                    {scratchedCheckText(data.workspace.scratched_check)}
                  </p>
                </div>
              </div>
            )}
          </div>

          {data?.reservation_status !== statusReservation.COMPLETED && (
            <div className={`${style['block']}`}>
              <div className={style['title-red']}>
                <i className={style['icon-exclamationmark_circle']} />
                ご注意事項
              </div>
              <ul>
                <li className={style['text-li']}>
                  車両の装備品によって施工が出来ない場合がございます。
                </li>
                <li className={style['text-li']}>
                  当日の作業や道路状況によっては到着時間が前後する可能性がございます。
                </li>
                {(data.cancellation_charge.charge_percent ||
                  data.cancellation_charge.charge_price) && (
                  <li className={style['text-li']}>
                    予約のキャンセルはキャンセル料が発生しますのでご注意ください。
                    {data.cancellation_charge.charge_percent ? (
                      <span>({data.cancellation_charge.charge_percent}%)</span>
                    ) : (
                      <span>({data.cancellation_charge.charge_price}円)</span>
                    )}
                  </li>
                )}
                {data?.reservation_status === statusReservation.TENTATIVE && (
                  <li className={`${style['text-red']} ${style['text-li']}}`}>
                    予約の変更・キャンセルの受付期間は予約日より4日前までです。それ以降はお電話にてお問い合せください。
                  </li>
                )}
                {showAcceptancePeriod() && (
                  <li className={`${style['text-red']} ${style['text-li']}}`}>
                    予約の変更・受付期間は
                    {formatToJaDate(lastChangeDate() as Date)}
                    まで変更可能です。それ以降はお電話にてお問い合わせください。
                  </li>
                )}
                <li className={style['text-li']}>
                  電話でのお問い合わせは、
                  <a
                    className={style['text-blue']}
                    onClick={handleClickContact}
                  >
                    050-2018-6478
                  </a>
                  までお問い合わせください。
                </li>
              </ul>
            </div>
          )}

          {isShowBtn() && (
            <div className={style['button-container']}>
              <Button
                onClick={handleClickCancel}
                text="予約を取り消す"
                type="button"
              />
            </div>
          )}
        </>
      )}
    </PageTemplate>
  );
};
