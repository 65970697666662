import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { connectorJobApi } from '@/api/linkDriveApi';
import {
  ConnectorJobApiParams,
  ConnectorJobApiResponse,
} from '@/types/api/linkDriveApi';

interface Args {
  params: ConnectorJobApiParams;
  resolve?: (data: ConnectorJobApiResponse['data']) => void;
  onFailure?: (err: any) => void;
}

const errorMessage =
  'エラーコード：LD0010<br><br>デバイスのセットアップを開始できませんでした。デバイスの起動状態をご確認の上、再度セットアップを実施してください。解決できない場合は、お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。';

export const useConnectorJob = () => {
  const [data, setData] = useState<ConnectorJobApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const requestConnectorJob = async (args: Args) => {
    try {
      setIsLoading(true);
      const { data } = await connectorJobApi(args.params);
      setData(data);
      if (!data.success) {
        throw new Error(errorMessage);
      }
      args.resolve?.(data);
    } catch (err) {
      setError(err as AxiosError);
      args.onFailure?.(err);
      f7.dialog.alert(errorMessage, 'エラー', () => {
        // f7.view.main.router.navigate(paths.linkDriveSetupList, {
        //   animate: true,
        // });
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    requestConnectorJob,
    isLoading,
    error,
  };
};
