import { HistoryContent } from '@/types/api/driveRecordApi';
import { HistoryData } from '@/types/driveHistory';
import { formatSeconds, getTimes, hyphenToDay } from '@/utils/date';

// Magic number of kilo
const kilo = 1000;
const numberOfDigitAfterDecimal = 1;

export const getHistoryData = (data?: HistoryContent[]) => {
  if (!data) return;
  data.sort((a, b) => {
    return new Date(b.diagnosis_date ?? 0) < new Date(a.diagnosis_date ?? 0)
      ? -1
      : 1;
  });
  let currentFlag = '';
  const historyList: HistoryData[] = [];
  data.forEach((n) => {
    const history: HistoryData = {
      ...n,
    };
    history.id = n.id;
    history.status = n.status;
    //kmに変換、小数点1桁を残す
    history.driving_distance = Number(
      ((n.driving_distance ?? 0) / kilo).toFixed(numberOfDigitAfterDecimal),
    );
    const { tripHours, tripMinutes } = formatSeconds(n.total_trip_time ?? 0);
    history.driving_duration_start = n.diagnosis_date;
    history.driving_duration_end = getTimes(
      n.diagnosis_date ?? '',
      n.total_trip_time ?? 0,
    );
    history.hours = tripHours;
    history.minutes = tripMinutes;
    const substr_diagnosis_date = hyphenToDay(
      (n.diagnosis_date ?? '').substring(0, 10),
      false,
    );
    if (substr_diagnosis_date != currentFlag) {
      currentFlag = substr_diagnosis_date;
      history.oneDayFlag = substr_diagnosis_date;
    }

    //Todo: statusの値の意味調査
    if (n.status == '2' && n.phyd_score) {
      history.phyd_score_level = n.phyd_score.toString();
    } else if (n.status == '5') {
      history.phyd_score_level = '診断対象外';
    } else if (n.consent_flg == 1) {
      if (n.phyd_score != null) {
        history.phyd_score_level = n.phyd_score.toString();
      } else {
        // 1時間以上　'診断対象外'なる
        let currentDate = new Date().getTime();
        let diagnosisDate = new Date(n.diagnosis_date ?? '').getTime();
        let flag = (currentDate - diagnosisDate) / (1000 * 3600) > 1;
        if (flag) {
          history.phyd_score_level = '診断対象外';
        } else {
          history.phyd_score_level = '診断中';
        }
      }
    } else {
      history.phyd_score_level = '-';
    }
    historyList.push(history);
  });

  return historyList;
};
