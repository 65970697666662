import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { ResetPassword } from './ResetPassword/ResetPassword';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { mailSorting } from '@/consts/auth';
import { usePasswordReset } from '@/hooks/api/auth/usePasswordReset';
import { RequestPasswordResetApiParams } from '@/types/api/authApi';
import {
  addAffiliateIdToRequestParams,
  getAffiliateIdFromThemeId,
} from '@/utils/shop';

interface ResetPasswordPageProps {
  f7router: Router.Router;
  from: string;
}
export const ResetPasswordPage: FC<ResetPasswordPageProps> = ({
  f7router,
  from,
}) => {
  const [id, setId] = useState('');
  const { resetPassword, data } = usePasswordReset();
  const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  };
  const handleClickSend = async () => {
    const params: RequestPasswordResetApiParams = {
      mail_address: id.length !== 0 ? id : store.state.loginInfo.email,
      mail_kbn: mailSorting.afterSecond,
    };
    addAffiliateIdToRequestParams(
      getAffiliateIdFromThemeId(store.state.themeId),
      params,
      false,
    );
    await resetPassword(params);
  };
  useEffect(() => {
    if (data?.success) {
      f7.view.main.router.navigate(paths.resetPasswordComplete, {
        props: {
          id,
        },
      });
    }
    // f7routerとidを省略
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <ResetPassword
      id={id}
      from={from}
      f7router={f7router}
      handleClickButton={handleClickSend}
      handleChangeId={handleChangeId}
    />
  );
};
