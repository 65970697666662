import { FC } from 'react';
import { WarrantyParts } from './WarrantyParts/WarrantyParts';
import { store } from '@/config/store';
import {
  findDefectAppendWarranty,
  getWarrantyPartsData,
  WarrantyPartsType,
} from '@/utils/warranty';

export const WarrantyPartsPage: FC = () => {
  const { currentWarranty } = store.state;
  const appendWarranty = findDefectAppendWarranty(currentWarranty);
  const warrantyParts: WarrantyPartsType = appendWarranty?.m_warranty_parts
    ? getWarrantyPartsData(appendWarranty?.m_warranty_parts)
    : [];

  return (
    <WarrantyParts
      currentWarranty={currentWarranty}
      appendWarranty={appendWarranty}
      warrantyParts={warrantyParts}
    />
  );
};
