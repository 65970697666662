import { AxiosError } from 'axios';
import { useState } from 'react';
import { linkDriveSetupStatus } from '@/api/linkDriveApi';
import {
  LinkDriveSetupStatusApiParams,
  LinkDriveSetupStatusApiResponse,
} from '@/types/api/linkDriveApi';

interface Args {
  params: LinkDriveSetupStatusApiParams;
  signal: AbortSignal;
  resolve?: (data: LinkDriveSetupStatusApiResponse['data']) => void;
  onFailure?: () => void;
}

export const useLinkDriveSetupStatus = () => {
  const [data, setData] = useState<LinkDriveSetupStatusApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const fetchLinkDriveSetupStatus = (args: Args) => {
    const { params, signal, resolve, onFailure } = args;
    // async/awaitを使うとAPIコールの保留中にpathタグが初期化されてしまい、
    // プログレスサークルが一時的に0%になってしまう
    linkDriveSetupStatus(params, signal)
      .then(({ data }) => {
        setData(data);
        resolve?.(data);
      })
      .catch((err) => {
        setError(err as AxiosError);
        onFailure?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    fetchLinkDriveSetupStatus,
    data,
    error,
  };
};
