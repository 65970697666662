import { FC } from 'react';
import style from './popupTermsStyle.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import './popupTermsStyle.scss';

interface PopupTermsProps {
  html?: React.ReactNode;
  title: string;
  open: boolean;
  handleClose?: () => void;
}

export const PopupTerms: FC<PopupTermsProps> = ({
  html,
  title,
  open,
  handleClose,
}) => {
  if (!html) return <></>;
  return (
    <PopupTemplate title={title} opened={open} handleClose={handleClose}>
      <div className={style['container']}>
        <RoundSheet>
          <div data-name="terms">{html}</div>
        </RoundSheet>
      </div>
    </PopupTemplate>
  );
};
