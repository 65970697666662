import { yupResolver } from '@hookform/resolvers/yup';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { passwordSchema } from '../passwordSchema';
import style from './passwordStyle.module.css';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { RhfInput } from '@/components/uiParts/ReactHookForm/RhfInput/RhfInput';
import { LoginPageTemplate } from '@/components/uiParts/Template/LoginPageTemplate/LoginPageTemplate';
import { UnderLineLink } from '@/components/uiParts/UnderLineLink/UnderLineLink';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { AffiliateId } from '@/consts/shop';
import { useLogin } from '@/hooks/api/auth';
import { LoginApiParams } from '@/types/api/authApi';
import { addAffiliateIdToRequestParams } from '@/utils/shop';

interface PasswordProps {
  f7router: Router.Router;
  affiliateId: AffiliateId;
}

interface FormInput {
  password: string;
}

export const Password: FC<PasswordProps> = ({ f7router, affiliateId }) => {
  const { login } = useLogin();
  const formMethods = useForm<FormInput>({
    mode: 'onChange',
    defaultValues: { password: '' },
    resolver: yupResolver(passwordSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const params: LoginApiParams = {
      login_id: store.state.loginInfo.email,
      login_pass: data.password,
    };
    addAffiliateIdToRequestParams(affiliateId, params);
    await login(params, store.state.emailDuplicated);
  };

  return (
    <LoginPageTemplate
      pageName="PasswordInput"
      title="パスワード入力"
      showNavBack
    >
      <div className={style['container']}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style['password-input-label-wrapper']}>
              <span>パスワードを入力してください</span>
              <div className={style['form-check-icon-wrapper']}>
                <RhfInput
                  className={style['input']}
                  name="password"
                  type="password"
                  placeholder="パスワード"
                />
              </div>
            </div>
            <div className={style['button-container']}>
              <div className={style['button-wrapper']}>
                <Button type="submit" disabled={!isValid}>
                  次へ
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
        <div className={style['text-link']}>
          <UnderLineLink
            text="パスワードを忘れたかた"
            path={paths.resetPassword}
            f7router={f7router}
          />
        </div>
      </div>
    </LoginPageTemplate>
  );
};
