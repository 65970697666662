import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { Car } from './Car/Car';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { Car as CarType } from '@/types/api/customerApi';

interface CarPageProps {
  f7router: Router.Router;
  id: string;
  isChangeCarImage: boolean;
}
export const CarPage: FC<CarPageProps> = ({
  f7router,
  id,
  isChangeCarImage,
}) => {
  const carItemInfo = useStore(store, 'carItemInfo') as Required<CarType>;
  const hasCar = id !== '0';
  const licensePlate = {
    area: carItemInfo.transport_name,
    classNumber: carItemInfo.class_no,
    kana: carItemInfo.hiragana,
    consecutiveNumber: carItemInfo.registry_no,
    transportBu: carItemInfo.transport_bu,
  };

  const handleChangeLicenseInfo = () => {
    f7.view.main.router.navigate(paths.updateNumber);
  };
  const handleChangeInspectionInfo = () => {
    f7.view.main.router.navigate(paths.updateInspection(id));
  };

  return (
    <Car
      id={id}
      hasCar={hasCar}
      carInfo={carItemInfo}
      handleChangeLicenseInfo={handleChangeLicenseInfo}
      handleChangeInspectionInfo={handleChangeInspectionInfo}
      licensePlateInfo={licensePlate}
      isChangeCarImage={isChangeCarImage}
    />
  );
};
