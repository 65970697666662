import { Router } from 'framework7/types';
import {
  AccidentPage,
  RoadserviceGuidePage,
  RoadservicePage,
  RoadServiceTermsPage,
  RoadServiceTermsPopupPage,
  TroubleShootingPage,
} from '@/components/pages';

export const roadServiceRoutes: Router.RouteParameters[] = [
  //   {
  //     path: '/roadservice_tutorial/',
  //     component: RoadserviceTutorialPage,
  //     name: 'ロードサービスチュートリアル'
  //   },
  {
    path: '/trouble_shooting/',
    component: TroubleShootingPage,
    name: '車のトラブル',
  },
  {
    path: '/roadservice/',
    component: RoadservicePage,
    name: 'ロードサービス内容',
  },
  {
    path: '/accident/',
    component: AccidentPage,
    name: '事故を起こしてしまった',
  },
  {
    path: '/roadservice_guide/',
    component: RoadserviceGuidePage,
    name: 'ロードサービス利用手順',
  },
  {
    path: '/roadservice_terms/',
    component: RoadServiceTermsPage,
    name: 'ロードサービス利用規約',
  },
  {
    path: '/roadservice_terms_popup/',
    popup: {
      component: RoadServiceTermsPopupPage,
    },
    name: 'ロードサービス利用規約ポップアップ',
  },
];
