import { Router } from 'framework7/types';
import { Dispatch, FC, SetStateAction } from 'react';
import { Card } from '../Card/Card';
import { TroubleShootingCardLink } from '../TroubleShootingCardLink/TroubleShootingCardLink';
import { WarrantyPlanRequiredPopup } from '../WarrantyPlanRequiredPopup/WarrantyPlanRequiredPopup';
import style from './TroubleShooting.module.css';
import accidentImage from '@/assets/images/menu/icon-car-red.svg';
import sluggishImage from '@/assets/images/menu/icon_car-blue.svg';
import notStartImage from '@/assets/images/menu/icon_car-yel.svg';
import { Button } from '@/components/uiParts/Button';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { Warranty } from '@/types/api/customerApi';
import {
  findDefectAppendWarranty,
  switchWarrantyPartsTitleByCoverageType,
} from '@/utils/warranty';

type TroubleShootingProps = {
  f7router: Router.Router;
  warranty: Warranty | undefined;
  isOpenWarrantyRequired: boolean;
  setIsOpenWarrantyRequired: Dispatch<SetStateAction<boolean>>;
  handleCallRoadService: () => void;
  handleCallSupportCenter: () => void;
  handleClickAccident: () => void;
  handleClickProblem: () => void;
  handleClickTrouble: () => void;
};

export const TroubleShooting: FC<TroubleShootingProps> = ({
  f7router,
  warranty,
  isOpenWarrantyRequired,
  setIsOpenWarrantyRequired,
  handleCallRoadService,
  handleCallSupportCenter,
  handleClickAccident,
  handleClickProblem,
  handleClickTrouble,
}) => {
  const { currentWarranty } = store.state;

  const warrantyInfo = findDefectAppendWarranty(currentWarranty);

  return (
    <PageTemplate pageName="TroubleShooting" title="車のトラブル">
      <div className={style['container']}>
        <Card title="どこに連絡すればいいの？" iconType="icon-help-new">
          <TroubleShootingCardLink
            imageSrc={sluggishImage}
            cardTitle="車が不調な気がする！"
            cardBody="修理受付を行います。　"
            handleClick={handleClickProblem}
          />
          <TroubleShootingCardLink
            imageSrc={notStartImage}
            cardTitle="車が動かなくなった！"
            cardBody="ロードサービスをご利用いただくことができます。"
            handleClick={handleClickTrouble}
          />
          <TroubleShootingCardLink
            imageSrc={accidentImage}
            cardTitle="事故を起こしてしまった！"
            cardBody="まずは落ち着いて、次のページをご覧ください。"
            handleClick={handleClickAccident}
          />
        </Card>
        {warranty && (
          <Card
            title="各種サービスについて"
            iconType="icon-help-new"
            CardCss={style['card']}
          >
            <div className={style['list-wrap']}>
              <p className={style['list-title']}>ロードサービス</p>
              <ul className={style.lists}>
                <CustomList
                  text="ご利用可能なサービス"
                  path={paths.roadservice}
                  f7router={f7router}
                  fontSize="14px"
                  textColor="#323232"
                  isTopBorder={false}
                  isBottom
                />
                <CustomList
                  text="利用手順"
                  path={paths.roadserviceGuide}
                  f7router={f7router}
                  fontSize="14px"
                  textColor="#323232"
                  isTopBorder={false}
                  isBottom
                />
                <CustomList
                  text="利用規約"
                  path={paths.roadserviceTerms}
                  f7router={f7router}
                  fontSize="14px"
                  textColor="#323232"
                  isTopBorder={false}
                  isBottom
                />
              </ul>
            </div>
            <div className="u-margin-top-xs">
              <p className={style['list-title']}>保証</p>
              <ul className={style.lists}>
                <CustomList
                  text={
                    'ご利用の保証プラン・' +
                    switchWarrantyPartsTitleByCoverageType(
                      warrantyInfo?.covering_type,
                    )
                  }
                  path={paths.warrantyParts}
                  f7router={f7router}
                  fontSize="14px"
                  textColor="#323232"
                  isTopBorder={false}
                  isBottom
                />
                <CustomList
                  text="保証規約"
                  path={paths.warrantyTerms}
                  f7router={f7router}
                  fontSize="14px"
                  textColor="#323232"
                  isTopBorder={false}
                />
              </ul>
            </div>
          </Card>
        )}
        <Card title="お電話でのお問い合わせはこちら" isSubTitle>
          <div className={style['call-type']}>
            <Button
              text="ロードサービス"
              className={style['call-btn']}
              outline
              onClick={handleCallRoadService}
            />
            <Button
              text="サポートセンター"
              className={style['call-btn']}
              outline
              onClick={handleCallSupportCenter}
            />
          </div>
        </Card>
      </div>
      <WarrantyPlanRequiredPopup
        isOpen={isOpenWarrantyRequired}
        setIsOpenWarrantyRequired={setIsOpenWarrantyRequired}
      />
    </PageTemplate>
  );
};
