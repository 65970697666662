import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { cancelCarWash } from '@/api/reservationApi';
import { CancelCarWashParams } from '@/types/api/reservationApi';

export const useCancelCarWash = () => {
  const [error, setError] = useState<AxiosError>();

  const cancel = async (args: {
    body: CancelCarWashParams;
    onSuccess?: () => void;
  }) => {
    try {
      f7.preloader.show();
      const res = await cancelCarWash(args.body);

      if (res.data.success) {
        setTimeout(() => {
          args.onSuccess?.();
        }, 0);
      } else {
        f7.dialog.alert('', res.data?.error_message || '');
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    cancel,
    error,
  };
};
