import { FC } from 'react';
import './creditCardPayInfoStyle.scss';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

interface CreditCardPayInfoProps {
  title?: string;
  textContent?: string;
}

export const CreditCardPayInfo: FC<CreditCardPayInfoProps> = ({
  title = '',
  textContent = '',
}) => {
  return (
    <RoundSheet py="6px">
      <div className="creditcard-info-box list">
        <div className="item-content">
          <div className="item-inner">
            <div className="item-title">
              <i className={`icon left left-alert icon-bulb`} />
              {title || 'クレジットカードでお支払いの方へ'}
            </div>
          </div>
        </div>
        <div className="content">
          <p>
            {textContent ||
              '各履歴に記載されている請求日とクレジットカードの引とし日は異なる場合があります。'}
          </p>
        </div>
      </div>
    </RoundSheet>
  );
};
