import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchDrivingRouteDataApi } from '@/api/driveRecordApi';
import {
  DrivingRouteParams,
  DrivingRouteResponse,
} from '@/types/api/driveRecordApi';

export const useDrivingRoute = ({
  trip_id,
  diagnosis_data_id,
}: DrivingRouteParams) => {
  const [data, setData] = useState<DrivingRouteResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(true);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    if (diagnosis_data_id == null) {
      return;
    }
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchDrivingRouteDataApi({
          trip_id,
          diagnosis_data_id,
        });
        if (res.data.success) {
          setIsNoData(false);
        }
        setData(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [diagnosis_data_id, trip_id]);

  return {
    data,
    isLoading,
    isNoData,
    error,
  };
};
