import { AxiosError } from 'axios';
import { useState } from 'react';
import { loginApi } from '@/api/authApi';
import { useToast } from '@/hooks/useToast';
import { LoginApiParams } from '@/types/api/authApi';

export const useWatchingServiceLogin = () => {
  const { openToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const login = async (params: LoginApiParams): Promise<boolean> => {
    let isAuth = false;
    try {
      setIsLoading(true);
      const { data } = await loginApi(params);
      if (!data.success) openToast('認証に失敗しました', 'toast-failed');
      isAuth = data.success;
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      return isAuth;
    }
  };

  return {
    login,
    isLoading,
    error,
  };
};
