import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { loginPermissionApi } from '@/api/accountApi';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import {
  LoginPermissionParams,
  LoginPermissionResponse,
} from '@/types/api/account';

export const useLoginPermission = (initialParams: LoginPermissionParams) => {
  const [params] = useState(initialParams);
  const [data, setData] = useState<LoginPermissionResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const loginType = store.state.loginInfo['loginType'];
  const isTelephoneLogin = loginType === 'telephone';

  useEffect(() => {
    f7.preloader.show();
    if (isTelephoneLogin) {
      const fn = async () => {
        try {
          f7.preloader.show();
          try {
            setIsLoading(true);
            const res = await loginPermissionApi(params);
            setData(res.data);
            if (!res.data.success) {
              openToast(
                'エラーが発生しました。' + res.data.message,
                'toast-success',
                1500,
                true,
                'top',
              );
              f7.popup.close();
            } else {
              // ログイン手段(1:電話番号)のログイン許可状態
              console.log(res.data.login_info?.[0]);
              document.getElementById('loginSelect1')!.textContent =
                '携帯電話番号';

              document.getElementById('loginId1')!.textContent =
                res.data!.login_info![0]['login_id'];

              document.getElementById('loginPermission1')!.textContent =
                res.data.login_info![0]['login_permission'] == true
                  ? '許可する'
                  : '許可しない';

              // ログイン手段(2:メールアドレス)のログイン許可状態
              console.log(res.data.login_info?.[1]);
              document.getElementById('loginSelect2')!.textContent =
                'メールアドレス';

              document.getElementById('loginId2')!.textContent =
                res.data!.login_info![1]['login_id'];

              document.getElementById('loginPermission2')!.textContent =
                res.data.login_info![1]['login_permission'] == true
                  ? '許可する'
                  : '許可しない';
            }
          } catch (err) {
            setError(err as AxiosError);
          }
        } catch (err: any) {
          setError(err as AxiosError);
          if (err.data) {
            openToast(
              'エラーが発生しました。',
              'toast-failed',
              1500,
              true,
              'top',
            );
          }
        } finally {
          setIsLoading(false);
          f7.preloader.hide();
        }
      };
      fn();
    }
    f7.preloader.hide();
  }, [params]);

  return {
    data,
    isLoading,
    error,
  };
};
