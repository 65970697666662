import { Button } from 'framework7-react';
import { FC } from 'react';
import style from './LinkDriveSetupStep2Style.module.css';
import EngineImage from '@/assets/images/engine.png';

interface Props {
  onSubmit: () => void;
}

export const StepEngine: FC<Props> = ({ onSubmit }) => {
  return (
    <>
      <h2 className={style['head']}>
        <i className={`icon-check ${style['head-icon']}`} />
        エンジンを始動してください
      </h2>
      <div className={style['rounded-image']}>
        <img src={EngineImage} alt="" />
      </div>
      {/* <!-- 以降の手順の記載 --> */}
      <Button
        fill
        round
        text="始動した"
        style={{ marginTop: 'auto' }}
        onClick={onSubmit}
      />
    </>
  );
};
