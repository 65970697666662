import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { serialNumberSchema } from '../serialNumberSchema';
import style from './SerialNumberInputStyle.module.css';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { TextInput } from '@/components/uiParts/Input/TextInput';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

interface FormInput {
  serialNumber: string;
}

type SerialNumberInputProp = {
  onSubmitData: (serialNo: string) => void;
};

export const SerialNumberInput: FC<SerialNumberInputProp> = ({
  onSubmitData,
}) => {
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: { serialNumber: '' },
    resolver: yupResolver(serialNumberSchema),
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
  } = formMethods;
  const onSubmit: SubmitHandler<FormInput> = (data) => {
    storeDispatch(
      'setSerialNumberInput',
      'serialNumberInput',
      data.serialNumber,
    );
    onSubmitData?.(data.serialNumber);
    f7CustomBack();
  };

  return (
    <PopupPageTemplate
      pageName="SerialNumberInput"
      title="シリアルナンバー入力"
      iconType="left"
      closeByBackdropClick={false}
      isCloseIcon
    >
      <FormProvider {...formMethods}>
        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label={'シリアルナンバー'}
            inputName={'serialNumber'}
            placeholder={'00000000000'}
            isCheckIcon
            isCheckIconValid={isValid}
            error={errors.serialNumber?.message}
          />

          <div className={style['button-wrap']}>
            <Button text="次へ" isActive isFixed type="submit" />
          </div>
        </form>
      </FormProvider>
    </PopupPageTemplate>
  );
};
