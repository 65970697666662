import React, { FC } from 'react';
import style from './SelfScanStep2Style.module.css';
import engineImage from '@/assets/images/engine.png';
import { Button } from '@/components/uiParts/Button';

interface SelfScanStep2Props {
  messageErrorScan: string;
  handleClickAction: () => void;
}

export const SelfScanStep2: FC<SelfScanStep2Props> = ({
  messageErrorScan,
  handleClickAction,
}) => {
  return (
    <div>
      <div className={style['corresponding-checklist']}>
        <div className={`${style.heading} ${style['check-paragraph']}`}>
          <i className={`icon-check`} />
          <h2>エンジンを始動してください</h2>
        </div>
        <div className={style.image}>
          <img width={'100%'} src={engineImage} alt="" />
        </div>
      </div>
      <div
        className={`${style['check-paragraph']} ${style.inactive} ${style['check-safety']}`}
      >
        <i className={`icon-check`} />
        <p>安全を確認してください</p>
      </div>
      <div className={`${style['check-paragraph']} ${style.inactive}`}>
        <i className={`icon-check`} />
        <p>診断を実施します</p>
      </div>
      {messageErrorScan && (
        <div className={style['scanning-error-message']}>
          {messageErrorScan}
        </div>
      )}
      <div className={style['button-wrap']}>
        <Button className={style.button} handleClickAction={handleClickAction}>
          始動した
        </Button>
      </div>
    </div>
  );
};
