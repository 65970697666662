import { baseApi } from '@/config/axios';
import {
  AccountInfoParams,
  AccountInfoResponse,
  LoginPermissionParams,
  LoginPermissionResponse,
} from '@/types/api/account';

export const fetchUserChangeApi = (
  params: AccountInfoParams,
): Promise<AccountInfoResponse> =>
  baseApi.post('rest/linkdrive/user_info_change', params);

export const loginPermissionApi = (
  params: LoginPermissionParams,
): Promise<LoginPermissionResponse> =>
  baseApi.get('/v1/customer/get-login-permission', { params });
