import { store } from '@/config/store';
import { ScoreLinkedTransmissionConsentParams } from '@/types/api/linkDriveApi';

export const fromPage = store.state.fromPage;
export const ownerId = store.state.ownerId;
export const customerId = store.state.authInfo.m_customer_id;
export const vinNo = store.state.carItemInfo.vin;
export const serialNumberInput = store.state.serialNumberInput;
export const warrantyNo = store.state.carItemInfo.warranties?.[0]?.warranty_no;
export const serialNumberDb = store.state.serialNumberDb;
export const vehicleId = store.state.linkDriveOwnerInfo.vehicle_id;
type LinkageType = {
  noLinkage: '0';
  drivingDataLinkage: '1';
  insurancePolicyLinkage: '2';
  drivingDataInsurancePolicyLinkage: '3';
};
export const linkageType: LinkageType = {
  noLinkage: '0',
  drivingDataLinkage: '1',
  insurancePolicyLinkage: '2',
  drivingDataInsurancePolicyLinkage: '3',
};
export const replaceConnectorApiParams = {
  owner_id: ownerId,
  new_serial_no: serialNumberInput,
};
export const vehicleChangeStatusApiParams = {
  m_customer_id: String(customerId),
  vin: vinNo,
};
export const changeVehicleApiParams = {
  m_customer_id: customerId,
  warranty_no: warrantyNo ?? '',
  serial_no: serialNumberInput,
};
export const collaborateOwnerApiParams = {
  customer_id: customerId,
  warranty_no: warrantyNo,
};
export const startConnectorUseApiParams = {
  owner_id: ownerId,
  serial_no: serialNumberInput || serialNumberDb || '',
  exchange_flag: 0,
};
export const updateScoreLinkedTransmissionConsentApiParams: ScoreLinkedTransmissionConsentParams =
  {
    owner_id: ownerId,
    linkage_type: linkageType.drivingDataLinkage,
  };

export const mCustomerId = store.state.authInfo.m_customer_id;
export const tStockCarId = store.state.carItemInfo.t_stock_car_id;
export const serialNo = store.state.serialNumberInput;
export const linkDriveOwnerApiParams = {
  params: {
    customer_id: mCustomerId,
    stock_car_id: tStockCarId,
    del_flg: 0,
  },
};
