import { useStore } from 'framework7-react';
import { useEffect, useState } from 'react';
import { store } from '@/config/store';
import { setupProcessingStatus } from '@/consts/linkDrive';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

export const steps = {
  SERIAL: 0,
  INTRO: 1,
  ENGINE: 1.1,
  PRE_CONFIRM: 1.2,
  STATUS_CHECK: 1.3,
  CONNECT: 1.4,
  INTERUPTED: 1.5,
  SUCCESS: 2.1,
};

export const useLinkDriveSetup = () => {
  const linkDriveUpdating = useStore(store, 'linkDriveUpdating') as number;
  const [step, setStep] = useState(steps.SERIAL);
  const [closeOpen, setCloseOpen] = useState(false);
  const handleClose = () => {
    if (step === steps.SUCCESS) {
      return f7CustomBack(2);
    }
    setCloseOpen(true);
  };
  const submitStep1 = () => {
    setStep(steps.INTRO);
  };
  const submitStep2 = () => {
    setStep(steps.PRE_CONFIRM);
  };
  const submitStep3 = () => {
    setStep(steps.SUCCESS);
  };
  const handleBack = () => {
    switch (step) {
      case steps.SERIAL:
        handleClose();
        break;
      case steps.INTRO:
        setStep(steps.SERIAL);
        break;
      case steps.ENGINE:
        setStep(steps.INTRO);
        break;
      case steps.PRE_CONFIRM:
        setStep(steps.ENGINE);
        break;
      default:
        handleClose();
        break;
    }
  };

  useEffect(() => {
    if (linkDriveUpdating === setupProcessingStatus.PROCESSING) {
      setStep(steps.CONNECT);
    }
  }, []);

  useEffect(() => {
    if (linkDriveUpdating === setupProcessingStatus.SUCCESS) {
      storeDispatch('setLinkDriveUpdating', 'linkDriveUpdating', false);
      storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0);
      setStep(steps.SUCCESS);
    }
  }, [linkDriveUpdating]);

  return {
    step,
    closeOpen,
    setStep,
    setCloseOpen,
    handleBack,
    handleClose,
    submitStep1,
    submitStep2,
    submitStep3,
  };
};
