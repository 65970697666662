import { Router } from 'framework7/types';
import { FC } from 'react';
import { UsePrepaidConfirm } from './UsePrepaidConfirm/UsePrepaidConfirm';

interface UsePrepaidComfirmPageProps {
  f7router: Router.Router;
  value: string;
  unit: string;
  handleChangeBalanceTotal: (chargeing: number) => void;
}

export const UsePrepaidConfirmPage: FC<UsePrepaidComfirmPageProps> = ({
  f7router,
  value,
  unit,
  handleChangeBalanceTotal,
}) => {
  return (
    <UsePrepaidConfirm
      f7router={f7router}
      value={value}
      unit={unit}
      handleChangeBalanceTotal={handleChangeBalanceTotal}
    />
  );
};
