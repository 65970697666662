export const mailSorting = {
  firstLogin: 1,
  afterSecond: 2,
} as const;

export const selectedProcess = {
  login: 1,
  register: 2,
  change: 3,
} as const;

export const mobileAuthSelectedProcess = {
  login: 1,
  change: 2,
  register: 3,
} as const;
