/* eslint-disable no-magic-numbers */
import { f7, Page, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button } from '@/components/uiParts/Button';
import { store } from '@/config/store';
import { Houseprepaid } from '@/types/api/prepaidApi';
import { storeDispatch } from '@/utils/store';
import './ReservingsStep1Style.scss';

export interface ReservingsStep1Props {
  changeStep: (step: number) => void;
  f7router: Router.Router;
  planList?: Houseprepaid[];
  selectedPlanInfo?: Houseprepaid;
}

export const ReservingsStep1: FC<ReservingsStep1Props> = ({
  changeStep,
  f7router,
  planList,
  selectedPlanInfo,
}) => {
  const reserveAmount = useStore(store, 'getReserveAmount') as number; // store.state.reserveAmount;
  const amountList = [
    { text: '+1,000円', value: 1000 },
    { text: '+3,000円', value: 3000 },
    { text: '+5,000円', value: 5000 },
  ];

  const [amount, setAmount] = useState<number | undefined>(
    Number(reserveAmount),
  );
  const monthlyChargeUnitPlan = useMemo(
    () => selectedPlanInfo?.monthly_charge_unit || 1,
    [selectedPlanInfo],
  );

  const updateAmount = (value?: number) => {
    if (value != null && value > 999999) {
      setAmount(999999);
      return;
    }
    setAmount(value);
  };

  //金額入力時の処理
  const changeAmount = (input: number) => {
    const amount = input > 0 ? input : undefined;
    updateAmount(amount);
  };

  //金額ボタン押下時の処理
  const handleAddChargeAmountClick = (value: number) => {
    if (amount) {
      updateAmount(amount + value);
    } else {
      updateAmount(value);
    }
  };

  //プラン選択時の処理
  const handleSelectedPlan = async (item: Houseprepaid, index: number) => {
    await storeDispatch(
      'setMPrepaidPlanId',
      'mPrepaidPlanId',
      item.m_prepaid_plan_id,
    );
    await storeDispatch('setPrepaidPlanName', 'prepaidPlanName', item.name);
  };

  //決済登録ボタン押下時の処理
  const handleClickRegister = async () => {
    const inputAmount = amount ?? 0;
    if (inputAmount < (selectedPlanInfo?.monthly_charge_min ?? 0)) {
      f7.dialog.alert(
        '',
        '積み立て金額が月額チャージ最低金額(' +
          selectedPlanInfo?.monthly_charge_min +
          '円)を下回っています。',
      );
      return;
    }
    if (selectedPlanInfo?.monthly_charge_max != null) {
      if (inputAmount > selectedPlanInfo?.monthly_charge_max) {
        f7.dialog.alert(
          '',
          '積み立て金額が月額チャージ最高金額(' +
            selectedPlanInfo?.monthly_charge_max +
            '円)を上回っています。',
        );
        return;
      }
    }

    if (inputAmount % monthlyChargeUnitPlan) {
      f7.dialog.alert(
        '',
        `${monthlyChargeUnitPlan.toLocaleString()}円単位で金額を指定してください。`,
      );
      return;
    }

    await storeDispatch('setReserveAmount', 'reserveAmount', amount);
    changeStep(1);
  };

  // GMOからのリダイレクト前の情報をストアに格納する
  useEffect(() => {
    const prepaidRegistrationInfo = window.localStorage.getItem(
      'prepaid_registration_info',
    );
    window.localStorage.removeItem('prepaid_registration_info');
    if (!prepaidRegistrationInfo) return;
    const { reserveAmount, mPrepaidPlanId, prepaidPlanName } = JSON.parse(
      prepaidRegistrationInfo,
    );
    storeDispatch('setReserveAmount', 'reserveAmount', reserveAmount);
    storeDispatch('setMPrepaidPlanId', 'mPrepaidPlanId', mPrepaidPlanId);
    storeDispatch('setPrepaidPlanName', 'prepaidPlanName', prepaidPlanName);
    changeStep(2);
  }, [changeStep]);

  return (
    <Page name="ReservingsStep1" pageContent={false}>
      <div className="content">
        <div className="heading-top">毎月の積み立て金額を設定してください</div>
        <div className="input-group">
          <div className="input-group-area">
            <NumericFormat
              value={amount}
              allowNegative={false}
              thousandSeparator=","
              placeholder="0"
              isAllowed={(values) => {
                const { value } = values;
                return !value || Number(value) <= 999999;
              }}
              onValueChange={(valueObject) => {
                changeAmount(Number(valueObject.value));
              }}
              pattern="\d*"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div className="input-group-icon">
            <span>円</span>
          </div>
        </div>
        <div className="button-group">
          {amountList.map((item, i) => (
            <div key={i} onClick={() => handleAddChargeAmountClick(item.value)}>
              {item.text}
            </div>
          ))}
        </div>
        <div className="text-group">
          ※ 最低積み立て金額は
          {selectedPlanInfo?.monthly_charge_min
            ? selectedPlanInfo?.monthly_charge_min.toLocaleString()
            : '1,000'}
          円/月です。
        </div>
        <div className="text-group">
          ※ {monthlyChargeUnitPlan.toLocaleString()}
          円単位で設定できます。
        </div>

        <div className="plan-group">
          <h2 className="title">プランを選択してください</h2>
          {(planList || []).map((item, index) => (
            <div
              className="plan-card"
              key={index}
              onClick={() => handleSelectedPlan(item, index)}
            >
              <div className="radio-container">
                <input
                  className="radio-input"
                  type="radio"
                  checked={
                    item?.m_prepaid_plan_id ===
                    selectedPlanInfo?.m_prepaid_plan_id
                  }
                  onChange={() => {}}
                />
                <span className="custom-radio" />
                <label className="radio-label">プラン名：{item.name}</label>
              </div>
            </div>
          ))}
        </div>

        <div className="button-wrap">
          <Button
            text="決済方法の登録へ"
            handleClickAction={handleClickRegister}
          />
        </div>
      </div>
    </Page>
  );
};
