import { CSSProperties } from 'react';
import style from './scheduledRefundDetailStyle.module.css';
import { ListRoundSheet } from '@/components/uiParts/Sheet/ListRoundSheet/ListRoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';

const listStyle: CSSProperties = { paddingLeft: 0, paddingRight: 0 };
const titleStyle: CSSProperties = {
  fontSize: '18px',
  padding: '8px 0 16px 0',
  borderBottom: '1px solid #efefef',
  width: '100%',
};

export const ScheduledRefundDetail = () => {
  const refundable = (
    <div className={style['refundable']}>
      <p>保証</p>
      <p>00-00 ランドクルーザーブラド</p>
    </div>
  );

  // TODO: value動的に置き換え
  const refundInfoList = [
    { label: '返金対象', value: refundable },
    { label: '返金理由', value: '誤請求' },
    { label: '返金金額', value: '8000円' },
    { label: '返金予定日', value: '2022年6月25日' },
  ];
  const refundToList = [
    { label: '金融機関名', value: '保証' },
    { label: '支店名', value: '誤請求' },
    { label: '口座種別', value: '8000円' },
    { label: '口座番号', value: '2022年6月25日' },
    { label: '口座名義人', value: '2022年6月25日' },
  ];

  return (
    <PageTemplate
      pageName="ScheduledRefundDetail"
      title="返金予定詳細"
      showNavBack
    >
      <div className={style['container']}>
        <ListRoundSheet
          title="返金情報"
          list={refundInfoList}
          listStyle={listStyle}
          titleStyle={titleStyle}
          isValueEnd={false}
        />
        <ListRoundSheet
          title="返金先"
          list={refundToList}
          listStyle={listStyle}
          titleStyle={titleStyle}
          isValueEnd={false}
        />
      </div>
    </PageTemplate>
  );
};
