import { f7 } from 'framework7-react';
import { FC } from 'react';
import { CalibrationProcessBar } from '../CalibrationProcessBar/CalibrationProcessBar';
import carHorizontal from '@/assets/images/carHorizontal.png';
import carOblique from '@/assets/images/carOblique.png';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { paths } from '@/config/paths';
import './LinkDriveCheckHorizontalStyle.scss';

interface LinkDriveCheckHorizontalProps {
  handleClickAction: () => void;
}

export const LinkDriveCheckHorizontal: FC<LinkDriveCheckHorizontalProps> = ({
  handleClickAction,
}) => {
  // TODO: キャンセル処理と中止処理未完成
  const handleClickInterruptionCalibration = () => {
    f7.view.main.router.navigate(paths.connectorSetting);
    f7.popup.close('#LinkDriveCheckHorizontal');
  };

  return (
    <div className="linkDriveCheckHorizontal">
      <CalibrationProcessBar />
      <p className="title">現在の位置が水平かどうかを確認してください。</p>
      <div className="content">
        <div className="contentBox">
          <p className="iconHorizontal">
            <span>&#10003;</span>OK
          </p>
          <img className="image" src={carHorizontal} alt="" />
          <p className="note">水平な位置からスタートさせてください</p>
        </div>
        <div className="contentBox">
          <p className="iconOblique">
            <span>!</span>NG
          </p>
          <img className="image" src={carOblique} alt="" />
          <p className="note">水平な位置からスタートさせてください</p>
        </div>
      </div>
      <div className="checkHorizontalButton">
        <Button
          style={{ backgroundColor: '#1E55E6' }}
          text="次へ"
          handleClickAction={handleClickAction}
        />
      </div>
    </div>
  );
};
