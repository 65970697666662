import { FC } from 'react';

interface MedalIconProps {
  color: string;
  isCurrentRank?: boolean;
}

export const MedalIcon: FC<MedalIconProps> = ({
  color,
  isCurrentRank = false,
}) => {
  const size = isCurrentRank ? '48' : '32';
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2002 32C30.8276 32 36.2002 26.6274 36.2002 20C36.2002 13.3726 30.8276 8 24.2002 8C17.5728 8 12.2002 13.3726 12.2002 20C12.2002 26.6274 17.5728 32 24.2002 32ZM24.2002 36C33.0368 36 40.2002 28.8366 40.2002 20C40.2002 11.1634 33.0368 4 24.2002 4C15.3636 4 8.2002 11.1634 8.2002 20C8.2002 28.8366 15.3636 36 24.2002 36Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2002 24C26.4093 24 28.2002 22.2091 28.2002 20C28.2002 17.7909 26.4093 16 24.2002 16C21.9911 16 20.2002 17.7909 20.2002 20C20.2002 22.2091 21.9911 24 24.2002 24ZM24.2002 28C28.6185 28 32.2002 24.4183 32.2002 20C32.2002 15.5817 28.6185 12 24.2002 12C19.7819 12 16.2002 15.5817 16.2002 20C16.2002 24.4183 19.7819 28 24.2002 28Z"
        fill={color}
      />
      <path
        d="M16.2002 30L24.8604 35L18.8604 45.3923L15.5303 41.1603L10.2002 40.3923L16.2002 30Z"
        fill={color}
      />
      <path
        d="M32.8564 30L24.1962 35L30.1962 45.3923L33.5263 41.1603L38.8564 40.3923L32.8564 30Z"
        fill={color}
      />
    </svg>
  );
};
