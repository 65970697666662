import { baseApi } from '@/config/axios';
import { paymentTypeId } from '@/consts/payment';
import {
  GmoCardParameterParams,
  GmoCardParameterResponse,
  PaymentCardParams,
  PaymentCardResponse,
  PaymentListParams,
  PaymentListResponse,
  RegisterBankRequestParams,
  RegisterBankRequestResponse,
} from '@/types/api/payment';
import { PaymentTypeId } from '@/types/payment';

export const getGmoCardParameterApi = (
  params: GmoCardParameterParams,
): Promise<GmoCardParameterResponse> =>
  baseApi.get('/v1/customer/payment/getGmoCardParameter', { params });

export const getPaymentCard = (
  params: PaymentCardParams,
): Promise<PaymentCardResponse> =>
  baseApi.get('/v1/customer/payment/card', { params });

//Todo: any調査
export const registerBankRequestApi = (
  params: RegisterBankRequestParams,
): Promise<RegisterBankRequestResponse> =>
  baseApi.post('/v1/customer/payment/registBankRequest', params);

export const getPaymentListApi = (
  params: PaymentListParams,
): Promise<PaymentListResponse> =>
  baseApi.get(`/v1/customer/payment/list`, { params });

export const deletePaymentOption = (params: {
  paymentTypeId: PaymentTypeId;
  paymentMethodId: string;
}) => {
  const methodType =
    Number(params.paymentTypeId) === paymentTypeId.BANK ? 'bank' : 'card';
  if (
    params.paymentTypeId === paymentTypeId.BANK ||
    params.paymentTypeId === paymentTypeId.CREDIT
  ) {
    return baseApi.delete(
      `/v1/customer/payment/${methodType}/${params.paymentMethodId}`,
    );
  }
  return baseApi.delete('/v1/customer/payment-info/', {
    params: {
      payment_method_type: params.paymentTypeId,
      payment_method_id: params.paymentMethodId,
    },
  });
};
