import { f7, useStore } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import style from './PaymentRegister.module.scss';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { paymentTypeId } from '@/consts/payment';
import { useGmo } from '@/hooks/useGmo';
import { Car, PaymentMethod } from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';
import { formatDateISO, formatToJaDate } from '@/utils/date';
import { switchPaymentIcon, switchPaymentMethodDesc } from '@/utils/payment';

type PaymentRegisterProps = {
  redirectPage: string;
  choosePaymentMethod: (item: PaymentMethod) => void;
  paymentMethodSelected?: PaymentMethod;
};

export const PaymentRegister: FC<PaymentRegisterProps> = ({
  redirectPage,
  paymentMethodSelected,
  choosePaymentMethod,
}) => {
  const { m_car_id, t_order_id } =
    (useStore(store, 'carItemInfo') as Car) || {};
  const reserveAmount = useStore(store, 'getReserveAmount') as number; // store.state.reserveAmount;

  const [actionUrl, setActionUrl] = useState('');
  const { addCreditCardPaymentMethod, GMOParameters } = useGmo(redirectPage);
  const {
    globalTheme,
    isLoggedInToMaikuru,
    paymentMethodsInfo,
    loginInfo: { loginType },
  } = store.state;
  const handleClickWithdrawFromBank = () => {
    const payments = paymentMethodsInfo.map(
      ({ m_payment_method_id, m_payment_type_id }) => ({
        m_payment_method_id,
        m_payment_type_id,
      }),
    );
    window.localStorage.setItem('payment_methods', JSON.stringify(payments));
    window.localStorage.setItem('m_car_id', String(m_car_id));
    window.localStorage.setItem('t_order_id', String(t_order_id));
    window.localStorage.setItem('loginType', String(loginType));
    window.localStorage.setItem('globalTheme', `${globalTheme}`);
    window.localStorage.setItem(
      'isLoggedInToMaikuru',
      `${isLoggedInToMaikuru}`,
    );
    window.localStorage.setItem('reserve_amount', reserveAmount.toString());

    f7.view.main.router.navigate(paths.registerBank, {
      props: {
        redirectPage: redirectPage,
      },
    });
  };

  const handleClickCreditCardPayment = async () => {
    const { token, m_customer_id } = store.state.authInfo;
    const {
      globalTheme,
      isLoggedInToMaikuru,
      paymentMethodsInfo,
      mPrepaidPlanId,
    } = store.state;
    const prepaidPlanName = store.state.prepaidPlanName;
    const payments = paymentMethodsInfo.map(
      ({ m_payment_method_id, m_payment_type_id }) => ({
        m_payment_method_id,
        m_payment_type_id,
      }),
    );
    const prepaidRegistrationInfo = {
      reserveAmount,
      mPrepaidPlanId,
      prepaidPlanName,
    };
    window.localStorage.setItem(
      'prepaid_registration_info',
      JSON.stringify(prepaidRegistrationInfo),
    );
    window.localStorage.setItem('payment_methods', JSON.stringify(payments));
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('m_customer_id', m_customer_id.toString());
    window.localStorage.setItem('m_car_id', String(m_car_id));
    window.localStorage.setItem('t_order_id', String(t_order_id));
    window.localStorage.setItem('loginType', String(loginType));
    window.localStorage.setItem('globalTheme', `${globalTheme}`);
    window.localStorage.setItem(
      'isLoggedInToMaikuru',
      `${isLoggedInToMaikuru}`,
    );
    window.localStorage.setItem('destination_step', '2');
    window.localStorage.setItem('destination_page', paths.reservings);
    window.localStorage.setItem('reserve_amount', reserveAmount.toString());

    await addCreditCardPaymentMethod(setActionUrl);
    // 一回目のクリック時点ではsetActionUrlでactionUrlが更新されるが
    // 非同期処理のためから文字のまま
    document.getElementById('submit')?.click();
  };

  // 一回目のクリックでactionUrlが更新されたらもう一度クリック
  useEffect(() => {
    document.getElementById('submit')?.click();
  }, [actionUrl]);

  return (
    <div className={style['register-box']}>
      {paymentMethodsInfo.length > 0 && (
        <p className={style.heading}>登録済みの決済方法を選択</p>
      )}
      {paymentMethodsInfo.map((item, index: number) => (
        <div
          className={style['payment-method']}
          key={index}
          onClick={() => choosePaymentMethod(item)}
        >
          <div className={style['radio-container']}>
            <input
              className={style['radio-input']}
              type="radio"
              checked={
                item.m_payment_method_id ===
                  paymentMethodSelected?.m_payment_method_id &&
                item.m_payment_type_id ===
                  paymentMethodSelected?.m_payment_type_id
              }
              onChange={() => {}}
            />
            <span className={style['custom-radio']} />
          </div>
          <div className={style['payment-method-text']}>
            <p>
              <i
                className={`icon ${switchPaymentIcon(
                  item.m_payment_type_id as PaymentTypeId,
                )}`}
              />
              {switchPaymentMethodDesc(
                item.m_payment_type_id,
                item.bank_name,
                item.payment_type_name,
              )}
            </p>
            <p className={style['payment-info']}>
              {item.m_payment_type_id == paymentTypeId.BANK ? (
                <>
                  <span className={style['bank-name']}>{item.bank_name}</span>
                  {`口座番号： ${item.account_number ?? '口座番号情報なし'}`}
                </>
              ) : (
                item.card_no
              )}
            </p>
            <p className={style['payment-info']}>
              {`登録日：${formatToJaDate(
                formatDateISO(item?.register || new Date().toISOString()),
              )}`}
            </p>
          </div>
        </div>
      ))}

      <p className={`${style.heading} ${style['mt-1']}`}>
        新たに決済方法を追加
      </p>
      <div className={style['payment-register']}>
        <div
          className={style['payment-register-column']}
          onClick={handleClickWithdrawFromBank}
        >
          <i className={`icon-bank icon`} />
          <p>銀行から引き落し</p>
        </div>

        <div
          className={style['payment-register-column']}
          onClick={handleClickCreditCardPayment}
        >
          <i className={`icon-credit-card icon`} />
          <p>クレジットカード</p>
        </div>
      </div>
      {actionUrl && (
        <form action={actionUrl} method="POST">
          <input type="hidden" name="SiteID" value={GMOParameters.SiteID} />
          <input type="hidden" name="MemberID" value={GMOParameters.memberID} />
          <input type="hidden" name="ShopID" value={GMOParameters.ShopID} />
          <input
            type="hidden"
            name="MemberPassString"
            value={GMOParameters.memberPassString}
          />
          <input type="hidden" name="RetURL" value={GMOParameters.RetURL} />
          <input
            type="hidden"
            name="CancelURL"
            value={GMOParameters.CancelURL}
          />
          <input type="hidden" name="DateTime" value={GMOParameters.dateTime} />
          <input type="hidden" name="Enc" value={GMOParameters.Enc} />
          <input id="submit" type="submit" style={{ display: 'none' }} />
        </form>
      )}
    </div>
  );
};
