import { FC } from 'react';
import {
  CouponIcon,
  PrepaidChargeIcon,
  ReferralBenefitIcon,
  TransferBonusIcon,
  TireIcon,
  BirthdayGift,
} from './Icons';
import { BenefitType } from '@/consts/rank';

interface BenefitIconProps {
  color?: string;
  type: BenefitType;
  className?: string;
}

export const BenefitIcon: FC<BenefitIconProps> = ({ color, type }) => {
  switch (type) {
    case BenefitType.COUPON:
      return <CouponIcon color={color} />;
    case BenefitType.PREPAID_CHARGE:
      return <PrepaidChargeIcon color={color} />;
    case BenefitType.REFERRAL_BENEFIT:
      return <ReferralBenefitIcon color={color} />;
    case BenefitType.TRANSFER_BONUS:
      return <TransferBonusIcon color={color} />;
    case BenefitType.TIRE:
      return <TireIcon color={color} />;
    case BenefitType.BIRTHDAY_GIFT:
      return <BirthdayGift color={color} />;
    default:
      return <></>;
  }
};
