import { Router } from 'framework7/types';
import { FC } from 'react';
import { PrepaidStop } from './PrepaidStop/PrepaidStop';

type PrepaidStop = {
  f7router: Router.Router;
  event?: () => void;
};

export const PrepaidStopPage: FC<PrepaidStop> = ({ f7router, event }) => {
  return <PrepaidStop f7router={f7router} event={event} />;
};
