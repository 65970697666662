import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchTransportApi } from '@/api/carApi';
import { TransportCode } from '@/types/api/carApi';

export const useFetchTransport = () => {
  const [data, setData] = useState<TransportCode[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchTransportApi();
        setData(res.data.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, []);

  return {
    data,
    isLoading,
    error,
  };
};
