import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { PromptCarCheckDelivery } from './TodoDetail/PromptCarCheckDelivery';
import { PromptConfirmDetail } from './TodoDetail/PromptConfirmDetail';
import { PromptDeliveryDetail } from './TodoDetail/PromptDeliveryDetail';
import { PromptDepositDetail } from './TodoDetail/PromptDepositDetail';
import { PromptDocumentDetail } from './TodoDetail/PromptDocumentDetail';
import { inputCustomerApi } from '@/api/todoListApi';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import { TASK_ID } from '@/consts/news';
import { useToast } from '@/hooks/useToast';
import { Prepaid, Warranty } from '@/types/api/customerApi';
import { dateToHyphenConnection } from '@/utils/date';

type TodoDetailPageProps = {
  f7router: Router.Router;
  item: any;
};
const carCheckDelivery = [
  TASK_ID.CAR_CHECK_DELIVERY,
  TASK_ID.CAR_CHECK_01_MAINTE_CAR_INSPECT,
  TASK_ID.CAR_CHECK_02_MAINTE_CAR_INSPECT,
];
export const TodoDetailPage: FC<TodoDetailPageProps> = ({ f7router, item }) => {
  const { openToast } = useToast();
  const [isMarkTodoCompleted, setIsMarkTodoCompleted] = useState(false);
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Prepaid
    | undefined;
  const warranties = useStore(store, 'getWarranties') as Warranty[];
  const title =
    item.task_id !== TASK_ID.DELIVERY_FOR_CUSTOMER_BY_CUSTOMER
      ? 'やることリスト'
      : !prepaid && !warranties.length
      ? '納車確認'
      : '重要事項の確認';

  const getMarkCompletedParams = () => {
    let markCompletedParams: any = {
      t_wf_instance_task_id: Number(item.t_wf_instance_task_id),
      task_id: item.task_id,
    };

    if (item.task_id !== TASK_ID.PAYMENT_COMPLETE) {
      markCompletedParams = {
        ...markCompletedParams,
        ...item,
      };
    }

    if (item.task_id === TASK_ID.DOCUMENTS_SEND) {
      markCompletedParams.mail_day = dateToHyphenConnection(new Date());
    }
    return markCompletedParams;
  };

  const handleClickCompleted = async () => {
    let markCompletedParams = getMarkCompletedParams();
    f7.preloader.show();

    const res = await inputCustomerApi(markCompletedParams);
    f7.preloader.hide();

    if (res.data.success) {
      openToast('送信に成功しました');
      setIsMarkTodoCompleted(true);
    } else {
      openToast('エラーが発生しました', 'toast-failed');
    }
  };

  if (!item) return <></>;

  return (
    <PageTemplate pageName="TodoDetail" showNavBack title={title}>
      {item.task_id === TASK_ID.DOCUMENTS_SEND && (
        <PromptDocumentDetail
          f7router={f7router}
          handleClickCompleted={() => setIsMarkTodoCompleted(true)}
          item={item}
          isMarkTodoCompleted={isMarkTodoCompleted}
        />
      )}

      {item.task_id === TASK_ID.DELIVERY_PREFERRED_DATE && (
        <PromptDeliveryDetail f7router={f7router} item={item} />
      )}

      {item.task_id === TASK_ID.PAYMENT_COMPLETE && (
        <PromptDepositDetail
          f7router={f7router}
          handleClickCompleted={() => setIsMarkTodoCompleted(true)}
          item={item}
          isMarkTodoCompleted={isMarkTodoCompleted}
        />
      )}

      {item.task_id === TASK_ID.DELIVERY_FOR_CUSTOMER_BY_CUSTOMER && (
        <PromptConfirmDetail item={item} />
      )}
      {carCheckDelivery.includes(item.task_id) && (
        <PromptCarCheckDelivery item={item} />
      )}
    </PageTemplate>
  );
};
