import { Icon, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import Calendar from 'react-calendar';
import {
  CalenderLinkDrive,
  DateClass,
} from '@/components/pages/DriveCalender/CalenderLinkDrive/CalenderLinkDrive';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { useDrivingDays } from '@/hooks/api/driveRecord/useDrivingDays';
import { Car } from '@/types/api/customerApi';
import {
  DATE_MONTH_INCREMENTER,
  dateToHyphenConnection,
  timeAdd0,
} from '@/utils/date';
import 'react-calendar/dist/Calendar.css';
import './DriveCalenderStyle.scss';
import { isAdContractorData } from '@/utils/home';
import { f7CustomBack } from '@/utils/utils';

interface DriveCalenderProps {
  f7router: Router.Router;
}

export const DriveCalender: FC<DriveCalenderProps> = () => {
  const ownerId = store.state.linkDriveOwnerInfo.owner_id;
  const selectedDay = useStore(store, 'getSelectedDay');
  const maxDate = new Date();
  const date = selectedDay ? new Date(selectedDay) : new Date();
  const diagnosisDate =
    date.getFullYear() + timeAdd0(String(date.getMonth() + 1));
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;

  const isAdContractor = isAdContractorData({
    linkDriveStatus: selectedCar?.linkdrive_status,
  });

  const [params, setParams] = useState({
    owner_id: ownerId,
    diagnosis_date_ym: diagnosisDate,
  });

  const { data } = useDrivingDays(params);

  const [value, onChange] = useState(date);
  const [isSelectingMonth, setIsSelectingMonth] = useState(false);

  //日を押下した場合その日時をstoreに格納
  const clickDay = (value: Date) => {
    if (!data?.day?.includes(String(value.getDate())) && !isAdContractor) {
      return;
    }
    const driveHistoryDay = dateToHyphenConnection(value);
    store.dispatch('setSelectedDay', { selectedDay: value });
    store.dispatch('setDiagnosticDate', { diagnosticDate: driveHistoryDay });
    f7CustomBack(['#driveCalendarPopUp']);
  };

  const clickMonth = (value: Date) => {
    const year = value.getFullYear();
    const month = timeAdd0(String(value.getMonth() + 1));
    const clickDate = year + month;
    setParams({
      ...params,
      diagnosis_date_ym: clickDate,
    });
  };

  const clickNextMonth = ({ activeStartDate = new Date() }) => {
    onChange(activeStartDate);
    const year = activeStartDate.getFullYear();
    const month = timeAdd0(String(activeStartDate.getMonth() + 1));
    const clickDate = year + month;
    setParams({
      ...params,
      diagnosis_date_ym: clickDate,
    });
  };

  const renderContent = () => {
    if (isSelectingMonth) {
      return (
        <Calendar
          calendarType="Hebrew"
          prev2Label={null}
          next2Label={null}
          // minDetail={'year'}
          onClickMonth={(value) => {
            onChange(value);
          }}
          nextLabel={
            <div className="control-label">
              <Icon f7="chevron_right" size={24} />
            </div>
          }
          prevLabel={
            <div className="control-label">
              <Icon f7="chevron_left" size={24} />
            </div>
          }
          navigationLabel={({ date }: DateClass) => {
            const year = date.getFullYear();
            const month = value.getMonth() + DATE_MONTH_INCREMENTER;
            return (
              <a
                className="custom-navigation-label"
                onClick={() => setIsSelectingMonth(false)}
              >
                {year}年{month}月
                <Icon
                  f7="chevron_down"
                  size={21}
                  style={{ marginLeft: '0.5rem' }}
                />
              </a>
            );
          }}
          // tileClassName={'month-tile'}
          tileClassName={({ date }: DateClass) => {
            let className = '';
            if (
              value.getMonth() == date.getMonth() &&
              value.getFullYear() == date.getFullYear()
            ) {
              className = 'active';
            }
            return `${className} month-tile`;
          }}
          formatMonth={(locale, date) => {
            const month = date.getMonth() + DATE_MONTH_INCREMENTER;
            return `${month}月`;
          }}
          value={value}
          view="year"
          maxDate={maxDate}
        />
      );
    }
    return (
      <div id="day-selection">
        <Calendar
          calendarType="Hebrew"
          prev2Label={null}
          next2Label={null}
          value={value}
          onClickDay={(value) => {
            if (!data?.day?.includes(String(value.getDate()))) {
              return;
            }
            store.dispatch('setSelectedDay', { selectedDay: value });
          }}
          minDetail={'year'}
          onClickMonth={clickMonth}
          nextLabel={
            <div className="control-label">
              <Icon f7="chevron_right" size={24} />
            </div>
          }
          prevLabel={
            <div className="control-label">
              <Icon f7="chevron_left" size={24} />
            </div>
          }
          navigationLabel={({ date }: DateClass) => {
            const year = date.getFullYear();
            const month = date.getMonth() + DATE_MONTH_INCREMENTER;
            return (
              <a
                className="custom-navigation-label"
                onClick={() => setIsSelectingMonth(true)}
              >
                {year}年{month}月
                <Icon
                  f7="chevron_right"
                  size={21}
                  style={{ marginLeft: '0.5rem' }}
                />
              </a>
            );
          }}
          formatShortWeekday={(locale, date) => {
            const day = date.getDay();
            const weekday = ['日', '月', '火', '水', '木', '金', '土'];
            return weekday[day];
          }}
          formatDay={(_, date) => String(date.getDate())}
          tileClassName={({ date }: DateClass) => {
            let className = 'noneTileClass';
            if (value.getMonth() == date.getMonth()) {
              if (data?.day) {
                if (date.toString() == selectedDay?.toString())
                  className = 'tileActive';
                else if (data?.day.includes(String(date.getDate())))
                  className = 'tileClass';
                else className = 'noneTileClass';
              }
            } else className = 'diferrenceTile';
            return `${className} tile`;
          }}
          onActiveStartDateChange={clickNextMonth}
          maxDate={maxDate}
          formatWeekday={(locale, date) => {
            const day = date.getDay();
            const weekday = ['月', '火', '水', '木', '金', '土', '日'];
            return weekday[day];
          }}
        />
      </div>
    );
  };

  return (
    <PopupPageTemplate
      id="driveCalendarPopUp"
      pageName={isAdContractor ? 'DriveCalendar' : 'DriveCalendarLD'}
      title={isAdContractor ? 'カレンダーで絞り込む' : 'カレンダー'}
      isCloseIcon={!isAdContractor}
    >
      <div>
        <div className="close-icon-container">
          <a
            onClick={() => {
              if (isSelectingMonth) {
                setIsSelectingMonth(false);
                return;
              }
              f7CustomBack(['#driveCalendarPopUp']);
              return;
            }}
          >
            <Icon f7="multiply" size={24} className="close-icon" />
          </a>
        </div>
        {isAdContractor ? (
          <div>
            {renderContent()}
            <Button
              text={isSelectingMonth ? '選択する' : '絞り込む'}
              className="u-margin-top-xxl"
              handleClickAction={() => {
                if (isSelectingMonth) {
                  clickMonth(value);
                  setIsSelectingMonth(false);
                  return;
                }
                if (!selectedDay) {
                  return f7CustomBack(['#driveCalendarPopUp']);
                }
                clickDay(selectedDay);
              }}
            />
          </div>
        ) : (
          <CalenderLinkDrive
            value={value}
            onChange={onChange}
            clickDay={clickDay}
            clickNextMonth={clickNextMonth}
            clickMonth={clickMonth}
            data={data}
          />
        )}
      </div>
    </PopupPageTemplate>
  );
};
