import { FC } from 'react';
import { AboutContent } from './AboutContent';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';

type CarWashHandAboutPopupProps = {
  showLink?: boolean;
};

export const CarWashHandAboutPopup: FC<CarWashHandAboutPopupProps> = ({
  showLink = true,
}) => {
  return (
    <PopupPageTemplate
      id="CarWashHandAboutPopup"
      title="出張水なし手洗い洗車"
      pageName="CarWashHandAbout"
    >
      <AboutContent showLink={showLink} />
    </PopupPageTemplate>
  );
};
