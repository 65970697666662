import {
  Link,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
} from 'framework7-react';
import { CSSProperties, FC, ReactNode } from 'react';
import style from './CustomBgPageTemplateStyle.module.css';
import { MenuFooter } from '@/components/uiParts/MenuFooter/MenuFooter';
import { isIPhoneApp } from '@/config/device';

interface CustomBgPageTemplateProps {
  children: ReactNode;
  pageName: string;
  title: string;
  background: CSSProperties;
  showNavBack?: boolean;
  noScroll?: boolean;
  inlinePageStyle?: React.CSSProperties;
  className?: string;
  handleClickBack?: () => void;
}
export const CustomBgPageTemplate: FC<CustomBgPageTemplateProps> = ({
  children,
  pageName,
  title,
  background,
  showNavBack = false,
  noScroll = false,
  inlinePageStyle,
  className = '',
  handleClickBack,
}) => {
  // IPhone safe-area padding!
  const iPhoneTopPadding = 50;

  // IPhone only top Padding for safe-area has been added. Even on the prior model of Iphone X, it still adds the safe area padding regardless
  return (
    <Page
      name={pageName}
      className={`${className} ${
        !isIPhoneApp ? style['page'] : `${style['page']} ${style['ios-page']}`
      }`}
      style={{ ...inlinePageStyle }}
    >
      <Navbar noShadow noHairline>
        {showNavBack &&
          (!handleClickBack ? (
            <NavLeft>
              <Link back animate className={`${style['no-ripple']} no-ripple`}>
                <div className={style['icon-wrapper']}>
                  <i className={`${style['nav-icon']} icon-left no-ripple`} />
                </div>
              </Link>
            </NavLeft>
          ) : (
            <NavLeft>
              <Link
                onClick={handleClickBack}
                className={`${style['no-ripple']} no-ripple`}
              >
                <div className={style['icon-wrapper']}>
                  <i className={`${style['nav-icon']} icon-left no-ripple`} />
                </div>
              </Link>
            </NavLeft>
          ))}
        <NavTitle>{title}</NavTitle>
        <NavRight>
          <Link
            animate
            href="/help_top/"
            className={`${style['no-ripple']} no-ripple`}
          >
            <div className={style['icon-wrapper']}>
              <i className={`${style['nav-icon']} icon-help`} />
            </div>
          </Link>
        </NavRight>
      </Navbar>
      <div className={style['container']}>
        <div
          style={background}
          className={`${style['content']} ${
            noScroll ? style['no-scroll'] : ''
          }`}
          id="page-template-content"
        >
          {children}
        </div>
      </div>
      <MenuFooter />
    </Page>
  );
};
