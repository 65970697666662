import { Router } from 'framework7/types';
import {
  AvailableBankListPage,
  ChargePrepaidPage,
  PrepaidChangeMoneyPage,
  PrepaidHistoryDetailPage,
  PrepaidHistoryPage,
  PrepaidLawsPage,
  PrepaidPage,
  PrepaidSettingsPage,
  PrepaidSummaryPage,
  PrepaidTermsPage,
  ReservingsPage,
  RestartReservingsPage,
  ShopmapPage,
  StopReservingsCompletePage,
  StopReservingsConfirmPage,
  StopReservingsPage,
  UpdateReservingsPage,
  UsePrepaidCompletePage,
  UsePrepaidConfirmPage,
  UsePrepaidPage,
  PrepaidStopPage,
  PrepaidRestartPage,
} from '@/components/pages';
import { paths } from '@/config/paths';

export const prepaidRoutes: Router.RouteParameters[] = [
  {
    path: paths.prepaid,
    component: PrepaidPage,
    name: 'おサイフ',
  },
  {
    path: '/prepaid/:status',
    component: PrepaidPage,
    name: 'おサイフ',
  },
  {
    path: paths.usePrepaid,
    popup: {
      component: UsePrepaidPage,
    },
    options: {
      animate: true,
    },
    name: 'プリペイドサービス支払い金額入力',
  },
  {
    path: paths.usePrepaidConfirm,
    popup: {
      component: UsePrepaidConfirmPage,
    },
    options: {
      animate: true,
    },
    name: 'プリペイドサービス支払い確認',
  },
  {
    path: paths.usePrepaidComplete,
    popup: {
      component: UsePrepaidCompletePage,
    },
    options: {
      animate: true,
    },
    name: 'プリペイドサービス支払い完了',
  },
  {
    path: paths.chargePrepaid,
    component: ChargePrepaidPage,
    options: {
      animate: true,
    },
    name: 'チャージ',
  },
  {
    path: paths.prepaidHistory,
    component: PrepaidHistoryPage,
    name: 'プリペイドサービス履歴',
    options: {
      animate: true,
    },
  },
  {
    path: paths.prepaidHistoryDetail,
    component: PrepaidHistoryDetailPage,
    options: {
      animate: true,
    },
    name: '履歴詳細',
  },
  {
    path: paths.prepaidSummary,
    popup: {
      component: PrepaidSummaryPage,
    },
    options: {
      animate: true,
    },
    name: 'マイくるPay',
  },
  {
    path: paths.reservings,
    popup: {
      component: ReservingsPage,
    },
    name: 'プリペイド利用開始',
    options: {
      animate: true,
    },
  },
  {
    path: paths.availableBankList,
    popup: {
      component: AvailableBankListPage,
    },
    name: 'ご利用可能な銀行の一覧',
    options: {
      animate: true,
    },
  },
  {
    path: paths.prepaidSetting,
    component: PrepaidSettingsPage,
    options: {
      animate: true,
    },
    name: 'プリペイド設定',
  },
  {
    path: paths.prepaidSettingStatus(':status'),
    component: PrepaidSettingsPage,
    options: {
      animate: true,
    },
    name: 'プリペイド設定',
  },
  {
    path: paths.updateReservings,
    popup: {
      component: UpdateReservingsPage,
    },
    options: {
      animate: true,
    },
    name: '積立更新',
  },
  {
    path: paths.stopReservings,
    popup: {
      component: StopReservingsPage,
    },
    options: {
      animate: true,
    },
    name: '積立の停止',
  },
  {
    path: paths.restartReservings,
    component: RestartReservingsPage,
    name: '積立を再開する',
  },
  {
    path: paths.stopReservingsConfirm,
    component: StopReservingsConfirmPage,
    name: '積立プラン停止確認',
  },
  {
    path: paths.stopReservingsComplete,
    component: StopReservingsCompletePage,
    name: '積立プラン停止完了',
  },
  {
    path: paths.prepaidTerms,
    component: PrepaidTermsPage,
    name: 'プリペイド規約',
  },
  {
    path: paths.prepaidLaws,
    component: PrepaidLawsPage,
    name: '資金決済法',
  },
  {
    path: paths.prepaidChangeMoney,
    popup: {
      component: PrepaidChangeMoneyPage,
    },
    options: {
      animate: true,
    },
    name: '積立金額の変更',
  },
  {
    path: paths.shopmap,
    popup: {
      component: ShopmapPage,
    },
    name: '店舗MAP',
    options: {
      animate: true,
    },
  },
  {
    path: paths.prepaidStop,
    popup: {
      component: PrepaidStopPage,
    },
    options: {
      animate: true,
    },
    name: '積み立ての停止',
  },
  {
    path: paths.prepaidRestart,
    popup: {
      component: PrepaidRestartPage,
    },
    options: {
      animate: true,
    },
    name: '積み立てを再開',
  },
];
