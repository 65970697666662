import { useState } from 'react';
import { getContractInfo } from '../DriveRecord/DriveRecordHighLight/DriveRecordHighLightHelper';
import { TotalScore } from './TotalScore/TotalScore';
import { store } from '@/config/store';
import { useCalcScore } from '@/hooks/api/driveRecord/useCalcScore';
import { useContractInformation } from '@/hooks/api/linkDrive/useContractInformation';
import {
  dateToHyphenConnection,
  getStartDateSomeMonthsAgo,
  dateToFullYearMonth,
} from '@/utils/date';

export const TotalScorePage = () => {
  const today = new Date();
  const storeState = store.state;
  const contractParams = {
    owner_id: storeState.ownerId,
    device_key: storeState.linkDriveOwnerInfo.device_key,
    period_of_insurance: dateToHyphenConnection(getStartDateSomeMonthsAgo(4)),
  };

  const calcScoreInitialParams = {
    m_customer_id: storeState.authInfo.m_customer_id,
    vehicle_id: storeState.linkDriveOwnerInfo.vehicle_id,
    report_date_ym: dateToFullYearMonth(today),
  };
  const linkDriveOwnerInfo = store.state.linkDriveOwnerInfo;
  const [insuredDate, setInsuredDate] = useState(today);
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [calcScoreParams, setCalcScoreParams] = useState(
    calcScoreInitialParams,
  );

  const { data: contractionData } = useContractInformation(contractParams);
  const contractInfo = getContractInfo(contractionData?.data);
  const { data: calcScoreData } = useCalcScore(calcScoreParams);

  const handleChangeInsuredDate = (date: Date) => {
    setInsuredDate(date);
    setCalcScoreParams({
      ...calcScoreParams,
      report_date_ym: dateToFullYearMonth(date),
    });
  };

  const handlePopup = () => {
    setIsPopupOpened(!isPopupOpened);
  };

  return (
    <TotalScore
      linkDriveOwner={linkDriveOwnerInfo}
      isPopupOpened={isPopupOpened}
      insuredDate={insuredDate}
      contractionData={contractInfo?.specialContract}
      calcScoreData={calcScoreData}
      handleChangeInsuredDate={handleChangeInsuredDate}
      handlePopup={handlePopup}
    />
  );
};
