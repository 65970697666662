import React, { FC } from 'react';
import './NotifyByPeriod.scss';
import { ReactComponent as InfoIcon } from '@/assets/images/icon-info.svg';
import { compareDate, stringToDate } from '@/utils/date';

type NotifyByDateProps = {
  displayStartDate: string;
  displayEndDate: string;
  title: string;
  description?: string;
};

export const NotifyByDate: FC<NotifyByDateProps> = ({
  displayStartDate,
  displayEndDate,
  title,
  description,
}) => {
  const compareStartDate = compareDate(
    stringToDate(displayStartDate),
    new Date(),
  );
  let endDate = stringToDate(displayEndDate);
  endDate.setDate(endDate.getDate() + 1);

  const compareEndDate = compareDate(new Date(), endDate);
  const isValid = compareStartDate && compareEndDate;
  return (
    <>
      {isValid && (
        <div className={'u-margin-bottom-sm'}>
          <div className="notify-by-date info-card">
            <div className="card-container">
              <div className="container-title">
                <InfoIcon />
                <h2>{title}</h2>
              </div>
              <div className="container-desc u-margin-top-xs">
                {description}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
