import { IconProps } from './type';

export const FactoryStockIcon = ({
  width = '56',
  height = '57',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill={color} fillOpacity="0.1" />
      <path
        d="M16.3933 32.6175C16.634 31.1074 17.9365 29.9961 19.4657 29.9961H36.5295C38.0587 29.9961 39.3611 31.1074 39.6018 32.6175L39.9976 35.1003L40.1906 37.9151C40.2214 38.3645 39.8651 38.7461 39.4146 38.7461H16.5805C16.13 38.7461 15.7737 38.3645 15.8045 37.9151L15.9976 35.1003L16.3933 32.6175Z"
        fill="#B1C7FC"
      />
      <path
        d="M20.154 25.3085C20.5189 23.949 21.7511 23.0039 23.1587 23.0039H32.8364C34.244 23.0039 35.4762 23.949 35.8412 25.3085L36.4976 27.7539H19.4976L20.154 25.3085Z"
        fill="#B1C7FC"
      />
      <path
        d="M40.522 39.6942H15.4829C14.9414 39.6942 14.5024 39.2553 14.5024 38.7137C14.5024 36.495 14.7047 34.2809 15.1067 32.0988L15.1613 31.8023C15.4575 30.1945 16.8593 29.0273 18.4941 29.0273H37.5108C39.1456 29.0273 40.5474 30.1945 40.8436 31.8023L40.8982 32.0988C41.3002 34.2809 41.5024 36.495 41.5024 38.7137C41.5024 39.2553 41.0635 39.6942 40.522 39.6942Z"
        stroke="#323232"
        strokeLinecap="round"
      />
      <path
        d="M37.875 28.8092H18.125L19.0549 24.6763C19.4028 23.1299 20.7761 22.0312 22.3612 22.0312H33.6388C35.2239 22.0312 36.5972 23.1299 36.9451 24.6763L37.875 28.8092Z"
        stroke="#323232"
        strokeLinecap="round"
      />
      <path
        d="M21.7765 39.8029V41.3585C21.7765 42.2176 21.0801 42.9141 20.221 42.9141H18.6654C17.8063 42.9141 17.1099 42.2176 17.1099 41.3585V39.8029"
        stroke="#323232"
        strokeLinecap="round"
      />
      <path
        d="M38.8888 39.8029V41.3585C38.8888 42.2176 38.1924 42.9141 37.3333 42.9141H35.7777C34.9186 42.9141 34.2222 42.2176 34.2222 41.3585V39.8029"
        stroke="#323232"
        strokeLinecap="round"
      />
      <path
        d="M21.2765 33.1888C21.2765 34.2014 20.4557 35.0222 19.4432 35.0222C18.4307 35.0222 17.6099 34.2014 17.6099 33.1888C17.6099 32.1763 18.4307 31.3555 19.4432 31.3555C20.4557 31.3555 21.2765 32.1763 21.2765 33.1888Z"
        fill="white"
        stroke="#323232"
      />
      <path
        d="M38.3888 33.1888C38.3888 34.2014 37.568 35.0222 36.5555 35.0222C35.543 35.0222 34.7222 34.2014 34.7222 33.1888C34.7222 32.1763 35.543 31.3555 36.5555 31.3555C37.568 31.3555 38.3888 32.1763 38.3888 33.1888Z"
        fill="white"
        stroke="#323232"
      />
      <path
        d="M14.8853 26.9774C14.8853 26.3945 15.3578 25.9219 15.9408 25.9219H17.1544C17.8205 25.9219 18.3201 26.5313 18.1895 27.1845L17.8642 28.8108H15.9408C15.3578 28.8108 14.8853 28.3382 14.8853 27.7553V26.9774Z"
        stroke="#323232"
      />
      <path
        d="M41.1147 26.9774C41.1147 26.3945 40.6422 25.9219 40.0592 25.9219H38.8456C38.1795 25.9219 37.6799 26.5313 37.8105 27.1845L38.1358 28.8108H40.0592C40.6422 28.8108 41.1147 28.3382 41.1147 27.7553V26.9774Z"
        stroke="#323232"
      />
      <rect
        x="21.7778"
        y="35.9219"
        width="12.4444"
        height="0.75"
        rx="0.375"
        fill="#323232"
      />
      <rect
        x="21.7778"
        y="37.4688"
        width="12.4444"
        height="0.75"
        rx="0.375"
        fill="#323232"
      />
      <path
        d="M23.3164 33.5868C23.3164 32.9348 23.845 32.4062 24.497 32.4062H31.4969C32.149 32.4062 32.6775 32.9348 32.6775 33.5868V34.7674H23.3164V33.5868Z"
        fill="white"
        stroke="#323232"
        strokeWidth="0.75"
      />
      <mask id="path-15-inside-1_29664_120266" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0049 10.4961C8.90032 10.4961 8.00488 11.3915 8.00488 12.4961L8.00488 18.4961V43.4961C8.00488 44.0484 8.4526 44.4961 9.00488 44.4961H11.0049C11.5572 44.4961 12.0049 44.0484 12.0049 43.4961V19.4961H44.0049V43.4961C44.0049 44.0484 44.4526 44.4961 45.0049 44.4961H47.0049C47.5572 44.4961 48.0049 44.0484 48.0049 43.4961V19.4961V18.4961V12.4961C48.0049 11.3915 47.1095 10.4961 46.0049 10.4961H10.0049Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0049 10.4961C8.90032 10.4961 8.00488 11.3915 8.00488 12.4961L8.00488 18.4961V43.4961C8.00488 44.0484 8.4526 44.4961 9.00488 44.4961H11.0049C11.5572 44.4961 12.0049 44.0484 12.0049 43.4961V19.4961H44.0049V43.4961C44.0049 44.0484 44.4526 44.4961 45.0049 44.4961H47.0049C47.5572 44.4961 48.0049 44.0484 48.0049 43.4961V19.4961V18.4961V12.4961C48.0049 11.3915 47.1095 10.4961 46.0049 10.4961H10.0049Z"
        fill="#EFEFEF"
      />
      <path
        d="M8.00488 12.4961L9.00488 12.4961V12.4961H8.00488ZM8.00488 18.4961L7.00488 18.4961V18.4961H8.00488ZM12.0049 19.4961V18.4961H11.0049V19.4961H12.0049ZM44.0049 19.4961H45.0049V18.4961H44.0049V19.4961ZM9.00488 12.4961C9.00488 11.9438 9.4526 11.4961 10.0049 11.4961V9.49609C8.34803 9.49609 7.00488 10.8392 7.00488 12.4961H9.00488ZM9.00488 18.4961L9.00488 12.4961L7.00488 12.4961L7.00488 18.4961L9.00488 18.4961ZM7.00488 18.4961V43.4961H9.00488V18.4961H7.00488ZM7.00488 43.4961C7.00488 44.6007 7.90031 45.4961 9.00488 45.4961V43.4961H9.00488H7.00488ZM9.00488 45.4961H11.0049V43.4961H9.00488V45.4961ZM11.0049 45.4961C12.1095 45.4961 13.0049 44.6007 13.0049 43.4961H11.0049V45.4961ZM13.0049 43.4961V19.4961H11.0049V43.4961H13.0049ZM44.0049 18.4961H12.0049V20.4961H44.0049V18.4961ZM43.0049 19.4961V43.4961H45.0049V19.4961H43.0049ZM43.0049 43.4961C43.0049 44.6007 43.9003 45.4961 45.0049 45.4961V43.4961H43.0049ZM45.0049 45.4961H47.0049V43.4961H45.0049V45.4961ZM47.0049 45.4961C48.1095 45.4961 49.0049 44.6007 49.0049 43.4961H47.0049V45.4961ZM49.0049 43.4961V19.4961H47.0049V43.4961H49.0049ZM49.0049 19.4961V18.4961H47.0049V19.4961H49.0049ZM47.0049 12.4961V18.4961H49.0049V12.4961H47.0049ZM46.0049 11.4961C46.5572 11.4961 47.0049 11.9438 47.0049 12.4961H49.0049C49.0049 10.8392 47.6617 9.49609 46.0049 9.49609V11.4961ZM10.0049 11.4961H46.0049V9.49609H10.0049V11.4961Z"
        fill="#323232"
        mask="url(#path-15-inside-1_29664_120266)"
      />
    </svg>
  );
};
