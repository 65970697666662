import { FC } from 'react';
import { PrivacyPolicy } from './Privacy/PrivacyPolicy';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const PrivacyPage: FC = () => {
  const { data } = useTerm(termType.PRIVACY_POLICY);
  const html = data?.term;
  return (
    <PageTemplate pageName="Privacy" title="プライバシーポリシー" showNavBack>
      <PrivacyPolicy html={html} />
    </PageTemplate>
  );
};
