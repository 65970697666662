import { Box, Content, DisplayOptProps, Title } from './BoxCarditem';
import { categoryTypes } from '@/consts/carOrder';
import { PurchaseOrder, PurchaseOrderOptionItem } from '@/types/api/carApi';

const DISOUNT_TYPE_ID = 700;

const defaultOptionCostDisplayOpt: DisplayOptProps = {
  content: [
    {
      label: 'オプション費合計',
      amount: 0,
    },
  ],
  box: [],
};

const defaultExpenseDisplayOpt: DisplayOptProps = {
  content: [
    {
      label: '諸費用合計',
      amount: 0,
    },
  ],
  box: [],
};

function generateContents(
  items: PurchaseOrderOptionItem['type'][0]['groups'][0]['items'],
  isDiscount: boolean = false,
): Content[] {
  return items
    .map(({ name, price, additional_cost, additional_info, donation }) => {
      const amount = (price || 0) + (additional_cost || 0);
      const contents: Content[] = [
        {
          label: `${name} ${additional_info || ''}`,
          amount: isDiscount && amount > 0 ? -amount : amount,
        },
      ];
      if (donation) {
        contents.push({
          label: '希望ナンバー寄付金額',
          amount: donation,
        });
      }
      return contents;
    })
    .flat();
}

function processOptionCostList(optionCostItem: PurchaseOrderOptionItem): {
  boxs: Box[];
  total: number;
  discount: number;
} {
  // The Box array for UI
  const boxs: Box[] = optionCostItem.type.map(
    ({ groups, type_name, type_id }) => {
      const isDiscount = type_id === DISOUNT_TYPE_ID;
      // The Title array for UI
      const optionGroupTitles: Title[] = groups.map(({ items, group_name }) => {
        return {
          header: group_name || '',
          content: generateContents(items, isDiscount),
        };
      });
      const amount = optionGroupTitles.reduce(
        (acc, title) =>
          title.content.reduce((acc, content) => acc + content.amount, acc),
        0,
      );
      return {
        content: [
          {
            label: type_name,
            amount,
          },
        ],
        title: optionGroupTitles,
      };
    },
  );

  const total = optionCostItem.type.reduce((acc, { type_id, groups }) => {
    if (type_id === DISOUNT_TYPE_ID) return acc;
    const groupTotal = groups.reduce((groupAcc, { items }) => {
      const itemTotal = items.reduce(
        (itemAcc, { price, additional_cost, donation }) =>
          itemAcc + (price || 0) + (additional_cost || 0) + (donation || 0),
        0,
      );
      return groupAcc + itemTotal;
    }, 0);
    return acc + groupTotal;
  }, 0);

  const discount = optionCostItem.type.reduce((acc, { groups, type_id }) => {
    if (type_id === DISOUNT_TYPE_ID) {
      const groupDiscount = groups.reduce((groupAcc, { items }) => {
        const itemDiscount = items.reduce(
          (itemAcc, { price, additional_cost, donation }) =>
            itemAcc + (price || 0) + (additional_cost || 0) + (donation || 0),
          0,
        );
        return groupAcc + itemDiscount;
      }, 0);
      return acc + groupDiscount;
    }
    return acc;
  }, 0);

  return {
    boxs,
    total,
    discount,
  };
}

export function generateOptionCostDisplayOpt(
  purchaseOrder?: PurchaseOrder,
  optionItemList?: PurchaseOrderOptionItem[],
): DisplayOptProps {
  if (!purchaseOrder || !optionItemList) return defaultOptionCostDisplayOpt;
  const optionCostItem = optionItemList.find(
    (optionItem) => optionItem.category_id === categoryTypes.OPTION_COST,
  );
  if (!optionCostItem) return defaultOptionCostDisplayOpt;
  const { boxs, total, discount } = processOptionCostList(optionCostItem);
  const { option_total_price } = purchaseOrder;
  return {
    content: [
      {
        label: 'オプション費合計',
        amount: option_total_price || 0,
      },
    ],
    box: [
      {
        title: [
          {
            content: [
              {
                label: 'オプション費',
                amount: total,
              },
            ],
          },
          {
            content: [
              {
                label: '値引き',
                amount: discount || -0,
              },
            ],
          },
        ],
      },
      ...boxs,
    ],
  };
}

function processExpensesList(
  optionItem?: PurchaseOrderOptionItem,
): [amount: number, box: Box[]] {
  if (!optionItem) {
    return [0, []];
  }

  const titles: Title[] = optionItem.type.map(({ type_name, groups }) => {
    const contents: Content[] = generateContents(
      groups.map((group) => group.items).flat(),
    );

    return {
      header: type_name,
      content: contents,
    };
  });

  const amount = titles.reduce(
    (acc, title) =>
      title.content.reduce((acc, content) => acc + content.amount, acc),
    0,
  );

  return [
    amount,
    [
      {
        title: titles,
        content: [
          {
            label: optionItem.category_name,
            amount,
          },
        ],
      },
    ],
  ];
}

export function generateExpenseDisplayOpt(
  optionItemList?: PurchaseOrderOptionItem[],
): DisplayOptProps {
  if (!optionItemList) return defaultExpenseDisplayOpt;
  const taxableOptionItem = optionItemList.find(
    (optionItem) => optionItem.category_id === categoryTypes.TAXABLE_EXPENSES,
  );
  const nonTaxableOptionItem = optionItemList.find(
    (optionItem) =>
      optionItem.category_id === categoryTypes.NON_TAXABLE_EXPENSES,
  );

  const [taxableAmount, taxableBox] = processExpensesList(taxableOptionItem);
  const [nonTaxableAmount, nonTaxableBox] =
    processExpensesList(nonTaxableOptionItem);

  return {
    content: [
      {
        label: '諸費用合計',
        amount: taxableAmount + nonTaxableAmount,
      },
    ],
    box: [...taxableBox, ...nonTaxableBox],
  };
}
