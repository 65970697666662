import { FC } from 'react';
import { PrepaidHistoryDetail } from './PrepaidHistoryDetail/PrepaidHistoryDetail';
import { BillInformationDetail } from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';
import { hyphenToYear } from '@/utils/date';
import { isBankOrPayEasy, switchPaymentIcon } from '@/utils/payment';

type PrepaidHistoryDetailPageProps = {
  bill: BillInformationDetail;
};

export const PrepaidHistoryDetailPage: FC<PrepaidHistoryDetailPageProps> = ({
  bill,
}) => {
  const billingDate = hyphenToYear(bill.billing_date || '', false);
  const paymentIconClass = switchPaymentIcon(
    bill.m_payment_type_id as PaymentTypeId,
  );
  const list = [
    {
      label: '支払対象',
      value: bill.pay_subject,
    },
    {
      label: '支払金額',
      value: `${bill.bill_price?.toLocaleString()} 円`,
    },
    {
      label: '支払日',
      value: billingDate,
    },
    {
      label: '決済方法',
      value: (
        <div style={{ display: 'flex', gap: '4px' }}>
          <i className={paymentIconClass} />
          <p>
            {isBankOrPayEasy(bill.m_payment_type_id)
              ? bill.bank_name + '\n' + bill.account_number
              : bill.card_no}
          </p>
        </div>
      ),
    },
    {
      label: '備考',
      value: bill.memo,
    },
  ];
  return <PrepaidHistoryDetail list={list} />;
};
