import { FC } from 'react';
interface WarrantyAutoRenewalProps {}
import styles from './WarrantyAutoRenewalStyle.module.scss';
import { WarrantyFlow } from './WarrantyFlow';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

export const WarrantyAutoRenewal: FC<WarrantyAutoRenewalProps> = () => {
  return (
    <RoundSheet>
      <div className={styles.wrapper}>
        <h3 className={styles.title}>自動更新型保証につきまして</h3>
        <div className="u-margin-top-xs">
          ご加入いただいております修理保証は自動更新型の商品でございます。
          <br />
          保証料は前受でございますため、保証更新月の前月より次年度の保証料請求が開始いたします。
          <br />
          ご加入プランやお車の経過年数などによりまして、初年度と保証料が変わる場合がございます。
        </div>
        <p className={styles.remark}>
          <span>※</span>
          更新月2ヵ月前末日までに決済情報のご登録がない場合は、更新されません。
        </p>
        <WarrantyFlow />
      </div>
    </RoundSheet>
  );
};
