import { useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { PrepaidSummary } from './PrepaidSummary/PrepaidSummary';
import { AuthInfoState } from '@/config/initialState';
import { store } from '@/config/store';
import { useHouseprepaidApplication } from '@/hooks/api/prepaid/useHouseprepaidApplication';
import { GmoRedirectInfo, PrepaidData } from '@/types/payment';

type PrepaidSummaryPageProps = {
  f7router: Router.Router;
  gmoRedirectInfo?: GmoRedirectInfo;
  setPrepaidData?: Dispatch<SetStateAction<PrepaidData>>;
};

export const PrepaidSummaryPage: FC<PrepaidSummaryPageProps> = ({
  setPrepaidData,
}) => {
  const { m_customer_id } = useStore(store, 'authInfo') as AuthInfoState;
  const housePrepaidParams = useMemo(
    () => ({ m_customer_id }),
    [m_customer_id],
  );
  const { data: housePrepaid } = useHouseprepaidApplication(housePrepaidParams);
  return (
    <PrepaidSummary
      setPrepaidData={setPrepaidData}
      housePrepaid={housePrepaid?.houseprepaids}
    />
  );
};
