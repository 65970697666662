import { InquiryType } from '@/types/inquiry';

export const contacts: { value: string; key: string | InquiryType }[] = [
  { value: '選択してください', key: '' },
  { value: '車検や定期点検について', key: '1' },
  { value: 'プリペイドサービスについて', key: '2' },
  { value: '修理保証について', key: '3' },
  { value: 'アプリについて', key: '4' },
  { value: 'その他', key: '5' },
];
