import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/framework7-types';
import { FC, useState } from 'react';
import GreenTick from 'src/assets/images/green-tick.svg';
import { usePrepaid } from '../Prepaid/usePrepaid';
import style from './CarwashCancelReasonPage.module.scss';
import { Button } from '@/components/uiParts/Button';
import { Dialog } from '@/components/uiParts/Dialog/Dialog';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  cancellationTypeOptions,
  cancellationType as cancellationTypeConst,
  cancellationFrom,
} from '@/consts/carWash';
import { useCancelCarWash } from '@/hooks/api/reservation/useCancelCarWash';
import { Reservation } from '@/types/api/reservationApi';

type CarwashCancelReasonPageProps = {
  f7router: Router.Router;
  id?: string;
};

export const CarwashCancelReasonPage: FC<CarwashCancelReasonPageProps> = ({
  f7router,
  id,
}) => {
  // eslint-disable-next-line no-magic-numbers
  const [cancellationType, setCancellationType] = useState(5);
  const [cancellationDetail, setCancellationDetail] = useState('');
  const [cancellationDetailError, setCancellationDetailError] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const reservation = useStore(store, 'getReservation') as Reservation;
  const { cancel } = useCancelCarWash();
  const { fetchAdvancePaymentInfo } = usePrepaid();

  const handleCancellationDetailChange = (reasonDetail: string) => {
    setCancellationDetail(reasonDetail);
    if (reasonDetail) {
      setCancellationDetailError('');
    }
  };

  const toCancel = () => {
    if (
      cancellationType === cancellationTypeConst.OTHER &&
      !cancellationDetail
    ) {
      setCancellationDetailError('予約の取り消し理由をご記入ください。');
      return;
    }

    setShowConfirm(true);
  };

  const handleConfirm = async () => {
    setShowConfirm(false);
    cancel({
      body: {
        reservation_id: Number(id),
        cancellation_from: cancellationFrom.USER,
        cancellation_type: cancellationType,
        cancellation_detail:
          cancellationType === cancellationTypeConst.OTHER
            ? cancellationDetail
            : (cancellationTypeOptions.find(
                (crt) => crt.value === cancellationType,
              )?.detail as string),
        service_type: reservation.service_type,
      },
      onSuccess: async () => {
        await fetchAdvancePaymentInfo();
        setShowSuccess(true);
      },
    });
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccess(false);
    f7.views.main.router.navigate(paths.reservationHistory);
  };

  return (
    <PopupPageTemplate
      backdrop={true}
      id="CarwashCancelReasonPage"
      pageName="CarwashCancelReasonPage"
      containerClass={style['popup-container']}
      closeByBackdropClick={false}
      title="予約の取り消し"
    >
      <div className={style['container']}>
        <p className={style['heading']}>予約の取り消し理由をご回答ください。</p>
        <h3 className={style['title']}>
          予約の取り消し理由
          <span className={style['label-tip-required']}>必須</span>
        </h3>
        <div className={style['radio-items']}>
          {cancellationTypeOptions.map((item) => (
            <div
              key={`cancel-reason-${item.value}`}
              className={style['radio-container']}
              onClick={() => setCancellationType(item.value)}
            >
              <input
                className={style['radio-input']}
                value={item.value}
                type="radio"
                checked={cancellationType === item.value}
                name="cancellationType"
                onChange={() => {}}
              />
              <span className={style['custom-radio']} />
              <label className={style['radio-label']}>{item.text}</label>
            </div>
          ))}
        </div>
        {cancellationType === cancellationTypeConst.OTHER && (
          <>
            <textarea
              rows={4}
              className={`${style['reason-detail-text']} ${
                cancellationDetailError ? style['has-error'] : ''
              } `}
              onChange={(e) => handleCancellationDetailChange(e.target.value)}
              defaultValue={cancellationDetail}
            />
            {cancellationDetailError && (
              <p className={style['reason-detail-error']}>
                {cancellationDetailError}
              </p>
            )}
          </>
        )}
        <div className={style['button-wrap']}>
          <Button
            popupClose
            text="キャンセル"
            type="button"
            className={style['btn-close']}
          />
          <Button
            onClick={toCancel}
            text="予約を取り消す"
            type="button"
            className={style['btn-to-cancel']}
          />
        </div>
      </div>
      {showConfirm && (
        <Dialog
          title="予約を取り消す"
          subTitle="予約が破棄されます。<br>本当に取り消しますか？"
          handleCancel={() => handleConfirm()}
          handleOk={() => setShowConfirm(false)}
          buttonOkText="キャンセル"
          buttonCancelText="予約を取り消す"
          className={style['confirm-dialog']}
          btnOkClassName={style['btn-confirm-ok']}
          btnCancelClassName={style['btn-confirm-cancel']}
        />
      )}

      {showSuccess && (
        <div className={style['success-popup']}>
          <div className={style['backdrop-popup']} />
          <div className={style['success-container']}>
            <i
              className="icon-close"
              onClick={() => handleCloseSuccessDialog()}
            />
            <img src={GreenTick} alt="" />
            <div className={style['success-title']}>予約を取り消しました。</div>
            <div className={style['success-text']}>
              またのご利用をお待ちしております。
            </div>
          </div>
        </div>
      )}
    </PopupPageTemplate>
  );
};
