import { Router } from 'framework7/types';
import { FC } from 'react';
import { ConsultingHistory } from './ConsultingHistory/ConsultingHistory';

type ConsultingHistoryPageProps = {
  f7router: Router.Router;
  id: string;
};

export const ConsultingHistoryPage: FC<ConsultingHistoryPageProps> = ({
  f7router,
  id,
}) => {
  return <ConsultingHistory f7router={f7router} id={id} />;
};
