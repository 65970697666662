import { FC } from 'react';
import { CalibrationProcessBar } from '../CalibrationProcessBar/CalibrationProcessBar';
import style from './CalibrationNotStart.module.css';
import { Button } from '@/components/uiParts/Button';

interface CalibrationNotStartProps {
  handleClickAction: () => void;
}

export const CalibrationNotStart: FC<CalibrationNotStartProps> = ({
  handleClickAction,
}) => {
  return (
    <div className={style['CalibrationBox']}>
      <CalibrationProcessBar />
      <div className={style['description']}>
        <p>キャリブレーションが開始できませんでした。</p>
        <p>次回の走行時にキャリブレーションが実施されます。</p>
      </div>

      <div className={style['button']}>
        <div />
        <Button handleClickAction={handleClickAction}>終了する</Button>
        <div />
      </div>
    </div>
  );
};
