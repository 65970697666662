import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './ReservationItemStyle.module.css';
import OrderDateIcon from '@/assets/images/maintenance-group/order_date.svg';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { serviceType } from '@/consts/reservation';
import { Reservation } from '@/types/api/reservationApi';
import { convertToDay } from '@/utils/date';

type ReservationItemProps = {
  reservation: Reservation;
  fromPage?: string;
};

export const ReservationItem: FC<ReservationItemProps> = ({
  reservation,
  fromPage,
}) => {
  const getTitle = (service_type: string) => {
    switch (service_type) {
      case serviceType.carwash:
        return '水なし手洗い洗車';
      case serviceType.airconditionerCleaning:
        return 'カーエアコンクリーニング';
      case serviceType.repairWarranty:
        return '保証修理';
      case serviceType.maintenance:
        return '整備';
      default:
        return '';
    }
  };

  const handleClickDetail = (reservation: Reservation) => {
    if (
      [serviceType.repairWarranty, serviceType.maintenance].includes(
        reservation.service_type,
      )
    ) {
      f7.view.main.router.navigate(
        paths.repairDetail(reservation.reservation_id.toString()),
        {
          props: {
            fromPage,
          },
        },
      );
    }
    if (
      [serviceType.carwash, serviceType.airconditionerCleaning].includes(
        reservation.service_type,
      )
    ) {
      store.dispatch('setReservationHistory', { reservation });
      f7.view.main.router.navigate(
        paths.carwashDetail(String(reservation.reservation_id)),
        {
          props: {
            fromPage,
          },
        },
      );
    }
  };

  return (
    <div
      className={style['reservation-box']}
      onClick={() => handleClickDetail(reservation)}
    >
      <div className={style['reservation-box-icon-title']}>
        <div>
          <div className={style['reservation']}>
            <p className={style['reservation__service-type']}>
              {getTitle(reservation.service_type)}
            </p>
            <p className={style['reservation__status']}>
              {reservation.status_value}
            </p>
          </div>
          <p className={style['reservation__order-date']}>
            <img src={OrderDateIcon} />
            <span className={style['reservation__order-date-detail']}>
              受付日:{convertToDay(reservation.order_date)}
            </span>
          </p>
        </div>
      </div>
      <i className="icon-right" style={{ color: '#1E55E6', fontSize: 23 }} />
    </div>
  );
};
