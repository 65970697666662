import { Router } from 'framework7/types';
import { FC } from 'react';
import { DeliveryCheckList } from './DeliveryCheckList';

type DeliveryCheckListPageProps = {
  f7router: Router.Router;
  item?: any;
};

export const DeliveryCheckListPage: FC<DeliveryCheckListPageProps> = ({
  f7router,
  item,
}) => {
  return <DeliveryCheckList f7router={f7router} item={item} />;
};
