import { baseApi } from '@/config/axios';
import {
  DeliveryCompleteCustomerWfParams,
  DeliveryCompleteCustomerWfResponse,
  DeliveryInputCustomerWfParams,
  DeliveryInputCustomerWfResponse,
  TodoDetailParams,
  TodoDetailResponse,
  TodoListParams,
  TodoListResponse,
} from '@/types/api/todoListApi';

export const fetchTodoListApi = (
  params: TodoListParams,
): Promise<TodoListResponse> =>
  baseApi.get(`v1/customer/customer-wf-ids`, { params });

export const fetchTodoDetailApi = (
  params: TodoDetailParams,
): Promise<TodoDetailResponse> =>
  baseApi.get(`v1/customer/customer-wf-datas`, { params });

export const inputCustomerApi = (
  params: DeliveryInputCustomerWfParams,
): Promise<DeliveryInputCustomerWfResponse> =>
  baseApi.post(`v1/customer/input-customer-wf`, params);

export const completeCustomerApi = (
  params: DeliveryCompleteCustomerWfParams,
): Promise<DeliveryCompleteCustomerWfResponse> =>
  baseApi.post(`v1/customer/complete-customer-wf`, params);
