import { Router } from 'framework7/types';
import { FC } from 'react';
import { LoginTutorial } from './LoginTutorial/LoginTutorial';

interface LoginTutorialPageProps {
  f7router: Router.Router;
}
export const LoginTutorialPage: FC<LoginTutorialPageProps> = ({ f7router }) => {
  return <LoginTutorial f7router={f7router} />;
};
