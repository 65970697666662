import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './ConsultingStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { IconList } from '@/components/uiParts/List/IconList/IconList';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { CONSULTING_FROM_TYPE } from '@/consts/consultingHistory';
import { VIEW_STATUS } from '@/consts/news';
import { useFetchContactList } from '@/hooks/api/inquiry/useFetchContactList';
import { InqueryDetail } from '@/types/api/queryApi';
import { hyphenToDay } from '@/utils/date';

type ConsultingProps = {
  f7router: Router.Router;
};

export const Consulting: FC<ConsultingProps> = ({ f7router }) => {
  const mCustomerId = store.state.authInfo.m_customer_id;

  const { data } = useFetchContactList({
    m_customer_id: mCustomerId,
  });
  const consultingHistoryList = data?.data?.inquiry_header;

  const handleClickConsulting = () => {
    f7.view.main.router.navigate('/contact/', {
      props: {
        previousPage: 'news_consulting',
      },
    });
  };

  const handleClickConsultingDetail = (id: string) => {
    f7.view.main.router.navigate(paths.consultingHistory(id));
  };

  const checkIsUnReadNotice = (inquiryDetail: InqueryDetail[]) => {
    return inquiryDetail.some(
      (inquiry) =>
        inquiry.from_type === CONSULTING_FROM_TYPE.BPO &&
        inquiry.read_flg === VIEW_STATUS.UNREAD,
    );
  };

  return (
    <div>
      <div className={style['button-wrap']}>
        <Button text="お問い合わせ" handleClickAction={handleClickConsulting} />
      </div>
      <h3 className={style.heading3}>お問い合わせ履歴</h3>
      <ul className={style['list-wrap']}>
        {consultingHistoryList?.map((item, idx) => (
          <div
            onClick={() =>
              handleClickConsultingDetail(
                String(item.t_customer_inquiry_header_id),
              )
            }
            key={idx}
          >
            <IconList
              text={item.inquiry_title}
              isNew={checkIsUnReadNotice(item.inquiry_detail)}
              date={hyphenToDay(item.inquiry_date, true)}
            />
          </div>
        ))}
        {!consultingHistoryList && (
          <div className={style['no-list']}>お問い合わせ履歴がありません。</div>
        )}
      </ul>
    </div>
  );
};
