import { FC, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './Partial.module.scss';

interface RadioProps {
  id: string;
  name: string;
  value: any;
  className?: string;
  checked?: boolean;
  handleOnClick?: (value: string) => void;
  children?: ReactNode;
}

export const Radio: FC<RadioProps> = ({
  id,
  name,
  className = '',
  value,
  checked = false,
  handleOnClick,
  children,
}) => {
  const { register } = useFormContext();
  return (
    <label htmlFor={id} className={`${style['radio-label']} ${className}`}>
      <div className={style['radio-container']}>
        <input
          id={id}
          className={style['radio-input']}
          type="radio"
          {...register(name)}
          value={value}
          checked={checked}
          onClick={() => handleOnClick?.(value)}
        />
        <span className={style['custom-radio']} />
      </div>
      {children}
    </label>
  );
};
