import { FC } from 'react';

interface ErrorPageTitleTipsProps {
  title?: string;
  possibleTitle?: string;
  eventTitle?: string;
  duplicatedTipsWordsShow?: boolean;
  duplicatedPhoneNumber?: string;
}

export const ErrorPageTitleTips: FC<ErrorPageTitleTipsProps> = ({
  title,
  possibleTitle,
  eventTitle,
  duplicatedTipsWordsShow,
  duplicatedPhoneNumber,
}) => {
  return (
    <div className="u-text-center">
      <h2 className="u-white-space">{title}</h2>
      <div style={{ padding: '8px 0 0 6px' }}>
        <img src="src/assets/images/login/icon_prev.svg" alt="" />
      </div>
      {possibleTitle && (
        <p style={{ fontWeight: 'bold' }}>
          {`${possibleTitle}間違っている\n可能性があります`}
        </p>
      )}
      {eventTitle && (
        <div className="account-forgot__wrap">
          <p>
            {`大変お手数ですが、サポートセンターにご連絡の上\n
            ${eventTitle}をお願いいたします`}
          </p>
        </div>
      )}
      {duplicatedTipsWordsShow && (
        <div>
          <p className="error-page__text">
            過去に、別のお客様がこちらの番号
            <br />
            <span>{duplicatedPhoneNumber}</span>で アカウント登録を
            <br />
            していた可能性があります
          </p>
          <div className="error-page__wrap">
            <div />
            <span>
              大変お手数ですが、お客様情報の安全のため、
              <br />
              サポートセンターにて本人確認をさせてください
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
