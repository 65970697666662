import { Router } from 'framework7/types';
import { FC } from 'react';
import { TodoInstrunction } from './TodoInstruction/TodoInstruction';

type TodoInstructionPageProps = {
  f7router: Router.Router;
};

export const TodoInstructionPage: FC<TodoInstructionPageProps> = ({
  f7router,
}) => {
  return <TodoInstrunction f7router={f7router} />;
};
