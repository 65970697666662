import { FC } from 'react';
import style from './TradeWarrantyDetailStyle.module.css';
import { ReactComponent as HelpIcon } from '@/assets/images/menu/icon-help.svg';
import { WarrantyCard } from '@/components/projects/WarrantyCard/WarrantyCard';
import { store } from '@/config/store';
import { hyphenToDayNoPadding } from '@/utils/date';

type WarrantyInfoProps = {
  handleClickIcon: () => void;
};

export const WarrantyInfo: FC<WarrantyInfoProps> = ({ handleClickIcon }) => {
  const {
    currentWarranty,
    carItemInfo,
    customerInfo: { affiliate_name: affiliateName },
  } = store.state;
  const warranty = currentWarranty?.append_warranties?.[0];

  const warranties = [
    {
      label: '保証名',
      value: warranty?.warranty_name,
    },
    {
      label: 'プラン料金',
      value: (
        <p>
          {Number(warranty?.warranty_fee?.toString() || '0').toLocaleString()}円
        </p>
      ),
    },
    {
      label: '免責金',
      value: (
        <>
          <p>{`販売店入庫：${Number(
            warranty?.deductible_s?.toString() || '0',
          ).toLocaleString()}円`}</p>
          <p>
            {`他社様への入庫：${Number(
              warranty?.deductible_o?.toString() || '0',
            ).toLocaleString()}円`}
            <p>※一度の修理受付につき、</p>
            <p>「修理事務手数料として」</p>
          </p>
        </>
      ),
    },
    {
      label: '保証期間',
      value: '納車日から1ヶ月間',
    },
    {
      label: '納車予定日',
      value: hyphenToDayNoPadding(
        carItemInfo?.delivery_confirmed_date || '',
        true,
      ),
    },
  ];
  return (
    <div>
      <h2 className="warranty-detail-header">
        プラン詳細
        <span onClick={handleClickIcon}>
          取引保証とは？
          <HelpIcon width={24} height={24} />
        </span>
      </h2>
      <div className={`${style['block']}`}>
        <div className={style['divide']}>
          <WarrantyCard
            warrantyCardInfo={{
              warrantyName: warranty?.warranty_name ?? '',
              affiliateName,
            }}
          />
          {warranties?.map(({ label, value }) => (
            <div key={label} className={style['content-line']}>
              <p className={style['text-label']}>{label}</p>
              <p className={style['text']}>{value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
