import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchDriveRecordInfo } from '@/api/driveRecordApi';
import {
  fetchContractInformationApi,
  fetchVehicleTypeApi,
} from '@/api/linkDriveApi';
import { useToast } from '@/hooks/useToast';
import { HighlightParams, HighlightResponse } from '@/types/api/driveRecordApi';
import {
  ContractInformationParams,
  ContractInformationResponse,
  VehicleTypeResponse,
} from '@/types/api/linkDriveApi';
import { VehicleTypeParams } from '@/types/api/linkDriveApi';
import { storeDispatch } from '@/utils/store';

export type HighlightFetchData = {
  highlight: HighlightResponse['data'] | undefined;
  contractInformation: ContractInformationResponse['data'] | undefined;
  vehicleType: VehicleTypeResponse['data'] | undefined;
};

const initialData: HighlightFetchData = {
  highlight: undefined,
  contractInformation: undefined,
  vehicleType: undefined,
};

export const useHighlightData = (
  { owner_id, vehicle_id, m_customer_id }: HighlightParams,
  { device_key, period_of_insurance }: ContractInformationParams,
  { t_stock_car_id }: VehicleTypeParams,
) => {
  const [data, setData] = useState<HighlightFetchData>(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const { openToast } = useToast();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        let newData = { ...initialData };
        const driveRecordRes = await fetchDriveRecordInfo({
          owner_id,
          vehicle_id,
          m_customer_id,
        });
        if (driveRecordRes.data.success) {
          newData = { ...newData, highlight: driveRecordRes.data };
          await storeDispatch(
            'setMonthlyReportDate',
            'monthlyReportDate',
            driveRecordRes.data?.data?.monthly_report.analysis_from_date ?? '',
          );
        }
        const contractInfoRes = await fetchContractInformationApi({
          owner_id,
          device_key,
          period_of_insurance,
        });
        if (contractInfoRes.data.success) {
          newData = { ...newData, contractInformation: contractInfoRes.data };
        }
        const vehicleTypeRes = await fetchVehicleTypeApi({ t_stock_car_id });
        if (vehicleTypeRes.data.success) {
          newData = {
            ...newData,
            vehicleType: vehicleTypeRes.data,
          };
        }
        setData(newData);
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
        console.error(err);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [
    owner_id,
    vehicle_id,
    m_customer_id,
    device_key,
    period_of_insurance,
    t_stock_car_id,
  ]);

  useEffect(() => {
    if (error) {
      openToast(
        'エラーが発生しました。もう一度お試しください。',
        'toast-failed',
      );
    }
  }, [error, openToast]);

  return {
    data,
    isLoading,
    error,
  };
};
