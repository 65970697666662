import { Block, List, ListItem, Navbar, Page } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { CommonpageTitle } from '@/components/uiParts/CommonpageTitle/CommonpageTitle';
import { MenuFooter } from '@/components/uiParts/MenuFooter/MenuFooter';
import './NoticeDetailDummy2Styles.scss';

interface NoticeDetailDummy2Props {
  f7router?: Router.Router;
  title?: string;
  content?: string;
  type?: number;
}
export const NoticeDetailDummy2: FC<NoticeDetailDummy2Props> = ({
  title = '',
  content = '',
  type = 1,
}) => {
  const sendInfo = {
    send_title: '郵送期日',
    send_date: '2022-5-13',
    send_content:
      '新しい車の保証料は、ご納車月より変更となります。\n\nしかし請求システムの都合上、<b>お客様への請求が切り替わるのが納車月の翌月から</b>となります。\n\n請求切り替えまでの金額の差分は、<b>納車月の翌月に精算</b>されます。',
    send_remark: 'お客様は納車月の翌月の保証料に<b>4,000円加算</b>されます。',
    send_address: '東京都渋谷区東1-12-12祐ビル',
    send_postNo: '〒 000-0000',
    send_name: '○○モータース',
    after_send_process:
      '郵送が完了しましたら、「完了済みにする」ボタンを押してください。\n\n※書類が確認できましたら、やることリストからこちらのタスクがなくなります。',
  };
  const fileListInfo = {
    file_title: '必要書類一覧',
    file_list: [
      { file_name: '印鑑証明' },
      { file_name: '車庫証明' },
      { file_name: '委任状' },
      { file_name: '自動車保管場所証明申請書' },
      { file_name: '保管場所の所在図・配置図' },
      { file_name: '保管場所の使用権を説明する書類' },
      { file_name: '譲渡証明書' },
      { file_name: '過誤納金についての書類' },
      { file_name: '自動車税の債務譲渡に関する書類' },
    ],
  };
  const transferInfo = {
    transfer_title: '振込期日',
    transfer_date: '2022-5-13',
    transfer_sub_title: '振込情報',
    transfer_list: [
      { label: '金融機関名', value: '○○銀行' },
      { label: '支店名', value: '○○支店（支店番号：00）' },
      { label: '口座種別', value: '普通預金' },
      { label: '口座番号', value: '00000000000' },
      { label: '口座名義人', value: '株式会社○○オート' },
      { label: '振込金額', value: '000,000円' },
    ],
    after_transfer_process:
      '入金が完了しましたら、「完了済みにする」ボタンを押してください。\n\n店舗で入金の確認をさせていただき、こちらのアプリで受け取確認完了の通知をさせていただきます。\n\n※入金確認が取れましたら、やることリストからこちらのタスクがなくなります。',
  };
  const handleClickCompleted = () => {};
  return (
    <Page name="NoticeDetail">
      <Navbar className="car-home__icon">
        <CommonpageTitle navTitle="" leftIcon="left" helpIcon={true} />
      </Navbar>
      <div className="home__wrapper-sub">
        <div>やることリスト</div>
      </div>
      <Block
        style={{
          marginTop: '61px',
          lineHeight: '2rem',
          whiteSpace: 'pre-wrap',
          marginBottom: '10rem',
        }}
      >
        <div className="info-card info-card-elevated">
          <h2 className="notice-title u-text-center">{title}</h2>
          <br />
          <div
            className="notice-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div className="info-card info-card-elevated">
          <h2 className="notice-title">
            {type == 1 ? sendInfo.send_title : transferInfo.transfer_title}
          </h2>
          <p className="notice-date">
            {type == 1
              ? sendInfo.send_date.substring(0, 14)
              : transferInfo.transfer_date.substring(0, 14)}
          </p>
          <div className="notice-content">
            ※ご指定日までの{type == 1 ? '送付' : '入金'}
            が難しい場合は、購入店舗にご相談ください。
          </div>
          <div className="shop-info">
            <span>購入店舗</span>
            <span>○○モータース</span>
            <span>
              <i className="icon-telephone" />
            </span>
          </div>
        </div>
        {type == 1 && (
          <div className="info-card info-card-elevated">
            <h2 className="notice-title">{fileListInfo.file_title}</h2>
            <p />
            <List simpleList className="mypage-list transfer-list">
              {fileListInfo.file_list.map((item, index) => (
                <ListItem className="file-list" key={index}>
                  <div
                    className="item-desc u-font-bold"
                    style={{
                      marginTop: '0',
                      fontSize: '1.5rem',
                      marginRight: '1rem',
                    }}
                  >
                    {item.file_name}
                  </div>
                  <div
                    className="item-desc0"
                    style={{ fontSize: '1.4rem', fontWeight: 'normal' }}
                  >
                    取得方法
                  </div>
                  <div className="item-term">
                    <i className="icon-middle icon-right" />
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        )}
        {type == 2 && (
          <div className="info-card info-card-elevated">
            <h2 className="notice-title">{transferInfo.transfer_sub_title}</h2>
            <p />
            <List simpleList className="mypage-list transfer-list">
              {transferInfo.transfer_list.map((item, index) => (
                <ListItem className="transfer-list" key={index}>
                  <div className="item-desc1 u-font-bold">{item.label}</div>
                  <div className="item-desc2 u-font-bold">{item.value}</div>
                </ListItem>
              ))}
            </List>
          </div>
        )}
        {type == 1 && (
          <div className="info-card info-card-elevated">
            <h2 className="notice-title">郵送先</h2>
            <p />
            <div className="notice-content">{sendInfo.send_postNo}</div>
            <div className="notice-content">{sendInfo.send_address}</div>
            <div className="notice-content">{sendInfo.send_name}</div>
          </div>
        )}
        <div className="info-card info-card-elevated">
          <h2 className="notice-title">
            {type == 1 ? '郵送後の流れ' : '振り込み後の流れ'}
          </h2>
          <p />
          <div className="notice-content">
            {type == 1
              ? sendInfo.after_send_process
              : transferInfo.after_transfer_process}
          </div>
        </div>
        <div className="completed-btn" onClick={handleClickCompleted}>
          完了済みにする
          <span style={{ fontSize: '1.3rem', display: 'block' }}>
            {type == 1 ? '送付' : '入金'}完了後に押してください
          </span>
        </div>
      </Block>
      <MenuFooter />
    </Page>
  );
};
