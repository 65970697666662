import { IconProps } from './type';

export const OpenUrlIcon = ({
  width = '20',
  height = '21',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.392 2.5L11.9204 2.5C11.5847 2.5 11.3125 2.76503 11.3125 3.09196C11.3125 3.41888 11.5847 3.68391 11.9204 3.68391H15.9243L9.99931 9.45298C9.76188 9.68415 9.76188 10.059 9.99931 10.2901C10.2367 10.5213 10.6217 10.5213 10.8591 10.2901L16.7841 4.52106V8.41955C16.7841 8.74648 17.0563 9.01151 17.392 9.01151C17.7278 9.01151 18 8.74648 18 8.41955L18 3.09196C18 2.76503 17.7278 2.5 17.392 2.5ZM3.51727 5.94398C3.51727 5.53602 3.85692 5.20531 4.27591 5.20531H8.84768C9.25564 5.20531 9.58635 4.8746 9.58635 4.46664C9.58635 4.05869 9.25564 3.72797 8.84768 3.72797H4.27591C3.01896 3.72797 2 4.72011 2 5.94398V16.2853C2 17.5092 3.01896 18.5014 4.27591 18.5014H14.8968C16.1537 18.5014 17.1727 17.5092 17.1727 16.2853V11.8733C17.1727 11.4543 16.8331 11.1147 16.4141 11.1147C15.9951 11.1147 15.6554 11.4543 15.6554 11.8733V16.2853C15.6554 16.6933 15.3158 17.024 14.8968 17.024H4.27591C3.85692 17.024 3.51727 16.6933 3.51727 16.2853V5.94398Z"
        fill={color}
      />
    </svg>
  );
};
