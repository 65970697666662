import { FC } from 'react';
import styles from './CommisionedWorkStyle.module.css';
import IconCarFronShine from '@/assets/images/menu/icon_car-front-shine.svg';
// import IconCarMagnifyingGlass from '@/assets/images/menu/icon_car-magnifying-glass.svg';
// import IconCarSpin from '@/assets/images/menu/icon_car-spin.svg';
// import IconTool from '@/assets/images/menu/icon_tool.svg';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';

interface CommisionedWorkProps {
  handleClickItem: (target: string) => void;
}

export const CommisionedWork: FC<CommisionedWorkProps> = ({
  handleClickItem,
}) => {
  return (
    <PageTemplate pageName="CommisionedWork" title="作業依頼" showNavBack>
      <div className={styles.container}>
        {/* <div className={styles.card}>
          <img src={IconCarMagnifyingGlass} />
          <h4>車検</h4>
        </div>
        <div className={styles.card}>
          <img src={IconCarSpin} />
          <h4>定期点検</h4>
        </div>
        <div className={styles.card}>
          <img src={IconTool} />
          <h4>整備</h4>
        </div> */}
        <div
          className={styles.card}
          onClick={() => handleClickItem(paths.carWash)}
        >
          <img src={IconCarFronShine} />
          <h4>洗車・コーティング</h4>
        </div>
      </div>
    </PageTemplate>
  );
};
