import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './PhoneNumberRegistrationCardStyle.module.css';
import { paths } from '@/config/paths';

export const PhoneNumberRegistrationCard: FC = () => {
  return (
    <div className={style.card}>
      <a
        onClick={() => f7.views.main.router.navigate(paths.registerPhone)}
        className={style.link}
      >
        <div className={style.grid}>
          <div className={style['icon-bulb-wrap']}>
            <i className={`icon-bulb`} />
          </div>
          <p className={style.text}>電話番号が登録されていません</p>
          <div className={style['icon-right-wrap']}>
            <i className={`icon-right`} />
          </div>
        </div>
      </a>
      <p className={style.description}>
        電話番号を登録すると、SMS認証で安全にログインできるようになります
      </p>
    </div>
  );
};
