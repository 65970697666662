import { Router } from 'framework7/types';
import { FC } from 'react';
import { UsePrepaid } from './UsePrepaid/UsePrepaid';

interface UsePrepaidPageProps {
  f7router: Router.Router;
  handleChangeBalanceTotal: (charging: number) => void;
}

export const UsePrepaidPage: FC<UsePrepaidPageProps> = ({
  f7router,
  handleChangeBalanceTotal,
}) => {
  return (
    <UsePrepaid
      f7router={f7router}
      handleChangeBalanceTotal={handleChangeBalanceTotal}
    />
  );
};
