import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { useMonthlyReportList } from '@/hooks/api/driveRecord/useMonthlyReportList';
import { MonthReport } from '@/types/api/driveRecordApi';
import './totalScoreListStyle.scss';

type TotalScoreListProps = {
  f7router: Router.Router;
};

export const TotalScoreList: FC<TotalScoreListProps> = ({ f7router }) => {
  const customerId = store.state.authInfo.m_customer_id;
  const linkDriveOwnerInfo = store.state.linkDriveOwnerInfo;

  const { data, isNoData } = useMonthlyReportList({
    m_customer_id: customerId,
    vehicle_id: linkDriveOwnerInfo.vehicle_id,
  });

  // Todo: DrivingScoreCheckページ作成後URL追加
  const handleClickDetails = (item: MonthReport) => {
    f7.view.main.router.navigate('', { props: { info: item } });
  };

  // Todo: アカウント、デザイン共有され次第挙動確認

  return (
    <PopupPageTemplate pageName="TotalScoreList" title="安全運転スコア(累計)">
      {!isNoData && data ? (
        data.map((item, index: number) => (
          <div
            className="info-card info-card-elevated u-margin-bottom-sm"
            key={index}
            onClick={() => handleClickDetails(item)}
          >
            <div className="card-container">
              <div
                className={`score-box score-box-color-${
                  item.total_score >= 80 ? 'red' : 'green'
                }`}
              >
                <div className="score">{item.total_score}点</div>
              </div>
              <div className="item-content u-font-size-xl">
                <div className="u-margin-bottom-xs">
                  {item.from_date}~{item.total_to_date}
                </div>
                <div>走行距離: {item.total_driving_distance} km</div>
              </div>
              <div className="link-icon">
                <i className="icon-right u-font-size-xxl" />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="no-data u-text-center u-font-size-xxl">No Data</div>
      )}
    </PopupPageTemplate>
  );
};
