import { yupResolver } from '@hookform/resolvers/yup';
import { f7 } from 'framework7-react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { validationSchema } from '../validationSchema';
import style from './contactLoginStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { ParallelContactInput } from '@/components/uiParts/ParallelInput/ParallelContactInput';
import { RhfTextareaWithLabel } from '@/components/uiParts/ReactHookForm/RhfTextareaWithLabel/RhfTextareaWithLabel';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { RhfTextInput } from '@/components/uiParts/TextInput/RhfTextInput';
import { paths } from '@/config/paths';
import { useSendInquiry } from '@/hooks/api/inquiry/useSendInquiry';

interface FormInput {
  lastName: string;
  firstName: string;
  kanaLastName: string;
  kanaFirstName: string;
  phoneNumber: string;
  email: string;
  other: string;
}

export const ContactLogin = () => {
  // hook
  const formMethods = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
  });
  const { sendInquiry } = useSendInquiry();
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const params = {
      family_name: data.lastName,
      family_name_kana: data.kanaLastName,
      first_name: data.firstName,
      first_name_kana: data.kanaFirstName,
      mail_address: data.email,
      phone_no: data.phoneNumber,
      supplements_item: data.other,
    };
    const result = await sendInquiry(params);
    if (result) {
      f7.views.main.router.navigate(paths.contactComplete, {
        props: { email: data.email },
      });
    }
  };
  return (
    <PopupPageTemplate pageName="ContactNumber" title="問い合わせ">
      <div className={style['container']}>
        <div className={style['sub-title-wrapper']}>
          <h3>下記の情報を入力の上、送信してください</h3>
          <h3>
            {`お客様情報を確認の上\nお電話・メールにて連絡させていただきます`}
          </h3>
        </div>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`list no-hairlines ${style['form-wrapper']}`}>
              <ul>
                <ParallelContactInput
                  label="お名前"
                  firstInput={{
                    name: 'lastName',
                    placeholder: '姓',
                  }}
                  secondInput={{
                    name: 'firstName',
                    placeholder: '名',
                  }}
                  required
                  error={errors.firstName?.message || errors.lastName?.message}
                />
                <ParallelContactInput
                  label="フリガナ"
                  firstInput={{
                    name: 'kanaLastName',
                    placeholder: 'セイ',
                  }}
                  secondInput={{
                    name: 'kanaFirstName',
                    placeholder: 'メイ',
                  }}
                  required
                  error={
                    errors.kanaFirstName?.message ||
                    errors.kanaLastName?.message
                  }
                />
                <RhfTextInput
                  name="phoneNumber"
                  label="電話番号"
                  placeholder="09000000000"
                  required
                  annotation="ハイフンなし"
                  error={errors.phoneNumber?.message}
                />
                <RhfTextInput
                  name="email"
                  label="メールアドレス"
                  placeholder="mailaddress@example.com"
                  required
                  annotation="連絡・返信先として使用します"
                  error={errors.email?.message}
                />
                <RhfTextareaWithLabel
                  name="other"
                  label="その他補足事項"
                  placeholder="補足事項"
                />
              </ul>
              <div className={style['button-container']}>
                <div className={style['button-wrapper']}>
                  <Button type="submit">送信する</Button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </PopupPageTemplate>
  );
};
