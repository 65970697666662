import { Router } from 'framework7/types';
import { FC } from 'react';
import { VehicleNoticeSettings } from './VehicleNoticeSettings/VehicleNoticeSettings';

interface VehicleNoticeSettingsPageProps {
  f7router: Router.Router;
}

export const VehicleNoticeSettingsPage: FC<VehicleNoticeSettingsPageProps> = ({
  f7router,
}) => {
  return <VehicleNoticeSettings f7router={f7router} />;
};
