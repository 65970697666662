import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './appSettingsStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { State } from '@/config/initialState';

interface AppSettingsProps {
  f7router: Router.Router;
  handleClickChange: () => void;
  permission: {
    gpsPermission: State['permission']['gpsPermission'];
    cameraPermission: State['permission']['cameraPermission'];
  };
}
export const AppSettings: FC<AppSettingsProps> = ({
  f7router,
  handleClickChange,
  permission,
}) => {
  return (
    <PageTemplate title="アプリ設定" pageName="AppSettings" showNavBack>
      <RoundSheet>
        <div className={style['list-wrapper']}>
          <div className={style['title-wrapper']}>
            <h3>アプリの許可設定状況</h3>
            <div className={style['button-wrapper']}>
              <Button
                className={style['button']}
                colorTheme="gray"
                handleClickAction={handleClickChange}
              >
                変更する
              </Button>
            </div>
          </div>
          <div className={style['list']}>
            <span className={style['list-label']}>位置情報</span>
            <span className={style['list-value']}>
              {permission.gpsPermission ? '許可' : '許可しない'}
            </span>
          </div>
          <div className={style['list']}>
            <span className={style['list-label']}>カメラ</span>
            <span className={style['list-value']}>
              {permission.cameraPermission ? 'ON' : 'OFF'}
            </span>
          </div>
        </div>
      </RoundSheet>
    </PageTemplate>
  );
};
