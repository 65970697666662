import { Router } from 'framework7/types';
import { FC } from 'react';
import { UpdateInspection } from './UpdateInspection/UpdateInspection';

interface UpdateInspectionPageProps {
  f7router: Router.Router;
}
export const UpdateInspectionPage: FC<UpdateInspectionPageProps> = ({
  f7router,
}) => {
  return <UpdateInspection f7router={f7router} />;
};
