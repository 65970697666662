import { Router } from 'framework7/types';
import { FC } from 'react';
import { PrepaidChangeMoney } from './PrepaidChangeMoney/PrepaidChangeMoney';

type PrepaidChangeMoney = {
  f7router: Router.Router;
  event?: () => void;
};

export const PrepaidChangeMoneyPage: FC<PrepaidChangeMoney> = ({
  f7router,
  event,
}) => {
  return <PrepaidChangeMoney f7router={f7router} event={event} />;
};
