export const notificationMapping = {
  noNotification: 0,
  small: 1,
  middle: 2,
  big: 3,
  bigAndMiddle: 5,
} as const;

export const emergencyType = {
  noNotification: 0,
  big: 1,
  bigAndMiddle: 2,
} as const;

export type EmergencyType = typeof emergencyType[keyof typeof emergencyType];
