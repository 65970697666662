import { FC } from 'react';
import { Picker, PickerProps } from './Picker';
import { TIME_PICKER_RANGER_OPTIONS } from '@/consts/carRepair';

interface TimePickerProps extends PickerProps {
  isRanger?: boolean;
}

export const TimePicker: FC<TimePickerProps> = ({
  id,
  name,
  ...pickerProps
}) => {
  return (
    <Picker
      id={id}
      name={name}
      options={TIME_PICKER_RANGER_OPTIONS}
      {...pickerProps}
    />
  );
};
