import { Popup } from 'framework7-react';
import { PopupProps } from 'framework7-react/components/popup';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import './PopupConfirm.css';
interface PopupConfirmProps extends PopupProps {
  callCenterTel?: string;
}
export const PopupConfirm: FC<PopupConfirmProps> = ({
  callCenterTel,
  onPopupClose,
  opened,
  ...props
}) => {
  const handleClickPhone = () => {
    window.location.href = `tel://${callCenterTel}`;
  };

  return (
    <Popup
      onPopupClose={onPopupClose}
      className="confirm-container"
      opened={opened}
      backdrop
      {...props}
    >
      <div className="confirm-popup">
        <div>
          <h4 className="title">近隣の工場などに入庫する場合</h4>
        </div>
        <div>
          <p>以下のいずれかの条件を満たす工場へご予約の上、ご入庫ください</p>
          <ul className="note-requirements">
            <li>メーカー直営のディーラー</li>
            <li>国の認証もしくは指定を取得している修理工場</li>
          </ul>
          <div className="note-description">
            <span>※</span>
            <p>いずれもインボイス登録済みであることが必須条件です</p>
          </div>
        </div>
        <div className="note">
          <h5 className="note-title">入庫先が決まったら</h5>
          <p className="note-content">
            工場のご担当者様に入庫を受付次第
            <br />
            {callCenterTel ? (
              <>
                (<span onClick={handleClickPhone}>{callCenterTel}</span>)
              </>
            ) : (
              ''
            )}
            へご連絡いただけますようお伝えください。
          </p>
        </div>
        <Button className="confirm-popup-button" onClick={onPopupClose}>
          閉じる
        </Button>
      </div>
    </Popup>
  );
};
