import axios from 'axios';
import { endpoint } from '@/consts/api';
import { apiKey } from '@/consts/apiKey';
import { GetBankCodeApiResponse } from '@/types/api/bankCodeApi';

// getBankCode API
export const getBankCodeApi = (): Promise<GetBankCodeApiResponse> =>
  axios.get(
    `${endpoint.BANKCODE_API}/banks/?apikey=${apiKey.BANKCODE_API_KEY}&filter=&cursor=&sort=hiragana&limit=2000`,
  );

// getBranches API
export const getBranchesApi = (
  bankCode: string,
): Promise<GetBankCodeApiResponse> =>
  axios.get(
    `${endpoint.BANKCODE_API}/banks/${bankCode}/branches/?apikey=${apiKey.BANKCODE_API_KEY}&filter=&cursor=&sort=hiragana&limit=2000`,
  );
