import { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import './couponChargeSuccessStyle.scss';

type CouponCode = {
  amount: number;
  open: boolean;
  handleClose: () => void;
};

export const CouponChargeSuccess: FC<CouponCode> = ({
  amount = 0,
  open,
  handleClose,
}) => {
  const handleClickConfirm = () => {
    handleClose();
  };

  return (
    <PopupTemplate
      title=""
      isCloseIcon={false}
      opened={open}
      handleClose={handleClose}
      className="charge-coupon-popup"
    >
      <h6 className="title">
        <span>{Number(amount).toLocaleString()}円</span>のクーポンマネーが
        <br />
        チャージされました
      </h6>
      <div className="icon-container">
        <img width="183px" height="117px" src="/images/theme/gift_icon.png" />
      </div>
      <Button className="close-btn" handleClickAction={handleClickConfirm}>
        <span>閉じる</span>
      </Button>
    </PopupTemplate>
  );
};
