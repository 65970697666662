import { FC, useEffect, useMemo, useState } from 'react';
import { RegisterBankStep2 } from './RegisterBankStep2';
import { RegisterBankStep3 } from './RegisterBankStep3';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { endpoint } from '@/consts/api';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';
import { useResiterBankRequest } from '@/hooks/api/payment/useRegisterBankRequest';
import { useAvailableRegisterBankList } from '@/hooks/api/prepaid/useAvailableRegisterBankList';
import './RegisterBankStyle.scss';

type ResigterBankProps = {
  redirectPage: string;
};

const hashLength = (arr: object | []) => {
  if (typeof arr !== 'object') return 0;
  var result = 0;
  for (var _ in arr) result++;
  return result;
};

export const RegisterBank: FC<ResigterBankProps> = ({ redirectPage }) => {
  const [step, setStep] = useState(1);
  const [memberID, setMemberID] = useState('');
  const [bankcode, setBankcode] = useState('');
  const [bankname, setBankname] = useState('');
  const [branchCodeRequiredFlg, setBranchCodeRequiredFlg] = useState('');
  const [accountTypeRequiredFlg, setAccountTypeRequiredFlg] = useState('');
  const [accountNumberRequiredFlg, setAccountNumberRequiredFlg] = useState('');
  const [accountNameKanaRequiredFlg, setAccountNameKanaRequiredFlg] =
    useState('');
  const mCustomerId = store.state.authInfo.m_customer_id;
  const { m_affiliate_id } = store.state.customerInfo;
  const { data: mAffiliateSettingsData } = useMAffiliateSettings({
    m_affiliate_id,
  });

  const GMOParameters = useMemo(() => {
    return {
      retURL: `${endpoint.MAIKURU_API}/v1/gmo-payment/bankresult/${redirectPage}`,
      accountType: '1',
      createMember: '1',
      consumerDevice: 'pc',
      siteID: mAffiliateSettingsData?.data.gmopg_bank_id ?? '',
      sitePass: mAffiliateSettingsData?.data.gmopg_bank_pass ?? '',
    };
  }, [redirectPage, mAffiliateSettingsData]);

  const { data: paymentBankData } = useAvailableRegisterBankList({
    site_id: GMOParameters.siteID,
    m_customer_id: mCustomerId,
    with_trashed: true,
  });

  useEffect(() => {
    if (paymentBankData?.success) {
      const count = Number(hashLength(paymentBankData.data)) + 1;
      setMemberID(`B${mCustomerId}_${count}`);
    }
  }, [paymentBankData, mCustomerId]);

  const setBankInfo = (
    code: string,
    name: string,
    branchCodeRequiredFlg: string,
    accountTypeRequiredFlg: string,
    accountNumberRequiredFlg: string,
    accountNameKanaRequiredFlg: string,
  ) => {
    setBankcode(code);
    setBankname(name);
    setBranchCodeRequiredFlg(branchCodeRequiredFlg.toString());
    setAccountTypeRequiredFlg(accountTypeRequiredFlg.toString());
    setAccountNumberRequiredFlg(accountNumberRequiredFlg.toString());
    setAccountNameKanaRequiredFlg(accountNameKanaRequiredFlg.toString());
  };

  const { registerBankRequest } = useResiterBankRequest();

  const handleClickStep = (step: number) => {
    setStep(step);
  };

  const onClosed = () => {
    window.localStorage.removeItem('m_car_id');
    window.localStorage.removeItem('t_order_id');
    window.localStorage.removeItem('loginType');
    window.localStorage.removeItem('globalTheme');
    window.localStorage.removeItem('prepaid_registration_info');
    window.localStorage.removeItem('isLoggedInToMaikuru');
  };

  //Todo: 本番で挙動確認
  const handleClickAccessBankPage = async (
    memberName: string,
    branchCode: string,
    // accountType: string,
    accountNumber: string,
    accountName: string,
    accountNameKanji: string,
  ) => {
    const params = {
      MemberID: memberID,
      MemberName: memberName,
      BankCode: bankcode,
      BranchCode: branchCode,
      AccountNumber: accountNumber,
      AccountName: accountName,
      AccountNameKanji: accountNameKanji,
      RetURL: GMOParameters.retURL,
      CreateMember: GMOParameters.createMember,
      ConsumerDevice: GMOParameters.consumerDevice,
      AccountType: GMOParameters.accountType,
      SiteID: GMOParameters.siteID,
      SitePass: GMOParameters.sitePass,
    };

    // GMOページから遷移後のオートログイン用にlocalStorageに格納
    const token = store.state.authInfo.token;
    const mCustomerId = store.state.authInfo.m_customer_id.toString();
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('m_customer_id', mCustomerId);
    window.localStorage.setItem('destination_step', '2');
    window.localStorage.setItem('destination_page', paths.reservings);
    // GMOページへの遷移前の金額などを保持
    const reserveAmount = store.state.reserveAmount;
    const mPrepaidPlanId = store.state.mPrepaidPlanId;
    const prepaidPlanName = store.state.prepaidPlanName;
    const prepaidRegistrationInfo = {
      reserveAmount,
      mPrepaidPlanId,
      prepaidPlanName,
    };
    window.localStorage.setItem(
      'prepaid_registration_info',
      JSON.stringify(prepaidRegistrationInfo),
    );

    await registerBankRequest(params);
    return;
  };

  return (
    <PopupPageTemplate
      pageName="RegisterBank"
      title="決済方法の追加"
      onPopupClose={onClosed}
    >
      {step == 1 && (
        <RegisterBankStep2
          handleClickStep={handleClickStep}
          setBankInfo={setBankInfo}
        />
      )}
      {step == 2 && (
        <RegisterBankStep3
          handleClickAccessBankPage={handleClickAccessBankPage}
          handleClickStep={handleClickStep}
          bankname={bankname}
          bankcode={bankcode}
          branchCodeRequiredFlg={branchCodeRequiredFlg}
          accountTypeRequiredFlg={accountTypeRequiredFlg}
          accountNumberRequiredFlg={accountNumberRequiredFlg}
          accountNameKanaRequiredFlg={accountNameKanaRequiredFlg}
        />
      )}
    </PopupPageTemplate>
  );
};
