import { Router } from 'framework7/types';
import {
  ConnectorSettingPage,
  ConnectorTermsPage,
  DataUsingSettingPage,
  LinkDriveCalibrationConfirmPage,
  LinkDriveCalibrationPage,
  LinkDriveSetupStepsPage,
  LinkDriveTermPage,
  LinkDriveUpdatePage,
  PreventTheftSettingsPage,
  SerialNumberInputPage,
  StrikeNoticeSettingsPage,
  VehicleNoticeSettingsPage,
  WatchingServiceSettingsPage,
} from '@/components/pages';
import { LinkDriveSetupListPage } from '@/components/pages/LinkDriveSetupList/LinkDriveSetupListPage';

import { paths } from '@/config/paths';

export const connectorRoutes: Router.RouteParameters[] = [
  //   {
  //     path: '/link_drive/',
  //     component: LinkDrivePage,
  //     name: 'LinkDrive'
  //   },
  {
    path: paths.linkDriveSetupList,
    popup: {
      component: LinkDriveSetupListPage,
    },
    options: {
      animate: true,
    },
    name: 'やることリスト',
  },
  {
    path: '/linkdrive_setup_steps/',
    popup: {
      component: LinkDriveSetupStepsPage,
    },
    options: {
      animate: true,
    },
    name: 'セットアップ',
  },
  {
    path: '/link_drive_calibration/',
    popup: {
      component: LinkDriveCalibrationPage,
    },
    options: {
      animate: true,
    },
    name: 'キャリブレーション',
  },
  //   {
  //     path: '/link_drive_calibration_completed/',
  //     component: LinkDriveCalibrationCompletedPage,
  //     name: 'キャリブレーション設定完了'
  //   },
  {
    path: '/serial_number_input/',
    popup: {
      component: SerialNumberInputPage,
    },
    options: {
      animate: true,
    },
    name: 'シリアルナンバーの入力',
  },
  {
    path: '/linkdrive_terms/',
    component: LinkDriveTermPage,
    name: 'LinkDrive利用規約',
  },
  {
    path: '/linkdrive_update/',
    component: LinkDriveUpdatePage,
    name: 'LinkDrive更新',
  },
  {
    path: '/connecter_terms/',
    component: ConnectorTermsPage,
    name: 'コネクター保証規約',
  },
  {
    path: '/connector_setting/',
    component: ConnectorSettingPage,
    name: 'コネクタ設定',
  },

  //   {
  //     path: '/reset_connector_setting/',
  //     popup: {
  //       component: () =VehicleManagement/ResetConnectorSettingPage
  //     },
  //     options: {
  //       animate: true
  //     },
  //     name: 'コネクタの初期化'
  //   },
  {
    path: '/calibration_confirm/',
    popup: {
      component: LinkDriveCalibrationConfirmPage,
    },
    options: {
      animate: true,
    },
    name: 'キャリブレーション中止確認',
  },
  {
    path: '/vehicle_notice_settings/',
    component: VehicleNoticeSettingsPage,
    name: '車両通知設定',
  },
  {
    path: '/watchingService_settings/',
    popup: {
      component: WatchingServiceSettingsPage,
    },
    options: {
      animate: true,
    },
    name: 'みまもりサービス設定',
  },
  {
    path: '/prevent_theft_settings/',
    popup: {
      component: PreventTheftSettingsPage,
    },
    options: {
      animate: true,
    },
    name: '盗難防止時の通知設定',
  },
  {
    path: '/strike_notice_settings/',
    popup: {
      component: StrikeNoticeSettingsPage,
    },
    name: '衝撃通知設定',
  },
  {
    path: '/data_using_setting/',
    component: DataUsingSettingPage,
    name: 'データ利用設定',
  },
  // {
  //   path: '/driving_data_analysis_settings/',
  //   popup: {
  //     component: DrivingDataAnalysisSettings,
  //   },
  //   options: {
  //     animate: true,
  //   },
  //   name: '運転分析サービス設定',
  // },
];
