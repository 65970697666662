import { Card, CardContent } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import './UsePrepaidCompleteStyle.scss';
import { formatDate } from '@/utils/date';
import { f7CustomBack } from '@/utils/utils';

interface UsePrepaidCompleteProps {
  f7router: Router.Router;
  value: string;
}

export const UsePrepaidComplete: FC<UsePrepaidCompleteProps> = ({
  f7router,
  value,
}) => {
  const customerInfo = store.state.customerInfo;

  const handleClickBack = () => {
    f7CustomBack(['#UsePrepaidComplete']);
  };

  return (
    <PopupPageTemplate
      id="UsePrepaidComplete"
      pageName="UsePrepaidComplete"
      title="お支払い完了"
    >
      <div style={{ marginTop: '40px' }}>
        <Card
          style={{
            boxShadow: 'none',
            border: '1px solid #efefef',
            borderRadius: '8px',
            width: '96%',
            paddingBottom: '2rem',
            margin: '0 auto',
          }}
        >
          <CardContent>
            <div className="conetnt-line1">
              <div className="payment_destination_label u-padding-bottom-xl">
                お支払いありがとうございました
              </div>
              {/* <div className="payment_destination_label">整備料金として</div> */}
              <div
                className="payment_destination_label"
                style={{
                  color: '#898989',
                  fontSize: '1.6rem',
                  fontWeight: 'normal',
                }}
              >
                {formatDate(new Date())}
              </div>
            </div>
            <div className="conetnt-line3">
              <div
                className="payment_destination_label"
                style={{ fontSize: '1.6rem' }}
              >
                お支払い額
              </div>
              <div className="payment_destination">
                <span className="payment_money" style={{ fontSize: '4rem' }}>
                  {value}
                </span>{' '}
                円
              </div>
              {/* <div className="payment_destination_label" style="color: #898989; font-size: 1.6rem; font-weight: normal;">決済番号：{{  }}</div> */}
            </div>
          </CardContent>
        </Card>
        <Button
          style={{
            width: '30%',
            margin: '6rem auto 0',
            background: '#efefef',
            color: '#232323',
          }}
          text="とじる"
          icon="icon-close"
          handleClickAction={handleClickBack}
        />
      </div>
    </PopupPageTemplate>
  );
};
