import { Router } from 'framework7/types';
import { FC } from 'react';
import { CouponDetail } from './CouponDetail/CouponDetail';
import { store } from '@/config/store';

interface CouponDetailPageProps {
  id: string;
  f7router: Router.Router;
}

export const CouponDetailPage: FC<CouponDetailPageProps> = ({
  id,
  f7router,
}) => {
  const authInfo = store.state.authInfo;
  const coupons = store.state.campaignsInfo;
  const couponInfo = coupons.filter((coupon) => {
    return coupon.t_campaigns_id == Number(id);
  });
  const prepaidsInfo = store.state.advancePaymentsInfo[0];
  return (
    <CouponDetail
      f7router={f7router}
      authInfo={authInfo}
      couponInfo={couponInfo[0]}
      prepaidsInfo={prepaidsInfo}
    />
  );
};
