import { addMonths } from 'date-fns';
import { dateToYearMonth } from './date';
import { getNextBillDetail } from './prepaid';
import { BillInformation } from '@/types/api/customerApi';
import { PrepaidFlg } from '@/types/payment';

export const findNextBillDetail = (
  bill: BillInformation,
  prepaidFlg: PrepaidFlg,
) => {
  const today = new Date();
  const addMonthsAmount = 1;
  const billDetails = bill.bill_infomation_details ?? [];
  const currentBill = billDetails.find(
    (detail) =>
      detail.bill_month === dateToYearMonth(today) &&
      detail.prepaid_flg === prepaidFlg,
  );
  const nextBill = billDetails.find(
    (detail) =>
      detail.bill_month ===
        dateToYearMonth(addMonths(today, addMonthsAmount)) &&
      detail.prepaid_flg === prepaidFlg,
  );
  const prevBill = bill.bill_infomation_details?.find(
    (item) =>
      item.bill_month === dateToYearMonth(addMonths(today, -1)) &&
      item.prepaid_flg === prepaidFlg,
  );
  const closestMonthBill =
    (bill.m_prepaid_id && getNextBillDetail(billDetails, prepaidFlg)) ||
    undefined;
  return {
    currentBill,
    nextBill,
    prevBill,
    closestMonthBill,
  };
};
