import { FC, ReactNode } from 'react';
import style from './Card.module.css';

interface CardProps {
  title: string;
  iconType?: string;
  isSubTitle?: boolean;
  CardCss?: string;
  children: ReactNode;
}

export const Card: FC<CardProps> = ({
  title,
  iconType,
  isSubTitle = false,
  CardCss = '',
  children,
}) => {
  return (
    <div className={`${style.card} ${CardCss}`}>
      <div className={style['card-title']}>
        <i className={`${style.icon} ${iconType}`} />
        <h2 className={isSubTitle ? style['sub-title'] : ''}>{title}</h2>
      </div>
      {children}
    </div>
  );
};
