import { Router } from 'framework7/types';
import { FC } from 'react';
import { WarrantyList } from '../WarrantyList/WarrantyList';
import { CarInfoTiredToTheWarranty } from '../WarrantyManagementHelper';
import { SettlementInformation } from '@/components/projects/SettlementInformation/SettlementInformation';
import {
  BillInformationDetail,
  NextWarranty,
  PaymentMethod,
  WarrantiesAppendWarranty,
  Warranty,
} from '@/types/api/customerApi';
import { isCancellationInProcess } from '@/utils/warranty';

interface WarrantyInfoGroupProps {
  f7router: Router.Router;
  carInfoTiredToTheWarranty: CarInfoTiredToTheWarranty;
  warranties: Warranty[];
  nextWarranties: NextWarranty[];
  warrantyNo: string;
  appendWarranty: WarrantiesAppendWarranty | undefined;
  displayPayment: BillInformationDetail | Required<PaymentMethod> | undefined;
  handleClickChangeSetElementMethod: () => void;
}

export const WarrantyInfoGroup: FC<WarrantyInfoGroupProps> = ({
  f7router,
  carInfoTiredToTheWarranty,
  warranties,
  nextWarranties,
  warrantyNo,
  appendWarranty,
  displayPayment,
  handleClickChangeSetElementMethod,
}) => {
  const applyStatus = appendWarranty?.apply_status;
  const warrantyStatus = appendWarranty?.warranty_status;
  const isInCancellation = isCancellationInProcess(applyStatus, warrantyStatus);

  return (
    <>
      <WarrantyList
        f7router={f7router}
        warranties={warranties}
        nextWarranties={nextWarranties}
      />
      {/* 決済情報 */}
      {!isInCancellation && (
        <SettlementInformation
          f7router={f7router}
          displayPayment={displayPayment}
          carInfo={carInfoTiredToTheWarranty}
          handleClickChangeSettlement={handleClickChangeSetElementMethod}
        />
      )}
    </>
  );
};
