import { Router } from 'framework7/types';
import React from 'react';
import { AdminAuth } from './AdminAuth/AdminAuth';

interface AdminAuthPageProps {
  f7route: Router.Route;
}

export const AdminAuthPage: React.FC<AdminAuthPageProps> = ({ f7route }) => {
  return <AdminAuth f7route={f7route} />;
};
