import { Router } from 'framework7/types';
import React, { FC } from 'react';
import { UsedCoupon } from './UsedCoupon/UsedCoupon';

type UsedCouponPageProps = {
  f7router: Router.Router;
};

export const UsedCouponPage: FC<UsedCouponPageProps> = ({ f7router }) => {
  return <UsedCoupon f7router={f7router} />;
};
