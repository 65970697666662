import { FC } from 'react';
import { CalibrationProcessBar } from '../CalibrationProcessBar/CalibrationProcessBar';
import style from './LinkDriveCalibrationStyle.module.css';
import { Button } from '@/components/uiParts/Button';

interface LinkDriveCalibrationStep1Props {
  handleClickAction: () => void;
}

export const LinkDriveCalibrationStep1: FC<LinkDriveCalibrationStep1Props> = ({
  handleClickAction,
}) => {
  return (
    <div className={`${style['step1']}`}>
      <CalibrationProcessBar />
      <h2 className={style.heading}>
        道路交通法に従って一般走行してください。
      </h2>
      <div className={style['description-wrap']}>
        <p>
          測定には
          <span style={{ color: '#1e55e6' }}>
            時速20km以上のスピードで10分間の走行
          </span>
          をお願いします。
        </p>
        <div className={style.step1Note}>
          <p className={style.iconNote}>※</p>
          <p>ボタンをタップしてから、30分以内に10分間の走行をお願いします。</p>
        </div>
      </div>

      <div className={style.button}>
        <div />
        <Button handleClickAction={handleClickAction}>はじめる</Button>
        <div />
      </div>
    </div>
  );
};
