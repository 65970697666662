import { IconProps } from './type';

export const CarDeliveredIcon = ({
  width = '56',
  height = '57',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28.5" r="28" fill={color} fillOpacity="0.1" />
      <path
        d="M12.5739 31.7965C12.8679 29.8995 14.5009 28.5 16.4206 28.5H39.5794C41.4991 28.5 43.1321 29.8995 43.4261 31.7965C43.8081 34.2618 44 36.7529 44 39.2477V39.5C44 40.0523 43.5523 40.5 43 40.5H13C12.4477 40.5 12 40.0523 12 39.5V39.2477C12 36.7529 12.1919 34.2618 12.5739 31.7965Z"
        fill="#B1C7FC"
      />
      <path
        d="M17.4751 22.5429C17.9155 20.7559 19.5185 19.5 21.3589 19.5H34.641C36.4815 19.5 38.0845 20.7559 38.5249 22.5429L39.5 26.5H16.5L17.4751 22.5429Z"
        fill="#B1C7FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9034 41H44.0966C44.5955 41 45 40.5955 45 40.0966C45 37.2656 44.742 34.4406 44.2291 31.6565L44.1588 31.2753C43.8092 29.3776 42.1547 28 40.225 28H15.775C13.8453 28 12.1908 29.3776 11.8412 31.2753L11.7709 31.6565C11.258 34.4406 11 37.2656 11 40.0966C11 40.5955 11.4045 41 11.9034 41ZM44.0966 42H11.9034C10.8522 42 10 41.1478 10 40.0966C10 37.2049 10.2636 34.3192 10.7875 31.4754L10.8577 31.0941C11.2947 28.722 13.3629 27 15.775 27H40.225C42.6371 27 44.7053 28.722 45.1423 31.0941L45.2125 31.4754C45.7364 34.3192 46 37.2049 46 40.0966C46 41.1478 45.1478 42 44.0966 42Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5 28L40.128 21.9024C39.6147 19.6209 37.5885 18 35.25 18H20.75C18.4115 18 16.3853 19.6209 15.872 21.9024L14.5 28H41.5ZM40.25 27L39.1524 22.122C38.7418 20.2967 37.1208 19 35.25 19H20.75C18.8792 19 17.2582 20.2967 16.8476 22.122L15.75 27H40.25Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 43.5C14.5 44.3284 15.1716 45 16 45H18C18.8284 45 19.5 44.3284 19.5 43.5V41.5C19.5 41.2239 19.7239 41 20 41C20.2761 41 20.5 41.2239 20.5 41.5V43.5C20.5 44.8807 19.3807 46 18 46H16C14.6193 46 13.5 44.8807 13.5 43.5V41.5C13.5 41.2239 13.7239 41 14 41C14.2761 41 14.5 41.2239 14.5 41.5V43.5Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5 43.5C36.5 44.3284 37.1716 45 38 45H40C40.8284 45 41.5 44.3284 41.5 43.5V41.5C41.5 41.2239 41.7239 41 42 41C42.2761 41 42.5 41.2239 42.5 41.5V43.5C42.5 44.8807 41.3807 46 40 46H38C36.6193 46 35.5 44.8807 35.5 43.5V41.5C35.5 41.2239 35.7239 41 36 41C36.2761 41 36.5 41.2239 36.5 41.5V43.5Z"
        fill="#323232"
      />
      <path
        d="M20 33C20 34.6569 18.6569 36 17 36C15.3431 36 14 34.6569 14 33C14 31.3431 15.3431 30 17 30C18.6569 30 20 31.3431 20 33Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 35C18.1046 35 19 34.1046 19 33C19 31.8954 18.1046 31 17 31C15.8954 31 15 31.8954 15 33C15 34.1046 15.8954 35 17 35ZM17 36C18.6569 36 20 34.6569 20 33C20 31.3431 18.6569 30 17 30C15.3431 30 14 31.3431 14 33C14 34.6569 15.3431 36 17 36Z"
        fill="#323232"
      />
      <path
        d="M42 33C42 34.6569 40.6569 36 39 36C37.3431 36 36 34.6569 36 33C36 31.3431 37.3431 30 39 30C40.6569 30 42 31.3431 42 33Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39 35C40.1046 35 41 34.1046 41 33C41 31.8954 40.1046 31 39 31C37.8954 31 37 31.8954 37 33C37 34.1046 37.8954 35 39 35ZM39 36C40.6569 36 42 34.6569 42 33C42 31.3431 40.6569 30 39 30C37.3431 30 36 31.3431 36 33C36 34.6569 37.3431 36 39 36Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0604 24H12.5C11.9477 24 11.5 24.4477 11.5 25V26C11.5 26.5523 11.9477 27 12.5 27H14.6802L15.041 25.1961C15.1647 24.5773 14.6914 24 14.0604 24ZM15.5 28L16.0216 25.3922C16.2691 24.1546 15.3225 23 14.0604 23H12.5C11.3954 23 10.5 23.8954 10.5 25V26C10.5 27.1046 11.3954 28 12.5 28H15.5Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.9396 24H43.5C44.0523 24 44.5 24.4477 44.5 25V26C44.5 26.5523 44.0523 27 43.5 27H41.3198L40.959 25.1961C40.8353 24.5773 41.3086 24 41.9396 24ZM40.5 28L39.9784 25.3922C39.7309 24.1546 40.6775 23 41.9396 23H43.5C44.6046 23 45.5 23.8954 45.5 25V26C45.5 27.1046 44.6046 28 43.5 28H40.5Z"
        fill="#323232"
      />
      <path
        d="M20 37C20 36.7239 20.2239 36.5 20.5 36.5H35.5C35.7761 36.5 36 36.7239 36 37C36 37.2761 35.7761 37.5 35.5 37.5H20.5C20.2239 37.5 20 37.2761 20 37Z"
        fill="#323232"
      />
      <path
        d="M20 39C20 38.7239 20.2239 38.5 20.5 38.5H35.5C35.7761 38.5 36 38.7239 36 39C36 39.2761 35.7761 39.5 35.5 39.5H20.5C20.2239 39.5 20 39.2761 20 39Z"
        fill="#323232"
      />
      <path
        d="M21.5 33.5C21.5 32.3954 22.3954 31.5 23.5 31.5H32.5C33.6046 31.5 34.5 32.3954 34.5 33.5V35.5H21.5V33.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5 32.5H23.5C22.9477 32.5 22.5 32.9477 22.5 33.5V34.5H33.5V33.5C33.5 32.9477 33.0523 32.5 32.5 32.5ZM23.5 31.5C22.3954 31.5 21.5 32.3954 21.5 33.5V35.5H34.5V33.5C34.5 32.3954 33.6046 31.5 32.5 31.5H23.5Z"
        fill="#323232"
      />
      <path
        d="M36 17C36 21.4183 32.4183 25 28 25C23.5817 25 20 21.4183 20 17C20 12.5817 23.5817 9 28 9C32.4183 9 36 12.5817 36 17Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 24C31.866 24 35 20.866 35 17C35 13.134 31.866 10 28 10C24.134 10 21 13.134 21 17C21 20.866 24.134 24 28 24ZM28 25C32.4183 25 36 21.4183 36 17C36 12.5817 32.4183 9 28 9C23.5817 9 20 12.5817 20 17C20 21.4183 23.5817 25 28 25Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8905 14.6877C32.063 14.9033 32.028 15.2179 31.8124 15.3905L27.2383 19.0498C26.7803 19.4161 26.1068 19.3116 25.7815 18.8236L24.084 16.2774C23.9309 16.0476 23.9929 15.7372 24.2227 15.584C24.4525 15.4308 24.7629 15.4929 24.9161 15.7227L26.6136 18.2689L31.1877 14.6096C31.4033 14.4371 31.718 14.472 31.8905 14.6877Z"
        fill="#15A380"
      />
    </svg>
  );
};
