import { yupResolver } from '@hookform/resolvers/yup';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ResendSmsPopup } from '../ResendSmsPopup/ResendSmsPopup';
import { pinCodeSchema } from '../pinCodeSchema';
import style from './pinCodeStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { RhfInput } from '@/components/uiParts/ReactHookForm/RhfInput/RhfInput';
import { LoginPageTemplate } from '@/components/uiParts/Template/LoginPageTemplate/LoginPageTemplate';
import { UnderLineLink } from '@/components/uiParts/UnderLineLink/UnderLineLink';
import { store } from '@/config/store';
import { AffiliateId } from '@/consts/shop';
import { usePinAuth } from '@/hooks/api/auth/usePinAuth';
import { SelectedProcess } from '@/types/api/authApi';
import { addAffiliateIdToRequestParams } from '@/utils/shop';

interface PinCodeProps {
  f7router: Router.Router;
  process: SelectedProcess;
  telNumber: {
    now: string;
    before?: string;
  };
  isModalOpen: boolean;
  affiliateId: AffiliateId;
  changeIsModalOpen: (bool: boolean) => void;
  handleClickReSendSms: () => void;
  handleClickCannotReceive: () => void;
  handleClickReTypeNumber: () => void;
  handleClickShouldReceive: () => void;
}

interface FormInput {
  pinCode: string;
}

const PIN_CODE_MAX_LENGTH = 6;

export const PinCode: FC<PinCodeProps> = ({
  f7router,
  process,
  telNumber,
  isModalOpen,
  affiliateId,
  changeIsModalOpen,
  handleClickReSendSms,
  handleClickCannotReceive,
  handleClickReTypeNumber,
  handleClickShouldReceive,
}) => {
  const formMethods = useForm<FormInput>({
    mode: 'onChange',
    defaultValues: { pinCode: '' },
    resolver: yupResolver(pinCodeSchema),
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = formMethods;
  const { authenticatePin } = usePinAuth(f7router);
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const params = {
      selected_process: process,
      phone_no: telNumber.now,
      authentication_code: data.pinCode,
      m_customer_id: store.state.authInfo.m_customer_id,
      phone_no_before: telNumber.before,
    };
    addAffiliateIdToRequestParams(affiliateId, params);
    await authenticatePin(params);
  };

  // Watch Pingcode change and supply it to RhfInput
  const pinCodeValue = watch('pinCode');

  return (
    <LoginPageTemplate pageName="PinCode" title="認証コードの入力" showNavBack>
      <div className={style['container']}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style['email-input-label-wrapper']}>
              <span>認証コードを入力してください</span>
              <div className={style['form-check-icon-wrapper']}>
                <RhfInput
                  className={style['input']}
                  name="pinCode"
                  type="password"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={pinCodeValue}
                  placeholder="6桁の認証コード"
                  onChangeOverride={true}
                  onChange={(e) => {
                    if (e.target.value.length <= PIN_CODE_MAX_LENGTH) {
                      // TODO: There is a utility function that removes non numeric value from text
                      // use it once its merged into develop.
                      // Remove any non-numeric characters from the input value
                      const updatedValue = e.target.value.replace(/\D/g, '');
                      setValue('pinCode', updatedValue);
                    }
                  }}
                />
                <div>
                  <CheckIcon isValid={isValid} />
                </div>
              </div>
              <span className={style['phone-input-label-wrapper']}>{`${
                telNumber ? telNumber?.now : store.state.loginInfo.tel
              } に\n届いた6桁の番号を入力して下さい`}</span>
            </div>
            <div className={style['button-container']}>
              <div className={style['button-wrapper']}>
                <Button type="submit" disabled={!isValid}>
                  認証する
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
        <div className={style['text-link-box']}>
          <UnderLineLink
            onClick={() => changeIsModalOpen(true)}
            text="届かない/確認方法がわからない"
          />
          {/* メールアドレス変更画面(creat_account_by_email/resend_sms/)に遷移するがページがないためコメントアウト */}
          <UnderLineLink
            onClick={() => handleClickShouldReceive()}
            text="SMSを受信できない番号をお使いの方"
          />
        </div>
      </div>
      <ResendSmsPopup
        isModalOpen={isModalOpen}
        changeIsModalOpen={changeIsModalOpen}
        handleClickReSendSms={handleClickReSendSms}
        handleClickCannotReceive={handleClickCannotReceive}
        handleClickReTypeNumber={handleClickReTypeNumber}
        handleClickShouldReceive={handleClickShouldReceive}
      />
    </LoginPageTemplate>
  );
};
