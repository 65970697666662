import { Button, Swiper, SwiperSlide, f7 } from 'framework7-react';
import { FC, ReactNode, useState } from 'react';
import style from './CarWashConditionerAboutPageStyle.module.css';
import { ReactComponent as IconCarWashConditionerPoint1 } from '@/assets/images/carwash/carwash_conditioner_point_1.svg';
import { ReactComponent as IconCarWashConditionerPoint2 } from '@/assets/images/carwash/carwash_conditioner_point_2.svg';
import { ReactComponent as IconCarWashConditionerPoint3 } from '@/assets/images/carwash/carwash_conditioner_point_3.svg';
import { ReactComponent as IconCarWashConditionerPoint4 } from '@/assets/images/carwash/carwash_conditioner_point_4.svg';
import CarWashConditionerStep1 from '@/assets/images/carwash/carwash_conditioner_step_1.svg';
import CarWashConditionerStep2 from '@/assets/images/carwash/carwash_conditioner_step_2.svg';
import CarWashConditionerStep3 from '@/assets/images/carwash/carwash_conditioner_step_3.svg';
import CarWashConditionerStep4 from '@/assets/images/carwash/carwash_conditioner_step_4.svg';
import CarWashConditionerStep5 from '@/assets/images/carwash/carwash_conditioner_step_5.svg';
import CarWashConditionerWhy1 from '@/assets/images/carwash/carwash_conditioner_why_1.png';
import CarWashConditionerWhy2 from '@/assets/images/carwash/carwash_conditioner_why_2.png';
import { ReactComponent as IconCarWashConditionerCard } from '@/assets/images/menu/icon_car-wash-conditioner-card.svg';
import { paths } from '@/config/paths';
import { usePromote } from '@/hooks/usePromote';
import { f7CustomBack } from '@/utils/utils';

type AboutContentProps = {
  showLink?: boolean;
};

export const AboutContent: FC<AboutContentProps> = ({ showLink = true }) => {
  const conditionerleClick = () => {
    const carwashIndex = f7.views.main.router.history.findIndex(
      (value) => value === paths.carWashConditioner,
    );
    if (
      carwashIndex !== -1 &&
      carwashIndex === f7.views.main.router.history.length - 2
    ) {
      f7CustomBack();
    } else {
      f7.views.main.router.navigate(paths.carWashConditioner);
    }
  };
  const handleScroll = (id: string) => {
    document.querySelector('.popup.modal-in .page-content')?.scrollTo?.({
      behavior: 'smooth',
      top: document.getElementById(id)?.offsetTop,
    });
  };
  const { isShowPromote } = usePromote();
  return (
    <div className={style['container']}>
      <div className={style['heading-card']}>
        <div className={style['heading-card-content']}>
          <h4>
            出張カーエアコン
            <br />
            クリーニング
          </h4>
          <p>“カビ・臭い”にお悩みの方必見！</p>
        </div>
        <IconCarWashConditionerCard className={style['heading-card-image']} />
      </div>
      <div className={`${style['section']} ${style['has-bottom-sign']}`}>
        <svg
          className={style['bottom-sign']}
          xmlns="http://www.w3.org/2000/svg"
          width="47"
          height="21"
          viewBox="0 0 47 21"
          fill="none"
        >
          <path d="M0 0H47L24.675 21L0 0Z" fill="white" />
        </svg>
        <div className={style['section-head']}>
          <h4>ABOUT</h4>
          <p>出張カーエアコンクリーニングとは</p>
        </div>
        <p className={`${style['paragraph']} ${style['text-center']}`}>
          {
            '当社が独自に開発した洗浄工具を使用した\nオリジナルのお掃除サービスです。\nカーエアコンの臭いを改善することができます。'
          }
        </p>
        <div className={style['section-about-list']}>
          <a
            href="#point"
            className={style['section-about-list-item']}
            onClick={() => handleScroll('point')}
          >
            <p>出張カーエアコンクリーニングの4つのポイント</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9068 19.7316C15.8303 19.7316 19.8215 15.7404 19.8215 10.817C19.8215 5.89357 15.8303 1.90234 10.9068 1.90234C5.98341 1.90234 1.99219 5.89357 1.99219 10.817C1.99219 15.7404 5.98341 19.7316 10.9068 19.7316ZM11.3796 15.7471L15.6345 11.4921C15.8956 11.231 15.8956 10.8077 15.6345 10.5466C15.3734 10.2855 14.9501 10.2855 14.689 10.5466L11.5754 13.6602V6.35967C11.5754 5.99041 11.2761 5.69107 10.9068 5.69107C10.5376 5.69107 10.2382 5.99041 10.2382 6.35967V13.6602L7.12467 10.5466C6.86356 10.2855 6.44023 10.2855 6.17913 10.5466C5.91802 10.8077 5.91802 11.231 6.17913 11.4921L10.4341 15.7471C10.6952 16.0082 11.1185 16.0082 11.3796 15.7471Z"
                fill="#4883DB"
              />
            </svg>
          </a>
          <a
            href="#why"
            className={style['section-about-list-item']}
            onClick={() => handleScroll('why')}
          >
            <p>車のエアコンが汚れるのはなぜ？</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9068 19.7316C15.8303 19.7316 19.8215 15.7404 19.8215 10.817C19.8215 5.89357 15.8303 1.90234 10.9068 1.90234C5.98341 1.90234 1.99219 5.89357 1.99219 10.817C1.99219 15.7404 5.98341 19.7316 10.9068 19.7316ZM11.3796 15.7471L15.6345 11.4921C15.8956 11.231 15.8956 10.8077 15.6345 10.5466C15.3734 10.2855 14.9501 10.2855 14.689 10.5466L11.5754 13.6602V6.35967C11.5754 5.99041 11.2761 5.69107 10.9068 5.69107C10.5376 5.69107 10.2382 5.99041 10.2382 6.35967V13.6602L7.12467 10.5466C6.86356 10.2855 6.44023 10.2855 6.17913 10.5466C5.91802 10.8077 5.91802 11.231 6.17913 11.4921L10.4341 15.7471C10.6952 16.0082 11.1185 16.0082 11.3796 15.7471Z"
                fill="#4883DB"
              />
            </svg>
          </a>
          <a
            href="#diff"
            className={style['section-about-list-item']}
            onClick={() => handleScroll('diff')}
          >
            <p>既存の方法との違い</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9068 19.7316C15.8303 19.7316 19.8215 15.7404 19.8215 10.817C19.8215 5.89357 15.8303 1.90234 10.9068 1.90234C5.98341 1.90234 1.99219 5.89357 1.99219 10.817C1.99219 15.7404 5.98341 19.7316 10.9068 19.7316ZM11.3796 15.7471L15.6345 11.4921C15.8956 11.231 15.8956 10.8077 15.6345 10.5466C15.3734 10.2855 14.9501 10.2855 14.689 10.5466L11.5754 13.6602V6.35967C11.5754 5.99041 11.2761 5.69107 10.9068 5.69107C10.5376 5.69107 10.2382 5.99041 10.2382 6.35967V13.6602L7.12467 10.5466C6.86356 10.2855 6.44023 10.2855 6.17913 10.5466C5.91802 10.8077 5.91802 11.231 6.17913 11.4921L10.4341 15.7471C10.6952 16.0082 11.1185 16.0082 11.3796 15.7471Z"
                fill="#4883DB"
              />
            </svg>
          </a>
          <a
            href="#htu"
            className={style['section-about-list-item']}
            onClick={() => handleScroll('htu')}
          >
            <p>ご利用の流れ</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9068 19.7316C15.8303 19.7316 19.8215 15.7404 19.8215 10.817C19.8215 5.89357 15.8303 1.90234 10.9068 1.90234C5.98341 1.90234 1.99219 5.89357 1.99219 10.817C1.99219 15.7404 5.98341 19.7316 10.9068 19.7316ZM11.3796 15.7471L15.6345 11.4921C15.8956 11.231 15.8956 10.8077 15.6345 10.5466C15.3734 10.2855 14.9501 10.2855 14.689 10.5466L11.5754 13.6602V6.35967C11.5754 5.99041 11.2761 5.69107 10.9068 5.69107C10.5376 5.69107 10.2382 5.99041 10.2382 6.35967V13.6602L7.12467 10.5466C6.86356 10.2855 6.44023 10.2855 6.17913 10.5466C5.91802 10.8077 5.91802 11.231 6.17913 11.4921L10.4341 15.7471C10.6952 16.0082 11.1185 16.0082 11.3796 15.7471Z"
                fill="#4883DB"
              />
            </svg>
          </a>
          <a
            href="#price"
            className={style['section-about-list-item']}
            onClick={() => handleScroll('price')}
          >
            <p>価格</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9068 19.7316C15.8303 19.7316 19.8215 15.7404 19.8215 10.817C19.8215 5.89357 15.8303 1.90234 10.9068 1.90234C5.98341 1.90234 1.99219 5.89357 1.99219 10.817C1.99219 15.7404 5.98341 19.7316 10.9068 19.7316ZM11.3796 15.7471L15.6345 11.4921C15.8956 11.231 15.8956 10.8077 15.6345 10.5466C15.3734 10.2855 14.9501 10.2855 14.689 10.5466L11.5754 13.6602V6.35967C11.5754 5.99041 11.2761 5.69107 10.9068 5.69107C10.5376 5.69107 10.2382 5.99041 10.2382 6.35967V13.6602L7.12467 10.5466C6.86356 10.2855 6.44023 10.2855 6.17913 10.5466C5.91802 10.8077 5.91802 11.231 6.17913 11.4921L10.4341 15.7471C10.6952 16.0082 11.1185 16.0082 11.3796 15.7471Z"
                fill="#4883DB"
              />
            </svg>
          </a>
          {/* <a
              href="#faq"
              className={style['section-about-list-item']}
              onClick={() => handleScroll('faq')}
            >
              <p>よくあるご質問</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.9068 19.7316C15.8303 19.7316 19.8215 15.7404 19.8215 10.817C19.8215 5.89357 15.8303 1.90234 10.9068 1.90234C5.98341 1.90234 1.99219 5.89357 1.99219 10.817C1.99219 15.7404 5.98341 19.7316 10.9068 19.7316ZM11.3796 15.7471L15.6345 11.4921C15.8956 11.231 15.8956 10.8077 15.6345 10.5466C15.3734 10.2855 14.9501 10.2855 14.689 10.5466L11.5754 13.6602V6.35967C11.5754 5.99041 11.2761 5.69107 10.9068 5.69107C10.5376 5.69107 10.2382 5.99041 10.2382 6.35967V13.6602L7.12467 10.5466C6.86356 10.2855 6.44023 10.2855 6.17913 10.5466C5.91802 10.8077 5.91802 11.231 6.17913 11.4921L10.4341 15.7471C10.6952 16.0082 11.1185 16.0082 11.3796 15.7471Z"
                  fill="#4883DB"
                />
              </svg>
            </a> */}
        </div>
      </div>
      <div id="point" className={`${style['section']} ${style['bg-sub']}`}>
        <div className={style['section-head']}>
          <h4>POINT</h4>
          <p>出張カーエアコンクリーニングの4つのポイント</p>
        </div>
        <div className={style['section-point-item']}>
          <div
            className={`${style['section-point-icon']} ${style['text-center']}`}
          >
            <IconCarWashConditionerPoint1 />
          </div>
          <h4>特許取得の技術</h4>
          <p className={style['paragraph']}>
            独自開発した特許(特許第6529145号)の専用工具によりエバポレーター(車のエアコン内部)を外さずに丸洗いすることでエバポレーターを外して洗浄した場合と同等の効果が得られます。
          </p>
        </div>
        <div className={style['section-point-item']}>
          <div
            className={`${style['section-point-icon']} ${style['text-center']}`}
          >
            <IconCarWashConditionerPoint2 />
          </div>
          <h4>アレルギー予防・改善</h4>
          <p className={style['paragraph']}>
            エバポレーターにはホコリや水分が蓄積され、カビやダニの温床になる可能性があります。また、PM2.5や花粉もたまり、これらは健康被害の原因となります。当社のエバポレーター洗浄でこの問題を改善できます。
          </p>
        </div>
        <div className={style['section-point-item']}>
          <div
            className={`${style['section-point-icon']} ${style['text-center']}`}
          >
            <IconCarWashConditionerPoint3 />
          </div>
          <h4>高い顧客満足度</h4>
          <p className={style['paragraph']}>
            エバポレーターに起因した臭いはほぼ完全に取り除けるため、100%に近いお客様の満足度を得ております。
          </p>
        </div>
        <div className={style['section-point-item']}>
          <div
            className={`${style['section-point-icon']} ${style['text-center']}`}
          >
            <IconCarWashConditionerPoint4 />
          </div>
          <h4>どこでもできる手軽さ</h4>
          <p className={style['paragraph']}>
            出張での対応が可能です。また、施工時間も1~2時間のため、お車のお預かりが不要です。
          </p>
        </div>
      </div>
      <div id="why" className={style['section']}>
        <div className={style['section-head']}>
          <h4>WHY?</h4>
          <p>車のエアコンが汚れるのはなぜ？</p>
        </div>
        <div className={style['section-why-img']}>
          <img src={CarWashConditionerWhy1} />
        </div>
        <div className={style['section-why-content']}>
          <h4>
            屋外にある車は、家庭用のエアコンよりも常に過酷な環境に置かれています。
          </h4>
          <p className={style['paragraph']}>
            屋外の汚れた空気やホコリ、花粉などを常時吸い込むためエアコンフィルターだけでは防ぎきれません。また車に乗り降りする際に、必ず花粉やホコリなどが車内に入り込むため、常に汚れにさらされることになります。
          </p>
        </div>
        <div className={style['section-why-img']}>
          <div>
            <span>外からの空気</span>
            <span>フィルター</span>
            <span>車内の空気</span>
          </div>
          <img src={CarWashConditionerWhy2} />
        </div>
        <div className={style['section-why-content']}>
          <p className={style['paragraph']}>
            {
              '車のエアコン内部(エバポレーター)は、空気を冷やす際に発生する水分を常に含んだ状態になり、エバポレーターにカビ菌や酵母菌が発生し汚れがどんどん溜まっていきます。\nエアコンフィルターでは、その奥にあるエバポレーターで発生するカビ菌や酵母菌（臭いの原因）を防ぐことはできず、発生した菌は何の障害も無くストレートに車内に吹き込みます。'
            }
          </p>
        </div>
      </div>
      <div id="diff" className={style['section']}>
        <div className={style['section-head']}>
          <h4>DIFFERENCES</h4>
          <p>既存の方法との違い</p>
        </div>
        <div className={style['section-why-content']}>
          <p className={style['paragraph']}>
            直接エアコン内部にアクセスし、モニターを見ながら薬剤を直接噴射し汚れを浮かした上で薬剤をきれいに洗い流します。さらにエアコンダクト内をマイクロミストの除菌消臭液できれいにします。
          </p>
        </div>
        <div className={style['section-diff']}>
          <div className={style['section-diff-col-1']}>
            <div className={style['section-diff-cell-1']} />
            <div
              className={`${style['section-diff-cell-2']} ${style['section-diff-col-1-item']}`}
            >
              持続性
            </div>
            <div
              className={`${style['section-diff-cell-2']} ${style['section-diff-col-1-item']}`}
            >
              {'ダクト内の\nカビや汚れ'}
            </div>
            <div
              className={`${style['section-diff-cell-3']} ${style['section-diff-col-1-item']}`}
            >
              値段
            </div>
          </div>
          <div className={style['section-diff-col-2']}>
            <div className={style['section-diff-cell-1-2']}>
              <h4>{'出張カーエアコン\nクリーニング'}</h4>
            </div>
            <div className={style['section-diff-cell-2']}>
              <DiffCircleIcon />
              <p>新車同様に復活</p>
            </div>
            <div className={style['section-diff-cell-2']}>
              <DiffCircleIcon />
              <p>直接洗浄できるため</p>
            </div>
            <div className={style['section-diff-cell-3-2']}>
              <DiffTriangleIcon />
              <div>
                <h5>高価</h5>
                <p>(費用対効果は高い)</p>
              </div>
            </div>
          </div>
          <div className={style['section-diff-col-3']}>
            <div className={style['section-diff-cell-1']}>
              <h4>既存の方法</h4>
            </div>
            <div className={style['section-diff-cell-2']}>
              <DiffTriangleIcon color="#4883DB" />
              <p>3日程度</p>
            </div>
            <div className={style['section-diff-cell-2']}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.0565 15.4521C15.6819 15.858 15.0493 15.8833 14.6434 15.5087L3.05628 3.95313C2.65046 3.57852 2.62515 2.94587 2.99975 2.54004C3.37436 2.13422 4.00702 2.10892 4.41284 2.48352L16 14.0391C16.4058 14.4137 16.4311 15.0463 16.0565 15.4521Z"
                  fill="#323232"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 2.53473C15.6094 2.1442 14.9763 2.1442 14.5857 2.53473L3 14.0352C2.60948 14.4257 2.60948 15.0588 3 15.4494C3.39053 15.8399 4.02369 15.8399 4.41422 15.4494L16 3.94894C16.3905 3.55842 16.3905 2.92525 16 2.53473Z"
                  fill="#323232"
                />
              </svg>
            </div>
            <div className={style['section-diff-cell-3']}>
              <DiffCircleIcon color="#4883DB" />
              <div>
                <h5>安価</h5>
                <p>自分で施工できる</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="htu" className={`${style['section']} ${style['bg-sub']}`}>
        <div className={style['section-head']}>
          <h4>HOW TO USE</h4>
          <p>ご利用の流れ</p>
        </div>

        <Swiper
          className={style['section-compare-swiper']}
          pagination
          navigation
          space-between="50"
        >
          <SwiperSlide>
            <div className={style['section-compare-swiper-item']}>
              <div className={style['section-swiper-step']}>
                <p>STEP</p>
                <span>01</span>
              </div>
              <img src={CarWashConditionerStep1} />
              <div className={style['section-swiper-content']}>
                <h4>予約・日程調整</h4>
                <p>
                  予約フォームにてご希望の日程をいただき、施工日の調節をいたします。メールまたはお電話で連絡いたします。
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style['section-compare-swiper-item']}>
              <div className={style['section-swiper-step']}>
                <p>STEP</p>
                <span>02</span>
              </div>
              <img src={CarWashConditionerStep2} />
              <div className={style['section-swiper-content']}>
                <h4>お支払い</h4>
                <p>
                  ご予約時に、プリペイドによる事前決済がご利用いただけます。
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style['section-compare-swiper-item']}>
              <div className={style['section-swiper-step']}>
                <p>STEP</p>
                <span>03</span>
              </div>
              <img src={CarWashConditionerStep3} />
              <div className={style['section-swiper-content']}>
                <h4>訪問</h4>
                <p>
                  当日、お客様のお宅に空気の洗車屋さんが訪問し、施工内容・注意事項の確認を行います。
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style['section-compare-swiper-item']}>
              <div className={style['section-swiper-step']}>
                <p>STEP</p>
                <span>04</span>
              </div>
              <img src={CarWashConditionerStep4} />
              <div className={style['section-swiper-content']}>
                <h4>出張カーエアコンクリーニング実施</h4>
                <p>
                  お車を丁寧に養生し作業を開始します。エバポレーター（エアコン内部）は、カメラを使い汚れを確認しながらしっかりと洗浄・すすぎをしていきます。
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style['section-compare-swiper-item']}>
              <div className={style['section-swiper-step']}>
                <p>STEP</p>
                <span>05</span>
              </div>
              <img src={CarWashConditionerStep5} />
              <div className={style['section-swiper-content']}>
                <h4>作業報告と保証書のお渡し</h4>
                <p>
                  洗浄後の汚れた排水の確認、エアコンのニオイの確認をしていただきます。保証書をお渡ししますので、大切に保管ください。
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div id="price" className={style['section']}>
        <div className={style['section-head']}>
          <h4>PRICE</h4>
          <p>価格</p>
        </div>
        <p className={`${style['section-price']}`}>
          セット価格と別売り価格を用意しています。
        </p>
        <div className={isShowPromote ? style['in-promote'] : ''}>
          <div
            className={`${style['section-price-item']}  ${style['theme-blue-light']}`}
          >
            <div className={style['section-price-item-ribbon']}>
              <div className={style['price-ribbon-content']}>
                <p className={style['price-ribbon-label']}>今だけ</p>
                <p className={style['price-ribbon-price']}>
                  1,000
                  <span className={style['price-ribbon-currency']}>円</span>
                </p>
                <p>OFF</p>
              </div>
            </div>
            <div className={style['section-price-item-head']}>
              <svg
                className={style['section-price-item-head-sign']}
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="13"
                viewBox="0 0 29 13"
                fill="none"
              >
                <path d="M0 0H29L14.5 13L0 0Z" fill="#EB7846" />
              </svg>
              <p>オプション全て追加した</p>
              <h4>セット価格</h4>
            </div>
            <div className={style['section-price-item-content']}>
              <p className={style['paragraph-2']}>
                オプション全てをセットで選択いただいた場合はこちらの料金が適用されます。
              </p>
              <div className={style['section-price-list-group']}>
                <div className={style['section-price-list']}>
                  <div className={style['section-price-list-left']}>
                    <h4>国産車</h4>
                  </div>
                  <div className={style['section-price-list-right']}>
                    <p>通常価格</p>
                    <h4>
                      27,500
                      <span>円</span>
                    </h4>
                    <h3>
                      26,500
                      <span>円</span>
                    </h3>
                  </div>
                </div>
                <div className={style['section-price-list']}>
                  <div className={style['section-price-list-left']}>
                    <h4>輸入車</h4>
                  </div>
                  <div className={style['section-price-list-right']}>
                    <p>通常価格</p>
                    <h4>
                      33,500
                      <span>円</span>
                    </h4>
                    <h3>
                      32,500
                      <span>円</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className={style['section-price-item-note']}>※税込価格</div>
              <div className={style['section-price-item-comment']}>
                <h4>セットに含まれる内容</h4>
                <p className={style['paragraph-2']}>
                  出張カーエアコンクリーニング / 防カビコーティング /
                  車内BAZOOKA!消臭
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${style['section-price-item']}  ${style['theme-blue-dark']}`}
        >
          <div className={style['section-price-item-head']}>
            <svg
              className={style['section-price-item-head-sign']}
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="13"
              viewBox="0 0 29 13"
              fill="none"
            >
              <path d="M0 0H29L14.5 13L0 0Z" fill="#4883DB" />
            </svg>
            <p>お好きなものだけをチョイス</p>
            <h4>別売り価格</h4>
          </div>
          <div className={style['section-price-item-content']}>
            <p className={style['paragraph-2']}>
              オプションを個別に選択いただいた場合はこちらの料金が適用されます。
            </p>
            <div className={style['section-price-list-group']}>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>出張カーエアコンクリーニング 国産車</h4>
                </div>
                <div className={style['section-price-list-right']}>
                  <h4>
                    27,500
                    <span>円</span>
                  </h4>
                </div>
              </div>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>出張カーエアコンクリーニング 輸入車</h4>
                </div>
                <div className={style['section-price-list-right']}>
                  <h4>
                    33,000
                    <span>円</span>
                  </h4>
                </div>
              </div>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>防カビコーティング</h4>
                </div>
                <div className={style['section-price-list-right']}>
                  <h4>
                    5,500
                    <span>円</span>
                  </h4>
                </div>
              </div>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>車内BAZOOKA!消臭</h4>
                </div>
                <div className={style['section-price-list-right']}>
                  <h4>
                    16,500
                    <span>円</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className={style['section-price-item-note']}>※税込価格</div>
          </div>
        </div>
        <div className={style['section-price-note']}>
          <div className={style['section-price-note-row']}>
            <span>※</span>
            <p>プロアファンが外せる車種のみとなります。</p>
          </div>
          <div className={style['section-price-note-row']}>
            <span>※</span>
            <p>エアコンフィルターは貴社にてご提供ください。</p>
          </div>
          <div className={style['section-price-note-row']}>
            <span>※</span>
            <p>
              車内消臭はタバコの臭いにも有効ですが、タバコのヤニの付着状況により完全に消えない場合があります。その場合は、別途費用がかかりますが、車内クリーニングの必要性をご提案いたします。
            </p>
          </div>
        </div>
      </div>
      {/* <div id="faq" className={style['section']}>
          <div className={style['section-head']}>
            <h4>FAQ</h4>
            <p>よくあるご質問</p>
          </div>
          <Accordion title="エアコンの風が弱くて全然冷房が効かないのですが、エアコン洗浄で効果はありますか？" />
          <Accordion title="エバポレータ洗浄をお願いしたら、新車のような匂いになるのでしょうか？">
            新車並みにエアコンの匂いは改善します。車自体に染み付いた匂いというものもありますので、車内が新車の匂いになるいことは難しいとは思いますが、エアコンから出てくる風は爽やかな匂いになることが多いです。オプションで車内消臭をご依頼いただくことで、車内全体の匂いの改善になります。
          </Accordion>
          <Accordion title="エバポレーター洗浄で検索すると類似しているサービス内容のサイトが出てきました。貴社のサービスと何が違うのでしょうか？" />
          <Accordion title="貴社と同じようなサービスがありましたが、価格が全然違います。他のサービスはどうしてこんなに安いのでしょうか？" />
          <Accordion title="エバポレーターを高圧洗浄するということですが、車やエアコンが壊れたりしないでしょうか？" />
          <Accordion title="自分でやってみたいと思うのですが、簡単にできますか？" />
        </div> */}
      {showLink && (
        <div className={style['section']}>
          <Button
            fill
            round
            style={{ background: '#4883DB' }}
            onClick={conditionerleClick}
          >
            出張カーエアコンクリーニングを予約する
          </Button>
        </div>
      )}
    </div>
  );
};

type AccordionProps = {
  title: string;
  children?: ReactNode;
};

const Accordion: FC<AccordionProps> = ({ title, children }) => {
  const [opened, setOpened] = useState(false);
  return (
    <div className={style['section-faq']}>
      <div className={style['section-faq-q']}>
        <h4>Q</h4>
        <h5>{title}</h5>
        <div onClick={() => setOpened(!opened)}>
          {!opened ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 9V14H9V9H14V7H9V2H7V7H2V9H7Z"
                fill="#CCCCCC"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path d="M2 7H14V9H2V7Z" fill="#CCCCCC" />
            </svg>
          )}
        </div>
      </div>
      {opened && (
        <div className={style['section-faq-a']}>
          <h4>A</h4>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

const DiffCircleIcon = ({ color = '#FD9347' }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.5391C12.866 16.5391 16 13.4051 16 9.53906C16 5.67307 12.866 2.53906 9 2.53906C5.13401 2.53906 2 5.67307 2 9.53906C2 13.4051 5.13401 16.5391 9 16.5391ZM9 18.5391C13.9706 18.5391 18 14.5096 18 9.53906C18 4.5685 13.9706 0.539062 9 0.539062C4.02944 0.539062 0 4.5685 0 9.53906C0 14.5096 4.02944 18.5391 9 18.5391Z"
        fill={color}
      />
      <circle
        cx="9"
        cy="9.53906"
        r="4.10854"
        stroke={color}
        strokeWidth="1.78293"
      />
    </svg>
  );
};

const DiffTriangleIcon = ({ color = '#FD9347' }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99986 5.02274L3.47385 14.5398H14.5259L8.99986 5.02274ZM9.86464 2.52912C9.4792 1.8653 8.52051 1.86529 8.13507 2.52911L0.872063 15.0376C0.484968 15.7043 0.965953 16.5398 1.73685 16.5398H16.2629C17.0338 16.5398 17.5147 15.7043 17.1276 15.0376L9.86464 2.52912Z"
        fill={color}
      />
    </svg>
  );
};
