import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { cancelImpactNotificationApi } from '@/api/infoApi';
import { CancelImpactNotificationApiParams } from '@/types/api/infoApi';

export const useCancelImpactNotification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const cancelImpact = async (params: CancelImpactNotificationApiParams) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      await cancelImpactNotificationApi(params);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    cancelImpact,
    isLoading,
    error,
  };
};
