import { Router } from 'framework7/types';
import { FC } from 'react';
import { DriveCalender } from './DriveCalender/DriveCalender';

interface DriveCalenderPageProps {
  f7router: Router.Router;
}

export const DriveCalenderPage: FC<DriveCalenderPageProps> = ({ f7router }) => {
  return <DriveCalender f7router={f7router} />;
};
