import { FC } from 'react';
import style from './customerPinInquiryBoxStyle.module.css';

interface CustomerPinInquiryBoxProps {
  infoList: string[];
}
export const CustomerPinInquiryBox: FC<CustomerPinInquiryBoxProps> = ({
  infoList,
}) => {
  return (
    <div className={style['confirmation-box']}>
      <div>
        <p
          className={style['title']}
        >{`お客様照会のため、下記の情報を\n確認させていただきます`}</p>
        <div>
          {infoList.map((el) => (
            <span key={el}>{el}</span>
          ))}
        </div>
      </div>
    </div>
  );
};
