import * as yup from 'yup';

const firstNameValidator = yup.string().when('isCorporation', {
  is: (isCorporation: boolean) => !isCorporation,
  then: yup.string().required('契約者様氏名（姓）は必須です'),
  otherwise: yup.string(),
});

const familyNameValidator = yup.string().when('isCorporation', {
  is: (isCorporation: boolean) => !isCorporation,
  then: yup.string().required('契約者様氏名（名）は必須です'),
  otherwise: yup.string(),
});

const firstNameKanaValidator = yup.string().when('isCorporation', {
  is: (isCorporation: boolean) => !isCorporation,
  then: yup
    .string()
    .required('フリガナ（セイ）は必須です')
    .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
  otherwise: yup.string().when('firstNameKana', {
    is: (firstNameKana: string) =>
      firstNameKana && firstNameKana.trim().length > 0,
    then: yup
      .string()
      .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
    otherwise: yup.string().nullable(),
  }),
});

const familyNameKanaValidator = yup.string().when('isCorporation', {
  is: (isCorporation: boolean) => !isCorporation,
  then: yup
    .string()
    .required('フリガナ（セイ）は必須です')
    .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
  otherwise: yup.string().when('familyNameKana', {
    is: (familyNameKana: string) =>
      familyNameKana && familyNameKana.trim().length > 0,
    then: yup
      .string()
      .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
    otherwise: yup.string().nullable(),
  }),
});

const companyNameValidator = yup.string().when('isCorporation', {
  is: (isCorporation: boolean) => !!isCorporation,
  then: yup.string().required('法人名は必須です'),
  otherwise: yup.string(),
});

const companyNameKanaValidator = yup.string().when('isCorporation', {
  is: (isCorporation: boolean) => !!isCorporation,
  then: yup
    .string()
    .required('法人名ヨミガナは必須です')
    .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
  otherwise: yup.string().when('companyNameKana', {
    is: (companyNameKana: string) =>
      companyNameKana && companyNameKana.trim().length > 0,
    then: yup
      .string()
      .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
    otherwise: yup.string().nullable(),
  }),
});

const yearValidator = yup
  .number()
  .transform((value, originalValue) =>
    String(originalValue).trim() === '' ? null : value,
  )
  .nullable()
  .notRequired()
  .moreThan(1899, '正しい年を入力してください')
  .lessThan(new Date().getFullYear() + 1, '現在より先の年を入力できません')
  .test('year', '正しい生年月日を入力してください', (value, context) => {
    const { year, month, day } = context.parent;
    if (!year && !month && !day) {
      return true; // If all are undefined or null, pass the test
    }
    return !!year && !!month && !!day; // If any value is present, all must be present
  });

const monthValidator = yup
  .number()
  .nullable()
  .notRequired()
  .transform((value, originalValue) =>
    String(originalValue).trim() === '' ? null : value,
  )
  .test('month', '正しい生年月日を入力してください', (value, context) => {
    const { year, month, day } = context.parent;
    if (!year && !month && !day) {
      return true; // If all are undefined or null, pass the test
    }
    return !!year && !!month && !!day; // If any value is present, all must be present
  })
  .moreThan(0, '正しい月を入力してください')
  .lessThan(13, '正しい月を入力してください');

const dayValidator = yup
  .number()
  .nullable()
  .notRequired()
  .transform((value, originalValue) =>
    String(originalValue).trim() === '' ? null : value,
  )
  .test('day', '正しい生年月日を入力してください', function (value) {
    const { year, month, day } = this.parent;
    if (!year && !month && !day) {
      return true; // If all are undefined or null, pass the test
    }
    if (!year || !month || !day) {
      return false; // If any value is present, all must be present
    }

    if (!value) return false;

    const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    const daysInMonth = [
      31,
      isLeapYear ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    return value > 0 && value <= daysInMonth[month - 1]; // Check if the day is valid for the given month and year
  })
  .lessThan(32, '正しい日を入力してください'); // Max 31, specific check handled above

export const customerSchema = yup.object().shape({
  firstName: firstNameValidator,
  familyName: familyNameValidator,
  firstNameKana: firstNameKanaValidator,
  familyNameKana: familyNameKanaValidator,
  companyName: companyNameValidator,
  companyNameKana: companyNameKanaValidator,
  year: yearValidator,
  month: monthValidator,
  day: dayValidator,
});

export const NamesValidation = yup.object().shape({
  firstName: firstNameValidator,
  familyName: familyNameValidator,
});

export const NamesKanaValidation = yup.object().shape({
  firstNameKana: firstNameKanaValidator,
  familyNameKana: familyNameKanaValidator,
});

export const CompanyNamesValidation = yup.object().shape({
  companyName: companyNameValidator,
});

export const CompanyNamesKanaValidation = yup.object().shape({
  companyNameKana: companyNameKanaValidator,
});

export const DatesValidator = yup.object().shape({
  year: yearValidator,
  month: monthValidator,
  day: dayValidator,
});
