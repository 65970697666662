import { AxiosError } from 'axios';
import { useState } from 'react';
import { connectorStatus } from '@/api/linkDriveApi';
import {
  ConnectorStatusApiParams,
  ConnectorStatusApiResponse,
} from '@/types/api/linkDriveApi';

interface Args {
  params: ConnectorStatusApiParams;
  resolve?: (data: ConnectorStatusApiResponse['data']) => void;
}

export const useConnectorStatus = () => {
  const [data, setData] = useState<ConnectorStatusApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const fetchConnectorStatus = async (args: Args) => {
    try {
      setIsLoading(true);
      const { data } = await connectorStatus(args.params);
      setData(data);
      args.resolve?.(data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    fetchConnectorStatus,
    data,
    error,
  };
};
