import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button } from '@/components/uiParts/Button';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { TextInput } from '@/components/uiParts/TextInput/TextInput';
import './couponCodeStyle.scss';

type CouponCode = {
  open: boolean;
  isActive: boolean;
  identificationNumber: string | undefined;
  handleClose: () => void;
  handleInput: (code: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const CouponCode: FC<CouponCode> = ({
  open,
  isActive,
  identificationNumber,
  handleClose,
  handleInput,
}) => {
  const handleClickConfirm = () => {
    if (!identificationNumber) return;
    handleClose();
  };

  return (
    <PopupTemplate
      title="暗証番号の入力"
      opened={open}
      handleClose={handleClose}
    >
      <div className="last-block">
        <div className="list no-hairlines sub-block block-area">
          <ul className="text-area">
            <NumericFormat
              label="クーポンの暗証番号を入力してください"
              customInput={TextInput}
              inputMode="numeric"
              max={9999}
              value={
                identificationNumber == null
                  ? ''
                  : identificationNumber.slice(0, 4)
              }
              allowNegative={false}
              placeholder="0000"
              required={true}
              error=""
              changeText={handleInput}
            />
          </ul>
        </div>

        <div className="u-padding-right-sm u-padding-left-sm u-margin-top-md u-text-center">
          <Button
            text="確定する"
            isActive={isActive}
            handleClickAction={handleClickConfirm}
          />
        </div>
      </div>
    </PopupTemplate>
  );
};
