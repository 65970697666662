import { Checkbox as F7Checkbox } from 'framework7-react';
import { CheckboxProps as F7CheckboxProps } from 'framework7-react/components/checkbox';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldWrapper } from '../FieldWrapper';
import { ControllerFieldForm } from '../Form';
import style from './CheckboxStyle.module.css';

interface ControlerCheckboxProps extends F7CheckboxProps {
  name: string;
  handleOnChange?: (name: string) => void;
}

export const ControlerCheckbox: FC<
  ControllerFieldForm<ControlerCheckboxProps>
> = ({ label, error, name, handleOnChange, ...props }) => {
  const { control } = useFormContext();
  return (
    <FieldWrapper label={label} error={error}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, name } }) => {
          const handleChange = (e: any) => {
            handleOnChange && handleOnChange(name);
            onChange(e);
          };

          return (
            <F7Checkbox
              className={style.checkBox}
              defaultChecked={value}
              checked={value ?? false}
              value={value}
              onChange={handleChange}
              {...props}
            />
          );
        }}
      />
    </FieldWrapper>
  );
};
