import { FC } from 'react';
import style from './CarChangeListStyle.module.css';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { store } from '@/config/store';
import { Affiliate } from '@/types/api/authApi';
import { Car } from '@/types/api/customerApi';
import { carNameTitle } from '@/utils/home';
import { getCarPhoto } from '@/utils/utils';

interface CarChangeListProps {
  isOpenCarList: boolean;
  selectedCar?: Car;
  affiliatesInfo: Affiliate[];
  changeIsOpenCarList: (bool: boolean) => void;
  handleClickListImage: (
    car: Affiliate['cars'][0] | null,
    affiliateID: number,
  ) => Promise<void>;
}
export const CarChangeList: FC<CarChangeListProps> = ({
  isOpenCarList,
  selectedCar,
  affiliatesInfo,
  changeIsOpenCarList,
  handleClickListImage,
}) => {
  return (
    <PopupTemplate
      id="ResendSms"
      opened={isOpenCarList}
      handleClose={() => changeIsOpenCarList(false)}
      onPopupClosed={() => changeIsOpenCarList(false)}
      title="車両切り替え"
    >
      <h3 className={style['sub-title']}>
        切り替えたい車両を選択してください。
      </h3>
      <div className={style['container']}>
        {affiliatesInfo.map((affiliate) => (
          <div
            className={
              store.state.globalTheme == null ? style['car-card-wrapper'] : ''
            }
            key={affiliate.id}
          >
            {store.state.globalTheme == null && affiliatesInfo.length > 1 && (
              <p>{affiliate.affiliate_name}</p>
            )}
            {!affiliate.cars.length && (
              <div
                className={style['car-card']}
                onClick={() => handleClickListImage(null, affiliate.id)}
              >
                <div className={style['car-image-wrapper']}>
                  <img src={getCarPhoto()} />
                </div>
                <div className={style['car-information-wrapper']}>
                  <span className={style['car-information-title']}>
                    契約中車両はありません
                  </span>
                </div>
                <div
                  className={`${style['car-icon-group']} ${
                    store.state.authInfo.m_customer_id === affiliate.id &&
                    style['active']
                  }`}
                >
                  <i className={`icon-circle ${style['icon-circle']}`} />
                  <i className={`icon-check ${style['icon-check']}`} />
                </div>
              </div>
            )}
            {affiliate.cars.map((car) => (
              <div
                className={style['car-card']}
                key={car.m_car_id || car.t_order_id}
                onClick={() => handleClickListImage(car, affiliate.id)}
              >
                <div className={style['car-image-wrapper']}>
                  <img src={getCarPhoto(car)} />
                </div>
                <div className={style['car-information-wrapper']}>
                  <span className={style['car-information-title']}>
                    {carNameTitle(
                      true,
                      true,
                      car?.car_nm || '現在契約中の車両はありません',
                    )}
                  </span>
                  <span className={style['car-information-number']}>
                    {car?.registry_no || ''}
                  </span>
                </div>
                <div
                  className={`${style['car-icon-group']} ${
                    selectedCar &&
                    affiliate.id === store.state.customerInfo.id &&
                    (selectedCar?.m_car_id == null
                      ? selectedCar?.t_order_id === car.t_order_id
                      : selectedCar?.m_car_id === car.m_car_id) &&
                    style['active']
                  }`}
                >
                  <i className={`icon-circle ${style['icon-circle']}`} />
                  <i className={`icon-check ${style['icon-check']}`} />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </PopupTemplate>
  );
};
