import { Router } from 'framework7/types';
import { FC } from 'react';
import { DriveShockHistory } from './DriveShockHistory';

interface DriveShockHistoryPageProps {
  f7router: Router.Router;
  f7route: Router.Route;
}

export const DriveShockHistoryPage: FC<DriveShockHistoryPageProps> = ({
  f7router,
  f7route,
}) => {
  return <DriveShockHistory f7router={f7router} title={f7route.name} />;
};
