import { Router } from 'framework7/types';
import { FC } from 'react';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { OptionsCheckListType } from '../DeliveryCheckList/DeliveryCheckList';
import { UserTasks } from './UserTasks/UserTasks';

export type UserTasksPageProps = {
  f7router: Router.Router;
  options: OptionsCheckListType[];
  idx: number;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
};

export const UserTasksPage: FC<UserTasksPageProps> = (props) => {
  return <UserTasks {...props} />;
};
