import { Popup } from 'framework7-react';
import { PopupProps } from 'framework7-react/components/popup';
import { FC } from 'react';
import style from './CarWashHandStep2Style.module.css';
import { Button } from '@/components/uiParts/Button';

export const PopupAbout: FC<PopupProps> = ({ onPopupClosed, ...props }) => {
  return (
    <Popup {...props}>
      <div className={style['permission-popup']}>
        <h4>作業場所の許可取りについて</h4>
        <p>
          マンションや職場など、管理会社への確認が必要なスペースでの洗車をご希望の方は、お客様より管理会社への確認・許可取りをお願いしております。
          <br />
          下記内容をスムーズかと存じますので、ご活用ください。
        </p>
        <p>
          <ul>
            <li>
              水なし洗車は水や泡を使用しない施工となるため、駐車場および他車両を汚すことはございません。
            </li>
            <li>
              植物成分配合で有害物質は一切含まないので、地面および周囲環境に害は与えません。
            </li>
          </ul>
        </p>
        <Button
          className={style['permission-popup-button']}
          onClick={onPopupClosed}
        >
          閉じる
        </Button>
      </div>
    </Popup>
  );
};
