import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { getPrivacyApi } from '@/api/termsApi';

export const usePrivacy = () => {
  const [data, setData] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await getPrivacyApi();
        setData(res.data);
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.message);
        }
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, []);

  return {
    data,
    isLoading,
    error,
  };
};
