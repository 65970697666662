import parse from 'html-react-parser';
import { FC } from 'react';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import './prepaidLawsStyle.scss';

export const PrepaidLaws: FC = () => {
  const prepaidInfo = store.state.advancePaymentsInfo[0];
  const terms = prepaidInfo.term_of_transfer;

  return (
    <PageTemplate
      pageName="PrepaidLaws"
      title="資金決済法に基づく表示"
      showNavBack={true}
    >
      <div className="u-margin-top-sm u-margin-bottom-sm">
        <RoundSheet>
          <div>{parse(terms)}</div>
        </RoundSheet>
      </div>
    </PageTemplate>
  );
};
