import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import styles from './UpdateWarrantyComplete.module.css';
import { Button } from '@/components/uiParts/Button';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { List } from '@/components/uiParts/List/List';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { paymentTypeId as PAYMENT_TYPE_ID } from '@/consts/payment';
import { latestResult } from '@/consts/warranty';
import { UpdateWarrantyCompleteWarrantyInfo } from '@/types/warranty';
import { slashToYear } from '@/utils/date';

interface UpdateWarrantyCompleteProps {
  f7router: Router.Router;
  warrantyInfo: UpdateWarrantyCompleteWarrantyInfo;
}

//Todo: ページ必要か確認
export const UpdateWarrantyComplete: FC<UpdateWarrantyCompleteProps> = ({
  f7router,
  warrantyInfo: warranty,
}) => {
  const warrantyInfo = {
    ...warranty,
    // TODO: 仮データ
    paymentTypeId: 4,
    paymentTypeName: 'test銀行',
    accountNumber: '100000',
    cardNo: '111111',
  };

  const warrantyList = [
    { label: '保証名', value: warrantyInfo.warrantyName },
    { label: '保証番号', value: warrantyInfo.warrantyNo },
    {
      label: '保証開始日',
      value: warrantyInfo.warrantyStartDate,
    },
    { label: '保証終了日', value: warrantyInfo.warrantyEndDate },
    {
      label: '免責金',
      value: (
        <div>
          <p>販売店入庫：{warrantyInfo.deductibleS}</p>
          <p>他社様への入庫：{warrantyInfo.deductibleO}</p>
          <p>※一度の修理受付につき、「修理事務手数料」として</p>
        </div>
      ),
    },
    {
      label: '保証限度額',
      value: warrantyInfo.amountLimit
        ? warrantyInfo.amountLimit + ' 円'
        : '無制限',
    },
    { label: '規定走行距離', value: `${warrantyInfo.maxTrip}km` },
    { label: '規定経過年数', value: `${warrantyInfo.yearsLimit}年未満` },
  ];

  const makePaymentMethodObj = () => {
    const paymentObj = {
      name: 'credit-card',
      noValue: `カードNo ${warrantyInfo.cardNo}`,
    };
    if (warrantyInfo.paymentTypeId === PAYMENT_TYPE_ID.BANK) {
      paymentObj.name = 'bank';
      paymentObj.noValue = `口座番号 ${warrantyInfo.accountNumber}`;
    }
    return paymentObj;
  };

  const makeCancelWarrantyObj = () => {
    const cancelWarrantyObj = {
      path: paths.warrantyCancelable(warrantyInfo.warrantyNo),
      subText: '※現在解約可能期間外です',
      subTextStyle: { color: '#c9c9c9', fontSize: '14px' },
    };
    if (warrantyInfo.cancelEnableTerm) {
      cancelWarrantyObj.path = paths.warranty('false');
      cancelWarrantyObj.subText = `${warrantyInfo.cancelEnableTermEnd} 23:59まで解約可能です`;
      cancelWarrantyObj.subTextStyle = { color: '#ff4655', fontSize: '14px' };
    }
    return cancelWarrantyObj;
  };

  const paymentMethodObj = makePaymentMethodObj();
  const cancelWarrantyObj = makeCancelWarrantyObj();

  const handleClickPlanChange = () => {
    f7.view.main.router.navigate(paths.updateWarranty(warrantyInfo.warrantyNo));
  };
  const handleClickPaymentChange = () => {
    // f7.view.main.router.navigate(paths.registerPayment);
  };
  return (
    <PageTemplate
      pageName="UpdateWarrantyComplete"
      title="保証管理"
      showNavBack
    >
      <div className={styles['container']}>
        <RoundSheet>
          <div className={styles['button-title-wrapper']}>
            <h3>ご契約中の保証プラン</h3>
            <div className={`${styles['button-wrapper']} no-active-state`}>
              <Button handleClickAction={handleClickPlanChange}>
                変更する
              </Button>
            </div>
          </div>
          <div className={styles['info-card-wrapper']}>
            <InfoCard
              className={styles['warning-infoCard']}
              bgColor="warning"
              text="プラン変更を承りました"
              textSub={`変更適用日：${slashToYear(
                warrantyInfo.warrantyStartDate,
              )}`}
              iconType="confirm-notification"
              url={`/update_warranty_detail/'${warrantyInfo.warrantyNo}/`}
              isIconRight
            />
          </div>
          <List list={warrantyList} />
        </RoundSheet>
        <RoundSheet>
          <div className={styles['button-title-wrapper']}>
            <h3>決済情報</h3>
            <div className={`${styles['button-wrapper']} no-active-state`}>
              <Button handleClickAction={handleClickPaymentChange}>
                変更する
              </Button>
            </div>
          </div>
          {warrantyInfo.latestResult === latestResult.SUCCESS && (
            <InfoCard
              className={styles['danger-infoCard']}
              bgColor="danger"
              text="引き落としが確認できませんでした"
              textSub="2021年10月の支払い"
              iconType="exclamationmark_circle"
              url={paths.updateWarrantyDetail(warrantyInfo.warrantyNo)}
            />
          )}
          <div className={styles['payment-method-wrapper']}>
            <i
              className={`${styles['building-icon']} icon-${paymentMethodObj.name}`}
            />
            <div style={{ marginLeft: '1rem' }}>
              <h4 className={styles['payment-method']}>
                {warrantyInfo.paymentTypeName}から引き落とし
              </h4>
              <div>{paymentMethodObj.noValue}</div>
            </div>
          </div>
          <CustomList
            path={`/payments_schedule/${warrantyInfo.warrantyNo}/`}
            text="請求予定"
            subText={`次回請求: ${slashToYear(warrantyInfo.nextBillDate)}`}
            subTextStyle={{ fontSize: '14px', marginTop: '4px' }}
            fontSize="14px"
            isBold
            f7router={f7router}
          />
          <CustomList
            path={`/payments_history/${warrantyInfo.warrantyNo}/`}
            text="お支払い履歴"
            subText={
              warrantyInfo.latestResult !== latestResult.SUCCESS
                ? '前回の引き落としが確認できませんでした'
                : ''
            }
            subTextStyle={{
              fontSize: '14px',
              color: '#ff4655',
              marginTop: '4px',
            }}
            fontSize="14px"
            isBold
            f7router={f7router}
          />
        </RoundSheet>
        <div className={styles['help-rules-wrapper']}>
          <h3>保証に関するヘルプ・規約</h3>
          <div>
            {/* phase2以降 */}
            {/* <CustomList
              path={`/warranty_guide/${warrantyInfo.warranty_no}/`}
              text="ご利用方法"
              f7router={f7router}
            />
            <CustomList
              path={`/warranty_parts_2/${warrantyInfo.warranty_no}/`}
              text="対象部位"
              f7router={f7router}
            /> */}
            <CustomList
              path={paths.warrantyTerms}
              text="保証規約"
              isBottom
              f7router={f7router}
            />
          </div>
        </div>
        <div className={styles['cancel-warranty-wrapper']}>
          <RoundSheet>
            <CustomList
              className={styles['cancel-warranty']}
              text="保証を解約する"
              path={cancelWarrantyObj.path}
              subText={cancelWarrantyObj.subText}
              subTextStyle={cancelWarrantyObj.subTextStyle}
              isTopBorder={false}
              isBold
              f7router={f7router}
            />
          </RoundSheet>
        </div>
      </div>
    </PageTemplate>
  );
};
