import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { useState } from 'react';
import { mobileAuthApi } from '@/api/authApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { loginType } from '@/consts/login';
import { useToast } from '@/hooks/useToast';
import { MobileAuthApiParams } from '@/types/api/authApi';

export const useMobileAuth = (f7router?: Router.Router) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();

  const authenticateMobile = async (
    params: MobileAuthApiParams,
    isResend: boolean = false,
  ) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const { data } = await mobileAuthApi(params);
      if (!params.m_affiliate_id) {
        await store.dispatch('setIsLoggedInToMaikuru', {
          isLoggedInToMaikuru: true,
        });
      }
      if (!data.success) {
        throw new Error(data.error?.message);
      }
      if (!isResend) {
        await store.dispatch('setAuthInfo', {
          authInfo: {
            m_customer_id: data.m_customers.id,
            isLogin: true,
          },
        });
        await store.dispatch('setLoginInfo', {
          loginInfo: {
            email: '',
            tel: params.phone_no,
            loginType: loginType.TELEPHONE,
          },
        });
        f7.view.main.router?.navigate(paths.pinCode, {
          props: {
            process: params.selected_process,
            telNumber: {
              now: params.phone_no,
              before: params.phone_no_before,
            },
          },
        });
        return data;
      } else {
        openToast('SMSを再送しました');
      }
    } catch (e) {
      setError(e as AxiosError);
      if (e instanceof Error) {
        openToast(e.message, 'toast-failed');
      }
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    authenticateMobile,
    isLoading,
    error,
  };
};
