import { FC } from 'react';
import { BannerTemplate } from '../BannerTemplate/BannerTemplate';
import brainTrainingImage from '@/assets/images/brain-training.png';
import brainTrainingImageResponsive from '@/assets/images/brain-training@2x.png';
import { store } from '@/config/store';
import { endpoint } from '@/consts/api';

export const BrainTraining: FC = () => {
  const handleClickGoLink = async () => {
    const carItemInfo = store.state.carItemInfo;
    const baseUrl = endpoint.MAIKURU_API;
    const kanriNo = carItemInfo.before_contract_id;
    const polNo = carItemInfo.before_contract_number;
    const polNoEda = carItemInfo.before_contract_number_sub;
    const sysId = '20';
    const link = `${baseUrl}/v1/telema/brain_game?kanri_no=${kanriNo}&pol_no=${polNo}&pol_no_eda=${polNoEda}&sys_id=${sysId}`;
    window.open(link);
  };
  return (
    <BannerTemplate
      handleClickNavigation={handleClickGoLink}
      imagePath={brainTrainingImage}
      responsiveImagePath={brainTrainingImageResponsive}
      head="川島隆太教授のいきいき脳体操"
      text="アプリで楽しくトレーニング！"
    />
  );
};
