import { Card, List, ListItem } from 'framework7-react';
import { FC, useMemo } from 'react';
import { ChildProblemGuide } from './ChildProblemGuide';
import style from './ProblemGuideStyles.module.css';
import {
  RepairReceptionIcon,
  ReviewCarIcon,
  FactoryStockIcon,
  CarDeliveredIcon,
  DocumentIcon,
  ToolIcon,
  CarRepairIcon,
} from '@/components/uiParts/Icon';

interface ProblemGuideProps {}

export const ProblemGuide: FC<ProblemGuideProps> = () => {
  const ProblemGuideOpt = useMemo(
    () => [
      {
        icon: <RepairReceptionIcon />,
        title: '修理受付',
        content: 'アプリまたは店頭にて修理受付をします。',
      },
      {
        icon: <FactoryStockIcon />,
        title: '工場入庫',
        content:
          '工場にお車を入庫いただきます。（ロードサービスをご利用の場合、受付完了後に担当者がそちらに伺いお車を搬送いたします。）',
      },
      {
        icon: <ReviewCarIcon />,
        title: 'お見積もり・審査',
        content:
          '入庫後、工場にてお見積もりを行い、それが届き次第審査を行います。',
      },
      {
        icon: <ToolIcon />,
        title: '修理準備',
        content: '審査結果を工場に伝え、修理の準備および部品の手配をします。',
      },
      {
        icon: <CarRepairIcon />,
        title: '修理',
        content: '準備が完了しましたら、お車の修理作業を行います。',
      },
      {
        icon: <CarDeliveredIcon />,
        title: '修理完了・納車',
        content:
          '作業が完了しましたら、工場からご報告の上修理済みのお車を納車いたします。',
      },
      {
        icon: <DocumentIcon />,
        title: 'お支払い',
        content: '請求書をご確認いただき、修理代のお支払いに進みます。',
      },
    ],
    [],
  );

  return (
    <Card className={`${style['card']} u-margin-bottom-0`}>
      <h2 className="u-padding-bottom-sm">修理受付後の流れ</h2>
      <List menuList>
        {ProblemGuideOpt.map((item, index) => (
          <ListItem key={`${item.title}-${index}`}>
            <ChildProblemGuide
              icon={item.icon}
              title={item.title}
              content={item.content}
              order={index + 1}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
};
