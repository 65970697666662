export const ImageSetting = Object.freeze({
  BASEURL: 'https://develop.mobi-con.com/api/common/file/file/',
});

export const noticeType = {
  PROMPT_DOCUMENT: 1,
  PROMPT_DEPOSIT: 2,
};

export const TASK_ID = {
  DELIVERY_PREFERRED_DATE: 'delivery_preferred_date',
  DOCUMENTS_SEND: 'documents_send',
  PAYMENT_COMPLETE: 'payment_complete',
  DELIVERY_FOR_CUSTOMER_BY_CUSTOMER: 'delivery_for_customer_by_customer',
  CAR_CHECK_DELIVERY: 'car_check_delivery',
  CAR_CHECK_01_MAINTE_CAR_INSPECT: 'car_check_01_mainte_car_inspect',
  CAR_CHECK_02_MAINTE_CAR_INSPECT: 'car_check_02_mainte_car_inspect',
} as const;

export const DOCUMENT_TYPES = {
  SEAL_CERTIFICATE: '印鑑証明',
  PARKING_CERTIFICATE: '車庫証明',
  POA: '委任状',
  STORAGE_LOCATION_CERTIFICATE_APPLICATION: '自動車保管場所証明申請書',
  STORAGE_LOCATION_MAP: '保管場所の所在図・配置図',
  STORAGE_LOCATION_TITLE_DOCUMENT: '保管場所の仕様権原を疎明する書類',
  TRANSFER_CERTIFICATE: '譲渡証明書',
  OVERPAYMENT_DOCUMENTS: '過誤納金についての書類',
  AUTOMOBILE_TEXT_DEBT_TRANSFER_CONCERNING_DOCUMENTS:
    '自動車税の債務譲渡に関する書類',
};

export const VIEW_STATUS = {
  UNREAD: 0, // 未読
  READ: 1, // 既読
};
