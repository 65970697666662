import { parse } from 'date-fns';
import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './ScanHistoryCardStyle.module.css';
import { paths } from '@/config/paths';
import { ErrorType, ScanResult } from '@/types/api/linkDriveApi';
import { formatToJaDateTime } from '@/utils/date';
import { storeDispatch } from '@/utils/store';

interface ScanHistoryCardProps {
  storeName: string;
  scanResult: ScanResult;
}

export const ScanHistoryCard: FC<ScanHistoryCardProps> = ({
  storeName,
  scanResult,
}) => {
  // powertrain, chassis, body, networkをJSONパースして結合する
  const createErrors = (scanHistory: ScanResult): ErrorType[] => {
    const errors: ErrorType[] = [];
    const powertrain = scanHistory.powertrain;
    const chassis = scanHistory.chassis;
    const body = scanHistory.body;
    const network = scanHistory.network;

    if (!(typeof powertrain === 'string')) {
      errors.push(...powertrain);
    }

    if (!(typeof chassis === 'string')) {
      errors.push(...chassis);
    }

    if (!(typeof body === 'string')) {
      errors.push(...body);
    }

    if (!(typeof network === 'string')) {
      errors.push(...network);
    }

    return errors;
  };
  const errors = createErrors(scanResult);
  const isError = !!errors.length;
  const scanResultId = scanResult.id;
  const implementationDate = formatToJaDateTime(
    parse(scanResult.diagnosed_at, 'yyyy-MM-dd HH:mm:ss', new Date()),
  );
  const handleClickNavigationDetail = () => {
    if (!isError || !scanResultId) return;
    storeDispatch('setScanResultErrors', 'scanResultErrors', errors);
    f7.view.main.router.navigate(`${paths.scanHistory}${scanResultId}/`, {
      animate: true,
    });
  };

  return (
    <div className={style['scan-card']}>
      <ul className={style.lists}>
        <li className={style['link-list']}>
          <a onClick={handleClickNavigationDetail}>
            <div className={style['list-content']}>
              {implementationDate}実施
            </div>
            {isError && (
              <div>
                <i className="icon-right" />
              </div>
            )}
          </a>
        </li>
        {/* <li className={style.list}>
          <div className={style.head}>実施</div>
          <div className={style.body}>{storeName}店で実施</div>
        </li> */}
        <li className={style.list}>
          <div className={style.head}>結果</div>
          {!isError && (
            <div className={style.body}>異常は見つかりませんでした</div>
          )}
          {isError && (
            <div className={`${style.body} ${style['error-body']}`}>
              {errors.length || 0}個のエラーが見つかりました
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};
