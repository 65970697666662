import { f7 } from 'framework7-react';
import { FC, useEffect } from 'react';
import { CarOrderDetail } from './CarOrderDetail/CarOrderDetail';
import { PurchaseOrder, PurchaseOrderOptionItem } from '@/types/api/carApi';
import { f7CustomBack } from '@/utils/utils';
interface CarOrderDetailPageProps {
  id: string;
  purchaseOrder?: PurchaseOrder;
  optionItemList?: PurchaseOrderOptionItem[];
}

export const CarOrderDetailPage: FC<CarOrderDetailPageProps> = ({
  purchaseOrder,
  optionItemList,
}) => {
  useEffect(() => {
    if (!optionItemList || !purchaseOrder) {
      f7.dialog.alert('データの取得に失敗しました。', '', () => {
        f7CustomBack();
      });
    }
  }, [optionItemList, purchaseOrder]);

  return (
    <CarOrderDetail
      purchaseOrder={purchaseOrder}
      optionItemList={optionItemList}
    />
  );
};
