import { FC } from 'react';
import { DrivingScoreTermsDetail } from './DrivingScoreTermsDetail/DrivingScoreTermsDetail';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const DrivingScoreTermsDetailPage: FC = () => {
  const { data } = useTerm(termType.SCORE);
  const html = data?.term;
  return (
    <PopupPageTemplate
      pageName="DrivingScoreTermsDetail"
      title={`安全運転分析サービス\nご利用同意`}
    >
      <DrivingScoreTermsDetail html={html} />
    </PopupPageTemplate>
  );
};
