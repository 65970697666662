import { f7 } from 'framework7-react';
import { FC } from 'react';
import { CommisionedWork } from './CommisionedWork/CommisionedWork';

export const CommisionedWorkPage: FC = () => {
  const handleClickItem = (target: string) => {
    if (target) f7.view.main.router.navigate(target);
  };
  return <CommisionedWork handleClickItem={handleClickItem} />;
};
