import { parse } from 'date-fns';
import { useStore } from 'framework7-react';
import { useEffect, useState } from 'react';
import { Step1FormData } from './useCarWashHandStep1';
import { Step2FormData } from './useCarWashHandStep2';
import { Step3FormData } from './useCarWashHandStep3';
import { store } from '@/config/store';
import {
  carSizes,
  carSizesOptions,
  marginTypeOption,
  parkingTypeOption,
  scratchedCheckOption,
  scratchedChecks,
  securitySystemOption,
  securitySystems,
  workspaceTypeOption,
} from '@/consts/carWash';
import { processingCategory } from '@/consts/prepaid';
import { Car, Prepaid } from '@/types/api/customerApi';
import {
  CourseItem,
  CreateCarWashParams,
  OptionItem,
} from '@/types/api/reservationApi';
import { calculateCarSize } from '@/utils/carWash';
import { formatDate } from '@/utils/date';

interface DisplayDetail {
  sizeLabel?: string;
  serviceContents?: string;
  fee?: number;
  amount?: string;
  datetime1?: string;
  datetime2?: string;
  datetime3?: string;
  name?: string;
  nameKana?: string;
  phoneNo?: string;
  carLabel?: string;
  makerName?: string;
  carName?: string;
  carColor?: string;
  registryNo?: string;
  carVin?: string;
  address?: string;
  workspaceMemo?: string;
  workspaceType?: string;
  parkingType?: string;
  marginType?: string;
  securityType?: string;
  scratchedType?: string;
  consultPhone?: string;
  modelCode?: string;
}

export const useCarWashHandStep4 = (
  step1Data: Step1FormData,
  step2Data: Step2FormData,
  step3Data: Step3FormData,
  courseItem: CourseItem | undefined,
  optionItem: OptionItem[],
) => {
  const carsInfo = useStore(store, 'carsInfo') as Required<Car>[];
  const prepaid = useStore('getFirstAdvancePaymentsInfo') as
    | Required<Prepaid>
    | undefined;
  const [data, setData] = useState<CreateCarWashParams>();
  const [display, setDisplay] = useState<DisplayDetail>({});
  useEffect(() => {
    if (!courseItem || !step1Data || !step2Data || !step3Data) return;
    let car: CreateCarWashParams['car'] | undefined;
    const {
      car: {
        openSizeSelect = false,
        size = carSizes.UNKNOWN,
        unregisteredCar: {
          useUserCar,
          carColor = '',
          carVin = '',
          classNo = '',
          makerName = '',
          carName = '',
          registryNo = '',
          transportName = '',
          hiragana = '',
          modelCode = '',
        },
        tStockCarId = 0,
        carwashItemCode = 0,
        optionItemCodes = [],
      },
    } = step1Data;
    const {
      preferredDatetimes: { datetime1 = '', datetime2 = '', datetime3 = '' },
      reservationHolder: {
        familyName = '',
        familyNameKana = '',
        firstName = '',
        firstNameKana = '',
        tel1 = '',
        tel2 = '',
        tel3 = '',
      },
      workspace: {
        zipCode = '',
        prefectures = '',
        municipalities = '',
        street = '',
        workspaceMemo = '',
        workspaceType = 0,
        parkingType = 0,
      },
    } = step2Data;
    const {
      workspace: {
        marginType = 1,
        securitySystem = securitySystems.UNKNOWN,
        scratchedCheck = scratchedChecks.AVAILABLE,
      },
    } = step3Data;

    const setCarData = async () => {
      let carSize = size;
      let carItem: Required<Car> | undefined | null = null;
      if (useUserCar) {
        car = {
          t_stock_car_id: tStockCarId,
        };
        carItem = carsInfo?.find((c) => c.t_stock_car_id === tStockCarId);
        if (carItem) {
          const predict = await calculateCarSize(carItem);
          if (predict !== carSizes.UNKNOWN) {
            carSize = predict;
          }
        }
      } else {
        car = {
          unregistered_car: {
            car_color: carColor,
            car_name: carName,
            car_vin: carVin,
            class_no: classNo,
            maker_name: makerName,
            registry_no: registryNo,
            transport_name: transportName,
            hiragana: hiragana,
            model_code: modelCode,
          },
        };
      }
      const zip = zipCode.split('-');
      const workspaceTOption = workspaceTypeOption.find(
        (o) => o.value === workspaceType,
      );
      const parkingTOption = parkingTypeOption.find(
        (o) => o.value === parkingType,
      );
      const marginTypeSelected = marginTypeOption.find(
        (o) => o.value === marginType,
      );
      const securityCheckSelected = securitySystemOption.find(
        (o) => o.value === securitySystem,
      );
      const scratchedCheckSelected = scratchedCheckOption.find(
        (o) => o.value === scratchedCheck,
      );

      const sizeLabel =
        carSizesOptions.find((s) => s.value === carSize)?.label || '';
      const priceAmount =
        ((!!courseItem?.discount_info?.length
          ? courseItem?.discount_info[0].discounted_price
          : courseItem?.price) || 0) +
        optionItem.reduce((current, item) => {
          const price = !!item?.discount_info?.length
            ? item.discount_info[0].discounted_price
            : item?.price;
          return current + price;
        }, 0);
      const coursePrice =
        (!!courseItem?.discount_info?.length
          ? courseItem.discount_info[0].discounted_price
          : courseItem?.price) || 0;
      const optionPrice = optionItem
        .filter((c) => optionItemCodes.includes(c.code))
        .reduce((current, item) => {
          const price = !!item?.discount_info?.length
            ? item.discount_info[0].discounted_price
            : item?.price;
          return current + price;
        }, 0);
      setDisplay({
        sizeLabel: openSizeSelect ? sizeLabel : '',
        serviceContents: `${courseItem?.name || ''}${
          optionItem.length
            ? `、${optionItem.map((i) => i.name).join('、')}`
            : ''
        }`,
        fee: coursePrice + optionPrice,
        amount: priceAmount.toLocaleString(),
        datetime1: `${formatDate(
          parse(datetime1, 'yyyy-MM-dd HH:mm:ss', new Date()),
        )}～`,
        datetime2: `${formatDate(
          parse(datetime2, 'yyyy-MM-dd HH:mm:ss', new Date()),
        )}～`,
        datetime3: `${formatDate(
          parse(datetime3, 'yyyy-MM-dd HH:mm:ss', new Date()),
        )}～`,
        name: `${familyName} ${firstName}`,
        nameKana: `${familyNameKana} ${firstNameKana}`,
        phoneNo: `${tel1}-${tel2}-${tel3}`,
        carLabel: `${carItem ? carItem.transport_name : transportName}${
          carItem ? carItem.class_no : classNo
        }${carItem ? carItem.hiragana : hiragana}${
          carItem ? carItem.registry_no : registryNo
        }`,
        makerName: carItem ? carItem.maker_nm : makerName,
        carName: carItem ? carItem.car_nm : carName,
        carColor: carItem ? carItem.color_nm : carColor,
        registryNo: carItem ? carItem.registry_no : registryNo,
        carVin: carItem ? carItem.vin : carVin,
        address: `〒${zipCode}\n${prefectures}${municipalities}${street}`,
        workspaceMemo,
        workspaceType: workspaceTOption?.label || '',
        parkingType: parkingTOption?.label || '',
        marginType: marginTypeSelected?.label || '',
        securityType: securityCheckSelected?.label || '',
        scratchedType: scratchedCheckSelected?.label || '',
        consultPhone: '050-2018-6478',
        modelCode: carItem ? carItem.katasiki : modelCode,
      });

      setData({
        m_customer_id: store.state.authInfo.m_customer_id,
        m_affiliate_id: store.state.customerInfo.m_affiliate_id,
        m_prepaid_id: store.state.advancePaymentsInfo[0].id,
        service_type: 'carwash',
        car_size: carSize,
        carwash_item_code: carwashItemCode,
        option_item_codes: optionItemCodes.length ? optionItemCodes : null,
        proc_kbn: !!prepaid?.fixed_deposit_flg
          ? processingCategory.REGULAR_SAVING
          : processingCategory.BASIC_SAVING,
        preferred_datetimes: {
          datetime1,
          datetime2,
          datetime3,
        },
        reservation_holder: {
          family_name: familyName,
          first_name: firstName,
          family_name_kana: familyNameKana,
          first_name_kana: firstNameKana,
          phone_no: `${tel1}-${tel2}-${tel3}`,
        },
        car,
        workspace: {
          zip_code1: zip[0],
          zip_code2: zip[1],
          address1: prefectures,
          address2: municipalities,
          address3: street,
          workspace_memo: workspaceMemo,
          workspace_type: workspaceType,
          parking_type: parkingType,
          margin_type: marginType,
          security_system: securitySystem,
          scratched_check: scratchedCheck,
        },
      });
    };
    setCarData();
  }, [carsInfo, courseItem, optionItem, step1Data, step2Data, step3Data]);

  return {
    display,
    data,
  };
};
