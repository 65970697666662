import { f7, List, ListItem } from 'framework7-react';
import { FC, useMemo } from 'react';
import style from './DrivingScoreListStyle.module.scss';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useDriveMonthlyScore } from '@/hooks/api/driveRecord/useDriveMonthlyScore';

type DrivingScoreListProps = {};

export const DrivingScoreList: FC<DrivingScoreListProps> = () => {
  const { authInfo, linkDriveOwnerInfo } = store.state;
  const mCustomerId = authInfo.m_customer_id;
  const ownerId = linkDriveOwnerInfo.owner_id;
  const vehicleId = linkDriveOwnerInfo.vehicle_id;
  const params = useMemo(
    () => ({
      owner_id: ownerId,
      vehicle_id: vehicleId,
      m_customer_id: mCustomerId,
    }),
    [mCustomerId, ownerId, vehicleId],
  );
  const { data } = useDriveMonthlyScore(params);

  const handleClickCheckDrivingScore = (monthlyReport: any) => {
    f7.view.main.router.navigate(paths.drivingScoreTotal, {
      props: { monthlyReport },
    });
  };
  return (
    <PageTemplate
      pageName="DrivingScoreList"
      title="安全運転スコア一覧"
      showNavBack
    >
      <h3 className="u-margin-top-0 u-margin-bottom-md">安全運転スコア一覧</h3>
      <div className={style['container']}>
        {data?.length ? (
          <List menuList mediaList className={style['list']}>
            {data?.map(({ monthly_report }, idx) => (
              <ListItem
                key={`${monthly_report?.diagnosis_report_id}-${idx}`}
                onClick={() => handleClickCheckDrivingScore(monthly_report)}
              >
                <div className="display-flex-sb">
                  <div className={style['content']}>
                    <div className={style['score']}>
                      <div>
                        {monthly_report?.total_score ?? '-'}
                        <span className={style['score-unit']}>点</span>
                      </div>
                    </div>
                    <div className={style['time']}>
                      <div className={style['title']}>期間</div>
                      <div>
                        {`${monthly_report?.total_from_date ?? '-'}~${
                          monthly_report?.total_to_date ?? '-'
                        }`}
                      </div>
                    </div>
                  </div>
                  <div className={style['icon']}>
                    <i className={`icon-right u-font-size-xxl`} />
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <div className="no-data u-text-center u-font-size-xxl">
            累計スコアはありません。
          </div>
        )}
      </div>
    </PageTemplate>
  );
};
