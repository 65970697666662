import { Block, Link, Page, Popup } from 'framework7-react';
import { FC } from 'react';
import './ResetPasswordCompleteStyle.scss';

interface ResetPasswordCompleteProps {
  mailAddress: string;
}
export const ResetPasswordComplete: FC<ResetPasswordCompleteProps> = ({
  mailAddress,
}) => {
  return (
    <Popup>
      <Page name="ResetPasswordComplete">
        <div className="reset-password-complete-top">
          <Link animate popupClose>
            <i className="icon-close" style={{ color: '#232323' }} />
          </Link>
          <div className="u-text-center u-font-bold" style={{ height: '5rem' }}>
            パスワード再設定メールを
            <br />
            送信しました
          </div>
        </div>

        <Block className="reset-password-complete-block">
          <h3>
            <span>{mailAddress}</span>
            <br />
            <span>に確認メールを送信しました</span>
          </h3>
          <p>
            23時間以内に、メールに記載されたURLを開いて
            <br />
            パスワードを再設定してください
          </p>
        </Block>
      </Page>
    </Popup>
  );
};
