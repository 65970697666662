import { yupResolver } from '@hookform/resolvers/yup';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import style from './EditPhoneStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { RhfInput } from '@/components/uiParts/ReactHookForm/RhfInput/RhfInput';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { mobileAuthSelectedProcess } from '@/consts/auth';
import { loginType as LOGIN_TYPE } from '@/consts/login';
import { AffiliateId } from '@/consts/shop';
import { useMobileAuth } from '@/hooks/api/auth/useMobileAuth';
import { MobileAuthApiParams } from '@/types/api/authApi';
import { LoginType } from '@/types/login';
import { addAffiliateIdToRequestParams } from '@/utils/shop';

interface FormInput {
  tel1: string;
  tel2: string;
  tel3: string;
}

interface EditPhoneProps {
  f7router: Router.Router;
  affiliateId: AffiliateId;
  loginType: LoginType;
}

export const EditPhone: FC<EditPhoneProps> = ({
  f7router,
  affiliateId,
  loginType,
}) => {
  const { authenticateMobile } = useMobileAuth(f7router);

  const phoneNumberSchema = yup.object().shape({
    tel1: yup
      .string()
      .required()
      .matches(/^0[789]0$/),
    tel2: yup
      .string()
      .required()
      .matches(/^[0-9]{4}$/),
    tel3: yup
      .string()
      .required()
      .matches(/^[0-9]{4}$/),
  });

  // TODO: defaultValueを現在の電話番号に変更する
  const formMethods = useForm<FormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      tel1: '',
      tel2: '',
      tel3: '',
    },
    resolver: yupResolver(phoneNumberSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;
  const onSubmit: SubmitHandler<FormInput> = ({ tel1, tel2, tel3 }) => {
    // 当該携帯電話番号がデータベース上に存在するかのチェックを行う（mobile-auth）
    const customerInfo = store.state.customerInfo;
    const phoneNumberBefore = customerInfo.mobile_flg1
      ? customerInfo.phone_no1
      : customerInfo.phone_no2;
    const phoneNumber = `${tel1}-${tel2}-${tel3}`;
    const params: MobileAuthApiParams = {
      selected_process: mobileAuthSelectedProcess.change,
      phone_no: phoneNumber,
      phone_no_before: phoneNumberBefore,
    };
    addAffiliateIdToRequestParams(affiliateId, params);
    authenticateMobile(params);
  };
  return (
    <PopupPageTemplate pageName={'EditPhone'} title={'携帯電話番号の変更'}>
      <div className={style.container}>
        {loginType === LOGIN_TYPE.TELEPHONE && (
          <IconCard
            heading={'ログイン情報として利用されます'}
            iconType="confirm-notification"
            color="danger"
          >
            <p className={style.body}>
              電話番号を変更すると、前の電話番号はログインに使用できなくなります
            </p>
          </IconCard>
        )}
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style['form-check-icon-wrapper']}>
              <div className={style['label-wrap']}>
                <label className={style.label} htmlFor="tel">
                  電話番号
                </label>
                <span className={style['label-tips']}>必須</span>
              </div>
              <div className={style['input-wrap']}>
                <div className={style['tel-input-wrapper']}>
                  <RhfInput
                    className={style['tel-input']}
                    name="tel1"
                    type="string"
                    placeholder="090"
                    id="tel"
                  />
                  <span className={style.span}>-</span>
                  <RhfInput
                    className={style['tel-input']}
                    name="tel2"
                    type="string"
                    placeholder="0000"
                  />
                  <span className={style.span}>-</span>
                  <RhfInput
                    className={style['tel-input']}
                    name="tel3"
                    type="string"
                    placeholder="0000"
                  />
                </div>
                <div>
                  <CheckIcon isValid={isValid} />
                </div>
              </div>
              <span className={style['helper-text']}>
                認証用SMSが送信されます
              </span>
            </div>
            <div className={style['button-wrapper']}>
              <Button type="submit">SMSを送信する</Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </PopupPageTemplate>
  );
};
