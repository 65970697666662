import { FC } from 'react';

export const TireIcon: FC<{ color?: string }> = ({ color = '#AA7F66' }) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 16.5C28 23.1274 22.6274 28.5 16 28.5C9.37258 28.5 4 23.1274 4 16.5C4 9.87258 9.37258 4.5 16 4.5C22.6274 4.5 28 9.87258 28 16.5ZM23.6106 18.9743C24.9759 14.7723 22.6763 10.259 18.4743 8.89371C14.2723 7.52838 9.75903 9.82799 8.39371 14.03C7.02838 18.2321 9.32799 22.7453 13.53 24.1106C17.7321 25.4759 22.2453 23.1763 23.6106 18.9743ZM16.4344 14.7517C16.242 15.085 15.7608 15.085 15.5684 14.7517L13.8363 11.7517C13.6439 11.4183 13.8845 11.0017 14.2694 11.0017H17.7335C18.1184 11.0017 18.3589 11.4183 18.1665 11.7517L16.4344 14.7517ZM17.5314 15.5482C17.2738 15.8342 17.4225 16.2918 17.799 16.3718L21.1874 17.0921C21.5639 17.1721 21.8858 16.8145 21.7669 16.4485L20.6964 13.1539C20.5775 12.7879 20.1068 12.6878 19.8493 12.9739L17.5314 15.5482ZM16.6801 18.1715C16.6398 17.7887 17.0291 17.5059 17.3807 17.6625L20.5453 19.0715C20.8969 19.228 20.9472 19.7065 20.6358 19.9327L17.8333 21.9689C17.5219 22.1951 17.0824 21.9994 17.0422 21.6166L16.6801 18.1715ZM15.3228 18.1719C15.363 17.7892 14.9738 17.5064 14.6221 17.6629L11.4575 19.0719C11.1059 19.2284 11.0556 19.7069 11.367 19.9332L14.1695 21.9693C14.4809 22.1956 14.9204 21.9999 14.9607 21.6171L15.3228 18.1719ZM14.4707 15.5488C14.7283 15.8349 14.5796 16.2925 14.2031 16.3725L10.8147 17.0927C10.4382 17.1727 10.1163 16.8152 10.2352 16.4491L11.3057 13.1546C11.4246 12.7885 11.8952 12.6885 12.1528 12.9745L14.4707 15.5488ZM16 17.5C16.5523 17.5 17 17.0523 17 16.5C17 15.9477 16.5523 15.5 16 15.5C15.4477 15.5 15 15.9477 15 16.5C15 17.0523 15.4477 17.5 16 17.5Z"
        fill={color}
      />
    </svg>
  );
};
