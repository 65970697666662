import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { paths } from '@/config/paths';
import { storeDispatch } from '@/utils/store';

interface Button {
  buttonType: string;
  name: string;
  transitionTo?: string;
  phoneNumber?: string;
  isBig?: boolean;
  href?: string;
  f7router: Router.Router;
  handleChangeBalanceTotal?: (charging: number) => void;
}

export const PrepaidButtons: FC<Button> = ({
  buttonType,
  name,
  transitionTo = null,
  phoneNumber = null,
  isBig = true,
  href = null,
  f7router,
  handleChangeBalanceTotal,
}) => {
  const handleClickBtn = () => {
    if (phoneNumber) {
      window.location.href = 'tel://' + phoneNumber;
      return;
    }
    if (transitionTo == paths.prepaidSetting) {
      storeDispatch('setSettingsPrevPage', 'settingsPrevPage', 'prepaid');
    }
    if (transitionTo) {
      if (transitionTo === paths.chargePrepaid)
        window.localStorage.setItem('source_page', paths.chargePrepaid);
      window.localStorage.removeItem('charge_amount');
      f7.view.main.router.navigate(transitionTo, {
        props: {
          f7router: f7router,
          redirectPage: 'prepaid',
          handleChangeBalanceTotal,
        },
      });
      return;
    }
    if (href) {
      window.open(href);
      return;
    }
  };
  return (
    <div className="prepaid-button" onClick={handleClickBtn}>
      <div className="prepaid-button__in">
        <div className={`prepaid-button__in__icon${isBig ? '-big' : ''}`}>
          <i className={`icon-${buttonType} icon`}>
            {buttonType === 'website' && <span className="path1" />}
          </i>
        </div>
        <div className={`prepaid-button__in__name${isBig ? '-big' : ''}`}>
          {name}
        </div>
      </div>
    </div>
  );
};
