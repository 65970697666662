import { Checkbox as F7Checkbox } from 'framework7-react';
import { CheckboxProps as F7CheckboxProps } from 'framework7-react/components/checkbox';
import { BaseSyntheticEvent, FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import style from './CheckboxStyle.module.css';

interface CheckboxProps extends F7CheckboxProps {
  name: string;
  handleOnChange?: (event: BaseSyntheticEvent, name: string) => void;
}

export const Checkbox: FC<CheckboxProps> = ({
  name,
  handleOnChange,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => {
        const handleChange = (e: any) => {
          handleOnChange && handleOnChange(e, name);

          onChange(e.target.checked);
        };
        return (
          <F7Checkbox
            className={style.checkBox}
            defaultChecked={value}
            onChange={handleChange}
            {...props}
          />
        );
      }}
    />
  );
};
