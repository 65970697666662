import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { BalanceDetails } from './BalanceDetails';
import { PrepaidRectange } from './PrepaidRectange';
import style from './prepaidCardStyle.module.css';
import { paths } from '@/config/paths';
import { MAP_RANK_NAMES, Rank as RankEnum } from '@/consts/rank';
import { themeId as THEME_ID, ThemeId } from '@/consts/shop';
import { useRank } from '@/hooks/api/rank/useRank';
import { switchShopTheme } from '@/utils/shop';
import { storeDispatch } from '@/utils/store';
import { getKeyByValue } from '@/utils/utils';

type PrepaidData = {
  balanceTotal: number;
  deadline: string;
  isPrepaid: boolean;
  f7router: Router.Router;
  themeId: ThemeId;
  prepaidName: string;
};

export const PrepaidCard: FC<PrepaidData> = ({
  balanceTotal,
  deadline,
  isPrepaid,
  f7router,
  themeId,
  prepaidName,
}) => {
  const { rankTheme, userRank } = useRank();

  const shopThemeObj = switchShopTheme(themeId);
  const shopName =
    getKeyByValue(THEME_ID, themeId)?.toLocaleLowerCase() ?? 'default';
  const prepaidColor = shopThemeObj.prepaidColor;
  const prepaidBackground = {
    '--main': `${prepaidColor.main}`,
    '--webkit-background': `-webkit-linear-gradient(to bottom right, ${prepaidColor.leftTop}, ${prepaidColor.rightBottom})`,
    '--background': `linear-gradient(to bottom right, ${prepaidColor.leftTop}, ${prepaidColor.rightBottom})`,
  } as React.CSSProperties;

  const prepaidFlexBackground = {
    '--background': '#0256BA',
  } as React.CSSProperties;

  const prepaidRoyalBackground = {
    '--background': '#093880',
  } as React.CSSProperties;

  const prepaidCarchsBackground = {
    '--background': '#D4261E',
  } as React.CSSProperties;

  const prepaidProudBackground = {
    '--background': '#E60039',
  } as React.CSSProperties;

  const prepaidTsuchiyaBackground = {
    '--background': '#9EBE42',
  } as React.CSSProperties;
  const prepaidAction1Background = {
    '--background': '#191919',
  } as React.CSSProperties;
  const prepaidRoyalColtdBackground = {
    '--background': '#191919',
  } as React.CSSProperties;
  const prepaidGtnetBackground = {
    '--background': '#D4261E',
  } as React.CSSProperties;
  const prepaidLiberalaBackground = {
    '--background': '#000000',
  } as React.CSSProperties;
  const prepaidKindOutletBackground = {
    '--background': '#F11313',
  } as React.CSSProperties;
  const prepaidYamadaBackground = {
    '--background': '#004EA2',
  } as React.CSSProperties;

  const handleClickLink = (path: string) => {
    storeDispatch('setPaymentTempData', 'paymentTempData', {
      type: 'prepaid',
      openDialogName: `/reservings_start/`,
      isTransition: false,
    });
    f7.view.main.router.navigate(path);
  };

  const getPrepaidBackground = () => {
    if (themeId === THEME_ID.FLEX) {
      return prepaidFlexBackground;
    }
    if (themeId === THEME_ID.CARCHS) {
      return prepaidCarchsBackground;
    }
    if (themeId === THEME_ID.ROYAL) {
      return prepaidRoyalBackground;
    }
    if (themeId === THEME_ID.PROUD) {
      return prepaidProudBackground;
    }
    if (themeId === THEME_ID.TSUCHIYA) {
      return prepaidTsuchiyaBackground;
    }
    if (themeId === THEME_ID.ACTION1) {
      return prepaidAction1Background;
    }
    if (themeId === THEME_ID.YOSHIDA_COLTD) {
      return prepaidRoyalColtdBackground;
    }
    if (themeId === THEME_ID.GTNET) {
      return prepaidGtnetBackground;
    }
    if (themeId === THEME_ID.LIBERALA) {
      return prepaidLiberalaBackground;
    }
    if (themeId === THEME_ID.KIND_OUTLET) {
      return prepaidKindOutletBackground;
    }
    if (themeId === THEME_ID.YAMADA) {
      return prepaidYamadaBackground;
    }
    return prepaidBackground;
  };

  return (
    <div
      className={`${style['container']} ${!isPrepaid && style['no-plan']} ${
        style[shopName]
      }`}
      style={getPrepaidBackground()}
    >
      {themeId === THEME_ID.FLEX && (
        <div className={style['backgroundFlex']}> </div>
      )}
      {themeId === THEME_ID.CARCHS && (
        <div className={style['background-carchs']}> </div>
      )}
      {themeId === THEME_ID.ROYAL && (
        <div className={style['background-royal']}> </div>
      )}
      {themeId === THEME_ID.PROUD && (
        <div className={style['background-proud']}> </div>
      )}
      {themeId === THEME_ID.TSUCHIYA && (
        <div className={style['background-tsuchiya']}> </div>
      )}
      {themeId === THEME_ID.ACTION1 && (
        <div className={style['background-action1']}> </div>
      )}
      {themeId === THEME_ID.YOSHIDA_COLTD && (
        <div className={style['background-yoshida-coltd']}> </div>
      )}
      {themeId === THEME_ID.GTNET && (
        <div className={style['background-gtnet']}> </div>
      )}
      {themeId === THEME_ID.LIBERALA && (
        <div className={style['background-liberala']}> </div>
      )}
      {themeId === THEME_ID.KIND_OUTLET && (
        <div className={style['background-kind-outlet']}> </div>
      )}
      {themeId === THEME_ID.YAMADA && (
        <div className={style['background-yamada']}> </div>
      )}
      <div className={`${style['title']} ${rankTheme && 'display-flex-sb'}`}>
        {prepaidName}
        {rankTheme && (
          <div
            className={style.rank}
            onClick={() =>
              f7router.navigate(paths.rank, {
                props: { redirectPage: paths.prepaid },
              })
            }
          >
            {MAP_RANK_NAMES[userRank as RankEnum]}会員
          </div>
        )}
      </div>
      {isPrepaid ? (
        <div className={style['registered-box']}>
          <div className="display-flex-sb">
            <div className={style['rest']}>残高</div>
            <div className={style['rest-wrapper']}>
              <div className={style['rest-value']}>
                {balanceTotal.toLocaleString()}
              </div>
              <span className={style['rest-unit']}>円</span>
            </div>
          </div>
          <BalanceDetails />
        </div>
      ) : (
        <div className={style['unregister-box']}>
          <div className={style['unregister']}>未登録</div>
          <div
            className={style['register-link']}
            onClick={() => handleClickLink(paths.prepaidSummary)}
          >
            プリペイドカードの登録はこちら
          </div>
        </div>
      )}
      {rankTheme && (
        <div className={style['rectange']} style={getPrepaidBackground()}>
          <PrepaidRectange
            width="100%"
            stop1Color={prepaidColor.leftTop}
            stop2Color={prepaidColor.rightBottom}
          />
        </div>
      )}
    </div>
  );
};
