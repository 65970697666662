import {
  GoogleMap,
  GoogleMapProps,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { FC, useState } from 'react';
import styles from './MapCardStyle.module.css';
import arrivalPointMark from '@/assets/images/menu/map_start_mark.svg';
import { apiKey } from '@/consts/apiKey';
import { GOOGLE_MAP_FALLBACK_LAT_LNG } from '@/consts/googleMap';
import { hyphenToTime } from '@/utils/date';

interface MapCardProps {
  mapData:
    | {
        latitude?: number;
        longitude?: number;
        location?: string;
        time?: string;
        color: string;
        weight: number;
      }
    | undefined;
}

export const MapCard: FC<MapCardProps> = ({ mapData }) => {
  const [center, setCenter] = useState({
    lat: mapData?.latitude ?? GOOGLE_MAP_FALLBACK_LAT_LNG.lat,
    lng: mapData?.longitude ?? GOOGLE_MAP_FALLBACK_LAT_LNG.lng,
  });
  const mapOptions: GoogleMapProps['options'] = {
    disableDefaultUI: true,
  };

  const mapStyle = {
    width: '100%',
    height: '100%',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey.GOOGLE_MAPS_API_KEY,
  });

  return (
    <div>
      {isLoaded && (
        <div className={styles['map-card']}>
          <div className={styles['map-box']} style={{ width: '28%' }}>
            <GoogleMap
              mapContainerStyle={mapStyle}
              center={center}
              options={mapOptions}
              zoom={15}
            >
              <Marker position={center} icon={arrivalPointMark} />
            </GoogleMap>
          </div>
          <div className={styles['map-data']} style={{ width: '72%' }}>
            <p className="u-font-bold u-margin-bottom-xs">最後の駐車記録</p>
            <p>{mapData?.location}</p>
            <p>{hyphenToTime(mapData?.time)}</p>
            <p>タップしてマップアプリで開く</p>
          </div>
        </div>
      )}
    </div>
  );
};
