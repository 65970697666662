import React, { FC } from 'react';
import { Label } from '../Label/Label';
import { RhfInput } from '../ReactHookForm/RhfInput/RhfInput';

interface RhfTextInputProps {
  name: string;
  label: string;
  required?: boolean;
  placeholder: string;
  annotation?: string;
  error?: string;
  val?: string;
  index?: number;
}

export const RhfTextInput: FC<RhfTextInputProps> = ({
  name,
  label = null,
  required = false,
  annotation = null,
  error = null,
  placeholder = '',
}) => {
  return (
    <li>
      <div
        className={`item-content item-input item-input-outline 
          ${error && 'item-input-invalid item-input-with-error-message'}
          ${annotation && 'item-input-with-info'}
          `}
      >
        <div className="item-inner">
          <div className="item-title item-label">
            {label}
            <Label required={required} />
          </div>
          <div className="item-input-wrap">
            <RhfInput type="text" placeholder={placeholder} name={name} />

            {error && <div className="item-input-error-message">{error}</div>}

            {annotation && <div className="item-input-info">{annotation}</div>}
          </div>
        </div>
      </div>
    </li>
  );
};
