import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchWarrantyCancelReasonApi } from '@/api/carApi';
import { CancelReasonResponse } from '@/types/api/warrantyApi';

export const useCancelReason = () => {
  const [data, setData] = useState<CancelReasonResponse['data']>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        const res = await fetchWarrantyCancelReasonApi();
        setData(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };
    fn();
  }, []);

  return { data, error };
};
