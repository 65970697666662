import { f7 } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import { SuccessPopup } from '../Partials/SuccessPopup/SuccessPopup';
import { RegisterBankAccountPopUp } from '../RegisterBankAccountPopUp/RegisterBankAccountPopUp';
import { TradeGuaranteeActions } from '../TradeGuaranteeActions/TradeGuaranteeActions';
import { TradeGuaranteeDetail } from '../TradeGuaranteeDetail/TradeGuaranteeDetail';
import { TradeGuaranteeDocuments } from '../TradeGuaranteeDocuments/TradeGuaranteeDocuments';
import { TradeGuaranteeExamResult } from '../TradeGuaranteeExamResult/TradeGuaranteeExamResult';
import { TradeGuaranteeRefundAccount } from '../TradeGuaranteeRefundAccount/TradeGuaranteeRefundAccount';
import { TradeGuaranteeRenderStatus } from '../TradeGuaranteeRenderStatus/TradeGuaranteeRenderStatus';
// import { TradeGuaranteeTransactionResults } from '../TradeGuaranteeTransactionResults/TradeGuaranteeTransactionResults';
import style from './TradeGuaranteeApplicationStyle.module.css';
import { postDealWarrantyUpdateApi } from '@/api/warrantyApi';
import { Button } from '@/components/uiParts/Button';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  PROCESS_KB,
  SCREENING_RESULT_TYPE,
  STATUS_TRADE_GUARANTEE,
} from '@/consts/warranty';
import { useGetDealWarrantyInfo } from '@/hooks/api/warranty/useGetDealWarrantyInfo';
import { usePostDealWarrantyRefundAccount } from '@/hooks/api/warranty/usePostDealWarrantyRefundAccount';
import { GetDealWarrantyInfoApiResponse } from '@/types/api/warrantyApi';

interface TradeGuaranteeApplicationProps {
  isFromDeepLink?: boolean;
  initialData?: GetDealWarrantyInfoApiResponse['data'];
}

export const TradeGuaranteeApplication: FC<TradeGuaranteeApplicationProps> = ({
  isFromDeepLink = false,
  initialData,
}) => {
  const m_customer_id = store.state.authInfo.m_customer_id;
  const t_stock_car_id = store.state.carItemInfo.t_stock_car_id;
  const warranty_no = store.state.currentWarranty?.warranty_no as string;
  const branch_no = store.state.currentWarranty?.branch_no as number;

  const [openRegisterBankAccount, setOpenRegisterBankAccount] = useState(false);
  const [openPopupSuccess, setOpenPopupSuccess] = useState(false);
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false);
  const [openPopupConfirmReturnCar, setOpenPopupConfirmReturnCar] =
    useState(false);

  const { data, refetch } = useGetDealWarrantyInfo({
    m_customer_id,
    t_stock_car_id,
    warranty_no,
    branch_no,
    initialData,
  });

  const handleRefundAccountSuccess = () => {
    setOpenPopupSuccess(true);
    refetch();
  };

  const { dealWarrantyRefundAccount } = usePostDealWarrantyRefundAccount(
    handleRefundAccountSuccess,
  );

  const onClickConfirmReview = () => {
    f7.preloader.show();
    postDealWarrantyUpdateApi({
      t_repair_recept_id: data?.repair_recept_id || 0,
      m_customer_id: store.state.authInfo.m_customer_id,
      process_kb: PROCESS_KB.CONTINUE_USE_AFTER_REVIEW,
      issues: [],
      documents: [],
    })
      .then(() => {
        setOpenPopupConfirm(false);
        return refetch();
      })
      .catch(() => {
        f7.dialog.alert('エラーが発生しました');
      })
      .finally(() => f7.preloader.hide());
  };

  const onClickReturnVehicle = () => {
    f7.preloader.show();
    postDealWarrantyUpdateApi({
      t_repair_recept_id: data?.repair_recept_id || 0,
      m_customer_id: store.state.authInfo.m_customer_id,
      process_kb: PROCESS_KB.REQUEST_TO_RETURN,
      issues: [],
      documents: [],
    })
      .then(() => {
        setOpenPopupConfirmReturnCar(false);
        return refetch();
      })
      .catch(() => {
        f7.dialog.alert('エラーが発生しました');
      })
      .finally(() => f7.preloader.hide());
  };

  const onClickWithdraw = () => {
    f7.preloader.show();
    postDealWarrantyUpdateApi({
      t_repair_recept_id: data?.repair_recept_id || 0,
      m_customer_id: store.state.authInfo.m_customer_id,
      process_kb: PROCESS_KB.WITHDRAW,
      issues: [],
      documents: [],
    })
      .then(() => {
        f7.view.main.router.back();
      })
      .catch(() => {
        f7.dialog.alert('エラーが発生しました');
      })
      .finally(() => f7.preloader.hide());
  };

  const onClickPartsReceived = () => {
    f7.preloader.show();
    postDealWarrantyUpdateApi({
      t_repair_recept_id: data?.repair_recept_id || 0,
      m_customer_id: store.state.authInfo.m_customer_id,
      process_kb: PROCESS_KB.CONFIRM_DELIVERY,
      issues: [],
      documents: [],
    })
      .then(() => {
        return refetch();
      })
      .catch(() => {
        f7.dialog.alert('エラーが発生しました');
      })
      .finally(() => f7.preloader.hide());
  };

  useEffect(() => {
    if (!data) return;
    if (!data.success) {
      f7.dialog.alert(data.error_message || 'エラーが発生しました', () => {
        f7.view.main.router.back();
      });
    }
    if (data.status === STATUS_TRADE_GUARANTEE.WITHDRAWN_APPLICATION) {
      f7.view.main.router.back();
    }
  }, [data]);

  return (
    <PageTemplateV2
      pageName="TradeGuaranteeApplication"
      title="取引保証詳細"
      showNavBack={true}
      handleClickBack={() => {
        if (isFromDeepLink)
          return f7.view.main.router.navigate(paths.home, {
            transition: 'push-reverse',
          });
        f7.view.main.router.back();
      }}
    >
      <div className={style['trade-guarantee-application-wrapper']}>
        <TradeGuaranteeRenderStatus
          data={data}
          setOpenRegisterBankAccount={setOpenRegisterBankAccount}
        />
        <TradeGuaranteeActions
          data={data}
          onClickConfirmReview={onClickConfirmReview}
          onClickPartsReceived={onClickPartsReceived}
          openPopupConfirm={openPopupConfirm}
          setOpenPopupConfirm={setOpenPopupConfirm}
        />
        {data?.status === STATUS_TRADE_GUARANTEE.REFUNDS_PROCESSED && (
          <TradeGuaranteeRefundAccount
            refundAccount={data?.refund_account}
            hasRefundAccount={data?.has_refund_account}
          />
        )}
        {[
          STATUS_TRADE_GUARANTEE.INCOMPLETE_DOCUMENT,
          STATUS_TRADE_GUARANTEE.WITHDRAWN_APPLICATION,
          STATUS_TRADE_GUARANTEE.REVIEW_COMPLETED,
          STATUS_TRADE_GUARANTEE.CONTINUE_USE_AFTER_REVIEW,
        ].includes(data?.status || -1) && (
          <TradeGuaranteeExamResult
            data={data}
            onClickReturnVehicle={onClickReturnVehicle}
            openPopupConfirm={openPopupConfirmReturnCar}
            setOpenPopupConfirm={setOpenPopupConfirmReturnCar}
          />
        )}

        {data?.status === STATUS_TRADE_GUARANTEE.REVIEW_COMPLETED &&
          data?.deal_warranty_screening_result_type !==
            SCREENING_RESULT_TYPE.OUT_OF_SCOPE && (
            <div className={style['withdrawal-group']}>
              <h3 className={style['withdrawal-title']}>申請の取り下げ</h3>
              <div className={style['withdrawal-button-group']}>
                <p>
                  申請の取り下げを行う場合は、下記ボタンよりお願いいたします。
                </p>
                <Button onClick={onClickWithdraw} color="red">
                  申請を取り下げる
                </Button>
              </div>
            </div>
          )}

        <TradeGuaranteeDetail data={data} />
        {/* {[
          STATUS_TRADE_GUARANTEE.NOT_PASS,
          STATUS_TRADE_GUARANTEE.REVIEW_COMPLETED,
        ].includes(data.status) && <TradeGuaranteeTransactionResults />} */}
        <TradeGuaranteeDocuments data={data} />
        <RegisterBankAccountPopUp
          repairReceptId={data?.repair_recept_id}
          opened={openRegisterBankAccount}
          handleClose={() => setOpenRegisterBankAccount(false)}
          dealWarrantyRefundAccount={dealWarrantyRefundAccount}
        />
        <SuccessPopup
          opened={openPopupSuccess}
          handleClose={() => setOpenPopupSuccess(false)}
        />
      </div>
    </PageTemplateV2>
  );
};
