import { Link, NavLeft, NavRight, NavTitle } from 'framework7-react';
import { NavTitleProps } from 'framework7-react/components/nav-title';
import { FC } from 'react';
import styles from './CommonpageTitleStyle.module.css';

interface CommonPageTitleProps extends NavTitleProps {
  navTitle: string;
  leftIcon?: string;
  helpIcon?: boolean;
  category?: string;
  handleHelpClick?: () => void;
}

export const CommonpageTitle: FC<CommonPageTitleProps> = ({
  navTitle,
  leftIcon = '',
  helpIcon = false,
  category = '',
  handleHelpClick,
}) => {
  return (
    <>
      <NavLeft>
        <Link slot="left" back animate popupClose className={styles.linkColor}>
          <i className={`icon-${leftIcon}`} />
        </Link>
      </NavLeft>
      <NavTitle className={styles.title}> {navTitle} </NavTitle>
      {helpIcon && (
        <NavRight>
          <Link slot="right" animate onClick={handleHelpClick}>
            <i className="icon-help" />
          </Link>
        </NavRight>
      )}
    </>
  );
};
