import {
  Block,
  Col,
  Link,
  List,
  ListInput,
  Page,
  Popup,
  Row,
} from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './ResetPasswordStyle.module.scss';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { TextLink } from '@/components/uiParts/Button/TextLink/TextLink';
import { paths } from '@/config/paths';
import { store } from '@/config/store';

interface ResetPasswordProps {
  id: string;
  from?: string;
  f7router: Router.Router;
  handleChangeId: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickButton: () => void;
}
export const ResetPassword: FC<ResetPasswordProps> = ({
  id,
  from = '',
  f7router,
  handleChangeId,
  handleClickButton,
}) => {
  return (
    <Popup>
      <Page name="ResetPassword">
        <div className={style['top']}>
          <Link animate popupClose>
            <i className="icon-close" style={{ color: '#232323' }} />
          </Link>
          <div
            className="u-text-center u-font-bold"
            style={{ height: '5rem', lineHeight: '5rem' }}
          >
            パスワードを忘れた方
          </div>
        </div>

        <Block className={`${style['block']}`}>
          <div
            className={`${style['lhStyle']} u-font-bold u-text-center u-margin-bottom-lg`}
          >
            <div>ログインに利用している</div>
            <div>
              {from === 'mail' ? 'メールアドレス' : '連絡先'}を入力してください
            </div>
            <p>
              パスワードリセット用の
              <br />
              リンクを送信します
            </p>
          </div>

          <List noHairlines>
            <ListInput
              outline
              required
              clearButton
              label={
                from === 'mail'
                  ? 'メールアドレス'
                  : 'ID（メールアドレス・電話番号）'
              }
              type="text"
              placeholder={from === 'mail' ? 'abc@example.com' : '09000000000'}
              defaultValue={store.state.loginInfo.email}
              onChange={handleChangeId}
            />
          </List>

          <Row className="u-margin-top-xs u-margin-bottom-lg">
            <Col />
            <Col>
              <Button
                text="送信する"
                animate
                handleClickAction={handleClickButton}
              />
            </Col>
            <Col />
          </Row>
        </Block>
        {from === 'mail' && (
          <div className="u-text-center">
            <TextLink path={paths.accountForgotten} f7router={f7router}>
              登録したメールアドレスがわからない方
            </TextLink>
          </div>
        )}
        {from === 'id' && (
          <div className="u-text-center">
            <TextLink path={paths.passwordForgotten} f7router={f7router}>
              該当の連絡先でメールやSMSを受け取れない方
            </TextLink>
          </div>
        )}
      </Page>
    </Popup>
  );
};
