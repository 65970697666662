import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { createAccountByEmailSchema } from '../createAccountByEmailSchema';
import style from './createAccountByEmailStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { RhfInput } from '@/components/uiParts/ReactHookForm/RhfInput/RhfInput';
import { LoginPageTemplate } from '@/components/uiParts/Template/LoginPageTemplate/LoginPageTemplate';

interface CreateAccountByEmailProps {
  onSubmit: SubmitHandler<FormInput>;
}

export interface FormInput {
  email: string;
}

export const CreateAccountByEmail: FC<CreateAccountByEmailProps> = ({
  onSubmit,
}) => {
  const formMethods = useForm<FormInput>({
    mode: 'onChange',
    defaultValues: { email: '' },
    resolver: yupResolver(createAccountByEmailSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  return (
    <LoginPageTemplate
      pageName="CreateAccountByEmail"
      title="認証コードの入力"
      showNavBack
    >
      <div className={style['container']}>
        <h2>
          SMSを受け取れない方は、<span>メールアドレスでアカウントを</span>
          <span>作成いただけます</span>
        </h2>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style['email-input-label-wrapper']}>
              <span> メールアドレスを入力してください</span>
              <div className={style['form-check-icon-wrapper']}>
                <RhfInput
                  className={style['input']}
                  name="email"
                  type="email"
                  placeholder="メールアドレス"
                />
                <div>
                  <CheckIcon isValid={isValid} />
                </div>
              </div>
            </div>
            <div className={style['button-container']}>
              <div className={style['button-wrapper']}>
                <Button type="submit" disabled={!isValid}>
                  次へ
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </LoginPageTemplate>
  );
};
