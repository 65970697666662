import { f7, Page, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Button } from '@/components/uiParts/Button';
import { AuthInfoState, State } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useCustomerInfo } from '@/hooks/api/customer/useCustomerInfo';
import { useRegisterPrepaidInfo } from '@/hooks/api/prepaid/useRegisterPrepaidInfo';
import { useToast } from '@/hooks/useToast';
import './ReservingsStep4Style.scss';
import { Houseprepaid } from '@/types/api/prepaidApi';
import { PaymentTypeId, PrepaidData } from '@/types/payment';
import { trigger } from '@/utils/eventHelper';
import { isBankOrPayEasy, switchPaymentIcon } from '@/utils/payment';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

export interface ReservingsStep4Props {
  changeStep: (step: number) => void;
  handleCancel?: () => void;
  f7router: Router.Router;
  setPrepaidData?: Dispatch<SetStateAction<PrepaidData>>;
  selectedPlanInfo?: Houseprepaid;
}

export const ReservingsStep4: FC<ReservingsStep4Props> = ({
  changeStep,
  handleCancel,
  setPrepaidData,
  selectedPlanInfo,
}) => {
  const reserveAmount = useStore(store, 'getReserveAmount') as number; //store.state.reserveAmount;
  const paymentInfo = useStore(store, 'getPaymentInfo') as State['paymentInfo']; // store.state.paymentInfo;
  const { m_customer_id } = useStore(store, 'authInfo') as AuthInfoState;

  const { setCustomerInfo } = useCustomerInfo({
    m_customer_id,
  });

  //ToDo: Prepaid開始のテストアカウントが出来次第実装
  const { registerPrepaid, data, setData } = useRegisterPrepaidInfo();

  const handleClickStep1 = async () => {
    await storeDispatch('setReserveAmount', 'reserveAmount', reserveAmount);
    changeStep(0);
  };

  const handleClickStep2 = () => {
    // eslint-disable-next-line no-magic-numbers
    changeStep(1);
  };

  const { openToast } = useToast();

  const handleClickReservings = () => {
    const params = {
      m_customer_id,
      m_prepaid_plan_id: selectedPlanInfo?.m_prepaid_plan_id as number,
      monthry_charge: reserveAmount,
      m_payment_type_id: paymentInfo.mPaymentTypeId,
      m_payment_method_id: paymentInfo.mPaymentMethodId,
    };
    registerPrepaid(params, setPrepaidData);
  };

  useEffect(() => {
    const closingMs = 1500;
    switch (data?.data.success) {
      case true:
        openToast(
          'プリペイドの登録が完了しました',
          'toast-success',
          closingMs,
          true,
          'top',
        );
        setData(undefined);
        setCustomerInfo();
        trigger('forceRefresh', { message: 'Hey pick me up' });
        const redirectPage = localStorage.getItem(
          'redirectCarWashAfterPayment',
        );
        if (redirectPage) {
          f7.view.main.router.navigate(redirectPage);
        } else {
          f7.view.main.router.navigate(paths.prepaid, {
            reloadAll: true,
          });
        }
        f7CustomBack(['#prepaid-summary']);
        break;
      case false:
        openToast(
          'プリペイドの登録が失敗しました',
          'toast-failed',
          closingMs,
          true,
          'top',
        );
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, openToast]);

  return (
    <Page id="ReservingsStep4" name="ReservingsStep4" pageContent={false}>
      <div className="content">
        <h3 className="heading">申し込み内容を確認してください</h3>
        <div className="content-block">
          <div className="content-block-head">
            <h3 className="content-block-title">積み立て設定金額</h3>
            <Button
              round
              className="block-button"
              color="gray"
              onClick={handleClickStep1}
            >
              修正する
            </Button>
          </div>
          <div className="content-block-row">
            <div className="row-label">積立額</div>
            <div className="row-value">
              {reserveAmount.toLocaleString()} 円/月
            </div>
          </div>
          {!!selectedPlanInfo?.info && (
            <div className="content-block-row">
              <div className="row-label">プラン説明</div>
              <div className="row-value">{selectedPlanInfo?.info}</div>
            </div>
          )}
        </div>

        <div className="content-block">
          <div className="content-block-head">
            <h3 className="content-block-title">決済方法</h3>
            <Button
              round
              className="block-button"
              color="gray"
              onClick={handleClickStep2}
            >
              修正する
            </Button>
          </div>
          <div className="content-block-row payment-selected no-border-bottom">
            <div className="row-label">
              <i
                className={`icon ${switchPaymentIcon(
                  paymentInfo.mPaymentTypeId as PaymentTypeId,
                )}`}
              />
            </div>
            <div className="row-value">
              <p className="account-name">
                {isBankOrPayEasy(paymentInfo.mPaymentTypeId)
                  ? `${paymentInfo.paymentTypeName} (${paymentInfo.bankName})`
                  : paymentInfo.paymentTypeName}
              </p>
              <p className="account-number">
                {isBankOrPayEasy(paymentInfo.mPaymentTypeId)
                  ? paymentInfo.accountNumber || '口座番号情報なし'
                  : paymentInfo.cardNo}
              </p>
            </div>
          </div>
        </div>
        <div className="button-wrap">
          <Button
            className="button-start-pay"
            text="この内容で利用開始する"
            handleClickAction={handleClickReservings}
          />
          <Button
            className="btn-cancel"
            text="申し込みを中止する"
            handleClickAction={handleCancel}
          />
        </div>
      </div>
    </Page>
  );
};
