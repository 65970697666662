import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { replaceConnectorApi } from '@/api/linkDriveApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  ReplaceConnectorApiParams,
  ReplaceConnectorApiResponse,
} from '@/types/api/linkDriveApi';

export const useReplaceConnector = () => {
  const [data, setData] = useState<ReplaceConnectorApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const replaceConnector = async (params: ReplaceConnectorApiParams) => {
    let isSuccess = false;
    try {
      const { data } = await replaceConnectorApi(params);
      setData(data);
      if (data.success) {
        isSuccess = true;
        f7.view.main.router.navigate(paths.linkDriveSetupSteps2, {
          animate: true,
        });
        store.dispatch('setActiveTabId', 1);
      } else {
        // TODO: 呼び出しAPI、及びAPIからのエラーメッセージをSentyに送信する
        f7.dialog.alert(
          'エラーコード：LD0005<br><br>付け替えいただけないデバイスです。お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。',
          'エラー',
        );
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }

    return isSuccess;
  };

  useEffect(() => {}, [data]);

  return {
    data,
    isLoading,
    error,
    replaceConnector,
  };
};
