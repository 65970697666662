import { format } from 'date-fns';
import { chargeType, valueType } from '@/consts/charge';
import { HousePrepaidPaymentHistory } from '@/types/api/customerApi';
import { ChargeType } from '@/types/payment';
import { hyphenDate } from '@/utils/date';

export const filteredInquiryList = (
  prepaidHistories: HousePrepaidPaymentHistory[] | undefined,
  prepaidLatestHistories: HousePrepaidPaymentHistory | null,
  type: string,
) => {
  if (!prepaidHistories || !prepaidLatestHistories) {
    return;
  }

  let amount = 0;
  let plusAndMinus = '';
  let plusAndMinusSign = '';
  let limit = '';
  let balance = 0;
  if (prepaidHistories.length > 0) {
    switch (type) {
      case 'basic':
        limit =
          hyphenDate(prepaidLatestHistories.expire_date_basic ?? '') ?? '';
        balance = prepaidLatestHistories.balance_basic ?? 0;
        break;
      case 'bonus':
        limit =
          hyphenDate(prepaidLatestHistories.expire_date_bonus ?? '') ?? '';
        balance = prepaidLatestHistories.balance_bonus ?? 0;
        break;
      case 'coupon':
        limit =
          hyphenDate(prepaidLatestHistories.expire_date_coupon ?? '') ?? '';
        balance = prepaidLatestHistories.balance_coupon ?? 0;
        break;
      default:
        break;
    }

    if (balance > 0) {
      amount = balance;
    }
    if (balance > 0) {
      plusAndMinus = 'plus';
      plusAndMinusSign = '+ ';
    } else if (balance == 0) {
      plusAndMinus = 'minus';
      plusAndMinusSign = '';
    } else {
      plusAndMinus = 'minus';
      plusAndMinusSign = '- ';
    }
  }

  return {
    limit: limit,
    amount: amount,
    plusAndMinus: plusAndMinus,
    plusAndMinusSign: plusAndMinusSign,
  };
};

export const createPrepaidHistory = (
  prepaidHistories: HousePrepaidPaymentHistory[] | undefined,
) => {
  if (!prepaidHistories) return undefined;
  let historyList = prepaidHistories;
  if (prepaidHistories.length > 0) {
    prepaidHistories.sort(
      (a: HousePrepaidPaymentHistory, b: HousePrepaidPaymentHistory) => {
        return (b.deal_date ?? '') < (a.deal_date ?? '') ? -1 : 1;
      },
    );

    const datePrepaidHistory = separateByDate(prepaidHistories);

    const sortedPrepaidHistories: HousePrepaidPaymentHistory[] = (
      [] as HousePrepaidPaymentHistory[]
    ).concat(...datePrepaidHistory);

    const currentMonthDateStr = sortedPrepaidHistories[0]?.deal_date ?? '';
    let currentMonthHeader = format(
      new Date(currentMonthDateStr),
      'yyyy年MM月',
    );
    const updatedSortedPrepaidHistories = sortedPrepaidHistories.map(
      (item: HousePrepaidPaymentHistory, index: number) => {
        let instance = item;
        const instanceMonth = format(
          new Date(item.deal_date ?? ''),
          'yyyy年M月',
        );
        if (currentMonthHeader != instanceMonth || index === 0) {
          currentMonthHeader = instanceMonth;
          instance.oneMonthflag = instanceMonth;
        }

        if (item.value_deposit ?? 0 > 0) {
          instance.amount = item.value_deposit;
          instance.amountSign = '+';
          instance.plusAndMinus = 'plus';
        } else if ((item.value_withdraw ?? 0) < 0) {
          instance.amount = Math.abs(item.value_withdraw ?? 0);
          instance.amountSign = '+';
          instance.plusAndMinus = 'plus';
        } else if ((item.value_withdraw ?? 0) > 0) {
          instance.amount = item.value_withdraw;
          instance.amountSign = '-';
          instance.plusAndMinus = 'minus';
        } else {
          instance.amount = 0;
          instance.amountSign = '';
          instance.plusAndMinus = 'minus';
        }

        instance.iconType = '';
        instance.title = '';
        if (item.deal_service == 'withdraw') {
          instance.iconType = 'pay-tip';
          instance.title = 'お支払い';
          if ((item?.value_withdraw ?? 0) < 0) {
            instance.iconType = 'plus';
            instance.title = '返金';
          }
        }
        if (item.deal_service == 'cancelfee') {
          instance.iconType = 'pay-tip';
          instance.title = 'キャンセル料';
        }
        if (item.deal_service == 'deposit') {
          if (item.value_type === valueType.COUPON) {
            instance.iconType = 'plus';
            instance.title = 'クーポンチャージ';
          } else {
            switch (item.charge_type as ChargeType) {
              case chargeType.MONTHLY:
                instance.iconType = 'stairs';
                instance.title = '自動積立チャージ';
                break;
              case chargeType.SPOT:
                instance.iconType = 'plus';
                instance.title = 'スポットチャージ';
                break;
              default:
                break;
            }
          }
        }

        return instance;
      },
    );

    historyList = updatedSortedPrepaidHistories;
  }
  return historyList;
};

const separateByDate = (arr: HousePrepaidPaymentHistory[]) => {
  if (!arr) return [];
  let dateMap = new Map();
  for (const item of arr) {
    const date = new Date(item.deal_date ?? '');
    const key = date.toDateString();
    if (!dateMap.has(key)) {
      dateMap.set(key, []);
    }
    dateMap.get(key).push(item);
  }

  let result: HousePrepaidPaymentHistory[] = [];
  for (let value of dateMap.values()) {
    value.reverse();
    result.push(value);
  }
  return result;
};
