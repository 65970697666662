import { FC } from 'react';
import { ReactComponent as CheckGrayIcon } from '@/assets/images/login/icon_check_gray.svg';
import { ReactComponent as CheckGreenIcon } from '@/assets/images/login/icon_check_green.svg';

interface CheckIconProps extends React.SVGProps<SVGSVGElement> {
  isValid: boolean;
}

export const CheckIcon: FC<CheckIconProps> = ({ isValid, ...rest }) => {
  return isValid ? <CheckGreenIcon {...rest} /> : <CheckGrayIcon {...rest} />;
};
