import { FC } from 'react';
import { WarrantyTerms } from './WarrantyTerms/WarrantyTerms';
import { store } from '@/config/store';
import { findDefectAppendWarranty } from '@/utils/warranty';

export const WarrantyTermsPage: FC = () => {
  const { warranties } = store.state.carItemInfo;
  const warrantyTerms = findDefectAppendWarranty(warranties[0])?.low_ver || '';
  return <WarrantyTerms warrantyTerms={warrantyTerms} />;
};
