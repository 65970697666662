import { IconProps } from './type';

export const ReviewCarIcon = ({
  width = '56',
  height = '57',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28.5" r="28" fill={color} fillOpacity="0.1" />
      <path
        d="M11.506 31.458C11.7875 29.4874 13.4786 28 15.4692 28H38.5V40H12C11.4477 40 11 39.5523 11 39V38.5534C11 36.1874 11.1714 33.8002 11.506 31.458Z"
        fill="#B1C7FC"
      />
      <path
        d="M16.8225 21.1619C17.2177 19.3176 18.8475 18 20.7337 18H33.5V25H16L16.8225 21.1619Z"
        fill="#B1C7FC"
      />
      <path
        d="M38.5 41.5H11.5C10.3954 41.5 9.5 40.6046 9.5 39.5V38.8034C9.5 36.4374 9.66793 34.0745 10.0025 31.7323L10.1367 30.7929C10.4886 28.3296 12.5982 26.5 15.0865 26.5H27.2053"
        stroke="#323232"
        strokeLinecap="round"
      />
      <path
        d="M37.7502 26.5H14L15.1961 20.5194C15.6635 18.1823 17.7156 16.5 20.099 16.5H33.2502"
        stroke="#323232"
        strokeLinecap="round"
      />
      <path
        d="M19.5 41.5V43.5C19.5 44.6046 18.6046 45.5 17.5 45.5H15.5C14.3954 45.5 13.5 44.6046 13.5 43.5V41.5"
        stroke="#323232"
        strokeLinecap="round"
      />
      <circle cx="16.5" cy="32.5" r="2.5" fill="white" stroke="#323232" />
      <path
        d="M10 23.5C10 22.6716 10.6716 22 11.5 22H13.0604C14.007 22 14.7169 22.866 14.5313 23.7942L14.0901 26H11.5C10.6716 26 10 25.3284 10 24.5V23.5Z"
        stroke="#323232"
      />
      <rect x="19.5" y="36" width="17" height="1" rx="0.5" fill="#323232" />
      <rect x="19.5" y="38" width="17" height="1" rx="0.5" fill="#323232" />
      <path
        d="M21.5 33C21.5 32.1716 22.1716 31.5 23 31.5H33C33.8284 31.5 34.5 32.1716 34.5 33V34.5H21.5V33Z"
        fill="white"
        stroke="#323232"
      />
      <path
        d="M46.3081 18.1154C49.1108 22.8742 47.5251 29.0041 42.7663 31.8069C38.0074 34.6096 31.8776 33.0239 29.0748 28.2651C26.2721 23.5062 27.8578 17.3764 32.6166 14.5736C37.3754 11.7709 43.5053 13.3566 46.3081 18.1154Z"
        fill="#E8EEFC"
      />
      <path
        d="M45.0156 18.8766C47.3979 22.9216 46.05 28.132 42.005 30.5144C37.96 32.8967 32.7497 31.5488 30.3673 27.5038C27.985 23.4588 29.3328 18.2484 33.3778 15.8661C37.4228 13.4838 42.6332 14.8316 45.0156 18.8766ZM43.02 32.2377C48.0168 29.2948 49.6818 22.8584 46.7389 17.8617C43.796 12.8649 37.3596 11.1999 32.3629 14.1428C27.3661 17.0857 25.7011 23.522 28.644 28.5188C31.5869 33.5156 38.0232 35.1806 43.02 32.2377Z"
        fill="#323232"
        stroke="white"
      />
      <path
        d="M41.1523 32.3828L42.8757 31.3678L47.443 39.1228C47.7233 39.5987 47.5647 40.2117 47.0888 40.492C46.6129 40.7722 46 40.6137 45.7197 40.1378L41.1523 32.3828Z"
        fill="#323232"
      />
    </svg>
  );
};
