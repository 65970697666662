import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { checkWarrantyDocument } from '../../WarrantyManagement/WarrantyManagementHelper';
import { PopupHeader, TYPE_POPUP } from './PopupHeader/PopupHeader';
import { WarrantyClaim } from './WarrantyClaim';
import { WarrantyInfo } from './WarrantyInfo';
import { WarrantyNotes } from './WarrantyNotes';
import { ReactComponent as InfoIcon } from '@/assets/images/menu/icon-info.svg';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
interface TradeWarrantyDetailprops {
  f7router: Router.Router;
  hasDeal: boolean;
}
export const TradeWarrantyDetail: FC<TradeWarrantyDetailprops> = ({
  f7router,
  hasDeal,
}) => {
  const { currentWarranty } = store.state;
  const [openedPopup, setOpenedPopup] = useState(false);
  const [openedTermPopup, setOpenedTermPopup] = useState(false);
  const [typePopup, setTypePopup] = useState('');
  const { isWarrantiesMissDocument } = checkWarrantyDocument(currentWarranty);
  const openPopupTradeGuaranteePlan = () => {
    setOpenedPopup(true);
    setTypePopup(TYPE_POPUP.TRADE_GUARANTEE_PLAN);
  };

  const openPopupApplicationProcess = () => {
    setOpenedPopup(true);
    setTypePopup(TYPE_POPUP.APPLICATION_PROCESS);
  };

  const handleClosePopup = () => {
    setOpenedPopup(false);
    setTypePopup('');
  };

  return (
    <div>
      <PopupHeader
        opened={openedPopup}
        handleClose={handleClosePopup}
        type={typePopup}
      />
      {/* プラン詳細 */}
      <WarrantyInfo handleClickIcon={openPopupTradeGuaranteePlan} />
      {/* 各種書類 */}
      {isWarrantiesMissDocument && (
        <div className="warranty-document">
          <h2 className="u-margin-bottom-xs">各種書類</h2>
          <RoundSheet>
            <p className="u-margin-bottom-sm">
              取引保証プラン有効化に必要な情報があります
            </p>
            <IconCard
              path={paths.tradeGuaranteePlan}
              isTransition
              iconType="confirm-notification"
              headingFontSize="14px"
              iconFontSize="32px"
              color="warning"
              heading="必要な書類・画像を登録してください"
            />
          </RoundSheet>
        </div>
      )}
      {/* 保証の申請 */}
      <div className="warranty-document">
        <h2 className="warranty-detail-header">
          保証の申請
          <span onClick={openPopupApplicationProcess}>
            申請の流れ <InfoIcon width={24} height={24} />
          </span>
        </h2>
        <WarrantyClaim
          isDisabled={isWarrantiesMissDocument}
          hasDeal={hasDeal}
        />
      </div>
      {/* 保証に関するヘルプ・ご注意事項 */}
      <WarrantyNotes f7router={f7router} />
    </div>
  );
};
