import { AxiosError } from 'axios';
import { useState } from 'react';
import { searchAddressByZipApi } from '@/api/yahooApi';

interface ActualUseType {
  prefectures: string;
  municipalities: string;
  street: string;
}

interface Args {
  zipCode: string;
  onSuccess?: (
    prefectures: string,
    municipalities: string,
    street: string,
  ) => void;
}

export const useSearchAddress = () => {
  const [data, setData] = useState<ActualUseType>({
    prefectures: '',
    municipalities: '',
    street: '',
  });
  const [error, setError] = useState<AxiosError>();
  const searchAddressByZip = async (args: Args) => {
    try {
      const params = {
        appid: import.meta.env.VITE_YAHOO_APPLICATION_ID,
        query: args.zipCode,
        output: 'json',
        results: 1,
        detail: 'full',
      };
      const { data } = await searchAddressByZipApi(params);
      // TODO: オブジェクト処理を別ファイルで行う
      const addressElement = data.Feature[0].Property.AddressElement;
      let prefectures = '';
      let municipalities = '';
      let street = '';
      addressElement.map((address) => {
        if (address.Level === 'prefecture') prefectures += address.Name;
        if (address.Level === 'city') municipalities += address.Name;
        if (address.Level === 'oaza') street += address.Name;
        if (address.Level === 'aza') street += address.Name;
      });
      args.onSuccess?.(prefectures, municipalities, street);
      setData({ prefectures, municipalities, street });
    } catch (error) {
      setError(error as AxiosError);
    }
  };

  return {
    error,
    searchAddressByZip,
    setData,
    data,
  };
};
