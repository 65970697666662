import { format, parse } from 'date-fns';
import { Block, f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useMemo } from 'react';
import { getHistoryData } from '../../DriveRecord/DriveRecordHistory/DriveRecordHistoryHelper';
import { DriveHistoryCard } from '@/components/projects/DriveHistoryCard/DriveHistoryCard';
import { Button } from '@/components/uiParts/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { AuthInfoState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useHistory } from '@/hooks/api/driveRecord/useHistory';
import { Car } from '@/types/api/customerApi';
import { HistoryParams } from '@/types/api/driveRecordApi';
import { LinkDriveOwner } from '@/types/api/infoApi';
import { HistoryData } from '@/types/driveHistory';
import { formatDateSpaceDay } from '@/utils/date';
import { isAdContractorData } from '@/utils/home';
import { f7CustomBack } from '@/utils/utils';
interface DriveHistoryProps {
  f7router: Router.Router;
  title: string;
}

export const DriveHistory: FC<DriveHistoryProps> = ({ title }) => {
  const linkDriveOwnerInfo = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const diagnosisDate = useStore(store, 'getDiagnosticDate') as string;
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;
  const ownerId = linkDriveOwnerInfo.owner_id;
  const mCustomerId = authInfo.m_customer_id;
  const isAdContractor = isAdContractorData({
    linkDriveStatus: selectedCar?.linkdrive_status,
  });

  const historyParams: HistoryParams = useMemo(() => {
    const convertedDiagnosisDate = diagnosisDate
      ? parse(diagnosisDate, 'yyyy-MM-dd', new Date())
      : '';
    return {
      owner_id: ownerId,
      m_customer_id: mCustomerId,
      diagnosis_date: convertedDiagnosisDate
        ? format(convertedDiagnosisDate, 'yyyy-MM-dd')
        : '',
    };
  }, [diagnosisDate, mCustomerId, ownerId]);

  const {
    data: dataHistory,
    filterData,
    isLoading,
    isNoData,
  } = useHistory(historyParams);
  const historyList = getHistoryData(dataHistory?.data);

  const historyADList = historyList?.reduce(
    (listArr: HistoryData[][], currentValue) => {
      const monthArr = [];
      const currentDate = currentValue?.diagnosis_date?.substring(0, 10);
      const existDate = listArr
        ?.slice(-1)[0]?.[0]
        ?.diagnosis_date?.substring(0, 10);

      if (currentDate !== existDate) {
        monthArr?.push(currentValue);
        listArr?.push(monthArr);
      } else {
        listArr?.slice(-1)[0]?.push(currentValue);
      }
      return listArr;
    },
    [],
  );

  const handleClickNavigation = () => {
    f7.view.main.router.navigate(paths.driveCalender, { animate: true });
  };
  const buttonCalenderName = useMemo(() => {
    if (filterData) {
      if (isAdContractor) {
        return formatDateSpaceDay(diagnosisDate).split(' ')?.[0];
      }
      return formatDateSpaceDay(diagnosisDate)
        .split(' ')
        .map((e, i) => <span key={i}>{e}</span>);
    }
    return isAdContractor ? 'カレンダーで絞り込む' : '日付を絞り込む';
  }, [diagnosisDate, filterData, isAdContractor]);
  return (
    <PageTemplate
      pageName="HistoryRecord"
      title={title}
      showNavBack
      handleClickBack={() => {
        store.dispatch('setDiagnosticDate', { diagnosticDate: '' });
        store.dispatch('setSelectedDay', { selectedDay: undefined });
        f7CustomBack();
      }}
    >
      <div style={{ marginTop: isAdContractor ? 0 : '-2.4rem' }}>
        {isAdContractor ? (
          <>
            <Button
              onClick={handleClickNavigation}
              icon="icon-calendar-schedule-blue"
              color="white"
              textColor="black"
            >
              {buttonCalenderName}
            </Button>
            {!isLoading &&
              historyADList?.map((historyList, idx) => (
                <DriveHistoryCard
                  key={idx}
                  historyList={historyList ?? []}
                  filterData={filterData}
                  isAdContractor={isAdContractor}
                />
              ))}
          </>
        ) : (
          <>
            <DriveHistoryCard
              historyList={historyList ?? []}
              btnName={buttonCalenderName}
              paths={paths.driveCalender}
              endIconBtn="icon-calendar-schedule-blue"
              filterData={filterData}
              isAdContractor={isAdContractor}
            />
          </>
        )}
        {!filterData && !historyList?.length && !isNoData && (
          <Block>
            <div
              className="no-data u-text-center u-font-size-xxl"
              style={{ color: '#999' }}
            >
              直近の走行データはありません。
              <br />
              カレンダーから過去の走行データ
              <br />
              を確認できます。
            </div>
          </Block>
        )}
      </div>
    </PageTemplate>
  );
};
