import {} from 'framework7-react';
import { FC } from 'react';
import {
  ContractSignature,
  SignFormData,
} from './ContractSignature/ContractSignature';

interface ContractSignaturePageProps {
  event?: (data: SignFormData) => void;
}

export const ContractSignaturePage: FC<ContractSignaturePageProps> = (
  props,
) => {
  return <ContractSignature event={props.event} />;
};
