import { AxiosError } from 'axios';
import { useState } from 'react';
import { fetchVehicleStrikeSettingsApi } from '@/api/driveRecordApi';
import { FetchStrikeSettingsApiParams } from '@/types/api/driveRecordApi';

export type NotificationSettings = {
  m_customer_id: number;
  notification_category: number;
  notification_type: number;
  target_flg: number;
};

interface StrikeSettingsDataFormat {
  bigSettings: NotificationSettings;
  middleSettings: NotificationSettings;
  smallSettings: NotificationSettings;
}

export const useFetchVehicleStrikeSettings = () => {
  const [data, setData] = useState<NotificationSettings[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const fetchVehicleStrikeSettings = async (
    params: FetchStrikeSettingsApiParams,
  ) => {
    try {
      setIsLoading(true);
      const { data } = await fetchVehicleStrikeSettingsApi(params);
      if (!data || !data.success || !data.settings) return;
      setData(data.settings);
    } catch (error) {
      setError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    fetchVehicleStrikeSettings,
  };
};
