import { Button } from 'framework7-react';
import { FC } from 'react';
import style from './LinkDriveSetupStep2Style.module.css';
import lightImage from '@/assets/images/light.png';

interface Props {
  onSubmit: () => void;
}

export const StepPreconfirm: FC<Props> = ({ onSubmit }) => {
  return (
    <>
      <h2 className={style['head']}>
        <i className={`icon-check ${style['head-icon']}`} />
        青いランプが点滅後、点灯するまでお待ちください。
      </h2>
      <div className={style['rounded-image']}>
        <img src={lightImage} alt="" />
      </div>
      <div className={style['text-warning']}>
        点滅後、点灯が確認できない場合は、
        <br />
        コネクターを一度抜き、差し込み直してください
      </div>

      {/* <!-- 以降の手順の記載 --> */}
      <Button
        fill
        round
        text="点灯を確認した"
        style={{ marginTop: 'auto' }}
        onClick={onSubmit}
      />
    </>
  );
};
