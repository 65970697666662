import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './emailDisableStyle.module.css';
import { CustomerInquiryBox } from '@/components/projects/CustomerInquiryBox/CustomerInquiryBox';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';

interface EmailDisableProps {
  f7router: Router.Router;
}
export const EmailDisable: FC<EmailDisableProps> = ({ f7router }) => {
  const handleClickContact = () => {
    // TODO: サポートセンターに電話するボタン押下時の処理
  };
  return (
    <PopupPageTemplate
      title={`登録したはずのメールアドレスが\nエラーになる方`}
      pageName="EmailDisable"
    >
      <div className={style['container']}>
        <span>
          <img src="/src/assets/images/login/icon_prev.svg" alt="" />
        </span>
        <h3
          className={style['pre-wrap']}
        >{`ご登録いただいたメールアドレスが\n間違っている可能性があります`}</h3>

        <div className={style['support-box']}>
          <img src="/src/assets/images/login/icon_support.svg" alt="" />
          <span>
            <h3>
              大変お手数ですがサポートセンターにご連絡の上、登録メールアドレスのご確認ををお願いいたします
            </h3>
          </span>
        </div>
        <CustomerInquiryBox
          infoList={[
            'お客様のお名前',
            'お客様の生年月日',
            'お客様のお電話番号',
            'ご登録いただいているメールアドレス',
          ]}
        />
        <h3>「登録メールアドレスの確認」とお伝えください</h3>
        <div className={style['buttons-box']}>
          <Button icon="icon-telephone" handleClickAction={handleClickContact}>
            サポートセンターに電話する
          </Button>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
