import { differenceInSeconds, format } from 'date-fns';
import { f7 } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import { SelfScanStart } from './SelfScanStart/SelfScanStart';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useScanResult } from '@/hooks/api/linkDrive/useScanResult';

export const SelfScanStartPage: FC = () => {
  const [latestScanAt, setLatestScanAt] = useState<string>();
  const { data, fetchScanResult } = useScanResult();

  useEffect(() => {
    const ownerId = store.state.linkDriveOwnerInfo.owner_id;
    const scanResultApiParams = {
      owner_id: ownerId,
    };

    fetchScanResult({
      params: scanResultApiParams,
      resolve: (data) => {
        if (data.success) {
          const results = data.t_linkdrive_scan_results;
          const latest = results.length
            ? results.reduce((prev, current) => {
                // const prevAt = parse(prev.diagnosed_at, 'yyyy-MM-dd HH:mm:ss', new Date());
                // const currentAt = parse(current.diagnosed_at, 'yyyy-MM-dd HH:mm:ss', new Date());
                // return differenceInSeconds(currentAt, prevAt) > 0 ? current : prev;
                return differenceInSeconds(
                  new Date(current.diagnosed_at),
                  new Date(prev.diagnosed_at),
                ) > 0
                  ? current
                  : prev;
              }, results[0])
            : null;
          if (latest) {
            // const latestAt = parse(latest.diagnosed_at, 'yyyy-MM-dd HH:mm:ss', new Date());
            // setLatestScanAt(format(latestAt, 'yyyy年M月d日'));
            setLatestScanAt(
              format(new Date(latest.diagnosed_at), 'yyyy年M月d日'),
            );
          }
        }
      },
    });
  }, []);

  const handleClickScanHistory = () => {
    f7.view.main.router.navigate(paths.scanHistory);
  };

  const handleClickAction = () => {
    f7.view.main.router.navigate(paths.selfScan);
  };

  const handleClickLinkDriveTerm = () => {
    f7.view.main.router.navigate(paths.linkDriveTerm);
  };

  return (
    <SelfScanStart
      latestScanAt={latestScanAt}
      handleClickScanHistory={handleClickScanHistory}
      handleClickAction={handleClickAction}
      handleClickLinkDriveTerm={handleClickLinkDriveTerm}
    />
  );
};
