import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { Radio } from '../Radio/Radio';
import style from './ChargePrepaidStyle.module.css';
import { ReactComponent as PlusColorIcon } from '@/assets/images/menu/icon-plus-color.svg';
import { Button } from '@/components/uiParts/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { PaymentDetail } from '@/config/initialState';
import { amountList } from '@/consts/charge';
import { PaymentTypeId } from '@/types/payment';
import { formatDateISO, formatToJaDate } from '@/utils/date';
import {
  isBankOrPayEasy,
  switchPaymentIcon,
  switchPaymentMethodDesc,
} from '@/utils/payment';
import './ChargePrepaidStyle.scss';

interface ChargePrepaidProps {
  amount: number | undefined;
  changeAmount: (input: number) => void;
  currentBalance: number;
  handleAddChargeAmountClick: (value: number) => void;
  handleClickChargeAction: () => void;
  isActive: () => boolean;
  handleBack: () => void;
  displayPayment: PaymentDetail;
  paymentList: PaymentDetail[];
  handleClickChangePaymentMethod: (item: PaymentDetail) => void;
  handleClickAddMethod: () => void;
  PostChargeDataToGmoForm: FC;
}

export const ChargePrepaid: FC<ChargePrepaidProps> = ({
  amount,
  changeAmount,
  currentBalance,
  handleAddChargeAmountClick,
  handleClickChargeAction,
  isActive,
  handleBack,
  displayPayment,
  paymentList,
  handleClickChangePaymentMethod,
  handleClickAddMethod,
  PostChargeDataToGmoForm,
}) => {
  return (
    <PageTemplate
      pageName="ChargePrepaid"
      title="チャージ"
      showNavBack={true}
      handleClickBack={handleBack}
    >
      <div className="page-top">
        <div className="heading-with-button u-font-size-xl u-font-bold">
          お支払い金額とチャージ額をご確認ください
        </div>
        <div className={`${style['annotation']} u-margin-bottom-md`}>
          ※毎月の積立と異なり、こちらからのチャージについてボーナスチャージの付与はございません
        </div>
        <div className="heading-with-button u-font-size-xl u-font-bold u-margin-bottom-xs">
          チャージ額（100円単位）
        </div>
        <div className="input-group u-margin-bottom-sm">
          <div className="input-group-area">
            <NumericFormat
              inputMode="numeric"
              value={amount}
              allowNegative={false}
              thousandSeparator=","
              placeholder="0"
              onValueChange={(e) => {
                changeAmount(Number(e.value));
              }}
            />
          </div>
          <div className="input-group-icon u-font-bold">
            <span>円</span>
          </div>
        </div>
        <div className="button-group u-margin-bottom-xl">
          {amountList.map((item, i) => (
            <Button
              key={i}
              text={item.text}
              buttonType="small"
              className="u-font-size-md charge-button"
              handleClickAction={() => handleAddChargeAmountClick(item.value)}
            />
          ))}
        </div>
        <div className={style['balance-container']}>
          <div className={style['balance-wrapper']}>
            <div className="u-font-size-md u-font-bold">お支払い合計金額</div>
            <div className={style['float-right']}>
              <span className={style['balance-value-large']}>
                {amount ? amount.toLocaleString() : 0}
              </span>
              <span className={style['balance-unit-large']}>円</span>
            </div>
          </div>
        </div>
        <hr className="u-margin-bottom-sm u-margin-top-xs border" />
        <div className={style['balance-container']}>
          <div className={style['balance-wrapper']}>
            <div className="u-font-size-md u-font-bold">現在の利用可能残高</div>
            <div className={style['float-right']}>
              <span className={style['balance-value']}>
                {currentBalance.toLocaleString()}
              </span>
              <span className={style['balance-unit']}>円</span>
            </div>
          </div>
        </div>

        <div className={style['payment-method-container']}>
          <div className={style.head}>決済方法</div>
          {!!paymentList &&
            paymentList.map((item, index: number) => (
              <div
                className="u-margin-top-sm info-card register-payment__wrap"
                key={index}
              >
                <Radio
                  id={`payment_method_${item.paymentMethodId}`}
                  name="chargePrepaid.paymentMethod"
                  checked={
                    item.paymentMethodId == displayPayment.paymentMethodId
                  }
                  className={style['input-radio']}
                  onChange={() => handleClickChangePaymentMethod(item)}
                >
                  <div className="pay-content display-flex">
                    <div className="u-font-bold">
                      <i
                        className={`icon ${switchPaymentIcon(
                          item.paymentTypeId as PaymentTypeId,
                        )}`}
                      />
                    </div>
                    <div className="u-padding-left-xs u-padding-right-xs register-payment__wrap01">
                      <p className="bank-name">
                        {switchPaymentMethodDesc(
                          item.paymentTypeId,
                          item.bankName,
                          item.paymentTypeName,
                        )}
                      </p>
                      <p className="number">
                        {isBankOrPayEasy(item.paymentTypeId as PaymentTypeId)
                          ? '口座番号：' +
                            (item.accountNumber || '口座番号情報なし')
                          : item.cardNo}
                      </p>
                      <p className="number">
                        {`登録日：${formatToJaDate(
                          formatDateISO(
                            item?.register || new Date().toISOString(),
                          ),
                        )}`}
                      </p>
                    </div>
                  </div>
                </Radio>
              </div>
            ))}
        </div>

        <div
          className="register-payment-group color-black"
          onClick={handleClickAddMethod}
        >
          <div className="u-font-bold u-font-size-lg icon-add">
            <PlusColorIcon {...{ width: 24, height: 24 }} />
          </div>
          <div className="payment-method-group-child">
            <div className="u-font-bold">別のクレジットカードを追加</div>
          </div>
          <i className="icon-middle icon icon-right" />
        </div>

        <div className="u-margin-top-xs info-box">※外部サイトに移動します</div>
        <Button
          text="確認"
          handleClickAction={handleClickChargeAction}
          style={{ width: '100%', marginTop: '70px' }}
          isActive={isActive()}
        />

        {/* 非表示フォーム。確認ボタン押下時にsubmitされ、GMOテンプレート画面に遷移する */}
        <PostChargeDataToGmoForm />
      </div>
    </PageTemplate>
  );
};
