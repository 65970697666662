import { Router } from 'framework7/types';
import { CarRepairPage } from '@/components/pages/CarRepair/CarRepairPage';
import { CarRepairDetailPage } from '@/components/pages/CarRepairDetail/CarRepairDetailPage';
import { paths } from '@/config/paths';

export const carRepairRoutes: Router.RouteParameters[] = [
  {
    path: paths.carRepair,
    component: CarRepairPage,
    name: '修理',
  },
  {
    path: paths.repairDetail(':id'),
    component: CarRepairDetailPage,
    name: '修理',
  },
];
