import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './smsDisableStyle.module.css';
import { CustomerInquiryBox } from '@/components/projects/CustomerInquiryBox/CustomerInquiryBox';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';

interface SmsDisableProps {
  f7router: Router.Router;
}
export const SmsDisable: FC<SmsDisableProps> = ({ f7router }) => {
  const handleClickLogin = () => {
    // TODO: 遷移しない
    f7.view.main.router.navigate(paths.loginEmail);
  };
  const handleClickContact = () => {
    // TODO: サポートセンターに電話するボタン押下時の処理
  };
  return (
    <PopupPageTemplate
      title={`SMSを受け取れない\n電話番号をお使いの方`}
      pageName="SmsDisable"
    >
      <div className={style['container']}>
        <span>
          <img src="/src/assets/images/login/icon_prev.svg" alt="" />
        </span>
        <h3
          className={style['pre-wrap']}
        >{`ご登録いただいた電話番号が\n間違っている可能性があります`}</h3>

        <div className={style['support-box']}>
          <img src="/src/assets/images/login/icon_support.svg" alt="" />
          <span>
            <h3>
              大変お手数ですがサポートセンターにご連絡の上、メールアドレスの登録手続きをお願いいたします
            </h3>
          </span>
        </div>
        <CustomerInquiryBox
          infoList={[
            'お客様のお名前',
            `お客様のお電話番号\n(以前利用されていたもの)`,
            'お客様の生年月日',
          ]}
        />
        <div className={style['buttons-box']}>
          <Button icon="icon-telephone" handleClickAction={handleClickContact}>
            サポートセンターに電話する
          </Button>
          <p>または</p>
          <Button
            icon="icon-mail"
            handleClickAction={handleClickLogin}
            colorTheme="gray"
          >
            メールアドレスでログインする
          </Button>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
