import { FC } from 'react';
import style from './LinkDriveSetupStep2Style.module.css';

export const SetupNote: FC = () => {
  return (
    <div className={style['setup-note']}>
      <div className={style['setup-note-title']}>
        <i className="icon-help-blue" />
        <span>セットアップがうまく進まない方はこちら</span>
      </div>
      <div className={style['setup-note-content']}>
        <p>通信状況によって実施結果が反映されないことがあります。</p>
        <p>セットアップが進まない場合は設定画面に戻り、再度実施ください</p>
      </div>
    </div>
  );
};
