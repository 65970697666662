import React, { FC } from 'react';
import { DrivingDataAnalysisSettings } from './DrivingDataAnalysisSettings/DrivingDataAnalysisSettings';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const DrivingDataAnalysisSettingsPage: FC = () => {
  const { data } = useTerm(termType.SCORE);
  const html = data?.term;
  return <DrivingDataAnalysisSettings drivingAnalysisServiceAgree={html} />;
};
