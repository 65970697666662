import { Router } from 'framework7/types';
import { TroubleGuide } from '@/components/pages/Trouble/Trouble/Tabs/TroubleGuide/TroubleGuide';
import { TroubleService } from '@/components/pages/Trouble/Trouble/Tabs/TroubleService/TroubleService';
import { TroubleTerms } from '@/components/pages/Trouble/Trouble/Tabs/TroubleTerms/TroubleTerms';
import { paths } from '@/config/paths';

export const tabsTrouble: Router.RouteParameters[] = [
  {
    path: paths.root,
    id: 'trouble-guide',
    component: TroubleGuide,
    name: '車が動かくなくなった_ご利用の流れ',
    options: {
      props: {
        icon: '',
        animate: true,
        tabText: 'ご利用の流れ',
        fontSize: '14px',
      },
    },
  },
  {
    path: paths.troubleService,
    id: 'trouble-service',
    component: TroubleService,
    name: '車が動かくなくなった_保証適用について',
    options: {
      props: {
        animate: true,
        icon: '',
        tabText: 'ご利用可能な\nサービス',
        fontSize: '14px',
      },
    },
  },
  {
    path: paths.troubleTerms,
    id: 'trouble-terms',
    component: TroubleTerms,
    name: '車が動かくなくなった_利用規約',
    options: {
      props: {
        animate: true,
        icon: '',
        tabText: '利用規約',
        fontSize: '14px',
      },
    },
  },
];
