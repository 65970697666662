import { f7, useStore } from 'framework7-react';
import { BaseSyntheticEvent, FC, useMemo, useRef, useState } from 'react';
import styles from './UploadCarPhoto.module.css';
import { postUploadCarPhoto } from '@/api/carApi';
import CarIcon0 from '@/assets/images/car-upload/car-icon-0.png';
import CarIcon1 from '@/assets/images/car-upload/car-icon-1.png';
import CarIcon2 from '@/assets/images/car-upload/car-icon-2.png';
import CarIcon3 from '@/assets/images/car-upload/car-icon-3.png';
import CarIcon4 from '@/assets/images/car-upload/car-icon-4.png';
import CarIcon5 from '@/assets/images/car-upload/car-icon-5.png';
import CarIcon6 from '@/assets/images/car-upload/car-icon-6.png';
import CarIcon7 from '@/assets/images/car-upload/car-icon-7.png';
import CarIcon8 from '@/assets/images/car-upload/car-icon-8.png';
import carDefault from '@/assets/images/carImg.svg';
import photoDefault from '@/assets/images/iconDefaultPhoto.png';
import { ChangeColorPhoto } from '@/components/pages/Car/Car/ChangePhotoColor/ChangePhotoColor';
import { ConfirmCancelUploadPopup } from '@/components/pages/Car/Car/ConfirmCancelUploadPopup/ConfirmCancelUploadPopup';
import { Button } from '@/components/uiParts/Button';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { store } from '@/config/store';
import { UploadCarPhotoResponse } from '@/types/api/carApi';
import { Car } from '@/types/api/customerApi';
import { trigger } from '@/utils/eventHelper';
import { storeDispatch } from '@/utils/store';
import { getCarPhoto } from '@/utils/utils';

const carIcons = [
  CarIcon1,
  CarIcon2,
  CarIcon3,
  CarIcon4,
  CarIcon5,
  CarIcon6,
  CarIcon7,
  CarIcon8,
];
interface UploadCarPhotoProps {
  carInfo: Car;
  isOpenUploadCarPhoto: boolean;
  onPopupClosed: (bool: boolean) => void;
}

export const UploadCarPhoto: FC<UploadCarPhotoProps> = ({
  carInfo,
  isOpenUploadCarPhoto,
  onPopupClosed,
}) => {
  const ownedCars = useStore(store, 'carsInfo') as Required<Car>[];
  const [selectedFileUpload, setSelectedFileUpload] = useState<Blob>();
  const [uploading, setUploading] = useState(false);
  const [carIconIndex, setCarIconIndex] = useState<number>();
  const [showChangeColor, setShowChangeColor] = useState(false);
  const [isOpenCancelUploadPopup, setIsOpenCancelUploadPopup] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const currentCarImageUrl = useMemo(
    () => getCarPhoto(carInfo, false),
    [carInfo],
  );

  const toChooseFile = () => fileInputRef.current?.click();
  const handleChangePhotoInput = async (e: BaseSyntheticEvent) => {
    setCarIconIndex(undefined);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const blob = await createImage(
        URL.createObjectURL(new Blob([file], { type: file.type })),
      );
      if (blob) {
        setSelectedFileUpload(blob);
      }
    }
  };

  const selectDefaultCarIcon = async (photo: string, index: number) => {
    setCarIconIndex(index);
    handleColorChange(photo);
  };

  const handleColorChange = async (photo: string) => {
    const blob = await createImage(photo);
    if (blob) {
      setSelectedFileUpload(blob);
    }
  };

  const createImage = async (url: string) => {
    const image = await loadImage(url);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Cannot open selected photo.');
      return null;
    }

    // set canvas size to match the bounding box
    let imgWidth = image.width;
    let imgHeight = image.height;

    //resize image
    const maxDimention = 250;
    if (imgWidth > maxDimention) {
      imgHeight = ~~(imgHeight / (imgWidth / maxDimention));
      imgWidth = maxDimention;
    }
    if (imgHeight > maxDimention) {
      imgWidth = ~~(imgWidth / (imgHeight / maxDimention));
      imgHeight = maxDimention;
    }

    canvas.width = imgWidth;
    canvas.height = imgHeight;

    ctx.drawImage(image, 0, 0, imgWidth, imgHeight);

    return new Promise<Blob>((resolve) => {
      canvas.toBlob((file) => {
        resolve(file as Blob);
      }, 'image/png');
    });
  };

  const loadImage = (photo: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = photo;
    });

  const uploadCarPhoto = async () => {
    setUploading(true);
    if (selectedFileUpload) {
      const response = await postUploadCarPhoto(
        store.state.authInfo.m_customer_id,
        carInfo.t_stock_car_id,
        selectedFileUpload,
      );

      setUploading(false);
      if (response?.data?.success) {
        return handleUploadCarPhotoSuccess(response.data);
      }

      f7.dialog.alert(
        response?.data?.message ||
          response?.data?.error_message ||
          '画像のアップロード中にエラーが発生しました',
        'アップロードに失敗しました',
        () => {},
      );
    }
  };

  const handleUploadCarPhotoSuccess = (data: UploadCarPhotoResponse) => {
    setSelectedFileUpload(undefined);
    setCarIconIndex(undefined);
    setShowChangeColor(false);

    const newPhotoUrl = data.user_upload_image_file_path as string;

    // change current selected car photo
    storeDispatch('setCarItemInfo', 'carItemInfo', {
      ...carInfo,
      user_upload_image_file_path: newPhotoUrl,
    });
    // Update affiliate
    trigger('getAndSetAffiliate', {});
    // Change car at car list photo at store key `carsInfo`
    const carIndex = ownedCars.findIndex(
      (oCar) => oCar.t_stock_car_id === carInfo.t_stock_car_id,
    );
    if (carIndex >= 0) {
      ownedCars[carIndex] = {
        ...ownedCars[carIndex],
        user_upload_image_file_path: newPhotoUrl,
      };
    }
    storeDispatch('setCarsInfo', 'carsInfo', ownedCars);

    return onPopupClosed(true);
  };

  const closeAndResetScreen = () => {
    setSelectedFileUpload(undefined);
    setCarIconIndex(undefined);
    setShowChangeColor(false);
    onPopupClosed(false);
  };

  const confirmClosePopup = () => {
    if (selectedFileUpload) {
      setIsOpenCancelUploadPopup(true);
    } else {
      closeAndResetScreen();
    }
  };

  const handleConfirmCancelUploadPopupClosed = (isConfirmed: boolean) => {
    setIsOpenCancelUploadPopup(false);
    if (isConfirmed) {
      closeAndResetScreen();
    }
  };

  return (
    <PopupTemplate
      id="UploadCarPhoto"
      opened={isOpenUploadCarPhoto}
      handleClose={() => confirmClosePopup()}
      onPopupClosed={closeAndResetScreen}
      title="画像登録"
    >
      {showChangeColor ? (
        <div className={styles['container']}>
          <ChangeColorPhoto
            carIconIdexSelected={carIconIndex}
            onColorChange={handleColorChange}
          />
          <div className={styles['popup-footer']}>
            <Button
              disabled={!selectedFileUpload}
              loading={uploading}
              preloader={true}
              onClick={uploadCarPhoto}
            >
              保存
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles['container']}>
          <h2 className={styles['sub-title']}>
            画像をアップロード、またはイラストを選択してください
          </h2>
          <div className={styles['car-photo-box']}>
            <input
              onChange={handleChangePhotoInput}
              ref={fileInputRef}
              type="file"
              accept="image/*"
            />
            <div
              className={`${styles['car-photo']} ${
                selectedFileUpload || currentCarImageUrl
                  ? ''
                  : styles['no-image']
              }`}
            >
              {selectedFileUpload ? (
                <img src={URL.createObjectURL(selectedFileUpload)} />
              ) : (
                <img
                  src={currentCarImageUrl || photoDefault}
                  onError={({ currentTarget }) =>
                    (currentTarget.src = carDefault)
                  }
                />
              )}
            </div>
          </div>
          <div className={styles['car-icon-box']}>
            <div
              onClick={toChooseFile}
              className={`${styles['car-icon-box-item']} ${styles['choose-upload']}`}
            >
              <img src={CarIcon0} width={90} height={67} />
            </div>
            {carIcons.map((carIcon, index) => (
              <div
                className={styles['car-icon-box-item']}
                key={`icon-${carIcon}`}
              >
                <img
                  onClick={() => selectDefaultCarIcon(carIcon, index)}
                  src={carIcon}
                  width={90}
                  height={67}
                />
              </div>
            ))}
          </div>
          <div className={styles['popup-footer']}>
            {carIconIndex === undefined ? (
              <Button
                disabled={!selectedFileUpload}
                loading={uploading}
                preloader={true}
                onClick={uploadCarPhoto}
              >
                保存
              </Button>
            ) : (
              <Button onClick={() => setShowChangeColor(true)}>次へ</Button>
            )}
          </div>
        </div>
      )}

      <ConfirmCancelUploadPopup
        backdropEl={`.${styles['container']}`}
        isOpen={isOpenCancelUploadPopup}
        onPopupClose={(isConfirmed) =>
          handleConfirmCancelUploadPopupClosed(isConfirmed)
        }
      />
    </PopupTemplate>
  );
};
