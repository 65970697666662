import { f7 } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import { EmailDuplicated } from './EmailDuplicated/EmailDuplicated';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useMultipleAffiliateInfo } from '@/hooks/api/auth/useMultipleAffiliateInfo';
import { MultipleAffiliateInfoApiParams, Affiliate } from '@/types/api/authApi';
import { getAffiliateIdFromThemeId } from '@/utils/shop';

interface EmailDuplicatedPageProps {
  affiliates?: Affiliate[];
}

export const EmailDuplicatedPage: FC<EmailDuplicatedPageProps> = ({
  affiliates,
}) => {
  const [customers, setCustomers] = useState<Affiliate[]>([]);
  const [customer, setCustomer] = useState<Affiliate | null | undefined>();
  const { getMultipleAffiliateInfo, data } = useMultipleAffiliateInfo();
  useEffect(() => {
    if (affiliates && affiliates.length > 0) {
      setCustomers(affiliates);
    } else {
      let parameter = '';
      if (store.state.loginInfo?.loginType === 'email') {
        parameter = store.state.loginInfo?.email || '';
      }
      if (store.state.loginInfo?.loginType === 'telephone') {
        parameter = store.state.loginInfo?.tel || '';
      }
      const params: MultipleAffiliateInfoApiParams = {
        parameter,
      };
      getMultipleAffiliateInfo(params);
    }
  }, []);

  useEffect(() => {
    if (data?.m_customers) {
      const globalTheme = store.state.globalTheme;
      if (globalTheme != null) {
        const currentAffiliate = data.m_customers.find(
          (customer) =>
            customer.m_affiliate_id === getAffiliateIdFromThemeId(globalTheme),
        );
        if (currentAffiliate) {
          setCustomers(
            data.m_customers.filter(
              (affiliate) =>
                affiliate.affiliate_app_type ===
                currentAffiliate.affiliate_app_type,
            ),
          );
          return;
        }
      }
      setCustomers(data.m_customers);
    }
  }, [data]);

  const handleClickChange = (customerData: Affiliate) => {
    setCustomer(customerData);
  };

  const handleClickSubmit = async () => {
    if (customer) {
      await store.dispatch('setAuthInfo', {
        authInfo: {
          ...store.state.authInfo,
          m_customer_id: customer.id,
        },
      });
      f7.views.main.router.navigate(paths.home);
      setCustomers([]);
    }
  };
  return (
    <EmailDuplicated
      customers={customers}
      customer={customer}
      handleClickChange={handleClickChange}
      handleClickSubmit={handleClickSubmit}
    />
  );
};
