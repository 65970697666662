import React, { FC } from 'react';
import style from './StepperStyle.module.css';

interface StepperProps {
  activeTabId: number;
  tabs: string[];
  className?: string;
  activeClassName?: string;
}

export const Stepper: FC<StepperProps> = ({
  activeTabId,
  tabs,
  className,
  activeClassName,
}) => {
  return (
    <div className={`${style['wrapper']} ${className}`}>
      <ol className={style['stepper']}>
        {tabs.map((item, index) => (
          <li
            key={index}
            className={`${style['stepper-item']} ${
              activeTabId >= index &&
              `${style['stepper-item-active']} ${activeClassName}`
            }`}
          >
            <h4>
              {activeTabId > index ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.67438 6.88151C2.91235 6.64354 3.29819 6.64354 3.53616 6.88151L5.54277 8.88812L11.2056 3.22526C11.4436 2.98729 11.8294 2.98729 12.0674 3.22526C12.3054 3.46324 12.3054 3.84907 12.0674 4.08705L5.54277 10.6117L2.67438 7.7433C2.4364 7.50532 2.4364 7.11949 2.67438 6.88151Z"
                    fill="white"
                  />
                </svg>
              ) : (
                index + 1
              )}
            </h4>
            <p className={style['stepper-desc']}>{item}</p>
          </li>
        ))}
      </ol>
    </div>
  );
};
