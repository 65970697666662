import { Col, Row } from 'framework7-react';
import { Router } from 'framework7/types';
import React, { FC } from 'react';
import style from './SelfScanFailure.module.css';
import failureImage from '@/assets/images/error-mark.png';
import { Button } from '@/components/uiParts/Button';
import { CustomList } from '@/components/uiParts/List/CustomList';

type SelfScanFailureProps = {
  f7router: Router.Router;
};

export const SelfScanFailure: FC<SelfScanFailureProps> = ({ f7router }) => {
  const handleClickCall = () => {};
  const handleClickNavigation = () => {};
  return (
    <div>
      <div className={style.icon}>
        <img src={failureImage} alt="" />
        <p className={style.p}>◯個のエラーが見つかりました</p>
      </div>
      <div className={style.card}>
        <h3 className={style['failure-alert-heading']}>
          検出された異常アラート（◯個）
        </h3>
        <ul className={style.lists}>
          <CustomList
            text="冷却水の水温が上昇しています"
            path=""
            f7router={f7router}
          />
          <CustomList
            text="冷却水の水温が上昇しています"
            path=""
            f7router={f7router}
          />
        </ul>
      </div>

      <div style={{ width: '100%', height: '16px' }} />
      <div className={style.card}>
        <h3 className={style['alert-resolution-heading']}>
          アラートを解消するには
        </h3>
        <p className={style['alert-resolution-p']}>
          一度詳細な情報を確認して、対応を切り分ける必要があります。
        </p>
        <p className={style['alert-resolution-p']}>
          サポートセンターに連絡して、エラー情報を確認してもらってください。
        </p>
        <p className={style['alert-resolution-p_remark']}>
          ※エラー情報は、サポートセンターに共有されています。
        </p>
        <Row>
          <Col width="10" />
          <Col width="80">
            <Button icon="icon-telephone" handleClickAction={handleClickCall}>
              サポートセンターに電話する
            </Button>
          </Col>
          <Col width="10" />
        </Row>
        <div style={{ width: '100%', height: '16px' }} />
      </div>

      <div style={{ width: '100%', height: '48px' }} />
      <Row>
        <Col width="10" />
        <Col width="60">
          <Button
            className={style.button}
            handleClickAction={handleClickNavigation}
          >
            終了する
          </Button>
        </Col>
        <Col width="10" />
      </Row>
      <div style={{ width: '100%', height: '24px' }} />
    </div>
  );
};
