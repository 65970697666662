import { AxiosError } from 'axios';
import { useState } from 'react';
import { collaborateOwnerApi } from '@/api/linkDriveApi';
import {
  OwnerCollaborationApiParams,
  OwnerCollaborationApiResponse,
} from '@/types/api/linkDriveApi';
import { storeDispatch } from '@/utils/store';

const errorMessage =
  'エラーコード：LD0006<br><br>サービス利用を開始できませんでした。お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。';

export const useCollaborateOwner = () => {
  const [data, setData] = useState<OwnerCollaborationApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const collaborateOwner = async (params: OwnerCollaborationApiParams) => {
    try {
      setIsLoading(true);
      const { data } = await collaborateOwnerApi(params);
      if (!data.success) throw new Error(errorMessage);
      setData(data);
      storeDispatch('setOwnerId', 'ownerId', data.owner.id);
    } catch (err) {
      setError(err as AxiosError);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    collaborateOwner,
  };
};
