import { Card, CardContent } from 'framework7-react';
import { FC } from 'react';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { WarrantiesAppendWarranty } from '@/types/api/customerApi';
import {
  switchWarrantyPartsTitleByCoverageType,
  WarrantyPartsType,
} from '@/utils/warranty';
import './warrantyParts2Style.scss';

interface WarrantyParts2Props {
  appendWarranty: WarrantiesAppendWarranty | undefined;
  warrantyParts: WarrantyPartsType;
}

export const WarrantyParts2: FC<WarrantyParts2Props> = ({
  appendWarranty,
  warrantyParts,
}) => {
  const titleName = switchWarrantyPartsTitleByCoverageType(
    appendWarranty?.covering_type,
  );
  return (
    <PageTemplate pageName="WarrantyParts2" title={titleName} showNavBack>
      <div>
        <div className="card-title u-font-size-xl u-font-bold u-padding-top-sm u-padding-bottom-xs">
          {titleName}一覧
        </div>
        {appendWarranty?.covering_type !== null &&
          warrantyParts.map((parts, index) => (
            <Card key={index} style={{ marginTop: '10px' }}>
              <CardContent>
                <div className="list accordion-list">
                  <ul>
                    <li className="accordion-item">
                      <a className="item-content item-link" href="#">
                        <div className="item-inner">
                          <div className="item-title">
                            {parts.m_accessory_category_name}
                          </div>
                        </div>
                      </a>
                      <div className="accordion-item-content">
                        <div className="block">
                          <ul>
                            {parts.subItems.map((item, index2) => (
                              <li className="list-items sub-item" key={index2}>
                                {item.parts_name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </CardContent>
            </Card>
          ))}
      </div>
    </PageTemplate>
  );
};
