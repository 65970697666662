import { PhydScoreResponse } from '@/types/api/driveRecordApi';
import {
  createMonths,
  createTwoDigitString,
  getDoubleDigitMonth,
  getXAxis,
} from '@/utils/date';

export const getDriveScoreData = (
  data: PhydScoreResponse['data'] | undefined,
) => {
  const initialData = {
    scoreWeeklyInfo: {
      weeklyScoreList: [0, 0, 0, 0, 0, 0, 0],
      createWeekly: [''],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
    },
    scoreMonthlyInfo: {
      monthlyScoreList: [0, 0, 0, 0, 0, 0],
      createMonthly: [''],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
    },
    scoreAllInfo: {
      allScoreList: [0],
      createAll: [''],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
    },
  };

  //過去１週間のデータ;
  const currentWeekDate = new Date();
  const currentWeekYear = currentWeekDate.getFullYear();
  const currentWeekMonth = createTwoDigitString(
    String(currentWeekDate.getMonth() + 1),
  );
  const currentWeekDay = currentWeekDate.getDate();
  const weekDate =
    currentWeekYear + '/' + currentWeekMonth + '/' + currentWeekDay;

  const weeklyDateList = [];
  const week = 7;
  for (let i = 0; i < week; i++) {
    let year = getXAxis(weekDate, 0).substring(-4, 4);
    if (getXAxis(weekDate, -i).substring(-4, 4) === year && i != 0) {
      weeklyDateList.unshift(getXAxis(weekDate, -i).substring(0, 7));
    } else {
      weeklyDateList.unshift(getXAxis(weekDate, -i));
    }
  }
  initialData.scoreWeeklyInfo.createWeekly = weeklyDateList;
  weeklyDateList.forEach((item, index: number) => {
    data?.data.phyd_score_past_week.find((datum) => {
      //同じ月日の時、データを格納
      if (datum.diagnosis_date?.substring(6, 8) === item.substring(5, 7)) {
        initialData.scoreWeeklyInfo.weeklyScoreList[index] =
          datum.phyd_score ?? 0;
      }
    });
  });

  //過去6ヶ月のデータ
  const currentMonthlyDate = new Date();
  const currentMonthlyYear = currentMonthlyDate.getFullYear();
  const currentMonthlyMonth = createTwoDigitString(
    String(currentMonthlyDate.getMonth() + 1),
  );
  const latestDate = currentMonthlyYear + '/' + currentMonthlyMonth;
  const monthlyDateList = createMonths(latestDate).reverse();
  initialData.scoreMonthlyInfo.createMonthly = monthlyDateList;
  monthlyDateList.forEach((item, index: number) => {
    data?.data.phyd_score_last_six_month?.find((datum) => {
      const nMonth = getDoubleDigitMonth(datum.analysis_from_date ?? '');
      if (nMonth === item.substring(0, 2)) {
        initialData.scoreMonthlyInfo.monthlyScoreList[index] =
          datum.average_score ?? 0;
      }
    });
  });

  //全期間（過去3年と今年）
  const currentDate = new Date();
  const startYear = currentDate.getFullYear() - 3;
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const allDateList: string[] = Array.from(
    { length: (currentYear - startYear + 1) * 12 },
    (_, index) => {
      const year = startYear + Math.floor(index / 12);
      const month = index % 12;

      if (year === currentYear && month > currentMonth) {
        return '';
      }
      return `${year}/${month + 1}/1`;
    },
  ).filter(Boolean);

  initialData.scoreAllInfo.createAll = allDateList;

  allDateList.forEach((item, index: number) => {
    data?.data.phyd_score_all?.find((datum) => {
      if (datum.analysis_from_date == item) {
        initialData.scoreAllInfo.allScoreList[index] = datum.average_score ?? 0;
      }
    });
  });
  return initialData;
};
