import { f7 } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import style from './SuccessDialogStyle.module.css';
import { completeCustomerApi } from '@/api/todoListApi';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
interface SuccessDialogProps {
  isAllCheck: boolean;
  t_wf_instance_task_id: number;
}

export const SuccessDialog: FC<SuccessDialogProps> = ({
  isAllCheck,
  t_wf_instance_task_id,
}) => {
  const { m_affiliate_id } = store.state.customerInfo;
  const { openToast } = useToast();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleCompleteCustomer = async () => {
    try {
      f7.preloader.show();
      const res = await completeCustomerApi({
        t_wf_instance_task_id,
        m_affiliate_id,
      });
      f7.preloader.hide();
      if (res.data.success) {
        setOpenDialog(true);
      }
      if (res.data?.error_message) {
        openToast(res.data?.error_message, 'toast-failed', 3000, true, 'top');
      }
    } catch {}
  };
  const handleClose = () => {
    f7.view.main.router.navigate('/news/todo_list/');
    setOpenDialog(false);
  };
  useEffect(() => {
    if (isAllCheck) {
      handleCompleteCustomer();
    }
  }, [isAllCheck]);

  return (
    <PopupTemplate
      id="SuccessDialog"
      title=""
      opened={openDialog}
      handleClose={handleClose}
      closeByBackdropClick={false}
    >
      <div className={`${style.container}`}>
        <i className="icon-success" />
        <h3 className="u-margin-bottom-xs u-margin-top-xs">
          チェックが完了しました。
        </h3>
        <p>ご確認ありがとうございます。</p>
        <p>おつかれさまでした！</p>
      </div>
    </PopupTemplate>
  );
};
