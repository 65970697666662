import { addDays, format } from 'date-fns';
import * as yup from 'yup';
import {
  IdentificationSubscriber,
  CheckExpectedAt,
  MIN_REPAIR_AFTER_CURRENT_DAY,
  TIME_PICKER_RANGER_OPTIONS,
} from '@/consts/carRepair';

const isUniqueDate: yup.TestFunction = (value, context) => {
  const {
    expected_nyuko_date1,
    expected_nyuko_date2,
    expected_nyuko_date3,
    expected_nyuko_time1,
    expected_nyuko_time2,
    expected_nyuko_time3,
  } = context.parent;
  const datetime1 =
    expected_nyuko_date1 && expected_nyuko_time1
      ? `${format(expected_nyuko_date1, 'yyyy-MM-dd')} ${expected_nyuko_time1}`
      : '';
  const datetime2 =
    expected_nyuko_date2 && expected_nyuko_time2
      ? `${format(expected_nyuko_date2, 'yyyy-MM-dd')} ${expected_nyuko_time2}`
      : '';
  const datetime3 =
    expected_nyuko_date3 && expected_nyuko_time3
      ? `${format(expected_nyuko_date3, 'yyyy-MM-dd')} ${expected_nyuko_time3}`
      : '';

  switch (context.path) {
    case 'expected_nyuko_date1':
    case 'expected_nyuko_time1':
      return !datetime1 || (datetime1 != datetime2 && datetime1 != datetime3);
    case 'expected_nyuko_date2':
    case 'expected_nyuko_time2':
      return !datetime2 || (datetime1 != datetime2 && datetime2 != datetime3);
    case 'expected_nyuko_date3':
    case 'expected_nyuko_time3':
      return !datetime3 || (datetime1 != datetime3 && datetime2 != datetime3);
    default:
      return true;
  }
};

export const carRepairRequestToStoreValidationSchema = yup.object().shape(
  {
    is_checked_expected_nyukosaki: yup
      .mixed<CheckExpectedAt>()
      .oneOf(Object.values(CheckExpectedAt))
      .required(),

    expected_nyuko_date1: yup.date().when('is_checked_expected_nyukosaki', {
      is: CheckExpectedAt.EXTERNAL_FACTORY,
      then: yup.date().notRequired(),
      otherwise: yup.date().required(),
    }),

    expected_nyuko_time1: yup.string().when('is_checked_expected_nyukosaki', {
      is: CheckExpectedAt.EXTERNAL_FACTORY,
      then: yup.string().notRequired(),
      otherwise: yup.string().required(),
    }),

    expected_nyuko_date2: yup
      .date()
      .optional()
      .when('expected_nyuko_time2', {
        is: (time: string) => !!time,
        then: yup.date().required('日付を選択してください'),
      })
      .when(
        [
          'is_checked_expected_nyukosaki',
          'expected_nyuko_date1',
          'expected_nyuko_time1',
        ],
        {
          is: (checkExpectedAt: CheckExpectedAt, date1: Date, time1: string) =>
            checkExpectedAt != CheckExpectedAt.EXTERNAL_FACTORY &&
            !!date1 &&
            !!time1,
          then: yup
            .date()
            .test('unique', '選択した時間が重複しています', isUniqueDate),
        },
      ),
    expected_nyuko_time2: yup
      .string()
      .optional()
      .when('expected_nyuko_date2', {
        is: (date: Date) => !!date,
        then: yup.string().required('開始時間を選択してください'),
      })
      .when(
        [
          'is_checked_expected_nyukosaki',
          'expected_nyuko_date1',
          'expected_nyuko_time1',
        ],
        {
          is: (checkExpectedAt: CheckExpectedAt, date1: Date, time1: string) =>
            checkExpectedAt != CheckExpectedAt.EXTERNAL_FACTORY &&
            !!date1 &&
            !!time1,
          then: yup
            .string()
            .test('unique', '選択した時間が重複しています', isUniqueDate),
        },
      ),

    expected_nyuko_date3: yup
      .date()
      .optional()
      .when('expected_nyuko_time3', {
        is: (time: string) => !!time,
        then: yup.date().required('日付を選択してください'),
      })
      .when(
        [
          'is_checked_expected_nyukosaki',
          'expected_nyuko_date1',
          'expected_nyuko_time1',
        ],
        {
          is: (checkExpectedAt: CheckExpectedAt, date1: Date, time1: string) =>
            checkExpectedAt != CheckExpectedAt.EXTERNAL_FACTORY &&
            !!date1 &&
            !!time1,
          then: yup
            .date()
            .test('unique', '選択した時間が重複しています', isUniqueDate),
        },
      ),
    expected_nyuko_time3: yup
      .string()
      .optional()
      .when('expected_nyuko_date3', {
        is: (date: Date) => !!date,
        then: yup.string().required('開始時間を選択してください'),
      })
      .when(
        [
          'is_checked_expected_nyukosaki',
          'expected_nyuko_date1',
          'expected_nyuko_time1',
        ],
        {
          is: (checkExpectedAt: CheckExpectedAt, date1: Date, time1: string) =>
            checkExpectedAt == CheckExpectedAt.EXTERNAL_FACTORY &&
            !!date1 &&
            !!time1,
          then: yup
            .string()
            .test('unique', '選択した時間が重複しています', isUniqueDate),
        },
      ),

    contact_person_phone_number: yup
      .object({
        first: yup.string(),
        second: yup.string(),
        third: yup.string(),
      })
      .test(
        'required',
        ({ first, second, third }) => !!first && !!second && !!third,
      )
      .test('phoneNo', ({ first, second, third }) => {
        const fixedRegex =
          /^0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}$/;
        const mobileRegex = /^(050|070|080|090)-\d{4}-\d{4}$/;
        if (fixedRegex.test(`${first}-${second}-${third}`)) return true;
        if (mobileRegex.test(`${first}-${second}-${third}`)) return true;
        return false;
      }),
    is_checked_identification_subscriber: yup
      .mixed<IdentificationSubscriber>()
      .oneOf(Object.values(IdentificationSubscriber))
      .required(),
    contact_person_name: yup
      .object()
      .when('is_checked_identification_subscriber', {
        is: IdentificationSubscriber.AGENT,
        then: yup.object().shape({
          family_name: yup.string().required(),
          first_name: yup.string().required(),
        }),
        otherwise: yup.object().shape({
          family_name: yup.string().notRequired(),
          first_name: yup.string().notRequired(),
        }),
      }),
    contact_person_name_kana: yup
      .object()
      .when('is_checked_identification_subscriber', {
        is: IdentificationSubscriber.AGENT,
        then: yup.object().shape({
          family_name: yup
            .string()
            .required('お名前ヨミガナが指定されていません')
            .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
          first_name: yup
            .string()
            .required('お名前ヨミガナが指定されていません')
            .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
        }),
        otherwise: yup.object().shape({
          family_name: yup.string().notRequired(),
          first_name: yup.string().notRequired(),
        }),
      }),
    warrantyConfirmed: yup
      .boolean()
      .oneOf([true])
      .when('is_checked_expected_nyukosaki', {
        is: CheckExpectedAt.EXTERNAL_FACTORY,
        then: yup.boolean().required(),
        otherwise: yup.boolean().transform(() => true),
      }),
    feeConfirmed: yup.boolean().oneOf([true]).required(),
  },
  [
    ['expected_nyuko_date2', 'expected_nyuko_time2'],
    ['expected_nyuko_date3', 'expected_nyuko_time3'],
  ],
);

export const carRepairRequestToStoreInitialState = {
  is_checked_expected_nyukosaki: CheckExpectedAt.AUTHORIZED_DEALER,
  expected_nyuko_date1: addDays(new Date(), MIN_REPAIR_AFTER_CURRENT_DAY),
  // eslint-disable-next-line no-magic-numbers
  expected_nyuko_time1: TIME_PICKER_RANGER_OPTIONS[1].value,

  expected_nyuko_date2: undefined,
  expected_nyuko_time2: undefined,

  expected_nyuko_date3: undefined,
  expected_nyuko_time3: undefined,

  contact_person_phone_number: {
    first: undefined,
    second: undefined,
    third: undefined,
  },
  is_checked_identification_subscriber: IdentificationSubscriber.MYSELF,
  contact_person_name: {
    family_name: '',
    first_name: '',
  },
  contact_person_name_kana: {
    family_name: '',
    first_name: '',
  },
  warrantyConfirmed: false,
  feeConfirmed: false,
};
