import { yupResolver } from '@hookform/resolvers/yup';
import { Page } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useRef } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  TEL1_MAX_LENGTH,
  TEL2_MAX_LENGTH,
  TEL3_MAX_LENGTH,
  loginTelephoneSchema,
} from '../loginTelephoneSchema';
import style from './loginTelephoneStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { RhfInput } from '@/components/uiParts/ReactHookForm/RhfInput/RhfInput';
import { UnderLineLink } from '@/components/uiParts/UnderLineLink/UnderLineLink';
import { paths } from '@/config/paths';
import { selectedProcess } from '@/consts/auth';
import { AffiliateId } from '@/consts/shop';
import { useMobileAuth } from '@/hooks/api/auth/useMobileAuth';
import { MobileAuthApiParams } from '@/types/api/authApi';
import { addAffiliateIdToRequestParams } from '@/utils/shop';

interface LoginTelephoneProps {
  f7router: Router.Router;
  affiliateId: AffiliateId;
}

interface FormInput {
  tel1: string;
  tel2: string;
  tel3: string;
}

const TEL1_INDEX = 0;
const TEL2_INDEX = 1;
const TEL3_INDEX = 2;

export const LoginTelephone: FC<LoginTelephoneProps> = ({
  f7router,
  affiliateId,
}) => {
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const formMethods = useForm<FormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { tel1: '', tel2: '', tel3: '' },
    resolver: yupResolver(loginTelephoneSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
    setValue,
    watch,
  } = formMethods;
  const { authenticateMobile } = useMobileAuth(f7router);
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const params: MobileAuthApiParams = {
      phone_no: `${data.tel1}-${data.tel2}-${data.tel3}`,
      selected_process: selectedProcess.login,
    };
    addAffiliateIdToRequestParams(affiliateId, params);
    await authenticateMobile(params);
  };

  const TEL1_VALUE = watch('tel1');
  const TEL2_VALUE = watch('tel2');
  const TEL3_VALUE = watch('tel3');

  return (
    <Page name="LoginTelephone" pageContent={false}>
      <div className={style['container']}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style['tel-input-label-wrapper']}>
              <span>ご登録済みの携帯電話番号を入力してください</span>
              <div className={style['form-check-icon-wrapper']}>
                <div className={style['tel-input-wrapper']}>
                  <RhfInput
                    ref={inputRefs[TEL1_INDEX]}
                    className={style['tel-input']}
                    name="tel1"
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="090"
                    value={TEL1_VALUE}
                    maxLength={3}
                    onChange={(e) => {
                      if (e.target.value.length <= TEL1_MAX_LENGTH)
                        setValue('tel1', e.target.value);
                      if (e.target.value.length === TEL1_MAX_LENGTH) {
                        inputRefs[TEL1_INDEX].current?.blur();
                        inputRefs[TEL2_INDEX].current?.focus();
                      }
                    }}
                    onChangeOverride={true}
                  />
                  <span>-</span>
                  <RhfInput
                    ref={inputRefs[TEL2_INDEX]}
                    className={style['tel-input']}
                    name="tel2"
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="0000"
                    value={TEL2_VALUE}
                    maxLength={4}
                    onChange={(e) => {
                      if (e.target.value.length <= TEL2_MAX_LENGTH)
                        setValue('tel2', e.target.value);
                      if (e.target.value.length === TEL2_MAX_LENGTH) {
                        inputRefs[TEL2_INDEX].current?.blur();
                        inputRefs[TEL3_INDEX].current?.focus();
                      }
                    }}
                    onChangeOverride={true}
                  />
                  <span>-</span>
                  <RhfInput
                    ref={inputRefs[TEL3_INDEX]}
                    className={style['tel-input']}
                    name="tel3"
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="0000"
                    value={TEL3_VALUE}
                    maxLength={4}
                    onChange={(e) => {
                      if (e.target.value.length <= TEL2_MAX_LENGTH)
                        setValue('tel3', e.target.value);
                      if (e.target.value.length === TEL3_MAX_LENGTH) {
                        inputRefs[TEL3_INDEX].current?.blur();
                      }
                    }}
                    onChangeOverride={true}
                  />
                </div>
                <div>
                  <CheckIcon isValid={isValid} />
                </div>
              </div>
              <span>初回ログイン時は、認証用のSMSが送信されます</span>
            </div>
            <div className={style['button-container']}>
              <div className={style['button-wrapper']}>
                <Button type="submit" disabled={!isValid}>
                  次へ
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
        <div className={style['text-link']}>
          <UnderLineLink
            text="電話番号が変わった方"
            path={paths.phoneChanged}
            f7router={f7router}
          />
          <UnderLineLink
            text="ログイン方法がわからなくなった方"
            path={paths.accountForgotten}
            f7router={f7router}
          />
        </div>
      </div>
    </Page>
  );
};
