import { Router } from 'framework7/types';
import { LoginEmailPage } from './LoginEmail/LoginEmailPage';
import { LoginTelephonePage } from './LoginTelephone/LoginTelephonePage';
import { paths } from '@/config/paths';

export type LoginTabRoute = Router.RouteParameters & {
  id: string;
  name: string;
  options: {
    props: {
      icon: string;
      tabText: string;
    };
  };
};

export const loginRouteId = {
  loginTelephone: 'login-telephone',
  loginEmail: 'login-email',
  loginOld: 'login-old',
};

export const loginTabRoutes: LoginTabRoute[] = [
  {
    path: paths.root,
    id: loginRouteId.loginTelephone,
    component: LoginTelephonePage,
    name: '電話番号でログイン',
    options: {
      props: {
        icon: 'icon-telephone',
        tabText: '電話番号で\nログイン',
      },
    },
  },
  {
    path: paths.loginEmail,
    id: loginRouteId.loginEmail,
    component: LoginEmailPage,
    name: 'メールアドレスでログイン',
    options: {
      props: {
        icon: 'icon-mail',
        tabText: 'メールアドレスで\nログイン',
      },
    },
  },
  // {
  //   path: paths.loginOld({
  //     authCallback: ':authCallback',
  //     provider: ':provider',
  //     providerId: ':providerId',
  //     message: ':message',
  //   }),
  //   id: loginRouteId.loginOld,
  //   component: LoginOldPage,
  //   name: '旧アプリをご利用の方',
  //   options: {
  //     props: {
  //       icon: 'icon-mt',
  //       tabText: '旧アプリを\nご利用の方',
  //     },
  //   },
  // },
];
