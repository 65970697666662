import { Router } from 'framework7/types';
import { FC, useEffect, useRef } from 'react';
import style from './NewsDetailStyle.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { Notification } from '@/types/api/customerApi';
import { hyphenToDay } from '@/utils/date';

type NewsDetailProps = {
  f7router?: Router.Router;
  item: Notification;
};

export const NewsDetail: FC<NewsDetailProps> = ({ f7router, item }) => {
  const contentsContainer = useRef<HTMLDivElement>(null);
  const img = item?.image_file_path;
  // const couponEndDate = stringToDate(couponInfo.t_end_date);

  // Important: When passing A tag to parse, you need to add class='external' to the A tag. Otherwise it cannot navigate to the destination
  // if the destination is external website.
  const content = item?.contents || '';

  useEffect(() => {
    if (contentsContainer.current && content) {
      contentsContainer.current.innerHTML = content.replaceAll(
        /[\n\r]/g,
        '<br />',
      );
    }
  }, [contentsContainer, content]);

  return (
    <PageTemplate pageName="NewsDetail" showNavBack title="お知らせ">
      <div className="home__wrapper">
        <RoundSheet>
          <h2 className={style?.notice_title}>{item?.title || ''}</h2>
          <p className={style?.notice_date}>
            {hyphenToDay(item?.start_date ?? '', true)}
          </p>
          {img && (
            <div className={style?.image_container}>
              <img src={img} className={style?.image} />
            </div>
          )}
          {/* {couponFlag === 1 && (
              <PrepaidTicketCoupon
                title={couponInfo.title}
                use_date={couponInfo.t_start_date}
                t_end_date={couponEndDate}
              />
            )} */}
          <div className={style['content-container']} ref={contentsContainer} />
        </RoundSheet>
      </div>
    </PageTemplate>
  );
};
