import { yupResolver } from '@hookform/resolvers/yup';
import {
  Block,
  Card,
  CardContent,
  Col,
  List,
  ListInput,
  Row,
} from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { updateNumberSchema } from '../updateNumberSchema';
import style from './UpdateNumberStyle.module.css';
import { customerInfoApi } from '@/api/customerApi';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { useFetchTransport } from '@/hooks/api/car/useFetchTransport';
import { useUpdateCar } from '@/hooks/api/car/useUpdateCar';
import { useToast } from '@/hooks/useToast';
import { Car } from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack, isEmptyObject } from '@/utils/utils';

type UpdateNumberProps = {
  f7router: Router.Router;
  mCustomerId: number;
  car: Required<Car>;
};

type FormInput = {
  transportBu: string;
  classNo: string;
  hiragana: string;
  registryNo: string;
};

export const UpdateNumber: FC<UpdateNumberProps> = ({
  f7router,
  mCustomerId,
  car,
}) => {
  const { openToast } = useToast();
  const { updateCar } = useUpdateCar();
  const formMethods = useForm<FormInput>({
    mode: 'onBlur',
    defaultValues: {
      transportBu: String(car.transport_bu),
      classNo: car.class_no,
      hiragana: car.hiragana,
      registryNo: car.registry_no,
    },
    resolver: yupResolver(updateNumberSchema),
  });
  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { errors, isDirty },
  } = formMethods;
  const { data: codes } = useFetchTransport();

  const handleSubmitAction: SubmitHandler<FormInput> = async (data) => {
    const tStockCarId = car.t_stock_car_id;
    const params = {
      transport_bu: Number(data.transportBu),
      class_no: data.classNo,
      hiragana: data.hiragana,
      registry_no: data.registryNo,
      m_customer_id: mCustomerId,
      next_inspection_date: car.next_inspection_date,
    };
    await updateCar({
      tStockCarId: String(tStockCarId),
      params,
      onSuccess,
    });
  };

  // 車両ナンバーの変更完了後、storeの車両情報を更新する
  const onSuccess = async () => {
    const { data: customerInfo } = await customerInfoApi({
      m_customer_id: mCustomerId,
    });
    if (customerInfo.cars) {
      const updatedCar = customerInfo.cars.find(
        (carItem) => carItem.t_stock_car_id === car.t_stock_car_id,
      );
      await storeDispatch('setCarItemInfo', 'carItemInfo', updatedCar);
      await storeDispatch('setCarsInfo', 'carsInfo', customerInfo.cars);
    }
    openToast('車両ナンバーの変更が完了しました。');
    f7CustomBack(['#UpdateNumber']);
  };
  const handleTriggerInput = (onchange: any, e: any) => {
    onchange(e);
    trigger();
  };

  return (
    <PopupPageTemplate
      id="UpdateNumber"
      pageName="UpdateNumber"
      title="対象車両ナンバーの変更"
    >
      <Block>
        <IconCard
          iconType="confirm-notification"
          color="warning"
          headingFontSize="14px"
          iconFontSize="30px"
          iconColor=""
          heading={'注意事項'}
        >
          同一使用名義人の転居等による車両登録番号の変更時にのみ変更を行ってください。
          <br />
          <br />
          保証規約に記載の通り、第三者への車両譲渡等による使用名義人及び車両登録番号の変更が認められた場合、本サービスの権利は失効いたします。
          <br />
          <br />
          （保証規約：第7条 保証の継承）
        </IconCard>
        <form onSubmit={handleSubmit(handleSubmitAction)}>
          <Card noShadow outline>
            <CardContent>
              <Row>
                <Col>
                  <List className={style.listStyleNone}>
                    <Controller
                      control={control}
                      name="transportBu"
                      render={({ field: { onChange, value } }) => (
                        <ListInput
                          outline
                          type="select"
                          value={value}
                          onChange={onChange}
                        >
                          {codes?.map((code) => (
                            <option key={code.code_key} value={code.code_key}>
                              {code.code_value}
                            </option>
                          ))}
                        </ListInput>
                      )}
                    />
                  </List>
                </Col>
                <Col>
                  <List className={style.listStyleNone}>
                    <Controller
                      control={control}
                      name="classNo"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <ListInput
                          outline
                          clearButton
                          onInputClear={(e) => {
                            handleTriggerInput(onChange, e);
                          }}
                          placeholder="500"
                          value={value}
                          onChange={(e) => {
                            handleTriggerInput(onChange, e);
                          }}
                          className={!!error ? style.error : ''}
                        />
                      )}
                    />
                    {!!errors.classNo && (
                      <span className={style.errorMessage}>
                        {errors.classNo?.message}
                      </span>
                    )}
                  </List>
                </Col>
              </Row>
              <Row>
                <Col>
                  <List className={style.listStyleNone}>
                    <Controller
                      control={control}
                      name="hiragana"
                      render={({ field: { onChange, value } }) => (
                        <ListInput
                          outline
                          clearButton
                          onInputClear={(e) => {
                            handleTriggerInput(onChange, e);
                          }}
                          type="text"
                          placeholder="さ"
                          value={value}
                          onChange={(e) => {
                            handleTriggerInput(onChange, e);
                          }}
                        />
                      )}
                    />
                    {!!errors.hiragana && (
                      <span className={style.errorMessage}>
                        {errors.hiragana?.message}
                      </span>
                    )}
                  </List>
                </Col>
                <Col>
                  <List className={style.listStyleNone}>
                    <Controller
                      control={control}
                      name="registryNo"
                      render={({ field: { onChange, value } }) => (
                        <ListInput
                          outline
                          clearButton
                          onInputClear={(e) => {
                            handleTriggerInput(onChange, e);
                          }}
                          placeholder="1224"
                          value={value}
                          onChange={(e) => {
                            handleTriggerInput(onChange, e);
                          }}
                        />
                      )}
                    />
                    {!!errors.registryNo && (
                      <span className={style.errorMessage}>
                        {errors.registryNo?.message}
                      </span>
                    )}
                  </List>
                </Col>
              </Row>
            </CardContent>
          </Card>
          <div className={style.buttonWrap}>
            <Button
              text="変更する"
              type="submit"
              disabled={!isDirty || !isEmptyObject(errors)}
            />
          </div>
        </form>
      </Block>
    </PopupPageTemplate>
  );
};
