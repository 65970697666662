import { format } from 'date-fns';
import { Col, Row } from 'framework7-react';
import { Router } from 'framework7/types';
import parse from 'html-react-parser';
import { FC, useEffect, useState } from 'react';
import { Button } from '@/components/uiParts/Button';
import { Dialog } from '@/components/uiParts/Dialog/Dialog';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import {
  CONSULTING_FROM_TYPE,
  CONSULTING_HISTORY_STATUS,
} from '@/consts/consultingHistory';
import { VIEW_STATUS } from '@/consts/news';
import { useReadModifyInquiry } from '@/hooks/api/inquiry/useReadModifyInquiry';
import { useRegisterInqueryDetail } from '@/hooks/api/inquiry/useRegisterInqueryDetail';
import { useRegisterInqueryEnd } from '@/hooks/api/inquiry/useRegisterInqueryEnd';
import { InquiryHeader } from '@/types/api/queryApi';
import { getNowTime, hyphenToDay } from '@/utils/date';
import { storeDispatch } from '@/utils/store';
import './consultingHistoryStyle.scss';

type ConsultingHistoryProps = {
  f7router: Router.Router;
  id: string;
};

//Todo: マジックナンバー調査

export const ConsultingHistory: FC<ConsultingHistoryProps> = ({
  f7router,
  id,
}) => {
  const consultingHistory = store.state.consultingHistoryList;
  const consultingInfo = consultingHistory.find(
    (item: InquiryHeader) => item.t_customer_inquiry_header_id == Number(id),
  );

  const consultingHistoryList = consultingInfo?.inquiry_detail ?? [];
  storeDispatch(
    'setConsultingHistoryDetail',
    'consultingHistoryDetail',
    consultingHistoryList,
  );

  const isConsultingHistoryCompleted =
    consultingInfo?.inquiry_status === CONSULTING_HISTORY_STATUS.COMPLETED;

  const [showDialog, setShowDialog] = useState(false);

  const [message, setMessage] = useState<string>();

  const { registerInqueryDetail } = useRegisterInqueryDetail();
  const { registerInqueryEnd } = useRegisterInqueryEnd();
  const { readModifyInquiry } = useReadModifyInquiry();

  const [isActive, setIsActive] = useState(false);

  const changeMessage = (message: string) => {
    if (message && consultingInfo?.inquiry_status != 2) setIsActive(true);
    else setIsActive(false);
    setMessage(message);
  };

  const handleSendMessage = async () => {
    if (!message) return;
    const params = {
      t_customer_inquiry_header_id: Number(id),
      inquiry_detail: message,
      from_type: 1,
    };
    const res = await registerInqueryDetail(params);
    if (res?.success) {
      const time = format(new Date(), 'HH:mm:ss');
      const historyDetail = {
        from_type: CONSULTING_FROM_TYPE.CUSTOMER,
        inquiry_detail: message,
        send_date: getNowTime(),
        send_time: time,
        read_flg: VIEW_STATUS.READ,
      };
      consultingHistoryList.push(historyDetail);
      setMessage('');
      setIsActive(false);
    }
  };

  const handleClickEndConfirm = (state: boolean) => {
    if (!state) setShowDialog(true);
  };

  const handleClickClose = () => {
    setShowDialog(false);
  };

  const handleClickEnd = () => {
    const params = { t_customer_inquiry_header_id: Number(id) };
    registerInqueryEnd(params, f7router);
  };

  useEffect(() => {
    const isUnReadInquiry = consultingInfo?.inquiry_detail.some(
      (inquiry) =>
        inquiry.from_type === CONSULTING_FROM_TYPE.BPO &&
        inquiry.read_flg === VIEW_STATUS.UNREAD,
    );

    if (isUnReadInquiry) {
      readModifyInquiry({
        t_customer_inquiry_header_id: Number(id),
        read_flg: VIEW_STATUS.READ,
      });
    }
  }, [id]);

  return (
    <PageTemplate
      pageName="ConsultingHistory"
      title={`${consultingInfo?.inquiry_title}のお問い合わせ`}
      showNavBack
    >
      <div className="home__wrapper">
        <div>
          {consultingHistoryList.map((item, index) => (
            <div key={index}>
              {item.from_type === CONSULTING_FROM_TYPE.CUSTOMER && (
                <div className="userStyle info-card info-card-elevated">
                  {index == 0 && (
                    <h3 className="title">
                      {consultingInfo?.inquiry_title}のお問い合わせ
                    </h3>
                  )}
                  <p className="date">
                    {hyphenToDay(item.send_date)}{' '}
                    {item.send_time.substring(0, 5)}
                  </p>
                  <div className="content">{parse(item.inquiry_detail)}</div>
                </div>
              )}
              {item.from_type === CONSULTING_FROM_TYPE.BPO && (
                <div
                  className={'info-card info-card-elevated supportcenterStyle'}
                >
                  <h3 className="title">サポートセンターからの回答</h3>
                  <p className="date">
                    {hyphenToDay(item.send_date)}{' '}
                    {item.send_time.substring(0, 5)}
                  </p>
                  <div className="content">{parse(item.inquiry_detail)}</div>
                </div>
              )}
            </div>
          ))}

          <div className="consulting-button-area">
            <Row className="u-margin-bottom-sm row-width">
              <Col width="20" />
              <Col>
                <Button
                  text={
                    isConsultingHistoryCompleted
                      ? 'お問合せを終了しました'
                      : 'お問合せを終了する'
                  }
                  handleClickAction={() =>
                    handleClickEndConfirm(isConsultingHistoryCompleted)
                  }
                  isActive={!isConsultingHistoryCompleted}
                  className={`consulting-end-button ${
                    isConsultingHistoryCompleted && 'color-gray'
                  }`}
                />
              </Col>
              <Col />
            </Row>
          </div>
          <div className="send-msg-area">
            <textarea
              disabled={isConsultingHistoryCompleted}
              className="send-area textarea"
              value={message}
              onChange={(e) => changeMessage(e.target.value)}
              placeholder="メッセージを入力"
            />
            <div className="send-btn">
              <Button
                text="送信"
                isActive={isActive}
                handleClickAction={handleSendMessage}
                className="send-button"
              />
            </div>
          </div>
        </div>
      </div>
      {showDialog && (
        <Dialog
          title="お問い合わせを終了する"
          subTitle="一度終了したお問い合わせは<br />再開できません。<br />本当に終了しますか?"
          handleOk={handleClickEnd}
          handleCancel={handleClickClose}
          buttonOkText="お問い合わせを終了する"
        />
      )}
    </PageTemplate>
  );
};
