import { Block } from 'framework7-react';
import { FC } from 'react';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import './WarrantyCancelableStyles.scss';

interface WarrantyCancelableProps {
  cancelableTermObj: {
    firstName: string;
    familyName: string;
    warrantyEndDate: string;
    cancelEnableTermStart: string;
    cancelEnableTermEnd: string;
  };
}
export const WarrantyCancelable: FC<WarrantyCancelableProps> = ({
  cancelableTermObj,
}) => {
  const isShowCancelableTerm =
    cancelableTermObj.cancelEnableTermStart &&
    cancelableTermObj.cancelEnableTermEnd;
  return (
    <PageTemplate
      pageName="WarrantyCancelable"
      title="解約可能期間"
      showNavBack
    >
      <Block>
        <div className="card card0">
          <div className="cardHeader">
            {cancelableTermObj.familyName}様の保証解約可能期間
          </div>
          <div className="cardContent">
            {isShowCancelableTerm && (
              <>
                <div>{cancelableTermObj.cancelEnableTermStart}から</div>
                <div>{cancelableTermObj.cancelEnableTermEnd}まで</div>
                <br />
              </>
            )}
            <p>
              ※解約可能期間は、満期日の3か月前の1日から次年度保証期間の2か月前の末日までとなります。
            </p>
          </div>
        </div>
      </Block>
    </PageTemplate>
  );
};
