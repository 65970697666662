import { FC } from 'react';
import { calcWarrantyEndDate, getWarranties } from '../UpdateWarrantyHelper';
import { UpdateWarranty } from '../UpdateWarrantyHelper';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { store } from '@/config/store';
import { useGuaranteeUpdateTarget } from '@/hooks/api/warranty/useGuaranteeUpdateTarget';
import { storeDispatch } from '@/utils/store';
import './UpdateWarrantyStep1.scss';

type UpdateWarrantyStep1PageProps = {
  setStep: (n: number) => void;
  warrantyNo: string;
};
export const UpdateWarrantyStep1Page: FC<UpdateWarrantyStep1PageProps> = ({
  setStep,
}) => {
  const { currentWarranty, updateWarranties } = store.state;
  const warrantyEndDate = calcWarrantyEndDate(
    currentWarranty?.append_warranties?.[0]?.warranty_end_date ?? '',
  );
  const { data } = useGuaranteeUpdateTarget({
    warranty_no: currentWarranty?.warranty_no ?? '',
    branch_no: currentWarranty?.branch_no ?? 0,
  });
  const continuousWarranties = data?.continuous_warranties;
  const warranties = getWarranties(continuousWarranties);

  const handleClickPlanSelectionButton = async (
    updateWarranty: UpdateWarranty,
  ) => {
    const nextStepNum = 2;
    await storeDispatch(
      'setUpdateWarrantyInfo',
      'updateWarrantyInfo',
      updateWarranty,
    );
    setStep(nextStepNum);
  };

  return (
    <div data-name="UpdateWarrantyStep1">
      <div className="content">
        <table className="menu-table u-margin-bottom-lg">
          <tr className="menu-tr-1">
            <td className="select-number">
              <div>1</div>
            </td>
            <td>●</td>
            <td>●</td>
            <td>●</td>
            <td>●</td>
            <td>●</td>
          </tr>
          <tr>
            <td
              style={{ verticalAlign: 'top' }}
              className="menu-falling-under menu-table-item"
            >
              プラン選択
            </td>
            <td className="menu-table-item">
              契約情報
              <br />
              確認
            </td>
            <td className="menu-table-item">
              重要事項
              <br />
              確認
            </td>
            <td className="menu-table-item">
              支払回数
              <br />
              選択
            </td>
            <td className="menu-table-item">
              決済方法
              <br />
              選択
            </td>
            <td style={{ verticalAlign: 'top' }} className="menu-table-item">
              最終確認
            </td>
          </tr>
        </table>

        <InfoCard
          bgColor="muted"
          text="プランの変更は次回更新時から適用されます"
          textSub={`次回更新：${warrantyEndDate}`}
          iconType="bulb"
          isElevated
        />

        <div>
          <div className="step-title">ご希望のプランを選択してください</div>
          {warranties?.map((item, index) => (
            <div className="card" key={index}>
              <div className="card-content">
                <div className="list no-ios-edges">
                  <ul>
                    <li className="plan-comm">
                      <div className="item1">{item.warranty_name}</div>{' '}
                    </li>
                    <li className="plan-description">
                      <div className="item1">保証期間</div>
                      <div>{item.continuous_contract_term ?? '-'} ヶ月</div>
                    </li>
                    <li className="plan-description">
                      <div className="item1">保証限度額</div>
                      <div>
                        {Number(item.amount_limit)
                          ? `${Number(item.amount_limit).toLocaleString()} 円`
                          : '無制限'}
                      </div>
                    </li>
                    <li className="plan-description">
                      <div className="item1">保証料総額</div>
                      <div style={{ color: '#fc6572' }}>
                        {Number(
                          item.warranty_fee_wh_continuous,
                        ).toLocaleString()}{' '}
                        円
                      </div>
                    </li>
                    <li className="plan-description-no-border">
                      <div>{item.plan_info}</div>
                    </li>
                  </ul>
                </div>
                <div className="btn-row">
                  <div />
                  <div />
                  <button
                    className="btn"
                    onClick={() => handleClickPlanSelectionButton(item)}
                  >
                    このプランにする
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
