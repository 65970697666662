import { AxiosError } from 'axios';
import { f7, useStore } from 'framework7-react';
import { useEffect, useMemo, useState } from 'react';
import { fetchDriveDaysApi } from '@/api/driveRecordApi';
import { store } from '@/config/store';
import { Car } from '@/types/api/customerApi';
import { DrivingDayParams } from '@/types/api/driveRecordApi';
import { isAdContractorData } from '@/utils/home';

export const useDrivingDays = (params: DrivingDayParams) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;

  const isAdContractor = isAdContractorData({
    linkDriveStatus: selectedCar?.linkdrive_status,
  });

  const initialData = {
    day: [''],
    month: '',
  };

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchDriveDaysApi(params);
        if (res.data.data.length === 0) {
          setData([]);
          return;
        } else {
          let month: string = '';
          const dayArray = [''];
          for (const i in res.data.data) month = i;
          initialData.month = month.substring(4, 6);
          const receivedData = res.data.data as {
            [YYYYMM: string]: {
              t_linkdrive_driving_diagnosis_results: [
                {
                  YYYYMMDD: string;
                },
              ];
              t_driving_trip_logs: [
                {
                  YYYYMMDD: string;
                },
              ];
            };
          };
          receivedData[month].t_driving_trip_logs.map((date: any) =>
            dayArray.push(date.split('-')[2]),
          );
          const newDayArray = [];
          for (const day of dayArray) {
            if (day[0] == '0') newDayArray.push(day[1]);
            else newDayArray.push(day);
          }
          initialData.day = newDayArray;
          setData(initialData);
          return;
        }
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [params]);

  return useMemo(
    () => ({
      data,
      isLoading,
      error,
    }),
    [data, isLoading, error],
  );
};
