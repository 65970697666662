import { FC } from 'react';
import { getCustomer } from '../UpdateWarranty/UpdateWarrantyHelper';
import { ConnectorTerm } from './ConnectorTerm/ConnectorTerm';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';

export const ConnectorTermsPage: FC = () => {
  const { customerInfo, carItemInfo } = store.state;
  const customer = getCustomer(customerInfo, carItemInfo);
  return (
    <PageTemplate
      pageName="LinkDriveTerm"
      title="コネクター保証規定"
      showNavBack
    >
      <ConnectorTerm html={customer?.connectorTerm} />
    </PageTemplate>
  );
};
