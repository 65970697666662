import { FC } from 'react';
import './BrakesProcessStyle.scss';

interface BrakesProcessProps {
  data?: number;
  label?: string;
  progressWidth?: number;
}

export const BrakesProcess: FC<BrakesProcessProps> = ({
  data = 0,
  label = '',
  progressWidth = 0,
}) => {
  return (
    <div className="brakes-data-card_thisWeek u-padding-top-xs u-padding-bottom-xs">
      <div style={{ paddingTop: '3px', paddingRight: '5px' }}>{label}</div>
      <div className="brakes-data-card_thisWeek_bar">
        <span style={{ width: `${progressWidth}%` }} />
      </div>
      <div className="brakes-data-card_thisWeek_data">
        <span className="u-font-bold u-font-size-xl">{data ?? '-'}</span>
        <span> 回 / 運転</span>
      </div>
    </div>
  );
};
