import { AxiosResponse } from 'axios';
import { f7 } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SuccessPopup } from 'src/components/pages/CarRepair/Partials';
import styles from './CarRepairConfirmation.module.scss';
import { Button } from '@/components/uiParts/Button';
import { store } from '@/config/store';
import {
  CanDriveType,
  CheckExpectedAt,
  IdentificationSubscriber,
} from '@/consts/carRepair';
import { CarRepairFormData } from '@/hooks/api/carRepair/useCarRepair';
import {
  Defect,
  DefectOccurrenceFrequency,
  RepairRequestResponse,
} from '@/types/api/carRepairApi';
import { formatDateWithDay } from '@/utils/date';

interface CarRepairConfirmationProp {
  getCanDriveType: () =>
    | {
        value: CanDriveType;
        text: string;
      }
    | undefined;
  getDefectSelectees: () => Defect[];
  getDefectOccurrenceFrequencySelected: () =>
    | DefectOccurrenceFrequency
    | undefined;
  getCheckExpectedAt: () =>
    | {
        value: CheckExpectedAt;
        text: string;
      }
    | undefined;
  getIdentificationSubscriber: () =>
    | {
        value: IdentificationSubscriber;
        text: string;
      }
    | undefined;
  sendCarRepairRequest: () => Promise<AxiosResponse<RepairRequestResponse>>;

  afterSubmit: () => void;
  backStepOne?: () => void;
}

export const CarRepairConfirmation: FC<CarRepairConfirmationProp> = ({
  getCanDriveType,
  getDefectSelectees,
  getDefectOccurrenceFrequencySelected,
  getCheckExpectedAt,
  getIdentificationSubscriber,
  sendCarRepairRequest,
  afterSubmit,
  backStepOne,
}) => {
  const { hiragana, registry_no, car_nm } = store.state.carItemInfo;

  const { getValues } = useFormContext<CarRepairFormData>();
  const [isOpenSuccessPopup, setIsOpenSuccessPopup] = useState(false);

  const {
    occur_date,
    symptom_etc,
    expected_nyuko_date1,
    expected_nyuko_time1,
    expected_nyuko_date2,
    expected_nyuko_time2,
    expected_nyuko_date3,
    expected_nyuko_time3,
    contact_person_phone_number,
    contact_person_name,
    contact_person_name_kana,
  } = getValues();
  const canDriveType = getCanDriveType();
  const defectSelectees = getDefectSelectees();
  const defectOccurrenceFrequencySelected =
    getDefectOccurrenceFrequencySelected();
  const checkExpectedAt = getCheckExpectedAt();
  const identificationSubscriber = getIdentificationSubscriber();

  const toSendApiCreateRepairRequest = async () => {
    f7.preloader.show();
    let response;
    try {
      response = await sendCarRepairRequest();
    } finally {
      f7.preloader.hide();
    }

    if (response?.data?.success) {
      return setIsOpenSuccessPopup(true);
    }

    f7.dialog.alert(
      response?.data?.error_message
        ? response?.data?.error_message.split(',').join(' ')
        : '修理フォーム送信時のエラー',
      '送信リクエストの失敗',
      () => {},
    );
  };

  useEffect(() => {
    document.querySelector('.page-content > div > div')?.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles['container']}>
      <div>
        <h3 className={styles['heading']}>予約内容</h3>
        <p className={styles['heading-content']}>予約内容をご確認ください。</p>
      </div>
      <div className={styles['content-row']}>
        <label>対象車両</label>
        <div>
          {[hiragana, registry_no, car_nm].filter((value) => !!value).join(' ')}
        </div>
      </div>
      <div className={styles['content-row']}>
        <label>入庫区分</label>
        <div>{checkExpectedAt?.text}</div>
      </div>

      <div className={styles['content-group']}>
        <h3>お車の症状</h3>
        <div className={styles['content-row']}>
          <label>自走はできますか</label>
          <div>{canDriveType?.text}</div>
        </div>
        <div className={styles['content-row']}>
          <label>お車の不具合</label>
          <div>
            {defectSelectees.map((defect) => (
              <p key={`defect-${defect.id}`}>{defect.name_for_customer}</p>
            ))}
          </div>
        </div>
        <div className={styles['content-row']}>
          <label>発生頻度はどれくらいですか</label>
          <div>
            {defectOccurrenceFrequencySelected && (
              <p>{defectOccurrenceFrequencySelected.name}</p>
            )}
          </div>
        </div>
        <div className={styles['content-row']}>
          <label>症状に気づかれたのはいつですか</label>
          <div>{formatDateWithDay(occur_date as Date)}</div>
        </div>
        <div className={styles['content-row']}>
          <label>その他の不具合症状などご入力ください</label>
          <div>{symptom_etc}</div>
        </div>
      </div>

      {checkExpectedAt?.value != CheckExpectedAt.EXTERNAL_FACTORY && (
        <div className={styles['content-group']}>
          <h3>ご予約希望日時</h3>
          <div className={styles['content-row']}>
            <label>第一希望</label>
            <div>
              {`${formatDateWithDay(
                expected_nyuko_date1 as Date,
              )} ${expected_nyuko_time1}`}
            </div>
          </div>
          <div className={styles['content-row']}>
            <label>第二希望</label>
            <div>
              {expected_nyuko_date2 &&
                expected_nyuko_time2 &&
                `${formatDateWithDay(
                  expected_nyuko_date2 as Date,
                )} ${expected_nyuko_time2}`}
            </div>
          </div>
          <div className={styles['content-row']}>
            <label>第三希望</label>
            <div>
              {expected_nyuko_date3 &&
                expected_nyuko_time3 &&
                `${formatDateWithDay(
                  expected_nyuko_date3 as Date,
                )} ${expected_nyuko_time3}`}
            </div>
          </div>
        </div>
      )}

      <div className={styles['content-group']}>
        <h3>ご連絡先</h3>
        <div className={styles['content-row']}>
          <label>ご連絡先</label>
          <div>
            {contact_person_phone_number.first}-
            {contact_person_phone_number.second}-
            {contact_person_phone_number.third}
          </div>
        </div>
        <div className={styles['content-row']}>
          <label>ご連絡先電話番号と契約者様の関係</label>
          <div>{identificationSubscriber?.text}</div>
        </div>
        {identificationSubscriber?.value == IdentificationSubscriber.AGENT && (
          <>
            <div className={styles['content-row']}>
              <label>お名前</label>
              <div className={styles['content-name-text']}>
                <span>{`${contact_person_name.family_name}　${contact_person_name.first_name}`}</span>
              </div>
            </div>
            <div className={styles['content-row']}>
              <label>フリガナ</label>
              <div className={styles['content-name-text']}>
                <span>{`${contact_person_name_kana.family_name}　${contact_person_name_kana.first_name}`}</span>
              </div>
            </div>
          </>
        )}
      </div>

      <div className={styles['note-group']}>
        <h3>
          <i className="icon-exclamation-mark-red" />
          ご注意事項
        </h3>
        <p className={styles['black-bullet']}>
          保証のご利用につきましては必ずお見積もり書の確認及び審査を伴います。
        </p>
        <p className={styles['black-bullet']}>
          審査結果連絡を待たずに部品手配依頼や修理着工依頼をしてしまいますといかなる内容の場合も保証適用外となります。
        </p>
        <p className={styles['black-bullet']}>
          部品交換が必要な場合は、中古部品の使用が最優先となります。中古部品の調達ができない場合はリビルト部品・社外新品・純正新品の優先順位での対応となります。
        </p>
        <p className={styles['black-bullet']}>
          代車費用につきましては、保証サービスに含まれません。※一部プランは除く
        </p>
        <p className={styles['black-bullet']}>
          受付いただきました工場にて保証修理対応をいただけない場合がございます。その場合、工場の移動をお願いさせていただくことがございます。
        </p>
      </div>
      <Button
        type="button"
        className={styles['submit-button']}
        text="この内容で依頼する"
        onClick={() => toSendApiCreateRepairRequest()}
      />
      <Button
        type="button"
        className="u-margin-top-xs"
        text="予約内容を修正する"
        outline
        onClick={backStepOne}
      />
      <SuccessPopup
        isModalOpen={isOpenSuccessPopup}
        onPopupClose={() => {
          setIsOpenSuccessPopup(false);
          afterSubmit();
        }}
      />
    </div>
  );
};
