import { differenceInCalendarMonths, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { findNextBillDetail } from './bill';
import { formatYearMonthToJa } from './date';
import { isEmptyObject } from './utils';
import { prepaidFlg } from '@/consts/payment';
import {
  BillInformation,
  BillInformationDetail,
  Prepaid,
} from '@/types/api/customerApi';
import { PrepaidFlg } from '@/types/payment';

export type PrepaidSettingsBill = {
  hasBill: boolean;
  mPrepaidId: number | undefined;
  paymentTypeId: number;
  paymentTypeName: string;
  bankName: string;
  accountNumber: string;
  cardNo: string;
  nextBillDate: string;
  nextBillAmount: number;
  billMonth: string;
  currentBill: BillInformationDetail | undefined;
  nextBill: BillInformationDetail | undefined;
  prevBill: BillInformationDetail | undefined;
  billingMonthDeal: string | undefined;
};

export const useGetPrepaidData = (prepaidsInfos: Prepaid | undefined) => {
  let expire_date = {
    basic: '',
    bonus: '',
    coupon: '',
  };
  let limit = '';
  let isPrepaid = false;

  if (prepaidsInfos && prepaidsInfos.name && prepaidsInfos.name != '') {
    isPrepaid = true;
    if (prepaidsInfos.t_houseprepaid_payment_histories) {
      const paymentHistories =
        prepaidsInfos.t_houseprepaid_payment_histories.sort(
          (a: any, b: any) => {
            return new Date(a.deal_date) < new Date(b.deal_date) ? -1 : 1;
          },
        );
      const histories = paymentHistories.slice(-1)[0];
      expire_date.basic = histories.expire_date_basic ?? '9999/12/32';
      expire_date.bonus = histories.expire_date_bonus ?? '9999/12/32';
      expire_date.coupon = histories.expire_date_coupon ?? '9999/12/32';
      limit =
        [
          histories.expire_date_basic ?? '',
          histories.expire_date_bonus ?? '',
          histories.expire_date_coupon ?? '',
        ]
          ?.sort()
          ?.slice(-1)[0] ?? '';
    }
  }
  return useMemo(
    () => ({
      expire_date,
      limit,
      isPrepaid,
    }),
    [expire_date, isPrepaid, limit],
  );
};

export const getPrepaidSettingsBillInfo = (
  bills: BillInformation[] | undefined,
  prepaid: Prepaid | undefined,
) => {
  if (!bills || !bills.length || !prepaid) return;
  const bill =
    bills.find((bill) => bill.m_prepaid_id === prepaid.id) ||
    bills.slice(-1)[0];
  let billInfo: PrepaidSettingsBill | undefined = undefined;
  if (!!bill) {
    const { currentBill, nextBill, prevBill, closestMonthBill } =
      findNextBillDetail(bill, prepaidFlg.HOUSE_PREPAID_EXISTS);
    const billDetail = bill.bill_infomation_details?.slice(-1)[0];
    billInfo = {
      hasBill: !isEmptyObject(bill),
      mPrepaidId: bill?.m_prepaid_id,
      paymentTypeId: billDetail?.m_payment_type_id ?? 0,
      paymentTypeName: billDetail?.payment_type_name ?? '',
      bankName: billDetail?.bank_name ?? '',
      accountNumber: billDetail?.account_number ?? '',
      cardNo: billDetail?.card_no ?? '',
      nextBillDate: bill.next_bill_date ?? '',
      nextBillAmount: bill.next_bill_amount ?? 0,
      billMonth: `${formatYearMonthToJa(billDetail?.bill_month)}分の支払い`,
      currentBill: currentBill ?? closestMonthBill,
      nextBill: nextBill ?? closestMonthBill,
      prevBill,
      billingMonthDeal: prevBill?.bill_month,
    };
  }
  return billInfo;
};

export const getPrepaidChangeBillInfo = (
  prepaidsInfo: Required<Prepaid>,
  billsInfo: Required<BillInformation>[],
) => {
  let billInfomation;
  let billInfo;
  let name;
  let monthlyCharge;
  if (Object.keys(prepaidsInfo).length != 0) {
    name = prepaidsInfo.name;
    monthlyCharge = prepaidsInfo.monthly_charge;
    billInfomation = billsInfo.find(
      (x: any) => x.m_prepaid_id == prepaidsInfo.id,
    );
  } else {
    billInfomation = billsInfo.slice(-1)[0];
  }
  if (billInfomation && Object.keys(billInfomation).length != 0) {
    if (billInfomation.bill_infomation_details.length) {
      let billInfomationDetail =
        billInfomation.bill_infomation_details.slice(-1)[0];
      billInfo = {
        payment_type_id: billInfomationDetail.m_payment_type_id,
        payment_method_id: billInfomationDetail.m_payment_method_id,
      };
    }
  }
  return { billInfo, name, monthlyCharge };
};

export const getNextBillDetail = (
  billDetails: BillInformationDetail[],
  prepaidFlg: PrepaidFlg,
) => {
  const filteredBillDetails = billDetails.filter(
    (item: BillInformationDetail) => item.prepaid_flg === prepaidFlg,
  );
  const currentDate = new Date();
  const diffInMonthsIndex = 1;
  const nextDateIndexesByDiff = filteredBillDetails
    .map((item: BillInformationDetail, index: number) => {
      const billingDate = parseISO(item.billing_date || '');
      const diffInMonths = differenceInCalendarMonths(currentDate, billingDate);
      return [index, diffInMonths];
    })
    // diffInMonthsが0より大きい(請求日が過去のもの)場合はフィルターで消去する
    .filter((item) => item[diffInMonthsIndex] < 0)
    // 請求日が未来のもので日付が近い順に並び替える
    .sort((a, b) => {
      return b[diffInMonthsIndex] - a[diffInMonthsIndex];
    });
  if (nextDateIndexesByDiff.length > 0) {
    return billDetails[nextDateIndexesByDiff[0][0]];
  }
};
