import { Router } from 'framework7/types';
import { FC } from 'react';
import { Problem } from './Problem/Problem';
import { store } from '@/config/store';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';

type ProblemPageProps = {
  f7router: Router.Router;
  isFromContact: boolean;
};

export const ProblemPage: FC<ProblemPageProps> = ({
  f7router,
  isFromContact,
}) => {
  const customerInfo = store.state.customerInfo;
  const mAffiliateId = customerInfo.m_affiliate_id;
  const { data } = useMAffiliateSettings({ m_affiliate_id: mAffiliateId });
  const callCenterTel = data?.data.callcenter_tel ?? '';
  return (
    <Problem
      f7router={f7router}
      supportCenterTel={callCenterTel}
      isFromContact={isFromContact}
    />
  );
};
