import { FC } from 'react';
import { AboutContent } from './AboutContent';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';

type CarWashConditionerAboutPopupProps = {
  showLink?: boolean;
};

export const CarWashConditionerAboutPopup: FC<
  CarWashConditionerAboutPopupProps
> = ({ showLink = true }) => {
  return (
    <PopupPageTemplate
      id="CarWashConditionerAbout"
      title="出張カーエアコンクリーニング"
      pageName="CarWashConditionerAbout"
    >
      <AboutContent showLink={showLink} />
    </PopupPageTemplate>
  );
};
