import { Router } from 'framework7/types';
import { FC } from 'react';
import { NewsDetail } from './NewsDetail/NewsDetail';
import { Notification } from '@/types/api/customerApi';

type NewsDetailPageProps = {
  f7router?: Router.Router;
  item: Notification;
};

export const NewsDetailPage: FC<NewsDetailPageProps> = ({ f7router, item }) => {
  return <NewsDetail f7router={f7router} item={item} />;
};
