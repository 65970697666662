import { f7 } from 'framework7-react';
import { useCallback, useState } from 'react';
import { TradeGuaranteeFormInput } from '../TradeGuaranteeForm/TradeGuaranteeForm';
import {
  getUploadedWarrantyDocumentsApi,
  getWarrantyDocumentsApi,
  postDealWarrantyUpdateApi,
  postWarrantyDocumentsApi,
} from '@/api/warrantyApi';
import { store } from '@/config/store';
import { ISSUE_CATEGORIES, PROCESS_KB } from '@/consts/warranty';
import { getAndSetCustomerInfo } from '@/hooks/api/customer/useCustomerInfo';
import {
  Documents,
  GetDealWarrantyInfoApiResponse,
  WarrantyDocument,
} from '@/types/api/warrantyApi';
import { storeDispatch } from '@/utils/store';

export const TRADE_GUARANTEE_STEPS = {
  DETAIL_FORM: 1,
  UPLOAD_DOCUMENTS: 2,
  UPLOAD: 3,
  CONFIRMATION: 4,
};

export const useTradeGuarantee = (
  data?: GetDealWarrantyInfoApiResponse['data'],
) => {
  const [selectedDocument, setSelectedDocument] = useState<WarrantyDocument>();
  const [uploadedDocument, setUploadedDocument] = useState<
    Record<number, Documents>
  >([]);
  const [warrantyDocument, setWarrantyDocument] = useState<WarrantyDocument[]>(
    [],
  );
  const [detailData, setDetailData] = useState<TradeGuaranteeFormInput>({
    issues:
      data?.deal_warranties.map((deal) => ({
        issue_category: deal.issue_category,
        issue_detail: deal.issue_detail,
        issue_found_date: deal.issue_found_date,
      })) || [],
  });
  const [step, setStep] = useState(TRADE_GUARANTEE_STEPS.DETAIL_FORM);

  const fetchUser = async () => {
    const { currentWarranty, authInfo, carItemInfo } = store.state;
    if (currentWarranty) {
      const customer = await getAndSetCustomerInfo(
        {
          m_customer_id: authInfo.m_customer_id,
        },
        (car) => {
          if (carItemInfo) {
            return car.m_car_id == null
              ? car.t_order_id === carItemInfo.t_order_id
              : car.m_car_id === carItemInfo.m_car_id;
          }
          return true;
        },
      );
      customer.cars?.forEach((c) => {
        const warr = c.warranties?.find(
          (w) => w.t_base_waranty_id === currentWarranty.t_base_waranty_id,
        );
        console.log(warr);
        storeDispatch('setCurrentWarranty', 'currentWarranty', warr);
      });
    }
  };

  const fetchDocuments = useCallback(async (data: TradeGuaranteeFormInput) => {
    const docs: WarrantyDocument[] = [];
    const issues = Array.from(
      new Set(data.issues.map((issue) => issue.issue_category)),
    );

    for (const issue of issues) {
      const doc = await getWarrantyDocumentsApi({
        warranty_type:
          store.state.currentWarranty?.append_warranties?.[0].warranty_type ||
          0,
        issue_category: issue || 0,
        // warranty_type: 4,
        // issue_category: 0,
      });
      if (doc.data.success) {
        doc.data.documents.forEach((doc) => {
          if (!docs.find((d) => d.document_type === doc.document_type)) {
            docs.push(doc);
          }
        });
      }
    }
    setWarrantyDocument(docs);
  }, []);

  const handleSubmitStep1 = useCallback(
    (data: TradeGuaranteeFormInput) => {
      setStep(TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS);
      setDetailData(data);
      f7.preloader.show();
      const documentPromise = fetchDocuments(data);
      const uploadedPromise = getUploadedWarrantyDocumentsApi({
        m_customer_id: store.state.authInfo.m_customer_id,
        t_stock_car_id: store.state.carItemInfo.t_stock_car_id,
        warranty_no: store.state.currentWarranty?.warranty_no || '',
        branch_no: store.state.currentWarranty?.branch_no || 0,
      }).then((res) => {
        setUploadedDocument(res.data.documents);
      });

      Promise.allSettled([documentPromise, uploadedPromise]).then(() => {
        f7.preloader.hide();
      });
    },
    [fetchDocuments],
  );

  const handleSubmitStep2 = useCallback(() => {
    setStep(TRADE_GUARANTEE_STEPS.CONFIRMATION);
  }, []);

  const handleClickDocument = useCallback((document: WarrantyDocument) => {
    setStep(TRADE_GUARANTEE_STEPS.UPLOAD);
    setSelectedDocument(document);
  }, []);

  const handleUpload = useCallback(
    async (files: File[]) => {
      if (selectedDocument?.m_warranty_document_property_id == null) return;
      const res = await postWarrantyDocumentsApi({
        warranty_no: store.state.currentWarranty?.warranty_no || '',
        documents: files.map((file) => ({
          file,
          document_type: selectedDocument.document_type,
        })),
        uploaded_by: 2, // 提供元（1：販売店、2：顧客）
      });
      if (!res?.data?.success) {
        throw new Error();
      }
      await getUploadedWarrantyDocumentsApi({
        m_customer_id: store.state.authInfo.m_customer_id,
        t_stock_car_id: store.state.carItemInfo.t_stock_car_id,
        warranty_no: store.state.currentWarranty?.warranty_no || '',
        branch_no: store.state.currentWarranty?.branch_no || 0,
      }).then((res) => {
        setUploadedDocument(res.data.documents);
      });
    },
    [selectedDocument],
  );

  const handleBack = useCallback(() => {
    if (step > TRADE_GUARANTEE_STEPS.DETAIL_FORM) {
      if (step === TRADE_GUARANTEE_STEPS.CONFIRMATION) {
        setStep(TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS);
        return;
      }
      // eslint-disable-next-line no-magic-numbers
      setStep(step - 1);
      return;
    }
    f7.views.main.router.back();
  }, [step]);

  const handleSubmitStep3 = useCallback(async () => {
    const res = await postDealWarrantyUpdateApi({
      t_repair_recept_id: data?.repair_recept_id || 0,
      m_customer_id: store.state.authInfo.m_customer_id,
      process_kb: PROCESS_KB.RE_REGISTER_APPLICATION_INFORMATION,
      issues: detailData.issues.map((issue) => ({
        t_deal_warranty_id:
          data?.deal_warranties.find(
            (deal) => deal.issue_category === issue.issue_category,
          )?.deal_warranty_id || undefined,
        issue_category: issue.issue_category || ISSUE_CATEGORIES.OTHER,
        issue_detail: issue.issue_detail,
        issue_found_date: issue.issue_found_date,
      })),
      documents: [],
    });
    if (!res?.data?.success) {
      throw new Error();
    }
    await fetchUser();
  }, [data?.deal_warranties, data?.repair_recept_id, detailData.issues]);

  return {
    step,
    detailData,
    warrantyDocument,
    uploadedDocument,
    selectedDocument,
    setStep,
    handleUpload,
    handleBack,
    handleClickDocument,
    handleSubmitStep1,
    handleSubmitStep2,
    handleSubmitStep3,
  };
};
