import { FC } from 'react';
import style from './ApplicationUsingRulesStyle.module.css';

export const ApplicationUsingRules: FC = () => {
  return (
    <div className={style['container']}>
      <p>
        <span>
          当マイくるサービス（以下、「本サービス」といいます）は自動車販売店より業務委託されリバイス株式会社（以下、「当社」といいます。）が適切に運営しております。
        </span>
        <span>
          この利用規約（以下、「本規約」といいます。）は、当社がこのウェブサイト上で提供する本サービスの利用条件を定めるものです。
        </span>
        <span>
          本サービスを利用されるお客様（以下、「お客様」といいます。）は、本規約に従って、本サービスをご利用いただきます。本サービスをご利用になった場合、本規約の内容に同意したものとみなします。{' '}
        </span>
      </p>
    </div>
  );
};
