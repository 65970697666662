import { formatISO9075 } from 'date-fns';
import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { getValidWarranties } from '../WarrantyManagement/WarrantyManagementHelper';
import { TroubleShooting } from './TroubleShooting/TroubleShooting';
import { isApp } from '@/config/device';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { callCenterDivision } from '@/consts/callCenter';
import { termType } from '@/consts/terms';
import { url } from '@/consts/url';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';
import { useGps } from '@/hooks/api/other/useGps';
import { useTerm } from '@/hooks/api/term/useTerm';
import { Car } from '@/types/api/customerApi';
import { isDelivered } from '@/utils/car';
import { openAlertWarrantyExpried } from '@/utils/car';
import { storeDispatch } from '@/utils/store';
import { getRandomNumbericString } from '@/utils/utils';

type TroubleShootingPageProps = {
  f7router: Router.Router;
};

export const TroubleShootingPage: FC<TroubleShootingPageProps> = ({
  f7router,
}) => {
  const { customerInfo, authInfo, currentWarranty, warranties, term } =
    store.state;
  const mAffiliateId = customerInfo.m_affiliate_id;
  const phoneNumber = customerInfo.phone_no1 ?? customerInfo.phone_no2 ?? '';
  const { data } = useMAffiliateSettings({ m_affiliate_id: mAffiliateId });
  const { data: roadService } = useTerm(
    termType.ROAD_SERVICE,
    '',
    !!term.roadService,
  );
  const callCenterTel = data?.data.callcenter_tel;
  const { sendGps } = useGps(true, callCenterDivision.ROAD_SERVICE);
  const [validWarranties] = getValidWarranties(warranties);
  const [isOpenWarrantyRequired, setIsOpenWarrantyRequired] = useState(false);
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;

  const handleCallRoadService = () => {
    if (currentWarranty?.warranty_no && !validWarranties) {
      return openAlertWarrantyExpried();
    }
    if (!currentWarranty?.warranty_no) {
      return setIsOpenWarrantyRequired(true);
    }
    if (isApp) {
      window.location.href = `${url.NATIVE_BASE_URL}/request_location_info`;
    } else {
      window.location.href = 'tel://' + data?.data.callcenter_tel;
    }
  };

  // REVIEW: ロードサービスと電話番号同じでよいのか
  const handleCallSupportCenter = () => {
    window.location.href = 'tel://' + data?.data.callcenter_tel;
  };

  const handleClickProblem = () => {
    if (isDelivered(selectedCar.delivery_complete_flg)) {
      if (!selectedCar?.warranties?.[0]?.warranty_no) {
        return setIsOpenWarrantyRequired(true);
      }
      if (!validWarranties) {
        return openAlertWarrantyExpried();
      }
      return f7.view.main.router.navigate(paths.problem);
    }
    if (selectedCar?.warranties?.[0]?.warranty_no) {
      return openAlertWarrantyExpried();
    }
    setIsOpenWarrantyRequired(true);
  };

  const handleClickTrouble = () => {
    if (!currentWarranty?.warranty_no) {
      return setIsOpenWarrantyRequired(true);
    }
    f7.view.main.router.navigate(paths.trouble);
  };

  const handleClickAccident = () => {
    f7.view.main.router.navigate(paths.accident);
  };

  const sendGpsInfo = (
    longitude: number,
    latitude: number,
    gps_time: string,
  ) => {
    const nowTime = formatISO9075(new Date(), {
      format: 'basic',
    }).replace(' ', '');
    gps_time = nowTime; // Androidから連携されるlocation.timeはdatetime型ではないため、フロント側の時間を利用する
    const params = {
      m_customer_id: authInfo.m_customer_id,
      warranty_no: currentWarranty?.warranty_no ?? '',
      branch_no: currentWarranty?.branch_no ?? undefined,
      service_queue: getRandomNumbericString(7),
      tel_no: phoneNumber.replace(/-/g, ''),
      service_tel_no: callCenterTel?.replace(/-/g, '') ?? '',
      longitude,
      latitude,
      gps_time,
    };
    sendGps(params);
  };

  window['onGetGps'] = (
    longitude: number,
    latitude: number,
    gps_time: string,
  ) => {
    sendGpsInfo(longitude, latitude, gps_time);
  };

  // ネイティブアプリの位置情報を許可していない状態でロードサービスを押した場合に呼ばれる
  window['onRequestRoadServiceWithoutGps'] = () => {
    window.location.href = 'tel://' + data?.data.callcenter_tel;
  };

  useEffect(() => {
    if (roadService) {
      const newTerm = { ...term, roadService: roadService.term };
      storeDispatch('setTerm', 'term', newTerm);
    }
  }, [roadService, term]);

  return (
    <TroubleShooting
      f7router={f7.view.main.router}
      warranty={currentWarranty}
      isOpenWarrantyRequired={isOpenWarrantyRequired}
      setIsOpenWarrantyRequired={setIsOpenWarrantyRequired}
      handleCallRoadService={handleCallRoadService}
      handleClickAccident={handleClickAccident}
      handleCallSupportCenter={handleCallSupportCenter}
      handleClickTrouble={handleClickTrouble}
      handleClickProblem={handleClickProblem}
    />
  );
};
