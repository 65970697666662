import { IconProps } from './type';

export const CarRepairIcon = ({
  width = '56',
  height = '57',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28.5" r="28" fill={color} fillOpacity="0.1" />
      <path
        d="M13.0084 30.458C13.29 28.4874 14.981 27 16.9716 27H40.0024V39H13.5024C12.9502 39 12.5024 38.5523 12.5024 38V37.5534C12.5024 35.1874 12.6738 32.8002 13.0084 30.458Z"
        fill="#B1C7FC"
      />
      <path
        d="M18.3225 20.158C18.7177 18.3137 20.3475 16.9961 22.2337 16.9961H35V23.9961H17.5L18.3225 20.158Z"
        fill="#B1C7FC"
      />
      <path
        d="M40.0024 40.5H13.0024C11.8979 40.5 11.0024 39.6046 11.0024 38.5V37.8034C11.0024 35.4374 11.1704 33.0745 11.505 30.7323L11.6392 29.7929C11.9911 27.3296 14.1007 25.5 16.5889 25.5H28.7077"
        stroke="#323232"
        strokeLinecap="round"
      />
      <path
        d="M39.2526 25.4961H15.5024L16.6986 19.5155C17.166 17.1784 19.2181 15.4961 21.6015 15.4961H34.7526"
        stroke="#323232"
        strokeLinecap="round"
      />
      <path
        d="M20.9976 40.5039V42.5039C20.9976 43.6085 20.1021 44.5039 18.9976 44.5039H16.9976C15.893 44.5039 14.9976 43.6085 14.9976 42.5039V40.5039"
        stroke="#323232"
        strokeLinecap="round"
      />
      <circle cx="17.9976" cy="31.5" r="2.5" fill="white" stroke="#323232" />
      <path
        d="M11.5024 22.5039C11.5024 21.6755 12.174 21.0039 13.0024 21.0039H14.5628C15.5094 21.0039 16.2193 21.8699 16.0337 22.7981L15.5925 25.0039H13.0024C12.174 25.0039 11.5024 24.3323 11.5024 23.5039V22.5039Z"
        stroke="#323232"
      />
      <rect
        x="20.9976"
        y="34.9961"
        width="17"
        height="1"
        rx="0.5"
        fill="#323232"
      />
      <rect
        x="20.9976"
        y="37.0039"
        width="17"
        height="1"
        rx="0.5"
        fill="#323232"
      />
      <path
        d="M22.9976 32C22.9976 31.1716 23.6691 30.5 24.4976 30.5H34.4976C35.326 30.5 35.9976 31.1716 35.9976 32V33.5H22.9976V32Z"
        fill="white"
        stroke="#323232"
      />
      <mask id="path-12-inside-1_29664_120339" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.4387 19.48L41.4397 14.4513C44.0514 15.2685 45.9466 17.7069 45.9466 20.5879C45.9466 23.2572 44.3199 25.5465 42.0037 26.5185V42.505C42.0037 43.6095 41.1083 44.505 40.0037 44.505H39.0037C37.8992 44.505 37.0037 43.6095 37.0037 42.505V26.5067C34.7025 25.528 33.0889 23.2464 33.0889 20.5879C33.0889 17.6951 34.9995 15.2486 37.6277 14.4414L37.6267 19.4793L39.5323 21.3447L41.4387 19.48Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.4387 19.48L41.4397 14.4513C44.0514 15.2685 45.9466 17.7069 45.9466 20.5879C45.9466 23.2572 44.3199 25.5465 42.0037 26.5185V42.505C42.0037 43.6095 41.1083 44.505 40.0037 44.505H39.0037C37.8992 44.505 37.0037 43.6095 37.0037 42.505V26.5067C34.7025 25.528 33.0889 23.2464 33.0889 20.5879C33.0889 17.6951 34.9995 15.2486 37.6277 14.4414L37.6267 19.4793L39.5323 21.3447L41.4387 19.48Z"
        fill="white"
      />
      <path
        d="M41.4397 14.4513L41.7383 13.4969L40.44 13.0907L40.4397 14.4511L41.4397 14.4513ZM41.4387 19.48L42.138 20.1949L42.4386 19.9008L42.4387 19.4802L41.4387 19.48ZM42.0037 26.5185L41.6167 25.5964L41.0037 25.8537V26.5185H42.0037ZM37.0037 26.5067H38.0037V25.8453L37.3951 25.5864L37.0037 26.5067ZM37.6277 14.4414L38.6277 14.4416L38.6279 13.0881L37.3341 13.4855L37.6277 14.4414ZM37.6267 19.4793L36.6267 19.4791L36.6266 19.8997L36.9271 20.1939L37.6267 19.4793ZM39.5323 21.3447L38.8328 22.0593L39.5321 22.7438L40.2316 22.0596L39.5323 21.3447ZM40.4397 14.4511L40.4387 19.4798L42.4387 19.4802L42.4397 14.4515L40.4397 14.4511ZM46.9466 20.5879C46.9466 17.2572 44.7554 14.4409 41.7383 13.4969L41.1411 15.4057C43.3475 16.096 44.9466 18.1565 44.9466 20.5879H46.9466ZM42.3907 27.4406C45.0652 26.3182 46.9466 23.6739 46.9466 20.5879H44.9466C44.9466 22.8404 43.5746 24.7748 41.6167 25.5964L42.3907 27.4406ZM41.0037 26.5185V42.505H43.0037V26.5185H41.0037ZM41.0037 42.505C41.0037 43.0573 40.556 43.505 40.0037 43.505V45.505C41.6606 45.505 43.0037 44.1618 43.0037 42.505H41.0037ZM40.0037 43.505H39.0037V45.505H40.0037V43.505ZM39.0037 43.505C38.4514 43.505 38.0037 43.0573 38.0037 42.505H36.0037C36.0037 44.1618 37.3469 45.505 39.0037 45.505V43.505ZM38.0037 42.505V26.5067H36.0037V42.505H38.0037ZM32.0889 20.5879C32.0889 23.6615 33.9552 26.2968 36.6124 27.4269L37.3951 25.5864C35.4498 24.7591 34.0889 22.8313 34.0889 20.5879H32.0889ZM37.3341 13.4855C34.2979 14.418 32.0889 17.2437 32.0889 20.5879H34.0889C34.0889 18.1466 35.7011 16.0792 37.9213 15.3973L37.3341 13.4855ZM38.6267 19.4795L38.6277 14.4416L36.6277 14.4412L36.6267 19.4791L38.6267 19.4795ZM40.2319 20.6301L38.3262 18.7647L36.9271 20.1939L38.8328 22.0593L40.2319 20.6301ZM40.7395 18.7651L38.8331 20.6298L40.2316 22.0596L42.138 20.1949L40.7395 18.7651Z"
        fill="#323232"
        mask="url(#path-12-inside-1_29664_120339)"
      />
    </svg>
  );
};
