import { Link, Navbar, NavLeft, NavTitle, Page } from 'framework7-react';
import { FC, ReactNode } from 'react';
import style from './blankPageTemplateStyle.module.css';
import { store } from '@/config/store';
import { switchShopTheme } from '@/utils/shop';

interface BlankPageTemplateProps {
  children: ReactNode;
  pageName: string;
  title: string;
  showNavBack?: boolean;
  noScroll?: boolean;
  inlinePageStyle?: React.CSSProperties;
  handleClickBack?: () => void;
}
export const BlankPageTemplate: FC<BlankPageTemplateProps> = ({
  children,
  pageName,
  title,
  showNavBack = false,
  noScroll = false,
  inlinePageStyle,
  handleClickBack,
}) => {
  const themeId = store.state.themeId;
  const shopThemeObj = switchShopTheme(themeId);
  const pageStyle = { backgroundColor: shopThemeObj.mainColor };
  const containerBackground = {
    '--background-color': shopThemeObj.mainBgColor,
  } as React.CSSProperties;

  const getClassPage = () => {
    return `${style['page']}`;
  };

  const navbarsElement = document.getElementsByClassName('navbars')[0];
  if (navbarsElement) {
    navbarsElement.setAttribute(
      'style',
      `height: calc(var(--f7-navbar-height) + var(--f7-safe-area-top)); background-image: linear-gradient(-180deg, ${shopThemeObj.homeBorderColorFirst} 4px, ${shopThemeObj.homeBorderColorSecond} 4px); background-size: 100% 8px; background-repeat: no-repeat; background-position: 0 100%;`,
    );
  }

  return (
    <Page
      name={pageName}
      className={getClassPage()}
      style={{ ...pageStyle, ...inlinePageStyle }}
    >
      <Navbar noShadow noHairline>
        {showNavBack &&
          (!handleClickBack ? (
            <NavLeft>
              <Link back animate className={`${style['no-ripple']} no-ripple`}>
                <div className={style['icon-wrapper']}>
                  <i className={`${style['nav-icon']} icon-left no-ripple`} />
                </div>
              </Link>
            </NavLeft>
          ) : (
            <NavLeft>
              <Link
                onClick={handleClickBack}
                className={`${style['no-ripple']} no-ripple`}
              >
                <div className={style['icon-wrapper']}>
                  <i className={`${style['nav-icon']} icon-left no-ripple`} />
                </div>
              </Link>
            </NavLeft>
          ))}
        <NavTitle>{title}</NavTitle>
      </Navbar>
      <div style={containerBackground} className={style['container']}>
        <div
          className={`${style['content']} ${
            noScroll ? style['no-scroll'] : ''
          }`}
          id="page-template-content"
        >
          {children}
        </div>
      </div>
    </Page>
  );
};
