import { Block } from 'framework7-react';
import { FC } from 'react';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import './DeletePaymentDisableStyle.scss';

export const DeletePaymentDisable: FC = () => {
  return (
    <PopupPageTemplate
      pageName="DeletePaymentsDisabled"
      title="お支払い方法の削除"
    >
      <Block style={{ marginTop: 30 }}>
        <div className="deletePaymentsDisabled_wraper">
          <div className="u-font-bold">
            お支払いにご利用中のものは
            <br />
            削除できません
          </div>
          <div>
            別のお支払い方法に変更してから、
            <br />
            削除してください
          </div>
        </div>

        <div className="info-card info-card-elevated u-margin-bottom-md billing-content">
          <div className="content">
            <div className="content-inner">
              <span className="u-font-bold">
                <i className="icon-bulb building-icon" />
              </span>
              <span>支払い方法の変更方法</span>
            </div>
            <div className="contetn-list">
              <ul>
                <li className="list-item">
                  該当のお支払い方法の欄に書かれた、ご利用中のサービスをご確認ください
                </li>
                <li className="list-item">
                  ご利用中のサービスを開き、決済方法欄から「決済方法を変更」を選択してください
                </li>
                <li className="list-item">
                  別の決済方法を選択するか、新しく決済方法を追加してください
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Block>
    </PopupPageTemplate>
  );
};
