import { Checkbox as F7Checkbox } from 'framework7-react';
import { CheckboxProps as F7CheckboxProps } from 'framework7-react/components/checkbox';
import { ChangeEventHandler, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './PreStartConfirmation.module.css';

interface CheckboxProps extends F7CheckboxProps {
  name: string;
  isBoolean?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  name,
  children,
  value,
  className,
  isBoolean = false,
  ...checkboxProps
}) => {
  const { watch, trigger, setValue } = useFormContext();
  const formValue = watch(name);
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (isBoolean) {
      setValue(name, !formValue);
    } else if (formValue === value) {
      setValue(name, undefined);
    } else {
      setValue(name, value);
    }
    trigger(name);
  };
  return (
    <label
      className={`${style['input-checkbox']} ${className ? className : ''}`}
    >
      <F7Checkbox
        {...checkboxProps}
        checked={formValue === value}
        onChange={onChange}
      />
      {children}
    </label>
  );
};
