export enum CarRepairStep {
  // Step 1: If you select whether or not you can drive on your own
  ENTER_CONDITION = 'condition',
  // Step 1: Contact road service directly
  CONTACT_ROAD_SERVICE = 'contactRoadService',

  // Step 2: After enter condition to enter repair request to store
  BRING_CAR_TO_STORE = 'bringCarToStore',

  // Step 3: Confirmation screen
  CONFIRMATION = 'confirmation',
}

export enum CanDriveType {
  CAN = 'can',
  CANNOT = 'cannot',
  UNKNOWN = 'unknown',
}

export const CAN_DRIVE_TYPES = [
  { value: CanDriveType.CAN, text: 'できる' },
  { value: CanDriveType.CANNOT, text: 'できない' },
  { value: CanDriveType.UNKNOWN, text: '分からない' },
];

export const DEFECT_CATEGORIES = [
  { id: 1, name: 'お車の状態異常' },
  { id: 2, name: '走行に関する異常' },
  { id: 3, name: '感覚的な異常' },
  { id: 4, name: '装備品の異常' },
  { id: 5, name: '外部要因による異常' },
  { id: 6, name: 'その他影響による異常' },
];

export enum CheckExpectedAt {
  AUTHORIZED_DEALER = 'authorizedDealer',
  EXTERNAL_FACTORY = 'externalFactory',
  BOTH_ARE_FINE = 'bothAreFine',
}
export const CHECK_EXPECTED_ATS = [
  {
    value: CheckExpectedAt.AUTHORIZED_DEALER,
    text: '車を購入した販売店',
  },
  { value: CheckExpectedAt.EXTERNAL_FACTORY, text: '近隣の工場などに入庫する' },
  { value: CheckExpectedAt.BOTH_ARE_FINE, text: 'どちらでも良い' },
];

export const MAP_CHECK_EXPECTED_AT_NUMBER = {
  [CheckExpectedAt.AUTHORIZED_DEALER]: 1,
  [CheckExpectedAt.EXTERNAL_FACTORY]: 2,
  [CheckExpectedAt.BOTH_ARE_FINE]: undefined,
};

// eslint-disable-next-line no-magic-numbers
export const DEFAULT_WORKING_TIME = [11, 14];

export enum IdentificationSubscriber {
  MYSELF = 'myself',
  AGENT = 'agent',
}
export const IDENTIFICATION_SUBSCRIBERS = [
  { value: IdentificationSubscriber.MYSELF, text: '本人' },
  { value: IdentificationSubscriber.AGENT, text: '代理人' },
];
export const MAP_IDENTIFICATION_SUBSCRIBER_NUMBER = {
  [IdentificationSubscriber.MYSELF]: 1,
  [IdentificationSubscriber.AGENT]: 2,
};

export const MIN_REPAIR_AFTER_CURRENT_DAY = 1;

export const TIME_PICKER_RANGER_OPTIONS = [
  { value: '9:00～11:00', label: '9:00～11:00' },
  { value: '11:00～14:00', label: '11:00～14:00' },
  { value: '14:00～17:00', label: '14:00～17:00' },
];

export const APPROVAL_FLG = {
  APPLICABLE: 1,
  NOT_APPLICABLE: 2,
};
