import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { useScanResult } from './api/linkDrive/useScanResult';
import { store } from '@/config/store';
import { ScanResultApiResponse } from '@/types/api/linkDriveApi';
import { storeDispatch } from '@/utils/store';

type ScanResults = ScanResultApiResponse['data']['t_linkdrive_scan_results'];
type ScanResultType = [number, ScanResults][];
interface Func {
  updateScanResult: () => void;
}

export const useScanHistory = (): [ScanResultType | undefined, Func] => {
  const ownerId = store.state.linkDriveOwnerInfo.owner_id;
  const scanResultApiParams = {
    owner_id: ownerId,
  };

  const [yearlyScanHistories, setYearlyScanHistories] =
    useState<ScanResultType>();
  const { fetchScanResult } = useScanResult();
  const updateScanResult = () => {
    f7.preloader.show();
    fetchScanResult({
      params: scanResultApiParams,
      resolve: ({ t_linkdrive_scan_results: scanResults }) => {
        storeDispatch('setScanResults', 'scanResults', scanResults || []);
        const result = groupBy(scanResults, (scanResult) =>
          new Date(scanResult.diagnosed_at).getFullYear(),
        );
        setYearlyScanHistories(result);
      },
    }).then(() => f7.preloader.hide());
  };

  // 第二引数の結果をもとに配列をグルーピングする
  const groupBy = <K, V>(
    array: readonly V[],
    getKey: (curr: V, idx: number) => K,
  ): [K, V[]][] =>
    // Array.fromでMapを配列に変換
    Array.from(
      array.reduce((map, curr, idx) => {
        const key = getKey(curr, idx);
        const list = map.get(key);
        if (list) list.push(curr);
        else map.set(key, [curr]);
        return map;
      }, new Map<K, V[]>()),
    );

  // TODO: テストアカウントが用意され次第動作確認
  useEffect(() => {
    updateScanResult();
  }, []);

  return [
    yearlyScanHistories,
    {
      updateScanResult,
    },
  ];
};
