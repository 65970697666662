import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { useCarChange } from './useCarChange';
import { stopUpdateNextYear } from '@/api/warrantyApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import { StopUpdateNextYearParams } from '@/types/api/warrantyApi';

export const useStopUpdateNextYearWarranty = () => {
  const { openToast } = useToast();
  const { changeCarStore } = useCarChange();

  const [error, setError] = useState<AxiosError>();
  const stopUpdateNextYearWarranty = async (
    params: StopUpdateNextYearParams,
    warrantyNo: string,
  ) => {
    try {
      f7.preloader.show();
      f7.dialog.close('#CancelWarranty');
      const res = await stopUpdateNextYear(params);
      if (res.data.success) {
        await changeCarStore({
          m_customer_id: store.state.authInfo.m_customer_id,
        });
        openToast('保証解約手続きを完了しました', 'toast-success', 3000);
        f7.view.main.router.navigate(paths.warranty('update_success'));
      } else {
        f7.dialog.alert(
          res.data.error.code + ':' + res.data.error.message,
          '  エラー',
        );
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    stopUpdateNextYearWarranty,
    error,
  };
};
