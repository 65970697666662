import { Button, Swiper, SwiperSlide, f7 } from 'framework7-react';
import { FC, ReactNode, useState } from 'react';
import style from './CarWashHandAboutPageStyle.module.css';
import { ReactComponent as IconCarWashHandCard } from '@/assets/images/carwash/carwash_hand_about.svg';
import CarWashHandCmp1 from '@/assets/images/carwash/carwash_hand_cmp_1.png';
import CarWashHandCmp2 from '@/assets/images/carwash/carwash_hand_cmp_2.png';
import CarWashHandCmp3 from '@/assets/images/carwash/carwash_hand_cmp_3.png';
import CarWashHandCmp4 from '@/assets/images/carwash/carwash_hand_cmp_4.png';
import CarWashHandCmp5 from '@/assets/images/carwash/carwash_hand_cmp_5.png';
import CarWashHandCmp6 from '@/assets/images/carwash/carwash_hand_cmp_6.png';
import CarWashHandCmp7 from '@/assets/images/carwash/carwash_hand_cmp_7.png';
import CarWashHandCmp8 from '@/assets/images/carwash/carwash_hand_cmp_8.png';
import { ReactComponent as IconCarWashHandPoint1 } from '@/assets/images/carwash/carwash_hand_point_1.svg';
import { ReactComponent as IconCarWashHandPoint2 } from '@/assets/images/carwash/carwash_hand_point_2.svg';
import { ReactComponent as IconCarWashHandPoint3 } from '@/assets/images/carwash/carwash_hand_point_3.svg';
import { ReactComponent as IconCarWashHandPoint4 } from '@/assets/images/carwash/carwash_hand_point_4.svg';
import CarWashHandStep1 from '@/assets/images/carwash/carwash_hand_step_1.svg';
import CarWashHandStep2 from '@/assets/images/carwash/carwash_hand_step_2.svg';
import CarWashHandStep3 from '@/assets/images/carwash/carwash_hand_step_3.svg';
import CarWashHandStep4 from '@/assets/images/carwash/carwash_hand_step_4.svg';
import { paths } from '@/config/paths';
import { usePromote } from '@/hooks/usePromote';
import { f7CustomBack } from '@/utils/utils';

type AboutContentProps = {
  showLink?: boolean;
};

export const AboutContent: FC<AboutContentProps> = ({ showLink = true }) => {
  const handleClick = () => {
    const carwashIndex = f7.views.main.router.history.findIndex(
      (value) => value === paths.carWashHand,
    );
    if (
      carwashIndex !== -1 &&
      carwashIndex === f7.views.main.router.history.length - 2
    ) {
      f7CustomBack();
    } else {
      f7.views.main.router.navigate(paths.carWashHand);
    }
  };
  const { isShowPromote } = usePromote();
  return (
    <div className={style['container']}>
      <div className={style['heading-card']}>
        <div className={style['heading-card-content']}>
          <h4>出張水なし手洗い洗車</h4>
          <p>大切なお車キレイにさせてください</p>
        </div>
        <IconCarWashHandCard className={style['heading-card-image']} />
      </div>
      <div className={`${style['section']} ${style['has-bottom-sign']}`}>
        <svg
          className={style['bottom-sign']}
          xmlns="http://www.w3.org/2000/svg"
          width="47"
          height="21"
          viewBox="0 0 47 21"
          fill="none"
        >
          <path d="M0 0H47L24.675 21L0 0Z" fill="white" />
        </svg>
        <div className={style['section-head']}>
          <h4>ABOUT</h4>
          <p>出張水なし手洗い洗車とは</p>
        </div>
        <p className={`${style['paragraph']} ${style['text-center']}`}>
          {
            '水を使用しない洗車という画期的な洗車方法で、\n場所を選ばずどこでも洗車が可能。\n駐車場に停めたその場で洗車が完了する\n次世代の洗車サービスです。'
          }
        </p>
      </div>
      <div className={`${style['section']} ${style['bg-sub']}`}>
        <div className={style['section-head']}>
          <h4>POINT</h4>
          <p>出張水なし手洗い洗車の4つのポイント</p>
        </div>
        <div className={style['section-point-item']}>
          <div
            className={`${style['section-point-icon']} ${style['text-center']}`}
          >
            <IconCarWashHandPoint1 />
          </div>
          <h4>場所を選ばず、どこでも洗車</h4>
          <p className={style['paragraph']}>
            水を使わないので電源や水道の確保が不要。ご自宅やマンションの駐車場、職場の駐車場など場所を選ばずどこででも洗車可能です。
          </p>
          <span>※状況に応じて敷地所有者の許可をご確認ください。</span>
        </div>
        <div className={style['section-point-item']}>
          <div
            className={`${style['section-point-icon']} ${style['text-center']}`}
          >
            <IconCarWashHandPoint2 />
          </div>
          <h4>待ち時間ゼロ。いつでもアプリから予約</h4>
          <p className={style['paragraph']}>
            アプリからいつでも予約可能。洗車作業中は作業に立ち会う必要も、終了までお待ち頂く必要もありません。
          </p>
        </div>
        <div className={style['section-point-item']}>
          <div
            className={`${style['section-point-icon']} ${style['text-center']}`}
          >
            <IconCarWashHandPoint3 />
          </div>
          <h4>{'洗車からWAXまで\n20〜40分スピード洗車！'}</h4>
          <p className={style['paragraph']}>
            水を使用しないため、洗車手順の短縮が可能。ボディーの洗浄・WAX、ホイール・タイヤの洗浄まで20〜40分で完了します。
          </p>
          <span>
            ※洗車時間に関しては、車輛状態やサイズにより多少前後する　場合もございます。
          </span>
        </div>
        <div className={style['section-point-item']}>
          <div
            className={`${style['section-point-icon']} ${style['text-center']}`}
          >
            <IconCarWashHandPoint4 />
          </div>
          <h4>キズの心配がいらない独自の技術力</h4>
          <p className={style['paragraph']}>
            ボディー表面を保護し、汚れと表面の摩擦が起こらないように設計された特殊液剤を使用しています。細心の注意を払いながら行う洗車ですので、水洗い洗車と同等レベルの洗車クオリティを実現しています。
          </p>
        </div>
      </div>
      <div className={style['section']}>
        <div className={style['section-head']}>
          <h4>BEFORE & AFTER</h4>
          <p>出張水なし手洗い洗車の効果</p>
        </div>
        <div className={style['section-compare-item']}>
          <p>車両全体</p>
          <div>
            <div className={style['badge-left']}>
              <p>Before</p>
            </div>
            <div className={style['badge-right']}>
              <p>After</p>
            </div>
            <div>
              <img src={CarWashHandCmp1} />
            </div>
            <div>
              <img src={CarWashHandCmp2} />
            </div>
          </div>
        </div>
        <div className={style['section-compare-item']}>
          <p>くすんだボディも復活</p>
          <div>
            <div className={style['badge-left']}>
              <p>Before</p>
            </div>
            <div className={style['badge-right']}>
              <p>After</p>
            </div>
            <div>
              <img src={CarWashHandCmp3} />
            </div>
            <div>
              <img src={CarWashHandCmp4} />
            </div>
          </div>
        </div>
        <div className={style['section-compare-item']}>
          <p>ホイール光沢も新品のように</p>
          <div>
            <div className={style['badge-left']}>
              <p>Before</p>
            </div>
            <div className={style['badge-right']}>
              <p>After</p>
            </div>
            <div>
              <img src={CarWashHandCmp5} />
            </div>
            <div>
              <img src={CarWashHandCmp6} />
            </div>
          </div>
        </div>
        <div className={style['section-compare-item']}>
          <p>雨の斑点模様もこの通り</p>
          <div>
            <div className={style['badge-left']}>
              <p>Before</p>
            </div>
            <div className={style['badge-right']}>
              <p>After</p>
            </div>
            <div>
              <img src={CarWashHandCmp7} />
            </div>
            <div>
              <img src={CarWashHandCmp8} />
            </div>
          </div>
        </div>
      </div>
      <div className={`${style['section']} ${style['bg-sub']}`}>
        <div className={style['section-head']}>
          <h4>HOW TO USE</h4>
          <p>ご利用の流れ</p>
        </div>

        <Swiper
          className={style['section-compare-swiper']}
          pagination
          navigation
          space-between="50"
        >
          <SwiperSlide>
            <div className={style['section-compare-swiper-item']}>
              <div className={style['section-swiper-step']}>
                <p>STEP</p>
                <span>01</span>
              </div>
              <img src={CarWashHandStep1} />
              <div className={style['section-swiper-content']}>
                <h4>予約申請、承認</h4>
                <p>
                  マイくるアプリで予約申請します。担当者が予約を承認すると予約が確定。
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style['section-compare-swiper-item']}>
              <div className={style['section-swiper-step']}>
                <p>STEP</p>
                <span>02</span>
              </div>
              <img src={CarWashHandStep2} />
              <div className={style['section-swiper-content']}>
                <h4>お車預かり</h4>
                <p>当日、指定した駐車場、もしくはご自宅に伺います。</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style['section-compare-swiper-item']}>
              <div className={style['section-swiper-step']}>
                <p>STEP</p>
                <span>03</span>
              </div>
              <img src={CarWashHandStep3} />
              <div className={style['section-swiper-content']}>
                <h4>洗車開始</h4>
                <p>
                  お車の汚れの状態・キズ等の確認をして洗車を開始します。(20〜40分ほどで完了)
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style['section-compare-swiper-item']}>
              <div className={style['section-swiper-step']}>
                <p>STEP</p>
                <span>04</span>
              </div>
              <img src={CarWashHandStep4} />
              <div className={style['section-swiper-content']}>
                <h4>お車お渡し・完了</h4>
                <p>洗車完了後、お車（鍵）をお渡しいたします。</p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        className={`${style['section']} ${
          isShowPromote ? style['in-promote'] : ''
        }`}
      >
        <div className={style['section-head']}>
          <h4>PRICE</h4>
          <p>価格</p>
        </div>
        <p className={style['section-price']}>
          スタンダードとプレミアムプランを用意しています。
        </p>
        <div
          className={`${style['section-price-item']}  ${style['theme-blue-light']}`}
        >
          <div className={style['section-price-item-ribbon']}>
            <div className={style['price-ribbon-content']}>
              <p className={style['price-ribbon-label']}>今だけ</p>
              <p className={style['price-ribbon-price']}>
                1,000
                <span className={style['price-ribbon-currency']}>円</span>
              </p>
              <p>OFF</p>
            </div>
          </div>
          <div className={style['section-price-item-head']}>
            <svg
              className={style['section-price-item-head-sign']}
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="13"
              viewBox="0 0 29 13"
              fill="none"
            >
              <path d="M0 0H29L14.5 13L0 0Z" fill="#5D89CA" />
            </svg>
            <p>まずはお気軽に</p>
            <h4>スタンダードコース</h4>
          </div>
          <div className={style['section-price-item-content']}>
            <p className={style['paragraph']}>
              {
                '初めてご利用の方や汚れの少ないお車をお持ちの方におすすめとなる基本のコースです。\n作業時間はおよそ20〜40分です。'
              }
            </p>
            <div className={style['section-price-list-group']}>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>Sサイズ</h4>
                  <p>軽自動車</p>
                </div>
                <div className={style['section-price-list-right']}>
                  <p>通常価格</p>
                  <h4>
                    3,700
                    <span>円</span>
                  </h4>
                  <h3>
                    2,700
                    <span>円</span>
                  </h3>
                </div>
              </div>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>Mサイズ</h4>
                  <p>普通車/車高1650mm未満</p>
                </div>
                <div className={style['section-price-list-right']}>
                  <p>通常価格</p>
                  <h4>
                    4,700
                    <span>円</span>
                  </h4>
                  <h3>
                    3,700
                    <span>円</span>
                  </h3>
                </div>
              </div>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>Lサイズ</h4>
                  <p>普通車/車高1650mm以上</p>
                </div>
                <div className={style['section-price-list-right']}>
                  <p>通常価格</p>
                  <h4>
                    5,700
                    <span>円</span>
                  </h4>
                  <h3>
                    4,700
                    <span>円</span>
                  </h3>
                </div>
              </div>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>2Lサイズ</h4>
                  <p>ワンボックス/ミニバン</p>
                </div>
                <div className={style['section-price-list-right']}>
                  <p>通常価格</p>
                  <h4>
                    6,200
                    <span>円</span>
                  </h4>
                  <h3>
                    5,200
                    <span>円</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className={style['section-price-item-note']}>※税込価格</div>
          </div>
        </div>
        <div
          className={`${style['section-price-item']}  ${style['theme-blue-dark']}`}
        >
          <div className={style['section-price-item-ribbon']}>
            <div className={style['price-ribbon-content']}>
              <p className={style['price-ribbon-label']}>今だけ</p>
              <p className={style['price-ribbon-price']}>
                1,000
                <span className={style['price-ribbon-currency']}>円</span>
              </p>
              <p>OFF</p>
            </div>
          </div>
          <div className={style['section-price-item-head']}>
            <svg
              className={style['section-price-item-head-sign']}
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="13"
              viewBox="0 0 29 13"
              fill="none"
            >
              <path d="M0 0H29L14.5 13L0 0Z" fill="#56659C" />
            </svg>
            <p>強力な汚れに</p>
            <h4>プレミアムコース</h4>
          </div>
          <div className={style['section-price-item-content']}>
            <p className={style['paragraph']}>
              {
                '社用車、外国車、濃色車などはプレミアムコースがおススメです。また泥汚れ、鳥のフン、雨染み等の比較的汚れやダメージが多い車両にも適しています。レギュラーコースとは異なる特殊液剤を使用して、さらに強力に汚れを除去します。\n作業時間はおよそ40〜60分です。'
              }
            </p>
            <div className={style['section-price-list-group']}>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>Sサイズ</h4>
                  <p>軽自動車</p>
                </div>
                <div className={style['section-price-list-right']}>
                  <p>通常価格</p>
                  <h4>
                    6,200
                    <span>円</span>
                  </h4>
                  <h3>
                    5,200
                    <span>円</span>
                  </h3>
                </div>
              </div>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>Mサイズ</h4>
                  <p>普通車/車高1650mm未満</p>
                </div>
                <div className={style['section-price-list-right']}>
                  <p>通常価格</p>
                  <h4>
                    6,500
                    <span>円</span>
                  </h4>
                  <h3>
                    5,500
                    <span>円</span>
                  </h3>
                </div>
              </div>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>Lサイズ</h4>
                  <p>普通車/車高1650mm以上</p>
                </div>
                <div className={style['section-price-list-right']}>
                  <p>通常価格</p>
                  <h4>
                    7,200
                    <span>円</span>
                  </h4>
                  <h3>
                    6,200
                    <span>円</span>
                  </h3>
                </div>
              </div>
              <div className={style['section-price-list']}>
                <div className={style['section-price-list-left']}>
                  <h4>2Lサイズ</h4>
                  <p>ワンボックス/ミニバン</p>
                </div>
                <div className={style['section-price-list-right']}>
                  <p>通常価格</p>
                  <h4>
                    8,200
                    <span>円</span>
                  </h4>
                  <h3>
                    7,200
                    <span>円</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className={style['section-price-item-note']}>※税込価格</div>
          </div>
        </div>
      </div>
      {/* <div className={style['section']}>
          <div className={style['section-head']}>
            <h4>FAQ</h4>
            <p>よくあるご質問</p>
          </div>
          <Accordion title="「水なし洗車」は本当に水は使用しないの？" />
          <Accordion title="作業の流れを教えてください。">
            「水なし洗車」の場合以下の流れとなります。1.作業前にお車の汚れの状態やキズ等の確認をさせていただきます。2.専用液剤散布3.汚れ除去4.磨き上げ（光沢仕上げ）2～4の3ステップをお車の部分ごとに繰り返していきます。
            この作業で洗車とワックスがけが完了します。
          </Accordion>
          <Accordion title="1台あたりの作業時間は？" />
          <Accordion title="ワックスの効果はどれくらい持続しますか？" />
          <Accordion title="ボディーコーティングしている車に使用しても大丈夫？" />
          <Accordion title="作業当日の立ち会いはいらないの？" />
          <Accordion title="料金の支払いはいつすれば良いの？" />
          <Accordion title="前日の予報や当日の天気が雨だった場合はどうするの？" />
          <Accordion title="予約後のキャンセルはキャンセル料が発生するの？" />
          <Accordion title="作業スタッフの指名はできるの？" />
        </div> */}
      {showLink && (
        <div className={style['section']}>
          <Button
            fill
            round
            style={{ background: '#2FA69F' }}
            onClick={handleClick}
          >
            出張水なし手洗い洗車を予約する
          </Button>
        </div>
      )}
    </div>
  );
};

type AccordionProps = {
  title: string;
  children?: ReactNode;
};

const Accordion: FC<AccordionProps> = ({ title, children }) => {
  const [opened, setOpened] = useState(false);
  return (
    <div className={style['section-faq']}>
      <div className={style['section-faq-q']}>
        <h4>Q</h4>
        <h5>{title}</h5>
        <div onClick={() => setOpened(!opened)}>
          {!opened ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 9V14H9V9H14V7H9V2H7V7H2V9H7Z"
                fill="#CCCCCC"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path d="M2 7H14V9H2V7Z" fill="#CCCCCC" />
            </svg>
          )}
        </div>
      </div>
      {opened && (
        <div className={style['section-faq-a']}>
          <h4>A</h4>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};
