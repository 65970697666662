import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchBankList } from '@/api/prepaidApi';
import { BankListResponse } from '@/types/api/prepaidApi';

export const useBankList = () => {
  const [data, setData] = useState<BankListResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchBankList();
        setData(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, []);

  return {
    data,
    isLoading,
    error,
  };
};
