import { Popup } from 'framework7-react';
import { FC } from 'react';
import style from './PopupConfirm.module.css';
import { Button } from '@/components/uiParts/Button';

interface PopupConfirmProps {
  opened: boolean;
  handleClose: () => void;
  title: string;
  content?: string;
  handleSubmit: () => void;
  textButtonSubmit: string;
}

export const PopupConfirm: FC<PopupConfirmProps> = ({
  opened,
  handleClose,
  title,
  content,
  handleSubmit,
  textButtonSubmit,
}) => {
  return (
    <Popup
      className={style['popup-confirm']}
      opened={opened}
      onPopupClosed={handleClose}
    >
      <h3 className={style['title']}>{title}</h3>
      {content && <p className={style['content']}>{content}</p>}
      <Button onClick={handleSubmit}>{textButtonSubmit}</Button>
    </Popup>
  );
};
