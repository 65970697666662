import { FC } from 'react';
import styles from './WarrantyDuplicated.module.css';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';

type WarrantyDuplicatedProps = {
  handleClickPopupClose: () => void;
  handleClickContact: () => void;
};

export const WarrantyDuplicated: FC<WarrantyDuplicatedProps> = ({
  handleClickPopupClose,
  handleClickContact,
}) => {
  return (
    <PopupPageTemplate
      id="WarrantyDuplicated"
      pageName="WarrantyDuplicated"
      title=""
      handleBack={handleClickPopupClose}
    >
      <div className={styles['card-header']}>
        <i className="icon icon-exclamationmark_circle" />
        <p className={styles['card-header-title']}>
          保証期間が重複しています。
        </p>
      </div>
      <div className={styles['card-body']}>
        <p>保証期間が重複しています。カスタマーセンターにご相談ください。</p>
        <br />
        <br />
        <p>カスタマーセンターに電話でご相談</p>
        <br />
        {/* <div className={styles['contact-info']}>
          <div className={styles['contact-avatar']} />
          <div className={styles['contact-detail']}>
            <span>保証会社</span>
            <p>山田 陽子</p>
          </div>
          <a
            className={`${styles['contact-button']} button button-round button-fill color-theme`}
            onClick={handleClickContact}
          >
            <i className="icon icon-telephone" />
          </a>
        </div> */}

        <Button icon="icon-telephone" handleClickAction={handleClickContact}>
          カスタマーセンターに電話する
        </Button>
        <br />
        <br />
        <br />
        <br />
        <Button
          handleClickAction={handleClickPopupClose}
          height="46px"
          colorTheme="gray"
        >
          閉じる
        </Button>
      </div>
    </PopupPageTemplate>
  );
};
