import { yupResolver } from '@hookform/resolvers/yup';
import { NavTitle, Navbar, Page, f7 } from 'framework7-react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { validationSchema } from '../validationSchema';
import style from './ContactPc.module.css';
import { ReactComponent as CheckGrayIcon } from '@/assets/images/login/icon_check_gray.svg';
import { ReactComponent as CheckGreenIcon } from '@/assets/images/login/icon_check_green.svg';
import { ReactComponent as CheckErrIcon } from '@/assets/images/login/icon_checkerr.svg';
import { Button } from '@/components/uiParts/Button';
import { ParallelContactInput } from '@/components/uiParts/ParallelInput/ParallelContactInput';
import { RhfTextareaWithLabel } from '@/components/uiParts/ReactHookForm/RhfTextareaWithLabel/RhfTextareaWithLabel';
import { RhfTextInput } from '@/components/uiParts/TextInput/RhfTextInput';
import { paths } from '@/config/paths';
import { useSendInquiry } from '@/hooks/api/inquiry/useSendInquiry';

interface FormInput {
  lastName: string;
  firstName: string;
  kanaLastName: string;
  kanaFirstName: string;
  phoneNumber: string;
  email: string;
  other: string;
}

export const ContactPc = () => {
  // hook
  const formMethods = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
  });
  const { sendInquiry } = useSendInquiry();
  const {
    handleSubmit,
    formState: { errors, touchedFields },
    watch,
  } = formMethods;
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const params = {
      family_name: data.lastName,
      family_name_kana: data.kanaLastName,
      first_name: data.firstName,
      first_name_kana: data.kanaFirstName,
      mail_address: data.email,
      phone_no: data.phoneNumber,
      supplements_item: data.other,
    };
    await sendInquiry(params);
    f7.views.main.router.navigate(paths.contactComplete, {
      props: { email: data.email },
    });
  };
  const hasNameValue = watch('firstName') || watch('lastName');
  const hasKanaValue = watch('kanaFirstName') || watch('kanaLastName');
  const hasPhoneValue = watch('phoneNumber');
  const hasEmailValue = watch('email');
  return (
    <Page>
      <Navbar className={style['navbar']}>
        <NavTitle className={style['nav-title']}>問い合わせ</NavTitle>
      </Navbar>
      <div className={style['container']}>
        <div className={style['sub-title-wrapper']}>
          <h3>下記の情報を入力の上、送信してください</h3>
          <p>
            {`お客様情報を確認の上、\nお電話・メールにて連絡させていただきます`}
          </p>
        </div>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`list no-hairlines ${style['form-wrapper']}`}>
              <ul>
                <li>
                  <ParallelContactInput
                    label="お名前"
                    firstInput={{
                      name: 'lastName',
                      placeholder: '姓',
                    }}
                    secondInput={{
                      name: 'firstName',
                      placeholder: '名',
                    }}
                    required
                    error={
                      errors.firstName?.message || errors.lastName?.message
                    }
                  />
                  {errors.firstName?.message || errors.lastName?.message ? (
                    <CheckErrIcon
                      className={`${style['icon']} ${style['icon-error']}`}
                    />
                  ) : hasNameValue ? (
                    <CheckGreenIcon className={style['icon']} />
                  ) : (
                    <CheckGrayIcon className={style['icon']} />
                  )}
                </li>
                <li>
                  <ParallelContactInput
                    label="フリガナ"
                    firstInput={{
                      name: 'kanaLastName',
                      placeholder: 'セイ',
                    }}
                    secondInput={{
                      name: 'kanaFirstName',
                      placeholder: 'メイ',
                    }}
                    required
                    error={
                      errors.kanaFirstName?.message ||
                      errors.kanaLastName?.message
                    }
                  />
                  {errors.kanaFirstName?.message ||
                  errors.kanaLastName?.message ? (
                    <CheckErrIcon
                      className={`${style['icon']} ${style['icon-error']}`}
                    />
                  ) : hasKanaValue ? (
                    <CheckGreenIcon className={style['icon']} />
                  ) : (
                    <CheckGrayIcon className={style['icon']} />
                  )}
                </li>
                <li>
                  <RhfTextInput
                    name="phoneNumber"
                    label="電話番号"
                    placeholder="09000000000"
                    required
                    error={errors.phoneNumber?.message}
                  />
                  {errors.phoneNumber?.message ? (
                    <CheckErrIcon
                      className={`${style['icon']} ${style['icon-error']}`}
                    />
                  ) : hasPhoneValue ? (
                    <CheckGreenIcon className={style['icon']} />
                  ) : (
                    <CheckGrayIcon className={style['icon']} />
                  )}
                </li>
                <li>
                  <RhfTextInput
                    name="email"
                    label="メールアドレス"
                    placeholder="mailaddress@example.com"
                    required
                    annotation="連絡・返信先として使用します"
                    error={errors.email?.message}
                  />
                  {errors.email?.message ? (
                    <CheckErrIcon
                      className={`${style['icon']} ${style['icon-error']}`}
                    />
                  ) : hasEmailValue ? (
                    <CheckGreenIcon className={style['icon']} />
                  ) : (
                    <CheckGrayIcon className={style['icon']} />
                  )}
                </li>
                <li>
                  <RhfTextareaWithLabel
                    name="other"
                    label="その他補足事項"
                    placeholder="補足事項"
                  />
                  <CheckGreenIcon className={style['icon']} />
                </li>
              </ul>
              <div className={style['button-container']}>
                <div className={style['button-wrapper']}>
                  <Button type="submit">送信する</Button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Page>
  );
};
