import { Router } from 'framework7/types';
import { FC } from 'react';
import { EmailComplete } from './EmailComplete/EmailComplete';
import { store } from '@/config/store';

interface EmailCompletePageProps {
  f7router: Router.Router;
}
export const EmailCompletePage: FC<EmailCompletePageProps> = ({ f7router }) => {
  const email = store.state.loginInfo.email;
  return <EmailComplete f7router={f7router} email={email} />;
};
