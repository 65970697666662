import { FC } from 'react';
import { RoadServiceTermsPopup } from './RoadserviceTermsPopup/RoadserviceTermsPopup';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const RoadServiceTermsPopupPage: FC = () => {
  const { data } = useTerm(termType.ROAD_SERVICE);
  const html = data?.term;
  return (
    <PopupPageTemplate
      pageName="RoadServiceTermsPage"
      title="ロードサービスご利用規約"
    >
      <RoadServiceTermsPopup html={html} />
    </PopupPageTemplate>
  );
};
