import * as yup from 'yup';

export const watchingFormSchema = yup.object().shape({
  familyName: yup.string().required('お名前は必須です'),
  firstName: yup.string().required('お名前は必須です'),
  familyNameKana: yup
    .string()
    .required('フリガナは必須です')
    .matches(/^([ァ-ン]|ー)+$/, { message: '全角カタカナで入力してください' }),
  firstNameKana: yup
    .string()
    .required('フリガナは必須です')
    .matches(/^([ァ-ン]|ー)+$/, { message: '全角カタカナで入力してください' }),
  mailAddress: yup
    .string()
    .required('メールアドレスは必須です')
    .email('正しいメールアドレスを入力してください'),
});
