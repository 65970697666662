import { FC } from 'react';
import { TradeGuaranteeApplication } from './TradeGuaranteeApplication/TradeGuaranteeApplication';
import { GetDealWarrantyInfoApiResponse } from '@/types/api/warrantyApi';

interface TradeGuaranteeApplicationProps {
  isFromDeepLink?: boolean;
  initialData?: GetDealWarrantyInfoApiResponse['data'];
}

export const TradeGuaranteeApplicationPage: FC<
  TradeGuaranteeApplicationProps
> = (props) => {
  return <TradeGuaranteeApplication {...props} />;
};
