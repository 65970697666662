import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchDriveRapidAccelerationApi } from '@/api/driveRecordApi';
import { RapidAccelerationParams } from '@/types/api/driveRecordApi';
import { createMonths, createOneWeek, doHandleMonth } from '@/utils/date';
import { echartsScale } from '@/utils/echartsScail';

export const useRapidAcceleration = (
  initialParams: RapidAccelerationParams,
) => {
  const [params] = useState(initialParams);
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  const initialData = {
    rapidAccelerationWeeklyInfo: {
      weeklyScoreList: [0, 0, 0, 0, 0, 0, 0],
      createWeekly: [''],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
      rapidAccelerationScale: {
        minNumber: 0,
        maxNumber: 10,
        YaxisLabelInterval: 1,
      },
    },
    rapidAccelerationMonthlyInfo: {
      monthlyScoreList: [0, 0, 0, 0, 0, 0],
      createMonthly: [''],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
      rapidAccelerationScale: {
        minNumber: 0,
        maxNumber: 10,
        YaxisLabelInterval: 1,
      },
    },
    rapidAccelerationAllInfo: {
      allScoreList: [0],
      createAll: [''],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
      rapidAccelerationScale: {
        minNumber: 0,
        maxNumber: 10,
        YaxisLabelInterval: 1,
      },
    },
    progressWidth: {
      morningProgress: 0,
      afternoonProgress: 0,
      eveningProgress: 0,
    },
    rapidAccelerationInfo: {
      yearScoreList: [],
      createMonthly: [],
      morningCounts: 0,
      afternoonCounts: 0,
      eveningCounts: 0,
    },
  };

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchDriveRapidAccelerationApi(params);
        const weeklyDateList = createOneWeek();

        initialData.rapidAccelerationWeeklyInfo.createWeekly = weeklyDateList;
        weeklyDateList.forEach((item: any, index: number) => {
          res?.data.data.rapid_acceleration_times_past_week.find((n) => {
            if (n.start_at_ymd?.substring(6, 8) === item.substring(5, 7)) {
              initialData.rapidAccelerationWeeklyInfo.weeklyScoreList[index] =
                n.rapid_acceleration_times_avg ?? 0;
            }
          });
        });
        initialData.rapidAccelerationWeeklyInfo.rapidAccelerationScale =
          echartsScale(
            'rapidAcceleration',
            initialData.rapidAccelerationWeeklyInfo.weeklyScoreList,
          ) ?? initialData.rapidAccelerationWeeklyInfo.rapidAccelerationScale;

        //過去6ヶ月のデータ
        const currentMonthlyDate = new Date();
        const currentMonthlyYear = currentMonthlyDate.getFullYear();
        const currentMonthlyMonth = doHandleMonth(
          currentMonthlyDate.getMonth() + 1,
        );
        const latestDate = currentMonthlyYear + '/' + currentMonthlyMonth;
        const monthlyDateList = createMonths(latestDate).reverse();
        initialData.rapidAccelerationMonthlyInfo.createMonthly =
          monthlyDateList;
        monthlyDateList.forEach((item: any, index: number) => {
          res.data.data.rapid_acceleration_times_last_six_month?.find((n) => {
            if (n.start_at_ym?.substring(4, 6) === item.substring(0, 2)) {
              initialData.rapidAccelerationMonthlyInfo.monthlyScoreList[index] =
                n.rapid_acceleration_times_avg ?? 0;
            }
          });
        });
        initialData.rapidAccelerationMonthlyInfo.rapidAccelerationScale =
          echartsScale(
            'rapidAcceleration',
            initialData.rapidAccelerationMonthlyInfo.monthlyScoreList,
          ) ?? initialData.rapidAccelerationMonthlyInfo.rapidAccelerationScale;

        // 全期間のデータ(直近4年分のデータを取得)
        const allDateList: string[] = [];
        const allScores: number[] = [];
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        for (let year = currentYear - 3; year <= currentYear; year++) {
          for (let month = 1; month <= 12; month++) {
            const formattedMonth = month.toString().padStart(2, '0');
            const itemDate = formattedMonth + '月\n' + year.toString();
            allDateList.push(itemDate);
            const foundItem = res.data.data.rapid_acceleration_times_all?.find(
              (n: any) =>
                n.start_at_ym?.substring(4, 6) === formattedMonth &&
                n.start_at_ym?.substring(0, 4) === year.toString(),
            );
            if (foundItem) {
              allScores.push(foundItem.rapid_acceleration_times_avg ?? 0);
            } else {
              allScores.push(0);
            }
          }
        }

        initialData.rapidAccelerationAllInfo.createAll = allDateList;
        initialData.rapidAccelerationAllInfo.allScoreList = allScores;
        initialData.rapidAccelerationAllInfo.rapidAccelerationScale =
          echartsScale(
            'rapidAcceleration',
            initialData.rapidAccelerationAllInfo.allScoreList,
          ) ?? initialData.rapidAccelerationAllInfo.rapidAccelerationScale;

        //朝昼夜の傾向
        // 朝、昼、夜
        const morningData =
          res.data.data.rapid_acceleration_times_time_zone?.find(
            (n: any) => n.time_zone == 1,
          );
        const afternoonData =
          res.data.data.rapid_acceleration_times_time_zone?.find(
            (n: any) => n.time_zone == 2,
          );
        const eveningData =
          res.data.data.rapid_acceleration_times_time_zone?.find(
            (n: any) => n.time_zone == 3,
          );

        initialData.rapidAccelerationInfo.morningCounts =
          morningData?.rapid_acceleration_times_avg ?? 0;
        initialData.rapidAccelerationInfo.afternoonCounts =
          afternoonData?.rapid_acceleration_times_avg ?? 0;
        initialData.rapidAccelerationInfo.eveningCounts =
          eveningData?.rapid_acceleration_times_avg ?? 0;

        initialData.progressWidth.morningProgress =
          ((morningData?.rapid_acceleration_times_avg ?? 0) / 5) * 100;
        initialData.progressWidth.afternoonProgress =
          ((afternoonData?.rapid_acceleration_times_avg ?? 0) / 5) * 100;
        initialData.progressWidth.eveningProgress =
          ((eveningData?.rapid_acceleration_times_avg ?? 0) / 5) * 100;

        setData(initialData);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [params]);

  return {
    data,
    isLoading,
    error,
  };
};
