import { f7 } from 'framework7-react';
import { FC } from 'react';
import { RoundButtonWithIcon } from '@/components/uiParts/Button/RoundButtonWithIcon/RoundButtonWithIcon';

interface ContactByFormButtonProps {
  path: string;
  isPopupPage?: boolean;
}

export const ContactByFormButton: FC<ContactByFormButtonProps> = ({
  path,
  isPopupPage = false,
}) => {
  const handleClickButton = () => {
    f7.view.main.router.navigate(path);
  };
  return (
    <RoundButtonWithIcon
      popupClose={isPopupPage}
      iconClass="icon-mail"
      colorTheme="gray"
      handleClickAction={handleClickButton}
    >
      フォームで問い合わせする
    </RoundButtonWithIcon>
  );
};
