import { Button, useStore } from 'framework7-react';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import style from './LinkDriveSetupStep2Style.module.css';
import { SetupNote } from './SetupNote';
import { StepEngine } from './StepEngine';
import { StepInterupted } from './StepInterupted';
import { StepIntro } from './StepIntro';
import { StepPreconfirm } from './StepPreconfirm';

import ExclamationMark from '@/assets/images/icon-exclamationmark_circle.svg';
import { SleepDialog } from '@/components/projects/SleepDialog';
import { isApp } from '@/config/device';
import { store } from '@/config/store';
import {
  connectorJob,
  jobUpdatingLabel,
  setupProcessingStatus,
  updatePromiseResolveFlg,
} from '@/consts/linkDrive';
import { url } from '@/consts/url';
import { useSetStoreLinkDriveOwner } from '@/hooks/api/info/useSetStoreLinkDriveOwner';
import { useConnectorJob } from '@/hooks/api/linkDrive/useConnectorJob';
import { useLinkDriveSetupStatus } from '@/hooks/api/linkDrive/useLinkDriveSetupStatus';
import { steps } from '@/hooks/useLinkDriveSetup';
import {
  StatusCheckResult,
  useLinkDriveSetupProgress,
} from '@/hooks/useLinkDriveSetupProgress';
import { storeDispatch } from '@/utils/store';

interface LinkDriveSetupStep2Props {
  step: number;
  signal: AbortSignal;
  setStep: (step: number) => void;
  onSubmit: () => void;
  setIsRecallLinkDriveOwnerSuccess: Dispatch<SetStateAction<boolean>>;
  setLinkDriveStepHasError: Dispatch<SetStateAction<boolean>>;
}

export const LinkDriveSetupStep2Page: FC<LinkDriveSetupStep2Props> = ({
  step,
  signal,
  setStep,
  onSubmit,
  setIsRecallLinkDriveOwnerSuccess,
  setLinkDriveStepHasError,
}) => {
  const ownerId = store.state.ownerId;
  const serialNo = store.state.serialNumberInput;
  const linkDriveOwnerInfo = store.state.linkDriveOwnerInfo;
  const authInfo = store.state.authInfo;
  const mCustomerId = authInfo.m_customer_id;
  const tStockCarId = linkDriveOwnerInfo.t_stock_car_id;
  const serial_no = store.state.serialNumberDb || store.state.serialNumberInput;
  const abortSignal = store.state.linkDriveAbortController.signal;
  const progress = useStore(store, 'linkDriveProgress') as number;
  const currentJobLabel = useStore(store, 'linkDriveSetupCurrent') as number;
  const [jobStatusError, setJobStatusError] = useState<number | string>('');
  const [isShowProgress, setIsShowProgress] = useState(false);
  const [isSetupStartable, setIsSetupStartable] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { fetchLinkDriveSetupStatus } = useLinkDriveSetupStatus();
  const { setStoreLinkDriveOwner } = useSetStoreLinkDriveOwner();
  const { requestConnectorJob } = useConnectorJob();
  const isJobStatusTimeOut = jobStatusError == updatePromiseResolveFlg.ENDED;
  const {
    recursiveJobStatus,
    recursiveStatusCheck,
    updateFW,
    updateModeReservation,
    updateECU,
  } = useLinkDriveSetupProgress(
    abortSignal,
    signal,
    setStep,
    setJobStatusError,
  );

  const handleClickCompleteStep1 = () => {
    setStep(steps.ENGINE);
  };

  const handleClickStartedEngine = () => {
    setStep(steps.PRE_CONFIRM);
  };

  const handleConnectorStatusCheckResult = async (
    result: StatusCheckResult,
  ) => {
    if (!result.success) return;
    let rs = updatePromiseResolveFlg.ENDED;
    setStep(steps.CONNECT);
    storeDispatch(
      'setLinkDriveUpdating',
      'linkDriveUpdating',
      setupProcessingStatus.PROCESSING,
    );
    if (result.job && result.job_id) {
      const startTime = new Date().getTime();
      if (isApp) {
        window.location.href =
          url.NATIVE_BASE_URL +
          `/linkdrive/setup_interrupted/?job=${result.job}&task_id=${result.job_id}`;
      }
      rs = await recursiveJobStatus(
        String(result.job),
        result.job_id,
        startTime,
      );
      if (
        rs === updatePromiseResolveFlg.SUCCESS &&
        String(result.job) === connectorJob.FIRMWARE_UPDATE
      ) {
        rs = await updateModeReservation();
      }
      if (
        rs === updatePromiseResolveFlg.SUCCESS &&
        String(result.job) !== connectorJob.ECU_LIST_UPDATE
      ) {
        const ecuStartTime = new Date().getTime();
        storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0);
        storeDispatch(
          'setLinkDriveSetupCurrent',
          'linkDriveSetupCurrent',
          jobUpdatingLabel[connectorJob.ECU_LIST_UPDATE],
        );
        rs = await updateECU(ecuStartTime);
      }
    } else {
      rs = await updateFW();
      if (rs === updatePromiseResolveFlg.SUCCESS) {
        rs = await updateModeReservation();
      }
      if (rs === updatePromiseResolveFlg.SUCCESS) {
        const ecuStartTime = new Date().getTime();
        storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0);
        storeDispatch(
          'setLinkDriveSetupCurrent',
          'linkDriveSetupCurrent',
          jobUpdatingLabel[connectorJob.ECU_LIST_UPDATE],
        );
        rs = await updateECU(ecuStartTime);
      }
    }
    setLinkDriveStepHasError(rs === updatePromiseResolveFlg.FAILLED);
    if (isApp) {
      window.location.href = url.NATIVE_BASE_URL + `/linkdrive/setup_complete/`;
    }
    if (rs === updatePromiseResolveFlg.SUCCESS) {
      const isSuccessfully = await setStoreLinkDriveOwner({
        params: {
          customer_id: mCustomerId,
          stock_car_id: tStockCarId,
          del_flg: 0,
        },
      });
      setIsRecallLinkDriveOwnerSuccess(isSuccessfully);
      storeDispatch(
        'setLinkDriveUpdating',
        'linkDriveUpdating',
        setupProcessingStatus.SUCCESS,
      );
      return;
    }
    if (rs === updatePromiseResolveFlg.ENDED) {
      setStep(steps.STATUS_CHECK);
      setJobStatusError(updatePromiseResolveFlg.ENDED);
    }
    storeDispatch(
      'setLinkDriveUpdating',
      'linkDriveUpdating',
      setupProcessingStatus.INACTIVE,
    );
    storeDispatch('setLinkDriveSetupCurrent', 'linkDriveSetupCurrent', '');
    storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0);
  };
  const handleOpenDialog = () => {
    setShowDialog(true);
  };
  const handleClickStartStatusRetry = () => {
    const params = {
      owner_id: ownerId,
      serial_no,
    };
    if (isJobStatusTimeOut) {
      setStep(steps.INTRO);
      setJobStatusError('');
      return;
    }
    setStep(steps.STATUS_CHECK);
    recursiveStatusCheck(27, params, handleOpenDialog).then((rs) => {
      handleConnectorStatusCheckResult(rs);
    });
  };

  const handleClickStartStatusCheck = () => {
    setStep(steps.STATUS_CHECK);
    // vue版と同じく800ms後に実行
    setTimeout(() => {
      const params = {
        owner_id: ownerId,
        serial_no,
      };
      recursiveStatusCheck(27, params, handleOpenDialog).then((rs) => {
        handleConnectorStatusCheckResult(rs);
      });
    }, 800);
  };

  useEffect(() => {
    setLinkDriveStepHasError(!!jobStatusError);
  }, [jobStatusError, setLinkDriveStepHasError]);

  useEffect(() => {
    if (step === steps.INTERUPTED) {
      const params = {
        owner_id: ownerId,
        serial_no,
      };
      recursiveStatusCheck(27, params, handleOpenDialog).then((rs) => {
        handleConnectorStatusCheckResult(rs);
      });
    }
  }, [step]);

  return (
    <div className={`${style['container']}`}>
      {step === steps.INTRO && (
        <StepIntro onSubmit={handleClickCompleteStep1} />
      )}
      {/* <!-- エンジン始動 --> */}
      {step === steps.ENGINE && (
        <StepEngine onSubmit={handleClickStartedEngine} />
      )}

      {/* <!-- デバイス点灯 --> */}
      {step === steps.PRE_CONFIRM && (
        <StepPreconfirm onSubmit={handleClickStartStatusCheck} />
      )}

      {step === steps.INTERUPTED && <StepInterupted />}

      {/* <!-- デバイス点灯 --> */}
      {step === steps.STATUS_CHECK &&
        (jobStatusError ? (
          <>
            <h2 className={style['head-error']}>
              <img src={ExclamationMark} width="20px" height="20px" />
              {isJobStatusTimeOut
                ? 'タイムアウトしました'
                : '再度セットアップを実施してください'}
            </h2>
            {isJobStatusTimeOut ? (
              <>
                <p className={style['status-paragraph']}>
                  一定時間が過ぎたため、セットアップを終了します。
                  <br />
                  <br />
                </p>
                <SetupNote />
              </>
            ) : (
              <p
                className={`${style['status-paragraph']} ${style['setup-error']}`}
              >
                {jobStatusError}
                <br />
                <br />
                コネクターを差し込み直して、
                <br />
                再度セットアップを実施してください。
              </p>
            )}

            <Button
              fill
              round
              text={
                isJobStatusTimeOut
                  ? 'ホームに戻る'
                  : '再度セットアップを実施する'
              }
              style={{ marginTop: 'auto' }}
              onClick={handleClickStartStatusRetry}
            />
          </>
        ) : (
          <>
            <h2 className={style['head']}>
              <i className={`icon-check ${style['head-icon']}`} />
              セットアップを実行します
            </h2>
            <p className={style['status-paragraph']}>
              デバイスの状態を確認中 ...
            </p>
          </>
        ))}
      {/* <!-- デバイス点灯 --> */}
      {step === steps.CONNECT && (
        <>
          <h2 className={style['head']}>セットアップを実行します</h2>
          <div className={style['progress']}>
            <CircularProgressbarWithChildren strokeWidth={5} value={progress}>
              <div className={style['progress-inner']}>
                <p>進捗</p>
                <div className={style['progress-inner-percentage']}>
                  <h4>{progress}</h4>
                  <p>%</p>
                </div>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <p className={style['status-paragraph']}>{currentJobLabel}</p>
          <p className={style['status-paragraph']}>このままお待ちください。</p>
          {progress && progress != 0 && progress != 100 ? <SetupNote /> : ''}
        </>
      )}
      {showDialog && <SleepDialog handleCancel={() => setShowDialog(false)} />}
    </div>
  );
};
