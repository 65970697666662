import { Popup } from 'framework7-react';
import { PopupProps } from 'framework7-react/components/popup';
import { FC } from 'react';
import style from './CarWashConditionerStep3Style.module.css';
import { Button } from '@/components/uiParts/Button';
import { CourseItem } from '@/types/api/reservationApi';

interface PopupAboutProps extends PopupProps {
  courseItem: CourseItem | undefined;
}

export const PopupAbout: FC<PopupAboutProps> = ({
  onPopupClosed,
  courseItem,
  ...props
}) => {
  return (
    <Popup {...props}>
      <div className={style['cancellation-popup']}>
        <h4>キャンセル料について</h4>
        <p>
          当日の作業環境が異なっており施工できない場合やお客様都合による場合は、キャンセル料が発生します。キャンセル料は以下の通りです。
        </p>
        <p>
          <ul>
            <li>
              前日キャンセルの場合：料金の
              {courseItem?.cancellation_charge.charge_percent_eve || 0}%
            </li>
            <li>
              当日キャンセルの場合：料金の
              {courseItem?.cancellation_charge.charge_percent || 0}%
            </li>
          </ul>
        </p>
        <p className={style['cancellation-popup-info']}>
          <span>※</span>
          <span>悪天候による場合はキャンセル料は発生しません。</span>
        </p>
        <Button
          className={style['cancellation-popup-button']}
          onClick={onPopupClosed}
        >
          閉じる
        </Button>
      </div>
    </Popup>
  );
};
