import { f7 } from 'framework7-react';
import { FC } from 'react';
import { BoxCard } from './BoxCard';
import style from './CarOrder.module.scss';
import { VehicleCard } from './VehicleCard';
import { Button } from '@/components/uiParts/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { paymentMethods } from '@/consts/carOrder';
import { genders } from '@/consts/customer';
import {
  PurchaseOrder,
  PurchaseOrderOptionItem,
  Remark,
} from '@/types/api/carApi';
import { hyphenToYear } from '@/utils/date';
interface CarOrderProps {
  purchaseOrder?: PurchaseOrder;
  optionItemList?: PurchaseOrderOptionItem[];
  remarks?: Remark[];
}

export const CarOrder: FC<CarOrderProps> = ({
  purchaseOrder,
  optionItemList,
  remarks,
}) => {
  const guaranteeAndCustomerItems = [
    {
      groupLabel: '保証',
      items: [
        {
          label: '保証プラン',
          value: purchaseOrder?.warranty_name || '加入なし',
        },
        {
          label: '保証期間',
          value: purchaseOrder?.warranty_period
            ? `${purchaseOrder.warranty_period / 12}年`
            : '-',
        },
      ],
    },
    {
      groupLabel: 'お客様情報',
      items: [
        {
          label: 'お客様名',
          value: `${purchaseOrder?.family_name || ''} ${
            purchaseOrder?.first_name || ''
          }`,
        },
        {
          label: 'お客様名カナ',
          value: `${purchaseOrder?.family_name_kana || ''} ${
            purchaseOrder?.first_name_kana || ''
          }`,
        },
        {
          label: '性別',
          value:
            purchaseOrder?.gender === genders.MALE
              ? '男'
              : purchaseOrder?.gender === genders.FEMALE
              ? '女'
              : 'その他',
        },
        {
          label: '生年月日',
          value: hyphenToYear(purchaseOrder?.birthday, false),
        },
        {
          label: '住所',
          value: `${purchaseOrder?.address1 || ''}${
            purchaseOrder?.address2 || ''
          }\n${purchaseOrder?.address3 || ''}`,
        },
        {
          label: '電話番号',
          value: purchaseOrder?.phone_no1 || purchaseOrder?.phone_no2 || '',
        },
        { label: 'メールアドレス', value: purchaseOrder?.email || '' },
      ],
    },
  ];

  const specialRemarks =
    remarks?.filter((remark) => remark.m_sale_remark_id === -1) || [];

  const normalRemarks =
    remarks?.filter((remark) => remark.m_sale_remark_id !== -1) || [];

  return (
    <PageTemplate pageName="CarOrder" title="注文書" showNavBack>
      <div className={style['car-order-content']}>
        <VehicleCard purchaseOrder={purchaseOrder} />
        {/* 明細 */}
        <div className={style['information-group']}>
          <div>
            <h3 className={style['information-large-label']}>
              お支払い情報
              {purchaseOrder?.payment_method &&
                [paymentMethods.LEASE, paymentMethods.LOAN].includes(
                  purchaseOrder.payment_method,
                ) && (
                  <Button
                    outline
                    style={{
                      height: '32px',
                      background: '#fff',
                      marginLeft: 'auto',
                      borderWidth: '2px',
                    }}
                    text="詳細を見る"
                    onClick={() =>
                      f7.view.main.router.navigate(
                        paths.carOrderDetail(purchaseOrder.order_id.toString()),
                        {
                          props: {
                            purchaseOrder,
                            optionItemList,
                          },
                        },
                      )
                    }
                  />
                )}
            </h3>
            <div className={style['information-box']}>
              <div className={style['payment-row']}>
                <label className={style['payment-row-label']}>総額</label>
                <label className={style['payment-row-value']}>
                  {purchaseOrder?.total_price?.toLocaleString?.() || 0}円
                </label>
              </div>
              <div
                className={`${style['payment-row']} ${style['secondary-row']}`}
              >
                <label className={style['payment-row-label']}>支払い方法</label>
                <label className={style['payment-row-value']}>
                  {purchaseOrder?.payment_method_name || ''}
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* 内訳 */}
        <div className={style['information-group']}>
          <div>
            <h3 className={style['information-large-label']}>内訳</h3>
            <BoxCard
              purchaseOrder={purchaseOrder}
              optionItemList={optionItemList}
            />
          </div>
        </div>
        <div className={style['information-group']}>
          {guaranteeAndCustomerItems.map((gcItem, gcIndex) => (
            <div key={`gcItem-${gcIndex}`}>
              <h3 className={style['information-large-label']}>
                {gcItem.groupLabel}
              </h3>
              <div className={style['information-box']}>
                {gcItem.items.map((item, index) => (
                  <div
                    key={`gcItem-${gcIndex}-${index}`}
                    className={style['information-row']}
                  >
                    <label className={style['box-row-label']}>
                      {item.label}
                    </label>
                    <label className={style['box-row-value']}>
                      {item.value}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div>
            <h3 className={style['information-large-label']}>特記事項・備考</h3>
            <div className={style['information-box']}>
              <div className={style['note-row']}>
                {!remarks?.length && <p>特記事項・備考はありません</p>}
                {specialRemarks.length ? (
                  <div>
                    <div className={style['paragraph-row']}>
                      <b>特記事項</b>
                    </div>
                    {specialRemarks.map((remark) => (
                      <p
                        key={remark.t_sale_remark_id}
                        className={style['black-dot-text']}
                      >
                        {remark.remark}
                      </p>
                    ))}
                  </div>
                ) : null}
                {normalRemarks.length ? (
                  <div>
                    <div className={style['paragraph-row']}>
                      <b>備考</b>
                    </div>
                    {normalRemarks.map((remark) => (
                      <p
                        key={remark.t_sale_remark_id}
                        className={style['black-dot-text']}
                      >
                        {remark.remark}
                      </p>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};
