export const chargeClassification = {
  BASIC: 1,
  REGULAR: 2,
  COUPON: 3,
} as const;

// チャージタイプ（1：月次チャージ、2：スポットチャージ）
export const chargeType = {
  MONTHLY: 1,
  SPOT: 2,
} as const;

// 取扱バリュータイプ（1：基本、2：定期、3：クーポン）
export const valueType = {
  BASIC: 1,
  FIXED_TERM: 2,
  COUPON: 3,
} as const;

export const amountList = [
  { text: '+100円', value: 100 },
  { text: '+1,000円', value: 1000 },
  { text: '+5,000円', value: 5000 },
] as const;
