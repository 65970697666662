import { CSSProperties, FC, ReactNode } from 'react';
import style from './roundSheetStyle.module.css';

export interface RoundSheetProps {
  children?: ReactNode;
  py?: string;
  px?: string;
  bgColor?: string;
  height?: string;
  radius?: string;
  overflowY?: CSSProperties['overflowY'];
}
export const RoundSheet: FC<RoundSheetProps> = ({
  children,
  py = '16px',
  px = '16px',
  bgColor = '#fff',
  height = 'auto',
  overflowY = 'auto',
  radius = '',
}) => {
  return (
    <div
      className={style.container}
      style={{
        padding: `${py} ${px}`,
        backgroundColor: bgColor,
        height,
        overflowY,
        borderRadius: radius,
      }}
    >
      {children}
    </div>
  );
};
