import { FC } from 'react';
import { CheckIcon } from '../../Check/Check';
import { RhfInput } from '../../ReactHookForm/RhfInput/RhfInput';
import style from './RhfParallelInputStyle.module.css';

interface RhfParallelInputProps {
  isCheckIcon?: boolean;
  isCheckIconValid?: boolean;
  required?: boolean;
  label: string;
  firstPlaceHolder: string;
  secondPlaceHolder: string;
  firstName: string;
  secondName: string;
  firstError?: string;
  secondError?: string;
}

export const RhfParallelInput: FC<RhfParallelInputProps> = ({
  isCheckIcon = false,
  isCheckIconValid,
  required = true,
  label,
  firstPlaceHolder,
  secondPlaceHolder,
  firstName,
  secondName,
  firstError = '',
  secondError = '',
}) => {
  return (
    <div>
      <div className={style['label-wrap']}>
        <p className={style.label}>{label}</p>
        {required && (
          <span className={`${style['label-tips']} ${style.required}`}>
            必須
          </span>
        )}
        {!required && (
          <span className={`${style['label-tips']} ${style.optional}`}>
            任意
          </span>
        )}
      </div>
      <div className={style['input-wrap']}>
        <div>
          <RhfInput
            name={firstName}
            type="text"
            placeholder={firstPlaceHolder}
            className={`${style.input} ${firstError && style['error-input']}`}
          />
        </div>
        <div>
          <RhfInput
            name={secondName}
            type="text"
            placeholder={secondPlaceHolder}
            className={`${style.input} ${secondError && style['error-input']}`}
          />
        </div>

        {isCheckIcon && (
          <div>
            <CheckIcon isValid={isCheckIconValid || false} />
          </div>
        )}
      </div>
      {secondError && <span className={style.error}>{secondError}</span>}
      {firstError && <span className={style.error}>{firstError}</span>}
    </div>
  );
};
