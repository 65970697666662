import { Router } from 'framework7/types';
import { FC } from 'react';
import { Password } from './Password/Password';
import { store } from '@/config/store';
import { getAffiliateIdFromThemeId } from '@/utils/shop';

interface PasswordPageProps {
  f7router: Router.Router;
}

export const PasswordPage: FC<PasswordPageProps> = ({ f7router }) => {
  const themeId = store.state.themeId;
  return (
    <Password
      f7router={f7router}
      affiliateId={getAffiliateIdFromThemeId(themeId)}
    />
  );
};
