import { Block } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import './TodoInstructionStyle.scss';

interface TodoInstructionProps {
  f7router: Router.Router;
}

export const TodoInstrunction: FC<TodoInstructionProps> = ({ f7router }) => {
  return (
    <PageTemplate pageName="TodoInstruction" showNavBack title="やることリスト">
      <div className="home__wrapper">
        <Block style={{ marginTop: 0 }}>
          <div className="info-card info-card-elevated">
            <h2 className="notice-title u-text-center">印鑑証明</h2>
            <div className="notice-content">
              印鑑登録をしているかしていないかで入手手順が異なります。
            </div>
          </div>
          <div className="info-card info-card-elevated">
            <h2 className="notice-title">印鑑登録をしている場合</h2>
            <div className="notice-content">
              ※指定日までの送付が難しい場合は、購入店舗にご相談ください
            </div>
            <h2 className="notice-title">
              役所・証明サービスコーナーなどの窓口で発行する
            </h2>
            <div className="notice-content">
              役所や証明サービスコーナーの窓口で手続きを行う方法です。
            </div>
            <h2 className="notice-title">必要なもの</h2>
            <div className="notice-content">本人が行く場合</div>
            <ul className="text-list">
              <li>
                印鑑登録証（印鑑登録カード）
                <br />
                またはマイナンバーカード
              </li>
              <li>
                本人確認書類
                <br />
                ※免許証・健康保険証など
              </li>
              <li>手数料300円</li>
            </ul>
          </div>
          <div className="info-card info-card-elevated">
            <h2 className="notice-title">郵送後の流れ</h2>
            <p />
            <div className="notice-content">
              <span>
                郵送が完了しましたら、「完了済みにする」ボタンを押してください。
              </span>
              <span>
                ※書類が確認できましたら、やることリストからこちらのタスクがなくなります。
              </span>
            </div>
          </div>
        </Block>
      </div>
    </PageTemplate>
  );
};
