import { Block, Swiper, SwiperSlide } from 'framework7-react';
import { FC, useEffect } from 'react';
import hightlightCaptureImg from '@/assets/images/highlight-capture.png';
import insuranceCaptureImg from '@/assets/images/insurance-capture.png';
import linkdriveAirImg from '@/assets/images/linkdrive-air-image.png';
import scoreCaptureImg from '@/assets/images/score-capture.png';
import { SettingsItemCard } from '@/components/uiParts/SettingsItemCard/SettingsItemCard';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { drivelogSendFlg } from '@/consts/linkDrive';
import { useLinkDriveOwner } from '@/hooks/api/info/useLinkDriveOwner';
import { LinkDriveOwnerApiParams } from '@/types/api/infoApi';
import './DataUsingSettingStyle.scss';

type Status = 'notification' | 'no-notification' | 'normal';

interface OptionType {
  optionsTitle: string;
  props?: number;
  status?: Status;
  statusText?: string;
  detailInfo?: string;
}

export const DataUsingSetting: FC = () => {
  const { t_stock_car_id } = store.state.carItemInfo;
  const { m_customer_id } = store.state.authInfo;
  const linkDriveOwnerApiParams: LinkDriveOwnerApiParams = {
    params: {
      customer_id: m_customer_id,
      stock_car_id: t_stock_car_id,
      del_flg: 0,
    },
  };
  const { setStoreLinkDriveOwner, data } = useLinkDriveOwner(
    linkDriveOwnerApiParams,
  );

  useEffect(() => {
    setStoreLinkDriveOwner({ params: linkDriveOwnerApiParams });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const info = {
    title: '安全運転分析サービス',
    path: paths.drivingDataAnalysisSettings,
    text: '運転ログを利用して安全運転診断を行い、スコアをお客様にフィードバックするサービスです。\nスコアを利用した保険にもご加入いただけます。',
  };
  const drivingDataAnalysisSettingsOptions: OptionType[] = [
    {
      optionsTitle: '安全運転分析サービス',
      props: data?.data?.link_drive_owner.drivelog_send_flg,
      statusText:
        data?.data?.link_drive_owner.drivelog_send_flg ===
        drivelogSendFlg.NOTIFY_ON
          ? '利用する'
          : '利用しない',
      detailInfo: '',
    },
  ];

  const imageList = [
    {
      step: '1',
      desc: 'トップ＞運転記録から、Special Offerバナーをタップ',
      imageUrl: hightlightCaptureImg,
    },
    {
      step: '2',
      desc: '「累計スコアを計算する」ボタンをタップ',
      imageUrl: insuranceCaptureImg,
    },
    {
      step: '3',
      desc: '安全運転スコアと必要情報が表示されます',
      imageUrl: scoreCaptureImg,
    },
  ];

  return (
    <PageTemplate
      pageName="DataUsingSetting"
      title="車両データ利用設定"
      showNavBack
    >
      <Block className="block">
        <SettingsItemCard
          info={info}
          options={drivingDataAnalysisSettingsOptions}
        />
      </Block>

      <Block>
        <div className="info-card info-card-elevated u-padding-top-sm">
          <h3>&lt;サービス概要&gt;</h3>
          <div className="u-padding-top-xs u-padding-bottom-md common-desc-box">
            LINKDrive
            Airを装着したお車から取得した走行データを活用した安全運転スコア・アドバイスを提供します。
            <br />
            また、その運転実績に応じて自動車保険を契約する際の保険料が割引になります。
          </div>
          <h3>対象者</h3>
          <div className="u-padding-top-xs u-padding-bottom-md common-desc-box">
            <span className="text-indent">
              ・ リンクドライブをご利用されている方
            </span>
            <span className="text-indent">
              ・ あいおいニッセイ同和損保の自動車保険へ加入される方
            </span>
          </div>
          <h3>データ送信開始時期</h3>
          <div className="u-padding-top-xs u-padding-bottom-md common-desc-box">
            <span className="text-indent">
              ・ 安全運転分析サービスに同意完了後より
            </span>
          </div>
          <h3>データ提供先（提携保険会社）</h3>
          <div className="u-padding-top-xs u-padding-bottom-md common-desc-box">
            <span className="text-indent">
              ・ あいおいニッセイ同和損害保険株式会社
            </span>
          </div>
          <div
            style={{ fontSize: '1.4rem' }}
            className="u-padding-bottom-xs u-padding-top-xs"
          >
            運転実績（安全運転スコア）と割引のイメージ
          </div>
          <div className="score-level-desc">
            <div className="row-title">
              <div>安全運転スコア</div>
              <div>区分</div>
              <div>運転特性割引</div>
            </div>
            <div className="row-a">
              <div>
                <span className="row-a-item">80</span>点以上
              </div>
              <div>
                <span className="row-a-item">A</span>区分
              </div>
              <div>
                <span className="row-a-item">8</span>%割引
              </div>
            </div>
            <div className="row-b">
              <div>
                <span className="row-b-item">60~79</span>点
              </div>
              <div>
                <span className="row-b-item">B</span>区分
              </div>
              <div>
                <span className="row-b-item">4</span>%割引
              </div>
            </div>
            <div className="row-c">
              <div>
                <span className="row-c-item">59</span>点以下
              </div>
              <div>
                <span className="row-c-item">C</span>区分
              </div>
              <div>割引なし</div>
            </div>
          </div>
          <div
            className="u-padding-bottom-md"
            style={{
              fontSize: '1.2rem',
              lineHeight: '2.2rem',
              textIndent: '-1rem',
              paddingLeft: '1rem',
            }}
          >
            （注）加入する保険商品の種類や条件によっては割引適用が行えません。また、加入する保険商品によっては適用される割引率が異なります。
          </div>

          <h3>保険料割引の概要</h3>
          <div className="u-padding-top-xs u-padding-bottom-md common-desc-box">
            運転特性計測期間中の「速度超過・急アクセル・急ブレーキ」の発生頻度（運転特性）から安全運転スコアを算定します。
          </div>
          <h3 className="tips-spot">運転特性計測期間</h3>
          <div
            className="u-padding-top-xs u-padding-bottom-sm"
            style={{ fontSize: '1.2rem', lineHeight: '2.2rem' }}
          >
            提携保険会社が運転特性割引を算出するために基準とする期間をいい、加入する保険契約の始期日が属する月の前月末日の3か月前から過去1年間
            <sup>（注）</sup>とします。
            <br />
            <span
              className="u-margin-top-xs"
              style={{
                display: 'block',
                textIndent: '-1rem',
                paddingLeft: '1rem',
              }}
            >
              （注）利用期間が1年に満たない場合もその他条件を満たす場合は割引適用が可能です。
            </span>
            <span
              style={{
                display: 'block',
                textIndent: '-.2rem',
                paddingLeft: '.8rem',
              }}
            >
              ※提携保険会社の保険加入以降の継続契約に適用される割引条件は異なります。詳細は提携保険会社にお問合わせください。
            </span>
          </div>

          <div style={{ fontSize: '1.4rem' }} className="u-padding-top-xs">
            イメージ
          </div>
          <div
            className="u-padding-top-xs u-padding-bottom-lg"
            style={{ width: '100%' }}
          >
            <img style={{ width: '100%' }} src={linkdriveAirImg} alt="" />
          </div>

          <h3 className="tips-spot">必要な走行距離</h3>
          <div className="u-padding-top-xs u-padding-bottom-md common-desc-box">
            運転特性計測期間中にLINKDrive
            Airで計測された有効走行距離が500km未満の場合、割引は適用されません。
            <br />
            この場合、平均的な安全運転スコアの区分であるB区分と同等の保険料水準が適用されます
          </div>

          <h3 className="tips-spot">その他割引適用の条件</h3>
          <div className="u-padding-top-xs u-padding-bottom-md common-desc-box">
            <span className="text-indent">
              ・
              デバイス契約会員（データ送信の同意者）と割引適用をする保険契約の記名被保険者が同一であること。
            </span>
            <span className="text-indent">
              ・
              デバイス契約会員の対象のお車と割引適用をする保険契約のお車が同一であること。
              <br />
              （お車が異なる場合であっても割引適用が可能なケースがあります。詳細は提携保険会社にお問い合わせください。）
            </span>
            <span className="text-indent">
              ・
              保険加入する月（始期月）に応じた累計スコアが算出されていること。
            </span>
            <span className="text-indent">
              ・ その他の割引適用条件は提携保険会社にお問い合わせください。
            </span>
          </div>

          <h3>安全運転スコアの確認方法</h3>
          <div className="u-padding-top-xs u-padding-bottom-md common-desc-box">
            保険加入時は下記の➂で表示される「累計スコア」「デバイス区分」「デバイスキー」を提携保険会社にお伝えください。
          </div>
          <div className="swiper_slider">
            <Swiper scrollbar slidesPerView={1.2} spaceBetween={1.5}>
              {imageList.map((item, index) => (
                <SwiperSlide key={index}>
                  <div>
                    <div className="step-circle">{item.step}</div>
                    <div className="step-desc">{item.desc}</div>
                    <img
                      style={{
                        width: '24rem',
                        borderRadius: '30px',
                        border: '1px solid #efefef',
                        boxSizing: 'border-box',
                      }}
                      src={item.imageUrl}
                      alt=""
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Block>
    </PageTemplate>
  );
};
