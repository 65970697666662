import { useStore } from 'framework7-react';
import { Col, Row } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { AuthInfoState } from '@/config/initialState';
import { store } from '@/config/store';
import { useHouseprepaidStopRestart } from '@/hooks/api/prepaid/useHouseprepaidStopRestart';
import { Prepaid } from '@/types/api/customerApi';
import { getPrepaidChangeBillInfo } from '@/utils/prepaid';
import './prepaidStopStyle.scss';
import { f7CustomBack } from '@/utils/utils';

type PrepaidStop = {
  f7router: Router.Router;
  event?: () => void;
};

export const PrepaidStop: FC<PrepaidStop> = ({ f7router, event }) => {
  const prepaidsInfo = useStore(
    store,
    'getFirstAdvancePaymentsInfo',
  ) as Required<Prepaid>;
  const billsInfo = store.state.billsInfo;
  const { monthlyCharge } = getPrepaidChangeBillInfo(prepaidsInfo, billsInfo);

  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const { stopRestartHouseprepaid } = useHouseprepaidStopRestart();

  const handleSubmit = async () => {
    const stopRestartPrepaidParams = {
      m_customer_id: authInfo.m_customer_id,
      proc_kbn: 1,
    };
    await stopRestartHouseprepaid(stopRestartPrepaidParams);
  };

  return (
    <PopupPageTemplate
      id="PrepaidStop"
      title="積み立ての停止"
      pageName="PrepaidStop"
    >
      <div style={{ marginTop: '20px' }}>
        <div className="u-font-size-xl u-font-bold u-margin-bottom-md">
          積み立てを停止しますか？
        </div>
        <div className="u-font-size-xl u-font-bold u-padding-top-xs u-margin-bottom-sm">
          現在の積み立て内容
        </div>
        <hr className="u-margin-bottom-sm border" />
        <div className="u-font-size-lg reserve-amount u-margin-bottom-sm">
          <div className="u-font-bold u-padding-right-xl">積立額</div>
          <span>{monthlyCharge?.toLocaleString()} 円 / 月</span>
        </div>
        <hr className="u-margin-bottom-lg border" />
        <div className="u-font-size-xl u-font-bold u-margin-bottom-sm">
          注意事項・連絡事項
        </div>
        <hr className="u-margin-bottom-xs border" />
        <div className="u-margin-bottom-xs">
          <ul className="precautions">
            <li>
              積み立てを停止すると、ご利用中のプランの特典も受けられなくなります。
            </li>
            <li>チャージ済みの金額は引き続きお使いいただけます。</li>
            <li>
              2年間ご利用・チャージがされない場合は、有効期限切れとなり、チャージ済み金額がご利用いただけなくなります。ご注意ください。
            </li>
            <li>積み立てはいつでも再開いただけます。</li>
          </ul>
        </div>
        <hr className="u-margin-bottom-lg border" />
        <Row style={{ marginTop: '30px' }}>
          <Col width="20" />
          <Col width="60">
            <Button
              style={{ backgroundColor: '#ff4655' }}
              text="停止する"
              handleClickAction={handleSubmit}
            />
          </Col>
          <Col width="20" />
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col width="20" />
          <Col width="60">
            <Button
              style={{ color: '#232323', backgroundColor: '#efefef' }}
              text="キャンセル"
              handleClickAction={() => f7CustomBack(['#PrepaidStop'])}
            />
          </Col>
          <Col width="20" />
        </Row>
      </div>
    </PopupPageTemplate>
  );
};
