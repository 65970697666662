import { Checkbox as F7Checkbox } from 'framework7-react';
import { CheckboxProps as F7CheckboxProps } from 'framework7-react/components/checkbox';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import style from './Partial.module.scss';

interface CheckboxProps extends F7CheckboxProps {
  name: string;
  isBoolean?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  name,
  className,
  children,
  value,
  isBoolean = true,
  ...checkboxProps
}) => {
  const { control, setValue, trigger } = useFormContext();

  return (
    <label className={`${style['input-checkbox']} ${className}`}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value: val } }) => (
          <F7Checkbox
            onChange={(event) => {
              onChange(event);
              if (isBoolean) {
                return onChange(event);
              }

              setValue(name, event.target.checked ? event.target.value : false);
            }}
            defaultChecked={isBoolean ? val : val == value}
            value={!isBoolean ? value : true}
            {...checkboxProps}
          />
        )}
      />
      {children}
    </label>
  );
};
