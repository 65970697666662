import { Router } from 'framework7/types';
import { FC } from 'react';
import styles from './WarrantyHelpStyle.module.css';
import { ReactComponent as IconFileColorInside } from '@/assets/images/menu/icon-file-color-inside.svg';
import { ReactComponent as IconHelp } from '@/assets/images/menu/icon-help.svg';
import { ReactComponent as IconInfo } from '@/assets/images/menu/icon-info.svg';
// import { ReactComponent as IconReload } from '@/assets/images/menu/icon-reload.svg';
import { ReactComponent as IconCarMagnifyingGlass } from '@/assets/images/menu/icon_car-magnifying-glass.svg';

import { CustomList } from '@/components/uiParts/List/CustomList';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  findDefectAppendWarranty,
  switchWarrantyPartsTitleByCoverageType,
} from '@/utils/warranty';

interface WarrantyHelpProps {
  warrantyNo: string;
  f7router: Router.Router;
}
export const WarrantyHelp: FC<WarrantyHelpProps> = ({
  warrantyNo,
  f7router,
}) => {
  const { currentWarranty } = store.state;
  const warrantyInfo = findDefectAppendWarranty(currentWarranty);

  return (
    <RoundSheet>
      <h3 className={styles.help}>保証に関するヘルプ・規約</h3>
      <CustomList
        icon={<IconInfo />}
        iconClassName={styles.icon}
        fontSize="14px"
        path={paths.warrantyGuide}
        text="ご利用方法"
        f7router={f7router}
      />
      <CustomList
        icon={<IconCarMagnifyingGlass />}
        iconClassName={styles.icon}
        fontSize="14px"
        path={paths.warrantyParts2(warrantyNo)}
        text={switchWarrantyPartsTitleByCoverageType(
          warrantyInfo?.covering_type,
        )}
        f7router={f7router}
      />
      {/* <CustomList
        icon={<IconReload />}
        iconClassName={styles.icon}
        fontSize="14px"
        path={'#'}
        text="保証料の切り替え"
        f7router={f7router}
      /> */}
      <CustomList
        icon={<IconFileColorInside />}
        iconClassName={styles.icon}
        fontSize="14px"
        path={paths.warrantyTerms}
        text="保証規約"
        f7router={f7router}
      />
      <CustomList
        icon={<IconHelp />}
        iconClassName={styles.icon}
        fontSize="14px"
        path={paths.warrantyFaq}
        text="よくあるご質問"
        f7router={f7router}
      />
    </RoundSheet>
  );
};
