import { FC, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';
import { Checkbox } from '../Checkbox/Checkbox';
import { NoticeSettingType } from '../StrikeNoticeSettings/StrikeNoticeSettings';
import style from './NotificationFormStyle.module.css';

interface NotificationFormProps {
  notificationSettings: NoticeSettingType[];
  isInsuranceSubscription: boolean;
}

export const NotificationForm: FC<NotificationFormProps> = ({
  notificationSettings,
  isInsuranceSubscription,
}) => {
  const {
    reset,
    setValue,
    formState: { defaultValues },
  } = useFormContext();
  const handleOnChange = useCallback(
    (name: string) => {
      if (isInsuranceSubscription) {
        const setting = notificationSettings?.find(
          (setting) => setting.props === name,
        );
        const resetFormFields = Object.keys(defaultValues ?? [])?.map(
          (field) => [field, name === field],
        );
        const newForm = Object.fromEntries(resetFormFields);
        reset(newForm);
        setValue('emergencyType', setting?.emergencyType);
      }
    },
    [
      defaultValues,
      isInsuranceSubscription,
      notificationSettings,
      reset,
      setValue,
    ],
  );
  return (
    <div className={style.form}>
      {notificationSettings.map((setting) => (
        <div className={style.inputWrapper} key={setting.type}>
          <div className={style.checkBoxWrapper}>
            <div>
              <p className={style.checkBoxLabel}>{setting.name}</p>
              <p className={style.checkBoxRemark}>{setting.under_text}</p>
            </div>
            <Checkbox name={setting.props} handleOnChange={handleOnChange} />
          </div>
        </div>
      ))}
    </div>
  );
};
