import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { linkDriveOwnerApi } from '@/api/infoApi';
import { LinkDriveOwnerApiResponse } from '@/types/api/infoApi';
import { storeDispatch } from '@/utils/store';

interface LinkDriveOwnerApiParams {
  customer_id: number;
  stock_car_id: number;
  del_flg: number;
}

interface Args {
  params: LinkDriveOwnerApiParams;
  onSuccess?: (data?: LinkDriveOwnerApiResponse['data']) => void;
}

export const useSetStoreLinkDriveOwner = () => {
  const [data, setData] = useState<LinkDriveOwnerApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  // useEffectで使われた際に無限ループを起こさないようuseCallbackを使用
  const setStoreLinkDriveOwner = useCallback(async (args: Args) => {
    let isSuccessfully = false;
    try {
      setIsLoading(true);
      const { data } = await linkDriveOwnerApi({ params: args.params });
      setData(data);
      if (data.success) {
        isSuccessfully = true;
        storeDispatch(
          'setOwnerId',
          'ownerId',
          data.data.link_drive_owner.owner_id,
        );
        storeDispatch(
          'setSerialNumberDb',
          'serialNumberDb',
          data.data.link_drive_owner.serial_no,
        );
        storeDispatch(
          'setLinkDriveOwnerInfo',
          'linkDriveOwnerInfo',
          data.data.link_drive_owner,
        );
        args.onSuccess?.(data);
      }
    } catch (error) {
      setError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }

    return isSuccessfully;
  }, []);

  return {
    data,
    setStoreLinkDriveOwner,
    isLoading,
    error,
  };
};
