import { Card } from 'framework7-react';
import { FC } from 'react';
import style from './ProblemNotesStyles.module.scss';
interface ProblemNotesProps {}
const noteOpt = [
  '保証のご利用につきましては必ずお見積もり書の確認及び審査を伴います。',
  '審査結果連絡を待たずに部品手配依頼や修理着工依頼をしてしまいますといかなる内容の場合も保証適用外となります。',
  '部品交換が必要な場合は、中古部品の使用が最優先となります。中古部品の調達ができない場合はリビルト部品・社外新品・純正新品の優先順位での対応となります。',
  '代車費用につきましては、保証サービスに含まれません。※一部プランは除く',
  '受付いただきました工場にて保証修理対応をいただけない場合がございます。その場合、工場の移動をお願いさせていただくことがございます。',
];
export const ProblemNotes: FC<ProblemNotesProps> = () => {
  return (
    <Card className={`${style['card']} u-margin-bottom-0`}>
      <div
        className={`${style.title} u-padding-bottom-xs u-margin-top-0`}
        style={{ color: 'red' }}
      >
        <i className="icon-alert" />
        <p>ご注意事項</p>
      </div>
      <ul className="u-padding-top-0 u-padding-left-md">
        {noteOpt.map((item, index) => (
          <li className={style['content']} key={index}>
            {item}
          </li>
        ))}
      </ul>
    </Card>
  );
};
