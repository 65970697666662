import { FC } from 'react';
import { Shopmap } from './Shopmap/Shopmap';

type ShopmapPageProps = {
  pageTitle?: string;
  lat?: number;
  lon?: number;
  parkingAddr?: string;
  isIcon?: boolean;
};

export const ShopmapPage: FC<ShopmapPageProps> = ({
  pageTitle,
  lat,
  lon,
  parkingAddr,
  isIcon = false,
}) => {
  return (
    <Shopmap
      pageTitle={pageTitle}
      lat={lat}
      lon={lon}
      parkingAddr={parkingAddr}
      isIcon={isIcon}
    />
  );
};
