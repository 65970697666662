import { FC, useEffect, useRef, useState } from 'react';
import style from './ChangePhotoColor.module.scss';
import {
  CarIcon1,
  CarIcon2,
  CarIcon3,
  CarIcon4,
  CarIcon5,
  CarIcon6,
  CarIcon7,
  CarIcon8,
} from '@/components/pages/Car/Car/CarIcon';

const CarIconComponents = [
  CarIcon1,
  CarIcon2,
  CarIcon3,
  CarIcon4,
  CarIcon5,
  CarIcon6,
  CarIcon7,
  CarIcon8,
];

const colors = [
  '#FFFFFF',
  '#C9C9C9',
  '#868686',
  '#F75959',
  '#FF9A62',
  '#F4E55C',
  '#8ED475',
  '#5DB998',
  '#B1C7FD',
  '#F5A5AA',
  '#C483F8',
  '#A4876C',
];

interface ChangeColorPhotoProps {
  carIconIdexSelected?: number;
  onColorChange: (file: string) => void;
}

export const ChangeColorPhoto: FC<ChangeColorPhotoProps> = ({
  carIconIdexSelected = 0,
  onColorChange,
}) => {
  const [color, setColor] = useState('#FFFFFF');
  const svgRefs = [
    useRef<SVGElement>(),
    useRef<SVGElement>(),
    useRef<SVGElement>(),
    useRef<SVGElement>(),
    useRef<SVGElement>(),
    useRef<SVGElement>(),
    useRef<SVGElement>(),
    useRef<SVGElement>(),
  ];

  const render = async () => {
    const svgString = new XMLSerializer().serializeToString(
      svgRefs[carIconIdexSelected].current as SVGElement,
    );
    const svgDataUri = URL.createObjectURL(
      new Blob([svgString], { type: 'image/svg+xml' }),
    );

    onColorChange &&
      onColorChange(
        URL.createObjectURL(new Blob([svgString], { type: 'image/svg+xml' })),
      );
  };

  const toChangeColor = (_color: string) => {
    setColor(_color);
  };

  useEffect(() => {
    render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const circlesPerRow = 6;
  const rows = colors.length / circlesPerRow;

  return (
    <div className={style['change-color-container']}>
      {CarIconComponents.map((CarIconComponent, index) => (
        <div
          className={`${style['svg-wrap']} ${
            index == carIconIdexSelected ? '' : style['d-none']
          }`}
          key={`car-icon-component-${index}`}
        >
          <CarIconComponent reference={svgRefs[index]} color={color} />
        </div>
      ))}
      <p>お好きな色を選んで車の色を変えましょう</p>
      <div className={style['color-group']}>
        {Array.from({ length: rows }, (_, rowIndex) => (
          <div key={rowIndex} className={style['color-row']}>
            {colors
              // eslint-disable-next-line no-magic-numbers
              .slice(rowIndex * circlesPerRow, (rowIndex + 1) * circlesPerRow)
              .map((_color, index) => (
                <div
                  onClick={() => toChangeColor(_color)}
                  key={`color-item-${index}`}
                  className={style['color-item']}
                  style={{
                    background: _color,
                    border: _color === '#FFFFFF' ? '2px solid #EFEFEF' : '',
                  }}
                >
                  {_color == color ? (
                    <i
                      className={`icon-check ${style['icon-check']}`}
                      style={{
                        color: _color === '#FFFFFF' ? '#323232' : '#FFFFFF',
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};
