import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { readModifyInquiryApi } from '@/api/inquiryApi';
import { ReadModifyInquiryParams } from '@/types/api/queryApi';

export const useReadModifyInquiry = () => {
  const [error, setError] = useState<AxiosError>();

  const readModifyInquiry = async (params: ReadModifyInquiryParams) => {
    try {
      f7.preloader.show();
      await readModifyInquiryApi(params);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return { readModifyInquiry, error };
};
