import React, { FC } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { MailDayInput } from './const';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { RhfDropdown } from '@/components/uiParts/ReactHookForm/RhfDropdown/RhfDropdown';
import {
  getMonthOptions,
  getDayInMonthOptions,
  getYearOptions,
} from '@/utils/date';

interface MailDayFormProps {
  watch: UseFormWatch<MailDayInput>;
  error?: string;
  isValid: boolean;
}

export const MailDayForm: FC<MailDayFormProps> = ({
  watch,
  isValid = false,
  error = null,
}) => {
  const year = watch('year');
  const month = watch('month');
  const day = watch('day');
  const yearOptions = getYearOptions(new Date().getFullYear() - 1, 1);
  const monthOptions = getMonthOptions();
  const daysOptions = getDayInMonthOptions(Number(year), Number(month));

  return (
    <div id="mail-day-input">
      <div className="label-wrap">
        <p className="label">送付した日付を入力してください</p>
        <span className="label-tips required">必須</span>
      </div>
      <div className="input-container">
        <div className="input-wrap">
          <RhfDropdown
            required
            className={`input year ${error && 'error-input'}`}
            name="year"
          >
            <option value="" disabled hidden />
            {yearOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </RhfDropdown>
          年
          <RhfDropdown
            required
            className={`input month ${error && 'error-input'}`}
            name="month"
          >
            <option value="" disabled hidden />
            {monthOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </RhfDropdown>
          月
          <RhfDropdown
            required
            className={`input day ${error && 'error-input'}`}
            name="day"
          >
            <option value="" disabled hidden />
            {daysOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </RhfDropdown>
          日
        </div>
        <div>
          <CheckIcon isValid={isValid} />
        </div>
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};
