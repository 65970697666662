import drivingFunction1 from 'src/assets/images/user-tasks/driving-function/driving-function-1.png';
import drivingFunction10 from 'src/assets/images/user-tasks/driving-function/driving-function-10.png';
import drivingFunction11 from 'src/assets/images/user-tasks/driving-function/driving-function-11.png';
import drivingFunction12 from 'src/assets/images/user-tasks/driving-function/driving-function-12.png';
import drivingFunction2 from 'src/assets/images/user-tasks/driving-function/driving-function-2.png';
import drivingFunction3 from 'src/assets/images/user-tasks/driving-function/driving-function-3.png';
import drivingFunction4 from 'src/assets/images/user-tasks/driving-function/driving-function-4.png';
import drivingFunction5 from 'src/assets/images/user-tasks/driving-function/driving-function-5.png';
import drivingFunction6 from 'src/assets/images/user-tasks/driving-function/driving-function-6.png';
import drivingFunction7 from 'src/assets/images/user-tasks/driving-function/driving-function-7.png';
import drivingFunction8 from 'src/assets/images/user-tasks/driving-function/driving-function-8.png';
import drivingFunction9 from 'src/assets/images/user-tasks/driving-function/driving-function-9.png';
import EngineRoomInspection1 from 'src/assets/images/user-tasks/engine-room-inspection/engine-room-inspection-1.png';
import EngineRoomInspection2 from 'src/assets/images/user-tasks/engine-room-inspection/engine-room-inspection-2.png';
import EngineRoomInspection3 from 'src/assets/images/user-tasks/engine-room-inspection/engine-room-inspection-3.png';
import EngineRoomInspection4 from 'src/assets/images/user-tasks/engine-room-inspection/engine-room-inspection-4.png';
import EquipmentInspection1 from 'src/assets/images/user-tasks/equipment-inspection/equipment-inspection-1.png';
import EquipmentInspection2 from 'src/assets/images/user-tasks/equipment-inspection/equipment-inspection-2.png';
import EquipmentInspection3 from 'src/assets/images/user-tasks/equipment-inspection/equipment-inspection-3.png';
import EquipmentInspection4 from 'src/assets/images/user-tasks/equipment-inspection/equipment-inspection-4.png';
import ExteriorInspection1 from 'src/assets/images/user-tasks/exterior-inspection/exterior-inspection-1.png';
import ExteriorInspection2 from 'src/assets/images/user-tasks/exterior-inspection/exterior-inspection-2.png';
import ExteriorInspection3 from 'src/assets/images/user-tasks/exterior-inspection/exterior-inspection-3.png';
import ExteriorInspection4 from 'src/assets/images/user-tasks/exterior-inspection/exterior-inspection-4.png';
import ExteriorInspection5 from 'src/assets/images/user-tasks/exterior-inspection/exterior-inspection-5.png';
import InsideVehicleInspection1 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-1.png';
import InsideVehicleInspection10 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-10.png';
import InsideVehicleInspection11 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-11.png';
import InsideVehicleInspection12 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-12.png';
import InsideVehicleInspection13 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-13.png';
import InsideVehicleInspection14 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-14.png';
import InsideVehicleInspection15 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-15.png';
import InsideVehicleInspection16 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-16.png';
import InsideVehicleInspection2 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-2.png';
import InsideVehicleInspection3 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-3.png';
import InsideVehicleInspection4 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-4.png';
import InsideVehicleInspection5 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-5.png';
import InsideVehicleInspection6 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-6.png';
import InsideVehicleInspection7 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-7.png';
import InsideVehicleInspection8 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-8.png';
import InsideVehicleInspection9 from 'src/assets/images/user-tasks/inside-vehicle-inspection/inside-vehicle-inspection-9.png';
export type TasksOptionType = {
  title: string;
  description: string;
  remark?: string;
  listChecked: string[];
  listImage: string[];
};

export const exteriorInspection: TasksOptionType[] = [
  {
    title: 'ガラス状態とステッカーの点検',
    description: 'ガラスとステッカーの状態を確認してください。',
    listChecked: [
      'ガラスに割れがないか',
      '四角い車検ステッカーと丸い点検ステッカーがあるか',
    ],
    listImage: [ExteriorInspection1, ExteriorInspection2],
  },
  {
    title: '傷とコーティング状態の点検',
    description:
      '車両の傷の状態とコーティング状態を確認してください。ボンネットを起点に左回りで車両を確認します。',
    listChecked: ['購入時から傷が増えてないか', 'コーティングはかかっているか'],
    listImage: [ExteriorInspection3],
  },
  {
    title: '装着タイヤの点検',
    description: 'タイヤ状態を確認してください。',
    listChecked: [
      'タイヤの空気圧が異常に低くないか',
      '左右/前後と見比べて明らかに違いがないかどうか',
    ],
    listImage: [ExteriorInspection4],
  },
  {
    title: 'ロックナットアダプターの確認',
    description:
      'ホイールを止めているナットに特殊形状のタイプが取り付いている場合、車両に特殊形状ナット脱着用アダプタ(通称：ロックナットアダプター)が車内にあるかのか確認してください。<br/>ドアポケットか、グローブボックスか、トランクルームに車載されているケースが多いため、そのあたりから探索を行います。',
    remark:
      '(ロックナットが取り付いていない車両も多く存在します。その場合は割愛下さい)',
    listChecked: ['ロックナットアダプターがありましたら正常です。'],
    listImage: [ExteriorInspection5],
  },
];
export const equipmentInspection: TasksOptionType[] = [
  {
    title: '給油機能点検',
    description:
      'ハンドル後部下にある給油口オープナーレバーを手前に引き、車両後方にある給油口が開いたことを確認してください。',
    remark:
      '(給油口オープナーレバーの場所は車種によって異なります。車両取扱説明書をご参照の上、ご確認ください)',
    listChecked: [
      'オープナーレバーを手前に引くと、車両後方にある給油口が開くか',
    ],
    listImage: [EquipmentInspection1],
  },
  {
    title: '車載部品の点検',
    description:
      'スペアタイヤのチェックを行ないます。トランクルーム下部と車両後部の下回りに取り付けられているケースが多いです。',
    remark:
      '(取扱説明書にスペアタイヤが車載されていない旨の記載がある場合は、割愛ください)',
    listChecked: ['スペアタイヤが車載されているか'],
    listImage: [EquipmentInspection2],
  },
  {
    title: 'キーレスキーの確認',
    description:
      'キーレスキーのスイッチを確認します。閉めるボタンを押した後、開けるボタンを押してください。',
    remark:
      '(操作方法は車種によって異なります。車両取扱説明書をご参照の上、ご確認ください。また、キーレスキーの機能がない場合は、割愛ください)',
    listChecked: ['開閉の反応があるか', 'Autoミラーの場合同時に閉まるか'],
    listImage: [EquipmentInspection3],
  },
  {
    title: 'シートベルトの確認',
    description:
      '全シートでシートベルトを引き出し、実際に人が座っている状態で装着をします。その後バックルを外してシートベルトを戻します。',
    remark:
      '(ロックナットが取り付いていない車両も多く存在します。その場合は割愛ください。)',
    listChecked: [
      'シートベルトが違和感なく引き出せるか',
      '簡単にバックルにロックができ、かつ戻りがスムーズか',
    ],
    listImage: [EquipmentInspection4],
  },
];
export const engineRoomInspection: TasksOptionType[] = [
  {
    title: 'ボンネット内部点検',
    description:
      '運転席足元にあるボンネットロック解除レバーを引き、ロック解除レバーを操作しながらボンネットを持ち上げます。ボンネット内で異様な匂いやモノがないか確認してください。命の危険が有りますので、必ずエンジンを切った状態で点検をしてください。',
    remark:
      '(ボンネットロック解除レバーの場所は車種によって異なります。持ち上げた状態で固定されず下がってくる場合は、ボンネットステーを使って固定します。ボンネットステーの取り付け場所は車両によって異なりますので、必要に応じて車両取扱説明書を確認下さい。)',
    listChecked: [
      '開けてすぐにガソリンやオイルのような匂いがしないか',
      '異物がないか',
    ],
    listImage: [EngineRoomInspection1],
  },
  {
    title: 'エンジンオイル量の点検',
    description:
      'エンジンルーム内にある丸い穴の空いたプラスチック(オイルゲージ)を引き抜きます。一度オイルゲージを引き抜いて、先端部分に付着しているオイルを拭き取ります。<br/>その後、元に戻して再度引き抜き「上限」と「下限」の間にオイルが付着しているかを確認します。<br/>命の危険が有りますので、必ずエンジンを切った状態で点検をしてください。',
    remark:
      '(オイルゲージの取り付け場所は車両によって異なるので、詳細場所は車両取扱説明書を参照下さい)',
    listChecked: ['「上限」と「下限」の間にオイルが付着しているか'],
    listImage: [EngineRoomInspection2],
  },
  {
    title: 'ブレーキオイル量の点検',
    description:
      'エンジンルーム向かって左奥の、黄色の液体の入ったタンクに刻まれている、上限と下限のオイル量の差を目視で確認します。<br/>命の危険が有りますので、必ずエンジンを切った状態で点検をしてください。',
    listChecked: ['上限と下限の間までオイルが入っているか'],
    listImage: [EngineRoomInspection3],
  },
  {
    title: '冷却水の確認',
    description:
      'エンジンルーム内にある、緑色またはピンク色をした液体が入っているプラスチックタンクに刻まれている上限と下限の冷却水量の差を目視で点検します。',
    remark:
      '(タンクの位置や色は車種によって異なります。車両取扱説明書をご参照の上、確認してください。)',
    listChecked: ['上限と下限の間までオイルが入っているか'],
    listImage: [EngineRoomInspection4],
  },
];
export const insideVehicleInspection: TasksOptionType[] = [
  {
    title: 'ドアロックの点検',
    description:
      '運転席ドアの内側ドアロックスイッチを開閉操作を行い、ドアロックの動作を確認します。',
    remark:
      '(一部車両では操作が違い検査ができません。車両取扱説明書をご参照の上ご確認下さい)',
    listChecked: [
      '全てのドアがロック時は開かず、解除時は開くか',
      '作動中に異音がしないか',
    ],
    listImage: [InsideVehicleInspection1],
  },
  {
    title: 'パワーウィンドウの点検',
    description:
      '運転席ドアの内側にある全パワーウィンドウスイッチの開閉操作を行い、パワーウィンドウの動作を確認します。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      '全てのパワーウィンドウで全開、全閉できるか',
      '作動中に異音がしないか',
    ],
    listImage: [InsideVehicleInspection2],
  },
  {
    title: 'オートスライドドアの点検',
    description:
      'オート機能の付いている全てのスライドドアの内外両側どちらも同様にノブを引き操作して確認します。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      '自動で最後まで開き、最後まで閉じるか',
      '作動中に異音がしないか',
    ],
    listImage: [InsideVehicleInspection3],
  },
  {
    title: 'オートクローザーの点検',
    description:
      'ドアの開閉をして確認します。<br/>ドアを開き、半ドアにします。運転席ドアだけではなく、オートクローザーの取り付いている全てのドアで行います。',
    remark:
      '(一部車両の機能となりますので取り付いていない車両では、こちらの点検項目はご割愛ください)',
    listChecked: ['半ドア状態から、完全にドアが閉じるか'],
    listImage: [InsideVehicleInspection4],
  },
  {
    title: 'パワーバックドアの確認',
    description: 'バックドアのスイッチの開閉操作をして確認します。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      '「開けるボタン」操作のみで自動的にバックドアが開きバックドアが上昇するか',
      '「閉じるボタン」操作のみでバックドアが下降し自動で締まるか',
    ],
    listImage: [InsideVehicleInspection5],
  },
  {
    title: 'サンルーフの確認',
    description:
      '車内中央天井にあるスイッチを操作して確認します。<br/>サンルーフの全開ボタン、全閉ボタン、チルトボタンの操作を行います。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      'ボタン操作に追従してサンルーフが可動するか',
      '作動中に異音がしないか',
    ],
    listImage: [InsideVehicleInspection6],
  },
  {
    title: 'エンジンスタートの確認',
    description:
      '車両の中にキーレス付きのキーを持ち込み、ハンドル右後ろのエンジンスタートスイッチを操作し、室内でエンジン音を確認します。エンジンスタートスイッチを押しながら回してエンジンを掛け、数秒間アイドリングをして、アクセルを少し踏み、停車状態でエンジン回転数のみを上げます。',
    remark:
      '(一部車両では操作が違い検査ができません。車両取扱説明書をご参照の上、確認して下さい)',
    listChecked: [
      '前方(エンジン側)からは勿論、下の方や後方からも異常な音がしないか<br/>(異常と感じられる音が通常作動音である事もありますので気になる音がしましたら、販売スタッフまで確認をしてください。)',
      '作動中に異音がしないか',
    ],
    listImage: [InsideVehicleInspection7],
  },
  {
    title: '警告灯の確認',
    description:
      'メーターパネルへの警告灯表示の有無で点検します。<br/>全てのドアを締め、シートベルトをしてエンジンを始動させ、始動直後に全ての警告灯が点灯します。その後数秒を掛けて全てが消灯します。',
    remark:
      '(ライトを点灯させている場合、警告灯が点灯するものもあります。ライトの消し忘れ防止のための機能ですので、不具合ではございません。)',
    listChecked: ['エンジン始動後10秒経過後に点灯している警告灯がないか'],
    listImage: [InsideVehicleInspection8],
  },
  {
    title: 'ホーンの確認',
    description: 'ホーンボタンの操作を行い、ホーンが鳴るかを確認します。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい。)',
    listChecked: ['音の大きさ及び音色（音量）が一定か'],
    listImage: [InsideVehicleInspection9],
  },
  {
    title: 'パワーシートの確認',
    description:
      'フロントシートの外側にあるスイッチを操作して確認します。シート調整の全ての操作を行って下さい。',
    remark: '(手動シート調整車両では、こちらの点検項目は割愛できます。)',
    listChecked: ['スイッチの操作どおりに全ての動きが実行されるか'],
    listImage: [InsideVehicleInspection10],
  },
  {
    title: 'エアコン温度調整の確認',
    description:
      'エアコン操作パネルを操作して確認します。<br/>エアコンスイッチをONにして冷房を入れ、温度を一番低くします。その後、温度を一番高くします。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      '温度を一番低くして冷たい風が出て、かつ温度を一番高くして温かい風が出るか',
    ],
    listImage: [InsideVehicleInspection11],
  },
  {
    title: 'エアコン風向・風量の確認',
    description:
      'エアコン操作パネルを操作して確認します<br/>風向を「上半身に送風」にして、風量を最大にします。その後、風量を最小にします',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['風向アイコンと実際に風の出ている範囲を確認し、差異がないか'],
    listImage: [InsideVehicleInspection12],
  },
  {
    title: 'デフロスターの確認',
    description:
      'エアコン操作パネルを操作して確認します。<br/>リアガラスの熱線をONにしたまま5分程度放置した後で、直接リアガラスを触り確認します。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['ガラス表面が暖かくなっているか'],
    listImage: [InsideVehicleInspection13],
  },
  {
    title: 'オーディオ類の確認',
    description:
      'オーディオのラジオをONにして音量をやや上げながら確認します。オーディオ操作は取付オーディオによって異なるため、説明書を参照しながら確認ください。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['全てのスピーカーより音が出ているか'],
    listImage: [InsideVehicleInspection14],
  },
  {
    title: 'ナビゲーションの確認',
    description:
      'ナビゲーションを操作して確認します。<br/>概ねのナビでは「現在地」ボタンを押すとナビが起動します。ナビ操作は取付ナビによって異なるため、説明書を参照しながら確認ください。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['現在地表示が正いか', 'タッチパネルの反応に問題がないか'],
    listImage: [InsideVehicleInspection15],
  },
  {
    title: 'バックモニターの確認',
    description:
      'シフトをR(リバース)にして確認します。<br/>各種モニター設定は取付オーディオによって異なるため、説明書を参照しながら確認してください。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['画面がバックモニターに切り替わるか'],
    listImage: [InsideVehicleInspection16],
  },
];
// Add more data
export const drivingFunction: TasksOptionType[] = [
  {
    title: 'ウォッシャー噴霧状態の確認',
    description:
      'ハンドルの左後ろ(輸入車では右後ろ)にあるレバーを手前に引き、ウィンドウウォッシャーの噴霧を確認します。',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      'ウィンドウウォッシャーが左右から噴霧され、しっかりとフロントガラスにあたるか(車種によっては真ん中から両脇に 噴霧するタイプもございますので注意してください)',
      'その後ワイパーが作動するか',
    ],
    listImage: [drivingFunction1],
  },
  {
    title: 'ワイパーの点検',
    description:
      'ハンドルの左後ろ(輸入車では右後ろ)にあるレバーを上下に操作します。以下の段階の動作を確認します。<br/>最上部：ワンタッチ→一回だけワイパー動作<br/>2段目：停止→ワイパー動作なし<br/>3段目：間欠→間隔を開けながらワイパー動作<br/>4段目：ステージ1→間隔なしでワイパー動作<br/>5段目：ステージ2→間隔無しで高速でワイパー動作',
    remark:
      '(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['全てのレバーポジションで規定の動作を確認できるか'],
    listImage: [drivingFunction2],
  },
  {
    title: 'スモールライトの点検',
    description: 'ハンドルの右後ろ(輸入車では左後ろ)にあるレバーを操作します。',
    remark:
      '(車両によって操作方法が異なります。車両取扱説明書をご参照の上、ご確認ください)<br/>(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      '車両前方： 薄暗いライトが左右ヘッドライトで点灯するか',
      '車両後方： 薄暗いライトが左右テールライトで点灯し、ナンバーがライトで 照らされるか',
    ],
    listImage: [drivingFunction3],
  },
  {
    title: 'ヘッドライトの点検',
    description:
      'ハンドルの右後ろ(輸入車では左後ろ)にあるレバー先端部を時計回りに回して、図の矢印に合せます。ロービームを点灯させ、壁にライトを照射させたままの状態でレバーを後ろに押すことで、ロービームがハイビームに切り替わります。',
    remark:
      '(車両によって操作方法が異なります。車両取扱説明書をご参照の上、ご確認ください。)<br/>(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      '左右ヘッドライトで車両前方の明るいライトが点灯するか',
      'ハイビーム設定で壁に照射されたライトの高さが上がるか',
    ],
    listImage: [drivingFunction4],
  },
  {
    title: 'ウィンカーの点検',
    description:
      'ハンドルの右後ろ(輸入車では左後ろ)にあるレバーを下に押すと左のウィンカーが点滅します。<br/>操作された方向のウィンカーが全て(前後、真ん中、メーター)点滅するかを確認します。',
    remark:
      '(輸入車ではそれぞれ逆になります)(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['左右の操作両方にて点滅の確認ができるか'],
    listImage: [drivingFunction5],
  },
  {
    title: 'フォグライトの点検',
    description:
      'ハンドルの右後ろ(輸入車では左後ろ)にあるレバーを操作します。<br/>レバー中間部に回転できるツマミがある場合は、フォグライトが搭載されていますので、ダイヤルをONに合せます。',
    remark:
      '(車両によって操作方法が異なります。車両取扱説明書をご参照の上、ご確認ください。また機能がない車両の場合は、割愛してください。)<br/>(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['左右ヘッドライト下にあるライト(フォグライト)で点灯するか'],
    listImage: [drivingFunction6],
  },
  {
    title: 'ブレーキライトの点検',
    description:
      '運転席足元にあるペダルの内、右から2番目のペダル(ブレーキペダル)を深く踏み込みます',
    remark:
      '(車両によって操作方法が異なります。車両取扱説明書をご参照の上、ご確認ください)<br/>(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['左右と真ん中のそれぞれのブレーキライトが点灯するか'],
    listImage: [drivingFunction7, drivingFunction8],
  },
  {
    title: 'ルームライトの点検',
    description:
      '車内の天井にあるルームライトのスイッチを左右に操作して切り替えます。<br/>通常3段階で、「ライト常時ON」「ライト常時OFF」「ドアを開けた時のみON」をそれぞれ操作します。',
    remark:
      '(車両によって操作方法が異なります。車両取扱説明書をご参照の上、ご確認ください)<br/>(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      '車内：「ライト常時ON」で点灯し、「ライト 常時OFF」で消灯するか',
      '車外：「ドアを開けた時のみON」で、 ドアを開いた際にルームライトが点灯し、 閉じて消灯するか<br/>(一部エンジンを掛けないと消灯しない車もございます)',
    ],
    listImage: [drivingFunction9],
  },
  {
    title: 'イルミネーションライトの点検',
    description:
      'ハンドルの右後ろ(輸入車では左後ろ)にあるレバー先端部を時計回りに回して、図の矢印に合せます。',
    remark:
      '(車両によって操作方法が異なります。車両取扱説明書をご参照の上、ご確認ください)<br/>(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: [
      'メーターパネル・エアコン操作パネル・オーディオ・ナビ・パワーウィンドウスイッチなど、イルミネーションランプが全て点灯するか',
    ],
    listImage: [drivingFunction10],
  },
  {
    title: 'オートライトの点検',
    description:
      'ハンドルの右後ろ(輸入車では左後ろ)にあるレバーを操作します。<br/>レバー先端部を時計回りに回して、図の矢印に合せます。確認用でフロントガラスに日光が 照射されないように防ぐだけでも点検は可能です',
    remark:
      '(車両によって操作方法が異なります。また、装備がない車種もございます。車両取扱説明書をご参照の上、ご確認ください)',
    listChecked: [
      '日光が指しているときにはライトが消灯し、日光が指していないとき(夜やトンネルなど)にはライトが点灯しているか',
    ],
    listImage: [drivingFunction11],
  },
  {
    title: 'ハザードの確認',
    description: '車両中央あたりにあるハザードスイッチを押して確認します。',
    remark:
      '(車輛中央あたりにあるハザードスイッチを押して確認します)(バッテリー上がり防止の為、検査中はエンジンを始動させながら確認して下さい)',
    listChecked: ['全てのウィンカーの点滅を確認出来るか'],
    listImage: [drivingFunction12],
  },
];
