import { FC } from 'react';
import { ScanHistoryCard } from '../ScanHistoryCard/ScanHistoryCard';
import style from './ScanHistoryStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { useScanHistory } from '@/hooks/useScanHistory';

// ====== ScanHistory ======
// スキャン履歴を配列で受け取る（scanResults）
// ストアに履歴の配列を保存する（update関数の中で）
// 履歴をmapメソッドを用いて繰り返し表示する
// 各履歴のidをスキャン履歴詳細に渡す

// ====== ScanHistoryDetail ======
// 渡ってきたidをもとにスキャン履歴をフィルターして該当のデータを取り出す
// データをもとに画面表示する
// {
//  id: 1
//  powertrain: '',
//  chassis: '',
// }
// エラー内容を加工する
// JSONをparseしてそれぞれの配列を結合
// 結合した配列をストアに保存する
// 画面にエラー配列を繰り返し表示する
// 異常アラート画面のurlにエラー配列のインデックスを渡す

// ======= AbnormalAlert =======
// 渡ってきたインデックスをもとにストアに保存されているエラー配列をフィルターして
// 該当のエラー内容を出力する

// TODO: LinkDrive用のテストアカウントが用意され次第動作確認
export const ScanHistory: FC = () => {
  const [yearlyScanHistories, { updateScanResult }] = useScanHistory();

  return (
    <PageTemplate pageName="ScanHistory" title="診断履歴" showNavBack>
      <div className={style.card}>
        {yearlyScanHistories?.map((yearlyScanHistory) => (
          <div key={yearlyScanHistory[0]} className={style['scan-history']}>
            <h3 className={style.title}>{yearlyScanHistory[0]}年</h3>

            {yearlyScanHistory[1].map((scanResult) => (
              <div key={scanResult.id} className={style['scan-history-card']}>
                <ScanHistoryCard
                  // TODO: どこで実施したのかを動的に変える
                  storeName="渋谷"
                  scanResult={scanResult}
                />
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className={style['button-wrap']}>
        {/* TODO: 更新処理関数渡す（関数は作成済み） */}
        <Button
          className={style.button}
          handleClickAction={() => {
            updateScanResult();
          }}
        >
          <i className={`icon-reload-refresh ${style.icon}`} />
          更新する
        </Button>
      </div>
    </PageTemplate>
  );
};
