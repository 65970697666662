import { FC } from 'react';
import { TermsOfUse } from './TermsOfUse/TermsOfUse';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const TermsOfUsePage: FC = () => {
  const { data } = useTerm(termType.APP);
  const html = data?.term;
  return (
    <PageTemplate pageName={'TermsOfUse'} title={'利用規約'} showNavBack>
      <TermsOfUse html={html} />
    </PageTemplate>
  );
};
