import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './Partial.module.scss';

interface PhoneNumberInputProps {
  name: string;
  value?: string;
  hasError?: boolean;
}

export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  name,
  hasError = false,
}) => {
  const { register, trigger } = useFormContext();

  return (
    <div
      className={`${style['input-phone-number']} ${
        hasError ? style['input-has-error'] : ''
      }`}
    >
      <input
        type="text"
        maxLength={3}
        minLength={3}
        placeholder="000"
        onInput={() => trigger(name)}
        {...register(`${name}.first`)}
      />
      <span className={style['divider']}>-</span>
      <input
        type="text"
        maxLength={4}
        minLength={4}
        placeholder="0000"
        onInput={() => trigger(name)}
        {...register(`${name}.second`)}
      />
      <span className={style['divider']}>-</span>
      <input
        type="text"
        maxLength={4}
        minLength={4}
        placeholder="0000"
        onInput={() => trigger(name)}
        {...register(`${name}.third`)}
      />
    </div>
  );
};
