import { Card } from 'framework7-react';
import { FC } from 'react';
import style from '../ProblemGuide/ProblemGuideStyles.module.css';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { paths } from '@/config/paths';

interface ProblemInsuranceProps {}

export const ProblemInsurance: FC<ProblemInsuranceProps> = () => {
  return (
    <Card className={`${style['card']} u-margin-bottom-0 u-padding-bottom-md`}>
      <h2 className="u-padding-bottom-sm">保証適用について</h2>
      <div style={{ fontSize: '14px', fontWeight: 400 }}>
        修理が必要になった場合、ご契約中の保証が適用できる場合があります。
        <br />
        詳しくは、ご契約中の保証プランをご参照ください。
      </div>
      <div className="u-margin-top-sm">
        <IconCard
          iconType="file-color-inside"
          color="gray"
          headingFontSize="14px"
          iconFontSize="25px"
          iconColor="#1e55e6"
          isTransition
          heading={'ご契約中の保証プラン'}
          path={paths.warrantyParts}
        />
      </div>
    </Card>
  );
};
