import { differenceInCalendarDays, isValid, parse } from 'date-fns';
import * as yup from 'yup';
const requiredText = '必須項目です。';

const mailDayTest = (
  value: string | null | undefined,
  textContext: yup.TestContext,
) => {
  const { year, month, day } = textContext.parent;
  const mailDay = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
  return isValid(mailDay) && differenceInCalendarDays(mailDay, Date.now()) <= 0;
};

const deliveryDateTest = (
  value: string | null | undefined,
  textContext: yup.TestContext,
) => {
  const { year, month, day } = textContext.parent;
  const deliveryDate = parse(
    `${year}-${month}-${day}`,
    'yyyy-MM-dd',
    new Date(),
  );
  const currentDate = new Date();
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  return (
    isValid(deliveryDate) &&
    deliveryDate <= currentDate &&
    deliveryDate >= oneMonthAgo
  );
};

export const validationSchema = yup
  .object()
  .shape({
    delivery_preferred_date_1: yup.string().required(requiredText),
    delivery_preferred_date_2: yup.string().required(requiredText),
    delivery_preferred_date_3: yup.string().required(requiredText),
    delivery_preferred_hm_1: yup.string().required(requiredText),
    delivery_preferred_hm_2: yup.string().required(requiredText),
    delivery_preferred_hm_3: yup.string().required(requiredText),
  })
  .test('duplicate', '既に入力された日付が含まれています', function (values) {
    const {
      delivery_preferred_date_1,
      delivery_preferred_hm_1,
      delivery_preferred_date_2,
      delivery_preferred_hm_2,
      delivery_preferred_date_3,
      delivery_preferred_hm_3,
    } = values;

    const date1 = new Date(delivery_preferred_date_1 || '');
    const date2 = new Date(delivery_preferred_date_2 || '');
    const date3 = new Date(delivery_preferred_date_3 || '');

    if (
      (date1.getTime() === date2.getTime() &&
        delivery_preferred_hm_1 === delivery_preferred_hm_2) ||
      (date1.getTime() === date3.getTime() &&
        delivery_preferred_hm_1 === delivery_preferred_hm_3) ||
      (date2.getTime() === date3.getTime() &&
        delivery_preferred_hm_2 === delivery_preferred_hm_3)
    ) {
      throw new yup.ValidationError(
        '既に入力された日付が含まれています',
        values,
        'duplicate',
      );
    }
    return true;
  });

export const mailDaySchema = yup.object().shape({
  year: yup.string().nullable().test('year', '年は必須です', mailDayTest),
  month: yup.string().nullable().test('month', '月は必須です', mailDayTest),
  day: yup.string().nullable().test('day', '日は必須です', mailDayTest),
});

export const deliveryDateSchema = yup.object().shape({
  deliveredDate: yup.object().shape({
    year: yup
      .string()
      .nullable()
      .test('year', '納車日は正しくありません。', deliveryDateTest),
    month: yup
      .string()
      .nullable()
      .test('month', '納車日は正しくありません。', deliveryDateTest),
    day: yup
      .string()
      .nullable()
      .test('day', '納車日は正しくありません。', deliveryDateTest),
  }),
  currentCheckbox: yup.number().oneOf([yup.ref('targetedCheckox'), null], ''),
});
