import { Router } from 'framework7/types';
import { FC } from 'react';
import { LoginTelephone } from './LoginTelephone/LoginTelephone';
import { store } from '@/config/store';
import { getAffiliateIdFromThemeId } from '@/utils/shop';

interface LoginTelephonePageProps {
  f7router: Router.Router;
}

export const LoginTelephonePage: FC<LoginTelephonePageProps> = ({
  f7router,
}) => {
  const themeId = store.state.themeId;
  return (
    <LoginTelephone
      f7router={f7router}
      affiliateId={getAffiliateIdFromThemeId(themeId)}
    />
  );
};
