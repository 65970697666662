import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { registerInquiryDetailApi } from '@/api/inquiryApi';
import { RegisterInqueryDetailParams } from '@/types/api/queryApi';

export const useRegisterInqueryDetail = () => {
  const [error, setError] = useState<AxiosError>();

  const registerInqueryDetail = async (params: RegisterInqueryDetailParams) => {
    try {
      f7.preloader.show();
      const res = await registerInquiryDetailApi(params);
      return res.data;
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    registerInqueryDetail,
    error,
  };
};
