import { Button, Page } from 'framework7-react';
import { FC, useState } from 'react';
import Calendar, { CalendarProps } from 'react-calendar';
import { useFormContext } from 'react-hook-form';
import style from './Partial.module.scss';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { DATE_MONTH_INCREMENTER, formatDateWithDay } from '@/utils/date';

interface SelectCalendarProps extends CalendarProps {
  name: string;
  timePairName?: string;
  onChange?: (value?: Date) => void;
  label?: string;
  required?: boolean;
  placeholder?: string;
  className?: string;
  hasError?: boolean;
  showClearButton?: boolean;
}

type DateClass = { date: Date };

export const SelectCalendar: FC<SelectCalendarProps> = ({
  name,
  timePairName,
  onChange,
  placeholder,
  label = '',
  required = false,
  className,
  hasError = false,
  showClearButton = false,
  ...calendarProps
}) => {
  const { trigger, watch, setValue } = useFormContext();

  const value = watch(name);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const chooseDay = (newValue?: Date) => {
    if (!newValue && timePairName) {
      setValue(timePairName, '');
      trigger(timePairName);
    }
    setIsModalOpen(false);
    setValue(name, newValue);
    trigger(name);
    onChange && onChange(newValue);
  };

  const formatDateJapanese = (val: string | Date) =>
    val ? formatDateWithDay(new Date(val) || Date.now()) : '';

  return (
    <>
      <div className={`${style['input-calendar']} ${className}`}>
        {label && (
          <label className={style['input-calendar-label']}>
            {label}
            {required && (
              <span
                className={`${style['input-label-tips']} ${style['required']}`}
              >
                必須
              </span>
            )}
          </label>
        )}
        <div
          className={`${style['input-calendar-wrap']} ${
            hasError && style['input-calendar-error']
          }`}
          onClick={() => setIsModalOpen(true)}
        >
          <input
            type="text"
            value={formatDateJapanese(watch(name))}
            placeholder={placeholder || ''}
            readOnly
          />
          <span className={style['input-caret']} />
        </div>
      </div>

      <PopupTemplate
        id={`SelectCalendar-${name}`}
        title=""
        opened={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        onPopupClosed={() => chooseDay(value)}
      >
        <Page name="deliveryCalendarPopUp">
          <Calendar
            prev2Label={null}
            next2Label={null}
            value={value || null}
            onClickDay={chooseDay}
            minDetail={'year'}
            nextLabel={'次月 ＞'}
            prevLabel={'＜ 前月'}
            navigationLabel={({ date }: DateClass) => {
              const year = date.getFullYear();
              const month = date.getMonth() + DATE_MONTH_INCREMENTER;
              return `${year}年${month}月`;
            }}
            formatShortWeekday={(locale, date) => {
              const day = date.getDay();
              const weekday = ['日', '月', '火', '水', '木', '金', '土'];
              return weekday[day];
            }}
            formatDay={(_, date) => String(date.getDate())}
            {...calendarProps}
          />
          {showClearButton && (
            <div className={style['clear-button-wrap']}>
              <Button onClick={() => chooseDay()}>クリア</Button>
            </div>
          )}
        </Page>
      </PopupTemplate>
    </>
  );
};
