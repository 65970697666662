import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { getBranchesApi } from '@/api/bankCodeApi';
import { GetBranchesApiResponse } from '@/types/api/bankCodeApi';

export const useGetBranches = (bankCode: string, opened: boolean) => {
  const [branches, setBranches] =
    useState<GetBranchesApiResponse['data']['data']>();
  const [error, setError] = useState<AxiosError>();

  const getBranchesData = useCallback(async () => {
    try {
      const res = await getBranchesApi(bankCode);
      setBranches(res.data.data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
    }
  }, [bankCode]);

  useEffect(() => {
    if (opened && bankCode) {
      getBranchesData();
    } else {
      setBranches([]);
    }
  }, [bankCode, getBranchesData, opened]);

  return { branches, error };
};
