import * as yup from 'yup';
import { CanDriveType } from '@/consts/carRepair';

export const carConditionValidationSchema = yup.object().shape({
  canDrive: yup
    .mixed<CanDriveType>()
    .oneOf(Object.values(CanDriveType))
    .required(),
  m_defect_ids: yup.array().when('canDrive', {
    is: CanDriveType.CANNOT,
    then: yup.array().ensure().compact().min(0),
    // eslint-disable-next-line no-magic-numbers
    otherwise: yup.array().ensure().compact().min(1),
  }),
  m_defect_occurrence_frequency_id: yup.string().when('canDrive', {
    is: CanDriveType.CANNOT,
    then: yup.string().notRequired(),
    otherwise: yup.string().required(),
  }),
  occur_date: yup.date().when('canDrive', {
    is: CanDriveType.CANNOT,
    then: yup.date().notRequired(),
    otherwise: yup.date().required(),
  }),
  symptom_etc: yup.string().notRequired(),
  confirm_warranty_plan: yup.boolean().required(),
});

export const carConditionInitialState = {
  canDrive: CanDriveType.CAN,
  m_defect_ids: [],
  openedDefectCategory: [],
  m_defect_occurrence_frequency_id: undefined,
  occur_date: undefined,
  symptom_etc: '',
  confirm_warranty_plan: false,
};
