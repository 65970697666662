import { Router } from 'framework7/types';
import { FC } from 'react';
import { EmailDisable } from './EmailDisable/EmailDisable';

interface EmailDisablePageProps {
  f7router: Router.Router;
}

export const EmailDisablePage: FC<EmailDisablePageProps> = ({ f7router }) => {
  return <EmailDisable f7router={f7router} />;
};
