import { useStore } from 'framework7-react';
import { FC, useMemo } from 'react';
import style from '../CarRepairDetailPageStyle.module.css';
import { store } from '@/config/store';
import { warrantyType } from '@/consts/warranty';
import { Car } from '@/types/api/customerApi';
import { RepairInfo } from '@/types/api/reservationApi';
import { convertToDayByFormat } from '@/utils/date';

type RepairDetailProps = {
  firstItem?: RepairInfo;
};

export const RepairDetail: FC<RepairDetailProps> = ({ firstItem }) => {
  const ownedCars = useStore(store, 'carsInfo') as Required<Car>[];

  const car = useMemo(() => {
    return ownedCars.find(
      (car) => car.t_stock_car_id === firstItem?.t_stock_car_id,
    );
  }, [firstItem, ownedCars]);

  const newestOccurDate = useMemo(() => {
    if (!firstItem) return '';

    const newestOccurDate = firstItem.symptom_detail?.reduce((acc, cur) => {
      return acc > cur?.occur_date ? acc : cur?.occur_date;
    }, firstItem?.symptom_detail?.[0]?.occur_date);

    if (!newestOccurDate) return '';

    return convertToDayByFormat(
      newestOccurDate,
      'yyyy-MM-dd HH:mm:ss.SSS',
      true,
    );
  }, [firstItem]);

  const carTitle = [car?.hiragana, car?.registry_no, car?.maker_nm, car?.car_nm]
    .filter(Boolean)
    .join(' ');

  const isDealWarranty = firstItem?.warranty_type === warrantyType.DEAL;

  return (
    <div className={`${style['block']}`}>
      <div>
        <div className={style['title']}>受付内容</div>
      </div>
      <div className={style['content-line']}>
        <p className={style['text-label']}>作業内容</p>
        <p className={style['text']}>保証修理</p>
      </div>
      <div className={style['content-line']}>
        <p className={style['text-label']}>依頼番号</p>
        <p className={style['text']}>{firstItem?.repair_id}</p>
      </div>
      <div className={style['content-line']}>
        <p className={style['text-label']}>対象車両</p>
        <p className={style['text']}>{carTitle}</p>
      </div>
      {!isDealWarranty && (
        <>
          <div className={style['block-header']}>
            <div className={style['title']}>お車の症状</div>
          </div>
          <div className={style['content-line']}>
            <p className={style['text-label']}>お車の不具合</p>
            <p className={style['text']}>
              {firstItem?.symptom_detail
                .map((detail) => detail.symptom)
                .join(', ')}
            </p>
          </div>
          <div className={style['content-line']}>
            <p className={style['text-label']}>発生頻度はどれくらいですか</p>
            <p className={style['text']}>{firstItem?.symptom_frequency}</p>
          </div>
          <div className={style['content-line']}>
            <p className={style['text-label']}>
              症状に気づかれたのはいつですか
            </p>
            <p className={style['text']}>{newestOccurDate}</p>
          </div>
          <div className={style['content-line']}>
            <p className={style['text-label']}>
              その他の不具合症状などご入力ください
            </p>
            <p className={style['text']}>{firstItem?.symptom_etc}</p>
          </div>
        </>
      )}
      <div className={style['block-header']}>
        <div className={style['title']}>ご連絡先</div>
      </div>
      <div className={style['content-line']}>
        <p className={style['text-label']}>ご連絡先</p>
        <p className={style['text']}>{firstItem?.contact_person_phone_num}</p>
      </div>
      <div className={style['content-line']}>
        <p className={style['text-label']}>ご連絡先電話番号と契約者様の関係</p>
        <p className={style['text']}>
          {firstItem?.contact_person_relationship}
        </p>
      </div>
      <div className={style['content-line']}>
        <p className={style['text-label']}>お名前</p>
        <p className={style['text']}>{firstItem?.contact_person_name}</p>
      </div>
      <div className={style['content-line']}>
        <p className={style['text-label']}>フリガナ</p>
        <p className={style['text']}>{firstItem?.contact_person_name_kana}</p>
      </div>
    </div>
  );
};
