import { FC, ReactNode } from 'react';
import style from './Card.module.css';

interface CardProps {
  title: string;
  iconType: string;
  children: ReactNode;
}

export const Card: FC<CardProps> = ({ title, iconType, children }) => {
  return (
    <div className={style.card}>
      <div className={style['card-title']}>
        <i className={`${style.icon} ${iconType}`} />
        <h2>{title}</h2>
      </div>
      <div style={{ width: '100%', height: '24px' }} />
      {children}
    </div>
  );
};
