import { FC } from 'react';
import styles from './SelfScanStartStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';

type Props = {
  latestScanAt?: string;
  handleClickScanHistory: () => void;
  handleClickAction: () => void;
  handleClickLinkDriveTerm: () => void;
};

export const SelfScanStart: FC<Props> = ({
  latestScanAt,
  handleClickScanHistory,
  handleClickAction,
  handleClickLinkDriveTerm,
}) => {
  return (
    <PageTemplate
      pageName="SelfScanStart"
      title="車両状態・セルフスキャン"
      showNavBack
    >
      <div className={styles['container']}>
        <h4 className={styles['title']}>車両状態・セルフスキャン</h4>
        <p className={styles['paragraph']}>
          {
            'LinkDrive を通して車から異常信号が発せられた場合、通知します。\n異常信号は、下記の場合に車から取得します。'
          }
        </p>
        <br />
        <ul className={styles['list']}>
          <li>走行時</li>
          <li>セルフスキャン実行時</li>
        </ul>
        <div className={styles['self-scan-container']}>
          <p>
            <b>セルフスキャン</b>
          </p>
          <br />
          <p>
            セルフスキャンすると、最新の情報を使って異常診断をすることができます。
          </p>
          <p className={`${styles['list']} ${styles['text-sub']}`}>
            セルフスキャンは、LinkDriveを装着した車のエンジンをかけて行う必要があります。
          </p>
        </div>
        <Button onClick={handleClickAction}>スキャンする</Button>
        {latestScanAt && (
          <>
            <div className={styles['menu']} onClick={handleClickScanHistory}>
              <div className={styles['menu-item']}>
                <div className={styles['menu-item-main']}>
                  <p>診断履歴</p>
                  <p className={styles['menu-item-sub']}>
                    最後の診断：{latestScanAt}
                  </p>
                </div>
                <i className="icon-right" />
              </div>
            </div>
            <div className={styles['line-break']} />
          </>
        )}
        <div className={styles['menu']}>
          <div
            className={styles['menu-item']}
            onClick={handleClickLinkDriveTerm}
          >
            <div className={styles['menu-item-main']}>
              <p>Link Drive利用規約</p>
            </div>
            <i className="icon-right" />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};
