import { Icon } from 'framework7-react';
import { FC } from 'react';
import style from './UploadDocuments.module.css';
import { ReactComponent as IconCheckGreen } from '@/assets/images/menu/icon-check-green.svg';
import { Documents } from '@/types/api/warrantyApi';

interface UploadDocumentsProps {
  uploadDocument: Record<number, Documents>;
  handleClickDocument: (document: Documents) => void;
}

export const UploadDocuments: FC<UploadDocumentsProps> = ({
  uploadDocument,
  handleClickDocument,
}) => {
  return (
    <div className={style['container']}>
      <div className={style['upload-group']}>
        <h3>アップロード済みの書類</h3>
        <div className={style['upload-list']}>
          {Object.entries(uploadDocument).length === 0 && (
            <p>未アップロードの書類はありません</p>
          )}
          {Object.entries(uploadDocument).map(([id, doc]) => (
            <div
              key={doc.m_warranty_document_property_id}
              className={style['upload-item']}
              onClick={() => handleClickDocument(doc)}
            >
              <IconCheckGreen />
              <p>{doc.name}</p>
              <Icon f7="chevron_right" size={24} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
