import { Router } from 'framework7/types';
import {
  CancelWarrantyDetailPage,
  CancelWarrantyPage,
  UpdateWarrantyCompletePage,
  UpdateWarrantyDetailPage,
  UpdateWarrantyPage,
  WarrantyCancelablePage,
  WarrantyDetailPage,
  WarrantyDuplicatedPage,
  WarrantyGuidePage,
  WarrantyManagementPage,
  WarrantyParts2Page,
  WarrantyPartsPage,
  WarrantyTermsPage,
  WarrantyFaqPage,
} from '@/components/pages';
import { paths } from '@/config/paths';

export const warrantyManagementRoutes: Router.RouteParameters[] = [
  {
    path: paths.warranty(':status'),
    component: WarrantyManagementPage,
    name: '保証プランを確認',
  },
  {
    path: paths.warrantyDetail,
    component: WarrantyDetailPage,
    name: 'プラン詳細',
    options: {
      animate: true,
    },
  },
  {
    path: paths.warrantyParts,
    component: WarrantyPartsPage,
    name: '保証プラン・対象部位',
    options: {
      animate: true,
    },
  },
  {
    path: paths.warrantyGuide,
    component: WarrantyGuidePage,
    name: '保証管理のご利用方法',
  },
  {
    path: paths.cancelWarranty,
    popup: {
      component: CancelWarrantyPage,
    },
    options: {
      animate: true,
    },
    name: '保証の解約',
  },
  {
    path: paths.warrantyCancelable(':warranty_no'),
    component: WarrantyCancelablePage,
    name: '解約可能期間',
    options: {
      animate: true,
    },
  },
  {
    path: paths.updateWarranty(':warrantyNo'),
    popup: {
      component: UpdateWarrantyPage,
    },
    name: '保証プラン変更',
    options: {
      animate: true,
    },
  },
  {
    path: paths.updateWarrantyComplete(':warranty_no'),
    component: UpdateWarrantyCompletePage,
    name: '保証プラン変更完了',
    options: {
      animate: true,
    },
  },
  {
    path: paths.updateWarrantyDetail(':warranty_no'),
    component: UpdateWarrantyDetailPage,
    name: '保証プラン変更内容',
    options: {
      animate: true,
    },
  },
  {
    path: paths.warrantyParts2(':warranty_no'),
    component: WarrantyParts2Page,
    name: '保証プラン対象部位',
  },
  {
    path: paths.warrantyTerms,
    component: WarrantyTermsPage,
    name: '保証プラン規約',
  },
  {
    path: paths.warrantyFaq,
    component: WarrantyFaqPage,
    name: 'よくあるご質問',
  },
  {
    path: paths.cancelWarrantyDetail(':warrantyNo'),
    component: CancelWarrantyDetailPage,
    options: {
      animate: true,
    },
    name: '保証の解約内容',
  },
  {
    path: paths.warrantyDuplicated,
    popup: {
      component: WarrantyDuplicatedPage,
    },
    options: {
      animate: true,
    },
    name: '保証期間が重複しています。',
  },
];
