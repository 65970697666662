import { Page, Popup } from 'framework7-react';
import { FC } from 'react';
import { Radio } from '../Radio/Radio';
import style from './emailDuplicatedStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { Affiliate } from '@/types/api/authApi';
import { getCarPhoto } from '@/utils/utils';

interface EmailDuplicatedProps {
  customers: Affiliate[];
  customer: Affiliate | null | undefined;
  handleClickChange: (customer: Affiliate) => void;
  handleClickSubmit: () => void;
}

export const EmailDuplicated: FC<EmailDuplicatedProps> = ({
  customers,
  customer,
  handleClickChange,
  handleClickSubmit,
}) => {
  return (
    <Popup closeByBackdropClick={false}>
      <Page name="EmailDuplicated">
        <div className={style['container']}>
          <h2 className={style['heading']}>
            以下のアカウントでも登録されています。どのアカウントでログインしますか？
          </h2>
          <div>
            {customers.map((c) => (
              <label
                key={c.id}
                htmlFor={`${c.id}`}
                className={style['radio-card']}
              >
                <div className={style['radio-group']}>
                  <Radio
                    id={`${c.id}`}
                    name="car-select"
                    checked={c.id === customer?.id}
                    onChange={() => handleClickChange(c)}
                  />
                  <span className={style['radio-group-label']}>
                    {c.affiliate_name || ''}
                  </span>
                </div>
                <div className={style['car-card']}>
                  <div className={style['car-image-wrapper']}>
                    <img src={getCarPhoto(c.cars[0])} />
                  </div>
                  <div className={style['car-information-wrapper']}>
                    <span className={style['car-information-title']}>
                      {c.cars[0]?.car_nm}
                    </span>
                    <div className={style['car-information-sub']}>
                      <span>
                        {`${c.cars[0]?.hiragana || ''} ${
                          c.cars[0]?.registry_no || ''
                        }`}
                      </span>
                      {c.cars.length - 1 > 0 && (
                        <span>{`他${(c.cars || []).length - 1}件`}</span>
                      )}
                    </div>
                  </div>
                </div>
              </label>
            ))}
          </div>
          <Button
            className={style['submit-button']}
            onClick={handleClickSubmit}
            disabled={!customer}
          >
            ログインする
          </Button>
        </div>
      </Page>
    </Popup>
  );
};
