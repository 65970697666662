import { Link } from 'framework7-react';
import { FC } from 'react';
import style from './WarrantyFaqStyle.module.scss';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';

export const WarrantyFaq: FC = () => {
  return (
    <PageTemplate
      pageName="WarrantyFaq"
      showNavBack={true}
      title="よくあるご質問"
    >
      <RoundSheet>
        <div className={style['container']}>
          <p>
            上記のルールを適用すると、お客様への月々の請求は下記のようになります。
          </p>
          <Link
            text="保証プランの変更はできますか？"
            href="https://tayori.com/q/maikuru/detail/518202/"
            target="_blank"
            className={`external ${style.link}`}
          />
          <Link
            text="保証の解約（次年度更新停止）の手続きはどのようにすればいいですか？"
            href="https://tayori.com/q/maikuru/detail/453846/"
            target="_blank"
            className={`external ${style.link}`}
          />
        </div>
      </RoundSheet>
    </PageTemplate>
  );
};
