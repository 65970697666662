import { FC } from 'react';
import style from './PopupHeader.module.css';
import StatusCompleted from '@/assets/images/reservation-status/status12.svg';
import StatusApplyApp from '@/assets/images/reservation-status/status13.svg';
import StatusWaiting from '@/assets/images/reservation-status/status5.svg';
import Deal1Img from '@/assets/images/warranty/deal1.png';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';

interface PopupTemplateProps {
  opened: boolean;
  handleClose?: () => void;
  type?: string;
}

export enum TYPE_POPUP {
  TRADE_GUARANTEE_PLAN = 'trade_guarantee_plan',
  APPLICATION_PROCESS = 'application_process',
}

const ApplicationProcessList = [
  {
    img: StatusApplyApp,
    title: 'アプリで申請',
    content: '実際に起きたトラブルや購入時の状況などを申請していただきます。',
  },
  {
    img: StatusWaiting,
    title: '審査結果を待つ',
    content:
      '申請していただいたトラブルが取引保証プランで保証できるか審査を行います。',
  },
  {
    img: StatusCompleted,
    title: '審査に通過した場合',
    content:
      '審査に通過できた場合はそのまま保証内容に応じたサポートを行います。',
  },
];

export const PopupHeader: FC<Partial<PopupTemplateProps>> = ({
  opened = false,
  handleClose,
  type = TYPE_POPUP.TRADE_GUARANTEE_PLAN,
}) => {
  const title =
    type === TYPE_POPUP.TRADE_GUARANTEE_PLAN
      ? '取引保証プランとは？'
      : '申請の流れ';

  return (
    <PopupTemplate
      opened={opened}
      title={title}
      onPopupClose={handleClose}
      handleClose={handleClose}
      className={style['popup-header-container']}
    >
      <div className={style['container']}>
        {type === TYPE_POPUP.TRADE_GUARANTEE_PLAN && <TradeGuaranteePlan />}
        {type === TYPE_POPUP.APPLICATION_PROCESS && <ApplicationProcess />}
      </div>
    </PopupTemplate>
  );
};

const ApplicationProcess = () => {
  return (
    <div className={style['application-process']}>
      {ApplicationProcessList.map((item, index) => (
        <div className={style['help-row']} key={item.title}>
          <div className={style['help-image-container']}>
            <span>{index + 1}</span>
            <div>
              <img src={item.img} width={48} />
            </div>
          </div>
          <div className={style['help-content']}>
            <h4>{item.title}</h4>
            <p>{item.content}</p>
          </div>
        </div>
      ))}

      <div className={style['application-process-content']}>
        <h2>お支払いについて</h2>
        <p>
          保証金やお見舞金が発生する審査結果の場合は審査通過後にお客様の振込先銀行口座を登録していただきます。
        </p>
      </div>
    </div>
  );
};

const TradeGuaranteePlan = () => {
  return (
    <div className={style['trade-guarantee-container']}>
      <div className={style['header-wrapper']}>
        <h2 className={style['header']}>
          納車日から1ヶ月の間に発生した
          <br /> トラブルを総額保証いたします！
        </h2>
      </div>
      <div className={style['image-wrapper']}>
        <img src={Deal1Img} width="50%" alt="deal" />
      </div>
      <h3>
        中古車購入における <span>総額保証</span>
      </h3>
      <p className={style['content']}>
        当社とお客様の間で交わしましたご契約内容に虚偽がないことを立証する保証です。契約トラブルの解決を第三者の立場から行うことで、安全安心な商取引が行われるようになります。
      </p>
    </div>
  );
};
