export const loginType = {
  NONE: '',
  TELEPHONE: 'telephone',
  EMAIL: 'email',
  OLD_APP: 'oldApli',
  APPLE: 'apple',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  ADMIN: 'admin',
} as const;

export const loginStatus = {
  FIRST: '0',
  MULTIPLE: '1',
  DUPLICATION: '2',
  UNREGISTERED: '3',
} as const;

export const mobileLoginStatus = {
  FIRST: '1',
  MULTIPLE: '2',
};

export const message = {
  DEFAULT_LOGIN_ERROR: 'ログイン時にエラーが発生しました',
};
