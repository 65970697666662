import { FC } from 'react';
import { WarrantyDetail } from '@/components/projects/WarrantyDetail/WarrantyDetail';
import { WarrantyPartsList } from '@/components/projects/WarrantyPartsList/WarrantyPartsList';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { WarrantiesAppendWarranty, Warranty } from '@/types/api/customerApi';
import {
  switchWarrantyPartsTitleByCoverageType,
  WarrantyPartsType,
} from '@/utils/warranty';
import './warrantyPartsStyle.scss';

interface WarrantyPartsProps {
  currentWarranty: Warranty | undefined;
  appendWarranty: WarrantiesAppendWarranty | undefined;
  warrantyParts: WarrantyPartsType;
}

export const WarrantyParts: FC<WarrantyPartsProps> = ({
  currentWarranty,
  appendWarranty,
  warrantyParts,
}) => {
  return (
    <PageTemplate
      pageName="WarrantyManagement"
      title="ご契約中の保証プラン"
      showNavBack
    >
      <div className="home__wrapper">
        <div className="u-margin-top-sm">
          {/* ご契約中の保証プラン */}
          <RoundSheet>
            <div className="WarrantyDetail-title">ご契約中の保証プラン</div>
            <WarrantyDetail
              warrantyInfo={appendWarranty}
              warrantyNo={currentWarranty?.warranty_no ?? ''}
            />
            {/* 保証対象部位一覧 */}
            <div className="sheet-top">
              <div className="WarrantyDetail-title">
                {switchWarrantyPartsTitleByCoverageType(
                  appendWarranty?.covering_type,
                )}
                一覧
              </div>
              {appendWarranty?.covering_type !== null && (
                <WarrantyPartsList warrantPartsList={warrantyParts} />
              )}
            </div>
          </RoundSheet>
        </div>
      </div>
    </PageTemplate>
  );
};
