import { Router } from 'framework7/types';
import { ContactPage } from '@/components/pages/Contact/ContactPage';
import { HelpTopPage } from '@/components/pages/HelpTop/HelpTopPage';
import { paths } from '@/config/paths';

export const helpRoutes: Router.RouteParameters[] = [
  {
    path: paths.helpTop,
    popup: {
      component: HelpTopPage,
    },
    options: {
      animate: true,
    },
    name: 'ヘルプtop',
  },
  {
    path: paths.contact,
    popup: {
      component: ContactPage,
    },
    name: 'お問い合わせ',
  },
];
