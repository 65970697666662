import { Icon } from 'framework7-react';
import { FC, useState } from 'react';
import { ImageZoom } from './ImageZoom';
import style from './Upload.module.css';
import { Button } from '@/components/uiParts/Button';
import { Documents } from '@/types/api/warrantyApi';
import {
  getDescriptionDocument,
  renderDescriptionDocument,
} from '@/utils/warranty';

interface UploadProps {
  selectedDocument?: Documents;
}

export const Upload: FC<UploadProps> = ({ selectedDocument }) => {
  const [zoomTarget, setZoomTarget] = useState<string | null>(null);

  return (
    <div className={style['container']}>
      {getDescriptionDocument(selectedDocument?.document_type) && (
        <p className={style['description']}>
          {renderDescriptionDocument(
            selectedDocument?.name,
            selectedDocument?.document_type,
          )}
        </p>
      )}
      {!!selectedDocument?.files?.length && (
        <div className={style['section']}>
          <h3>アップロード済みの書類</h3>
          <div className={style['block']}>
            {selectedDocument.files.map((file, index) => {
              return (
                <div className={style['image-list-item']} key={index}>
                  <img src={file.url} alt="uploaded" />
                  <Button
                    outline
                    style={{
                      color: '#707070',
                      backgroundColor: '#FFFFFF',
                      borderColor: '#707070',
                      fontWeight: 700,
                    }}
                    onClick={() => setZoomTarget(file.url)}
                  >
                    <Icon f7="search" size={20} />
                    拡大表示
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <ImageZoom src={zoomTarget || ''} onClose={() => setZoomTarget(null)} />
    </div>
  );
};
