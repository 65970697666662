import { FC } from 'react';
import style from './troubleTermsStyle.module.css';
import { Terms } from '@/components/pages/Terms/Terms/Terms';
import { store } from '@/config/store';

interface TroubleTermsProps {}

export const TroubleTerms: FC<TroubleTermsProps> = () => {
  const term = store.state.term.roadService;
  return (
    <div className={style.container}>
      <Terms html={term} height="1500px" title="ロードサービスご利用規約" />
    </div>
  );
};
