import { Col, Page, Row } from 'framework7-react';
import parse from 'html-react-parser';
import { FC } from 'react';
import { Button } from '../Button';
import './Dialog.scss';

type DialogProps = {
  title: string;
  subTitle: string;
  handleOk: () => void;
  handleCancel: () => void;
  buttonOkText: string;
  buttonCancelText?: string;
  className?: string;
  btnOkClassName?: string;
  btnCancelClassName?: string;
};

export const Dialog: FC<DialogProps> = ({
  title,
  subTitle,
  handleOk,
  handleCancel,
  buttonOkText,
  buttonCancelText = 'キャンセル',
  className = '',
  btnOkClassName = '',
  btnCancelClassName = '',
}) => {
  return (
    <Page name="ConfirmDialog">
      <div className={className}>
        <div className="container">
          <div className="container-title">
            <i className="icon-info" />
            <h2>{title}</h2>
          </div>
          <div className="u-margin-top-md" style={{ fontSize: '1.6rem' }}>
            {parse(subTitle)}
          </div>
          <Row style={{ marginTop: '30px' }}>
            <Col width="10" />
            <Col width="80">
              <Button
                className={`btn-ok ${btnOkClassName}`}
                text={buttonOkText}
                handleClickAction={handleOk}
              />
            </Col>
            <Col width="10" />
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col width="10" />
            <Col width="80">
              <Button
                className={`btn-cancel ${btnCancelClassName}`}
                text={buttonCancelText}
                handleClickAction={handleCancel}
              />
            </Col>
            <Col width="10" />
          </Row>
        </div>
      </div>
    </Page>
  );
};
