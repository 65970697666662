import { FC } from 'react';
import style from './PrivacyPolicyStyle.module.css';

export const PrivacyPolicy: FC = () => {
  return (
    <div className={style['container']}>
      <p>
        <span>
          当社は、個人情報保護の重要性を認識し、また、中古車保証業に対する社会の信頼をより向上させるため、個人情報の保護に関する法令、国が定める指針およびその他の規範を遵守し、当社の事業規模を考慮した上で、個人情報を適正に取り扱うとともに、適切な措置を講じます。
        </span>
        <span>
          当社は、従業者への教育・指導を徹底し、個人情報の取り扱いが適正に行われるように取り組んでまいります。また、当社の個人情報の取り扱いおよび安全管理に係る適切な措置については、適宜見直し、改善いたします。
        </span>
      </p>
      <h4>1. 個人情報の取得と利用</h4>
      <p>
        <span>
          当社は、事業に伴う個人情報の取得・利用にあたっては利用目的・提供の有無等を明確にし、適法で公正な手段により個人情報を取得し、目的外利用はいたしません。また、そのための措置を講じます。
        </span>
      </p>
    </div>
  );
};
