import { AxiosError } from 'axios';
import { Button, f7, useStore } from 'framework7-react';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import connectorBig from '../../../../assets/images/connector_big.png';
import ExclamationMark from '../../../../assets/images/icon-exclamationmark_circle.svg';
import style from './LinkDriveSetupStep1Style.module.css';
import { linkDriveStock } from '@/api/linkDriveApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { url } from '@/consts/url';
import { useSetStoreLinkDriveOwner } from '@/hooks/api/info/useSetStoreLinkDriveOwner';
import { useChangeVehicle } from '@/hooks/api/linkDrive/useChangeVehicle';
import { useCollaborateOwner } from '@/hooks/api/linkDrive/useCollaborateOwner';
import { useReplaceConnector } from '@/hooks/api/linkDrive/useReplaceConnector';
import { useScoreLinkedTransmissionConsent } from '@/hooks/api/linkDrive/useScoreLinkedTransmissionConsent';
import { useStartConnectorUse } from '@/hooks/api/linkDrive/useStartConnectorUse';
import { useVehicleChangeStatus } from '@/hooks/api/linkDrive/useVehicleChangeStatus';
import { ScoreLinkedTransmissionConsentParams } from '@/types/api/linkDriveApi';
import { linkageType } from '@/utils/linkDriveSetup';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

export interface LinkDriveSetupStep1PageProps {
  onSubmit: () => void;
  setLinkDriveStepHasError: Dispatch<SetStateAction<boolean>>;
}
export const LinkDriveSetupStep1Page: FC<LinkDriveSetupStep1PageProps> = ({
  onSubmit,
  setLinkDriveStepHasError,
}) => {
  const fromPage = useStore(store, 'fromPage') as string;
  const serialInput = useStore(store, 'serialNumberInput') as string;
  const serialNumberDb = useStore(store, 'serialNumberDb') as string;
  const ownerId = useStore(store, 'ownerId') as number;
  const [serialError, setSerialError] = useState({
    serialInvalid: '',
    errorCode: '',
  });
  const { serialInvalid, errorCode } = serialError;
  const [serialNumberInput, setSerialNumberInput] = useState(serialInput);
  const [isReEnteringSerial, setIsReEnteringSerial] = useState(false);
  const carItemInfo = store.state.carItemInfo;
  const vinNo = carItemInfo.vin;
  const authInfo = store.state.authInfo;
  const mCustomerId = authInfo.m_customer_id;
  const vehicleChangeStatusApiParams = {
    m_customer_id: String(mCustomerId),
    vin: vinNo,
  };
  const hasSerialNo =
    (serialNumberDb || serialNumberInput) && !isReEnteringSerial;
  const { collaborateOwner } = useCollaborateOwner();
  const { changeVehicle } = useChangeVehicle();
  const { replaceConnector } = useReplaceConnector();
  const { fetchVehicleChangeStatus } = useVehicleChangeStatus();
  const { startConnectorUse } = useStartConnectorUse();
  const { updateScoreLinkedTransmissionConsent } =
    useScoreLinkedTransmissionConsent();
  const { setStoreLinkDriveOwner } = useSetStoreLinkDriveOwner();

  const handleClickNavigationNext = async () => {
    f7.preloader.show();
    if (
      fromPage === 'connectorReplace' ||
      (serialNumberDb &&
        serialNumberInput &&
        serialNumberDb != serialNumberInput)
    ) {
      const replaceConnectorApiParams = {
        owner_id: ownerId,
        new_serial_no: serialNumberInput,
      };

      const replacedSuccessfully = await replaceConnector(
        replaceConnectorApiParams,
      );

      // Stop when replace has error
      if (!replacedSuccessfully) {
        setLinkDriveStepHasError(true);
        return f7.preloader.hide();
      }
    }
    fetchVehicleChangeStatus({
      params: vehicleChangeStatusApiParams,
      onSuccess: async () => {
        try {
          const changeVehicleApiParams = {
            m_customer_id: mCustomerId,
            warranty_no: carItemInfo?.warranties?.[0].warranty_no,
            serial_no: serialNumberDb || serialNumberInput,
          };
          const rs = await changeVehicle(changeVehicleApiParams);
          if (rs && rs.success) {
            const tStockCarId = store.state.carItemInfo.t_stock_car_id;
            const linkDriveOwnerParams = {
              customer_id: mCustomerId,
              stock_car_id: tStockCarId,
              del_flg: 0,
            };
            await setStoreLinkDriveOwner({ params: linkDriveOwnerParams });
            onSubmit();
          } else {
            setLinkDriveStepHasError(true);
            f7.dialog.alert(
              'エラーコード：LD0004<br><br>サービス利用を開始できませんでした。お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。',
              'エラー',
            );
          }
        } finally {
          f7.preloader.hide();
        }
      },
      // successがfalseの場 → 車両変更がない場合
      onFailure: async () => {
        const ownerId = store.state.ownerId;
        const serialNumberInput = store.state.serialNumberInput;
        const vehicleId = store.state.linkDriveOwnerInfo.vehicle_id;
        const tStockCarId = store.state.carItemInfo.t_stock_car_id;
        const linkDriveOwnerParams = {
          customer_id: mCustomerId,
          stock_car_id: tStockCarId,
          del_flg: 0,
        };

        try {
          if (!ownerId) {
            if (!store.state.authInfo.m_customer_id) {
              setLinkDriveStepHasError(true);
              f7.dialog.alert('', '顧客IDがありません');
              f7.preloader.hide();
              return;
            }
            if (!store.state.carItemInfo?.warranties?.[0]?.warranty_no) {
              setLinkDriveStepHasError(true);
              f7.dialog.alert(
                '',
                'LinkDriveを利用するには、保証に加入いただく必要があります',
              );
              f7.preloader.hide();
              return;
            }
            const collaborateOwnerApiParams = {
              customer_id: store.state.authInfo.m_customer_id,
              warranty_no:
                store.state.carItemInfo.warranties?.[0]?.warranty_no || '',
            };
            await collaborateOwner(collaborateOwnerApiParams);
          }
          if (!serialNumberDb) {
            const startConnectorUseApiParams = {
              owner_id: store.state.ownerId,
              serial_no: serialNumberInput || serialNumberDb || '',
              exchange_flag: '',
            };
            await startConnectorUse(startConnectorUseApiParams);
          }
          if (!vehicleId) {
            const updateScoreLinkedTransmissionConsentApiParams: ScoreLinkedTransmissionConsentParams =
              {
                owner_id: store.state.ownerId,
                linkage_type: linkageType.drivingDataLinkage,
              };
            await updateScoreLinkedTransmissionConsent(
              updateScoreLinkedTransmissionConsentApiParams,
            );
          }
          f7.preloader.hide();
          onSubmit();
        } catch (error) {
          f7.preloader.hide();
          setLinkDriveStepHasError(true);
          f7.dialog.alert((error as AxiosError).message, 'エラー', () => {
            f7CustomBack();
          });
        } finally {
          await setStoreLinkDriveOwner({ params: linkDriveOwnerParams });
        }
      },
    });
    storeDispatch('setActiveTabId', 'activeTabId', 1);
  };

  const handleClickNavigationSerialNumberInput = () => {
    f7.view.main.router.navigate(paths.serialNumberInput, {
      animate: true,
      props: {
        onSubmitData: (serialNo: string) => {
          if (serialNo === serialNumberInput) {
            handleCheckStock(serialNo);
          }
          setSerialNumberInput(serialNo);
        },
      },
    });
  };

  const handleClickBarCodeScan = () => {
    f7.dialog.confirm(
      '許可することで、QRコードを読み取ることができるようになります',
      '"マイくる"がカメラへのアクセスを求めています',
      () => {
        window.open(`${url.NATIVE_BASE_URL}/barcode_reader`);
      },
    );
  };

  // 在庫確認処理
  const handleCheckStock = (input: string) => {
    if (serialNumberDb === input) {
      setSerialError({
        serialInvalid: '',
        errorCode: '',
      });
      setIsReEnteringSerial(false);
      return;
    }
    if (input) {
      f7.preloader.show();
      linkDriveStock({
        serial_no: input,
      })
        .then(({ data }) => {
          setIsReEnteringSerial(false);
          if (data.success) {
            if (!data.data.exist) {
              setSerialError({
                serialInvalid:
                  'ご利用いただけないシリアルナンバーです。シリアルナンバーをご確認の上、再度入力してください。解決できない場合は、お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。',
                errorCode: 'LD0002',
              });
            } else if (data.data.setup) {
              setSerialError({
                serialInvalid:
                  'すでに登録されているデバイスです。シリアルナンバーをご確認の上、再度入力してください。解決できない場合は、お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。',
                errorCode: 'LD0003',
              });
            } else {
              setSerialError({ serialInvalid: '', errorCode: '' });
            }
          } else {
            setSerialError({
              serialInvalid:
                'シリアルナンバーが無効です。シリアルナンバーをご確認の上、再度入力してください。解決できない場合は、お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。',
              errorCode: 'LD0001',
            });
          }
        })
        .finally(() => f7.preloader.hide());
    }
  };

  window['onCodeReading'] = function (qrData: string) {
    if (!qrData) {
      f7.dialog.alert('', '無効なバーコード');
      return;
    }
    if (qrData === serialNumberInput) {
      handleCheckStock(qrData);
      return;
    }
    setSerialNumberInput(qrData);
    storeDispatch('setSerialNumberInput', 'serialNumberInput', qrData);
  };

  useEffect(() => {
    const tStockCarId = store.state.carItemInfo.t_stock_car_id;
    const linkDriveOwnerParams = {
      customer_id: mCustomerId,
      stock_car_id: tStockCarId,
      del_flg: 0,
    };
    setStoreLinkDriveOwner({ params: linkDriveOwnerParams });
  }, [mCustomerId, setStoreLinkDriveOwner]);

  useEffect(() => {
    handleCheckStock(serialNumberInput);
  }, [serialNumberInput]);

  return (
    <div className={style.block}>
      <div className={`${style.image} image u-text-center`}>
        <img
          className={`link-drive-step1__img ${style['link-drive-step1__img']}`}
          src={connectorBig}
        />
        <div className="display-flex flex-column-center-center u-margin-bottom-xxl">
          {!hasSerialNo && (
            <div>
              <p className={style.description}>
                コネクタのバーコードを
                <br />
                スキャンしてください
              </p>
              <p className={style['sub-description']}>
                シリアルナンバーが自動で入力されます
              </p>
              <p className={style.annotation}>
                <b>※ コネクタはまだ車両に挿さないで下さい</b>
              </p>
            </div>
          )}

          {hasSerialNo && (
            <div className="u-padding-top-lg display-flex flex-column-center-center">
              <h2>{serialNumberInput ? serialNumberInput : serialNumberDb}</h2>
              <div className={style['serial-number-desc']}>
                {!!serialInvalid ? (
                  <div className={style['error']}>
                    <div className="display-flex justify-content-center u-margin-bottom-sm">
                      <img src={ExclamationMark} />
                      <div className={style['error-padding-left']}>
                        エラーコード：{errorCode}
                      </div>
                    </div>
                    <span>{serialInvalid}</span>
                  </div>
                ) : (
                  <div className={style['serial-status-group']}>
                    <div>
                      <span style={{ color: '#2275EA', fontSize: '18px' }}>
                        ✓
                      </span>
                      シリアルナンバーは有効です
                    </div>
                    <Button
                      round
                      fill
                      className={`${style['button']} ${style['enter-another-serial-btn']}`}
                      text="別のシリアルナンバーを入力"
                      onClick={() => setIsReEnteringSerial(true)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* <!-- バーコードリーダー機能を呼び出すボタン --> */}
        {(!hasSerialNo || serialInvalid) && (
          <>
            <Button
              round
              fill
              text="バーコードをスキャンする"
              className={style['button']}
              onClick={handleClickBarCodeScan}
            />
            <Button
              round
              fill
              className={style['button']}
              text="シリアルナンバーを手で入力する"
              onClick={handleClickNavigationSerialNumberInput}
            />
          </>
        )}

        {hasSerialNo && !serialInvalid && (
          <>
            <Button
              round
              fill
              text="次へ"
              className={style['button']}
              onClick={() => handleClickNavigationNext()}
            />
          </>
        )}
      </div>
    </div>
  );
};
