import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  MarginType,
  ScratchedCheck,
  SecuritySystem,
} from '@/types/api/reservationApi';

const validationSchema = yup.object().shape({
  dateAnnotation: yup.bool().required().oneOf([true]),
  badWeather: yup.bool().required().oneOf([true]),
  cancelFee: yup.bool().required().oneOf([true]),
  workspace: yup.object().shape({
    marginType: yup.number().required(),
    securitySystem: yup.number().required(),
    scratchedCheck: yup.number().required(),
  }),
});

export interface Step3FormData {
  dateAnnotation: boolean;
  badWeather: boolean;
  cancelFee: boolean;
  workspace: {
    marginType?: MarginType;
    securitySystem?: SecuritySystem;
    scratchedCheck?: ScratchedCheck;
  };
}

export const useCarWashHandStep3 = (initialState: Step3FormData) => {
  const formMethods = useForm<Step3FormData>({
    defaultValues: initialState,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  return {
    formMethods,
  };
};
