import React, { FC } from 'react';
import style from './SelfScanStep1Style.module.css';
import carMirrorImage from '@/assets/images/car_mirror.png';
import { Button } from '@/components/uiParts/Button';

interface SelfScanStep1Props {
  handleClickAction: () => void;
}

export const SelfScanStep1: FC<SelfScanStep1Props> = ({
  handleClickAction,
}) => {
  return (
    <div>
      <div className={style['corresponding-checklist']}>
        <div className={`${style.heading} ${style['check-paragraph']}`}>
          <i className={`icon-check`} />
          <h2>安全を確認してください</h2>
        </div>
        <div className={style.image}>
          <img width={'100%'} src={carMirrorImage} alt="" />
        </div>
        <p className={style.description}>
          安全な場所に車が停車しているか
          <br />
          確認をしてください。
        </p>
      </div>
      <div className={`${style['check-paragraph']} ${style.inactive}`}>
        <i className={`icon-check`} />
        <p>エンジンを始動してください</p>
      </div>
      <div className={`${style['check-paragraph']} ${style.inactive}`}>
        <i className={`icon-check`} />
        <p>診断を実施します</p>
      </div>
      <div className={style['button-wrap']}>
        <Button className={style.button} handleClickAction={handleClickAction}>
          確認した
        </Button>
      </div>
    </div>
  );
};
