import { Block, Link, Navbar, NavLeft, NavTitle, Page } from 'framework7-react';
import React, { FC } from 'react';
import style from './DrivingMonthlyDataStyle.module.css';
import { DataList } from '@/components/projects/DataList/DataList';

export const DrivingMonthlyData: FC = () => {
  const drivingMonthlyData = {
    date: '1月',
    PrevDate: '12月',
    fullDate: '2020年1月',
    drivingDistance: '0.0km',
    daytimeDrivingDuration: '3分',
    nightDrivingDuration: '0分',
    drivingTimes: '1回',
    suddenSatrt: '0回',
    suddenBraking: '0回',
  };

  type LabelPropsType = keyof typeof drivingMonthlyData;

  interface LabelListType {
    label: string;
    props: LabelPropsType;
  }

  const labelList: LabelListType[] = [
    {
      label: '走行距離',
      props: 'drivingDistance',
    },
    {
      label: '走行時間(日中)',
      props: 'daytimeDrivingDuration',
    },
    {
      label: '走行時間(夜間)',
      props: 'nightDrivingDuration',
    },
    {
      label: '運転回数',
      props: 'drivingTimes',
    },
    {
      label: '急アクセル回数',
      props: 'suddenSatrt',
    },
    {
      label: '急ブレーキ回数',
      props: 'suddenBraking',
    },
  ];

  return (
    <Page name="DrivingMonthlyData">
      <Navbar>
        <NavLeft>
          <Link back animate>
            <i className="icon-left" />
          </Link>
        </NavLeft>
        <NavTitle>ドライブ</NavTitle>
      </Navbar>
      <Block>
        <div
          className={`${style['day-nav']} day-nav u-font-size-xxl u-margin-bottom-sm`}
        >
          <div className={`${style['day-nav-div']} before-day`}>
            <i className="icon-left" />
            <span>{drivingMonthlyData.PrevDate}</span>
          </div>
          <div className={`${style['current-day']} current-day`}>
            {drivingMonthlyData.fullDate}
          </div>
        </div>
        <div className="info-card info-card-elevated data-list">
          <DataList
            labelList={labelList}
            data={drivingMonthlyData}
            title={`【${drivingMonthlyData.date}のサマリー】`}
          />
        </div>
      </Block>
    </Page>
  );
};
