import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { Account } from './Account/Account';
import { store } from '@/config/store';

interface AccountPageProps {
  f7router: Router.Router;
  status: string;
}
export const AccountPage: FC<AccountPageProps> = ({ f7router, status }) => {
  const {
    themeId,
    customerInfo,
    paymentMethodsInfo,
    advancePaymentsInfo: prepaidInfo,
    loginInfo,
    currentWarranty,
    nextWarranty,
    carItemInfo,
    carsInfo,
  } = store.state;
  return (
    <Account
      f7router={f7.views.main.router}
      themeId={themeId}
      status={status}
      customerInfo={customerInfo}
      paymentMethodsInfo={paymentMethodsInfo}
      prepaidInfo={prepaidInfo}
      loginInfo={loginInfo}
      currentWarranty={currentWarranty}
      nextWarranty={nextWarranty}
      carItemInfo={carItemInfo}
      carsInfo={carsInfo}
    />
  );
};
