import { FC, ReactNode } from 'react';
import { Button, MainButtonProps } from '../Button/Button';
import style from './roundButtonWithIconStyle.module.css';

interface RoundButtonWithIconProps extends MainButtonProps {
  iconClass: string;
  children: ReactNode;
}

export const RoundButtonWithIcon: FC<RoundButtonWithIconProps> = ({
  iconClass,
  children,
  className,
  ...rest
}) => {
  return (
    <Button className={`${className} ${style['button']}`} {...rest}>
      <div>
        <i className={iconClass} />
      </div>
      <p>{children}</p>
    </Button>
  );
};
