import { f7 } from 'framework7-react';
import { fetchCarWashSize } from '@/api/reservationApi';
import { carSizes } from '@/consts/carWash';
import { Car } from '@/types/api/customerApi';
import { CarSize, CarWashSizeParams } from '@/types/api/reservationApi';

// S-class (mini vehicles): Overall length 3.4 meters or less, overall width 1.48 meters or less,
// total height 2 meters or less, total displacement 660cc or less

// M class: 5.3 number, less than 1650mm in total height

// L-class: Height 1650mm or more

// 2L size: Other

const S_MAX_LENGTH = 3400;
const S_MAX_WIDTH = 1480;
const M_MAX_HEIGHT = 1650;
const L_MIN_HEIGHT = 1650;

export const calculateCarSize = async (car: Car): Promise<CarSize> => {
  //get by API
  try {
    f7.preloader.show();
    if (car.car_cd) {
      const carSizeParams: CarWashSizeParams = {
        car_cd: car.car_cd,
      };
      const { data: carSizeRes } = await fetchCarWashSize(carSizeParams);
      if (carSizeRes.success && carSizeRes.car_size != carSizes.UNKNOWN) {
        return carSizeRes.car_size;
      }
    }
  } catch (err) {
  } finally {
    f7.preloader.hide();
  }

  //get by car data
  const height = car.carsize_h;
  const length = car.carsize_l;
  const width = car.carsize_w;
  const class_no = car.class_no || '';
  if (!height || !length || !width) return carSizes.UNKNOWN;
  if (length <= S_MAX_LENGTH && width <= S_MAX_WIDTH) {
    return carSizes.S;
  }
  if (
    (class_no.startsWith('5') || class_no.startsWith('3')) &&
    height < M_MAX_HEIGHT
  ) {
    return carSizes.M;
  }
  if (height >= L_MIN_HEIGHT) {
    return carSizes.L;
  }
  return carSizes.XL;
};
