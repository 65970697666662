import { Col, f7, Row } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import style from './CouponDetail.module.css';
import { CouponChargeSuccess } from '@/components/projects/CouponChargeSuccess/CouponChargeSuccess';
import { CouponCode } from '@/components/projects/CouponCode/CouponCode';
import { Button } from '@/components/uiParts/Button';
import { PrepaidTicketCoupon } from '@/components/uiParts/PrepaidTicketCoupon/PrepaidTicketCoupon';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { AuthInfoState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { CAMPAIGN_PREPAID_DEPOSIT } from '@/consts/campaign';
import { chargeClassification, chargeType } from '@/consts/charge';
import { useHouseprepaidCharge } from '@/hooks/api/prepaid/useHouseprepaidCharge';
import { useUpdateCampaign } from '@/hooks/api/prepaid/useUpdateCampaign';
import { Campaign, Prepaid } from '@/types/api/customerApi';
import { hyphenToDay, nowMonthDay } from '@/utils/date';
import { f7CustomBack } from '@/utils/utils';

const POPUP_CLOSE_ANIMATION_DELAY = 250;

interface CouponDetailProps {
  f7router: Router.Router;
  authInfo: AuthInfoState;
  couponInfo: Campaign;
  prepaidsInfo: Required<Prepaid>;
}

//Todo: ご利用方法などの文言が分かり次第修正

export const CouponDetail: FC<CouponDetailProps> = ({
  f7router,
  authInfo,
  prepaidsInfo,
  couponInfo,
}) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);

  const [identificationNumber, setIdentificationNumber] = useState<string>();

  const [isActive, setIsActive] = useState(false);

  const advancePaymentsInfo = store.state.advancePaymentsInfo;

  const currentMonthDay = nowMonthDay();

  const handleInput = (code: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = /^\d+$/.test(code.target.value)
      ? code.target.value
      : undefined;

    setIdentificationNumber(value);
    if (value) setIsActive(true);
    else setIsActive(false);
  };

  const handleClickToUsing = async () => {
    if (couponInfo?.prepaid_deposit === CAMPAIGN_PREPAID_DEPOSIT.NEED_CHARGE) {
      if (isNoPrepaidId()) return;

      await prepaidCharge();
      setSuccessPopupOpen(true);
      return;
    }
    if (!identificationNumber) {
      setIsShowPopup(true);
      return;
    }
    if (identificationNumber != currentMonthDay) {
      f7.dialog.alert(
        '暗証番号違います、もう一度入力してください',
        'エラー',
        () => {
          setIsShowPopup(true);
        },
      );
      return;
    }
    await updateCampaidnInformation(couponInfo.t_campaigns_id);
    f7CustomBack();
  };

  const { chargePrepaid } = useHouseprepaidCharge(authInfo.m_customer_id);
  const { updateCampaidnInformation } = useUpdateCampaign(
    authInfo.m_customer_id,
  );

  const handleClose = () => {
    setIsShowPopup(false);
  };

  const isNoPrepaidId = () => {
    const prepaidId = advancePaymentsInfo[0]?.id;
    if (!prepaidId) {
      f7.dialog.alert('プリペイド申し込み後、ご利用いただけます', 'エラー');
    }
    return !prepaidId;
  };

  const prepaidCharge = async () => {
    const params = {
      m_customer_id: authInfo.m_customer_id,
      m_prepaid_id: prepaidsInfo.id ?? 0,
      charge_price: couponInfo.campaign_value ? couponInfo.campaign_value : 0,
      charge_kbn: chargeClassification.COUPON,
      coupon_expiration_date: couponInfo.t_end_date,
      t_campaigns_id: couponInfo.t_campaigns_id,
      charge_type: chargeType.SPOT,
    };
    await chargePrepaid(params);
  };

  return (
    <PageTemplate pageName="CouponDetail" title="クーポン" showNavBack>
      <div className={`${style['card']}`}>
        <PrepaidTicketCoupon
          couponInfo={couponInfo}
          f7router={f7router}
          showArrow={false}
        />
        <div className={style.title}>{couponInfo?.title}</div>
        <div className={style.description}>{couponInfo?.contents}</div>
        <div className={style.space} />
        <div className={style.details}>
          <div className={style.subtitle}>ご利用期間</div>
          <div>
            <p className={style.detail}>
              {hyphenToDay(String(couponInfo?.t_start_date), true)}~
              {hyphenToDay(String(couponInfo?.t_end_date), true)}
            </p>
          </div>
        </div>
        <div className={style.space} />
        <div className={style.details}>
          <div className={style.subtitle}>ご利用方法</div>
          {/* <div>
            <p className={style.detail}>この画面をスタッフにご提示ください</p>
          </div> */}
        </div>
        <div className={style.space} />
        <div className={style.details}>
          <div className={style.subtitle}>ご利用条件</div>
          {couponInfo?.usage_rules?.map((rule, index) => (
            <div key={index} className={style.list}>
              <p className={style.detail}>{rule}</p>
            </div>
          ))}
        </div>
        {/* TODO: Vue版では注意事項の文言なし */}
        <div className={style.space} />
        <div className={style.details}>
          <div className={style.subtitle}>注意事項</div>
          {/* <div className={style.list}>
            <p className={style.detail}>2020年10月10日までに車検をご利用の方</p>
          </div> */}
        </div>
      </div>
      {!couponInfo.status && (
        <Row className={style.row}>
          <Col width="10" />
          <Col width="80">
            <Button
              className={style.button}
              text={
                couponInfo?.prepaid_deposit ===
                CAMPAIGN_PREPAID_DEPOSIT.NEED_CHARGE
                  ? '受け取る'
                  : '利用する'
              }
              isFixed={true}
              handleClickAction={handleClickToUsing}
            />
          </Col>
          <Col width="10" />
        </Row>
      )}
      <CouponCode
        open={isShowPopup}
        isActive={isActive}
        identificationNumber={identificationNumber}
        handleClose={handleClose}
        handleInput={handleInput}
      />
      <CouponChargeSuccess
        amount={couponInfo.campaign_value}
        open={successPopupOpen}
        handleClose={() => {
          setSuccessPopupOpen(false);
          setTimeout(() => {
            f7.view.main.router.navigate(paths.prepaid, {
              animate: true,
              reloadAll: true,
            });
          }, POPUP_CLOSE_ANIMATION_DELAY);
        }}
      />
    </PageTemplate>
  );
};
