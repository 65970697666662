import { FC } from 'react';
import { CarIconProps } from './CarIcon';

export const CarIcon4: FC<CarIconProps> = ({ color, reference }) => {
  return (
    <svg
      ref={reference}
      width="728"
      height="536"
      viewBox="0 0 91 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="0.75"
        width="88.5"
        height="65.5"
        rx="15.25"
        fill="#F3F5F6"
        stroke="#EFEFEF"
        strokeWidth="1.5"
      />
      <path
        d="M82 23.5C82 19.0817 78.4183 15.5 74 15.5L28.5053 15.5C27.1777 15.5 25.8584 15.7122 24.6738 16.3117C21.5151 17.9102 14.9441 22.1118 10.5 31C8.8711 34.2578 8.51579 40.9117 8.46594 45.1532C8.44068 47.3024 10.1998 48.9998 12.3491 48.9998H78C80.2091 48.9998 82 47.2089 82 44.9998V23.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3745 37.9929C11.6689 35.4293 12.1311 33.328 12.7361 32.118C16.8687 23.8528 22.9574 19.9822 25.8027 18.5423C26.5303 18.174 27.4292 18 28.5053 18H74C77.0376 18 79.5 20.4624 79.5 23.5V44.9998C79.5 45.8282 78.8284 46.4998 78 46.4998H12.3491C11.5219 46.4998 10.9577 45.8706 10.9658 45.1826C10.9902 43.1053 11.0894 40.4754 11.3745 37.9929ZM74 15.5C78.4183 15.5 82 19.0817 82 23.5V44.9998C82 47.2089 80.2091 48.9998 78 48.9998H12.3491C10.1998 48.9998 8.44068 47.3024 8.46594 45.1532C8.51579 40.9117 8.8711 34.2578 10.5 31C14.9441 22.1118 21.5151 17.9102 24.6738 16.3117C25.8584 15.7122 27.1777 15.5 28.5053 15.5H74Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.381 37.8788C10.6771 35.3003 11.1529 33.0484 11.8416 31.6708C16.0988 23.1564 22.3805 19.1534 25.3511 17.65C26.2615 17.1893 27.3286 17 28.5053 17H74C77.5898 17 80.5 19.9102 80.5 23.5V44.9998C80.5 46.3805 79.3807 47.4998 78 47.4998H12.3491C10.9931 47.4998 9.95088 46.4433 9.96584 45.1708C9.99046 43.0761 10.0905 40.4087 10.381 37.8788ZM74 15.5C78.4183 15.5 82 19.0817 82 23.5V44.9998C82 47.2089 80.2091 48.9998 78 48.9998H12.3491C10.1998 48.9998 8.44068 47.3024 8.46594 45.1532C8.51579 40.9117 8.8711 34.2578 10.5 31C14.9441 22.1118 21.5151 17.9102 24.6738 16.3117C25.8584 15.7122 27.1777 15.5 28.5053 15.5H74Z"
        fill="#323232"
      />
      <path
        d="M23.7167 22.0585C24.5304 21.3455 25.5847 21 26.6665 21H35.4999C36.0522 21 36.4999 21.4477 36.4999 22V29C36.4999 29.5523 36.0522 30 35.4999 30H19.2465C18.6067 30 18.1348 29.406 18.3736 28.8125C19.4818 26.0579 22.1238 23.4544 23.7167 22.0585Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9999 22.5H26.6665C25.8988 22.5 25.2103 22.7441 24.7053 23.1866C23.3172 24.4031 21.2998 26.4058 20.1761 28.5H34.9999V22.5ZM26.6665 21C25.5847 21 24.5304 21.3455 23.7167 22.0585C22.1238 23.4544 19.4818 26.0579 18.3736 28.8125C18.1348 29.406 18.6067 30 19.2465 30H35.4999C36.0522 30 36.4999 29.5523 36.4999 29V22C36.4999 21.4477 36.0522 21 35.4999 21H26.6665Z"
        fill="#323232"
      />
      <path
        d="M39.5 22C39.5 21.4477 39.9477 21 40.5 21H56.5C57.0523 21 57.5 21.4477 57.5 22V29C57.5 29.5523 57.0523 30 56.5 30H40.5C39.9477 30 39.5 29.5523 39.5 29V22Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 22.5V28.5H56V22.5H41ZM40.5 21C39.9477 21 39.5 21.4477 39.5 22V29C39.5 29.5523 39.9477 30 40.5 30H56.5C57.0523 30 57.5 29.5523 57.5 29V22C57.5 21.4477 57.0523 21 56.5 21H40.5Z"
        fill="#323232"
      />
      <path
        d="M59.5 24C59.5 23.4477 59.9477 23 60.5 23H75.5C76.0523 23 76.5 23.4477 76.5 24V31C76.5 31.5523 76.0523 32 75.5 32H60.5C59.9477 32 59.5 31.5523 59.5 31V24Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61 22.5V28.5H75V22.5H61ZM60.5 21C59.9477 21 59.5 21.4477 59.5 22V29C59.5 29.5523 59.9477 30 60.5 30H75.5C76.0523 30 76.5 29.5523 76.5 29V22C76.5 21.4477 76.0523 21 75.5 21H60.5Z"
        fill="#323232"
      />
      <path
        d="M34.5 43.75C34.5 43.3358 34.8358 43 35.25 43H47.75C48.1642 43 48.5 43.3358 48.5 43.75C48.5 44.1642 48.1642 44.5 47.75 44.5H35.25C34.8358 44.5 34.5 44.1642 34.5 43.75Z"
        fill="#323232"
      />
      <path
        d="M32.5 46.5C32.5 51.1944 28.6944 55 24 55C19.3056 55 15.5 51.1944 15.5 46.5C15.5 41.8056 19.3056 38 24 38C28.6944 38 32.5 41.8056 32.5 46.5Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 53.5C27.866 53.5 31 50.366 31 46.5C31 42.634 27.866 39.5 24 39.5C20.134 39.5 17 42.634 17 46.5C17 50.366 20.134 53.5 24 53.5ZM24 55C28.6944 55 32.5 51.1944 32.5 46.5C32.5 41.8056 28.6944 38 24 38C19.3056 38 15.5 41.8056 15.5 46.5C15.5 51.1944 19.3056 55 24 55Z"
        fill="#323232"
      />
      <path
        d="M76.5 46.5C76.5 51.1944 72.6944 55 68 55C63.3056 55 59.5 51.1944 59.5 46.5C59.5 41.8056 63.3056 38 68 38C72.6944 38 76.5 41.8056 76.5 46.5Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68 53.5C71.866 53.5 75 50.366 75 46.5C75 42.634 71.866 39.5 68 39.5C64.134 39.5 61 42.634 61 46.5C61 50.366 64.134 53.5 68 53.5ZM68 55C72.6944 55 76.5 51.1944 76.5 46.5C76.5 41.8056 72.6944 38 68 38C63.3056 38 59.5 41.8056 59.5 46.5C59.5 51.1944 63.3056 55 68 55Z"
        fill="#323232"
      />
      <path
        d="M28.5 46.5C28.5 48.9853 26.4853 51 24 51C21.5147 51 19.5 48.9853 19.5 46.5C19.5 44.0147 21.5147 42 24 42C26.4853 42 28.5 44.0147 28.5 46.5Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 49.5C25.6569 49.5 27 48.1569 27 46.5C27 44.8431 25.6569 43.5 24 43.5C22.3431 43.5 21 44.8431 21 46.5C21 48.1569 22.3431 49.5 24 49.5ZM24 51C26.4853 51 28.5 48.9853 28.5 46.5C28.5 44.0147 26.4853 42 24 42C21.5147 42 19.5 44.0147 19.5 46.5C19.5 48.9853 21.5147 51 24 51Z"
        fill="#323232"
      />
      <path
        d="M72.5 46.5C72.5 48.9853 70.4853 51 68 51C65.5147 51 63.5 48.9853 63.5 46.5C63.5 44.0147 65.5147 42 68 42C70.4853 42 72.5 44.0147 72.5 46.5Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68 49.5C69.6569 49.5 71 48.1569 71 46.5C71 44.8431 69.6569 43.5 68 43.5C66.3431 43.5 65 44.8431 65 46.5C65 48.1569 66.3431 49.5 68 49.5ZM68 51C70.4853 51 72.5 48.9853 72.5 46.5C72.5 44.0147 70.4853 42 68 42C65.5147 42 63.5 44.0147 63.5 46.5C63.5 48.9853 65.5147 51 68 51Z"
        fill="#323232"
      />
    </svg>
  );
};
