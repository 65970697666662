import { useStore } from 'framework7-react';
import { FC, useMemo } from 'react';
import { LoginMethodCard } from '../LoginMethodCard/LoginMethodCard';
import { PhoneNumberRegistrationCard } from '../PhoneNumberRegistrationCard/PhoneNumberRegistrationCard';
import { SignInList } from '../SignInList/SignInList';
import style from './SigninComponent.module.css';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import { useLoginPermission } from '@/hooks/api/account/useLoginPermission';

export const SigninComponent: FC = () => {
  // todo:: 要確認!! ci error対策 merge_requests/271
  const { data: means } = useLoginPermission({
    m_customer_id: store.state.authInfo.m_customer_id,
  });
  const customerInfo = useStore(store, 'customerInfo');
  const loginType = store.state.loginInfo['loginType'];
  const isTelephoneLogin = loginType === 'telephone';

  const isDisplayRegisteredPhoneNumber = () => {
    return customerInfo.phone_no1 || customerInfo.phone_no2 ? false : true;
  };

  const loginInfoLabelList = useMemo(
    () => [
      {
        label: 'メールアドレス',
        value: customerInfo.email,
        iconNext: 'icon-next',
        path: '/edit_email/',
      },
      {
        label: 'パスワード',
        value: '********',
        iconNext: 'icon-next',
        path: '/update_password/',
      },
    ],
    [customerInfo],
  );

  return (
    <PageTemplate pageName="Signin" title="ログイン情報" showNavBack>
      {isDisplayRegisteredPhoneNumber() && <PhoneNumberRegistrationCard />}
      {/* @ts-ignore  todo:: 要確認!! ci error対策 merge_requests/271 */}
      {isTelephoneLogin && <LoginMethodCard means={means} />}

      <div className={style.card}>
        <h3 className={style.heading}>ログイン情報（メールアドレス）</h3>
        <p className={style.description}>
          各々の項目をタップすると、変更・登録ができます
        </p>
        <ul className={style['link-wrap']}>
          {loginInfoLabelList.map((item, index) => (
            <SignInList
              key={index}
              path={item.path}
              head={item.label}
              specificValue={item.value}
            />
          ))}
        </ul>
      </div>
    </PageTemplate>
  );
};
