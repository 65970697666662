import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { PinCode } from './PinCode/PinCode';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { selectedProcess } from '@/consts/auth';
import { useMobileAuth } from '@/hooks/api/auth/useMobileAuth';
import { MobileAuthApiParams, SelectedProcess } from '@/types/api/authApi';
import {
  addAffiliateIdToRequestParams,
  getAffiliateIdFromThemeId,
} from '@/utils/shop';

interface PinCodePageProps {
  f7router: Router.Router;
  process: SelectedProcess;
  telNumber: {
    now: string;
    before?: string;
  };
}

export const PinCodePage: FC<PinCodePageProps> = ({
  f7router,
  telNumber,
  process,
}) => {
  const themeId = store.state.themeId;
  const { authenticateMobile } = useMobileAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeIsModalOpen = (bool: boolean) => {
    setIsModalOpen(bool);
  };
  const handleClickReSendSms = () => {
    const params: MobileAuthApiParams = {
      phone_no: store.state.loginInfo.tel,
      selected_process: selectedProcess.login,
    };
    addAffiliateIdToRequestParams(getAffiliateIdFromThemeId(themeId), params);
    authenticateMobile(params, true);
    f7.popup.close('#ResendSms');
  };
  const handleClickReTypeNumber = () => {
    f7.view.main.router.navigate(paths.login);
    f7.popup.close('#ResendSms');
  };
  const handleClickCannotReceive = () => {
    // TODO: メールアドレス変更画面(creat_account_by_email/resend_sms/)に遷移させる
  };
  const handleClickShouldReceive = () => {
    f7.view.main.router.navigate(paths.smsDisable2);
  };
  return (
    <PinCode
      f7router={f7router}
      process={process}
      telNumber={telNumber}
      isModalOpen={isModalOpen}
      affiliateId={getAffiliateIdFromThemeId(themeId)}
      changeIsModalOpen={changeIsModalOpen}
      handleClickReSendSms={handleClickReSendSms}
      handleClickCannotReceive={handleClickCannotReceive}
      handleClickReTypeNumber={handleClickReTypeNumber}
      handleClickShouldReceive={handleClickShouldReceive}
    />
  );
};
