import { FC } from 'react';
import style from './carInfoStyle.module.css';
import { getCarImageSrc } from '@/utils/utils';

interface CarInfoProps {
  mkNm: string;
  carNm: string;
  gradeNm: string;
  colorNm: string;
  carPhotoUrl: string;
}

export const CarInfo: FC<CarInfoProps> = ({
  mkNm,
  carNm,
  carPhotoUrl,
  gradeNm,
  colorNm,
}) => {
  return (
    <>
      <div className={style['car-card-wrapper']}>
        <div className={style['car-card']}>
          <div className={style['car-selected-wrapper']}>
            <div className={style['car-image-wrapper']}>
              <img src={getCarImageSrc(carPhotoUrl)} />
            </div>
            <div className={style['car-information-wrapper']}>
              <span className={style['car-information-text']}>{mkNm}</span>
              <span className={style['car-information-title']}>{carNm}</span>
              <span className={style['car-information-text']}>
                {gradeNm}/{colorNm}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
