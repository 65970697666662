import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NotificationForm } from '../NotificationForm/NotificationForm';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { linkDriveStatus as LINK_DRIVE_STATUS } from '@/consts/linkDrive';
import { linkDriveStatus as constLinkDriveStatus } from '@/consts/linkDrive';
import { notificationCategory, targetFlg } from '@/consts/notification';
import {
  EmergencyType,
  emergencyType,
  notificationMapping,
} from '@/consts/strikeNoticeSettings';
import { useVehicleStrikeSettings } from '@/hooks/api/driveRecord/useVehicleStrikeSettings';
import { useUpdateUserInformation } from '@/hooks/api/linkDrive/useUpdateUserInformation';
import { useToast } from '@/hooks/useToast';
import { AccountInfoResponse } from '@/types/api/account';
import { trigger } from '@/utils/eventHelper';
import { f7CustomBack } from '@/utils/utils';
export type NoticeSettingType = {
  type: number;
  status?: boolean;
  under_text: string;
  props: string;
  name: string;
  emergencyType?: EmergencyType;
};

type NotificationSettings = {
  options_title: string;
  type: number;
  props: string;
  status: number;
};

interface StrikeNoticeSettingsProps {
  f7router: Router.Router;
  settings: NotificationSettings[];
}

type CheckBoxType = {
  ecall_big: boolean;
  ecall_middle: boolean;
  ecall_small: boolean;
  ecall_middle_and_big: boolean;
  not_notify: boolean;
  emergencyType?: EmergencyType;
};
// TODO: 初期化処理が必要
// propsで現在の設定内容を取得してcheckbox, radioに反映させる
export const StrikeNoticeSettings: FC<StrikeNoticeSettingsProps> = ({
  f7router,
  settings,
}) => {
  const {
    carItemInfo: { linkdrive_status: linkDriveStatus },
    authInfo: { m_customer_id: mCustomerId },
    linkDriveOwnerInfo: { owner_id: ownerId },
    customerInfo: { email },
  } = store.state;

  const isInsuranceSubscription =
    linkDriveStatus === LINK_DRIVE_STATUS.FORMAL_REGISTRATION;
  const notification_category =
    linkDriveStatus === constLinkDriveStatus.FORMAL_REGISTRATION
      ? notificationCategory.FORMAL_REGISTRATION
      : notificationCategory.TEMPORARY_REGISTRATION;
  const { updateVehicleStrikeSetting } = useVehicleStrikeSettings();
  const { updateLinkDriveUser } = useUpdateUserInformation();
  const { openToast } = useToast();

  const defaultValues = useMemo(() => {
    if (isInsuranceSubscription) {
      return {
        ecall_middle_and_big: !!(settings[0]?.status && settings[1]?.status),
        ecall_big: !!settings[0]?.status && settings[1]?.status === 0,
        not_notify: !(settings[0]?.status || settings[1]?.status),
      };
    }
    return {
      ecall_big: !!settings[0]?.status,
      ecall_middle: !!settings[1]?.status,
      ecall_small: !!settings[2]?.status,
    };
  }, [isInsuranceSubscription, settings]);
  const formMethods = useForm<CheckBoxType>({
    defaultValues,
    // resolver: yupResolver(schema), // yup, joi and even your own.
  });
  const { handleSubmit } = formMethods;
  const noticeSettings: NoticeSettingType[] = useMemo(
    () =>
      isInsuranceSubscription
        ? [
            {
              type: 4,
              status: defaultValues.ecall_middle_and_big,
              under_text: '',
              props: 'ecall_middle_and_big',
              name: '中程度&大きな衝撃を受けた際に通知する',
              emergencyType: emergencyType.bigAndMiddle,
            },
            {
              type: 5,
              status: defaultValues?.ecall_big,
              under_text: '',
              props: 'ecall_big',
              name: '大きな衝撃を受けた際に通知する',
              emergencyType: emergencyType.big,
            },
            {
              type: 0,
              status: defaultValues?.not_notify,
              under_text: '',
              props: 'not_notify',
              name: '通知しない',
              emergencyType: emergencyType.noNotification,
            },
          ]
        : [
            {
              type: 3,
              status: defaultValues?.ecall_big,
              under_text: '大きな衝撃は必ず通知されます',
              props: 'ecall_big',
              name: '大きな衝撃を受けた際に通知する',
            },
            {
              type: 2,
              status: defaultValues?.ecall_middle,
              under_text: '中程度の衝撃は必ず通知されます',
              props: 'ecall_middle',
              name: '中程度の衝撃を受けた際に通知する',
            },
            {
              type: 1,
              status: defaultValues?.ecall_small,
              under_text: '小さな衝撃は必ず通知されます',
              props: 'ecall_small',
              name: '小さな衝撃を受けた際に通知する',
            },
          ],
    [
      defaultValues?.ecall_big,
      defaultValues?.ecall_middle,
      defaultValues?.ecall_middle_and_big,
      defaultValues?.ecall_small,
      defaultValues?.not_notify,
      isInsuranceSubscription,
    ],
  );

  const updateUser = async (emergencyType?: EmergencyType) => {
    await updateLinkDriveUser({
      params: {
        owner_id: ownerId,
        mailaddress: email,
        emergency_type: emergencyType,
      },
      hideToast: true,
    });
  };
  const updateSetting = async (
    valueCheckbox: boolean,
    notification_type: number,
    notiSettings?: NoticeSettingType,
    enabled?: boolean,
  ) => {
    const setting = notiSettings
      ? notiSettings
      : noticeSettings?.find(({ type }) => type === notification_type);
    return await updateVehicleStrikeSetting({
      params: {
        m_customer_id: mCustomerId,
        notification_category,
        notification_type,
        target_flg:
          valueCheckbox && setting?.type !== notificationMapping.noNotification
            ? targetFlg.TRUE
            : targetFlg.FALSE,
      },
      enabled: enabled || valueCheckbox !== setting?.status,
    });
  };
  const completeApi = () => {
    trigger('fetchVehicleStrikeSettings', {});
    trigger('setDefaultValue', {});
  };
  const submit = async ({
    ecall_big,
    ecall_middle,
    ecall_small,
    ecall_middle_and_big,
    not_notify,
    emergencyType,
  }: CheckBoxType) => {
    f7.preloader.show();
    try {
      if (isInsuranceSubscription) {
        let setting = noticeSettings?.find((setting) => {
          return setting?.emergencyType === emergencyType;
        });
        await updateUser(emergencyType);

        const updateBigImpact = await updateSetting(
          ecall_big || ecall_middle_and_big || not_notify,
          notificationMapping.big,
          setting,
        );
        const updateMiddleImpact = await updateSetting(
          ecall_middle_and_big || not_notify,
          notificationMapping.middle,
          setting,
          !!ecall_big,
        );

        Promise.allSettled([updateBigImpact, updateMiddleImpact]);
      } else {
        const updateBigImpact = await updateSetting(
          ecall_big,
          notificationMapping.big,
        );
        const updateMiddleImpact = await updateSetting(
          ecall_middle,
          notificationMapping.middle,
        );
        const updateSmallImpact = await updateSetting(
          ecall_small,
          notificationMapping.small,
        );

        Promise.allSettled([
          updateBigImpact,
          updateMiddleImpact,
          updateSmallImpact,
        ]);
      }
      completeApi();
      openToast('衝撃通知設定を変更しました');
    } catch (e) {
      openToast(
        (e as AccountInfoResponse['data']['error'])?.message ??
          'エラーが発生しました',
      );
    } finally {
      f7.preloader.hide();
      f7CustomBack();
    }
  };

  return (
    <PopupPageTemplate
      id="NoticeSettingsPopUp"
      pageName="NoticeSettings"
      title="衝撃通知設定"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submit)}>
          {settings && (
            <div>
              <NotificationForm
                notificationSettings={noticeSettings}
                isInsuranceSubscription={isInsuranceSubscription}
              />
              <Button
                text="変更を保存する"
                type="submit"
                style={{
                  marginTop: isInsuranceSubscription ? '340px' : '320px',
                }}
              />
            </div>
          )}
        </form>
      </FormProvider>
    </PopupPageTemplate>
  );
};
