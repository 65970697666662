import perse from 'html-react-parser';
import { FC } from 'react';
interface WarrantyFlowProps {}
import styles from './WarrantyAutoRenewalStyle.module.scss';
import { ArrowDownIcon, CircularArrowIcon } from '@/components/uiParts/Icon';

const warrantyFlowOptions = [
  {
    label: perse(`更新月3ヵ前1日<br/>
    ～2ヵ月前末日`),
    desc: perse(`解約（更新停止）<br/>
    <p>手続可能期間</p>`),
  },
  {
    label: '更新月前月',
    desc: perse(`次年度保証料<br/>
    <p>請求開始</p>`),
  },
  {
    label: '保証更新月',
    desc: `次年度保証開始`,
  },
];
export const WarrantyFlow: FC<WarrantyFlowProps> = () => {
  const lastoptions = warrantyFlowOptions.length - 1;

  return (
    <div className="u-margin-top-sm">
      {warrantyFlowOptions.map((option, index) => (
        <div className={styles['flow-wrapper']} key={index}>
          <div className="display-flex" style={{ gap: '12px' }}>
            <div
              className={styles['flow-label']}
              style={{
                background: lastoptions === index ? '#1E55E6' : '',
                color: lastoptions === index ? 'white' : '',
              }}
            >
              {option.label}
            </div>
            <div className={styles['flow-wrapper_desc']}>
              <CircularArrowIcon />
              <div className={styles['flow-desc']}>{option.desc}</div>
            </div>
          </div>
          {lastoptions > index && (
            <div className={styles['flow-arrow']} style={{ padding: '12px 0' }}>
              <ArrowDownIcon color="#C9C9C9" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
