import { parse } from 'date-fns';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './ScanHistoryDetailStyle.module.css';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import { ErrorType } from '@/types/api/linkDriveApi';
import { formatToJaDateTime } from '@/utils/date';

interface ErrorDetailListProps {
  title: string;
  errors: string | ErrorType[];
}
interface ScanHistoryDetailProps {
  id: number;
  f7router: Router.Router;
}

// TODO: LinkDriveのテストアカウントが用意され次第動作確認
export const ScanHistoryDetail: FC<ScanHistoryDetailProps> = ({
  id,
  f7router,
}) => {
  const errors = store.state.scanResultErrors;
  const scanResult = store.state.scanResults.filter(
    (scanResult) => String(scanResult.id) === String(id),
  )[0];
  const implementationDate =
    scanResult &&
    formatToJaDateTime(
      parse(scanResult.diagnosed_at, 'yyyy-MM-dd HH:mm:ss', new Date()),
    );

  return (
    <PageTemplate pageName="ScanHistoryDetail" title="スキャン履歴" showNavBack>
      <div className={style.card}>
        <h3 className={style['card-head']}>実施概要</h3>
        <div style={{ width: '100%', height: '16px' }} />
        <ul className={style.lists}>
          <li className={style.list}>
            <div>開始日時</div>
            <div className={style.body}>{implementationDate}</div>
          </li>
        </ul>
        <ErrorDetailList
          title="パワートレイン"
          errors={scanResult.powertrain}
        />
        <ErrorDetailList title="シャーシ" errors={scanResult.chassis} />
        <ErrorDetailList title="ボディ" errors={scanResult.body} />
        <ErrorDetailList title="ネットワーク" errors={scanResult.network} />
      </div>
    </PageTemplate>
  );
};

const ErrorDetailList: FC<ErrorDetailListProps> = ({ title, errors }) => {
  const hasError = typeof errors !== 'string' && errors.length;
  return (
    <div className={style['custom-lists']}>
      <h4>{title}</h4>
      {hasError &&
        errors.map((error) => (
          <>
            <ul className={style['custom-list']}>
              <li className={style['custom-list-item']}>
                <span>クラウド診断結果詳細ID</span>
                <p>{error.id}</p>
              </li>
              <li className={style['custom-list-item']}>
                <span>点検箇所</span>
                <p>{error.dtc_check_point}</p>
              </li>
              <li className={style['custom-list-item']}>
                <span>故障コード</span>
                <p>{error.dtc_code}</p>
              </li>
              <li className={style['custom-list-item']}>
                <span>内容</span>
                <p>{error.dtc_message}</p>
              </li>
              <li className={style['custom-list-item']}>
                <span>理由</span>
                <p>{error.dtc_reason}</p>
              </li>
              <li className={style['custom-list-item']}>
                <span>故障部位</span>
                <p>{error.dtc_title}</p>
              </li>
            </ul>
          </>
        ))}
      {!hasError && (
        <p className={style['custom-list-empty']}>検出されませんでした</p>
      )}
    </div>
  );
};
