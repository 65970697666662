import { FC } from 'react';
import style from './ContactCardLinkCardLink.module.css';

interface ContactCardLinkCardLinkProps {
  imageLeft: string;
  imageRight: string;
  cardTitle: string;
  cardBody: string;
  cardType: 'phone' | 'text';
  handleClick: () => void;
}

export const ContactCardLinkCardLink: FC<ContactCardLinkCardLinkProps> = ({
  imageLeft,
  imageRight,
  cardTitle,
  cardBody,
  cardType,
  handleClick,
}) => {
  return (
    <div onClick={handleClick} className={style.card}>
      {imageLeft && (
        <div className={style.imageLeft}>
          <img width={'40px'} height={'100%'} src={imageLeft} alt="" />
        </div>
      )}
      <div className={style['card-content']}>
        <p className={style['card-title']}>{cardTitle}</p>
        <p className={`${style['card-body']} ${style[cardType]}`}>{cardBody}</p>
      </div>
      {imageRight && (
        <div className={style.imageRight}>
          <img width={'32px'} height={'100%'} src={imageRight} alt="" />
        </div>
      )}
    </div>
  );
};
