import { f7 } from 'framework7-react';
import { useCallback, useState } from 'react';
import { TradeGuaranteeFormInput } from '../TradeGuaranteeForm/TradeGuaranteeForm';
import {
  getUploadedWarrantyDocumentsApi,
  getWarrantyDocumentsApi,
  postDealWarrantyApi,
  postWarrantyDocumentsApi,
} from '@/api/warrantyApi';
import { store } from '@/config/store';
import { UPLOADED_BY } from '@/consts/warranty';
import { Documents, WarrantyDocument } from '@/types/api/warrantyApi';
import { f7CustomBack } from '@/utils/utils';

export const TRADE_GUARANTEE_STEPS = {
  PRE_START_CONFIRMATION: 0,
  DETAIL_FORM: 1,
  UPLOAD_DOCUMENTS: 2,
  UPLOAD: 3,
  CONFIRMATION: 4,
};

export const useTradeGuarantee = () => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<WarrantyDocument>();
  const [uploadedDocument, setUploadedDocument] = useState<
    Record<number, Documents>
  >([]);
  const [warrantyDocument, setWarrantyDocument] = useState<WarrantyDocument[]>(
    [],
  );
  const [detailData, setDetailData] = useState<TradeGuaranteeFormInput>({
    issues: [
      {
        issue_category: null,
        issue_detail: '',
        issue_found_date: '',
      },
    ],
  });
  const [step, setStep] = useState(
    TRADE_GUARANTEE_STEPS.PRE_START_CONFIRMATION,
  );

  const fetchDocuments = useCallback(async (data: TradeGuaranteeFormInput) => {
    const docs: WarrantyDocument[] = [];
    const issues = Array.from(
      new Set(data.issues.map((issue) => issue.issue_category)),
    );

    for (const issue of issues) {
      const doc = await getWarrantyDocumentsApi({
        warranty_type:
          store.state.currentWarranty?.append_warranties?.[0].warranty_type ||
          0,
        issue_category: issue || 0,
        // warranty_type: 4,
        // issue_category: 0,
      });
      if (doc.data.success) {
        doc.data.documents.forEach((doc) => {
          if (!docs.find((d) => d.document_type === doc.document_type)) {
            docs.push(doc);
          }
        });
      }
    }
    setWarrantyDocument(docs);
  }, []);

  const handleSubmitStep1 = useCallback(() => {
    setStep(TRADE_GUARANTEE_STEPS.DETAIL_FORM);
    setIsConfirmed(true);
  }, []);

  const handleSubmitStep2 = useCallback(
    (data: TradeGuaranteeFormInput) => {
      setStep(TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS);
      setDetailData(data);
      f7.preloader.show();
      const documentPromise = fetchDocuments(data);
      const uploadedPromise = getUploadedWarrantyDocumentsApi({
        m_customer_id: store.state.authInfo.m_customer_id,
        t_stock_car_id: store.state.carItemInfo.t_stock_car_id,
        warranty_no: store.state.currentWarranty?.warranty_no || '',
        branch_no: store.state.currentWarranty?.branch_no || 0,
      }).then((res) => {
        setUploadedDocument(res.data.documents);
      });

      Promise.allSettled([documentPromise, uploadedPromise]).then(() => {
        f7.preloader.hide();
      });
    },
    [fetchDocuments],
  );

  const handleSubmitStep3 = useCallback(() => {
    setStep(TRADE_GUARANTEE_STEPS.CONFIRMATION);
  }, []);

  const handleClickDocument = useCallback((document: WarrantyDocument) => {
    setStep(TRADE_GUARANTEE_STEPS.UPLOAD);
    setSelectedDocument(document);
  }, []);

  const handleUpload = useCallback(
    async (files: File[]) => {
      if (selectedDocument?.m_warranty_document_property_id == null) return;
      const res = await postWarrantyDocumentsApi({
        warranty_no: store.state.currentWarranty?.warranty_no || '',
        documents: files.map((file) => ({
          file,
          document_type: selectedDocument.document_type,
        })),
        uploaded_by: 2, // 提供元（1：販売店、2：顧客）
      });
      if (!res?.data?.success) {
        throw new Error();
      }
      await getUploadedWarrantyDocumentsApi({
        m_customer_id: store.state.authInfo.m_customer_id,
        t_stock_car_id: store.state.carItemInfo.t_stock_car_id,
        warranty_no: store.state.currentWarranty?.warranty_no || '',
        branch_no: store.state.currentWarranty?.branch_no || 0,
      }).then((res) => {
        setUploadedDocument(res.data.documents);
      });
    },
    [selectedDocument],
  );

  const handleBack = useCallback(() => {
    if (step > TRADE_GUARANTEE_STEPS.PRE_START_CONFIRMATION) {
      if (step === TRADE_GUARANTEE_STEPS.CONFIRMATION) {
        setStep(TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS);
        return;
      }
      // eslint-disable-next-line no-magic-numbers
      setStep(step - 1);
      return;
    }
    f7CustomBack();
  }, [step]);

  const handleSubmitStep4 = useCallback(async () => {
    const res = await postDealWarrantyApi({
      warranty_no: store.state.currentWarranty?.warranty_no || '',
      m_affiliate_id: store.state.customerInfo?.m_affiliate_id,
      m_customer_id: store.state.authInfo.m_customer_id,
      t_stock_car_id: store.state.carItemInfo.t_stock_car_id,
      branch_no: store.state.currentWarranty?.branch_no || 0,
      m_unit_id: store.state.currentWarranty?.m_unit_id || 0,
      issues: detailData.issues.map((issue) => ({
        issue_category: issue.issue_category || 0,
        issue_detail: issue.issue_detail || '',
        issue_found_date: issue.issue_found_date || '',
      })),
      uploaded_by: UPLOADED_BY.CUSTOMER,
    });
    if (!res?.data?.success) {
      throw new Error();
    }
  }, [detailData]);

  return {
    step,
    isConfirmed,
    detailData,
    warrantyDocument,
    uploadedDocument,
    selectedDocument,
    setStep,
    handleUpload,
    handleBack,
    handleClickDocument,
    handleSubmitStep1,
    handleSubmitStep2,
    handleSubmitStep3,
    handleSubmitStep4,
  };
};
