import { AccordionContent, List, ListItem, useStore } from 'framework7-react';
import { FC } from 'react';
import './balanceDetailsStyle.scss';
import { Prepaid } from '@/types/api/customerApi';
interface BalanceDetailsProps {}

export const BalanceDetails: FC<BalanceDetailsProps> = () => {
  const prepaid = useStore('getFirstAdvancePaymentsInfo') as
    | Required<Prepaid>
    | undefined;
  const prepaidHistories = prepaid?.t_houseprepaid_payment_histories?.sort(
    (a: any, b: any) => {
      // eslint-disable-next-line no-magic-numbers
      return new Date(a.deal_date) < new Date(b.deal_date) ? -1 : 1;
    },
  );
  const prepaidLatestHistories = prepaidHistories?.slice(-1)[0] ?? null;
  return (
    <List>
      <ListItem accordionItem accordionItemOpened title="残高内訳を確認する">
        <AccordionContent>
          <div className="display-flex-sb">
            <p>基本マネー</p>
            <p className="item-content-amount">
              {prepaidLatestHistories?.balance_basic?.toLocaleString() || 0} 円
            </p>
          </div>
          <div className="display-flex-sb">
            <p>ボーナスマネー</p>
            <p className="item-content-amount">
              {prepaidLatestHistories?.balance_bonus?.toLocaleString() || 0} 円
            </p>
          </div>
          <div className="display-flex-sb">
            <p>クーポン</p>
            <p className="item-content-amount">
              {prepaidLatestHistories?.balance_coupon?.toLocaleString() || 0} 円
            </p>
          </div>
          <div className="balance-description">
            <span>※</span> 各マネーの有効期限は残高履歴からご確認ください
          </div>
        </AccordionContent>
      </ListItem>
    </List>
  );
};
