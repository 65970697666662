export const echartsScale = (type: string, valueList: number[]) => {
  let maxValue = Math.max.apply(null, valueList);
  switch (type) {
    case 'drivingDuration':
      return drivingDurationScale(maxValue);
    case 'drivingFuel':
      return drivingFuelScale(maxValue);
    case 'phydScore':
      return phydScoreScale(maxValue);
    case 'rapidDeceleration':
      return rapidDecelerationScale(maxValue);
    case 'rapidAcceleration':
      return rapidAccelerationScale(maxValue);
    case 'speedOver':
      return speedOverScale(maxValue);
    default:
      return;
  }
};
// 一日の運転時間
function drivingDurationScale(value: any) {
  if (value >= 0 && value <= 1) {
    return {
      minNumber: 0,
      maxNumber: 1,
      YaxisLabelInterval: 0.2,
    };
  } else if (value <= 2) {
    return {
      minNumber: 0.5,
      maxNumber: 2,
      YaxisLabelInterval: 0.5,
    };
  } else if (value > 2 && value <= 4) {
    return {
      minNumber: 1,
      maxNumber: 4,
      YaxisLabelInterval: 1,
    };
  } else if (value > 4 && value <= 8) {
    return {
      minNumber: 2,
      maxNumber: 8,
      YaxisLabelInterval: 2,
    };
  } else if (value > 8 && value <= 12) {
    return {
      minNumber: 3,
      maxNumber: 12,
      YaxisLabelInterval: 3,
    };
  } else if (value > 12) {
    return {
      minNumber: 6,
      maxNumber: 24,
      YaxisLabelInterval: 6,
    };
  } else {
    return {
      minNumber: 0.5,
      maxNumber: 2,
      YaxisLabelInterval: 0.5,
    };
  }
}
// 一日の平均燃費
function drivingFuelScale(value: any) {
  if (value >= 0 && value <= 10) {
    return {
      minNumber: 4,
      maxNumber: 10,
      YaxisLabelInterval: 2,
    };
  } else if (value > 10 && value <= 20) {
    return {
      minNumber: 5,
      maxNumber: 20,
      YaxisLabelInterval: 5,
    };
  } else if (value > 20 && value <= 40) {
    return {
      minNumber: 10,
      maxNumber: 40,
      YaxisLabelInterval: 10,
    };
  } else if (value > 40) {
    return {
      minNumber: 15,
      maxNumber: 60,
      YaxisLabelInterval: 15,
    };
  } else {
    return {
      minNumber: 4,
      maxNumber: 10,
      YaxisLabelInterval: 2,
    };
  }
}
// 安全運転スコア
function phydScoreScale(value: any) {
  // if(value <= 40) {
  //     return {
  //         minNumber: 0,
  //         maxNumber: 40,
  //         YaxisLabelInterval: 10
  //     }
  // } else {
  return {
    minNumber: 0,
    maxNumber: 100,
    YaxisLabelInterval: 20,
  };
  // }
}
// 平均ブレーキ回数
function rapidDecelerationScale(value: any) {
  if (value >= 0 && value <= 6) {
    return {
      minNumber: 0,
      maxNumber: 6,
      YaxisLabelInterval: 2,
    };
  } else if (value > 6) {
    return {
      minNumber: 3,
      maxNumber: 12,
      YaxisLabelInterval: 3,
    };
  } else {
    return {
      minNumber: 0,
      maxNumber: 6,
      YaxisLabelInterval: 2,
    };
  }
}
// 平均アクセル回数
function rapidAccelerationScale(value: any) {
  if (value >= 0 && value <= 6) {
    return {
      minNumber: 0,
      maxNumber: 6,
      YaxisLabelInterval: 2,
    };
  } else if (value > 6) {
    return {
      minNumber: 3,
      maxNumber: 12,
      YaxisLabelInterval: 3,
    };
  } else {
    return {
      minNumber: 0,
      maxNumber: 6,
      YaxisLabelInterval: 2,
    };
  }
}
// 平均速度超過回数
function speedOverScale(value: any) {
  if (value >= 0 && value <= 6) {
    return {
      minNumber: 0,
      maxNumber: 6,
      YaxisLabelInterval: 2,
    };
  } else if (value > 6) {
    return {
      minNumber: 3,
      maxNumber: 12,
      YaxisLabelInterval: 3,
    };
  } else {
    return {
      minNumber: 0,
      maxNumber: 6,
      YaxisLabelInterval: 2,
    };
  }
}
