import { f7, useStore } from 'framework7-react';
import { useState } from 'react';
import { useLinkDriveOwner } from './api/info/useLinkDriveOwner';
import { useConnectorDetail } from './api/linkDrive/useConnectorDetail';
import { useConnectorJob } from './api/linkDrive/useConnectorJob';
import { useLinkDriveSetupStatus } from './api/linkDrive/useLinkDriveSetupStatus';
import { useConnectorStatusRecursiveCall } from './useConnectorStatusRecursiveCall';
import { AuthInfoState } from '@/config/initialState';
import { store } from '@/config/store';
import {
  connectorJob,
  linkDriveSetUpMode,
  mode,
  timeOutJob,
  updatePromiseResolveFlg,
} from '@/consts/linkDrive';
import { Car } from '@/types/api/customerApi';
import { LinkDriveOwner, LinkDriveOwnerApiParams } from '@/types/api/infoApi';
import { ConnectorJobApiParams } from '@/types/api/linkDriveApi';

// 更新作業の種類に応じた表示用テキストのマッピング
const currentUpdateComponent = {
  [connectorJob.INITIALIZE]: '',
  [connectorJob.FIRMWARE_UPDATE]: 'FW',
  [connectorJob.ECU_LIST_UPDATE]: 'ECUリスト',
  [connectorJob.IMPACT_JUDGEMENT_UPDATE]: '衝撃判定データ',
} as const;

/**
 * LinkDriveコネクタの更新プロセス管理する。
 *
 * @param {Function} initialDisplayConditions 初期表示条件を設定するコールバック関数
 * @returns {object} 更新処理実行関数および更新状態
 */
export const useConnectorUpdate = (initialDisplayConditions: () => void) => {
  const [showDialog, setShowDialog] = useState(false);
  const ownerId = useStore(store, 'ownerId') as number;
  const { t_stock_car_id } = useStore(store, 'carItemInfo') as Required<Car>;
  const { m_customer_id } = useStore(store, 'authInfo') as AuthInfoState;
  const { serial_no, owner_id } = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;

  const {
    fetchConnectorDetail,
    data: connectorDetailInfo,
    isFirmWareUpToDate,
    isECUUpToDate,
    isImpactJudgementUpToDate,
  } = useConnectorDetail();
  const isFWLatestVersion = isFirmWareUpToDate;
  const isECULatestVersion = isECUUpToDate;
  const isImpactJudgementLatestVersion = isImpactJudgementUpToDate;
  const isAvaiableForUpdate =
    !isFWLatestVersion ||
    !isECULatestVersion ||
    !isImpactJudgementLatestVersion;

  // 更新フラグや進行状況
  const [progress, setProgress] = useState(0); // 進捗率
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  // 更新ジョブのための状態フラグ
  const [isDuringConnectorStartup, setIsDuringConnectorStartup] =
    useState(false);
  const [isFirmWareUpdating, setIsFirmWareUpdating] = useState(false);
  const [isFirmWareUpdated, setIsFirmWareUpdated] = useState(false);
  const [isECUUpdating, setIsECUUpdating] = useState(false);
  const [isECUUpdated, setIsECUUpdated] = useState(false);
  const [isImpactJudgementUpdating, setIsImpactJudgementUpdating] =
    useState(false);
  const [isImpactJudgementUpdated, setIsImpactJudgementUpdated] =
    useState(false);

  const [currentUpdate, setCurrentUpdate] = useState<string>(
    currentUpdateComponent[connectorJob.INITIALIZE],
  );
  const controller = new AbortController();

  const linkDriveOwnerApiParams: LinkDriveOwnerApiParams = {
    params: {
      customer_id: m_customer_id,
      stock_car_id: t_stock_car_id ?? 0,
      del_flg: 0,
    },
  };

  const { data: linkDriveOwner, setStoreLinkDriveOwner } = useLinkDriveOwner(
    linkDriveOwnerApiParams,
  );
  const { connectorStatusRecursiveCall } = useConnectorStatusRecursiveCall();
  const { fetchLinkDriveSetupStatus } = useLinkDriveSetupStatus();
  const { requestConnectorJob } = useConnectorJob();

  // TODO: 共通化
  const updateFirmWare = () => {
    const job = connectorJob.FIRMWARE_UPDATE;
    setCurrentUpdate(currentUpdateComponent[job]);
    setIsFirmWareUpdating(true);
    const params: ConnectorJobApiParams = {
      owner_id: Number(ownerId),
      job: '2',
      mode: '',
      prockbn: 1,
    };
    return new Promise<number>((resolve, reject) => {
      requestConnectorJob({
        params,
        resolve: async () => {
          const startTime = new Date().getTime();
          const rs = await recursiveInitStatus(job, startTime);
          setTimeout(() => {
            if (rs !== updatePromiseResolveFlg.ENDED) {
              setStateUpdating(job);
            }
            resolve(rs);
          }, 2000);
        },
        onFailure: () => {
          setTimeout(() => {
            setStateUpdating(job);
            resolve(updatePromiseResolveFlg.FAILLED);
          }, 2000);
        },
      });
    });
  };

  const updateECU = () => {
    const job = connectorJob.ECU_LIST_UPDATE;
    setCurrentUpdate(currentUpdateComponent[job]);
    setIsECUUpdating(true);
    const params: ConnectorJobApiParams = {
      owner_id: Number(ownerId),
      job: '3',
      mode: '',
    };
    return new Promise<number>((resolve, reject) => {
      requestConnectorJob({
        params,
        resolve: async () => {
          const startTime = new Date().getTime();
          const rs = await recursiveInitStatus(job, startTime);
          setTimeout(() => {
            if (rs !== updatePromiseResolveFlg.ENDED) {
              setStateUpdating(job);
            }
            resolve(rs);
          }, 2000);
        },
        onFailure: () => {
          setTimeout(() => {
            setStateUpdating(job);
            resolve(updatePromiseResolveFlg.FAILLED);
          }, 2000);
        },
      });
    });
  };

  const updateImpactJudgement = () => {
    const job = connectorJob.IMPACT_JUDGEMENT_UPDATE;
    setCurrentUpdate(currentUpdateComponent[job]);
    setIsImpactJudgementUpdating(true);
    const params: ConnectorJobApiParams = {
      owner_id: Number(ownerId),
      job: '4',
      mode: '',
    };
    return new Promise<number>((resolve, reject) => {
      requestConnectorJob({
        params,
        resolve: async () => {
          const startTime = new Date().getTime();
          const rs = await recursiveInitStatus(job, startTime);
          setTimeout(() => {
            if (rs !== updatePromiseResolveFlg.ENDED) {
              setStateUpdating(job);
            }
            resolve(rs);
          }, 2000);
        },
        onFailure: () => {
          setTimeout(() => {
            setStateUpdating(job);
            resolve(updatePromiseResolveFlg.FAILLED);
          }, 2000);
        },
      });
    });
  };

  // 仕様で
  // FirmWare → ECU → 衝撃判定
  // の順番で更新がかかる直列処理だったため並列処理はしていない
  const updateProcess = async () => {
    if (!isFWLatestVersion) {
      setProgress(0);
      const rs = await updateFirmWare();
      if (rs === updatePromiseResolveFlg.ENDED) {
        return;
      }
    }
    if (!isECULatestVersion) {
      setProgress(0);
      const rs = await updateECU();
      if (rs === updatePromiseResolveFlg.ENDED) {
        return;
      }
    }
    if (!isImpactJudgementLatestVersion) {
      setProgress(0);
      const rs = await updateImpactJudgement();
      if (rs === updatePromiseResolveFlg.ENDED) {
        return;
      }
    }
    initialDisplayConditions();
    setIsUpdating(false);
    setTimeout(() => {
      setIsOpened(false);
      reset();
    }, 2000);
  };

  // 更新状態をリセットする
  const reset = () => {
    setIsFirmWareUpdating(false);
    setIsFirmWareUpdated(false);
    setIsECUUpdating(false);
    setIsECUUpdated(false);
    setIsImpactJudgementUpdating(false);
    setIsImpactJudgementUpdated(false);
    setProgress(0);
    setIsFailure(false);
  };

  const setStateUpdating = (job: string) => {
    switch (job) {
      case connectorJob.FIRMWARE_UPDATE:
        setIsFirmWareUpdating(false);
        setIsFirmWareUpdated(true);
        break;
      case connectorJob.ECU_LIST_UPDATE:
        setIsECUUpdating(false);
        setIsECUUpdated(true);
        break;
      case connectorJob.IMPACT_JUDGEMENT_UPDATE:
        setIsImpactJudgementUpdating(false);
        setIsImpactJudgementUpdated(true);
        break;
      default:
        break;
    }
  };

  // init_statusを呼び出す
  // リンクドライブのセットアップステータスをポーリングする
  const recursiveInitStatus = (job: string, startTime: number) => {
    return new Promise<number>((resolve, reject) => {
      const params = {
        owner_id: ownerId,
        setUpMode: linkDriveSetUpMode.FIRMWARE_UPDATE,
      };
      const maxExecuteTime = timeOutJob[job];
      const now = new Date().getTime();
      const diff = (now - startTime) / 1000;

      if (diff >= maxExecuteTime) {
        setIsFailure(true);
        return resolve(updatePromiseResolveFlg.ENDED);
      }
      setTimeout(() => {
        fetchLinkDriveSetupStatus({
          params,
          signal: controller.signal,
          resolve: async (data) => {
            if (!data?.data) return resolve(updatePromiseResolveFlg.FAILLED);
            const currentProgress = data.data.progress;

            // if (data.data.init_status === 13 || data.data.progress >= 100 ) {
            if (currentProgress >= 100) {
              setProgress(100);
              return resolve(updatePromiseResolveFlg.SUCCESS);
            }

            // 未完了
            // TODO: 9の意味を設定する
            if (data.data.init_status !== 9) {
              setProgress(currentProgress);
              const rs = await recursiveInitStatus(job, startTime);
              return resolve(rs);
            }
          },
          onFailure: () => {
            reject();
          },
        });
      }, 3000);
    });
  };

  const handleClickUpdateSystemInfo = () => {
    reset();
    setIsUpdating(true);
    setIsOpened(true);
    setIsDuringConnectorStartup(true);
    f7.preloader.show();

    const params = { owner_id, serial_no };
    connectorStatusRecursiveCall(
      // コネクタ状態取得APIを80秒間コールさせるため27回に設定
      // 27回 × 3秒 = 81秒
      27,
      params,
      (data) => {
        setIsDuringConnectorStartup(false);
        f7.preloader.hide();
        setStoreLinkDriveOwner({ params: linkDriveOwnerApiParams });
        if (!linkDriveOwner?.success) return;
        store.dispatch('setLinkDriveOwnerInfo', linkDriveOwner);
        if (data?.data?.event === mode.sleep) {
          setIsOpened(false);
          setIsUpdating(false);
          setShowDialog(true);
          return;
        }
        initialDisplayConditions();
        updateProcess();
      },
    );
    f7.preloader.hide();
  };

  const refetch = () => {
    setStoreLinkDriveOwner({ params: linkDriveOwnerApiParams });
  };

  return {
    currentUpdate,
    connectorDetailInfo,
    isOpened,
    showDialog,
    isUpdating,
    isFirmWareUpdating,
    isFirmWareUpdated,
    isFWLatestVersion,
    isECUUpdating,
    isECUUpdated,
    isECULatestVersion,
    isImpactJudgementUpdating,
    isImpactJudgementUpdated,
    isImpactJudgementLatestVersion,
    isAvaiableForUpdate,
    progress,
    isDuringConnectorStartup,
    isFailure,
    setIsOpened,
    setShowDialog,
    fetchConnectorDetail,
    handleClickUpdateSystemInfo,
    refetch,
  };
};
