import { createStore } from 'framework7/lite';
import { StoreObject } from 'framework7/modules/store/store';
import { Getter, initialState, State } from './initialState';
import { themeId as constThemeId } from '@/consts/shop';

interface Store extends StoreObject {
  state: State;
  getters: Getter;
}

export const store: Store = createStore({
  state: initialState,
  actions: {
    setLoginInfo: ({ state }: Store, { loginInfo }: State) => {
      state.loginInfo = { ...state.loginInfo, ...loginInfo };
    },
    setIsLoggedInToMaikuru: (
      { state }: Store,
      { isLoggedInToMaikuru }: State,
    ) => {
      state.isLoggedInToMaikuru = isLoggedInToMaikuru;
    },
    setAuthInfo: ({ state }: Store, { authInfo }: State) => {
      state.authInfo = { ...state.authInfo, ...authInfo };
    },
    setCustomerInfo: ({ state }: Store, { customerInfo }: State) => {
      state.customerInfo = { ...state.customerInfo, ...customerInfo };
    },
    setEmailInfo: ({ state }: Store, { emailLoginInfo }: State) => {
      state.emailLoginInfo = { ...state.emailLoginInfo, ...emailLoginInfo };
    },
    setAffiliatesInfo: ({ state }: Store, { affiliatesInfo }: State) => {
      state.affiliatesInfo = [...affiliatesInfo];
    },
    setAdTelemileageContent: (
      { state }: Store,
      { adTelemileageContent }: State,
    ) => {
      state.adTelemileageContent = adTelemileageContent;
    },
    setEmailDuplicated: ({ state }: Store, { emailDuplicated }: State) => {
      state.emailDuplicated = emailDuplicated;
    },
    setCarsInfo: ({ state }: Store, { carsInfo }: State) => {
      state.carsInfo = carsInfo;
    },
    setBillsInfo: ({ state }: Store, { billsInfo }: State) => {
      state.billsInfo = billsInfo;
    },
    setIsImpactInfoChecked: (
      { state }: Store,
      { isImpactInfoChecked }: State,
    ) => {
      state.isImpactInfoChecked = isImpactInfoChecked;
    },
    setPaymentMethodsInfo: (
      { state }: Store,
      { paymentMethodsInfo }: State,
    ) => {
      state.paymentMethodsInfo = paymentMethodsInfo;
    },
    setAdvancePaymentsInfo: (
      { state }: Store,
      { advancePaymentsInfo }: State,
    ) => {
      state.advancePaymentsInfo = advancePaymentsInfo;
    },
    setNotificationsInfo: ({ state }: Store, { notificationsInfo }: State) => {
      state.notificationsInfo = notificationsInfo;
    },
    setCampaignsInfo: ({ state }: Store, { campaignsInfo }: State) => {
      state.campaignsInfo = campaignsInfo;
    },
    setIsInfoLoaded: ({ state }: Store, { isInfoLoaded }: State) => {
      state.isInfoLoaded = isInfoLoaded;
    },
    setLinkDriveCheck: ({ state }: Store, { linkDriveCheck }: State) => {
      state.linkDriveCheck = linkDriveCheck;
    },
    setLinkDriveUpdating: ({ state }: Store, { linkDriveUpdating }: State) => {
      state.linkDriveUpdating = linkDriveUpdating;
    },
    setLinkDriveProgress: ({ state }: Store, { linkDriveProgress }: State) => {
      state.linkDriveProgress = linkDriveProgress;
    },
    setLinkDriveAbortController: (
      { state }: Store,
      { linkDriveAbortController }: State,
    ) => {
      state.linkDriveAbortController = linkDriveAbortController;
    },
    setLinkDriveSetupCurrent: (
      { state }: Store,
      { linkDriveSetupCurrent }: State,
    ) => {
      state.linkDriveSetupCurrent = linkDriveSetupCurrent;
    },
    setLinkDriveOwnerInfo: (
      { state }: Store,
      { linkDriveOwnerInfo }: State,
    ) => {
      state.linkDriveOwnerInfo = {
        ...state.linkDriveOwnerInfo,
        ...linkDriveOwnerInfo,
      };
    },
    setLinkDriveSetupStatusCompleted: (
      { state }: Store,
      { linkDriveSetupStatusCompleted }: State,
    ) => {
      state.linkDriveSetupStatusCompleted = linkDriveSetupStatusCompleted;
    },
    setOwnerId: ({ state }: Store, { ownerId }: State) => {
      state.ownerId = ownerId;
    },
    setCarItemInfo: ({ state }: Store, { carItemInfo }: State) => {
      state.carItemInfo = carItemInfo;
    },
    setFromPage: ({ state }: Store, { fromPage }: State) => {
      state.fromPage = fromPage;
    },
    setRecordDetailItem: ({ state }: Store, { recordDetailItem }: State) => {
      state.recordDetailItem = {
        ...state.recordDetailItem,
        ...recordDetailItem,
      };
    },
    setSerialNumberInput: ({ state }: Store, { serialNumberInput }: State) => {
      state.serialNumberInput = serialNumberInput;
    },
    setSerialNumberDb: ({ state }: Store, { serialNumberDb }: State) => {
      state.serialNumberDb = serialNumberDb;
    },
    setUpdateWarrantyInfo: (
      { state }: Store,
      { updateWarrantyInfo }: State,
    ) => {
      state.updateWarrantyInfo = updateWarrantyInfo;
    },
    setUpdateWarranties: ({ state }: Store, { updateWarranties }: State) => {
      state.updateWarranties = updateWarranties;
    },
    setUpdateLoan: ({ state }: Store, { updateLoan }: State) => {
      state.updateLoan = updateLoan;
    },
    setCalibrationInterruptionStep: (
      { state }: Store,
      { calibrationInterruptionStep }: State,
    ) => {
      state.calibrationInterruptionStep = calibrationInterruptionStep;
    },
    setCalibrationJobId: ({ state }: Store, { calibrationJobId }: State) => {
      state.calibrationJobId = calibrationJobId;
    },
    setActiveTabId: ({ state }: Store, { activeTabId }: State) => {
      state.activeTabId = activeTabId;
    },
    setReserveAmount: ({ state }: Store, { reserveAmount }: State) => {
      state.reserveAmount = reserveAmount;
    },
    setPaymentInfo: ({ state }: Store, { paymentInfo }: State) => {
      state.paymentInfo = { ...state.paymentInfo, ...paymentInfo };
    },
    setMPrepaidPlanId: ({ state }: Store, { mPrepaidPlanId }: State) => {
      state.mPrepaidPlanId = mPrepaidPlanId;
    },
    setScanResults: ({ state }: Store, { scanResults }: State) => {
      state.scanResults = [...scanResults];
    },
    setScanResultErrors: ({ state }: Store, { scanResultErrors }: State) => {
      state.scanResultErrors = [...scanResultErrors];
    },
    setNowSpecialContract: (
      { state }: Store,
      { nowSpecialContract }: State,
    ) => {
      state.nowSpecialContract = nowSpecialContract;
    },
    setMonthlyReportDate: ({ state }: Store, { monthlyReportDate }: State) => {
      state.monthlyReportDate = monthlyReportDate;
    },
    setPaymentTempData: ({ state }: Store, { paymentTempData }: State) => {
      state.paymentTempData = paymentTempData;
    },
    setThemeId: ({ state }: Store, { themeId }: State) => {
      state.themeId = themeId;
    },
    setGlobalThemeId: ({ state }: Store, { themeId, globalTheme }: State) => {
      state.themeId = themeId;
      state.globalTheme = globalTheme
        ? globalTheme
        : themeId !== constThemeId.DEFAULT
        ? themeId
        : null;
    },
    setCoupons: ({ state }: Store, { coupons }: State) => {
      state.coupons = coupons;
    },
    setUpdateWarranty: ({ state }: Store, { updateWarranties }: State) => {
      state.updateWarranties = updateWarranties;
    },
    setTerm: ({ state }: Store, { term }: State) => {
      state.term = term;
    },
    setPrepaidPlanList: ({ state }: Store, { prepaidPlanList }: State) => {
      state.prepaidPlanList = prepaidPlanList;
    },
    setPrepaidPlanName: ({ state }: Store, { prepaidPlanName }: State) => {
      state.prepaidPlanName = prepaidPlanName;
    },
    setSettingsPrevPage: ({ state }: Store, { settingsPrevPage }: State) => {
      state.settingsPrevPage = settingsPrevPage;
    },
    setConsultingHistoryList: (
      { state }: Store,
      { consultingHistoryList }: State,
    ) => {
      state.consultingHistoryList = consultingHistoryList;
    },
    setConsultingHistoryDetail: (
      { state }: Store,
      { consultingHistoryDetail }: State,
    ) => {
      state.consultingHistoryDetail = consultingHistoryDetail;
    },
    setChargePrice: ({ state }: Store, { chargePrice }: State) => {
      state.chargePrice = chargePrice;
    },
    setPermission: ({ state }: Store, { permission }: State) => {
      state.permission = {
        ...state.permission,
        ...permission,
      };
    },
    setPrepaidChargePayment: (
      { state }: Store,
      { prepaidChargePayment }: State,
    ) => {
      state.prepaidChargePayment = prepaidChargePayment;
    },
    setWarranties: ({ state }: Store, { warranties }: State) => {
      state.warranties = warranties || [];
    },
    setNextWarranties: ({ state }: Store, { nextWarranties }: State) => {
      state.nextWarranties = nextWarranties || [];
    },
    setCurrentWarranty: ({ state }: Store, { currentWarranty }: State) => {
      state.currentWarranty = currentWarranty;
    },
    setNextWarranty: ({ state }: Store, { nextWarranty }: State) => {
      state.nextWarranty = nextWarranty;
    },
    setPaymentListPrevPage: (
      { state }: Store,
      { paymentListPrevPage }: State,
    ) => {
      state.paymentListPrevPage = paymentListPrevPage;
    },
    setGMOCardParams: ({ state }: Store, { GMOCardParams }: State) => {
      state.GMOCardParams = { ...state.GMOCardParams, ...GMOCardParams };
    },
    setDiagnosticDate: ({ state }: Store, { diagnosticDate }: State) => {
      state.diagnosticDate = diagnosticDate;
    },
    setSelectedDay: ({ state }: Store, { selectedDay }: State) => {
      state.selectedDay = selectedDay;
    },
    setDeeplink: ({ state }: Store, { deeplink }: State) => {
      state.deeplink = deeplink;
    },
    setReservationHistory: ({ state }: Store, { reservation }: State) => {
      state.reservation = reservation;
    },
    setContractSignature: ({ state }: Store, { contractSignature }: State) => {
      state.contractSignature = contractSignature;
    },
    setMenu: ({ state }: Store, { menu }: State) => {
      state.menu = menu;
    },
  },
  getters: {
    loginInfo({ state }: Store) {
      return state.loginInfo;
    },
    authInfo({ state }: Store) {
      return state.authInfo;
    },
    linkDriveOwnerInfo({ state }: Store) {
      return state.linkDriveOwnerInfo;
    },
    ownerIdApi({ state }: Store) {
      return state.ownerId;
    },
    adTelemileageContent({ state }: Store) {
      return state.adTelemileageContent;
    },
    customerInfo({ state }: Store) {
      return state.customerInfo;
    },
    carItemInfo({ state }: Store) {
      return state.carItemInfo;
    },
    carsInfo({ state }: Store) {
      return state.carsInfo;
    },
    affiliatesInfo({ state }: Store) {
      return state.affiliatesInfo;
    },
    globalTheme({ state }: Store) {
      return state.globalTheme;
    },
    fromPage({ state }: Store) {
      return state.fromPage;
    },
    ownerId({ state }: Store) {
      return state.ownerId;
    },
    recordDetailItem({ state }: Store) {
      return state.recordDetailItem;
    },
    serialNumberDb({ state }: Store) {
      return state.serialNumberDb;
    },
    serialNumberInput({ state }: Store) {
      return state.serialNumberInput;
    },
    linkDriveCheck({ state }: Store) {
      return state.linkDriveCheck;
    },
    linkDriveUpdating({ state }: Store) {
      return state.linkDriveUpdating;
    },
    linkDriveProgress({ state }: Store) {
      return state.linkDriveProgress;
    },
    linkDriveSetupCurrent({ state }: Store) {
      return state.linkDriveSetupCurrent;
    },
    filteredCoupon({ state }: Store, id: string) {
      return state.coupons.filter((coupon) => coupon.t_campaigns_id === id);
    },
    updateWarrantyInfo({ state }: Store) {
      return state.updateWarrantyInfo;
    },
    updateWarranties({ state }: Store) {
      return state.updateWarranties;
    },
    updateLoan({ state }: Store) {
      return state.updateWarrantyLoan;
    },
    getPaymentMethodsInfo({ state }: Store) {
      return state.paymentMethodsInfo;
    },
    // This is working reactive
    getFirstAdvancePaymentsInfo({ state }: Store) {
      return state.advancePaymentsInfo?.[0];
    },
    getAdvancePaymentsInfo({ state }: Store) {
      return state.advancePaymentsInfo;
    },
    getBillInfo({ state }: Store) {
      return state.billsInfo;
    },
    getIsImpactInfoChecked({ state }: Store) {
      return state.isImpactInfoChecked;
    },
    getPaymentListPreviousPage({ state }: Store) {
      return state.paymentListPrevPage;
    },
    getPaymentInfo({ state }: Store) {
      return state.paymentInfo;
    },
    getPrepaidPlanId({ state }: Store) {
      return state.mPrepaidPlanId;
    },
    getReserveAmount({ state }: Store) {
      return state.reserveAmount;
    },
    getPrepaidPlanList({ state }: Store) {
      return state.prepaidPlanList;
    },
    getPrepaidChargePayment({ state }: Store) {
      return state.prepaidChargePayment;
    },
    getThemeId({ state }: Store) {
      return state.themeId;
    },
    getIsInfoLoaded({ state }: Store) {
      return state.isInfoLoaded;
    },
    getSettingsPreviousPage({ state }: Store) {
      return state.settingsPrevPage;
    },
    getDiagnosticDate({ state }: Store) {
      return state.diagnosticDate;
    },
    getActiveTabId({ state }: Store) {
      return state.activeTabId;
    },
    getCampaignsInfo({ state }: Store) {
      return state.campaignsInfo;
    },
    getPermission({ state }: Store) {
      return state.permission;
    },
    getCurrentWarranty({ state }: Store) {
      return state.currentWarranty;
    },
    getNextWarranty({ state }: Store) {
      return state.nextWarranty;
    },
    getWarranties({ state }: Store) {
      return state.warranties;
    },
    getNextWarranties({ state }: Store) {
      return state.nextWarranties;
    },
    getSelectedDay({ state }: Store) {
      return state.selectedDay;
    },
    getDeeplink({ state }: Store) {
      return state.deeplink;
    },
    getReservation({ state }: Store) {
      return state.reservation;
    },
    getContractSignature({ state }: Store) {
      return state.contractSignature;
    },
    getMenu({ state }: Store) {
      return state.menu;
    },
  },
});
