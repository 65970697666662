import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchDriveHistoryDetailApi } from '@/api/driveRecordApi';
import {
  HistoryDetailParams,
  HistoryDetailResponse,
} from '@/types/api/driveRecordApi';

export const useHistoryDetail = ({
  owner_id,
  t_driving_trip_log_id,
}: HistoryDetailParams) => {
  const [data, setData] = useState<HistoryDetailResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(true);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchDriveHistoryDetailApi({
          owner_id,
          t_driving_trip_log_id,
        });
        if (res.data.success) {
          setIsNoData(false);
        }
        setData(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [owner_id, t_driving_trip_log_id]);

  return {
    data,
    isLoading,
    isNoData,
    error,
  };
};
