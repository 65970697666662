import { Popup, f7 } from 'framework7-react';
import { PopupProps } from 'framework7-react/components/popup';
import { FC } from 'react';
import style from './PopupCancelStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';
import { f7CustomBack, findLastIndex } from '@/utils/utils';

interface PopupCancelProps extends PopupProps {
  status?: string;
}

export const PopupCancel: FC<PopupCancelProps> = ({
  onPopupClosed,
  status,
  ...props
}) => {
  const bacToHomePage = () => {
    f7.views.main.router.navigate(paths.home, {
      animate: false,
      reloadAll: true,
    });
  };

  const onCancel = () => {
    onPopupClosed?.();

    if (status) {
      return bacToHomePage();
    }
    const carwashIndex = findLastIndex(
      f7.views.main.router.history,
      (value) => value === paths.carWash,
    );
    if (
      carwashIndex !== -1 &&
      carwashIndex === f7.views.main.router.history.length - 2
    ) {
      return f7CustomBack();
    }

    bacToHomePage();
  };

  return (
    <Popup {...props}>
      <div className={style['container']}>
        <h4>
          <i className="icon-exclamationmark_circle u-padding-right-xs" />
          予約を中止します
        </h4>
        <p>
          入力した内容が破棄されます。
          <br />
          本当に中止しますか？
        </p>
        <Button
          color="gray"
          className={style['cancel-button']}
          onClick={onPopupClosed}
        >
          閉じる
        </Button>
        <Button
          color="red"
          className={style['submit-button']}
          onClick={onCancel}
        >
          予約を中止する
        </Button>
      </div>
    </Popup>
  );
};
