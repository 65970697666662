import { FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import style from './CarWashConditionerStep2Style.module.css';
import { PhoneInput } from './PhoneInput';
import { PopupAbout } from './PopupAbout';
import { ZipCodeInput } from './ZipCodeInput';
import { Button } from '@/components/uiParts/Button';
import { DateTimeSelect } from '@/components/uiParts/DateTimeSelect/DateTimeSelect';
import { Picker } from '@/components/uiParts/Picker/Picker';
import { parkingTypeOption, workspaceTypeOption } from '@/consts/carWash';
import {
  Step2FormData,
  useCarWashConditionerStep2,
} from '@/hooks/api/carWash/useCarWashConditionerStep2';

interface Props {
  step2Data: Step2FormData;
  onSubmit: (data: Step2FormData) => void;
  onClickCancel: () => void;
}

export const CarWashConditionerStep2: FC<Props> = ({
  step2Data,
  onSubmit,
  onClickCancel,
}) => {
  const { formMethods } = useCarWashConditionerStep2(step2Data);
  const {
    watch,
    setValue,
    trigger,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = formMethods;
  const [opened, setOpened] = useState(false);
  const datetime1 = watch('preferredDatetimes.datetime1');
  const datetime2 = watch('preferredDatetimes.datetime2');
  useEffect(() => {
    trigger('reservationHolder');
  }, []);
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PopupAbout opened={opened} onPopupClosed={() => setOpened(false)} />
        <div className={style['container']}>
          <h2 className={style['input-label']}>
            ご予約のご希望のお日にち・お時間を選択してください
          </h2>
          <DateTimeSelect
            label="第一希望"
            name="preferredDatetimes.datetime1"
            showError={!!errors.preferredDatetimes?.datetime1 && !!datetime1}
            required
          />
          <DateTimeSelect
            label="第二希望"
            name="preferredDatetimes.datetime2"
            showError={
              errors.preferredDatetimes?.datetime2 &&
              (!errors.preferredDatetimes?.datetime1 || !datetime1)
            }
            required
          />
          <DateTimeSelect
            label="第三希望"
            name="preferredDatetimes.datetime3"
            required
          />
          <div className={style['info-box']}>
            <h4 className={style['info-box-title']}>所要時間の目安</h4>
            <ul className={style['info-box-list']}>
              <li>1〜２時間ほど</li>
            </ul>
          </div>
          <div className={`${style['input-group']} ${style['has-border-top']}`}>
            <h2 className={style['input-label']}>
              当日対応いただけるお客さまの情報を入力してください。
            </h2>
            <div className={`${style['input-group']}`}>
              <div
                className={`${style['input-text']} ${
                  !!errors.reservationHolder?.familyName ||
                  !!errors.reservationHolder?.firstName
                    ? style['input-text-error']
                    : ''
                }`}
              >
                <label>
                  お名前
                  <span
                    className={`${style['input-label-tips']} ${style['required']}`}
                  >
                    必須
                  </span>
                </label>
                <div className={style['input-group-1']}>
                  <input
                    placeholder="姓"
                    {...register('reservationHolder.familyName')}
                  />
                  <input
                    placeholder="名"
                    {...register('reservationHolder.firstName')}
                  />
                </div>
              </div>
            </div>
            <div className={`${style['input-group']}`}>
              <div
                className={`${style['input-text']} ${
                  !!errors.reservationHolder?.familyNameKana ||
                  !!errors.reservationHolder?.firstNameKana
                    ? style['input-text-error']
                    : ''
                }`}
              >
                <label>
                  フリガナ
                  <span
                    className={`${style['input-label-tips']} ${style['required']}`}
                  >
                    必須
                  </span>
                </label>
                <div className={style['input-group-1']}>
                  <input
                    placeholder="セイ"
                    {...register('reservationHolder.familyNameKana')}
                  />
                  <input
                    placeholder="メイ"
                    {...register('reservationHolder.firstNameKana')}
                  />
                </div>
              </div>
            </div>
            <PhoneInput />
          </div>
          <div className={`${style['input-group']} ${style['has-border-top']}`}>
            <h2 className={style['input-label']}>
              作業場所のご住所と環境について入力してください。
            </h2>
            <ZipCodeInput />
            <div className={style['input-text']}>
              <label>
                目印・駐車位置など
                <span className={style['input-label-tips']}>任意</span>
              </label>
              <textarea
                className={style['input-textarea']}
                rows={5}
                placeholder="マンション前の赤いコーンが立っている駐車場でお願いします。"
                {...register('workspace.workspaceMemo')}
              />
            </div>
            <Picker
              label="洗車場所について"
              id="about"
              options={workspaceTypeOption}
              initValue={watch('workspace.workspaceType')}
              required
              onChange={(data) => {
                setValue('workspace.workspaceType', data.value);
                trigger('workspace.workspaceType');
              }}
            />
            <Picker
              label="駐車場タイプ"
              id="parking"
              options={parkingTypeOption}
              initValue={watch('workspace.parkingType')}
              required
              onChange={(data) => {
                setValue('workspace.parkingType', data.value);
                trigger('workspace.parkingType');
              }}
            >
              <p className={style['info-link']} onClick={() => setOpened(true)}>
                <i className="icon-help" />
                作業場所の管理会社への許可取りについて
              </p>
            </Picker>
          </div>
          <div className={style['button-group']}>
            <Button text="次へ" type="submit" disabled={!isValid} />
            <Button
              style={{
                color: '#232323',
                backgroundColor: '#fff',
                marginTop: '8px',
              }}
              text="キャンセル"
              onClick={onClickCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
