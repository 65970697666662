import { AxiosError } from 'axios';
import { useState } from 'react';
import { fetchConnectorDetailApi } from '@/api/linkDriveApi';
import {
  ConnectorDetailApiParams,
  ConnectorDetailApiResponse,
} from '@/types/api/linkDriveApi';

interface FetchConnectorDetailResponse {
  isFirmWareUpToDate: boolean;
  isECUUpToDate: boolean;
  isImpactJudgementUpToDate: boolean;
}

/**
 * LinkDriveコネクタの詳細情報を取得し、その最新状態を管理する。
 * ファームウェア、ECU、および衝撃判定データが最新であるかどうかの状態を提供し、
 * 必要に応じてこれらの状態を更新する機能を提供する。
 *
 * @returns {object} コネクタの詳細データ、最新状態確認関数、および通信状態やエラー情報
 */
export const useConnectorDetail = () => {
  const [isFirmWareUpToDate, setIsFirmWareUpToDate] = useState(false);
  const [isECUUpToDate, setIsECUUpToDate] = useState(false);
  const [isImpactJudgementUpToDate, setIsImpactJudgementUpToDate] =
    useState(false);
  const [data, setData] = useState<ConnectorDetailApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  const createConnectorVersionInfo = (
    data: ConnectorDetailApiResponse['data'],
  ) => {
    const {
      firmware,
      ecu_latest_version_at,
      ecu_now_version_at,
      impact_max_version,
      impact_version,
    } = data.connector[0];
    const firmwareOutOfDate = firmware?.find(
      ({ latest_version, version }) => latest_version !== version,
    );

    setIsFirmWareUpToDate(!firmwareOutOfDate);
    setIsECUUpToDate(ecu_latest_version_at === ecu_now_version_at);
    setIsImpactJudgementUpToDate(impact_max_version === impact_version);
  };

  const fetchConnectorDetail = async (
    params: ConnectorDetailApiParams,
  ): Promise<FetchConnectorDetailResponse> => {
    try {
      setIsLoading(true);
      const { data } = await fetchConnectorDetailApi(params);
      setData(data);
      createConnectorVersionInfo(data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      return {
        isFirmWareUpToDate,
        isECUUpToDate,
        isImpactJudgementUpToDate,
      };
    }
  };

  return {
    data, // コネクタの詳細情報
    fetchConnectorDetail,
    isFirmWareUpToDate,
    isECUUpToDate,
    isImpactJudgementUpToDate,
    isLoading,
    error,
  };
};
