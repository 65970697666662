import { secondsInHour } from 'date-fns';
import React, { FC } from 'react';
import style from './DrivePropertyDisplay.module.css';
import { getHMS } from '@/utils/utils';

interface DrivePropertyDisplayProps {
  drivingTime: number;
  hour: string;
  minute: string;
  travelDistance: string;
  fuelConsumption: string;
  phydScoreLevel: string;
  isAdContractor: boolean;
}

export const DrivePropertyDisplay: FC<DrivePropertyDisplayProps> = ({
  drivingTime,
  hour,
  minute,
  travelDistance,
  fuelConsumption,
  phydScoreLevel,
  isAdContractor,
}) => {
  return (
    <div className={style['flex']}>
      <div className={`${style['travel-time']}`}>
        <span className={style['title']}>走行時間</span>
        <div>
          {drivingTime > secondsInHour && (
            <>
              <span className={style['property']}>
                {getHMS(drivingTime, 'hour')}
              </span>
              <span className={style['unit']}>時間</span>
            </>
          )}
          <span className={style['property']}>
            {getHMS(drivingTime, 'minute')}
          </span>
          <span className={style['unit']}>分</span>
        </div>
      </div>
      <div className={`${style['travel-distance']}`}>
        <span className={style['title']}>走行距離</span>
        <div>
          <span className={style['property']}>{travelDistance}</span>
          <span className={style['unit']}>km</span>
        </div>
      </div>
      <div className={`${style['fuel-consumption']}`}>
        <span className={style['title']}>燃費</span>
        <div>
          <span className={style['property']}>{fuelConsumption}</span>
          <span className={style['unit']}>km/L</span>
        </div>
      </div>
      {isAdContractor && (
        <div className={`${style['driving-score']}`}>
          <span className={style['title']}>
            運転スコア
            <br />
            (区分)
          </span>
          <div>
            <span className={style['property']}>{phydScoreLevel}</span>
          </div>
        </div>
      )}
    </div>
  );
};
