export const termType = {
  WARRANTY: '1',
  APP: '11',
  PRIVACY_POLICY: '10',
  SCORE: '12',
  LINK_DRIVE: '7',
  ROAD_SERVICE: '9',
  PREPAID: '5',
  TRADE_GUARANTEE: '13',
} as const;
