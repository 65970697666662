import ReactEcharts from 'echarts-for-react';
import React, { FC } from 'react';

interface AlertGraphProps {
  xAxisData: any;
  seriesDataList: any;
  showSplitLineX?: boolean;
  showSplitLineY?: boolean;
  isAlignWithLabelX?: boolean;
  showLabel?: boolean;
  labelColor?: string;
  labelPosition?: string;
  labelSize?: string;
  gridLeft?: string;
  axisLabelInterval?: number;
  axisLabelIntervalY?: number;
  minNumber?: number;
  maxNumber?: number;
  piecesColor?: string;
  unitXLabel?: string;
  unitYLabel?: string;
}

export const AlertGraph: FC<AlertGraphProps> = ({
  xAxisData,
  seriesDataList,
  showSplitLineX = false,
  showSplitLineY = true,
  isAlignWithLabelX = false,
  showLabel = false,
  labelColor = '#FF4655',
  labelPosition = 'top',
  labelSize = '12',
  axisLabelInterval = 1,
  axisLabelIntervalY = 1,
  piecesColor = '#FF4655',
  unitXLabel = '',
  unitYLabel = '',
}) => {
  const options = {
    visualMap: {
      show: false,
      pieces: [
        {
          gt: 0,
          lte: 100,
          color: piecesColor,
        },
      ],
    },
    grid: {
      left: '0',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisLabel: {
        interval: (idx: number) => {
          // 01はラベル表示
          if (idx === 0) return true;
          // 5の倍数の日にちはラベル表示
          if (idx % 4 === 0) return true;
          return false;
        },
        formatter: (value: string) => {
          return value + unitXLabel;
        },
        fontSize: '12',
        fontWeight: 'bold',
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: showSplitLineY,
      },
      min: function (value: any) {
        if (value.min > 10) return value.min - 10;
        return 0;
      },
      // max値が5の倍数の場合は最大値をmax値+5
      // それ以外はその数より大きい5の倍数に設定
      // 15 → 15
      // 12 → 15
      max: function (value: any) {
        return value.max;
      },
      interval: axisLabelIntervalY,
      axisLabel: {
        fontSize: '12',
        fontWeight: 'bold',
        formatter: (value: number) => {
          console.log(value + unitYLabel);
          return value + unitYLabel;
        },
      },
    },
    series: [
      {
        type: 'bar',
        data: seriesDataList,
        barWidth: 8,
        itemStyle: {
          color: '#2275EA',
        },
      },
    ],
  };

  return (
    <ReactEcharts option={options} style={{ width: '100%', height: '100%' }} />
  );
};
