import * as yup from 'yup';

const uniqueDateCheck: yup.TestFunction<string | undefined, {}> = (
  value,
  context,
) => {
  const { datetime1, datetime2, datetime3 } = context.parent;
  switch (context.path) {
    case 'datetime1': {
      if (value === datetime2 || value === datetime3) {
        return !value;
      }
      return true;
    }
    case 'datetime2': {
      if (value === datetime1 || value === datetime3) {
        return !value;
      }
      return true;
    }
    case 'datetime3': {
      if (value === datetime1 || value === datetime2) {
        return !value;
      }
      return true;
    }
  }
  return false;
};

export const updateCarwash = yup.object().shape({
  datetime1: yup.string().required().test(uniqueDateCheck),
  datetime2: yup.string().test(uniqueDateCheck),
  datetime3: yup.string().test(uniqueDateCheck),
});
