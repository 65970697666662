export const categoryTypes = {
  OPTION_COST: 1,
  TAXABLE_EXPENSES: 2,
  NON_TAXABLE_EXPENSES: 3,
};

export const paymentMethods = {
  CASH: 1,
  LOAN: 2,
  LEASE: 3,
};

export const loanTypes = {
  NORMAL: 1, // 通常ローン
  DEFERRED: 2, // 据置ローン
  FREE_REPAYMENT: 3, // 自由返済型
  FREE_REPAYMENT_DEFERRED: 4, // 自由返済据置型
};
