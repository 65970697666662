import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchReservationDetail } from '@/api/reservationApi';
import {
  ReservationDetailParams,
  ReservationDetailResponse,
} from '@/types/api/reservationApi';

export const useFetchReservationDetail = ({
  reservation_id,
  service_type,
}: ReservationDetailParams) => {
  const [data, setData] = useState<ReservationDetailResponse['data']['data']>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        const res = await fetchReservationDetail({
          reservation_id,
          service_type,
        });
        setData(res.data.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };
    fn();
  }, []);

  return {
    data,
    error,
  };
};
