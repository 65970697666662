import { f7 } from 'framework7-react';
import { FC, useEffect, useMemo } from 'react';
import { Confirmation } from './Confirmation/Confirmation';
import { TradeGuaranteeForm } from './TradeGuaranteeForm/TradeGuaranteeForm';
import { Upload } from './Upload/Upload';
import { UploadDocuments } from './UploadDocuments/UploadDocuments';
import {
  TRADE_GUARANTEE_STEPS,
  useTradeGuarantee,
} from './hooks/useTradeGuarantee';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { GetDealWarrantyInfoApiResponse } from '@/types/api/warrantyApi';

interface TradeGuaranteeUpdateProps {
  data: GetDealWarrantyInfoApiResponse['data'];
}

export const TradeGuaranteeUpdatePage: FC<TradeGuaranteeUpdateProps> = ({
  data,
}) => {
  const {
    step,
    detailData,
    warrantyDocument,
    uploadedDocument,
    selectedDocument,
    setStep,
    handleUpload,
    handleClickDocument,
    handleBack,
    handleSubmitStep1,
    handleSubmitStep2,
    handleSubmitStep3,
  } = useTradeGuarantee(data);

  const title = useMemo(() => {
    if (step === TRADE_GUARANTEE_STEPS.DETAIL_FORM) return '取引保証の再申請';
    if (step === TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS)
      return '各種書類のアップロード';
    if (step === TRADE_GUARANTEE_STEPS.UPLOAD)
      return selectedDocument?.name ?? '';
    if (step === TRADE_GUARANTEE_STEPS.CONFIRMATION) return '申請内容の確認';
    return '';
  }, [step, selectedDocument]);

  useEffect(() => {
    if (!data) {
      f7.views.main.router.back();
    }
  }, [data]);

  return (
    <PageTemplateV2
      pageName="TradeGuarantee"
      title={title}
      showNavBack={true}
      handleClickBack={handleBack}
      containerBackgroundColor={
        [
          TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS,
          TRADE_GUARANTEE_STEPS.UPLOAD,
        ].includes(step)
          ? '#efefef'
          : '#FFFFFF'
      }
    >
      {step === TRADE_GUARANTEE_STEPS.DETAIL_FORM && (
        <TradeGuaranteeForm
          data={data}
          handleBack={handleBack}
          onSubmit={handleSubmitStep1}
          defaultValues={detailData}
        />
      )}
      {step === TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS && (
        <UploadDocuments
          data={data}
          warrantyDocument={warrantyDocument}
          uploadDocument={uploadedDocument}
          onSubmit={handleSubmitStep2}
          handleClickDocument={handleClickDocument}
        />
      )}
      {step === TRADE_GUARANTEE_STEPS.UPLOAD && (
        <Upload
          handleUpload={handleUpload}
          setStep={setStep}
          uploadedDocument={uploadedDocument}
          selectedDocument={selectedDocument}
        />
      )}
      {step === TRADE_GUARANTEE_STEPS.CONFIRMATION && (
        <Confirmation
          detailData={detailData}
          warrantyDocument={warrantyDocument}
          uploadedDocument={uploadedDocument}
          setStep={setStep}
          onSubmit={handleSubmitStep3}
        />
      )}
    </PageTemplateV2>
  );
};
