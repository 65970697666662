import { IconProps } from './type';

export const ToolIcon = ({
  width = '56',
  height = '57',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28.5" r="28" fill={color} fillOpacity="0.1" />
      <path
        d="M29.8898 28.4945L35.564 22.8203L38.4219 25.6778L32.7477 31.352C32.4839 31.6024 32.1314 31.7379 31.7678 31.7287C31.4042 31.7195 31.0591 31.5664 30.8083 31.3029L29.9389 30.4335C29.6755 30.1827 29.5225 29.8377 29.5133 29.4741C29.5041 29.1106 29.6395 28.7583 29.8898 28.4945Z"
        fill="#CFDCFC"
        stroke="#323232"
        strokeWidth="1.07693"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.3744 16.5676L38.397 21.5246L39.7325 22.8598L44.7094 17.9028L46.2478 17.555L47.2266 15.3856L45.8912 14.0508L43.7001 15.0076L43.3744 16.5676Z"
        fill="white"
        stroke="#323232"
        strokeWidth="1.07693"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.7671 25.4141L31.6016 29.5805"
        stroke="#323232"
        strokeWidth="1.07693"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4614 33.0781H28.2307V38.4628H24.4614V33.0781Z"
        fill="white"
      />
      <path
        d="M28.3346 38.2576C28.6142 37.9722 28.7694 37.5875 28.7694 37.1885V35.7689H41.154V44.0417C41.154 44.4217 41.006 44.784 40.746 45.0494C40.4864 45.3145 40.1366 45.4613 39.7742 45.4613H12.9183C12.5559 45.4613 12.2061 45.3145 11.9465 45.0494L11.5619 45.4263L11.9465 45.0494C11.6865 44.784 11.5385 44.4217 11.5385 44.0417V35.7689H24.041V37.1885C24.041 37.5875 24.1961 37.9722 24.4757 38.2576C24.7557 38.5434 25.1378 38.706 25.5386 38.706H27.2717C27.6725 38.706 28.0546 38.5434 28.3346 38.2576Z"
        fill="white"
        stroke="#323232"
        strokeWidth="1.07693"
      />
      <path
        d="M41.154 32.574V35.7687H28.7694V34.4018C28.7694 34.0052 28.6107 33.6255 28.3294 33.3462C28.0483 33.0669 27.6678 32.9107 27.2717 32.9107H25.5386C25.1425 32.9107 24.762 33.0669 24.4809 33.3462C24.1996 33.6255 24.041 34.0052 24.041 34.4018V35.7687H11.5385L11.5385 32.5822L11.5385 32.5791C11.5382 32.5251 11.5486 32.4715 11.5692 32.4213C11.5898 32.3713 11.6202 32.3255 11.6588 32.2867C11.6589 32.2866 11.659 32.2865 11.6592 32.2863L15.5702 28.3922C15.6091 28.354 15.6555 28.3236 15.7067 28.3028C15.7582 28.2819 15.8135 28.2713 15.8694 28.2716V28.2716H15.8725H21.6199H22.1583V27.7331V26.4139V26.3953L22.1571 26.3768C22.1452 26.205 22.1681 26.0326 22.2246 25.8697C22.2715 25.7343 22.3408 25.6077 22.4296 25.4949C22.3218 25.6626 22.2593 25.8622 22.2593 26.0763V27.7331V28.2716H22.7978H29.8463H30.3847V27.7331V26.1064C30.4034 26.2024 30.4117 26.3008 30.4092 26.3999L30.409 26.3999V26.4139V27.7331V28.2716H30.9474H36.8187C36.9296 28.2723 37.0352 28.3161 37.113 28.3925C37.1132 28.3927 37.1134 28.3928 37.1136 28.393L41.0333 32.2863C41.0336 32.2866 41.0338 32.2868 41.034 32.287C41.1107 32.3641 41.1533 32.4671 41.154 32.574Z"
        fill="white"
        stroke="#323232"
        strokeWidth="1.07693"
      />
      <path
        d="M24.7308 40.0751H27.9616C29.1511 40.0751 30.1155 39.1108 30.1155 37.9213V37.3828H39.5386V42.7675C39.5386 43.3622 39.0564 43.8444 38.4617 43.8444H14.2307C13.636 43.8444 13.1538 43.3622 13.1538 42.7675V37.3828H22.5769V37.9213C22.5769 39.1108 23.5413 40.0751 24.7308 40.0751Z"
        fill="#B1C7FD"
      />
      <path
        d="M36.0386 29.5781L39.5384 33.3681L39.5384 34.1551H30.1152V33.6166C30.1152 32.4271 29.1509 31.4628 27.9614 31.4628H24.7306C23.541 31.4628 22.5767 32.4271 22.5767 33.6166V34.1551H13.1538V33.0989L16.9231 29.5781H20.9616H31.6256H36.0386Z"
        fill="#B1C7FD"
      />
      <mask id="path-10-inside-1_29664_120314" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.2892 16.1514L30.5755 13.4366C32.4256 12.4654 34.7658 12.7574 36.3212 14.3128C37.7574 15.749 38.1164 17.8545 37.3983 19.6254L45.7626 27.9897C46.5072 28.7344 46.5072 29.9417 45.7626 30.6864C45.0179 31.4311 43.8106 31.4311 43.0659 30.6864L34.7026 22.3231C32.931 23.0431 30.8237 22.6846 29.3865 21.2474C27.8278 19.6887 27.5379 17.3417 28.5167 15.4897L31.2328 18.207L33.2667 18.1853L33.2892 16.1514Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2892 16.1514L30.5755 13.4366C32.4256 12.4654 34.7658 12.7574 36.3212 14.3128C37.7574 15.749 38.1164 17.8545 37.3983 19.6254L45.7626 27.9897C46.5072 28.7344 46.5072 29.9417 45.7626 30.6864C45.0179 31.4311 43.8106 31.4311 43.0659 30.6864L34.7026 22.3231C32.931 23.0431 30.8237 22.6846 29.3865 21.2474C27.8278 19.6887 27.5379 17.3417 28.5167 15.4897L31.2328 18.207L33.2667 18.1853L33.2892 16.1514Z"
        fill="white"
      />
      <path
        d="M30.5755 13.4366L30.0749 12.483L28.7794 13.1631L29.8138 14.1979L30.5755 13.4366ZM33.2892 16.1514L34.3661 16.1633L34.3711 15.7104L34.0509 15.39L33.2892 16.1514ZM37.3983 19.6254L36.4003 19.2207L36.132 19.8822L36.6368 20.3869L37.3983 19.6254ZM45.7626 27.9897L46.5241 27.2282L45.7626 27.9897ZM34.7026 22.3231L35.4641 21.5616L34.959 21.0564L34.2971 21.3254L34.7026 22.3231ZM28.5167 15.4897L29.2783 14.7283L28.2465 13.6961L27.5645 14.9865L28.5167 15.4897ZM31.2328 18.207L30.4712 18.9683L30.7914 19.2887L31.2443 19.2838L31.2328 18.207ZM33.2667 18.1853L33.2782 19.2621L34.3319 19.2509L34.3436 18.1972L33.2667 18.1853ZM29.8138 14.1979L32.5276 16.9127L34.0509 15.39L31.3371 12.6752L29.8138 14.1979ZM37.0827 13.5513C35.1846 11.6532 32.3305 11.299 30.0749 12.483L31.076 14.3901C32.5207 13.6317 34.3471 13.8617 35.5597 15.0743L37.0827 13.5513ZM38.3962 20.0301C39.2713 17.8725 38.8355 15.3041 37.0827 13.5513L35.5597 15.0743C36.6793 16.1939 36.9616 17.8366 36.4003 19.2207L38.3962 20.0301ZM36.6368 20.3869L45.0011 28.7512L46.5241 27.2282L38.1598 18.8639L36.6368 20.3869ZM45.0011 28.7512C45.3252 29.0753 45.3252 29.6008 45.0011 29.9249L46.5241 31.4479C47.6893 30.2827 47.6893 28.3934 46.5241 27.2282L45.0011 28.7512ZM45.0011 29.9249C44.677 30.249 44.1515 30.249 43.8274 29.9249L42.3044 31.4479C43.4696 32.6131 45.3588 32.6131 46.5241 31.4479L45.0011 29.9249ZM43.8274 29.9249L35.4641 21.5616L33.9411 23.0846L42.3044 31.4479L43.8274 29.9249ZM28.625 22.0089C30.379 23.7629 32.9496 24.1981 35.1081 23.3208L34.2971 21.3254C32.9125 21.8882 31.2684 21.6062 30.1481 20.4859L28.625 22.0089ZM27.5645 14.9865C26.3712 17.2444 26.7229 20.1067 28.625 22.0089L30.1481 20.4859C28.9328 19.2707 28.7045 17.439 29.4688 15.9929L27.5645 14.9865ZM31.9945 17.4456L29.2783 14.7283L27.755 16.251L30.4712 18.9683L31.9945 17.4456ZM33.2552 17.1084L31.2213 17.1301L31.2443 19.2838L33.2782 19.2621L33.2552 17.1084ZM32.2123 16.1395L32.1898 18.1734L34.3436 18.1972L34.3661 16.1633L32.2123 16.1395Z"
        fill="#323232"
        mask="url(#path-10-inside-1_29664_120314)"
      />
    </svg>
  );
};
