import { Col, f7, Row } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { Button } from '@/components/uiParts/Button';
import { PaymentInput } from '@/components/uiParts/PaymentInput/PaymentInput';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import './UsePrepaidStyle.scss';
import { f7CustomBack } from '@/utils/utils';

interface UsePrepaidProps {
  f7router: Router.Router;
  handleChangeBalanceTotal: (chargeing: number) => void;
}

export const UsePrepaid: FC<UsePrepaidProps> = ({
  f7router,
  handleChangeBalanceTotal,
}) => {
  const customerInfo = store.state.customerInfo;
  const prepaidInfo = store.state.advancePaymentsInfo[0];

  const handleClickCharge = () => {
    f7CustomBack(['#UsePrepaid']);
    window.localStorage.setItem('source_page', paths.chargePrepaid);
    f7.view.main.router.navigate(paths.chargePrepaid, {
      props: {
        f7router: f7router,
        redirectPage: 'prepaid',
        previousPage: 'charge_prepaid',
        handleChangeBalanceTotal: handleChangeBalanceTotal,
      },
    });
  };

  const charging = prepaidInfo?.balance_total ?? 0;
  const [balanceTotal, setBalanceTotal] = useState(0);

  useEffect(() => {
    setBalanceTotal(charging);
  }, [charging]);

  const handleClickMoneySelect = () => {
    if (spendYen == 0 || spendYen == null) {
      return;
    }
    if (spendYen > prepaidInfo.balance_total) {
      f7.dialog.alert('', '入力した金額が現在の残高を上回っています');
      return;
    }
    f7CustomBack(['#UsePrepaid']);
    f7.view.main.router.navigate(paths.usePrepaidConfirm, {
      props: {
        f7router: f7router,
        value: spendYen,
        unit: customerInfo.unit_name,
        handleChangeBalanceTotal: handleChangeBalanceTotal,
      },
    });
  };

  const [spendYen, setSpendYen] = useState(0);

  const isActive = () => {
    if (!spendYen) return false;
    return spendYen > 0;
  };

  return (
    <PopupPageTemplate
      id="UsePrepaid"
      pageName="UsePrepaid"
      title="お支払い金額の入力"
    >
      <div style={{ margin: '40px auto', width: '95%' }}>
        <div className="list no-hairlines u-margin-top-md">
          <ul>
            <PaymentInput setInputYen={setSpendYen} />
          </ul>
        </div>

        <div
          className="u-margin-top-md"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div>
            <div className="payment_destination_label">現在の残高</div>
            <div className="payment_destination">
              <span>{balanceTotal.toLocaleString()}</span>円
            </div>
          </div>
          <Button
            style={{ background: '#efefef', color: '#232323' }}
            handleClickAction={handleClickCharge}
          >
            チャージする
          </Button>
        </div>

        <Row className="u-margin-top-lg">
          <Col width="15" />
          <Col width="70">
            <Button
              text="金額を確定する"
              isActive={isActive()}
              handleClickAction={handleClickMoneySelect}
              style={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                height: '5rem',
              }}
            />
          </Col>
          <Col width="15" />
        </Row>
      </div>
    </PopupPageTemplate>
  );
};
