import axios from 'axios';
import { endpoint } from '@/consts/api';
import {
  SearchAddressByZipApiParams,
  SearchAddressByZipApiResponse,
} from '@/types/api/yahooApi';

// 郵便番号検索API
export const searchAddressByZipApi = (
  params: SearchAddressByZipApiParams,
): Promise<SearchAddressByZipApiResponse> =>
  axios.get(`${endpoint.YAHOO_API}/search/zip/V1/zipCodeSearch`, { params });
