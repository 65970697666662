import { f7 } from 'framework7-react';
import { FC } from 'react';
import ContactImageAvatar from 'src/assets/images/repair-contact-image.jpeg';
import style from './ContactPopUpStyle.module.css';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { store } from '@/config/store';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';

interface ContactPopupProps {
  opened: boolean;
  handleClose?: () => void;
}

export const ContactPopup: FC<ContactPopupProps> = ({
  opened = false,
  handleClose,
}) => {
  const { data: affiliateData } = useMAffiliateSettings({
    m_affiliate_id: store.state.customerInfo.m_affiliate_id,
  });

  const handleCallSupportCenter = () => {
    if (affiliateData?.data?.callcenter_tel) {
      window.location.href = 'tel://' + affiliateData?.data?.callcenter_tel;
    } else {
      openAlertNoWarranty();
    }
  };

  const openAlertNoWarranty = () => {
    f7.dialog.alert(
      '発信可能な電話番号がありません。\n お手数ですがお問い合わせフォームからご連絡ください。',
      ' エラー',
      () => {
        f7.dialog.close();
      },
    );
  };

  return (
    <PopupTemplate
      opened={opened}
      title="サポートセンター"
      onPopupClose={handleClose}
      handleClose={handleClose}
    >
      <div className={style['contact-container']}>
        <p>サポートセンターに電話でご相談</p>
        <div
          className={style['contact-box']}
          onClick={() => handleCallSupportCenter()}
        >
          <img className={style['contact-avatar']} src={ContactImageAvatar} />
          <div className={style['contact-info-text']}>
            {affiliateData?.data?.callcenter_name}
          </div>
          <div className={style['contact-call-button']}>
            <i className="icon-telephone" />
          </div>
        </div>
      </div>
    </PopupTemplate>
  );
};
