import { Button as Framework7Button } from 'framework7-react';
import { ButtonProps } from 'framework7-react/components/button';
import { FC } from 'react';
import style from './buttonStyle.module.css';

export interface MainButtonProps extends ButtonProps {
  buttonType?: string;
  height?: string;
  isFixed?: boolean;
  isActive?: boolean;
  outline?: boolean;
  handleClickAction?: () => void;
  icon?: string;
  bold?: boolean;
  disabled?: boolean;
}

export const Button: FC<MainButtonProps> = ({
  children,
  height = undefined,
  isFixed = false,
  isActive = true,
  outline = false,
  handleClickAction,
  icon = undefined,
  bold = true,
  disabled = false,
  ...props
}) => {
  return (
    <Framework7Button
      round
      className={`${disabled && style['button-disabled']}`}
      style={{ height: height, fontWeight: bold ? 'bold' : undefined }}
      disabled={disabled}
      fill={isActive && !outline}
      outline={!isActive || outline}
      icon={icon}
      color={isActive ? 'theme' : 'gray'}
      onClick={handleClickAction}
      {...props}
    >
      {children}
    </Framework7Button>
  );
};
