import { AxiosError } from 'axios';
import { useState } from 'react';
import { fetchWatcherAllApi } from '@/api/linkDriveApi';
import {
  FetchWatcherAllApiParams,
  FetchWatcherAllApiResponse,
} from '@/types/api/linkDriveApi';

export const useFetchWatcherAll = () => {
  const [data, setData] = useState<FetchWatcherAllApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const fetchWatcherAll = async (params: FetchWatcherAllApiParams) => {
    try {
      setIsLoading(true);
      const { data } = await fetchWatcherAllApi(params);
      setData(data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    fetchWatcherAll,
    data,
    error,
  };
};
