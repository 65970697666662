import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './helpTopStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';

interface HelpTopProps {
  f7router: Router.Router;
  handleClickHelp: () => void;
  handleClickContact: () => void;
}
export const HelpTop: FC<HelpTopProps> = ({
  handleClickHelp,
  handleClickContact,
}) => {
  return (
    <PopupPageTemplate pageName="HelpTop" title="ヘルプ">
      <div className={style['container']}>
        <Button colorTheme="gray" handleClickAction={handleClickHelp}>
          ヘルプページを見る
        </Button>
        <Button colorTheme="gray" handleClickAction={handleClickContact}>
          お問い合わせ
        </Button>
      </div>
    </PopupPageTemplate>
  );
};
