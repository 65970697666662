import { Page } from 'framework7-react';
import { FC } from 'react';

export const RestartReservingsPage: FC = () => {
  return (
    <Page>
      <h3>積立プラン再開</h3>
    </Page>
  );
};
