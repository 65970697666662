import { FC } from 'react';
import style from './TroubleShootingCardLink.module.css';

interface TroubleShootingCardLinkProps {
  imageSrc: string;
  cardTitle: string;
  cardBody: string;
  handleClick: () => void;
}

export const TroubleShootingCardLink: FC<TroubleShootingCardLinkProps> = ({
  imageSrc,
  cardTitle,
  cardBody,
  handleClick,
}) => {
  return (
    <div onClick={handleClick} className={style.card}>
      <div className={style.image}>
        <img width="48px" height="100%" src={imageSrc} alt="" />
      </div>
      <div className={style['card-content']}>
        <p className={style['card-title']}>{cardTitle}</p>
        <p className={style['card-body']}>{cardBody}</p>
      </div>
      <i className="icon-right" />
    </div>
  );
};
