import { differenceInDays, endOfMonth, parse, subMonths } from 'date-fns';
import {
  isCancelEnableTerm,
  makeCancelableWarrantyObj,
} from '../WarrantyManagement/WarrantyManagementHelper';
import { prepaidFlg } from '@/consts/payment';
import {
  PLAN_CHANGE_MONTH_BEFORE_EXPIRE,
  warrantyType,
} from '@/consts/warranty';
import {
  BillInformation,
  Car,
  Customer,
  NextWarranty,
  NextWarrantyAppendWarranty,
  WarrantiesAppendWarranty,
} from '@/types/api/customerApi';
import { ContinuousWarranty } from '@/types/api/warrantyApi';
import { UpdateWarrantyDetailCustomer } from '@/types/warranty';
import { getWarrantyPartsData } from '@/utils/warranty';

export const updateWarrantyStep = {
  STEP_ONE: 1,
  STEP_TOW: 2,
  STEP_THREE: 3,
  STEP_FOUR: 4,
  STEP_FIVE: 5,
  STEP_SIX: 6,
} as const;

export const makeUpdateWarrantyDetailCustomer = (
  warrantyNo: string,
  billsInfo: Required<BillInformation>[],
  customer: Required<Customer>,
  car: Required<Car> | undefined,
  continuousWarranties: ContinuousWarranty[] | undefined,
  nextWarranty: NextWarranty | undefined,
  warranty: WarrantiesAppendWarranty | undefined,
): UpdateWarrantyDetailCustomer => {
  const cancelableWarrantyObj = makeCancelableWarrantyObj(
    warranty?.warranty_end_date,
  );
  const billDetail = billsInfo
    .find((bill) => bill.warranty_no === warrantyNo)
    ?.bill_infomation_details.slice(-1)[0];
  const billInfo =
    billDetail?.prepaid_flg === prepaidFlg.NO_HOUSE_PREPAID_EXISTS
      ? billDetail
      : undefined;

  const nextWarrantyDetail = nextWarranty?.append_warranties?.find(
    (aw) => aw.warranty_type === warrantyType.DEFECT,
  );

  const continuousWarranty = findContinuousWarranty(
    continuousWarranties,
    nextWarrantyDetail,
  );

  return {
    warranty,
    warrantyNo: warrantyNo,
    contractorName: customer.family_name + ' ' + customer.first_name,
    contractorNameKana:
      customer.family_name_kana + ' ' + customer.first_name_kana,
    corporationName: customer.company_name,
    birthDay: customer.birthday,
    mobilePhone: customer.mobile_flg1 ? customer.phone_no1 : customer.phone_no2,
    homePhone: customer.mobile_flg1 ? customer.phone_no2 : customer.phone_no1,
    zipCode: customer.zip_code1 + '-' + customer.zip_code2,
    address1: customer.address1,
    address2: customer.address2 + customer.address3,
    transportName: car?.transport_name ?? '',
    classNo: car?.class_no ?? '',
    hiragana: car?.hiragana ?? '',
    registryNo: car?.registry_no ?? '',
    warrantyName: nextWarrantyDetail?.warranty_name ?? '',
    deductibleTerm: nextWarrantyDetail?.contract_term,
    amountLimit: nextWarrantyDetail?.amount_limit,
    warrantyFeeWhContinuous:
      continuousWarranty?.warranty_fee_wh_continuous ?? 0,
    planInfo: nextWarrantyDetail?.plan_info,
    // TODO: apiからloan_num,loan_fee_f,loan_fee_sが返ってきていない
    loanNum: nextWarrantyDetail?.loan_num ?? 0,
    loanNumF: nextWarrantyDetail?.loan_fee_f ?? 0,
    loanNumS: nextWarrantyDetail?.loan_fee_s ?? 0,
    paymentTypeId: billInfo?.m_payment_type_id ?? 0,
    paymentTypeName: billInfo?.payment_type_name,
    accountNumber: billInfo?.account_number ?? '',
    coveringType: nextWarrantyDetail?.covering_type,
    cardNo: billInfo?.card_no ?? '',
    warrantyStartDate: nextWarrantyDetail?.warranty_start_date,
    warrantyEndDate: nextWarrantyDetail?.warranty_end_date,
    cancelEnableTerm: isCancelEnableTerm(
      cancelableWarrantyObj?.cancelEnableTermStart ?? '',
      cancelableWarrantyObj?.cancelEnableTermEnd ?? '',
    ),
    cancelEnableTermEnd: cancelableWarrantyObj?.cancelEnableTermEnd,
    cancellationPeriodFrom: nextWarranty?.cancellation_period_from,
    cancellationPeriodTo: nextWarranty?.cancellation_period_to,
    customerType: customer.customer_type,
    partsList: getWarrantyPartsData(nextWarrantyDetail?.m_warranty_parts || []),
    warrantyFee: nextWarrantyDetail?.warranty_fee,
  };
};

export const findContinuousWarranty = (
  warranties: ContinuousWarranty[] | undefined,
  nextWarrantyDetail: NextWarrantyAppendWarranty | undefined,
) => {
  let continuousWarranty: ContinuousWarranty | undefined = undefined;
  if (warranties) {
    continuousWarranty = warranties.find(
      (warranty) =>
        nextWarrantyDetail?.m_warranty_plan_header_id ===
          warranty.m_warranty_plan_header_id &&
        nextWarrantyDetail.m_warranty_plan_detail_id ===
          warranty.m_warranty_plan_detail_id,
    );
  }
  return continuousWarranty;
};

export const isCancelableDate = (
  cancellationPeriodTo: NextWarranty['cancellation_period_to'] | undefined,
) => {
  if (!cancellationPeriodTo) return false;
  const today = new Date();
  const cancellationPeriodToDate = parse(
    cancellationPeriodTo,
    'yyyy-MM-dd',
    new Date(),
  );

  return differenceInDays(today, cancellationPeriodToDate) <= 0;
};

export const checkIfAllowToChangePlan = (
  isWarrantiesDuplicated: boolean,
  warrantyEndDate?: string,
) => {
  if (isWarrantiesDuplicated) return false;
  const lastDayOfTwoMonthAgo = endOfMonth(
    subMonths(
      new Date(warrantyEndDate || Date.now()),
      PLAN_CHANGE_MONTH_BEFORE_EXPIRE,
    ),
  );
  const daysTillEnd = differenceInDays(lastDayOfTwoMonthAgo, new Date());
  return daysTillEnd >= 0;
};
