import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useCallback, useEffect, useState } from 'react';
import { Prepaid } from './Prepaid/Prepaid';
import { chargePrepaidApi } from '@/api/prepaidApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { chargeClassification, chargeType } from '@/consts/charge';
import { ThemeId } from '@/consts/shop';
import {
  getAndSetCustomerInfo,
  useCustomerInfo,
} from '@/hooks/api/customer/useCustomerInfo';
import { usePaymentList } from '@/hooks/api/payment/usePaymentList';
import { useLoadCustomer } from '@/hooks/useLoadCustomer';
import { useToast } from '@/hooks/useToast';
import { Campaign } from '@/types/api/customerApi';
import { GmoRedirectInfo } from '@/types/payment';
import { generatePrepaidName } from '@/utils/shop';
import { storeDispatch } from '@/utils/store';
// 下記importを消去しないように注意
import '@/utils/permissionHelper';

type PrepaidPageProps = {
  f7router: Router.Router;
  status?: string;
  gmoRedirectInfo?: GmoRedirectInfo;
};

export const PrepaidPage: FC<PrepaidPageProps> = ({
  f7router,
  status,
  gmoRedirectInfo,
}) => {
  const router = f7.views.main.router;
  const [refresh, setRefresh] = useState(false);
  const authInfo = store.state.authInfo;
  const themeId = useStore(store, 'getThemeId') as ThemeId;
  const couponList =
    (useStore(store, 'getCampaignsInfo') as Required<Campaign>[]) || [];

  const validCouponList = couponList.filter((coupon) => !coupon.status);
  const [currentStatus, setCurrentStatus] = useState(status);
  const customerInfo = useStore(store, 'customerInfo');
  const prepaidInfo = useStore(store, 'getFirstAdvancePaymentsInfo');
  const prepaidName = generatePrepaidName(customerInfo?.affiliate_prepaid_name);
  const customerId = window.localStorage.getItem('m_customer_id');
  const { updatePaymentList, isFetched, isFetching } = usePaymentList(false);
  const { openToast } = useToast();

  const token =
    window.localStorage.getItem('token') === null
      ? undefined
      : window.localStorage.getItem('token');

  const { refetch } = useCustomerInfo(
    {
      m_customer_id: authInfo.m_customer_id,
    },
    !status,
  );

  const { isLoggedIn, resetIsLoggedIn } = useLoadCustomer(
    router,
    token === null ? undefined : token,
    Number(customerId),
    currentStatus,
    false,
  );

  const handleOnRefetch = async (cb: () => void) => {
    try {
      await refetch();
      cb();
    } catch (e) {
      console.error('Error while refetching: ', e);
    }
  };

  const afterGmoRedirect = useCallback(async () => {
    // GMOからのリダイレクトに必要なパラメータ
    const mCustomerId = window.localStorage.getItem('m_customer_id');
    const destinationStep = window.localStorage.getItem('destination_step');
    const destinationPage = window.localStorage.getItem('destination_page');
    const reserveAmount = window.localStorage.getItem('reserve_amount');
    const sourcePage = window.localStorage.getItem('source_page');
    if (currentStatus) {
      storeDispatch('setMenu', 'menu', 'prepaid');
    }
    window.localStorage.removeItem('destination_step');

    const gmoRedirectInfo = {
      status: currentStatus,
      destinationStep,
      destinationPage,
      homeNextPage: paths.prepaid,
      openDialog: paths.reservings,
    };
    reserveAmount &&
      (await storeDispatch(
        'setReserveAmount',
        'reserveAmount',
        Number(reserveAmount) ?? 0,
      ));

    switch (currentStatus) {
      // GMOペイメント post →　maikuru バックエンド（api.mobicon.net）からのリダイレクト
      case 'gmo_success':
        if (isLoggedIn) {
          setCurrentStatus(undefined);
          resetIsLoggedIn();
          if (sourcePage === paths.chargePrepaid) {
            window.localStorage.removeItem('source_page');
            router.navigate(paths.chargePrepaid, {
              animate: false,
              props: {
                redirectPage: 'prepaid',
              },
            });
            return;
          }

          if (!isFetched && !isFetching) {
            await updatePaymentList(Number(mCustomerId));
            if (gmoRedirectInfo.destinationPage) {
              f7.views.main.router.navigate(gmoRedirectInfo.destinationPage, {
                animate: true,
                props: {
                  isBackShowPrepaidSummary: true,
                },
              });
            }
            return;
          }
        }
        break;
      case 'gmo_cancel':
        if (isLoggedIn) {
          setCurrentStatus(undefined);
          resetIsLoggedIn();
          if (sourcePage === paths.chargePrepaid) {
            window.localStorage.removeItem('source_page');
            router.navigate(paths.chargePrepaid, {
              animate: false,
              props: {
                redirectPage: 'prepaid',
              },
            });
            return;
          }
          if (gmoRedirectInfo.destinationPage) {
            f7.views.main.router.navigate(gmoRedirectInfo.destinationPage, {
              animate: true,
              props: {
                isBackShowPrepaidSummary: true,
              },
            });
          }
        }
        break;
      case 'charge_prepaid_success':
        const chargePrice = window.localStorage.getItem('charge_amount');
        const mPrepaidId = window.localStorage.getItem('m_prepaid_id');
        if (
          isLoggedIn &&
          !isFetched &&
          !isFetching &&
          chargePrice &&
          mPrepaidId &&
          prepaidInfo
        ) {
          setCurrentStatus(undefined);
          window.localStorage.removeItem('charge_amount');
          const params = {
            m_customer_id: authInfo.m_customer_id,
            m_prepaid_id: Number(mPrepaidId) ?? 0,
            charge_price: Number(chargePrice),
            charge_kbn: prepaidInfo?.fixed_deposit_flg
              ? chargeClassification.REGULAR
              : chargeClassification.BASIC,
            charge_type: chargeType.SPOT,
          };
          chargePrepaidApi(params).then((res) => {
            const error_message =
              res.data.error_message ?? '処理に失敗しました。';
            if (!res.data.success) {
              openToast(error_message, 'toast-failed', 3000, true, 'top');
              return;
            }

            getAndSetCustomerInfo({
              m_customer_id: authInfo.m_customer_id,
            });
          });
        }
        break;
      case 'charge_prepaid_cancel':
        if (isLoggedIn && store.state.advancePaymentsInfo[0]) {
          // chargePrepaidPageでadvancePaymentInfoを参照しているため
          // advancePaymentInfoがfalsyな値の場合は遷移しない
          setCurrentStatus(undefined);
          resetIsLoggedIn();
          router.navigate(paths.chargePrepaid, {
            animate: false,
            props: {
              redirectPage: 'prepaid',
            },
          });
        }
        break;
      default:
        break;
    }
  }, [
    prepaidInfo,
    authInfo.m_customer_id,
    currentStatus,
    isFetched,
    isFetching,
    isLoggedIn,
    resetIsLoggedIn,
    router,
    updatePaymentList,
  ]);

  useEffect(() => {
    afterGmoRedirect();
  }, [afterGmoRedirect]);

  return (
    <Prepaid
      f7router={f7.view.main.router}
      themeId={themeId}
      couponList={couponList}
      prepaidName={prepaidName}
      customerInfo={customerInfo}
      validCouponList={validCouponList}
      prepaidInfo={prepaidInfo}
      onRefetch={handleOnRefetch}
    />
  );
};
