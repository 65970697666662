import { FC } from 'react';
import style from './AbnormalAlertStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';

interface AbnormalAlertProps {
  id: number;
}

export const AbnormalAlert: FC<AbnormalAlertProps> = ({ id }) => {
  const error = store.state.scanResultErrors[id];
  return (
    <PageTemplate pageName="AbnormalAlert" title="異常アラート" showNavBack>
      <div className={style.card}>
        <div className={style['error-card']}>
          <h3 className={style['card-head']}>{error.dtc_title}</h3>
          <p className={style['card-paragraph']}>
            検出日時: 2020年10月10日 00:00
          </p>
          <p className={style['card-paragraph']}>
            エラーコード: {error.dtc_code}
          </p>
        </div>
        <h3 className={style.heading}>このアラートが意味すること</h3>
        <p className={style.description}>説明</p>
        <h3 className={style.heading}>アラートを解消するには</h3>
        <p className={style.description}>説明</p>
        <p className={style.supplement}>
          ※エラー情報はサポートセンターに共有されています。
        </p>
        <div className={style.grid}>
          <div />
          <Button icon="icon-telephone">サポートセンターに電話する</Button>
          <div />
        </div>
      </div>
    </PageTemplate>
  );
};
