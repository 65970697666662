import { Router } from 'framework7/types';
import { FC } from 'react';
import { Trouble } from './Trouble/Trouble';
import { store } from '@/config/store';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';

type TroublePageProps = {
  f7router: Router.Router;
};

export const TroublePage: FC<TroublePageProps> = ({ f7router }) => {
  const customerInfo = store.state.customerInfo;
  const mAffiliateId = customerInfo.m_affiliate_id;
  const { data } = useMAffiliateSettings({ m_affiliate_id: mAffiliateId });
  const callCenterTel = data?.data.callcenter_tel ?? '';
  return <Trouble f7router={f7router} roadServiceTel={callCenterTel} />;
};
