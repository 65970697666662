import { Block } from 'framework7-react';
import { FC, useState } from 'react';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { TextInput } from '@/components/uiParts/TextInput/TextInput';
import './registerPhoneStyle.scss';

type ChangeEventType = React.ChangeEvent<HTMLInputElement>;

export const RegisterPhone: FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const handleClickUpdateCustomer = () => {
    return;
  };

  return (
    <PopupPageTemplate pageName="RegisterPhone" title="携帯電話番号の登録">
      <Block>
        <div className="list no-hairlines">
          <ul>
            <TextInput
              label="電話番号"
              placeholder="090-0000-0000"
              annotation="認証用のSMSが送信されます"
              type="text"
              required={true}
              value={phoneNumber}
              changeText={(e: ChangeEventType) =>
                setPhoneNumber(e.target.value)
              }
            />
          </ul>
        </div>

        <div className="u-padding-right-sm u-padding-left-sm u-margin-top-md u-text-center">
          <Button
            text="SMSを送信する"
            handleClickAction={handleClickUpdateCustomer}
          />
        </div>
      </Block>
    </PopupPageTemplate>
  );
};
