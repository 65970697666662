import { FC, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './TradeReservationPage.module.css';

interface RadioProps {
  id: string;
  name: string;
  value: any;
  className?: string;
  checked?: boolean;
  children?: ReactNode;
  handleOnClick?: (value: string) => void;
}

export const Radio: FC<RadioProps> = ({
  id,
  name,
  className = '',
  value,
  checked = false,
  handleOnClick,
  children,
}) => {
  const { setValue, trigger } = useFormContext();
  return (
    <label htmlFor={id} className={className}>
      <div className={style['radio-container']}>
        <input
          id={id}
          name={name}
          className={style['radio-input']}
          type="radio"
          onChange={() => {
            setValue(name, value);
            trigger(name);
          }}
          onClick={() => handleOnClick?.(value)}
          checked={checked}
        />
        <span className={style['custom-radio']} />
      </div>
      {children}
    </label>
  );
};
