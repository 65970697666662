import { IconProps } from './type';

export const DocumentIcon = ({
  width = '56',
  height = '57',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28.5" r="28" fill={color} fillOpacity="0.1" />
      <path
        d="M16.3384 15.9935C16.3384 15.5333 16.7115 15.1602 17.1717 15.1602H30.0884C30.2515 15.1602 30.4109 15.208 30.5471 15.2978L39.2971 21.067C39.531 21.2212 39.6717 21.4826 39.6717 21.7627V40.9935C39.6717 41.4537 39.2986 41.8268 38.8384 41.8268H17.1717C16.7115 41.8268 16.3384 41.4537 16.3384 40.9935V15.9935Z"
        fill="#CCDBFF"
      />
      <path
        d="M39.6616 43.0833H16.3283C15.6379 43.0833 15.0783 42.5237 15.0783 41.8333V15.1667C15.0783 14.4763 15.6379 13.9167 16.3283 13.9167H31.1957L40.9116 20.7988V41.8333C40.9116 42.5237 40.352 43.0833 39.6616 43.0833Z"
        stroke="#323232"
        strokeWidth="0.833333"
        strokeLinecap="round"
      />
      <rect
        x="20.5"
        y="23.5"
        width="15"
        height="0.833333"
        rx="0.416667"
        fill="#323232"
      />
      <rect
        x="20.5"
        y="26.8398"
        width="15"
        height="0.833333"
        rx="0.416667"
        fill="#323232"
      />
      <rect
        x="20.5"
        y="30.1602"
        width="15"
        height="0.833333"
        rx="0.416667"
        fill="#323232"
      />
      <rect
        x="20.5"
        y="33.5"
        width="15"
        height="0.833333"
        rx="0.416667"
        fill="#323232"
      />
      <rect
        x="20.5"
        y="36.8398"
        width="15"
        height="0.833333"
        rx="0.416667"
        fill="#323232"
      />
    </svg>
  );
};
