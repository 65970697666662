import {
  GoogleMap,
  GoogleMapProps,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { FC, useEffect, useState } from 'react';
import accelerationMark from '@/assets/images/menu/map_acceleration_mark.svg';
import decelerationMark from '@/assets/images/menu/map_deceleration_mark.svg';
import arrivalPointMark from '@/assets/images/menu/map_end_mark.svg';
import speedOverMark from '@/assets/images/menu/map_speed_over_mark.svg';
import departurePointMark from '@/assets/images/menu/map_start_mark.svg';
import { apiKey } from '@/consts/apiKey';
import { GOOGLE_MAP_FALLBACK_LAT_LNG } from '@/consts/googleMap';
import { DrivingRouteResponse } from '@/types/api/driveRecordApi';

interface MapCardProps {
  markers: DrivingRouteResponse['data'] | undefined;
}

const TOTAL_MARKERS = 2;

export const MapCard: FC<MapCardProps> = ({ markers }) => {
  const mapStyle = {
    width: '100%',
    height: '300px',
  };
  const mapOptions: GoogleMapProps['options'] = {
    disableDefaultUI: true,
  };
  const arrivalPosition = {
    lat: markers?.data.arrival_point?.lat || GOOGLE_MAP_FALLBACK_LAT_LNG.lat,
    lng: markers?.data.arrival_point?.lng || GOOGLE_MAP_FALLBACK_LAT_LNG.lng,
  };
  const departurePosition = {
    lat: markers?.data.departure_point?.lat || GOOGLE_MAP_FALLBACK_LAT_LNG.lat,
    lng: markers?.data.departure_point?.lng || GOOGLE_MAP_FALLBACK_LAT_LNG.lng,
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey.GOOGLE_MAPS_API_KEY,
  });
  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  useEffect(() => {
    if (!googleMap) return;
    if (markers) {
      var bounds = new google.maps.LatLngBounds();
      if (markers.data.departure_point)
        bounds.extend(markers.data.departure_point);
      if (markers.data.arrival_point) bounds.extend(markers.data.arrival_point);
      googleMap.fitBounds(bounds);
    } else {
      const getGeoLocation = () => {
        if (!navigator.geolocation) return;
        navigator.geolocation.getCurrentPosition((position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      };
      getGeoLocation();
    }
  }, [markers, googleMap]);

  return (
    <div>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyle}
          center={center}
          options={mapOptions}
          zoom={15}
          onLoad={(map) => {
            setGoogleMap(map);
          }}
        >
          {markers && (
            <Marker position={arrivalPosition} icon={arrivalPointMark} />
          )}
          {markers && (
            <Marker position={departurePosition} icon={departurePointMark} />
          )}
          {markers &&
            markers.data &&
            markers.data?.behavior_point?.map((point, index) => (
              <>
                {point.behavior_type === 1 && (
                  <Marker
                    key={index}
                    position={{ lat: point.lat, lng: point.lng }}
                    icon={speedOverMark}
                  />
                )}
                {point.behavior_type === 2 && (
                  <Marker
                    key={index}
                    position={{ lat: point.lat, lng: point.lng }}
                    icon={accelerationMark}
                  />
                )}
                {point.behavior_type === 3 && (
                  <Marker
                    key={index}
                    position={{ lat: point.lat, lng: point.lng }}
                    icon={decelerationMark}
                  />
                )}
              </>
            ))}
        </GoogleMap>
      )}
    </div>
  );
};
