import { FC } from 'react';
import { MedalIcon } from 'src/components/pages/Rank/MedalIcon/MedalIcon';
import style from './RankStepperStyle.module.css';
import { MAP_RANK_NAMES, RANK_THEMES, Rank } from '@/consts/rank';
import { RankTheme } from '@/types/api/rank';

interface RankStepperProps {
  currentRank?: RankTheme;
}

export const RankStepper: FC<RankStepperProps> = ({ currentRank }) => {
  return (
    <div className={`${style['wrapper']}`}>
      <ol className={style['stepper']}>
        {Object.keys(MAP_RANK_NAMES).map((rank, index) => (
          <li
            key={index}
            className={`${style['stepper-item']} ${
              (currentRank?.index || 0) >=
                (RANK_THEMES[rank as Rank]?.index || 0) &&
              `${style['stepper-item-active']}`
            }`}
          >
            <h4>&nbsp;</h4>
            <div
              className={`${style['stepper-desc']} ${
                RANK_THEMES[rank as Rank].index === currentRank?.index
                  ? style['stepper-desc-active']
                  : ''
              }`}
            >
              <MedalIcon
                color={RANK_THEMES[rank as Rank]?.primary || '#BDBDBD'}
                isCurrentRank={
                  RANK_THEMES[rank as Rank].index === currentRank?.index
                }
              />
              <p>{MAP_RANK_NAMES[rank as Rank]}</p>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};
