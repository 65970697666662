import { Router } from 'framework7/types';
import { FC } from 'react';
import { PhoneChanged } from './PhoneChanged/PhoneChanged';

interface PhoneChangedPageProps {
  f7router: Router.Router;
}
export const PhoneChangedPage: FC<PhoneChangedPageProps> = ({ f7router }) => {
  return <PhoneChanged f7router={f7router} />;
};
