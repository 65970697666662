export const inquiryType = {
  INSPECTION: 1,
  PREPAID: 2,
  WARRANTY: 3,
  APP: 4,
  OTHER: 5,
} as const;

export const supportCenter = {
  TEL: '05053066729',
} as const;
