import { AxiosError } from 'axios';
import { f7, useStore } from 'framework7-react';
import { useState } from 'react';
import { customerInfoApi } from '@/api/customerApi';
import { findRelevantNextWarranty } from '@/components/pages/WarrantyManagement/WarrantyManagementHelper';
import { initialState } from '@/config/initialState';
import { store } from '@/config/store';
import { Car, CustomerInfoApiParams } from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';

export const useCarChange = () => {
  const [error, setError] = useState<AxiosError>();
  const car = useStore(store, 'carItemInfo') as Required<Car>;
  const changeCarStore = async (params: CustomerInfoApiParams) => {
    try {
      const res = await customerInfoApi(params);
      storeDispatch('setCarsInfo', 'carsInfo', res.data.cars);
      const currentCar =
        res.data.cars?.find((c) => c.m_car_id === car.m_car_id) ||
        res.data?.cars?.[0];
      if (!currentCar) return;
      storeDispatch(
        'setCarItemInfo',
        'carItemInfo',
        currentCar || initialState.carItemInfo,
      );
      storeDispatch(
        'setWarranties',
        'warranties',
        currentCar?.warranties || [],
      );
      storeDispatch(
        'setNextWarranties',
        'nextWarranties',
        currentCar?.next_warranty || [],
      );
      storeDispatch(
        'setCurrentWarranty',
        'currentWarranty',
        currentCar?.warranties?.[0],
      );
      storeDispatch(
        'setNextWarranty',
        'nextWarranty',
        findRelevantNextWarranty(
          currentCar?.warranties?.[0],
          currentCar?.next_warranty,
        ),
      );
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    changeCarStore,
  };
};
