import { FC } from 'react';
import { CarIconProps } from './CarIcon';

export const CarIcon2: FC<CarIconProps> = ({ color, reference }) => {
  return (
    <svg
      ref={reference}
      width="728"
      height="536"
      viewBox="0 0 91 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="0.5"
        width="89"
        height="66"
        rx="15.5"
        fill="#F3F5F6"
        stroke="#EFEFEF"
      />
      <path
        d="M8.86876 34.4935C9.24229 30.9449 11.9207 28.0728 15.4345 27.4527L26.4997 25.5L36.0728 17.6364C38.9351 15.2852 42.5245 14 46.2286 14H67.5272C70.8265 14 73.7874 16.0255 74.9832 19.1004L79.7277 31.3006C79.9074 31.7628 79.9997 32.2545 79.9997 32.7504V43.4998C79.9997 45.7089 78.2088 47.4998 75.9997 47.4998H11.9428C9.56801 47.4998 7.71618 45.4428 7.96479 43.081L8.86876 34.4935Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0866 27.4318C27.7544 27.7047 27.3575 27.8873 26.9342 27.962L15.869 29.9146C13.4532 30.3409 11.6118 32.3156 11.355 34.7552L10.4511 43.3427C10.3578 44.2284 11.0523 44.9998 11.9428 44.9998H75.9997C76.8281 44.9998 77.4997 44.3282 77.4997 43.4998V32.7504C77.4997 32.5644 77.4651 32.3801 77.3977 32.2067L72.6532 20.0066C71.8311 17.8925 69.7954 16.5 67.5272 16.5H46.2286C43.1033 16.5 40.0747 17.5844 37.6597 19.5682L28.0866 27.4318ZM26.4997 25.5L15.4345 27.4527C11.9207 28.0728 9.24229 30.9449 8.86876 34.4935L7.96479 43.081C7.71618 45.4428 9.56801 47.4998 11.9428 47.4998H75.9997C78.2088 47.4998 79.9997 45.7089 79.9997 43.4998V32.7504C79.9997 32.2545 79.9074 31.7628 79.7277 31.3006L74.9832 19.1004C73.7874 16.0255 70.8265 14 67.5272 14H46.2286C42.5245 14 38.9351 15.2852 36.0728 17.6364L26.4997 25.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4518 26.6591C27.2525 26.8228 27.0144 26.9324 26.7604 26.9772L15.6952 28.9299C12.8402 29.4337 10.664 31.7673 10.3605 34.6505L9.45655 43.2381C9.30117 44.7141 10.4586 45.9998 11.9428 45.9998H75.9997C77.3804 45.9998 78.4997 44.8805 78.4997 43.4998V32.7504C78.4997 32.4404 78.442 32.1332 78.3297 31.8443L73.5852 19.6441C72.6136 17.1457 70.2079 15.5 67.5272 15.5H46.2286C42.8718 15.5 39.6189 16.6647 37.0249 18.7955L27.4518 26.6591ZM26.4997 25.5L15.4345 27.4527C11.9207 28.0728 9.24229 30.9449 8.86876 34.4935L7.96479 43.081C7.71618 45.4428 9.56801 47.4998 11.9428 47.4998H75.9997C78.2088 47.4998 79.9997 45.7089 79.9997 43.4998V32.7504C79.9997 32.2545 79.9074 31.7628 79.7277 31.3006L74.9832 19.1004C73.7874 16.0255 70.8265 14 67.5272 14H46.2286C42.5245 14 38.9351 15.2852 36.0728 17.6364L26.4997 25.5Z"
        fill="#323232"
      />
      <path
        d="M32.5 46.5C32.5 51.7467 28.2467 56 23 56C17.7533 56 13.5 51.7467 13.5 46.5C13.5 41.2533 17.7533 37 23 37C28.2467 37 32.5 41.2533 32.5 46.5Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 54.5C27.4183 54.5 31 50.9183 31 46.5C31 42.0817 27.4183 38.5 23 38.5C18.5817 38.5 15 42.0817 15 46.5C15 50.9183 18.5817 54.5 23 54.5ZM23 56C28.2467 56 32.5 51.7467 32.5 46.5C32.5 41.2533 28.2467 37 23 37C17.7533 37 13.5 41.2533 13.5 46.5C13.5 51.7467 17.7533 56 23 56Z"
        fill="#323232"
      />
      <path
        d="M74.5 46.5C74.5 51.7467 70.2467 56 65 56C59.7533 56 55.5 51.7467 55.5 46.5C55.5 41.2533 59.7533 37 65 37C70.2467 37 74.5 41.2533 74.5 46.5Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65 54.5C69.4183 54.5 73 50.9183 73 46.5C73 42.0817 69.4183 38.5 65 38.5C60.5817 38.5 57 42.0817 57 46.5C57 50.9183 60.5817 54.5 65 54.5ZM65 56C70.2467 56 74.5 51.7467 74.5 46.5C74.5 41.2533 70.2467 37 65 37C59.7533 37 55.5 41.2533 55.5 46.5C55.5 51.7467 59.7533 56 65 56Z"
        fill="#323232"
      />
      <path
        d="M27.5 46.5C27.5 48.9853 25.4853 51 23 51C20.5147 51 18.5 48.9853 18.5 46.5C18.5 44.0147 20.5147 42 23 42C25.4853 42 27.5 44.0147 27.5 46.5Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 49.5C24.6569 49.5 26 48.1569 26 46.5C26 44.8431 24.6569 43.5 23 43.5C21.3431 43.5 20 44.8431 20 46.5C20 48.1569 21.3431 49.5 23 49.5ZM23 51C25.4853 51 27.5 48.9853 27.5 46.5C27.5 44.0147 25.4853 42 23 42C20.5147 42 18.5 44.0147 18.5 46.5C18.5 48.9853 20.5147 51 23 51Z"
        fill="#323232"
      />
      <path
        d="M69.5 46.5C69.5 48.9853 67.4853 51 65 51C62.5147 51 60.5 48.9853 60.5 46.5C60.5 44.0147 62.5147 42 65 42C67.4853 42 69.5 44.0147 69.5 46.5Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65 49.5C66.6569 49.5 68 48.1569 68 46.5C68 44.8431 66.6569 43.5 65 43.5C63.3431 43.5 62 44.8431 62 46.5C62 48.1569 63.3431 49.5 65 49.5ZM65 51C67.4853 51 69.5 48.9853 69.5 46.5C69.5 44.0147 67.4853 42 65 42C62.5147 42 60.5 44.0147 60.5 46.5C60.5 48.9853 62.5147 51 65 51Z"
        fill="#323232"
      />
      <path
        d="M41.1667 19.5C42.0321 18.8509 43.0848 18.5 44.1667 18.5H49.5C50.0523 18.5 50.5 18.9477 50.5 19.5V28C50.5 28.5523 50.0523 29 49.5 29H33.5C32.9477 29 32.5 28.5523 32.5 28C32.5 26.741 33.0928 25.5554 34.1 24.8L41.1667 19.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49 20H44.1667C43.4094 20 42.6725 20.2456 42.0667 20.7L35 26C34.5061 26.3704 34.1717 26.9063 34.0505 27.5H49V20ZM44.1667 18.5C43.0848 18.5 42.0321 18.8509 41.1667 19.5L34.1 24.8C33.0928 25.5554 32.5 26.741 32.5 28C32.5 28.5523 32.9477 29 33.5 29H49.5C50.0523 29 50.5 28.5523 50.5 28V19.5C50.5 18.9477 50.0523 18.5 49.5 18.5H44.1667Z"
        fill="#323232"
      />
      <path
        d="M70.0388 20.9243C69.4085 19.4536 67.9624 18.5 66.3623 18.5H53.4998C52.9476 18.5 52.4998 18.9477 52.4998 19.5V28C52.4998 28.5523 52.9476 29 53.4998 29H71.9833C72.7012 29 73.1852 28.2659 72.9024 27.6061L70.0388 20.9243Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.9998 20H66.3623C67.3623 20 68.2662 20.596 68.6601 21.5152L71.225 27.5H53.9998V20ZM66.3623 18.5C67.9624 18.5 69.4085 19.4536 70.0388 20.9243L72.9024 27.6061C73.1852 28.2659 72.7012 29 71.9833 29H53.4998C52.9476 29 52.4998 28.5523 52.4998 28V19.5C52.4998 18.9477 52.9476 18.5 53.4998 18.5H66.3623Z"
        fill="#323232"
      />
      <path
        d="M33.5 44.75C33.5 44.3358 33.8358 44 34.25 44H46.75C47.1642 44 47.5 44.3358 47.5 44.75C47.5 45.1642 47.1642 45.5 46.75 45.5H34.25C33.8358 45.5 33.5 45.1642 33.5 44.75Z"
        fill="#323232"
      />
      <path
        d="M77.7223 27.79C77.1707 26.3191 78.258 24.75 79.829 24.75H81.5C82.7426 24.75 83.75 25.7574 83.75 27V39C83.75 40.2426 82.7426 41.25 81.5 41.25H79.25V32.544C79.25 32.0943 79.1691 31.6483 79.0112 31.2273L77.7223 27.79Z"
        stroke="#323232"
        strokeWidth="1.5"
      />
      <path
        d="M77.7223 27.79C77.1707 26.3191 78.258 24.75 79.829 24.75H81.5C82.7426 24.75 83.75 25.7574 83.75 27V39C83.75 40.2426 82.7426 41.25 81.5 41.25H79.25V32.544C79.25 32.0943 79.1691 31.6483 79.0112 31.2273L77.7223 27.79Z"
        fill="white"
        stroke="#323232"
        strokeWidth="1.5"
      />
    </svg>
  );
};
