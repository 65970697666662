import parse from 'html-react-parser';
import { FC } from 'react';
import style from './roadServiceTermsStyle.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

interface RoadServiceTermsProps {
  html?: string;
}
export const RoadServiceTerms: FC<RoadServiceTermsProps> = ({ html }) => {
  if (!html) return <></>;
  return (
    <RoundSheet>
      <p className={style['title']}>ロードサービスご利用規約</p>
      <div className={style['container']}>{parse(html)}</div>
    </RoundSheet>
  );
};
