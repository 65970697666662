import { BenefitType, Rank } from '@/consts/rank';
import { RankBenefit } from '@/types/api/rank';

export const RANK_BENEFITS: Record<Rank, RankBenefit[]> = {
  [Rank.REGULAR]: [],
  [Rank.BRONZE]: [
    {
      type: BenefitType.PREPAID_CHARGE,
      title: 'プリペイドボーナスチャージ率 5%',
      content:
        'チャージするたび自動でボーナスが貯まります！チャージされたタイミングからご利用可能です！',
      links: [],
    },
    {
      type: BenefitType.REFERRAL_BENEFIT,
      title: '紹介特典 JCB1万円プレゼント',
      content:
        'ご友人やご家族が紹介で車を購入いただくと、JCBギフトカードをプレゼント！',
      links: [],
    },
    {
      type: BenefitType.TRANSFER_BONUS,
      title: '乗換ボーナス 1万円査定UP',
      content: '車両入庫受付時にアプリ画面をスタッフにご提示ください。',
      links: [],
    },
  ],
  [Rank.SILVER]: [
    {
      type: BenefitType.PREPAID_CHARGE,
      title: 'プリペイドボーナスチャージ率 5%',
      content:
        'チャージするたび自動でボーナスが貯まります！チャージされたタイミングからご利用可能です！',
      links: [],
    },
    {
      type: BenefitType.REFERRAL_BENEFIT,
      title: '紹介特典 JCB2万円プレゼント',
      content:
        'ご友人やご家族が紹介で車を購入いただくと、JCBギフトカードをプレゼント！',
      links: [],
    },
    {
      type: BenefitType.TRANSFER_BONUS,
      title: '乗換ボーナス 2万円査定UP',
      content: '車両入庫受付時にアプリ画面をスタッフにご提示ください。',
      links: [],
    },
    {
      type: BenefitType.TIRE,
      title: 'タイヤ購入サポート 3%割引',
      content: '車両入庫受付時にアプリ画面をスタッフにご提示ください。',
      annotation:
        '割引適用できるタイヤは当社指定のものに限ります。詳しくは店頭スタッフまでお問い合わせください。',
      links: [],
    },
  ],
  [Rank.GOLD]: [
    {
      type: BenefitType.PREPAID_CHARGE,
      title: 'プリペイドボーナスチャージ率 5%',
      content:
        'チャージするたび自動でボーナスが貯まります！チャージされたタイミングからご利用可能です！',
      links: [],
    },
    {
      type: BenefitType.REFERRAL_BENEFIT,
      title: '紹介特典 JCB3万円プレゼント',
      content:
        'ご友人やご家族が紹介で車を購入いただくと、JCBギフトカードをプレゼント！',
      links: [],
    },
    {
      type: BenefitType.TRANSFER_BONUS,
      title: '乗換ボーナス 3万円査定UP',
      content: '車両入庫受付時にアプリ画面をスタッフにご提示ください。',
      links: [],
    },
    {
      type: BenefitType.TIRE,
      title: 'タイヤ購入サポート 5%割引',
      content: '車両入庫受付時にアプリ画面をスタッフにご提示ください',
      annotation:
        '割引適用できるタイヤは当社指定のものに限ります。詳しくは店頭スタッフまでお問い合わせください。',
      links: [],
    },
    {
      type: BenefitType.BIRTHDAY_GIFT,
      title: '誕生日特典 電池プレゼント',
      content:
        '車検、定期点検、オイル交換など車両入庫の受付時にクーポン画面をスタッフにご提示ください。',
      annotation: '封書の到着とクーポン発行のタイミングは異なります。',
      links: [],
    },
  ],
  [Rank.PLATINUM]: [
    {
      type: BenefitType.PREPAID_CHARGE,
      title: 'プリペイドボーナスチャージ率 8%',
      content:
        'チャージするたび自動でボーナスが貯まります！チャージされたタイミングからご利用可能です！',
      links: [],
    },
    {
      type: BenefitType.REFERRAL_BENEFIT,
      title: '紹介特典 JCB4万円プレゼント',
      content:
        'ご友人やご家族が紹介で車を購入いただくと、JCBギフトカードをプレゼント！',
      links: [],
    },
    {
      type: BenefitType.TRANSFER_BONUS,
      title: '乗換ボーナス 5万円査定UP',
      content:
        '車両入庫受付時にアプリ画面をスタッフにご提示ください。(車両価値5万円以上の車両のみ)',
      links: [],
    },
    {
      type: BenefitType.TIRE,
      title: 'タイヤ購入サポート 10%割引',
      content: '車両入庫受付時にアプリ画面をスタッフにご提示ください。',
      annotation:
        '割引適用できるタイヤは当社指定のものに限ります。詳しくは店頭スタッフまでお問い合わせください。',
      links: [],
    },
    {
      type: BenefitType.BIRTHDAY_GIFT,
      title: '誕生日特典 オイルエレメントプレゼント',
      content:
        '車検、定期点検、オイル交換など車両入庫の受付時にクーポン画面をスタッフにご提示ください。',
      annotation: '封書の到着とクーポン発行のタイミングは異なります。',
      links: [],
    },
  ],
  [Rank.DIAMOND]: [
    {
      type: BenefitType.PREPAID_CHARGE,
      title: 'プリペイドボーナスチャージ率 10%',
      content:
        'チャージするたび自動でボーナスが貯まります！チャージされたタイミングからご利用可能です！',
      links: [],
    },
    {
      type: BenefitType.REFERRAL_BENEFIT,
      title: '紹介特典 JCB5万円プレゼント',
      content:
        'ご友人やご家族が紹介で車を購入いただくと、JCBギフトカードをプレゼント！',
      links: [],
    },
    {
      type: BenefitType.TRANSFER_BONUS,
      title: '乗換ボーナス 10万円査定UP',
      content:
        '車両入庫受付時にアプリ画面をスタッフにご提示ください。(車両価値10万円以上の車両のみ)',
      links: [],
    },
    {
      type: BenefitType.TIRE,
      title: 'タイヤ購入サポート 15%割引',
      content: '車両入庫受付時にアプリ画面をスタッフにご提示ください。',
      annotation:
        '割引適用できるタイヤは当社指定のものに限ります。詳しくは店頭スタッフまでお問い合わせください。',
      links: [],
    },
    {
      type: BenefitType.BIRTHDAY_GIFT,
      title: '誕生日特典 フロントワイパーゴムプレゼント',
      content:
        '車検、定期点検、オイル交換など車両入庫の受付時にクーポン画面をスタッフにご提示ください。',
      annotation: '封書の到着とクーポン発行のタイミングは異なります。',
      links: [],
    },
  ],
};
