import { Block } from 'framework7-react';
import { FC } from 'react';
import { SupportCenterPhone } from '@/components/uiParts/SupportCenterPhone/SupportCenterPhone';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import './AlertPreventionStyle.scss';

export const AlertPrevention: FC = () => {
  return (
    <PageTemplate
      title="予防アラート"
      pageName="AlertPrevation"
      showNavBack={true}
    >
      <Block style={{ position: 'relative', height: '86vh' }}>
        <div className="display-flex flex-column-center-center">
          <div>アラート検出日: 1970/01/01</div>
          {/* <!-- 内容 --> */}
          <div className="alert-box info-card info-card-elevated u-margin-top-md u-padding-top-sm">
            <div className="alert-icon">
              <div className="circle alertLevel-0" />
              <div className="inner" />
            </div>
            <div>
              <h3>現在稼働中</h3>
              <p>現在平常通り動いております。</p>
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <SupportCenterPhone />
        </div>
      </Block>
    </PageTemplate>
  );
};
