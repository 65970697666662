import { InputProps } from 'framework7-react/components/input';
import { FC, useState } from 'react';
import styles from './paymentInput.module.css';
import { removeNonNumericLetters } from '@/utils/utils';

type PaymentInput = {
  name: string;
  value: string;
  placeholder: string;
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
interface PaymentInputProps extends InputProps {
  required?: boolean;
  annotation?: string;
  error?: string;
  setInputYen: any;
}

export const PaymentInput: FC<PaymentInputProps> = ({
  required = false,
  annotation = null,
  error = null,
  setInputYen,
}) => {
  const [useYen, setUseYen] = useState<number>();
  const changeYen = (e: number) => {
    if (e == 0) {
      setUseYen(undefined);
    } else {
      setUseYen(e);
    }
    setInputYen(e);
  };
  return (
    <li className="payment-input">
      <div
        className={`item-content item-input item-input-outline ${
          error && 'item-input-invalid item-input-with-error-message'
        }`}
      >
        <div className={`item-inner ${(annotation || error) && 'with-info'}`}>
          <div className={styles['item-title']}>
            お支払い額
            {required && <span className="item-label-required">(必須)</span>}
          </div>
          <div className="item-input-wrap yen">
            <input
              type="number"
              className="u-font-bold"
              style={{ fontSize: '3rem', height: '50px' }}
              maxLength={21}
              placeholder="0"
              value={useYen}
              onChange={(e) => {
                const _value = removeNonNumericLetters(e.target.value);
                changeYen(Number(_value));
              }}
            />
          </div>

          {error && <div className="item-input-error-message">{error}</div>}

          {annotation && <div className="item-input-info">{annotation}</div>}
        </div>
      </div>
    </li>
  );
};
