import { Col, Row } from 'framework7-react';
import { FC } from 'react';
import { Button } from '../../Button';
import style from './UncompletedCardStyle.module.css';

interface UncompletedCardProps {
  cardHead: string;
  cardBody: string;
  annotation?: string;
  buttonText: string;
  handleClickAction: () => void;
  isInner?: boolean;
}

export const UncompletedCard: FC<UncompletedCardProps> = ({
  cardHead,
  cardBody,
  annotation,
  buttonText,
  handleClickAction,
  isInner = false,
}) => {
  return (
    <div
      className={`${style.card} ${
        isInner ? style['inner-card'] : style['outside-card']
      }`}
    >
      <div className={style.title}>
        <i className="icon-notification-settings" />
        <p>{cardHead}</p>
      </div>
      <div style={{ width: '100%', height: '16px' }} />
      <p className={style.description}>{cardBody}</p>
      <div style={{ width: '100%', height: '16px' }} />
      <Row className="">
        <Col width="15" />
        <Col width="70">
          <Button
            text={buttonText}
            handleClickAction={handleClickAction}
            style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
          />
        </Col>
        <Col width="15" />
      </Row>
      <div style={{ width: '100%', height: '8px' }} />
      {annotation && <p className={style.annotation}>{annotation}</p>}
    </div>
  );
};
