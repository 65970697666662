import { FC } from 'react';
import { RoundButtonWithIcon } from '@/components/uiParts/Button/RoundButtonWithIcon/RoundButtonWithIcon';
import { supportCenter } from '@/consts/inquiry';

const tel = supportCenter.TEL;
interface CallSupportButtonProps {
  telNumber?: string;
}
export const CallSupportButton: FC<CallSupportButtonProps> = ({
  telNumber = tel,
}) => {
  const handleClickButton = () => {
    window.location.href = `tel://${telNumber}`;
  };
  return (
    <RoundButtonWithIcon
      iconClass="icon-telephone"
      handleClickAction={handleClickButton}
    >
      サポートセンターに電話する
    </RoundButtonWithIcon>
  );
};
