import { baseApi } from '@/config/axios';
import {
  AdminAuthApiParams,
  AdminAuthApiResponse,
  CheckFirstLoginApiParams,
  CheckFirstLoginApiResponse,
  CountLoginApiApiResponse,
  CountLoginApiParams,
  EmailFirstLoginDecisionApiParams,
  EmailFirstLoginDecisionApiResponse,
  LoginApiParams,
  LoginApiResponse,
  LoginByNativeApiParams,
  LoginByNativeApiResponse,
  MobileAuthApiParams,
  MobileAuthApiResponse,
  MultipleAffiliateInfoApiParams,
  MultipleAffiliateInfoApiResponse,
  PasswordResetApiParams,
  PasswordResetApiResponse,
  PinAuthApiParams,
  PinAuthApiResponse,
  RequestPasswordResetApiParams,
  RequestPasswordResetApiResponse,
  SnsLoginApiParams,
  SnsLoginApiResponse,
} from '@/types/api/authApi';

// CSRF保護の初期化
export const sanctumApi = () => baseApi.get('sanctum/csrf-cookie');

// 1.ログイン
export const loginApi = (params: LoginApiParams): Promise<LoginApiResponse> =>
  baseApi.post('v1/customer/login', params);

// 2.携帯電話番号認証
export const mobileAuthApi = async (
  params: MobileAuthApiParams,
): Promise<MobileAuthApiResponse> => {
  await sanctumApi();
  return baseApi.post(`v1/customer/mobile-auth`, params);
};

// 3.暗証番号認証
export const pinAuthApi = async (
  params: PinAuthApiParams,
): Promise<PinAuthApiResponse> => {
  await sanctumApi();
  return baseApi.post(`v1/customer/pin-auth`, params);
};

// 39.PWリセット
export const requestPasswordResetApi = (
  params: RequestPasswordResetApiParams,
): Promise<RequestPasswordResetApiResponse> =>
  baseApi.post(`v1/customer/request-pw-reset`, params);

// 41.PWリセット（本人確認）
export const passwordResetApi = (
  params: PasswordResetApiParams,
): Promise<PasswordResetApiResponse> =>
  baseApi.post(`v1/customer/password-reset`, params);

// 69.メールアドレス初回ログイン判定
export const emailFirstLoginDecisionApi = async (
  params: EmailFirstLoginDecisionApiParams,
): Promise<EmailFirstLoginDecisionApiResponse> => {
  await sanctumApi();
  return baseApi.get('/v1/customer/email-first-login-decision', { params });
};

// 78.携帯電話番号初回ログイン判定
export const checkFirstLoginApi = (
  params: CheckFirstLoginApiParams,
): Promise<CheckFirstLoginApiResponse> =>
  baseApi.get('v1/customer/telephone-first-login-decision', { params });

// 91.ログインカウント
export const countLoginApi = (
  params: CountLoginApiParams,
): Promise<CountLoginApiApiResponse> =>
  baseApi.post('v1/customer/login-count', params);

// 97.token発行
export const loginByNativeApi = (
  params: LoginByNativeApiParams,
): Promise<LoginByNativeApiResponse> =>
  baseApi.post('v1/customer/token/create', params);

// 99.トークン発行(管理者)
export const adminAuthApi = async (
  params: AdminAuthApiParams,
): Promise<AdminAuthApiResponse> => {
  await sanctumApi();
  return baseApi.post('v1/customer/token/create-admin-auth', params);
};

// 107.顧客情報取得
export const multipleAffiliateInfo = (
  params: MultipleAffiliateInfoApiParams,
): Promise<MultipleAffiliateInfoApiResponse> =>
  baseApi.get('v1/customer/multiple-affiliate-info', { params });

// OAuth
export const snsLoginApi = async (
  params: SnsLoginApiParams,
): Promise<SnsLoginApiResponse> => {
  await sanctumApi();
  return baseApi.post('v1/customer/oauth/login', params);
};
