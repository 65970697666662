import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { customerInfoApi } from '@/api/customerApi';
import { chargePrepaidApi } from '@/api/prepaidApi';
import {
  HousePrepaidChargeParams,
  HousePrepaidChargeResponse,
} from '@/types/api/prepaidApi';
import { storeDispatch } from '@/utils/store';

export const useHouseprepaidCharge = (customerId: number) => {
  const [data, setDate] = useState<HousePrepaidChargeResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const chargePrepaid = async (params: HousePrepaidChargeParams) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const data = await chargePrepaidApi(params);
      const { data: customerInfo } = await customerInfoApi({
        m_customer_id: customerId,
      });
      if (!customerInfo.success) {
        throw new Error('基礎情報取得に失敗');
      }
      await storeDispatch(
        'setCampaignsInfo',
        'campaignsInfo',
        customerInfo.campaigns ?? [],
      );
      await storeDispatch(
        'setAdvancePaymentsInfo',
        'advancePaymentsInfo',
        customerInfo.prepaids,
      );
      setDate(data.data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    chargePrepaid,
    data,
    isLoading,
    error,
  };
};
