import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './SelectMonthsStyle.module.css';

interface SelectMonthsProps {
  defaultMonths: number;
}

export const SelectMonths: FC<SelectMonthsProps> = ({ defaultMonths }) => {
  const NUMBER_OF_MONTHS = 12;
  const OFFSET = 1;
  const { register } = useFormContext();
  return (
    <div className="input-dropdown">
      <select
        defaultValue={defaultMonths}
        className={style.select}
        {...register('month')}
      >
        {[...Array(NUMBER_OF_MONTHS)].map((month, idx) => (
          <option key={idx} value={idx + OFFSET}>{`${idx + OFFSET}月`}</option>
        ))}
      </select>
    </div>
  );
};
