import { FC } from 'react';
import style from './TableListStyle.module.css';

interface SupplementStyle {
  fontSize?: string;
  color?: string;
}

interface TableListProps {
  head: string;
  body: string | undefined;
  supplement?: string;
  supplementStyle?: SupplementStyle;
  isBetween?: boolean;
  bodyColor?: string;
}

export const TableList: FC<TableListProps> = ({
  head,
  body,
  supplement = undefined,
  supplementStyle = undefined,
  isBetween = false,
  bodyColor = '#323232',
}) => {
  return (
    <li className={style.list}>
      <div className={style['head-wrap']}>
        <h3 className={style.head}>{head}</h3>
      </div>
      <div
        className={`${style['body-wrap']} ${style['align-self-center']} ${
          isBetween && style['text-right']
        }`}
      >
        <p style={{ color: bodyColor }} className={style.body}>
          {body}
        </p>
        {supplement && (
          <span style={supplementStyle} className={style.supplement}>
            {supplement}
          </span>
        )}
      </div>
    </li>
  );
};
