import { Router } from 'framework7/types';
import {
  ConsultingHistoryPage,
  DeliveryCheckListPage,
  NewsDetailPage,
  NewsPage,
  UserTasksPage,
  NewsWarrantyExpriedPage,
} from '@/components/pages';
import { CarWashPage } from '@/components/pages/CarWash/CarWashPage';
import { CarWashConditionerPage } from '@/components/pages/CarWashConditioner/CarWashConditionerPage';
import { CarWashConditionerAboutPage } from '@/components/pages/CarWashConditionerAbout/CarWashConditionerAboutPage';
import { CarWashConditionerAboutPopup } from '@/components/pages/CarWashConditionerAbout/CarWashConditionerAboutPopup';
import { CarWashHandPage } from '@/components/pages/CarWashHand/CarWashHandPage';
import { CarWashHandAboutPage } from '@/components/pages/CarWashHandAbout/CarWashHandAboutPage';
import { CarWashHandAboutPopup } from '@/components/pages/CarWashHandAbout/CarWashHandAboutPopup';
import { CarwashCancelReasonPage } from '@/components/pages/CarwashCancelReason/CarwashCancelReasonPage';
import { CarwashDetailPage } from '@/components/pages/CarwashDetail/CarwashDetailPage';
import { CarwashEditPage } from '@/components/pages/CarwashEdit/CarwashEditPage';
import { CarwashEditSuccessPage } from '@/components/pages/CarwashEditSuccess/CarwashEditSuccessPage';
import { MaintenanceGroup } from '@/components/pages/MaintenanceGroup/MaintenanceGroup';
import { tabsNews } from '@/components/pages/News/Tabs/tabsNews';
import { NoticeDetailDummy1 } from '@/components/pages/NoticeDetailDummy/NoticeDetailDummy1';
import { NoticeDetailDummy2 } from '@/components/pages/NoticeDetailDummy/NoticeDetailDummy2';
import { ReservationHistoryPage } from '@/components/pages/ReservationHistory/ReservationHistoryPage';
import { TodoDetailPage } from '@/components/pages/TodoDetail/TodoDetailPage';
import { TodoInstructionPage } from '@/components/pages/TodoInstruction/TodoInstructionPage';
import { paths } from '@/config/paths';

export const noticeRoutes: Router.RouteParameters[] = [
  {
    path: paths.reservationHistory,
    component: ReservationHistoryPage,
    name: '予約履歴',
  },
  {
    path: paths.carWash,
    component: CarWashPage,
    name: '洗車・コーティング',
  },
  {
    path: paths.carWashStatus(':status'),
    component: CarWashPage,
    name: '洗車・コーティング',
  },
  {
    path: paths.carWashConditioner,
    component: CarWashConditionerPage,
    name: '出張カーエアコンクリーニング',
  },
  {
    path: paths.carWashConditionerAbout,
    popup: {
      component: CarWashConditionerAboutPopup,
    },
    options: {
      animate: true,
    },
    name: '出張カーエアコンクリーニング',
  },
  {
    path: paths.carWashConditionerAboutFull,
    component: CarWashConditionerAboutPage,
    options: {
      animate: true,
      props: {
        showLink: false,
      },
    },
    name: '出張カーエアコンクリーニング',
  },
  {
    path: paths.carWashConditionerStatus(':status'),
    component: CarWashConditionerPage,
    name: '出張カーエアコンクリーニング',
  },
  {
    path: paths.carWashHand,
    component: CarWashHandPage,
    name: '出張水なし手洗い洗車',
  },
  {
    path: paths.carWashHandAbout,
    popup: {
      component: CarWashHandAboutPopup,
    },
    options: {
      animate: true,
    },
    name: '出張水なし手洗い洗車',
  },
  {
    path: paths.carWashHandAboutFull,
    component: CarWashHandAboutPage,
    options: {
      animate: true,
      props: {
        showLink: false,
      },
    },
    name: '出張水なし手洗い洗車',
  },
  {
    path: paths.carWashHandStatus(':status'),
    component: CarWashHandPage,
    name: '出張水なし手洗い洗車',
  },
  {
    path: paths.carwashDetail(':id'),
    component: CarwashDetailPage,
    name: '予約履歴',
  },
  {
    path: paths.carwashEdit(':id'),
    popup: {
      component: CarwashEditPage,
    },
    options: {
      animate: true,
    },
    name: '日程変更',
  },
  {
    path: paths.carwashEditSuccess(':id'),
    popup: {
      component: CarwashEditSuccessPage,
    },
    options: {
      animate: true,
    },
    name: '予約の変更を受付ました',
  },
  {
    path: paths.carwashCancelReason(':id'),
    popup: {
      component: CarwashCancelReasonPage,
    },
    options: {
      animate: true,
    },
    name: '予約の取り消し',
  },
  {
    path: '/news/',
    component: NewsPage,
    name: 'お知らせ一覧',
    tabs: tabsNews,
  },
  {
    path: paths.noticeDetail(':id'),
    component: NewsDetailPage,
    name: 'お知らせ詳細',
  },
  {
    path: paths.todoDetail(':id'),
    component: TodoDetailPage,
    name: 'やることリスト',
  },
  {
    path: paths.todoInstruction(':id'),
    component: TodoInstructionPage,
    name: 'やることリスト',
  },
  {
    path: '/notice_detail_dummy1/',
    component: NoticeDetailDummy1,
    name: 'お知らせダミ1',
  },
  {
    path: '/notice_detail_dummy2/',
    component: NoticeDetailDummy2,
    name: 'お知らせダミ2',
  },
  {
    path: paths.consultingHistory(':id'),
    component: ConsultingHistoryPage,
    name: '問い合わせ履歴詳細',
  },
  {
    path: paths.deliveryCheckList,
    component: DeliveryCheckListPage,
    name: '納車チェック',
  },
  {
    path: paths.userTasks,
    popup: {
      component: UserTasksPage,
    },
    name: 'ユーザタスク',
  },
  {
    path: paths.noticeWarrantyDetail,
    component: NewsWarrantyExpriedPage,
    name: 'お知らせ',
  },
  {
    path: paths.maintenance,
    component: MaintenanceGroup,
    name: 'お知らせ',
  },
];
