import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from 'framework7-react';
import React, { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CautionCard } from '../CautionCard/CautionCard';
import style from './EditEmailStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { RhfInput } from '@/components/uiParts/ReactHookForm/RhfInput/RhfInput';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { AuthInfoState } from '@/config/initialState';
import { store } from '@/config/store';
import { loginType as LOGIN_TYPE } from '@/consts/login';
import { useCustomerInfo } from '@/hooks/api/customer/useCustomerInfo';
import { useUpdateEmail } from '@/hooks/api/customer/useUpdateEmail';
import { useUpdateUserInformation } from '@/hooks/api/linkDrive/useUpdateUserInformation';
import { LoginType } from '@/types/login';
import { f7CustomBack } from '@/utils/utils';

interface FormInput {
  email: string;
}

interface EditEmailProps {
  loginType: LoginType;
}

export const EditEmail: FC<EditEmailProps> = ({ loginType }) => {
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const emailSchema = yup.object().shape({
    email: yup.string().required().email(),
  });
  const formMethods = useForm<FormInput>({
    mode: 'onBlur',
    defaultValues: { email: '' },
    resolver: yupResolver(emailSchema),
  });
  const { handleSubmit } = formMethods;
  const { updateEmail } = useUpdateEmail();
  const { updateLinkDriveUser } = useUpdateUserInformation();
  const { setCustomerInfo } = useCustomerInfo({
    m_customer_id: authInfo.m_customer_id,
  });
  const onSubmit: SubmitHandler<FormInput> = ({ email }) => {
    const mCustomerId = store.state.authInfo.m_customer_id;
    const linkDriveOwnerId = store.state.linkDriveOwnerInfo.owner_id;
    const updateEmailParams = {
      m_customer_id: mCustomerId,
      mail_address: email,
    };
    const updateLinkDriveUserParams = {
      owner_id: linkDriveOwnerId,
      mailaddress: email,
    };
    updateEmail({
      params: updateEmailParams,
      onSuccess: async () => {
        // LinkDriveを連携している場合は
        // LinkDriveのユーザ情報を更新する（メールアドレスのみ）
        if (linkDriveOwnerId) {
          updateLinkDriveUser({ params: updateLinkDriveUserParams });
        }
        await setCustomerInfo();
        f7CustomBack(['#editEmailPopUp']);
      },
      onFailure: () => {
        throw new Error('メールアドレスの変更に失敗しました');
      },
    });
  };

  return (
    <PopupPageTemplate
      id="editEmailPopUp"
      pageName="EditEmail"
      title="メールアドレス変更"
    >
      {loginType === LOGIN_TYPE.EMAIL && <CautionCard />}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={style['label-wrap']}>
            <p className={style.label}>新しいメールアドレス</p>
            <span className={style['label-tips']}>必須</span>
          </div>
          <div className={style['input-wrap']}>
            <RhfInput
              className={style.input}
              name="email"
              type="email"
              placeholder="abcd@test.jp"
            />
            <div>
              <CheckIcon isValid={false} />
            </div>
          </div>
          <span className={style.supplement}>認証用のメールが送信されます</span>

          <div className={style['button-wrap']}>
            <Button text="変更する" type="submit" />
          </div>
        </form>
      </FormProvider>
    </PopupPageTemplate>
  );
};
