import { Popup } from 'framework7-react';
import { FC } from 'react';
import style from './UploadConfirmDialogStyle.module.css';
import { Button } from '@/components/uiParts/Button';

interface UploadConfirmDialog {
  open: boolean;
  onClose: () => void;
  onUpload: () => void;
}

export const UploadConfirmDialog: FC<UploadConfirmDialog> = ({
  open,
  onClose,
  onUpload,
}) => {
  return (
    <Popup
      opened={open}
      onPopupClosed={onClose}
      animate={false}
      closeByBackdropClick={false}
      className={style['popup']}
    >
      <div className={style['container']}>
        <div className={style['title']}>書類をアップロードしますか？</div>
        <div className={style['content']}>
          一度アップロードされた書類は後から変更・削除することができません。
        </div>
        <div className={style['button-group']}>
          <Button
            style={{
              backgroundColor: '#EFEFEF',
              color: '#323232',
            }}
            onClick={onClose}
          >
            キャンセル
          </Button>
          <Button onClick={onUpload}>アップロード</Button>
        </div>
      </div>
    </Popup>
  );
};
