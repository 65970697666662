import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useCallback, useEffect, useState } from 'react';
import {
  BillInformation,
  Customer,
  PaymentMethod,
  Prepaid,
} from '@/types/api/customerApi';
import {
  PrepaidSettingsBill,
  getPrepaidSettingsBillInfo,
} from '@/utils/prepaid';

export const usePrepaidSetting = (
  customerInfo?: Required<Customer>,
  billsInfo?: Required<BillInformation>[],
  firstPrepaid?: Required<Prepaid>,
  paymentMethods?: PaymentMethod[],
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [prepaid, setPrepaid] = useState<Prepaid>();
  const [bill, setBill] = useState<PrepaidSettingsBill>();
  const [payment, setPayment] = useState<PaymentMethod>();

  const callCustomerInfoApi = useCallback(async () => {
    try {
      if (!customerInfo) return;
      f7.preloader.show();
      setIsLoading(true);
      if (billsInfo) {
        const bill = getPrepaidSettingsBillInfo(billsInfo, firstPrepaid);
        setBill(bill);
      }
      if (paymentMethods) {
        const _payment = paymentMethods.find(
          (method) =>
            method.m_payment_method_id === firstPrepaid?.payment_method_id &&
            method.m_payment_type_id === firstPrepaid?.payment_type_id,
        );
        setPayment(_payment);
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
      }
      setError(e as AxiosError);
      f7.views.main.router.navigate('/');
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  }, [billsInfo, customerInfo, firstPrepaid, payment, paymentMethods]);

  useEffect(() => {
    void callCustomerInfoApi();
  }, [callCustomerInfoApi]);

  return {
    isLoading,
    error,
    prepaid,
    bill,
    payment,
  };
};
