import { yupResolver } from '@hookform/resolvers/yup';
import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormInput, SetPassword } from './SetPassword/SetPassword';
import { setPasswordSchema } from './setPasswordSchema';
import { loginByNativeApi, passwordResetApi } from '@/api/authApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { loginType } from '@/consts/login';
import { useToast } from '@/hooks/useToast';
import { LoginByNativeApiParams } from '@/types/api/authApi';
import {
  addAffiliateIdToRequestParams,
  getAffiliateIdFromThemeId,
} from '@/utils/shop';
interface StrikeNoticeSettingsProps {
  f7router: Router.Router;
  dest: string;
}

export const SetPasswordPage: FC<StrikeNoticeSettingsProps> = ({
  f7router,
  dest,
}) => {
  const { openToast } = useToast();
  const themeId = store.state.themeId;
  const formMethods = useForm<FormInput>({
    mode: 'onChange',
    defaultValues: { password: '', confirmation: '' },
    resolver: yupResolver(setPasswordSchema),
  });

  const isSuccessPerformLogin = async (mail: string, pass: string) => {
    const params: LoginByNativeApiParams = {
      login_id: mail,
      login_pass: pass,
      token_abilities: [],
    };
    addAffiliateIdToRequestParams(getAffiliateIdFromThemeId(themeId), params);
    const { data } = await loginByNativeApi(params);
    if (!data.success) {
      openToast('認証に失敗しました', 'toast-failed');
      return false;
    }

    store.dispatch('setAuthInfo', {
      authInfo: {
        m_customer_id: data.m_customers.id,
        token: decodeURI(data.token),
        isLogin: true,
      },
    });
    store.dispatch('setLoginInfo', {
      loginInfo: {
        email: mail,
        loginType: loginType.EMAIL,
      },
    });
    return true;
  };

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const authInfo = store.state.authInfo;
    const token = authInfo.token;
    if (!token) {
      return openToast('トークンがありません', 'toast-failed');
    }

    const params = {
      token: token,
      new_password: data.password,
    };

    const response = await passwordResetApi(params);
    if (!response.data.success) {
      return openToast(
        response?.data?.error?.message ||
          response?.data?.message ||
          'パスワードの設定でエラーが発生しました',
        'toast-failed',
      );
    }

    if (response.data.success) {
      const email = response.data.data.email;
      // 認証結果OKの場合、既存ユーザ（パスワードリセット）はホーム画面へ、
      // 新規ユーザ（パスワード初回設定）は利用規約同意画面へ。
      if (!email) {
        return openToast('メールアドレスがありません', 'toast-failed');
      }

      if (await isSuccessPerformLogin(email, data.password)) {
        if (dest == 'pwsetting') {
          f7.view.main.router.navigate(paths.confirmTerms, { animate: true });
        } else if (dest == 'pwreset') {
          f7.view.main.router.navigate(paths.home, { animate: true });
        }
      }
    }
  };

  const alertError = (message?: string) => {
    f7.dialog.alert(
      message || 'パスワードの設定でエラーが発生しました',
      'パスワード設定に失敗しました',
      () => {},
    );
  };

  return <SetPassword onSubmit={onSubmit} formMethods={formMethods} />;
};
