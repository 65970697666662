import { format, parse } from 'date-fns';
import { Block, Page, f7, useStore } from 'framework7-react';
import { FC, useMemo } from 'react';
import { getHistoryData } from './DriveRecordHistoryHelper';
import { HistoryListItem } from '@/components/projects/HistoryListItem/HistoryListItem';
import { AuthInfoState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useHistory } from '@/hooks/api/driveRecord/useHistory';
import { HistoryParams } from '@/types/api/driveRecordApi';
import { LinkDriveOwner } from '@/types/api/infoApi';
import { hyphenToDay } from '@/utils/date';
import './DriveRecordHistoryStyle.scss';

interface DriveRecordHistoryProps {}

export const DriveRecordHistory: FC<DriveRecordHistoryProps> = () => {
  const linkDriveOwnerInfo = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const diagnosisDate = useStore(store, 'getDiagnosticDate') as string;
  const ownerId = linkDriveOwnerInfo.owner_id;
  const mCustomerId = authInfo.m_customer_id;

  const historyParams: HistoryParams = useMemo(() => {
    const convertedDiagnosisDate = diagnosisDate
      ? parse(diagnosisDate, 'yyyy-MM-dd', new Date())
      : '';
    return {
      owner_id: ownerId,
      m_customer_id: mCustomerId,
      diagnosis_date: convertedDiagnosisDate
        ? format(convertedDiagnosisDate, 'yyyy-MM-dd')
        : '',
    };
  }, [diagnosisDate, mCustomerId, ownerId]);

  const { data, filterData, isLoading, isNoData } = useHistory(historyParams);
  const historyList = getHistoryData(data?.data);

  const handleClickDriveCalender = () => {
    f7.view.main.router.navigate(paths.driveCalender);
  };

  return (
    <Page name="History" pageContent={false} className="page">
      {/*  カレンダーボタン */}
      <div />
      <div className="calendar-box" onClick={handleClickDriveCalender}>
        <div className="calendar-box-selectedDate">
          {filterData ? (
            <span>絞り込み：{hyphenToDay(diagnosisDate, false)}</span>
          ) : (
            <span>カレンダーで日付を絞り込み</span>
          )}
        </div>
        <div>
          <i
            className="icon-calendar-schedule"
            style={{ color: isLoading ? '#ebeced' : '' }}
          />
        </div>
      </div>
      {!isNoData && (
        <Block>
          {historyList?.map((item, index: number) => (
            <div key={index}>
              {item.oneDayFlag && (
                <div className="drive-history-date-title u-text-center">
                  <span>{item.oneDayFlag}</span>
                </div>
              )}
              <HistoryListItem
                driving_duration_start={item.driving_duration_start ?? ''}
                driving_duration_end={item.driving_duration_end ?? ''}
                driving_time={item.driving_time ?? 0}
                hours={item.hours ?? 0}
                minutes={item.minutes ?? 0}
                driving_distance={item.driving_distance ?? 0}
                fuel_efficiency={item.fuel_efficiency ?? 0}
                phyd_score_level={item.phyd_score_level ?? ''}
                status={item.status ?? ''}
                id={item.id ?? 0}
              />
            </div>
          ))}
        </Block>
      )}

      {isNoData && (
        <Block>
          <div className="no-data u-text-center u-font-size-xxl">
            直近の走行データはありません。
            <br />
            カレンダーから過去の走行データ
            <br />
            を確認できます。
          </div>
        </Block>
      )}
    </Page>
  );
};
