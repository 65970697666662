import { FC } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { CalibrationProcessBar } from '../CalibrationProcessBar/CalibrationProcessBar';
import style from './LinkDriveCalibrationStep2Style.module.css';
import CarIcon from '@/assets/images/car-light.svg';

interface LinkDriveCalibrationStep2Props {
  progress: number;
}

export const LinkDriveCalibrationStep2: FC<LinkDriveCalibrationStep2Props> = ({
  progress,
}) => {
  return (
    <div className={`${style['step2']}`}>
      <CalibrationProcessBar step={2} />
      <h2 className={style.heading}>
        キャリブレーションの計測を開始しています。
      </h2>
      <div className={style.loading}>
        <CircularProgressbarWithChildren strokeWidth={5} value={progress}>
          <div className={style['progress-inner']}>
            <img src={CarIcon} />
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <div className="u-margin-top-sm">
        <p className={style['processing-statement']}>
          キャリブレーション計測中
        </p>
      </div>
    </div>
  );
};
