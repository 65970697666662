import { f7 } from 'framework7-react';
import { deliveryCompleteFlg } from '@/consts/car';
import { Car } from '@/types/api/customerApi';

export const openAlertWarrantyExpried = () => {
  f7.dialog.alert('現在、ご利用可能な保証はございません。', ' エラー');
};

export const isDelivered = (deliveryFlg: number | string | undefined) => {
  return deliveryFlg !== deliveryCompleteFlg.BEFORE_DELIVERY;
};

// 車両輸送名
export const generateTransportName = (linkCar?: Car) => {
  return [
    linkCar?.transport_name,
    linkCar?.class_no,
    linkCar?.hiragana,
    linkCar?.registry_no,
  ]
    .filter((i) => !!i)
    .join(' ');
};
