import { FC } from 'react';
import { AlertResult } from '../AlertResult/AlertResult';
import { DrivingResult } from '../DrivingResult/DrivingResult';
import { MonthlyDriveScore } from '../MonthlyDriveScore/MonthlyDriveScore';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { AffiliateId } from '@/consts/shop';
import { useWatchingServiceMonthlyDriveReport } from '@/hooks/useWatchingServiceMonthlyDriveReport';

interface WatchingMonthlyDriveReportProps {
  ownerId: string;
  vehicleId: string;
  customerId: string;
  analysisFromDate: string;
  affiliateId: AffiliateId;
}

export const WatchingMonthlyDriveReport: FC<
  WatchingMonthlyDriveReportProps
> = ({ ownerId, vehicleId, customerId, analysisFromDate, affiliateId }) => {
  const { isLoading, data: monthlyDriveReports } =
    useWatchingServiceMonthlyDriveReport(
      ownerId,
      vehicleId,
      customerId,
      analysisFromDate,
      affiliateId,
    );

  const monthlyDriveScore = {
    reportDate: monthlyDriveReports.reportDate,
    averageScore: monthlyDriveReports.averageScore,
    speedStarScore: monthlyDriveReports.stars_speed,
    accelerationStarScore: monthlyDriveReports.stars_acceleration,
    decelerationStarScore: monthlyDriveReports.stars_deceleration,
    scoreComment: monthlyDriveReports.scoreComment,
    monthlyDriveReportXAxis: monthlyDriveReports.createMonthly,
    monthlyDriveReportYAxis: monthlyDriveReports.yearScoreList,
  };

  const alertResult = {
    speedExceededTimes: monthlyDriveReports.eventSpeed,
    averageSpeedExceededTimes: monthlyDriveReports.averageEventSpeed,
    acceleratedTimes: monthlyDriveReports.eventAcceralatedStability,
    averageAcceleratedTimes:
      monthlyDriveReports.averageEventAcceralatedStability,
    acceleratedTimesPerDay: monthlyDriveReports.suddenStartDataList,
    deceleratedTimes: monthlyDriveReports.eventDowndecelerateStability,
    averageDeceleratedTimes:
      monthlyDriveReports.averageEventDowndecelerateStability,
    deceleratedTimesPerDay: monthlyDriveReports.suddenBrakingDataList,
    dayList: monthlyDriveReports.dayList,
  };

  const drivingResult = {
    drivingCount: monthlyDriveReports.tripCount,
    resultValueA: monthlyDriveReports.resultValueA,
    resultValueB: monthlyDriveReports.resultValueB,
    resultValueC: monthlyDriveReports.resultValueC,
    drivingDistance: monthlyDriveReports.drivingDistance,
    averageDrivingDistance: monthlyDriveReports.averageDrivingDistance,
    drivingHours: monthlyDriveReports.drivingHours,
    drivingMinutes: monthlyDriveReports.drivingMinutes,
    averageDrivingHours: monthlyDriveReports.averageDrivingHours,
    averageDrivingMinutes: monthlyDriveReports.averageDrivingMinutes,
  };

  return (
    <PageTemplate
      pageName="WatchingMonthlyDriveReport"
      title="見守り者向けマンスリーレポート"
      showNavBack
    >
      {!isLoading && (
        <>
          <MonthlyDriveScore monthlyDriveScore={monthlyDriveScore} />
          <AlertResult alertResult={alertResult} />
          <DrivingResult drivingResult={drivingResult} />
        </>
      )}
    </PageTemplate>
  );
};
