import { FC } from 'react';
import { DrivingAnalysisServiceAgree } from './DrivingAnalysisServiceAgree/DrivingAnalysisServiceAgree';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const DrivingAnalysisServiceAgreePage: FC = () => {
  const { data } = useTerm(termType.SCORE);
  const html = data?.term;
  return <DrivingAnalysisServiceAgree html={html} />;
};
