import { Col, Row } from 'framework7-react';
import { FC, useState } from 'react';
import { Label } from '../Label/Label';
import { RhfInput } from '../ReactHookForm/RhfInput/RhfInput';

interface BirthdayInputProps {
  required?: boolean;
  annotation?: string;
  error?: string;
}

export const BirthdayInput: FC<BirthdayInputProps> = ({
  required = false,
  annotation = null,
  error = null,
}) => {
  const [inputYear, setInputYear] = useState('');
  const [inputMonth, setInputMonth] = useState('');
  const [inputDay, setInputDay] = useState('');

  return (
    <li className="birthday-input">
      <div
        className={`item-content item-input item-input-outline ${
          error && 'item-input-invalid item-input-with-error-message'
        }`}
      >
        <div className={`item-inner ${(annotation || error) && 'with-info'}`}>
          <div className="item-title item-label">
            生年月日
            <Label required={required} />
          </div>

          <Row>
            <Col width="40">
              <div className="item-input-wrap year">
                <RhfInput
                  name="birthYear"
                  type="number"
                  placeholder="1900"
                  value={inputYear}
                  onChange={(e) => setInputYear(e.target.value)}
                />
              </div>
            </Col>

            <Col width="30">
              <div className="item-input-wrap month">
                <RhfInput
                  name="birthMonth"
                  type="number"
                  placeholder="1"
                  value={inputMonth}
                  onChange={(e) => setInputMonth(e.target.value)}
                />
              </div>
            </Col>

            <Col width="30">
              <div className="item-input-wrap day">
                <RhfInput
                  name="birthDay"
                  type="number"
                  placeholder="1"
                  value={inputDay}
                  onChange={(e) => setInputDay(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          {error && <div className="item-input-error-message">{error}</div>}

          {annotation && <div className="item-input-info">{annotation}</div>}
        </div>
      </div>
    </li>
  );
};
