import { Link, Navbar, NavLeft, NavTitle, Page } from 'framework7-react';
import { FC, ReactNode } from 'react';
import style from './loginPageTemplateStyle.module.css';
import { store } from '@/config/store';
import { switchShopTheme } from '@/utils/shop';

interface LoginPageTemplateProps {
  pageName: string;
  children: ReactNode;
  navLeft?: ReactNode;
  showNavBack?: boolean;
  title?: string;
}
export const LoginPageTemplate: FC<LoginPageTemplateProps> = ({
  pageName,
  children,
  navLeft,
  showNavBack = false,
  title = '',
}) => {
  const themeId = store.state.themeId;
  const shopThemeObj = switchShopTheme(themeId);
  const pageStyle = { backgroundColor: shopThemeObj.mainColor };

  const stripeBackground = {
    '--stripe-image-url': `url(/images/theme/${shopThemeObj.homeWrapperSubBg})`,
    '--stripe-image-url-pc': `url(/images/theme/${shopThemeObj.homeWrapperSubBgPc})`,
  } as React.CSSProperties;
  const background = {
    backgroundColor: shopThemeObj.mainBgColor,
  };

  const navbarsElement = document.getElementsByClassName('navbars')[0];
  if (navbarsElement) {
    navbarsElement.setAttribute('style', '');
  }

  return (
    <Page name={pageName} className={style['page']} style={pageStyle}>
      <Navbar className={style['navBar']} noShadow noHairline>
        {showNavBack && (
          <NavLeft>
            <Link back className={`${style['no-ripple']} no-ripple`}>
              <div className={style['icon-wrapper']}>
                <i className={`${style['nav-icon']} icon-left no-ripple`} />
              </div>
            </Link>
          </NavLeft>
        )}
        {!showNavBack && navLeft}
        <NavTitle>{title}</NavTitle>
      </Navbar>
      <div className={style['container']}>{children}</div>
      <div className={style['bottom-box']} style={background}>
        <div className={style['background-stripe']} style={stripeBackground} />
      </div>
      <div className={style['space']} style={background} />
    </Page>
  );
};
