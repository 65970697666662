import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './SignInListStyle.module.css';

interface SignInListProps {
  head: string;
  specificValue: string;
  path: string;
}

export const SignInList: FC<SignInListProps> = ({
  head,
  specificValue,
  path,
}) => {
  return (
    <li className={style.list}>
      <a
        onClick={() => f7.views.main.router.navigate(path)}
        className={style['list-link']}
      >
        <div className={style.grid}>
          <div className={style.head}>{head}</div>
          <div className={style.specific}>{specificValue}</div>
          <div className={style.icon}>
            <i className="icon-right" />
          </div>
        </div>
      </a>
    </li>
  );
};
