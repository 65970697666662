import { yupResolver } from '@hookform/resolvers/yup';
import { f7, useStore } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ConfirmDateInput } from './PromptConfirm/ConfirmDateInput';
import { ConfirmDefault } from './PromptConfirm/ConfirmDefault';
import { ConfirmPrepaid } from './PromptConfirm/ConfirmPrepaid';
import { ConfirmSuccess } from './PromptConfirm/ConfirmSuccess';
import { ConfirmWarranty } from './PromptConfirm/ConfirmWarranty';
import style from './PromptConfirm/PromptConfirmStyle.module.css';
import { formatDate } from './const';
import { deliveryDateSchema } from './validationSchema';
import { inputCustomerApi } from '@/api/todoListApi';
import { Button } from '@/components/uiParts/Button';
import { Stepper } from '@/components/uiParts/Stepper/Stepper';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { TASK_ID } from '@/consts/news';
import { useToast } from '@/hooks/useToast';
import { Prepaid, Warranty } from '@/types/api/customerApi';
import { DeliveryInputCustomerWfParams } from '@/types/api/todoListApi';

interface PromptConfirmationDetailProps {
  item?: any;
}

interface Tab {
  id: string;
  label: string;
}

export const formModes = {
  DEFAULT: 0,
  PREPAID: 1,
  WARRANTY: 2,
  BOTH: 3,
  SHARED: 4,
};

const formTabs = {
  [formModes.DEFAULT]: {
    id: 'default',
    label: '納車日入力',
  },
  [formModes.SHARED]: {
    id: 'date-input',
    label: '納車日入力',
  },
  [formModes.PREPAID]: {
    id: 'prepaid',
    label: 'プリペイドサービス重要事項',
  },
  [formModes.WARRANTY]: {
    id: 'warranty-2',
    label: '保証サービス重要事項',
  },
};

export interface ConfirmInput {
  deliveredDate: {
    year: string;
    month: string;
    day: string;
  };
  currentCheckbox: number;
  targetedCheckox: number;
}

export const PromptConfirmDetail: FC<PromptConfirmationDetailProps> = ({
  item,
}) => {
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Prepaid
    | undefined;
  const warranties = useStore(store, 'getWarranties') as Warranty[];
  const [successOpen, setSuccessOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [formMode, setFormMode] = useState(formModes.DEFAULT);
  const [tabs, setTabs] = useState<Tab[]>([]);
  const { openToast } = useToast();

  const deliveryConfirmedDate = item.delivery_confirmed_date
    ? item.delivery_confirmed_date.split('-')
    : [];

  const currentTab = tabs[activeTab] || {};

  const currentDate = new Date();
  const formMethods = useForm<ConfirmInput>({
    defaultValues: {
      deliveredDate: {
        year: String(currentDate.getFullYear()),
        month: (currentDate.getMonth() + 1).toString().padStart(2, '0'),
        day: String(currentDate.getDate()).toString().padStart(2, '0'),
      },
      currentCheckbox: 0,
      targetedCheckox: 0,
    },
    resolver: yupResolver(deliveryDateSchema),
  });

  const {
    watch,
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const currentCheckbox = watch('currentCheckbox');
  const targetedCheckox = watch('targetedCheckox');

  const onSubmit: SubmitHandler<ConfirmInput> = async (data) => {
    f7.preloader.show();
    const dataSubmit: DeliveryInputCustomerWfParams = {
      task_id: TASK_ID.DELIVERY_FOR_CUSTOMER_BY_CUSTOMER,
      t_wf_instance_task_id: item.t_wf_instance_task_id,
      data: {
        delivery_confirmed_date: null,
        id: item.id,
      },
    };
    if (formMode !== formModes.PREPAID) {
      const { year, month, day } = data.deliveredDate;
      const delivery_confirmed_date =
        year && month && day
          ? formatDate(`${year}-${month}-${day}`)
          : undefined;
      dataSubmit.data.delivery_confirmed_date = delivery_confirmed_date;
    }
    if (formMode !== formModes.DEFAULT) {
      dataSubmit.data.confirmed = true;
    }
    const res = await inputCustomerApi(dataSubmit);

    f7.preloader.hide();

    if (res.data.success) {
      setSuccessOpen(true);
    } else {
      openToast('エラーが発生しました', 'toast-failed');
    }
  };

  useEffect(() => {
    if (warranties.length && prepaid) {
      setTabs([
        formTabs[formModes.SHARED],
        formTabs[formModes.WARRANTY],
        formTabs[formModes.PREPAID],
      ]);
      setFormMode(formModes.BOTH);
      return;
    }
    if (warranties.length) {
      setTabs([formTabs[formModes.SHARED], formTabs[formModes.WARRANTY]]);
      setFormMode(formModes.WARRANTY);
      return;
    }
    if (prepaid) {
      setTabs([formTabs[formModes.SHARED], formTabs[formModes.PREPAID]]);
      setFormMode(formModes.PREPAID);
      return;
    }
    setTabs([formTabs[formModes.DEFAULT]]);
    setFormMode(formModes.DEFAULT);
  }, [prepaid, warranties]);

  return (
    <form className={style['page-container']} onSubmit={handleSubmit(onSubmit)}>
      {tabs.length > 1 && (
        <Stepper
          className={style['stepper-wrapper']}
          activeClassName={style['stepper-active']}
          activeTabId={activeTab}
          tabs={tabs.map(({ label }) => label)}
        />
      )}
      <FormProvider {...formMethods}>
        <ConfirmSuccess
          formMode={formMode}
          opened={successOpen}
          onPopupClose={() => {
            setSuccessOpen(false);
            f7.view.main.router.navigate(`/news${paths.todoList}`);
          }}
        />
        {currentTab.id === 'default' && (
          <ConfirmDefault deliveryConfirmedDate={deliveryConfirmedDate} />
        )}
        {currentTab.id === 'date-input' && (
          <ConfirmDateInput deliveryConfirmedDate={deliveryConfirmedDate} />
        )}
        {currentTab.id === 'warranty-2' && <ConfirmWarranty />}
        {currentTab.id === 'prepaid' && <ConfirmPrepaid />}
        <div className={style['button-wrapper']}>
          {!!targetedCheckox && (
            <p>
              確認する項目：残り
              <span className={style['button-description']}>
                {targetedCheckox - currentCheckbox}件
              </span>
              (全{targetedCheckox}件)
            </p>
          )}
          {activeTab === tabs.length - 1 ? (
            <Button type="submit" disabled={!isValid}>
              完了
            </Button>
          ) : (
            <Button
              onClick={() => {
                setTimeout(() => {
                  document
                    .querySelector('#page-template-content')
                    ?.scrollTo({ top: 0, behavior: 'smooth' });
                });
                setActiveTab(activeTab + 1);
              }}
              disabled={!isValid}
            >
              次へ
            </Button>
          )}
        </div>
      </FormProvider>
    </form>
  );
};
