import React, { FC, useEffect, useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { MailDayInput } from './const';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { RhfDropdown } from '@/components/uiParts/ReactHookForm/RhfDropdown/RhfDropdown';
import { getDayInMonthOptions, getYearOptions } from '@/utils/date';

const getMonthOption = (current: number) => {
  const options = [];
  const previous = current - 1;
  if (previous === 0) {
    options.push('12');
  } else {
    options.push(previous < 10 ? '0' + previous : String(previous));
  }
  options.push(current < 10 ? '0' + current : String(current));
  return options;
};

interface DepositDayFormProps {
  watch: UseFormWatch<MailDayInput>;
  setValue: UseFormSetValue<MailDayInput>;
  error?: string;
  isValid: boolean;
}

export const DepositDayForm: FC<DepositDayFormProps> = ({
  watch,
  setValue,
  isValid = false,
  error = null,
}) => {
  const year = watch('year');
  const month = watch('month');
  const day = watch('day');
  const [dayOptions, setDayOptions] = useState<string[]>(
    getDayInMonthOptions(Number(year), Number(month)),
  );
  const [yearOptions, setYearOptions] = useState<string[]>(
    getYearOptions(new Date().getFullYear() - 1, 1),
  );
  const monthOptions = getMonthOption(new Date().getMonth() + 1);

  useEffect(() => {
    setDayOptions(getDayInMonthOptions(Number(year), Number(month)));
  }, [month, year]);

  useEffect(() => {
    const currentMonth = 0;
    const currentYear = new Date().getFullYear();
    if (month === '12' && currentMonth === 0) {
      setValue('year', String(currentYear - 1));
    } else if (year !== String(currentYear)) {
      setValue('year', String(currentYear));
    }
  }, [month]);

  return (
    <div id="mail-day-input">
      <div className="label-wrap">
        <p className="label">送金した日付を入力してください</p>
      </div>
      <div className="input-container">
        <div className="input-wrap">
          <RhfDropdown
            className={`input year ${error && 'error-input'}`}
            name="year"
            disabled
          >
            <option value="" disabled hidden />
            {yearOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </RhfDropdown>
          年
          <RhfDropdown
            className={`input month ${error && 'error-input'}`}
            name="month"
          >
            <option value="" disabled hidden />
            {monthOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </RhfDropdown>
          月
          <RhfDropdown
            className={`input day ${error && 'error-input'}`}
            name="day"
          >
            <option value="" disabled hidden />
            {dayOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </RhfDropdown>
          日
        </div>
        <div>
          <CheckIcon isValid={isValid} />
        </div>
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};
