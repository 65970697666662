import {
  Block,
  Link,
  Navbar,
  NavLeft,
  NavTitle,
  Page,
  Popup,
} from 'framework7-react';
import { ErrorPageTitleTips } from '@/components/projects/ErrorPageTitleTips/ErrorPageTitleTips';
import { RequestCustomerList } from '@/components/projects/RequestCustomerList/RequestCustomerList';
import { SupportCenterPhone } from '@/components/uiParts/SupportCenterPhone/SupportCenterPhone';

export const PhoneDuplicated = () => {
  const duplicatedPhoneNumber = '00000000000';
  const items = ['お客様のお電話番号', 'お客様のお名前', 'お客様の生年月日'];
  return (
    <Popup>
      <Page name="PhoneDuplicated">
        <Navbar noShadow>
          <NavLeft>
            <Link popupClose back>
              <i className="icon-close" />
            </Link>
          </NavLeft>
          <NavTitle>お問い合わせのお願い</NavTitle>
        </Navbar>

        <Block>
          <ErrorPageTitleTips
            title={`同じ電話番号でご登録のお客様が\n複数いるようです`}
            duplicatedTipsWordsShow
            duplicatedPhoneNumber={duplicatedPhoneNumber}
          />
          <RequestCustomerList
            title={`お客様照会のため、下記の情報を\n確認させていただきます`}
            items={items}
          />
          <p
            className="u-text-center"
            style={{ fontSize: '1.6rem', fontWeight: 'bold' }}
          >
            「電話番号の重複エラーの件」と伝えてください
          </p>
          <SupportCenterPhone />
        </Block>
      </Page>
    </Popup>
  );
};
