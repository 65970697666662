import { FC } from 'react';
import { Button } from '../Button/Button/Button';

export const SupportCenterPhone: FC = () => {
  const handleClickContact = () => {
    window.location.href = 'tel://';
  };
  return (
    <div className="u-padding-right-sm u-padding-left-sm u-margin-bottom-md u-text-center">
      <Button icon="icon-telephone" handleClickAction={handleClickContact}>
        サポートセンターに電話する
      </Button>
    </div>
  );
};
