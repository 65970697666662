import parse from 'date-fns/parse';
import { store } from '@/config/store';
import { HighlightResponse } from '@/types/api/driveRecordApi';
import { ContractInformation } from '@/types/api/linkDriveApi';
import {
  dateToHyphenConnection,
  formatDateISO,
  isAfterMonthDay,
  rTime,
} from '@/utils/date';
import { storeDispatch } from '@/utils/store';

export const getContractInfo = (
  contractInfomation: ContractInformation[] | undefined,
) => {
  const linkDriveOwnerInfo = store.state.linkDriveOwnerInfo;
  const linkDriveStatus = store.state.carItemInfo.linkdrive_status;
  if (!contractInfomation) return;
  let result;
  const resultList: ContractInformation[] = [];
  const currentDate = new Date();
  if (contractInfomation.length > 1) {
    contractInfomation.map((item: ContractInformation) => {
      if (linkDriveOwnerInfo.contract_flg == 2 || linkDriveStatus == 3) {
        if (item.special_contract == 'A') {
          resultList.push(item);
        }
      } else {
        if (currentDate >= new Date(item.issue_date)) {
          resultList.push(item);
        }
      }
    });
    resultList.sort((a, b) => {
      return b.issue_date < a.issue_date ? -1 : 1;
    });
    result = resultList[0];
  } else {
    result = contractInfomation[0];
  }

  const issueDate = new Date(result.issue_date);
  const expireDate = new Date(result.expire_date);
  if (currentDate >= issueDate && currentDate <= expireDate) {
    storeDispatch(
      'setNowSpecialContract',
      'nowSpecialContract',
      result.special_contract,
    );
  } else {
    storeDispatch('setNowSpecialContract', 'nowSpecialContract', '');
  }

  return {
    specialContract: result.special_contract,
    cancelKbn: result.cancel_kbn,
    expireDate: result.expire_date,
    issueDate: result.issue_date,
    phydScoreKind: result.phyd_score_kind,
    contractExpireDate: result.contract_expire_date,
    contractIssueDate: result.contract_issue_date,
    isShowJoinedInsuranceBtn: false,
    isShowScoreBtn: false,
  };
};

//Todo: リファクタ
export const judgeShowBtns = (linkDriveOwnerInfo: any, data: any) => {
  if (!data) {
    return [false, false];
  }
  const { del_flg, drivelog_send_flg, service_end_date, device_del_date } =
    linkDriveOwnerInfo;
  const {
    specialContract,
    cancelKbn,
    expireDate,
    issueDate,
    contractExpireDate,
    contractIssueDate,
    phydScoreKind,
  } = data;

  let isShowScoreBtn = false;
  let isShowJoinedInsuranceBtn = false;

  let endDateFlag: Boolean;
  let delDateFlag: Boolean;
  let expireDateFlag: Boolean;
  let contractExpireDateFlag: Boolean;
  const deviceDelDate = new Date(device_del_date);
  const serviceEndDate = new Date(service_end_date);
  const expireDateTemp = new Date(expireDate);
  const contractExpireDateTemp = new Date(contractExpireDate);

  const endDate = dateToHyphenConnection(
    new Date(
      new Date(
        serviceEndDate.getFullYear(),
        serviceEndDate.getMonth() + 1,
        1,
      ).setDate(0),
    ),
  );

  endDateFlag = isAfterMonthDay(
    rTime(parse(endDate, 'yyyy-M-d', new Date())),
    2,
  );

  const DelDate = dateToHyphenConnection(
    new Date(
      new Date(
        deviceDelDate.getFullYear(),
        deviceDelDate.getMonth() + 1,
        1,
      ).setDate(0),
    ),
  );
  delDateFlag = isAfterMonthDay(parse(DelDate, 'yyyy-M-d', new Date()), 2);

  const exDate = dateToHyphenConnection(
    new Date(
      new Date(
        expireDateTemp.getFullYear(),
        expireDateTemp.getMonth() + 1,
        1,
      ).setDate(0),
    ),
  );
  expireDateFlag = isAfterMonthDay(parse(exDate, 'yyyy-M-d', new Date()), 1);

  const conexDate = dateToHyphenConnection(
    new Date(
      new Date(
        contractExpireDateTemp.getFullYear(),
        contractExpireDateTemp.getMonth() + 1,
        1,
      ).setDate(0),
    ),
  );
  contractExpireDateFlag = isAfterMonthDay(
    parse(conexDate, 'yyyy-M-d', new Date()),
    1,
  );

  if (specialContract != 'A') {
    if (del_flg == 0) {
      // 安全運転分析サービス利用同意取り下げ
      if (drivelog_send_flg == 0) {
        if (endDateFlag) {
          isShowScoreBtn = false;
          isShowJoinedInsuranceBtn = false;
        } else {
          isShowJoinedInsuranceBtn = true;
        }
      } else if (drivelog_send_flg == 1) {
        isShowJoinedInsuranceBtn = true;
      }
    } else if (del_flg == 1) {
      if (delDateFlag) {
        isShowJoinedInsuranceBtn = false;
        isShowScoreBtn = false;
      } else {
        isShowJoinedInsuranceBtn = true;
      }
    }
  }
  const now = new Date();
  if (specialContract == 'A') {
    if (del_flg == 0) {
      // 安全運転分析サービス利用同意中
      if (drivelog_send_flg == 1) {
        console.log('drivelog_send_flg == 1');
        if (phydScoreKind != 'K') {
          console.log('phydScoreKind != K');
          if (
            (!cancelKbn || cancelKbn.trim().length === 0) &&
            formatDateISO(contractIssueDate) <= now &&
            formatDateISO(contractExpireDate) >= now
          ) {
            isShowScoreBtn = true;
          } else if (
            cancelKbn === '2' &&
            formatDateISO(issueDate) <= now &&
            formatDateISO(expireDate) >= now
          ) {
            isShowScoreBtn = true;
          } else {
            isShowJoinedInsuranceBtn = true;
          }
        } else if (phydScoreKind == 'K') {
          if (
            cancelKbn == null &&
            formatDateISO(contractIssueDate) <= now &&
            formatDateISO(contractExpireDate) >= now
          ) {
            isShowJoinedInsuranceBtn = true;
            isShowScoreBtn = true;
          } else if (
            cancelKbn === '2' &&
            formatDateISO(issueDate) <= now &&
            formatDateISO(expireDate) >= now
          ) {
            isShowJoinedInsuranceBtn = true;
            isShowScoreBtn = true;
          } else {
            isShowJoinedInsuranceBtn = true;
          }
          // 安全運転分析サービス利用同意取り下げ
        } else {
          isShowJoinedInsuranceBtn = false;
          isShowScoreBtn = false;
        }
      }
    } else if (del_flg == 1) {
      if (cancelKbn == null && contractExpireDateFlag) {
        isShowScoreBtn = true;
      } else if (cancelKbn === '2' && expireDateFlag) {
        isShowScoreBtn = true;
      } else {
        isShowJoinedInsuranceBtn = false;
        isShowScoreBtn = false;
      }
    }
  }
  return [isShowScoreBtn, isShowJoinedInsuranceBtn];
};

export const getHighlightMapData = (
  highlightData: HighlightResponse['data'] | undefined,
) => {
  if (!highlightData?.success) return;
  const mapData = {
    latitude: highlightData.data.timeline_log.lat,
    longitude: highlightData.data.timeline_log.lon,
    location: highlightData.data.timeline_log.address,
    time: highlightData.data.timeline_log.target_at,
    color: '#55a3d2',
    weight: 5,
  };
  return mapData;
};

export const getHighlightInsuranceCardData = (
  highlightData: HighlightResponse['data'] | undefined,
) => {
  if (!highlightData?.success) return;
  const insuranceCard = {
    monthlyreport: highlightData.data.monthly_report,
    mileageOfData: highlightData.data.mileage_of_date,
    tripLog: highlightData.data.trip_log,
    timelineLog: highlightData.data.timeline_log,
    phydMeas: highlightData.data.phyd_meas,
    adInsuranceDiscount: highlightData.data.ad_insurance_discount,
    latestDiagnosisResult: highlightData.data.latest_diagnosis_result,
  };
  return insuranceCard;
};
