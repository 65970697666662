import { Col, Row } from 'framework7-react';
import React, { FC } from 'react';
import style from './SelfScanSuccess.module.css';
import successImage from '@/assets/images/no-error-mark.png';
import { Button } from '@/components/uiParts/Button';

export const SelfScanSuccess: FC = () => {
  const handleClickClose = () => {};
  return (
    <div>
      <div className={style.icon}>
        <img src={successImage} alt="" />
        <p className={style.p}>異常は見つかりませんでした</p>
      </div>
      <Row className={style.button}>
        <Col width="20" />
        <Col width="60">
          <Button text="終了する" handleClickAction={handleClickClose} />
        </Col>
        <Col width="20" />
      </Row>
    </div>
  );
};
