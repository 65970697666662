import { f7, useStore } from 'framework7-react';
import { useEffect, useState } from 'react';
import { useConnectorUpdate } from './useConnectorUpdate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  delFlg,
  linkDriveInitStatus,
  linkDriveSetupStatus,
} from '@/consts/linkDrive';
import { Car } from '@/types/api/customerApi';
import { LinkDriveOwner } from '@/types/api/infoApi';

/**
 * LinkDriveのコネクタ設定と状態管理を行う。
 * LinkDriveオーナー情報を基にして、車両のセットアップ状況、キャリブレーションの完了状況、および各種更新処理の状態管理を行う。
 *
 * @returns {Object} LinkDriveコネクタの設定状態や更新状況、およびそれらを操作するための関数群
 */
export const useConnectorSetting = () => {
  const ownerId = useStore(store, 'ownerId') as number;
  const linkDriveOwnerInfo = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;
  const {
    email,
    gender,
    birthday,
    zip_code1,
    zip_code2,
    address1,
    address2,
    address3,
    first_name_kana,
    family_name_kana,
    first_name,
    family_name,
  } = store.state.customerInfo;
  const ownedCars = store.state.carsInfo;

  const [linkCar, setLinkCar] = useState<Car>();
  const [isSetupCompleted, setIsSetupCompleted] = useState(false);
  const [isCalibrationCompleted, setIsCalibrationCompleted] = useState(false);

  // LinkDriveオーナー情報に基づき、コネクタの設定状態を初期化
  const initialDisplayConditions = () => {
    const { init_status, setup_status, del_flg, t_stock_car_id } =
      linkDriveOwnerInfo;
    if (del_flg === delFlg.NOT_DELETE) {
      // セットアップ完了判定
      if (
        setup_status === linkDriveSetupStatus.MODE_RESERVATION_COMPLETE ||
        setup_status ===
          linkDriveSetupStatus.CALIBRATION_FARMWARE_UPDATE_COMPLETE ||
        init_status === linkDriveInitStatus.COMPLETE
      ) {
        setIsSetupCompleted(true);
        const car = ownedCars.find((c) => c.t_stock_car_id === t_stock_car_id);
        setLinkCar(car);
      } else {
        setIsSetupCompleted(false);
      }
      // キャリブレーション実施有無判定
      if (
        setup_status ===
        linkDriveSetupStatus.CALIBRATION_FARMWARE_UPDATE_COMPLETE
      ) {
        setIsCalibrationCompleted(true);
      } else {
        setIsCalibrationCompleted(false);
      }
    }
    fetchConnectorDetail({ owner_id: ownerId });
  };

  useEffect(() => {
    initialDisplayConditions();
  }, [linkDriveOwnerInfo, isSetupCompleted, isCalibrationCompleted]);

  const handleClickNavigationLinkDriveCalibration = () => {
    f7.view.main.router.navigate(paths.linkDriveCalibration, {
      props: { setIsCalibrationCompleted },
    });

    // f7.view.main.router.navigate(paths.linkDriveCalibrationProcedure);
  };

  const handleClickNavigationLinkDriveSetupList = () => {
    // LinkDriveをセットアアップするにはemai郵便番号住所カナ名性別生年月日漢字名が必要
    let errMsg = '';
    const checkUserInfomaition = [
      [!!email, 'メールアドレス'],
      [!!(zip_code1 && zip_code2), '郵便番号'],
      [!!(address1 && address2 && address3), '住所'],
      [!!(first_name_kana && family_name_kana), 'カナ名'],
      [!!gender, '性別'],
      [!!birthday, '生年月日'],
      [!!(first_name && family_name), '漢字名'],
    ];
    checkUserInfomaition.forEach((msg) => {
      if (!msg[0]) {
        errMsg += errMsg ? `・${msg[1]}` : msg[1];
      }
    });
    if (!!errMsg) {
      f7.dialog.alert(
        `LinkDriveを利用するには${errMsg}が必要です。`,
        '',
        () => {
          f7.view.main.router.navigate('/signup_email/', { animate: true });
        },
      );
      return;
    }
    store.dispatch('setFromPage', { fromPage: '' });
    f7.view.main.router.navigate(paths.linkDriveSetupList, { animate: true });
  };

  const handleClickSetupConnectorChange = () => {
    store.dispatch('setFromPage', { fromPage: 'connectorReplace' });
    f7.view.main.router.navigate(paths.linkDriveSetupList, { animate: true });
  };

  // 更新処理
  const {
    currentUpdate,
    connectorDetailInfo,
    showDialog,
    isOpened,
    isUpdating,
    isFirmWareUpdating,
    isFirmWareUpdated,
    isFWLatestVersion,
    isECUUpdating,
    isECUUpdated,
    isECULatestVersion,
    isImpactJudgementUpdating,
    isImpactJudgementUpdated,
    isImpactJudgementLatestVersion,
    isAvaiableForUpdate,
    progress,
    isDuringConnectorStartup,
    isFailure,
    handleClickUpdateSystemInfo,
    fetchConnectorDetail,
    setShowDialog,
    setIsOpened,
    refetch,
  } = useConnectorUpdate(initialDisplayConditions);

  return {
    linkCar,
    currentUpdate,
    isSetupCompleted,
    isCalibrationCompleted,
    showDialog,
    isOpened,
    isUpdating,
    isFirmWareUpdating,
    isFirmWareUpdated,
    isFWLatestVersion,
    isECUUpdating,
    isECUUpdated,
    isECULatestVersion,
    isImpactJudgementUpdating,
    isImpactJudgementUpdated,
    isImpactJudgementLatestVersion,
    isAvaiableForUpdate,
    progress,
    isDuringConnectorStartup,
    connectorDetailInfo,
    isFailure,
    setIsOpened,
    setShowDialog,
    setIsCalibrationCompleted,
    handleClickNavigationLinkDriveCalibration,
    handleClickNavigationLinkDriveSetupList,
    handleClickSetupConnectorChange,
    handleClickUpdateSystemInfo,
    refetch,
  };
};
