import { FC } from 'react';
import style from './pushSettingsStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { State } from '@/config/initialState';

interface PushSettingsProps {
  handleClickButton: () => void;
  pushPermission: State['permission']['pushPermission'];
}
export const PushSettings: FC<PushSettingsProps> = ({
  handleClickButton,
  pushPermission,
}) => {
  const heading = pushPermission ? '許可されています' : '許可されていません';
  const buttonClassName = pushPermission ? style['button-inactive'] : undefined;
  return (
    <PageTemplate title="通知設定" pageName="PushSettings" showNavBack>
      <RoundSheet>
        <h2 className={style['heading']}>通知は{heading}</h2>
        <p
          className={style['sub-title']}
        >{`通知の許可をすると、下記のような\nお知らせを受け取ることができます。`}</p>
        <RoundSheet bgColor="#efefef">
          <h3 className={style['example-title']}>受け取れる通知の例</h3>
          <div className={style['list-wrapper']}>
            <p>定期点検や車検の時期になった時</p>
            <p>クーポンが発行された時</p>
            <p>車に衝撃を感知した時や盗難が疑われた時</p>
          </div>
        </RoundSheet>

        <div className={style['button-wrapper']}>
          <Button
            handleClickAction={handleClickButton}
            className={buttonClassName}
          >
            設定する
          </Button>
        </div>
      </RoundSheet>
    </PageTemplate>
  );
};
