import { Page } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import style from './ReservingsStep2Style.module.scss';
import { PaymentRegister } from '@/components/pages/Reservings/PaymentRegister/PaymentRegister';
import { Button } from '@/components/uiParts/Button';
import { store } from '@/config/store';
import { BillInformationDetail, PaymentMethod } from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';

export interface ReservingsStep2Props {
  changeStep: (step: number) => void;
  handleCancel?: () => void;
  f7router: Router.Router;
  paymentList: {
    accountNumber: string | undefined;
    bankName: string | undefined;
    cardNo: string | undefined;
    carNm: string | undefined;
    isSelected: boolean;
    noPayFlg: number | undefined;
    paymentTypeId: number | undefined;
    paymentTypeName: string | undefined;
    paymentMethodId: number | undefined;
    paySubject: string | undefined;
    prepaidId: number | undefined;
    prepaidName: string | undefined;
    warrantyNo: string;
  }[];
  nextBillObj: {
    nextBill: BillInformationDetail | undefined;
    closestMonthBill: BillInformationDetail | undefined;
  };
}

export const ReservingsStep2: FC<ReservingsStep2Props> = ({
  changeStep,
  f7router,
  nextBillObj,
  handleCancel,
}) => {
  const { paymentInfo, paymentMethodsInfo } = store.state;
  const [paymentMethodSelected, setPaymentMethodSelected] =
    useState<PaymentMethod>({
      m_payment_type_id: paymentInfo.mPaymentTypeId,
      m_payment_method_id: paymentInfo.mPaymentMethodId,
      payment_type_name: paymentInfo.paymentTypeName,
      bank_name: paymentInfo.bankName,
      card_no: paymentInfo.cardNo,
      account_number: paymentInfo.accountNumber,
    });
  const redirectPage = localStorage.getItem('paymentRedirectPage') || 'prepaid';

  useEffect(() => {
    if (paymentMethodSelected?.m_payment_method_id) {
      return;
    }
    const lastRegisteredPayment = paymentMethodsInfo.reduce((max, cur) => {
      const maxDate = new Date(max.register);
      const curDate = new Date(cur.register);
      return curDate > maxDate ? cur : max;
    }, paymentMethodsInfo[0]);

    setPaymentMethodSelected(lastRegisteredPayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodsInfo]);

  const handleClickComplite = () => {
    if (!paymentMethodSelected) {
      return;
    }

    storeDispatch('setPaymentInfo', 'paymentInfo', {
      mPaymentTypeId: paymentMethodSelected.m_payment_type_id,
      mPaymentMethodId: paymentMethodSelected.m_payment_method_id,
      paymentTypeName: paymentMethodSelected.payment_type_name,
      bankName: paymentMethodSelected.bank_name,
      cardNo: paymentMethodSelected.card_no,
      accountNumber: paymentMethodSelected.account_number,
    });
    // reservingStep3に飛ぶ
    // eslint-disable-next-line no-magic-numbers
    changeStep(2);
  };

  return (
    <Page name="ReservingsStep2" pageContent={false}>
      <div className={style.container}>
        <PaymentRegister
          redirectPage={redirectPage}
          choosePaymentMethod={setPaymentMethodSelected}
          paymentMethodSelected={paymentMethodSelected}
        />

        <div className={style['button-wrap']}>
          {paymentMethodSelected?.m_payment_method_id ? (
            <Button
              className={style['btn-next']}
              text="次へ"
              handleClickAction={handleClickComplite}
            />
          ) : (
            <Button
              className={style['btn-cancel']}
              text="申し込みを中止する"
              handleClickAction={handleCancel}
            />
          )}
        </div>
      </div>
    </Page>
  );
};
