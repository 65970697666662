import { FC, useEffect, useState } from 'react';
import style from './CarWashConditionerPageStyle.module.css';
import { CarWashConditionerStep1 } from './CarWashConditionerStep1/CarWashConditionerStep1';
import { CarWashConditionerStep2 } from './CarWashConditionerStep2/CarWashConditionerStep2';
import { CarWashConditionerStep3 } from './CarWashConditionerStep3/CarWashConditionerStep3';
import { CarWashConditionerStep4 } from './CarWashConditionerStep4/CarWashConditionerStep4';
import { PopupCancel } from './PopupCancel/PopupCancel';
import { CustomBgPageTemplate } from '@/components/uiParts/Template/CustomBgPageTemplate/CustomBgPageTemplate';
import { steps } from '@/consts/carWash';
import { useCarWashConditioner } from '@/hooks/api/carWash/useCarWashConditioner';

type CarWashConditionerPageProp = {
  status?: string;
};

export const CarWashConditionerPage: FC<CarWashConditionerPageProp> = ({
  status,
}) => {
  const [opened, setOpened] = useState(false); // 予約中止ポップアップの表示状態
  const storageStep = localStorage.getItem('step') || '';
  const [initialStatus, setInitialStatus] = useState(!!status || !!storageStep);
  const {
    step,
    step1Data,
    step2Data,
    step3Data,
    courseItems,
    optionItems,
    optionItem,
    courseItem,
    submitSuccess,
    setStep,
    submitStep1,
    submitStep2,
    submitStep3,
    submitStep4,
  } = useCarWashConditioner(status || storageStep);

  const handleClickBack = () => {
    if (step === steps.PRE_SELECTION) {
      setOpened(true);
      return;
    }
    setStep(step - 1);
  };

  useEffect(() => {
    document.querySelector('.page-content > div > div')?.scrollTo(0, 0);
  }, [step]);

  return (
    <CustomBgPageTemplate
      pageName="CarWashConditioner"
      title={
        step === steps.REVIEW
          ? '予約内容の確認'
          : '出張カーエアコンクリーニング'
      }
      className={style['page']}
      handleClickBack={handleClickBack}
      background={{ background: '#fff' }}
      showNavBack
    >
      <PopupCancel
        closeOnEscape={false}
        closeByBackdropClick={false}
        status={status}
        opened={opened}
        onPopupClosed={() => setOpened(false)}
      />
      {step === steps.PRE_SELECTION && (
        <CarWashConditionerStep1
          step1Data={step1Data}
          courseItems={courseItems}
          optionItems={optionItems}
          onSubmit={submitStep1}
          onClickCancel={() => setOpened(true)}
        />
      )}
      {step === steps.REGISTATION && (
        <CarWashConditionerStep2
          step2Data={step2Data}
          onSubmit={submitStep2}
          onClickCancel={() => setOpened(true)}
        />
      )}
      {step === steps.ADDITIONAL_INFO && (
        <CarWashConditionerStep3
          courseItem={courseItem}
          step3Data={step3Data}
          onSubmit={submitStep3}
          onClickCancel={() => setOpened(true)}
        />
      )}
      {step === steps.REVIEW && (
        <CarWashConditionerStep4
          initialStatus={initialStatus}
          step1Data={step1Data}
          step2Data={step2Data}
          step3Data={step3Data}
          courseItem={courseItem}
          optionItem={optionItem}
          onSubmit={submitStep4}
          submitSuccess={submitSuccess}
          setInitialStatus={setInitialStatus}
          onClickCancel={() => setOpened(true)}
        />
      )}
    </CustomBgPageTemplate>
  );
};
