import { Router } from 'framework7/types';
import { Dispatch, FC, SetStateAction } from 'react';
import { CalibrationDialog } from '../CalibrationDialog/CalibrationDialog';
import { CalibrationNotStart } from '../CalibrationNotStart/CalibrationNotStart';
import { LinkDriveCalibrationProcedure } from '../LinkDriveCalibrationProcedure/LinkDriveCalibrationProcedure';
import { LinkDriveCalibrationStep1 } from '../LinkDriveCalibrationStep1/LinkDriveCalibrationStep1';
import { LinkDriveCalibrationStep2 } from '../LinkDriveCalibrationStep2/LinkDriveCalibrationStep2';
import { LinkDriveCalibrationStep3 } from '../LinkDriveCalibrationStep3/LinkDriveCalibrationStep3';
import { LinkDriveCalibrationTrouble } from '../LinkDriveCalibrationTrouble/LinkDriveCalibrationTrouble';
import { LinkDriveCheckHorizontal } from '../LinkDriveCheckHorizontal/LinkDriveCheckHorizontal';
import style from './linkDriveCalibrationStyle.module.css';
import { Dialog } from '@/components/uiParts/Dialog/Dialog';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { calibrationSteps } from '@/consts/linkDrive';
import { useLinkDriveStep } from '@/hooks/useLinkDriveCalibration';

interface LinkDriveCalibrationProps {
  f7router: Router.Router;
  setIsCalibrationCompleted: Dispatch<SetStateAction<boolean>>;
}

export const LinkDriveCalibration: FC<LinkDriveCalibrationProps> = ({
  f7router,
  setIsCalibrationCompleted,
}) => {
  const {
    currentStep,
    progress,
    showDialog,
    showStandByDialog,
    handleClickStartedEngine,
    handleClickCalibrationTrouble,
    handleClickStatusCheckError,
    handleClickCalibrationCompleted,
    handleClickDriveRestart,
    handleClickCalibrationStop,
    handleClose,
    handleClickClose,
    setShowDialog,
    setShowStandByDialog,
    handleClickSetupCalibration,
    handleClickCheckHorizontal,
  } = useLinkDriveStep(f7router, setIsCalibrationCompleted);

  return (
    <PopupPageTemplate
      pageName="LinkDriveCalibration"
      title="キャリブレーション"
      isCloseIcon
      closeByBackdropClick={false}
      closeOnEscape={false}
      swipeToClose={false}
      handleBack={handleClickClose}
    >
      <div className={style['container']}>
        {currentStep === calibrationSteps.ENGINE_START && (
          <LinkDriveCalibrationProcedure
            f7router={f7router}
            handleClickAction={handleClickSetupCalibration}
          />
        )}

        {currentStep === calibrationSteps.CHECK_POSITION && (
          <LinkDriveCheckHorizontal
            handleClickAction={handleClickCheckHorizontal}
          />
        )}

        {currentStep === calibrationSteps.IN_PREPARATION && (
          <LinkDriveCalibrationStep1
            handleClickAction={handleClickStartedEngine}
          />
        )}

        {currentStep === calibrationSteps.IN_PROGRESS && (
          <LinkDriveCalibrationStep2 progress={progress} />
        )}

        {/* <!-- キャリブレーション完了しない場合 --> */}
        {currentStep === calibrationSteps.PROGRESS_ERROR && (
          <LinkDriveCalibrationTrouble
            handleClickDriveRestart={handleClickDriveRestart}
            handleClickCalibrationStop={handleClickCalibrationStop}
            f7router={f7router}
          />
        )}

        {currentStep === calibrationSteps.STATUS_CHECK_ERROR && (
          <CalibrationNotStart
            handleClickAction={handleClickStatusCheckError}
          />
        )}

        {currentStep === calibrationSteps.COMPLETED && (
          <LinkDriveCalibrationStep3 />
        )}
      </div>

      {showStandByDialog && (
        <CalibrationDialog handleOk={() => setShowStandByDialog(false)} />
      )}

      {showDialog && (
        <Dialog
          title="キャリブレーションを中止しますか？"
          subTitle="キャリブレーションは完了していません。<br />本当に中止しますか"
          handleOk={handleClose}
          handleCancel={() => setShowDialog(false)}
          buttonOkText="キャリブレーションを中止する"
        />
      )}
    </PopupPageTemplate>
  );
};
