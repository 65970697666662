import { List, ListItem } from 'framework7-react';
import { FC } from 'react';
import style from './DataListStyle.module.css';

interface DataType {
  connectorNo?: string;
  lineStatus?: string;
  firstLoginDate?: string;
  connectorUsingTerms?: string;
  linkdriveUsingTerms?: string;
  date?: string;
  PrevDate?: string;
  fullDate?: string;
  drivingDistance?: string;
  daytimeDrivingDuration?: string;
  nightDrivingDuration?: string;
  drivingTimes?: string;
  suddenSatrt?: string;
  suddenBraking?: string;
}

type LabelListProps = keyof DataType;

interface LabelListType {
  label: string;
  props: LabelListProps;
  type?: string;
  path?: string;
}

interface Props {
  title: string;
  labelList: LabelListType[];
  data?: DataType;
}

export const DataList: FC<Props> = ({
  title = '',
  labelList = [],
  data = { connectorNo: '' },
}) => {
  return (
    <div>
      {title && (
        <div className="u-font-size-lg u-font-bold u-text-center">{title}</div>
      )}
      <List>
        {labelList.map((item, index) => (
          <ListItem key={index}>
            <div className={`${style['list-item']}`} onClick={() => {}}>
              <div>{item.label}</div>
              {item.type === 'link' && (
                <div>
                  <i className="icon-right" />
                </div>
              )}
              {item.type !== 'link' && <div>{data[item.props]}</div>}
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
