import { Checkbox as F7Checkbox, f7, useStore } from 'framework7-react';
import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { Radio } from '../Inputs/Radio';
import { TextInput } from '../Inputs/TextInput';
import style from './CarWashConditionerStep1Style.module.css';
import CarWashConditionerPromote1 from '@/assets/images/carwash/carwash_conditioner_promote_1.png';
import { ReactComponent as IconCarWashCard } from '@/assets/images/menu/icon_car-wash-card.svg';
import { ReactComponent as IconCarWashConditionerCard } from '@/assets/images/menu/icon_car-wash-conditioner-card.svg';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  Step1FormData,
  useCarWashConditionerStep1,
} from '@/hooks/api/carWash/useCarWashConditionerStep1';
import { usePromote } from '@/hooks/usePromote';
import { Car } from '@/types/api/customerApi';
import { CourseItem, OptionItem } from '@/types/api/reservationApi';
import { isDelivered } from '@/utils/car';
import { getCarPhoto } from '@/utils/utils';

interface Props {
  courseItems: CourseItem[];
  optionItems: OptionItem[];
  step1Data: Step1FormData;
  onSubmit: (data: Step1FormData) => void;
  onClickCancel: () => void;
}

export const CarWashConditionerStep1: FC<Props> = ({
  step1Data,
  courseItems,
  optionItems,
  onSubmit,
  onClickCancel,
}) => {
  const carsInfo = useStore(store, 'carsInfo') as Required<Car>[];
  const { fee, prepaid, paymentShort, formMethods } =
    useCarWashConditionerStep1(step1Data, courseItems, optionItems);
  const {
    watch,
    handleSubmit,
    setValue,
    trigger,
    register,
    formState: { isValid, errors },
  } = formMethods;
  const useUserCar = watch('car.unregisteredCar.useUserCar');
  const tStockCarId = watch('car.tStockCarId');
  const carwashItemCode = watch('car.carwashItemCode');
  const optionItemCodes = watch('car.optionItemCodes');
  const { isShowPromote } = usePromote();

  const handleOpenAbout = () => {
    f7.views.main.router.navigate(paths.carWashConditionerAbout, {
      props: {
        showLink: false,
      },
    });
  };
  const submit = (data: Step1FormData) => {
    onSubmit(data);
  };
  const validCars = useMemo(() => {
    return carsInfo.filter(
      (car) => !!car.t_stock_car_id && isDelivered(car.delivery_complete_flg),
    );
  }, [carsInfo]);

  useEffect(() => {
    if (validCars.length === 0) {
      setValue('car.unregisteredCar.useUserCar', false);
    }
  }, [setValue, validCars]);
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submit)} className={style['container']}>
        <div className={style['heading-card']}>
          <IconCarWashCard className={style['heading-card-image']} />
          <IconCarWashConditionerCard className={style['heading-card-image']} />
          <h4>出張カーエアコンクリーニングとは？</h4>
          <p>
            独自に開発した技術で、エアコン内部の気になるカビ・臭いをお掃除します。
          </p>
          <Button
            className={style['about-button']}
            text="詳しくはこちら"
            handleClickAction={handleOpenAbout}
          >
            <i className="icon-right" />
          </Button>
        </div>
        <div className={style['input-group']}>
          <h2 className={style['input-label']}>
            施工する車の情報を入力してください。
            <span
              className={`${style['input-label-tips']} ${style['required']}`}
            >
              必須
            </span>
          </h2>
          {validCars.length > 0 && (
            <Radio
              id="user-car"
              name="car.unregisteredCar.useUserCar"
              checked={useUserCar}
              value={true}
              className={style['input-radio']}
            >
              既に登録されている車から選ぶ
            </Radio>
          )}
          <Radio
            id="input-car"
            name="car.unregisteredCar.useUserCar"
            checked={!useUserCar}
            value={false}
            className={style['input-radio']}
          >
            新しく車を登録する
          </Radio>
        </div>
        {/* 既に登録されている車から選ぶ */}
        {useUserCar && (
          <div>
            {validCars.map((car, index) => (
              <div
                key={index}
                className={`${style['input-radio']} ${
                  style['input-radio-box']
                } ${
                  tStockCarId === car.t_stock_car_id
                    ? style['input-radio-car-active']
                    : ''
                }`}
                onClick={() => {
                  setValue('car.tStockCarId', car.t_stock_car_id);
                  trigger('car.tStockCarId');
                }}
              >
                <div className={style['input-car-image']}>
                  <img src={getCarPhoto(car)} />
                </div>
                <div>
                  <h4>
                    {car.maker_nm}
                    {car.car_nm}
                  </h4>
                  <p>車台番号：{car.vin}</p>
                  <p>型式：{car.katasiki}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* 新しく車を登録する */}
        {!useUserCar && (
          <>
            <div>
              <TextInput
                label="メーカー"
                name="car.unregisteredCar.makerName"
                error={errors?.car?.unregisteredCar?.makerName?.message}
                required
              />
              <TextInput
                label="車種"
                name="car.unregisteredCar.carName"
                error={errors?.car?.unregisteredCar?.carName?.message}
                required
              />
              <TextInput
                label="車の色"
                name="car.unregisteredCar.carColor"
                error={errors?.car?.unregisteredCar?.carColor?.message}
                required
              />
              <TextInput
                label="車台番号"
                name="car.unregisteredCar.carVin"
                error={errors?.car?.unregisteredCar?.carVin?.message}
                required
              />
              <TextInput
                label="型式"
                name="car.unregisteredCar.modelCode"
                error={errors?.car?.unregisteredCar?.modelCode?.message}
                required
              />
            </div>
            <div
              className={`${style['input-group']} ${style['has-border-top']}`}
            >
              <h2 className={style['input-label']}>
                車両ナンバーを入力してください。
              </h2>
              <TextInput
                label="地域名"
                name="car.unregisteredCar.transportName"
                required
              />
              <TextInput
                label="分類番号"
                name="car.unregisteredCar.classNo"
                error={errors?.car?.unregisteredCar?.classNo?.message}
                required
              />
              <TextInput
                label="平仮名等"
                name="car.unregisteredCar.hiragana"
                error={errors?.car?.unregisteredCar?.hiragana?.message}
                required
              />
              <TextInput
                label="車両番号"
                name="car.unregisteredCar.registryNo"
                error={errors?.car?.unregisteredCar?.registryNo?.message}
                required
              />
            </div>
          </>
        )}
        <div className={style['promote-box']}>
          {isShowPromote && <img src={CarWashConditionerPromote1} />}
        </div>
        <div
          className={`${style['input-group']} ${
            isShowPromote ? '' : style['has-border-top']
          }`}
        >
          <h2 className={style['input-label']}>
            ご希望のコースを選択してください
            <span
              className={`${style['input-label-tips']} ${style['required']}`}
            >
              必須
            </span>
          </h2>
          {courseItems.map((item, index) => (
            <Radio
              id={`course-${item.code}`}
              name="car.carwashItemCode"
              key={index}
              value={item.code}
              checked={carwashItemCode === item.code}
              className={`${style['input-radio']} ${style['input-radio-box']}`}
            >
              <div>
                <h4>{item.name}</h4>
                <p>{item.price.toLocaleString()}円(税込)</p>
                <p className={style['sub-text']}>{item.details}</p>
              </div>
            </Radio>
          ))}
        </div>
        {!!optionItems.length && (
          <div className={`${style['input-group']} ${style['has-border-top']}`}>
            <h2 className={style['input-label']}>
              オプションを選択してください
              <span className={`${style['input-label-tips']}`}>任意</span>
              <p className={style['input-label-sub']}>別途料金がかかります。</p>
            </h2>
            {optionItems.map((item, index) => (
              <label
                key={item.code}
                className={`${style['input-checkbox']} ${style['input-radio']} ${style['input-radio-box']}`}
              >
                <F7Checkbox
                  id={`option-${item.code}`}
                  checked={optionItemCodes.includes(item.code)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      setValue('car.optionItemCodes', [
                        ...optionItemCodes,
                        item.code,
                      ]);
                    } else {
                      setValue('car.optionItemCodes', [
                        ...optionItemCodes.filter((i) => i !== item.code),
                      ]);
                    }
                  }}
                />
                <div>
                  <h4>{item.name}</h4>
                  <p>{item.price.toLocaleString()}円(税込)</p>
                </div>
              </label>
            ))}
          </div>
        )}
        <div className={style['price-box']}>
          <p className={style['price-box-label']}>現在の合計金額</p>
          <p className={style['price-box-price']}>{fee.toLocaleString()}</p>
          <p className={style['price-box-currency']}>円</p>
        </div>
        {!!paymentShort && (
          <div className={style['price-box-warning']}>
            現在{paymentShort.toLocaleString()}円不足しています
          </div>
        )}
        <div className={style['price-box-info']}>
          ご登録された{prepaid?.name}の残高から自動でお支払いが行われます。
        </div>
        <div className={style['button-group']}>
          <Button text="次へ" type="submit" disabled={!isValid} />
          <Button
            style={{
              color: '#232323',
              backgroundColor: '#fff',
              marginTop: '8px',
            }}
            text="キャンセル"
            onClick={onClickCancel}
          />
        </div>
      </form>
    </FormProvider>
  );
};
