import { Router } from 'framework7/types';
import { FC } from 'react';
import { TotalScoreList } from './TotalScoreList/TotalScoreList';

type TotalScoreListPageProps = {
  f7router: Router.Router;
};

export const TotalScoreListPage: FC<TotalScoreListPageProps> = ({
  f7router,
}) => {
  return <TotalScoreList f7router={f7router} />;
};
