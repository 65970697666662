import { AxiosError } from 'axios';
import { useState } from 'react';
import { fetchScanResultApi } from '@/api/linkDriveApi';
import {
  ScanResultApiParams,
  ScanResultApiResponse,
} from '@/types/api/linkDriveApi';

interface Args {
  params: ScanResultApiParams;
  resolve?: (data: ScanResultApiResponse['data']) => void;
}

export const useScanResult = () => {
  const [data, setData] = useState<ScanResultApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const fetchScanResult = async (args: Args) => {
    try {
      const { data } = await fetchScanResultApi(args.params);
      setData(data);
      args.resolve?.(data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    fetchScanResult,
  };
};
