import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { fetchDriveMonthlyScoreApi } from '@/api/driveRecordApi';
import {
  MonthlyDriveReportApiResponse,
  MonthlyPhydReportParams,
  MonthlyPhydReportResponse,
} from '@/types/api/driveRecordApi';
import { date, formatMinutes, getMonthDays } from '@/utils/date';

const initialData = {
  reportDate: '',
  createMonthly: [''],
  yearScoreList: [0],
  averageScore: 0,
  scoreComment: '',
  tripCount: 0,
  resultValueA: 0,
  resultValueB: 0,
  resultValueC: 0,
  drivingDistance: 0,
  averageDrivingDistance: 0,
  drivingMinutes: 0,
  averageDrivingMinutes: 0,
  drivingHours: 0,
  averageDrivingHours: 0,
  eventSpeed: 0,
  eventAcceralatedStability: 0,
  eventDowndecelerateStability: 0,
  averageEventSpeed: 0,
  averageEventAcceralatedStability: 0,
  averageEventDowndecelerateStability: 0,
  speedDataList: [0],
  suddenStartDataList: [0],
  suddenBrakingDataList: [0],
  analysis_from_date: '',
  analysis_to_date: '',
  dayList: [''],
  stars_acceleration: 0,
  stars_deceleration: 0,
  stars_speed: 0,
  diagnosis_report_id: 0,
  game_url: '',
  game_title: '',
  game_id: '',
};

// TODO: utilに移動する
const convertMonthlyDriveReport = (
  data: MonthlyPhydReportResponse['data']['data'],
): MonthlyDriveReportApiResponse => {
  const monthlyReport = data[0].monthly_report;
  initialData.reportDate = date(monthlyReport.report_date ?? '') ?? '';
  initialData.createMonthly = monthlyReport.create_monthly?.split(',') ?? [];
  initialData.yearScoreList =
    monthlyReport.year_score?.split(',').map(Number) ?? [];
  initialData.averageScore = monthlyReport.average_score ?? 0;
  initialData.scoreComment =
    monthlyReport.advice_message
      ?.replace(/x07/g, '<br />')
      .replace(/\u0007/g, '<br />') ?? '';
  initialData.tripCount = monthlyReport.trip_count ?? 0;
  initialData.resultValueA = monthlyReport.result_value_a ?? 0;
  initialData.resultValueB = monthlyReport.result_value_b ?? 0;
  initialData.resultValueC = monthlyReport.result_value_c ?? 0;
  initialData.drivingDistance = monthlyReport.driving_distance ?? 0;
  initialData.averageDrivingDistance =
    monthlyReport.average_driving_distance ?? 0;
  initialData.drivingHours =
    formatMinutes(monthlyReport.driving_minutes ?? 0).hours ?? 0;
  initialData.drivingMinutes =
    formatMinutes(monthlyReport.driving_minutes ?? 0).minutes ?? 0;
  initialData.averageDrivingHours =
    formatMinutes(monthlyReport.average_driving_minutes ?? 0).hours ?? 0;
  initialData.averageDrivingMinutes =
    formatMinutes(monthlyReport.average_driving_minutes ?? 0).minutes ?? 0;
  initialData.eventSpeed = monthlyReport.event_speed ?? 0;
  initialData.eventAcceralatedStability =
    monthlyReport.event_acceralated_stability ?? 0;
  initialData.eventDowndecelerateStability =
    monthlyReport.event_downdecelerate_stability ?? 0;
  initialData.averageEventSpeed = monthlyReport.average_event_speed ?? 0;
  initialData.averageEventAcceralatedStability =
    monthlyReport.average_event_acceralated_stability ?? 0;
  initialData.averageEventDowndecelerateStability =
    monthlyReport.average_event_downdecelerate_stability ?? 0;
  initialData.analysis_from_date =
    date(monthlyReport.analysis_from_date ?? '') ?? '';
  initialData.analysis_to_date =
    date(monthlyReport.analysis_to_date ?? '') ?? '';
  initialData.stars_acceleration = monthlyReport.stars_acceleration ?? 0;
  initialData.stars_deceleration = monthlyReport.stars_deceleration ?? 0;
  initialData.stars_speed = monthlyReport.stars_speed ?? 0;

  const specifiedYmReport = data[0].specified_ym_report;
  if (specifiedYmReport && specifiedYmReport.length > 0) {
    initialData.dayList = getMonthDays(
      Number(specifiedYmReport[0].start_at_ymd?.substring(0, 4)),
      Number(specifiedYmReport[0].start_at_ymd?.substring(4, 6)),
    );
  }

  initialData.dayList.forEach((item: any, index: number) => {
    initialData.speedDataList[index] = 0;
    initialData.suddenStartDataList[index] = 0;
    initialData.suddenBrakingDataList[index] = 0;
    specifiedYmReport?.find((n: any) => {
      if (n.start_at_ymd.substring(6, 8) === item.substring(0, 2)) {
        initialData.suddenStartDataList[index] = n.rapid_acceleration_times_sum;
        initialData.suddenBrakingDataList[index] =
          n.rapid_deceleration_times_sum;
      }
    });
  });
  initialData.diagnosis_report_id = monthlyReport.diagnosis_report_id;
  initialData.game_id = monthlyReport.game_id ?? '';
  initialData.game_title = monthlyReport.game_title ?? '';
  initialData.game_url = monthlyReport.game_url ?? '';
  return initialData;
};

export const useWatchingServiceDriveMonthlyScore = () => {
  const [data, setData] = useState<MonthlyDriveReportApiResponse>(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(true);
  const [error, setError] = useState<AxiosError>();

  const fetchMonthlyDriveReport = async (params: MonthlyPhydReportParams) => {
    try {
      setIsLoading(true);
      const { data } = await fetchDriveMonthlyScoreApi(params);
      if (data.success) setIsNoData(false);
      setData(convertMonthlyDriveReport(data.data));
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    fetchMonthlyDriveReport,
    data,
    isLoading,
    isNoData,
    error,
  };
};
