import { Accordion, AccordionItem } from 'framework7-react';
import { AccordionProps } from 'framework7-react/components/accordion';
import React from 'react';

import { FieldError } from 'react-hook-form';
import { Label, RequiredLabelProps } from '../../Label/Label';
import style from './FieldWrapperStyles.module.css';

interface FieldWrapperProps extends RequiredLabelProps {
  children: React.ReactNode;
  label?: string;
  error?: FieldError;
  AccordionProps?: AccordionProps;
}

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, error, children, AccordionProps, ...labelProps } = props;

  return (
    <Accordion {...AccordionProps}>
      {label}
      {label && <Label {...labelProps} />}
      <div className={`${label ? 'u-margin-top-sm' : 'u-margin-top-0'}`}>
        {children}
      </div>
      <AccordionItem opened={!!error?.message}>
        {error?.message && (
          <span className={style['err-msg']}>{error.message}</span>
        )}
      </AccordionItem>
    </Accordion>
  );
};
