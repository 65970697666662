import { IconProps } from './type';

export const ArrowDownIcon = ({
  width = '15',
  height = '12',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15 12"
      fill="none"
    >
      <path d="M15 0L7.5 12L0 -6.55671e-07L15 0Z" fill={color} />
    </svg>
  );
};
