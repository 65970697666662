import { FC, useMemo } from 'react';
import { TRADE_GUARANTEE_DOCUMENTS_STEPS } from '../TradeGuaranteeDocumentsPage';
import { Upload } from '../Upload/Upload';
import { UploadDocuments } from '../UploadDocuments/UploadDocuments';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { Documents, WarrantyDocument } from '@/types/api/warrantyApi';

interface TradeGuaranteeDocumentsProps {
  step: number;
  uploadedDocument: Record<number, Documents>;
  warrantyDocument: WarrantyDocument[];
  selectedDocument?: WarrantyDocument;
  setStep: (step: number) => void;
  handleClickDocument: (document: WarrantyDocument) => void;
  handleUpload: (files: File[]) => Promise<void>;
  handleClickBack: () => void;
}

export const TradeGuaranteeDocuments: FC<TradeGuaranteeDocumentsProps> = ({
  step,
  uploadedDocument,
  warrantyDocument,
  selectedDocument,
  setStep,
  handleClickDocument,
  handleUpload,
  handleClickBack,
}) => {
  const title = useMemo(() => {
    if (step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD_DOCUMENTS)
      return '取引保証の有効化';
    if (step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD)
      return selectedDocument?.name || '';
    return '';
  }, [selectedDocument?.name, step]);

  return (
    <PageTemplateV2
      pageName="TradeGuaranteeDocuments"
      title={title}
      showNavBack={true}
      handleClickBack={handleClickBack}
    >
      {step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD_DOCUMENTS && (
        <UploadDocuments
          warrantyDocument={warrantyDocument}
          uploadDocument={uploadedDocument}
          handleClickDocument={handleClickDocument}
        />
      )}
      {step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD && (
        <Upload
          handleUpload={handleUpload}
          setStep={setStep}
          uploadedDocument={uploadedDocument}
          selectedDocument={selectedDocument}
        />
      )}
    </PageTemplateV2>
  );
};
