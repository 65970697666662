import { AxiosError } from 'axios';
import { useState } from 'react';
import { updateVehicleStrikeSettingApi } from '@/api/driveRecordApi';
import { useToast } from '@/hooks/useToast';
import {
  StrikeSettingApiParams,
  StrikeSettingApiResponse,
} from '@/types/api/driveRecordApi';

interface Args {
  params: StrikeSettingApiParams;
  resolve?: (data: StrikeSettingApiResponse['data']) => void;
  enabled?: boolean;
}

export const useVehicleStrikeSettings = () => {
  const { openToast } = useToast();
  const [data, setData] = useState<StrikeSettingApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const updateVehicleStrikeSetting = async ({
    params,
    resolve,
    enabled = true,
  }: Args) => {
    try {
      if (!enabled) {
        return;
      }
      setIsLoading(true);
      const { data } = await updateVehicleStrikeSettingApi(params);
      setData(data);
      resolve?.(data);
    } catch (error) {
      openToast('予期せぬエラーが発生しました', 'toast-failed');
      setError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    updateVehicleStrikeSetting,
  };
};
