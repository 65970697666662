import { Link, Page, Popup } from 'framework7-react';
import { PopupProps } from 'framework7-react/components/popup';
import { FC, ReactNode } from 'react';
import style from './popupLandscapeStyle.module.css';

interface PopupLandscapeProps extends PopupProps {
  pageName: string;
  title: string;
  iconType?: string;
  children: ReactNode;
  isCloseIcon?: boolean;
  handleBack?: () => void;
}

export const PopupLandscape: FC<PopupLandscapeProps> = ({
  pageName,
  title,
  iconType = 'close',
  isCloseIcon = true,
  children,
  handleBack = undefined,
  ...rest
}) => {
  return (
    <Popup {...rest}>
      <Page name={pageName}>
        {isCloseIcon && (
          <div className={style['close-wrapper']}>
            <Link
              popupClose={!handleBack}
              onClick={handleBack}
              animate
              className={style['close']}
            >
              <i className={`icon-${iconType}`} style={{ color: '#232323' }} />
            </Link>
          </div>
        )}
        <div className={style['popup-container']}>{children}</div>
      </Page>
    </Popup>
  );
};
