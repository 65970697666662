import { parse } from 'date-fns';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { UpdateWarrantyComplete } from './UpdateWarrantyComplete/UpdateWarrantyComplete';
import { store } from '@/config/store';
import { UpdateWarrantyCompleteWarrantyInfo } from '@/types/warranty';
import { hyphenToDay } from '@/utils/date';

interface UpdateWarrantyCompletePage {
  f7router: Router.Router;
  warrantyNo: string;
}
export const UpdateWarrantyCompletePage: FC<UpdateWarrantyCompletePage> = ({
  f7router,
  warrantyNo,
}) => {
  const carInfo = store.state.carsInfo.find(
    (car) => car.warranties[0]?.warranty_no === warrantyNo,
  );
  const warranty = carInfo?.warranties[0]?.append_warranties?.find(
    // TODO: 1
    (warranty) => warranty.warranty_type === 1,
  );
  const nextWarranty = carInfo?.next_warranty;

  const billInformation = store.state.billsInfo.find(
    (info) => info.warranty_no === warrantyNo,
  );

  const determineIfCancellationPeriod = (
    start?: string,
    end?: string,
  ): boolean => {
    if (!start || !end) return false;
    const today = new Date();
    return (
      parse(start, 'yyyy-MM-dd', new Date()) <= today &&
      parse(end, 'yyyy-MM-dd', new Date()) >= today
    );
  };

  const warrantyInfo: UpdateWarrantyCompleteWarrantyInfo = {
    warrantyNo,
    warrantyName: warranty?.warranty_name || '',
    warrantyStartDate: warranty?.warranty_start_date || '',
    warrantyEndDate: warranty?.warranty_end_date || '',
    deductibleS: warranty?.deductible_s || 0,
    deductibleO: warranty?.deductible_o || 0,
    amountLimit: warranty?.amount_limit || 0,
    maxTrip: warranty?.max_trip || 0,
    yearsLimit: warranty?.years_limit || 0,
    // TODO: vue側でbill_infomation_detailsが配列なのにドット記法で参照している。
    // payment_type_id: billInfomations.bill_infomation_details.m_payment_type_id,
    // payment_type_name: billInfomations.bill_infomation_details.payment_type_name,
    // account_number: billInfomations.bill_infomation_details.account_number,
    // card_no: billInfomations.bill_infomation_details.card_no,
    nextBillDate: billInformation?.next_bill_date || '',
    nextBillAmount: billInformation?.next_bill_amount || 0,
    latestResult: billInformation?.latest_result || '',
    cancellationPeriodFrom: nextWarranty?.[0]?.cancellation_period_from || '',
    cancellationPeriodTo: nextWarranty?.[0]?.cancellation_period_to || '',
    cancelEnableTerm: determineIfCancellationPeriod(
      nextWarranty?.[0]?.cancellation_period_from,
      nextWarranty?.[0]?.cancellation_period_to,
    ),
    cancelEnableTermStart: nextWarranty?.[0]?.cancellation_period_from
      ? hyphenToDay(nextWarranty?.[0]?.cancellation_period_from ?? '', true)
      : '',
    cancelEnableTermEnd: nextWarranty?.[0]?.cancellation_period_to
      ? hyphenToDay(nextWarranty?.[0]?.cancellation_period_to ?? '', true)
      : '',
  };

  return (
    <UpdateWarrantyComplete f7router={f7router} warrantyInfo={warrantyInfo} />
  );
};
