import { Router } from 'framework7/types';
import { DriveHistoryPage } from '../DriveHistory/DriveHistoryPage';
import { DriveShockHistoryPage } from '../DriveShockHistory/DriveShockHistoryPage';
import { DriveRecordData } from './DriveRecordData/DriveRecordData';
import { DriveRecordHistory } from './DriveRecordHistory/DriveRecordHistory';
import { paths } from '@/config/paths';

export type DriveRecordTabRoute = Router.RouteParameters & {
  id: string;
  name: string;
};

export const driveRecordRoutes: Router.RouteParameters[] = [
  {
    path: paths.driveData,
    component: DriveRecordData,
    name: 'データ',
  },
  {
    path: paths.driveHistory,
    component: DriveHistoryPage,
    name: '運転履歴',
  },
  {
    path: paths.driveShockHistory,
    component: DriveShockHistoryPage,
    name: '衝撃履歴',
  },
  {
    path: paths.driveHistoryDetail,
    component: DriveRecordHistory,
    name: '衝撃履歴',
  },
];
