import { useConnectorStatus } from './api/linkDrive/useConnectorStatus';
import { eventStatusLaunch, mode } from '@/consts/linkDrive';
import {
  ConnectorStatusApiParams,
  ConnectorStatusApiResponse,
} from '@/types/api/linkDriveApi';

const DEFAULT_TIMEOUT = 3000;
const errorMessage =
  'エラーコード：LD0009<br><br>デバイスの接続状況を確認できませんでした。デバイスが起動されているかお確かめください。';

export const useConnectorStatusRecursiveCall = () => {
  const { fetchConnectorStatus } = useConnectorStatus();

  const connectorStatusRecursiveCall = (
    remainingCallCount: number,
    params: ConnectorStatusApiParams,
    resolve: (data?: ConnectorStatusApiResponse['data'] | undefined) => void,
    onFailure?: (message?: string) => void,
    timeout?: number,
  ) => {
    // ベースケース
    // コネクタ状態取得APIが20回呼ばれたら強制的にコネクタJOB依頼APIをコールする
    if (remainingCallCount <= 0) {
      resolve(undefined);
      return;
    }
    setTimeout(
      (message?: any) => {
        fetchConnectorStatus({
          params: params,
          resolve: (data) => {
            if (!data.success) {
              if (
                data.error?.code === 'DATA_NOT_AVAILABLE' ||
                data.error?.message === 'LinkDriveConnectorStatus情報なし'
              ) {
                connectorStatusRecursiveCall(
                  remainingCallCount - 1,
                  params,
                  resolve,
                  onFailure,
                );
              } else {
                onFailure?.(errorMessage);
              }
            }

            if (data.success) {
              if (data.data.event === mode.sleep) {
                resolve(data);
              } else if (
                data.data.event === mode.launch &&
                data.data.event_status === eventStatusLaunch.COMPLETE_STANDBY
              ) {
                resolve(data);
                return;
              } else {
                connectorStatusRecursiveCall(
                  remainingCallCount - 1,
                  params,
                  resolve,
                  onFailure,
                );
              }
            }
          },
        });
      },
      timeout === undefined ? DEFAULT_TIMEOUT : timeout,
    );
  };

  return { connectorStatusRecursiveCall };
};
