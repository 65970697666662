import perse from 'html-react-parser';
import { FC } from 'react';
import style from './warrantyTermsStyle.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';

interface WarrantyTermsProps {
  warrantyTerms: string;
}
export const WarrantyTerms: FC<WarrantyTermsProps> = ({ warrantyTerms }) => {
  var startIndex = warrantyTerms.indexOf('<table>');
  var endIndex = warrantyTerms.indexOf('</table>') + '</table>'.length;
  var tableContent = warrantyTerms.substring(startIndex, endIndex);
  var wrappedTable =
    `<div className="${style['scrollable-table']}">` + tableContent + '</div>';
  var modifiedString =
    warrantyTerms.substring(0, startIndex) +
    wrappedTable +
    warrantyTerms.substring(endIndex);

  return (
    <PageTemplate pageName="WarrantyTerms" showNavBack={true} title="保証規約">
      <RoundSheet>
        <div className={style.container}>{perse(modifiedString)}</div>
      </RoundSheet>
    </PageTemplate>
  );
};
