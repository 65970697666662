import axiosBase, {
  AxiosInstance,
  HeadersDefaults,
  RawAxiosRequestHeaders,
} from 'axios';
import { f7 } from 'framework7-react';
import { isApp } from './device';
import { store } from './store';
import { paths } from '@/config/paths';
import { logout } from '@/utils/logout';

export const axiosSetting = {
  BASEURL: import.meta.env.VITE_MAIKURU_API,
  WITH_CREDENTIALS: true,
  HEADERS: {
    'Content-Type': 'application/json',
  },
};

const errorMessageRes = {
  SESSION_TIMEOUT: 'Unauthenticated.',
} as const;

const configInterceptor = (baseApi: AxiosInstance) => {
  baseApi.interceptors.request.use((config) => {
    const token = store.state.authInfo.token;
    if (token && !!config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

  baseApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.data.message === errorMessageRes.SESSION_TIMEOUT) {
        f7.dialog.alert(
          '再度ログインしてください',
          'セッションの有効期限が切れました',
          () => {
            f7.popup.close();
            if (isApp) {
              // ネイティブアプリの場合、ログアウトせずログインチュートリアル画面に遷移させる
              // ログアウトしてしまうと、ネイティブ側の処理としてローカルに保持しているtokenを破棄してしまうため
              f7.view.main.router.navigate(paths.loginTutorial);
            } else {
              logout();
            }
          },
        );
        return;
      }
      return error.response || error;
    },
  );

  return baseApi;
};

export const createBaseApi = (
  header?: RawAxiosRequestHeaders | Partial<HeadersDefaults>,
) => {
  const baseApi = axiosBase.create({
    baseURL: axiosSetting.BASEURL,
    withCredentials: axiosSetting.WITH_CREDENTIALS,
    headers: header || axiosSetting.HEADERS,
  });

  return configInterceptor(baseApi);
};

export const baseApi = createBaseApi();
export const baseFormDataApi = createBaseApi({
  'Content-Type': 'multipart/form-data',
});
