import { FC, useState } from 'react';
import { PopupSuccess } from './PopupSuccess';
import style from './TradeConfirmApplicationDetailPage.module.css';
import { TradeContentDetail } from './TradeContentDetail';
import { Button } from '@/components/uiParts/Button';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';

export const TradeConfirmApplicationDetailPage: FC = () => {
  const [opened, setOpened] = useState(false);
  return (
    <PageTemplateV2
      pageName="TradeConfirmApplicationDetail"
      title="申請内容の確認"
      showNavBack
      containerBackgroundColor="#FFFFFF"
    >
      <div className={style['content-page']}>
        <TradeContentDetail />

        <PopupSuccess opened={opened} onPopupClosed={() => setOpened(false)} />
        <div
          className={style['trade-confirm-bottom']}
          onClick={() => setOpened(true)}
        >
          <Button>この内容で申請</Button>
        </div>
      </div>
    </PageTemplateV2>
  );
};
