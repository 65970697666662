import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useMemo, useState } from 'react';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { AuthInfoState } from '@/config/initialState';
import { store } from '@/config/store';
import { useHouseprepaidApplication } from '@/hooks/api/prepaid/useHouseprepaidApplication';
import { useHouseprepaidRestart } from '@/hooks/api/prepaid/useHouseprepaidRestart';
import './prepaidRestartStyle.scss';

type PrepaidRestart = {
  f7router: Router.Router;
  event?: () => void;
};

export const PrepaidRestart: FC<PrepaidRestart> = ({ f7router, event }) => {
  const handleAddChargeAmountClick = (value: number) => {
    setAmount((amount ?? 0) + value);
  };

  const authInfo = useStore(store, 'authInfo') as AuthInfoState; //store.state.authInfo;

  const params = useMemo(
    () => ({
      m_customer_id: authInfo.m_customer_id,
    }),
    [authInfo.m_customer_id],
  );

  const { data } = useHouseprepaidApplication(params);
  const chargeMin = data?.houseprepaids?.slice(-1)[0].monthly_charge_min ?? 0;
  const chargeMax = data?.houseprepaids?.slice(-1)[0].monthly_charge_max ?? 0;

  const { restartHouseprepaid } = useHouseprepaidRestart();

  const handleChargeClickAction = async () => {
    if (!amount) return;
    if (amount < chargeMin) {
      f7.dialog.alert(
        '',
        '積み立て金額が月額チャージ最低金額(' +
          chargeMin.toLocaleString() +
          '円)を下回っています',
      );
      return;
    }

    if (amount > chargeMax) {
      f7.dialog.alert(
        '',
        '積み立て金額が月額チャージ最高金額(' +
          chargeMax.toLocaleString() +
          '円)を上回っています',
      );
      return;
    }
    const changePrepaidParams = {
      m_customer_id: authInfo.m_customer_id,
      prepaid_fee: amount,
    };

    const stopRestartPrepaidParams = {
      m_customer_id: authInfo.m_customer_id,
      proc_kbn: 2,
    };
    await restartHouseprepaid(stopRestartPrepaidParams, changePrepaidParams);
  };

  const amountList = [
    { text: '+100円', value: 100 },
    { text: '+1,000円', value: 1000 },
    { text: '+3,000円', value: 3000 },
  ];

  const [amount, setAmount] = useState<number>();

  const changeAmount = (input: number) => {
    const amount = input > 0 ? input : 0;
    setAmount(amount);
  };

  return (
    <PopupPageTemplate
      id="PrepaidRestart"
      title="積み立てを再開"
      pageName="PrepaidRestart"
    >
      <div style={{ marginTop: '20px' }}>
        <div className="u-font-size-xl u-font-bold u-margin-bottom-md">
          毎月の積み立て金額を設定してください
        </div>
        <div className="input-group u-margin-bottom-md">
          <div className="input-group-area">
            <input
              type="number"
              className="u-font-bold"
              placeholder="0"
              value={amount}
              onChange={(e) => changeAmount(Number(e.target.value))}
            />
          </div>
          <div className="input-group-icon u-font-bold">
            <span>円</span>
          </div>
        </div>
        <div className="button-group u-margin-bottom-sm">
          {amountList.map((item, index) => (
            <div
              key={index}
              className="u-font-size-lg"
              onClick={() => handleAddChargeAmountClick(item.value)}
            >
              {item.text}
            </div>
          ))}
        </div>
        <div className="u-font-bold u-padding-top-xs">
          ※最低積み立て金額は{chargeMin?.toLocaleString()}円/月です。
        </div>
        <div className="u-font-bold u-padding-top-xs">
          ※1,000円単位で設定できます。
        </div>
        <Button
          text="積み立てを再開する"
          isActive={Boolean(amount)}
          handleClickAction={handleChargeClickAction}
          style={{ width: '48%', margin: '10rem auto 0' }}
        />
      </div>
    </PopupPageTemplate>
  );
};
