import { FC } from 'react';
import style from './AlertCountStyle.module.css';

type AlertCountProps = {
  total: number;
  average: number;
};

export const AlertCount: FC<AlertCountProps> = ({ total, average }) => {
  return (
    <div className={style['alert-wrap']}>
      <div className={style['frequency-wrap']}>
        <span>全</span>
        <span className={style.frequency}>{total}</span>
        <span>回</span>
      </div>
      <div className={style['average-wrap']}>
        <p>
          走行ごとの平均<span className={style.average}>{average}</span>回
        </p>
      </div>
    </div>
  );
};
