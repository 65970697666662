import { FC, useMemo } from 'react';
import style from './CarOrderDetailStyle.module.scss';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paymentMethods } from '@/consts/carOrder';
import { PurchaseOrder, PurchaseOrderOptionItem } from '@/types/api/carApi';
interface CarOrderDetailProps {
  purchaseOrder?: PurchaseOrder;
  optionItemList?: PurchaseOrderOptionItem[];
}

function getPaymentValue(value?: number | null) {
  if (value == null) {
    return '-円';
  }
  return `${value.toLocaleString()}円`;
}

export const CarOrderDetail: FC<CarOrderDetailProps> = ({ purchaseOrder }) => {
  const detailData = useMemo(() => {
    if (purchaseOrder?.payment_method === paymentMethods.LOAN) {
      return [
        {
          title: '支払いプラン',
          contents: [
            {
              label: '支払い回数',
              value: `${purchaseOrder.payment_times || 0}回`,
            },
            {
              label: '利率',
              value: `${purchaseOrder.interest_rate || 0}%`,
            },
            {
              label: 'ボーナス回数/年',
              value: `${purchaseOrder.payment_bonus_times || 0}回`,
            },
            {
              label: 'ボーナス加算額',
              value: getPaymentValue(purchaseOrder.payment_bonus_additional),
            },
            {
              label: '据置金額',
              value: getPaymentValue(purchaseOrder.payment_remainder_setting),
            },
          ],
        },
        {
          title: '支払いシミュレーション',
          contents: [
            {
              label: '初回支払額',
              value: getPaymentValue(purchaseOrder.payment_first),
            },
            {
              label: `月々支払額(${
                purchaseOrder?.payment_times
                  ? purchaseOrder.payment_times - 1
                  : 0
              }回)`,
              value: getPaymentValue(purchaseOrder.payment_after_first),
            },
            {
              label: '割賦手数料',
              value: getPaymentValue(purchaseOrder.split_fee),
            },
            {
              label: '頭金',
              value: getPaymentValue(purchaseOrder.payment_cash),
            },
            {
              label: '最終回支払金額',
              value: getPaymentValue(purchaseOrder.payment_final),
            },
            {
              className: style['card-row-footer'],
              label: 'ローン支払い総額',
              value: getPaymentValue(purchaseOrder.payment_loan_total),
            },
          ],
        },
      ];
    }
    if (purchaseOrder?.payment_method === paymentMethods.LEASE) {
      return [
        {
          title: '支払いシミュレーション',
          contents: [
            {
              label: '初回支払額',
              value: getPaymentValue(purchaseOrder.payment_first),
            },
            {
              label: `二回目以降支払い額（${
                purchaseOrder?.lease_contract_term
                  ? purchaseOrder.lease_contract_term - 1
                  : 0
              }回）`,
              value: purchaseOrder?.lease_residual_value
                ? getPaymentValue(purchaseOrder.payment_after_first)
                : '-円',
            },
            {
              label: '頭金',
              value: getPaymentValue(purchaseOrder.payment_cash),
            },
          ],
        },
      ];
    }
    return [];
  }, [purchaseOrder]);

  return (
    <PageTemplate pageName="CarOrderDetail" title="お支払い情報" showNavBack>
      <div className={style['container']}>
        <div className={style['card']}>
          <div className={style['card-head-main']}>
            <p>総額</p>
            <p>{getPaymentValue(purchaseOrder?.total_price)}</p>
          </div>
          <div className={style['card-head']}>
            <p>支払い方法</p>
            <p className={style['payment-method']}>
              {purchaseOrder?.payment_method_name || ''}
            </p>
          </div>
          {purchaseOrder?.payment_method === paymentMethods.LOAN ? (
            <>
              <div className={`${style['card-row']} ${style['hide-border']}`}>
                <p>借入金（元金）</p>
                <p>{getPaymentValue(purchaseOrder.payment_loan)}</p>
              </div>
              <div className={style['card-row']}>
                <p>頭金</p>
                <p>{getPaymentValue(purchaseOrder.payment_cash)}</p>
              </div>
            </>
          ) : (
            <>
              <div className={style['card-row']}>
                <p>リース期間</p>
                <p>{purchaseOrder?.lease_contract_term || 0}ヶ月</p>
              </div>
              <div className={style['card-row']}>
                <p>ボーナス回数/年</p>
                <p>{purchaseOrder?.payment_bonus_times || 0}回</p>
              </div>
              <div className={style['card-row']}>
                <p>ボーナス加算額</p>
                <p>
                  {getPaymentValue(purchaseOrder?.payment_bonus_additional)}
                </p>
              </div>
              <div className={style['card-row']}>
                <p>残価</p>
                <p>{getPaymentValue(purchaseOrder?.lease_residual_value)}</p>
              </div>
              <div className={style['card-row']}>
                <p>想定走行距離</p>
                <p className={style['expected-monthly-meter-count']}>
                  {purchaseOrder?.expected_monthly_meter_count || ''}
                </p>
              </div>
            </>
          )}
        </div>
        {detailData?.map((data, index) => (
          <div key={`data-${index}`} className={style['card']}>
            <div className={style['card-head-main']}>
              <p>{data.title}</p>
            </div>
            {data.contents.map((content, contentIndex) => (
              <div
                key={`content-${contentIndex}`}
                className={`${style['card-row']} ${content.className || ''}`}
              >
                <p>{content.label}</p>
                <p>{content.value}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </PageTemplate>
  );
};
