import { FC } from 'react';
import { AboutContent } from './AboutContent';
import style from './CarWashConditionerAboutPageStyle.module.css';
import { BlankPageTemplate } from '@/components/uiParts/Template/BlankPageTemplate/BlankPageTemplate';

type CarWashConditionerAboutPageProps = {
  showLink?: boolean;
};

export const CarWashConditionerAboutPage: FC<
  CarWashConditionerAboutPageProps
> = ({ showLink = true }) => {
  return (
    <BlankPageTemplate
      title="出張カーエアコンクリーニング"
      pageName="CarWashConditionerAbout"
      showNavBack={showLink}
    >
      <div className={style['full-page']}>
        <AboutContent showLink={showLink} />
      </div>
    </BlankPageTemplate>
  );
};
