import { FC, useMemo } from 'react';
import style from './DriveShockItemStyles.module.scss';
import { shockHistoryLevel } from '@/consts/shockHistory';
export interface DriveShockItemProps {
  level: number;
  time: string;
}

export const DriveShockItem: FC<DriveShockItemProps> = ({ level, time }) => {
  const levelOpt = useMemo(() => {
    switch (level) {
      case shockHistoryLevel.lagre:
        return { class: 'level_large', label: '衝撃度：大' };
      case shockHistoryLevel.medium:
        return { class: 'level_medium', label: '衝撃度：中' };
      case shockHistoryLevel.small:
        return { class: 'level_small', label: '衝撃度：小' };
    }
  }, [level]);
  return (
    <div className={style['content_wrapper']}>
      <div
        className={`${style['level']} ${levelOpt && style[levelOpt?.class]}`}
      >
        {levelOpt?.label}
      </div>
      <p>{time}</p>
    </div>
  );
};
