import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { getTermsApi } from '@/api/termsApi';
import { GetTermsApiResponse, TermType } from '@/types/api/termsApi';

export const useTerm = (
  termType?: TermType,
  mPrepaidsId?: string,
  isFetched: boolean = false,
) => {
  const [data, setData] = useState<GetTermsApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    if (!termType || isFetched) return;
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await getTermsApi({
          term_type: termType,
          m_prepaids_id: mPrepaidsId ?? '',
        });
        if (typeof res.data.error !== 'boolean') {
          throw new Error(res.data.error.message);
        }
        setData(res.data);
      } catch (err) {
        if (err instanceof Error) {
          console.error(err.message);
        }
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [termType, isFetched]);

  return {
    data,
    isLoading,
    error,
  };
};
