import React, { FC } from 'react';
import { DrivingScoreTotal } from './DrivingScoreTotal/DrivingScoreTotal';
import { MonthlyPhydReportResponse } from '@/types/api/driveRecordApi';
type DrivingScoreTotalPageProps = {
  monthlyReport: MonthlyPhydReportResponse['data']['data'][0]['monthly_report'];
};

export const DrivingScoreTotalPage: FC<DrivingScoreTotalPageProps> = ({
  monthlyReport,
}) => {
  return <DrivingScoreTotal monthlyReport={monthlyReport} />;
};
