import { List, ListItem, f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import './TroubleServiceStyle.scss';

interface TroubleServiceProps {
  f7router: Router.Router;
  pageContent: boolean;
}

export const TroubleService: FC<TroubleServiceProps> = ({
  pageContent = true,
}) => {
  const handleClick = () => {
    f7.view.main.router.navigate('/trouble/trouble_terms/');
    document.querySelector('#page-template-content')!.scrollTop = 0;
  };
  return (
    <div className="card-top" data-name="TroubleService">
      <div className="u-font-size-xxl u-font-bold u-margin-bottom-lg u-margin-top-sm">
        下記の作業を無料で承ります
        <p className="sub-text">
          ※一部有料での対応となります。
          <br />
          詳しくは、各項目の詳細をご確認ください。
        </p>
      </div>
      <RoundSheet>
        <div className="card-header">レッカー牽引</div>
        <div className="card-content">
          事故や故障により、自力での走行ができない時、
          <span style={{ fontWeight: 'bold', color: '#1e55e6' }}>
            最大50kmまで無料
          </span>
          搬送いたします。
          <br />
          ※事故による不動時も対応いたします。
          <p className="weak-text">
            ※超過分および高速道路利用料金は有料となります
          </p>
        </div>
      </RoundSheet>
      <div className="round">
        <RoundSheet>
          <div className="card-header">キーとじこみ開錠作業</div>
          <div className="card-content">
            車の鍵を車内においたままドアをロックして外に出てしまった場合のドアの開錠をいたします。
            <br />
            一般キーシリンダーの開錠は無料で対応いたします。
            <div className="u-margin-top-sm ">
              ※下記の開錠作業は有料となります
              <div className="u-padding-left-sm u-padding-right-sm u-margin-top-s u-margin-bottom-0">
                <ul>
                  <li>
                    <span className="listitem">トランクの直接開錠作業</span>
                  </li>
                  <li>
                    <span className="listitem">特殊構造キーの場合</span>
                  </li>
                  <li>
                    <span className="listitem">電子ロックキーの場合</span>
                  </li>
                  <li>
                    <span className="listitem">盗難防止装置付き車両の場合</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </RoundSheet>
      </div>
      <div className="round">
        <RoundSheet>
          <div className="card-header">パンク時スペアタイヤ交換</div>
          <div className="card-content">
            お持ちのスペアタイヤとの交換作業を、無料で対応いたします。
            <p className="u-margin-top-sm" style={{ fontSize: '1.4rem' }}>
              ※スペアタイヤをお持ちでない場合、タイヤ代はお客様負担となります
            </p>
          </div>
        </RoundSheet>
      </div>
      <div className="round">
        <RoundSheet>
          <div className="card-header">ガス欠時給油</div>
          <div className="card-content">
            ガソリンがなくなった場合に、給油作業を行います。（ディーゼル車の場合、エア抜き作業も含みます。）
            <p className="u-margin-top-sm subtext">
              ※燃料代はお客様負担となります
            </p>
          </div>
        </RoundSheet>
      </div>
      <div className="round">
        <RoundSheet>
          <div className="card-header">バッテリー上がりの解消</div>
          <div className="card-content">
            ランプの消し忘れなどにより、車の電力を使いすぎてエンジンがかからなくなってしまった時に、バッテリーの復旧作業を行います。
            <p className="u-margin-top-sm subtext">
              ※電気代はお客様負担となります
            </p>
          </div>
        </RoundSheet>
      </div>
      <div className="round">
        <RoundSheet>
          <div className="card-header">各種遠方サポートサービス</div>
          <div className="card-content">
            <div className="u-margin-bottom-0">
              <List mediaList>
                <ListItem title="">
                  出先でロードサービスをご利用の際、移動や宿泊のためのサポートを承ります。
                </ListItem>
                <ListItem title="レンタカーサポート">
                  ロードサービス会社指定のレンタカーが
                  <span style={{ fontWeight: 'bold', color: '#1e55e6' }}>
                    基本料金無料
                  </span>
                  となります。
                </ListItem>
                <ListItem title="代替公共機関サポート">
                  会員様、および同乗者様
                  <span style={{ fontWeight: 'bold', color: '#1e55e6' }}>
                    一人当たり20,000円（税込）まで交通費を負担
                  </span>
                  いたします。
                </ListItem>
                <ListItem title="緊急宿泊費用サポート">
                  会員および同乗者
                  <span style={{ fontWeight: 'bold', color: '#1e55e6' }}>
                    一人当たり15,000円（税込）まで宿泊費用を負担
                  </span>
                  いたします。
                </ListItem>
              </List>
            </div>
            <div>
              <p style={{ paddingTop: '10px' }}>
                ※それぞれ別途規定がございます
              </p>
              <p style={{ padding: '10px' }}>
                （詳細はロードサービス利用規約第８条をご確認ください）
              </p>
            </div>
            <IconCard
              iconType=""
              color="gray"
              headingFontSize="14px"
              iconFontSize="25px"
              iconColor="#1e55e6"
              isTransition
              heading={'ロードサービス利用規約'}
              onClick={handleClick}
            />
          </div>
        </RoundSheet>
      </div>
      {!pageContent && <div style={{ height: '10rem' }} />}
    </div>
  );
};
