import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { serviceStart } from '@/api/linkDriveApi';
import {
  ServiceStartApiParams,
  ServiceStartApiResponse,
} from '@/types/api/linkDriveApi';

interface Args {
  params: ServiceStartApiParams;
  resolve: () => void;
}

export const useServiceStart = () => {
  const [data, setData] = useState<ServiceStartApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const startService = async (args: Args) => {
    try {
      const { data } = await serviceStart(args.params);
      setData(data);
      if (data.success) args.resolve();
      if (!data.success) {
        f7.dialog.alert(
          data?.error?.message ?? 'エラーが発生しました',
          '',
          () => {
            f7.dialog.close();
          },
        );
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    startService,
    error,
  };
};
