import { paths } from '@/config/paths';
import { ecuTypes, simStatus } from '@/consts/linkDrive';
import { LinkDriveOwner } from '@/types/api/infoApi';
import { formatToJaDate } from '@/utils/date';

export const systemInfoLabelList = (isAvaiableForUpdate: boolean) => [
  {
    label: 'データ',
    props: isAvaiableForUpdate
      ? 'アップデート情報があります'
      : 'アップデート情報がありません。',
  },
];

export const connectorInfoLabelList = (
  model: string | undefined,
  linkDriveOwnerInfo: LinkDriveOwner,
) => {
  const { serial_no, regist_date, sim_status, ecu_type, firmware } =
    linkDriveOwnerInfo;

  const ONE_DECIMAL_PLACE = 1;

  return [
    { label: 'シリアルナンバー', props: serial_no },
    {
      label: '回線状態',
      props: simStatus.find((status) => status.type === sim_status)?.text ?? '',
    },
    {
      label: '製品モデル',
      props: model ?? '',
    },
    {
      label: 'モード',
      props:
        ecuTypes.find((ecuType) => ecuType.type === Number(ecu_type))?.text ??
        '',
    },
    {
      label: 'ファームウェア',
      props: Number(firmware).toFixed(ONE_DECIMAL_PLACE),
    },
    {
      label: '初回登録日',
      props: formatToJaDate(new Date(regist_date || Date.now())),
    },
  ];
};

export const noticeList = [
  {
    label: '車両からの通知設定',
    remark: '衝撃通知や盗難防止通知の設定を行います',
    props: 'vehicleNoticeSetting',
    path: paths.vehicleNoticeSettings,
    cancelable: true,
  },
  {
    label: 'データ利用設定',
    remark: '車両データを使ったサービスの設定を行います',
    props: 'dataUsingSetting',
    path: paths.dataUsingSetting,
    cancelable: false,
  },
];

export const linkDriveHelpList = [
  {
    label: 'LINKDrive　利用規約',
    props: 'using',
    path: paths.linkDriveTerm,
    cancelable: true,
    remark: '',
    rightIcon: '',
  },
  {
    label: 'コネクター保証規定',
    props: 'connectorTerms',
    path: paths.connecterTerms,
    cancelable: false,
    remark: '',
    rightIcon: '',
  },
];
