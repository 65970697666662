import { FC } from 'react';
import style from './BoxCarditemStyle.module.scss';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
interface BoxCarditemProps {
  displayOpt: DisplayOptProps;
}
export interface DisplayOptProps {
  content: Content[];
  box: Box[];
}

export interface Box {
  content?: Content[];
  title: Title[];
}

export interface Title {
  header?: string;
  content: Content[];
}

export interface Content {
  label: string;
  amount: number;
}
export const BoxCarditem: FC<BoxCarditemProps> = ({ displayOpt }) => {
  return (
    <RoundSheet radius="8px">
      <div className={style['container']}>
        {displayOpt.content?.map(({ label, amount }, idx) => (
          <div key={`${label}-${idx}`} className={style['information-group']}>
            <p className={style['information-label']}>{label}</p>
            <p className={style['information-amount']}>
              {amount?.toLocaleString()}円
            </p>
          </div>
        ))}
        {/* Box */}
        {displayOpt.box?.map(({ content, title }, idx) => (
          <div className={style['box-group']} key={idx}>
            {content?.map(({ label, amount }, idx) => (
              <div
                className={style['box-header-wrapper']}
                key={`${label}-${idx}`}
              >
                <p className={style['box-header-label']}>{label}</p>
                <p
                  className={`${style['box-header-amount']} ${
                    amount?.toLocaleString().includes('-')
                      ? style['box-content-amount-negative']
                      : ''
                  }`}
                >
                  {amount?.toLocaleString()}円
                </p>
              </div>
            ))}
            {/* Title */}
            <div className={style['container']}>
              {title?.map(({ header, content }, idx) => (
                <div key={idx} className={style['box-title-wrapper']}>
                  {header && <p className={style['box-title']}>{header}</p>}
                  {content?.map(({ label, amount }, idx) => (
                    <div
                      className={`${style['box-content-wrapper']} ${
                        header ? 'u-padding-left-xs' : ''
                      }`}
                      key={`${label}-${idx}`}
                    >
                      <p className={style['box-content-label']}>{label}</p>
                      <p
                        className={`${style['box-content-amount']} ${
                          amount?.toLocaleString().includes('-')
                            ? style['box-content-amount-negative']
                            : ''
                        }`}
                      >
                        {amount?.toLocaleString()}円
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </RoundSheet>
  );
};
