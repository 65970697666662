import parse from 'html-react-parser';
import { FC } from 'react';
import style from './drivingScoreTermsDetail.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

interface DrivingScoreTermsDetailProps {
  html?: string;
}
export const DrivingScoreTermsDetail: FC<DrivingScoreTermsDetailProps> = ({
  html,
}) => {
  if (!html) return <></>;
  return (
    <RoundSheet>
      <div className={style['container']}>{parse(html)}</div>
    </RoundSheet>
  );
};
