import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  CompanyNamesKanaValidation,
  customerSchema,
  NamesKanaValidation,
  NamesValidation,
} from '../validationSchema';
import style from './EditCustomerField.module.css';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { EditCustomerBirthdayInput } from '@/components/uiParts/EditCustomerBirthdayInput/EditCustomerBirthdayInput';
import { TextInput } from '@/components/uiParts/Input/TextInput';
import { RhfParallelInput } from '@/components/uiParts/ParallelInput/RhfParallelInput/RhfParallelInput';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { customerType } from '@/consts/customer';
import { useUpdateCustomer } from '@/hooks/api/customer/useUpdateCustomer';
import { useUpdateUserInformation } from '@/hooks/api/linkDrive/useUpdateUserInformation';
import { Customer, Car } from '@/types/api/customerApi';
import { LinkDriveOwner } from '@/types/api/infoApi';
import { fillCustomerDefaults } from '@/utils/customer';
import { createTwoDigitString } from '@/utils/date';
import { isAdContractorData } from '@/utils/home';
import { f7CustomBack } from '@/utils/utils';

interface FormInput {
  firstName: string;
  familyName: string;
  firstNameKana: string;
  familyNameKana: string;
  companyName: string;
  companyNameKana: string;
  year: string;
  month: string;
  day: string;
  isCorporation?: boolean;
}

interface EditCustomerProps {
  initialSetCustomer: (customer: Customer) => void;
}

const MinYear = 1899;

export const EditCustomerField: FC<EditCustomerProps> = ({
  initialSetCustomer,
}) => {
  const { updateLinkDriveUser } = useUpdateUserInformation();
  const { updateCustomer } = useUpdateCustomer();
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;
  const linkDriveOwnerInfo = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;
  const customerInfo = fillCustomerDefaults(
    useStore(store, 'customerInfo') as Required<Customer>,
  );
  const isAdContractor = isAdContractorData({
    linkDriveStatus: selectedCar?.linkdrive_status,
  });

  const firstName = customerInfo.first_name;
  const firstNameKana = customerInfo.first_name_kana;
  const familyName = customerInfo.family_name;
  const familyNameKana = customerInfo.family_name_kana;
  const companyName = customerInfo.company_name ?? '';
  const companyNameKana = customerInfo.company_name_kana ?? '';
  const birthdays = customerInfo.birthday.split('-');
  const year = birthdays[0] ?? '';
  const month = birthdays[1] ?? '';
  const day = birthdays[2] ?? '';
  const isCorporation =
    customerInfo.customer_type == customerType.CORPORATION ||
    customerInfo.customer_type == customerType.TRADER;

  const formMethods = useForm<FormInput>({
    resolver: yupResolver(customerSchema),
    mode: 'onTouched',
  });
  const {
    getValues,
    handleSubmit,
    formState: { isValid, errors },
    clearErrors,
  } = formMethods;

  const [namesValidation, setNamesValidation] = useState(false);
  const [namesKanaValidation, setNamesKanaValidation] = useState(false);
  const [companyNameValidation, setCompanyNameValidation] = useState(false);
  const [companyNameKanaValidation, setCompanyNameKanaValidation] =
    useState(false);
  const [datesValid, setDatesValid] = useState(false);

  const createBirthdayFormat = (year: string, month: string, day: string) => {
    if (year && month && day)
      return `${year}-${createTwoDigitString(month)}-${createTwoDigitString(
        day,
      )}`;
    return '';
  };

  const updateCustomerInformation = (data: FormInput, birthday: string) => {
    updateCustomer({
      params: {
        customer: {
          ...customerInfo,
          first_name: data.firstName,
          family_name: data.familyName,
          first_name_kana: data.firstNameKana,
          family_name_kana: data.familyNameKana,
          company_name: data.companyName,
          company_name_kana: data.companyNameKana,
          birthday,
        },
      },
      onSuccess: () => {
        initialSetCustomer({
          ...customerInfo,
          first_name: data.firstName,
          family_name: data.familyName,
          first_name_kana: data.firstNameKana,
          family_name_kana: data.familyNameKana,
          company_name: data.companyName,
          company_name_kana: data.companyNameKana,
          birthday,
        });
        f7CustomBack(['#EditCustomer']);
      },
    });
  };

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    const ownerId = linkDriveOwnerInfo.owner_id;
    const year = data.year ?? '';
    const month = data.month ?? '';
    const day = data.day ?? '';
    const birthday = createBirthdayFormat(year, month, day);
    // LinkDriveオーナーがいない場合は /v1/customer PUT
    if (!ownerId || !isAdContractor) {
      updateCustomerInformation(data, birthday);
      return;
    }

    // LinkDriveのオーナーがいる場合は/rest/linkdrive/user_info_change POST
    const params: any = {
      owner_id: ownerId,
      nick_name: data.firstNameKana,
    };

    if (isCorporation) {
      params.company_name = data.companyName;
      params.company_name_kana = data.companyNameKana;
      params.nick_name = data.companyNameKana;
      params.first_name = '';
      params.family_name = '';
      params.first_name_kana = '';
      params.family_name_kana = '';
    } else {
      params.first_name = data.firstName;
      params.family_name = data.familyName;
      params.first_name_kana = data.firstNameKana;
      params.family_name_kana = data.familyNameKana;
    }

    updateLinkDriveUser({
      params,
      onSuccess: () => updateCustomerInformation(data, birthday),
    });
  };

  const values = getValues();

  const isNamesValid = async () => {
    try {
      await NamesValidation.validate({
        firstName: values.firstName,
        familyName: values.familyName,
      });
      setNamesValidation(true);
    } catch (e) {
      setNamesValidation(false);
    }
  };

  const isNamesKanaValid = async () => {
    try {
      await NamesKanaValidation.validate({
        firstNameKana: values.firstNameKana,
        familyNameKana: values.familyNameKana,
      });
      setNamesKanaValidation(true);
    } catch (e) {
      setNamesKanaValidation(false);
    }
  };

  const isCompanyNameValid = async () => {
    try {
      await NamesKanaValidation.validate({
        companyName: values.companyName,
      });
      setCompanyNameValidation(true);
    } catch (e) {
      setCompanyNameValidation(false);
    }
  };

  const isCompanyNameKanaValid = async () => {
    try {
      await CompanyNamesKanaValidation.validate({
        companyNameKana: values.companyNameKana,
      });
      setCompanyNameKanaValidation(true);
    } catch (e) {
      setCompanyNameKanaValidation(false);
    }
  };

  useEffect(() => {
    isNamesValid();
    isNamesKanaValid();
    isCompanyNameValid();
    isCompanyNameKanaValid();
  }, [values]);

  useEffect(() => {
    if (!errors.year && !errors.month && !errors.day) return;
    const { year, month, day } = values;
    if ((!!year && !!month && !!day) || (!year && !month && !day)) {
      if (
        typeof year === 'undefined' ||
        (Number(year) > MinYear && Number(year) < new Date().getFullYear() + 1)
      )
        clearErrors(['year', 'month', 'day']);
    }
  }, [datesValid, values]);

  return (
    <PopupPageTemplate
      id="EditCustomer"
      pageName="EditCustomer"
      title="お客様基本情報の変更"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
          <div className={style['input-wrap']}>
            {isCorporation ? (
              <>
                <RhfParallelInput
                  key={'nameInput-rhf-1'}
                  label={'契約者様氏名'}
                  firstPlaceHolder={'東京'}
                  secondPlaceHolder={'太郎'}
                  firstName={'familyName'}
                  secondName={'firstName'}
                  isCheckIcon
                  isCheckIconValid={namesValidation}
                  firstError={errors.familyName?.message}
                  secondError={errors.firstName?.message}
                  required={false}
                />
                <RhfParallelInput
                  key={'nameInput-rhf-2'}
                  label={'フリガナ'}
                  firstPlaceHolder={'トウキョウ'}
                  secondPlaceHolder={'タロウ'}
                  firstName={'familyNameKana'}
                  secondName={'firstNameKana'}
                  isCheckIcon
                  isCheckIconValid={namesKanaValidation}
                  firstError={errors.familyNameKana?.message}
                  secondError={errors.firstNameKana?.message}
                  required={false}
                />
                <TextInput
                  label={'法人名'}
                  inputName={'companyName'}
                  isCheckIcon
                  isCheckIconValid={companyNameValidation}
                  error={errors.companyName?.message}
                  required={true}
                />
                <TextInput
                  label={'法人名ヨミガナ'}
                  inputName={'companyNameKana'}
                  isCheckIcon
                  isCheckIconValid={companyNameKanaValidation}
                  error={errors.companyNameKana?.message}
                  required={true}
                />
              </>
            ) : (
              <>
                <RhfParallelInput
                  key={'nameInput-rhf-3'}
                  label={'契約者様氏名'}
                  firstPlaceHolder={'東京'}
                  secondPlaceHolder={'太郎'}
                  firstName={'familyName'}
                  secondName={'firstName'}
                  isCheckIcon
                  isCheckIconValid={namesValidation}
                  firstError={errors.familyName?.message}
                  secondError={errors.firstName?.message}
                />
                <RhfParallelInput
                  key={'nameInput-rhf-4'}
                  label={'フリガナ'}
                  firstPlaceHolder={'トウキョウ'}
                  secondPlaceHolder={'タロウ'}
                  firstName={'familyNameKana'}
                  secondName={'firstNameKana'}
                  isCheckIcon
                  isCheckIconValid={namesKanaValidation}
                  firstError={errors.familyNameKana?.message}
                  secondError={errors.firstNameKana?.message}
                />
              </>
            )}
            <EditCustomerBirthdayInput
              isValid={datesValid}
              error={
                errors.year?.message ||
                errors.month?.message ||
                errors.day?.message
              }
            />
            <Button text="変更する" type="submit" />
          </div>
        </form>
      </FormProvider>
    </PopupPageTemplate>
  );
};
