import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './CustomerListStyle.module.css';

interface CustomerListProps {
  head: string | undefined;
  specificValue: string | undefined;
  path: string | undefined;
}

const handleClickNavigation = (path?: string) => {
  if (!path) return;
  f7.view.main.router.navigate(path, { animate: true });
};

export const CustomerList: FC<CustomerListProps> = ({
  head,
  specificValue,
  path,
}) => {
  return (
    <li className={style.list}>
      <a
        onClick={() => handleClickNavigation(path)}
        className={style['list-link']}
      >
        <div className={style.grid}>
          <div className={style.head}>{head}</div>
          <div className={style.specific}>{specificValue}</div>
          <div className={style.icon}>
            <i className="icon-right" />
          </div>
        </div>
      </a>
    </li>
  );
};
