import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchMonthlyReportListAPI } from '@/api/driveRecordApi';
import {
  MonthlyReportListAPIParams,
  MonthlyReportListAPIResponse,
} from '@/types/api/driveRecordApi';

export const useMonthlyReportList = ({
  m_customer_id,
  vehicle_id,
}: MonthlyReportListAPIParams) => {
  const [data, setData] = useState<MonthlyReportListAPIResponse['data']>();
  const [isNoData, setIsNoData] = useState(true);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        const res = await fetchMonthlyReportListAPI({
          m_customer_id,
          vehicle_id,
        });
        if (res.success) {
          setIsNoData(false);
        }
        setData(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };
    fn();
  }, [m_customer_id, vehicle_id]);

  return {
    data,
    isNoData,
    error,
  };
};
