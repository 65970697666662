import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckIcon } from '../Check/Check';
import { RhfInput } from '../ReactHookForm/RhfInput/RhfInput';
import style from './EditCustomerBirthdayInputStyle.module.css';

interface BirthdayInputProps {
  error?: string;
  isValid: boolean;
  isCheckIcon?: boolean;
}

export const EditCustomerBirthdayInput: FC<BirthdayInputProps> = ({
  isValid = false,
  error = null,
  isCheckIcon = false,
}) => {
  const { getValues } = useFormContext();
  const values = getValues();
  const isLeapYear =
    values.year % 4 === 0 &&
    (values.year % 100 !== 0 || values.year % 400 === 0);
  const daysInMonth = [
    31,
    isLeapYear ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];

  return (
    <div>
      <div className={style['label-wrap']}>
        <p className={style.label}>生年月日</p>
        <span className={`${style['label-tips']} ${style.optional}`}>任意</span>
      </div>
      <div className={style['input-container']}>
        <div className={style['input-wrap']}>
          <RhfInput
            className={`${style.input} ${style.year} ${
              error && style['error-input']
            }`}
            value={values.year}
            name="year"
            type="number"
            placeholder="2000年"
          />
          <RhfInput
            className={`${style.input} ${style.month} ${
              error && style['error-input']
            }`}
            name="month"
            type="number"
            placeholder="9"
            min={1}
            max={12}
          />
          <RhfInput
            className={`${style.input} ${style.day} ${
              error && style['error-input']
            }`}
            value={values.day}
            name="day"
            type="number"
            placeholder="25"
            min={1}
            max={daysInMonth[values.month - 1] ?? 1}
          />
        </div>
        <div>
          {isCheckIcon ? (
            <CheckIcon isValid={isValid} />
          ) : (
            <div className={style.emptyCheckIcon} />
          )}
        </div>
      </div>
      {error && <div className={style.error}>{error}</div>}
    </div>
  );
};
