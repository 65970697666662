import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchDriveMonthlyScoreApi } from '@/api/driveRecordApi';
import {
  MonthlyPhydReportParams,
  MonthlyPhydReportResponse,
} from '@/types/api/driveRecordApi';

export const useDriveMonthlyScore = (params: MonthlyPhydReportParams) => {
  const [data, setData] = useState<MonthlyPhydReportResponse['data']['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    const fetchDriveMonthlyScore = async () => {
      try {
        setIsLoading(true);
        const { data } = await fetchDriveMonthlyScoreApi(params);
        if (!data.success) return;
        setData(data.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fetchDriveMonthlyScore();
  }, [params]);

  return {
    data,
    isLoading,
    error,
  };
};
