import { Router } from 'framework7/types';
import { FC } from 'react';
import { SmsShouldBeAvailable } from './SmsShouldBeAvailable/SmsShouldBeAvailable';

interface SmsDisablePageProps {
  f7router: Router.Router;
}
export const SmsShouldBeAvailablePage: FC<SmsDisablePageProps> = ({
  f7router,
}) => {
  return <SmsShouldBeAvailable f7router={f7router} />;
};
