import { Router } from 'framework7/types';
import { FC } from 'react';
import { EditPhone } from './EditPhone/EditPhone';
import { store } from '@/config/store';
import { getAffiliateIdFromThemeId } from '@/utils/shop';

interface EditPhonePageProps {
  f7router: Router.Router;
}

export const EditPhonePage: FC<EditPhonePageProps> = ({ f7router }) => {
  const themeId = store.state.themeId;
  const loginType = store.state.loginInfo.loginType;
  return (
    <EditPhone
      f7router={f7router}
      affiliateId={getAffiliateIdFromThemeId(themeId)}
      loginType={loginType}
    />
  );
};
