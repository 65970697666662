import { Router } from 'framework7/types';
import { FC } from 'react';
import { LoginEmail } from './LoginEmail/LoginEmail';
import { store } from '@/config/store';
import { getAffiliateIdFromThemeId } from '@/utils/shop';

interface LoginEmailPageProps {
  f7router: Router.Router;
}

export const LoginEmailPage: FC<LoginEmailPageProps> = ({ f7router }) => {
  const themeId = store.state.themeId;
  return (
    <LoginEmail
      f7router={f7router}
      affiliateId={getAffiliateIdFromThemeId(themeId)}
    />
  );
};
