import { FC } from 'react';
import style from './CalibrationProcessBarStyle.module.scss';

interface LinkDriveCheckHorizontalProps {
  step?: number;
}

export const CalibrationProcessBar: FC<LinkDriveCheckHorizontalProps> = ({
  step,
}) => {
  const classProcess = () => {
    switch (step) {
      case 2:
        return 'stepTwo';
      case 3:
        return 'stepComplete';
      default:
        return 'stepOne';
    }
  };

  return (
    <div className={style['calibrationProcessBar']}>
      <ul className={`${style['progressbar']} ${style[classProcess()]}`}>
        <li className={style['one']}>準備</li>
        <li className={style['two']}>実施</li>
        <li className={style['three']}>完了</li>
      </ul>
    </div>
  );
};
