import { FC, InputHTMLAttributes } from 'react';
import style from './Radio.module.css';

export const Radio: FC<InputHTMLAttributes<HTMLInputElement>> = (props) => {
  return (
    <div className={style['container']}>
      <input className={style['radio-input']} type="radio" {...props} />
      <span className={style['custom-radio']} />
    </div>
  );
};
