import { FC, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './InputsStyle.module.css';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  error?: string;
  required?: boolean;
}

export const TextInput: FC<TextInputProps> = ({
  name,
  label,
  error = '',
  required = false,
  ...rest
}) => {
  const { register } = useFormContext();
  return (
    <div
      className={`${style['input-text']} ${
        !!error ? style['input-text-error'] : ''
      }`}
    >
      <label>
        {label}
        <span
          className={`${style['input-label-tips']} ${
            required && style['required']
          }`}
        >
          {required ? '必須' : '任意'}
        </span>
      </label>
      <input {...rest} {...register(name)} />
      {!!error && <p className={style['input-error-message']}>{error}</p>}
    </div>
  );
};
