import { Checkbox } from 'framework7-react';
import { FC } from 'react';
import style from './CheckBoxWithLabelStyle.module.css';

interface CheckBoxWithLabelProps {
  name: string;
  label: string;
}

export const CheckBoxWithLabel: FC<CheckBoxWithLabelProps> = ({
  name,
  label,
}) => {
  return (
    <div className={style['checkbox-wrapper']}>
      <Checkbox name={name} />
      <span>{label}</span>
    </div>
  );
};
