import { f7 } from 'framework7-react';
import { useState } from 'react';
import { adminAuthApi } from '@/api/authApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import { AdminAuthApiParams, AdminAuthApiResponse } from '@/types/api/authApi';

const FAIL_REDIRECT_PATH = paths.loginTutorial;

export const useAdminAuth = () => {
  const [data, setData] = useState<AdminAuthApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const { openToast } = useToast();

  const adminAuth = async (params: AdminAuthApiParams) => {
    f7.preloader.show();
    setIsLoading(true);

    try {
      const { data } = await adminAuthApi(params);
      if (!data.success) throw new Error(data.message || '認証に失敗しました');

      store.dispatch('setAuthInfo', {
        authInfo: {
          m_customer_id: data.m_customers.id,
          token: decodeURI(data.token),
          isLogin: true,
        },
      });

      store.dispatch('setLoginInfo', {
        loginInfo: { loginType: 'admin' },
      });

      store.dispatch('setIsLoggedInToMaikuru', {
        isLoggedInToMaikuru: true,
      });

      f7.view.main.router.navigate(paths.home, { animate: true });
    } catch (e: unknown) {
      if (e instanceof Error) {
        openToast(e.message, 'toast-failed');
      }
      f7.view.main.router.navigate(FAIL_REDIRECT_PATH, { animate: true });
      setError(e);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    adminAuth,
    isLoading,
    error,
  };
};
