import { useState } from 'react';

export const useUpdateProgress = (svgPathElement: SVGPathElement | null) => {
  const [progress, setProgress] = useState(0);

  const calculateProgressOffset = (progress: number) => {
    const offset = progress * 3.838;
    if (svgPathElement != null) {
      svgPathElement.style.strokeDashoffset = (
        Number(svgPathElement.style.strokeDashoffset) - offset
      ).toString();
    }
    return progress;
  };

  const updateProgress = (progress: number) => {
    if (svgPathElement == null) return;
    setProgress(() => calculateProgressOffset(progress));
  };

  return {
    updateProgress,
    progress,
  };
};
