import { AxiosError } from 'axios';
import { useState } from 'react';
import { fetchUserChangeApi } from '@/api/accountApi';
import { useToast } from '@/hooks/useToast';
import { AccountInfoParams, AccountInfoResponse } from '@/types/api/account';

interface Args {
  params: AccountInfoParams;
  onSuccess?: (data: AccountInfoResponse['data']) => void;
  onFailure?: () => void;
  hideToast?: boolean;
}

export const useUpdateUserInformation = () => {
  const [data, setData] = useState<AccountInfoResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const updateLinkDriveUser = async (args: Args) => {
    try {
      setIsLoading(true);
      const { data } = await fetchUserChangeApi(args.params);
      if (data.success) {
        if (!args.hideToast) {
          openToast('メールアドレスを更新しました');
        }
        args.onSuccess?.(data);
      }
      if (!data.success) openToast('エラーが発生しました', 'toast-failed');
    } catch (error) {
      setError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    updateLinkDriveUser,
  };
};
