import * as yup from 'yup';

export enum BankAccountType {
  NORMAL = 1,
  CURRENT = 2,
}
const bankAccountNumberRegex = /^[0-9]{1,7}$/;
const bankAccountHolder = /^([ァ-ン]|ー)+$/;

export const BankAccountTypeOptions = [
  { value: BankAccountType.NORMAL, text: '普通' },
  { value: BankAccountType.CURRENT, text: '当座' },
];

export interface FormRegisterBankAccount {
  bank_code: string;
  bank_name: string;
  branch_code: string;
  branch_name: string;
  account_type: number;
  account_number: string;
  account_holder: string;
}

export const validationSchema = yup.object().shape({
  bank_code: yup.string().required('金融機関名は必要です。'),
  bank_name: yup.string().required(),
  branch_code: yup.string().required('支店コードは必要です。'),
  branch_name: yup.string().required('支店名は必要です。'),
  account_type: yup.number().required('口座種別は必要です。'),
  account_number: yup
    .string()
    .required('口座番号は必要です。')
    .matches(bankAccountNumberRegex, {
      message: '数字で入力してください。',
    }),
  account_holder: yup
    .string()
    .required('口座名義（カタカナ）は必要です。')
    .matches(bankAccountHolder, {
      message: '全角カタカナで入力してください。',
    }),
});
