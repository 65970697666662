import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { HelpTop } from './HelpTop/HelpTop';
import { paths } from '@/config/paths';
import { url } from '@/consts/url';

interface HelpTopPageProps {
  f7router: Router.Router;
}
export const HelpTopPage: FC<HelpTopPageProps> = ({ f7router }) => {
  const handleClickHelp = () => {
    window.open(url.TAYORI_MAIKURU);
  };
  const handleClickContact = () => {
    f7.view.main.router.navigate(paths.contact);
  };
  return (
    <HelpTop
      f7router={f7router}
      handleClickHelp={handleClickHelp}
      handleClickContact={handleClickContact}
    />
  );
};
