import { IconProps } from '../Icon/type';
interface PrepaidRectangeProps extends IconProps {
  stop1Color?: string;
  stop2Color?: string;
}
export const PrepaidRectange = ({
  width = '375',
  height = '106',
  stop1Color = '#92664D',
  stop2Color = '#E5A780',
}: PrepaidRectangeProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`M0 22.3128C184.22 62.5053 224.125 -12.6152 736 1.88727V106H0V22.3128Z`}
        fill="url(#paint0_linear_36333_900342)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_36333_900342"
          x1="167.821"
          y1="4.51739"
          x2="186.597"
          y2="100.296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={stop1Color} />
          <stop offset="1" stopColor={stop2Color} />
        </linearGradient>
      </defs>
    </svg>
  );
};
