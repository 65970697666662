import { FC } from 'react';
import styles from './DrivingScoreCheckStyle.module.css';

// TODO: APIの型調査
interface InfoType {
  monthlyreport?: any;
  mileageOfData?: any;
  tripLog?: any;
  timelineLog?: any;
  phydMeas?: any;
  adInsuranceDiscount?: any;
  latestDiagnosisResult?: any;
}

interface InsuranceCardProps {
  info?: InfoType;
}

export const DrivingScoreCheck: FC<InsuranceCardProps> = () => {
  return (
    <div
      className={`${styles['all-report-score-card']} info-card info-card-elevated u-padding-bottom-lg`}
    >
      <div
        className={`${styles['all-report-score-card_data']} u-padding-top-lg u-font-bold`}
      >
        累計スコアを確認する場合はこちら
      </div>
      <div className={styles['all-report-score-card_text']}>詳細を見る</div>
      <div className={styles['all-report-score-card_icon']} />
    </div>
  );
};
