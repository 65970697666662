import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import perse from 'html-react-parser';
import { FC } from 'react';
import style from './confirmTermsStyle.module.css';
import { ApplicationUsingRules } from '@/components/statics/ApplicationUsingRule/ApplicationUsingRules';
import { DrivingAnalysisUsageAgreement } from '@/components/statics/DrivingAnalysisUsageAgreement/DrivingAnalysisUsageAgreement';
import { PrepaidService } from '@/components/statics/PrepaidService/PrepaidService';
import { PrivacyPolicy } from '@/components/statics/PrivacyPolicy/PrivacyPolicy';
import { Button } from '@/components/uiParts/Button';
import { LoginPageTemplate } from '@/components/uiParts/Template/LoginPageTemplate/LoginPageTemplate';
import { TextRead } from '@/components/uiParts/TextRead/TextRead';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { termType } from '@/consts/terms';
import { warrantyType } from '@/consts/warranty';
import { CustomerInfoApiResponse } from '@/types/api/customerApi';

interface ConfirmTermsProps {
  f7router: Router.Router;
  basicInfo?: CustomerInfoApiResponse['data'];
  isMultiAffiliate: boolean;
  prepaidName: string;
}

export const ConfirmTerms: FC<ConfirmTermsProps> = ({
  f7router,
  basicInfo,
  isMultiAffiliate,
  prepaidName,
}) => {
  const term =
    basicInfo?.cars?.[0]?.warranties?.[0]?.append_warranties?.find(
      (warranty) => warranty.warranty_type === warrantyType.DEFECT,
    )?.low_ver || '';
  const handleClickNext = () => {
    if (isMultiAffiliate && store.state.globalTheme == null) {
      f7.views.main.router.navigate(paths.emailDuplicated);
    } else {
      f7.views.main.router.navigate(paths.home);
    }
  };
  return (
    <LoginPageTemplate pageName="ConfirmTerms" title="認証が完了しました">
      <div className={style['container']}>
        <div className={style['sub-title-container']}>
          <h2 className={style['title']}>
            {`アプリのご利用\nありがとうございます`}
          </h2>
          <p>
            {`アプリをご利用いただくにあたり、\n下記の事項をご確認いただいた上で、\nご利用を開始してください。`}
          </p>
        </div>
        <div className={style['confirmation-container']}>
          <div>
            <div className={style['confirmation-desc-wrapper']}>
              <h3>アプリに関する確認事項</h3>
            </div>
            <div className="u-margin-bottom-sm">
              <TextRead
                title="利用規約"
                termType={termType.APP}
                path={paths.terms}
                f7router={f7router}
              >
                <ApplicationUsingRules />
              </TextRead>
            </div>
            <div>
              <TextRead
                title="プライバシーポリシー"
                termType={termType.PRIVACY_POLICY}
                path={paths.terms}
                f7router={f7router}
              >
                <PrivacyPolicy />
              </TextRead>
            </div>
          </div>
          {basicInfo && (
            <div>
              <div className={style['confirmation-desc-wrapper']}>
                <h3>保証に関する確認事項</h3>
                <p>
                  {`お客様は保証にご加入いただいております。\n
                保証に関する重要事項をご確認ください。`}
                </p>
              </div>
              <div className="u-margin-bottom-sm">
                <TextRead
                  title="保証規定書"
                  path={paths.terms}
                  f7router={f7router}
                  html={term}
                >
                  <div className={style['warranty-container']}>
                    {perse(term)}
                  </div>
                </TextRead>
              </div>
            </div>
          )}
          <div>
            <div className={style['confirmation-desc-wrapper']}>
              <h3>ブリペイドサービスに関する確認事項</h3>
              <p>
                {` お客様は${prepaidName}にご加入いただいております。\n${prepaidName}に関する重要事項をご確認ください。`}
              </p>
            </div>
            <TextRead
              title="プリペイドサービス規約"
              path={paths.terms}
              termType={termType.PREPAID}
              f7router={f7router}
            >
              <PrepaidService />
            </TextRead>
          </div>
          <div>
            <div className={style['confirmation-desc-wrapper']}>
              <h3>安全運転分析サービスに関する確認事項</h3>
              <p>
                {`お客様は安全運転分析サービスにご加入いただいております。\n安全運転分析サービスに関する重要事項をご確認ください。`}
              </p>
            </div>
            <TextRead
              title="安全運転分析サービス ご利用同意"
              path={paths.terms}
              termType={termType.ROAD_SERVICE}
              f7router={f7router}
            >
              <DrivingAnalysisUsageAgreement />
            </TextRead>
          </div>
        </div>
        <div className={style['button-wrapper']}>
          <Button handleClickAction={handleClickNext}>
            同意して利用開始する
          </Button>
        </div>
      </div>
    </LoginPageTemplate>
  );
};
