import { FC } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  CreateAccountByEmail,
  FormInput,
} from './CreateAccountByEmail/CreateAccountByEmail';
import { useUpdateUserInformation } from '@/hooks/api/linkDrive/useUpdateUserInformation';

export const CreateAccountByEmailPage: FC = () => {
  const { updateLinkDriveUser } = useUpdateUserInformation();
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const params = {
      // TODO: owner_idはログイン後に取得できる値なためこのページでこのAPIはコールが不可能
      owner_id: 0,
      mail_address: data.email,
    };
    await updateLinkDriveUser({ params });
  };
  return <CreateAccountByEmail onSubmit={onSubmit} />;
};
