import { Router } from 'framework7/types';
import { FC } from 'react';
import { NoticeSettings } from '@/components/projects/NoticeSettings/NoticeSettings';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
interface VehicleNoticeSettingsProps {
  f7router: Router.Router;
}

export type Options = {
  options_title: string;
  type: number;
  props: string;
  status: number;
};

export const VehicleNoticeSettings: FC<VehicleNoticeSettingsProps> = ({
  f7router,
}) => {
  return (
    <PageTemplate
      pageName="VehicleNoticeSettings"
      title="車両通知設定"
      showNavBack
    >
      <NoticeSettings f7router={f7router} />
    </PageTemplate>
  );
};
