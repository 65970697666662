import { Card } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { paths } from '@/config/paths';
import './TroubleGuideStyle.scss';

type TroubleGuideProps = {
  f7router: Router.Router;
};

export const TroubleGuide: FC<TroubleGuideProps> = ({ f7router }) => {
  return (
    <div className="card-top" data-name="TroubleGuide">
      <Card>
        <div className="u-margin-top-0 u-padding-top-0 u-padding-bottom-md border-bottom-line">
          <div
            className="u-font-size-xl u-font-bold"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <span className="number">➊</span>&nbsp; ロードサービスに電話
          </div>
          <div className="card-content u-padding-top-sm">
            上のボタンからロードサービスに電話をかけ、状況をご説明ください。
          </div>
          <p className="u-margin-top-sm" style={{ color: 'gray' }}>
            ※お電話の際に位置情報を取得しております。
          </p>
        </div>
        <div className="u-margin-top-0 u-padding-top-sm u-padding-bottom-md border-bottom-line">
          <div
            className="u-font-size-xl u-font-bold"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <span className="number">➋</span> &nbsp;ロードサービス到着
          </div>
          <div className="card-content u-padding-top-sm ">
            到着しましたら、お車の状態を確認いたします。
            <br />
            現場復旧が可能でしたらその場で処置を行い完了となります。
            <br />
            現場復旧が困難な場合は、指定工場へ搬送の上、保証修理対応をさせていただきます。
          </div>
        </div>
        <div className="u-margin-top-0 u-padding-top-sm u-padding-bottom-md border-bottom-line">
          <div
            className="u-font-size-xl u-font-bold "
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <span className="number">➌</span> &nbsp;入庫／故障内容の確認
          </div>
          <div className="card-content u-padding-top-sm ">
            指定工場にて故障内容を確認し、修理が必要な箇所のお見積もりを作成します。
            <br />
            <p className="u-margin-top-xs">
              ※作成したお見積もりは、指定工場からサポートセンターに共有されます。
            </p>
          </div>
        </div>
        <div className="u-margin-top-0 u-padding-top-sm u-padding-bottom-md border-bottom-line">
          <div
            className="u-font-size-xl u-font-bold"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <span className="number">➍</span>{' '}
            &nbsp;保証適用可否審査・結果のご連絡
          </div>
          <div className="card-content u-padding-top-sm ">
            工場で作成されましたお見積もり内容をもとに、保証適用可否の判定をさせていただきます。
            <br />
            判定結果が出ましたら、ご連絡します。
            <br />
            <p className="u-margin-top-xs" style={{ color: 'red' }}>
              ※判定結果のご連絡を待たずに修理を進められますと保険適用不可となります。お気を付けください。
            </p>
          </div>
        </div>
        <div className="u-margin-top-0 u-padding-top-sm u-padding-bottom-md border-bottom-line">
          <div
            className="u-font-size-xl u-font-bold"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <span className="number">➎</span> &nbsp;免責金のお支払い
          </div>
          <div className="card-content u-padding-top-sm ">
            保証のご利用が可能となった場合、保証条件の免責金をお支払いください。
            <br />
            <p className="u-margin-top-xs">
              ※ご加入の保証プランなどにより、免責金が発生しないこともございます。
              <br />
            </p>
            <p className="u-margin-top-xs">
              ※保証が適用できない場合も、そのまま修理を進めることが可能です。
            </p>
            <div className="u-margin-top-sm u-margin-bottom-0">
              <IconCard
                iconType=""
                color="gray"
                headingFontSize="14px"
                iconFontSize="25px"
                iconColor="#1e55e6"
                isTransition
                heading={'ご加入の保証プランを確認する'}
                path={paths.warrantyDetail}
              />
            </div>
          </div>
        </div>
        <div className="u-margin-top-0 u-padding-top-sm u-padding-bottom-md border-bottom-line">
          <div
            className="u-font-size-xl u-font-bold"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <span className="number">➏</span> &nbsp;故障個所の修理
          </div>
          <div className="card-content u-padding-top-sm ">
            免責金のご入金が確認できましたら、部品発注・修理着工へと進みます。
            <br />
          </div>
        </div>
        <div className="u-margin-top-0 u-padding-top-sm u-padding-bottom-md ">
          <div
            className="u-font-size-xl u-font-bold"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <span className="number">➐</span> &nbsp;納車
          </div>
          <div className="card-content u-padding-top-sm ">
            修理が完了しましたら、連絡をいたします。
            <br />
            その際、返事日時を決めさせていただきますので、お約束の日時でお車の引取りをお願いいたします。
            <br />
          </div>
        </div>
      </Card>
    </div>
  );
};
