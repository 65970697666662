import { Col, Row } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import style from './UpdateInspectionStyle.module.css';
import { SelectMonths } from '@/components/projects/SelectMonths/SelectMonths';
import { SelectYears } from '@/components/projects/SelectYears/SelectYears';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { useUpdateCar } from '@/hooks/api/car/useUpdateCar';
import { useToast } from '@/hooks/useToast';
import { createTwoDigitString } from '@/utils/date';
import { storeDispatch } from '@/utils/store';
import './UpdateInspectionStyles.scss';
import { f7CustomBack } from '@/utils/utils';

interface UpdateInspectionProps {
  f7router: Router.Router;
}

interface FormInput {
  year: string;
  month: string;
}

export const UpdateInspection: FC<UpdateInspectionProps> = ({ f7router }) => {
  const YEAR_INDEX = 0;
  const MONTH_INDEX = 1;
  const carItemInfo = store.state.carItemInfo;
  const nextInspectionMonths = Number(
    carItemInfo.next_inspection_date.split('-')[MONTH_INDEX],
  );
  const nextInspectionYears = Number(
    carItemInfo.next_inspection_date.split('-')[YEAR_INDEX],
  );

  const { openToast } = useToast();
  const { updateCar } = useUpdateCar();

  const formMethods = useForm<FormInput>({});
  const { handleSubmit } = formMethods;

  const createUpdateCarApiParams = (year: string, month: string) => {
    const { transport_bu, class_no, hiragana, registry_no } = carItemInfo;
    const { m_customer_id } = store.state.authInfo;
    return {
      m_customer_id,
      next_inspection_date: `${year}-${month}-01`,
      transport_bu,
      class_no,
      hiragana,
      registry_no,
    };
  };

  const onSubmit: SubmitHandler<FormInput> = ({ year, month }) => {
    const tStockCarId = carItemInfo.t_stock_car_id.toString();
    // 一桁の月（1~9）の前に0をつけないと車検情報が更新されない
    const twoDigitMonth = createTwoDigitString(month);
    updateCar({
      tStockCarId,
      params: createUpdateCarApiParams(year, twoDigitMonth),
      onSuccess: () => {
        openToast('車検情報を更新しました');
        storeDispatch('setCarItemInfo', 'carItemInfo', {
          ...carItemInfo,
          next_inspection_date: `${year}-${twoDigitMonth}-01`,
        });
        f7CustomBack(['#UpdateInspection']);
      },
    });
  };

  return (
    <PopupPageTemplate
      id="UpdateInspection"
      pageName="update-inspection"
      title="車検情報の変更"
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className={style.label}>次回車検月</label>
          <Row className={style['select-container']}>
            <Col>
              <SelectYears defaultYear={nextInspectionYears} />
            </Col>
            <Col>
              <SelectMonths defaultMonths={nextInspectionMonths} />
            </Col>
          </Row>
          <div className={style['button-wrap']}>
            <Button text="変更する" type="submit" />
          </div>
        </form>
      </FormProvider>
    </PopupPageTemplate>
  );
};
