import { Fab, Navbar, Page, Popup } from 'framework7-react';
import { FC } from 'react';
import { BlockComponent } from './BlockComponent/BlockComponent';
import { CommonpageTitle } from '@/components/uiParts/CommonpageTitle/CommonpageTitle';

export const StopReservingsPage: FC = () => {
  const handleHelpClick = () => {
    return;
  };

  const handleStopReservingClick = () => {
    return;
  };

  return (
    <Popup>
      <Navbar noHairline>
        <CommonpageTitle
          navTitle="積み立ての停止"
          leftIcon="close"
          handleHelpClick={handleHelpClick}
        >
          積立の停止
        </CommonpageTitle>
      </Navbar>

      <Page name="StopReservings">
        <BlockComponent />
        <Fab
          className="u-margin-bottom-lg color-pink"
          position="center-bottom"
          text="積み立てを停止する"
          onClick={handleStopReservingClick}
        />
      </Page>
    </Popup>
  );
};
