import { format } from 'date-fns';
import { useMemo } from 'react';
import { getCurrentTimeInTokyo } from '@/utils/date';

export const usePromote = () => {
  const isShowPromote = useMemo(() => {
    try {
      const promoteStart = '2024/05/09 00:00:01';
      const promoteEnd = '2024/07/31 23:59:59';
      const tokyoTime = format(getCurrentTimeInTokyo(), 'yyyy/MM/dd HH:mm:ss');

      return tokyoTime >= promoteStart && tokyoTime <= promoteEnd;
    } catch {
      return false;
    }
  }, []);

  return {
    isShowPromote,
  };
};
