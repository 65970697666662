import { IconProps } from './type';

export const ContactSupportIcon = ({
  width = '56',
  height = '56',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill={color} fillOpacity="0.1" />
      <path
        d="M43 29C43 27.0302 42.612 25.0796 41.8582 23.2597C41.1044 21.4399 39.9995 19.7863 38.6066 18.3934C37.2137 17.0005 35.5601 15.8956 33.7403 15.1418C31.9204 14.388 29.9698 14 28 14C26.0302 14 24.0796 14.388 22.2597 15.1418C20.4399 15.8956 18.7863 17.0005 17.3934 18.3934C16.0005 19.7863 14.8956 21.4399 14.1418 23.2597C13.388 25.0796 13 27.0302 13 29"
        stroke="#323232"
        strokeWidth="2"
      />
      <rect x="11" y="25" width="4" height="8" rx="1" fill="#323232" />
      <rect x="41" y="25" width="4" height="8" rx="1" fill="#323232" />
      <circle
        cx="28"
        cy="30"
        r="11"
        fill="white"
        stroke="#323232"
        strokeWidth="2"
      />
      <circle
        cx="2"
        cy="2"
        r="1"
        transform="matrix(1 0 0 -1 26 38)"
        fill="white"
        stroke="#323232"
        strokeWidth="2"
      />
      <path
        d="M43 32V34C43 35.1046 42.1046 36 41 36H28"
        stroke="#323232"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
