import { yupResolver } from '@hookform/resolvers/yup';
import { Preloader } from 'framework7-react';
import { forwardRef, useImperativeHandle } from 'react';
import {
  ArrayPath,
  FieldError,
  FieldValues,
  FormProvider,
  UseControllerProps,
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  UseFormProps,
  UseFormRegisterReturn,
  UseFormReturn,
} from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import Lazy from 'yup/lib/Lazy';

export type FieldRegistrationWithHookFormProps<T> = Omit<
  T,
  'error' | 'label'
> & {
  registration?: Partial<UseFormRegisterReturn>;
  label?: string;
  error?: FieldError | undefined;
  placeholder?: string;
};

export type FieldForm<T> = FieldRegistrationWithHookFormProps<T>;

export type ControllerFieldForm<T> = FieldRegistrationWithHookFormProps<T> &
  UseControllerProps;

export type FormProps<TFormValues extends FieldValues, Schema> = {
  onSubmit?: (
    dataForm: TFormValues,
    methods: UseFormReturn<TFormValues>,
  ) => void;
  children: (
    methods: UseFormReturn<TFormValues>,
    fieldArrayMethods: UseFieldArrayReturn<TFormValues>,
  ) => React.ReactNode;
  options?: UseFormProps<TFormValues>;
  schema?: Schema;
  isLoading?: boolean;
  fieldArrayName?: ArrayPath<TFormValues>;
};

export type RefForm<
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
> = {
  methods: UseFormReturn<TFormValues>;
};

const FormCommon = forwardRef(
  <
    TFormValues extends Record<string, unknown> = Record<string, unknown>,
    Schema extends AnyObjectSchema | Lazy<any> = AnyObjectSchema | Lazy<any>,
  >(
    props: FormProps<TFormValues, Schema>,
    ref?: React.ForwardedRef<RefForm<TFormValues>>,
  ) => {
    const {
      onSubmit,
      children,
      options,
      schema,
      isLoading = false,
      fieldArrayName = '' as ArrayPath<TFormValues>,
    } = props;

    const methods = useForm<TFormValues>({
      ...options,
      resolver: schema && yupResolver(schema),
    });

    const fieldArrayMethods = useFieldArray<TFormValues>({
      control: methods.control,
      name: fieldArrayName,
    });

    const handleSubmit = (dataForm: TFormValues) => {
      onSubmit && onSubmit(dataForm, methods);
    };

    useImperativeHandle(
      ref,
      () => ({
        methods,
      }),
      [methods],
    );

    if (isLoading) {
      return (
        <div className="preloader-modal">
          <Preloader color="white" />
        </div>
      );
    }
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {children(methods, fieldArrayMethods)}
        </form>
      </FormProvider>
    );
  },
);
FormCommon.displayName = 'FormCommon';
export const Form = FormCommon as unknown as <
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends AnyObjectSchema | Lazy<any> = AnyObjectSchema | Lazy<any>,
>(
  props: FormProps<TFormValues, Schema> & {
    ref?: React.ForwardedRef<RefForm<TFormValues>>;
  },
) => ReturnType<typeof FormCommon>;
