import { f7 } from 'framework7-react';
import React, { FC, ReactNode } from 'react';
import style from './IconCardStyle.module.css';

type IconType =
  | 'alert'
  | 'confirm-notification'
  | 'help'
  | 'bulb'
  | 'file-color-inside'
  | '';
type Color = 'danger' | 'warning' | 'gray' | 'white';

interface IconCardProps {
  heading: string;
  iconType?: IconType;
  color?: Color;
  children?: ReactNode;
  iconFontSize?: string;
  supplement?: string | JSX.Element | JSX.Element[];
  isTransition?: boolean;
  headingFontSize?: string;
  path?: string;
  iconColor?: string;
  onClick?: () => void;
}

export const IconCard: FC<IconCardProps> = ({
  iconType = 'confirm-notification',
  color = 'warning',
  heading,
  children = undefined,
  iconFontSize = '20px',
  supplement = '',
  isTransition = false,
  headingFontSize = '15px',
  path = '',
  iconColor = '',
  onClick = undefined,
}) => {
  const handleClickNavigation = () => {
    if (!path) return;
    f7.view.main.router.navigate(path, { animate: true });
  };
  return (
    <div
      onClick={onClick || handleClickNavigation}
      className={`${style.card} ${style[`card-${color}`]}`}
    >
      <div
        className={`${style.head} ${style[`head-${color}`]} ${
          children ?? style['no-body']
        }`}
      >
        {/* icon */}
        <div className={style['icon-wrap']}>
          <i
            style={{ fontSize: iconFontSize, color: iconColor }}
            className={`icon-${iconType} ${style.icon} ${
              style[`icon-${color}`]
            }`}
          />
        </div>
        {/* カードボディ */}
        <div className={style.body}>
          <h3 style={{ fontSize: headingFontSize }} className={style.heading}>
            {heading}
          </h3>
          {supplement && <span className={style.supplement}>{supplement}</span>}
        </div>
        {/* right-icon */}
        {isTransition && (
          <div className={style['right-icon-wrap']}>
            <i className={`icon-right ${style['right-icon']}`} />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
