import { FC } from 'react';
import style from './DrivingResultStyle.module.css';

interface DrivingResultProps {
  drivingResult: {
    drivingCount: number;
    resultValueA: number;
    resultValueB: number;
    resultValueC: number;
    drivingDistance: number;
    averageDrivingDistance: number;
    drivingHours: number;
    drivingMinutes: number;
    averageDrivingHours: number;
    averageDrivingMinutes: number;
  };
}

export const DrivingResult: FC<DrivingResultProps> = ({ drivingResult }) => {
  return (
    <div className={style.card}>
      <h3 className={style.heading}>走行実績</h3>
      <div className={style['driving-frequency-wrap']}>
        {/* TODO: コンポーネント化 */}
        <div className={style['driving-frequency-head-wrap']}>
          <div className={style['driving-frequency-head']}>
            <span>走行回数</span>
          </div>
          <div className={style['driving-frequency-head']}>
            <span className={`${style.judgement} ${style.judgementA}`}>
              A判定
            </span>
            <span className={style.supplement}>80 ~ 100点</span>
          </div>
          <div className={style['driving-frequency-head']}>
            <span className={`${style.judgement} ${style.judgementB}`}>
              B判定
            </span>
            <span className={style.supplement}>60 ~ 79点</span>
          </div>
          <div className={style['driving-frequency-head']}>
            <span className={`${style.judgement} ${style.judgementC}`}>
              C判定
            </span>
            <span className={style.supplement}> ~ 59点</span>
          </div>
        </div>
        <div className={style['driving-frequency-body-wrap']}>
          <div className={style['driving-frequency-body']}>
            <span className={style['highlighted-digit']}>
              {drivingResult.drivingCount}
            </span>
            <span className={style.unit}>回</span>
          </div>
          <div className={style['driving-frequency-body']}>
            <span className={style['highlighted-digit']}>
              {drivingResult.resultValueA}
            </span>
            <span className={style.unit}>回</span>
          </div>
          <div className={style['driving-frequency-body']}>
            <span className={style['highlighted-digit']}>
              {drivingResult.resultValueB}
            </span>
            <span className={style.unit}>回</span>
          </div>
          <div className={style['driving-frequency-body']}>
            <span className={style['highlighted-digit']}>
              {drivingResult.resultValueC}
            </span>
            <span className={style.unit}>回</span>
          </div>
        </div>
      </div>
      <div className={style['grid-table']}>
        <div className={style.head}>走行距離</div>
        <div className={style.head}>平均走行距離</div>
        <div className={style['highlighted-digit']}>
          {drivingResult.drivingDistance}
          <span className={style.unit}>km</span>
        </div>
        <div className={style['highlighted-digit']}>
          {drivingResult.averageDrivingDistance}
          <span className={style.unit}>km/走行</span>
        </div>
      </div>
      <div className={style['grid-table']}>
        <div className={style.head}>走行時間</div>
        <div className={style.head}>平均走行時間（走行毎）</div>
        <div className={style['highlighted-digit']}>
          {drivingResult.drivingHours}
          <span className={style.unit}>時間</span>
          {drivingResult.drivingMinutes}
          <span className={style.unit}>分</span>
        </div>
        <div className={style['highlighted-digit']}>
          {drivingResult.averageDrivingHours}
          <span className={style.unit}>時間</span>
          {drivingResult.averageDrivingMinutes}
          <span className={style.unit}>分/走行</span>
        </div>
      </div>
    </div>
  );
};
