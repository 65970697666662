import Framework7React from 'framework7-react';
import Framework7 from 'framework7/lite-bundle';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './assets/scss/index.scss';
import { InitializeSentry } from './sentry';

InitializeSentry();

Framework7.use(Framework7React);

ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(
  <App />,
);
