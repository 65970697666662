import { Router } from 'framework7/types';
import { FC } from 'react';
import { ResetPasswordComplete } from './ResetPasswordComplete/ResetPasswordComplete';

interface ResetPasswordCompleteProps {
  f7router: Router.Router;
  id: string;
}
export const ResetPasswordCompletePage: FC<ResetPasswordCompleteProps> = ({
  f7router,
  id,
}) => {
  return <ResetPasswordComplete mailAddress={id} />;
};
