import { Router } from 'framework7/types';
import {
  AccelerationDataPage,
  DecelerationDataPage,
  DriveCalenderPage,
  DriveHistoryDetailPage,
  DriveRecordPage,
  DriveScorePage,
  DrivingDailyDataPage,
  DrivingDataAnalysisSettingsPage,
  DrivingMonthlyDataPage,
  DrivingRecordByDayOrTimePage,
  DrivingReportListPage,
  DrivingScoreListPage,
  DrivingScoreTotalPage,
  EvaluationDetailsPage,
  FuelEfficiencyPage,
  MonthlyDriveReportPage,
  PositionDetectingPage,
  ScoreSendDataApprovalPage,
  TotalScoreListPage,
  TotalScorePage,
  WatchingMonthlyDriveReportPage,
} from '@/components/pages';
import { Co2InformationPage } from '@/components/pages/Co2Information/Co2InformationPage';
import { driveRecordRoutes } from '@/components/pages/DriveRecord/driveRecordTabRoutes';
import { DrivingAnalysisServiceAgreePage } from '@/components/pages/DrivingAnalysisServiceAgree/DrivingAnalysisServiceAgreePage';
import { paths } from '@/config/paths';

export const drivingRoutes: Router.RouteParameters[] = [
  //   {
  //     path: '/score_terms/',
  //     component: ScoreTermsPage,
  //     name: 'スコア連携 規約同意'
  //   },
  {
    path: '/score_send_data_approval/',
    component: ScoreSendDataApprovalPage,
    name: 'スコア連携 データ送信同意',
  },
  {
    path: '/drive_score/',
    component: DriveScorePage,
    name: '安全運転スコア',
  },
  //   {
  //     path: '/driving_trip_report/',
  //     component: DrivingTripReportPage,
  //     name: '運転診断 トリップレポート'
  //   },
  //  // path: '/driving_report/:id/',
  //     path: '/driving_report/',
  //     asyncComponent: () => import('../views/DrivingReport.vue'),
  //     name: '運転診断 運転レポート',
  // 　},
  {
    path: paths.drivingScoreList,
    component: DrivingScoreListPage,
    name: '安全運転スコア一覧',
  },
  {
    path: '/driving_score_total/',
    popup: {
      component: DrivingScoreTotalPage,
    },
    name: '安全運転スコア累計',
  },
  {
    path: '/driving_report_list/',
    component: DrivingReportListPage,
    name: '安全運転スコア マンスリー',
  },
  //   {
  //     path: '/driving_report_monthly/',
  //     component: DrivingReportMonthlyPage,
  //     name: '月間運転レポート'
  //   },
  {
    path: '/driving_record_by_day_or_time/',
    component: DrivingRecordByDayOrTimePage,
    name: '走行実績（時間帯・曜日別）',
  },
  //   {
  //     path: '/driving_strike_history/',
  //     component: DrivingStrikeHistoryPage,
  //     name: '衝撃履歴'
  //   },
  {
    path: '/driving_monthly_data/',
    component: DrivingMonthlyDataPage,
    name: 'ドライブ 月別',
  },
  {
    path: '/driving_daily_data/',
    component: DrivingDailyDataPage,
    name: 'ドライブ 日別',
  },
  {
    path: '/brakes_data/',
    component: DecelerationDataPage,
    name: '急ブレーキ回数',
  },
  {
    path: '/acceleration_data/',
    component: AccelerationDataPage,
    name: '急アクセル回数',
  },
  {
    path: '/drive_record/',
    component: DriveRecordPage,
    name: '運転記録',
    routes: driveRecordRoutes,
  },
  {
    path: '/evaluation_details/',
    popup: {
      component: EvaluationDetailsPage,
    },
    options: {
      animate: true,
    },
    name: '判定詳細',
  },
  {
    path: '/drive_calender/',
    popup: {
      component: DriveCalenderPage,
    },
    options: {
      animate: true,
    },
    name: '運転履歴カレンダー',
  },
  {
    path: paths.totalScore,
    popup: {
      component: TotalScorePage,
    },
    options: {
      animate: true,
    },
    name: '累計スコア判定',
  },
  {
    path: '/total_score_list/',
    popup: {
      component: TotalScoreListPage,
    },
    options: {
      animate: true,
    },
    name: '累計スコアリスト',
  },
  {
    path: '/drive_history_detail/',
    component: DriveHistoryDetailPage,
    name: '運転履歴詳細',
  },
  {
    path: '/monthly_drive_report/',
    component: MonthlyDriveReportPage,
    name: '月間安全運転レポート',
  },
  {
    path: '/position_detecting/',
    component: PositionDetectingPage,
    name: '検知場所',
  },
  //   {
  //     path: '/drive_durations/',
  //     component: DriveHistory/DriveDurationsPage,
  //     name: '運転時間'
  //   },
  {
    path: '/fuel_efficiency/',
    component: FuelEfficiencyPage,
    name: '平均燃費',
  },
  {
    path: '/driving_data_analysis_settings/',
    popup: {
      component: DrivingDataAnalysisSettingsPage,
    },
    options: {
      animate: true,
    },
    name: '運転分析サービス設定',
  },
  {
    path: '/driving-analysis-service-agree/',
    popup: {
      component: DrivingAnalysisServiceAgreePage,
    },
    options: {
      animate: true,
    },
    name: '安全運転分析サービス ご利用同意',
  },
  {
    path: '/watching_service/monthly_drive_report/:ownerId/:vehicleId/:customerId/:analysisFromDate',
    component: WatchingMonthlyDriveReportPage,
    name: '見守り者向け月間安全運転レポート',
  },
  {
    path: '/co2_information/',
    popup: {
      component: Co2InformationPage,
    },
    options: {
      animate: true,
    },
    name: '安全運転によるCO2排出削減量',
  },
];
