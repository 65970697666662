import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './resendEmailStyle.module.css';
import { RoundButtonWithIcon } from '@/components/uiParts/Button/RoundButtonWithIcon/RoundButtonWithIcon';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { AffiliateId } from '@/consts/shop';
import { useEmailFirstLoginDecision } from '@/hooks/api/auth';
import { EmailFirstLoginDecisionApiParams } from '@/types/api/authApi';
import { addAffiliateIdToRequestParams } from '@/utils/shop';

interface ResendEmailProps {
  affiliateId: AffiliateId;
}

export const ResendEmail: FC<ResendEmailProps> = ({ affiliateId }) => {
  const { emailFirstLoginDecision } = useEmailFirstLoginDecision(affiliateId);

  const handleClickResend = async () => {
    // メール再送
    const email = store.state.loginInfo.email;
    if (email) {
      const params: EmailFirstLoginDecisionApiParams = {
        email,
      };
      addAffiliateIdToRequestParams(affiliateId, params);
      await emailFirstLoginDecision(params);
      f7.views.main.router.navigate(paths.emailComplete);
    }
  };
  const handleClickRetyping = () => {
    // TODO: pathsを確認
    f7.views.main.router.navigate('/login/email/');
  };
  return (
    <PopupPageTemplate pageName="ResendEmail" title="メールが届きませんか？">
      <div className={style['sending-info']}>
        {`${store.state.loginInfo.email}\nに送っています`}
      </div>
      <div className={style['button-wrapper']}>
        <RoundButtonWithIcon
          colorTheme="gray"
          iconClass="icon-send"
          handleClickAction={handleClickResend}
        >
          メールを再送する
        </RoundButtonWithIcon>
        <RoundButtonWithIcon
          colorTheme="gray"
          iconClass="icon-left"
          handleClickAction={handleClickRetyping}
        >
          メールアドレスを再入力する
        </RoundButtonWithIcon>
      </div>
      <div className={style['domain-setting-box']}>
        <h3>ドメイン指定受信設定をされている方</h3>
        <div className={style['domain-setting-desc-wrapper']}>
          <p>
            お使いのメールで迷惑メール防止の機能が有効な場合、メールをお届けすることができません。
          </p>
          <p>メールは「info@maikuru.net」のアドレスから送信されます。</p>
          <p>
            お使いの携帯電話会社のメール設定画面から、「@maikuru.net」を許可してください。
          </p>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
