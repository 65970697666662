import { f7 } from 'framework7-react';
import { useState } from 'react';
import { sendGpsApi } from '@/api/troubleApi';
import { SendGpsApiParams } from '@/types/api/trouble';

type UrlElementToCall = {
  tel: string;
  roadServiceId: string;
  divisionNo: string;
};

export const useGps = (
  isLoadServiceCall: boolean = false,
  division: string,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const sendGps = async (params: SendGpsApiParams) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const { data } = await sendGpsApi(params);
      if (isLoadServiceCall) {
        const urlElement: UrlElementToCall = {
          tel: params.service_tel_no,
          roadServiceId: params.service_queue,
          divisionNo: division,
        };
        navigateUrlToCall(data.success, urlElement);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      if (isLoadServiceCall) {
        window.location.href = `tel://${params.service_tel_no}`;
      }
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  const navigateUrlToCall = (
    success: boolean,
    urlElement: UrlElementToCall,
  ) => {
    if (!success) {
      window.location.href = `tel://${urlElement.tel}`;
    }
    window.location.href = generateUrlToCall(urlElement);
  };

  const generateUrlToCall = (urlElement: UrlElementToCall) => {
    const { tel, roadServiceId, divisionNo } = urlElement;
    return `tel://${tel},9,${roadServiceId}${encodeURIComponent(divisionNo)}`;
  };

  return {
    isLoading,
    error,
    sendGps,
  };
};
