import { FC } from 'react';
import { WarrantyDuplicated } from './WarrantyDuplicated/WarrantyDuplicated';
import { store } from '@/config/store';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';
import { f7CustomBack } from '@/utils/utils';

export const WarrantyDuplicatedPage: FC = () => {
  const { customerInfo } = store.state;
  const mAffiliateId = customerInfo.m_affiliate_id;
  const { data } = useMAffiliateSettings({ m_affiliate_id: mAffiliateId });
  const handleClickContact = () => {
    window.location.href = 'tel://' + data?.data.callcenter_tel || '';
  };
  const handleClickPopupClose = () => f7CustomBack(['#WarrantyDuplicated']);

  return (
    <WarrantyDuplicated
      handleClickPopupClose={handleClickPopupClose}
      handleClickContact={handleClickContact}
    />
  );
};
