import { FC } from 'react';
import { Terms } from './Terms/Terms';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { useTerm } from '@/hooks/api/term/useTerm';
import { TermType } from '@/types/api/termsApi';

interface TermsPageProps {
  termType?: TermType;
  title: string;
  html?: string;
}

export const TermsPage: FC<TermsPageProps> = ({ termType, title, html }) => {
  const { data } = useTerm(termType);
  const htmlRes = data?.term;
  return (
    <PopupPageTemplate pageName="Terms" title={title}>
      <Terms html={html ?? htmlRes} />
    </PopupPageTemplate>
  );
};
