import { Router } from 'framework7/types';
import React, { FC } from 'react';
import { VehicleCondition } from './VehicleCondition/VehicleCondition';

type VehicleConditionPageProps = {
  f7router: Router.Router;
};

export const VehicleConditionPage: FC<VehicleConditionPageProps> = ({
  f7router,
}) => {
  return <VehicleCondition f7router={f7router} />;
};
