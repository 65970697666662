import { FC } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import style from './setPasswordStyle.module.css';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { RhfInput } from '@/components/uiParts/ReactHookForm/RhfInput/RhfInput';
import { LoginPageTemplate } from '@/components/uiParts/Template/LoginPageTemplate/LoginPageTemplate';

interface SetPasswordProps {
  onSubmit: SubmitHandler<FormInput>;
  formMethods: UseFormReturn<FormInput>;
}

export interface FormInput {
  password: string;
  confirmation: string;
}

export const SetPassword: FC<SetPasswordProps> = ({
  onSubmit,
  formMethods,
}) => {
  const {
    handleSubmit,
    formState: { isValid, errors, dirtyFields },
  } = formMethods;
  return (
    <LoginPageTemplate
      pageName="SetPassword"
      title="パスワードの設定"
      showNavBack
    >
      <div className={style['container']}>
        <div className={style['desc-wrapper']}>
          <CheckIcon width="70px" height="70px" isValid />
          <h3>メールアドレスの認証が完了しました</h3>
          <h3>{` 続いて、ログイン用の\nパスワードを設定してください`}</h3>
        </div>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style['forms-wrapper']}>
              <div className={style['password-input-label-wrapper']}>
                <span className={style['input-label']}>パスワード</span>
                <div className={style['form-check-icon-wrapper']}>
                  <RhfInput
                    maxLength={32}
                    className={style['input']}
                    name="password"
                    type="password"
                    placeholder="パスワード"
                  />
                  <div>
                    <CheckIcon
                      isValid={!!dirtyFields.password && !errors.password}
                    />
                  </div>
                </div>
                <span className={style['input-sub-text']}>
                  半角英数字記号6文字以上32文字以内
                </span>
              </div>
              <div className={style['password-input-label-wrapper']}>
                <span className={style['input-label']}>
                  パスワード（確認用）
                </span>
                <div className={style['form-check-icon-wrapper']}>
                  <RhfInput
                    maxLength={32}
                    className={style['input']}
                    name="confirmation"
                    type="password"
                    placeholder="もう一度ご入力ください"
                  />
                  <div>
                    <CheckIcon
                      isValid={!!dirtyFields.confirmation && isValid}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={style['button-container']}>
              <div className={style['button-wrapper']}>
                <Button type="submit" disabled={!isValid}>
                  設定する
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </LoginPageTemplate>
  );
};
