import style from './resendSmsStyle.module.css';
import messageImage from '@/assets/images/menu/icon-message-1.svg';
import messageApp1Image from '@/assets/images/menu/icon-message-app-1.svg';
import messageApp2Image from '@/assets/images/menu/icon-message-app-2.svg';
import messageApp3Image from '@/assets/images/menu/icon-message-app-3.svg';
import plusMessageImage from '@/assets/images/menu/icon-plus-message.svg';
import { RoundButtonWithIcon } from '@/components/uiParts/Button/RoundButtonWithIcon/RoundButtonWithIcon';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';

export const ResendSms = () => {
  const handleClickReSendSms = () => {
    //TODO
  };
  const handleClickReTypeNumber = () => {
    //TODO
  };
  const handleClickCannotReceive = () => {
    //TODO
  };
  const handleClickShouldReceive = () => {
    //TODO
  };
  return (
    <PopupPageTemplate pageName="ResendSms" title="SMSが届きませんか？">
      <div className={style['container']}>
        <h3>{store.state.loginInfo.tel} に送っています</h3>
        <div className={style['button-wrapper']}>
          <RoundButtonWithIcon
            colorTheme="gray"
            iconClass="icon-send"
            handleClickAction={handleClickReSendSms}
          >
            SMSを再送する
          </RoundButtonWithIcon>
          <RoundButtonWithIcon
            colorTheme="gray"
            iconClass="icon-arrow"
            handleClickAction={handleClickReTypeNumber}
          >
            電話番号を再入力する
          </RoundButtonWithIcon>
          <RoundButtonWithIcon
            colorTheme="gray"
            iconClass="icon-phone"
            handleClickAction={handleClickCannotReceive}
          >
            SMSを受信できない番号をお使いの方
          </RoundButtonWithIcon>
          <RoundButtonWithIcon
            colorTheme="gray"
            iconClass="icon-phone"
            handleClickAction={handleClickShouldReceive}
          >
            SMSを受信できる番号なのに届かない方
          </RoundButtonWithIcon>
        </div>
        <div className={style['sms-confirmation-box']}>
          <h3>SMSの確認方法が分かりませんか？</h3>
          <p className={style['sms-confirmation-desc']}>
            下記のようなアプリでSMSの確認ができます。お使いの端末にあるアプリを使ってください
          </p>
          <div className={style['icons-wrapper']}>
            <div className={style['icon-message']}>
              <img src={messageImage} alt="" />
              <span>メッセージ</span>
            </div>
            <div className={style['icon-message']}>
              <img src={plusMessageImage} alt="" />
              <span>+メッセージ</span>
            </div>
          </div>
          <p className={style['sms-confirmation-desc']}>
            端末（メーカー）によっては、こういうアプリの可能性もあります
          </p>
          <div className={style['icons-wrapper']}>
            <div>
              <img src={messageApp1Image} alt="" />
            </div>
            <div>
              <img src={messageApp2Image} alt="" />
            </div>
            <div>
              <img src={messageApp3Image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
