import { Block, Link, Page, Popup } from 'framework7-react';
import { FC } from 'react';
import style from './contactCompleteStyle.module.css';

interface ContactCompleteProps {
  email: string;
}

export const ContactComplete: FC<ContactCompleteProps> = ({ email }) => {
  return (
    <Popup>
      <Page name="ContactComplete">
        <div className={style['top']}>
          <Link animate popupClose back>
            <i className="icon-close" style={{ color: '#232323' }} />
          </Link>
          <div
            className="u-text-center u-font-bold"
            style={{ height: '5rem', lineHeight: '5rem' }}
          >
            問い合わせ
          </div>
        </div>

        <Block className={style['block']}>
          <h3 style={{ fontSize: '1.8rem' }}>お問い合わせを送信しました</h3>
          <h3>
            <span>{email}</span>
            <br />
            <span>に確認メールを送信しました</span>
          </h3>
          <p className={style['description']}>
            今後のやりとりは
            <br />
            こちらのメールアドレスと使って行われるため、
            <br />
            ご確認をお願いいたします
          </p>
          <div className={`${style['trouble-list']} u-margin-top-xl`}>
            <h3>
              <i className="icon-bulb" />
              確認メールが届かない場合
            </h3>
            <div className={style['list']}>
              <p>
                しばらくたってもメールが届かない場合は、以下の事が考えられます。
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>メールアドレスに誤りがある</li>
                <li>メールの受信設定で受け取れない状態になっている</li>
              </ul>
              <p>「info@maikuru.net」のアドレスから送信されます。</p>
              <p>
                ドメイン指定受信を設定されている場合は「@maikuru.net」を許可してください。
              </p>
            </div>
          </div>
        </Block>
      </Page>
    </Popup>
  );
};
