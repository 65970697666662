import { Router } from 'framework7/types';
import { FC } from 'react';
import { Coupons } from './Coupons/Coupons';

type CouponPage = {
  f7router: Router.Router;
  redirectPage: string;
};

export const CouponsPage: FC<CouponPage> = ({ f7router, redirectPage }) => {
  return <Coupons f7router={f7router} redirectPage={redirectPage} />;
};
