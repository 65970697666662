import { Button, f7 } from 'framework7-react';
import { FC } from 'react';
import './buttonIconVercalStyle.scss';
import style from './buttonIconVerticalStyle.module.css';

interface ButtonIconVerticalProps {
  path: string;
  iconType: string;
  label: string;
  width?: number;
  height?: number;
}

const defaultSize = 70;

export const ButtonIconVertical: FC<ButtonIconVerticalProps> = ({
  path,
  iconType,
  label,
  width = defaultSize,
  height = defaultSize,
}) => {
  const handleClickButton = () => {
    f7.views.main.router.navigate(path);
  };
  return (
    <div className={style['button-wrapper']}>
      <Button
        onClick={handleClickButton}
        className={style['button']}
        round
        iconSize="70px"
        style={{
          width: `${width}px`,
          height: `${height}px`,
          backgroundColor: '#fff',
        }}
      >
        <i className={`icon-${iconType}`} />
      </Button>
      <span className={style['label']}>{label}</span>
    </div>
  );
};
