import { Col, f7, Row } from 'framework7-react';
import { FC, useState } from 'react';
import { Button } from '@/components/uiParts/Button';
import './RegisterBankStyle.scss';

type RegisterBankStep3Props = {
  handleClickStep: (step: number) => void;
  handleClickAccessBankPage: (
    memberName: string,
    branchCode: string,
    // accountType: string,
    accountNumber: string,
    accountName: string,
    accountNameKanji: string,
  ) => void;
  bankname: string;
  bankcode: string;
  branchCodeRequiredFlg: string;
  accountTypeRequiredFlg: string;
  accountNumberRequiredFlg: string;
  accountNameKanaRequiredFlg: string;
};

export const RegisterBankStep3: FC<RegisterBankStep3Props> = ({
  handleClickStep,
  handleClickAccessBankPage,
  branchCodeRequiredFlg,
  accountTypeRequiredFlg,
  accountNumberRequiredFlg,
  accountNameKanaRequiredFlg,
  bankname,
  bankcode,
}) => {
  const [memberName, setMemberName] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNameKanji, setAccountNameKanji] = useState('');

  const handleClickValidate = () => {
    const memberNamePattern = /^[^\x01-\x7E]{1,255}$/;
    if (memberName != '') {
      if (!memberNamePattern.test(memberName)) {
        f7.dialog.alert('', '会員名は1～255文字で入力してください');
        return;
      }
    }
    const branchCodePattern = /^[0-9]{3}$/;
    if (branchCodeRequiredFlg === '1') {
      if (branchCode == '') {
        f7.dialog.alert('', '支店コードが未入力です');
        return;
      }
      if (!branchCodePattern.test(branchCode)) {
        f7.dialog.alert('', '支店コードは数値3桁で入力してください');
        return;
      }
    } else {
      if (branchCode != '') {
        if (!branchCodePattern.test(branchCode)) {
          f7.dialog.alert('', '支店コードは数値3桁で入力してください');
          return;
        }
      }
    }
    const accountTypePattern = /^[1-2]{1}$/;
    if (accountTypeRequiredFlg === '1') {
      if (accountType == '') {
        f7.dialog.alert('', '預金区分が未入力です');
        return;
      }
      if (!accountTypePattern.test(accountType)) {
        f7.dialog.alert('', '預金区分は1または2で入力してください');
        return;
      }
    } else {
      if (accountType != '') {
        if (!accountTypePattern.test(accountType)) {
          f7.dialog.alert('', '預金区分は1または2で入力してください');
          return;
        }
      }
    }
    const accountNumberPattern = /^[0-9]{7}$/;
    if (accountNumberRequiredFlg === '1') {
      if (accountNumber == '') {
        f7.dialog.alert('', '口座番号が未入力です');
        return;
      }
      if (!accountNumberPattern.test(accountNumber)) {
        f7.dialog.alert('', '口座番号は数値7桁で入力してください');
        return;
      }
    } else {
      if (accountNumber && !accountNumberPattern.test(accountNumber)) {
        f7.dialog.alert('', '口座番号は数値7桁で入力してください');
        return;
      }
    }
    const accountNamePattern = /^([0-9a-zA-Zァ-ンヴー]+(\s?)){2,60}$/;
    if (accountNameKanaRequiredFlg === '1') {
      if (accountName == '') {
        f7.dialog.alert('', '口座名義カナが未入力です');
        return;
      }
      if (!accountNamePattern.test(accountName)) {
        f7.dialog.alert(
          '',
          '口座名義カナは全角英数カナ空白2～60文字で入力してください',
        );
        return;
      }
    } else {
      if (accountNumber && !accountNamePattern.test(accountName)) {
        f7.dialog.alert(
          '',
          '口座名義カナは全角英数カナ空白2～60文字で入力してください',
        );
        return;
      }
    }
    const accountNameKanjiPattern = /^[^\x01-\x7E]{2,60}$/;
    if (accountNameKanji != '') {
      if (!accountNameKanjiPattern.test(accountNameKanji)) {
        f7.dialog.alert('', '口座名義は全角2～60文字で入力してください');
        return;
      }
    }
    handleClickAccessBankPage(
      memberName,
      branchCode,
      accountNumber,
      accountName,
      accountNameKanji,
    );
  };

  return (
    <form acceptCharset="Shift-JIS">
      <div className="form-input-wrap">
        <Row className="u-margin-top-sm u-margin-bottom-md">
          <Col width="50">
            <h2>
              {bankname}({bankcode})
            </h2>
          </Col>
          <Col width="50">
            <Button
              text="再選択する"
              handleClickAction={() => handleClickStep(1)}
              buttonType="small"
              style={{
                width: '50%',
                height: '25px',
                float: 'right',
                backgroundColor: 'rgb(229 229 229)',
                fontWeight: 'normal',
                color: 'black',
              }}
            />
          </Col>
        </Row>
        <p>
          <div className="input-label">
            会員名
            <span className="input-label-sub inputlabelnormal">任意</span>
          </div>
          <input
            type="text"
            value={memberName}
            onChange={(e) => setMemberName(e.target.value)}
          />
        </p>
        <p className="input-block">
          <div className="input-label">
            支店コード
            <span
              className={`input-label-sub ${
                branchCodeRequiredFlg === '1'
                  ? 'inputlabelrequired'
                  : 'inputlabelnormal'
              }`}
            >
              {branchCodeRequiredFlg === '1' ? ' 必須' : ' 任意'}
            </span>
          </div>
          <input
            type="text"
            name="BranchCode"
            value={branchCode}
            onChange={(e) => setBranchCode(e.target.value)}
          />
        </p>
        <p className="input-block">
          <div className="input-label">
            預金区分（1:普通 2:当座）
            <span
              className={`input-label-sub ${
                accountTypeRequiredFlg == '1'
                  ? 'inputlabelrequired'
                  : 'inputlabelnormal'
              }`}
            >
              {accountTypeRequiredFlg === '1' ? ' 必須' : ' 任意'}
            </span>
          </div>
          <input
            type="text"
            name="AccountType"
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
          />
        </p>
        <p className="input-block">
          <div className="input-label">
            口座番号
            <span
              className={`input-label-sub ${
                accountNumberRequiredFlg == '1'
                  ? 'inputlabelrequired'
                  : 'inputlabelnormal'
              }`}
            >
              {accountNumberRequiredFlg === '1' ? ' 必須' : ' 任意'}
            </span>
          </div>
          <input
            type="text"
            name="AccountNumber"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </p>
        <p className="input-block">
          <div className="input-label">
            口座名義カナ
            <span
              className={`input-label-sub ${
                accountNameKanaRequiredFlg == '1'
                  ? 'inputlabelrequired'
                  : 'inputlabelnormal'
              }`}
            >
              {accountNameKanaRequiredFlg === '1' ? ' 必須' : ' 任意'}
            </span>
          </div>
          <input
            type="text"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
        </p>
        <p className="input-block">
          <div className="input-label">
            口座名義
            <span className="input-label-sub inputlabelnormal">任意</span>
          </div>

          <input
            type="text"
            value={accountNameKanji}
            onChange={(e) => setAccountNameKanji(e.target.value)}
          />
        </p>
        <Row className="u-margin-top-xxl">
          <Col width="15" />
          <Col width="70">
            <Button text="追加する" handleClickAction={handleClickValidate} />
          </Col>
          <Col width="15" />
        </Row>
      </div>
    </form>
  );
};
