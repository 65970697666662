import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { useCallback, useEffect, useState } from 'react';
import { useToast } from './useToast';
import { multipleAffiliateInfo } from '@/api/authApi';
import { customerInfoApi } from '@/api/customerApi';
import { linkDriveOwnerApi } from '@/api/infoApi';
import { AuthInfoState, initialState } from '@/config/initialState';
import { store } from '@/config/store';
import { affiliateId as AFFILIATE_ID } from '@/consts/shop';
import { Car } from '@/types/api/customerApi';
import { getThemeIdFromAffiliateId } from '@/utils/shop';
import { storeDispatch } from '@/utils/store';
import { getKeyByValue } from '@/utils/utils';

export const useLoadCustomer = (
  f7router: Router.Router,
  token?: string,
  customerId?: number,
  status?: string,
  isLoggedInInit?: boolean,
) => {
  const m_car_id = window.localStorage.getItem('m_car_id');
  const t_order_id = window.localStorage.getItem('t_order_id');
  const storedGlobalTheme = window.localStorage.getItem('globalTheme');
  const isLoggedInToMaikuru =
    window.localStorage.getItem('isLoggedInToMaikuru') === 'true';
  const loginType = window.localStorage.getItem('loginType');
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInInit ?? false);
  const { openToast } = useToast();
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const isInfoLoaded = useStore(store, 'getIsInfoLoaded') as boolean;
  const [isFetching, setIsFetching] = useState(false);

  const autoLogin = useCallback(async () => {
    if (token && customerId) {
      await storeDispatch('setAuthInfo', 'authInfo', {
        m_customer_id: Number(customerId),
        token: decodeURI(token),
        isLogin: true,
      });
      await storeDispatch('setLoginInfo', 'loginInfo', {
        loginInfo: { loginType },
      });
      await storeDispatch(
        'setIsLoggedInToMaikuru',
        'isLoggedInToMaikuru',
        isLoggedInToMaikuru,
      );
      window.localStorage.removeItem('loginType');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('m_customer_id');
      window.localStorage.removeItem('globalTheme');
      window.localStorage.removeItem('isLoggedInToMaikuru');
      setIsLoggedIn(true);
    }
  }, [customerId, isLoggedInToMaikuru, token]);

  const setThemeIdFromAffiliateId = async (affiliateId: number | undefined) => {
    const globalTheme =
      storedGlobalTheme && storedGlobalTheme !== 'null'
        ? Number(storedGlobalTheme)
        : null;
    if (affiliateId && getKeyByValue(AFFILIATE_ID, affiliateId)) {
      if (globalTheme) {
        await storeDispatch('setGlobalThemeId', 'themeId', globalTheme);
      } else {
        await storeDispatch(
          'setThemeId',
          'themeId',
          getThemeIdFromAffiliateId(affiliateId),
        );
      }
    }
  };

  const setStoreLinkDriveOwner = async (cars?: Car[]) => {
    if (!cars || !customerId) return;

    try {
      const { data: linkDriveOwner } = await linkDriveOwnerApi({
        params: {
          customer_id: Number(customerId),
          stock_car_id: cars[0].t_stock_car_id,
          del_flg: 0,
        },
      });
      window.localStorage.removeItem('currentCarId');

      if (
        linkDriveOwner?.error &&
        linkDriveOwner.error.message !== 'LinkDriveOwnerなし'
      ) {
        throw new Error(linkDriveOwner.error.message);
      }
      await Promise.all([
        await storeDispatch(
          'setLinkDriveOwnerInfo',
          'linkDriveOwnerInfo',
          linkDriveOwner.data.link_drive_owner,
        ),
        await storeDispatch(
          'setOwnerId',
          'ownerId',
          linkDriveOwner.data.link_drive_owner.owner_id,
        ),
        await storeDispatch(
          'setSerialNumberDb',
          'serialNumberDb',
          linkDriveOwner.data.link_drive_owner.serial_no,
        ),
      ]);
      return linkDriveOwner;
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e.message);
      }
    }
  };

  const setCustomerInfo = useCallback(async () => {
    if (!customerId) return;
    try {
      const { data: customerInfo } = await customerInfoApi({
        m_customer_id: Number(customerId),
      });
      if (!customerInfo.success) {
        throw new Error('customerInfoApi failed');
      }
      const {
        customer,
        cars,
        bill_infomations,
        payment_methods,
        prepaids,
        informations,
        campaigns,
      } = customerInfo;
      let car = cars?.[0];
      if ((m_car_id || t_order_id) && cars) {
        car =
          cars.find((i) =>
            !i.m_car_id
              ? i.t_order_id === Number(t_order_id)
              : i.m_car_id === Number(m_car_id),
          ) || cars[0];
      }
      await Promise.all([
        storeDispatch(
          'setCarItemInfo',
          'carItemInfo',
          car || initialState.carItemInfo,
        ),
        storeDispatch('setCustomerInfo', 'customerInfo', customer),
        storeDispatch('setCarsInfo', 'carsInfo', cars),
        storeDispatch('setBillsInfo', 'billsInfo', bill_infomations),
        storeDispatch(
          'setPaymentMethodsInfo',
          'paymentMethodsInfo',
          payment_methods,
        ),
        storeDispatch(
          'setAdvancePaymentsInfo',
          'advancePaymentsInfo',
          prepaids,
        ),
        storeDispatch(
          'setNotificationsInfo',
          'notificationsInfo',
          informations,
        ),
        storeDispatch('setCampaignsInfo', 'campaignsInfo', campaigns),
        storeDispatch(
          'setWarranties',
          'warranties',
          cars?.[0]?.warranties || [],
        ),
        storeDispatch(
          'setNextWarranties',
          'nextWarranties',
          cars?.[0]?.next_warranty || [],
        ),
        storeDispatch(
          'setCurrentWarranty',
          'currentWarranty',
          cars?.[0]?.warranties?.[0],
        ),
        storeDispatch(
          'setNextWarranty',
          'nextWarranty',
          cars?.[0]?.next_warranty?.[0],
        ),
      ]);
      return customerInfo;
    } catch (e) {
      openToast('基本情報の取得に失敗しました', 'toast-failed');
      f7.views.main.router.navigate('/');
    }
  }, [customerId, openToast]);

  const retrieveUserInfo = useCallback(async () => {
    try {
      f7.preloader.show();
      const customerInfo = await setCustomerInfo();
      if (!customerInfo) return;
      let parameter = '';
      if (loginType === 'email') {
        parameter = customerInfo.customer?.email || '';
      }
      if (loginType === 'telephone') {
        parameter = customerInfo.customer?.phone_no1 || '';
      }
      if (loginType === 'admin') {
        parameter =
          customerInfo?.customer?.email ||
          customerInfo?.customer?.phone_no1 ||
          '';
      }
      if (parameter) {
        const { data: affiliatesInfo } = await multipleAffiliateInfo({
          parameter,
        });
        await storeDispatch(
          'setAffiliatesInfo',
          'affiliatesInfo',
          affiliatesInfo.m_customers,
        );
      }
      await storeDispatch('setIsInfoLoaded', 'isInfoLoaded', true);
      await setThemeIdFromAffiliateId(customerInfo.customer?.m_affiliate_id);
      await setStoreLinkDriveOwner(customerInfo.cars);
    } catch (e: unknown) {
      console.error(e);
    } finally {
      setIsFetching(false);
      f7.preloader.hide();
    }
  }, []);

  const resetIsLoggedIn = () => setIsLoggedIn(false);

  useEffect(() => {
    if (!isLoggedIn) {
      autoLogin();
    }
  }, [autoLogin, isLoggedIn, status]);

  useEffect(() => {
    if (authInfo.isLogin && isInfoLoaded) return;
    if (!isFetching) {
      setIsFetching(true);
      void retrieveUserInfo();
    }
  }, [authInfo, isInfoLoaded]);

  return {
    isLoggedIn,
    resetIsLoggedIn,
    retrieveUserInfo,
  };
};
