import { Router } from 'framework7/types';
import { FC } from 'react';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import './roadserviceGuide.scss';

interface RoadserviceGuideProps {
  f7router: Router.Router;
}

export const RoadserviceGuide: FC<RoadserviceGuideProps> = ({ f7router }) => {
  return (
    <PageTemplate
      pageName="roadserviceGuide"
      title="ロードサービス利用手順"
      showNavBack={true}
    >
      <div className="card card-outline u-margin-top-md">
        <div className="card-block">
          <div className="card-header">
            <span className="number">➊</span> ロードサービスに電話
          </div>
          <div className="card-content">
            上のボタンからロードサービスに電話をかけ、状況をご説明ください。
            <p className="u-margin-top-sm" style={{ color: 'gray' }}>
              ※お電話の際に位置情報を取得しております。
            </p>
          </div>
        </div>
        <div className="card-block">
          <div className="card-header">
            <span className="number">❷</span>ロードサービス到着
          </div>
          <div className="card-content">
            到着しましたら、お車の状態を確認いたします。
            <br />
            現場復旧が可能でしたらその場で処置を行い完了となります。
            <br />
            現場復旧が困難な場合は、指定工場へ搬送の上、保証修理対応をさせていただきます。
          </div>
        </div>
        <div className="card-block">
          <div className="card-header">
            <span className="number">❸</span>入庫／故障内容の確認
          </div>
          <div className="card-content">
            指定工場にて故障内容を確認し、修理が必要な箇所のお通盛を作成します。
            <br />
            <p className="u-margin-top-xs">
              ※作成したお見積もりは、指定工場からサポートセンターに共有されます。
            </p>
          </div>
        </div>

        <div className="card-block">
          <div className="card-header">
            <span className="number">❹</span>保証適用可否審査・結果のご連絡
          </div>
          <div className="card-content">
            工場で作成されましたお見積もり内容をもとに、保証適用可否の判定をさせていただきます。
            <br />
            判定結果が出ましたら、ご連絡します。
            <br />
            <p className="u-margin-top-xs" style={{ color: '#FF4655' }}>
              ※判定結果のご連絡を待たずに修理を進められますと保険適用不可となります。お気を付けください。
            </p>
          </div>
        </div>

        <div className="card-block">
          <div className="card-header">
            <span className="number">❺</span>免責金のお支払い
          </div>
          <div className="card-content">
            保証のご利用が可能となった場合、保証条件の免責金をお支払いください。
            <br />
            <p className="u-margin-top-xs">
              ※ご加入の保証プランなどにより、免責金が発生しないこともございます。
              <br />
            </p>
            <p className="u-margin-top-xs">
              ※保証が適用できない場合も、そのまま修理を進めることが可能です。
            </p>
            <div className="u-margin-top-sm">
              <RoundSheet bgColor="#efefef" py="0px">
                <CustomList
                  f7router={f7router}
                  path={paths.warrantyDetail}
                  text="ご加入保証プランを確認する"
                  isBold
                  isTopBorder={false}
                />
              </RoundSheet>
            </div>
          </div>
        </div>
        <div className="card-block">
          <div className="card-header">
            <span className="number">❻</span>故障個所の修理
          </div>
          <div className="card-content">
            免責金のご入金が確認できましたら、部品発注・修理着工へと進みます。
            <br />
          </div>
          <div className="card-header">
            <span className="number">❼</span>納車
          </div>
          <div className="card-content-bottom">
            修理が完了しましたら、連絡をいたします。
            <br />
            その際、返事日時を決めさせていただきますので、お約束の日時でお車の引取りをお願いいたします。
            <br />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};
