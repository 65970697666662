import { Button, f7 } from 'framework7-react';
import { ButtonProps } from 'framework7-react/components/button';
import { Router } from 'framework7/types';
import { FC } from 'react';

interface TextLinkProps extends ButtonProps {
  icon?: string;
  path: string;
  f7router: Router.Router;
}

export const TextLink: FC<TextLinkProps> = ({
  icon = undefined,
  path,
  f7router,
  children,
}) => {
  const handleLinkJump = () => {
    f7.view.main.router.navigate(path, { animate: true });
  };
  return (
    <Button
      className="text-link-block-fl-row-left"
      icon={icon}
      iconSize="20px"
      onClick={handleLinkJump}
    >
      {children}
    </Button>
  );
};
