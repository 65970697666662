import { f7, Icon } from 'framework7-react';
import { FC } from 'react';
import { isIPhoneApp } from '@/config/device';
import './MenuFooterStyle.scss';
import { store } from '@/config/store';
import { switchShopTheme } from '@/utils/shop';
import { storeDispatch } from '@/utils/store';

interface Props {}

export const MenuFooter: FC<Props> = () => {
  const router = f7.view.main.router;
  const { themeId, menu } = store.state;
  const shopThemeObj = switchShopTheme(themeId);
  const liStyle = {
    '--background-color': shopThemeObj.mainColor,
  } as React.CSSProperties;
  const handleOnClick = (tabMenu: string) => {
    if (tabMenu !== menu) {
      storeDispatch('setMenu', 'menu', tabMenu);
    }
    router.navigate(`/${tabMenu}/`, {
      animate: false,
      reloadAll: true,
    });
  };

  return (
    <div id="footer-fix">
      <ul
        id="footer-fix-menu"
        className={isIPhoneApp ? 'footer-fix-menu-ios' : ''}
      >
        <li
          className={`${menu === 'news' && 'active'}`}
          style={{ ...liStyle }}
          onClick={() => handleOnClick('news')}
        >
          <Icon
            icon={menu === 'news' ? 'icon-info-fill' : 'icon-info'}
            size="24px"
          />
          <div>お知らせ</div>
        </li>
        <li
          className={`${menu === 'prepaid' && 'active'}`}
          style={{ ...liStyle }}
          onClick={() => handleOnClick('prepaid')}
        >
          <Icon
            icon={menu === 'prepaid' ? 'icon-wallet-fill' : 'icon-wallet'}
            size="24px"
          />
          <div>おサイフ</div>
        </li>
        <li
          className={`${menu === 'home' && 'active'}`}
          style={{ ...liStyle }}
          onClick={() => handleOnClick('home')}
        >
          <Icon
            icon={menu === 'home' ? 'icon-car' : 'icon-car-menu-fill'}
            size="32px"
          />
        </li>
        <li
          className={`${menu === 'trouble_shooting' && 'active'}`}
          style={{ ...liStyle }}
          onClick={() => handleOnClick('trouble_shooting')}
        >
          <Icon
            icon={
              menu === 'trouble_shooting'
                ? 'icon-car-active'
                : 'icon-car-inactive'
            }
            size="24px"
          />
          <div>トラブル</div>
        </li>
        <li
          className={`${menu === 'account' && 'active'}`}
          style={{ ...liStyle }}
          onClick={() => handleOnClick('account')}
        >
          <Icon
            icon={menu === 'account' ? 'icon-user-fill' : 'icon-user'}
            size="24px"
          />
          <div>アカウント</div>
        </li>
      </ul>
    </div>
  );
};
