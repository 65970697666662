import { baseApi } from '@/config/axios';
import {
  RepairSymptomResponse,
  RepairRequestParams,
  RepairRequestResponse,
  CarRepairInspectionParams,
  CarRepairInspectionResponse,
  DealRepairRequestParams,
} from '@/types/api/carRepairApi';

export const fetchRepairSymptoms = () =>
  baseApi.get<RepairSymptomResponse>(`/v1/customer/repair/symptoms`);

export const postRepairRequest = (repairRequestParams: RepairRequestParams) =>
  baseApi.post<RepairRequestResponse>(
    `/v1/customer/repair`,
    repairRequestParams,
  );
export const postDealRepairRequest = (
  repairRequestParams: DealRepairRequestParams,
) =>
  baseApi.post<RepairRequestResponse>(
    `/v1/customer/deal-warranty/repair`,
    repairRequestParams,
  );

export const fetchCarRepairInspection = async (
  params: CarRepairInspectionParams,
): Promise<CarRepairInspectionResponse> =>
  baseApi.get(`/v1/customer/cars/inspections`, { params });
