import { Router } from 'framework7/types';
import { FC } from 'react';
import { CancelWarranty } from './CancelWarranty/CancelWarranty';
import { store } from '@/config/store';

type CancelWarrantyPageProps = {
  f7router: Router.Router;
};

export const CancelWarrantyPage: FC<CancelWarrantyPageProps> = ({
  f7router,
}) => {
  const { currentWarranty: warranty } = store.state;
  return <CancelWarranty f7router={f7router} warranty={warranty} />;
};
