import { f7 } from 'framework7-react';
import { useState } from 'react';
import { multipleAffiliateInfo } from '@/api/authApi';
import { useToast } from '@/hooks/useToast';
import {
  MultipleAffiliateInfoApiParams,
  MultipleAffiliateInfoApiResponse,
} from '@/types/api/authApi';
import { storeDispatch } from '@/utils/store';

export const useMultipleAffiliateInfo = () => {
  const [data, setData] = useState<MultipleAffiliateInfoApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const { openToast } = useToast();

  const getMultipleAffiliateInfo = async (
    params: MultipleAffiliateInfoApiParams,
  ) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const { data } = await multipleAffiliateInfo(params);
      setData(data);
      if (!data.success) {
        throw new Error(data.error?.message);
      }
      if (data.m_customers.length === 0) {
        throw new Error('顧客データなし');
      }
      await storeDispatch(
        'setAffiliatesInfo',
        'affiliatesInfo',
        data.m_customers,
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        openToast(e.message, 'toast-failed');
      }
      setError(e);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    getMultipleAffiliateInfo,
    data,
    isLoading,
    error,
  };
};
