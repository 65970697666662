import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { getGuaranteeUpdateTargetApi } from '@/api/warrantyApi';
import {
  GetGuaranteeUpdateTargetParams,
  GetGuaranteeUpdateTargetResponse,
} from '@/types/api/warrantyApi';

export const useGuaranteeUpdateTarget = ({
  warranty_no,
  branch_no,
}: GetGuaranteeUpdateTargetParams) => {
  const [data, setData] = useState<GetGuaranteeUpdateTargetResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    const fn = async () => {
      try {
        const res = await getGuaranteeUpdateTargetApi({
          warranty_no,
          branch_no,
        });
        if (res.data.success) {
          if (!res.data.continuous_warranties) {
            throw new Error(res.data.error_message);
          }
          setData(res.data);
        } else {
          throw new Error(res.data.error_message);
        }
      } catch (err) {
        if (err instanceof Error) {
          const message =
            err.message === '追加保証情報なし'
              ? '変更できるプランがありません'
              : err.message;
          f7.dialog.alert(message, 'エラー');
        }
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [warranty_no, branch_no]);

  return {
    data,
    isLoading,
    error,
  };
};
