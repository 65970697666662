import { Card, CardContent, f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { getCustomer } from '../UpdateWarrantyHelper';
import { LicensePlate } from '@/components/projects/LicensePlate/LicensePlate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useCustomerInfo } from '@/hooks/api/customer/useCustomerInfo';
import { hyphenToDay } from '@/utils/date';
import './UpdateWarrantyStep2Page.scss';

type UpdateWarrantyStep2Props = {
  setStep: (n: number) => void;
  f7router: Router.Router;
};

export const UpdateWarrantyStep2Page: FC<UpdateWarrantyStep2Props> = ({
  setStep,
  f7router,
}) => {
  const { customerInfo, carItemInfo } = store.state;
  const customer = getCustomer(customerInfo, carItemInfo);

  const execEmit = (n: number) => {
    setStep(n);
  };

  const handleNavigateClick = (u: string) => {
    f7.view.main.router.navigate(u);
  };

  useCustomerInfo({ m_customer_id: 0 });

  return (
    <div data-name="UpdateWarrantyStep2">
      <div className="content">
        <table className="menu-table u-margin-bottom-lg">
          <tr className="menu-tr-1">
            <td>●</td>
            <td className="select-number">
              <div>2</div>
            </td>
            <td>●</td>
            <td>●</td>
            <td>●</td>
            <td>●</td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'top' }} className="menu-table-item">
              プラン選択
            </td>
            <td className="menu-falling-under menu-table-item">
              契約情報
              <br />
              確認
            </td>
            <td className="menu-table-item">
              重要事項
              <br />
              確認
            </td>
            <td className="menu-table-item">
              支払回数
              <br />
              選択
            </td>
            <td className="menu-table-item">
              決済方法
              <br />
              選択
            </td>
            <td style={{ verticalAlign: 'top' }} className="menu-table-item">
              最終確認
            </td>
          </tr>
        </table>

        <div>
          <div className="step-title u-margin-bottom-sm">
            契約者様情報のご確認
          </div>
          <div style={{ fontSize: '1.4rem' }}>
            変更がある場合は「変更する」ボタンより変更してください。
          </div>
          <div className="card">
            <div className="card-content">
              <div className="list no-ios-edges">
                <ul>
                  <li className="plan-description2">
                    <div className={'card-title'}>契約者様情報</div>
                    <div
                      className={'card-title-btn'}
                      onClick={() => {
                        handleNavigateClick(paths.editCustomer);
                      }}
                    >
                      {' '}
                      変更する
                    </div>
                  </li>
                  <li className="plan-description3">
                    <div className="item1">契約者様氏名</div>
                    <div className="data-item1">{customer.contractorName}</div>
                  </li>
                  <li className="plan-description3">
                    <div className="item1">フリガナ</div>
                    <div className="data-item1">
                      {customer.contractorNameKana}
                    </div>
                  </li>
                  <li className="plan-description3">
                    <div className="item1">法人名</div>
                    <div style={{ width: '50%', textAlign: 'left' }}>
                      {customer.corporationName}
                    </div>
                  </li>
                  <li className="plan-description3">
                    <div className="item1">生年月日</div>
                    <div className="data-item1">
                      {hyphenToDay(customer.birthDay ?? '', true)}
                    </div>
                  </li>
                  <li className="plan-description3">
                    <div className="item1">携帯電話番号</div>
                    <div className="data-item1">{customer.mobilePhone}</div>
                  </li>
                  <li className="plan-description3" style={{ border: 'none' }}>
                    <div className="item1">自宅電話番号</div>
                    <div className="data-item1">{customer.homePhone}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="card">
            <div className="card-content">
              <div className="list no-ios-edges">
                <ul>
                  <li className="plan-description2">
                    <div className="card-title">契約者様お住まい情報</div>
                    <div
                      className="card-title-btn"
                      onClick={() => {
                        handleNavigateClick(paths.editAddress);
                      }}
                    >
                      変更する
                    </div>
                  </li>
                  <li className="address-info">
                    <div>{customer.zipCode}</div>
                    <div>{customer.address1}</div>
                    <div>{customer.address2}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Card>
            <CardContent>
              <div className="heading-with-button u-font-size-lg u-font-bold u-margin-bottom-sm">
                <div className="card-title">対象車両ナンバー</div>
                <div
                  className="u-margin-left-auto card-title-btn"
                  onClick={() => {
                    handleNavigateClick(paths.updateNumber);
                  }}
                >
                  変更する
                </div>
              </div>
              <LicensePlate
                area={customer.transportName!}
                classNumber={customer.classNo!}
                kana={customer.hiragana!}
                consecutiveNumber={customer.registryNo!}
              />
            </CardContent>
          </Card>
        </div>

        <div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <button className="btn" onClick={() => execEmit(3)}>
              次へ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
