import React, { FC } from 'react';

interface TextLinkCardProps {
  cardText: string;
  handleClick: () => void;
}

export const TextLinkCard: FC<TextLinkCardProps> = ({
  cardText,
  handleClick,
}) => {
  return (
    <div className="u-margin-bottom-md info-card info-card-elevated">
      <div
        className="other-info display-flex"
        style={{ border: '0 none' }}
        onClick={handleClick}
      >
        <div className="u-font-bold">{cardText}</div>
        <div>
          <i className="icon-right u-font-size-xxl" />
        </div>
      </div>
    </div>
  );
};
