import { addDays } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { RepairConfirmation } from './RepairConfirmation/RepairConfirmation';
import { TradeReservation } from './TradeReservation/TradeReservation';
import {
  FormInput,
  ReservationStep,
  tradeReservationSchema,
} from './TradeReservation/tradeReservationSchema';
import { Form } from '@/components/uiParts/Form';
import { CustomBgPageTemplate } from '@/components/uiParts/Template/CustomBgPageTemplate/CustomBgPageTemplate';
import { store } from '@/config/store';
import {
  CheckExpectedAt,
  IdentificationSubscriber,
  MIN_REPAIR_AFTER_CURRENT_DAY,
  TIME_PICKER_RANGER_OPTIONS,
} from '@/consts/carRepair';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';
import { f7CustomBack } from '@/utils/utils';
type TradeReservationPageProps = {
  repairId: number;
  repairReceptId: number;
};
export const TradeReservationPage: FC<TradeReservationPageProps> = ({
  repairId,
  repairReceptId,
}) => {
  const [reservationStep, setReservationStep] = useState<ReservationStep>(
    ReservationStep.BRING_CAR_TO_STORE,
  );
  const { m_affiliate_id, phone_no1, phone_no2 } = store.state.customerInfo;

  const { data: affiliateSettingResponse } = useMAffiliateSettings({
    m_affiliate_id,
  });
  const callCenterTel = affiliateSettingResponse?.data?.callcenter_tel;
  const defaultValues = useMemo(() => {
    const phoneNumber = phone_no1 || phone_no2;
    const initialState: FormInput = {
      is_checked_expected_nyukosaki: CheckExpectedAt.AUTHORIZED_DEALER,
      expected_nyuko_date1: addDays(new Date(), MIN_REPAIR_AFTER_CURRENT_DAY),
      // eslint-disable-next-line no-magic-numbers
      expected_nyuko_time1: TIME_PICKER_RANGER_OPTIONS[1].value,
      is_checked_identification_subscriber: IdentificationSubscriber.MYSELF,
      contact_person_name: {
        family_name: '',
        first_name: '',
      },
      contact_person_name_kana: {
        family_name: '',
        first_name: '',
      },
      contact_person_phone_number: {
        tel1: undefined,
        tel2: undefined,
        tel3: undefined,
      },
    };
    if (phoneNumber) {
      const [tel1, tel2, tel3] = phoneNumber.split('-');
      if (tel1 && tel2 && tel3) {
        initialState.contact_person_phone_number = {
          tel1,
          tel2,
          tel3,
        };
      }
    }
    return initialState;
  }, [phone_no1, phone_no2]);
  const titlePage = useMemo(
    () =>
      reservationStep === ReservationStep.CONFIRMATION
        ? '依頼内容の確認'
        : '入庫予約',
    [reservationStep],
  );

  const handleClickBack = () => {
    if (reservationStep === ReservationStep.BRING_CAR_TO_STORE) {
      return f7CustomBack();
    }

    setReservationStep(ReservationStep.BRING_CAR_TO_STORE);
  };
  const handleOnSubmit = () => {
    setReservationStep(ReservationStep.CONFIRMATION);
  };

  return (
    <CustomBgPageTemplate
      pageName="TradeReservationPage"
      title={titlePage}
      showNavBack
      handleClickBack={handleClickBack}
      background={{ background: '#fff' }}
    >
      <Form<FormInput, typeof tradeReservationSchema>
        options={{ defaultValues }}
        onSubmit={handleOnSubmit}
        schema={tradeReservationSchema}
      >
        {() => (
          <div>
            {reservationStep === ReservationStep.BRING_CAR_TO_STORE && (
              <TradeReservation callCenterTel={callCenterTel} />
            )}
            {reservationStep === ReservationStep.CONFIRMATION && (
              <RepairConfirmation
                repairId={repairId}
                repairReceptId={repairReceptId}
              />
            )}
          </div>
        )}
      </Form>
    </CustomBgPageTemplate>
  );
};
