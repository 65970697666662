import { Router } from 'framework7/types';
import { FC } from 'react';
import { RoadserviceGuide } from './RoadserviceGuide/RoadserviceGuide';

interface RoadserviceGuidePageProps {
  f7router: Router.Router;
}
export const RoadserviceGuidePage: FC<RoadserviceGuidePageProps> = ({
  f7router,
}) => {
  return <RoadserviceGuide f7router={f7router} />;
};
