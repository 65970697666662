import { Block, Link, Navbar, NavLeft, NavTitle, Page } from 'framework7-react';
import React, { FC } from 'react';

export const PositionDetectingPage: FC = () => {
  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link back animate>
            <i className="icon-left" />
          </Link>
        </NavLeft>
        <NavTitle>検知場所</NavTitle>
      </Navbar>
      <Block>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3240.88852697053!2d139.8059981!3d35.6797467!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018891bfc592aad%3A0xd222029e041bd31b!2z44CSMTM1LTAwMjIg5p2x5Lqs6YO95rGf5p2x5Yy65LiJ5aW977yU5LiB55uu77yR4oiS77yRIOadseS6rOmDveePvuS7o-e-juihk-mkqA!5e0!3m2!1sja!2sjp!4v1621326065459!5m2!1sja!2sjp"
          width="400"
          height="450"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
        />
      </Block>
    </Page>
  );
};
