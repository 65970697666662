import { FC } from 'react';
import style from './DriveInsuranceStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';

interface DriveInsuranceProps {
  handleClickCalculateButton: () => void;
}
export const DriveInsurance: FC<DriveInsuranceProps> = ({
  handleClickCalculateButton,
}) => {
  return (
    <PageTemplate pageName="DriveInsurance" title="保険" showNavBack>
      <div className={style['page-container']}>
        <RoundSheet>
          <div className={style['insurance-card-title']}>Special Offer</div>
          <div className={`${style['title']}`}>
            安全運転をすると安くなる保険
          </div>
          <div
            className={`${style['insurance-card-desc']} insurance-card_desc`}
          >
            LINKDrive
            Airを装着したお車から取得した走行データを活用した運転運転レポートに記載された運転アドバイスなどを確認することで、これまで意識していなかった
            <span className={style['blue-bold']}>自分の運転の特徴</span>
            （クセなど）を知り、具体的な要注意ポイントを把握できます。
            <br />
            また、一定の条件を満たした場合、運転実績に応じてあいおいニッセイ同和損害保険株式会社（あいおいニッセイ同和損保）のテレマティクス自動車保険に加入する際の
            <span className={style['blue-bold']}>保険料が割引</span>になります。
          </div>
          <div className={style['section']}>
            <div className={style['sub-title']}>
              {`運転実績（安全運転スコア）と\n割引のイメージ`}
            </div>
            <div className={style['score-image-wrapper']}>
              <div className={`${style['row']} ${style['row-title']}`}>
                <div className={style['row-div']}>安全運転スコア</div>
                <div className={style['row-div']}>区分</div>
                <div className={style['row-div']}>運転特性割引</div>
              </div>
              <div className={style['row']}>
                <div className={style['row-div']}>
                  <span className={style['row-a-item']}>80</span>
                  点以上
                </div>
                <div className={style['row-div']}>
                  <span className={`${style['row-a-item']}`}>A</span>
                  区分
                </div>
                <div className={`${style['row-div']}`}>
                  <span className={`${style['row-a-item']} row-a-item`}>8</span>
                  %割引
                </div>
              </div>
              <div className={style['row']}>
                <div className={style['row-div']}>
                  <span className={style['row-b-item']}>60~79</span>点
                </div>
                <div className={style['row-div']}>
                  <span className={style['row-b-item']}>B</span>
                  区分
                </div>
                <div className={style['row-div']}>
                  <span className={style['row-b-item']}>4</span>
                  %割引
                </div>
              </div>
              <div className={style['row']}>
                <div className={style['row-div']}>
                  <span className={style['row-c-item']}>59</span>
                  点以下
                </div>
                <div className={style['row-div']}>
                  <span className={style['row-c-item']}>C</span>
                  区分
                </div>
                <div className={style['row-div']}>割引なし</div>
              </div>
            </div>
            <div className={style['image-annotation']}>
              {`(注) 加入する保険商品の種類や条件によっては割引適用が行えません。\nまた、加入する保険商品によっては適用される割引率が異なります。`}
            </div>
          </div>
          <div className={style['section']}>
            <div className={style['sub-title']}>
              運転実績（安全運転スコア）の確認方法
            </div>
            <div className={style['insurance-card-desc']}>
              画面下部の
              <span className={`${style['blue-bold']}`}>
                「累計スコアを計算する」
              </span>
              から確認することができます。
              <br />
              保険加入時は累計スコア判定画面に表示される
              <span className={style['blue-bold']}>
                「累計スコア」 「デバイス区分」 「デバイスキー」
              </span>
              をあいおいニッセイ同和損保にお伝えください。
            </div>
          </div>
          <div className={style['section']}>
            <div className={style['sub-title']}>対象者</div>
            <div className={style['insurance-card-desc']}>
              <div className={style['flex-column']}>
                <span>・ リンクドライブをご利用されている方</span>
                <span>
                  ・ あいおいニッセイ同和損保の自動車保険へ加入される方
                </span>
              </div>
            </div>
          </div>
          <div className={style['section']}>
            <div className={style['sub-title']}>割引が可能な保険会社</div>
            <div className={style['insurance-card-desc']}>
              <div className={style['flex-column']}>
                ・ あいおいニッセイ同和損害保険株式会社
              </div>
            </div>
          </div>
        </RoundSheet>
        <div className={style['button']}>
          <Button handleClickAction={handleClickCalculateButton}>
            累計スコアを計算する
          </Button>
        </div>
      </div>
    </PageTemplate>
  );
};
