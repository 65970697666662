import { Block, List, ListItem } from 'framework7-react';
import { FC } from 'react';
import './style.scss';

export const BlockComponent: FC = () => {
  return (
    <Block>
      <div className="u-margin-bottom-lg u-font-bold u-font-size-xl text-align-center">
        積み立てを停止しますか？
      </div>
      <List className="plan-info u-margin-bottom-lg">
        <ListItem>
          <div className="u-font-size-lg u-font-bold">ご契約中のプラン内容</div>
        </ListItem>
        <ListItem>
          <div className="list-item-title u-font-size-lg u-font-bold">
            プラン名
          </div>
          <div className="u-font-size-lg">○○プラン</div>
        </ListItem>
        <ListItem>
          <div className="list-item-title u-font-size-lg u-font-bold">
            積み立て額
          </div>
          {/* <div className="u-font-size-lg">{{ $filters.currency(10000) }} 円 / 月</div> */}
          <div className="u-font-size-lg">1,000 円 / 月</div>
        </ListItem>
        <ListItem>
          <div className="list-item-title u-font-size-lg u-font-bold">特典</div>
          <div className="u-font-size-lg">
            リース残価精算時の実質負担を最大50万円サポート
          </div>
        </ListItem>
      </List>
      <List className="plan-info">
        <ListItem>
          <div className="u-font-size-lg u-font-bold">注意事項・連絡事項</div>
        </ListItem>
        <ListItem>
          <div className="u-font-size-lg">
            積み立てを停止すると、ご利用中のプランの特典も受けられなくなります
          </div>
        </ListItem>
        <ListItem>
          <div className="u-font-size-lg">
            チャージ済みの金額は引き続きお使いいただけます
          </div>
        </ListItem>
        <ListItem>
          <div className="u-font-size-lg">
            2年間ご利用。チャージがされない場合は、有効期限切れとなり、チャージ済み金額がご利用いただけなくなります
            <br />
            ご注意ください
          </div>
        </ListItem>
        <ListItem>
          <div className="u-font-size-lg">
            積み立てはいつでも再開いただけます
          </div>
        </ListItem>
      </List>
    </Block>
  );
};
