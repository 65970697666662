import { f7 } from 'framework7-react';
import { FC } from 'react';
import { MapCar } from './MapCar';
import { Paper } from '@/components/uiParts/Card';
import { HighlightFetchData } from '@/hooks/api/driveRecord/useHighlightData';

interface ParkingRecordProps {
  mapData?: {
    latitude?: number;
    longitude?: number;
    location?: string;
    time?: string;
    color: string;
    weight: number;
  };
  dataHighLight?: HighlightFetchData;
}

export const ParkingRecord: FC<ParkingRecordProps> = ({
  mapData,
  dataHighLight,
}) => {
  const handleClickMap = () => {
    f7.view.main.router.navigate('/shopmap/', {
      props: {
        pageTitle: '最後の駐車記録',
        parkingAddr: dataHighLight?.highlight?.data.timeline_log.address,
        isIcon: true,
      },
    });
  };
  return (
    <Paper cardHeader="最後の駐車記録" cardCN="u-margin-top-0">
      <MapCar mapData={mapData} handleClickMap={handleClickMap} />
    </Paper>
  );
};
