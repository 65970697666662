import { Link } from 'framework7-react';
import { FC, useState } from 'react';
import { LinkDriveSetupStep1Page } from '../LinkDriveSetupStep1/LinkDriveSetupStep1Page';
import { LinkDriveSetupStep2Page } from '../LinkDriveSetupStep2/LinkDriveSetupStep2Page';
import { LinkDriveSetupStep3Page } from '../LinkDriveSetupStep3/LinkDriveSetupStep3Page';
import style from './LinkDriveSetupStepsStyle.module.css';
import { Dialog } from '@/components/uiParts/Dialog/Dialog';
import { Stepper } from '@/components/uiParts/Stepper/Stepper';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { useSetStoreLinkDriveOwner } from '@/hooks/api/info/useSetStoreLinkDriveOwner';
import { steps, useLinkDriveSetup } from '@/hooks/useLinkDriveSetup';
import { f7CustomBack } from '@/utils/utils';

const tabs = [
  'シリアルナンバー入力 or スキャン',
  'コネクタを挿してエンジン始動',
  '完了',
];

export const LinkDriveSetupSteps: FC = () => {
  const mCustomerId = store.state.authInfo.m_customer_id;
  const tStockCarId = store.state.linkDriveOwnerInfo.t_stock_car_id;

  const {
    step,
    closeOpen,
    setStep,
    setCloseOpen,
    handleBack,
    handleClose,
    submitStep1,
    submitStep2,
  } = useLinkDriveSetup();
  const { setStoreLinkDriveOwner } = useSetStoreLinkDriveOwner();

  const [controller] = useState(new AbortController());
  const [isRecallLinkDriveOwnerSuccess, setIsRecallLinkDriveOwnerSuccess] =
    useState(false);
  const [linkDriveStepHasError, setLinkDriveStepHasError] = useState(false);

  const handlePopupClose = () => {
    controller.abort();
    f7CustomBack();

    // back to connector setting when error
    if (linkDriveStepHasError) {
      setStoreLinkDriveOwner({
        params: {
          customer_id: mCustomerId,
          stock_car_id: tStockCarId,
          del_flg: 0,
        },
      });
      f7CustomBack();
    }
  };

  return (
    <PopupPageTemplate
      pageName="LinkDriveSetupSteps"
      title="LinkDriveセットアップ"
      isCloseIcon={false}
      swipeToClose={false}
      closeOnEscape={false}
      closeByBackdropClick={false}
      containerClass={style['container']}
    >
      {step < steps.STATUS_CHECK && (
        <div className={style['icon-wrapper']}>
          <Link onClick={handleBack} animate className={style['icon']}>
            <i className={`icon-left`} style={{ color: '#232323' }} />
          </Link>
        </div>
      )}
      <div className={`${style['icon-wrapper']} ${style['icon-right']}`}>
        <Link onClick={handleClose} animate className={style['icon']}>
          <i className={`icon-close`} style={{ color: '#232323' }} />
        </Link>
      </div>
      <Stepper activeTabId={step} tabs={tabs} />
      {step === steps.SERIAL && (
        <LinkDriveSetupStep1Page
          onSubmit={submitStep1}
          setLinkDriveStepHasError={setLinkDriveStepHasError}
        />
      )}
      {step > steps.SERIAL && step < steps.SUCCESS && (
        <LinkDriveSetupStep2Page
          setIsRecallLinkDriveOwnerSuccess={setIsRecallLinkDriveOwnerSuccess}
          setLinkDriveStepHasError={setLinkDriveStepHasError}
          step={step}
          signal={controller.signal}
          setStep={setStep}
          onSubmit={submitStep2}
        />
      )}
      {step === steps.SUCCESS && (
        <LinkDriveSetupStep3Page
          isRecallLinkDriveOwnerSuccess={isRecallLinkDriveOwnerSuccess}
        />
      )}
      {closeOpen && (
        <Dialog
          buttonOkText="セットアップを中止する"
          title="セットアップを中止しますか？"
          subTitle={'入力した内容が破棄されます。\n本当に中止しますか？'}
          handleCancel={() => setCloseOpen(false)}
          handleOk={handlePopupClose}
        />
      )}
    </PopupPageTemplate>
  );
};
