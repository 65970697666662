export const chargeFlg = {
  STOP: 0,
} as const;

export const warrantyAlignmentFlg = {
  LINK_WARRANTY: 1,
};

export const processingCategory = {
  BASIC_SAVING: 1,
  REGULAR_SAVING: 2,
};
