import { Router } from 'framework7/types';
import { Consulting } from '../Consulting/Consulting';
import { NewsList } from '../NewsList/NewsList';
import { TodoList } from '../TodoList/TodoList';
import { paths } from '@/config/paths';

export const tabsNews: Router.RouteParameters[] = [
  {
    path: paths.newsList,
    id: 'news-list',
    component: NewsList,
    name: 'お知らせ',
    options: {
      props: {
        icon: '',
        animate: true,
        tabText: 'お知らせ',
        fontSize: '14px',
      },
    },
  },
  {
    path: paths.todoList,
    id: 'todo-list',
    component: TodoList,
    name: '予定/やること',
    options: {
      props: {
        animate: true,
        icon: '',
        tabText: '予定/やること',
        fontSize: '14px',
      },
    },
  },
  {
    path: paths.consulting,
    id: 'consulting',
    component: Consulting,
    name: 'お問合せ',
    options: {
      props: {
        animate: true,
        icon: '',
        tabText: 'お問合せ',
        fontSize: '14px',
      },
    },
  },
];
