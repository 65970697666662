import { FC } from 'react';
import style from './RepairResultStyle.module.scss';

type RepairPartsProps = {
  header: string;
  desc: string;
  blockList?: string[];
};

export const RepairParts: FC<RepairPartsProps> = ({
  header = '',
  desc = '',
  blockList,
}) => {
  return (
    <div className={style['common-part']}>
      <div className={style['header']}>{header}</div>
      <div className={style['desc']}>{desc}</div>
      <div className={style['block-background']}>
        <div className={style['block-list']}>
          <ul
            className={`${style['list']} ${
              !blockList || blockList?.length < 2 ? style['list-no-number'] : ''
            }`}
          >
            {blockList?.map((part, idx) => (
              <li key={`${part}-${idx}`}>
                <p>{part}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
