import { AxiosError } from 'axios';
import { useState } from 'react';
import { vehicleChangeStatus } from '@/api/linkDriveApi';
import {
  VehicleChangeStatusApiParams,
  VehicleChangeStatusApiResponse,
} from '@/types/api/linkDriveApi';

interface Args {
  params: VehicleChangeStatusApiParams;
  onSuccess?: (
    data: VehicleChangeStatusApiResponse['data'],
  ) => void | Promise<void>;
  onFailure?: () => void;
}

export const useVehicleChangeStatus = () => {
  const [data, setData] = useState<VehicleChangeStatusApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const fetchVehicleChangeStatus = async (args: Args) => {
    try {
      setIsLoading(true);
      const { data } = await vehicleChangeStatus(args.params);
      setData(data);
      if (data.success) args.onSuccess?.(data);
      if (!data.success) args.onFailure?.();
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    fetchVehicleChangeStatus,
  };
};
