import { f7 } from 'framework7-react';
import { FC } from 'react';
import { PopupConfirm } from '../PopupConfirm/PopupConfirm';
import style from './TradeGuaranteeActions.module.css';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';
import {
  SCREENING_RESULT_TYPE,
  STATUS_TRADE_GUARANTEE,
} from '@/consts/warranty';
import { GetDealWarrantyInfoApiResponse } from '@/types/api/warrantyApi';

interface TradeGuaranteeActionsProps {
  data?: GetDealWarrantyInfoApiResponse['data'];
  onClickConfirmReview: () => void;
  onClickPartsReceived: () => void;
  openPopupConfirm: boolean;
  setOpenPopupConfirm: (value: boolean) => void;
}

export const TradeGuaranteeActions: FC<TradeGuaranteeActionsProps> = ({
  data,
  onClickConfirmReview,
  onClickPartsReceived,
  openPopupConfirm,
  setOpenPopupConfirm,
}) => {
  return (
    <>
      <PopupConfirm
        title="付属品の受け取り手続きを進めてよろしいですか？"
        content="お車ご購入時にお知らせいただきましたご住所へお送りいたします。"
        handleSubmit={onClickConfirmReview}
        textButtonSubmit={'内容を確認して承諾'}
        opened={openPopupConfirm}
        handleClose={() => setOpenPopupConfirm(false)}
      />

      {(data?.deal_warranty_screening_result_type ===
        SCREENING_RESULT_TYPE.ACCESSORIES_DELIVERY ||
        data?.deal_warranty_screening_result_type ===
          SCREENING_RESULT_TYPE.MAINTENANCE_DELIVERY_AND_ACCESSORIES_DELIVERY) &&
        data?.status === STATUS_TRADE_GUARANTEE.CONTINUE_USE_AFTER_REVIEW && (
          <div className={style['container']}>
            <h4>付属品の受け取りはお済みでしょうか？</h4>
            <p className={style['content']}>
              保証対象の付属部品に関してお受け取りが完了した場合は下記ボタンより受け取り確認を行ってください。
            </p>
            <Button onClick={onClickPartsReceived}>付属品を受け取った</Button>
          </div>
        )}

      {data?.status === STATUS_TRADE_GUARANTEE.INCOMPLETE_DOCUMENT && (
        <div className={style['container']}>
          <h4>手続きを進める</h4>
          <p className={style['content']}>
            以下の書類を再アップロードし、再申請を行っててください。
          </p>
          <div className={style['status-remark']}>{data.status_remark}</div>
          <Button
            onClick={() => {
              f7.views.main.router.navigate(paths.tradeGuaranteeUpdate, {
                props: {
                  data: data,
                },
              });
            }}
          >
            再申請を行う
          </Button>
        </div>
      )}

      {data?.status === STATUS_TRADE_GUARANTEE.REVIEW_COMPLETED &&
        data?.deal_warranty_screening_result_type !==
          SCREENING_RESULT_TYPE.RETURN_CAR_SUPPORT && (
          <div className={style['container']}>
            {[
              SCREENING_RESULT_TYPE.MAINTENANCE_DELIVERY,
              SCREENING_RESULT_TYPE.MAINTENANCE_DELIVERY_AND_ACCESSORIES_DELIVERY,
            ].includes(data?.deal_warranty_screening_result_type || 0) && (
              <>
                <h4>手続きを進める</h4>

                <p className={style['content']}>
                  審査の結果、入庫していただき保証対応をいたします。下記ボタンより入庫予約を行なってください。
                </p>
                <Button
                  onClick={() =>
                    f7.view.main.router.navigate(paths.tradeReservation, {
                      props: {
                        repairId: data?.repair_id,
                        repairReceptId: data?.repair_recept_id,
                      },
                    })
                  }
                >
                  入庫予約を行う
                </Button>
              </>
            )}

            {data?.deal_warranty_screening_result_type ==
              SCREENING_RESULT_TYPE.ACCESSORIES_DELIVERY && (
              <>
                <h4>手続きを進める</h4>
                <p className={style['content']}>
                  審査の結果、入庫していただき保証対応をいたします。下記ボタンより入庫予約を行なってください。
                </p>
                <Button onClick={() => setOpenPopupConfirm(true)}>
                  付属品受け取り手続きを進める
                </Button>
              </>
            )}

            {data?.deal_warranty_screening_result_type ===
              SCREENING_RESULT_TYPE.OUT_OF_SCOPE && (
              <>
                <h4>手続きを進める</h4>
                <p className={style['content']}>
                  取引保証対象外となりますので、下記ボタンより審査結果の承諾を行なってください。
                </p>
                <Button onClick={onClickConfirmReview}>
                  審査結果を確認して承諾する
                </Button>
              </>
            )}
          </div>
        )}
    </>
  );
};
