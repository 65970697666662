import { f7 } from 'framework7-react';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { CarRepairCondition } from './CarRepairCondition/CarRepairCondition';
import { CarRepairConfirmation } from './CarRepairConfirmation/CarRepairConfirmation';
import { CarRepairContactRoadService } from './CarRepairContactRoadService/CarRepairContactRoadService';
import styles from './CarRepairPage.module.scss';
import { CarRepairRequestToStore } from './CarRepairRequestToStore/CarRepairRequestToStore';
import { CustomBgPageTemplate } from '@/components/uiParts/Template/CustomBgPageTemplate/CustomBgPageTemplate';
import { paths } from '@/config/paths';
import { CanDriveType, CarRepairStep } from '@/consts/carRepair';
import { useCarRepair } from '@/hooks/api/carRepair/useCarRepair';
import { f7CustomBack } from '@/utils/utils';

export const CarRepairPage: FC = () => {
  const {
    form,
    carRepairStep,
    setCarRepairStep,
    defects,
    defectOccurrenceFrequencies,
    callCenterTel,
    callCenterName,
    getCanDriveType,
    getDefectSelectees,
    getDefectOccurrenceFrequencySelected,
    getCheckExpectedAt,
    getIdentificationSubscriber,
    sendCarRepairRequest,
  } = useCarRepair();
  const { getValues, trigger } = form;

  const titlePage = useMemo(
    () =>
      carRepairStep === CarRepairStep.CONFIRMATION ? '依頼内容の確認' : '修理',
    [carRepairStep],
  );

  const handleClickBack = () => {
    if (carRepairStep === CarRepairStep.ENTER_CONDITION) {
      return f7CustomBack();
    }

    setCarRepairStep(
      [
        CarRepairStep.CONTACT_ROAD_SERVICE,
        CarRepairStep.BRING_CAR_TO_STORE,
      ].includes(carRepairStep)
        ? CarRepairStep.ENTER_CONDITION
        : CarRepairStep.BRING_CAR_TO_STORE,
    );
  };

  const submitRepairConditionStep = () => {
    setCarRepairStep(
      getValues('canDrive') === CanDriveType.CANNOT
        ? CarRepairStep.CONTACT_ROAD_SERVICE
        : CarRepairStep.BRING_CAR_TO_STORE,
    );
  };

  const submitRepairRequestToStoreForm = () => {
    setCarRepairStep(CarRepairStep.CONFIRMATION);
  };

  const handleBackToRepairConditionStep = () => {
    setCarRepairStep(CarRepairStep.ENTER_CONDITION);
  };

  const handleAfterSubmit = () =>
    f7.views.main.router.navigate(paths.home, { animate: false });

  useEffect(() => {
    if (carRepairStep === CarRepairStep.BRING_CAR_TO_STORE) {
      trigger('contact_person_phone_number');
    }
  }, [carRepairStep, trigger]);

  return (
    <CustomBgPageTemplate
      pageName="CarRepair"
      title={titlePage}
      className={styles['car-repair-page']}
      handleClickBack={handleClickBack}
      background={{ background: '#fff' }}
      showNavBack
    >
      <FormProvider {...form}>
        <form className={styles['container']} noValidate>
          {carRepairStep === CarRepairStep.ENTER_CONDITION && (
            <CarRepairCondition
              defects={defects}
              defectOccurrenceFrequencies={defectOccurrenceFrequencies}
              onSubmit={submitRepairConditionStep}
            />
          )}
          {carRepairStep === CarRepairStep.CONTACT_ROAD_SERVICE && (
            <CarRepairContactRoadService
              callCenterTel={callCenterTel}
              callCenterName={callCenterName}
            />
          )}
          {carRepairStep === CarRepairStep.BRING_CAR_TO_STORE && (
            <CarRepairRequestToStore
              callCenterTel={callCenterTel}
              onSubmit={submitRepairRequestToStoreForm}
            />
          )}
          {carRepairStep === CarRepairStep.CONFIRMATION && (
            <CarRepairConfirmation
              getCanDriveType={getCanDriveType}
              getDefectSelectees={getDefectSelectees}
              getDefectOccurrenceFrequencySelected={
                getDefectOccurrenceFrequencySelected
              }
              getCheckExpectedAt={getCheckExpectedAt}
              getIdentificationSubscriber={getIdentificationSubscriber}
              sendCarRepairRequest={sendCarRepairRequest}
              afterSubmit={handleAfterSubmit}
              backStepOne={handleBackToRepairConditionStep}
            />
          )}
        </form>
      </FormProvider>
    </CustomBgPageTemplate>
  );
};
