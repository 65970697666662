import { forwardRef } from 'react';
import { GmoChargeParams } from '@/types/api/payment';

interface PostChargeDataToGmoFormProps {
  GmoChargeParams: GmoChargeParams;
}

export const PostChargeDataToGmoForm = forwardRef<
  HTMLFormElement,
  PostChargeDataToGmoFormProps
>(function PostChargeDataToGmoForm({ GmoChargeParams }, ref) {
  return (
    <form
      action={`/gmo_link/link/${GmoChargeParams.ShopID}/Multi/Entry`}
      method="POST"
      ref={ref}
    >
      {Object.entries(GmoChargeParams).map(([key, value]) => (
        <input type="hidden" name={key} value={value} key={key} />
      ))}
    </form>
  );
});
