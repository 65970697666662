import { format, parse, parseISO } from 'date-fns';
import { Block, useStore } from 'framework7-react';
import { FC, useMemo } from 'react';
import { ParkingRecord } from '../ParkingRecord';
import styles from './DriveRecordForLinkDriverOwnerStyle.module.scss';
import { getHistoryData } from '@/components/pages/DriveRecord/DriveRecordHistory/DriveRecordHistoryHelper';
import { DriveHistoryCard } from '@/components/projects/DriveHistoryCard/DriveHistoryCard';
import {
  DriveShockItem,
  DriveShockItemProps,
} from '@/components/projects/DriveShockItem/DriveShockItem';
import { Paper } from '@/components/uiParts/Card';
import { AuthInfoState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { JaDateTime } from '@/consts/day';
import { HighlightFetchData } from '@/hooks/api/driveRecord/useHighlightData';
import { useHistory } from '@/hooks/api/driveRecord/useHistory';
import { useImpactHistory } from '@/hooks/api/driveRecord/useImpactHistory';
import { HistoryParams } from '@/types/api/driveRecordApi';
import { LinkDriveOwner } from '@/types/api/infoApi';
import { formatToJaDateTime } from '@/utils/date';

const impactHistorylimit = 3;
const historylimit = 2;

interface DriveRecordProps {
  mapData?: {
    latitude?: number;
    longitude?: number;
    location?: string;
    time?: string;
    color: string;
    weight: number;
  };
  dataHighLight?: HighlightFetchData;
}

export const DriveRecordForLinkDriverOwner: FC<DriveRecordProps> = ({
  mapData,
  dataHighLight,
}) => {
  const linkDriveOwnerInfo = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const diagnosisDate = useStore(store, 'getDiagnosticDate') as string;
  const ownerId = linkDriveOwnerInfo.owner_id;
  const mCustomerId = authInfo.m_customer_id;

  const historyParams: HistoryParams = useMemo(() => {
    const convertedDiagnosisDate = diagnosisDate
      ? parse(diagnosisDate, 'yyyy-MM-dd', new Date())
      : '';
    return {
      owner_id: ownerId,
      m_customer_id: mCustomerId,
      diagnosis_date: convertedDiagnosisDate
        ? format(convertedDiagnosisDate, 'yyyy-MM-dd')
        : '',
    };
  }, [diagnosisDate, mCustomerId, ownerId]);

  const { data: dataHistory, isNoData } = useHistory(historyParams);
  const historyList = getHistoryData(dataHistory?.data?.slice(0, historylimit));

  const impactHistoryParams = useMemo(
    () => ({
      owner_id: ownerId,
    }),
    [ownerId],
  );
  const { data: impactHistory, isNoData: isNoDataImpact } =
    useImpactHistory(impactHistoryParams);

  const listDriveShockHistory = useMemo<DriveShockItemProps[]>(
    () =>
      impactHistory?.success
        ? impactHistory.impact_histories
            ?.slice(0, impactHistorylimit)
            .map(({ impact_level, accident_time }) => ({
              level: impact_level,
              time: formatToJaDateTime(parseISO(accident_time), JaDateTime),
            }))
        : [],
    [impactHistory?.impact_histories, impactHistory?.success],
  );

  return (
    <div className={styles['container']}>
      {mapData && (
        <ParkingRecord dataHighLight={dataHighLight} mapData={mapData} />
      )}
      <DriveHistoryCard
        historyList={historyList ?? []}
        btnName="すべて見る"
        paths={`.${paths.driveHistory}`}
      />
      {!isNoDataImpact && (
        <Paper
          cardHeader="運転履歴"
          btnName="すべて見る"
          path={`.${paths.driveShockHistory}`}
        >
          {listDriveShockHistory.map((item, index) => (
            <DriveShockItem
              key={`${item.level}-${index}`}
              level={item.level}
              time={item.time}
            />
          ))}
        </Paper>
      )}
      {(isNoData || dataHistory?.data?.length === 0) && !mapData && (
        <Block className={styles['no-data']}>
          <div className="u-text-center u-font-size-xxl">
            直近の走行データはありません。
            <br />
            カレンダーから過去の走行データ
            <br />
            を確認できます。
          </div>
        </Block>
      )}
    </div>
  );
};
