import { FC } from 'react';
import style from './PromptConfirmStyle.module.css';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { useTerm } from '@/hooks/api/term/useTerm';
import { TermType } from '@/types/api/termsApi';

interface Props {
  type: TermType;
  opened: boolean;
  title: string;
  prepaidId?: string;
  onPopupClosed: () => void;
}

export const ConfirmTerm: FC<Props> = ({
  type,
  opened,
  title,
  prepaidId,
  onPopupClosed,
}) => {
  const { data } = useTerm(type, prepaidId);
  const html = { __html: data?.term || '' };

  return (
    <PopupTemplate title={title} opened={opened} onPopupClosed={onPopupClosed}>
      <p dangerouslySetInnerHTML={html} className={style['term-content']} />
    </PopupTemplate>
  );
};
