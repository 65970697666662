import { Customer } from '@/types/api/customerApi';

export const generateFullName = (
  first: string | undefined,
  last: string | undefined,
  defaultStr: string = '',
) => {
  let name = defaultStr;
  if (first && last) {
    name = `${last} ${first}`;
  } else if (first && !last) {
    name = first;
  } else if (!first && last) {
    name = last;
  }
  return name;
};

export const fillCustomerDefaults = (customer: Customer) => {
  return {
    ...customer,
    id: customer.id ?? 0,
    affiliate_name: customer.affiliate_name ?? '',
    m_unique_customer_id: customer.m_unique_customer_id ?? 0,
    m_affiliate_id: customer.m_affiliate_id ?? 0,
    customer_type: customer.customer_type ?? 0,
    first_name: customer.first_name ?? '',
    family_name: customer.family_name ?? '',
    first_name_kana: customer.first_name_kana ?? '',
    family_name_kana: customer.family_name_kana ?? '',
    company_name: customer.company_name ?? '',
    company_name_kana: customer.company_name_kana ?? '',
    company_flg: customer.company_flg ?? 0,
    company_representative_phone_no:
      customer.company_representative_phone_no ?? '',
    birthday: customer.birthday ?? '',
    gender: customer.gender ?? 0,
    zip_code1: customer.zip_code1 ?? '',
    zip_code2: customer.zip_code2 ?? '',
    address1: customer.address1 ?? '',
    address2: customer.address2 ?? '',
    address3: customer.address3 ?? '',
    email: customer.email ?? '',
    phone_no1: customer.phone_no1 ?? '',
    phone_no2: customer.phone_no2 ?? '',
    mobile_flg1: customer.mobile_flg1 ?? false,
    mobile_flg2: customer.mobile_flg2 ?? false,
    mobile_type1: customer.mobile_type1 ?? 0,
    mobile_type2: customer.mobile_type2 ?? 0,
    unit_name: customer.unit_name ?? '',
    unit_zip_code1: customer.unit_zip_code1 ?? '',
    unit_zip_code2: customer.unit_zip_code2 ?? '',
    unit_address: customer.unit_address ?? '',
    unit_tel: customer.unit_tel ?? '',
    unit_url: customer.unit_url ?? '',
    prepaid_service_flg: customer.prepaid_service_flg ?? 0,
    carwash_service_flg: customer.carwash_service_flg ?? 0,
    connector_term: customer.connector_term ?? '',
  };
};
