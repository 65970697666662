import { FC } from 'react';
import { EditAddress } from './EditAddress/EditAddress';
import { Customer } from '@/types/api/customerApi';

interface EditAddressPageProps {
  initialSetCustomer: (customer: Customer) => void;
}

export const EditAddressPage: FC<EditAddressPageProps> = ({
  initialSetCustomer,
}) => {
  return <EditAddress initialSetCustomer={initialSetCustomer} />;
};
