import { List, ListInput, f7 } from 'framework7-react';
import { ListInputProps } from 'framework7-react/components/list-input';
import { Picker as F7Picker } from 'framework7/types';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import style from './Partial.module.scss';

export interface PickerOption {
  label: string;
  value: number | string;
}

export interface PickerProps extends ListInputProps {
  id: string;
  name: string;
  label?: string;
  options?: PickerOption[];
  required?: boolean;
  showInputCaret?: boolean;
  pickerParameters?: Partial<F7Picker.Parameters>;
  hasError?: boolean;
  children?: ReactNode;
}

export const Picker: FC<PickerProps> = ({
  id,
  label,
  name,
  options = [],
  children,
  required = false,
  showInputCaret = true,
  pickerParameters = null,
  hasError = false,
  onChange = null,
  ...listInputProps
}) => {
  const [pickerInited, setPickerInited] = useState(false);
  const { control, setValue, trigger } = useFormContext();
  const currentValue = useWatch({ control, name });

  const handleChangePickerValue = (value: string | number) => {
    setValue(name, value);
    trigger(name);
    onChange && onChange();
  };

  const findOption = (value?: number | string) =>
    options.find((op) => op.value == value);

  useEffect(() => {
    if (pickerInited) {
      return;
    }
    setPickerInited(true);

    const picker = f7.picker.create({
      inputEl: `#picker-${id}`,
      rotateEffect: true,
      backdrop: true,
      toolbarCloseText: '完了',
      cssClass: style['picker'],
      value: pickerParameters?.value || [currentValue],
      formatValue:
        pickerParameters?.formatValue ||
        ((values, displayValues) => {
          if (!values[0]) {
            return '';
          }

          const currentDisplayValue = options.find(
            (op) => op.value == values[0],
          )?.label;
          if (currentDisplayValue && currentDisplayValue != displayValues[0]) {
            return currentDisplayValue;
          }
          return displayValues[0] as string;
        }),
      on: pickerParameters?.on || {},
      cols: pickerParameters?.cols || [
        {
          textAlign: 'center',
          displayValues: options.map((option) => option.label),
          values: options.map((option) => option.value as string),
          onChange: (picker, value) => handleChangePickerValue(value),
        },
      ],
    });
    return () => {
      f7.picker.destroy(picker);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style['input-picker-group']}>
      {label && (
        <label className={style['input-picker-label']}>
          {label}
          {required && (
            <span
              className={`${style['input-label-tips']} ${style['required']}`}
            >
              必須
            </span>
          )}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { value, name } }) => {
          return (
            <List
              className={`${style['input-picker-wrap']} ${
                hasError && style['input-picker-error']
              }`}
            >
              <ListInput
                {...listInputProps}
                type="text"
                readonly
                inputId={`picker-${id}`}
                value={findOption(value)?.label || ''}
              />
              {showInputCaret && <span className={style['input-caret']} />}
            </List>
          );
        }}
      />
      {children}
    </div>
  );
};
