import { f7, Link, NavLeft, Tab, Tabs } from 'framework7-react';
import { FC, useState } from 'react';
import { loginRouteId, LoginTabRoute, loginTabRoutes } from '../loginTabRoutes';
import style from './loginStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { LoginPageTemplate } from '@/components/uiParts/Template/LoginPageTemplate/LoginPageTemplate';
import { paths } from '@/config/paths';

export const Login: FC = () => {
  const [selectedTab, setSelectedTab] = useState(loginRouteId.loginTelephone);
  const handleClickTabButton = (id: string) => {
    setSelectedTab(id);
  };
  const navLeft = (
    <NavLeft>
      <Link
        onClick={() => {
          // backを使うとログアウト後にログインチュートリアルのページに戻れないのでnavigateを使う
          f7.views.main.router.navigate(paths.loginTutorial, {
            animate: false,
          });
        }}
        className={`${style['no-ripple']} no-ripple`}
      >
        <div>
          <i className="icon-left no-ripple" />
        </div>
      </Link>
    </NavLeft>
  );
  return (
    <LoginPageTemplate pageName="Login" title="ログイン" navLeft={navLeft}>
      <>
        <div className={style['upper-container']}>
          <h3>ログイン方法を選択してください</h3>
          <div className={style['buttons-container']}>
            {loginTabRoutes.map((route: LoginTabRoute) => (
              <div key={route.id}>
                <div className={style['button-wrapper']}>
                  <Button
                    className={`no-active-state ${style['button']}
                    ${
                      selectedTab === route.id
                        ? style['selected-button']
                        : style['no-selected-button']
                    }`}
                    tabLink
                    animate
                    round
                    href={`.${route.path}`}
                    handleClickAction={() => handleClickTabButton(route.id)}
                  >
                    <i className={route.options.props.icon} />
                  </Button>
                  <p className={style['label']}>
                    {route.options.props.tabText}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Tabs routable animated>
          {loginTabRoutes.map((tab) => (
            <Tab key={tab.id} id={tab.id} className="page-content" />
          ))}
        </Tabs>
      </>
    </LoginPageTemplate>
  );
};
