import { FC, ReactNode } from 'react';
import { List } from '@/components/uiParts/List/List';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import './prepaidHistoryStyle.scss';

type PrepaidHistoryDetail = {
  list: {
    label: string;
    value: string | ReactNode;
  }[];
};

export const PrepaidHistoryDetail: FC<PrepaidHistoryDetail> = ({ list }) => {
  return (
    <PageTemplate
      pageName="PrepaidHistoryDetail"
      title="支払履歴詳細"
      showNavBack={true}
    >
      <RoundSheet>
        <h3 className="head3">支払情報</h3>
        <List list={list} />
      </RoundSheet>
    </PageTemplate>
  );
};
