import { Router } from 'framework7/types';
import { Dispatch, FC, SetStateAction } from 'react';
import { Reservings } from './Reservings/Reservings';
import { GmoRedirectInfo, PrepaidData } from '@/types/payment';

type ReservingsPageProps = {
  f7router: Router.Router;
  setPrepaidData?: Dispatch<SetStateAction<PrepaidData>>;
  gmoRedirectInfo?: GmoRedirectInfo;
  isBackShowPrepaidSummary?: boolean;
};

export const ReservingsPage: FC<ReservingsPageProps> = ({
  f7router,
  setPrepaidData,
  gmoRedirectInfo,
  isBackShowPrepaidSummary,
}) => {
  return (
    <Reservings
      f7router={f7router}
      setPrepaidData={setPrepaidData}
      gmoRedirectInfo={gmoRedirectInfo}
      isBackShowPrepaidSummary={isBackShowPrepaidSummary}
    />
  );
};
