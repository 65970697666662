import { format } from 'date-fns';

export const HOUR_OPTIONS = (startTime: number, endTime: number) => {
  let hours = [];
  const SPLIT_HOUR = 2;
  const UNIT_MINUTE = 30;
  const UNIT = 10;

  for (let i = startTime; i <= endTime; i++) {
    for (let j = 0; j < SPLIT_HOUR; j++) {
      hours.push(
        `${i < UNIT ? `0${i}` : i}:${j === 0 ? `00` : UNIT_MINUTE * j}`,
      );
    }
  }

  return hours.splice(0, hours.length - 1);
};

export interface FormInput {
  delivery_preferred_date_1: string;
  delivery_preferred_date_2: string;
  delivery_preferred_date_3: string;
  delivery_preferred_hm_1: string;
  delivery_preferred_hm_2: string;
  delivery_preferred_hm_3: string;
  delivery_preferred_date_complement: string;
  duplicate: string;
}

export interface MailDayInput {
  year: string;
  month: string;
  day: string;
}

export interface DepositDayInput {
  year: string;
  month: string;
  day: string;
}

export const formatDate = (date: string) =>
  format(new Date(date), 'yyyy-MM-dd');

export const formatTime = (time: string) => time.replace(':', '');

export const convertTime = (time: string) => {
  const SLICE_POSITION = 2;
  if (!time) return '';

  return time.slice(0, SLICE_POSITION) + ':' + time.slice(SLICE_POSITION);
};

export const deopsitImperfectConfirmation = {
  NO_DEFECT: 1, // 不備なし
  DEFECTIVE: 2, // 不備あり
} as const;

export const imperfectConfirmation = {
  NO_DEFECT: 0, // 不備なし
  DEFECTIVE: 1, // 不備あり
} as const;
