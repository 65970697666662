import { Page } from 'framework7-react';
import { FC } from 'react';
import style from './SleepDialogStyle.module.scss';
import PlugLd from '@/assets/images/plug_link_drive.png';
import UnplugLd from '@/assets/images/unplug_link_drive.png';
import { Button } from '@/components/uiParts/Button';
interface SleepDialogProps {
  handleCancel: () => void;
  containerClass?: string;
}

export const SleepDialog: FC<SleepDialogProps> = ({
  handleCancel,
  containerClass,
}) => {
  return (
    <Page name="ConfirmDialog">
      <div>
        <div className={`container ${style['container']} ${containerClass}`}>
          <div>
            <div className={`container-title ${style['title']}`}>
              <i className="icon-info" />
              <h2>LINKDriveデバイスが停止状態です</h2>
            </div>
            <p className={style['sub-title']}>以下の手順を行なってください。</p>
          </div>
          <div className={style['icon-wrapper']}>
            <ol>
              <div>
                <img src={UnplugLd} width="100%" height="100%" />
              </div>
              <li>
                エンジンを停止させ、差し込み口からデバイスを抜いてください。
              </li>
              <div>
                <img src={PlugLd} width="100%" height="100%" />
              </div>
              <li>
                デバイスを差し直し、エンジンを始動させ、デバイスの起動が完了したら速やかにセットアップを始めてください。
                <br />
                <span>
                  ※デバイスは起動後数分以内にセットアップを始めないと自動的に停止します。
                </span>
              </li>
            </ol>
          </div>
          <Button
            className={`btn-cancel ${style['btn']}`}
            text="閉じる"
            handleClickAction={handleCancel}
          />
        </div>
      </div>
    </Page>
  );
};
