import { useStore } from 'framework7-react';
import { FC } from 'react';
import './PaymentReceiptPage.scss';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import { paymentTypeId } from '@/consts/payment';
import { BillInformationDetail } from '@/types/api/customerApi';
import { formatToJaDate, hyphenToYear } from '@/utils/date';

interface PaymentReceiptPageProps {
  billDetail: BillInformationDetail;
}
export const PaymentReceiptPage: FC<PaymentReceiptPageProps> = ({
  billDetail,
}) => {
  const customerInfo = useStore(store, 'customerInfo');

  return (
    <PageTemplate
      pageName="PaymentReceiptPage"
      title="領収書"
      showNavBack={true}
    >
      <div className="content-box">
        <div className="affiliate-row">
          <span>
            {billDetail?.m_payment_type_id === paymentTypeId.CREDIT
              ? `${customerInfo.affiliate_name}(${customerInfo.affiliate_invoice_service_name_credit})`
              : billDetail?.m_payment_type_id === paymentTypeId.BANK
              ? `${customerInfo.affiliate_name}(${customerInfo.affiliate_invoice_service_name_bank})`
              : customerInfo.affiliate_name}
          </span>

          <span>
            {customerInfo.affiliate_invoice_registration_number || ''}
          </span>
        </div>
        <div className="amount-box">
          <h3>{billDetail.pay_subject}</h3>
          <p className="head-small-text">
            {hyphenToYear(billDetail.billing_date || '', false)}
          </p>
          <div className="amount-content">
            <h3>お支払額</h3>
            <p className="amount-small-text">
              {!billDetail.warranty_no && billDetail.m_prepaid_id
                ? '（消費税対象外）'
                : '（消費税10%込）'}
            </p>
            <p className="amount">
              <label>{billDetail.bill_price?.toLocaleString()}</label>
              <span className="currency">円</span>
            </p>
          </div>
          <p className="publication-date">
            発行日付：{formatToJaDate(new Date())}
          </p>
        </div>
      </div>
    </PageTemplate>
  );
};
