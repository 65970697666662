import { FC } from 'react';
import style from './DriveScoreStyle.module.css';
import { phydScoreLevel } from '@/consts/driveHistory';

interface DriveScoreProps {
  score?: number;
}

export const DriveScore: FC<DriveScoreProps> = ({ score = 0 }) => {
  const judgeScoreLevel = () => {
    if (score >= phydScoreLevel.scoreA) return 'A';
    if (score < phydScoreLevel.scoreB) return 'C';
    return 'B';
  };

  return (
    <div className={style.scoreWrapper}>
      <div className={style.scoreWrapperRank}>
        <span>{judgeScoreLevel()}</span>
      </div>
      <div className={style.driveScoreWrapper}>
        <span>
          <span className={style.driveScore}>{score}</span>
          <span className={style.driveScoreUnit}>点</span>
        </span>
      </div>
    </div>
  );
};
