import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect } from 'react';
import { RegisterPayment } from './RegisterPayment/RegisterPayment';
import { getRegisterPaymentList } from './RegisterPaymentHelper';
import { PaymentDetail } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { usePaymentMethodChange } from '@/hooks/api/warranty/usePaymentMethodChange';
import { useRegisterPayment } from '@/hooks/api/warranty/useRegisterPayment';
import { BillInformationDetail } from '@/types/api/customerApi';
import { ChangePaymentInfo } from '@/types/payment';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

type RegisterPaymentPageProps = {
  f7router: Router.Router;
  type: string;
  no: string;
  // navigatorのオプションで渡ってくる選択されている決済方法を書き換える関数
  handleSetPaymentInfo: (paymentInfo: ChangePaymentInfo) => void;
  event?: () => void;
  redirectPage?: string;
  nextBill?: BillInformationDetail;
  chargeFlg?: string;
  currentCarId?: string;
  isGmoSuccess?: boolean;
};

export const RegisterPaymentPage: FC<RegisterPaymentPageProps> = ({
  f7router,
  type,
  no,
  handleSetPaymentInfo,
  event,
  redirectPage = '',
  nextBill,
  chargeFlg = '',
  currentCarId = '',
  isGmoSuccess = false,
}) => {
  const mCarId = currentCarId || localStorage.getItem('m_car_id');
  const tOrderId = currentCarId || localStorage.getItem('t_order_id');
  const { authInfo, customerInfo } = store.state;
  const { bills, paymentMethods, prepaids, currentWarranty } =
    useRegisterPayment({
      m_customer_id: authInfo.m_customer_id,
      warrantyNo: no,
      findFunc: (car) =>
        car.m_car_id
          ? car.m_car_id === Number(mCarId)
          : car.t_order_id === Number(tOrderId),
    });
  const { changePaymentMethod } = usePaymentMethodChange();
  const paymentList = getRegisterPaymentList(
    paymentMethods,
    prepaids,
    bills,
    currentWarranty,
    type,
    no,
    chargeFlg,
    isGmoSuccess,
  );
  const handleClickChangePaymentMethod = async (
    item: PaymentDetail,
    haveClose = true,
  ) => {
    if (item.isSelected) return;
    if (chargeFlg === 'prepaidCharge') {
      f7CustomBack(['#RegisterPayment']);
      await storeDispatch(
        'setPrepaidChargePayment',
        'prepaidChargePayment',
        item,
      );
      handleSetPaymentInfo({
        bankName: item.bankName,
        accountNumber: item.accountNumber,
        cardNo: item.cardNo,
        paymentTypeId: item.paymentTypeId,
      });
      return;
    }
    const params = {
      m_customer_id: customerInfo.id,
      bill_kind: type == 'warranty' ? 1 : 2,
      m_payment_type_id: item.paymentTypeId,
      m_payment_method_id: item.paymentMethodId,
      warranty_no: type == 'warranty' ? no : '',
      branch_no: type == 'warranty' ? currentWarranty?.branch_no : '',
    };
    await changePaymentMethod(params);
    !!event && event();
    haveClose && f7CustomBack(['#RegisterPayment']);
    return;
  };

  const handleClickAddMethod = () => {
    f7.view.main.router.navigate(paths.registerPaymentList, {
      props: {
        redirectPage: redirectPage ?? 'home',
        currentCarId,
      },
    });
  };

  const handleClickPopupClose = () => {
    f7CustomBack();
  };

  // 直前のページがチャージプリペイドの場合は
  // GMOに遷移する前のページをlocalStorageに保持する
  useEffect(() => {
    if (chargeFlg === 'prepaidCharge') {
      window.localStorage.setItem('source_page', paths.chargePrepaid);
    }
  }, [chargeFlg]);

  useEffect(() => {
    if (isGmoSuccess && !!paymentMethods?.length) {
      const lastRegisteredPayment = paymentMethods.reduce((max, cur) => {
        const maxDate = new Date(max.register ?? '');
        const curDate = new Date(cur.register ?? '');
        return curDate > maxDate ? cur : max;
      }, paymentMethods[0]);

      const payment: PaymentDetail = {
        paymentTypeId: lastRegisteredPayment.m_payment_type_id ?? 0,
        paymentTypeName: lastRegisteredPayment.payment_type_name ?? '',
        paymentMethodId: lastRegisteredPayment.m_payment_method_id ?? 0,
        bankName: lastRegisteredPayment.bank_name ?? '',
        accountNumber: lastRegisteredPayment.account_number ?? '',
        cardNo: lastRegisteredPayment.card_no ?? '',
        warrantyNo: '',
        noPayFlg: 0,
        isSelected: false,
        paySubject: '',
        register: lastRegisteredPayment.register ?? '',
      };
      handleClickChangePaymentMethod(payment, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGmoSuccess, paymentMethods]);

  return (
    <RegisterPayment
      paymentList={paymentList}
      nextBill={nextBill}
      handleClickChangePaymentMethod={handleClickChangePaymentMethod}
      handleClickAddMethod={handleClickAddMethod}
      handleClickPopupClose={handleClickPopupClose}
    />
  );
};
