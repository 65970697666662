import parse from 'html-react-parser';
import { FC, ReactNode } from 'react';
import style from './listStyle.module.css';

interface ListProps {
  list: {
    label: string;
    value: string | ReactNode;
  }[];
  listClass?: string;
  labelWidth?: string;
  isValueEnd?: boolean;
}
export const List: FC<ListProps> = ({
  list,
  listClass = undefined,
  labelWidth = '100px',
  isValueEnd = false,
}) => {
  return (
    <div className={style['list-wrapper']}>
      {list.map((e) => (
        <div className={`${style['list']} ${listClass}`} key={e.label}>
          <div style={{ width: labelWidth, flexShrink: 0 }}>
            <span className={style['list-label']}>{parse(e.label)}</span>
          </div>
          <div
            className={`${style['list-value-wrapper']} ${
              isValueEnd && style['value-end']
            }`}
          >
            <span className={style['list-value']}>{e.value}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
