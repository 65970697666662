import isLastDayOfMonth from 'date-fns/isLastDayOfMonth';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ConfirmInput } from '../PromptConfirmDetail';
import style from './PromptConfirmStyle.module.css';
import { RhfDropdown } from '@/components/uiParts/ReactHookForm/RhfDropdown/RhfDropdown';
import { getDayInMonthOptions } from '@/utils/date';

const getMonthOption = (current: number) => {
  const options = [];
  const previous = current - 1;
  const isLastDay = isLastDayOfMonth(new Date());
  if (!isLastDay) {
    if (previous === 0) {
      options.push('12');
    } else {
      options.push(previous < 10 ? '0' + previous : String(previous));
    }
  }
  options.push(current < 10 ? '0' + current : String(current));
  return options;
};

const getYears = () => {
  const currentDate = new Date();
  const month = currentDate.getMonth();
  const options = [String(currentDate.getFullYear())];
  if (month === 0) {
    options.push(String(currentDate.getFullYear() - 1));
  }
  return options;
};

interface Props {
  deliveryConfirmedDate: string[];
}

export const ConfirmDateInput: FC<Props> = ({ deliveryConfirmedDate }) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ConfirmInput>();
  const year = watch('deliveredDate.year');
  const month = watch('deliveredDate.month');
  const day = watch('deliveredDate.day');
  const [dayOptions, setDayOptions] = useState<string[]>([]);
  const yearOptions = getYears();
  const monthOptions = getMonthOption(new Date().getMonth() + 1);

  const error = errors?.deliveredDate?.year?.message;

  useEffect(() => {
    const options: string[] = [];
    const currentMonth = new Date().getMonth() + 1;
    const currentDay = new Date().getDate();
    const days = getDayInMonthOptions(Number(year), Number(month));
    if (Number(month) === currentMonth) {
      options.push(...days.slice(0, currentDay));
    } else {
      let index = days.findIndex((day) => Number(day) === currentDay);
      index = index === -1 ? days.length - 2 : index;
      options.push(...days.slice(index + 1, days.length));
    }
    if (!options.find((option) => option === day)) {
      setValue('deliveredDate.day', '');
    }
    setDayOptions(options);
  }, [year, month]);

  return (
    <div className={style['container']}>
      <div className={style['label-wrapper']}>
        <p className={style['label']}>お車が納車された日付けをご入力ください</p>
        <span className={style['label-tip']}>必須</span>
      </div>
      {deliveryConfirmedDate[0] && (
        <p className={style['description-1']}>
          お客様の納車予定日は
          {deliveryConfirmedDate[0]}年{deliveryConfirmedDate[1]}月
          {deliveryConfirmedDate[2]}日です
        </p>
      )}
      {/* お客様の納車予定日は2023年8月31日です */}
      <div id="mail-day-input" className="input-container">
        <div className="input-wrap">
          <RhfDropdown
            required
            defaultValue={''}
            className={`input year ${error && 'error-input'}`}
            name="deliveredDate.year"
          >
            <option value="" disabled hidden />
            {yearOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </RhfDropdown>
          年
          <RhfDropdown
            required
            defaultValue={''}
            className={`input month ${error && 'error-input'}`}
            name="deliveredDate.month"
          >
            <option value="" disabled hidden />
            {monthOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </RhfDropdown>
          月
          <RhfDropdown
            required
            defaultValue={''}
            className={`input day ${error && 'error-input'}`}
            name="deliveredDate.day"
            value={day}
          >
            <option value="" disabled hidden />
            {dayOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </RhfDropdown>
          日
        </div>
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};
