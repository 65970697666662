import { f7 } from 'framework7-react';
import { customerInfoApi } from '@/api/customerApi';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import { storeDispatch } from '@/utils/store';

export const usePrepaid = () => {
  const { openToast } = useToast();
  const fetchAdvancePaymentInfo = async () => {
    const mCustomerId = store.state.authInfo.m_customer_id;
    try {
      f7.preloader.show();
      const { data } = await customerInfoApi({ m_customer_id: mCustomerId });
      if (!data.success) {
        throw new Error();
      }
      const { prepaids } = data;
      storeDispatch('setAdvancePaymentsInfo', 'advancePaymentsInfo', prepaids);
    } catch (error) {
      openToast('基本情報の取得に失敗しました', 'toast-failed');
    } finally {
      f7.preloader.hide();
    }
  };

  return { fetchAdvancePaymentInfo };
};
