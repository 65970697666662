import {
  BillInformation,
  PaymentMethod,
  Prepaid,
} from '@/types/api/customerApi';

export const getPrepaidPaymentInfo = (
  prepaidsInfo: Prepaid,
  paymentMethods: PaymentMethod[],
) => {
  if (paymentMethods.length > 0) {
    return paymentMethods.find(
      (item) =>
        item.m_payment_method_id == prepaidsInfo?.payment_method_id &&
        item.m_payment_type_id == prepaidsInfo?.payment_type_id,
    );
  }
};

export type ChargePrepaidBillInfo = {
  bank_name: string | undefined;
  account_number: string | undefined;
  card_no: string | undefined;
  m_payment_type_id: number | undefined;
  m_payment_method_id: number | undefined;
  payment_type_name: string | undefined;
  id: number;
} | null;

export const getChargePrepaidBillInfo = (
  billsInfo: Required<BillInformation>[],
  prepaidsInfo: Required<Prepaid>,
): ChargePrepaidBillInfo => {
  let billInfo = null;
  if (billsInfo && billsInfo.length != 0) {
    const specificBillInfo = billsInfo.find(
      (x: BillInformation) => x.m_prepaid_id == prepaidsInfo?.id,
    );
    if (!specificBillInfo || !specificBillInfo.bill_infomation_details) {
      return {
        bank_name: '',
        account_number: '',
        card_no: '',
        m_payment_type_id: 0,
        m_payment_method_id: 0,
        payment_type_name: '',
        id: 0,
      };
    }

    let billInfomationDetail =
      specificBillInfo.bill_infomation_details.slice(-1)[0];
    billInfo = {
      bank_name: billInfomationDetail.bank_name,
      account_number: billInfomationDetail.account_number,
      card_no: billInfomationDetail.card_no,
      m_payment_type_id: billInfomationDetail.m_payment_type_id,
      m_payment_method_id: billInfomationDetail.m_payment_method_id,
      payment_type_name: billInfomationDetail.payment_type_name,
      id: specificBillInfo.m_prepaid_id,
    };
  }
  return billInfo;
};

export const makeDisplayPayment = (
  paymentMethod: PaymentMethod | undefined,
  bill: ChargePrepaidBillInfo,
): PaymentMethod | ChargePrepaidBillInfo | undefined => {
  let displayPayment: PaymentMethod | ChargePrepaidBillInfo | undefined =
    undefined;
  if (paymentMethod) {
    displayPayment = paymentMethod;
  }
  if (bill && bill.m_payment_method_id != 0 && bill.m_payment_type_id != 0) {
    displayPayment = bill;
  }
  return displayPayment;
};
