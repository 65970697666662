import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useMemo, useState } from 'react';
import { fetchDriveHistoryApi } from '@/api/driveRecordApi';
import { HistoryParams, HistoryResponse } from '@/types/api/driveRecordApi';

// react custom hook receives HistoryParams as parameter
// and returns FetchDriveHistoryResult
export const useHistory = (params: HistoryParams) => {
  const [responseData, setResponseData] = useState<HistoryResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(true);
  const [error, setError] = useState<AxiosError>();
  const [filterData, setFilterData] = useState(false);

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchDriveHistoryApi(params);
        if (params.diagnosis_date != '') {
          setFilterData(true);
        }
        if (res.data.success) {
          setResponseData(res.data);
          setIsNoData(false);
        }
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [params]);

  return useMemo(
    () => ({
      data: responseData,
      filterData,
      isLoading,
      isNoData,
      error,
    }),
    [responseData, filterData, isLoading, isNoData, error],
  );
};
