import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { AppSettings } from './AppSettings/AppSettings';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { url } from '@/consts/url';

interface AppSettingsPageProps {
  f7router: Router.Router;
}
export const AppSettingsPage: FC<AppSettingsPageProps> = ({ f7router }) => {
  const { gpsPermission, cameraPermission } = store.state.permission;
  const handleClickChange = () => {
    // 変更するボタンを押した時の処理
    window.open(url.NATIVE_BASE_URL + '/on_go_app_setting');
    f7.view.main.router.navigate(paths.home, {
      props: { fromPage: '' },
      reloadAll: true,
    });
  };
  return (
    <AppSettings
      f7router={f7router}
      handleClickChange={handleClickChange}
      permission={{ gpsPermission, cameraPermission }}
    />
  );
};
