import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { getValidWarranties } from '../WarrantyManagement/WarrantyManagementHelper';
import { MaintenanceGuide } from './MaintenanceGuide/MaintenanceGuide';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { isDelivered } from '@/utils/car';
import { openAlertWarrantyExpried } from '@/utils/car';
import { hyphenToDay } from '@/utils/date';

interface MaintenanceGuidePageProps {
  f7router: Router.Router;
}

export const MaintenanceGuidePage: FC<MaintenanceGuidePageProps> = ({
  f7router,
}) => {
  const [isOpenWarrantyRequired, setIsOpenWarrantyRequired] = useState(false);
  const carItemInfo = store.state.carItemInfo;
  const validWarranties = getValidWarranties(store.state.warranties);
  const nextInspectionDate = hyphenToDay(
    carItemInfo?.next_inspection_date,
    true,
  );

  const handleClickTrouble = () => {
    if (!carItemInfo?.warranties?.[0]?.warranty_no) {
      setIsOpenWarrantyRequired(true);
      return;
    }
    f7.view.main.router.navigate(paths.trouble);
  };

  const handleClickProblem = () => {
    if (isDelivered(carItemInfo.delivery_complete_flg)) {
      if (!carItemInfo?.warranties?.[0]?.warranty_no) {
        return setIsOpenWarrantyRequired(true);
      }
      if (!validWarranties?.length) {
        return openAlertWarrantyExpried();
      }
      return f7.view.main.router.navigate(paths.problem);
    }
    if (carItemInfo?.warranties?.[0]?.warranty_no) {
      return openAlertWarrantyExpried();
    }
    setIsOpenWarrantyRequired(true);
  };

  const handleClickContact = () => {
    f7.view.main.router.navigate(paths.contact);
  };

  return (
    <MaintenanceGuide
      handleClickProblem={handleClickProblem}
      handleClickTrouble={handleClickTrouble}
      handleClickContact={handleClickContact}
      setIsOpenWarrantyRequired={setIsOpenWarrantyRequired}
      nextInspectionDate={nextInspectionDate}
      isOpenWarrantyRequired={isOpenWarrantyRequired}
    />
  );
};
