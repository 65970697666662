import { IconProps } from './type';

export const AlertIcon = ({
  width = '16',
  height = '16',
  color = '#FF4655',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50117 14.6676C7.18261 14.6677 5.89362 14.2768 4.79723 13.5443C3.70083 12.8119 2.84627 11.7707 2.34161 10.5525C1.83694 9.33437 1.70485 7.99392 1.96202 6.70067C2.2192 5.40743 2.8541 4.2195 3.78643 3.2871C4.71875 2.3547 5.90664 1.71971 7.19986 1.46243C8.49308 1.20515 9.83355 1.33714 11.0518 1.84171C12.27 2.34628 13.3112 3.20076 14.0437 4.2971C14.7763 5.39344 15.1673 6.68239 15.1673 8.00096C15.1654 9.76839 14.4625 11.4629 13.2128 12.7127C11.963 13.9625 10.2686 14.6656 8.50117 14.6676ZM8.50117 2.37098C7.38777 2.37088 6.29934 2.70094 5.37353 3.31943C4.44771 3.93792 3.72609 4.81705 3.29991 5.84566C2.87374 6.87427 2.76215 8.00616 2.97926 9.09818C3.19638 10.1902 3.73244 11.1933 4.51966 11.9807C5.30688 12.7681 6.3099 13.3043 7.40189 13.5216C8.49388 13.7389 9.62578 13.6276 10.6545 13.2016C11.6832 12.7756 12.5624 12.0541 13.1811 11.1284C13.7997 10.2027 14.13 9.11436 14.1301 8.00096C14.1286 6.50844 13.5351 5.07747 12.4798 4.022C11.4246 2.96653 9.99369 2.37277 8.50117 2.37098Z"
        fill={color}
      />
      <path
        d="M9.02274 4.17624C8.97427 4.12081 8.91612 4.07671 8.85178 4.0466C8.78743 4.01648 8.71822 4.00096 8.64829 4.00098H8.35372C8.28391 4.00093 8.21481 4.01635 8.15054 4.04634C8.08627 4.07632 8.02816 4.12024 7.97968 4.17548C7.93119 4.23072 7.89333 4.29614 7.86836 4.36783C7.84338 4.43953 7.83181 4.51602 7.83432 4.59274L7.982 8.78523C7.9872 8.93278 8.04415 9.07239 8.14092 9.17476C8.23768 9.27713 8.36674 9.33431 8.50101 9.33431C8.63528 9.33431 8.76433 9.27713 8.8611 9.17476C8.95786 9.07239 9.01482 8.93278 9.02002 8.78523L9.16691 4.59274C9.16967 4.51592 9.15829 4.43927 9.13345 4.36741C9.10861 4.29554 9.07082 4.22993 9.02235 4.17452"
        fill={color}
      />
      <path
        d="M8.50065 11.3343C8.3688 11.3343 8.2399 11.2952 8.13027 11.222C8.02064 11.1487 7.93519 11.0446 7.88473 10.9228C7.83427 10.8009 7.82107 10.6669 7.84679 10.5376C7.87252 10.4083 7.93601 10.2895 8.02925 10.1962C8.12248 10.103 8.24127 10.0395 8.37059 10.0138C8.49991 9.98806 8.63396 10.0013 8.75577 10.0517C8.87759 10.1022 8.98171 10.1876 9.05496 10.2973C9.12822 10.4069 9.16732 10.5358 9.16732 10.6676C9.16712 10.8444 9.09682 11.0139 8.97184 11.1388C8.84686 11.2638 8.6774 11.3341 8.50065 11.3343Z"
        fill={color}
      />
    </svg>
  );
};
