import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { Dispatch, FC, SetStateAction } from 'react';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { PrepaidData } from '@/types/payment';
import { storeDispatch } from '@/utils/store';

type PrepaidGuideButtonProps = {
  f7router: Router.Router;
  setPrepaidData: Dispatch<SetStateAction<PrepaidData>>;
  prepaidName: string;
};

export const PrepaidGuideButton: FC<PrepaidGuideButtonProps> = ({
  f7router,
  setPrepaidData,
  prepaidName,
}) => {
  const themeId = store.state.themeId;
  const handleClickLink = (path: string) => {
    storeDispatch('setPaymentTempData', 'paymentTempData', {
      type: 'prepaid',
      openDialogName: `/reservings_start/`,
      isTransition: false,
    });
    f7.view.main.router.navigate(path, {
      animate: true,
      props: { setPrepaidData: setPrepaidData },
    });
  };

  return (
    <a className="prepaid-guide-button">
      <div className="text-box">
        <div className="text-main">
          <span>積み立て型電子マネー</span>
        </div>
        <div className="text-main">{prepaidName}</div>
        <ul className="text-list">
          <li className="text-item">毎月積み立てで急な出費も安心</li>
          <li className="text-item">車検・整備など使い道たくさん</li>
        </ul>
        {/* <div className={styles.circle}>
          <div className={styles['circle-list']}>
            <div className={`${styles[`circle-item1`]} u-font-bold`}>
              今なら
            </div>
            <div className={`${styles[`circle-item2`]} u-font-bold`}>
              1万円分
            </div>
            <div className={`${styles[`circle-item3`]} u-font-bold`}>
              プレゼント
            </div>
            <div className={styles['circle-item4']}>※初回登録時</div>
          </div>
        </div> */}
      </div>
      <div
        className="more"
        onClick={() => handleClickLink(paths.prepaidSummary)}
      >
        登録・詳細はこちら
        <span>
          <i className="icon icon-right" />
        </span>
      </div>
    </a>
  );
};
