import { Card, CardContent } from 'framework7-react';
import { ReactNode } from 'react';
import AccordionItem from './AccordionItem/AccordionItem';

import './Accordion.css';

type AccordionProps<T> = {
  items: T[];
  renderTitle: (item: T) => string;
  renderContent: (item: T, index?: number) => ReactNode;
};

const Accordion = <T,>({
  items,
  renderTitle,
  renderContent,
}: AccordionProps<T>) => (
  <div className="card-list">
    {items.map((item, index) => (
      // itemsに何が入るか決まっておらず、一意な値を特定できないため、やむを得ずkeyにindexを指定する
      <Card key={index} className="part-detail">
        <CardContent>
          <div className="list accordion-list">
            <ul>
              <AccordionItem title={renderTitle(item)}>
                {renderContent(item, index)}
              </AccordionItem>
            </ul>
          </div>
        </CardContent>
      </Card>
    ))}
  </div>
);

export default Accordion;
