import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchMAffiliateSettingsApi } from '@/api/infoApi';
import {
  MAffiliateSettingsApiParams,
  MAffiliateSettingsApiResponse,
} from '@/types/api/infoApi';

export const useMAffiliateSettings = ({
  m_affiliate_id,
}: MAffiliateSettingsApiParams) => {
  const [data, setData] = useState<MAffiliateSettingsApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(true);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchMAffiliateSettingsApi({
          m_affiliate_id,
        });
        if (res.data.success) {
          setIsNoData(false);
          setData(res.data);
        }
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [m_affiliate_id]);

  return {
    data,
    isLoading,
    isNoData,
    error,
  };
};
