import { DetailedHTMLProps, FC, ReactNode, SelectHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

interface RhfDropdownProps
  extends DetailedHTMLProps<
    SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  children: ReactNode;
  name: string;
}
export const RhfDropdown: FC<RhfDropdownProps> = ({
  children,
  name,
  ...rest
}) => {
  const { register } = useFormContext();
  return (
    <select {...register(name)} {...rest}>
      {children}
    </select>
  );
};
