import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { FC } from 'react';
import arrivalPointMark from '@/assets/images/menu/map_start_mark.svg';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { apiKey } from '@/consts/apiKey';
import { useGoogleGeocode } from '@/hooks/api/prepaid/useGetGoogleGeocode';
import './shopmapStyle.scss';

type ShopmapPageProps = {
  pageTitle?: string;
  parkingAddr?: string;
  lat?: number;
  lon?: number;
  isIcon?: boolean;
};

export const Shopmap: FC<ShopmapPageProps> = ({
  pageTitle,
  lat,
  lon,
  parkingAddr,
  isIcon,
}) => {
  const googleMapsApiKey = apiKey.GOOGLE_MAPS_API_KEY;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
  });

  const mapStyle = {
    width: '100%',
    height: '60rem',
  };

  const address = store.state.customerInfo.unit_address;
  const params = {
    address: parkingAddr ?? address,
    key: googleMapsApiKey,
  };
  const { data: longitudeAndLatitude } = useGoogleGeocode(params, {
    lat,
    lng: lon,
  });

  return (
    <PopupPageTemplate title={`${pageTitle ?? '店舗位置'}`} pageName="shopmap">
      <div className="pageTop">
        {isLoaded && (
          <div className="map-card">
            <div className="map-box">
              <GoogleMap
                mapContainerStyle={mapStyle}
                center={longitudeAndLatitude}
                zoom={12}
                options={{ disableDefaultUI: true }}
              >
                <Marker
                  position={longitudeAndLatitude}
                  icon={isIcon ? arrivalPointMark : undefined}
                />
              </GoogleMap>
            </div>
          </div>
        )}
      </div>
    </PopupPageTemplate>
  );
};
