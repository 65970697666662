import { Col, Row } from 'framework7-react';
import { InputProps } from 'framework7-react/components/input';
import { FC } from 'react';
import { Label } from '../Label/Label';
import { RhfInput } from '../ReactHookForm/RhfInput/RhfInput';

type Input = {
  name: string;
  placeholder: string;
};
interface ParallelInputProps extends InputProps {
  label: string;
  required: boolean;
  annotation?: string;
  error?: string;
  firstInput: Input;
  secondInput: Input;
}

export const ParallelContactInput: FC<ParallelInputProps> = ({
  label = null,
  required = false,
  annotation = null,
  error = null,
  firstInput,
  secondInput,
}) => {
  return (
    <>
      <li className="pararell-input">
        <div
          className={`item-content item-input item-input-outline ${
            error && 'item-input-invalid item-input-with-error-message'
          }`}
        >
          <div className={`item-inner ${(annotation || error) && 'with-info'}`}>
            <div className="item-title item-label">
              {label}
              <Label required={required} />
            </div>
            <Row>
              <Col>
                <div className="item-input-wrap">
                  <RhfInput
                    type="text"
                    placeholder={firstInput.placeholder}
                    name={firstInput.name}
                  />
                </div>
              </Col>

              <Col>
                <div className="item-input-wrap">
                  <RhfInput
                    type="text"
                    placeholder={secondInput.placeholder}
                    name={secondInput.name}
                  />
                </div>
              </Col>
            </Row>

            {error && <div className="item-input-error-message">{error}</div>}

            {annotation && <div className="item-input-info">{annotation}</div>}
          </div>
        </div>
      </li>
    </>
  );
};
