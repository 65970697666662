import { useStore } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import style from './CarWashConditionerStep4Style.module.css';
import { PaymentDialog } from './PaymentDialog';
import { ReactComponent as ExclamationmarkCircle } from '@/assets/images/icon-exclamationmark_circle.svg';
import { Button } from '@/components/uiParts/Button';
import { store } from '@/config/store';
import { Step1FormData } from '@/hooks/api/carWash/useCarWashConditionerStep1';
import { Step2FormData } from '@/hooks/api/carWash/useCarWashConditionerStep2';
import { Step3FormData } from '@/hooks/api/carWash/useCarWashConditionerStep3';
import { useCarWashConditionerStep4 } from '@/hooks/api/carWash/useCarWashConditionerStep4';
import { Prepaid } from '@/types/api/customerApi';
import {
  CourseItem,
  CreateCarWashParams,
  OptionItem,
} from '@/types/api/reservationApi';

interface Props {
  initialStatus: boolean;
  step1Data: Step1FormData;
  step2Data: Step2FormData;
  step3Data: Step3FormData;
  courseItem: CourseItem | undefined;
  optionItem: OptionItem[];
  submitSuccess: boolean;
  onSubmit: (data?: CreateCarWashParams) => void;
  setInitialStatus: (status: boolean) => void;
  onClickCancel: () => void;
}

export const CarWashConditionerStep4: FC<Props> = ({
  initialStatus,
  courseItem,
  optionItem,
  step1Data,
  step2Data,
  step3Data,
  onSubmit,
  submitSuccess,
  setInitialStatus,
  onClickCancel,
}) => {
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Required<Prepaid>
    | undefined;
  const [paymentOpen, setPaymentOpen] = useState(false);
  const { data, display } = useCarWashConditionerStep4(
    step1Data,
    step2Data,
    step3Data,
    courseItem,
    optionItem,
  );

  const handleClickPhone = () => {
    window.location.href = `tel://${display.consultPhone}`;
  };

  const handleSubmit = () => {
    setPaymentOpen(true);
  };

  const submitPayment = () => {
    onSubmit(data);
    !submitSuccess && setPaymentOpen(false);
  };

  useEffect(() => {
    if (initialStatus && courseItem) {
      setPaymentOpen(true);
      setInitialStatus(false);
    }
  }, [initialStatus, courseItem, setInitialStatus]);

  return (
    <div className={style['container']}>
      <h3 className={style['heading']}>予約内容の確認</h3>
      {paymentOpen && (
        <PaymentDialog
          amount={display.amount || '0'}
          fee={display.fee || 0}
          prepaid={prepaid}
          onOk={submitPayment}
          onCancel={() => setPaymentOpen(false)}
        />
      )}
      <p className={style['label-text']}>予約内容をご確認ください。</p>
      <div className={`${style['list']} ${style['no-last-border']}`}>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>サービス内容</div>
          <div className={style['list-item-value']}>
            {display.serviceContents}
          </div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>ご利用金額</div>
          <div className={style['list-item-value']}>￥{display.amount}</div>
        </div>
      </div>
      <div className={style['lsit']}>
        <p className={style['list-title']}>ご予約希望日時</p>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>第一希望</div>
          <div className={style['list-item-value']}>{display.datetime1}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>第二希望</div>
          <div className={style['list-item-value']}>{display.datetime2}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>第三希望</div>
          <div className={style['list-item-value']}>{display.datetime3}</div>
        </div>
      </div>
      <div className={style['lsit']}>
        <p className={style['list-title']}>お客様の情報</p>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>お名前</div>
          <div className={style['list-item-value']}>{display.name}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>フリガナ</div>
          <div className={style['list-item-value']}>{display.nameKana}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>お電話番号</div>
          <div className={style['list-item-value']}>{display.phoneNo}</div>
        </div>
      </div>
      <div className={style['lsit']}>
        <p className={style['list-title']}>お車の情報</p>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>車両ナンバー</div>
          <div className={style['list-item-value']}>{display.carLabel}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>メーカー</div>
          <div className={style['list-item-value']}>{display.makerName}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>車種</div>
          <div className={style['list-item-value']}>{display.carName}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>車の色</div>
          <div className={style['list-item-value']}>{display.carColor}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>車台番号</div>
          <div className={style['list-item-value']}>{display.carVin}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>型式</div>
          <div className={style['list-item-value']}>{display.modelCode}</div>
        </div>
      </div>
      <div className={style['lsit']}>
        <p className={style['list-title']}>洗車場所の情報</p>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>ご住所</div>
          <div className={style['list-item-value']}>{display.address}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>目印・駐車位置など</div>
          <div className={style['list-item-value']}>
            {display.workspaceMemo}
          </div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>洗車場所について</div>
          <div className={style['list-item-value']}>
            {display.workspaceType}
          </div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>駐車場タイプ</div>
          <div className={style['list-item-value']}>{display.parkingType}</div>
        </div>
      </div>
      <div className={style['lsit']}>
        <p className={style['list-title']}>作業環境について</p>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>
            作業環境についてお車の前後左右に50~60cm程度の間隔はございますか？
          </div>
          <div className={style['list-item-value']}>{display.marginType}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>
            後付けのセキュリティシステムが付いていますか？
          </div>
          <div className={style['list-item-value']}>{display.securityType}</div>
        </div>
        <div className={style['list-item']}>
          <div className={style['list-item-label']}>
            作業環境についての確認事項
          </div>
          <div className={style['list-item-value']}>確認しました</div>
        </div>
        {/* <div className={style['list-item']}>
          <div className={style['list-item-label']}>
            ご要望・
            <br />
            ご相談
          </div>
          <div className={style['list-item-value']}>なし</div>
        </div> */}
      </div>
      <div className={style['warning-box']}>
        <div className={style['warning-box-title']}>
          <ExclamationmarkCircle />
          <span>ご注意事項</span>
        </div>
        <ul className={style['warning-box-list']}>
          <li>車両の装備品によって施工が出来ない場合がございます。</li>
          <li>
            当日の作業や道路状況によっては到着時間が前後する可能性がございます。
          </li>
          <li>
            予約のキャンセルはキャンセル料が発生しますのでご注意ください。(前日：
            {courseItem?.cancellation_charge.charge_percent_eve || 0}%、当日：
            {courseItem?.cancellation_charge.charge_percent || 0}％)
          </li>
          <li>
            <span className={style['text-alert']}>
              予約の変更・キャンセルの受付期間は予約日より３日前までです。それ以降はお電話にてお問い合せください。
            </span>
          </li>
          <li>
            電話でのお問い合わせは、
            <span className={style['text-link']} onClick={handleClickPhone}>
              {display.consultPhone}
            </span>
            までお問い合わせください。
          </li>
        </ul>
      </div>
      <div className={style['button-group']}>
        <Button text="予約を確定する" onClick={handleSubmit} />
        <Button
          style={{
            color: '#232323',
            backgroundColor: '#fff',
            marginTop: '8px',
          }}
          text="キャンセル"
          onClick={onClickCancel}
        />
      </div>
    </div>
  );
};
