import { Block, Link, Navbar, NavLeft, NavTitle, Page } from 'framework7-react';
import React, { FC } from 'react';
import './DrivingReportListStyle.scss';

export const DrivingReportList: FC = () => {
  return (
    <Page name="DrivingReportList">
      <Navbar>
        <NavLeft>
          <Link back animate>
            <i className="icon-left" />
          </Link>
        </NavLeft>
        <NavTitle>マンスリーレポート</NavTitle>
      </Navbar>
      <Block>
        <div>
          <h2 className="sub-title">安全運転スコア（一カ月ごと）</h2>
          <p>月ごとの運転診断レポートを確認できます。</p>

          <div className="info-card info-card-elevated u-margin-top-md u-text-center u-padding-bottom-sm u-padding-top-sm u-margin-bottom-lg">
            選択中のドライブレコーダー： driveRecoder
          </div>

          <div className="info-card info-card-elevated u-padding-top-sx u-padding-bottom-sx">
            <div className="card-container">
              <div className="score-box">
                <div className="score">0点</div>
              </div>
              <div className="item-content u-font-size-xl">
                <div>item.report_date</div>
                <div>走行回数: item.trip_count 回</div>
                <div>走行距離: item.total_driving_distance km</div>
              </div>
              <div className="link-icon">
                <i className="icon-right u-font-size-xxl" />
              </div>
            </div>
          </div>
        </div>
      </Block>
    </Page>
  );
};
