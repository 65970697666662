import styles from './SubItemList.module.css';

interface SubItemListProps<T> {
  subItems: T[];
  key?: number | string;
  renderSubItem: (item: T) => string;
}

const SubItemList = <T,>({
  subItems,
  key,
  renderSubItem,
}: SubItemListProps<T>) => (
  <ul>
    {subItems.map((subItem, subItemIndex) => (
      <li className={styles['list-items']} key={`${key}-${subItemIndex}`}>
        <p className={styles['part-value']}>{renderSubItem(subItem)}</p>
      </li>
    ))}
  </ul>
);

export default SubItemList;
