import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { getBankCodeApi } from '@/api/bankCodeApi';
import { GetBankCodeApiResponse } from '@/types/api/bankCodeApi';

export const useGetBanks = (opened: boolean) => {
  const [banks, setBanks] = useState<GetBankCodeApiResponse['data']['data']>();
  const [error, setError] = useState<AxiosError>();

  const getBanksData = async () => {
    try {
      const res = await getBankCodeApi();
      setBanks(res.data.data);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
    }
  };

  useEffect(() => {
    opened && getBanksData();
  }, [opened]);

  return { banks, error };
};
