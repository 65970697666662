import { differenceInHours } from 'date-fns';
import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './impactInfoStyle.module.css';
import { ReactComponent as CautionIcon } from '@/assets/images/menu/icon-caution.svg';
import { Button } from '@/components/uiParts/Button';
import { RoundButtonWithIcon } from '@/components/uiParts/Button/RoundButtonWithIcon/RoundButtonWithIcon';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { isApp } from '@/config/device';
import { store } from '@/config/store';
import { accidentImpact, operationCd } from '@/consts/accident';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';
import { useCancelImpactNotification } from '@/hooks/api/other/useCancelImpactNotification';
import { FetchImpactInfoApiResponse } from '@/types/api/infoApi';
import { isAdContractorData, isAdTempContractorData } from '@/utils/home';
import { f7CustomBack } from '@/utils/utils';

interface ImpactInfoPageProps {
  impactData?: FetchImpactInfoApiResponse['data']['data'];
  customerId: number;
}

export const ImpactInfo: FC<ImpactInfoPageProps> = ({
  impactData,
  customerId,
}) => {
  const mAffiliateId = store.state.customerInfo.m_affiliate_id;
  const impactInfo = impactData?.impact_infos[0];
  const isHugeImpact = impactInfo?.accident_impact === accidentImpact.HUGE;
  const params = {
    short_user_id: customerId,
    impact_detection_id: impactInfo?.accident_id ?? 0,
    operation_cd: 0,
  };
  const { cancelImpact } = useCancelImpactNotification();
  const { data } = useMAffiliateSettings({ m_affiliate_id: mAffiliateId });
  const storeLinkDriveStatus = store.state.carItemInfo.linkdrive_status;

  const isAdContractor = isAdContractorData({
    linkDriveStatus: storeLinkDriveStatus,
  });

  const isTempAdContractor = isAdTempContractorData({
    linkDriveStatus: storeLinkDriveStatus,
  });

  // AD契約者（仮契約以外）の場合は、あいおいサポートセンター電話番号を使用する
  // AD契約者（仮契約）、LD単体利用者の場合は、加盟店のサポートセンタ電話番号を使用する
  const supportNumber =
    isAdContractor && !isTempAdContractor
      ? impactData?.callcenter
      : data?.data.callcenter_tel;

  const isOneHourElapsedAfterAccident = () => {
    const currentMs = new Date();
    const accidentTimeMs =
      impactInfo?.accident_time && new Date(impactInfo.accident_time);
    try {
      if (!(accidentTimeMs instanceof Date)) {
        throw new Error(`accidentTimeMs: ${accidentTimeMs}`);
      }
      if (differenceInHours(accidentTimeMs, currentMs) > 1) {
        f7.dialog.alert('', '一定の時間の操作がありませんでした。', () => {
          f7CustomBack(['#ImpactInfo']);
        });
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  const handleClickContact = async (tel: string) => {
    if (!isOneHourElapsedAfterAccident()) {
      await cancelImpact({ ...params, operation_cd: operationCd.TEL });
    }
    if (isApp) {
      window.location.href = tel;
    }
  };

  const handleClickNotAccident = async () => {
    await cancelImpact({ ...params, operation_cd: operationCd.CANCEL });
    f7CustomBack(['#ImpactInfo']);
  };

  return (
    <PopupPageTemplate id="ImpactInfo" pageName="ImpactInfo" title="">
      <div className={style['container']}>
        <div
          className={`${style['main-wrapper']} ${
            isHugeImpact && style['flex-grow']
          }`}
        >
          <p className={style['title']}>車両に衝撃を検知しました</p>
          <CautionIcon />
          <p className={style['decs']}>
            {`事故の場合はあわてず、落ち着いてください。${
              isHugeImpact ? 'このあと安否確認の連絡を差し上げます。' : ''
            }`}
          </p>
        </div>
        {!isHugeImpact && (
          <div className={style['mid-wrapper']}>
            {isAdContractor && !isTempAdContractor ? (
              <p>
                あいおいニッセイ同和損保
                <span>安心サポートセンターに連絡する</span>
              </p>
            ) : (
              <p>サポートセンターに連絡する</p>
            )}
            <div className={style['button-wrapper']}>
              <Button
                height="56px"
                handleClickAction={() =>
                  handleClickContact(`tel://${supportNumber}`)
                }
              >
                サポートセンター
              </Button>
            </div>
            <div className={style['mid-buttons-wrapper']}>
              <div className={style['button-wrapper']}>
                <RoundButtonWithIcon
                  iconClass="icon-ambulance"
                  colorTheme="pink"
                  height="56px"
                  handleClickAction={() => handleClickContact('tel://119')}
                >
                  救急 119番
                </RoundButtonWithIcon>
              </div>
              <div className={style['button-wrapper']}>
                <RoundButtonWithIcon
                  iconClass="icon-police"
                  colorTheme="black"
                  height="56px"
                  handleClickAction={() => handleClickContact('tel://100')}
                >
                  警察 100番
                </RoundButtonWithIcon>
              </div>
            </div>
          </div>
        )}
        <div className={style['bottom-wrapper']}>
          <p>
            事故でない場合は<span>下のボタンを押してください</span>
          </p>
          <Button height="56px" handleClickAction={handleClickNotAccident}>
            事故ではありません
          </Button>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
