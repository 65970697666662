import { FC } from 'react';
import { LinkDriveTerm } from './LinkDriveTerm/LinkDriveTerm';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const LinkDriveTermPage: FC = () => {
  const { data } = useTerm(termType.LINK_DRIVE);
  const html = data?.term;
  return (
    <PageTemplate
      pageName="LinkDriveTerm"
      title="Link Drive 利用規約"
      showNavBack
    >
      <LinkDriveTerm html={html} />
    </PageTemplate>
  );
};
