// 通知カテゴリ
export const notificationCategory = {
  TEMPORARY_REGISTRATION: 1, // 仮契約
  ASSURANCE: 2, // 保証関連
  PREPAID: 3, // プリペイド関連
  MAINTENANCE: 4, // メンテナンス関連
  INSURANCE: 5, // 保険関連
  FORMAL_REGISTRATION: 6, // 本契約
};

// 通知対象
export const notificationType = {
  SMALL: 1, // e-call衝撃度合い）小
  MIDDLE: 2, // e-call衝撃度合い）中
  BIG: 3, // e-call衝撃度合い）大
};

// 通知設定
export const targetFlg = {
  FALSE: 0, // 通知しない
  TRUE: 1, // 通知する
};
