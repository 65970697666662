import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './SelectYearsStyle.module.css';

interface SelectYearsProps {
  label?: string;
  firstYear?: number;
  showTerm?: number;
  defaultYear: number;
}
export const SelectYears: FC<SelectYearsProps> = ({
  firstYear = 2022,
  showTerm = 10,
  defaultYear,
}) => {
  const yearRepeat = (): number[] => {
    const yearsRepeat = Array.from(
      { length: showTerm },
      (v, i) => firstYear + i,
    );
    return yearsRepeat;
  };
  const { register } = useFormContext();
  return (
    <div className="input-dropdown">
      <select
        defaultValue={defaultYear}
        className={style.select}
        {...register('year')}
      >
        {yearRepeat().map((year) => (
          <option key={year} value={year}>{`${year}年`}</option>
        ))}
      </select>
    </div>
  );
};
