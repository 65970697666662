import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchCarRepairInspection } from '@/api/carRepairApi';
import { caller } from '@/consts/reservation';
import { CarRepairInspectionParams } from '@/types/api/carRepairApi';
import { RepairInfo } from '@/types/api/reservationApi';

export const useFetchCarRepairInspection = ({
  m_customer_id,
  warranty_no,
  branch_no,
  t_repair_id,
}: CarRepairInspectionParams) => {
  const [data, setData] = useState<RepairInfo[]>();
  const [firstItem, setFirstItem] = useState<RepairInfo>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        const res = await fetchCarRepairInspection({
          m_customer_id,
          warranty_no,
          branch_no,
          t_repair_id,
          call_kbn: caller.MAIKURU,
        });

        if (res.data.repair_info[0].status) {
          res.data.repair_info[0].status =
            res.data.repair_info[0].status.trim();
        }

        setData(res.data.repair_info);
        setFirstItem(res.data.repair_info[0]);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };
    fn();
  }, []);

  return {
    data,
    error,
    firstItem,
  };
};
