import { FC, useEffect, useMemo, useState } from 'react';
import { HasNoCar } from '../HasNoCar/HasNoCar';
import styles from './CarStyle.module.css';
import { UploadCarPhoto } from './UploadCarPhoto/UploadCarPhoto';
import carDefault from '@/assets/images/carImg.svg';
import imageDefault from '@/assets/images/iconDefaultPhoto.png';
import { Button } from '@/components/uiParts/Button';
import { List } from '@/components/uiParts/List/List';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { Car as CarType } from '@/types/api/customerApi';
import { isDelivered } from '@/utils/car';
import { hyphenToDayNoPadding } from '@/utils/date';
import { getCarPhoto } from '@/utils/utils';

interface LicensePlateType {
  area: string;
  classNumber: string;
  kana: string;
  consecutiveNumber: string;
  transportBu: number;
}

interface CarProps {
  id: string;
  hasCar: boolean;
  carInfo: CarType;
  handleChangeLicenseInfo: () => void;
  handleChangeInspectionInfo: () => void;
  licensePlateInfo: LicensePlateType;
  isChangeCarImage: boolean;
}

export const Car: FC<CarProps> = ({
  id,
  hasCar,
  carInfo,
  handleChangeLicenseInfo,
  handleChangeInspectionInfo,
  licensePlateInfo,
  isChangeCarImage = false,
}) => {
  const [isOpenUploadCarPhoto, setIsOpenUploadCarPhoto] = useState(false);

  const carInfomationItems = [
    { label: 'メーカー', value: carInfo.maker_nm },
    { label: '車種名', value: carInfo.car_nm },
    {
      label: '初年度登録',
      value: hyphenToDayNoPadding(carInfo.registration_date, true),
    },
    { label: 'グレード', value: carInfo.grade },
    { label: '車台番号', value: carInfo.vin },
    { label: '排気量', value: `${carInfo.haiki} cc` },
  ];

  const licensePlateItems = [
    { label: '地域名', value: licensePlateInfo.area },
    { label: '分類番号', value: licensePlateInfo.classNumber },
    { label: '平仮名等', value: licensePlateInfo.kana },
    { label: '車両番号', value: licensePlateInfo.consecutiveNumber },
  ];

  const openUploadCarPhoto = () => setIsOpenUploadCarPhoto(true);
  const closeUploadCarPhoto = () => setIsOpenUploadCarPhoto(false);

  const currentCarImageUrl = useMemo(
    () => getCarPhoto(carInfo, false),
    [carInfo],
  );
  useEffect(() => {
    if (isChangeCarImage) {
      openUploadCarPhoto();
    }
  }, [isChangeCarImage]);
  return (
    <PageTemplate pageName="CarInfo" title="車両情報" showNavBack>
      <div className={styles['container']}>
        {hasCar ? (
          isDelivered(carInfo.delivery_complete_flg) ? (
            // 納車後
            <>
              <div className={styles['car-photo-box']}>
                <div
                  onClick={openUploadCarPhoto}
                  className={`${styles['car-photo']} ${
                    currentCarImageUrl ? '' : styles['no-image']
                  }`}
                >
                  {
                    <>
                      <img
                        src={currentCarImageUrl || imageDefault}
                        onError={({ currentTarget }) =>
                          (currentTarget.src = carDefault)
                        }
                      />
                      {currentCarImageUrl && (
                        <>
                          <div className={styles['backdrop-image']} />
                          <div className={styles['backdrop-image-icon']}>
                            <img src={imageDefault} />
                          </div>
                        </>
                      )}
                    </>
                  }
                </div>
                <div
                  onClick={openUploadCarPhoto}
                  className={styles['change-photo-label']}
                >
                  画像を変更
                </div>
              </div>
              <UploadCarPhoto
                carInfo={carInfo}
                isOpenUploadCarPhoto={isOpenUploadCarPhoto}
                onPopupClosed={closeUploadCarPhoto}
              />
              <RoundSheet px="8px 16px" py="24px 16px">
                <div className={styles.card}>
                  <div className={styles['card-head']}>
                    <h3>車両情報</h3>
                    <Button
                      href={`/car_detail/${id}/`}
                      text="もっと見る"
                      buttonType="small"
                      className={`${styles['card-header-button']}`}
                    />
                  </div>
                  <div className={styles['card-body']}>
                    <List
                      labelWidth="120px"
                      list={carInfomationItems}
                      listClass={styles['list-class']}
                    />
                  </div>
                </div>
              </RoundSheet>
              <RoundSheet px="8px 16px" py="24px 16px">
                <div className={styles.card}>
                  <div className={styles['card-head']}>
                    <h3>ナンバープレート</h3>
                    <Button
                      text="変更する"
                      buttonType="small"
                      handleClickAction={handleChangeLicenseInfo}
                      className={`${styles['card-header-button']}`}
                    />
                  </div>
                  <div className={styles['card-body']}>
                    <List
                      labelWidth="120px"
                      list={licensePlateItems}
                      listClass={styles['list-class']}
                    />
                  </div>
                </div>
              </RoundSheet>
              <RoundSheet px="8px 16px" py="24px 16px">
                <div className={styles.card}>
                  <div className={styles['card-head']}>
                    <h3>車検情報</h3>
                    <Button
                      text="変更する"
                      buttonType="small"
                      handleClickAction={handleChangeInspectionInfo}
                      className={`${styles['card-header-button']}`}
                    />
                  </div>
                  <div className={styles['card-body']}>
                    <List
                      labelWidth="120px"
                      list={[
                        {
                          label: '次回車検',
                          value: hyphenToDayNoPadding(
                            carInfo.next_inspection_date,
                            true,
                          ),
                        },
                      ]}
                      listClass={styles['list-class']}
                    />
                  </div>
                </div>
              </RoundSheet>
              {/* <RoundSheet px="8px 16px" py="24px 16px">
                <div className={styles.card}>
                  <div className={styles['card-head']}>
                    <h3>詳細なスペック</h3>
                  </div>
                  <div className={styles['card-body']}>
                    <div
                      className={`${styles['list-class']} ${styles['list-class-document']}`}
                    >
                      <i
                        className={`icon-document ${styles['icon-document']}`}
                      />
                      <span>ブラウザアプリでPDFを開く</span>
                      <i className={`icon-right ${styles['icon-right']}`} />
                    </div>
                  </div>
                </div>
              </RoundSheet>
              <RoundSheet px="8px 16px" py="24px 16px">
                <div className={styles.card}>
                  <div className={styles['card-head']}>
                    <h3>説明書</h3>
                  </div>
                  <div className={styles['card-body']}>
                    <div
                      className={`${styles['list-class']} ${styles['list-class-document']}`}
                    >
                      <i
                        className={`icon-document ${styles['icon-document']}`}
                      />
                      <span>ブラウザアプリでPDFを開く</span>
                      <i className={`icon-right ${styles['icon-right']}`} />
                    </div>
                  </div>
                </div>
              </RoundSheet> */}
            </>
          ) : (
            // 納車前
            <RoundSheet>
              <div className={styles['no-car-title']}>
                <h3>車両情報</h3>
                <p>現在契約中の車両はありません</p>
              </div>
            </RoundSheet>
          )
        ) : (
          <HasNoCar />
        )}
      </div>
    </PageTemplate>
  );
};
