import { baseApi } from '@/config/axios';
import {
  ChangeVehicleApiParams,
  ChangeVehicleApiResponse,
  ConnectorDetailApiParams,
  ConnectorDetailApiResponse,
  ConnectorJobApiParams,
  ConnectorJobApiResponse,
  ConnectorStatusApiParams,
  ConnectorStatusApiResponse,
  ContractInformationParams,
  ContractInformationResponse,
  DeleteWatcherApiParams,
  DeleteWatcherApiResponse,
  FetchWatcherAllApiParams,
  FetchWatcherAllApiResponse,
  ImpactHistoriesApiParams,
  ImpactHistoriesApiResponse,
  JobExecStatusApiParams,
  JobExecStatusApiResponse,
  JobStatusApiResponse,
  JobStatusParams,
  LinkDriveSetupStatusApiParams,
  LinkDriveSetupStatusApiResponse,
  LinkDriveStockApiParams,
  LinkDriveStockApiResponse,
  OwnerCollaborationApiParams,
  OwnerCollaborationApiResponse,
  RegisterWatcherApiParams,
  RegisterWatcherApiResponse,
  ReplaceConnectorApiParams,
  ReplaceConnectorApiResponse,
  ScanResultApiParams,
  ScanResultApiResponse,
  ScoreLinkedTransmissionConsentApiResponse,
  ScoreLinkedTransmissionConsentParams,
  ServiceStartApiParams,
  ServiceStartApiResponse,
  ServiceStopApiParams,
  ServiceStopApiResponse,
  StartConnectorUseApiParams,
  StartConnectorUseApiResponse,
  UpdateWatcherApiParams,
  UpdateWatcherApiResponse,
  VehicleChangeStatusApiParams,
  VehicleChangeStatusApiResponse,
  VehicleTypeParams,
  VehicleTypeResponse,
} from '@/types/api/linkDriveApi';
const API_V1 = 'v1/';
const REST = 'rest/';

// 1.シリアルナンバー登録
export const api = () => {};
// 2.LINKDriveアラート取得

// 3.LINKDriveスキャン結果取得
export const fetchScanResultApi = (
  params: ScanResultApiParams,
): Promise<ScanResultApiResponse> =>
  baseApi.get(`${API_V1}linkdrive/scan_result/`, { params });

// 4.LINKDriveアラート取得
// 5.LINKDriveアラート取得
// 6.車両変更有無確認
export const vehicleChangeStatus = (
  params: VehicleChangeStatusApiParams,
): Promise<VehicleChangeStatusApiResponse> =>
  baseApi.get(`${REST}linkdrive/check_change_vehicle`, { params });

// 7.LINKDriveオーナー連携
export const collaborateOwnerApi = (
  params: OwnerCollaborationApiParams,
): Promise<OwnerCollaborationApiResponse> =>
  baseApi.post(`${REST}linkdrive/sim_regist_user`, { ...params });

// 8.カーオーナー-コネクタ利用開始
export const startConnectorUseApi = (
  params: StartConnectorUseApiParams,
): Promise<StartConnectorUseApiResponse> =>
  baseApi.post(`${REST}linkdrive/sim_activation`, { ...params });

// 9.コネクタJOB依頼
export const connectorJobApi = (
  params: ConnectorJobApiParams,
): Promise<ConnectorJobApiResponse> =>
  baseApi.post(`${REST}linkdrive/connector/job`, { ...params });

// 10.AD利用開始登録
export const updateScoreLinkedTransmissionConsentApi = (
  params: ScoreLinkedTransmissionConsentParams,
): Promise<ScoreLinkedTransmissionConsentApiResponse> =>
  baseApi.post(`${REST}linkdrive/use_regist`, { ...params });

// 11.車両変更
export const changeVehicleApi = (
  params: ChangeVehicleApiParams,
): Promise<ChangeVehicleApiResponse> =>
  baseApi.post(`${REST}linkdrive/change_vehicle`, { ...params });

// 12.走行データ送信停止
export const serviceStop = (
  params: ServiceStopApiParams,
): Promise<ServiceStopApiResponse> =>
  baseApi.post(`${REST}linkdrive/service_stop`, { ...params });

// 13.走行データ送信再開
export const serviceStart = (
  params: ServiceStartApiParams,
): Promise<ServiceStartApiResponse> =>
  baseApi.post(`${REST}linkdrive/service_restart`, { ...params });

// 14.コネクタ付替
export const replaceConnectorApi = (
  params: ReplaceConnectorApiParams,
): Promise<ReplaceConnectorApiResponse> =>
  baseApi.post(`${REST}linkdrive/connector_replace`, { ...params });

// 15.用途車種取得

// 契約情報連携データ
export const fetchContractInformationApi = (
  params: ContractInformationParams,
): Promise<ContractInformationResponse> =>
  baseApi.get(`${API_V1}linkdrive/contract_information`, { params });

// 車種判別
export const fetchVehicleTypeApi = (
  params: VehicleTypeParams,
): Promise<VehicleTypeResponse> =>
  baseApi.get(`${REST}linkdrive/vehicle_type`, { params });

// コネクタ情報取得
export const connectorStatus = (
  params: ConnectorStatusApiParams,
  signal?: AbortSignal,
): Promise<ConnectorStatusApiResponse> =>
  baseApi.get(`${API_V1}linkdrive/connector_status`, { params, signal });

// LinkDriveセットアップステータス
export const linkDriveSetupStatus = (
  params: LinkDriveSetupStatusApiParams,
  signal: AbortSignal,
): Promise<LinkDriveSetupStatusApiResponse> =>
  baseApi.get(`${API_V1}linkdrive/init_status`, { params, signal });

// 見守り者取得
export const fetchWatcherAllApi = (
  params: FetchWatcherAllApiParams,
): Promise<FetchWatcherAllApiResponse> =>
  baseApi.get(`${REST}linkdrive/watcher`, { params });

// 見守り者登録
export const registerWatcherApi = (
  params: RegisterWatcherApiParams,
): Promise<RegisterWatcherApiResponse> =>
  baseApi.post(`${REST}linkdrive/registwatcher`, params);

// 見守り者更新
export const updateWatcherApi = (
  params: UpdateWatcherApiParams,
): Promise<UpdateWatcherApiResponse> =>
  baseApi.post(`${REST}linkdrive/modifywatcher`, params);

// 見守り者削除
export const deleteWatcherApi = (
  params: DeleteWatcherApiParams,
): Promise<DeleteWatcherApiResponse> =>
  baseApi.post(`${REST}linkdrive/delwatcher`, params);

// コネクタ詳細情報取得
export const fetchConnectorDetailApi = (
  params: ConnectorDetailApiParams,
): Promise<ConnectorDetailApiResponse> =>
  baseApi.get(`${REST}linkdrive/connector_detail_bridge`, { params });

// LINKDriveJob実行結果取得
export const jobStatusApi = (
  params: JobStatusParams,
  signal?: AbortSignal,
): Promise<JobStatusApiResponse> =>
  baseApi.get(`${API_V1}/linkdrive/job_status`, { params, signal });

// 24.LinkDrive在庫確認
export const linkDriveStock = (
  params: LinkDriveStockApiParams,
): Promise<LinkDriveStockApiResponse> =>
  baseApi.get(`${API_V1}/linkdrive/stock`, { params });

// 25.JOB実行状況取得
export const jobExecStatus = (
  params: JobExecStatusApiParams,
): Promise<JobExecStatusApiResponse> =>
  baseApi.get(`${API_V1}/linkdrive/job_exec_status`, { params });

// 26.衝撃データ取得
export const getImpactHistories = (
  params: ImpactHistoriesApiParams,
): Promise<ImpactHistoriesApiResponse> =>
  baseApi.get(`${API_V1}/linkdrive/impact_histories`, { params });
