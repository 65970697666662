import { yupResolver } from '@hookform/resolvers/yup';
import { Block, f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { MailDayForm } from './MailDayInput';
import { ShopInfo } from './ShopInfo';
import { MailDayInput, formatDate, imperfectConfirmation } from './const';
import useDocumentDetailItem from './useDocumentDetailItem';
import { mailDaySchema } from './validationSchema';
import { inputCustomerApi } from '@/api/todoListApi';
import { Button } from '@/components/uiParts/Button/Button/Button';
import './TodoDetailStyle.scss';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import { formatDateISO, formatDateWithDay } from '@/utils/date';

interface PromptDocumentDetailProps {
  f7router: Router.Router;
  item?: any;
  handleClickCompleted: () => void;
  isMarkTodoCompleted: boolean;
}

export const PromptDocumentDetail: FC<PromptDocumentDetailProps> = ({
  f7router,
  item,
  handleClickCompleted,
  isMarkTodoCompleted,
}) => {
  const { customerInfo } = store.state;
  const { openToast } = useToast();
  const [documentType, setDocumentType] = useState<number | undefined>();
  const { setIsOpen, PopupDocumentDetailItem } = useDocumentDetailItem({
    requiredDocumentType: documentType,
  });

  const formMethods = useForm<MailDayInput>({
    defaultValues: {
      year: '',
      month: '',
      day: '',
    },
    resolver: yupResolver(mailDaySchema),
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = formMethods;

  const onSubmit: SubmitHandler<MailDayInput> = async (data) => {
    f7.preloader.show();

    const mailDay = formatDate(`${data.year}-${data.month}-${data.day}`);

    const dataSubmit = {
      task_id: item.task_id,
      t_wf_instance_task_id: item.t_wf_instance_task_id,
      data: {
        ...item,
        mail_day: mailDay,
      },
    };

    delete dataSubmit.data.task_id;
    delete dataSubmit.data.t_wf_instance_task_id;

    const res = await inputCustomerApi(dataSubmit);

    f7.preloader.hide();

    if (res.data.success) {
      handleClickCompleted();
      openToast('送信に成功しました');
    } else {
      openToast('エラーが発生しました', 'toast-failed');
    }
  };

  const sendInfo = {
    send_title: '郵送期日',
    send_date: formatDateWithDay(
      new Date(formatDateISO(item?.mail_deadline) || Date.now()),
    ),
    send_content:
      '新しい車の保証料は、ご納車月より変更となります。\n\nしかし請求システムの都合上、<b>お客様への請求が切り替わるのが納車月の翌月から</b>となります。\n\n請求切り替えまでの金額の差分は、<b>納車月の翌月に精算</b>されます。',
    send_remark: 'お客様は納車月の翌月の保証料に<b>4,000円加算</b>されます。',
    send_address: customerInfo.unit_address,
    send_postNo: `〒 ${customerInfo.unit_zip_code1}-${customerInfo.unit_zip_code2}`,
    send_name: customerInfo.unit_tel,
    after_send_process: '',
  };

  const FileListInfo = useCallback(() => {
    return (
      <div className="info-card">
        <h2 className="notice-title-2">必要書類一覧</h2>
        <br />
        <br />
        <div className="confirmation-list">
          {item?.confirmation_list &&
            item?.confirmation_list?.map((i: any) => (
              <div
                className="file-list flex-sb"
                key={i.id}
                onClick={() => {
                  setDocumentType(i.document_name);
                  setIsOpen(true);
                }}
              >
                <div>
                  <div className="item-desc u-font-bold">{i.document_name}</div>
                  {i.imperfect_confirmation ===
                    imperfectConfirmation.DEFECTIVE && (
                    <div className="item-note">{i.imperfect_content}</div>
                  )}
                </div>
                <div className="item-term">
                  <i className="icon-help" />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }, [item]);

  return (
    <>
      <div className="home__wrapper">
        <Block style={{ marginTop: 0, padding: 0 }}>
          <div className="info-card">
            {isMarkTodoCompleted && (
              <div className="info-card-confirm">確認中</div>
            )}
            <h2 className="notice-title u-text-center">
              必要書類の送付をお願いします
            </h2>
            <div className="notice-content">
              納車までの手続きに必要な書類になります。下記の情報をご確認の上、期日までに送付いただきますようお願いいたします。
            </div>
          </div>
          <div className="info-card">
            <h2 className="notice-title-2">{sendInfo.send_title}</h2>
            <p className="notice-date">{sendInfo.send_date.substring(0, 14)}</p>
            <div className="notice-content">
              ※ご指定日までの送付が難しい場合は、購入店舗にご相談ください。
            </div>
            <ShopInfo />
          </div>
          <FileListInfo />
          <div className="info-card">
            <h2 className="notice-title-2">郵送先</h2>
            <p />
            <div className="notice-content info-address">
              {sendInfo.send_postNo}
            </div>
            <div className="notice-content info-address">
              {sendInfo.send_address}
            </div>
            <div className="notice-content info-address">
              {sendInfo.send_name}
            </div>
          </div>

          <div className="info-card">
            <h2 className="notice-title-2">郵送後の流れ</h2>
            <p />
            <div className="notice-content">
              <p>
                郵送が完了しましたら、「完了済みにする」ボタンを押してください。
              </p>
              <br />
              <p>
                ※書類が確認できましたら、やることリストからこちらのタスクがなくなります。
              </p>
            </div>
          </div>

          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="info-card">
                <MailDayForm
                  watch={watch}
                  isValid={isValid}
                  error={
                    errors.year?.message ||
                    errors.month?.message ||
                    errors.day?.message
                  }
                />
              </div>

              <div className="bottom-card">
                <Button
                  className="completed-btn"
                  type="submit"
                  disabled={isMarkTodoCompleted || !isValid}
                >
                  完了済みにする
                </Button>
              </div>
            </form>
          </FormProvider>
        </Block>
      </div>
      {PopupDocumentDetailItem}
    </>
  );
};
