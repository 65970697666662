import { Router } from 'framework7/types';
import { CSSProperties, FC, ReactNode } from 'react';
import style from './CustomList.module.css';
import { handleClickLink } from '@/utils/utils';

interface CustomListProps {
  path: string;
  text: string;
  textColor?: string;
  isBottom?: boolean;
  isTopBorder?: boolean;
  subText?: string | string[];
  subColor?: string;
  subTextStyle?: CSSProperties;
  fontSize?: string;
  isBold?: boolean;
  icon?: ReactNode;
  isRightArrow?: boolean;
  className?: string;
  iconClassName?: string;
  handleClickList?: (path: string, f7router: Router.Router) => void;
  f7router: Router.Router;
}

export const CustomList: FC<CustomListProps> = ({
  path,
  text,
  textColor = '#1b1c1d',
  isBottom = false,
  isTopBorder = true,
  subText = '',
  subColor = undefined,
  subTextStyle = undefined,
  fontSize = '16px',
  isBold = false,
  icon = undefined,
  isRightArrow = true,
  className = undefined,
  iconClassName = '',
  handleClickList = handleClickLink,
  f7router,
}) => {
  const renderSubtextRow = (text: string, index = 0) => {
    if (!text) return <></>;
    return (
      <span
        key={`sub-text-${index}`}
        className={style['sub-text']}
        style={{ color: subColor, ...subTextStyle }}
      >
        {text}
      </span>
    );
  };

  return (
    <li className={`${style.list} ${className}`}>
      <a
        className={`${isBottom && style['list-bottom']} ${style['list-link']} ${
          isTopBorder && style['list-top-border']
        }`}
        onClick={() => {
          handleClickList(path, f7router);
        }}
      >
        <div className={style['list-content-wrap']}>
          <div className={style['icon-text-wrapper']}>
            {icon && <span className={iconClassName}>{icon}</span>}
            <div className={style['texts-wrapper']}>
              <p
                className={style['list-content']}
                style={{
                  fontSize,
                  fontWeight: isBold ? 'bold' : '',
                  color: textColor,
                }}
              >
                {text}
              </p>
              {typeof subText === 'string'
                ? renderSubtextRow(subText)
                : subText.map((text, index) => renderSubtextRow(text, index))}
            </div>
          </div>
        </div>
        {isRightArrow && (
          <div>
            <i className="icon-right" />
          </div>
        )}
      </a>
    </li>
  );
};
