import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { linkDriveOwnerApi } from '@/api/infoApi';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import {
  LinkDriveOwnerApiParams,
  LinkDriveOwnerApiResponse,
} from '@/types/api/infoApi';

interface Args {
  params: LinkDriveOwnerApiParams;
  onSuccess?: (data?: LinkDriveOwnerApiResponse['data']) => void;
}

// LinkDriveオーナー情報をfetchし、storeに保存する
export const useLinkDriveOwner = (initialParams: LinkDriveOwnerApiParams) => {
  const [params] = useState<LinkDriveOwnerApiParams>(initialParams);
  const [data, setData] = useState<LinkDriveOwnerApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const setStoreLinkDriveOwner = async (args: Args) => {
    let isSuccessfully = false;
    try {
      setIsLoading(true);
      const { data } = await linkDriveOwnerApi(args.params);
      setData(data);
      if (data.success) {
        store.dispatch('setLinkDriveOwnerInfo', {
          linkDriveOwnerInfo: data.data.link_drive_owner,
        });
        isSuccessfully = true;
        args.onSuccess?.(data);
      }
      if (!data.success) {
        store.dispatch('setLinkDriveOwnerInfo', {
          linkDriveOwnerInfo: {
            serial_no: '',
            sim_status: '',
            ecu_type: '',
            regist_date: '',
            mode: '',
            init_status: '',
          },
        });
        if (data?.error?.code !== 'NOT_LINK_DRIVE_OWNER') {
          openToast('情報更新に失敗しました', 'toast-failed');
        }
      }
    } catch (error) {
      setError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }

    return isSuccessfully;
  };

  useEffect(() => {
    setStoreLinkDriveOwner({ params });
  }, [params]);

  return {
    data,
    setStoreLinkDriveOwner,
    isLoading,
    error,
  };
};
