import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { AuthInfoState } from '@/config/initialState';
import { store } from '@/config/store';
import { useChangePrepaidMoney } from '@/hooks/api/prepaid/useChangePrepaidMoney';
import { useHouseprepaidApplication } from '@/hooks/api/prepaid/useHouseprepaidApplication';
import { Prepaid } from '@/types/api/customerApi';
import { getPrepaidChangeBillInfo } from '@/utils/prepaid';
import './prepaidChangeMoneyStyle.scss';

type PrepaidChangeMoney = {
  f7router: Router.Router;
  event?: () => void;
};

export const PrepaidChangeMoney: FC<PrepaidChangeMoney> = ({
  f7router,
  event,
}) => {
  const handleAddChargeAmountClick = (value: number) => {
    setAmount((amount ?? 0) + value);
  };

  const prepaidsInfo = useStore(
    store,
    'getFirstAdvancePaymentsInfo',
  ) as Required<Prepaid>; // store.state.advancePaymentsInfo[0];
  const authInfo = useStore(store, 'authInfo') as AuthInfoState; //store.state.authInfo;
  const billsInfo = store.state.billsInfo;
  const { billInfo, name, monthlyCharge } = getPrepaidChangeBillInfo(
    prepaidsInfo,
    billsInfo,
  );

  const params = useMemo(
    () => ({
      m_customer_id: authInfo.m_customer_id,
    }),
    [authInfo.m_customer_id],
  );

  const { data } = useHouseprepaidApplication(params);
  const prepaidPlanSelected = useMemo(
    () =>
      (data?.houseprepaids || []).find(
        (hp) => hp.m_prepaid_plan_id === prepaidsInfo.m_prepaid_plan_id,
      ),
    [data, prepaidsInfo],
  );

  const chargeMin = useMemo(
    () => prepaidPlanSelected?.monthly_charge_min || 0,
    [prepaidPlanSelected],
  );
  const chargeMax = useMemo(
    () => prepaidPlanSelected?.monthly_charge_max || 0,
    [prepaidPlanSelected],
  );
  const chargeUnit = useMemo(
    () => prepaidPlanSelected?.monthly_charge_unit || 1,
    [prepaidPlanSelected],
  );

  const { changePrepaid } = useChangePrepaidMoney();

  const handleChargeClickAction = async () => {
    if (!amount) return;
    if (amount < chargeMin) {
      f7.dialog.alert(
        '',
        '積み立て金額が月額チャージ最低金額(' +
          chargeMin.toLocaleString() +
          '円)を下回っています',
      );
      return;
    }

    if (amount > chargeMax) {
      f7.dialog.alert(
        '',
        '積み立て金額が月額チャージ最高金額(' +
          chargeMax.toLocaleString() +
          '円)を上回っています',
      );
      return;
    }

    if (amount % chargeUnit) {
      f7.dialog.alert(
        '',
        `${chargeUnit.toLocaleString()}円単位で金額を指定してください。`,
      );
      return;
    }

    const changePrepaidParams = {
      m_customer_id: authInfo.m_customer_id,
      prepaid_fee: amount,
    };
    await changePrepaid(changePrepaidParams);
  };

  const amountList = [
    { text: '+100円', value: 100 },
    { text: '+1,000円', value: 1000 },
    { text: '+3,000円', value: 3000 },
  ];

  const [amount, setAmount] = useState<number>();

  const changeAmount = (input: number) => {
    const amount = input > 0 ? input : undefined;
    setAmount(amount);
  };

  return (
    <PopupPageTemplate
      id="PrepaidChangeMoney"
      title="積み立て金額の変更"
      pageName="PrepaidChangeMoney"
    >
      <div style={{ marginTop: '20px' }}>
        <div className="u-font-size-xl u-font-bold u-margin-bottom-md">
          毎月の積立金額を設定してください
        </div>
        <div className="u-margin-bottom-xs" style={{ textAlign: 'right' }}>
          <span className="u-font-size-md">
            変更前の積立額：{monthlyCharge?.toLocaleString()} 円 / 月
          </span>
        </div>
        <div className="input-group u-margin-bottom-md">
          <div className="input-group-area">
            <NumericFormat
              className="u-font-bold"
              value={amount}
              allowNegative={false}
              thousandSeparator=","
              placeholder="0"
              onValueChange={(e) => {
                changeAmount(Number(e.value));
              }}
            />
          </div>
          <div className="input-group-icon u-font-bold">
            <span>円</span>
          </div>
        </div>
        <div className="button-group u-margin-bottom-sm">
          {amountList.map((item, index) => (
            <div
              key={index}
              className="u-font-size-lg"
              onClick={() => handleAddChargeAmountClick(item.value)}
            >
              {item.text}
            </div>
          ))}
        </div>
        <div className="u-font-bold u-padding-top-xs">
          ※最低積み立て金額は{chargeMin?.toLocaleString()}円/月です。
        </div>
        <div className="u-font-bold u-padding-top-xs">
          ※{chargeUnit.toLocaleString()}円単位で設定できます。
        </div>
        <div
          className="info-card info-card-elevated u-padding-top-sm u-margin-bottom-md u-padding-top-sm u-margin-top-sm"
          style={{ backgroundColor: '#ebeced' }}
        >
          <p className="u-font-bold">プラン名：{name}</p>
        </div>
        <Button
          text="変更を保存する"
          isActive={Boolean(amount)}
          handleClickAction={handleChargeClickAction}
          style={{ width: '48%', margin: '10rem auto 0' }}
        />
      </div>
    </PopupPageTemplate>
  );
};
