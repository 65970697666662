import { Popup } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { ReactComponent as GuaranteeIcon } from '@/assets/images/menu/icon-guarantee.svg';
import { Button } from '@/components/uiParts/Button';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { PaymentMethodWithItems, PaymentTypeId } from '@/types/payment';
import './PaymentsStyle.scss';
import { formatDateISO, formatToJaDate } from '@/utils/date';
import { isBankOrPayEasy, switchPaymentIcon } from '@/utils/payment';

interface PaymentsProps {
  isPopupOpened: boolean;
  f7router: Router.Router;
  paymentList: PaymentMethodWithItems[];
  hanldeClosePopup: () => void;
  handleClickWarranty: (mCarId?: number) => void;
  handleClickBack: () => void;
}

export const Payments: FC<PaymentsProps> = ({
  isPopupOpened,
  f7router,
  paymentList,
  hanldeClosePopup,
  handleClickWarranty,
  handleClickBack,
}) => {
  const [renderPopup, setRenderPopup] = useState(true);

  const closePopup = () => {
    setTimeout(() => {
      setRenderPopup(false);
    }, 500);
    hanldeClosePopup();
  };

  return (
    <PageTemplate
      pageName="payments"
      title="ご登録の決済方法"
      showNavBack
      handleClickBack={handleClickBack}
    >
      <div className="container">
        {paymentList.map((item, index) => {
          const accountNumberOrCardNum = item.cardNo ?? item.accountNumber;
          const usefulValue =
            accountNumberOrCardNum === '' ? 'null' : accountNumberOrCardNum;
          const paymentWarranties = item.items.filter((i) => i.mCarId);
          const paymentPrepaid = item.items.filter((i) => i.prepaidId);
          return (
            <RoundSheet key={index}>
              {item.mPaymentTypeId && (
                <div className="sheet-header">
                  <span>
                    <i
                      className={`building-icon ${switchPaymentIcon(
                        item.mPaymentTypeId as PaymentTypeId,
                      )}`}
                    />
                  </span>
                  {isBankOrPayEasy(item.mPaymentTypeId) ? (
                    <div className="payment-title-wrapper">
                      <p>{item.bankName}</p>
                      <span>{`口座番号 ${item.accountNumber || ''}`}</span>
                      <span>{`登録日：${formatToJaDate(
                        formatDateISO(
                          item?.register || new Date().toISOString(),
                        ),
                      )}`}</span>
                    </div>
                  ) : (
                    <div className="payment-title-wrapper">
                      <p>{item.paymentTypeName}</p>
                      <span>{item.cardNo}</span>
                      <span>{`登録日：${formatToJaDate(
                        formatDateISO(
                          item?.register || new Date().toISOString(),
                        ),
                      )}`}</span>
                    </div>
                  )}
                </div>
              )}
              <div className="service-wrapper">
                <h3>ご利用中のサービス</h3>
                {paymentPrepaid.map(({ prepaidName, prepaidId }) => (
                  <CustomList
                    key={prepaidId}
                    text={prepaidName || ''}
                    path={paths.prepaidSetting}
                    icon={<i className="icon-credit-card building-icon" />}
                    f7router={f7router}
                  />
                ))}
                {paymentWarranties.map(({ carNm, mCarId, warrantyNo }) => (
                  <CustomList
                    key={mCarId}
                    text="保証"
                    subText={carNm || ''}
                    subColor="#c9c9c9"
                    path={paths.warranty('false')}
                    handleClickList={() => handleClickWarranty(mCarId)}
                    icon={<GuaranteeIcon width="30px" height="30px" />}
                    f7router={f7router}
                  />
                ))}
                {!paymentWarranties.length && !paymentPrepaid.length && (
                  <CustomList
                    text="この支払い方法でご利用中のサービスはありません"
                    path=""
                    icon={<GuaranteeIcon width="30px" height="30px" />}
                    isRightArrow={false}
                    f7router={f7router}
                  />
                )}
                {paymentPrepaid.length || paymentWarranties.length ? (
                  <CustomList
                    path={paths.deletePaymentsDisabled}
                    text="この支払い情報を削除する"
                    subText="＊支払いにご利用中の支払方法です"
                    textColor="#898989"
                    subColor="#898989"
                    subTextStyle={{ marginTop: '8px' }}
                    f7router={f7router}
                  />
                ) : (
                  <CustomList
                    text="この支払い情報を削除する"
                    path={paths.deletePayments(
                      item.mPaymentMethodId?.toString(),
                      item.mPaymentTypeId?.toString(),
                      usefulValue,
                    )}
                    textColor="#f24965"
                    f7router={f7router}
                  />
                )}
              </div>
            </RoundSheet>
          );
        })}
        <div className="bottom-button">
          <Button
            text="決済方法を追加"
            outline
            handleClickAction={() => {
              f7router.navigate(paths.accountRegistPayment(), {
                props: {
                  fromPage: paths.payments,
                },
              });
            }}
          />
        </div>
      </div>
      {renderPopup && (
        <Popup
          opened={isPopupOpened}
          onPopupClose={closePopup}
          className="regist-success"
        >
          <div className="popup-content">
            <div className="close-icon">
              <i className="icon-close" onClick={closePopup} />
            </div>

            <div className="regist-success-content">
              <i className="icon-success" />
              <h2>決済方法のご登録が完了しました</h2>
              <p>決済方法のご登録ありがとうございます。</p>
            </div>
          </div>
        </Popup>
      )}
    </PageTemplate>
  );
};
