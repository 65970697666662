import { AxiosError } from 'axios';
import { f7, useStore } from 'framework7-react';
import { useState } from 'react';
import { customerInfoApi } from '@/api/customerApi';
import { registerHouseprepaidUseInfoApi } from '@/api/prepaidApi';
import { processingCategory } from '@/consts/prepaid';
import { useToast } from '@/hooks/useToast';
import { Prepaid } from '@/types/api/customerApi';
import {
  HousePrepaidUseInfoParams,
  HousePrepaidUseInfoResponse,
} from '@/types/api/infoApi';
import { dispatchBasicInfo } from '@/utils/store';

export const useHouseprepaidUseInfo = (customMessage?: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const prepaid = useStore('getFirstAdvancePaymentsInfo') as
    | Required<Prepaid>
    | undefined;
  const usePrepaid = async (
    params: Omit<HousePrepaidUseInfoParams, 'proc_kbn'>,
  ) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      // fixed_deposit_flg
      // 0の場合、処理区分  1：基本積立
      // 1の場合、処理区分  2：定期積立
      const customParams: HousePrepaidUseInfoParams = {
        ...params,
        proc_kbn: !!prepaid?.fixed_deposit_flg
          ? processingCategory.REGULAR_SAVING
          : processingCategory.BASIC_SAVING,
      };
      const res: any = await registerHouseprepaidUseInfoApi(customParams);
      if (!res.data.success) {
        const error_message =
          customMessage ?? res.data.error_message ?? '処理に失敗しました。';
        openToast(error_message, 'toast-failed', 3000, true, 'top');
        setIsLoading(false);
        f7.preloader.hide();
        return false;
      }
      const { data: basicInfo } = await customerInfoApi({
        m_customer_id: params.m_customer_id,
      });
      await dispatchBasicInfo(basicInfo);
      return res.data as HousePrepaidUseInfoResponse;
    } catch (err) {
      setError(err as AxiosError);
      const error_message = customMessage ?? '処理に失敗しました。';
      openToast(error_message, 'toast-failed', 3000, true, 'top');
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    usePrepaid,
    registerPrepaid: usePrepaid,
    isLoading,
    error,
  };
};
