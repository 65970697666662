import { Block, Link, Navbar, NavLeft, NavTitle, Page } from 'framework7-react';
import { FC } from 'react';
import { TextLinkCard } from '@/components/uiParts/TextLinkCard/TextLinkCard';

export const LinkDriveUpdatePage: FC = () => {
  const list = [
    { title: 'プログラムを更新する', id: 1, latestVer: false },
    { title: '重要データは最新の状態です', id: 2, latestVer: true },
    { title: 'プログラムデータを更新する', id: 3, latestVer: false },
  ];

  return (
    <Page name="LINKDriveUpdate">
      <Navbar>
        <NavLeft>
          <Link back animate>
            <i className="icon-left" />
          </Link>
        </NavLeft>
        <NavTitle>LINKDrive更新</NavTitle>
      </Navbar>
      <Block>
        {list.map((item, index) => (
          <div key={index} className="u-margin-bottom-sm btn-card">
            <TextLinkCard
              iconType={item.latestVer ? '' : 'reload-refresh'}
              isEleveted
              linkDriveMenus={[item]}
              handleClick={() => {}}
            />
          </div>
        ))}
      </Block>
    </Page>
  );
};
