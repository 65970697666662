import { useStore } from 'framework7-react';
import { FC } from 'react';
import { PaymentsSchedule2 } from './PaymentsSchedule2/PaymentsSchedule2';
import { store } from '@/config/store';
import { invalidateFlag, noPayFlg } from '@/consts/payment';
import {
  BillInformation,
  BillInformationDetail,
} from '@/types/api/customerApi';
interface PaymentsSchedule2PageProps {
  warrantyNo: string;
}

export const PaymentsSchedule2Page: FC<PaymentsSchedule2PageProps> = ({
  warrantyNo,
}) => {
  const bills = useStore(store, 'getBillInfo') as Required<BillInformation>[];
  const warrantyBills = bills.filter((bill) => bill.warranty_no === warrantyNo);
  const untreatedBillDetails: BillInformationDetail[] = [];
  warrantyBills.forEach((bill) => {
    if (!bill.bill_infomation_details) return;
    bill.bill_infomation_details.forEach((detail) => {
      const billingDate = new Date(detail.billing_date as string);
      const now = new Date();
      now.setHours(0, 0, 0, 0);

      if (
        billingDate >= now &&
        detail.invalidate_flg == invalidateFlag.NOT_EXPIRED &&
        detail.no_pay_flg === noPayFlg.UNTREATED
      ) {
        untreatedBillDetails.push(detail);
      }
    });
  });

  return <PaymentsSchedule2 billDetails={untreatedBillDetails} />;
};
