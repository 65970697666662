import { useStore } from 'framework7-react';
import { FC } from 'react';
import { PrepaidTerms } from './PrepaidTerms/PrepaidTerms';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import { termType } from '@/consts/terms';
import { useTerm } from '@/hooks/api/term/useTerm';

export const PrepaidTermsPage: FC = () => {
  const prepaidsInfo = useStore(store, 'getFirstAdvancePaymentsInfo');
  const { data } = useTerm(termType.PREPAID, prepaidsInfo.id);
  const html = data?.term;
  return (
    <PageTemplate
      pageName="PrepaidTerms"
      title="プリペイドサービスご利用規約"
      showNavBack
    >
      <PrepaidTerms html={html} />
    </PageTemplate>
  );
};
