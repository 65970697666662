import { FC } from 'react';

export const CouponIcon: FC<{ color?: string }> = ({ color = '#AA7F66' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 9H19V8H3.5C3.22386 8 3 8.22386 3 8.5V13C3.79565 13 4.55871 13.3161 5.12132 13.8787C5.68393 14.4413 6 15.2044 6 16C6 16.7956 5.68393 17.5587 5.12132 18.1213C4.55871 18.6839 3.79565 19 3 19V23.5C3 23.7761 3.22386 24 3.5 24H19V23H21V24H28.5C28.7761 24 29 23.7761 29 23.5V19C28.2043 19 27.4413 18.6839 26.8787 18.1213C26.3161 17.5587 26 16.7956 26 16C26 15.2044 26.3161 14.4413 26.8787 13.8787C27.4413 13.3161 28.2044 13 29 13V8.5C29 8.22386 28.7761 8 28.5 8H21V9ZM19 15H21V17H19V15ZM21 19H19V21H21V19ZM19 11H21V13H19V11Z"
        fill={color}
      />
    </svg>
  );
};
