import { Popup, useStore } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import {
  connectorInfoLabelList,
  linkDriveHelpList,
  noticeList,
  systemInfoLabelList,
} from './ConnectorSettingConst';
import style from './ConnectorSettingStyle.module.css';
import { LinkDriveInfo } from './LinkDriveInfo/LinkDriveInfo';
import loader from '@/assets/images/loading/loading_start.svg';
import { SleepDialog } from '@/components/projects/SleepDialog';
import { Button } from '@/components/uiParts/Button';
import { TextLinkCard, UncompletedCard } from '@/components/uiParts/Card';
import { LinkedCard } from '@/components/uiParts/Card/LinkedCard/LinkedCard';
import { ListCard } from '@/components/uiParts/Card/ListCard/ListCard';
import { CheckIcon } from '@/components/uiParts/Check/Check';
import { ProgressCircleCar } from '@/components/uiParts/ProgressCircleCar/ProgressCircleCar';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { store } from '@/config/store';
import { useConnectorSetting } from '@/hooks/useConnectorSetting';
import { useUpdateProgress } from '@/hooks/useUpdateProgress';
import { LinkDriveOwner } from '@/types/api/infoApi';
import { generateTransportName } from '@/utils/car';
import { f7CustomBack } from '@/utils/utils';
import './ConnectorSettingStyle.scss';

export const ConnectorSetting: FC = () => {
  const {
    linkCar,
    currentUpdate,
    progress,
    isSetupCompleted,
    isCalibrationCompleted,
    showDialog,
    isOpened,
    isDuringConnectorStartup,
    isUpdating,
    connectorDetailInfo,
    isFirmWareUpdating,
    isFWLatestVersion,
    isECUUpdating,
    isECULatestVersion,
    isImpactJudgementUpdating,
    isImpactJudgementLatestVersion,
    isAvaiableForUpdate,
    isFailure,
    setShowDialog,
    setIsOpened,
    handleClickNavigationLinkDriveCalibration,
    handleClickNavigationLinkDriveSetupList,
    handleClickSetupConnectorChange,
    handleClickUpdateSystemInfo,
    refetch,
  } = useConnectorSetting();

  const [duringConnectorText, setDuringConnectorText] =
    useState('コネクター起動確認中');

  const initialize = () => {
    setDuringConnectorText('コネクタ起動確認中');
    updateProgress(0);
  };

  const progressPath = document.querySelector<SVGPathElement>('#progress');
  if (progressPath != null) {
    const pathLength = progressPath.getTotalLength();
    progressPath.style.strokeDasharray = pathLength.toString();
    progressPath.style.strokeDashoffset = pathLength.toString();
  }
  const { updateProgress, progress: progressDetail } =
    useUpdateProgress(progressPath);

  const handleClickClose = () => {
    setIsOpened(false);
    initialize();
  };

  useEffect(() => {
    updateProgress(progress);
  });

  // 車両輸送名
  const transportName = generateTransportName(linkCar);

  const linkDriveOwnerInfo = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;
  const handleOnRefech = async (cb: () => void) => {
    try {
      await refetch();
      cb();
    } catch (e) {
      console.error('Error while refetching: ', e);
    }
  };
  return (
    <PageTemplateV2
      pageName="ConnectorSetting"
      title="コネクター設定"
      showNavBack
      onRefresh={(done) => {
        if (handleOnRefech) {
          handleOnRefech(() => done());
        }
      }}
      handleClickBack={() => f7CustomBack()}
    >
      <div style={{ marginBottom: '70px' }}>
        <div>
          {/* <!-- セットアップ --> */}
          {!isSetupCompleted && (
            <UncompletedCard
              cardHead={`LinkDriveのセットアップが\n完了していません`}
              cardBody="セットアップ完了後に運転記録や異常診断が使用できるようになります。"
              annotation="※ コネクタはまだ車両に挿さないで下さい"
              buttonText="セットアップする"
              handleClickAction={handleClickNavigationLinkDriveSetupList}
            />
          )}

          {/* <!-- キャリブレーション --> */}
          {isSetupCompleted && !isCalibrationCompleted && (
            <UncompletedCard
              cardHead={`LinkDriveのキャリブレーションが\n完了していません`}
              cardBody="キャリブレーション完了後に運転記録や異常診断が使用できるようになります。"
              annotation="※ コネクタはまだ車両に挿さないで下さい"
              buttonText="キャリブレーションする"
              handleClickAction={handleClickNavigationLinkDriveCalibration}
            />
          )}

          {/* モード */}
          {linkDriveOwnerInfo?.ecu_type && isSetupCompleted && (
            <LinkDriveInfo ecu_type={linkDriveOwnerInfo?.ecu_type} />
          )}

          {/* <!-- コネクター情報 --> */}
          {isSetupCompleted && (
            <ListCard
              cardHead="コネクター情報"
              cardBodys={connectorInfoLabelList(
                connectorDetailInfo?.model,
                linkDriveOwnerInfo,
              )}
            />
          )}

          {/* <!-- 対象車両 --> */}
          {isSetupCompleted && (
            <div className="u-margin-bottom-md info-card info-card-elevated">
              <div
                className="head display-flex u-font-bold u-font-size-xxl"
                style={{ margin: '10px 0px 15px' }}
              >
                対象車両
              </div>
              <div className="display-flex-sb u-padding-top-sm u-padding-bottom-sm">
                <div className={style['card-sub-title']}>車両名</div>
                <div className={style['card-description']}>
                  {linkCar?.car_nm} {transportName}
                </div>
              </div>
            </div>
          )}

          {/* <!-- 通知設定 --> */}
          {isSetupCompleted && (
            <LinkedCard
              cardHead="通知・データ利用設定"
              cardBodys={noticeList}
            />
          )}

          {/* <!-- システム情報 --> */}
          {isSetupCompleted && (
            <ListCard
              cardHead="システム情報"
              cardBodys={systemInfoLabelList(isAvaiableForUpdate)}
              isButton
              hasUpdatedData={isAvaiableForUpdate}
              isDisabled={
                isFWLatestVersion &&
                isECULatestVersion &&
                isImpactJudgementLatestVersion
              }
              handleClick={handleClickUpdateSystemInfo}
            />
          )}

          {/*  キャリブレーション */}
          {isSetupCompleted && (
            <TextLinkCard
              cardText="キャリブレーション"
              handleClick={handleClickNavigationLinkDriveCalibration}
            />
          )}

          {/* コネクタ付替  */}
          {isSetupCompleted && (
            <TextLinkCard
              cardText="コネクタ付替"
              handleClick={handleClickSetupConnectorChange}
            />
          )}

          {/* <!-- Linkdriveに関するヘルプ・規約 --> */}
          {isSetupCompleted && (
            <LinkedCard
              cardHead="LinkDriveに関するヘルプ・規約"
              cardBodys={linkDriveHelpList}
            />
          )}
        </div>
        {/* TODO: コンポーネント分割 */}
        <Popup
          opened={isOpened}
          closeOnEscape={false}
          closeByBackdropClick={false}
          onPopupClose={() => {
            setIsOpened(false);
            initialize();
          }}
        >
          <div className={style.container}>
            <p className={style.headingText}>ファームウェアアップデート</p>
            {isDuringConnectorStartup && (
              <div className={style.loading}>
                <img src={loader} alt="loading" />
                <p className={style.text}>{duringConnectorText}</p>
              </div>
            )}
            {(isFirmWareUpdating ||
              isECUUpdating ||
              isImpactJudgementUpdating) && (
              <div className={style.loading}>
                <div
                  style={{
                    width: '120px',
                    position: 'relative',
                    margin: '0 auto',
                  }}
                >
                  <ProgressCircleCar
                    id={'progress'}
                    progress={progressDetail}
                  />
                </div>
                <p className={style.mainText}>
                  {progress < 100
                    ? `${currentUpdate}はアップデート中です...`
                    : 'アップデートが完了しました！'}
                </p>
                {progress < 100 && (
                  <div className={style.subText}>
                    <span>※</span>
                    <span>
                      アップデートが完了するまで時間がかかる場合があります。
                    </span>
                  </div>
                )}
              </div>
            )}
            {!isUpdating && (
              <div className={style.loading}>
                <CheckIcon
                  isValid={true}
                  width={'50px'}
                  height={'50px'}
                  style={{ alignSelf: 'center' }}
                />
                <p className={style.text}>更新完了しました</p>
              </div>
            )}

            <div className={style.cancelButton}>
              <Button
                text="閉じる"
                disabled={!isFailure}
                handleClickAction={handleClickClose}
              />
            </div>
          </div>
        </Popup>
        <Popup
          opened={showDialog}
          closeOnEscape={false}
          closeByBackdropClick={false}
        >
          <SleepDialog
            handleCancel={() => setShowDialog(false)}
            containerClass={style['sleep-dialog']}
          />
        </Popup>
      </div>
    </PageTemplateV2>
  );
};
