import React, { FC } from 'react';
import style from './MonthlyDriveScoreStyle.module.css';
import { TransitionChart } from '@/components/projects/TransitionChart/TransitionChart';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';

interface MonthlyDriveScoreProps {
  monthlyDriveScore: {
    reportDate: string;
    averageScore: number;
    speedStarScore: number;
    accelerationStarScore: number;
    decelerationStarScore: number;
    scoreComment: string;
    monthlyDriveReportXAxis: string[];
    monthlyDriveReportYAxis: number[];
  };
}

export const MonthlyDriveScore: FC<MonthlyDriveScoreProps> = ({
  monthlyDriveScore,
}) => {
  const pieces = [
    {
      gt: 80,
      lte: 100,
      color: '#1E55E6',
    },
    {
      gt: 60,
      lte: 79,
      color: '#00C4FF',
    },
    {
      gt: 0,
      lte: 59,
      color: '#FFDC19',
    },
  ];
  return (
    <div className={style.card}>
      <div className={style['tool-bar-wrap']}>
        <div className={style.date}>{monthlyDriveScore.reportDate}</div>
      </div>

      <h3 className={style.heading}>月間安全スコア</h3>
      {/* TODO: コンポーネント化 */}
      <div className={style['evaluation-wrap']}>
        <div className={style['score-wrap']}>
          <div>
            <span className={style['alpha-score']}>B</span>
          </div>
          <div>
            <span className={style.score}>
              {monthlyDriveScore.averageScore}
            </span>
            点
          </div>
        </div>
        <div className={style['star-rating-wrap']}>
          <div className={style['star-rating']}>
            <span>スピード</span>
            <span className={style.stars}>
              {/* スコアが4の場合 */}
              {[...Array(5)].map((_, idx) => (
                <span key={idx}>
                  {idx < monthlyDriveScore.speedStarScore ? '★' : '☆'}
                </span>
              ))}
            </span>
          </div>
          <div className={style['star-rating']}>
            <span>アクセル</span>
            <span className={style.stars}>
              {/* スコアが4の場合 */}
              {[...Array(5)].map((_, idx) => (
                <span key={idx}>
                  {idx < monthlyDriveScore.accelerationStarScore ? '★' : '☆'}
                </span>
              ))}
            </span>
          </div>
          <div className={style['star-rating']}>
            <span>ブレーキ</span>
            <span className={style.stars}>
              {/* スコアが4の場合 */}
              {[...Array(5)].map((_, idx) => (
                <span key={idx}>
                  {idx < monthlyDriveScore.decelerationStarScore ? '★' : '☆'}
                </span>
              ))}
            </span>
          </div>
        </div>
      </div>

      {/* 評価テキスト */}
      <div className={style['evaluation-text']}>
        <p>{monthlyDriveScore.scoreComment}</p>
      </div>

      {/* グラフ */}
      <div
        className={style['graph-wrap']}
        style={{ width: '100%', height: '140px' }}
      >
        <TransitionChart
          id="monthlyTransition"
          yearScoreList={monthlyDriveScore.monthlyDriveReportYAxis}
          createMonthly={monthlyDriveScore.monthlyDriveReportXAxis}
          showLabel={false}
          showSplitLineX={false}
          gridLeft="10%"
          seriesType="bar"
          axisLabelInterval={1}
          pieces={pieces}
        />
      </div>

      <IconCard
        heading={'安全運転スコアの算出基準'}
        iconType="help"
        isTransition
        path=""
        color="gray"
      />

      {/* デバイス */}
      <div className={style['device-wrap']}>
        <div className={style['device-classification']}>
          <p className={style['']}>デバイス区分</p>
          <p className={style.identification}>LD</p>
        </div>
        <div className={style['device-key']}>
          <p className={style['']}>デバイスキー</p>
          <p className={style.identification}>LD123456</p>
        </div>
      </div>
    </div>
  );
};
