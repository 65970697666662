import { Card } from 'framework7-react';
import perse from 'html-react-parser';
import { FC } from 'react';
import style from './ProblemTermsStyles.module.scss';
import { store } from '@/config/store';
import { findDefectAppendWarranty } from '@/utils/warranty';

export const ProblemTerms: FC = () => {
  const { warranties } = store.state.carItemInfo;
  const warrantyTerms = findDefectAppendWarranty(warranties[0])?.low_ver || '';

  const startIndex = warrantyTerms.indexOf('<table>');
  const endIndex = warrantyTerms.indexOf('</table>') + '</table>'.length;
  const tableContent = warrantyTerms.substring(startIndex, endIndex);
  const wrappedTable =
    `<div className="${style['scrollable-table']}">` + tableContent + '</div>';
  const modifiedString =
    warrantyTerms.substring(0, startIndex) +
    wrappedTable +
    warrantyTerms.substring(endIndex);

  return (
    <Card className={`${style['card']} u-margin-bottom-0`}>
      <h3 className="u-padding-bottom-sm u-margin-top-0">保証規約</h3>
      <div className={style.container}>{perse(modifiedString)}</div>
    </Card>
  );
};
