import {
  applyStatus as APPLY_STATUS,
  WARRANTY_DOCUMENT_TYPE,
  WARRANTY_STATUS,
  warrantyCoverageType,
  warrantyType,
} from '@/consts/warranty';
import {
  Car,
  MWarrantyPart,
  NextWarranty,
  NextWarrantyAppendWarranty,
  WarrantiesAppendWarranty,
  Warranty,
} from '@/types/api/customerApi';
import { ApplyStatus } from '@/types/warranty';

import { WarrantyCoverageType } from '@/types/warranty';

export type WarrantyPartsType = WarrantyPart[];

export type WarrantyPart = {
  m_accessory_category_name: string;
  subItems: MWarrantyPart[];
};

export type WarrantyGroup = {
  [index: string]: MWarrantyPart[];
};

export const getWarrantyPartsData = (warrantyList: MWarrantyPart[]) => {
  const groups: WarrantyGroup = {};
  warrantyList.forEach((el) => {
    const group = el['parts_category_name'] ?? '';
    groups[group] = groups[group] || [];
    groups[group].push(el);
  });
  return Object.keys(groups).map((group) => {
    const data: WarrantyPart = {
      m_accessory_category_name: group,
      subItems: groups[group],
    };
    return data;
  });
};

export const hasWarrantyTiredToCar = (car: Car | undefined) => {
  return !!car?.warranties?.[0]?.warranty_no;
};

export const findDefectAppendWarranty = (warranty: Warranty | undefined) => {
  return warranty?.append_warranties?.find(
    (w: WarrantiesAppendWarranty) => w.warranty_type === warrantyType.DEFECT,
  );
};

export const findNextDefectAppendWarranty = (
  warranty: NextWarranty | undefined,
) => {
  return warranty?.append_warranties?.find(
    (w: NextWarrantyAppendWarranty) => w.warranty_type === warrantyType.DEFECT,
  );
};

export const switchWarrantyPartsTitleByCoverageType = (
  type: WarrantyCoverageType | undefined | null,
) => {
  switch (type) {
    case warrantyCoverageType.COVERED:
      return '保証対象外部位';
    case warrantyCoverageType.NOT_COVERED:
      return '保証対象部位';
    default:
      return '保証対象部位';
  }
};

export const isCancellationInProcess = (
  applyStatus: ApplyStatus | undefined,
  warrantyStatus: number | undefined,
) => {
  return (
    warrantyStatus === WARRANTY_STATUS.VALID &&
    (applyStatus === APPLY_STATUS.DURING_CANCELLATION_APPLICATION ||
      applyStatus === APPLY_STATUS.OUTSIDE_CANCELLATION_APPLICATION)
  );
};

export const getDescriptionDocument = (documentType?: number) => {
  switch (documentType) {
    case WARRANTY_DOCUMENT_TYPE.CONTRACT_PHOTO:
      return 'お車をご購入いただきました際のご契約書です。';
    case WARRANTY_DOCUMENT_TYPE.CAR_INSPECTION_PHOTO:
      return 'お車の車検証（自動車検査証）です。';
    case WARRANTY_DOCUMENT_TYPE.CONDITION_SHEET_PHOTO:
      return 'ご納車時のお車の状態が記されたものです。';
    case WARRANTY_DOCUMENT_TYPE.EXTERIOR_PHOTO:
      return '車輛の外装状態がわかる写真です。';
    case WARRANTY_DOCUMENT_TYPE.INTERIOR_PHOTO:
      return '車輛の内装状態がわかる写真です。';
    case WARRANTY_DOCUMENT_TYPE.METER_PHOTO:
      return 'お車のメーターパネル箇所の写真です。';
    default:
      return '';
  }
};

export const renderDescriptionDocument = (
  name?: string,
  documentType?: number,
) => {
  if (!documentType || !getDescriptionDocument(documentType) || !name)
    return '';

  return name + 'とは、' + getDescriptionDocument(documentType);
};
