import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { updatePasswordApi } from '@/api/customerApi';
import { useToast } from '@/hooks/useToast';
import { UpdatePasswordApiParams } from '@/types/api/customerApi';

export const useUpdatePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const updatePassword = async (params: UpdatePasswordApiParams) => {
    let result = false;
    try {
      f7.preloader.show();
      setIsLoading(true);
      const { data } = await updatePasswordApi(params);
      result = data.success;
      if (data.success) openToast('パスワードが更新されました');
      if (!data.success)
        openToast('パスワードの更新に失敗しました', 'toast-failed');
    } catch (error) {
      setError(error as AxiosError);
      openToast('パスワードの更新に失敗しました', 'toast-failed');
    } finally {
      f7.preloader.hide();
      setIsLoading(false);
      return result;
    }
  };

  return {
    error,
    isLoading,
    updatePassword,
  };
};
