import { store } from '@/config/store';
import { Car, CustomerInfoApiResponse } from '@/types/api/customerApi';

export const storeDispatch = async (
  actions: string,
  stateName: string,
  newState: unknown,
) => {
  await store.dispatch(actions, {
    [stateName]: newState,
  });
};

export const dispatchBasicInfo = async (
  customerInfo: CustomerInfoApiResponse['data'],
  findFnc?: (value: Car) => unknown,
) => {
  const {
    customer,
    cars,
    bill_infomations,
    payment_methods,
    prepaids,
    informations,
    campaigns,
  } = customerInfo;
  const promises = [
    storeDispatch('setCustomerInfo', 'customerInfo', customer),
    storeDispatch('setCarsInfo', 'carsInfo', cars),
    storeDispatch('setBillsInfo', 'billsInfo', bill_infomations),
    storeDispatch(
      'setPaymentMethodsInfo',
      'paymentMethodsInfo',
      payment_methods,
    ),
    storeDispatch('setAdvancePaymentsInfo', 'advancePaymentsInfo', prepaids),
    storeDispatch('setNotificationsInfo', 'notificationsInfo', informations),
    storeDispatch('setCampaignsInfo', 'campaignsInfo', campaigns),
  ];
  if (cars?.length) {
    const car = (findFnc ? cars.find(findFnc) : cars[0]) || cars[0];
    promises.push(storeDispatch('setCarItemInfo', 'carItemInfo', car));
    if (car?.warranties?.length) {
      promises.push(
        storeDispatch('setWarranties', 'warranties', car.warranties),
      );
      promises.push(
        storeDispatch(
          'setCurrentWarranty',
          'currentWarranty',
          car.warranties?.[0],
        ),
      );
    }
    if (car.next_warranty?.length) {
      promises.push(
        storeDispatch('setNextWarranties', 'nextWarranties', car.next_warranty),
      );
      promises.push(
        storeDispatch(
          'setNextWarranty',
          'nextWarranty',
          car.next_warranty?.[0],
        ),
      );
    }
  }
  await Promise.all(promises);
};
