import { FC } from 'react';
import style from './TradeConfirmApplicationDetailPage.module.css';
import { paths } from '@/config/paths';
import { handleClickLink } from '@/utils/utils';

interface LineItemProps {
  label: string;
  value: string;
  isBorder?: boolean;
}

export const TradeContentDetail: FC = () => {
  return (
    <>
      <div className={`${style['block-header']} ${style['flex-center']}`}>
        <div className={style['title']}>取引保証申請内容</div>
        <div
          className={style['edit-btn']}
          onClick={() => handleClickLink(paths.tradeGuarantee)}
        >
          編集
        </div>
      </div>

      <div className={`${style['block']} ${style['first-block']}`}>
        <div>
          <p className={style['title-first']}>トラブルの詳細</p>
          <LineItem label="トラブルの種類" value="ボディ損傷" />
          <LineItem
            label="トラブル内容"
            value="購入時に傷はないと聞いていたが、実際には擦り傷が数箇所あった。"
          />
          <LineItem label="対象車両" value="あ46-49 TOYOTA ヴォクシー" />
          <LineItem label="トラブル発生日時" value="2023年1月1日（月）" />

          <p className={style['title-second']}>購入時の金額について</p>
          <LineItem label="購入金額" value="123,000円" isBorder={false} />
        </div>
      </div>

      <div className={`${style['block-header']} ${style['flex-center']}`}>
        <div className={style['title']}>各種書類</div>
        <div
          className={style['edit-btn']}
          // onClick={() => handleClickLink(paths.tradeAssuranceApplication)}
        >
          編集する
        </div>
      </div>
    </>
  );
};

const LineItem = (props: LineItemProps) => {
  const { label, value, isBorder = true } = props;

  return (
    <div
      className={`${style['content-line']} ${
        isBorder ? style['border-bottom-normal'] : ''
      } `}
    >
      <p className={style['text-label']}>{label}</p>
      <p className={style['text']}>{value}</p>
    </div>
  );
};
