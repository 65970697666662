import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, PairInput } from '../../CarRepair/Partials';
import { DesiredDate } from './DesiredDate';
import { PhoneInput } from './PhoneInput';
import { Radio } from './Radio';
import style from './TradeReservationPage.module.css';
import { FormInput } from './tradeReservationSchema';
import { PopupConfirm } from '@/components/projects/CarRepair/PopupConfirm';
import { Button } from '@/components/uiParts/Button';
import {
  CHECK_EXPECTED_ATS,
  CheckExpectedAt,
  IDENTIFICATION_SUBSCRIBERS,
  IdentificationSubscriber,
} from '@/consts/carRepair';

type TradeReservationProps = {
  callCenterTel?: string;
};
export const TradeReservation: FC<TradeReservationProps> = ({
  callCenterTel,
}) => {
  const [opened, setOpened] = useState(false);
  const {
    watch,
    trigger,
    formState: { errors, isValid },
  } = useFormContext<FormInput>();

  const checkExpected = watch('is_checked_expected_nyukosaki');
  const identificationSubscriber = watch(
    'is_checked_identification_subscriber',
  );
  const isExternalFactory = checkExpected == CheckExpectedAt.EXTERNAL_FACTORY;
  const handleOpenPopupConfirm = (value: string) => {
    value === CheckExpectedAt.EXTERNAL_FACTORY &&
      !isExternalFactory &&
      setOpened(true);
  };

  useEffect(() => {
    trigger('contact_person_phone_number');
  }, []);
  return (
    <div className={style['content-page']}>
      <PopupConfirm
        opened={opened}
        onPopupClose={() => setOpened(false)}
        callCenterTel={callCenterTel}
      />
      {/* 修理する車の入庫先を選択してください */}
      <div className={style['form-item']}>
        <h2 className={style['input-label']}>
          修理する車の入庫先を選択してください
          <span className={`${style['input-label-tips']} ${style['required']}`}>
            必須
          </span>
        </h2>
        <div className={style['checkbox-radio-items']}>
          {CHECK_EXPECTED_ATS.map((item) => (
            <Radio
              key={item.value}
              id={item.value}
              name="is_checked_expected_nyukosaki"
              checked={checkExpected === item.value}
              value={item.value}
              className={style['input-radio']}
              handleOnClick={handleOpenPopupConfirm}
            >
              {item.text}
            </Radio>
          ))}
        </div>
      </div>
      {/* ご入庫の際、工場の担当者へ以下の3点を点検開始前にお話しください。 */}
      <div
        className={`${style['input-group']} ${
          checkExpected != CheckExpectedAt.EXTERNAL_FACTORY
            ? style['hidden']
            : ''
        }`}
      >
        <h3 className={style['input-label']}>
          ご入庫の際、工場の担当者へ以下の3点を点検開始前にお話しください。
          <span className={`${style['input-label-tips']} ${style['required']}`}>
            必須
          </span>
        </h3>
        <div className={`${style['note-field']} u-margin-top-sm`}>
          <p className={style['number-bullet']}>
            自動車保証に加入しておりその保証を利用しての修理を希望すること
          </p>
          <p className={style['number-bullet']}>
            工場担当者からサポートセンターへご連絡を入れていただく必要があること
          </p>
          <p className={style['number-bullet']}>
            当サポートセンターの電話番号
            {callCenterTel && ` (${callCenterTel})`}
          </p>
        </div>
        <Checkbox name="warrantyConfirmed">上記の内容を確認しました</Checkbox>
      </div>
      {/* ご依頼の希望日時を選択してください */}
      <DesiredDate isHidden={isExternalFactory} />
      <div className={style['divider']} />
      {/* ご連絡先の電話番号 */}
      <div className={style['form-item']}>
        <h2 className={style['input-label']}>
          ご連絡先の電話番号
          <span className={`${style['input-label-tips']} ${style['required']}`}>
            必須
          </span>
        </h2>

        <PhoneInput />
      </div>
      <div className={style['divider']} />
      {/* ご連絡先電話番号と契約者様の関係 */}
      <div className={style['form-item']}>
        <h2 className={style['input-label']}>
          ご連絡先電話番号と契約者様の関係
          <span className={`${style['input-label-tips']} ${style['required']}`}>
            必須
          </span>
        </h2>
        <div className={style['checkbox-radio-items']}>
          {IDENTIFICATION_SUBSCRIBERS.map((item) => (
            <Radio
              key={item.value}
              id={item.value}
              name="is_checked_identification_subscriber"
              checked={identificationSubscriber === item.value}
              value={item.value}
              className={style['input-radio']}
            >
              {item.text}
            </Radio>
          ))}
        </div>
      </div>
      {/* ご連絡先の氏名 */}
      {identificationSubscriber == IdentificationSubscriber.AGENT && (
        <>
          <div className={style['form-item']}>
            <h2 className={style['input-label']}>
              ご連絡先の氏名
              <span
                className={`${style['input-label-tips']} ${style['required']}`}
              >
                必須
              </span>
            </h2>
            <PairInput
              label="お名前"
              firstInputName="contact_person_name.family_name"
              secondInputName="contact_person_name.first_name"
              firstPlaceholder="田中"
              secondPlaceholder="太郎"
              firstError={
                errors?.contact_person_name?.family_name?.type === 'required'
                  ? 'お名前が指定されていません'
                  : ''
              }
              secondError={
                errors?.contact_person_name?.first_name?.type === 'required'
                  ? 'お名前が指定されていません'
                  : ''
              }
            />
            <PairInput
              label="フリガナ"
              firstInputName="contact_person_name_kana.family_name"
              secondInputName="contact_person_name_kana.first_name"
              firstPlaceholder="タナカ"
              secondPlaceholder="タロウ"
              firstError={
                errors?.contact_person_name_kana?.family_name?.message
              }
              secondError={
                errors?.contact_person_name_kana?.first_name?.message
              }
            />
          </div>
        </>
      )}
      <div className={style['btn-submit']}>
        <Button
          text={isExternalFactory ? '内容を確認する' : '依頼内容を確認する'}
          type="submit"
          disabled={!isValid}
        />
      </div>
    </div>
  );
};
