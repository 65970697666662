import { Router } from 'framework7/types';
import { FC } from 'react';
import { UsePrepaidComplete } from './UsePrepaidComplete/UsePrepaidComplete';

interface UsePrepaidCompletePageProps {
  f7router: Router.Router;
  value: string;
}

export const UsePrepaidCompletePage: FC<UsePrepaidCompletePageProps> = ({
  f7router,
  value,
}) => {
  return <UsePrepaidComplete f7router={f7router} value={value} />;
};
