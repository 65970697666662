import { FC } from 'react';
import { CreditCardPayInfo } from '@/components/pages/PaymentsSchedule/CreditCardPayInfo/CreditCardPayInfo';
import { OfficeFeeInfo } from '@/components/pages/PaymentsSchedule/OfficeFeeInfo/OfficeFeeInfo';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import { noPayFlg, paymentTypeId } from '@/consts/payment';
import { BillInformationDetail } from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';
import { formatToJaDate, hyphenToYear } from '@/utils/date';
import './paymentsHistory2Style.scss';
import { createPaymentBankNameText, isBankOrPayEasy } from '@/utils/payment';

type PaymentsHistory2Props = {
  billDetailsGroupByMonth: BillDetailsGroupByMonth;
  onClickBillDetail: (bill: BillInformationDetail) => void;
};

export type PaymentsHistoryInfo = {
  billing_date_ym?: string;
  bill_price_total?: number;
  billinfoDetail?: PaymentsHistoryInfoDetail[];
};

export type PaymentsHistoryInfoDetail = {
  pay_subject?: string;
  memo?: string;
  billing_date?: string;
  bill_price?: number;
  m_payment_type_id?: number;
  card_no?: string;
  bank_name?: string;
  account_number?: string;
  payment_type_name?: string;
};

export type BillDetailsGroupByMonth = {
  month: string;
  billDetails: BillInformationDetail[];
  totalPrice: number;
}[];

export const PaymentsHistory2: FC<PaymentsHistory2Props> = ({
  billDetailsGroupByMonth,
  onClickBillDetail,
}) => {
  const { customerInfo } = store.state;

  const hasBillBeenPaid = (detail: BillInformationDetail) => {
    return (
      (!detail?.warranty_no && detail?.m_prepaid_id) ||
      detail?.no_pay_flg === noPayFlg.BEFORE_PAYMENT
    );
  };

  return (
    <PageTemplate
      pageName="PaymentsHistory2"
      title="支払履歴"
      showNavBack={true}
    >
      <CreditCardPayInfo />
      <div className="margin-bottom" />
      <OfficeFeeInfo />
      <div className="margin-bottom" />

      {billDetailsGroupByMonth.length ? (
        billDetailsGroupByMonth.map((item, index) => (
          <div key={index}>
            <RoundSheet>
              <div className="list">
                <ul>
                  <li className="accordion-item">
                    <a className="item-content item-link" href="#">
                      <div className="item-inner">
                        <div className="item-title item-title01">
                          {item.month}請求分
                        </div>
                        {/* <div className="item-title item-title02 u-font-bold">
                          {item.totalPrice.toLocaleString()} 円
                        </div> */}
                      </div>
                    </a>
                    <div className="accordion-item-content">
                      <div className="list-items no-border">
                        <div className="list-item">
                          <div className="affiliate-name">
                            {customerInfo.affiliate_name}
                          </div>
                          <div className="invoice-registration-number">
                            登録番号：
                            {customerInfo.affiliate_invoice_registration_number ||
                              ''}
                          </div>
                        </div>
                      </div>

                      {item.billDetails.map((detail, index2) => (
                        <div
                          className="list-items"
                          key={index2}
                          onClick={() =>
                            hasBillBeenPaid(detail)
                              ? null
                              : onClickBillDetail(detail)
                          }
                        >
                          <div className="list-item">
                            <div className="list-item-left">
                              <span
                                className="pay-subject"
                                style={{ color: '#232323' }}
                              >
                                {detail.pay_subject}
                                {!detail?.warranty_no && detail?.m_prepaid_id
                                  ? '（消費税対象外）'
                                  : '（消費税10%込）'}
                              </span>
                              <span>{detail.memo}</span>
                              <span className="billing-date">
                                {hyphenToYear(detail.billing_date || '', false)}
                              </span>
                            </div>
                            <div
                              className={`list-item-right ${
                                hasBillBeenPaid(detail) ? 'mr-32' : ''
                              }`}
                            >
                              <div>
                                <span className="bill-price">
                                  {detail.bill_price
                                    ? `${detail.bill_price?.toLocaleString()}円`
                                    : ''}
                                </span>
                              </div>
                              {detail.m_payment_type_id ==
                                paymentTypeId.CREDIT && (
                                <div>
                                  <i className="icon-credit-card" />
                                  <div className="account-info">
                                    {detail?.card_no || ''}
                                  </div>
                                </div>
                              )}
                              {isBankOrPayEasy(
                                detail.m_payment_type_id as PaymentTypeId,
                              ) && (
                                <div>
                                  <i className="icon-bank" />
                                  <div className="account-info">
                                    {createPaymentBankNameText(detail)}
                                  </div>
                                  <div className="account-info">
                                    普通{detail.account_number}
                                  </div>
                                </div>
                              )}
                            </div>
                            {!hasBillBeenPaid(detail) && (
                              <div>
                                <i className="icon icon-right" />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="list-items">
                        <div className="list-item accordion-content-date">
                          発行日付：{formatToJaDate(new Date())}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </RoundSheet>
            <div className="margin-bottom" />
          </div>
        ))
      ) : (
        <RoundSheet>
          <div className="no-item">支払履歴はありません</div>
        </RoundSheet>
      )}
    </PageTemplate>
  );
};
