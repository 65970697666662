import { baseApi } from '@/config/axios';
import {
  ContactApiParams,
  ContactApiResponse,
  FetchContactApiParams,
  FetchContactApiResponse,
  ReadModifyInquiryParams,
  RegisterInqueryDetailParams,
  RegisterInqueryDetailResponse,
  RegisterInquiryEndParams,
  RegisterInquiryEndResponse,
  SendInquiryAboutTelApiParams,
  SendInquiryAboutTelApiResponse,
  SendInquiryApiParams,
  SendInquiryApiResponse,
} from '@/types/api/queryApi';

// 27.問い合わせ
export const contactApi = (
  params: ContactApiParams,
): Promise<ContactApiResponse> => baseApi.post(`v1/customer/inquiry`, params);

// 問い合わせ情報取得
export const fetchInquiryListApi = (
  params: FetchContactApiParams,
): Promise<FetchContactApiResponse> =>
  baseApi.get(`v1/customer/inquiry`, { params });

// 42.問い合わせ関連(ログイン関連)
export const sendInquiryApi = (
  params: SendInquiryApiParams,
): Promise<SendInquiryApiResponse> =>
  baseApi.post(`v1/customer/contacts/about-login`, params);

// 43.電話番号を変更に関する問い合わせ
export const sendInquiryAboutTelApi = (
  params: SendInquiryAboutTelApiParams,
): Promise<SendInquiryAboutTelApiResponse> =>
  baseApi.post(`v1/customer/contacts/about-change-phone-number`, params);

// 問い合わせ詳細登録
export const registerInquiryDetailApi = (
  data: RegisterInqueryDetailParams,
): Promise<RegisterInqueryDetailResponse> =>
  baseApi.post('v1/customer/inquiry-detail', data);

// 問い合わせ完了登録
export const registerInquiryEndApi = (
  data: RegisterInquiryEndParams,
): Promise<RegisterInquiryEndResponse> =>
  baseApi.put('v1/customer/inquiry-end', data);

// 問い合わせ未読/既読更新
export const readModifyInquiryApi = (
  data: ReadModifyInquiryParams,
): Promise<RegisterInquiryEndResponse> =>
  baseApi.put('v1/customer/inquiry-readmodify', data);
