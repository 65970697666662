import { List, ListItem } from 'framework7-react';
import { FC } from 'react';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import './WarrantyGuide.scss';

export const WarrantyGuide: FC = () => {
  return (
    <PageTemplate pageName="WarrantyGuide" title="ご利用方法" showNavBack>
      <div>
        <div className="content-box content">
          <div className="block-item">
            <RoundSheet>
              <p className="sub-title titletop">保証適用の条件</p>
              <p className="u-font-bold text-size">
                保証を受けるためには、提携している工場で修理を行う必要があります。
              </p>
              <p
                style={{ color: '#ff7681' }}
                className="text-indent text-bottom"
              >
                ※提携工場以外で修理を行った場合、保証を適用することはできかねます
              </p>
              <br />
              <p className="sub-title">大まかな流れ</p>
              <p className="u-font-bold text-size">
                提携工場へのご入庫後、点検を行いお見積もりを作成いたします。その後、サポートセンターにて保証適用可否審査をさせていただきます。
                <br />
                適用可能と判断された場合に、修理費用を負担いたします。
              </p>
              <p
                style={{ color: 'gray' }}
                className="text-indent text-bottom text"
              >
                ※詳細な流れもこのページの下部に記載しています
              </p>
              <br />
              <p className="sub-title">提携工場への入庫方法</p>
              <div className="text-size u-font-bold bottom">
                <p className="text-size">
                  提携工場へは、下記の２つの方法で入庫できます。
                </p>
                <ul className="u-margin-top-sm u-padding-left-sm u-padding-right-sm">
                  <li>アプリの整備予約機能を利用して入庫する</li>
                  <li>サポートセンタにーに問い合わせをして入庫する</li>
                </ul>
              </div>
            </RoundSheet>

            <p className="title">入庫までの手順</p>
            <div className="round-block">
              <RoundSheet>
                <div className="card-header u-font-bold">
                  サポートセンターに問い合わせをする場合
                </div>
                <div
                  className="card-content card-padding text-size "
                  style={{ lineHeight: '2rem' }}
                >
                  アプリメニューの「車のトラブル」 &gt; サポートセンターに電話
                  &gt;
                  「車の不具合について」の手順で操作をし、電話をかけてください。
                  <p className="text">
                    サポートセンターの担当者が、不調の内容を聞かせていただき、必要に応じて点検入庫の案内をさせていただきます。
                  </p>
                  <p className="text">
                    案内のあった整備工場へと入庫してください。
                  </p>
                </div>
              </RoundSheet>
            </div>

            <p className="title">入庫後の流れ</p>
            <div className="round-block">
              <RoundSheet>
                <div className="card-header u-font-bold">
                  １.点検・故障内容の確認
                </div>
                <div
                  className="card-content card-padding text-size"
                  style={{ lineHeight: '2rem' }}
                >
                  入庫先工場にて車両状態・故障内容を確認し、修理が必要な場合は、お見積りを作成します。
                  <p className="text">
                    ※作成したお見積もりは、修理工場からサポートセンターに共有されます。
                  </p>
                </div>
              </RoundSheet>
            </div>

            <div className="round-block">
              <RoundSheet>
                <div className="card-header u-font-bold">
                  ２.保証適用可否審査・結果のご連絡
                </div>
                <div
                  className="card-content card-padding text-size "
                  style={{ lineHeight: '2rem' }}
                >
                  工場で作成されましたお見積もり内容をもとに、保証適用可否の判定をさせていただきます。
                  <br />
                  判定結果がでましたら、ご連絡します。
                  <br />
                  <p
                    className="u-margin-top-xs text-indent text-size "
                    style={{ color: '#ff7681' }}
                  >
                    ※判定結果のご連絡をまたず修理を進められますと保証適用不可となります。お気を付けください。
                  </p>
                </div>
              </RoundSheet>
            </div>
            <div className="round-block">
              <RoundSheet>
                <div className="card-header u-font-bold">
                  ３.免責金のお支払い
                </div>
                <div
                  className="card-content card-padding text-size "
                  style={{ lineHeight: '2rem' }}
                >
                  保証のご利用が可能となった場合、保証条件の免責金をお支払いください。
                  <br />
                  <p className="u-margin-top-xs text-indent">
                    ※ご加入の保証プランなどにより、免責金が発生しないこともございます。
                    <br />
                  </p>
                  <p className="u-margin-top-xs text-indent bottom">
                    ※保証が適用できない場合も、そのまま修理を進めることが可能です。
                  </p>
                  <div
                    className="u-margin-top-s u-margin-bottom-0"
                    style={{ borderTop: '1px solid #C9C9C9' }}
                  >
                    <List>
                      <ListItem
                        title="ご加入の保証プランを確認する"
                        link={paths.warrantyDetail}
                      />
                    </List>
                  </div>
                </div>
              </RoundSheet>
            </div>
            <div className="round-block">
              <RoundSheet>
                <div className="card-header u-font-bold">４.故障個所の修理</div>
                <div
                  className="card-content card-padding text-size"
                  style={{ lineHeight: '2rem' }}
                >
                  免責金のご入金が確認できましたら、部品発注・修理着工へと進みます。
                  判定結果がでましたら、ご連絡します。
                  <br />
                </div>
              </RoundSheet>
            </div>
            <div className="round-block">
              <RoundSheet>
                <div className="card-header u-font-bold">５.納車</div>
                <div
                  className="card-content card-padding text-size"
                  style={{ lineHeight: '2rem' }}
                >
                  修理が完了しましたら、連絡をいたします。
                  <br />
                  その際、返車日時を決めさせていただきますので、お約束の日時でお車の引き取りをお願いいたします。
                </div>
              </RoundSheet>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};
