import { App as F7App, View } from 'framework7-react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { isIPhone } from './config/device';
import { paths } from './config/paths';
import { store } from './config/store';
import { endpoint } from './consts/api';
import { ThemeId } from './consts/shop';
import { routes } from './routes';
import 'react-circular-progressbar/dist/styles.css';
import '@/utils/checkECall';

const f7params = {
  routes,
  name: 'Maikuru',
  theme: 'ios',
  view: {
    browserHistory: true,
    browserHistorySeparator: '',
    unloadTabContent: true,
    iosSwipeBack: false,
    preloadPreviousPage: false,
  },
  navbar: {
    mdCenterTitle: true,
  },
  statusbar: {
    enabled: false,
    iosOverlaysWebView: false,
  },
  dialog: {
    title: 'My App',
    buttonOk: 'はい',
    buttonCancel: 'いいえ',
  },
  iosTranslucentBars: false,
};

const theme = Number(new URLSearchParams(location.search).get('theme')) as
  | ThemeId
  | 0;

export const App = () => {
  useEffect(() => {
    if (window && isIPhone) {
      window.document
        .querySelector('body')
        ?.setAttribute('style', 'min-height: 100vh');
    }
    if (window && endpoint.GA_TRACKING_ID) {
      ReactGA.initialize(endpoint.GA_TRACKING_ID);
    }
  }, []);
  return (
    <F7App {...f7params} store={store}>
      <View
        main
        url={paths.root}
        onViewInit={async () => {
          if (theme) {
            await store.dispatch('setGlobalThemeId', { themeId: theme });
          }
        }}
      />
    </F7App>
  );
};
