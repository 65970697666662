import { Popup } from 'framework7-react';
import React, { useMemo, useState } from 'react';
import { ReactComponent as FileIcon } from '@/assets/images/menu/icon-file.svg';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { DOCUMENT_TYPES } from '@/consts/news';

interface UseDocumentDetailItemProps {
  requiredDocumentType?: number;
}

export default function useDocumentDetailItem({
  requiredDocumentType,
}: UseDocumentDetailItemProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const item =
    itemsContent[requiredDocumentType || DOCUMENT_TYPES.SEAL_CERTIFICATE];

  const PopupDocumentDetailItem = useMemo(() => {
    return (
      <Popup
        id="DocumentDetailItemPopup"
        opened={isOpen}
        closeByBackdropClick
        onPopupClose={() => setIsOpen(false)}
      >
        <h3 className="popup-title">
          <FileIcon />
          {item.title}
        </h3>
        <p className="popup-content">{item.content}</p>
        <Button
          className="popup-cancel"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          閉じる
        </Button>
      </Popup>
    );
  }, [isOpen, item]);

  return {
    isOpen,
    setIsOpen,
    PopupDocumentDetailItem,
  };
}

const itemsContent = {
  [DOCUMENT_TYPES.SEAL_CERTIFICATE]: {
    title: '印鑑証明とは',
    content:
      '登録された印鑑が本物であることを証明するものです。\n\n自動車は「資産」という扱いとなるため、資産を登録する際に必要となる「実印」が、 本当に本人のものであるということを公的に証明する重要な書類です。\n\n取得方法などの詳細につきましては、お住まいの地方自治体にご確認ください。\n\n',
  },
  [DOCUMENT_TYPES.PARKING_CERTIFICATE]: {
    title: '車庫証明とは',
    content:
      '車庫法およびその施行規則で定められたもので、自動車の保管場所が確保されていることを証明する書類です。\n\nお住まいのお近くの警察署で「保管場所証明申請手続き」を行い、入手することができます。\n\n',
  },
  [DOCUMENT_TYPES.POA]: {
    title: '委任状とは',
    content:
      'ある人が特定の事務処理を他の人に委託することを記載した文書です。\n\n国土交通省のサイトでダウンロードできます。\n\n',
  },
  [DOCUMENT_TYPES.STORAGE_LOCATION_CERTIFICATE_APPLICATION]: {
    title: '自動車保管場所証明申請書とは',
    content:
      '「車庫証明」とも呼ばれているものです。\n\n車庫法およびその施行規則で定められたもので、自動車の保管場所が確保されていることを証明する書類です。\n\n最寄りの警察署で「保管場所証明申請手続き」を行い、入手することができます。\n\n',
  },
  [DOCUMENT_TYPES.STORAGE_LOCATION_MAP]: {
    title: '保管場所の所在図・配置図とは',
    content:
      '自宅から駐車場の位置関係、駐車場及びその付近の位置関係を示す書類です。\n\n最寄りの警察署で入手、または都道府県警のホームページからダウンロードすることができます。\n\n',
  },
  [DOCUMENT_TYPES.STORAGE_LOCATION_TITLE_DOCUMENT]: {
    title: '保管場所の仕様権原を疎明する書類',
    content:
      '保管場所の使用権原を疎明する書類とは、車の保有者に、保管場所を使用する権原があること（駐車場の所有権もしくは賃借権があること）を証明する書類です。\n\n最寄りの警察署で入手、または都道府県警のホームページからダウンロードすることができます。\n\n',
  },
  [DOCUMENT_TYPES.TRANSFER_CERTIFICATE]: {
    title: '譲渡証明書',
    content:
      '旧所有者から新所有者へ車を譲渡することを証明する書類です。\n\n中古車の購入や、車の個人売買などで名義変更をする際に必要です。\n\n本書類は車検証上の所有者の実印で押印する必要があります。\n\n運輸支局の窓口または国土交通省の公式サイトから入手できます。\n\n',
  },
  [DOCUMENT_TYPES.OVERPAYMENT_DOCUMENTS]: {
    title: '過誤納金についての書類',
    content:
      '申告などで既に納付された税金が減額になり多く納めたことになった場合や、誤って多く納付をしてしまった場合に発行される通知書などです。\n\n',
  },
  [DOCUMENT_TYPES.AUTOMOBILE_TEXT_DEBT_TRANSFER_CONCERNING_DOCUMENTS]: {
    title: '自動車税の債務譲渡に関する書類',
    content:
      '自動車税（種別割）の過誤納金の還付請求権を譲渡する場合に提出する書類です。納税通知書に記載されている各自治体の管轄税事務所が受付窓口です、\n詳細は各自治体へご確認ください。\n\n',
  },
};
