import { Page } from 'framework7-react';
import { FC } from 'react';

export const StopReservingsConfirmPage: FC = () => {
  return (
    <Page name="StopReservingsConfirm">
      <h3>積立プラン停止確認</h3>
    </Page>
  );
};
