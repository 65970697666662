import { Router } from 'framework7/types';
import { FC } from 'react';
import {
  checkWarrantyDocument,
  findRelevantNextWarranty,
  isWarrantiesDuplicatedCheck,
  makeDisplayWarrantyItemUnderChanging,
  makeDisplayWarrantyItemUnderContract,
} from '../WarrantyManagementHelper';
import { WarrantyUnderChanging } from '../WarrantyUnderChanging/WarrantyUnderChanging';
import { WarrantyUnderContract } from '../WarrantyUnderContract/WarrantyUnderContract';
import styles from './WarrantyList.module.css';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { paths } from '@/config/paths';
import { WARRANTY_STATUS } from '@/consts/warranty';
import {
  NextWarranty,
  NextWarrantyAppendWarranty,
  WarrantiesAppendWarranty,
  Warranty,
} from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';
import { handleClickLink } from '@/utils/utils';
import { isCancellationInProcess } from '@/utils/warranty';

interface WarrantyListProps {
  f7router: Router.Router;
  warranties: Warranty[];
  nextWarranties: NextWarranty[];
}

export const WarrantyList: FC<WarrantyListProps> = ({
  f7router,
  warranties,
  nextWarranties,
}) => {
  const isWarrantiesDuplicated = isWarrantiesDuplicatedCheck(warranties);
  return (
    <>
      {isWarrantiesDuplicated && (
        <InfoCard
          className={styles['danger-infoCard']}
          bgColor="danger"
          text="保証期間が重複しています"
          textSub="カスタマーセンターにご相談ください"
          iconType="exclamationmark_circle"
          isIconRight={true}
          url={paths.warrantyDuplicated}
        />
      )}
      {warranties?.map((warranty: Warranty, index: number) => {
        const warrantyNo = warranty.warranty_no;
        const nextWarranty = findRelevantNextWarranty(warranty, nextWarranties);
        const appendWarranty = warranty?.append_warranties?.[0];
        const nextAppendWarranty = nextWarranty?.append_warranties?.[0];
        const isAutoRenew = !!warranty.auto_continuous_flg;
        const { isWarrantiesMissDocument, isWarrantyTypeFour } =
          checkWarrantyDocument(warranty);

        const handleClickOpenLink = async () => {
          await storeDispatch(
            'setCurrentWarranty',
            'currentWarranty',
            warranty,
          );
          await storeDispatch('setNextWarranty', 'nextWarranty', nextWarranty);
        };

        const handleClickOpenDetail = async () => {
          await handleClickOpenLink();
          handleClickLink(
            isWarrantyTypeFour
              ? paths.tradeWarrantyDetail
              : paths.warrantyDetail,
            f7router,
          );
        };

        const handleClickOpenCancel = async () => {
          await handleClickOpenLink();
          handleClickLink(paths.cancelWarrantyDetail(warrantyNo), f7router);
        };

        const handleClickOpenUpdate = async () => {
          await handleClickOpenLink();
          handleClickLink(paths.updateWarrantyDetail(warrantyNo), f7router);
        };

        return (
          <WarrantyListItem
            key={index}
            warrantyNo={warrantyNo}
            isAutoRenew={isAutoRenew}
            nextBaseWarrantyId={nextWarranty?.t_base_waranty_id}
            appendWarranty={appendWarranty}
            nextAppendWarranty={nextAppendWarranty}
            isWarrantiesDuplicated={isWarrantiesDuplicated}
            isWarrantiesMissDocument={isWarrantiesMissDocument}
            isWarrantyTypeFour={isWarrantyTypeFour}
            handleClickOpenDetail={handleClickOpenDetail}
            handleClickOpenCancel={handleClickOpenCancel}
            handleClickOpenUpdate={handleClickOpenUpdate}
          />
        );
      })}
    </>
  );
};

interface WarrantyListItemProps {
  warrantyNo: string;
  nextBaseWarrantyId: number | undefined;
  isAutoRenew: boolean;
  appendWarranty: WarrantiesAppendWarranty | undefined;
  nextAppendWarranty: NextWarrantyAppendWarranty | undefined;
  isWarrantiesDuplicated: boolean;
  isWarrantiesMissDocument?: boolean;
  isWarrantyTypeFour?: boolean;
  handleClickOpenDetail: () => void;
  handleClickOpenCancel: () => void;
  handleClickOpenUpdate: () => void;
}

const WarrantyListItem: FC<WarrantyListItemProps> = ({
  warrantyNo,
  nextBaseWarrantyId,
  isAutoRenew,
  appendWarranty,
  nextAppendWarranty,
  isWarrantiesDuplicated,
  isWarrantiesMissDocument,
  isWarrantyTypeFour,
  handleClickOpenDetail,
  handleClickOpenCancel,
  handleClickOpenUpdate,
}) => {
  const applyStatus = appendWarranty?.apply_status;
  const warranty = makeDisplayWarrantyItemUnderContract(appendWarranty);
  const warrantyStatus = appendWarranty?.warranty_status;
  const isShowNextWarranty = warrantyStatus === WARRANTY_STATUS.VALID;
  const isCancelled = isCancellationInProcess(applyStatus, warrantyStatus);

  return (
    <>
      <WarrantyUnderContract
        isWarrantiesDuplicated={isWarrantiesDuplicated}
        isCancelled={isCancelled}
        isUnderChanging={!!nextAppendWarranty}
        warranty={warranty}
        warrantyStatus={warrantyStatus}
        isAutoRenew={isAutoRenew}
        isWarrantiesMissDocument={isWarrantiesMissDocument}
        isWarrantyTypeFour={isWarrantyTypeFour}
        handleClickOpenDetail={handleClickOpenDetail}
        handleClickOpenCancel={handleClickOpenCancel}
      />
      {isShowNextWarranty &&
        nextAppendWarranty &&
        !isCancelled &&
        warranty.isWarrantyStarted && (
          // 次期保証が存在する、または解約手続き済みのとき表示
          <>
            <WarrantyUnderChanging
              warrantyNo={warrantyNo}
              warranty={makeDisplayWarrantyItemUnderChanging(
                nextAppendWarranty,
              )}
              handleClickOpenUpdate={handleClickOpenUpdate}
            />
          </>
        )}
    </>
  );
};
