import { FC } from 'react';
import style from './LinkDriveSetupStep2Style.module.css';
import ExclamationMark from '@/assets/images/icon-exclamationmark_circle.svg';

interface Props {}

export const StepInterupted: FC<Props> = () => {
  return (
    <>
      <h2 className={style['head-error']}>
        <img src={ExclamationMark} />
        セットアップが中断されました
      </h2>
      <p className={style['status-paragraph']}>
        セットアップが中断されたので、
        <br />
        コネクターを差し込み直してください。
        <br />
        <br />
        自動的にセットアップが再開されるので、
        <br />
        お待ちください。
      </p>
    </>
  );
};
