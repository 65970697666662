// Consulting Hisotry status

export const CONSULTING_HISTORY_STATUS = {
  IN_PROGRESS: 1,
  COMPLETED: 2,
};

export const CONSULTING_FROM_TYPE = {
  CUSTOMER: 1,
  BPO: 2,
};
