import * as yup from 'yup';

const classNoRegex = /^[ACFHKLMPXY0-9]*$/;
const registryNoRegex = /^[0-9]*$/;
const CLASS_NO_MAX_LENGTH = 3;
const REGISTRY_NO_MAX_LENGTH = 4;
const HIRAGANA_MAX_LENGTH = 1;

export const updateNumberSchema = yup.object().shape({
  transportBu: yup.string(),
  classNo: yup
    .string()
    .required('分類番号は必須です')
    .max(
      CLASS_NO_MAX_LENGTH,
      `英数字最大${CLASS_NO_MAX_LENGTH}桁まで入力してください`,
    )
    .matches(classNoRegex, {
      message: '使用できない文字が含まれています',
    }),
  hiragana: yup
    .string()
    .required('ひらがなは必須です')
    .matches(/^([あ-ん])+$/, '全角ひらがなで入力してください')
    .max(
      HIRAGANA_MAX_LENGTH,
      `全角ひらがな最大${HIRAGANA_MAX_LENGTH}桁まで入力してください`,
    ),
  registryNo: yup
    .string()
    .required('ナンバーは必須です')
    .max(
      REGISTRY_NO_MAX_LENGTH,
      `数字最大${REGISTRY_NO_MAX_LENGTH}桁まで入力してください`,
    )
    .matches(registryNoRegex, {
      message: `使用できない文字が含まれています`,
    }),
});
