import { ChangeEventHandler, FC, ReactNode } from 'react';
import style from './Radio.module.css';

interface RadioProps {
  id: string;
  name: string;
  className?: string;
  checked?: boolean;
  children?: ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const Radio: FC<RadioProps> = ({
  id,
  name,
  className = '',
  checked = false,
  children,
  onChange,
}) => {
  // const { register, setValue, trigger } = useFormContext();
  return (
    <label htmlFor={id} className={`${style['label']} ${className}`}>
      <div className={style['radio-container']}>
        <input
          id={id}
          name={name}
          className={style['radio-input']}
          type="radio"
          onChange={onChange}
          checked={checked}
        />
        <span className={style['custom-radio']} />
      </div>
      {children}
    </label>
  );
};
