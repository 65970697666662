import {
  GoogleMap,
  GoogleMapProps,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { FC, useState } from 'react';
import styles from './MapCarStyle.module.scss';
import arrivalPointMark from '@/assets/images/menu/map_start_mark.svg';
import { apiKey } from '@/consts/apiKey';
import { GOOGLE_MAP_FALLBACK_LAT_LNG } from '@/consts/googleMap';
import { hyphenToTime } from '@/utils/date';

interface MapCardProps {
  mapData?: {
    latitude?: number;
    longitude?: number;
    location?: string;
    time?: string;
    color: string;
    weight: number;
  };
  handleClickMap: () => void;
}

export const MapCar: FC<MapCardProps> = ({ mapData, handleClickMap }) => {
  const [center, setCenter] = useState({
    lat: mapData?.latitude ?? GOOGLE_MAP_FALLBACK_LAT_LNG.lat,
    lng: mapData?.longitude ?? GOOGLE_MAP_FALLBACK_LAT_LNG.lng,
  });
  const mapOptions: GoogleMapProps['options'] = {
    disableDefaultUI: true,
  };

  const mapStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '1.6rem',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey.GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={styles['map-card']} onClick={handleClickMap}>
      <div className={styles['map-box']} style={{ width: '28%' }}>
        <GoogleMap
          mapContainerStyle={mapStyle}
          center={center}
          options={mapOptions}
          zoom={15}
        >
          <Marker position={center} icon={arrivalPointMark} />
        </GoogleMap>
      </div>
      <div className={styles['map-data']} style={{ width: '72%' }}>
        <p>{mapData?.location}</p>
        <p>{hyphenToTime(mapData?.time)}</p>
        <p>タップしてマップアプリで開く</p>
      </div>
    </div>
  );
};
