import { Checkbox } from 'framework7-react';
import { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ConfirmInput } from '../PromptConfirmDetail';
import { ConfirmTerm } from './ConfirmTerm';
import style from './PromptConfirmStyle.module.css';
import { termType } from '@/consts/terms';

const RoadServiceTerm: FC = () => {
  const [popupOpened, setPopupOpened] = useState(false);
  return (
    <div>
      <ConfirmTerm
        type={termType.ROAD_SERVICE}
        opened={popupOpened}
        title="ロードサービス規約"
        onPopupClosed={() => setPopupOpened(false)}
      />
      {
        'ロードサービスは「ロードサービス規約」に定める内容となります。\n特殊作業や無料搬送距離超過の場合、有料作業となる場合がございます。\n詳細は、「'
      }
      <a className={style['text-link']} onClick={() => setPopupOpened(true)}>
        ロードサービス規約
      </a>
      」をご一読ください。
    </div>
  );
};

const CHECKBOX_GROUPS = [
  {
    label: '保証開始準備',
    checkbox: [
      '保証プランの保証内容や範囲について、マイくるアプリの「保証管理」よりご確認いただくことができます。',
    ],
  },
  {
    label: '保証継続契約の注意点',
    checkbox: [
      '原則といたしまして、保証期間中の解約による返戻金はございません。',
      'お客様による不正利用や虚偽申告が判明した場合、本保証サービスは失効いたします。',
      '本保証は自動更新型の商品です。\n契約を継続しない場合は満期月の３か月前1日～2か月前末日の期間にマイくるにて保証解約の手続きが必要です。\nそのお手続きが無い場合は、契約時と同プラン、同お支払方法にて自動的に契約は継続されます。',
    ],
  },
  {
    label: '保証利用時の注意点',
    checkbox: [
      'お車に故障が発生した場合、速やかにサポートセンター等に連絡をしてください。\n保証審査前に修理着工や部品発注を行った場合は保証の適用外となりますのでご注意ください。\n故障の発生が保証期間内であっても、保証会社等への連絡が保証期間を過ぎていた場合は、本サービスの適用外となります。',
      '保証修理に際し、中古部品の使用を最優先とし、中古部品の調達ができない場合は、リビルト部品・社外新品部品・純正新品部品の優先順位での部品交換となります。',
      '保証修理が適用される修理範囲は、アプリの「保証管理」内に記載の内容を基準とし、委託会社が定める審査方針に基づき、本サービスの適用対象となるか確認し判断します。',
      <RoadServiceTerm key={'road-service'} />,
    ],
  },
  {
    label: '保証料お支払の注意点',
    checkbox: [
      '納車翌月末までに決済情報のご登録を行ってください。',
      '保証料の請求開始時期と回数について、店舗スタッフからのご説明の通りです。\nまた、マイくるアプリ「保証管理」内の「次回請求予定」よりご確認いただくことができます。',
      '次年度の保証料のお支払は、12回の分割払いとなります。\n更新月の前月よりご請求が開始いたします。',
      '口座振替のお引落名義は「JCクルマアフター」で毎月27日（銀行休業日の場合は翌営業日）のお引落です。\nクレジットカードの明細の表記は「コールセンター」名義です。お引落日はお客様とクレジットカード会社様とのご契約に準じます。',
      '保証料のお引落しが履行されなかった場合、翌月に前月分が加算され引落が行われます。その間は保証修理が受けられませんのでご注意ください。',
      '保証料のお引落が2か月連続で履行されなかった場合、本保証サービスは失効いたしますのでご注意ください。\n但し保証が失効した際においても、本契約上、利用中の保証サービスの支払の責務は残ります。',
    ],
  },
];

export const ConfirmWarranty: FC = () => {
  const { watch, setValue, trigger } = useFormContext<ConfirmInput>();
  const [popupOpened, setPopupOpened] = useState(false);

  const currentCheckbox = watch('currentCheckbox');

  const handleChange = (event: BaseSyntheticEvent) => {
    if (event.target.checked) {
      setValue('currentCheckbox', currentCheckbox + 1, {
        shouldValidate: true,
      });
    } else {
      setValue('currentCheckbox', currentCheckbox - 1, {
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    const checkboxCount = CHECKBOX_GROUPS.reduce(
      (current, item) => current + item.checkbox.length,
      0,
    );
    setValue('targetedCheckox', checkboxCount);
    setValue('currentCheckbox', 0, {
      shouldValidate: true,
    });
  }, [setValue, trigger]);

  return (
    <div className={style['container']}>
      <ConfirmTerm
        type={termType.WARRANTY}
        opened={popupOpened}
        title="保証規約"
        onPopupClosed={() => setPopupOpened(false)}
      />
      <div className={style['label-wrapper']}>
        <p className={style['label']}>自動更新型保証　重要事項説明書</p>
        <span className={style['label-tip']}>必須</span>
      </div>
      <p className={style['description']}>
        お客様に安心して保証サービスをご利用いただく為に、重要な項目を抜粋し記載しております。下記事項をご確認いただくとともに、詳しくは「
        <span
          className={style['text-link']}
          onClick={() => setPopupOpened(true)}
        >
          保証規約
        </span>
        」をご一読のうえ、保証サービスのご利用を頂きます様、お願い申し上げます。
      </p>
      {CHECKBOX_GROUPS.map(({ label, checkbox }, index) => (
        <div key={index} className={style['checkbox-group']}>
          <h4>{label}</h4>
          {checkbox.map((checkboxLabel, checboxIndex) => (
            <label key={checboxIndex} className={style['checkbox-item']}>
              <Checkbox
                value={`${index}-${checboxIndex}`}
                onChange={handleChange}
              />
              {checkboxLabel}
            </label>
          ))}
        </div>
      ))}
    </div>
  );
};
