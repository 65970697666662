import { f7, useStore } from 'framework7-react';
import { useEffect, useState } from 'react';
import style from './TodoListStyle.module.css';
import { fetchTodoDetailApi } from '@/api/todoListApi';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { IconList } from '@/components/uiParts/List/IconList/IconList';
import { isApp } from '@/config/device';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { TASK_ID } from '@/consts/news';
import { url } from '@/consts/url';
import { useFetchTodoList } from '@/hooks/api/news/useFetchTodoList';
import { useToast } from '@/hooks/useToast';
import { Prepaid, Warranty } from '@/types/api/customerApi';
import {
  DeliveryPreferredDate,
  DocumentsSend,
  Task,
} from '@/types/api/todoListApi';
import { formatDateISO, formatToJaDate } from '@/utils/date';

export type TaskJoin = Task & DocumentsSend & DeliveryPreferredDate;

export const TodoList = () => {
  const { openToast } = useToast();
  const { permission } = store.state;
  const { m_customer_id } = store.state.authInfo;
  const { t_stock_car_id } = store.state.carItemInfo || {};
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Prepaid
    | undefined;
  const warranties = useStore(store, 'getWarranties') as Warranty[];
  const { data: todoList } = useFetchTodoList({
    m_customer_id: m_customer_id,
    t_stock_car_id: t_stock_car_id,
  });
  const [tasks, setTasks] = useState<TaskJoin[]>([]);

  const handleClickPushSettingCard = () => {
    if (isApp) {
      location.href = url.NATIVE_BASE_URL + '/on_go_app_setting';
    }
  };

  const getDetailForList = async () => {
    if (todoList?.tasks) {
      const listTaskDetail = [];
      f7.preloader.show();
      let errorMessage = '';

      for (let task of todoList.tasks) {
        const res = await fetchTodoDetailApi(task);
        if (res?.data?.error_message) errorMessage = res?.data?.error_message;

        if (res && res?.data?.result?.data) {
          listTaskDetail.push({
            ...res.data.result.data,
            ...task,
          } as TaskJoin);
        }
      }

      setTasks(listTaskDetail);
      f7.preloader.hide();
      errorMessage && openToast(errorMessage, 'toast-failed');
    }
  };

  const handleClickToDetail = (item: { t_wf_instance_task_id: number }) => {
    f7.view.main.router.navigate(
      paths.todoDetail(item.t_wf_instance_task_id.toString()),
      {
        props: { item: item },
      },
    );
  };

  useEffect(() => {
    getDetailForList();
  }, [todoList]);

  const renderTitle = (task_id: string) => {
    switch (task_id) {
      case TASK_ID.DELIVERY_PREFERRED_DATE:
        return 'ご納車のご希望日時をお知らせください';
      case TASK_ID.DOCUMENTS_SEND:
        return '必要な書類の送付をお願いします';
      case TASK_ID.PAYMENT_COMPLETE:
        return 'ご入金をお願いします';
      case TASK_ID.DELIVERY_FOR_CUSTOMER_BY_CUSTOMER:
        if (!prepaid && !warranties.length) return '納車確認';
        return '重要事項の確認';
      case TASK_ID.CAR_CHECK_DELIVERY:
      case TASK_ID.CAR_CHECK_01_MAINTE_CAR_INSPECT:
      case TASK_ID.CAR_CHECK_02_MAINTE_CAR_INSPECT:
        return '納車時チェックを行なってください。';
      default:
        return '';
    }
  };

  return (
    <div>
      {isApp && !permission.pushPermission && (
        <IconCard
          color="gray"
          headingFontSize="14px"
          iconFontSize="3.6rem"
          iconColor="#1e55e6"
          iconType="bulb"
          isTransition
          heading={'通知の設定を行うと、大事なお知らせの見逃しがなくなります'}
          onClick={handleClickPushSettingCard}
        />
      )}
      <ul className={style['list-wrap']}>
        {!tasks || tasks.length === 0 ? (
          <p className="u-text-center">
            現在、該当の「予定/やること」はございません
          </p>
        ) : (
          tasks.map((item, idx) => (
            <div
              className={style['list-item']}
              onClick={() => handleClickToDetail(item)}
              key={item.t_wf_instance_task_id}
            >
              <IconList
                key={idx}
                iconType={'confirm-notification'}
                iconColor={'#FFBF17'}
                text={renderTitle(item.task_id)}
                date={formatToJaDate(new Date(formatDateISO(item.end_date)))}
              />
            </div>
          ))
        )}
      </ul>
    </div>
  );
};
