import { Router } from 'framework7/types';
import React, { FC, useState } from 'react';
import { LinkDriveCalibrationConfirm } from '../../LinkDriveCalibrationConfirm/LinkDriveCalibrationConfirm/LinkDriveCalibrationConfirm';
import { CalibrationProcessBar } from '../CalibrationProcessBar/CalibrationProcessBar';
import style from './LinkDriveCalibrationTroubleStyle.module.css';
import { ReactComponent as ExclaminationIcon } from '@/assets/images/icon-exclamationmark_circle.svg';
import { Button } from '@/components/uiParts/Button';

interface LinkDriveCalibrationTroubleProps {
  handleClickDriveRestart: () => void;
  handleClickCalibrationStop: () => void;
  f7router: Router.Router;
}

export const LinkDriveCalibrationTrouble: FC<
  LinkDriveCalibrationTroubleProps
> = ({ handleClickDriveRestart, handleClickCalibrationStop, f7router }) => {
  const [popupOpened, setPopupOpened] = useState(false);
  const handleClickCalibrationInterrupted = () => {
    handleClickCalibrationStop();
    setPopupOpened(true);
  };

  return (
    <div className={style['trouble-container']}>
      <CalibrationProcessBar step={2} />
      <p>
        キャリブレーションが中断されました。
        <br />
        再度キャリブレーションを実行する場合は、
        <br />
        キャリブレーションを再開してください。
      </p>
      <div className={style['icon']}>
        <ExclaminationIcon width={120} height={120} />
      </div>
      <h3>設定失敗</h3>
      <div className={style['button-group']}>
        <Button text="走行再開" handleClickAction={handleClickDriveRestart} />
        <Button
          style={{ color: '#232323', backgroundColor: '#efefef' }}
          text="中止する"
          handleClickAction={handleClickCalibrationInterrupted}
        />
      </div>

      <LinkDriveCalibrationConfirm f7router={f7router} />
    </div>
  );
};
