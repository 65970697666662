import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { getPaymentListApi } from '@/api/payment';
import { useToast } from '@/hooks/useToast';
import { storeDispatch } from '@/utils/store';

export const usePaymentList = (isFetchedInit?: boolean) => {
  const [error, setError] = useState<AxiosError>();
  const [isFetched, setIsFetch] = useState(isFetchedInit || false);
  const [isFetching, setIsFetching] = useState(false);

  const { openToast } = useToast();

  const updatePaymentList = async (mCustomerId: number) => {
    f7.preloader.show();
    try {
      setIsFetching(true);
      const res = await getPaymentListApi({ m_customer_id: mCustomerId });
      if (res.success) {
        storeDispatch('setPaymentMethodsInfo', 'paymentMethodsInfo', res.data);
        openToast('決済方法を追加しました', 'toast-success', 3000, true, 'top');
      } else {
        f7.dialog.alert(res.error.message, '  決済方法取得エラー', () => {
          f7.dialog.close();
        });
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
      setIsFetch(true);
      setIsFetching(false);
    }
  };

  return {
    updatePaymentList,
    error,
    isFetched,
    isFetching,
  };
};
