type EventType = string;
type EventListener = (e: any) => void;

function on(eventType: EventType, listener: EventListener) {
  document.addEventListener(eventType, listener);
}

function off(eventType: EventType, listener: EventListener) {
  document.removeEventListener(eventType, listener);
}

function trigger(eventType: EventType, data: any) {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

export { on, off, trigger };
