import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useCallback, useEffect, useState } from 'react';
import { PrepaidSettings } from './PrepaidSettings/PrepaidSettings';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { usePaymentList } from '@/hooks/api/payment/usePaymentList';
import { usePrepaidSetting } from '@/hooks/api/prepaid/usePrepaidSetting';
import { useLoadCustomer } from '@/hooks/useLoadCustomer';
import {
  BillInformation,
  Customer,
  PaymentMethod,
  Prepaid,
} from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';

type PrepaidSettingsPageProps = {
  f7router: Router.Router;
  status?: string;
};

export const PrepaidSettingsPage: FC<PrepaidSettingsPageProps> = ({
  f7router,
  status,
}) => {
  const customerInfo = useStore(store, 'customerInfo') as Required<Customer>;
  const settingsPrevPage = store.state.settingsPrevPage;
  const prevPage = settingsPrevPage || window.localStorage.getItem('prevPage');
  const prepaidInfo = useStore(
    store,
    'getFirstAdvancePaymentsInfo',
  ) as Required<Prepaid>;
  const billsInfo = useStore(
    store,
    'getBillInfo',
  ) as Required<BillInformation>[];
  const paymentMethods = useStore(
    store,
    'getPaymentMethodsInfo',
  ) as Required<PaymentMethod>[];
  const [isUpdating, setIsUpdating] = useState(false);
  const { updatePaymentList, isFetched, isFetching } = usePaymentList(false);
  const [currentStatus, setCurrentStatus] = useState(status);
  const { bill, payment, isLoading } = usePrepaidSetting(
    customerInfo,
    billsInfo,
    prepaidInfo,
    paymentMethods,
  );

  const token =
    window.localStorage.getItem('token') === null
      ? undefined
      : window.localStorage.getItem('token');
  const customerId = window.localStorage.getItem('m_customer_id');

  const { isLoggedIn, resetIsLoggedIn } = useLoadCustomer(
    f7.views.main.router,
    token === null ? undefined : token,
    Number(customerId),
    currentStatus,
  );

  // ページを再レンダリングさせたい際に、別画面で呼び出す用の関数
  const handleIsUpdating = () => {
    setIsUpdating(true);
  };

  const afterGmoRedirect = useCallback(() => {
    const mCustomerId = window.localStorage.getItem('m_customer_id');
    const mPrepaidId = window.localStorage.getItem('m_prepaid_id');
    if (currentStatus) {
      storeDispatch('setMenu', 'menu', prevPage);
    }

    switch (currentStatus) {
      // GMOペイメント post → maikuru バックエンド（api.mobicon.net）からのリダイレクト
      case 'gmo_success':
        if (isLoggedIn && !isFetched && !isFetching) {
          setCurrentStatus(undefined);
          resetIsLoggedIn();
          updatePaymentList(Number(mCustomerId));
          f7.views.main.router.navigate(
            paths.registerPayment('prepaid', mPrepaidId || 'undefined'),
            {
              props: {
                isGmoSuccess: true,
              },
            },
          );
        }
        break;
      case 'gmo_cancel':
        if (!isLoggedIn) return;
        setCurrentStatus(undefined);
        resetIsLoggedIn();
        f7.view.main.router.navigate(
          paths.registerPayment('prepaid', mPrepaidId || 'undefined'),
          {
            props: {
              redirectPage: 'prepaid_settings',
              event: handleIsUpdating,
            },
          },
        );
        break;
      default:
        break;
    }
  }, [
    currentStatus,
    isFetched,
    isFetching,
    isLoggedIn,
    resetIsLoggedIn,
    updatePaymentList,
  ]);

  // GMOからのリダイレクト処理
  useEffect(() => {
    afterGmoRedirect();
  }, [afterGmoRedirect]);

  return (
    <PrepaidSettings
      f7router={f7router}
      status={status}
      isLoading={isLoading}
      bill={bill}
      prepaid={prepaidInfo}
      payment={payment}
      handleIsUpdating={handleIsUpdating}
      prevPage={prevPage ?? undefined}
    />
  );
};
