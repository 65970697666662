import { Tab, Tabs } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import style from './NewsStyle.module.css';
import { TabBarText } from './TabBarText/NewsTabBarText';
import { tabsNews } from './Tabs/tabsNews';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { isIPhoneApp } from '@/config/device';
import { store } from '@/config/store';
import { url } from '@/consts/url';
import { useAccordion } from '@/hooks/useAccordion';
import { Car } from '@/types/api/customerApi';
import { storeDispatch } from '@/utils/store';
import { getCarPhoto } from '@/utils/utils';

type NewsPageProps = {
  f7router: Router.Router;
};

export const NewsPage: FC<NewsPageProps> = ({ f7router }) => {
  const [selectedCar, setSelectedCar] = useState<Car | undefined>();
  const carItemInfo = store.state.carItemInfo;
  const ownedCars = () => {
    return store.state.carsInfo.filter((car) =>
      !car.m_car_id
        ? car.t_order_id !== selectedCar?.t_order_id
        : car.m_car_id !== selectedCar?.m_car_id,
    );
  };
  const { isOpen, setIsOpen, accordionRef } = useAccordion();
  const openAccordion = () => {
    setIsOpen((prevState) => !prevState);
  };

  // 画面遷移をネイティブに通知
  if (isIPhoneApp) {
    window.location.href = url.NATIVE_BASE_URL + '/news/';
  }

  const handleClickChangeCar = (car: Required<Car>) => {
    setSelectedCar(car);
    storeDispatch('setCarItemInfo', 'carItemInfo', car);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedCar(carItemInfo);
  }, [carItemInfo]);

  useEffect(() => {
    //parent: set not scroll for fix do not scroll in Safari
    let lockElements = [
      ...document.querySelectorAll('html.ios, html.ios #page-template-content'),
    ] as HTMLElement[];
    lockElements.map((item) => {
      if (item) {
        item.style.overflow = 'hidden';
      }
    });
    //component  unmount
    return () => {
      lockElements.map((item) => {
        item.style.removeProperty('overflow');
      });
    };
  });

  return (
    <PageTemplate pageName="News" title="お知らせ">
      <div className={style.card}>
        <div className={style['accordion-wrap']}>
          <div className={style.accordion} onClick={openAccordion}>
            <div className={style['image-wrap']}>
              <img
                className={style.image}
                src={getCarPhoto(selectedCar as Car)}
              />
              <p className={style['car-name']}>
                {selectedCar?.maker_nm} {selectedCar?.car_nm}
              </p>
            </div>
            <div className={`${style['icon-wrap']} ${isOpen && style.open}`}>
              <i className={`icon-down ${style['icon-down']}`} />
            </div>
          </div>

          {/* アコーディオン内容 */}
          <div ref={accordionRef} className={style['accordion-content-wrap']}>
            <div>
              {ownedCars().map((car) => (
                <div
                  key={car.unique_code}
                  className={style['accordion-content']}
                  onClick={() => handleClickChangeCar(car)}
                >
                  <div className={style['image-wrap']}>
                    <img className={style.image} src={getCarPhoto(car)} />
                    <p className={style['car-name']}>
                      {car.maker_nm} {car.car_nm}
                    </p>
                  </div>
                  {/* TODO: 動的にかえる */}
                  {/* <div className={style.tips}>3</div> */}
                </div>
              ))}
            </div>
          </div>
        </div>

        <TabBarText tabs={tabsNews} />

        <Tabs
          routable
          swipeable
          className={`${isOpen ? style['opened-tabs'] : style.tabs}`}
        >
          {tabsNews.map((item) => (
            <Tab className={style.tab} key={item.id} id={item.id} />
          ))}
        </Tabs>
      </div>
    </PageTemplate>
  );
};
