import { FC } from 'react';
import style from './CautionCardStyle.module.css';

export const CautionCard: FC = () => {
  return (
    <div className={style.card}>
      <div className={style.head}>
        <div className={style['icon-wrap']}>
          <i className={`icon-confirm-notification ${style.icon}`} />
        </div>
        <h3 className={style.heading}>ログイン情報として利用されています</h3>
      </div>
      <p className={style.description}>
        メールアドレスを変更すると、前のメールアドレスはログインに使用できなくなります
      </p>
    </div>
  );
};
