import React, { FC } from 'react';
import style from './IconListStyle.module.css';

interface IconListProps {
  iconType?: string;
  text: string;
  date: string;
  isNew?: boolean;
  iconColor?: string;
}

export const IconList: FC<IconListProps> = ({
  iconType = 'talk-comment',
  text,
  date,
  isNew = false,
  iconColor = '#1E55E6',
}) => {
  return (
    <li className={style.list}>
      <div className={`${style['icon-wrap']} ${isNew && style.tip}`}>
        <i
          style={{ color: iconColor }}
          className={`icon-${iconType} ${style['left-icon']}`}
        />
      </div>
      <div className={style['content-wrap']}>
        <p className={style.text}>{text}</p>
        <p className={style.date}>{date}</p>
      </div>
      <div className={style['icon-wrap']}>
        <i className={`icon-right`} />
      </div>
    </li>
  );
};
