import { FC } from 'react';
import { WatchingMonthlyDriveReport } from './WatchingMonthlyDriveReport/WatchingMonthlyDriveReport';
import { store } from '@/config/store';
import { getAffiliateIdFromThemeId } from '@/utils/shop';

interface WatchingMonthlyDriveReportProps {
  ownerId: string;
  vehicleId: string;
  customerId: string;
  analysisFromDate: string;
}

export const WatchingMonthlyDriveReportPage: FC<
  WatchingMonthlyDriveReportProps
> = ({ ownerId, vehicleId, customerId, analysisFromDate }) => {
  const themeId = store.state.themeId;
  return (
    <WatchingMonthlyDriveReport
      ownerId={ownerId}
      vehicleId={vehicleId}
      customerId={customerId}
      analysisFromDate={analysisFromDate}
      affiliateId={getAffiliateIdFromThemeId(themeId)}
    />
  );
};
