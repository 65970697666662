import React, { FC, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './TradeReservationPage.module.css';
import { FormInput } from './tradeReservationSchema';
import {
  TEL1_MAX_LENGTH,
  TEL2_MAX_LENGTH,
  TEL3_MAX_LENGTH,
} from '@/hooks/api/carWash/useCarWashConditionerStep2';

const TEL1_INDEX = 0;
const TEL2_INDEX = 1;
const TEL3_INDEX = 2;

export const PhoneInput: FC = () => {
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<FormInput>();

  const tel1 = watch('contact_person_phone_number.tel1');
  const tel2 = watch('contact_person_phone_number.tel2');
  const tel3 = watch('contact_person_phone_number.tel3');

  const error = !!errors.contact_person_phone_number;

  return (
    <div className={`${style['input-group']}`}>
      <div
        className={`${style['input-text']} ${
          !!error ? style['input-text-error'] : ''
        }`}
      >
        <label>お電話番号</label>
        <div className={style['input-group-number']}>
          <input
            ref={inputRefs[TEL1_INDEX]}
            placeholder="000"
            type="number"
            maxLength={3}
            value={tel1}
            onChange={(e) => {
              trigger('contact_person_phone_number');
              if (e.target.value.length <= TEL1_MAX_LENGTH)
                setValue('contact_person_phone_number.tel1', e.target.value, {
                  shouldValidate: true,
                  shouldTouch: true,
                });
              if (e.target.value.length === TEL1_MAX_LENGTH) {
                inputRefs[TEL1_INDEX].current?.blur();
                inputRefs[TEL2_INDEX].current?.focus();
              }
            }}
          />
          -
          <input
            ref={inputRefs[TEL2_INDEX]}
            placeholder="1234"
            type="number"
            maxLength={4}
            value={tel2}
            onChange={(e) => {
              trigger('contact_person_phone_number');
              if (e.target.value.length <= TEL2_MAX_LENGTH)
                setValue('contact_person_phone_number.tel2', e.target.value, {
                  shouldValidate: true,
                  shouldTouch: true,
                });
              if (e.target.value.length === TEL2_MAX_LENGTH) {
                inputRefs[TEL2_INDEX].current?.blur();
                inputRefs[TEL3_INDEX].current?.focus();
              }
            }}
          />
          -
          <input
            ref={inputRefs[TEL3_INDEX]}
            placeholder="5678"
            type="number"
            maxLength={4}
            value={tel3}
            onChange={(e) => {
              trigger('contact_person_phone_number');
              if (e.target.value.length <= TEL3_MAX_LENGTH)
                setValue('contact_person_phone_number.tel3', e.target.value, {
                  shouldValidate: true,
                  shouldTouch: true,
                });
              if (e.target.value.length === TEL3_MAX_LENGTH) {
                inputRefs[TEL3_INDEX].current?.blur();
              }
            }}
          />
        </div>
      </div>
      {!!error && (
        <p className={style['error-message']}>
          有効な電話番号が指定されていません
        </p>
      )}
    </div>
  );
};
