import { FC } from 'react';
import { WatchingServiceSettings } from './WatchingServiceSettings/WatchingServiceSettings';

interface Watcher {
  short_user_id: number;
  vehicle_id: string;
  watch_user_id: number;
  mailaddress: string;
  first_name: string;
  family_name: string;
  first_name_kana: string;
  family_name_kana: string;
}

interface WatchingServiceSettingsPageProps {
  watcher: Watcher | undefined;
  initialFetchWatchers: () => void;
}

export const WatchingServiceSettingsPage: FC<
  WatchingServiceSettingsPageProps
> = ({ watcher, initialFetchWatchers }) => {
  return (
    <WatchingServiceSettings
      initialFetchWatchers={initialFetchWatchers}
      watcher={watcher}
    />
  );
};
