import { f7, useStore } from 'framework7-react';
import { useEffect } from 'react';
import { usePaymentList } from './api/payment/usePaymentList';
import { useLoadCustomer } from './useLoadCustomer';
import { useToast } from './useToast';
import { customerInfoApi } from '@/api/customerApi';
import { chargePrepaidApi } from '@/api/prepaidApi';
import { AuthInfoState, initialState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { chargeClassification, chargeType } from '@/consts/charge';
import { storeDispatch } from '@/utils/store';

export const useGmoRedirect = (status?: string) => {
  const router = f7.views.main.router;
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const customerId = localStorage.getItem('m_customer_id');
  const token = localStorage.getItem('token');
  const { openToast } = useToast();
  const { updatePaymentList, isFetched, isFetching } = usePaymentList(false);
  const { isLoggedIn } = useLoadCustomer(
    router,
    token === null ? undefined : token,
    Number(customerId),
    status,
  );

  useEffect(() => {
    if (!isLoggedIn) return;
    const mCustomerId = window.localStorage.getItem('m_customer_id');
    const destinationStep = window.localStorage.getItem('destination_step');
    const destinationPage = window.localStorage.getItem('destination_page');
    const reserveAmount = window.localStorage.getItem('reserve_amount');
    window.localStorage.removeItem('destination_step');
    window.localStorage.removeItem('destination_page');
    const gmoRedirectInfo = {
      destinationStep,
      destinationPage,
      homeNextPage: paths.prepaid,
      openDialog: paths.reservings,
    };
    storeDispatch('setReserveAmount', 'reserveAmount', reserveAmount ?? 0);
    switch (status) {
      // GMOペイメント post →　maikuru バックエンド（api.mobicon.net）からのリダイレクト
      case 'gmo_success':
        if (isLoggedIn && !isFetched && !isFetching) {
          updatePaymentList(Number(mCustomerId));
          if (gmoRedirectInfo.destinationPage) {
            localStorage.setItem('paymentRedirectPage', 'car_wash');
            f7.views.main.router.navigate(gmoRedirectInfo.destinationPage, {
              animate: true,
              props: {
                isBackShowPrepaidSummary: true,
              },
            });
          }
          return;
        }
        break;
      case 'gmo_cancel':
        if (isLoggedIn) {
          if (gmoRedirectInfo.destinationPage) {
            localStorage.setItem('paymentRedirectPage', 'car_wash');
            f7.views.main.router.navigate(gmoRedirectInfo.destinationPage, {
              animate: true,
              props: {
                isBackShowPrepaidSummary: true,
              },
            });
          }
        }
        break;
      case 'charge_prepaid_success':
        const chargePrice = window.localStorage.getItem('charge_amount');
        const mPrepaidId = window.localStorage.getItem('m_prepaid_id');
        if (isLoggedIn && chargePrice && mPrepaidId) {
          localStorage.setItem('paymentRedirectPage', 'car_wash');
          const params = {
            m_customer_id: authInfo.m_customer_id,
            m_prepaid_id: Number(mPrepaidId) ?? 0,
            charge_price: Number(chargePrice),
            charge_kbn: chargeClassification.BASIC,
            charge_type: chargeType.SPOT,
          };
          chargePrepaidApi(params).then((res) => {
            const error_message =
              res.data.error_message ?? '処理に失敗しました。';
            if (!res.data.success) {
              openToast(error_message, 'toast-failed', 3000, true, 'top');
              return;
            }
            customerInfoApi({ m_customer_id: Number(mCustomerId) }).then(
              async (data) => {
                const {
                  customer,
                  cars,
                  bill_infomations,
                  payment_methods,
                  prepaids,
                  informations,
                  campaigns,
                } = data.data;
                Promise.all([
                  storeDispatch(
                    'setCarItemInfo',
                    'carItemInfo',
                    cars ? cars[0] : initialState.carItemInfo,
                  ),
                  storeDispatch('setCustomerInfo', 'customerInfo', customer),
                  storeDispatch('setCarsInfo', 'carsInfo', cars),
                  storeDispatch('setBillsInfo', 'billsInfo', bill_infomations),
                  storeDispatch(
                    'setPaymentMethodsInfo',
                    'paymentMethodsInfo',
                    payment_methods,
                  ),
                  storeDispatch(
                    'setAdvancePaymentsInfo',
                    'advancePaymentsInfo',
                    prepaids,
                  ),
                  storeDispatch(
                    'setNotificationsInfo',
                    'notificationsInfo',
                    informations,
                  ),
                  storeDispatch('setCampaignsInfo', 'campaignsInfo', campaigns),
                  storeDispatch(
                    'setWarranties',
                    'warranties',
                    cars?.[0]?.warranties || [],
                  ),
                  storeDispatch(
                    'setNextWarranties',
                    'nextWarranties',
                    cars?.[0]?.next_warranty || [],
                  ),
                  storeDispatch(
                    'setCurrentWarranty',
                    'currentWarranty',
                    cars?.[0]?.warranties?.[0],
                  ),
                  storeDispatch(
                    'setNextWarranty',
                    'nextWarranty',
                    cars?.[0]?.next_warranty?.[0],
                  ),
                ]);
              },
            );
          });
        }
        break;
      case 'charge_prepaid_cancel':
        // chargePrepaidPageでadvancePaymentInfoを参照しているため
        // advancePaymentInfoがfalsyな値の場合は遷移しない
        if (store.state.advancePaymentsInfo[0]) {
          router.navigate(paths.chargePrepaid, {
            animate: false,
          });
        }
        break;
      default:
        break;
    }
  }, [isLoggedIn]);
};
