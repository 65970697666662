import { AxiosError } from 'axios';
import { useState } from 'react';
import { updateScoreLinkedTransmissionConsentApi } from '@/api/linkDriveApi';
import {
  ScoreLinkedTransmissionConsentApiResponse,
  ScoreLinkedTransmissionConsentParams,
} from '@/types/api/linkDriveApi';

const errorMessage =
  'エラーコード：LD0008<br><br>サービス利用を開始できませんでした。お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。';

export const useScoreLinkedTransmissionConsent = () => {
  const [data, setData] =
    useState<ScoreLinkedTransmissionConsentApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const updateScoreLinkedTransmissionConsent = async (
    params: ScoreLinkedTransmissionConsentParams,
  ) => {
    try {
      setIsLoading(true);
      const { data } = await updateScoreLinkedTransmissionConsentApi(params);
      setData(data);
      if (!data.success) throw new Error(errorMessage);
    } catch (err) {
      setError(err as AxiosError);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    error,
    updateScoreLinkedTransmissionConsent,
  };
};
