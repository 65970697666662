import { AxiosError } from 'axios';
import { useState } from 'react';
import { registerWatcherApi } from '@/api/linkDriveApi';
import { useToast } from '@/hooks/useToast';
import {
  RegisterWatcherApiParams,
  RegisterWatcherApiResponse,
} from '@/types/api/linkDriveApi';
import { f7CustomBack } from '@/utils/utils';

interface Args {
  params: RegisterWatcherApiParams;
  onSuccess: () => void;
}

export const useRegisterWatcher = () => {
  const { openToast } = useToast();
  const [data, setData] = useState<RegisterWatcherApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const registerWatcher = async (args: Args) => {
    try {
      setIsLoading(true);
      const { data } = await registerWatcherApi(args.params);
      setData(data);
      if (!data.success) openToast(data.message, 'toast-failed');
      if (data.success) {
        openToast('見守り者を追加しました');
        f7CustomBack(['#WatchingServiceSettings']);
        args.onSuccess();
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    registerWatcher,
    data,
    error,
  };
};
