import { FC } from 'react';
import style from './ NewsWarrantyExpriedStyle.module.scss';
import { ContactSupportIcon, TelephoneIcon } from '@/components/uiParts/Icon';
import { store } from '@/config/store';

interface ContactSupportProps {}

export const ContactSupport: FC<ContactSupportProps> = () => {
  const { unit_name, unit_tel } = store.state.customerInfo;
  const handleCallShop = () => {
    window.location.href = 'tel://' + unit_tel;
  };

  return (
    <div className={style['contact-support-wrapper']} onClick={handleCallShop}>
      <div
        className="display-flex flex-row-center-center u-padding-right-xs"
        style={{ flex: 'none' }}
      >
        <ContactSupportIcon />
      </div>
      <div style={{ flex: 1 }}>
        <div>{unit_name}</div>
        <div className={style['notice-phone']}>{unit_tel}</div>
      </div>
      <TelephoneIcon />
    </div>
  );
};
