import React, { FC } from 'react';
import { SerialNumberInput } from './SerialNumberInput/SerialNumberInput';

type SerialNumberInputPageProp = {
  onSubmitData: (serialNo: string) => void;
};

export const SerialNumberInputPage: FC<SerialNumberInputPageProp> = ({
  onSubmitData,
  ...props
}) => {
  return <SerialNumberInput onSubmitData={onSubmitData} />;
};
