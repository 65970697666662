import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMAffiliateSettings } from 'src/hooks/api/info/useFetchMAffiliateSettings';
import {
  carConditionInitialState,
  carConditionValidationSchema,
} from './repairCondition';
import {
  carRepairRequestToStoreInitialState,
  carRepairRequestToStoreValidationSchema,
} from './repairRequestToStore';
import { fetchRepairSymptoms, postRepairRequest } from '@/api/carRepairApi';
import { getValidWarranties } from '@/components/pages/WarrantyManagement/WarrantyManagementHelper';
import { store } from '@/config/store';
import {
  CAN_DRIVE_TYPES,
  CHECK_EXPECTED_ATS,
  CanDriveType,
  CarRepairStep,
  CheckExpectedAt,
  IDENTIFICATION_SUBSCRIBERS,
  IdentificationSubscriber,
  MAP_CHECK_EXPECTED_AT_NUMBER,
  MAP_IDENTIFICATION_SUBSCRIBER_NUMBER,
} from '@/consts/carRepair';
import { customerType } from '@/consts/customer';
import {
  Defect,
  DefectOccurrenceFrequency,
  RepairRequestParams,
} from '@/types/api/carRepairApi';

export interface CarRepairFormData {
  canDrive: CanDriveType;
  m_defect_ids: number[];
  m_defect_occurrence_frequency_id?: number;
  occur_date?: Date;
  symptom_etc?: string;
  confirm_warranty_plan?: boolean;

  // step 2
  is_checked_expected_nyukosaki: CheckExpectedAt;
  expected_nyuko_date1?: Date;
  expected_nyuko_time1?: string;

  expected_nyuko_date2?: Date;
  expected_nyuko_time2?: string;

  expected_nyuko_date3?: Date;
  expected_nyuko_time3?: string;

  contact_person_phone_number: {
    first?: string;
    second?: string;
    third?: string;
  };
  is_checked_identification_subscriber: IdentificationSubscriber;
  contact_person_name: {
    family_name: string;
    first_name: string;
  };
  contact_person_name_kana: {
    family_name: string;
    first_name: string;
  };
  warrantyConfirmed?: boolean;
  feeConfirmed?: boolean;
  openedDefectCategory: boolean[];
}

const makeCarRepairFormData = (phoneNumber?: string) => {
  const initialCarRepairState: CarRepairFormData = {
    ...carConditionInitialState,
    // step 2
    ...carRepairRequestToStoreInitialState,
  };

  if (phoneNumber) {
    const [first, second, third] = phoneNumber.split('-');
    if (first && second && third) {
      initialCarRepairState.contact_person_phone_number = {
        first,
        second,
        third,
      };
    }
  }

  return initialCarRepairState;
};

export const useCarRepair = () => {
  const [carRepairStep, setCarRepairStep] = useState<CarRepairStep>(
    CarRepairStep.ENTER_CONDITION,
  );
  const [defects, setDefects] = useState<Defect[]>([]);
  const [defectOccurrenceFrequencies, setDefectOccurrenceFrequencies] =
    useState<DefectOccurrenceFrequency[]>([]);
  const { m_customer_id } = store.state.authInfo;
  const {
    m_affiliate_id,
    first_name,
    family_name,
    first_name_kana,
    family_name_kana,
    customer_type,
    company_name,
    company_name_kana,
    phone_no1,
    phone_no2,
  } = store.state.customerInfo;
  const { warranties } = store.state;
  const { data: affiliateSettingResponse } = useMAffiliateSettings({
    m_affiliate_id,
  });

  const [currentWarranty] = getValidWarranties(warranties);

  const validationSchema = useMemo(
    () =>
      carRepairStep === CarRepairStep.BRING_CAR_TO_STORE
        ? carRepairRequestToStoreValidationSchema
        : carConditionValidationSchema,
    [carRepairStep],
  );

  const form = useForm<CarRepairFormData>({
    defaultValues: makeCarRepairFormData(phone_no1 || phone_no2),
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    getRepairSymptoms();
  }, []);

  const getRepairSymptoms = async () => {
    const response = await fetchRepairSymptoms();
    if (response.data.success) {
      setDefects(response.data.defects);
      setDefectOccurrenceFrequencies(
        response.data.defect_occurrence_frequencies,
      );
    }
  };

  const { getValues } = form;

  const getCanDriveType = () =>
    CAN_DRIVE_TYPES.find(
      (canDriveType) => getValues('canDrive') === canDriveType.value,
    );

  const getDefectSelectees = () =>
    defects.filter((df) =>
      getValues('m_defect_ids')
        .filter((mdi) => mdi)
        .map((mdi) => Number(mdi))
        .includes(df.id),
    );

  const getDefectOccurrenceFrequencySelected = () =>
    defectOccurrenceFrequencies.find(
      (defectOccurrenceFrequency) =>
        defectOccurrenceFrequency.id ==
        getValues('m_defect_occurrence_frequency_id'),
    );

  const getCheckExpectedAt = () =>
    CHECK_EXPECTED_ATS.find(
      (checkExpectedAt) =>
        checkExpectedAt.value == getValues('is_checked_expected_nyukosaki'),
    );

  const getIdentificationSubscriber = () =>
    IDENTIFICATION_SUBSCRIBERS.find(
      (identificationSubscriber) =>
        identificationSubscriber.value ==
        getValues('is_checked_identification_subscriber'),
    );

  const preparePersonNameDefault = () => {
    if (customer_type === customerType.CORPORATION) {
      return company_name || '';
    }

    if (customer_type === customerType.TRADER && company_name) {
      return company_name;
    }

    return `${family_name || ''}　${first_name || ''}`;
  };

  const preparePersonNameKanaDefault = () => {
    if (customer_type === customerType.CORPORATION) {
      return company_name_kana || '';
    }

    if (customer_type === customerType.TRADER && company_name_kana) {
      return company_name_kana;
    }

    return `${family_name_kana || ''}　${first_name_kana || ''}`;
  };

  const sendCarRepairRequest = () => {
    return postRepairRequest(getRepairRequestParam());
  };

  const getRepairRequestParam = (): RepairRequestParams => {
    const {
      is_checked_expected_nyukosaki,
      expected_nyuko_date1,
      expected_nyuko_time1,
      expected_nyuko_date2,
      expected_nyuko_time2,
      expected_nyuko_date3,
      expected_nyuko_time3,
      is_checked_identification_subscriber,
      contact_person_name,
      contact_person_name_kana,
      contact_person_phone_number,
      occur_date,
      symptom_etc,
    } = getValues();

    return {
      m_customer_id,
      m_affiliate_id: m_affiliate_id as number,
      m_unit_id: currentWarranty?.m_unit_id as number,
      warranty_no: (currentWarranty?.warranty_no as string) || '',
      branch_no: (currentWarranty?.branch_no as number) || undefined,
      t_uketuke_warranty: {
        is_checked_expected_nyukosaki:
          MAP_CHECK_EXPECTED_AT_NUMBER[is_checked_expected_nyukosaki],
        expected_nyuko_date1:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY
            ? format(expected_nyuko_date1 as Date, 'yyyy-MM-dd')
            : '',
        expected_nyuko_time1:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY
            ? (expected_nyuko_time1 as string)
            : '',
        expected_nyuko_date2:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY &&
          expected_nyuko_date2 &&
          expected_nyuko_time2
            ? format(expected_nyuko_date2 as Date, 'yyyy-MM-dd')
            : '',
        expected_nyuko_time2:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY &&
          expected_nyuko_date2 &&
          expected_nyuko_time2
            ? expected_nyuko_time2
            : '',
        expected_nyuko_date3:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY &&
          expected_nyuko_date3 &&
          expected_nyuko_time3
            ? format(expected_nyuko_date3 as Date, 'yyyy-MM-dd')
            : '',
        expected_nyuko_time3:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY &&
          expected_nyuko_date3 &&
          expected_nyuko_time3
            ? expected_nyuko_time3
            : '',
        is_checked_identification_subscriber:
          MAP_IDENTIFICATION_SUBSCRIBER_NUMBER[
            is_checked_identification_subscriber
              ? is_checked_identification_subscriber
              : IdentificationSubscriber.MYSELF
          ],
        contact_person_name:
          is_checked_identification_subscriber == IdentificationSubscriber.AGENT
            ? `${contact_person_name.family_name}　${contact_person_name.first_name}`
            : preparePersonNameDefault(),
        contact_person_name_kana:
          is_checked_identification_subscriber == IdentificationSubscriber.AGENT
            ? `${contact_person_name_kana.family_name}　${contact_person_name_kana.first_name}`
            : preparePersonNameKanaDefault(),
        contact_person_phone_number: `${contact_person_phone_number.first}-${contact_person_phone_number.second}-${contact_person_phone_number.third}`,
        symptoms: getDefectSelectees().map((defect) => ({
          m_defect_id: defect.id,
          overview: defect.name_for_customer,
          occur_date: format(occur_date as Date, 'yyyy-MM-dd'),
        })),
        symptom_etc,
        m_defect_occurrence_frequency_id: getDefectOccurrenceFrequencySelected()
          ?.id as number,
      },
    };
  };

  return {
    form,
    defects,
    defectOccurrenceFrequencies,
    carRepairStep,
    setCarRepairStep,
    getCanDriveType,
    getDefectSelectees,
    getDefectOccurrenceFrequencySelected,
    getCheckExpectedAt,
    getIdentificationSubscriber,
    sendCarRepairRequest,
    callCenterTel: affiliateSettingResponse?.data.callcenter_tel,
    callCenterName: affiliateSettingResponse?.data.callcenter_name,
  };
};
