import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchTodoListApi } from '@/api/todoListApi';
import { TodoListParams, TodoListResponse } from '@/types/api/todoListApi';

export const useFetchTodoList = ({
  m_customer_id,
  t_stock_car_id,
}: TodoListParams) => {
  const [data, setData] = useState<TodoListResponse['data']>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        const res = await fetchTodoListApi({ m_customer_id, t_stock_car_id });
        setData(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };
    fn();
  }, []);

  return {
    data,
    error,
  };
};
