import React, { FC } from 'react';
import style from './DriveLogPropertyDisplayStyle.module.css';

interface DriveLogPropertyDisplayProps {
  numberOfSuddenAcceleration: number;
  numberOfSuddenDeceleration: number;
  speedOverTimes: number;
  drivingScore: string;
  isAdContractor: boolean;
}

export const DriveLogPropertyDisplay: FC<DriveLogPropertyDisplayProps> = ({
  numberOfSuddenAcceleration,
  numberOfSuddenDeceleration,
  speedOverTimes,
  drivingScore,
  isAdContractor,
}) => {
  return (
    <div className={`${style['wrapper']} ${style['flex']}`}>
      <div>
        <span className={style['title']}>急アクセル</span>
        <div className={`${style['flex']} `}>
          <span className={style['property']}>
            {numberOfSuddenAcceleration}
          </span>
          <span className={style['unit']}>回</span>
        </div>
      </div>
      <div>
        <span className={style['title']}>急ブレーキ</span>
        <div className={`${style['flex']}`}>
          <span className={style['property']}>
            {numberOfSuddenDeceleration}
          </span>
          <span className={style['unit']}>回</span>
        </div>
      </div>
      {isAdContractor && (
        <div>
          <span className={style['title']}>速度超過</span>
          <div className={`${style['flex']}`}>
            <span className={style['property']}>{speedOverTimes}</span>
            <span className={style['unit']}>回</span>
          </div>
        </div>
      )}
      {isAdContractor && (
        <div>
          <span className={style['title']}>
            運転スコア
            <br />
            (区分)
          </span>
          <div className={`${style['flex']}`}>
            <span className={style['property']}>{drivingScore}</span>
          </div>
        </div>
      )}
    </div>
  );
};
