import { f7 } from 'framework7-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { TradeGuaranteeDocumentsPreview } from './TradeGuaranteeDocumentsPreview/TradeGuaranteeDocuments';

import { Documents } from '@/types/api/warrantyApi';

interface TradeGuaranteeDocumentsPreivewPageProps {
  uploadedDocument?: Record<number, Documents>;
}

export const TRADE_GUARANTEE_DOCUMENTS_STEPS = {
  UPLOAD_DOCUMENTS: 1,
  UPLOAD: 2,
};

export const TradeGuaranteeDocumentsPreivewPage: FC<
  TradeGuaranteeDocumentsPreivewPageProps
> = ({ uploadedDocument }) => {
  const [selectedDocument, setSelectedDocument] = useState<Documents>();
  const [step, setStep] = useState(
    TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD_DOCUMENTS,
  );

  const handleClickDocument = useCallback((document: Documents) => {
    setStep(TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD);
    setSelectedDocument(document);
  }, []);

  const handleClickBack = useCallback(() => {
    if (step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD) {
      return setStep(TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD_DOCUMENTS);
    }
    f7.views.main.router.back();
  }, [step]);

  useEffect(() => {
    if (!uploadedDocument) {
      f7.views.main.router.back();
    }
  }, [uploadedDocument]);

  return (
    <TradeGuaranteeDocumentsPreview
      step={step}
      selectedDocument={selectedDocument}
      uploadedDocument={uploadedDocument || {}}
      setStep={setStep}
      handleClickDocument={handleClickDocument}
      handleClickBack={handleClickBack}
    />
  );
};
