import { FC } from 'react';
import { ContactComplete } from './ContactComplete/ContactComplete';

interface ContactCompletePageProps {
  email: string;
}
export const ContactCompletePage: FC<ContactCompletePageProps> = ({
  email,
}) => {
  return <ContactComplete email={email} />;
};
