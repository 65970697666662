import { Button } from 'framework7-react';
import { FC, ReactNode } from 'react';
import { InfoCard } from '../../InfoCard/InfoCard';

interface ListCardProps {
  isButton?: boolean;
  isDisabled?: boolean;
  handleClick?: () => void;
  children?: string | ReactNode;
  hasUpdatedData?: boolean;
  cardHead: string;
  cardBodys: {
    label: string;
    props: string | number;
  }[];
}

export const ListCard: FC<ListCardProps> = ({
  isButton = false,
  isDisabled = false,
  handleClick = () => {},
  children,
  cardHead,
  cardBodys,
  hasUpdatedData = false,
}) => {
  return (
    <div className="info-card info-card-elevated u-margin-bottom-md">
      <div
        className="head display-flex u-font-bold u-font-size-xxl"
        style={{ margin: '10px 0 15px' }}
      >
        {cardHead}
      </div>
      {hasUpdatedData && (
        <InfoCard
          bgColor="warning"
          text="LinkDrive のアップデートが可能です"
          iconType="confirm-notification"
        />
      )}
      <div>
        {cardBodys.map((cardBody, index) => (
          <div className="list-item" key={index}>
            {cardBody.label && (
              <div className="list-item-label u-font-bold">
                {cardBody.label}
              </div>
            )}
            <div className="list-item-content list-card-content">
              {cardBody.props}
            </div>
          </div>
        ))}
        {isButton && (
          <div
            className="u-margin-top-md u-margin-bottom-xs"
            style={{ maxHeight: 'initial' }}
          >
            <Button
              text="アップデート"
              fill
              round
              disabled={isDisabled}
              onClick={handleClick}
              style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
