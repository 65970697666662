import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Checkbox } from './Checkbox';
import style from './PreStartConfirmation.module.css';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';
import { handleClickLink } from '@/utils/utils';

interface FormInput {
  prepaidServices: boolean;
  thirdPartyNotProvided: boolean;
  withdrawals: boolean;
  cancelPayment: boolean;
  prepaidServiceBonus: boolean;
  prepaidServiceBonusPriorityOrder: boolean;
  nonRefundable: boolean;
  terminatePrepaidService: boolean;
  validityPeriod: boolean;
  specifiedAmount: boolean;
  chargeBack: boolean;
  regardlessReason: boolean;
}

const validationSchema = yup.object().shape({
  prepaidServices: yup.boolean().oneOf([true]),
  thirdPartyNotProvided: yup.boolean().oneOf([true]),
  withdrawals: yup.boolean().oneOf([true]),
  cancelPayment: yup.boolean().oneOf([true]),
  prepaidServiceBonus: yup.boolean().oneOf([true]),
  prepaidServiceBonusPriorityOrder: yup.boolean().oneOf([true]),
  nonRefundable: yup.boolean().oneOf([true]),
  terminatePrepaidService: yup.boolean().oneOf([true]),
  validityPeriod: yup.boolean().oneOf([true]),
  specifiedAmount: yup.boolean().oneOf([true]),
  chargeBack: yup.boolean().oneOf([true]),
  regardlessReason: yup.boolean().oneOf([true]),
});

interface PreStartConfirmProps {
  isConfirmed?: boolean;
  onSubmit: () => void;
}

export const PreStartConfirm: FC<PreStartConfirmProps> = ({
  isConfirmed = false,
  onSubmit,
}) => {
  const formMethods = useForm<FormInput>({
    defaultValues: {
      prepaidServices: isConfirmed,
      thirdPartyNotProvided: isConfirmed,
      withdrawals: isConfirmed,
      cancelPayment: isConfirmed,
      prepaidServiceBonus: isConfirmed,
      prepaidServiceBonusPriorityOrder: isConfirmed,
      nonRefundable: isConfirmed,
      terminatePrepaidService: isConfirmed,
      validityPeriod: isConfirmed,
      specifiedAmount: isConfirmed,
      chargeBack: isConfirmed,
      regardlessReason: isConfirmed,
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    getValues,
    formState: { isValid },
  } = formMethods;

  const totalItems = Object.keys(getValues());
  type KeyForm = keyof typeof getValues;
  const remainingItems = totalItems.filter(
    (key) => !getValues()[key as KeyForm],
  );

  return (
    <div className={style['check-notes']}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className={style['check-notes-head']}>注意事項ご確認のお願い</h2>

          <h4 className={style['input-label']}>
            取引保証プランのご注意事項
            <span
              className={`${style['input-label-tips']} ${style['required']}`}
            >
              必須
            </span>
          </h4>
          <p className={style['check-notes-content']}>
            当サービスのお申込みおよびご利用開始にあたりまして、
            <span onClick={() => handleClickLink(paths.tradeAssuranceTerms)}>
              取引保証プラン利用規約
            </span>
            より重要な項目を抜粋しております。
            <br />
            すべての項目をご確認いただき、チェックを入れてください。
          </p>

          <h4 className={style['check-notes-title']}>払い戻し等について</h4>
          <Checkbox name="prepaidServices" isBoolean value={true}>
            プリペイドサービスは、原則といたしまして、法令に定める場合を除き払い戻すことはできません。
          </Checkbox>
          <Checkbox name="thirdPartyNotProvided" isBoolean value={true}>
            プリペイドサービスは、第三者に利用させることはできません。
          </Checkbox>

          <h4 className={style['check-notes-title']}>
            プリペイドサービスの積立について
          </h4>
          <Checkbox name="withdrawals" isBoolean value={true}>
            プリペイドサービスのお申込みならびに決済方法登録の翌月よりお引落（あるいはクレジットカード決済）が開始されます。残高への反映はさらに翌月の15日です。　例）1月に申込みと決済登録→2月に引落→3月15日に残高へ反映
          </Checkbox>

          <h4 className={style['check-notes-title']}>
            プリペイドサービスによる決済について
          </h4>
          <Checkbox name="cancelPayment" isBoolean value={true}>
            プリペイドサービスによる決済を取り消す必要が生じた場合、対象商品等の代金に相当する金額を利用者のプリペイドサービスアカウントに加算する方法によるご返金となります。
          </Checkbox>

          <h4 className={style['check-notes-title']}>
            プリペイドサービスボーナスの取扱いについて
          </h4>
          <Checkbox name="prepaidServiceBonus" isBoolean value={true}>
            プリペイドサービスをご利用のお客様に対し、プリペイドサービスへのご入金額に応じたプリペイドサービスボーナス（当社が無償で付与するプリペイドサービスのことをいいます）を付与します。
            <br />
            プリペイドサービスボーナスが付与された決済の取引が取り消された場合には、付与されたプリペイドサービスボーナスも同時に取り消されます。
          </Checkbox>
          <Checkbox
            name="prepaidServiceBonusPriorityOrder"
            isBoolean
            value={true}
          >
            プリペイドサービスボーナスの残高がなくなるまで、自動的にプリペイドサービス残高に優先して、支払いに充当されます。
          </Checkbox>
          <Checkbox name="nonRefundable" isBoolean value={true}>
            プリペイドサービスボーナスの払戻しや譲渡はできません。
          </Checkbox>
          <Checkbox name="terminatePrepaidService" isBoolean value={true}>
            お客様がプリペイドサービスのご利用を終了（プリペイドサービスアカウントの停止等）された場合には、プリペイドサービスボーナスはすべて消滅します。
          </Checkbox>

          <h4 className={style['check-notes-title']}>
            プリペイドサービスの有効期間について
          </h4>
          <Checkbox name="validityPeriod" isBoolean value={true}>
            プリペイドサービスの有効期間は、最後に入金または利用した日より2年後です。
          </Checkbox>

          <h4 className={style['check-notes-title']}>
            入金遅滞の場合のお取り扱いについて
          </h4>
          <Checkbox name="specifiedAmount" isBoolean value={true}>
            残高不足等の理由により、指定口座からお支払い日に指定金額の引落ができないときは、該当月は未払い扱いといたします。次月以降の合算引落はございません。
          </Checkbox>
          <Checkbox name="chargeBack" isBoolean value={true}>
            クレジットカード会社からのチャージバックが発生した際には、チャージバック相当額を該当のハウスプリペイドアカウントの残高より相殺いたします。
          </Checkbox>
          <Checkbox
            name="regardlessReason"
            isBoolean
            value={true}
            className={style['last-item']}
          >
            理由の如何を問わず、未払い扱いの月が連続で2ヵ月発生した場合、引落継続の意思が無いものとみなし引落を停止いたします。引落を再開する場合は、当社指定の手続きを行うことにより引落を再開することができます。
          </Checkbox>

          <div className={style['check-notes-bottom']}>
            <div className={style['check-notes-total']}>
              確認する項目：残り
              <span>{remainingItems.length}件</span>
              (全{totalItems.length}件)
            </div>
            <Button
              type="submit"
              disabled={!isValid}
              className={style['check-notes-button']}
              text="次へ"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
