import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchImpactInfoApi } from '@/api/infoApi';
import { FetchImpactInfoApiResponse } from '@/types/api/infoApi';

export const useImpactInfo = (customerId: number) => {
  const [data, setData] = useState<FetchImpactInfoApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    if (!customerId) return;
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchImpactInfoApi({ m_customer_id: customerId });
        console.log(res);
        setData(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [customerId]);

  return {
    data,
    isLoading,
    error,
  };
};
