import { f7 } from 'framework7-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { TradeGuaranteeDocuments } from './TradeGuaranteeDocuments/TradeGuaranteeDocuments';
import {
  getUploadedWarrantyDocumentsApi,
  getWarrantyDocumentsApi,
  postWarrantyDocumentsApi,
} from '@/api/warrantyApi';
import { store } from '@/config/store';
import { getAndSetCustomerInfo } from '@/hooks/api/customer/useCustomerInfo';
import { Documents, WarrantyDocument } from '@/types/api/warrantyApi';
import { storeDispatch } from '@/utils/store';

interface TradeGuaranteeDocumentsPageProps {
  uploadedDocument?: Record<number, Documents>;
  warrantyDocument?: WarrantyDocument[];
}

export const TRADE_GUARANTEE_DOCUMENTS_STEPS = {
  UPLOAD_DOCUMENTS: 1,
  UPLOAD: 2,
};

export const TradeGuaranteeDocumentsPage: FC<
  TradeGuaranteeDocumentsPageProps
> = ({ uploadedDocument, warrantyDocument }) => {
  const [selectedDocument, setSelectedDocument] = useState<WarrantyDocument>();
  const [uploadedDocs, setUploadedDocs] = useState<Record<number, Documents>>(
    uploadedDocument || {},
  );
  const [documents, setDocuments] = useState<WarrantyDocument[]>(
    warrantyDocument || [],
  );
  const [step, setStep] = useState(
    TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD_DOCUMENTS,
  );

  const fetchUploaded = useCallback(async () => {
    const res = await getUploadedWarrantyDocumentsApi({
      warranty_no: store.state.currentWarranty?.warranty_no || '',
      m_customer_id: store.state.authInfo?.m_customer_id || 0,
      t_stock_car_id: store.state.carItemInfo?.t_stock_car_id || 0,
      branch_no: store.state.currentWarranty?.branch_no || 0,
    });

    if (!res.data.success) {
      throw new Error();
    }

    setUploadedDocs(res.data.documents);
  }, []);

  const fetchWarantyDocuments = useCallback(async () => {
    // fetch documents
    const res = await getWarrantyDocumentsApi({
      warranty_type:
        store.state.currentWarranty?.append_warranties?.[0].warranty_type || 0,
      issue_category: 0,
    });

    if (!res.data.success) {
      throw new Error();
    }

    setDocuments(res.data.documents);
  }, []);

  const handleClickDocument = useCallback((document: WarrantyDocument) => {
    setStep(TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD);
    setSelectedDocument(document);
  }, []);

  const fetchUser = async () => {
    const { currentWarranty, authInfo, carItemInfo } = store.state;
    if (currentWarranty) {
      const customer = await getAndSetCustomerInfo(
        {
          m_customer_id: authInfo.m_customer_id,
        },
        (car) => {
          if (carItemInfo) {
            return car.m_car_id == null
              ? car.t_order_id === carItemInfo.t_order_id
              : car.m_car_id === carItemInfo.m_car_id;
          }
          return true;
        },
      );
      customer.cars?.forEach((c) => {
        const warr = c.warranties?.find(
          (w) => w.t_base_waranty_id === currentWarranty.t_base_waranty_id,
        );
        storeDispatch('setCurrentWarranty', 'currentWarranty', warr);
      });
    }
  };

  const handleUpload = useCallback(
    async (files: File[]) => {
      if (selectedDocument?.m_warranty_document_property_id == null) return;
      const res = await postWarrantyDocumentsApi({
        warranty_no: store.state.currentWarranty?.warranty_no || '',
        documents: files.map((file) => ({
          file,
          document_type: selectedDocument.document_type,
        })),
        uploaded_by: 2, // 提供元（1：販売店、2：顧客）
      });
      if (!res?.data?.success) {
        throw new Error();
      }
      const docs = await getUploadedWarrantyDocumentsApi({
        m_customer_id: store.state.authInfo.m_customer_id,
        t_stock_car_id: store.state.carItemInfo.t_stock_car_id,
        warranty_no: store.state.currentWarranty?.warranty_no || '',
        branch_no: store.state.currentWarranty?.branch_no || 0,
      });
      await fetchUser().then(() => {
        setUploadedDocs(docs.data.documents);
      });
    },
    [selectedDocument],
  );

  const handleClickBack = useCallback(() => {
    if (step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD) {
      return setStep(TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD_DOCUMENTS);
    }
    f7.views.main.router.back();
  }, [step]);

  useEffect(() => {
    if (Object.keys(uploadedDocs).length > 0 && documents.length > 0) return;
    const promises = [];
    f7.preloader.show();
    if (!Object.keys(uploadedDocument || {}).length) {
      promises.push(fetchUploaded());
    }
    if (!warrantyDocument?.length) {
      promises.push(fetchWarantyDocuments());
    }
    Promise.all(promises)
      .catch(() => {
        f7.dialog.alert('データの取得に失敗しました。');
        f7.views.main.router.back();
      })
      .finally(() => {
        f7.preloader.hide();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchUploaded,
    fetchWarantyDocuments,
    uploadedDocument,
    warrantyDocument?.length,
  ]);

  return (
    <TradeGuaranteeDocuments
      step={step}
      uploadedDocument={uploadedDocs}
      warrantyDocument={documents}
      selectedDocument={selectedDocument}
      setStep={setStep}
      handleClickDocument={handleClickDocument}
      handleUpload={handleUpload}
      handleClickBack={handleClickBack}
    />
  );
};
