import React, { FC } from 'react';
import style from './InsuranceCardStyle.module.css';
import { store } from '@/config/store';

// TODO: APIの型調査
interface InfoType {
  monthlyreport?: any;
  mileageOfData?: any;
  tripLog?: any;
  timelineLog?: any;
  phydMeas?: any;
  adInsuranceDiscount?: any;
  latestDiagnosisResult?: any;
}

interface InsuranceCardProps {
  info?: InfoType;
}

export const InsuranceCard: FC<InsuranceCardProps> = (props) => {
  const userFamilyName = store.state.customerInfo.family_name;

  return (
    <div
      className={`${style['all-report-score-card']} info-card info-card-elevated u-padding-bottom-lg`}
      onClick={() => {}}
    >
      <div
        className={`${style['all-report-score-card_title']} ${style['all-report-score-card_text::after']}`}
      >
        Special Offer
      </div>
      <div
        className={`${style['all-report-score-card_data']} u-padding-top-xs u-font-bold`}
      >
        {userFamilyName}様に特別な保険のお知らせ
      </div>
      <div
        className={`${style['all-report-score-card_data']} u-padding-bottom-sm`}
        style={{ fontSize: '1.6rem', fontWeight: 'normal' }}
      >
        累計スコアを確認する場合はこちら
      </div>
      <div className={`${style['all-report-score-card_text']}`}>詳細を見る</div>
      <div className={`${style['all-report-score-card_icon']}`} />
    </div>
  );
};
