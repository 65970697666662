import { Router } from 'framework7/types';
import { FC } from 'react';
import { PrepaidRestart } from './PrepaidRestart/PrepaidRestart';

type PrepaidRestart = {
  f7router: Router.Router;
  event?: () => void;
};

export const PrepaidRestartPage: FC<PrepaidRestart> = ({ f7router, event }) => {
  return <PrepaidRestart f7router={f7router} event={event} />;
};
