import * as yup from 'yup';

export const addressSchema = yup.object().shape({
  zipCode: yup
    .string()
    .required('郵便番号は必須です')
    .matches(/^[0-9]{3}-[0-9]{4}$/, {
      message: '郵便番号を正しい形式で入力して下さい',
    }),
  prefectures: yup.string().required('都道府県は必須です'),
  municipalities: yup.string().required('市区町村は必須です'),
  street: yup.string().required('町名番地は必須です'),
  buildingRoomName: yup.string(),
});
