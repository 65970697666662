import { Button } from 'framework7-react';
import { Dispatch, FC, SetStateAction } from 'react';
import style from './WarrantyPlanRequiredPopup.module.scss';
import contactImage from '@/assets/images/menu/icon-contact.svg';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { store } from '@/config/store';

interface WarrantyPlanRequiredPopupProps {
  isOpen: boolean;
  setIsOpenWarrantyRequired: Dispatch<SetStateAction<boolean>>;
}

export const WarrantyPlanRequiredPopup: FC<WarrantyPlanRequiredPopupProps> = ({
  isOpen,
  setIsOpenWarrantyRequired,
}) => {
  const { unit_name, unit_tel } = store.state.customerInfo;

  const handleCallShop = () => {
    window.location.href = 'tel://' + unit_tel;
  };

  return (
    <PopupTemplate
      className={style['warranty-plan-required-popup']}
      opened={isOpen}
      title=""
      isCloseIcon={false}
      onPopupClose={() => setIsOpenWarrantyRequired(false)}
      backdrop
    >
      <div className={style.head}>
        <i className={`icon icon-exclamation-mark-red ${style.icon}`} />
        <p className={style.text}>
          このサービスを利用するには
          <br />
          保証プランの契約が必要です
        </p>
      </div>
      <div className={style.body}>
        <div className={style['body-text']}>
          保証プランの契約については
          <br />
          お車を購入された販売店に
          <br />
          お問合せください。
        </div>
        {unit_tel && (
          <div
            className={style['body-button']}
            onClick={() => handleCallShop()}
          >
            <div className={style['call-information']}>
              <img src={contactImage} />
              <div className={style['button-text']}>
                <div className={style['button-text-first']}>
                  {unit_name || '販売店'}
                </div>
                <div className={style['button-text-second']}>{unit_tel}</div>
              </div>
            </div>
            <div className={style['button-icon']}>
              <i className={`icon icon-telephone ${style['telephone-icon']}`} />
            </div>
          </div>
        )}
      </div>
      <Button
        className={style['popup-button']}
        onClick={() => setIsOpenWarrantyRequired(false)}
      >
        閉じる
      </Button>
    </PopupTemplate>
  );
};
