import { useStore } from 'framework7-react';
import { FC } from 'react';
import style from './VehicleCardStyle.module.scss';
import carImg from '@/assets/images/carImg.svg';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { store } from '@/config/store';
import { PurchaseOrder } from '@/types/api/carApi';
import { Car } from '@/types/api/customerApi';
import { isDelivered } from '@/utils/car';
import { hyphenToYear } from '@/utils/date';
import { carNameTitle } from '@/utils/home';
import { getCarPhoto } from '@/utils/utils';
interface VehicleCardProps {
  purchaseOrder?: PurchaseOrder;
}

export const VehicleCard: FC<VehicleCardProps> = ({ purchaseOrder }) => {
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;
  const carInfoCardTitle = [
    purchaseOrder?.mk_nm,
    purchaseOrder?.car_name,
    purchaseOrder?.grade,
  ]
    .filter(Boolean)
    .join(' ');
  return (
    <RoundSheet radius="8px">
      <div className={style['container']}>
        <div className={style['car-image-wrapper']}>
          <img src={getCarPhoto(selectedCar || carImg)} />
        </div>
        <div className={style['title']}>
          {carNameTitle(
            true,
            isDelivered(selectedCar?.delivery_complete_flg),
            carInfoCardTitle || '現在契約中の車両はありません',
          )}
        </div>
        <p>{purchaseOrder?.unique_code || ''}</p>
        <div className={style['date-wrapper']}>
          <div
            className={`${style['date-info']} display-flex flex-row-center-center`}
          >
            <div
              className={`${style['icon-wrapper']} display-flex flex-row-center-center`}
            >
              <i className="icon-calendar-schedule-blue" />
              見積発行日
            </div>
            <p>{hyphenToYear(purchaseOrder?.estimate_issue_date, false)}</p>
          </div>
          <div
            className={`${style['date-info']} display-flex flex-row-center-center`}
          >
            <div
              className={`${style['icon-wrapper']} display-flex flex-row-center-center`}
            >
              <i className="icon-calendar-schedule-blue" />
              注文確定日
            </div>
            <p>{hyphenToYear(purchaseOrder?.order_fixed_date, false)}</p>
          </div>
        </div>
      </div>
    </RoundSheet>
  );
};
