import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { PrepaidButtons } from '@/components/uiParts/PrepaidButtons/PrepaidButtons';
import { PrepaidCard } from '@/components/uiParts/PrepaidCard/PrepaidCard';
import { PrepaidGuideButton } from '@/components/uiParts/PrepaidGuideButton/PrepaidGuideButton';
import { PrepaidTicketCoupon } from '@/components/uiParts/PrepaidTicketCoupon/PrepaidTicketCoupon';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { paths } from '@/config/paths';
import {
  Campaign,
  Customer,
  HousePrepaidPaymentHistory,
} from '@/types/api/customerApi';
import { useGetPrepaidData } from '@/utils/prepaid';
import './prepaidStyle.scss';

type Button = {
  buttonType: string;
  name: string;
  transitionTo?: string;
  phoneNumber?: string;
};

type Prepaid = {
  id?: number;
  m_affiliate_id?: number;
  m_prepaid_plan_id?: number;
  name?: string;
  bonus_rate?: number;
  start_date?: string;
  end_date?: string;
  monthly_charge?: number;
  latest_monthly_charge_result?: string;
  latest_transaction_date?: string;
  charge_flg?: number;
  prepaid_status?: number;
  balance_total?: number;
  term_of_service?: string;
  term_of_transfer?: string;
  warranty_alignment_flg?: number;
  t_houseprepaid_payment_histories?: HousePrepaidPaymentHistory[];
};

interface PrepaidProps {
  f7router: Router.Router;
  themeId: number;
  couponList: Campaign[];
  validCouponList: Campaign[];
  customerInfo: Customer;
  prepaidName: string;
  prepaidInfo?: Prepaid;
  onRefetch?: (cd: () => void) => void;
}

export const Prepaid: FC<PrepaidProps> = ({
  f7router,
  themeId,
  couponList,
  validCouponList,
  customerInfo,
  prepaidName,
  prepaidInfo,
  onRefetch,
}) => {
  // おサイフメニュー（登録済の場合）
  const prepaidMenuList: Button[] = [
    { buttonType: 'pay-tip', name: '支払い', transitionTo: paths.usePrepaid },
    { buttonType: 'plus', name: 'チャージ', transitionTo: paths.chargePrepaid },
    {
      buttonType: 'history',
      name: '残高履歴',
      transitionTo: paths.prepaidHistory,
    },
    {
      buttonType: 'settings',
      name: 'プリペイド設定',
      transitionTo: paths.prepaidSetting,
    },
  ];

  // 担当者
  const operatorInfoButtonList = [
    { buttonType: 'telephone', name: '電話', phoneNumber: '' },
    { buttonType: 'video', name: '紹介動画' },
    { buttonType: 'pay-tip', name: 'チップを送る' },
  ];

  // 店舗
  const shopInfoButtonList = () => {
    const list: {
      buttonType: string;
      name: string;
      transitionTo?: string;
      href?: string;
      phoneNumber?: string;
    }[] = [
      {
        buttonType: 'place-map-pin-prepaid',
        name: 'MAP',
        transitionTo: paths.shopmap,
      },
      {
        buttonType: 'telephone-prepaid',
        name: '電話',
        phoneNumber: customerInfo.unit_tel,
      },
    ];
    if (customerInfo.unit_url) {
      list.push({
        buttonType: 'website-color',
        name: 'ウェブサイト',
        href: customerInfo.unit_url,
      });
    }
    return list;
  };

  const charging = prepaidInfo?.balance_total ?? 0;
  const [balanceTotal, setBalanceTotal] = useState(0);

  useEffect(() => {
    setBalanceTotal(charging);
  }, [charging]);

  const handleChangeBalanceTotal = (charging: number) => {
    setBalanceTotal(charging + balanceTotal);
  };
  const { expire_date, limit, isPrepaid } = useGetPrepaidData(prepaidInfo);
  const [prepaidData, setPrepaidData] = useState({
    balanceTotal: prepaidInfo?.balance_total ?? 0,
    expireDate: expire_date,
    limit: limit,
    isPrepaid: isPrepaid,
  });

  const eigyoTanto = false; // 担当営業情報については非表示で良いとのこと。

  const handleShowAllCoupon = () => {
    f7.view.main.router.navigate(paths.coupons);
  };

  useEffect(() => {
    const balanceTotal = prepaidInfo?.balance_total ?? 0;
    if (prepaidData.balanceTotal !== balanceTotal) {
      setPrepaidData({
        ...prepaidData,
        balanceTotal: prepaidInfo?.balance_total ?? 0,
      });
    }
  }, [prepaidData, prepaidInfo]);

  return (
    <PageTemplateV2
      pageName="Prepaid"
      title="おサイフ"
      onRefresh={(done) => {
        if (onRefetch) {
          onRefetch(() => done());
        }
      }}
    >
      <div className="wrapper">
        <div>
          {!!customerInfo.prepaid_service_flg ? (
            <div
              className={`prepaid-card-wrapper ${
                isPrepaid && 'prepaid-card-wrapper_plan'
              }`}
            >
              <PrepaidCard
                themeId={themeId}
                balanceTotal={balanceTotal}
                deadline={limit}
                isPrepaid={isPrepaid}
                f7router={f7router}
                prepaidName={prepaidName}
              />
              {isPrepaid ? (
                <div className="prepaid-buttons-wrapper">
                  {prepaidMenuList.map((button, index) => (
                    <PrepaidButtons
                      key={index}
                      buttonType={button.buttonType}
                      name={button.name}
                      transitionTo={button.transitionTo}
                      f7router={f7router}
                      handleChangeBalanceTotal={handleChangeBalanceTotal}
                      isBig={false}
                    />
                  ))}
                </div>
              ) : (
                <PrepaidGuideButton
                  f7router={f7router}
                  setPrepaidData={setPrepaidData}
                  prepaidName={prepaidName}
                />
              )}
            </div>
          ) : (
            <div className="prepaid-card-height" />
          )}

          <div className="u-margin-top-lg u-margin-bottom-lg">
            <div className="heading-with-button u-font-size-xxl u-margin-bottom-sm">
              <span className="u-font-bold">クーポン</span>
              <div className="u-margin-left-auto">
                <div
                  className="more-button u-font-bold"
                  onClick={handleShowAllCoupon}
                >
                  全て見る
                  <i className="icon-right" style={{ fontSize: '2rem' }} />
                </div>
              </div>
            </div>
            {couponList.length > 0 &&
              validCouponList.map((coupon) => (
                <PrepaidTicketCoupon
                  key={coupon.m_campaigns_id}
                  couponInfo={coupon}
                  f7router={f7router}
                />
              ))}
          </div>

          {eigyoTanto && (
            <div className="u-margin-top-lg u-margin-bottom-lg">
              <div className="heading-with-button u-font-bold u-font-size-xxl u-margin-bottom-sm">
                担当営業
              </div>
              <div className="prepaid-info-card" style={{ height: '200px' }}>
                <div>
                  <div className="words u-padding-top-sm u-padding-right-sm u-padding-bottom-sm u-padding-left-sm">
                    <span
                      className="u-font-size-xxl"
                      style={{ display: 'block' }}
                    >
                      LOGO
                    </span>
                    <br />
                    販売営業担当
                    <br />
                    三浦翔平
                    <br />
                    mizurasyouhei
                    <br />
                  </div>
                  <div className="photo">{/* <img src="" alt=""> */}</div>
                </div>
              </div>
              <div className="buttons">
                {operatorInfoButtonList.map((button, index) => (
                  <PrepaidButtons
                    key={index}
                    buttonType={button.buttonType}
                    name={button.name}
                    f7router={f7router}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="u-margin-top-lg u-margin-bottom-lg">
            <div className="heading-with-button u-font-bold u-font-size-xxl u-margin-bottom-sm">
              店舗情報
            </div>
            <div className="prepaid-info-card">
              <div className="shop-words u-padding-top-md u-padding-right-sm u-padding-bottom-md u-padding-left-sm">
                {/* <span className="u-font-size-xxl u-padding-bottom-xs u-padding-top-sm" style="display: block; box-sizing: border-box;">LOGO</span><br /> */}
                <div>{customerInfo.unit_name}</div>
                <div>
                  〒{customerInfo.unit_zip_code1}-{customerInfo.unit_zip_code2}{' '}
                  {customerInfo.unit_address}
                </div>
                <div>TEL {customerInfo.unit_tel}</div>
              </div>
            </div>
          </div>
          <div className="buttons">
            {shopInfoButtonList().map((button, index) => (
              <PrepaidButtons
                key={index}
                buttonType={button.buttonType}
                transitionTo={button?.transitionTo}
                name={button.name}
                phoneNumber={button?.phoneNumber}
                f7router={f7router}
                href={button?.href}
              />
            ))}
          </div>
        </div>
      </div>
    </PageTemplateV2>
  );
};
