import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import { Block, Card, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { DriveHistoryToolbar } from '../DriveHistoryToolbar/DriveHistoryToolbar';
import { DriveLogPropertyDisplay } from '../DriveLogPropertyDisplay/DriveLogPropertyDisplay';
import { DrivePropertyDisplay } from '../DrivePropertyDisplay/DrivePropertyDisplay';
import { MapCard } from '../MapCard/MapCard';
import arrivalPointMark from '@/assets/images/menu/map_end_mark.svg';
import departurePointMark from '@/assets/images/menu/map_start_mark.svg';
import { DrivingReportScoreStar } from '@/components/projects/DrivingReportScoreStar/DrivingReportScoreStar';
import { AlertIcon } from '@/components/uiParts/Icon/AlertIcon';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useDrivingRoute } from '@/hooks/api/driveRecord/useDrivingRoute';
import { useHistoryDetail } from '@/hooks/api/driveRecord/useHistoryDetail';
import { Car } from '@/types/api/customerApi';
import { hyphenToDay } from '@/utils/date';
import { isAdContractorData } from '@/utils/home';
import './DriveHistoryDetailStyle.scss';

// タイムスタンプを時間と分数の文字列に変換
// 1669797520901 → 'hh:mm'
const timestampFormat = (timestamp: number) => format(timestamp, 'HH:mm');
const timestampFormatFromDate = (dateStr: string) => {
  const originalDate = parse(
    dateStr,
    "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'",
    new Date(),
  );
  // eslint-disable-next-line no-magic-numbers
  const japTimeInMillis = originalDate.getTime() + 32400000;
  const japDate = new Date(japTimeInMillis);
  return format(japDate, 'HH:mm', { locale: ja });
};

interface DriveHistoryDetailProps {
  f7router: Router.Router;
}

export const DriveHistoryDetail: FC<DriveHistoryDetailProps> = ({
  f7router,
}) => {
  const recordDetailItem = store.state.recordDetailItem;
  const owerId = store.state.linkDriveOwnerInfo.owner_id;
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;

  const isAdContractor = isAdContractorData({
    linkDriveStatus: selectedCar?.linkdrive_status,
  });

  const historyDetail = useHistoryDetail({
    owner_id: owerId,
    t_driving_trip_log_id: recordDetailItem.id,
  });

  const {
    data: drivingRouteData,
    isLoading: routeIsLoading,
    isNoData: routeIsNoData,
  } = useDrivingRoute({
    trip_id: recordDetailItem.id,
    diagnosis_data_id: historyDetail.data?.data.diagnosis_data_id,
  });

  const arrivalPoint = drivingRouteData?.data.arrival_point;
  const departurePoint = drivingRouteData?.data.departure_point;
  const behaviorPoint = drivingRouteData?.data.behavior_point;
  const speedOverTimes = behaviorPoint?.filter(
    (point) => point.behavior_type === 1,
  );
  const suddenAccelerationTimes = behaviorPoint?.filter(
    (point) => point.behavior_type === 2,
  );
  const suddenDecelerationTimes = behaviorPoint?.filter(
    (point) => point.behavior_type === 3,
  );
  const drivingDurationStart =
    store.state.recordDetailItem.driving_duration_start;
  const drivingDurationEnd = store.state.recordDetailItem.driving_duration_end;
  const drivingStartEndTimes = `${drivingDurationStart?.substring(
    11,
    16,
  )}-${drivingDurationEnd?.substring(11, 16)}`;
  const drivingTime = store.state.recordDetailItem.driving_time;
  const hours = store.state.recordDetailItem.hours;
  const minutes = store.state.recordDetailItem.minutes;
  const fuelEfficiency = store.state.recordDetailItem.fuel_efficiency;
  const drivingDistance = store.state.recordDetailItem.driving_distance;
  const phydScoreLevel = store.state.recordDetailItem.phyd_score_level;
  const {
    stars_acceleration: starsAcceleration,
    stars_deceleration: starsDeceleration,
    stars_speed: starsSpeed,
  } = historyDetail.data?.data || {};

  const driving_route = historyDetail.data?.data.driving_route?.sort(
    (a, b) =>
      new Date(a.target_at as string).getTime() -
      new Date(b.target_at as string).getTime(),
  );

  const isLocationAvailable =
    !!arrivalPoint?.lat &&
    !!arrivalPoint?.lng &&
    !!departurePoint?.lat &&
    !!departurePoint?.lng;

  return (
    <PageTemplate
      pageName="DriveHistoryDetail"
      title="運転履歴詳細"
      showNavBack={true}
    >
      {drivingRouteData && (
        <>
          <div className="card">
            <div className="card-content">
              <DriveHistoryToolbar
                date={
                  drivingDurationStart
                    ? hyphenToDay(drivingDurationStart.substring(0, 10))
                    : ''
                }
                time={drivingStartEndTimes}
              />
              {isAdContractor && (
                <>
                  <div id="map-container">
                    {isLocationAvailable ? (
                      <MapCard markers={drivingRouteData} />
                    ) : (
                      <div className="no-location-error-container">
                        <AlertIcon />
                        <p className="u-color-system-error error-message alert-padding-left">
                          位置情報を正常に取得できませんでした
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="map-icon">
                    <div className="acceleration">
                      <span>急アクセル</span>
                    </div>
                    <div className="deceleration">
                      <span>急ブレーキ</span>
                    </div>
                    <div className="speedOver">
                      <span>速度超過</span>
                    </div>
                  </div>
                  <div className="map-icon">
                    <div className="start">
                      <img src={departurePointMark} />
                      <span>運転開始</span>
                    </div>
                    <div className="end">
                      <img src={arrivalPointMark} />
                      <span>運転終了</span>
                    </div>
                  </div>
                  <div style={{ height: '10px', width: '100%' }} />
                </>
              )}
              <DrivePropertyDisplay
                drivingTime={drivingTime}
                hour={String(hours == 0 ? '' : hours)}
                minute={String(minutes)}
                travelDistance={String(drivingDistance)}
                fuelConsumption={String(fuelEfficiency)}
                phydScoreLevel={phydScoreLevel}
                isAdContractor={isAdContractor}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <h3 className="title">運転ログ</h3>
              <div style={{ height: '24px', width: '100%' }} />
              <div className="driving-log-detail-wrapper">
                {/* 出発地点 */}
                {departurePoint && (
                  <div className="driving-log-detail">
                    <span className="time">
                      {timestampFormat(departurePoint.timestamp)}
                    </span>
                    <span className="place">運転開始</span>
                  </div>
                )}

                {/* 急発進、急ブレーキ、速度超過項目 */}
                {behaviorPoint?.map((point, index) => (
                  <>
                    {point.behavior_type === 1 && (
                      <div className="driving-log-detail">
                        <span className="time">
                          {timestampFormat(point.timestamp)}
                        </span>
                        <span className="place-red">
                          <i className="icon-exclamationmark_circle u-padding-right-xs" />
                          速度超過
                        </span>
                      </div>
                    )}
                    {point.behavior_type === 2 && (
                      <div className="driving-log-detail">
                        <span className="time">
                          {timestampFormat(point.timestamp)}
                        </span>
                        <span className="place-red">
                          <i className="icon-exclamationmark_circle u-padding-right-xs" />
                          急アクセル
                        </span>
                      </div>
                    )}
                    {point.behavior_type === 3 && (
                      <div className="driving-log-detail">
                        <span className="time">
                          {timestampFormat(point.timestamp)}
                        </span>
                        <span className="place-red">
                          <i className="icon-exclamationmark_circle u-padding-right-xs" />
                          急ブレーキ
                        </span>
                      </div>
                    )}
                  </>
                ))}

                {/* 到着地点 */}
                {arrivalPoint && (
                  <div className="driving-log-detail">
                    <span className="place">
                      {timestampFormat(arrivalPoint.timestamp)}
                    </span>
                    <span className="place">運転終了</span>
                  </div>
                )}
                <div style={{ height: '16px', width: '100%' }} />
              </div>
              <div style={{ height: '16px', width: '100%' }} />
              <DriveLogPropertyDisplay
                numberOfSuddenAcceleration={
                  suddenAccelerationTimes?.length || 0
                }
                numberOfSuddenDeceleration={
                  suddenDecelerationTimes?.length || 0
                }
                speedOverTimes={speedOverTimes?.length || 0}
                drivingScore={phydScoreLevel}
                isAdContractor={isAdContractor}
              />
              {isAdContractor && (
                <InfoCard
                  className="valuation_card"
                  url={paths.evaluationDetails}
                  text={'安全運転スコアの算出基準と\nアラートの判定基準'}
                  isElevated={false}
                  iconType=""
                  bgColor="muted"
                  isIconRight={true}
                />
              )}
            </div>
          </div>
          {isAdContractor && (
            <div className="card">
              <div className="card-content">
                <h3 className="title">運転評価詳細</h3>
                <div id="history-detail" className="score-container">
                  <DrivingReportScoreStar
                    justifyContent="space-between"
                    fontSize="1.6rem"
                    scoreList={[
                      {
                        type: 'スピードに関する評価',
                        score: starsSpeed || 0,
                      },
                      {
                        type: 'アクセルに関する評価',
                        score: starsAcceleration || 0,
                      },
                      {
                        type: 'ブレーキに関する評価',
                        score: starsDeceleration || 0,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {isAdContractor && !routeIsLoading && routeIsNoData && (
        <Block style={{ marginTop: '61px' }}>
          <Card
            style={{ borderRadius: '20px' }}
            className="skeleton-text"
            title="Card Header"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit."
            footer="Card Footer"
          />
        </Block>
      )}

      {!isAdContractor && (
        <>
          <div className="card">
            <div className="card-content">
              <DriveHistoryToolbar
                date={
                  drivingDurationStart
                    ? hyphenToDay(drivingDurationStart.substring(0, 10))
                    : ''
                }
                time={drivingStartEndTimes}
              />
              <DrivePropertyDisplay
                drivingTime={drivingTime}
                hour={String(hours == 0 ? '' : hours)}
                minute={String(minutes)}
                travelDistance={String(drivingDistance)}
                fuelConsumption={String(fuelEfficiency)}
                phydScoreLevel={phydScoreLevel}
                isAdContractor={isAdContractor}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <h3 className="title">運転ログ</h3>
              <div style={{ height: '24px', width: '100%' }} />
              <div className="driving-log-detail-wrapper">
                {/* 急発進、急ブレーキ、速度超過項目 */}
                {driving_route?.map((point, index) => (
                  <>
                    <div className="driving-log-detail">
                      <span
                        className={
                          index === driving_route.length - 1 &&
                          Number(point.rapid_acceleration_times) <= 0 &&
                          Number(point.rapid_deceleration_times) <= 0
                            ? 'place'
                            : 'time'
                        }
                      >
                        {timestampFormatFromDate(point.target_at as string)}
                      </span>
                      <span className="place">{point.address}</span>
                    </div>
                    {Number(point.rapid_acceleration_times) > 0 && (
                      <div className="driving-log-detail">
                        <span
                          className={
                            index === driving_route.length - 1 &&
                            Number(point.rapid_deceleration_times) <= 0
                              ? 'place'
                              : 'time'
                          }
                        >
                          {timestampFormatFromDate(point.target_at as string)}
                        </span>
                        <span className="place-red">
                          <i className="icon-exclamationmark_circle u-padding-right-xs" />
                          急アクセル
                        </span>
                      </div>
                    )}
                    {Number(point.rapid_deceleration_times) > 0 && (
                      <div className="driving-log-detail">
                        <span
                          className={
                            index === driving_route.length - 1
                              ? 'place'
                              : 'time'
                          }
                        >
                          {timestampFormatFromDate(point.target_at as string)}
                        </span>
                        <span className="place-red">
                          <i className="icon-exclamationmark_circle u-padding-right-xs" />
                          急ブレーキ
                        </span>
                      </div>
                    )}
                  </>
                ))}
                <div style={{ height: '16px', width: '100%' }} />
              </div>
              <div style={{ height: '16px', width: '100%' }} />
              <DriveLogPropertyDisplay
                numberOfSuddenAcceleration={
                  historyDetail.data?.data?.driving_route?.reduce(
                    (acc, route) => acc + (route.rapid_acceleration_times || 0),
                    0,
                  ) || 0
                }
                numberOfSuddenDeceleration={
                  historyDetail.data?.data?.driving_route?.reduce(
                    (acc, route) => acc + (route.rapid_deceleration_times || 0),
                    0,
                  ) || 0
                }
                speedOverTimes={speedOverTimes?.length || 0}
                drivingScore={phydScoreLevel}
                isAdContractor={isAdContractor}
              />
            </div>
          </div>
        </>
      )}
    </PageTemplate>
  );
};
