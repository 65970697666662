import { FC } from 'react';
import style from './DrivingReportScoreStar.module.css';

interface ScoreType {
  type: string;
  score: number;
}

interface DrivingReportScoreStarProps {
  scoreList?: ScoreType[];
  justifyContent?: string;
  fontSize?: string;
}

export const DrivingReportScoreStar: FC<DrivingReportScoreStarProps> = ({
  scoreList,
  justifyContent = 'normal',
  fontSize = '1rem',
}) => {
  const starList = [1, 2, 3, 4, 5];
  return (
    <div className={style.scoreStarsWrapper}>
      {scoreList?.map((score, scoreIndex) => (
        <div
          key={scoreIndex}
          className={style.starsLabel}
          style={{ justifyContent, fontSize }}
        >
          <span className={style.starType}>{score.type}</span>
          <div className={style.starsWrapper}>
            {starList.map((star) => (
              <span
                key={star}
                className={`${
                  star < score.score + 1
                    ? style.starsColorBlue
                    : style.starsColorDefault
                } ${style.starFontSize}`}
              >
                {star < score.score + 1 ? ' ★ ' : ' ☆ '}
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
