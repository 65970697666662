import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { updateWarrantyStep } from '../UpdateWarrantyDetail/updateWarrantyDetailHelper';
import { UpdateWarrantyStep1Page } from './UpdateWarrantyStep1/UpdateWarrantyStep1Page';
import { UpdateWarrantyStep2Page } from './UpdateWarrantyStep2/UpdateWarrantyStep2Page';
import { UpdateWarrantyStep3Page } from './UpdateWarrantyStep3/UpdateWarrantyStep3Page';
import { UpdateWarrantyStep4Page } from './UpdateWarrantyStep4/UpdateWarrantyStep4Page';
import { UpdateWarrantyStep5Page } from './UpdateWarrantyStep5/UpdateWarrantyStep5Page';
import { UpdateWarrantyStep6Page } from './UpdateWarrantyStep6/UpdateWarrantyStep6Page';
import { Dialog } from '@/components/uiParts/Dialog/Dialog';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { f7CustomBack } from '@/utils/utils';

type UpdateWarrantyPageProps = {
  f7router: Router.Router;
  warrantyNo: string;
};

export const UpdateWarrantyPage: FC<UpdateWarrantyPageProps> = ({
  warrantyNo,
  f7router,
}) => {
  const [activeStep, setActiveStep] = useState<number>(
    updateWarrantyStep.STEP_ONE,
  );
  const [showDialog, setShowDialog] = useState(false);

  const cancelConfirmText =
    '入力した内容が破棄されます。<br />本当に中止しますか？';

  const setStep = (n: number) => {
    setActiveStep(n);
  };

  const handleOpen = () => {
    setShowDialog(true);
  };

  const handleClosed = () => {
    setShowDialog(false);
  };

  const handleBack = () => {
    f7CustomBack(['#none']);
  };

  return (
    <PopupPageTemplate
      id={showDialog ? 'none' : 'UpdateWarranty'}
      pageName="UpdateWarranty"
      title="プラン変更"
      handleBack={handleOpen}
    >
      {activeStep === updateWarrantyStep.STEP_ONE && (
        <UpdateWarrantyStep1Page setStep={setStep} warrantyNo={warrantyNo} />
      )}
      {activeStep === updateWarrantyStep.STEP_TOW && (
        <UpdateWarrantyStep2Page setStep={setStep} f7router={f7router} />
      )}
      {activeStep === updateWarrantyStep.STEP_THREE && (
        <UpdateWarrantyStep3Page setStep={setStep} f7router={f7router} />
      )}
      {activeStep === updateWarrantyStep.STEP_FOUR && (
        <UpdateWarrantyStep4Page setStep={setStep} />
      )}
      {activeStep === updateWarrantyStep.STEP_FIVE && (
        <UpdateWarrantyStep5Page
          f7router={f7router}
          warrantyNo={warrantyNo}
          setStep={setStep}
        />
      )}
      {activeStep === updateWarrantyStep.STEP_SIX && (
        <UpdateWarrantyStep6Page
          f7router={f7router}
          warrantyNo={warrantyNo}
          setStep={setStep}
        />
      )}

      {showDialog && (
        <Dialog
          title="プランの変更を中止します"
          subTitle={cancelConfirmText}
          buttonOkText="変更を中止する"
          handleOk={handleBack}
          handleCancel={handleClosed}
        />
      )}
    </PopupPageTemplate>
  );
};
