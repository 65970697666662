import { Popup } from 'framework7-react';
import { FC } from 'react';
import style from './SuccessPopupStyle.module.css';
import CheckIcon from '@/assets/images/reservation-status/status11.svg';

interface SuccessPopupProps {
  opened: boolean;
  handleClose?: () => void;
}

export const SuccessPopup: FC<SuccessPopupProps> = ({
  opened = false,
  handleClose,
}) => {
  return (
    <Popup
      className={style['popup-success']}
      opened={opened}
      onPopupClosed={handleClose}
      backdrop
      closeByBackdropClick
      style={{ display: opened ? 'block' : 'none' }}
    >
      <i className="icon-close" onClick={handleClose} />
      <img src={CheckIcon} alt="Check" />
      <h3 className={style['title']}>受け取りの銀行口座登録が完了しました</h3>
    </Popup>
  );
};
