import { endOfMonth, parse, startOfMonth, subMonths } from 'date-fns';
import { formatToJaDate, isInvalidDate } from '@/utils/date';

export const makeCancelableWarrantyObj = (
  warrantyEndDate: string | undefined,
) => {
  const cancelableWarrantyObj = {
    warrantyEndDate: warrantyEndDate || '',
    cancelEnableTermStart: '',
    cancelEnableTermEnd: '',
  };

  const date = parse(
    cancelableWarrantyObj.warrantyEndDate,
    'yyyy-MM-dd',
    new Date(),
  );

  if (!cancelableWarrantyObj.warrantyEndDate || isInvalidDate(date))
    return cancelableWarrantyObj;

  // 3ヶ月前の月の最初の日付
  cancelableWarrantyObj.cancelEnableTermStart = formatToJaDate(
    // eslint-disable-next-line no-magic-numbers
    startOfMonth(subMonths(date, 3)),
  );

  // 2ヶ月前の月の最後の日付
  cancelableWarrantyObj.cancelEnableTermEnd = formatToJaDate(
    // eslint-disable-next-line no-magic-numbers
    endOfMonth(subMonths(date, 2)),
  );

  return cancelableWarrantyObj;
};
