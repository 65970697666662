import { secondsInHour } from 'date-fns';
import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './DriveHistoryItemStyle.module.scss';
import { paths } from '@/config/paths';
import { storeDispatch } from '@/utils/store';
import { getHMS } from '@/utils/utils';

export interface DriveHistoryItemProps {
  driving_duration_start: string;
  driving_duration_end: string;
  driving_time: number;
  hours: number;
  minutes: number;
  driving_distance: number;
  fuel_efficiency: number;
  phyd_score_level?: string;
  status: string;
  id: number;
}

export const DriveHistoryItem: FC<DriveHistoryItemProps> = ({
  driving_duration_start,
  driving_duration_end,
  driving_time,
  hours,
  minutes,
  driving_distance,
  fuel_efficiency,
  phyd_score_level,
  status,
  id,
}) => {
  const handleClickHistoryItem = () => {
    storeDispatch('setRecordDetailItem', 'recordDetailItem', {
      id,
      driving_duration_start,
      driving_duration_end,
      driving_time,
      hours,
      minutes,
      driving_distance,
      fuel_efficiency,
      phyd_score_level,
    });
    f7.view.main.router.navigate(paths.driveHistoryDetail, { animate: true });
  };

  return (
    <div className={style['drive-history']} onClick={handleClickHistoryItem}>
      <div className={`${style['drive-history_data']}`}>
        <div
          className={`${style['drive-history_data_duration']} u-padding-bottom-xs`}
        >
          <div className="drive-history_data_duration_time_start">
            {driving_duration_start != null
              ? driving_duration_start.substring(11, 16)
              : '-'}
          </div>
          <div>-</div>
          <div className="drive-history_data_duration_end u-padding-right-xs">
            {driving_duration_end != undefined
              ? driving_duration_end.substring(11, 16)
              : '-'}
          </div>
          <div className={`${style['drive-history_data_duration_all']}`}>
            {driving_time > secondsInHour ? (
              <>
                <span>{getHMS(driving_time, 'hour') ?? '-'}</span>
                <span style={{ fontSize: '1.2rem' }}> 時間 </span>
              </>
            ) : (
              <></>
            )}
            <span>{getHMS(driving_time, 'minute') ?? '-'}</span>
            <span style={{ fontSize: '1.2rem' }}> 分</span>
          </div>
        </div>
        <div className={`${style['history_data_other_info']}`}>
          走行{' '}
          <span>{driving_distance != null ? driving_distance : '-'} km</span>
        </div>
      </div>
      <div className={`${style['drive-history_data_duration_icon']}`}>
        <i className="icon-right" />
      </div>
    </div>
  );
};
