import { DetailedHTMLProps, FC, TextareaHTMLAttributes } from 'react';
import { CheckIcon } from '../../Check/Check';
import { Label } from '../../Label/Label';
import { RhfTextarea } from '../RhfTextarea/RhfTextarea';
import style from './rhfTextareaWithLabelStyle.module.css';

interface RhfTextareaWithLabelProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string;
  label?: string;
  required?: boolean;
  error?: string;
  index?: number;
  isValid?: boolean;
}

export const RhfTextareaWithLabel: FC<RhfTextareaWithLabelProps> = ({
  name,
  label = '',
  required = false,
  error = undefined,
  isValid = undefined,
  ...rest
}) => {
  return (
    <div className={style['container']}>
      <div className={style['label-wrapper']}>
        <span className={style['label']}>{label}</span>
        <Label required={required} />
      </div>
      <div className={style['textarea-check-wrapper']}>
        <div className={`${style['textarea-wrapper']} textarea-wrapper`}>
          <RhfTextarea
            rows={10}
            name={name}
            className={style['textarea']}
            {...rest}
          />
          {error && <div className="item-input-error-message">{error}</div>}
        </div>
        {isValid !== undefined && <CheckIcon isValid={isValid} />}
      </div>
    </div>
  );
};
