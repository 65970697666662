import { f7 } from 'framework7-react';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { OptionsCheckListType } from './DeliveryCheckList';
import style from './DeliveryCheckStyle.module.scss';
import { ControlerCheckbox } from '@/components/uiParts/Form';
import { paths } from '@/config/paths';
interface DeliveryCheckItemProps extends OptionsCheckListType {
  options: OptionsCheckListType[];
  idx: number;
}

export const DeliveryCheckItem: FC<DeliveryCheckItemProps> = ({
  id,
  title,
  description,
  name,
  options,
  idx,
}) => {
  const { setValue, watch } = useFormContext();

  const handleClickNavigation = () => {
    f7.view.main.router.navigate(paths.userTasks, {
      props: { options, idx, setValue, watch },
      animate: true,
    });
  };
  return (
    <div className={style.wrraper}>
      <ControlerCheckbox name={name} disabled />
      <div
        className="display-flex-sb"
        style={{ gap: '2rem', flex: 1 }}
        onClick={handleClickNavigation}
      >
        <div>
          <p className={style.title}>
            {id}.{title}
          </p>
          <p className={style.content}>{description}</p>
        </div>
        <i className={`icon-right u-font-size-xxl`} />
      </div>
    </div>
  );
};
