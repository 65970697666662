import * as Sentry from '@sentry/react';
import { f7, useStore } from 'framework7-react';
import { useCallback, useEffect, useState } from 'react';
import { useLoginCount } from './useLoginCount';
import { multipleAffiliateInfo } from '@/api/authApi';
import { customerInfoApi } from '@/api/customerApi';
import { linkDriveOwnerApi } from '@/api/infoApi';
import { isApp } from '@/config/device';
import { store } from '@/config/store';
import { loginType } from '@/consts/login';
import { affiliateId as AFFILIATE_ID } from '@/consts/shop';
import { url } from '@/consts/url';
import { useToast } from '@/hooks/useToast';
import { Affiliate } from '@/types/api/authApi';
import {
  Car,
  CustomerInfoApiParams,
  CustomerInfoApiResponse,
} from '@/types/api/customerApi';
import {
  handleMaikuruProtocolDeeplink,
  parseURL,
} from '@/utils/deeplinkHelper';
import { on } from '@/utils/eventHelper';
import { getThemeIdFromAffiliateId } from '@/utils/shop';
import { dispatchBasicInfo, storeDispatch } from '@/utils/store';
import { getKeyByValue } from '@/utils/utils';

export const getAndSetAffiliate = async (
  parameter?: string,
  defaultValue?: Affiliate[],
) => {
  const m_customers: Affiliate[] = [];
  if (parameter) {
    const { data: affiliatesInfo } = await multipleAffiliateInfo({
      parameter,
    });
    m_customers.push(...affiliatesInfo.m_customers);
  } else {
    m_customers.push(...(defaultValue || []));
  }
  await storeDispatch('setAffiliatesInfo', 'affiliatesInfo', m_customers);

  return m_customers;
};

export const getAndSetCustomerInfo = async (
  params: CustomerInfoApiParams,
  findFunc?: (value: Car) => unknown,
) => {
  const { data: customerInfo } = await customerInfoApi(params);
  if (!customerInfo.success) {
    throw new Error();
  }
  await dispatchBasicInfo(customerInfo, findFunc);
  await storeDispatch('setIsInfoLoaded', 'isInfoLoaded', true);
  await setThemeIdFromAffiliateId(customerInfo.customer?.m_affiliate_id);
  await setStoreLinkDriveOwner(customerInfo.cars || []);
  return customerInfo;
};

const setStoreLinkDriveOwner = async (cars?: Car[]) => {
  if (!cars?.length) return;
  try {
    const { data: linkDriveOwner } = await linkDriveOwnerApi({
      params: {
        customer_id: store.state.authInfo.m_customer_id,
        stock_car_id: cars[0].t_stock_car_id,
        del_flg: 0,
      },
    });
    if (
      linkDriveOwner?.error &&
      linkDriveOwner.error.message !== 'LinkDriveOwnerなし'
    ) {
      throw new Error(linkDriveOwner.error.message);
    }
    await storeDispatch(
      'setLinkDriveOwnerInfo',
      'linkDriveOwnerInfo',
      linkDriveOwner?.data?.link_drive_owner,
    );
    await storeDispatch(
      'setOwnerId',
      'ownerId',
      linkDriveOwner?.data?.link_drive_owner.owner_id,
    );
    await storeDispatch(
      'setSerialNumberDb',
      'serialNumberDb',
      linkDriveOwner?.data?.link_drive_owner.serial_no,
    );
    return linkDriveOwner;
  } catch (e: unknown) {
    console.log('ERROR: ', e);
    if (e instanceof Error) {
      console.error(e.message);
    }
  }
};

const setThemeIdFromAffiliateId = async (affiliateId: number | undefined) => {
  if (affiliateId && getKeyByValue(AFFILIATE_ID, affiliateId)) {
    const themeId = store.state.globalTheme
      ? store.state.globalTheme
      : getThemeIdFromAffiliateId(affiliateId);
    await storeDispatch('setThemeId', 'themeId', themeId);
  }
};

export const useCustomerInfo = (
  params: CustomerInfoApiParams,
  autoRun: boolean = true,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const { openToast } = useToast();
  const { callCountLoginApi } = useLoginCount(params.m_customer_id);
  const storeCarItemInfo = useStore(store, 'carItemInfo');
  const affiliatesInfo = useStore(store, 'affiliatesInfo') as Affiliate[];

  const setCustomerInfo = useCallback(
    async (
      nextParams?: CustomerInfoApiParams,
      findFnc?: (value: Car) => unknown,
    ) => {
      try {
        const { data: customerInfo } = await customerInfoApi(
          nextParams || params,
        );
        if (!customerInfo.success) {
          throw new Error();
        }
        // Set sentry user id
        Sentry.setUser({
          id: customerInfo?.customer?.id,
        });
        let parameter = '';
        if (store.state.loginInfo?.loginType === 'email') {
          parameter = customerInfo?.customer?.email || '';
        }
        if (store.state.loginInfo?.loginType === 'telephone') {
          parameter = customerInfo?.customer?.phone_no1 || '';
        }
        if (store.state.loginInfo?.loginType === 'admin') {
          parameter =
            customerInfo?.customer?.email ||
            customerInfo?.customer?.phone_no1 ||
            '';
        }
        const defaultAffiliate: Affiliate[] =
          affiliatesInfo && affiliatesInfo?.length
            ? affiliatesInfo
            : [
                {
                  id: customerInfo.customer?.id || 0,
                  affiliate_name: customerInfo.customer?.affiliate_name || '',
                  affiliate_app_type: 0,
                  cars:
                    customerInfo.cars?.map((car) => ({
                      car_nm: car.car_nm || '',
                      class_no: car.class_no || '',
                      file_path: car.file_path || null,
                      grade: car.grade || '',
                      hiragana: car.hiragana || '',
                      m_car_id: car.m_car_id || null,
                      maker_nm: car.maker_nm || '',
                      registry_no: car.registry_no || null,
                      user_upload_image_file_path:
                        car.user_upload_image_file_path || null,
                      t_order_id: car.t_order_id || null,
                    })) || [],
                  m_affiliate_id: customerInfo.customer?.m_affiliate_id || 0,
                },
              ];
        await getAndSetAffiliate(parameter, defaultAffiliate);
        const eventListener = () => {
          getAndSetAffiliate(parameter, defaultAffiliate);
        };
        on('getAndSetAffiliate', eventListener);

        await dispatchBasicInfo(customerInfo, findFnc);

        // ResponseをNative側へ保存する
        if (isApp) {
          const desiredUrl = `${url.NATIVE_BASE_URL}/set/login_success?token=${
            store.state.authInfo.token
          }&id=${customerInfo?.customer?.id}&type=${
            store.state.loginInfo?.loginType || loginType.EMAIL
          }`;
          window.location.href = desiredUrl;
        }

        return customerInfo;
      } catch (e) {
        openToast('基本情報の取得に失敗しました', 'toast-failed');
        f7.views.main.router.navigate('/');
      }
    },
    [openToast, params],
  );

  const refetch = async () => {
    try {
      const customerInfo = await setCustomerInfo(params);
      if (!customerInfo) return;
      f7.preloader.show();
      setIsLoading(true);
      await setThemeIdFromAffiliateId(customerInfo.customer?.m_affiliate_id);
      await setStoreLinkDriveOwner(customerInfo.cars);
      // restrict the login count. Only count when logged in first time not after redirection
      // if (loginCount)
      if (customerInfo.cars?.[0]) {
        await callCountLoginApi(customerInfo.cars[0]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  const getCustomerWithParams = async (
    nextParams: CustomerInfoApiParams,
    findFnc?: (value: Car) => unknown,
  ) => {
    let customerInfo: CustomerInfoApiResponse['data'] | undefined = undefined;
    try {
      f7.preloader.show();
      setIsLoading(true);
      customerInfo = await setCustomerInfo(nextParams, findFnc);
      if (!customerInfo) return undefined;
      await store.dispatch('setAuthInfo', {
        authInfo: {
          ...store.state.authInfo,
          m_customer_id: customerInfo?.customer?.id,
        },
      });
      await storeDispatch('setIsInfoLoaded', 'isInfoLoaded', true);
      await setThemeIdFromAffiliateId(customerInfo.customer?.m_affiliate_id);
      if (customerInfo.cars?.[0]) {
        await callCountLoginApi(customerInfo.cars[0]);
      }
      // await setStoreLinkDriveOwner(customerInfo.cars);
    } catch (e: unknown) {
      setError(e);
      console.error(e);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
      return customerInfo;
    }
  };

  useEffect(() => {
    if ((store.state.authInfo.isLogin && store.state.isInfoLoaded) || !autoRun)
      return;
    const fn = async () => {
      try {
        f7.preloader.show();
        setIsLoading(true);
        const customerInfo = await setCustomerInfo();
        if (!customerInfo) return;
        await storeDispatch('setIsInfoLoaded', 'isInfoLoaded', true);
        await setThemeIdFromAffiliateId(customerInfo.customer?.m_affiliate_id);
        if (customerInfo.cars?.[0]) {
          await callCountLoginApi(customerInfo.cars[0]);
        }
        await setStoreLinkDriveOwner(customerInfo.cars);
        const deeplinkURL = store.state.deeplink;

        if (deeplinkURL) {
          const linkURL = parseURL(deeplinkURL);
          const m_customer_id = linkURL.searchParams.get('m_customer_id');
          if (
            !m_customer_id ||
            Number(m_customer_id) !== customerInfo.customer?.id
          ) {
            return;
          }
          handleMaikuruProtocolDeeplink(linkURL);
          storeDispatch('setDeeplink', 'deeplink', '');
        }
      } catch (e: unknown) {
        setError(e);
        console.error(e);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
    // mount時にだけ呼び出すため,openToastとsetCustomerInfoを省略
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
    setCustomerInfo,
    refetch,
    getCustomerWithParams,
  };
};
