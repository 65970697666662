import { List, ListItem } from 'framework7-react';
import { FC } from 'react';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import './roadserviceStyle.scss';

export const Roadservice: FC = () => {
  return (
    <PageTemplate
      title="ロードサービス内容"
      pageName="roadservise"
      showNavBack={true}
    >
      <div className="u-font-size-lg u-margin-bottom-sm u-margin-top-sm">
        <div className="u-margin-bottom-s u-margin-top-s">
          <p className="subtitle1">下記の作業を無料で承ります。</p>
          <p className="u-font-size-lg u-margin-bottom-s u-margin-top-s subtitle2">
            ※一部有料での対応となります。
            <br />
            詳しくは、各項目の詳細をご確認ください。
          </p>
        </div>
      </div>
      <div className="card card-outline">
        <div className="card-header">レッカー牽引</div>
        <div className="card-content-padding">
          事故や故障により、自力での走行ができないとき、
          <span style={{ fontWeight: 'bold', color: '#0058D5' }}>
            最大50kmまで無料
          </span>
          搬送致します。
          <br />
          ※事故による不動時も対応いたします。
          <p
            className="u-margin-top-s"
            style={{ color: 'gray', fontSize: '12px', marginTop: '10px' }}
          >
            ※超過分および高速道路利用料金は有料となります
          </p>
        </div>
      </div>
      <div className="card card-outline">
        <div className="card-header">キーとじこみ開錠作業</div>
        <div className="card-content-padding">
          車の鍵を車内においたままドアをロックして外に出てしまった場合のドアの開錠をいたします。
          <br />
          一般キーシリンダーの開錠は無料で対応いたします。
          <div className="u-margin-top-sm">
            ※下記の開錠作業は有料となります
            <div className="u-padding-left-sm u-padding-right-sm u-margin-top-s u-margin-bottom-0">
              <ul className="listtop">
                <li>
                  <span className="listitem">トランクの直接開錠作業</span>
                </li>
                <li>
                  <span className="listitem">特殊構造キーの場合</span>
                </li>
                <li>
                  <span className="listitem">特殊構造キーの場合</span>
                </li>
                <li>
                  <span className="listitem">盗難防止装置付き車両の場合</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-outline">
        <div className="card-header">パンク時スペアタイヤ交換</div>
        <div className="card-content-padding">
          お持ちのスペアタイヤとの交換作業を、無料で対応いたします。
          <p className="u-margin-top-sm">
            ※スペアタイヤをお持ちでない場合、タイヤ代はお客様負担となります
          </p>
        </div>
      </div>
      <div className="card card-outline">
        <div className="card-header">ガス欠時給油</div>
        <div className="card-content-padding">
          ガソリンがなくなった場合に、給油作業を行います。（ディーゼル車の場合、エア抜き作業も含みます。）
          <p className="u-margin-top-sm small">※燃料代はお客様負担となります</p>
        </div>
      </div>
      <div className="card card-outline">
        <div className="card-header">バッテリー上がりの解消</div>
        <div className="card-content-padding">
          ランプの消し忘れなどにより、車の電力を使いすぎてエンジンがかからなくなってしまった時に、バッテリーの復旧作業を行います。
          <p className="u-margin-top-sm small">※電気代はお客様負担となります</p>
        </div>
      </div>
      <div className="card card-outline">
        <div className="card-header">各種遠方サポートサービス</div>
        <div className="card-content-padding">
          <p className="list-border list-padding">
            出先でロードサービスをご利用の際、移動や宿泊のためのサポートを承ります。
          </p>
          <div className="u-margin-top-s u-margin-bottom-0">
            <List mediaList>
              <ListItem title="レンタカーサポート" className="list-border">
                <p className="list-sub">
                  ロードサービス会社指定のレンタカーが
                  <span style={{ fontWeight: 'bold', color: '#0058D5' }}>
                    基本料金無料
                  </span>
                  となります。
                </p>
              </ListItem>
              <ListItem title="代替公共機関サポート" className="list-border">
                <p className="list-sub">
                  会員様、および同乗者様
                  <span style={{ fontWeight: 'bold', color: '#0058D5' }}>
                    一人当たり20,000円（税込）まで交通費を負担
                  </span>
                  いたします。
                </p>
              </ListItem>
              <ListItem title="緊急宿泊費用サポート" className="list-border">
                <p className="list-sub">
                  会員および同乗者
                  <span style={{ fontWeight: 'bold', color: '#0058D5' }}>
                    一人当たり15,000円（税込）まで宿泊費用を負担
                  </span>
                  いたします。
                </p>
              </ListItem>
              <ListItem title="">
                <p>※それぞれ別途規定がございます</p>
                <p className="list-span">
                  （詳細はロードサービス利用規約第８条をご確認ください）
                </p>
              </ListItem>
              <ListItem
                title="ロードサービス利用規約"
                link="/roadservice_terms/"
                className="termsbutton"
              />
            </List>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};
