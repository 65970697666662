import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { PaymentsHistory } from './PaymentsHistory/PaymentsHistory';
import { store } from '@/config/store';
import { OUTPUT_FLAG, historyType, noPayFlg } from '@/consts/payment';
import { BillInformationDetail } from '@/types/api/customerApi';
import { HistoryType } from '@/types/payment';

interface PaymentsHistoryPageProps {
  f7router: Router.Router;
  warranty_no: string;
}
export const PaymentsHistoryPage: FC<PaymentsHistoryPageProps> = ({
  f7router,
  warranty_no,
}) => {
  const id = warranty_no;
  const bills = store.state.billsInfo;
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const [billDetails, setBillDetails] = useState<BillInformationDetail[]>([]);

  const filterBillsByHistoryType = (type?: HistoryType) => {
    switch (type) {
      case historyType.WARRANTY:
        return bills.filter((bill) => bill.warranty_no === id);
      case historyType.PREPAID:
        return bills.filter((bill) => bill.m_prepaid_id === Number(id));
      default:
        return bills;
    }
  };

  const filterBillDetails = (historyTypeProp: HistoryType) => {
    const details: BillInformationDetail[] = [];
    filterBillsByHistoryType(historyTypeProp).forEach((bill) => {
      bill.bill_infomation_details.forEach((detail) => {
        const billDate = new Date(detail.billing_date as string);
        if (
          billDate < now &&
          bill.warranty_no !== null &&
          (detail.no_pay_flg === noPayFlg.BEFORE_PAYMENT ||
            detail.no_pay_flg === noPayFlg.PAYMENT_CONFIRMED ||
            (detail.output_flg === OUTPUT_FLAG.invoice &&
              detail.no_pay_flg === noPayFlg.UNTREATED))
        ) {
          detail.warranty_no = bill.warranty_no;
          detail.m_prepaid_id = bill.m_prepaid_id;
          details.push(detail);
        }
      });
    });

    return details.sort(
      (a, b) =>
        new Date(b.billing_date as string).getTime() -
        new Date(a.billing_date as string).getTime(),
    );
  };

  useEffect(() => {
    setBillDetails(
      filterBillDetails(
        f7router.currentRoute.query?.historyType as HistoryType,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [f7router]);

  return <PaymentsHistory f7router={f7router} billDetails={billDetails} />;
};
