import { AxiosError } from 'axios';
import { useState } from 'react';
import { updateCustomerApi } from '@/api/customerApi';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import { UpdateWarrantyCustomerParams } from '@/types/api/warrantyApi';

interface Args {
  params: UpdateWarrantyCustomerParams;
  onSuccess?: () => void;
}

export const useUpdateCustomer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { openToast } = useToast();
  const [error, setError] = useState<AxiosError>();
  const updateCustomer = async (args: Args) => {
    try {
      setIsLoading(true);
      const { data } = await updateCustomerApi(args.params);
      if (data.success) {
        store.dispatch('setCustomerInfo', {
          customerInfo: args.params.customer,
        });
        openToast('お客様基本情報を更新しました');
        args.onSuccess?.();
      }
      if (!data.success) openToast('更新に失敗しました', 'toast-failed');
    } catch (error) {
      setError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateCustomer,
    isLoading,
    error,
  };
};
