import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  firstName: yup.string().required('お名前は必須です'),
  lastName: yup.string().required('お名前は必須です'),
  kanaLastName: yup
    .string()
    .required('フリガナは必須です')
    .matches(/^([ァ-ン]|ー)+$/, { message: '全角カタカナで入力してください' }),
  kanaFirstName: yup
    .string()
    .required('フリガナは必須です')
    .matches(/^([ァ-ン]|ー)+$/, { message: '全角カタカナで入力してください' }),
  phoneNumber: yup
    .string()
    .required('電話番号は必須です')
    .matches(/^0\d{9,10}$/, { message: '正しい電話番号を入力してください' }),
  email: yup
    .string()
    .required('メールアドレスは必須です')
    .email('正しいメールアドレスを入力してください'),
});
