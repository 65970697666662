import { f7 } from 'framework7-react';
import React, { FC, useEffect, useState } from 'react';
import style from './ImplementScan.module.css';
import { Button } from '@/components/uiParts/Button';
import { ProgressCircleCar } from '@/components/uiParts/ProgressCircleCar/ProgressCircleCar';
import { paths } from '@/config/paths';
import { useUpdateProgress } from '@/hooks/useUpdateProgress';

interface ImplementScanProps {
  progress: number;
  isSelfScanCompleted: boolean;
  isDuringScanning: boolean;
  messageErrorScan: string;
  isUpdating: boolean;
}

export const ImplementScan: FC<ImplementScanProps> = ({
  progress,
  isSelfScanCompleted,
  isDuringScanning,
  messageErrorScan,
  isUpdating,
}) => {
  const [progressPath, setProgressPath] = useState<SVGPathElement | null>(null);

  const { updateProgress, progress: progressDetail } =
    useUpdateProgress(progressPath);

  useEffect(() => {
    const progressSVG = document.querySelector<SVGPathElement>('#progress');
    if (progressSVG != null) {
      const pathLength = progressSVG.getTotalLength();
      progressSVG.style.strokeDasharray = pathLength.toString();
      progressSVG.style.strokeDashoffset = pathLength.toString();
      setProgressPath(progressSVG);
    }
    updateProgress(progress);
  }, [updateProgress, progress]);

  const handleClickNavigationScanHistory = () => {
    f7.view.main.router.navigate(paths.scanHistory, { animate: true });
  };

  const handleClickClosePopup = () => {
    f7.view.main.router.navigate(paths.home, { animate: false });
  };

  return (
    <div>
      <div className={style['check-list']}>
        <div className={`${style['check-paragraph']} ${style.inactive}`}>
          <i className={`icon-check`} />
          <p>安全を確認してください</p>
        </div>
        <div className={`${style['check-paragraph']} ${style.inactive}`}>
          <i className={`icon-check`} />
          <p>エンジンを始動してください</p>
        </div>
        {!isDuringScanning && (
          <div className={`${style['check-paragraph']} ${style.inactive}`}>
            <i className={`icon-check`} />
            <p>スキャンを実施します</p>
          </div>
        )}
      </div>
      <div className={style['corresponding-checklist']}>
        <div className={`${style.heading} ${style['check-paragraph']}`}>
          <i className={`icon-check`} />
          <h2>
            {isDuringScanning ? '診断を実施します' : 'スキャンを実施しました'}
          </h2>
        </div>

        <div className={style.loading}>
          <div
            style={{
              width: '120px',
              position: 'relative',
              margin: '0 auto',
            }}
          >
            <ProgressCircleCar id={'progress'} progress={progressDetail} flip />
          </div>
          <p className={style.mainText}>{progress < 100 && ``}</p>
        </div>

        {isDuringScanning &&
          !messageErrorScan &&
          (progress < 10 ? (
            <p className={style.description}>
              準備が整いました。
              <br />
              診断を開始します。
            </p>
          ) : (
            <p className={style['during-scanning-description']}>
              診断開始中...
            </p>
          ))}

        {!isSelfScanCompleted && messageErrorScan && (
          <>
            <div className={style['scanning-error-message']}>
              {messageErrorScan}
            </div>
            <Button className={style.button} onClick={handleClickClosePopup}>
              閉じる
            </Button>
          </>
        )}

        {isSelfScanCompleted && !messageErrorScan && (
          <div className={style['button-wrap']}>
            <p className={style.supplement}>
              ※最新のスキャン結果が反映されるまで
              <br />
              時間がかかる場合があります。
            </p>
            <Button
              className={style.button}
              handleClickAction={handleClickNavigationScanHistory}
            >
              スキャン結果を見る
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
