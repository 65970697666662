import { FC, useMemo } from 'react';
import { BoxCarditem } from './BoxCarditem';
import style from './BoxCarditemStyle.module.scss';
import {
  generateExpenseDisplayOpt,
  generateOptionCostDisplayOpt,
} from './helper';
import { PurchaseOrder, PurchaseOrderOptionItem } from '@/types/api/carApi';

interface BoxCardProps {
  purchaseOrder?: PurchaseOrder;
  optionItemList?: PurchaseOrderOptionItem[];
}
export const BoxCard: FC<BoxCardProps> = ({
  purchaseOrder,
  optionItemList,
}) => {
  const basicPriceOpt = {
    content: [
      {
        label: '本体価格費合計',
        amount:
          (purchaseOrder?.car_price || 0) - (purchaseOrder?.discount || 0),
      },
    ],
    box: [
      {
        title: [
          {
            content: [
              {
                label: '車両本体価格',
                amount: purchaseOrder?.car_price || 0,
              },
            ],
          },
          {
            content: [
              {
                label: '値引き',
                amount: -(purchaseOrder?.discount || 0),
              },
            ],
          },
        ],
      },
    ],
  };
  const totalExpensesOpt = useMemo(
    () => generateExpenseDisplayOpt(optionItemList),
    [optionItemList],
  );
  const totalCostOpt = useMemo(
    () => generateOptionCostDisplayOpt(purchaseOrder, optionItemList),
    [optionItemList, purchaseOrder],
  );

  return (
    <div className={style['container']}>
      <BoxCarditem displayOpt={basicPriceOpt} />
      <BoxCarditem displayOpt={totalCostOpt} />
      <BoxCarditem displayOpt={totalExpensesOpt} />
    </div>
  );
};
