import { Block, Card, Navbar, Page } from 'framework7-react';
import React, { FC } from 'react';
import style from './FuelEfficiencyStyle.module.css';
import { TransitionChart } from '@/components/projects/TransitionChart/TransitionChart';
import { CommonpageTitle } from '@/components/uiParts/CommonpageTitle/CommonpageTitle';
import { TipsCard } from '@/components/uiParts/TipsCard/TipsCard';

export const FuelEfficiency: FC = () => {
  let loading = true;
  let isNoData = true;
  let currentDataType = 'fuelEfficiencyWeek';
  const changeBtnList = [
    { label: '日毎', subLabel: '過去7日間', flag: 'fuelEfficiencyWeek' },
    { label: '月毎', subLabel: '過去6ヶ月', flag: 'fuelEfficiencyYear' },
    { label: '全期間', subLabel: '0000年00月~', flag: 'fuelEfficiencyAll' },
  ];
  const gasfeesTipsTitle = {
    title: '燃費を良くする運転の仕方',
    subTitle: '急ブレーキをかけると燃費が悪くなる',
  };
  const brakesTipsTitle = {
    title: '正しいブレーキで維持費を節約',
    subTitle: '車両価値を維持するために',
  };
  return (
    <Page name="FuelEfficiency">
      <Navbar>
        <CommonpageTitle
          navTitle="一日の平均燃費"
          leftIcon="left"
          helpIcon={true}
          category="63448"
        />
      </Navbar>
      {!loading && !isNoData && (
        <Block>
          <div className="info-card info-card-elevated u-margin-bottom-md">
            <h2 className="u-font-size-xl">一日の平均燃費</h2>
            {/* <!-- 過去一週間 --> */}
            {currentDataType === 'fuelEfficiencyWeek' && (
              <div style={{ width: '100%', height: '18rem' }}>
                <TransitionChart
                  id="currentDataType"
                  yearScoreList={[]}
                  createMonthly={[]}
                  showLabel={false}
                  showSplitLineX={false}
                  minNumber={0}
                  maxNumber={10}
                  axisLabelUnit="km/L"
                  gridLeft="14%"
                  seriesType="bar"
                  axisLabelInterval={0}
                />
              </div>
            )}
            {/* <!-- 過去六ヶ月 --> */}
            {currentDataType === 'fuelEfficiencyYear' && (
              <div style={{ width: '100%', height: '18rem' }}>
                <TransitionChart
                  id="currentDataType"
                  yearScoreList={[]}
                  createMonthly={[]}
                  showLabel={false}
                  showSplitLineX={false}
                  minNumber={0}
                  maxNumber={10}
                  axisLabelUnit="km/L"
                  gridLeft="14%"
                  seriesType="bar"
                  axisLabelInterval={0}
                />
              </div>
            )}
            {/* <!-- 全期間 --> */}
            {currentDataType === 'fuelEfficiencyAll' && (
              <div style={{ width: '100%', height: '18rem' }}>
                <TransitionChart
                  id="currentDataType"
                  yearScoreList={[]}
                  createMonthly={[]}
                  showLabel={false}
                  showSplitLineX={false}
                  minNumber={0}
                  maxNumber={10}
                  axisLabelUnit="km/L"
                  gridLeft="14%"
                  seriesType="line"
                  axisLabelInterval={0}
                />
              </div>
            )}
            <div
              className={`${style['change-btns']} u-margin-top-sm u-margin-bottom-xs`}
            >
              {changeBtnList.map((item, index) => (
                <div
                  className={`${
                    item.flag === currentDataType && style['isActive']
                  } ${style['btns-item']}`}
                  key={index}
                >
                  <div className="u-font-size-lg">{item.label}</div>
                  <div>{item.subLabel}</div>
                </div>
              ))}
            </div>
          </div>

          <TipsCard imgUrl="imgUrl1" titles={gasfeesTipsTitle} />
          <TipsCard imgUrl="imgUrl2" titles={brakesTipsTitle} />
        </Block>
      )}

      {!loading && isNoData && (
        <Block>
          <div className="no-data u-text-center u-font-size-xxl">No Data</div>
        </Block>
      )}

      {loading && (
        <Block>
          <Card
            className="skeleton-text"
            title="Card Header"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit."
            footer="Card Footer"
          />
        </Block>
      )}
    </Page>
  );
};
