import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './cardRecommendStyle.module.css';
import carConditioner from '@/assets/images/carConditioner.png';
import carWash from '@/assets/images/carWash.png';
import CarRecommendPromotionImage1 from '@/assets/images/card-recommend/promotion1.png';
import CarRecommendPromotionImage2 from '@/assets/images/card-recommend/promotion2.png';
import { paths } from '@/config/paths';
import { usePromote } from '@/hooks/usePromote';

interface CardRecommendProps {}

export const CardRecommend: FC<CardRecommendProps> = ({}) => {
  const { isShowPromote } = usePromote();

  return (
    <>
      <div className="heading-with-button u-font-size-xl u-margin-bottom-sm u-margin-top-sm">
        <span className="u-font-bold">おすすめ</span>
      </div>
      <div className={style.listCard}>
        <div
          className={!isShowPromote ? style.card : undefined}
          onClick={() =>
            f7.views.main.router.navigate(paths.carWashHandAbout, {
              props: {
                showLink: true,
              },
            })
          }
        >
          {isShowPromote ? (
            <img src={CarRecommendPromotionImage1} />
          ) : (
            <>
              <div className={style.cardBox} style={{ color: '#278580' }}>
                <div className={style.cardContent}>
                  <p style={{ fontSize: '10px' }}>\いつでもどこでも/</p>
                  <p style={{ fontSize: '12px' }}>
                    出張水なし手洗い洗車
                    <br />
                    しませんか？
                  </p>
                  <img className={`${style['img']}`} src={carWash} alt="" />
                </div>
              </div>
              <div
                className={style['bottom']}
                style={{ backgroundColor: '#2FA69F' }}
              >
                <span>
                  詳しくはこちら
                  <i
                    className="icon icon-right"
                    style={{ color: '#fff', fontSize: '16px' }}
                  />
                </span>
              </div>
            </>
          )}
        </div>

        <div
          className={!isShowPromote ? style.card : undefined}
          onClick={() =>
            f7.views.main.router.navigate(paths.carWashConditionerAbout, {
              props: {
                showLink: true,
              },
            })
          }
        >
          {isShowPromote ? (
            <img src={CarRecommendPromotionImage2} />
          ) : (
            <>
              <div className={style.cardBox} style={{ color: '#245DB2' }}>
                <div className={style.cardContent}>
                  <p style={{ fontSize: '10px' }}>\気になるカビ・臭いに/</p>
                  <p style={{ fontSize: '12px' }}>
                    出張カーエアコン
                    <br />
                    クリーニングがおすすめ
                  </p>
                  <img
                    className={`${style['img']}`}
                    src={carConditioner}
                    alt=""
                  />
                </div>
              </div>
              <div
                className={style['bottom']}
                style={{ backgroundColor: '#4883DB' }}
              >
                <span>
                  詳しくはこちら
                  <i
                    className="icon icon-right"
                    style={{ color: '#fff', fontSize: '16px' }}
                  />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
