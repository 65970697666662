import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './LinkDriveSetupStep3Style.module.css';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { isIPhoneApp } from '@/config/device';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { f7CustomBack } from '@/utils/utils';

interface LinkDriveSetupStep3PageProps {
  isRecallLinkDriveOwnerSuccess: boolean;
}

export const LinkDriveSetupStep3Page: FC<LinkDriveSetupStep3PageProps> = ({
  isRecallLinkDriveOwnerSuccess,
}) => {
  const ecuType = store.state.linkDriveOwnerInfo.ecu_type;

  const handleClickCalibre = () => {
    // fix on iphone app
    f7CustomBack(['#LinkDriveSetupSteps', '#LinkDriveSetupList']);
    f7.views.main.router.navigate(paths.linkDriveCalibration, {
      animate: true,
    });
  };

  const handleClose = () => {
    f7CustomBack(['#LinkDriveSetupSteps', '#LinkDriveSetupList']);
    if (!isIPhoneApp) {
      f7CustomBack();
    }
  };

  return (
    <div className={`${style['container']}`}>
      <h2 className={style['head']}>
        <i className={`icon-check ${style['head-icon']}`} />
        セットアップが完了しました
      </h2>
      {isRecallLinkDriveOwnerSuccess ? (
        <>
          <div className={style['content-box']}>
            <div className={style['content-box-head']}>
              <i className="icon-notification-settings" />
              <p>
                {Number(ecuType) == 1 || Number(ecuType) == 2
                  ? 'この車両では、スタンダードモードを使ったLINKDriveの利用が可能です'
                  : 'この車両では、ノーマルモードを使ったLINKDriveの利用が可能です'}
              </p>
            </div>
            <p>
              {Number(ecuType) == 1 || Number(ecuType) == 2
                ? 'スタンダードモードでは、メーカーの診断機と同等の幅広いデータの取得、及びGPSを使ったデータの分析が可能です。'
                : 'ノーマルモードではGPSを使ったデータの取得・分析が可能です。'}
            </p>
          </div>
          {Number(ecuType) == 1 || Number(ecuType) == 2 ? (
            <p className={style['box-message']}>
              セットアップ完了後、キャリブレーションを行ってください。
            </p>
          ) : (
            <div className={`${style['box-message']} ${style['warning']}`}>
              <div className={style['title-wrapper']}>
                <div className={style['icon-wrapper']}>
                  <i className="icon-exclamationmark_circle_img" />
                </div>
                <div className={style['text-wrapper']}>
                  <p>ご注意事項</p>
                </div>
              </div>
              <div className={style['text-container']}>
                <div className={style['index-wrapper']}>
                  <p>1.</p>
                </div>
                <div className={style['text-wrapper']}>
                  <p>
                    エンジンを止めずにお待ちください
                    <br />
                    <span>
                      1分程度でデバイスが停止（青色のランプが消灯）します。
                    </span>
                  </p>
                </div>
              </div>

              <div className={style['text-container']}>
                <div className={style['index-wrapper']}>
                  <p>2.</p>
                </div>
                <div className={style['text-wrapper']}>
                  <p>
                    デバイスが停止してから、エンジンを一度止め、再度エンジンをスタートしてください
                  </p>
                </div>
              </div>

              <div className={style['text-container']}>
                <div className={style['index-wrapper']}>
                  <p>3.</p>
                </div>
                <div className={style['text-wrapper']}>
                  <p>
                    デバイスの起動（青色のランプが点灯状態）を確認した上で、キャリブレーションを開始してください
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <p className={style['box-message']}>
          セットアップ完了後、キャリブレーションを行ってください。
        </p>
      )}
      <div className={style['setup-note']}>
        <div className={style['setup-note-title']}>
          <i className="icon-help-blue" />
          <span>キャリブレーションとは？</span>
        </div>
        <div className={style['setup-note-content']}>
          <p>お車の標準器や標準試料を比較して正しいバランスに設定します。</p>
        </div>
      </div>
      <Button
        fill
        round
        text="キャリブレーションする"
        style={{ marginTop: 'auto' }}
        onClick={handleClickCalibre}
      />
      <Button
        fill
        round
        text="あとで行う"
        style={{ marginTop: '19px', background: '#EFEFEF', color: '#323232' }}
        onClick={handleClose}
      />
    </div>
  );
};
