import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './Partial.module.scss';

interface PairInputProps {
  firstInputName: string;
  secondInputName: string;
  label?: string;
  firstPlaceholder?: string;
  secondPlaceholder?: string;
  firstError?: string;
  secondError?: string;
}

export const PairInput: FC<PairInputProps> = ({
  firstInputName,
  secondInputName,
  label = '',
  firstPlaceholder = '',
  secondPlaceholder = '',
  firstError = '',
  secondError = '',
}) => {
  const { register, getFieldState, watch } = useFormContext();
  return (
    <div className={style['pair-input']}>
      {label && <label>{label}</label>}
      <div className={style['pair-input-wrap']}>
        <div
          className={`${style['input-group']} ${
            firstError ? style['input-group-has-error'] : ''
          }`}
        >
          <input
            type="string"
            placeholder={firstPlaceholder}
            {...register(firstInputName)}
          />
          {firstError && <p className={style['error-message']}>{firstError}</p>}
        </div>

        <div
          className={`${style['input-group']} ${
            secondError ? style['input-group-has-error'] : ''
          }`}
        >
          <input
            type="string"
            placeholder={secondPlaceholder}
            {...register(secondInputName)}
          />
          {secondError && (
            <p className={style['error-message']}>{secondError}</p>
          )}
        </div>
      </div>
    </div>
  );
};
