import { Router } from 'framework7/types';
import { FC } from 'react';
import { NewsWarrantyExpried } from './NewsWarrantyExpried/NewsWarrantyExpried';

type NewsWarrantyExpriedPageProps = {
  f7router: Router.Router;
};

export const NewsWarrantyExpriedPage: FC<NewsWarrantyExpriedPageProps> = ({
  f7router,
}) => {
  return <NewsWarrantyExpried f7router={f7router} />;
};
