import { CarSize } from '@/types/api/reservationApi';

export const steps = {
  PRE_SELECTION: 1,
  REGISTATION: 2,
  ADDITIONAL_INFO: 3,
  REVIEW: 4,
} as const;

export const serviceTypes = {
  CARWASH: 'carwash',
  CONDITIONER: 'airconditioner_cleaning',
} as const;

export const marginTypes = {
  NONE: 3,
  YES: 1,
  NARROW_GAP: 2,
} as const;

export const scratchedChecks = {
  AVAILABLE: 1,
  NOT_AVAILABLE: 2,
} as const;

export const securitySystems = {
  YES: 1,
  NO: 2,
  UNKNOWN: 9,
} as const;

export const carSizes = {
  UNKNOWN: 0,
  S: 1,
  M: 2,
  L: 3,
  XL: 4,
} as const;

export const carWashServiceFlag = {
  NONE_INSTALLED: 0,
  INSTALLED: 1,
} as const;

export const carWashIsUse = {
  UN_USED: 0,
  USED: 1,
} as const;

export const carSizesOptions: { label: string; value: CarSize }[] = [
  {
    label: 'Sサイズ\n（軽自動車）',
    value: carSizes.S,
  },
  {
    label: 'Mサイズ\n（3ナンバー、5ナンバーの車高1650mm未満）',
    value: carSizes.M,
  },
  {
    label: 'Lサイズ\n（車高1650mm以上）',
    value: carSizes.L,
  },
  {
    label: '2Lサイズ\n（ミニバン、ワンボックス）',
    value: carSizes.XL,
  },
];

export const workspaceTypeOption = [
  {
    label: 'ご自宅駐車場（戸建て）',
    value: 1,
  },
  {
    label: 'ご自宅駐車場（集合住宅）',
    value: 2,
  },
  {
    label: '契約駐車場',
    value: 3,
  },
  {
    label: '施設内駐車場',
    value: 4,
  },
  {
    label: '職場駐車場',
    value: 5,
  },
  // {
  //   label: '有料駐車場',
  //   value: 6,
  // },
];

export const parkingTypeOption = [
  {
    label: '平置き（屋外屋根あり）',
    value: 1,
  },
  {
    label: '平置き（屋外屋根なし）',
    value: 2,
  },
  {
    label: '平置き（屋内）',
    value: 3,
  },
  {
    label: '機械式（屋内）',
    value: 4,
  },
  {
    label: '機械式（屋外）',
    value: 5,
  },
];

export const marginTypeOption = [
  {
    label: 'あり',
    value: marginTypes.YES,
  },
  {
    label: 'なし',
    value: marginTypes.NONE,
  },
  {
    label: '間隔はあるがもう少し狭い',
    value: marginTypes.NARROW_GAP,
  },
];

export const securitySystemOption = [
  {
    label: '付いている',
    value: securitySystems.YES,
  },
  {
    label: '付いていない',
    value: securitySystems.NO,
  },
  {
    label: 'わからない',
    value: securitySystems.UNKNOWN,
  },
];

export const scratchedCheckOption = [
  {
    label: 'はい',
    value: scratchedChecks.AVAILABLE,
  },
  {
    label: 'いいえ',
    value: scratchedChecks.NOT_AVAILABLE,
  },
];

export const cancellationFrom = {
  USER: 1,
  OPERATOR: 2,
} as const;

export const cancellationType = {
  NO_TARGET_VEHICLE: 1,
  BAD_WEATHER: 2,
  NOT_AVAILABLE: 3,
  ANOTHER_APPOINTMENT: 4,
  NO_NEED: 5,
  MISTAKE: 6,
  OTHER: 7,
} as const;

export const cancellationTypeOptions = [
  {
    value: cancellationType.ANOTHER_APPOINTMENT,
    text: '都合が悪くなってしまった',
    detail: '別の予定が入った',
  },
  {
    value: cancellationType.NO_NEED,
    text: '洗車をしないことにした',
    detail: 'サービスが不要になった',
  },
  {
    value: cancellationType.OTHER,
    text: 'その他',
    detail: '',
  },
];
