import { FC } from 'react';
import style from './LoginMethodCardStyle.module.css';

interface LoginMethodCardProps {
  means: {
    means: string;
    specificValue: string;
    isPermit: boolean;
  }[];
}

export const LoginMethodCard: FC<LoginMethodCardProps> = ({ means }) => {
  return (
    <div className={style.card}>
      <div className={style['heading-wrap']}>
        <h3 className={style.heading}>ログイン手段</h3>
        <span className={style.button}>変更する</span>
      </div>
      <p className={style.description}>
        次回以降、許可された方法のいずれかでログインできます。
      </p>
      <ul className={style['list-wrap']}>
        {means.map((oneMeans, index) => (
          <li className={style.list} key={index}>
            <a href="" className={style.link}>
              <div className={style.grid}>
                <div className={style['icon-wrap']}>
                  <i className={`${style.icon} icon-key`} />
                </div>
                <div className={style['specific-means']}>
                  <span className={style.means}>{oneMeans.means}</span>
                  <span className={style.specific}>
                    {oneMeans.specificValue}
                  </span>
                </div>
                <div className={style.authority}>
                  {oneMeans.isPermit ? '許可する' : '許可しない'}
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
