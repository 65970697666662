import { FC, ReactNode } from 'react';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { List } from '@/components/uiParts/List/List';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import './BillingScheduleDetailStyle.scss';

type BillingScheduleDetailProps = {
  list: {
    label: string;
    value: string | ReactNode;
  }[];
  billingDate: string | undefined;
  isShowCard: boolean;
};

export const BillingScheduleDetail: FC<BillingScheduleDetailProps> = ({
  list,
  billingDate,
  isShowCard,
}) => {
  return (
    <PageTemplate
      pageName="BillScheduleDetail"
      title="請求予定詳細"
      showNavBack={true}
    >
      <div className="card">
        <h3 className="u-padding-bottom-sm title">請求情報</h3>
        <List list={list} />
        {isShowCard && (
          <InfoCard
            bgColor="warning"
            text="ご入金をお願いします"
            textSub={billingDate}
            iconType="confirm-notification"
            isElevated
            // TODO: url未設定
            url="#"
            isIconRight={true}
          />
        )}
      </div>
    </PageTemplate>
  );
};
