import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './Coupons.module.css';
import { PrepaidTicketCoupon } from '@/components/uiParts/PrepaidTicketCoupon/PrepaidTicketCoupon';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { Campaign } from '@/types/api/customerApi';
import { compareDate } from '@/utils/date';
import { f7CustomBack } from '@/utils/utils';

type CouponsProps = {
  f7router: Router.Router;
  redirectPage: string;
};

const today = new Date();

export const Coupons: FC<CouponsProps> = ({ f7router, redirectPage }) => {
  const couponList = useStore(
    store,
    'getCampaignsInfo',
  ) as Required<Campaign>[];

  const handleClickNavigationUsedCoupon = () => {
    f7.view.main.router.navigate(paths.usedCoupon);
  };

  return (
    <PageTemplate
      pageName="Coupons"
      title="クーポン一覧"
      showNavBack
      handleClickBack={() => {
        if (redirectPage) {
          f7.view.main.router.clearPreviousHistory();
          f7.view.main.router.navigate(redirectPage, {
            browserHistory: true,
            history: true,
            ignoreCache: true,
          });
          f7.view.main.router.navigate(paths.rank, { props: { redirectPage } });
          return;
        }
        f7CustomBack();
      }}
    >
      <div className={style.card}>
        <div className="heading-with-button u-font-size-lg u-font-bold u-margin-bottom-sm">
          利用可能クーポン
          <div className="u-margin-left-auto">
            <div
              className={style.button}
              onClick={handleClickNavigationUsedCoupon}
            >
              ご利用済み
              <i className="icon-right" />
            </div>
          </div>
        </div>
        {couponList.every(
          (coupon) =>
            Boolean(coupon.status) === Boolean('無効') ||
            compareDate(today, new Date(coupon.t_start_date)) ||
            compareDate(new Date(coupon.t_end_date), today),
        ) ? (
          <p className="u-margin-top-md u-text-center">
            ご利用可能なクーポンがありません
          </p>
        ) : (
          couponList.map((coupon, index) => (
            <div key={index}>
              {Boolean(!coupon.status) && (
                <PrepaidTicketCoupon couponInfo={coupon} f7router={f7router} />
              )}
            </div>
          ))
        )}
      </div>
    </PageTemplate>
  );
};
