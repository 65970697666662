import { Col, Page, Row } from 'framework7-react';
import { FC } from 'react';
import style from './CarWashConditionerStep4Style.module.css';
import { ReactComponent as IconPay } from '@/assets/images/menu/icon-pay-tip-color.svg';
import { Button } from '@/components/uiParts/Button';
import { Prepaid } from '@/types/api/customerApi';

type PaymentDialogProps = {
  prepaid?: Prepaid;
  fee: number;
  amount: string;
  onCancel: () => void;
  onOk: () => void;
};

export const PaymentDialog: FC<PaymentDialogProps> = ({
  prepaid,
  fee,
  amount,
  onCancel,
  onOk,
}) => {
  const lackMoney = (prepaid?.balance_total || 0) < fee;

  return (
    <Page name="ConfirmDialog">
      <div>
        <div className={style['dialog-container']}>
          <div className={style['dialog-title']}>
            <IconPay />
            <span>お支払い</span>
          </div>
          <div className={style['dialog-list-item']}>
            <div>支払い金額（税込）</div>
            <div className={style['dialog-list-item-price']}>
              <span className={style['dialog-list-item-fee']}>{amount}</span>円
            </div>
          </div>
          <div className={style['dialog-list-item']}>
            <div>支払い方法</div>
            <div>
              <p className={style['dialog-list-item-price']}>{prepaid?.name}</p>
              <p>利用可能金額：{prepaid?.balance_total?.toLocaleString()}円</p>
              {lackMoney && (
                <p className={style['error']}>残高が不足しています</p>
              )}
            </div>
          </div>
          <Row style={{ marginTop: '30px' }}>
            <Col width="100">
              {!lackMoney && <Button text="支払う" onClick={onOk} />}
              {lackMoney && <Button text="チャージする" onClick={onOk} />}
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col width="100">
              <Button
                style={{ color: '#232323', backgroundColor: '#efefef' }}
                text="キャンセル"
                onClick={onCancel}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Page>
  );
};
