import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { registerPrepaidInfo } from '@/api/prepaidApi';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import {
  RegisterPrepaidInfoParams,
  RegisterPrepaidInfoResponse,
} from '@/types/api/prepaidApi';
import { PrepaidData } from '@/types/payment';
import { storeDispatch } from '@/utils/store';

export const useRegisterPrepaidInfo = () => {
  const [data, setData] = useState<RegisterPrepaidInfoResponse>();
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const registerPrepaid = async (
    params: RegisterPrepaidInfoParams,
    setPrepaidData?: Dispatch<SetStateAction<PrepaidData>>,
  ) => {
    try {
      f7.preloader.show();
      const res = await registerPrepaidInfo(params);
      setData(res);
      if (!res.data.success) throw new Error('エラーが発生しました');
      const newAdvancePaymentsInfo = store.state.advancePaymentsInfo;
      if (newAdvancePaymentsInfo.length) {
        newAdvancePaymentsInfo[0].monthly_charge = params.monthry_charge;
        await storeDispatch(
          'setPrepaidsInfo',
          'prepaidsInfo',
          newAdvancePaymentsInfo,
        );
      }
      if (!setPrepaidData) return;
      setPrepaidData((prevPrepaidData) => ({
        ...prevPrepaidData,
        isPrepaid: true,
      }));
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    registerPrepaid,
    data,
    setData,
    error,
  };
};
