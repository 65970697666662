import { Link as F7Link, f7 } from 'framework7-react';
import { LinkProps as F7LinkProps } from 'framework7-react/components/link';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './underLineLinkStyle.module.css';

interface UnderLineLinkProps extends F7LinkProps {
  f7router?: Router.Router;
  path?: string;
  text: string;
  onClick?: () => void;
}
export const UnderLineLink: FC<UnderLineLinkProps> = ({
  f7router,
  path,
  text,
  onClick = undefined,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (f7router && path) {
      f7.view.main.router.navigate(path);
    }
  };
  return (
    <F7Link className={`${style['link']} no-ripple`} onClick={handleClick}>
      {text}
    </F7Link>
  );
};
