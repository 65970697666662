import { f7 } from 'framework7-react';
import React, { FC } from 'react';
import './CarwashEditSuccessPageStyle.scss';
import GreenTick from '../../../assets/images/green-tick.svg';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';

type CarwashEditSuccessProps = {
  id?: string;
};

export const CarwashEditSuccessPage: FC<CarwashEditSuccessProps> = ({ id }) => {
  const handleBack = () => {
    f7.views.main.router.navigate(paths.reservationHistory);
  };
  return (
    <PopupPageTemplate
      handleBack={handleBack}
      id="carwashEditSuccess"
      pageName="carwashEditSuccess"
      title="  "
    >
      <div className="content-wrapper">
        <img src={GreenTick} alt="" />
        <div className="title">
          {id === 'create' && '予約を申し込みました'}
          {id === 'edit' && '予約の変更を受付ました。'}
        </div>
        <div className="text">ご予約ありがとうございます。</div>
        <div className="text">担当者から予約内容についてのメッセージを</div>
        <div className="text">お送りします。しばらくお待ち下さい。</div>
      </div>
    </PopupPageTemplate>
  );
};
