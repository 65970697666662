import { Card, CardContent, f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { updateWarrantyStep } from '../../UpdateWarrantyDetail/updateWarrantyDetailHelper';
import { getCustomerStep6 } from '../UpdateWarrantyHelper';
import { LicensePlate } from '@/components/projects/LicensePlate/LicensePlate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { usePutGuaranteeUpdateTarget } from '@/hooks/api/warranty/usePutGuaranteeUpdateTarget';
import { BillInformation } from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';
import { hyphenToDay } from '@/utils/date';
import {
  createPaymentText,
  isBankOrPayEasy,
  switchPaymentIcon,
  switchPaymentMethodDesc,
} from '@/utils/payment';
import './UpdateWarrantyStep6Page.scss';

type UpdateWarrantyStep6PageProps = {
  f7router: Router.Router;
  warrantyNo: string;
  setStep: (n: number) => void;
};

export const UpdateWarrantyStep6Page: FC<UpdateWarrantyStep6PageProps> = ({
  f7router,
  warrantyNo,
  setStep,
}) => {
  const {
    customerInfo,
    carItemInfo,
    updateWarrantyInfo,
    updateLoan,
    nextWarranty,
    currentWarranty,
  } = store.state;

  const billsInfo = store.state.billsInfo;
  const billInfo = billsInfo.find(
    (x: BillInformation) => (x.warranty_no = warrantyNo),
  );
  const billInfoDetail = billInfo?.bill_infomation_details.slice(-1)[0];

  const { customer, warranty, loan, car, bill } = getCustomerStep6(
    customerInfo,
    carItemInfo,
    updateWarrantyInfo,
    updateLoan,
    billInfoDetail,
  );

  const execEmit = (n: number) => {
    setStep(n);
  };

  const handleNavigateClick = (u: string) => {
    f7.view.main.router.navigate(u);
  };

  const { putGuaranteeUpdateTarget } = usePutGuaranteeUpdateTarget();

  const updateWarrantyComp = async () => {
    const customerInfo = store.state.customerInfo;
    const warranties = nextWarranty?.append_warranties?.slice(-1)[0];
    const warranty = store.state.updateWarrantyInfo;
    const loan = store.state.updateLoan;
    const params = {
      t_base_warranty: {
        t_base_warranty_id: String(
          nextWarranty?.t_base_waranty_id == null
            ? ''
            : nextWarranty?.t_base_waranty_id,
        ),
        warranty_no: warrantyNo,
        m_customer_id: customerInfo.id,
      },
      t_append_warranties: [
        {
          t_append_warranty_id: warranties?.t_append_warranty_id ?? 0,
          warranty_type: warranties?.warranty_type ?? 0,
          m_warranty_plan_header_id: warranty.m_warranty_plan_header_id!,
          m_warranty_plan_detail_id: warranty.m_warranty_plan_detail_id!,
          loan_num_continuous: loan.loan_num,
        },
      ],
    };
    await putGuaranteeUpdateTarget(params);
    f7.view.main.router.navigate(paths.warranty('update_success'));
  };

  const amountTotal = (x: {
    loan_num: number;
    loan_num_f: number;
    loan_num_s: number;
  }) => {
    if (x) {
      return x.loan_num_f + x.loan_num_s * (x.loan_num - 1);
    } else {
      return 0;
    }
  };

  return (
    <div data-name="UpdateWarrantyStep6">
      <table className="menu-table u-margin-bottom-lg">
        <tr className="menu-tr-1">
          <td>●</td>
          <td>●</td>
          <td>●</td>
          <td>●</td>
          <td>●</td>
          <td className="select-number">
            <div>6</div>
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }} className="menu-table-item">
            プラン選択
          </td>
          <td className="menu-table-item">
            契約情報
            <br />
            確認
          </td>
          <td className="menu-table-item">
            重要事項
            <br />
            確認
          </td>
          <td className="menu-table-item">
            支払回数
            <br />
            選択
          </td>
          <td className="menu-table-item">
            決済方法
            <br />
            選択
          </td>
          <td
            style={{ verticalAlign: 'top' }}
            className="menu-falling-under menu-table-item"
          >
            最終確認
          </td>
        </tr>
      </table>

      <div>
        <div className="step-title">変更内容を確認してください</div>
        <div className="card">
          <div className="card-content">
            <div className="list no-ios-edges">
              <ul style={{ paddingBottom: '1em' }}>
                <li className="plan-description3 list-title">
                  <div className="item-title">お申し込みプラン</div>
                  <div
                    className="card-title-btn"
                    onClick={() => {
                      execEmit(updateWarrantyStep.STEP_SIX);
                    }}
                  >
                    変更する
                  </div>
                </li>
                <li className="plan-description3">
                  <div className="item1">プラン名</div>
                  <div>{warranty.warranty_name}</div>
                </li>
                <li className="plan-description3">
                  <div className="item1">保証期間</div>
                  <div>{warranty.continuous_contract_term} ヶ月</div>
                </li>
                <li className="plan-description3">
                  <div className="item1">保証限度額</div>
                  <div>
                    {Number(warranty.amount_limit)
                      ? `${Number(warranty.amount_limit).toLocaleString()} 円`
                      : '無制限'}
                  </div>
                </li>
                <li className="plan-description3 list-title">
                  <div className="item1">保証料総額</div>
                  <div>
                    {warranty.warranty_fee_wh_continuous.toLocaleString()} 円
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="card">
          <div className="card-content">
            <div className="list no-ios-edges">
              <ul style={{ paddingBottom: '1em' }}>
                <li className="plan-description3 list-title">
                  <div className="item-title">契約者様情報</div>
                  <div
                    className="card-title-btn"
                    onClick={() => {
                      handleNavigateClick(paths.editCustomer);
                    }}
                  >
                    変更する
                  </div>
                </li>

                <li className="plan-description3">
                  <div className="item1" style={{ width: '40%' }}>
                    契約者様氏名
                  </div>
                  <div style={{ width: '60%', textAlign: 'left' }}>
                    {customer.contractorName}
                  </div>
                </li>
                <li className="plan-description3">
                  <div className="item1" style={{ width: '40%' }}>
                    フリガナ
                  </div>
                  <div style={{ width: '60%', textAlign: 'left' }}>
                    {customer.contractorNameKana}
                  </div>
                </li>
                <li className="plan-description3">
                  <div className="item1" style={{ width: '40%' }}>
                    法人名
                  </div>
                  <div style={{ width: '60%', textAlign: 'left' }}>
                    {customer.corporationName}
                  </div>
                </li>
                <li className="plan-description3">
                  <div className="item1" style={{ width: '40%' }}>
                    生年月日
                  </div>
                  <div style={{ width: '60%', textAlign: 'left' }}>
                    {hyphenToDay(customer.birthDay ?? '', true)}
                  </div>
                </li>
                <li className="plan-description3">
                  <div className="item1" style={{ width: '40%' }}>
                    携帯電話番号
                  </div>
                  <div style={{ width: '60%', textAlign: 'left' }}>
                    {customer.mobilePhone}
                  </div>
                </li>
                <li className="plan-description3 list-title">
                  <div className="item1" style={{ width: '40%' }}>
                    自宅電話番号
                  </div>
                  <div style={{ width: '60%', textAlign: 'left' }}>
                    {customer.homePhone}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="card">
          <div className="card-content">
            <div className="list no-ios-edges">
              <ul style={{ paddingBottom: '1em' }}>
                <li className="plan-description3 list-title">
                  <div className="item-title">契約者様お住まい情報</div>
                  <div
                    className="card-title-btn"
                    onClick={() => {
                      handleNavigateClick(paths.editAddress);
                    }}
                  >
                    変更する
                  </div>
                </li>
                <li className="address-info">
                  <div>
                    <div>{customer.zipCode}</div>
                    <div>{customer.address1}</div>
                    <div>{customer.address2}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Card>
          <CardContent>
            <div className="heading-with-button u-font-size-lg u-font-bold u-margin-bottom-sm">
              対象車両ナンバー
              <div
                className="u-margin-left-auto card-title-btn"
                onClick={() => {
                  handleNavigateClick(paths.updateNumber);
                }}
              >
                変更する
              </div>
            </div>
            <LicensePlate
              classNumber={car.classNo ?? ''}
              kana={car.hiragana ?? ''}
              area={car.transportName ?? ''}
              consecutiveNumber={car.registryNo ?? ''}
            />
          </CardContent>
        </Card>
      </div>

      <div>
        <div className="card">
          <div className="card-content">
            <div className="list no-ios-edges">
              <ul style={{ paddingBottom: '1em' }}>
                <li className="plan-description3 list-title">
                  <div className="item-title">お支払い情報</div>
                  <div
                    className="card-title-btn"
                    onClick={() => {
                      execEmit(updateWarrantyStep.STEP_FOUR);
                    }}
                  >
                    変更する
                  </div>
                </li>
                <li className="plan-description3">
                  <div className="item1">お支払い方法</div>
                  <div>{createPaymentText(loan.loan_num)}</div>
                </li>
                <li className="plan-description3">
                  <div className="item1">月額</div>
                  <div>
                    <div style={{ textAlign: 'right' }}>
                      <div>{loan.loan_num_f.toLocaleString()}円</div>
                      {loan.loan_num > 1 && (
                        <div>
                          ※初月のみ
                          {loan.loan_num_s.toLocaleString()}円
                        </div>
                      )}
                    </div>
                  </div>
                </li>
                <li className="plan-description3">
                  <div className="item1">分割手数料</div>
                  <div>{loan.loan_num_f.toLocaleString()}円</div>
                </li>
                <li className="plan-description3 list-title">
                  <div className="item1">総額</div>
                  <div>{amountTotal(loan).toLocaleString()}円</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="card">
          <div className="card-content">
            <div className="list no-ios-edges">
              <ul>
                <li className="plan-description3 list-title">
                  <div className="item-title">決済方法</div>
                  <div
                    className="card-title-btn"
                    onClick={() => {
                      execEmit(updateWarrantyStep.STEP_FIVE);
                    }}
                  >
                    変更する
                  </div>
                </li>
                <li className="list2">
                  <div className="pay-content display-flex">
                    <div className="u-font-bold">
                      <i
                        className={`${switchPaymentIcon(
                          bill.payment_type_id as PaymentTypeId,
                        )} building-icon`}
                      />
                    </div>
                    <div className="u-padding-left-sm u-padding-right-sm">
                      <p>
                        {switchPaymentMethodDesc(
                          bill.payment_type_id ?? 0,
                          bill.bank_name,
                        )}
                      </p>
                      <p>
                        {isBankOrPayEasy(bill.payment_type_id)
                          ? `口座番号：${bill.account_number}`
                          : `カード番号：${bill.card_no}`}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <button className="btn" onClick={updateWarrantyComp}>
            この内容でプランを変更する
          </button>
        </div>
      </div>
    </div>
  );
};
