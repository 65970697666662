import { Block } from 'framework7-react';
import { FC } from 'react';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import './RegisterPaymentListStyle.scss';

type GMOParameters = {
  RetURL: string;
  CancelURL: string;
  CreateMember: string;
  ConsumerDevice: string;
  SiteID: string;
  SitePass: string;
  ShopID: string;
  dateTime: string;
  memberPassString: string;
  memberID: string;
  Enc: string;
};

type RegisterPaymentListProps = {
  handleClickWithdrawFromBank: () => void;
  handleClickCreditCardPayment: () => void;
  GMOParameters: GMOParameters;
  redirectPage: string;
  actionUrl: string;
  handleClickPopupClose: () => void;
};

export const RegisterPaymentList: FC<RegisterPaymentListProps> = ({
  handleClickWithdrawFromBank,
  handleClickCreditCardPayment,
  GMOParameters,
  actionUrl,
  redirectPage,
  handleClickPopupClose,
}) => {
  return (
    <PopupPageTemplate
      id="RegisterPaymentList"
      pageName="RegisterPayment"
      title="決済方法の追加"
      handleBack={handleClickPopupClose}
    >
      <Block className="last-block">
        <p className="u-font-bold u-font-size-xl">決済方法を選択してください</p>

        <div
          className="u-margin-top-sm info-card info-card-elevated"
          onClick={handleClickWithdrawFromBank}
        >
          <div className="pay-content">
            <div className="u-font-bold u-font-size-lg">
              <i className={`icon-bank icon`} />
            </div>
            <div className="u-padding-left-sm u-padding-right-sm">
              <p>銀行から引き落とし</p>
            </div>
            <div>
              <i className="icon-right icon" />
            </div>
          </div>
        </div>

        <div
          className="u-margin-top-sm info-card info-card-elevated"
          onClick={handleClickCreditCardPayment}
        >
          <div className="pay-content">
            <div className="u-font-bold u-font-size-lg">
              <i className={`icon-credit-card icon`} />
            </div>
            <div className="u-padding-left-sm u-padding-right-sm">
              <p>クレジットカード</p>
            </div>
            <div>
              <i className="icon-right icon" />
            </div>
          </div>
        </div>
        {/* {paymentMethods.map((method, index) => (
          <div
            className="u-margin-top-sm info-card info-card-elevated"
            key={index}
            onClick={() => handleClickCreditOrBank(method.payMethod)}
          >
            <div className="pay-content">
              <div className="u-font-bold u-font-size-lg">
                <i className={`icon-${method.icon} icon`} />
              </div>
              <div className="u-padding-left-sm u-padding-right-sm">
                <p>
                  {method.payMethod === 'bank'
                    ? '銀行から引き落とし'
                    : 'クレジットカード'}
                </p>
              </div>
              <div>
                <i className="icon-right icon" />
              </div>
            </div>
          </div>
        ))} */}
        <div className="u-margin-top-sm u-font-size-lg">
          ※外部サイトに移動します
        </div>
        {actionUrl && (
          <form action={actionUrl} method="POST">
            <input type="hidden" name="SiteID" value={GMOParameters.SiteID} />
            <input
              type="hidden"
              name="MemberID"
              value={GMOParameters.memberID}
            />
            <input type="hidden" name="ShopID" value={GMOParameters.ShopID} />
            <input
              type="hidden"
              name="MemberPassString"
              value={GMOParameters.memberPassString}
            />
            <input type="hidden" name="RetURL" value={GMOParameters.RetURL} />
            <input
              type="hidden"
              name="CancelURL"
              value={GMOParameters.CancelURL}
            />
            <input
              type="hidden"
              name="DateTime"
              value={GMOParameters.dateTime}
            />
            <input type="hidden" name="Enc" value={GMOParameters.Enc} />
            <input id="submit" type="submit" style={{ display: 'none' }} />
          </form>
        )}
      </Block>
    </PopupPageTemplate>
  );
};
