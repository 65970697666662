import { yupResolver } from '@hookform/resolvers/yup';
import { Block, Button, Page, Popup, f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SelectCalendar } from './SelectCalendar';
import { SelectHours } from './SelectHours';
import { ShopInfo } from './ShopInfo';
import { FormInput, convertTime, formatDate, formatTime } from './const';
import { validationSchema } from './validationSchema';
import { inputCustomerApi } from '@/api/todoListApi';
import { Label } from '@/components/uiParts/Label/Label';
import { RhfTextareaWithLabel } from '@/components/uiParts/ReactHookForm/RhfTextareaWithLabel/RhfTextareaWithLabel';
import './TodoDetailStyle.scss';
import { paths } from '@/config/paths';
import { useToast } from '@/hooks/useToast';
import { formatDateWithDay } from '@/utils/date';

interface PromptDeliveryDetailProps {
  f7router: Router.Router;
  item?: any;
}

export const PromptDeliveryDetail: FC<PromptDeliveryDetailProps> = (props) => {
  const { item } = props;
  const [isOpened, setIsOpened] = useState(false);
  const { openToast } = useToast();

  const formMethods = useForm<FormInput>({
    defaultValues: {
      delivery_preferred_date_1: item.delivery_preferred_date_1 ?? '',
      delivery_preferred_date_2: item.delivery_preferred_date_2 ?? '',
      delivery_preferred_date_3: item.delivery_preferred_date_3 ?? '',
      delivery_preferred_hm_1: convertTime(item.delivery_preferred_hm_1),
      delivery_preferred_hm_2: convertTime(item.delivery_preferred_hm_2),
      delivery_preferred_hm_3: convertTime(item.delivery_preferred_hm_3),
      delivery_preferred_date_complement:
        item.delivery_preferred_date_complement,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { isValid, errors },
    trigger,
    watch,
  } = formMethods;

  const deliveryPreferredDate1 = watch('delivery_preferred_date_1');
  const deliveryPreferredDate2 = watch('delivery_preferred_date_2');
  const deliveryPreferredDate3 = watch('delivery_preferred_date_3');
  const deliveryPreferredHm1 = watch('delivery_preferred_hm_1');
  const deliveryPreferredHm2 = watch('delivery_preferred_hm_2');
  const deliveryPreferredHm3 = watch('delivery_preferred_hm_3');

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    f7.preloader.show();

    const dataSubmit = {
      task_id: item.task_id,
      t_wf_instance_task_id: item.t_wf_instance_task_id,
      data: {
        ...item,
        ...data,
        delivery_preferred_hm_1: formatTime(data.delivery_preferred_hm_1),
        delivery_preferred_hm_2: formatTime(data.delivery_preferred_hm_2),
        delivery_preferred_hm_3: formatTime(data.delivery_preferred_hm_3),
        delivery_preferred_date_1: formatDate(data.delivery_preferred_date_1),
        delivery_preferred_date_2: formatDate(data.delivery_preferred_date_2),
        delivery_preferred_date_3: formatDate(data.delivery_preferred_date_3),
      },
    };

    delete dataSubmit.data.task_id;
    delete dataSubmit.data.t_wf_instance_task_id;

    const res = await inputCustomerApi(dataSubmit);

    f7.preloader.hide();

    if (res.data.success) {
      setIsOpened(true);
    } else {
      openToast('エラーが発生しました', 'toast-failed');
    }
  };

  const handleBackToList = () => {
    setIsOpened(false);
    f7.view.main.router.navigate(`/news${paths.todoList}`);
  };

  useEffect(() => {
    trigger();
  }, [
    deliveryPreferredDate1,
    deliveryPreferredDate2,
    deliveryPreferredDate3,
    deliveryPreferredHm1,
    deliveryPreferredHm2,
    deliveryPreferredHm3,
  ]);

  return (
    <>
      <div className="home__wrapper">
        <Block style={{ marginTop: 0, padding: 0 }}>
          <div className="info-card">
            <h2 className="notice-title u-text-center">
              ご納車のご希望日時をお知らせください
            </h2>
            <div className="notice-content">
              ご納車のためのご来店希望の日時をお知らせください。
            </div>
          </div>

          <div className="info-card info-card-no-content">
            <ShopInfo />
          </div>

          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} className="form-delivery">
              <div className="info-card">
                <h2 className="notice-title-2">
                  ご来店希望日時をご入力ください
                </h2>
                <div className="notice-content">
                  <p>
                    納車可能日以降のお日にちよりお選びいただくことができます。
                  </p>
                </div>

                <div className="delivery-info">
                  <h2 className="delivery-title">納車可能日</h2>
                  <div className="delivery-content">
                    {formatDateWithDay(
                      new Date(item.delivery_possible_date || Date.now()),
                    )}
                  </div>
                </div>

                <div className="delivery-item">
                  <div className="delivery-item-label-bold">
                    第一希望
                    <Label required />
                  </div>

                  <SelectCalendar
                    name="delivery_preferred_date_1"
                    formMethods={formMethods}
                    minDate={new Date(item.delivery_possible_date)}
                  />
                  <br />
                  <SelectHours
                    name="delivery_preferred_hm_1"
                    startTime={item.start_h}
                    endTime={item.end_h}
                  />
                </div>

                <div className="delivery-item">
                  <div className="delivery-item-label-bold">
                    第二希望
                    <Label required />
                  </div>

                  <SelectCalendar
                    name="delivery_preferred_date_2"
                    formMethods={formMethods}
                    minDate={new Date(item.delivery_possible_date)}
                  />
                  <br />
                  <SelectHours
                    name="delivery_preferred_hm_2"
                    startTime={item.start_h}
                    endTime={item.end_h}
                  />
                </div>

                <div className="delivery-item">
                  <div className="delivery-item-label-bold">
                    第三希望
                    <Label required />
                  </div>

                  <SelectCalendar
                    name="delivery_preferred_date_3"
                    formMethods={formMethods}
                    minDate={new Date(item.delivery_possible_date)}
                  />
                  <br />
                  <SelectHours
                    name="delivery_preferred_hm_3"
                    startTime={item.start_h}
                    endTime={item.end_h}
                  />
                  <span className="error">{errors.duplicate?.message}</span>
                </div>

                <div className="delivery-item delivery-item-textarea">
                  <RhfTextareaWithLabel
                    name="delivery_preferred_date_complement"
                    label="備考"
                    maxLength={500}
                    placeholder="ご自由に入力してください。"
                  />
                </div>

                <Button
                  className="completed-btn completed-delivery-btn"
                  type="submit"
                  disabled={!isValid}
                >
                  送信する
                </Button>
              </div>

              <Popup
                opened={isOpened}
                onPopupClose={() => setIsOpened(false)}
                className="delivery-success"
              >
                <Page name="DeliveryComplete">
                  <div className="close-icon">
                    <i className="icon-close" onClick={handleBackToList} />
                  </div>

                  <div className="delivery-success-content">
                    <i className="icon-success" />
                    <h2>送信されました</h2>
                    <p>
                      ご予約ありがとうございます。 <br />
                      担当者から確定した日時をお送りします。
                    </p>
                  </div>
                </Page>
              </Popup>
            </form>
          </FormProvider>
        </Block>
      </div>
    </>
  );
};
