import React, { FC } from 'react';
import { AbnormalAlert } from './AbnormalAlert/AbnormalAlert';

interface AbnormalAlertPageProps {
  id: number;
}

export const AbnormalAlertPage: FC<AbnormalAlertPageProps> = ({ id }) => {
  return <AbnormalAlert id={id} />;
};
