import { baseApi } from '@/config/axios';
import {
  CancelCarWashResponse,
  CancelCarWashParams,
  ReservationHistoriesParams,
  ReservationHistoriesResponse,
  ReservationDetailParams,
  ReservationDetailResponse,
  ChangePrefferedDateCarWashParams,
  ChangePrefferedDateCarWashResponse,
  FetchCourseInfosResponse,
  FetchCourseInfosParams,
  CreateCarWashParams,
  CreateCarWashResponse,
  CarWashSizeParams,
  CarWashSizeResponse,
} from '@/types/api/reservationApi';

export const fetchReservationHistories = (
  params: ReservationHistoriesParams,
): Promise<ReservationHistoriesResponse> =>
  baseApi.get(`/v1/customer/reservation/histories`, { params });

export const fetchReservationDetail = async (
  params: ReservationDetailParams,
): Promise<ReservationDetailResponse> =>
  baseApi.get(`/v1/customer/reservation/detail`, { params });

export const fetchCarWashSize = async (
  params: CarWashSizeParams,
): Promise<CarWashSizeResponse> =>
  baseApi.get(`/v1/customer/carwash/size`, { params });

export const cancelCarWash = (
  params: CancelCarWashParams,
): Promise<CancelCarWashResponse> =>
  baseApi.post(`/v1/customer/reservation/cancellation`, params);

export const changePrefferedDateCarWash = (
  params: ChangePrefferedDateCarWashParams,
): Promise<ChangePrefferedDateCarWashResponse> =>
  baseApi.put(`/v1/customer/reservation/preffered-date-change`, params);

// 114.洗車コースマスター情報取得
export const fetchCourseInfos = (
  params: FetchCourseInfosParams,
): Promise<FetchCourseInfosResponse> =>
  baseApi.get('v1/customer/carwash/course-infos', { params });

export const createCarWash = (
  params: CreateCarWashParams,
): Promise<CreateCarWashResponse> =>
  baseApi.post('v1/customer/carwash/create', params);
