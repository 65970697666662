import { Router } from 'framework7/types';
import { tabsTrouble } from './tabsTrouble';
import { CarOrderPage, MaintenanceGuidePage } from '@/components/pages';
import { CarPage } from '@/components/pages/Car/CarPage';
import { CarDetailPage } from '@/components/pages/CarDetail/ CarDetailPage';
import { CarOrderDetailPage } from '@/components/pages/CarOrderDetail/CarOrderDetailPage';
import { HomePage } from '@/components/pages/Home/HomePage';
import { ProblemPage } from '@/components/pages/Problem/ProblemPage';
import { TroublePage } from '@/components/pages/Trouble/TroublePage';
import { UpdateInspectionPage } from '@/components/pages/UpdateInspection/UpdateInspectionPage';
import { UpdateNumberPage } from '@/components/pages/UpdateNumber/UpdateNumberPage';
import { paths } from '@/config/paths';

export const carInformationRoutes: Router.RouteParameters[] = [
  {
    path: paths.home,
    component: HomePage,
    name: '車両管理',
  },
  {
    path: paths.homeStatus(':status'),
    component: HomePage,
    name: '車両管理',
  },
  {
    path: paths.car(':id'),
    component: CarPage,
    name: '車両情報',
  },
  {
    path: '/car_detail/:id/',
    component: CarDetailPage,
    options: {
      animate: true,
    },
    name: '車両詳細情報',
  },
  {
    path: paths.updateNumber,
    popup: {
      component: UpdateNumberPage,
    },
    options: {
      animate: true,
    },
    name: '車両ナンバー編集',
  },
  {
    path: paths.updateInspection(':id'),
    popup: {
      component: UpdateInspectionPage,
    },
    options: {
      animate: true,
    },
    name: '車検情報の変更',
  },
  {
    path: '/maintenance_guide/',
    component: MaintenanceGuidePage,
    name: '整備の依頼',
  },
  {
    path: '/trouble/',
    component: TroublePage,
    name: '車が動かなくなった',
    tabs: tabsTrouble,
  },
  {
    path: '/problem/',
    component: ProblemPage,
    name: '車の不調',
    // tabs: tabsProblem,
  },
  {
    path: paths.carOrder(':id'),
    component: CarOrderPage,
    name: '注文書',
  },
  {
    path: paths.carOrderDetail(':id'),
    component: CarOrderDetailPage,
    name: 'お支払い情報',
  },
];
