import { Router } from 'framework7/types';
import { FC } from 'react';
import { DeliveryCheckItem } from './DeliveryCheckItem';
import style from './DeliveryCheckStyle.module.scss';
import { SuccessDialog } from './SuccessDialog/SuccessDialog';
import { Paper } from '@/components/uiParts/Card';
import { Form } from '@/components/uiParts/Form';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';

interface DeliveryCheckListProps {
  f7router: Router.Router;
  item: any;
}
type DeliveryCheckListType = {
  check1: boolean;
  check2: boolean;
  check3: boolean;
  check4: boolean;
  check5: boolean;
};
export interface OptionsCheckListType {
  id: number;
  title: string;
  description: string;
  name: string;
}

export const DeliveryCheckList: FC<DeliveryCheckListProps> = ({
  f7router,
  item,
}) => {
  const options = [
    {
      id: 1,
      title: '外装点検',
      description: '車両の傷やタイヤの装着状態など車両の外装状態を確認します。',
      name: 'check1',
    },
    {
      id: 2,
      title: '装備点検',
      description:
        '給油口やスペアタイヤ、シートベルト等の車載装備の確認をします。',
      name: 'check2',
    },
    {
      id: 3,
      title: 'エンジンルームの点検',
      description:
        'ボンネット内部のエンジンオイル量、ブレーキオイル量、冷却水量等の確認をします。',
      name: 'check3',
    },
    {
      id: 4,
      title: '車内点検',
      description: 'ドア・電子装備・エアコン・AV機能等の確認をします。',
      name: 'check4',
    },
    {
      id: 5,
      title: '走行機能',
      description:
        'ワイパー・ウォッシャー機能、ライト類、走行機能の確認をします。',
      name: 'check5',
    },
  ];
  return (
    <PageTemplate
      pageName="DeliveryCheckList"
      showNavBack
      title="納車チェック"
      handleClickBack={() =>
        f7router.back(paths.todoDetail(item.t_wf_instance_task_id.toString()), {
          props: { item },
        })
      }
    >
      <Paper
        cardHeader="納車時チェックを行ってください。"
        wrapperCN="notice-title"
      >
        <p>5項目のお車の状態を確認してください。</p>
      </Paper>
      <Paper>
        <Form<DeliveryCheckListType>>
          {({ watch }) => {
            const { check1, check2, check3, check4, check5 } = watch();
            const isAllCheck = check1 && check2 && check3 && check4 && check5;

            return (
              <>
                <div className={style['form-wrraper']}>
                  {options.map((option, idx) => (
                    <DeliveryCheckItem
                      options={options}
                      idx={idx}
                      {...option}
                      key={idx}
                    />
                  ))}
                </div>
                <SuccessDialog
                  isAllCheck={isAllCheck}
                  t_wf_instance_task_id={item.t_wf_instance_task_id}
                />
              </>
            );
          }}
        </Form>
      </Paper>
    </PageTemplate>
  );
};
