import { Card, f7 } from 'framework7-react';
import { CardProps } from 'framework7-react/components/card';
import { FC, ReactNode } from 'react';
import { Button } from '../../Button/Button/Button';
import style from './PaperStyles.module.scss';

interface PaperProps {
  cardHeader?: string | ReactNode;
  iconType?: string;
  cardProps?: CardProps;
  cardCN?: string;
  wrapperCN?: string;
  iconCN?: string;
  titleCN?: string;
  contentCN?: string;
  btnName?: string | ReactNode;
  path?: string;
  startIconBtn?: string;
  endIconBtn?: string;
  pathProps?: {};
  children?: ReactNode;
}

export const Paper: FC<PaperProps> = ({
  cardHeader,
  iconType,
  cardProps,
  btnName,
  cardCN = '',
  wrapperCN = '',
  iconCN = '',
  titleCN = '',
  contentCN = '',
  startIconBtn,
  endIconBtn,
  path,
  pathProps = {},
  children,
}) => {
  const handleClickNavigation = () => {
    path &&
      f7.view.main.router.navigate(path, { animate: true, props: pathProps });
  };
  return (
    <Card
      className={`${style['card']} u-margin-bottom-0 ${cardCN}`}
      noShadow
      {...cardProps}
    >
      {(cardHeader || btnName) && (
        <div className={`${style.title} u-margin-top-0 ${wrapperCN}`}>
          <div className={`display-flex-sb`} style={{ flex: 1 }}>
            {cardHeader && (
              <div className={`display-flex ${titleCN}`} style={{ flex: 1 }}>
                {iconType && <i className={`${iconType} ${iconCN}`} />}
                <h3 className="u-margin-top-0">{cardHeader}</h3>
              </div>
            )}
            {btnName && (
              <Button
                className={style.button}
                onClick={handleClickNavigation}
                icon={startIconBtn}
                style={{
                  height: startIconBtn || endIconBtn ? '4rem' : '3.2rem',
                }}
              >
                {btnName}
                {endIconBtn && (
                  <div>
                    <i className={endIconBtn} />
                  </div>
                )}
              </Button>
            )}
          </div>
        </div>
      )}
      {children && (
        <div className={`${style.content} ${contentCN}`}>{children}</div>
      )}
    </Card>
  );
};
