import { Button } from 'framework7-react';
import { FC } from 'react';
import style from './LinkDriveSetupStep2Style.module.css';
import DriveSetup1 from '@/assets/images/drive-setup-1.png';
import DriveSetup2 from '@/assets/images/drive-setup-2.png';

interface Props {
  onSubmit: () => void;
}

export const StepIntro: FC<Props> = ({ onSubmit }) => {
  return (
    <>
      <h2 className={style['head']}>
        <i className={`icon-check ${style['head-icon']}`} />
        コネクターを車に装着してくださいケーブルを経由して、コネクタを車に装着してください
      </h2>
      <div className={style['description']}>
        <span>※</span>
        <p>コネクタが装着されている場合はそのままお進みください。</p>
      </div>
      <div className={style['guide-warning-container']}>
        <p>
          <span>必ず付属するケーブルを経由</span>して
          <br />
          LINKDrive端末を車両に装着してください。
        </p>
        <div className={style['img-container']}>
          <img
            src={'/images/link_d_connect.png'}
            alt="link-drive-connect-cable"
          />
        </div>
      </div>
      <div className={style['step1-section']}>
        <h4>装着部分</h4>
        <div className={style['step1-attatch']}>
          <img src={DriveSetup1} width={122} />
          <div>
            <h4>車両のOBD Ⅱ コネクタ</h4>
            <div className={style['description']}>
              <span>※</span>
              <p>車種によって色は異なります</p>
            </div>
          </div>
        </div>
      </div>
      <div className={style['step1-section']}>
        <h4>装着箇所</h4>
        <p>
          装着場所は車によって異なります。下記の画像を参考に装着してください。
        </p>
        <img src={DriveSetup2} className={style['step1-image']} />
        <div className={style['step1-desctiption']}>
          <div>① キックパネル付近</div>
          <div>⑥ 助手席足元右側</div>
          <div>② 運転席足元右側</div>
          <div>⑦ ステアリング右パネル裏側</div>
          <div>③ 運転席足元中央</div>
          <div>③ 助手席足元左側</div>
          <div>④ 運転席足元左側</div>
          <div>⑨ センターコンソール左側</div>
          <div>⑤ センターコンソール右側</div>
          <div>⑩ センターコンソール下側</div>
        </div>
      </div>
      <Button
        fill
        round
        text="装着した"
        style={{ marginTop: 'auto' }}
        onClick={onSubmit}
      />
    </>
  );
};
