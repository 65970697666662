import { f7 } from 'framework7-react';
import { useState } from 'react';
import { loginByNativeApi } from '@/api/authApi';
import { isApp } from '@/config/device';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { message } from '@/consts/login';
import { url } from '@/consts/url';
import { LoginApiParams, LoginByNativeApiParams } from '@/types/api/authApi';

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const login = async (params: LoginApiParams, isDuplicated?: boolean) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      await determineDeviceAndCallLogin(params, isDuplicated);
      if (!params.m_affiliate_id) {
        await store.dispatch('setIsLoggedInToMaikuru', {
          isLoggedInToMaikuru: true,
        });
      }
      store.dispatch('setDiagnosticDate', { diagnosticDate: null });
      if (isDuplicated && store.state.globalTheme == null) {
        f7.views.main.router.navigate(paths.emailDuplicated);
      } else {
        f7.views.main.router.navigate(paths.home);
      }
    } catch (err) {
      if (err instanceof Error) {
        f7.dialog.alert(err?.message, 'エラー');
        setError(err);
      }
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  const loginByNative = async (
    params: LoginByNativeApiParams,
    isDuplicated?: boolean,
  ) => {
    const { data: loginData } = await loginByNativeApi(params);
    if (!loginData.success) {
      const obj = loginData.error;
      const messages = Object.entries(obj ?? {}).flatMap(
        ([key, value]) => value,
      );
      throw new Error(messages.join('\n') ?? message.DEFAULT_LOGIN_ERROR);
    }
    const decodedToken = decodeURI(loginData.token);
    store.dispatch('setAuthInfo', {
      authInfo: {
        m_customer_id: loginData.m_customers.id,
        token: decodedToken,
        isLogin: true,
      },
    });
    // responseをNativeに渡す
    if (isApp && !isDuplicated) {
      const desiredUrl = `${url.NATIVE_BASE_URL}/set/login_success?token=${decodedToken}&id=${loginData.m_customers.id}&type=email`;
      window.location.href = desiredUrl;
    }
  };

  const determineDeviceAndCallLogin = async (
    // isApp: boolean,
    params: LoginApiParams,
    isDuplicated?: boolean,
  ) => {
    return await loginByNative(
      { ...params, token_abilities: [] },
      isDuplicated,
    );
  };

  return {
    login,
    isLoading,
    error,
  };
};
