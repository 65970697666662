import { FC } from 'react';
import { EditCustomerField } from './EditCustomerField/EditCustomerField';
import { Customer } from '@/types/api/customerApi';

interface EditCustomerPageProps {
  initialSetCustomer: (customer: Customer) => void;
}

export const EditCustomerPage: FC<EditCustomerPageProps> = ({
  initialSetCustomer,
}) => {
  return <EditCustomerField initialSetCustomer={initialSetCustomer} />;
};
