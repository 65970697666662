import { useEffect, useState } from 'react';
import { RANK_BENEFITS } from './rankData';
import { store } from '@/config/store';
import { RANK_THEMES, Rank } from '@/consts/rank';
import { affiliateId } from '@/consts/shop';
import { RankBenefit, RankTheme } from '@/types/api/rank';

export const useRank = () => {
  const { m_affiliate_id, customer_rank_name } = store.state.customerInfo;
  const [userRank, setUserRank] = useState<Rank>();
  const [rankTheme, setRankTheme] = useState<RankTheme>();
  const [rankBenefits, setRankBenefits] = useState<RankBenefit[]>([]);

  const initialRank = () => {
    if (m_affiliate_id !== affiliateId.YOSHIDA) {
      setUserRank(undefined);
      return;
    }

    setUserRank(
      !!customer_rank_name ? (customer_rank_name as Rank) : Rank.REGULAR,
    );
  };

  useEffect(() => {
    initialRank();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [m_affiliate_id, customer_rank_name]);

  useEffect(() => {
    setRankTheme(userRank ? RANK_THEMES[userRank] : undefined);

    setRankBenefits(userRank ? RANK_BENEFITS[userRank] : []);
  }, [userRank]);

  return {
    userRank,
    rankTheme,
    rankBenefits,
  };
};
