import { Checkbox, useStore } from 'framework7-react';
import { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ConfirmInput } from '../PromptConfirmDetail';
import { ConfirmTerm } from './ConfirmTerm';
import style from './PromptConfirmStyle.module.css';
import { store } from '@/config/store';
import { termType } from '@/consts/terms';

const CHECKBOX_GROUPS = [
  {
    label: '払い戻し等について',
    checkbox: [
      'プリペイドサービスは、原則といたしまして、法令に定める場合を除き払い戻すことはできません。',
      'プリペイドサービスは、第三者に利用させることはできません。',
    ],
  },
  {
    label: 'プリペイドサービスの積立について',
    checkbox: [
      'プリペイドサービスのお申込みならびに決済方法登録の翌月よりお引落（あるいはクレジットカード決済）が開始されます。残高への反映はさらに翌月の15日です。　例）1月に申込みと決済登録→2月に引落→3月15日に残高へ反映',
    ],
  },
  {
    label: 'プリペイドサービスによる決済について',
    checkbox: [
      'プリペイドサービスによる決済を取り消す必要が生じた場合、対象商品等の代金に相当する金額を利用者のプリペイドサービスアカウントに加算する方法によるご返金となります。',
    ],
  },
  {
    label: 'プリペイドサービスボーナスの取扱いについて',
    checkbox: [
      'プリペイドサービスをご利用のお客様に対し、プリペイドサービスへのご入金額に応じたプリペイドサービスボーナス（当社が無償で付与するプリペイドサービスのことをいいます）を付与します。\nプリペイドサービスボーナスが付与された決済の取引が取り消された場合には、付与されたプリペイドサービスボーナスも同時に取り消されます。',
      'プリペイドサービスボーナスの残高がなくなるまで、自動的にプリペイドサービス残高に優先して、支払いに充当されます。',
      'プリペイドサービスボーナスの払戻しや譲渡はできません。',
      'お客様がプリペイドサービスのご利用を終了（プリペイドサービスアカウントの停止等）された場合には、プリペイドサービスボーナスはすべて消滅します。',
    ],
  },
  {
    label: 'プリペイドサービスの有効期間について',
    checkbox: [
      'プリペイドサービスの有効期間は、最後に入金または利用した日より2年後です。',
    ],
  },
  {
    label: '入金遅滞の場合のお取り扱いについて',
    checkbox: [
      '残高不足等の理由により、指定口座からお支払い日に指定金額の引落ができないときは、該当月は未払い扱いといたします。次月以降の合算引落はございません。',
      'クレジットカード会社からのチャージバックが発生した際には、チャージバック相当額を該当のハウスプリペイドアカウントの残高より相殺いたします。',
      '理由の如何を問わず、未払い扱いの月が連続で2か月発生した場合、引落継続の意思が無いものとみなし引落を停止いたします。引落を再開する場合は、当社指定の手続きを行うことにより引落を再開することができます。',
    ],
  },
];

export const ConfirmPrepaid: FC = () => {
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo');
  const { watch, setValue, trigger } = useFormContext<ConfirmInput>();
  const [popupOpened, setPopupOpened] = useState(false);

  const currentCheckbox = watch('currentCheckbox');

  const handleChange = (event: BaseSyntheticEvent) => {
    if (event.target.checked) {
      setValue('currentCheckbox', currentCheckbox + 1, {
        shouldValidate: true,
      });
    } else {
      setValue('currentCheckbox', currentCheckbox - 1, {
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    const checkboxCount = CHECKBOX_GROUPS.reduce(
      (current, item) => current + item.checkbox.length,
      0,
    );
    setValue('targetedCheckox', checkboxCount);
    setValue('currentCheckbox', 0, {
      shouldValidate: true,
    });
  }, [setValue, trigger]);

  return (
    <div className={style['container']}>
      <ConfirmTerm
        type={termType.PREPAID}
        title="プリペイドサービス利用規約"
        opened={popupOpened}
        prepaidId={prepaid.id}
        onPopupClosed={() => setPopupOpened(false)}
      />
      <div className={style['label-wrapper']}>
        <p className={style['label']}>プリペイドサービス　重要事項説明書</p>
        <span className={style['label-tip']}>必須</span>
      </div>
      <p className={style['description']}>
        お客様に安心してプリペイドサービスをご利用いただく為に、重要な項目を抜粋し記載しております。下記事項をご確認いただくとともに、詳しくは「
        <br />
        <span
          className={style['text-link']}
          onClick={() => setPopupOpened(true)}
        >
          プリペイドサービス利用規約
        </span>
        」をご一読のうえ、プリペイドサービスのご利用を頂きます様、お願い申し上げます。
      </p>
      {CHECKBOX_GROUPS.map(({ label, checkbox }, index) => (
        <div key={index} className={style['checkbox-group']}>
          <h4>{label}</h4>
          {checkbox.map((checkboxLabel, checboxIndex) => (
            <label key={checboxIndex} className={style['checkbox-item']}>
              <Checkbox
                value={`${index}-${checboxIndex}`}
                onChange={handleChange}
              />
              {checkboxLabel}
            </label>
          ))}
        </div>
      ))}
    </div>
  );
};
