import { Card, CardContent, f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useHouseprepaidUseInfo } from '@/hooks/api/prepaid/useHouseprepaidUseInfo';
import { storeDispatch } from '@/utils/store';
import './UsePrepaidConfirmStyle.scss';
import { f7CustomBack } from '@/utils/utils';

interface UsePrepaidComfirmProps {
  f7router: Router.Router;
  value: string;
  unit: string;
  handleChangeBalanceTotal: (chargeing: number) => void;
}

export const UsePrepaidConfirm: FC<UsePrepaidComfirmProps> = ({
  f7router,
  value = 0,
  unit = 0,
  handleChangeBalanceTotal,
}) => {
  const handleAnkerClick = () => {
    f7CustomBack(['#UsePrepaidConfirm']);
    f7.view.main.router.navigate(paths.usePrepaid, {
      props: {
        f7router: f7router,
        handleChangeBalanceTotal: handleChangeBalanceTotal,
      },
    });
  };

  const prepaidsInfo = store.state.advancePaymentsInfo[0];

  const { usePrepaid } = useHouseprepaidUseInfo();

  const useHandlePaid = async () => {
    const params = {
      m_customer_id: store.state.authInfo.m_customer_id,
      m_prepaid_id: store.state.advancePaymentsInfo[0].id,
      use_price: Number(value),
    };
    const result: any = await usePrepaid(params);
    if (!result) {
      return;
    }

    handleChangeBalanceTotal(-Number(value));

    // storeに入れてる残高を更新
    const newAdvancePrepaidsInfo = store.state.advancePaymentsInfo;
    newAdvancePrepaidsInfo[0].balance_total =
      prepaidsInfo.balance_total - Number(value);

    await storeDispatch(
      'setAdvancePaymentsInfo',
      'advancePaymentsInfo',
      newAdvancePrepaidsInfo,
    );
    f7CustomBack(['#UsePrepaidConfirm']);
    f7.view.main.router.navigate(paths.usePrepaidComplete, {
      props: { value: value, f7router: f7router },
    });
  };

  return (
    <PopupPageTemplate
      id="UsePrepaidConfirm"
      pageName="UsePrepaidConfirm"
      title="お支払い"
    >
      <div style={{ marginTop: '40px' }}>
        <Card
          style={{
            boxShadow: 'none',
            border: '1px solid #efefef',
            borderRadius: '8px',
            width: '95%',
            paddingBottom: '2rem',
            margin: '0 auto',
          }}
        >
          <CardContent className="reflect-xy">
            {/* <div className="payment_destination_label">お支払い先</div>
            <div className="payment_destination">{unit}</div> */}
            <div className="payment_destination_label">お支払い額</div>
            <div className="payment_destination">
              <span className="payment_money">{value}</span> 円
            </div>
          </CardContent>
        </Card>

        <div className="destination" style={{ marginTop: '10rem' }}>
          店頭のスタッフに金額を見せ、
        </div>
        <div className="destination" style={{ marginBottom: '5rem' }}>
          確認できたら支払いを確定して下さい
        </div>

        <Button
          style={{ margin: '2rem auto', width: '60%', height: '5rem' }}
          text="支払う"
          handleClickAction={useHandlePaid}
        />
        <Button
          style={{
            margin: '3rem auto',
            width: '50%',
            background: '#efefef',
            color: '#232323',
          }}
          text="金額を変更する"
          handleClickAction={handleAnkerClick}
        />
      </div>
    </PopupPageTemplate>
  );
};
