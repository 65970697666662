import { format } from 'date-fns';
import * as yup from 'yup';
import { CheckExpectedAt, IdentificationSubscriber } from '@/consts/carRepair';
export enum ReservationStep {
  // Step 1: Repair request to store
  BRING_CAR_TO_STORE = 'bringCarToStore',
  // Step 2: Confirmation screen
  CONFIRMATION = 'confirmation',
}
export type FormInput = {
  is_checked_expected_nyukosaki: CheckExpectedAt;
  expected_nyuko_date1?: Date;
  expected_nyuko_time1?: string;
  expected_nyuko_date2?: Date;
  expected_nyuko_time2?: string;
  expected_nyuko_date3?: Date;
  expected_nyuko_time3?: string;
  is_checked_identification_subscriber: string;
  contact_person_phone_number: {
    tel1?: string;
    tel2?: string;
    tel3?: string;
  };
  contact_person_name: {
    family_name: string;
    first_name: string;
  };
  contact_person_name_kana: {
    family_name: string;
    first_name: string;
  };
  warrantyConfirmed?: boolean;
};

const isUniqueDate: yup.TestFunction = (value, context) => {
  const {
    expected_nyuko_date1,
    expected_nyuko_date2,
    expected_nyuko_date3,
    expected_nyuko_time1,
    expected_nyuko_time2,
    expected_nyuko_time3,
  } = context.parent;
  const datetime1 =
    expected_nyuko_date1 && expected_nyuko_time1
      ? `${format(expected_nyuko_date1, 'yyyy-MM-dd')} ${expected_nyuko_time1}`
      : '';
  const datetime2 =
    expected_nyuko_date2 && expected_nyuko_time2
      ? `${format(expected_nyuko_date2, 'yyyy-MM-dd')} ${expected_nyuko_time2}`
      : '';
  const datetime3 =
    expected_nyuko_date3 && expected_nyuko_time3
      ? `${format(expected_nyuko_date3, 'yyyy-MM-dd')} ${expected_nyuko_time3}`
      : '';

  switch (context.path) {
    case 'expected_nyuko_date1':
    case 'expected_nyuko_time1':
      return !datetime1 || (datetime1 != datetime2 && datetime1 != datetime3);
    case 'expected_nyuko_date2':
    case 'expected_nyuko_time2':
      return !datetime2 || (datetime1 != datetime2 && datetime2 != datetime3);
    case 'expected_nyuko_date3':
    case 'expected_nyuko_time3':
      return !datetime3 || (datetime1 != datetime3 && datetime2 != datetime3);
    default:
      return true;
  }
};

export const tradeReservationSchema = yup.object().shape(
  {
    is_checked_expected_nyukosaki: yup
      .mixed<CheckExpectedAt>()
      .oneOf(Object.values(CheckExpectedAt))
      .required(),
    expected_nyuko_date1: yup.date().when('is_checked_expected_nyukosaki', {
      is: CheckExpectedAt.EXTERNAL_FACTORY,
      then: yup.date().notRequired(),
      otherwise: yup.date().required(),
    }),

    expected_nyuko_time1: yup.string().when('is_checked_expected_nyukosaki', {
      is: CheckExpectedAt.EXTERNAL_FACTORY,
      then: yup.string().notRequired(),
      otherwise: yup.string().required(),
    }),

    expected_nyuko_date2: yup
      .date()
      .optional()
      .when('expected_nyuko_time2', {
        is: (time: string) => !!time,
        then: yup.date().required('日付を選択してください'),
      })
      .when(
        [
          'is_checked_expected_nyukosaki',
          'expected_nyuko_date1',
          'expected_nyuko_time1',
        ],
        {
          is: (checkExpectedAt: CheckExpectedAt, date1: Date, time1: string) =>
            checkExpectedAt != CheckExpectedAt.EXTERNAL_FACTORY &&
            !!date1 &&
            !!time1,
          then: yup
            .date()
            .test('unique', '選択した時間が重複しています', isUniqueDate),
        },
      ),
    expected_nyuko_time2: yup
      .string()
      .optional()
      .when('expected_nyuko_date2', {
        is: (date: Date) => !!date,
        then: yup.string().required('開始時間を選択してください'),
      })
      .when(
        [
          'is_checked_expected_nyukosaki',
          'expected_nyuko_date1',
          'expected_nyuko_time1',
        ],
        {
          is: (checkExpectedAt: CheckExpectedAt, date1: Date, time1: string) =>
            checkExpectedAt != CheckExpectedAt.EXTERNAL_FACTORY &&
            !!date1 &&
            !!time1,
          then: yup
            .string()
            .test('unique', '選択した時間が重複しています', isUniqueDate),
        },
      ),

    expected_nyuko_date3: yup
      .date()
      .optional()
      .when('expected_nyuko_time3', {
        is: (time: string) => !!time,
        then: yup.date().required('日付を選択してください'),
      })
      .when(
        [
          'is_checked_expected_nyukosaki',
          'expected_nyuko_date1',
          'expected_nyuko_time1',
        ],
        {
          is: (checkExpectedAt: CheckExpectedAt, date1: Date, time1: string) =>
            checkExpectedAt != CheckExpectedAt.EXTERNAL_FACTORY &&
            !!date1 &&
            !!time1,
          then: yup
            .date()
            .test('unique', '選択した時間が重複しています', isUniqueDate),
        },
      ),
    expected_nyuko_time3: yup
      .string()
      .optional()
      .when('expected_nyuko_date3', {
        is: (date: Date) => !!date,
        then: yup.string().required('開始時間を選択してください'),
      })
      .when(
        [
          'is_checked_expected_nyukosaki',
          'expected_nyuko_date1',
          'expected_nyuko_time1',
        ],
        {
          is: (checkExpectedAt: CheckExpectedAt, date1: Date, time1: string) =>
            checkExpectedAt == CheckExpectedAt.EXTERNAL_FACTORY &&
            !!date1 &&
            !!time1,
          then: yup
            .string()
            .test('unique', '選択した時間が重複しています', isUniqueDate),
        },
      ),
    is_checked_identification_subscriber: yup
      .mixed<IdentificationSubscriber>()
      .oneOf(Object.values(IdentificationSubscriber))
      .required(),
    contact_person_phone_number: yup
      .object({
        tel1: yup.string().required(),
        tel2: yup.string().required(),
        tel3: yup.string().required(),
      })
      .test('phoneNo', ({ tel1, tel2, tel3 }) => {
        const fixedRegex =
          /^0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}$/;
        const mobileRegex = /^(050|070|080|090)-\d{4}-\d{4}$/;
        if (fixedRegex.test(`${tel1}-${tel2}-${tel3}`)) return true;
        if (mobileRegex.test(`${tel1}-${tel2}-${tel3}`)) return true;
        return false;
      }),
    contact_person_name: yup
      .object()
      .when('is_checked_identification_subscriber', {
        is: IdentificationSubscriber.AGENT,
        then: yup.object().shape({
          family_name: yup.string().required(),
          first_name: yup.string().required(),
        }),
        otherwise: yup.object().shape({
          family_name: yup.string().notRequired(),
          first_name: yup.string().notRequired(),
        }),
      }),
    contact_person_name_kana: yup
      .object()
      .when('is_checked_identification_subscriber', {
        is: IdentificationSubscriber.AGENT,
        then: yup.object().shape({
          family_name: yup
            .string()
            .required('お名前ヨミガナが指定されていません')
            .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
          first_name: yup
            .string()
            .required('お名前ヨミガナが指定されていません')
            .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
        }),
        otherwise: yup.object().shape({
          family_name: yup.string().notRequired(),
          first_name: yup.string().notRequired(),
        }),
      }),
    warrantyConfirmed: yup
      .boolean()
      .oneOf([true])
      .when('is_checked_expected_nyukosaki', {
        is: CheckExpectedAt.EXTERNAL_FACTORY,
        then: yup.boolean().required(),
        otherwise: yup.boolean().transform(() => true),
      }),
  },
  [
    ['expected_nyuko_date2', 'expected_nyuko_time2'],
    ['expected_nyuko_date3', 'expected_nyuko_time3'],
  ],
);
