import { IconProps } from './type';

export const TelephoneIcon = ({
  width = '32',
  height = '32',
  color = '#1E55E6',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill={color} />
      <path
        d="M21.7356 24.1601H21.6914C18.1983 23.9477 14.9037 22.4642 12.4293 19.9895C9.95484 17.5147 8.47172 14.2199 8.25977 10.7268C8.25977 10.7123 8.25977 10.6976 8.25977 10.6826C8.25977 10.0402 8.51485 9.42406 8.96895 8.96964C9.42306 8.51523 10.039 8.25972 10.6814 8.25928H14.0823C14.2267 8.25928 14.3678 8.30254 14.4873 8.38349C14.6069 8.46445 14.6995 8.57937 14.7531 8.71344L16.4539 12.9634C16.5178 13.1229 16.5228 13.2999 16.468 13.4627C16.4131 13.6255 16.3021 13.7634 16.1548 13.8518L14.6223 14.7684C15.3627 16.0117 16.4015 17.0505 17.6448 17.7909L18.5614 16.2584C18.6498 16.1111 18.7877 16.0001 18.9505 15.9453C19.1133 15.8904 19.2903 15.8954 19.4498 15.9593L23.6998 17.6601C23.8338 17.7137 23.9488 17.8063 24.0297 17.9259C24.1107 18.0455 24.1539 18.1865 24.1539 18.3309V21.7318C24.1546 22.3743 23.9003 22.9908 23.447 23.4461C22.9936 23.9013 22.3781 24.1581 21.7356 24.1601ZM9.70393 10.6634C9.90238 13.7948 11.2356 16.7464 13.454 18.9653C15.6723 21.1842 18.6235 22.5183 21.7548 22.7176C22.0105 22.7122 22.254 22.6068 22.4331 22.4242C22.6122 22.2415 22.7127 21.9959 22.7131 21.7401V18.8243L19.4873 17.5343L18.5289 19.1343C18.4357 19.2899 18.2873 19.4047 18.1134 19.4559C17.9394 19.5072 17.7525 19.4911 17.5898 19.4109C15.6012 18.4264 13.991 16.8161 13.0064 14.8276C12.9262 14.6649 12.9102 14.478 12.9614 14.304C13.0127 14.13 13.1275 13.9817 13.2831 13.8884L14.8831 12.9301L13.5931 9.70511H10.6814C10.4255 9.70506 10.1797 9.80541 9.99692 9.9846C9.81415 10.1638 9.70895 10.4075 9.70393 10.6634Z"
        fill="white"
      />
    </svg>
  );
};
