import { addDays } from 'date-fns';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectCalendar, TimePicker } from '../../CarRepair/Partials';
import style from './TradeReservationPage.module.css';
import { FormInput } from './tradeReservationSchema';
import { MIN_REPAIR_AFTER_CURRENT_DAY } from '@/consts/carRepair';

type DesiredDateProps = {
  isHidden: boolean;
};

export const DesiredDate: FC<DesiredDateProps> = ({ isHidden }) => {
  const minDate = addDays(new Date(), MIN_REPAIR_AFTER_CURRENT_DAY);
  const {
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<FormInput>();
  const date1 = watch('expected_nyuko_date1');
  const time1 = watch('expected_nyuko_time1');
  const triggerDateTimeField = () => {
    trigger('expected_nyuko_date1');
    trigger('expected_nyuko_date2');
    trigger('expected_nyuko_date3');

    trigger('expected_nyuko_time1');
    trigger('expected_nyuko_time2');
    trigger('expected_nyuko_time3');
  };
  return (
    <div className={`u-margin-bottom-md ${isHidden ? style['hidden'] : ''}`}>
      <h2 className={style['input-label']}>
        ご依頼の希望日時を選択してください
      </h2>
      <h3 className={style['input-label']}>
        第一希望
        <span className={`${style['input-label-tips']} ${style['required']}`}>
          任意
        </span>
      </h3>
      <SelectCalendar
        label="日付"
        placeholder="年/月/日"
        name="expected_nyuko_date1"
        minDate={minDate}
        onChange={() => triggerDateTimeField()}
      />
      <TimePicker
        label="開始時間"
        id="expected_nyuko_time1"
        name="expected_nyuko_time1"
        onChange={() => triggerDateTimeField()}
        hasError={
          (!!errors?.expected_nyuko_date1 || !!errors?.expected_nyuko_time1) &&
          !!date1 &&
          !!time1
        }
      />
      {(!!errors?.expected_nyuko_date1 || !!errors?.expected_nyuko_time1) &&
        !!date1 &&
        !!time1 && (
          <p className={style['error-message']}>選択した時間が重複しています</p>
        )}
      <h3 className={`${style['input-label']} ${style['margin-top-2']}`}>
        第二希望
        <span className={style['input-label-tips']}>任意</span>
      </h3>
      <SelectCalendar
        label="日付"
        placeholder="年/月/日"
        name="expected_nyuko_date2"
        minDate={minDate}
        onChange={() => triggerDateTimeField()}
        timePairName="expected_nyuko_time2"
        showClearButton
        hasError={
          ['required', 'unique'].includes(
            errors?.expected_nyuko_date2?.type as string,
          ) || errors?.expected_nyuko_time2?.type == 'unique'
        }
      />
      {errors?.expected_nyuko_date2?.type == 'required' && (
        <p className={style['error-message']}>
          {errors.expected_nyuko_date2?.message}
        </p>
      )}
      <TimePicker
        label="開始時間"
        id="expected_nyuko_time2"
        name="expected_nyuko_time2"
        onChange={() => triggerDateTimeField()}
        hasError={
          ['required', 'unique'].includes(
            errors?.expected_nyuko_time2?.type as string,
          ) || errors?.expected_nyuko_date2?.type == 'unique'
        }
      />
      {(['required', 'unique'].includes(
        errors?.expected_nyuko_time2?.type as string,
      ) ||
        errors?.expected_nyuko_date2?.type == 'unique') && (
        <p className={style['error-message']}>
          {errors?.expected_nyuko_time2?.message ||
            errors?.expected_nyuko_date2?.message}
        </p>
      )}
      <h3 className={`${style['input-label']} ${style['margin-top-2']}`}>
        第三希望
        <span className={style['input-label-tips']}>任意</span>
      </h3>
      <SelectCalendar
        label="日付"
        placeholder="年/月/日"
        name="expected_nyuko_date3"
        minDate={minDate}
        onChange={() => triggerDateTimeField()}
        timePairName="expected_nyuko_time3"
        showClearButton
        hasError={
          ['required', 'unique'].includes(
            errors?.expected_nyuko_date3?.type as string,
          ) || errors?.expected_nyuko_time3?.type == 'unique'
        }
      />
      {errors?.expected_nyuko_date3?.type == 'required' && (
        <p className={style['error-message']}>
          {errors.expected_nyuko_date3?.message}
        </p>
      )}
      <TimePicker
        label="開始時間"
        id="expected_nyuko_time3"
        name="expected_nyuko_time3"
        onChange={() => triggerDateTimeField()}
        hasError={
          ['required', 'unique'].includes(
            errors?.expected_nyuko_time3?.type as string,
          ) || errors?.expected_nyuko_date3?.type == 'unique'
        }
      />
      {(['required', 'unique'].includes(
        errors?.expected_nyuko_time3?.type as string,
      ) ||
        errors?.expected_nyuko_date3?.type == 'unique') && (
        <p className={style['error-message']}>
          {errors?.expected_nyuko_time3?.message ||
            errors?.expected_nyuko_date3?.message}
        </p>
      )}
    </div>
  );
};
