import { FC, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './CarWashConditionerStep2Style.module.css';
import {
  Step2FormData,
  TEL1_MAX_LENGTH,
  TEL2_MAX_LENGTH,
  TEL3_MAX_LENGTH,
} from '@/hooks/api/carWash/useCarWashConditionerStep2';

const TEL1_INDEX = 0;
const TEL2_INDEX = 1;
const TEL3_INDEX = 2;

export const PhoneInput: FC = () => {
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<Step2FormData>();

  const tel1 = watch('reservationHolder.tel1');
  const tel2 = watch('reservationHolder.tel2');
  const tel3 = watch('reservationHolder.tel3');

  const error = errors.reservationHolder?.type === 'phoneNo';

  return (
    <div className={`${style['input-group']}`}>
      <div
        className={`${style['input-text']} ${
          !!error ? style['input-text-error'] : ''
        }`}
      >
        <label>
          お電話番号
          <span className={`${style['input-label-tips']} ${style['required']}`}>
            必須
          </span>
        </label>
        <div className={style['input-group-1']}>
          <input
            ref={inputRefs[TEL1_INDEX]}
            placeholder="000"
            type="number"
            maxLength={3}
            value={tel1}
            onInput={() => trigger('reservationHolder')}
            onChange={(e) => {
              if (e.target.value.length <= TEL1_MAX_LENGTH)
                setValue('reservationHolder.tel1', e.target.value, {
                  shouldValidate: true,
                  shouldTouch: true,
                });
              if (e.target.value.length === TEL1_MAX_LENGTH) {
                inputRefs[TEL1_INDEX].current?.blur();
                inputRefs[TEL2_INDEX].current?.focus();
              }
            }}
          />
          -
          <input
            ref={inputRefs[TEL2_INDEX]}
            placeholder="1234"
            type="number"
            maxLength={4}
            value={tel2}
            onInput={() => trigger('reservationHolder')}
            onChange={(e) => {
              if (e.target.value.length <= TEL2_MAX_LENGTH)
                setValue('reservationHolder.tel2', e.target.value, {
                  shouldValidate: true,
                  shouldTouch: true,
                });
              if (e.target.value.length === TEL2_MAX_LENGTH) {
                inputRefs[TEL2_INDEX].current?.blur();
                inputRefs[TEL3_INDEX].current?.focus();
              }
            }}
          />
          -
          <input
            ref={inputRefs[TEL3_INDEX]}
            placeholder="5678"
            type="number"
            maxLength={4}
            value={tel3}
            onInput={() => trigger('reservationHolder')}
            onChange={(e) => {
              trigger('reservationHolder.tel3');
              if (e.target.value.length <= TEL3_MAX_LENGTH)
                setValue('reservationHolder.tel3', e.target.value, {
                  shouldValidate: true,
                  shouldTouch: true,
                });
              if (e.target.value.length === TEL3_MAX_LENGTH) {
                inputRefs[TEL3_INDEX].current?.blur();
              }
            }}
          />
        </div>
        {!!errors?.reservationHolder && (
          <p className={`${style['error-text']} u-margin-top-xs`}>
            有効な電話番号が指定されていません
          </p>
        )}
      </div>
    </div>
  );
};
