import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { findRelevantNextWarranty } from '../WarrantyManagement/WarrantyManagementHelper';
import { Payments } from './Payments/Payments';
import { makePayment } from './paymentsHelper';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { PaymentMethod } from '@/types/payment';
import { storeDispatch } from '@/utils/store';

interface PaymentsPageProps {
  f7router: Router.Router;
  popupOpened: boolean;
}

export const PaymentsPage: FC<PaymentsPageProps> = ({
  f7router,
  popupOpened,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const paymentMethods = useStore(
    store,
    'getPaymentMethodsInfo',
  ) as Required<PaymentMethod>[];
  const {
    carsInfo,
    carItemInfo: car,
    advancePaymentsInfo,
    billsInfo: bills,
    // warranties,
  } = store.state;

  const handleClickWarranty = async (mCarId?: number) => {
    const carInfo = carsInfo.find((c) => mCarId === c.m_car_id);
    if (!carInfo) {
      f7.view.main.router.navigate(paths.warranty('false'));
      return;
    }
    await storeDispatch('setCarItemInfo', 'carItemInfo', carInfo);
    await storeDispatch('setWarranties', 'warranties', carInfo?.warranties);
    await storeDispatch(
      'setNextWarranties',
      'nextWarranties',
      carInfo?.next_warranty,
    );
    await storeDispatch(
      'setCurrentWarranty',
      'currentWarranty',
      carInfo?.warranties?.[0],
    );
    await storeDispatch(
      'setNextWarranty',
      'nextWarranty',
      findRelevantNextWarranty(
        carInfo?.warranties?.[0],
        carInfo?.next_warranty,
      ),
    );
    f7.view.main.router.navigate(paths.warranty('false'));
  };

  const handleClickBack = () => {
    f7.view.main.router.navigate(paths.account, {
      transition: 'push-reverse',
    });
  };

  const prepaid = advancePaymentsInfo[0];
  const paymentMethodList = makePayment(
    paymentMethods,
    carsInfo,
    bills,
    prepaid,
  );

  useEffect(() => {
    setTimeout(() => {
      setIsPopupOpened(popupOpened);
    }, 400);
  }, [popupOpened]);

  return (
    <Payments
      isPopupOpened={isPopupOpened}
      f7router={f7router}
      paymentList={paymentMethodList}
      hanldeClosePopup={() => setIsPopupOpened(false)}
      handleClickWarranty={handleClickWarranty}
      handleClickBack={handleClickBack}
    />
  );
};
