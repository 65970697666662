import {
  Link,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
} from 'framework7-react';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import style from './pageTemplateStyle.module.css';
import './pageTemplateV2Style.css';
import { MenuFooter } from '@/components/uiParts/MenuFooter/MenuFooter';
import { isApp, isIPhone } from '@/config/device';
import { store } from '@/config/store';
import { shopTheme } from '@/consts/shop';
import { switchShopTheme } from '@/utils/shop';
import { f7CustomBack } from '@/utils/utils';

interface PageTemplatePropsV2 {
  children: ReactNode;
  pageName: string;
  title: string;
  showNavBack?: boolean;
  noScroll?: boolean;
  inlinePageStyle?: React.CSSProperties;
  handleClickBack?: () => void;
  onRefresh?: (status: any) => void;
  containerBackgroundColor?: string;
}
export const PageTemplateV2: FC<PageTemplatePropsV2> = ({
  children,
  pageName,
  title,
  showNavBack = false,
  noScroll = false,
  inlinePageStyle,
  handleClickBack,
  onRefresh,
  containerBackgroundColor,
}) => {
  // IPhone safe-area padding!
  const iPhoneTopPadding = 50;
  const themeId = store.state.themeId;
  const shopThemeObj = switchShopTheme(themeId) || shopTheme.default;
  const pageStyle = { backgroundColor: shopThemeObj.mainColor };
  const [offsetY, setOffsetY] = useState(0);
  const isPtr = useMemo(() => {
    return onRefresh && offsetY === 0;
  }, [onRefresh, offsetY]);

  const containerBackground = {
    '--background-color': containerBackgroundColor || shopThemeObj.mainBgColor,
  } as React.CSSProperties;

  const getClassPage = () => {
    if (isApp) {
      if (isIPhone) {
        return `${style['page']} ${style['ios-page']}`;
      }
    }
    return `${style['page']}`;
  };

  useEffect(() => {
    const navbarsElement = document.getElementsByClassName('navbars')[0];
    if (navbarsElement) {
      // TODO: Confirm the background color should be dynamic or not, if so where should it be set from.
      // Then adjust the code accordingly.
      navbarsElement.setAttribute(
        'style',
        `height: calc(var(--f7-navbar-height) + var(--f7-safe-area-top)); background-image: linear-gradient(-180deg, ${shopThemeObj.homeBorderColorFirst} 4px, ${shopThemeObj.homeBorderColorSecond} 4px); background-size: 100% 8px; background-repeat: no-repeat; background-position: 0 100%; background-color: ${shopThemeObj.mainColor};`,
      );
    }
  });

  const handleScroll = (scrollTop: number) => {
    console.log('scrollTop: ', scrollTop);
    setOffsetY(scrollTop);
  };
  const handleCustomClickBack = () => {
    if (handleClickBack) {
      return handleClickBack();
    }

    f7CustomBack();
  };

  // IPhone only top Padding for safe-area has been added. Even on the prior model of Iphone X, it still adds the safe area padding regardless
  return (
    <Page
      name={pageName}
      className={[getClassPage(), 'ptr-content'].toString()}
      style={{ ...pageStyle, ...inlinePageStyle, height: '100vh' }}
      ptr={isPtr}
      ptrDistance={44}
      onPtrRefresh={onRefresh}
    >
      <Navbar noHairline>
        {showNavBack && (
          <NavLeft>
            <Link
              onClick={handleCustomClickBack}
              animate={!handleClickBack}
              ignoreCache={!handleClickBack}
              className={`${style['no-ripple']} no-ripple`}
            >
              <div
                className={style['icon-wrapper']}
                style={{ backgroundColor: shopThemeObj.mainColor }}
              >
                <i className={`${style['nav-icon']} icon-left no-ripple`} />
              </div>
            </Link>
          </NavLeft>
        )}
        <NavTitle>{title}</NavTitle>
        <NavRight>
          <Link
            animate
            href="/help_top/"
            className={`${style['no-ripple']} no-ripple`}
          >
            <div
              style={{ backgroundColor: shopThemeObj.mainColor }}
              className={style['icon-wrapper']}
            >
              <i className={`${style['nav-icon']} icon-help-fill`} />
            </div>
          </Link>
        </NavRight>
      </Navbar>
      <div
        style={{
          ...containerBackground,
          height: 'auto',
          minHeight: '100%',
        }}
        className={style['container']}
      >
        <div className={style['content']} id="page-template-content">
          {children}
        </div>
      </div>
      <MenuFooter />
    </Page>
  );
};
