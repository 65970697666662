import { Router } from 'framework7/types';
import {
  EditPhonePage,
  RegisterPhonePage,
  UpdatePasswordPage,
  VersionPage,
} from '@/components/pages';
import { AccountPage } from '@/components/pages/Account/AccountPage';
import { AccountRegistPaymentPage } from '@/components/pages/AccountRegistPayment/AccountRegistPaymentPage';
import { CustomerPage } from '@/components/pages/Customer/CustomerPage';
import { EditAddressPage } from '@/components/pages/EditAddress/EditAddressPage';
import { EditCustomerPage } from '@/components/pages/EditCustomer/EditCustomerPage';
import { EditEmailPage } from '@/components/pages/EditEmail/EditEmailPage';
import { EditSignInPage } from '@/components/pages/EditSignIn/EditSignInPage';
import { SigninPage } from '@/components/pages/Signin/SigninPage';
import { paths } from '@/config/paths';

export const accountRoutes: Router.RouteParameters[] = [
  {
    path: paths.account,
    component: AccountPage,
    name: 'アカウント',
  },
  {
    path: paths.accountRegistPayment(),
    component: AccountRegistPaymentPage,
    name: '決済方法を登録',
  },
  {
    path: paths.accountRegistPayment(':status'),
    component: AccountRegistPaymentPage,
    name: '決済方法を登録',
  },
  {
    path: '/customer/',
    component: CustomerPage,
    name: 'お客様情報',
  },
  {
    path: '/edit_customer/',
    popup: {
      component: EditCustomerPage,
    },
    name: 'お客様基本情報の変更',
    options: {
      animate: true,
    },
  },
  {
    path: paths.editAddress,
    popup: {
      component: EditAddressPage,
    },
    name: '契約者様お住まいの変更',
    options: {
      animate: true,
    },
  },
  {
    path: paths.singIn,
    component: SigninPage,
    name: 'ログイン情報',
  },
  {
    path: paths.editSignIn,
    popup: {
      component: EditSignInPage,
    },
    name: 'ログイン手段の変更',
    options: {
      animate: true,
    },
  },
  {
    path: '/register_phone/',
    popup: {
      component: RegisterPhonePage,
    },
    name: '携帯電話番号の登録',
    options: {
      animate: true,
    },
  },
  {
    path: '/edit_phone/',
    popup: {
      component: EditPhonePage,
    },
    name: '携帯電話番号の変更',
    options: {
      animate: true,
    },
  },
  {
    path: '/edit_email/',
    popup: {
      component: EditEmailPage,
    },
    name: 'メールアドレスの変更',
    options: {
      animate: true,
    },
  },
  //   {
  //     path: '/edit_email_confirm/',
  //     popup: {
  //       component: EditEmailConfirmPage
  //     },
  //     name: 'メールアドレス登録認証待ち',
  //     options: {
  //       animate: true
  //     }
  //   },
  {
    path: '/update_password/',
    popup: {
      component: UpdatePasswordPage,
    },
    name: 'ログインパスワードの変更',
    options: {
      animate: true,
    },
  },

  {
    path: '/version/',
    component: VersionPage,
    name: 'このアプリについて',
    options: {
      animate: true,
    },
  },
];
