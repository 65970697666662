import { AxiosError } from 'axios';
import { useState } from 'react';
import { useWatchingServiceLogin } from './useWatchingServiceLogin';
import { emailFirstLoginDecisionApi } from '@/api/authApi';
import {
  EmailFirstLoginDecisionApiParams,
  LoginApiParams,
} from '@/types/api/authApi';

interface Args {
  params: EmailFirstLoginDecisionApiParams;
  loginParam: LoginApiParams;
}

export const useWatchingServiceEmailFirstLoginDecision = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { login } = useWatchingServiceLogin();
  const emailFirstLoginDecision = async (args: Args): Promise<boolean> => {
    let isAuth = false;
    try {
      setIsLoading(true);
      const { data } = await emailFirstLoginDecisionApi(args.params);
      if (data.success) isAuth = await login(args.loginParam);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      return isAuth;
    }
  };

  return {
    isLoading,
    error,
    emailFirstLoginDecision,
  };
};
