import { f7 } from 'framework7-react';
import React, { FC } from 'react';
import './Co2Report.scss';
import { paths } from '@/config/paths';

interface Co2Report {
  co2Kg: number | null | string | undefined;
}

export const Co2Report: FC<Co2Report> = ({ co2Kg }) => {
  function roundScore(score: number) {
    const roundedValue = Math.round(Number(score) * 10000) / 10000;
    return roundedValue.toFixed(1);
  }

  const clickInformation = () => {
    f7.views.main.router.navigate(paths.co2Information);
  };

  const isNonScore = () => {
    // null, undefined, の場合
    if (!co2Kg) {
      return true;
    }

    //全角数値
    const inputString = co2Kg.toString();
    if (/[０-９]/.test(inputString)) {
      return true;
    }

    return false;
  };

  const isShowScore = () => {
    if (isNonScore()) {
      return false;
    }

    // 文字列の場合はnumberに変換
    if (typeof co2Kg !== 'number') {
      co2Kg = Number(co2Kg);
    }

    return co2Kg > 0;
  };

  const isNegativeScore = () => {
    if (isNonScore()) {
      return false;
    }

    if (isShowScore()) {
      return false;
    }

    if (typeof co2Kg !== 'number') {
      co2Kg = Number(co2Kg);
    }

    return co2Kg <= 0;
  };

  return (
    <div className="co2-section">
      <div className="co2-title">
        今月の安全運転によるCO2排出削減量
        <i className="icon-help" onClick={clickInformation} />
      </div>
      {isShowScore() && (
        <div>
          <span className="co2-number">{roundScore(co2Kg as number)}</span>kg
        </div>
      )}
      {!isShowScore() && isNegativeScore() && (
        <div className="co2-content">
          <p>
            残念ながら、今月は安全運転によるCO2排出削減を達成できませんでした。
          </p>
          <p>
            月間運転レポートに表示の「今月のアドバイス」をよく読んでいただき、来月はCO2削減を達成できるよう頑張ってください！
          </p>
          <p>
            月間の走行距離が短い場合は、CO2排出削減が計算できない場合があります。
          </p>
        </div>
      )}
      {!isShowScore() && isNonScore() && !isNegativeScore() && (
        <div className="co2-content">
          <p>
            お客さまの保険契約のお申込み情報からは、今月の安全運転によるCO2排出削減量を計算できませんでした。表示できないケースは以下が考えられます。
          </p>
          <p>①お車がガソリン車・ハイブリッド車以外の場合</p>
          <p>②お車が新車の場合</p>
          <p>次回以降の月間レポートにてご確認ください。</p>
          <p>③お車が型式不明車等の場合</p>
          <p>
            上記①②どちらにも当てはまらない場合、型式不明車等で計算ができないケースがあります。
          </p>
        </div>
      )}
    </div>
  );
};

export default Co2Report;
