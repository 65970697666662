import style from './HasNoCarStyle.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

export const HasNoCar = () => {
  return (
    <RoundSheet>
      <div className={style.title}>
        <h3>現在契約中の車両はありません</h3>
      </div>
    </RoundSheet>
  );
};
