import { useState } from 'react';
import { useConnectorJob } from './api/linkDrive/useConnectorJob';
import { useGetJobStatus } from './api/linkDrive/useGetJobStatus';
import { useConnectorStatusRecursiveCall } from './useConnectorStatusRecursiveCall';
import { useToast } from './useToast';
import { store } from '@/config/store';
import { mode } from '@/consts/linkDrive';
import {
  ConnectorJobApiParams,
  ConnectorStatusApiParams,
  JobStatusParams,
} from '@/types/api/linkDriveApi';

const TIMEOUT = 300; // 300s

interface HandleClick {
  handleClickConfirm: () => void;
  handleClickStartedEngine: () => void;
}

export const useSelfScanSteps = () => {
  const { requestConnectorJob } = useConnectorJob();
  const { getJobStatus } = useGetJobStatus();
  const { connectorStatusRecursiveCall } = useConnectorStatusRecursiveCall();
  const [isDuringScanning, setIsDuringScanning] = useState(false);
  const [isSelfScanCompleted, setIsSelfScanCompleted] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [messageErrorScan, setMessageErrorScan] = useState('');
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [steps, setSteps] = useState({
    step1: 'todo',
    step2: 'todo',
    step3: 'todo',
  });
  const { openToast } = useToast();

  const ownerId = store.state.linkDriveOwnerInfo.owner_id;
  const serialNo = store.state.linkDriveOwnerInfo.serial_no;
  const requestConnectorJobParams: ConnectorJobApiParams = {
    owner_id: ownerId,
    job: '5',
    mode: '',
  };
  const fetchConnectorStatusParams: ConnectorStatusApiParams = {
    owner_id: Number(ownerId),
    serial_no: serialNo,
  };

  const handleClickConfirm = () => {
    setCurrentStep(2);
    setSteps((prev) => ({ ...prev, step1: 'completed' }));
  };

  const jobStatusRecursive = (params: JobStatusParams, startTime: number) => {
    return new Promise<void>(async (resolve, reject) => {
      const now = new Date().getTime();
      const diff = (now - startTime) / 1000;

      if (diff >= TIMEOUT) {
        setMessageErrorScan('エラー発生しています');
        resolve();
        return;
      }
      getJobStatus({ params })
        .then(async (data) => {
          const jobStatus = data?.data?.job_status;
          const currentProgress = data?.data?.progress || 0;
          setProgress(currentProgress);

          if (currentProgress >= 100) {
            setIsSelfScanCompleted(true);
            setIsDuringScanning(false);
            setIsUpdating(false);
            resolve();
            return;
          }

          if (jobStatus === 0) {
            setIsSelfScanCompleted(true);
            setIsDuringScanning(false);
            setIsUpdating(false);
            resolve();
            return;
          }

          if (jobStatus === 9) {
            setIsSelfScanCompleted(false);
            setIsDuringScanning(false);
            setIsUpdating(false);
            setMessageErrorScan(data?.data?.message || 'Error');
            resolve();
            return;
          }
          await new Promise((resolve) =>
            setTimeout(() => {
              resolve(true);
            }, 3000),
          );
          await jobStatusRecursive(params, startTime)
            .then(resolve)
            .catch(resolve);
        })
        .catch((err) => {
          setIsSelfScanCompleted(false);
          setIsDuringScanning(false);
          setIsUpdating(false);
          setMessageErrorScan(err?.message || 'Error');
          resolve();
        });
    });
  };

  // コネクタJOB依頼API
  const requestCloudJob = () => {
    requestConnectorJob({
      params: requestConnectorJobParams,
      resolve: (data) => {
        // セルフスキャン完了処理
        // setIsSelfScanCompleted(true);
        setIsUpdating(true);

        const paramsJobStatus = {
          owner_id: data.owner_id,
          job: data.job,
          job_id: data.id,
        };
        const startTime = new Date().getTime();

        jobStatusRecursive(paramsJobStatus, startTime);
      },
      onFailure: (err) => {
        console.log(err);
      },
    });
  };

  // コネクタ状態取得APIを3秒ごとに呼び出す
  // eventが3の場合コネクタJOB依頼APIを呼び出す（JOB: 5）
  // コネクタJOB依頼APIが成功の場合次の画面へ
  const handleClickStartedEngine = () => {
    setMessageErrorScan('');
    connectorStatusRecursiveCall(
      // コネクタ状態取得APIを80秒間コールさせるため27回に設定
      // 27回 × 3秒 = 81秒
      27,
      fetchConnectorStatusParams,
      (data) => {
        if (data?.data?.event === mode.sleep) {
          setMessageErrorScan('LinkDriveがスリープ状態です');
          setShowDialog(true);
          return;
        }
        setIsDuringScanning(true);
        setCurrentStep(3);
        setSteps((prev) => ({ ...prev, step2: 'completed' }));
        requestCloudJob();
      },
      (message) => {
        setMessageErrorScan(message || '');
      },
      0,
    );
  };

  return {
    showDialog,
    isDuringScanning,
    isSelfScanCompleted,
    messageErrorScan,
    isUpdating,
    currentStep,
    steps,
    progress,
    setShowDialog,
    handleClickConfirm,
    handleClickStartedEngine,
  };
};
