import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import style from './Customer.module.css';
import { CustomerList } from './CustomerList/CustomerList';
import { Button } from '@/components/uiParts/Button';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { ListRoundSheet } from '@/components/uiParts/Sheet/ListRoundSheet/ListRoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { Customer as CustomerType } from '@/types/api/customerApi';
import { generateFullName } from '@/utils/customer';
import { hyphenDate } from '@/utils/date';

type ContactInfo = {
  label?: string | undefined;
  value?: string | undefined;
  path?: string | undefined;
  iconNext?: string | undefined;
};

interface CustomerProps {
  f7router: Router.Router;
  customerInfo: CustomerType;
}

export const Customer: FC<CustomerProps> = ({ f7router, customerInfo }) => {
  const [customer, setCustomer] = useState<CustomerType>(customerInfo);
  const storeCustomerInfo = useStore(store, 'customerInfo') as CustomerType;
  const handleClickMenu = (path: string) => {
    if (!path) return;
    f7.view.main.router.navigate(path, {
      animate: true,
      props: {
        initialSetCustomer,
      },
    });
  };

  const getIsInfoCard = () => {
    return customer.phone_no1 || customer.phone_no2 ? false : true;
  };

  const isInfoCard = getIsInfoCard();

  // 携帯電話番号・ご自宅電話番号・メールアドレス出し分け
  const getContactAddressInfoLabelList = (): ContactInfo[] => {
    const contactAddressInfoLabelList: ContactInfo[] = [];

    // 電話番号1が登録されていて、携帯電話番号フラグが1
    if (customer.phone_no1 !== null && customer.mobile_flg1) {
      contactAddressInfoLabelList.push({
        label: '携帯電話番号',
        value: customer.phone_no1,
        path: paths.editPhone,
        iconNext: 'icon-next',
      });
      // 電話番号1が登録されていて、携帯電話番号フラグが1ではない
    } else if (customer.phone_no1 !== null && !customer.mobile_flg1) {
      contactAddressInfoLabelList.push({
        label: 'ご自宅電話番号',
        value: customer.phone_no1,
      });
    }

    // 電話番号2が登録されていて、携帯電話番号フラグが1
    if (customer.phone_no2 !== null && customer.mobile_flg2) {
      contactAddressInfoLabelList.push({
        label: '携帯電話番号',
        value: customer.phone_no2,
        path: paths.editPhone,
        iconNext: 'icon-next',
      });
      // 電話番号2が登録されていて、携帯電話番号フラグが1ではない
    } else if (customer.phone_no2 !== null && !customer.mobile_flg2) {
      contactAddressInfoLabelList.push({
        label: 'ご自宅電話番号',
        value: customer.phone_no2,
      });
    }

    // メールアドレスの確認
    if (customer.email != null || customer.email != '') {
      contactAddressInfoLabelList.push({
        label: 'メールアドレス',
        value: storeCustomerInfo.email,
        path: paths.editEmail,
        iconNext: 'icon-next',
      });
    }
    // 電話番号が登録されていない場合
    if (contactAddressInfoLabelList.length < 1) {
      contactAddressInfoLabelList.push({
        label: '携帯電話番号',
        value: '未登録',
        path: paths.registerPhone,
        iconNext: 'icon-next',
      });
    }

    return contactAddressInfoLabelList;
  };

  const defaultStr = '登録されていません';
  const customerBasicLabelList = [
    {
      label: '氏名',
      value: generateFullName(
        customer.first_name,
        customer.family_name,
        defaultStr,
      ),
    },
    {
      label: 'フリガナ',
      value: generateFullName(
        customer.first_name_kana,
        customer.family_name_kana,
        defaultStr,
      ),
    },
    {
      label: '法人名',
      value: customer.company_name ? customer.company_name : defaultStr,
    },
    {
      label: '生年月日',
      value: customer.birthday
        ? hyphenDate(customer.birthday.toString())
        : defaultStr,
    },
  ];

  const initialSetCustomer = (customer: Required<CustomerType>) => {
    setCustomer(customer);
  };

  return (
    <PageTemplate pageName="Customer" title="お客様情報" showNavBack>
      <div className={style['container']}>
        <ListRoundSheet
          title="お客様基本情報"
          list={customerBasicLabelList}
          isValueEnd={false}
          button={
            <Button
              className={`${style['button']} no-active-state`}
              handleClickAction={() => handleClickMenu(paths.editCustomer)}
            >
              変更する
            </Button>
          }
        />
        <div className={style.card}>
          <h3 className={style.heading}>ご連絡先情報</h3>
          {isInfoCard && (
            <IconCard
              heading={'電話番号が登録されていません'}
              iconType="bulb"
              iconColor="#1E55E6"
              iconFontSize="30px"
              color="gray"
              isTransition
              path={paths.registerPhone}
            >
              <p className={style['heading-sub-title']}>
                電話番号を登録すると、SMS認証で安全にログインできるようになります
              </p>
            </IconCard>
          )}
          <p className={style.description}>
            各々の項目をタップすると、変更・登録ができます
          </p>
          <ul className={style['link-wrap']}>
            {getContactAddressInfoLabelList().map((item, index) => (
              <CustomerList
                key={index}
                path={item.path}
                head={item.label}
                specificValue={item.value}
              />
            ))}
          </ul>
        </div>
        <ListRoundSheet
          title="お住まい情報"
          isValueEnd={false}
          button={
            <Button
              className={`${style['button']} no-active-state`}
              handleClickAction={() => handleClickMenu(paths.editAddress)}
            >
              変更する
            </Button>
          }
        >
          <div className={style['living-info-wrapper']}>
            <p>
              {customer.zip_code1}-{customer.zip_code2}
            </p>
            <p>
              {customer.address1}
              {customer.address2}
              {customer.address3}
            </p>
          </div>
        </ListRoundSheet>
      </div>
    </PageTemplate>
  );
};
