/* eslint-disable no-magic-numbers */
import { AccordionContent, List, ListItem } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import style from './AvailableBankListStyle.module.scss';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { useBankList } from '@/hooks/api/prepaid/useBankList';

type AvailableBankListProps = {
  f7router: Router.Router;
};

export const AvailableBankList: FC<AvailableBankListProps> = ({ f7router }) => {
  const japaneseSyllabary = [
    { head: 'あ', title: 'あ行' },
    { head: 'か', title: 'か行' },
    { head: 'さ', title: 'さ行' },
    { head: 'た', title: 'た行' },
    { head: 'な', title: 'な行' },
    { head: 'は', title: 'は行' },
    { head: 'ま', title: 'ま行' },
    { head: 'や', title: 'や行' },
    { head: 'ら', title: 'ら行' },
    { head: 'わ', title: 'わ行' },
  ];

  const [step, setStep] = useState(1);
  const [banklistSelected, setBanklistSelected] = useState(['']);
  const [inputKeyword, setInputKeyword] = useState('');

  const bankList = useBankList();

  const updateKeywordValue = (e: any) => {
    setInputKeyword(e.target.value);
    if (e.target.value == '') {
      setStep(1);
      return;
    }
    setBanklistSelected([]);
    if (inputKeyword != '') {
      setStep(2);
      const initialBank = [];
      for (const i in bankList.data?.banks) {
        const bankName = bankList.data?.banks[Number(i)].name;
        if (bankName?.indexOf(e.target.value) != -1) {
          initialBank.push(bankList.data?.banks[Number(i)].name ?? '');
        }
      }
      setBanklistSelected(initialBank);
    }
  };

  return (
    <PopupPageTemplate
      id="AvailableBankList"
      pageName="AvailableBankList"
      containerClass={style['popup-container']}
      title="金融機関の検索"
      isCloseIcon={true}
    >
      <div className={style['container']}>
        <div className={style['input-wrap']}>
          <i className={`${style.icon} icon-search`} />
          <input
            className={style.input}
            value={inputKeyword}
            onChange={(e) => updateKeywordValue(e)}
            type="text"
            placeholder="金融機関名で検索"
          />
        </div>
        {step === 1 && bankList && (
          <List className={style['list-wrap']} accordion-list>
            {japaneseSyllabary.map((row, index) => (
              <ListItem
                className={style.list}
                accordionItem
                key={index}
                title={row.title}
              >
                <AccordionContent className={style['accordion-content']}>
                  {bankList.data?.banks.map((bank, index) => (
                    <>
                      {bank.search_mark === row.head && (
                        <div key={index} className={style['accordion-block']}>
                          {bank.name}
                        </div>
                      )}
                    </>
                  ))}
                </AccordionContent>
              </ListItem>
            ))}
          </List>
        )}

        {step == 2 && (
          <div className={style['list-wrap']}>
            {banklistSelected.map((bankName, index) => (
              <CustomList
                isRightArrow={false}
                key={index}
                path={''}
                text={bankName}
                f7router={f7router}
              />
            ))}
          </div>
        )}
      </div>
    </PopupPageTemplate>
  );
};
