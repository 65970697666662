import { FC } from 'react';

export const BirthdayGift: FC<{ color?: string }> = ({ color = '#AA7F66' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5.46917C9 4.65777 9.65777 4 10.4692 4H11C12.6675 4 14.0966 5.02031 14.6972 6.47068C14.7927 6.44037 14.8945 6.42402 15 6.42402H17C17.1055 6.42402 17.2073 6.44037 17.3028 6.47068C17.9034 5.02031 19.3325 4 21 4H21.5308C22.3422 4 23 4.65777 23 5.46917C23 6.53684 22.427 7.48508 21.5554 8H24C25.1046 8 26 8.89543 26 10V24C26 25.1046 25.1046 26 24 26H8C6.89543 26 6 25.1046 6 24V10C6 8.89543 6.89543 8 8 8H10.4446C9.57304 7.48508 9 6.53684 9 5.46917ZM24 15V10H18V15H24ZM8 10H14V15H8V10ZM8 24V19H14V24H8ZM18 19V24H24V19H18Z"
        fill={color}
      />
    </svg>
  );
};
