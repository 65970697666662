import { Block, Col, Navbar, Page, Popup, Row } from 'framework7-react';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { CommonpageTitle } from '@/components/uiParts/CommonpageTitle/CommonpageTitle';
import './PreventTheftSettingsStyle.scss';

export const PreventTheftSettings: FC = () => {
  return (
    <Popup opened>
      <Page name="StrikeNoticeSettings">
        <Navbar>
          <CommonpageTitle
            handleHelpClick={() => {}}
            navTitle="盗難防止時の通知設定"
            leftIcon="close"
          />
        </Navbar>
        <Block>
          <h3 className="header-text">盗難防止時の設定</h3>
          <p>
            GPSの情報をもとに、車両の位置情報にいつもと異なるパターンを検知した場合に確認するサービスです。
          </p>
          <p>盗難などのトラブル検知に役立ちます。</p>
          <div className="checkbox-list">
            <div className="u-margin-bottom-sm list-item">
              <div className="list-item-checkbox">
                <input id="checkbox" type="checkbox" disabled />
                <label htmlFor="checkbox" aria-disabled />
              </div>
              <div className="list-item-content">
                <div className="u-font-size-lg">
                  車両位置情報の異常に通知する
                </div>
              </div>
            </div>
          </div>
          <Row style={{ marginTop: '26rem' }}>
            <Col width="25" />
            <Col width="50">
              <Button text="変更する" handleClickAction={() => {}} />
            </Col>
            <Col width="25" />
          </Row>
        </Block>
      </Page>
    </Popup>
  );
};
