import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { usePasswordReset } from './usePasswordReset';
import { emailFirstLoginDecisionApi } from '@/api/authApi';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { loginStatus } from '@/consts/login';
import { AffiliateId } from '@/consts/shop';
import {
  EmailFirstLoginDecisionApiParams,
  EmailFirstLoginDecisionApiResponse,
  RequestPasswordResetApiParams,
} from '@/types/api/authApi';
import { LoginStatus } from '@/types/login';
import { addAffiliateIdToRequestParams } from '@/utils/shop';

export const useEmailFirstLoginDecision = (affiliateId: AffiliateId) => {
  const [data, setData] =
    useState<EmailFirstLoginDecisionApiResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError | Error>();
  const { resetPassword } = usePasswordReset();
  const emailFirstLoginDecision = async (
    params: EmailFirstLoginDecisionApiParams,
  ) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const { data } = await emailFirstLoginDecisionApi(params);
      setData(data);
      if (data?.success && isInvalidEmail(data.m_customers.rs_flg)) {
        const message = switchMessage(data.m_customers.rs_flg);
        throw new Error(message);
      }
      store.dispatch('setLoginInfo', {
        loginInfo: { email: params.email, loginType: 'email' },
      });
      store.dispatch('setEmailDuplicated', {
        emailDuplicated: data.m_customers.rs_flg === loginStatus.DUPLICATION,
      });
      if (data.m_customers.rs_flg === loginStatus.FIRST) {
        const passwordParams: RequestPasswordResetApiParams = {
          mail_address: params.email,
          mail_kbn: 1,
        };
        addAffiliateIdToRequestParams(affiliateId, passwordParams, false);
        await resetPassword(passwordParams);
        f7.views.main.router.navigate(paths.emailComplete);
      } else {
        f7.views.main.router.navigate(paths.password);
      }
    } catch (err) {
      if (err instanceof Error) {
        f7.dialog.alert(err.message, 'エラー');
        setError(err);
        return;
      }
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    emailFirstLoginDecision,
    isLoading,
    error,
  };
};

const switchMessage = (status: LoginStatus) => {
  switch (status) {
    case loginStatus.DUPLICATION:
      return '複数件メールアドレスが登録されています';
    case loginStatus.UNREGISTERED:
      return '該当するメールアドレスがありません';
    default:
      return '';
  }
};

const isInvalidEmail = (status: LoginStatus) => {
  return status === loginStatus.UNREGISTERED;
};
