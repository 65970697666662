import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './UsedCoupons.module.css';
import { PrepaidTicketCoupon } from '@/components/uiParts/PrepaidTicketCoupon/PrepaidTicketCoupon';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';

type UsedCouponProps = {
  f7router: Router.Router;
};

export const UsedCoupon: FC<UsedCouponProps> = ({ f7router }) => {
  const couponList = store.state.campaignsInfo;
  return (
    <PageTemplate showNavBack pageName="UseCoupons" title="クーポン一覧">
      <div className={style.card}>
        <div className="heading-with-button u-font-size-lg u-font-bold u-margin-bottom-sm">
          ご利用済みクーポン
        </div>

        {couponList.length > 0 ? (
          couponList.map((coupon, index) => (
            <div key={index}>
              {!!coupon.status && (
                <PrepaidTicketCoupon couponInfo={coupon} f7router={f7router} />
              )}
            </div>
          ))
        ) : (
          <p className="u-margin-top-md u-text-center">
            ご利用済みのクーポンはありません
          </p>
        )}
      </div>
    </PageTemplate>
  );
};
