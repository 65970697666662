import { parse } from 'date-fns';
import { prepaidFlg } from '@/consts/payment';
import {
  BillInformation,
  BillInformationDetail,
  Car,
  PaymentMethod,
  Prepaid,
} from '@/types/api/customerApi';
import { findNextBillDetail } from '@/utils/bill';
import { isBankOrPayEasy } from '@/utils/payment';
import { getArrayLast } from '@/utils/utils';

export const getPaymentList = (
  paymentMethods: PaymentMethod[],
  bills: BillInformation[],
  car: Car,
  advancePayments: Prepaid[],
) => {
  const today = new Date();
  let currentBill: undefined | BillInformationDetail = undefined;
  let nextBill: undefined | BillInformationDetail = undefined;
  let closestMonthBill: undefined | BillInformationDetail = undefined;

  const paymentList = paymentMethods.map((method) => {
    const {
      m_payment_type_id: paymentTypeId,
      payment_type_name: paymentTypeName,
      m_payment_method_id: paymentMethodId,
      card_no: cardNo,
      bank_name: bankName,
      account_number: accountNumber,
    } = method;
    const bill = bills.find((bill) => {
      const billDetail = getArrayLast(bill.bill_infomation_details ?? []);
      return (
        billDetail?.m_payment_type_id === paymentMethodId &&
        billDetail?.payment_type_name === paymentTypeName
      );
    });

    let isSelected = true;
    let warrantyNo = '';
    let noPayFlg: undefined | number = undefined;
    let paySubject = '';
    if (bill) {
      const {
        currentBill: cb,
        nextBill: nb,
        closestMonthBill: cmb,
      } = findNextBillDetail(bill, prepaidFlg.HOUSE_PREPAID_EXISTS);
      currentBill = cb;
      nextBill = nb;
      closestMonthBill = cmb;
      warrantyNo = bill.warranty_no ?? '';

      const billDetail = getArrayLast(bill.bill_infomation_details ?? []);
      noPayFlg = billDetail?.no_pay_flg;
      paySubject = billDetail?.pay_subject ?? '';
      const billMonth = billDetail?.bill_month;
      if (billMonth) {
        const billMonthDate = parse(billMonth, 'yyyyMM', new Date());
        if (billMonthDate > today) {
          if (isBankOrPayEasy(billDetail.m_payment_type_id)) {
            isSelected = billDetail.account_number !== accountNumber;
          } else {
            isSelected = billDetail.card_no !== cardNo;
          }
        }
      }
    }

    const registryNo = car?.registry_no;
    const carNm = car?.car_nm;
    const prepaid = advancePayments.find(
      (payment) => payment.id === bill?.m_prepaid_id,
    );
    const prepaidId = prepaid?.id;
    const prepaidName = prepaid?.name;
    return {
      accountNumber,
      bankName,
      cardNo,
      carNm,
      isSelected,
      noPayFlg,
      paymentTypeId,
      paymentTypeName,
      paymentMethodId,
      paySubject,
      prepaidId,
      prepaidName,
      registryNo,
      warrantyNo,
    };
  });
  return { paymentList, currentBill, nextBill, closestMonthBill };
};
