import { Link } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './ NewsWarrantyExpriedStyle.module.scss';
import { ContactSupport } from './ContactSupport';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import { historyType } from '@/consts/payment';

type NewsWarrantyExpriedProps = {
  f7router: Router.Router;
};

export const NewsWarrantyExpried: FC<NewsWarrantyExpriedProps> = ({
  f7router,
}) => {
  const { currentWarranty } = store.state;

  return (
    <PageTemplate pageName="NewsWarrantyExpried" showNavBack title="お知らせ">
      <RoundSheet>
        <div className={style['contact-wrapper']}>
          <div>
            <h2 className={style['notice-title']}>保証が失効しております</h2>
            <p className="u-margin-top-sm">
              保証料のご入金を確認できない月が連続いたしましたため、保証契約が失効し、保証サービスの役務提供を停止させていただいております。
            </p>
            <br />
            <p>
              保証契約の有効化をご希望の場合は、お手数ではございますが、各店舗へご相談ください。
            </p>
          </div>
          <ContactSupport />
          <div>
            <h4>保証が失効する条件</h4>
            <ul>
              <li>
                「残高不足」のため保証料の引落ができなかった月が2ヶ月連続した場合
              </li>
              <li>
                「残高不足以外の理由」で保証料の引落ができなかった月が3ヵ月連続した場合
              </li>
            </ul>
            <p className={style['notice-note']}>
              ※
              手数料の引落ができなくても、現在加入中の保証が失効することはありません。
            </p>
          </div>
          <Link
            noLinkClass
            text="支払い履歴を確認する"
            onClick={() => {
              f7router.navigate({
                name: '支払履歴',
                params: {
                  warranty_no: currentWarranty?.warranty_no,
                },
                query: {
                  historyType: historyType.WARRANTY,
                },
              });
            }}
          />
        </div>
      </RoundSheet>
    </PageTemplate>
  );
};
