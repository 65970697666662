import { AxiosError } from 'axios';
import { f7, useStore } from 'framework7-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useToast } from './useToast';
import { fetchMAffiliateSettingsApi } from '@/api/infoApi';
import { getGmoCardParameterApi, getPaymentCard } from '@/api/payment';
import { AuthInfoState } from '@/config/initialState';
import { store } from '@/config/store';
import { endpoint } from '@/consts/api';
import { url } from '@/consts/url';
import { Customer } from '@/types/api/customerApi';

export const useGmo = (redirectPage: string) => {
  const { openToast } = useToast();
  const { m_affiliate_id } = useStore(store, 'customerInfo') as Customer; // store.state.customerInfo.m_affiliate_id;
  const { m_customer_id } = useStore(store, 'authInfo') as AuthInfoState; // store.state.authInfo.m_customer_id;
  const m_car_id = useStore(store, 'carItemInfo')?.m_car_id;

  const [GMOParameters, setGMOParameters] = useState({
    RetURL: `${endpoint.MAIKURU_API}/v1/gmo-payment/cardresult/${redirectPage}`,
    CancelURL: `${url.MAIKURU_BASE_URL}/${redirectPage}/gmo_cancel`,
    CreateMember: '1',
    ConsumerDevice: 'pc',
    SiteID: '',
    SitePass: '',
    ShopID: '',
    dateTime: '',
    memberPassString: '',
    memberID: '',
    Enc: 'utf-8',
  });

  const addCreditCardPaymentMethod = async (
    setActionUrl?: Dispatch<SetStateAction<string>>,
  ) => {
    if (!m_affiliate_id || !m_customer_id) return;

    try {
      f7.preloader.show();
      const { data: mAffiliateSettings } = await fetchMAffiliateSettingsApi({
        m_affiliate_id,
      });
      if (!mAffiliateSettings.success)
        throw new Error('エラーが発生しました', {
          cause: 'fetchMAffiliateSettingsApi failed',
        });
      const { gmopg_site_id, gmopg_site_pass, gmopg_shop_id } =
        mAffiliateSettings.data;

      const { data: creditCardPaymentInfo } = await getPaymentCard({
        site_id: gmopg_site_id,
        shop_id: gmopg_shop_id,
        m_customer_id,
        with_trashed: true,
      });
      if (!creditCardPaymentInfo.success)
        throw new Error('エラーが発生しました', {
          cause: 'getPaymentCard Failed',
        });
      const gmoMemberId = `C${m_customer_id}_${
        creditCardPaymentInfo.data.length + 1
      }`;

      const { data: gmoPaymentParams } = await getGmoCardParameterApi({
        memberID: gmoMemberId,
        redirectPage: redirectPage,
        siteID: gmopg_site_id,
        sitePass: gmopg_site_pass,
        shopID: gmopg_shop_id,
      });
      if (!gmoPaymentParams.success)
        throw new Error('エラーが発生しました', {
          cause: 'getGmoCardParameterApi Failed',
        });
      const gmoMemberPassString = gmoPaymentParams.data.MemberPassString;
      const gmoDateTime = gmoPaymentParams.data.DateTime;

      setActionUrl?.(`/gmo_link/link/${gmopg_shop_id}/Member/Edit`);
      setGMOParameters({
        ...GMOParameters,
        CancelURL:
          GMOParameters.CancelURL + (m_car_id ? `?currentCar=${m_car_id}` : ''),
        SiteID: gmopg_site_id,
        SitePass: gmopg_site_pass,
        ShopID: gmopg_shop_id,
        memberID: gmoMemberId,
        memberPassString: gmoMemberPassString,
        dateTime: gmoDateTime,
      });
    } catch (error: any) {
      console.error(`${error.cause}: ${error.message}`);
      openToast((error as AxiosError).message, 'toast-failed');
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    addCreditCardPaymentMethod,
    GMOParameters,
  };
};
