import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from 'framework7-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { store } from '@/config/store';
import { Prepaid } from '@/types/api/customerApi';
import { CourseItem, OptionItem } from '@/types/api/reservationApi';

export const CLASS_NO_MAX_LENGTH = 3;
export const REGISTRY_NO_MAX_LENGTH = 4;
export const HIRAGANA_MAX_LENGTH = 1;

const validationSchema = yup.object().shape({
  car: yup.object().shape({
    tStockCarId: yup.number().when('unregisteredCar.useUserCar', {
      is: true,
      then: yup.number().required(),
    }),
    carwashItemCode: yup.number().required(),
    unregisteredCar: yup.object().shape({
      useUserCar: yup.boolean(),
      carVin: yup.string().when('useUserCar', {
        is: false,
        then: yup.string().required('車台番号は必須です'),
      }),
      modelCode: yup.string().when('useUserCar', {
        is: false,
        then: yup.string().required('型式は必須です'),
      }),
      transportName: yup.string().when('useUserCar', {
        is: false,
        then: yup.string().required('地域名は必須です'),
      }),
      classNo: yup.string().when('useUserCar', {
        is: false,
        then: yup
          .string()
          .required('分類番号は必須です')
          .max(
            CLASS_NO_MAX_LENGTH,
            `は${CLASS_NO_MAX_LENGTH}桁まで入力してください`,
          ),
      }),
      hiragana: yup.string().when('useUserCar', {
        is: false,
        then: yup
          .string()
          .required('平仮名等は必須です')
          .matches(/^([あ-ん])+$/, '全角ひらがなで入力してください')
          .max(
            HIRAGANA_MAX_LENGTH,
            `は${HIRAGANA_MAX_LENGTH}桁で入力してください`,
          ),
      }),
      registryNo: yup.string().when('useUserCar', {
        is: false,
        then: yup
          .string()
          .required('車両番号は必須です')
          .max(
            REGISTRY_NO_MAX_LENGTH,
            `は${REGISTRY_NO_MAX_LENGTH}桁まで入力してください`,
          ),
      }),
      makerName: yup.string().when('useUserCar', {
        is: false,
        then: yup.string().required('メーカーは必須です'),
      }),
      carName: yup.string().when('useUserCar', {
        is: false,
        then: yup.string().required('車種は必須です'),
      }),
      carColor: yup.string().when('useUserCar', {
        is: false,
        then: yup.string().required('車の色は必須です'),
      }),
    }),
  }),
});

export interface Step1FormData {
  car: {
    tStockCarId?: number;
    carwashItemCode?: number;
    optionItemCodes: number[];
    unregisteredCar: {
      useUserCar: boolean;
      carVin?: string;
      modelCode?: string;
      transportName?: string;
      classNo?: string;
      hiragana?: string;
      registryNo?: string;
      makerName?: string;
      carName?: string;
      carColor?: string;
    };
  };
}

export const useCarWashConditionerStep1 = (
  initialState: Step1FormData,
  courseItems: CourseItem[],
  optionItems: OptionItem[],
) => {
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Required<Prepaid>
    | undefined;
  const [paymentShort, setPaymentShort] = useState(0);
  const [fee, setFee] = useState(0);
  const formMethods = useForm<Step1FormData>({
    defaultValues: initialState,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { watch, reset } = formMethods;
  const carwashItemCode = watch('car.carwashItemCode');
  const optionItemCodes = watch('car.optionItemCodes');

  useEffect(() => {
    if (initialState.car.carwashItemCode) {
      reset(initialState);
    }
  }, [initialState, reset]);

  useEffect(() => {
    const coursePrice =
      courseItems.find((c) => c.code === carwashItemCode)?.price || 0;
    const optionPrices = optionItems
      .filter((c) => optionItemCodes.includes(c.code))
      .reduce((current, { price }) => current + price, 0);
    setFee(coursePrice + optionPrices);
    if (prepaid) {
      const shortage = Math.max(
        0,
        coursePrice + optionPrices - prepaid.balance_total,
      );
      setPaymentShort(shortage);
    }
  }, [carwashItemCode, optionItemCodes, courseItems, optionItems, prepaid]);

  return {
    fee,
    prepaid,
    paymentShort,
    formMethods,
  };
};
