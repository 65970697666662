import { f7 } from 'framework7-react';
import { useConnectorJob } from './api/linkDrive/useConnectorJob';
import { steps } from './useLinkDriveSetup';
import {
  connectorStatus,
  jobExecStatus,
  jobStatusApi,
} from '@/api/linkDriveApi';
import { isApp } from '@/config/device';
import { store } from '@/config/store';
import {
  connectorJob,
  eventStatusLaunch,
  jobTerminateStatus,
  jobUpdatingLabel,
  linkDriveJobExecStatus,
  mode,
  setupProcessingStatus,
  timeOutJob,
  updatePromiseResolveFlg,
} from '@/consts/linkDrive';
import { url } from '@/consts/url';
import {
  ConnectorJobApiParams,
  ConnectorStatusApiParams,
} from '@/types/api/linkDriveApi';
import { storeDispatch } from '@/utils/store';

export type StatusCheckResult = {
  success: boolean;
  error?: string;
  job?: number;
  job_id?: string;
};

const timeoutBlock = (timout?: number) =>
  new Promise<boolean>((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, timout || 3000);
  });

const jobExecStatusErrorMessage =
  'エラーコード：LD0011<br><br>デバイスのセットアップが途中で中断しました。デバイスの起動状態をご確認の上、再度セットアップを実施してください。解決できない場合は、お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。';
const jobStatusErrorMessage =
  'エラーコード：LD0012<br><br>デバイスのセットアップが途中で中断しました。デバイスの起動状態をご確認の上、再度セットアップを実施してください。解決できない場合は、お手数ですがお問い合わせフォームからサポートセンターへお問い合わせください。';

export const useLinkDriveSetupProgress = (
  jobStatusSignal: AbortSignal,
  connectorStatusSignal?: AbortSignal,
  setStep?: (step: number) => void,
  setErrorMessage?: (message: string) => void,
) => {
  const ownerId = store.state.ownerId;
  const serial_no = store.state.serialNumberDb || store.state.serialNumberInput;
  const { requestConnectorJob } = useConnectorJob();

  const recursiveJobStatus = (
    job: string,
    job_id: string,
    startTime: number,
  ) => {
    return new Promise<number>((resolve, reject) => {
      storeDispatch(
        'setLinkDriveSetupCurrent',
        'linkDriveSetupCurrent',
        jobUpdatingLabel[job],
      );
      const maxExecuteTime = timeOutJob[job] || 270;
      const now = new Date().getTime();
      const diff = (now - startTime) / 1000;

      if (diff >= maxExecuteTime) {
        return resolve(updatePromiseResolveFlg.ENDED);
      }
      setTimeout(() => {
        jobStatusApi(
          {
            owner_id: ownerId,
            job,
            job_id,
          },
          jobStatusSignal,
        )
          .then(async ({ data }) => {
            if (!data || !data.success) {
              throw new Error(jobStatusErrorMessage);
            }
            const currentProgress = Math.floor(data.data.progress || 10);
            if (
              data.data.job_status ===
              linkDriveJobExecStatus.ABNORMAL_TERMINATION
            ) {
              // セットアップ処理でECUリスト更新（job: 3）の実施で、job実行状況取得から以下が返却される場合、エラーと見做さず、正常終了を判定するように修正。
              // job_status: 9
              // status: 02002
              if (
                job === connectorJob.ECU_LIST_UPDATE &&
                data.data.status === jobTerminateStatus.UNABLE_DETECT_ECU
              ) {
                storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 100);
                await timeoutBlock(3000);
                return resolve(updatePromiseResolveFlg.SUCCESS);
              }

              if (
                data.data.status === jobTerminateStatus.CONNECTOR_DISCONNECT
              ) {
                setStep?.(steps.INTERUPTED);
                return resolve(updatePromiseResolveFlg.FAILLED);
              } else {
                f7.dialog.alert(
                  '',
                  data?.data?.message || 'jobの実施状況を確認できませんでした',
                );
                throw new Error(
                  data?.data?.message || 'jobの実施状況を確認できませんでした',
                );
              }
            }
            if (
              currentProgress >= 100 ||
              data.data.job_status === linkDriveJobExecStatus.SUCCESSFUL_END
            ) {
              storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 100);
              await timeoutBlock(3000);
              return resolve(updatePromiseResolveFlg.SUCCESS);
            }
            storeDispatch(
              'setLinkDriveProgress',
              'linkDriveProgress',
              currentProgress,
            );
            const rs = await recursiveJobStatus(job, job_id, startTime);
            return resolve(rs);
          })
          .catch((e) => {
            setStep?.(steps.STATUS_CHECK);
            setErrorMessage?.(
              e.message || 'jobの実施状況を確認できませんでした',
            );
            storeDispatch(
              'setLinkDriveUpdating',
              'linkDriveUpdating',
              setupProcessingStatus.INACTIVE,
            );
            storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0);
            return resolve(updatePromiseResolveFlg.FAILLED);
          });
      }, 3000);
    });
  };

  const recursiveStatusCheck = async (
    remainingCallCount: number,
    params: ConnectorStatusApiParams,
    cb?: () => void,
  ): Promise<StatusCheckResult> => {
    try {
      if (remainingCallCount === 0) {
        return {
          success: true,
        };
      }
      setErrorMessage?.('');
      const response = await connectorStatus(
        params,
        connectorStatusSignal || jobStatusSignal,
      );
      if (!response.data) {
        return {
          success: false,
        };
      }
      const { data } = response;
      if (data && data.success) {
        const {
          data: { event, event_status },
        } = data;
        if (event === mode.sleep && remainingCallCount === 1) {
          cb?.();
          throw new Error('LinkDriveがスリープ状態です');
        }
        if (
          event === mode.launch &&
          event_status === eventStatusLaunch.REFRESH_REBOOT
        ) {
          const { data: jobStatusData } = await jobExecStatus({
            owner_id: params.owner_id,
          });
          if (!jobStatusData || !jobStatusData.success) {
            throw new Error(jobExecStatusErrorMessage);
          }
          if (
            [
              linkDriveJobExecStatus.SUCCESSFUL_END,
              linkDriveJobExecStatus.INTERUPTED,
              linkDriveJobExecStatus.ABNORMAL_TERMINATION,
            ].includes(jobStatusData.data.job_status)
          ) {
            return {
              success: true,
              job: jobStatusData.data.job,
              job_id: jobStatusData.data.job_id,
            };
          }
          return {
            success: true,
          };
        }
        if (
          event === mode.launch &&
          event_status === eventStatusLaunch.COMPLETE_STANDBY
        ) {
          return {
            success: true,
          };
        }
      }
      await timeoutBlock();
      const rs = await recursiveStatusCheck(remainingCallCount - 1, params, cb);
      if (!rs.success) {
        return {
          success: false,
          error: rs.error,
        };
      }
      return rs;
    } catch (e: any) {
      setErrorMessage?.(e.message);
      return {
        success: false,
        error: e.message,
      };
    }
    return {
      success: true,
    };
  };

  const updateFW = () => {
    const job = connectorJob.FIRMWARE_UPDATE;
    const params: ConnectorJobApiParams = {
      owner_id: Number(ownerId),
      job,
      mode: '',
      prockbn: 1,
    };
    return new Promise<number>((resolve, reject) => {
      requestConnectorJob({
        params,
        resolve: async (data) => {
          const startTime = new Date().getTime();
          if (isApp) {
            window.location.href =
              url.NATIVE_BASE_URL +
              `/linkdrive/setup_interrupted?job=${job}&task_id=${data.id}`;
          }
          const rs = await recursiveJobStatus(job, data.id, startTime);
          await timeoutBlock(2000);
          if (isApp) {
            window.location.href =
              url.NATIVE_BASE_URL + `/linkdrive/setup_complete/`;
          }
          resolve(rs);
        },
        onFailure: () => {
          setStep?.(steps.INTRO);
          resolve(updatePromiseResolveFlg.FAILLED);
        },
      });
    });
  };

  const updateModeReservation = async () => {
    try {
      const { data } = await connectorStatus({
        owner_id: ownerId,
        serial_no,
      });
      if (!data || !data.success) {
        return updatePromiseResolveFlg.SUCCESS;
      }
      const {
        data: { event, event_status },
      } = data;
      return new Promise<number>((resolve, reject) => {
        if (
          event === mode.launch &&
          (event_status === eventStatusLaunch.CONNECTOR_STANDBY ||
            event_status === eventStatusLaunch.COMPLETE_STANDBY)
        ) {
          const job = connectorJob.MODE_RESERVATION;
          requestConnectorJob({
            params: {
              owner_id: ownerId,
              job,
            },
            resolve: async (data) => {
              const startTime = new Date().getTime();
              storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0);
              if (isApp) {
                window.location.href =
                  url.NATIVE_BASE_URL +
                  `/linkdrive/setup_interrupted?job=${job}&task_id=${data.id}`;
              }
              const rs = await recursiveJobStatus(job, data.id, startTime);
              setTimeout(() => {
                resolve(rs);
              }, 2000);
            },
            onFailure: () => {
              resolve(updatePromiseResolveFlg.FAILLED);
              setStep?.(steps.INTRO);
            },
          });
        } else {
          return resolve(updatePromiseResolveFlg.SUCCESS);
        }
      });
    } catch {
      return updatePromiseResolveFlg.FAILLED;
    }
  };

  const updateECU = async (startTime: number): Promise<number> => {
    const now = new Date().getTime();
    const diff = (now - startTime) / 1000;

    if (diff >= 80) {
      return updatePromiseResolveFlg.SUCCESS;
    }

    const { data } = await jobExecStatus({
      owner_id: ownerId,
    });
    if (
      data.success &&
      data.data.job_id &&
      String(data.data.job) === String(connectorJob.ECU_LIST_UPDATE) &&
      [
        linkDriveJobExecStatus.PROCESSING_RECEPTION,
        linkDriveJobExecStatus.PROCESSING_START,
        linkDriveJobExecStatus.PROCESSING,
      ].includes(data.data.job_status)
    ) {
      const { job, job_id } = data.data;
      return new Promise<number>(async (resolve, reject) => {
        const startTime = new Date().getTime();
        if (isApp) {
          window.location.href =
            url.NATIVE_BASE_URL +
            `/linkdrive/setup_interrupted?job=${job}&task_id=${job_id}`;
        }
        const rs = await recursiveJobStatus(
          connectorJob.ECU_LIST_UPDATE,
          job_id,
          startTime,
        );
        setTimeout(() => {
          resolve(rs);
        }, 8000);
      });
    } else {
      await timeoutBlock(10000);
      return updateECU(startTime);
    }
    return updatePromiseResolveFlg.SUCCESS;
  };

  return {
    recursiveJobStatus,
    recursiveStatusCheck,
    updateFW,
    updateModeReservation,
    updateECU,
  };
};
