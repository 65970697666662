import { f7 } from 'framework7-react';
import { FC, useEffect } from 'react';
import { CarOrder } from './CarOrder/CarOrder';
import { useFetchCarOrder } from '@/hooks/api/car/useFetchCarOrder';
import { f7CustomBack } from '@/utils/utils';
interface CarOrderPageProps {
  id: string;
}

export const CarOrderPage: FC<CarOrderPageProps> = ({ id }) => {
  const { data, error } = useFetchCarOrder(+id);

  const { purchaseOrder, optionItemList, remarks } = data || {};

  useEffect(() => {
    if (error) {
      f7.dialog.alert('', 'データの取得に失敗しました。', () => {
        f7CustomBack();
      });
    }
  }, [error]);

  return (
    <CarOrder
      purchaseOrder={purchaseOrder}
      optionItemList={optionItemList}
      remarks={remarks}
    />
  );
};
