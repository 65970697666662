import { Progressbar, f7, useStore } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import {
  getContractInfo,
  getHighlightInsuranceCardData,
  getHighlightMapData,
  judgeShowBtns,
} from './DriveRecordHighLightHelper';
import { MapCard } from './MapCard/MapCard';
import { DriveRecordForLinkDriverOwner } from '@/components/pages/DriveRecord/DriverRecordForLinkDriverOwner/DriveRecordForLinkDriverOwner';
import { DrivingScoreCheck } from '@/components/projects/DrivingScoreCheck/DrivingScoreCheck';
import { InsuranceCard } from '@/components/projects/InsuranceCard/InsuranceCard';
import { TransitionChart } from '@/components/projects/TransitionChart/TransitionChart';
import { AlertIcon } from '@/components/uiParts/Icon/AlertIcon';
import { AuthInfoState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useHighlightData } from '@/hooks/api/driveRecord/useHighlightData';
import { Car } from '@/types/api/customerApi';
import { HighlightParams, HighlightResponse } from '@/types/api/driveRecordApi';
import { LinkDriveOwner } from '@/types/api/infoApi';
import {
  ContractInformationParams,
  ContractInformationResponse,
  VehicleTypeParams,
  VehicleTypeResponse,
} from '@/types/api/linkDriveApi';
import { hyphenToYear, slashToYear } from '@/utils/date';
import { isAdContractorData } from '@/utils/home';
import { meterTokm } from '@/utils/utils';
import './DriveRecordHighLightStyle.scss';

export type HighlightFetchData = {
  highlight: HighlightResponse['data'] | undefined;
  contractInformation: ContractInformationResponse['data'] | undefined;
  vehicleType: VehicleTypeResponse['data'] | undefined;
};

export const DriveRecordHighLight: FC = () => {
  const linkDriveOwnerInfo = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const carItemInfo = useStore(store, 'carItemInfo') as Required<Car>;
  const ownerId = linkDriveOwnerInfo.owner_id;
  const vehicleId = linkDriveOwnerInfo.vehicle_id;
  const deviceKey = linkDriveOwnerInfo.device_key;
  const tStockCarId = carItemInfo.t_stock_car_id;
  const mCustomerId = authInfo.m_customer_id;
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;

  const isAdContractor = isAdContractorData({
    linkDriveStatus: selectedCar?.linkdrive_status,
  });
  const highlightParams: HighlightParams = {
    owner_id: ownerId,
    vehicle_id: vehicleId,
    m_customer_id: mCustomerId,
  };
  const contractionInformationParams: ContractInformationParams = {
    owner_id: ownerId,
    device_key: deviceKey,
    period_of_insurance: '',
  };
  const vehicleTypeParams: VehicleTypeParams = {
    t_stock_car_id: tStockCarId,
  };
  const { data, isLoading } = useHighlightData(
    highlightParams,
    contractionInformationParams,
    vehicleTypeParams,
  );
  const { highlight, contractInformation, vehicleType } = data;
  const mapData = getHighlightMapData(data?.highlight);
  const isLocationAvailable = !!mapData?.latitude || !!mapData?.longitude;
  const insuranceCard = getHighlightInsuranceCardData(data?.highlight);
  const contractInfo = getContractInfo(data?.contractInformation?.data);
  const handleClickDriveInsurance = () => {
    f7.view.main.router.navigate(paths.driveInsurance);
  };

  const handleClickMonthlyDriveReport = () => {
    f7.view.main.router.navigate(paths.monthlyDriveReport);
  };

  const handleClickCheckDrivingScore = () => {
    f7.view.main.router.navigate(paths.drivingScoreList);
  };

  const handleClickMap = () => {
    if (!isLocationAvailable) return;
    f7.view.main.router.navigate('/shopmap/', {
      props: {
        pageTitle: '最後の駐車記録',
        lat: data?.highlight?.data.timeline_log.lat,
        lon: data?.highlight?.data.timeline_log.lon,
        isIcon: true,
      },
    });
  };

  const [isShowScoreBtn, setIsShowScoreBtn] = useState(true);
  const [isShowBeforeJoinedInsuranceBtn, setIsShowBeforeJoinedInsuranceBtn] =
    useState(false);

  useEffect(() => {
    const [isShowScoreBtnState, isShowBeforeJoinedInsuranceBtnState] =
      judgeShowBtns(linkDriveOwnerInfo, contractInfo);
    setIsShowScoreBtn(isShowScoreBtnState);
    setIsShowBeforeJoinedInsuranceBtn(isShowBeforeJoinedInsuranceBtnState);
  }, [contractInfo, linkDriveOwnerInfo]);
  if (isLoading && !highlight) {
    return null;
  }
  return (
    <div data-name="HightLight">
      {isAdContractor && (
        <div>
          <h3 className="u-margin-top-md u-margin-bottom-sm">ハイライト</h3>
          {/* 今までの走行距離 */}
          <div className="info-card info-card-elevated u-margin-bottom-sm">
            <h3 className="distance-card_title u-padding-top-xs u-padding-bottom-xs">
              今日までの走行距離
            </h3>
            <div className="u-font-size-xxl u-font-bold">
              {highlight?.data?.mileage_of_date?.driving_distance_sum
                ? meterTokm(
                    highlight?.data?.mileage_of_date?.driving_distance_sum,
                  )
                : 0}{' '}
              km
              <Progressbar
                progress={
                  highlight?.data?.mileage_of_date?.driving_distance_sum
                    ? 30
                    : 0
                }
                className="progressbar"
              />
            </div>
          </div>
          {/*  最後の駐車記録 */}
          {mapData && (
            <div className="latest-parking-position" onClick={handleClickMap}>
              {isLocationAvailable ? (
                <MapCard mapData={mapData} />
              ) : (
                <div className="info-card">
                  <h3 className="u-margin-top-0 u-margin-bottom-xs">
                    最後の駐車記録
                  </h3>
                  <div className="no-location-error-container">
                    <AlertIcon />
                    <p className="u-color-system-error u-padding-top-lg u-padding-bottom-lg alert-padding-left">
                      位置情報を正常に取得できませんでした
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* 先月の安全運転レポート */}
          <div
            className="last-month-report-card info-card info-card-elevated u-margin-top-sm"
            onClick={handleClickMonthlyDriveReport}
          >
            <div className="display-flex-sb">
              <h3 className="last-month-report-card_title u-padding-top-xs u-padding-bottom-xs">
                先月の安全運転レポート
              </h3>
              <div>
                <i className="icon-right" style={{ fontSize: '2.4rem' }} />
              </div>
            </div>
            <div className="last-month-report-card_data display-flex-sb">
              <div className="last-month-report-card_data_score">
                <div className="last-month-report-card_data_score_number u-padding-bottom-xs">
                  <span className="u-font-bold" style={{ fontSize: '4rem' }}>
                    {highlight?.data?.monthly_report.average_score ?? '-'}
                  </span>{' '}
                  点
                </div>
                <div>
                  {highlight?.data?.monthly_report.analysis_from_date != null
                    ? slashToYear(
                        highlight?.data?.monthly_report.analysis_from_date,
                      ).substring(0, 7)
                    : 'ー'}
                  分
                </div>
              </div>
              <div
                className="u-padding-left-xs"
                style={{ width: '70%', height: '11rem' }}
              >
                <TransitionChart
                  id="driveHistoryMonthlyReport"
                  yearScoreList={highlight?.data?.monthly_report.year_score
                    ?.split(',')
                    .map(Number)}
                  createMonthly={
                    highlight?.data?.monthly_report.create_monthly?.split(
                      ',',
                    ) ?? []
                  }
                  showLabel={false}
                  showSplitLineX={false}
                  gridLeft="20%"
                  seriesType="bar"
                  minNumber={0}
                  // maxNumber={100}
                  axisLabelIntervalX={1}
                  axisLabelInterval={1}
                />
              </div>
            </div>
          </div>
          {vehicleType?.vehicle_type != 99 && linkDriveOwnerInfo.mode != 3 && (
            <div>
              {/*  累計安全運転スコアー保険加入後  */}
              {isShowBeforeJoinedInsuranceBtn && (
                <div
                  className="u-margin-top-sm"
                  onClick={handleClickDriveInsurance}
                >
                  <InsuranceCard info={insuranceCard} />
                </div>
              )}
              {/* 累計スコア確認ボタン */}
              {isShowScoreBtn && (
                <div
                  className="u-margin-top-sm"
                  onClick={handleClickCheckDrivingScore}
                >
                  <DrivingScoreCheck info={insuranceCard} />
                </div>
              )}
            </div>
          )}
          {/*  最新の運転スコア　平均燃費  */}
          <div
            className="display-flex-sb u-margin-bottom-lg"
            style={{ marginTop: '6px' }}
          >
            <div
              style={{ width: '47%' }}
              className="latest-drive-score-card info-card info-card-elevated"
            >
              <h3 className="latest-drive-score-card_title u-padding-top-xs u-padding-bottom-xs u-font-bold">
                最新の運転スコア
              </h3>
              <div className="u-padding-bottom-xs">
                <span className="u-font-bold" style={{ fontSize: '4rem' }}>
                  {highlight?.data?.latest_diagnosis_result.phyd_score ?? '-'}
                </span>{' '}
                点
                {/* 前回のスコアより上がった/下がったを表現するためのアイコンですが、そもそも判定するための情報がないので */}
                {/* <i
                  className="icon-arrow-up"
                  style={{ transform: 'rotate(-90deg)' }}
                /> */}
              </div>
              <div style={{ color: 'd5d8da' }}>
                {highlight?.data?.latest_diagnosis_result.diagnosis_date
                  ? hyphenToYear(
                      highlight?.data?.latest_diagnosis_result.diagnosis_date,
                    )
                  : 'ー'}
              </div>
            </div>
            <div
              style={{ width: '47%' }}
              className="gas-fees-card info-card info-card-elevated"
            >
              <h3 className="gas-fees-card_title u-padding-top-xs u-padding-bottom-xs u-font-bold">
                平均燃費
              </h3>
              <div className="u-padding-bottom-xs">
                <span className="u-font-bold" style={{ fontSize: '4rem' }}>
                  {highlight?.data?.trip_log.fuel_efficiency ?? '-'}
                </span>{' '}
                km/L
              </div>
              <div style={{ color: 'd5d8da' }}>
                {highlight?.data?.monthly_report.analysis_from_date
                  ? slashToYear(
                      highlight?.data?.monthly_report.analysis_from_date,
                    ).substring(0, 7)
                  : 'ー'}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {!isLoading && !data.highlight && isAdContractor && (
        <div>
          <div className="no-data u-text-center u-font-size-xxl">No Data</div>
        </div>
      )} */}
      {!isAdContractor && (
        <DriveRecordForLinkDriverOwner mapData={mapData} dataHighLight={data} />
      )}
    </div>
  );
};
