import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { contactApi } from '@/api/inquiryApi';
import { useToast } from '@/hooks/useToast';
import { ContactApiParams } from '@/types/api/queryApi';

export const useContact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const { openToast } = useToast();
  const contact = async (params: ContactApiParams) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const { data } = await contactApi(params);
      openToast('送信に成功しました');
    } catch (err) {
      setError(err as AxiosError);
      openToast('送信に失敗しました', 'toast-failed');
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    contact,
    isLoading,
    error,
  };
};
