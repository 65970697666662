import { FC } from 'react';
import { CarIconProps } from './CarIcon';

export const CarIcon8: FC<CarIconProps> = ({ color, reference }) => {
  return (
    <svg
      ref={reference}
      width="728"
      height="536"
      viewBox="0 0 91 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="0.75"
        width="88.5"
        height="65.5"
        rx="15.25"
        fill="#F3F5F6"
        stroke="#EFEFEF"
        strokeWidth="1.5"
      />
      <path
        d="M8.77188 38.8504C8.90329 34.908 11.8888 31.6497 15.8048 31.175L25.5002 29.9998L36.6235 20.8111C36.8612 20.6147 37.1763 20.539 37.4773 20.6059L40.034 21.174C40.7311 21.3289 41.0459 22.1435 40.6341 22.7269L35.5002 29.9998H63.5002L66.5002 25.4998H71.5002V28.9998L74.7587 29.3102C78.8646 29.7012 82.0002 33.1497 82.0002 37.2741V42.9996C82.0002 45.2087 80.2094 46.9996 78.0002 46.9996H12.6358C10.3744 46.9996 8.56267 45.1265 8.63801 42.8663L8.77188 38.8504Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0924 31.9272C26.7238 32.2317 26.2757 32.4241 25.8011 32.4817L16.1056 33.6569C13.4134 33.9832 11.3608 36.2233 11.2705 38.9337L11.1366 42.9496C11.1084 43.7972 11.7878 44.4996 12.6358 44.4996H78.0002C78.8287 44.4996 79.5002 43.828 79.5002 42.9996V37.2741C79.5002 34.4386 77.3445 32.0677 74.5217 31.7989L71.2632 31.4886C69.9801 31.3664 69.0002 30.2887 69.0002 28.9998V27.9998H67.8382L65.5804 31.3866C65.1167 32.0821 64.3361 32.4998 63.5002 32.4998H35.5002C34.5663 32.4998 33.7102 31.9792 33.2805 31.15C32.8508 30.3208 32.9192 29.3211 33.4578 28.5581L36.6568 24.0263L27.0924 31.9272ZM66.5002 25.4998H71.5002V28.9998L74.7587 29.3102C78.8646 29.7012 82.0002 33.1497 82.0002 37.2741V42.9996C82.0002 45.2087 80.2094 46.9996 78.0002 46.9996H12.6358C10.3744 46.9996 8.56267 45.1265 8.63801 42.8663L8.77188 38.8504C8.90329 34.908 11.8888 31.6497 15.8048 31.175L25.5002 29.9998L36.6235 20.8111C36.8612 20.6147 37.1763 20.539 37.4773 20.6059L40.034 21.174C40.7311 21.3289 41.0459 22.1435 40.6341 22.7269L35.5002 29.9998H63.5002L66.5002 25.4998Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4556 31.1563C26.2344 31.339 25.9655 31.4544 25.6807 31.4889L15.9853 32.6641C12.8036 33.0498 10.3778 35.6971 10.271 38.9004L10.1372 42.9163C10.0901 44.3289 11.2224 45.4996 12.6358 45.4996H78.0002C79.381 45.4996 80.5002 44.3803 80.5002 42.9996V37.2741C80.5002 33.923 77.9525 31.1211 74.6165 30.8034L71.358 30.4931C70.5882 30.4198 70.0002 29.7732 70.0002 28.9998V26.9998H67.303L64.7483 30.8319C64.4701 31.2492 64.0018 31.4998 63.5002 31.4998H35.5002C34.9399 31.4998 34.4262 31.1875 34.1684 30.6899C33.9106 30.1924 33.9516 29.5926 34.2748 29.1348L38.9756 22.4754L37.3904 22.1231L26.4556 31.1563ZM66.5002 25.4998H71.5002V28.9998L74.7587 29.3102C78.8646 29.7012 82.0002 33.1497 82.0002 37.2741V42.9996C82.0002 45.2087 80.2094 46.9996 78.0002 46.9996H12.6358C10.3744 46.9996 8.56267 45.1265 8.63801 42.8663L8.77188 38.8504C8.90329 34.908 11.8888 31.6497 15.8048 31.175L25.5002 29.9998L36.6235 20.8111C36.8612 20.6147 37.1763 20.539 37.4773 20.6059L40.034 21.174C40.7311 21.3289 41.0459 22.1435 40.6341 22.7269L35.5002 29.9998H63.5002L66.5002 25.4998Z"
        fill="#323232"
      />
      <path
        d="M34.5 44C34.5 48.4183 30.9183 52 26.5 52C22.0817 52 18.5 48.4183 18.5 44C18.5 39.5817 22.0817 36 26.5 36C30.9183 36 34.5 39.5817 34.5 44Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 50.5C30.0899 50.5 33 47.5899 33 44C33 40.4101 30.0899 37.5 26.5 37.5C22.9101 37.5 20 40.4101 20 44C20 47.5899 22.9101 50.5 26.5 50.5ZM26.5 52C30.9183 52 34.5 48.4183 34.5 44C34.5 39.5817 30.9183 36 26.5 36C22.0817 36 18.5 39.5817 18.5 44C18.5 48.4183 22.0817 52 26.5 52Z"
        fill="#323232"
      />
      <path
        d="M75.5 44C75.5 48.4183 71.9183 52 67.5 52C63.0817 52 59.5 48.4183 59.5 44C59.5 39.5817 63.0817 36 67.5 36C71.9183 36 75.5 39.5817 75.5 44Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.5 50.5C71.0899 50.5 74 47.5899 74 44C74 40.4101 71.0899 37.5 67.5 37.5C63.9101 37.5 61 40.4101 61 44C61 47.5899 63.9101 50.5 67.5 50.5ZM67.5 52C71.9183 52 75.5 48.4183 75.5 44C75.5 39.5817 71.9183 36 67.5 36C63.0817 36 59.5 39.5817 59.5 44C59.5 48.4183 63.0817 52 67.5 52Z"
        fill="#323232"
      />
      <path
        d="M30.5 44C30.5 46.2091 28.7091 48 26.5 48C24.2909 48 22.5 46.2091 22.5 44C22.5 41.7909 24.2909 40 26.5 40C28.7091 40 30.5 41.7909 30.5 44Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 46.5C27.8807 46.5 29 45.3807 29 44C29 42.6193 27.8807 41.5 26.5 41.5C25.1193 41.5 24 42.6193 24 44C24 45.3807 25.1193 46.5 26.5 46.5ZM26.5 48C28.7091 48 30.5 46.2091 30.5 44C30.5 41.7909 28.7091 40 26.5 40C24.2909 40 22.5 41.7909 22.5 44C22.5 46.2091 24.2909 48 26.5 48Z"
        fill="#323232"
      />
      <path
        d="M71.5 44C71.5 46.2091 69.7091 48 67.5 48C65.2909 48 63.5 46.2091 63.5 44C63.5 41.7909 65.2909 40 67.5 40C69.7091 40 71.5 41.7909 71.5 44Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.5 46.5C68.8807 46.5 70 45.3807 70 44C70 42.6193 68.8807 41.5 67.5 41.5C66.1193 41.5 65 42.6193 65 44C65 45.3807 66.1193 46.5 67.5 46.5ZM67.5 48C69.7091 48 71.5 46.2091 71.5 44C71.5 41.7909 69.7091 40 67.5 40C65.2909 40 63.5 41.7909 63.5 44C63.5 46.2091 65.2909 48 67.5 48Z"
        fill="#323232"
      />
      <path
        d="M36.5 42.75C36.5 42.3358 36.8358 42 37.25 42H49.75C50.1642 42 50.5 42.3358 50.5 42.75C50.5 43.1642 50.1642 43.5 49.75 43.5H37.25C36.8358 43.5 36.5 43.1642 36.5 42.75Z"
        fill="#323232"
      />
      <path
        d="M53.0675 26.2974C53.3258 25.5226 54.0509 25 54.8675 25C56.1626 25 57.0771 26.2688 56.6675 27.4974L55.5 31H51.5L53.0675 26.2974Z"
        fill="#323232"
      />
    </svg>
  );
};
