import { FC } from 'react';
import style from './paymentInfoStyle.module.css';

interface PaymentInfoProps {
  title: string;
  paymentMethod: string;
  paymentNumber: number;
}
export const PaymentInfo: FC<PaymentInfoProps> = ({
  title,
  paymentMethod,
  paymentNumber,
}) => {
  return (
    <div className={style['payment-info']}>
      <div className="heading-with-button u-font-size-xl u-font-bold">
        {title}
      </div>
      <div className={style['info-item']}>
        <span>お支払い方法</span>
        <span className="u-font-bold">{paymentMethod}</span>
      </div>
      {!!paymentNumber && (
        <div className={style['info-item']}>
          <span>お支払い回数</span>
          <span className="u-font-bold">{paymentNumber}回払い</span>
        </div>
      )}
    </div>
  );
};
