import { Col, Row } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import attachPart from '@/assets/images/attachPart.png';
import calibrationProcedure from '@/assets/images/calibrationProcedure.png';
import procedureDetail from '@/assets/images/procedureDetail.png';
import { Button } from '@/components/uiParts/Button/Button/Button';
import './LinkDriveCalibrationProcedureStyle.scss';

interface LinkDriveCalibrationProcedureProps {
  f7router: Router.Router;
  handleClickAction: () => void;
}

export const LinkDriveCalibrationProcedure: FC<
  LinkDriveCalibrationProcedureProps
> = ({ f7router, handleClickAction }) => {
  return (
    <div className="calibrationProcedure">
      <div className="procedureBox">
        <p className="boxTitle">キャリブレーション設定手順</p>
        <img
          className="imgProcedureStep u-margin-top-sm"
          src={calibrationProcedure}
          alt=""
        />
      </div>
      <div className="text-align-center u-margin-top-sm">
        <p className="text-blue u-font-size-xl">
          &#10003; コネクタを車に装着してください
        </p>
        <p className="text-note">
          ※コネクタが装着されている場合はそのままお進みください。
        </p>
      </div>
      <div className="procedureBox">
        <h3 className="u-margin-top-xs title">装着部分</h3>
        <div className="attachPartBox u-margin-top-sm">
          <img className="imgProcedureStep" src={attachPart} alt="" />
          <div className="attachPartBoxContent">
            <p className="title">車両のOBD Ⅱ コネクタ</p>
            <p className="note">※ 車種によって色は異なります</p>
          </div>
        </div>
      </div>
      <div className="procedureBox">
        <h3 className="u-margin-top-xs title">装着箇所</h3>
        <p className="u-padding-top-xs">
          装着場所は車によって異なります。下記の画像を参考に装着してください。
        </p>
        <div className="procedureBoxdetail">
          <img
            className="imgProcedureStep u-margin-top-sm"
            src={procedureDetail}
            alt=""
          />
          <div className="explainDetails">
            <div>
              <p>① キックパネル付近</p>
              <p>② 運転席足元右側</p>
              <p>③ 運転席足元中央</p>
              <p>④ 運転席足元左側</p>
              <p>⑤ センターコンソール右側</p>
            </div>
            <div>
              <p>⑥ 助手席足元右側</p>
              <p>⑦ ステアリング右パネル裏側</p>
              <p>③ 助手席足元左側</p>
              <p>⑨ センターコンソール左側</p>
              <p>⑩ センターコンソール下側</p>
            </div>
          </div>
        </div>
      </div>
      <Row className="u-margin-top-lg">
        <Col width="100">
          <Button
            style={{ backgroundColor: '#1E55E6' }}
            text="キャリブレーションを設定する"
            handleClickAction={handleClickAction}
          />
        </Col>
      </Row>
    </div>
  );
};
