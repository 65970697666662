import { Router } from 'framework7/types';
import React, { FC } from 'react';
import { DriveHistoryDetail } from './DriveHistoryDetail/DriveHistoryDetail';

interface DriveHistoryDetailPageProps {
  f7router: Router.Router;
}

export const DriveHistoryDetailPage: FC<DriveHistoryDetailPageProps> = ({
  f7router,
}) => {
  return <DriveHistoryDetail f7router={f7router} />;
};
