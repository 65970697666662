import { FC } from 'react';
import './linkDriveInfoStyle.scss';

interface LinkDriveInfoProps {
  ecu_type: string;
}

export const LinkDriveInfo: FC<LinkDriveInfoProps> = ({ ecu_type }) => {
  const isBasicMode = ecu_type == '1' || ecu_type == '2';
  const optInfo = isBasicMode
    ? {
        title:
          'この車両では、スタンダードモードを使ったLINKDriveの利用が可能です',
        textContent:
          'スタンダードモードでは、メーカーの診断機と同等の幅広いデータの取得・分析が可能です。',
      }
    : {
        title: 'この車両では、ノーマルモードを使ったLINKDriveの利用が可能です',
        textContent:
          'ノーマルモードではGPSを使ったデータの取得・分析が可能です。',
      };
  return (
    <div className="link-drive-info-box list">
      <div className="item-content">
        <div className="item-inner">
          <div className="item-title">
            <i className={`icon-notification-settings`} />
            {optInfo.title}
          </div>
        </div>
      </div>
      <div className="content">
        <p>{optInfo.textContent}</p>
      </div>
    </div>
  );
};
