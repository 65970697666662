import { Icon } from 'framework7-react';
import { FC } from 'react';
import style from './AccountRegistPaymentStyle.module.css';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';

interface AccountRegistPaymentProps {
  status?: string;
  currentCarId?: string;
  GMOParameters: GMOParameters;
  actionUrl: string;
  handleClickBack: () => void;
  handleClickWithdrawFromBank: () => void;
  handleClickCreditCardPayment: () => void;
}

type GMOParameters = {
  RetURL: string;
  CancelURL: string;
  CreateMember: string;
  ConsumerDevice: string;
  SiteID: string;
  SitePass: string;
  ShopID: string;
  dateTime: string;
  memberPassString: string;
  memberID: string;
  Enc: string;
};

export const AccountRegistPayment: FC<AccountRegistPaymentProps> = ({
  currentCarId,
  status,
  GMOParameters,
  actionUrl,
  handleClickBack,
  handleClickWithdrawFromBank,
  handleClickCreditCardPayment,
}) => {
  return (
    <PageTemplate
      showNavBack
      pageName="AccountRegistPayment"
      title="決済方法を登録"
      handleClickBack={handleClickBack}
    >
      <div className={style['container']}>
        <div
          className={style['list-item']}
          onClick={handleClickWithdrawFromBank}
        >
          <i className={`icon-bank icon`} />
          <p>銀行口座</p>
          <Icon className={style['chevron']} f7="chevron_right" size={24} />
        </div>
        <hr className={style['divider']} />
        <div
          className={style['list-item']}
          onClick={handleClickCreditCardPayment}
        >
          <i className={`icon-credit-card icon`} />
          <p>クレジットカード</p>
          <Icon className={style['chevron']} f7="chevron_right" size={24} />
        </div>
      </div>

      {actionUrl && (
        <form action={actionUrl} method="POST">
          <input type="hidden" name="SiteID" value={GMOParameters.SiteID} />
          <input type="hidden" name="MemberID" value={GMOParameters.memberID} />
          <input type="hidden" name="ShopID" value={GMOParameters.ShopID} />
          <input
            type="hidden"
            name="MemberPassString"
            value={GMOParameters.memberPassString}
          />
          <input type="hidden" name="RetURL" value={GMOParameters.RetURL} />
          <input
            type="hidden"
            name="CancelURL"
            value={GMOParameters.CancelURL}
          />
          <input type="hidden" name="DateTime" value={GMOParameters.dateTime} />
          <input type="hidden" name="Enc" value={GMOParameters.Enc} />
          <input id="submit" type="submit" style={{ display: 'none' }} />
        </form>
      )}
    </PageTemplate>
  );
};
