import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { useState } from 'react';
import {
  checkFirstLoginApi,
  multipleAffiliateInfo,
  pinAuthApi,
} from '@/api/authApi';
import { isApp } from '@/config/device';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { selectedProcess } from '@/consts/auth';
import { loginType, mobileLoginStatus } from '@/consts/login';
import { url } from '@/consts/url';
import { useToast } from '@/hooks/useToast';
import { PinAuthApiParams, SelectedProcess } from '@/types/api/authApi';
import { Customer } from '@/types/api/customerApi';
import {
  addAffiliateIdToRequestParams,
  getAffiliateIdFromThemeId,
} from '@/utils/shop';
import { storeDispatch } from '@/utils/store';

export const usePinAuth = (f7router: Router.Router) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const { openToast } = useToast();
  const themeId = store.state.themeId;
  const globalTheme = store.state.globalTheme;

  const switchApiParams = (
    process: SelectedProcess,
    params: PinAuthApiParams,
  ): PinAuthApiParams => {
    const affiliateId = getAffiliateIdFromThemeId(themeId);
    let newParams: PinAuthApiParams = {
      selected_process: params.selected_process,
      phone_no: params.phone_no,
      authentication_code: params.authentication_code,
    };
    addAffiliateIdToRequestParams(affiliateId, newParams);
    switch (process) {
      case selectedProcess.login:
        return newParams;
      case selectedProcess.register:
        return {
          ...newParams,
          m_customer_id: store.state.authInfo.m_customer_id,
        };
      case selectedProcess.change:
        return { ...newParams, phone_no_before: params.phone_no_before };
      default:
        return newParams;
    }
  };

  const switchCustomerInfo = (
    params: PinAuthApiParams,
    customer: Customer,
  ): Customer | undefined => {
    switch (params.selected_process) {
      case selectedProcess.register:
        if (!customer.phone_no1) {
          return {
            ...customer,
            phone_no1: params.phone_no,
            mobile_flg1: true,
            mobile_type1: 1,
          };
        } else {
          return {
            ...customer,
            phone_no2: params.phone_no,
            mobile_flg2: true,
            mobile_type2: 1,
          };
        }
      case selectedProcess.change:
        if (params.phone_no_before === customer.phone_no1) {
          return {
            ...customer,
            phone_no1: params.phone_no,
            mobile_flg1: true,
            mobile_type1: 1,
          };
        } else {
          return {
            ...customer,
            phone_no2: params.phone_no,
            mobile_flg2: true,
            mobile_type2: 1,
          };
        }
    }
  };

  const isFirstLogin = async (number: string) => {
    const { data } = await checkFirstLoginApi({ phone_no: number });
    return (
      data.success &&
      data.t_customer_auth_codes.rs_flg === mobileLoginStatus.FIRST
    );
  };

  const isDuplicatedCheck = async (number: string) => {
    const { data } = await multipleAffiliateInfo({
      parameter: number,
    });
    let isDuplicated = data.success && data.m_customers.length > 1;
    if (isDuplicated && globalTheme != null) {
      const currentAffiliate = data.m_customers.find(
        (customer) =>
          customer.m_affiliate_id === getAffiliateIdFromThemeId(globalTheme),
      );
      if (currentAffiliate) {
        data.m_customers = data.m_customers.filter(
          (affiliate) =>
            affiliate.affiliate_app_type ===
            currentAffiliate.affiliate_app_type,
        );
        isDuplicated = data.m_customers.length > 1;
      }
    }
    return {
      data,
      isDuplicated,
    };
  };

  const authenticatePin = async (params: PinAuthApiParams) => {
    f7.preloader.show();
    setIsLoading(true);
    const newParams = switchApiParams(params.selected_process, params);
    const isFirst = await isFirstLogin(params.phone_no);
    try {
      const { data } = await pinAuthApi(newParams);
      if (!data.success) throw new Error(data.error?.message);
      await storeDispatch('setAuthInfo', 'authInfo', {
        m_customer_id: data.m_customers?.id,
        isLogin: true,
        token: data.token,
      });

      if (!(params.selected_process === selectedProcess.login)) {
        await storeDispatch(
          'setCustomerInfo',
          'customerInfo',
          switchCustomerInfo(params, store.state.customerInfo),
        );
        f7.view.main.router.navigate(paths.account, {
          animate: true,
          props: { selectedProcess: params.selected_process },
        });
      }

      if (isFirst) {
        // ResponseをNative側へ保存する
        if (isApp) {
          window.location.href =
            url.NATIVE_BASE_URL +
            '/set/login_success?token=' +
            data.token +
            '&id=' +
            data.m_customers?.id +
            '&type=' +
            loginType.TELEPHONE;
        }
        f7.view.main.router.navigate(paths.confirmTerms, { animate: true });
      } else {
        const { isDuplicated, data: affiliatesInfo } = await isDuplicatedCheck(
          params.phone_no,
        );
        if (isDuplicated && affiliatesInfo && globalTheme == null) {
          f7.view.main.router.navigate(paths.emailDuplicated, {
            props: {
              affiliates: affiliatesInfo.m_customers,
            },
          });
        } else {
          // ResponseをNative側へ保存する
          if (isApp) {
            window.location.href =
              url.NATIVE_BASE_URL +
              '/set/login_success?token=' +
              data.token +
              '&id=' +
              data.m_customers?.id +
              '&type=' +
              loginType.TELEPHONE;
          }
          f7.view.main.router.navigate(paths.home, { animate: true });
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        openToast(e.message, 'toast-failed');
      }
      setError(e);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    authenticatePin,
    isLoading,
    error,
  };
};
