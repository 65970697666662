import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { PaymentsHistory2 } from './PaymentsHistory2/PaymentsHistory2';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { OUTPUT_FLAG, noPayFlg } from '@/consts/payment';
import { BillInformationDetail } from '@/types/api/customerApi';
import { formatYearMonthToJa } from '@/utils/date';
import { calcTotalBillPrice } from '@/utils/payment';

type PaymentHistory2PageProps = {
  f7router: Router.Router;
};

export const PaymentsHistory2Page: FC<PaymentHistory2PageProps> = ({
  f7router,
}) => {
  const bills = store.state.billsInfo;
  const billDetails = bills
    .map((bill) =>
      bill.bill_infomation_details.map((bid) => ({
        ...bid,
        warranty_no: bill.warranty_no,
        m_prepaid_id: bill.m_prepaid_id,
      })),
    )
    .flat()
    .filter((item) => !!item);

  const makeBillDetailsGroupByBillMonth = (
    billDetails: BillInformationDetail[],
  ) => {
    const newObj: { [key in string]: BillInformationDetail[] } = {};
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    billDetails.forEach((el) => {
      const billDate = new Date(el.billing_date as string);
      if (
        el.bill_month &&
        billDate < now &&
        (el.no_pay_flg === noPayFlg.BEFORE_PAYMENT ||
          el.no_pay_flg === noPayFlg.PAYMENT_CONFIRMED ||
          (el.output_flg == OUTPUT_FLAG.invoice &&
            el.no_pay_flg == noPayFlg.UNTREATED))
      ) {
        const month = el.bill_month; // 例: 202201
        if (!newObj[month]) {
          newObj[month] = [el];
        } else {
          newObj[month] = [...newObj[month], el];
        }
      }
    });

    return Object.keys(newObj)
      .sort((a, b) => Number(b) - Number(a))
      .map((key) => ({
        month: formatYearMonthToJa(key),
        billDetails: newObj[key].sort(
          (a, b) =>
            new Date(b.billing_date as string).getTime() -
            new Date(a.billing_date as string).getTime(),
        ),
        totalPrice: calcTotalBillPrice(newObj[key]),
      }));
  };

  const handleClickBillDetail = (bill: BillInformationDetail) => {
    f7.view.main.router.navigate(paths.paymentReceipt, {
      props: {
        billDetail: bill,
      },
    });
    return;
  };
  return (
    <PaymentsHistory2
      billDetailsGroupByMonth={makeBillDetailsGroupByBillMonth(billDetails)}
      onClickBillDetail={handleClickBillDetail}
    />
  );
};
