import axios from 'axios';
import { baseApi } from '@/config/axios';
import {
  HousePrepaidUseInfoParams,
  HousePrepaidUseInfoResponse,
} from '@/types/api/infoApi';
import { GoogleGeocodeParams } from '@/types/api/payment';
import {
  BankListResponse,
  ChangePrepaidMoneyParams,
  ChangePrepaidMoneyResponse,
  HouseprepaidApplicationParams,
  HouseprepaidApplicationResponse,
  HousePrepaidChargeParams,
  HousePrepaidChargeResponse,
  PaymentBankListParams,
  PaymentBankListResponse,
  PrepaidChargeParameterParams,
  PrepaidChargeParameterResponse,
  RegisterPrepaidInfoParams,
  RegisterPrepaidInfoResponse,
  UpdateCampaignParams,
  UpdateCampaignParamsResponse,
  StopRestartHouseprepaidParams,
  StopRestartHouseprepaidResponse,
} from '@/types/api/prepaidApi';
const API_V1 = 'v1/';

//houseprepaidApplication
export const fetchHouseprepaidApplication = (
  params: HouseprepaidApplicationParams,
): Promise<HouseprepaidApplicationResponse> =>
  baseApi.get(`${API_V1}customer/houseprepaid-application`, { params });

//銀行一覧
export const fetchBankList = (): Promise<BankListResponse> =>
  baseApi.get(`${API_V1}banks`);

//プリペイド開始
export const registerPrepaidInfo = (
  params: RegisterPrepaidInfoParams,
): Promise<RegisterPrepaidInfoResponse> =>
  baseApi.post(`${API_V1}customer/houseprepaid-application`, params);

// HP利用情報送信API
export const registerHouseprepaidUseInfoApi = (
  params: HousePrepaidUseInfoParams,
): Promise<HousePrepaidUseInfoResponse> =>
  baseApi.post('v1/customer/houseprepaid-use-info', params);

//キャンペーン情報更新
export const updateCampaign = (
  params: UpdateCampaignParams,
): Promise<UpdateCampaignParamsResponse> =>
  baseApi.put('v1/customer/campaign-modify', params);

//チャージ
export const chargePrepaidApi = (
  params: HousePrepaidChargeParams,
): Promise<HousePrepaidChargeResponse> =>
  baseApi.post('v1/customer/houseprepaid-charge', params);

//利用可能銀行一覧
export const fetchAvailableRegisterBankListApi = (
  params: PaymentBankListParams,
): Promise<PaymentBankListResponse> =>
  baseApi.get('/v1/customer/payment/bank', { params });

// 住所から緯度経度取得
export const getGoogleGeocode = (params: GoogleGeocodeParams): Promise<any> =>
  axios.get('https://maps.googleapis.com/maps/api/geocode/json', { params });

// プリペイドチャージ時のパラメータ取得
export const getPrepaidChargeParameter = (
  params: PrepaidChargeParameterParams,
): Promise<PrepaidChargeParameterResponse> =>
  baseApi.get('/v1/customer/payment/getPrepaidChargeParameter', { params });

export const changePrepaidMoneyApi = (
  params: ChangePrepaidMoneyParams,
): Promise<ChangePrepaidMoneyResponse> =>
  baseApi.put('v1/customer/houseprepaid-charge', params);

// HP利用停止・再開API
export const stopRestartHouseprepaidApi = (
  params: StopRestartHouseprepaidParams,
): Promise<StopRestartHouseprepaidResponse> =>
  baseApi.post('v1/customer/houseprepaid-stop-restart', params);
