import { FC } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { CalibrationProcessBar } from '../CalibrationProcessBar/CalibrationProcessBar';
import style from './LinkDriveCalibrationStep3Style.module.css';
import CarIcon from '@/assets/images/car-check-light.svg';

export const LinkDriveCalibrationStep3: FC = () => {
  return (
    <div className={style['step3']}>
      <CalibrationProcessBar step={3} />
      <div className={style.loading}>
        <CircularProgressbarWithChildren strokeWidth={5} value={100}>
          <div className={style['progress-inner']}>
            <img src={CarIcon} />
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <h2 className={style.heading}>キャリブレーション計測完了</h2>
    </div>
  );
};
