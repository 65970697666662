import { Router } from 'framework7/types';
import { FC } from 'react';
import { AccelerationData } from './AccelerationData/AccelerationData';

interface AccelerationDataPageProps {
  f7router: Router.Router;
}

export const AccelerationDataPage: FC<AccelerationDataPageProps> = ({
  f7router,
}) => {
  return <AccelerationData f7router={f7router} />;
};
