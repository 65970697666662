import { FC } from 'react';
import { HOUR_OPTIONS } from './const';
import { RhfDropdown } from '@/components/uiParts/ReactHookForm/RhfDropdown/RhfDropdown';
import './TodoDetailStyle.scss';

interface SelectHoursProps {
  name: string;
  startTime: number;
  endTime: number;
}

export const SelectHours: FC<SelectHoursProps> = (props) => {
  const { name, startTime, endTime } = props;

  return (
    <>
      <div className="delivery-item-label">時間</div>
      <div className="input-dropdown">
        <RhfDropdown className={'select-hour'} name={name}>
          {HOUR_OPTIONS(startTime, endTime).map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </RhfDropdown>
      </div>
    </>
  );
};
