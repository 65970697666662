import { FC } from 'react';
import { RegisterBank } from './RegisterBank/RegisterBank';

type ResigterBankPageProps = {
  redirectPage: string;
};

export const RegisterBankPage: FC<ResigterBankPageProps> = ({
  redirectPage,
}) => {
  return <RegisterBank redirectPage={redirectPage} />;
};
