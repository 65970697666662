import { FC } from 'react';
import style from './requestCustomerListStyle.module.css';

interface RequestCustomerListProps {
  title?: string;
  items?: string[];
}

export const RequestCustomerList: FC<RequestCustomerListProps> = ({
  title = 'お客様照会のため、\n下記の情報を確認させていただきます',
  items = [
    'お客様のお名前',
    'お客様のお電話番号\n（以前利用されていたもの）',
    'お客様の生年月日',
  ],
}) => {
  return (
    <div className={style['container']}>
      <p className={style['title']}>{title}</p>
      {items.map((item) => (
        <div key={item} className={style['list']}>
          <span>□</span>
          <p>{item}</p>
        </div>
      ))}
    </div>
  );
};
