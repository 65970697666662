import { FC } from 'react';
import { ImplementScan } from '../ImplementScan/ImplementScan';
import { SelfScanStep1 } from '../SelfScanStep1/SelfScanStep1';
import { SelfScanStep2 } from '../SelfScanStep2/SelfScanStep2';
import { SleepDialog } from '@/components/projects/SleepDialog';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { useSelfScanSteps } from '@/hooks/useSelfScanSteps';

export const SelfScanSteps: FC = () => {
  const {
    progress,
    isDuringScanning,
    isSelfScanCompleted,
    messageErrorScan,
    isUpdating,
    currentStep,
    steps,
    showDialog,
    setShowDialog,
    handleClickConfirm,
    handleClickStartedEngine,
  } = useSelfScanSteps();

  return (
    <PopupPageTemplate pageName="SelfScanSteps" title="車両状態診断">
      <div style={{ width: '100%', height: '16px' }} />
      {currentStep === 1 && (
        <SelfScanStep1 handleClickAction={handleClickConfirm} />
      )}
      {currentStep === 2 && (
        <SelfScanStep2
          messageErrorScan={messageErrorScan}
          handleClickAction={handleClickStartedEngine}
        />
      )}
      {currentStep === 3 && (
        <ImplementScan
          progress={progress}
          isSelfScanCompleted={isSelfScanCompleted}
          isDuringScanning={isDuringScanning}
          messageErrorScan={messageErrorScan}
          isUpdating={isUpdating}
        />
      )}
      {showDialog && <SleepDialog handleCancel={() => setShowDialog(false)} />}
    </PopupPageTemplate>
  );
};
