export enum Rank {
  REGULAR = `該当なし`,
  BRONZE = `ブロンズ`,
  SILVER = `シルバー`,
  GOLD = `ゴールド`,
  PLATINUM = `プラチナ`,
  DIAMOND = `ダイヤモンド`,
}

export enum BenefitType {
  COUPON = 'coupon', // 1
  PREPAID_CHARGE = 'prepaid_charge', // 2
  REFERRAL_BENEFIT = 'referral_benefit', // 3
  TRANSFER_BONUS = 'transfer_bonus', // 4
  TIRE = 'tire', // 5
  BIRTHDAY_GIFT = 'birthday_gift', // 6
}

export const RANK_THEMES = {
  [Rank.REGULAR]: {
    primary: '#BDBDBD',
    secondary: '#F0F0F0',
    index: 0,
  },
  [Rank.BRONZE]: {
    primary: '#AA7F66',
    secondary: '#F3EDEA',
    index: 1,
  },
  [Rank.SILVER]: {
    primary: '#8C8C8C',
    secondary: '#EFEFEF',
    index: 2,
  },
  [Rank.GOLD]: {
    primary: '#B39535',
    secondary: '#F5F1E2',
    index: 3,
  },
  [Rank.PLATINUM]: {
    primary: '#5F8DA2',
    secondary: '#EAF1F5',
    index: 4,
  },
  [Rank.DIAMOND]: {
    primary: '#7F95E4',
    secondary: '#EBEEFA',
    index: 5,
  },
};

export const MAP_RANK_NAMES = {
  [Rank.REGULAR]: 'レギュラー',
  [Rank.BRONZE]: 'ブロンズ',
  [Rank.SILVER]: 'シルバー',
  [Rank.GOLD]: 'ゴールド',
  [Rank.PLATINUM]: 'プラチナ',
  [Rank.DIAMOND]: 'ダイヤモンド',
};
