import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { customerInfoApi } from '@/api/customerApi';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import {
  BillInformation,
  Car,
  CustomerInfoApiParams,
  PaymentMethod,
  Prepaid,
  Warranty,
} from '@/types/api/customerApi';

interface Props extends CustomerInfoApiParams {
  warrantyNo?: string;
  findFunc?: (value: Car) => unknown;
}

export const useRegisterPayment = ({
  warrantyNo,
  findFunc,
  ...params
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentWarranty, setCurrentWarranty] = useState<Warranty>();
  const [cars, setCars] = useState<Car[]>([]);
  const [bills, setBills] = useState<BillInformation[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [prepaids, setPrepaids] = useState<Prepaid[]>([]);
  const [error, setError] = useState<unknown>();
  const { openToast } = useToast();

  const callCustomerInfoApi = async () => {
    try {
      const { data: customerInfo } = await customerInfoApi(params);
      if (!customerInfo.success) {
        throw new Error();
      }
      const {
        cars,
        bill_infomations: bills,
        payment_methods: paymentMethods,
        prepaids,
      } = customerInfo;
      const car = findFunc ? cars?.find(findFunc) : cars?.[0] || cars?.[0];
      const currentWarranty =
        car?.warranties?.find((w) => w.warranty_no === warrantyNo) ||
        store.state.currentWarranty;
      return { bills, cars, currentWarranty, paymentMethods, prepaids };
    } catch (e) {
      openToast('保証情報の取得に失敗しました', 'toast-failed');
    }
  };

  useEffect(() => {
    const fn = async () => {
      try {
        f7.preloader.show();
        setIsLoading(true);
        const info = await callCustomerInfoApi();
        setCurrentWarranty(info?.currentWarranty);
        setCars(info?.cars ?? []);
        setBills(info?.bills ?? []);
        setPaymentMethods(info?.paymentMethods ?? []);
        setPrepaids(info?.prepaids ?? []);
      } catch (e: unknown) {
        setError(e);
        console.error(e);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
    // mount時にだけ呼び出すため,openToastとsetCustomerInfoを省略
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    error,
    bills,
    cars,
    currentWarranty,
    paymentMethods,
    prepaids,
  };
};
