import { FC } from 'react';
import { CarIconProps } from './CarIcon';

export const CarIcon3: FC<CarIconProps> = ({ color, reference }) => {
  return (
    <svg
      ref={reference}
      width="728"
      height="536"
      viewBox="0 0 91 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="0.5"
        width="89"
        height="66"
        rx="15.5"
        fill="#F3F5F6"
        stroke="#EFEFEF"
      />
      <path
        d="M7.94234 37.459C8.28849 34.6898 10.0527 32.3012 12.5977 31.1559L18.4998 28.5L25.7482 20.6475C28.7771 17.3662 33.0395 15.5 37.5051 15.5H70.5272C73.8266 15.5 76.7875 17.5255 77.9833 20.6004L82.4558 32.1012C82.8153 33.0257 82.9997 34.0089 82.9997 35.0008V44.9998C82.9997 47.2089 81.2089 48.9998 78.9997 48.9998H11.0309C8.62487 48.9998 6.76332 46.891 7.06175 44.5036L7.94234 37.459Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3368 30.1957C20.1085 30.443 19.8326 30.6417 19.5257 30.7798L13.6236 33.4357C11.8739 34.2231 10.661 35.8653 10.423 37.7691L9.54245 44.8137C9.43053 45.709 10.1286 46.4998 11.0309 46.4998H78.9997C79.8282 46.4998 80.4997 45.8282 80.4997 44.9998V35.0008C80.4997 34.3189 80.3729 33.6429 80.1258 33.0074L75.6533 21.5066C74.8311 19.3925 72.7955 18 70.5272 18H37.5051C33.7373 18 30.1409 19.5746 27.5852 22.3432L20.3368 30.1957ZM18.4998 28.5L12.5977 31.1559C10.0527 32.3012 8.28849 34.6898 7.94234 37.459L7.06175 44.5036C6.76332 46.891 8.62487 48.9998 11.0309 48.9998H78.9997C81.2089 48.9998 82.9997 47.2089 82.9997 44.9998V35.0008C82.9997 34.0089 82.8153 33.0257 82.4558 32.1012L77.9833 20.6004C76.7875 17.5255 73.8266 15.5 70.5272 15.5H37.5051C33.0395 15.5 28.7771 17.3662 25.7482 20.6475L18.4998 28.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.602 29.5174C19.465 29.6658 19.2995 29.785 19.1153 29.8679L13.2132 32.5238C11.1454 33.4543 9.71201 35.3951 9.43076 37.6451L8.55017 44.6897C8.36365 46.1818 9.52711 47.4998 11.0309 47.4998H78.9997C80.3804 47.4998 81.4997 46.3805 81.4997 44.9998V35.0008C81.4997 34.1949 81.3499 33.396 81.0578 32.6449L76.5853 21.1441C75.6137 18.6457 73.2079 17 70.5272 17H37.5051C33.4582 17 29.5954 18.6912 26.8504 21.6649L19.602 29.5174ZM18.4998 28.5L12.5977 31.1559C10.0527 32.3012 8.28849 34.6898 7.94234 37.459L7.06175 44.5036C6.76332 46.891 8.62487 48.9998 11.0309 48.9998H78.9997C81.2089 48.9998 82.9997 47.2089 82.9997 44.9998V35.0008C82.9997 34.0089 82.8153 33.0257 82.4558 32.1012L77.9833 20.6004C76.7875 17.5255 73.8266 15.5 70.5272 15.5H37.5051C33.0395 15.5 28.7771 17.3662 25.7482 20.6475L18.4998 28.5Z"
        fill="#323232"
      />
      <path
        d="M33.5 46.5C33.5 51.7467 29.2467 56 24 56C18.7533 56 14.5 51.7467 14.5 46.5C14.5 41.2533 18.7533 37 24 37C29.2467 37 33.5 41.2533 33.5 46.5Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 54.5C28.4183 54.5 32 50.9183 32 46.5C32 42.0817 28.4183 38.5 24 38.5C19.5817 38.5 16 42.0817 16 46.5C16 50.9183 19.5817 54.5 24 54.5ZM24 56C29.2467 56 33.5 51.7467 33.5 46.5C33.5 41.2533 29.2467 37 24 37C18.7533 37 14.5 41.2533 14.5 46.5C14.5 51.7467 18.7533 56 24 56Z"
        fill="#323232"
      />
      <path
        d="M77.5 46.5C77.5 51.7467 73.2467 56 68 56C62.7533 56 58.5 51.7467 58.5 46.5C58.5 41.2533 62.7533 37 68 37C73.2467 37 77.5 41.2533 77.5 46.5Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68 54.5C72.4183 54.5 76 50.9183 76 46.5C76 42.0817 72.4183 38.5 68 38.5C63.5817 38.5 60 42.0817 60 46.5C60 50.9183 63.5817 54.5 68 54.5ZM68 56C73.2467 56 77.5 51.7467 77.5 46.5C77.5 41.2533 73.2467 37 68 37C62.7533 37 58.5 41.2533 58.5 46.5C58.5 51.7467 62.7533 56 68 56Z"
        fill="#323232"
      />
      <path
        d="M28.5 46.5C28.5 48.9853 26.4853 51 24 51C21.5147 51 19.5 48.9853 19.5 46.5C19.5 44.0147 21.5147 42 24 42C26.4853 42 28.5 44.0147 28.5 46.5Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 49.5C25.6569 49.5 27 48.1569 27 46.5C27 44.8431 25.6569 43.5 24 43.5C22.3431 43.5 21 44.8431 21 46.5C21 48.1569 22.3431 49.5 24 49.5ZM24 51C26.4853 51 28.5 48.9853 28.5 46.5C28.5 44.0147 26.4853 42 24 42C21.5147 42 19.5 44.0147 19.5 46.5C19.5 48.9853 21.5147 51 24 51Z"
        fill="#323232"
      />
      <path
        d="M72.5 46.5C72.5 48.9853 70.4853 51 68 51C65.5147 51 63.5 48.9853 63.5 46.5C63.5 44.0147 65.5147 42 68 42C70.4853 42 72.5 44.0147 72.5 46.5Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68 49.5C69.6569 49.5 71 48.1569 71 46.5C71 44.8431 69.6569 43.5 68 43.5C66.3431 43.5 65 44.8431 65 46.5C65 48.1569 66.3431 49.5 68 49.5ZM68 51C70.4853 51 72.5 48.9853 72.5 46.5C72.5 44.0147 70.4853 42 68 42C65.5147 42 63.5 44.0147 63.5 46.5C63.5 48.9853 65.5147 51 68 51Z"
        fill="#323232"
      />
      <path
        d="M31.5355 21.9645C32.4732 21.0268 33.745 20.5 35.0711 20.5H42.5L41.5862 30.0948C41.5373 30.608 41.1063 31 40.5907 31H26.5C25.9477 31 25.5 30.5523 25.5 30V29.6569C25.5 28.596 25.9214 27.5786 26.6716 26.8284L31.5355 21.9645Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8504 22H35.0711C34.1428 22 33.2526 22.3687 32.5962 23.0251L27.7322 27.8891C27.3005 28.3208 27.043 28.8942 27.0049 29.5H40.1361L40.8504 22ZM35.0711 20.5C33.745 20.5 32.4732 21.0268 31.5355 21.9645L26.6716 26.8284C25.9214 27.5786 25.5 28.596 25.5 29.6569V30C25.5 30.5523 25.9477 31 26.5 31H40.5907C41.1063 31 41.5373 30.608 41.5862 30.0948L42.5 20.5H35.0711Z"
        fill="#323232"
      />
      <path
        d="M71.0388 22.9243C70.4085 21.4536 68.9624 20.5 67.3623 20.5H60.7083C60.081 20.5 59.6086 21.0709 59.726 21.6871L61.345 30.1871C61.4348 30.6588 61.8472 31 62.3273 31H72.9833C73.7012 31 74.1852 30.2659 73.9024 29.6061L71.0388 22.9243Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.3125 22H67.3623C68.3623 22 69.2662 22.596 69.6601 23.5152L72.225 29.5H62.7411L61.3125 22ZM67.3623 20.5C68.9624 20.5 70.4085 21.4536 71.0388 22.9243L73.9024 29.6061C74.1852 30.2659 73.7012 31 72.9833 31H62.3273C61.8472 31 61.4348 30.6588 61.345 30.1871L59.726 21.6871C59.6086 21.0709 60.081 20.5 60.7083 20.5H67.3623Z"
        fill="#323232"
      />
      <path
        d="M57.6725 22.3104C57.5747 21.2839 56.7126 20.5 55.6815 20.5H45.4094C44.8938 20.5 44.4627 20.892 44.4139 21.4052L43.6043 29.9052C43.5484 30.4923 44.0101 31 44.5998 31H57.4003C57.99 31 58.4517 30.4923 58.3958 29.9052L57.6725 22.3104Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.864 22H55.6815C55.9393 22 56.1548 22.196 56.1792 22.4526L56.8504 29.5H45.1497L45.864 22ZM55.6815 20.5C56.7126 20.5 57.5747 21.2839 57.6725 22.3104L58.3958 29.9052C58.4517 30.4923 57.99 31 57.4003 31H44.5998C44.0101 31 43.5484 30.4923 43.6043 29.9052L44.4139 21.4052C44.4627 20.892 44.8938 20.5 45.4094 20.5H55.6815Z"
        fill="#323232"
      />
      <path
        d="M36.5 44.75C36.5 44.3358 36.8358 44 37.25 44H49.75C50.1642 44 50.5 44.3358 50.5 44.75C50.5 45.1642 50.1642 45.5 49.75 45.5H37.25C36.8358 45.5 36.5 45.1642 36.5 44.75Z"
        fill="#323232"
      />
    </svg>
  );
};
