import { Popup } from 'framework7-react';
import { PopupProps } from 'framework7-react/components/popup';
import { FC } from 'react';
import style from './CarWashConditionerStep2Style.module.css';
import { Button } from '@/components/uiParts/Button';

export const PopupAbout: FC<PopupProps> = ({ onPopupClosed, ...props }) => {
  return (
    <Popup {...props}>
      <div className={style['permission-popup']}>
        <h4>作業場所の許可取りについて</h4>
        <p>
          マンションや職場など、管理会社への確認が必要なスペースでの洗車をご希望の方は、お客様より管理会社への確認・許可取りをお願いしております。下記内容をお伝えいただきましたらスムーズかと存じますので存じますので、ご活用ください
        </p>
        <p>
          <ul>
            <li>
              カーエアコンクリーニングはアイドリング状態での施工となりますが、空ふかしなどの行為は行わないため、近隣住民への騒音はありません。また駐車場が濡れるなどもございません。
            </li>
          </ul>
        </p>
        <Button
          className={style['permission-popup-button']}
          onClick={onPopupClosed}
        >
          閉じる
        </Button>
      </div>
    </Popup>
  );
};
