import { Router } from 'framework7/types';
import {
  DealWarrantyTermsPage,
  TradeGuaranteePlanPage,
} from '@/components/pages';
// import { TradeBookOnlineConsultationPage } from '@/components/pages/TradeBookOnlineConsultation/TradeBookOnlineConsultationPage';
import { TradeAssuranceTermsPage } from '@/components/pages/TradeAssuranceTerms/TradeAssuranceTermsPage';
import { TradeConfirmApplicationDetailPage } from '@/components/pages/TradeConfirmApplicationDetail/TradeConfirmApplicationDetailPage';
import { TradeGuaranteePage } from '@/components/pages/TradeGuarantee/TradeGuaranteePage';
// import { TradeSurveyPage } from '@/components/pages/TradeSurvey/TradeSurveyPage';
import { TradeGuaranteeApplicationPage } from '@/components/pages/TradeGuaranteeApplication/TradeGuaranteeApplicationPage';
import { TradeGuaranteeDocumentsPage } from '@/components/pages/TradeGuaranteeDocuments/TradeGuaranteeDocumentsPage';
import { TradeGuaranteeDocumentsPreivewPage } from '@/components/pages/TradeGuaranteeDocumentsPreview/TradeGuaranteeDocumentsPreviewPage';
import { TradeGuaranteeUpdatePage } from '@/components/pages/TradeGuaranteeUpdate/TradeGuaranteeUpdatePage';
import { TradeReservationPage } from '@/components/pages/TradeReservation/TradeReservationPage';
import { TradeWarrantyDetailPage } from '@/components/pages/TradeWarrantyDetail/TradeWarrantyDetailPage';
import { paths } from '@/config/paths';

export const tradeRoutes: Router.RouteParameters[] = [
  {
    path: paths.tradeWarrantyDetail,
    name: '取引保証プランのご案内',
    component: TradeWarrantyDetailPage,
  },
  // {
  //   path: paths.tradeSurvey,
  //   name: 'アンケート',
  //   component: TradeSurveyPage,
  // },
  {
    path: paths.tradeConfirmAppDetail,
    name: '申請内容の確認',
    component: TradeConfirmApplicationDetailPage,
  },
  // {
  //   path: paths.tradeBookOnlineConsultation,
  //   name: '申請内容の確認',
  //   component: TradeBookOnlineConsultationPage,
  // },
  {
    path: paths.tradeGuarantee,
    name: '取引保証申請',
    component: TradeGuaranteePage,
  },
  {
    path: paths.tradeGuaranteeApplication,
    name: '取引保証詳細',
    component: TradeGuaranteeApplicationPage,
  },
  {
    path: paths.tradeGuaranteeUpdate,
    name: 'アンケート',
    component: TradeGuaranteeUpdatePage,
  },
  {
    path: paths.tradeReservation,
    name: '入庫予約',
    component: TradeReservationPage,
  },
  {
    path: paths.tradeAssuranceTerms,
    popup: {
      component: TradeAssuranceTermsPage,
    },
    options: {
      animate: true,
    },
    name: '取引保証プラン利用規約',
  },
  {
    path: paths.tradeGuaranteePlan,
    name: '取引保証プランのご案内',
    component: TradeGuaranteePlanPage,
  },
  {
    path: paths.tradeGuaranteeDocuments,
    name: '各種書類',
    component: TradeGuaranteeDocumentsPage,
  },
  {
    path: paths.tradeGuaranteeDocumentsPreview,
    name: '各種書類',
    component: TradeGuaranteeDocumentsPreivewPage,
  },
  {
    path: paths.dealWarrantyTerms,
    name: '取引保証利用規約',
    component: DealWarrantyTermsPage,
  },
];
