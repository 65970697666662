import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { getPayment } from '../UpdateWarrantyHelper';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { ChangePaymentInfo, PaymentTypeId } from '@/types/payment';
import {
  isBankOrPayEasy,
  switchPaymentIcon,
  switchPaymentMethodDesc,
} from '@/utils/payment';
import './UpdateWarrantyStep5Page.scss';

type UpdateWarrantyStep5PageProps = {
  f7router: Router.Router;
  warrantyNo: string;
  setStep: (n: number) => void;
};

export type NowPaymentInfo = {
  warranty_no: string;
  payment_type_id: number;
  payment_type_name: string;
  account_number: string;
  card_no: string;
  bank_name: string;
};

export const UpdateWarrantyStep5Page: FC<UpdateWarrantyStep5PageProps> = ({
  f7router,
  warrantyNo,
  setStep,
}) => {
  const billInformation = store.state.billsInfo;
  const [payment, setPayment] = useState(
    getPayment(billInformation, warrantyNo),
  );

  const handleSetPaymentInfo = (paymentInfo: ChangePaymentInfo) => {
    payment &&
      setPayment({
        ...payment,
        bank_name: paymentInfo.bankName,
        account_number: paymentInfo.accountNumber,
        card_no: paymentInfo.cardNo,
        payment_type_id: paymentInfo.paymentTypeId,
      });
  };

  const execEmit = (n: number) => {
    setStep(n);
    return;
  };

  const handleClickRegisterPaymentButton = () => {
    f7.view.main.router.navigate(
      paths.registerPayment('warranty', `${warrantyNo}`),
      {
        props: { handleSetPaymentInfo: handleSetPaymentInfo },
      },
    );
  };

  useEffect(() => {
    if (
      store.state.paymentTempData &&
      store.state.paymentTempData.isTransition === true
    ) {
      f7.view.main.router.navigate(
        paths.registerPayment('warranty', warrantyNo),
      );
    }
  }, [f7router, warrantyNo]);

  return (
    <div data-name="UpdateWarrantyStep5">
      <table className="menu-table u-margin-bottom-lg">
        <tr className="menu-tr-1">
          <td>●</td>
          <td>●</td>
          <td>●</td>
          <td>●</td>
          <td className="select-number">
            <div>5</div>
          </td>
          <td>●</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }} className="menu-table-item">
            プラン選択
          </td>
          <td className="menu-table-item">
            契約情報
            <br />
            確認
          </td>
          <td className="menu-table-item">
            重要事項
            <br />
            確認
          </td>
          <td className="menu-table-item">
            支払回数
            <br />
            選択
          </td>
          <td className="menu-falling-under menu-table-item">
            決済方法
            <br />
            選択
          </td>
          <td style={{ verticalAlign: 'top' }} className="menu-table-item">
            最終確認
          </td>
        </tr>
      </table>

      <div className="step-title u-margin-bottom-sm">決済方法のご確認</div>
      <div style={{ fontSize: '1.4rem' }}>
        変更がある場合は、「変更する」ボタンより変更してください。
      </div>

      <div>
        <div className="card">
          <div className="card-content">
            <div className="list no-ios-edges">
              <ul>
                <li className="plan-description">
                  <div className="item1">決済方法</div>
                  <div
                    className="card-title-btn"
                    onClick={handleClickRegisterPaymentButton}
                  >
                    変更する
                  </div>
                </li>
                {payment && (
                  <li className="list2">
                    <div className="pay-content display-flex">
                      <div className="u-font-bold">
                        <i
                          className={`${switchPaymentIcon(
                            payment?.payment_type_id as PaymentTypeId,
                          )} building-icon`}
                        />
                      </div>
                      <div className="u-padding-left-sm u-padding-right-sm">
                        <p>
                          {switchPaymentMethodDesc(
                            payment.payment_type_id,
                            payment.bank_name,
                          )}
                        </p>
                        <p>
                          {isBankOrPayEasy(payment.payment_type_id)
                            ? `口座番号：${payment.account_number}`
                            : `カード番号：${payment?.card_no}`}
                        </p>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        {payment ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <button
              className="btn"
              onClick={() => {
                // step6に遷移
                // eslint-disable-next-line no-magic-numbers
                execEmit(6);
              }}
            >
              次へ
            </button>
          </div>
        ) : (
          <button className="btn inactive-btn">次へ</button>
        )}
      </div>
    </div>
  );
};
