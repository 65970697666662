import { Router } from 'framework7/types';
import { FC } from 'react';
import { Customer } from './Customer/Customer';
import { store } from '@/config/store';
import { fillCustomerDefaults } from '@/utils/customer';

interface CustomerPageProps {
  f7router: Router.Router;
}
export const CustomerPage: FC<CustomerPageProps> = ({ f7router }) => {
  const customer = fillCustomerDefaults(store.state.customerInfo);
  return <Customer f7router={f7router} customerInfo={customer} />;
};
