import { FC } from 'react';
import { CarIconProps } from './CarIcon';

export const CarIcon7: FC<CarIconProps> = ({ color, reference }) => {
  return (
    <svg
      ref={reference}
      width="728"
      height="536"
      viewBox="0 0 91 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="0.75"
        width="88.5"
        height="65.5"
        rx="15.25"
        fill="#F3F5F6"
        stroke="#EFEFEF"
        strokeWidth="1.5"
      />
      <path
        d="M8.73862 38.906C9.49719 35.655 12.1947 33.2168 15.5056 32.7896L25.5 31.5L34.8575 24.9151C37.5533 23.0181 40.7691 22 44.0654 22H58.5158C60.4405 22 62.3007 22.6939 63.7552 23.9545L69 28.5L74.9782 31.0621C79.2912 32.9105 81.0945 38.0569 78.8787 42.1931L76.631 46.3887C75.9346 47.6885 74.5797 48.4998 73.1051 48.4998H11.5408C8.96448 48.4998 7.06003 46.0998 7.64546 43.5908L8.73862 38.906Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9388 33.5445C26.6067 33.7782 26.2227 33.9275 25.82 33.9794L15.8255 35.2691C13.5493 35.5628 11.6947 37.239 11.1732 39.474L10.0801 44.1589C9.86053 45.0998 10.5747 45.9998 11.5408 45.9998H73.1051C73.658 45.9998 74.1662 45.6955 74.4273 45.2081L76.675 41.0125C78.1984 38.1689 76.9586 34.6307 73.9934 33.3599L68.0152 30.7979C67.7779 30.6962 67.5578 30.5583 67.3627 30.3892L62.1179 25.8437C61.1179 24.9771 59.839 24.5 58.5158 24.5H44.0654C41.2842 24.5 38.5708 25.359 36.2963 26.9596L26.9388 33.5445ZM69 28.5L63.7552 23.9545C62.3007 22.6939 60.4405 22 58.5158 22H44.0654C40.7691 22 37.5533 23.0181 34.8575 24.9151L25.5 31.5L15.5056 32.7896C12.1947 33.2168 9.49719 35.655 8.73862 38.906L7.64546 43.5908C7.06003 46.0998 8.96448 48.4998 11.5408 48.4998H73.1051C74.5797 48.4998 75.9346 47.6885 76.631 46.3887L78.8787 42.1931C81.0945 38.0569 79.2912 32.9105 74.9782 31.0621L69 28.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3633 32.7267C26.164 32.8669 25.9336 32.9565 25.692 32.9877L15.6975 34.2773C13.0075 34.6244 10.8157 36.6054 10.1994 39.2468L9.10622 43.9317C8.74033 45.4998 9.93061 46.9998 11.5408 46.9998H73.1051C74.0267 46.9998 74.8736 46.4927 75.3088 45.6803L77.5565 41.4847C79.3568 38.1241 77.8916 33.9426 74.3873 32.4408L68.4091 29.8787C68.2668 29.8177 68.1347 29.735 68.0176 29.6335L62.7728 25.088C61.591 24.0638 60.0796 23.5 58.5158 23.5H44.0654C41.0781 23.5 38.1638 24.4227 35.7208 26.1418L26.3633 32.7267ZM69 28.5L63.7552 23.9545C62.3007 22.6939 60.4405 22 58.5158 22H44.0654C40.7691 22 37.5533 23.0181 34.8575 24.9151L25.5 31.5L15.5056 32.7896C12.1947 33.2168 9.49719 35.655 8.73862 38.906L7.64546 43.5908C7.06003 46.0998 8.96448 48.4998 11.5408 48.4998H73.1051C74.5797 48.4998 75.9346 47.6885 76.631 46.3887L78.8787 42.1931C81.0945 38.0569 79.2912 32.9105 74.9782 31.0621L69 28.5Z"
        fill="#323232"
      />
      <path
        d="M32.5 45C32.5 49.1421 29.1421 52.5 25 52.5C20.8579 52.5 17.5 49.1421 17.5 45C17.5 40.8579 20.8579 37.5 25 37.5C29.1421 37.5 32.5 40.8579 32.5 45Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 51C28.3137 51 31 48.3137 31 45C31 41.6863 28.3137 39 25 39C21.6863 39 19 41.6863 19 45C19 48.3137 21.6863 51 25 51ZM25 52.5C29.1421 52.5 32.5 49.1421 32.5 45C32.5 40.8579 29.1421 37.5 25 37.5C20.8579 37.5 17.5 40.8579 17.5 45C17.5 49.1421 20.8579 52.5 25 52.5Z"
        fill="#323232"
      />
      <path
        d="M73.5 45C73.5 49.1421 70.1421 52.5 66 52.5C61.8579 52.5 58.5 49.1421 58.5 45C58.5 40.8579 61.8579 37.5 66 37.5C70.1421 37.5 73.5 40.8579 73.5 45Z"
        fill="#C9C9C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 51C69.3137 51 72 48.3137 72 45C72 41.6863 69.3137 39 66 39C62.6863 39 60 41.6863 60 45C60 48.3137 62.6863 51 66 51ZM66 52.5C70.1421 52.5 73.5 49.1421 73.5 45C73.5 40.8579 70.1421 37.5 66 37.5C61.8579 37.5 58.5 40.8579 58.5 45C58.5 49.1421 61.8579 52.5 66 52.5Z"
        fill="#323232"
      />
      <path
        d="M28.5 45C28.5 46.933 26.933 48.5 25 48.5C23.067 48.5 21.5 46.933 21.5 45C21.5 43.067 23.067 41.5 25 41.5C26.933 41.5 28.5 43.067 28.5 45Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 47C26.1046 47 27 46.1046 27 45C27 43.8954 26.1046 43 25 43C23.8954 43 23 43.8954 23 45C23 46.1046 23.8954 47 25 47ZM25 48.5C26.933 48.5 28.5 46.933 28.5 45C28.5 43.067 26.933 41.5 25 41.5C23.067 41.5 21.5 43.067 21.5 45C21.5 46.933 23.067 48.5 25 48.5Z"
        fill="#323232"
      />
      <path
        d="M69.5 45C69.5 46.933 67.933 48.5 66 48.5C64.067 48.5 62.5 46.933 62.5 45C62.5 43.067 64.067 41.5 66 41.5C67.933 41.5 69.5 43.067 69.5 45Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 47C67.1046 47 68 46.1046 68 45C68 43.8954 67.1046 43 66 43C64.8954 43 64 43.8954 64 45C64 46.1046 64.8954 47 66 47ZM66 48.5C67.933 48.5 69.5 46.933 69.5 45C69.5 43.067 67.933 41.5 66 41.5C64.067 41.5 62.5 43.067 62.5 45C62.5 46.933 64.067 48.5 66 48.5Z"
        fill="#323232"
      />
      <path
        d="M34.5 44.25C34.5 43.8358 34.8358 43.5 35.25 43.5H47.75C48.1642 43.5 48.5 43.8358 48.5 44.25C48.5 44.6642 48.1642 45 47.75 45H35.25C34.8358 45 34.5 44.6642 34.5 44.25Z"
        fill="#323232"
      />
      <path
        d="M36.8835 27.5596C38.2583 26.5465 39.9213 26 41.6291 26H60.0858C60.351 26 60.6054 26.1054 60.7929 26.2929L65.7929 31.2929C66.4229 31.9229 65.9767 33 65.0858 33H32.5429C31.5776 33 31.1726 31.7676 31.9497 31.1949L36.8835 27.5596Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.2094 27.5H41.7204C40.3146 27.5 38.9458 27.944 37.814 28.7671L34.0565 31.5H64.2619L60.2094 27.5ZM41.7204 26C39.9902 26 38.3054 26.5465 36.9126 27.5596L31.9141 31.1949C31.1268 31.7676 31.5371 33 32.5151 33H65.4848C66.3874 33 66.8395 31.9229 66.2012 31.2929L61.1356 26.2929C60.9456 26.1054 60.688 26 60.4193 26H41.7204Z"
        fill="#323232"
      />
    </svg>
  );
};
