import { Router } from 'framework7/types';
import {
  BillingScheduleDetailPage,
  DeletePaymentDisablePage,
  DeletePaymentPage,
  PaymentsHistory2Page,
  PaymentsHistoryPage,
  PaymentsPage,
  PaymentsSchedule2Page,
  PaymentsSchedulePage,
  RegisterBankPage,
  RegisterPaymentListPage,
  RegisterPaymentPage,
} from '@/components/pages';
import { PaymentReceiptPage } from '@/components/pages/PaymentReceipt/PaymentReceiptPage';
import { ScheduledRefundDetailPage } from '@/components/pages/ScheduledRefundDetail/ScheduledRefundDetailPage';
import { paths } from '@/config/paths';

export const paymentRoutes: Router.RouteParameters[] = [
  {
    path: paths.registerPayment(':type', ':no'),
    popup: {
      component: RegisterPaymentPage,
    },
    options: {
      animate: true,
    },
    name: '決済方法の変更',
  },
  {
    path: paths.registerPaymentList,
    popup: {
      component: RegisterPaymentListPage,
    },
    options: {
      animate: true,
    },
    name: '決済方法の登録',
  },
  {
    path: '/register_bank/',
    popup: {
      component: RegisterBankPage,
    },
    options: {
      animate: true,
    },
    name: '口座登録',
  },
  {
    path: paths.payments,
    component: PaymentsPage,
    name: '登録済みの決済方法',
  },
  {
    path: paths.paymentsSchedule,
    component: PaymentsSchedulePage,
    name: '請求予定',
  },
  {
    path: paths.payments2Schedule(':warrantyNo'),
    component: PaymentsSchedule2Page,
    name: '請求予定ー保証管理画面から',
  },
  {
    path: paths.scheduledRefundDetail(':warranty_no', ':no'),
    component: ScheduledRefundDetailPage,
    name: '返金予定詳細',
  },
  {
    path: paths.billingScheduleDetail,
    component: BillingScheduleDetailPage,
    name: '請求予定詳細',
  },
  {
    path: paths.paymentsHistory(':warranty_no'),
    component: PaymentsHistoryPage,
    name: '支払履歴',
  },
  {
    path: paths.payments2History,
    component: PaymentsHistory2Page,
    name: '支払履歴―アカウント画面から',
  },
  {
    path: paths.paymentReceipt,
    component: PaymentReceiptPage,
    name: '領収書',
  },
  {
    path: paths.deletePayments(
      ':paymentMethodId',
      ':paymentTypeId',
      ':accountOrCardNumber',
    ),
    popup: {
      component: DeletePaymentPage,
    },
    options: {
      animate: true,
    },
    name: '支払方法の削除',
  },
  {
    path: paths.deletePaymentsDisabled,
    popup: {
      component: DeletePaymentDisablePage,
    },
    options: {
      animate: true,
    },
    name: '支払方法の削除不可',
  },
];
