import { Block, List, ListItem, Navbar, Page } from 'framework7-react';
import { CommonpageTitle } from '@/components/uiParts/CommonpageTitle/CommonpageTitle';
import { MenuFooter } from '@/components/uiParts/MenuFooter/MenuFooter';
import './NoticeDetailDummy1Styles.scss';

export const NoticeDetailDummy1 = () => {
  const noticeInfo = {
    notice_title: '保証料の切り替えについて',
    notice_date: '2021-10-11',
    notice_content:
      'お客様は月々保証料をお支払いいただいてる車両を下取りされています。\n旧車両と新車両の保証の請求額及び、切り替えのタイミングについて説明させていただきます。',
  };
  const ruleInfo = {
    rule_title: '保証料の切り替えルール',
    // 新しい車の保証料は、ご納車月より変更となります
    rule_content: `新しい車の保証料はご契約いただいた月から変更となります。\n\nしかし請求システムの都合上、<b>お客様への請求が切り替わるのが納車月の翌月から</b>となります。\n\n請求切り替えまでの金額の差分は、<b>納車月の翌月に精算</b>されます。`,
    rule_remark: 'お客様は納車月の翌月の保証料に<b>4,000円が加算</b>されます。',
  };
  const scheduleListInfo = {
    schedule_title: '請求金額の予定表',
    schedule_content:
      '上記のルールを適用すると、お客様への月々の請求は下記のようになります。\n\n※納車月に変更があった場合は、月々の請求額が変更になります。',
    schedule_list: [
      { deal_date: '2022-4', amount: 5000 },
      { deal_date: '2022-5', remark: '納車', amount: 5000 },
      { deal_date: '2022-6', amount: 11000 },
      { deal_date: '2022-7', amount: 7000 },
      { deal_date: '2022-8', amount: 7000 },
      { deal_date: '2022-9', amount: 7000 },
      { deal_date: '2022-10', amount: 7000 },
      { deal_date: '2022-11', amount: 7000 },
      { deal_date: '2022-12', amount: 7000 },
      { deal_date: '2023-1', amount: 7000 },
      { deal_date: '2023-2', amount: 7000 },
      { deal_date: '2023-3', amount: 7000 },
    ],
  };
  return (
    <Page name="NoticeDetail">
      <Navbar className="car-home__icon">
        <CommonpageTitle navTitle="" leftIcon="left" helpIcon={true} />
      </Navbar>
      {/* <div className="home__wrapper"> */}
      <div className="home__wrapper-sub">
        <div>お知らせ</div>
      </div>
      <Block
        style={{
          marginTop: '61px',
          lineHeight: '2rem',
          whiteSpace: 'pre-wrap',
        }}
      >
        <div className="info-card info-card-elevated">
          <h2 className="notice-title">{noticeInfo.notice_title}</h2>
          <p className="notice-date">{noticeInfo.notice_date}</p>
          <div className="notice-content">{noticeInfo.notice_content}</div>
        </div>
        <div className="info-card info-card-elevated">
          <h2 className="notice-title">{ruleInfo.rule_title}</h2>
          <p />
          <div
            className="notice-content"
            dangerouslySetInnerHTML={{ __html: ruleInfo.rule_content }}
          />
          <div className="rule-container u-margin-top-sm u-margin-bottom-xs">
            <div className="item-container">
              <div />
              <div>納車月前月</div>
              <div>
                納車月
                <span>
                  <i
                    className="icon-key u-font-bold"
                    style={{ color: '#04ad5', fontSize: '2rem' }}
                  />
                </span>
              </div>
              <div>納車月翌月</div>
            </div>
            <div className="item-container">
              <div>
                <span>下取り車両の保証の請求</span>
                <span>請求</span>
              </div>
              <div>5,000円</div>
              <div>5,000円</div>
              <div>0円</div>
            </div>
            <div className="item-container">
              <div>
                <span>新車両の保証の請求</span>
                <span>請求なし</span>
              </div>
              <div>7,000円</div>
              <div>7,000円</div>
              <div>7,000円</div>
            </div>
            <div className="item-container">
              <div>
                <span>2つの差分</span>
                <span>お店で一時負担</span>
              </div>
              <div>2,000円</div>
              <div>2,000円</div>
              <div>+ 一時負担分 4,000円</div>
            </div>
          </div>
          <div
            className="notice-container"
            dangerouslySetInnerHTML={{ __html: ruleInfo.rule_remark }}
          />
        </div>
        <div className="info-card info-card-elevated">
          <h2 className="notice-title">{scheduleListInfo.schedule_title}</h2>
          <p />
          <div className="notice-content">
            {scheduleListInfo.schedule_content}
          </div>
          <List simpleList className="mypage-list">
            {scheduleListInfo.schedule_list.map((item, index) => (
              <ListItem className="schedule-list" key={index}>
                <div className="item-desc">
                  <span
                    className="description u-font-bold"
                    style={{ marginTop: '0', fontSize: '1.6rem' }}
                  >
                    {item.deal_date}
                  </span>
                  {item.remark && (
                    <span style={{ fontWeight: 'bold', fontSize: '1.6rem' }}>
                      {item.remark}
                    </span>
                  )}
                </div>
                <div
                  className="item-desc"
                  style={{ fontSize: '1.6rem', fontWeight: 'normal' }}
                >
                  <span>{item.amount}円</span>
                  <span>
                    <i className="icon-info" />
                  </span>
                </div>
              </ListItem>
            ))}
          </List>
        </div>
      </Block>
      {/* </div> */}
      <MenuFooter />
    </Page>
  );
};
