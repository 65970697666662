import style from './TradeGuaranteeDetailStyle.module.css';
import { store } from '@/config/store';
import { GetDealWarrantyInfoApiResponse } from '@/types/api/warrantyApi';
import { formatDateWithDay } from '@/utils/date';
interface LineItemProps {
  label: string;
  value: string;
}

interface TradeGuaranteeDetailProps {
  data?: GetDealWarrantyInfoApiResponse['data'];
}

export const TradeGuaranteeDetail = ({ data }: TradeGuaranteeDetailProps) => {
  const { hiragana, registry_no, car_nm } = store.state.carItemInfo;

  const dataDetailIssues = data?.deal_warranties?.map((el) => {
    return [
      { label: 'トラブルの種類', value: el.issue_category_name },
      {
        label: 'トラブル内容',
        value: el.issue_detail,
      },
      {
        label: '対象車両',
        value: [hiragana, registry_no, car_nm]
          .filter((value) => !!value)
          .join(' '),
      },
      {
        label: 'トラブル発生日時',
        value: formatDateWithDay(new Date(el.issue_found_date)),
      },
    ];
  });

  return (
    <div className={`${style['block']}`}>
      <h4 className={style['header']}>取引保証申請内容</h4>
      {dataDetailIssues?.map((issue, index) => {
        return (
          <div
            className={`${style['block-item']} ${
              index === dataDetailIssues?.length - 1 ? style['no-border'] : ''
            }`}
            key={index}
          >
            <p className={style['title']}>トラブルの詳細</p>
            {issue.map((item, index) => (
              <LineItem
                label={item.label}
                value={item.value}
                key={`detail-problem-${index}`}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

const LineItem = (props: LineItemProps) => {
  const { label, value } = props;

  return (
    <div className={`${style['content-line']}`}>
      <p className={style['text-label']}>{label}</p>
      <p className={style['value']}>{value}</p>
    </div>
  );
};
