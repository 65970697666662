import { Router } from 'framework7/types';
import { DriveInsurancePage } from '@/components/pages';

export const insuranceRoutes: Router.RouteParameters[] = [
  {
    path: '/drive_insurance/',
    component: DriveInsurancePage,
    name: '保険',
  },
  //   {
  //     path: '/insurance_management/',
  //     component: () DriveInsurance/InsuranceManagementPage,
  //     name: '保険管理'
  //   },
  //   {
  //     path: '/totalScore_helpPage/',
  //     popup: {
  //       component: () =DriveHistory/TotalScoreHelpPagePage
  //     },
  //     options: {
  //       animate: true
  //     },
  //     name: '累計スコア判定画面の使い方'
  //   },
  //   {
  //     path: '/driving_score_check/',
  //     popup: {
  //         component: DrivingScoreCheckPage,
  //     },
  //     options: {
  //         animate: true,
  //     },
  //     name: '累計スコア確認',
  //   },
  //   {
  //     path: '/ad_telemileage/',
  //     component: DriveInsurance/ADTelemileagePage,
  //     name: 'ADテレマイレージ'
  //   },
  //   {
  //     path: '/drive_measurement/',
  //     component: () DriveInsurance/DriveMeasurementPage,
  //     name: '運転特性計測の進捗'
  //   },
  //   {
  //     path: '/insurance_detail/',
  //     component: DriveInsurance/InsuranceDetailPage,
  //     name: '相手への賠償（対人）'
  //   }
];
