import { Button, List, ListItem, Segmented } from 'framework7-react';
import perse from 'html-react-parser';
import { FC, useEffect } from 'react';
import {
  createPrepaidHistory,
  filteredInquiryList,
} from '../prepaidHistoryHelper';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { HousePrepaidPaymentHistory, Prepaid } from '@/types/api/customerApi';
import { hyphenDate } from '@/utils/date';
import './PrepaidHistoryStyle.scss';

type PrepaidHistoryProps = {
  selected: string;
  prepaid: Required<Prepaid> | undefined;
};

export const PrepaidHistory: FC<PrepaidHistoryProps> = ({
  selected,
  prepaid,
}) => {
  const selectedInquiryFunction = () => {
    const InquiryAreaElement = document.getElementById('InquiryArea');
    const selectedInquiryElement = document.getElementById('selectedInquiry');
    const HistoryAreaElement = document.getElementById('HistoryArea');
    const selectedHistoryElement = document.getElementById('selectedHistory');

    if (
      InquiryAreaElement &&
      selectedInquiryElement &&
      HistoryAreaElement &&
      selectedHistoryElement
    ) {
      InquiryAreaElement.style.display = 'block';
      if (!selectedInquiryElement.classList.contains('button-active')) {
        selectedInquiryElement.classList.add('button-active');
      }
      HistoryAreaElement.style.display = 'none';
      if (selectedHistoryElement.classList.contains('button-active')) {
        selectedHistoryElement.classList.remove('button-active');
      }
    }
  };

  const selectedHistoryFunction = () => {
    const InquiryAreaElement = document.getElementById('InquiryArea');
    const selectedInquiryElement = document.getElementById('selectedInquiry');
    const HistoryAreaElement = document.getElementById('HistoryArea');
    const selectedHistoryElement = document.getElementById('selectedHistory');

    if (
      InquiryAreaElement &&
      selectedInquiryElement &&
      HistoryAreaElement &&
      selectedHistoryElement
    ) {
      InquiryAreaElement.style.display = 'none';
      if (selectedInquiryElement.classList.contains('button-active')) {
        selectedInquiryElement.classList.remove('button-active');
      }
      HistoryAreaElement.style.display = 'block';
      if (!selectedHistoryElement.classList.contains('button-active')) {
        selectedHistoryElement.classList.add('button-active');
      }
    }
  };

  useEffect(() => {
    switch (selected) {
      case 'history':
        selectedHistoryFunction();
        break;
      case 'inquery':
        selectedInquiryFunction();
      default:
        break;
    }
  }, [selected]);

  const paymentHistories = prepaid?.t_houseprepaid_payment_histories?.sort(
    (a: any, b: any) => {
      // eslint-disable-next-line no-magic-numbers
      return new Date(a.deal_date) < new Date(b.deal_date) ? -1 : 1;
    },
  );
  const balanceTotal = prepaid?.balance_total ?? 0;
  const prepaidHistories = paymentHistories;
  const prepaidLatestHistories = prepaidHistories?.slice(-1)[0] ?? null;

  const basicList = filteredInquiryList(
    prepaidHistories,
    prepaidLatestHistories,
    'basic',
  );

  const bonusList = filteredInquiryList(
    prepaidHistories,
    prepaidLatestHistories,
    'bonus',
  );

  const couponList = filteredInquiryList(
    prepaidHistories,
    prepaidLatestHistories,
    'coupon',
  );

  const inquiryList = [
    {
      id: 1,
      title: '基本マネー',
      icon: 'icon-pay-blue',
      desc: `月次積立/スポットチャージの総額です。<br/>
      取引の詳細は残高履歴からご確認ください。`,
      limit: basicList?.limit,
      amountSign: basicList?.plusAndMinusSign,
      amount: basicList?.amount ?? 0,
      plusAndMinus: basicList?.plusAndMinus,
    },
    {
      id: 2,
      title: 'ボーナスマネー',
      icon: 'icon-bonus-blue',
      desc: '会員ランクによって定められたボーナスチャージ率で還元されたボーナス金額の総額です',
      limit: bonusList?.limit,
      amountSign: bonusList?.plusAndMinusSign,
      amount: bonusList?.amount ?? 0,
      plusAndMinus: bonusList?.plusAndMinus,
    },
    {
      id: 3,
      title: 'クーポン',
      icon: 'icon-coupon-blue',
      desc: 'クーポンによってチャージされた金額です。',
      limit: couponList?.limit,
      amountSign: couponList?.plusAndMinusSign,
      amount: couponList?.amount ?? 0,
      plusAndMinus: couponList?.plusAndMinus,
    },
  ];

  const historyList = createPrepaidHistory(prepaidHistories);
  return (
    <PageTemplate
      pageName="PrepaidHistory"
      title="残高照会/履歴"
      showNavBack={true}
    >
      <div className="home__wrapper">
        <RoundSheet>
          <div className="sheet-content">
            {/* 残高 */}
            <div className="balance-top">
              <h3>残高</h3>
              <div className="balance-total">
                {balanceTotal.toLocaleString()}
                <span className="yen">円</span>
              </div>
            </div>
            {/* tab */}
            <div>
              <Segmented className="buttons" raised round strong tag="p">
                <Button
                  id="selectedInquiry"
                  onClick={selectedInquiryFunction}
                  className="button button-round button-active"
                >
                  残高照会
                </Button>
                <Button
                  id="selectedHistory"
                  onClick={selectedHistoryFunction}
                  className="button button-round"
                >
                  残高履歴
                </Button>
              </Segmented>
            </div>
            {/* 残高照会 */}
            <div id="InquiryArea">
              {inquiryList.map((item, index) => (
                <List simple-list className="mypage-list" key={index}>
                  <ListItem className="InquiryArea-list-item">
                    <div className="item-desc">
                      <div className="item-title">
                        <i className={item.icon} />
                        {item.title}
                      </div>
                      <p className="desc">{perse(item.desc)}</p>
                    </div>
                    <div
                      className={`amount-wrapper ${
                        item.amount <= 0 ? 'amount-zero' : ''
                      }`}
                    >
                      {item.amount > 0 && <p>有効期限：{item.limit}</p>}
                      <span className={`${item.plusAndMinus}`}>
                        {item.amount?.toLocaleString()} 円
                      </span>
                    </div>
                  </ListItem>
                </List>
              ))}
            </div>
            {/* 残高履歴  */}
            <div id="HistoryArea">
              {historyList?.map(
                (item: HousePrepaidPaymentHistory, index2: number) => (
                  <List simple-list className="mypage-list" key={index2}>
                    {item.oneMonthflag && (
                      <div
                        className="u-margin-top-lg u-padding-bottom-sm u-font-bold u-font-size-xxl"
                        style={{ borderBottom: '1px solid #efefef' }}
                      >
                        {item.oneMonthflag}
                      </div>
                    )}
                    <ListItem className="HistoryArea-list">
                      <div className="item-term">
                        {item.iconType && (
                          <i
                            style={{ fontSize: '2.4rem' }}
                            className={`icon-middle icon-${item.iconType} `}
                          />
                        )}
                      </div>
                      <div className="item-desc">
                        {item.title && (
                          <span
                            className="u-font-bold"
                            style={{ display: 'block', fontSize: '1.5rem' }}
                          >
                            {item.title}
                          </span>
                        )}

                        <span
                          className="description"
                          style={{ display: 'block', paddingTop: '.5rem' }}
                        >
                          {hyphenDate(item.deal_date ?? '')}
                        </span>
                      </div>
                      <div
                        className="item-desc u-font-bold"
                        style={{ fontSize: '1.4rem' }}
                      >
                        <span className={`${item.plusAndMinus}`}>
                          {item.amountSign}
                          {item.amount?.toLocaleString()} 円
                        </span>
                      </div>
                      <div className="item-term">
                        {/* <i className="icon-middle icon-right"></i>  */}
                      </div>
                    </ListItem>
                  </List>
                ),
              )}
            </div>
          </div>
        </RoundSheet>
      </div>
    </PageTemplate>
  );
};
