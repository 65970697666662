import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './SelfScanStyle.module.css';
import selfScanImage from '@/assets/images/selfScan.png';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { f7CustomBack } from '@/utils/utils';

export const SelfScan: FC = () => {
  const handleClickNavigationSetup = () => {
    f7CustomBack(['#SelfScan']);
    f7.view.main.router.navigate(paths.selfScanSteps, { animate: true });
  };
  return (
    <PopupPageTemplate id="SelfScan" pageName="SelfScan" title="車両状態診断">
      <div style={{ width: '100%', height: '24px' }} />
      <div className={style.image}>
        <img width={'100%'} src={selfScanImage} alt="" />
      </div>

      <div style={{ width: '100%', height: '16px' }} />
      <h1 className={style.heading}>
        {/* <span className={style['heading-sub']}>
          点検時期は、2023年2月です。
        </span> */}
        お車のエンジンをかけて行う
        <br />
        必要があります。
      </h1>
      <br />
      <span className={style.span}>
        ※スキャン完了まで1分〜最大3分程度かかります。
      </span>

      <div className={style['button-wrap']}>
        <Button
          className={style.button}
          text="はじめる"
          handleClickAction={handleClickNavigationSetup}
        />
      </div>
    </PopupPageTemplate>
  );
};
