import { Page } from 'framework7-react';
import { FC } from 'react';
import './SuccessDialog.scss';

export const SuccessDialog: FC = () => {
  return (
    <Page name="SuccessDialog">
      <div>
        <div className="container">
          <i className="icon-success" />
          <h3>ご契約ありがとうございました。</h3>
          <p>タブレット（パソコン）の画面をご覧ください。</p>
        </div>
      </div>
    </Page>
  );
};
