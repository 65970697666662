import { FC } from 'react';
import { HistoryListItem } from '../HistoryListItem/HistoryListItem';
import { DriveHistoryItem } from './DriveHistoryItem';
import style from './DriveHistoryItemStyle.module.scss';
import { Paper } from '@/components/uiParts/Card';
import { HistoryData } from '@/types/driveHistory';
import { hyphenToDay } from '@/utils/date';

interface DriveHistoryProps {
  historyList: HistoryData[];
  btnName?: string | JSX.Element[];
  paths?: string;
  endIconBtn?: string;
  end?: boolean;
  filterData?: boolean;
  isAdContractor?: boolean;
}

export const DriveHistoryCard: FC<DriveHistoryProps> = ({
  historyList,
  btnName = '',
  paths,
  endIconBtn,
  isAdContractor,
}) => {
  if (historyList?.length === 0) {
    return null;
  }
  return (
    <Paper
      cardHeader={isAdContractor ? '' : '運転履歴'}
      btnName={btnName}
      path={paths}
      contentCN={style['content_wrapper']}
      endIconBtn={endIconBtn}
    >
      {historyList?.map((item, index: number) => (
        <div key={index} className={style['history-list_item']}>
          {item.oneDayFlag && (
            <div className={style['drive-history-date-title']}>
              {isAdContractor
                ? hyphenToDay(
                    (item.diagnosis_date ?? '').substring(0, 10),
                    true,
                  )
                : item.oneDayFlag}
            </div>
          )}
          {isAdContractor ? (
            <HistoryListItem
              driving_duration_start={item.driving_duration_start ?? ''}
              driving_duration_end={item.driving_duration_end ?? ''}
              driving_time={item.driving_time ?? 0}
              hours={item.hours ?? 0}
              minutes={item.minutes ?? 0}
              driving_distance={item.driving_distance ?? 0}
              fuel_efficiency={item.fuel_efficiency ?? 0}
              phyd_score_level={item.phyd_score_level ?? ''}
              status={item.status ?? ''}
              id={item.id ?? 0}
            />
          ) : (
            <DriveHistoryItem
              driving_duration_start={item.driving_duration_start ?? ''}
              driving_duration_end={item.driving_duration_end ?? ''}
              driving_time={item.driving_time ?? 0}
              hours={item.hours ?? 0}
              minutes={item.minutes ?? 0}
              driving_distance={item.driving_distance ?? 0}
              fuel_efficiency={item.fuel_efficiency ?? 0}
              phyd_score_level={item.phyd_score_level ?? ''}
              status={item.status ?? ''}
              id={item.id ?? 0}
            />
          )}
        </div>
      ))}
    </Paper>
  );
};
