import { FC, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Checkbox } from '../Inputs/Checkbox';
import { Radio } from '../Inputs/Radio';
import style from './CarWashConditionerStep3Style.module.css';
import { PopupAbout } from './PopupAbout';
import { Button } from '@/components/uiParts/Button';
import { marginTypes, securitySystems } from '@/consts/carWash';
import {
  Step3FormData,
  useCarWashConditionerStep3,
} from '@/hooks/api/carWash/useCarWashConditionerStep3';
import { CourseItem } from '@/types/api/reservationApi';

interface Props {
  step3Data: Step3FormData;
  courseItem: CourseItem | undefined;
  onSubmit: (data: Step3FormData) => void;
  onClickCancel: () => void;
}

export const CarWashConditionerStep3: FC<Props> = ({
  courseItem,
  step3Data,
  onSubmit,
  onClickCancel,
}) => {
  const { formMethods } = useCarWashConditionerStep3(step3Data);
  const {
    watch,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid, errors },
  } = formMethods;
  const [opened, setOpened] = useState(false);
  const marginType = watch('workspace.marginType');
  const securitySystem = watch('workspace.securitySystem');
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PopupAbout
          courseItem={courseItem}
          opened={opened}
          onPopupClosed={() => setOpened(false)}
        />
        <div className={style['container']}>
          <h2 className={style['input-label']}>
            作業環境について教えてください
            <span
              className={`${style['input-label-tips']} ${style['required']}`}
            >
              必須
            </span>
          </h2>
          <div className={`${style['input-group']}`}>
            <h2 className={style['input-label']}>
              お車の前後左右に50~60cmドアを全開できる間隔はございますか？
            </h2>
            <div
              className={`${style['input-radio']} ${style['no-align-items']}`}
            >
              <Radio
                id="margin-yes"
                name="workspace.marginType"
                checked={marginType === marginTypes.YES}
                value={marginTypes.YES}
                className={style['input-radio']}
              >
                あり
              </Radio>
            </div>
            <div
              className={`${style['input-radio']} ${style['no-align-items']}`}
            >
              <Radio
                id="margin-none"
                name="workspace.marginType"
                checked={marginType === marginTypes.NONE}
                value={marginTypes.NONE}
                className={style['input-radio']}
              >
                <div>
                  <p>なし</p>
                  <p className={style['sub-text']}>
                    なしの場合は予約ができない可能性があります。
                  </p>
                </div>
              </Radio>
            </div>
            <div
              className={`${style['input-radio']} ${style['no-align-items']}`}
            >
              <Radio
                id="margin-narrow"
                name="workspace.marginType"
                checked={marginType === marginTypes.NARROW_GAP}
                value={marginTypes.NARROW_GAP}
                className={style['input-radio']}
              >
                <div>
                  <p>間隔はあるがもう少し狭い</p>
                  <p className={style['sub-text']}>
                    間隔幅によっては作業できない部分が出る可能性があります。
                  </p>
                </div>
              </Radio>
            </div>
          </div>
          <div className={`${style['input-group']}`}>
            <h2 className={style['input-label']}>
              後付けのセキュリティシステムが付いていますか？
            </h2>
            <div
              className={`${style['input-radio']} ${style['no-align-items']}`}
            >
              <Radio
                id="security-yes"
                name="workspace.securitySystem"
                checked={securitySystem === securitySystems.YES}
                value={securitySystems.YES}
                className={style['input-radio']}
              >
                <div>
                  <p>付いている</p>
                  <p className={style['sub-text']}>
                    ご予約当日は念のため解除をお願いいたします。
                  </p>
                </div>
              </Radio>
            </div>
            <div
              className={`${style['input-radio']} ${style['no-align-items']}`}
            >
              <Radio
                id="security-no"
                name="workspace.securitySystem"
                checked={securitySystem === securitySystems.NO}
                value={securitySystems.NO}
                className={style['input-radio']}
              >
                付いていない
              </Radio>
            </div>
            <div
              className={`${style['input-radio']} ${style['no-align-items']}`}
            >
              <Radio
                id="security-unknown"
                name="workspace.securitySystem"
                checked={securitySystem === securitySystems.UNKNOWN}
                value={securitySystems.UNKNOWN}
                className={style['input-radio']}
              >
                わからない
              </Radio>
            </div>
          </div>
          <div className={`${style['input-group']}`}>
            <div className={`${style['input-group']}`}>
              <h2 className={style['input-label']}>
                作業時に車の鍵をお預かりいたします。またエンジンをかけての作業となります。
              </h2>
              <Checkbox
                value={true}
                name="engineStart"
                isBoolean
                className="margin-top"
              >
                確認しました
              </Checkbox>
            </div>
          </div>
          <div className={`${style['input-group']}`}>
            <div className={`${style['input-group']}`}>
              <h2 className={style['input-label']}>
                場所や日時によっては施工日の回答に時間を要する場合やスタッフから連絡が入る場合がございます。
              </h2>
              <p className={style['sub-text']}>
                ※土日・祝日は翌平日以降に日程調整となります。
              </p>
              <Checkbox
                value={true}
                name="dateAnnotation"
                isBoolean
                className="margin-top"
              >
                確認しました
              </Checkbox>
            </div>
          </div>
          <div className={`${style['input-group']}`}>
            <div className={`${style['input-group']}`}>
              <h2 className={style['input-label']}>
                作業日が悪天候で作業が難しい場合、施工業者から連絡をいたします。施工業者へお客様の連絡先を提供させていただきます。
              </h2>
              <Checkbox
                value={true}
                name="badWeather"
                isBoolean
                className="margin-top"
              >
                確認しました
              </Checkbox>
            </div>
          </div>
          <div className={`${style['input-group']}`}>
            <div className={`${style['input-group']}`}>
              <h2 className={style['input-label']}>
                ご予約後キャンセルする場合、以下のキャンセル料がかかります。
                <br />
                <span
                  className={style['input-link']}
                  onClick={() => setOpened(true)}
                >
                  キャンセル料について
                </span>
              </h2>
              <Checkbox
                value={true}
                name="cancelFee"
                isBoolean
                className="margin-top"
              >
                確認しました
              </Checkbox>
            </div>
          </div>
          <div className={style['button-group']}>
            <Button text="次へ" type="submit" disabled={!isValid} />
            <Button
              style={{
                color: '#232323',
                backgroundColor: '#fff',
                marginTop: '8px',
              }}
              text="キャンセル"
              onClick={onClickCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
