import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { useState } from 'react';
import { registerInquiryEndApi } from '@/api/inquiryApi';
import { paths } from '@/config/paths';
import { RegisterInquiryEndParams } from '@/types/api/queryApi';
export const useRegisterInqueryEnd = () => {
  const [error, setError] = useState<AxiosError>();

  const registerInqueryEnd = async (
    params: RegisterInquiryEndParams,
    f7router: Router.Router,
  ) => {
    try {
      f7.preloader.show();
      const res = await registerInquiryEndApi(params);
      if (res.data.success) {
        f7.view.main.router.navigate(paths.news, { animate: false });
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    registerInqueryEnd,
    error,
  };
};
