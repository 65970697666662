export const callCenterDivision = {
  UNUSED: '#01#', // 未使用
  DEALER: '#02#', // 販売店
  GARAGE: '#03#', // 整備工場
  UNSEPCIFIED: '#04#', // その他の方
  ROAD_SERVICE: '#05#', // ロードサービス
  REPAIR: '#06#', // 修理
  CONTINUATION: '#07#', // 継続
  OTHER: '#08#', // その他
} as const;
