import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { SignFormData } from '../ContractSignature/ContractSignature/ContractSignature';
import { paths } from '@/config/paths';
import {
  WS_APP_LINK_DATA_TYPE_END,
  WS_APP_LINK_DATA_TYPE_ORDER,
  WS_APP_LINK_DATA_TYPE_SIGN,
  WS_APP_LINK_DATA_TYPE_START,
} from '@/consts/ws';
import { listenToPrivate, whisperToPrivate } from '@/utils/echo';
import { storeDispatch } from '@/utils/store';
import { snakeToCamel } from '@/utils/utils';

interface QrAuthInfo {
  mCustomerId: string;
  token: string;
  mkNm: string;
  carNm: string;
  gradeNm: string;
  colorNm: string;
  carPhotoUrl: string;
  carPrice: number;
  optionTotalPrice: number;
  otherOptionTotalPrice: number;
  buyingPrice: number;
  totalPrice: number;
  paymentMethodName: string;
  paymentTimes: number;
}

const SOCKET_TIMEOUT_MS = 10000;
const SOCKET_INIT_WAITING_MS = 1500;
const SUCCESS_WAITING_MS = 3000;

export const useContactConfirm = (
  accessToken: string,
  websocketToken: string,
) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [qrAuthInfo, setQrAuthInfo] = useState<QrAuthInfo>({
    mCustomerId: '',
    token: '',
    mkNm: '',
    carNm: '',
    gradeNm: '',
    colorNm: '',
    carPhotoUrl: '',
    carPrice: 0,
    optionTotalPrice: 0,
    otherOptionTotalPrice: 0,
    buyingPrice: 0,
    totalPrice: 0,
    paymentMethodName: '',
    paymentTimes: 0,
  });
  const channel = `private-channel-${websocketToken}`;

  useEffect(() => {
    storeDispatch('setContractSignature', 'contractSignature', '');
    window.Echo.connector.options.auth.headers['Authorization'] =
      'Bearer ' + accessToken;

    f7.preloader.show();

    const timeout = setTimeout(() => {
      f7.preloader.hide();
      f7.dialog.alert('', 'QRの有効期限が切れました');
      f7.view.main.router.navigate(paths.loginTutorial);
    }, SOCKET_TIMEOUT_MS);
    const leaveChannel = listenToPrivate(
      channel,
      ({ data: { data_type, data } }) => {
        if (data_type === WS_APP_LINK_DATA_TYPE_ORDER) {
          clearTimeout(timeout);
          const payload = snakeToCamel(data) as QrAuthInfo;
          setQrAuthInfo(payload);
          localStorage.setItem('m_customer_id', payload.mCustomerId);
          localStorage.setItem('token', payload.token);
          f7.preloader.hide();
        }
        if (data_type === WS_APP_LINK_DATA_TYPE_END) {
          setIsSuccess(true);
          setTimeout(() => {
            f7.view.main.router.navigate(paths.homeStatus('false'));
          }, SUCCESS_WAITING_MS);
        }
      },
    );
    setTimeout(() => {
      whisperToPrivate(channel, {
        data: {
          data_type: WS_APP_LINK_DATA_TYPE_START,
        },
      });
    }, SOCKET_INIT_WAITING_MS);

    return leaveChannel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSignature = (signData: SignFormData) => {
    const data = {
      data_type: WS_APP_LINK_DATA_TYPE_SIGN,
      data: signData,
    };

    whisperToPrivate(channel, {
      data,
    });
  };

  return {
    isSuccess,
    qrAuthInfo,
    submitSignature,
  };
};
