import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { storeDispatch } from './store';
import { paths } from '@/config/paths';
import {
  getAndSetAffiliate,
  getAndSetCustomerInfo,
} from '@/hooks/api/customer/useCustomerInfo';
import { GmoRedirectInfo } from '@/types/payment';

/**
 * @param token logged in user token
 * @param id logged in user id
 * @param f7router framework7 router
 * @param gmoRedirectInfo redirect destination
 * @param type login type
 * @param navigate bool, if false router won't navigate home page
 */
interface AutoLoginParams {
  token: string;
  id: number;
  f7router: Router.Router;
  gmoRedirectInfo?: GmoRedirectInfo;
  isLoggedInToMaikuru?: boolean;
  type?: string;
  navigate?: boolean;
}

// GMOから戻ってくる際のオートログイン
export const autoLoginHelper = async ({
  token,
  id,
  f7router,
  gmoRedirectInfo,
  isLoggedInToMaikuru = false,
  type,
  navigate,
}: AutoLoginParams) => {
  try {
    f7.preloader.show();
    await storeDispatch('setAuthInfo', 'authInfo', {
      m_customer_id: id,
      token: decodeURI(token),
      isLogin: true,
    });
    if (token) {
      await storeDispatch(
        'setIsLoggedInToMaikuru',
        'isLoggedInToMaikuru',
        isLoggedInToMaikuru,
      );
      await storeDispatch('setLoginInfo', 'loginInfo', {
        loginInfo: { loginType: type },
      });
      const customerInfo = await getAndSetCustomerInfo({
        m_customer_id: id,
      });
      let parameter = '';
      if (type === 'email') {
        parameter = customerInfo?.customer?.email || '';
      }
      if (type === 'telephone') {
        parameter = customerInfo?.customer?.phone_no1 || '';
      }
      await getAndSetAffiliate(parameter);
      if (navigate) f7.view.main.router.navigate(paths.home);
    } else {
      f7.view.main.router.navigate(paths.root);
    }
  } finally {
    f7.preloader.hide();
  }
};
