import { Router } from 'framework7/types';
import { FC } from 'react';
import { StrikeNoticeSettings } from './StrikeNoticeSettings/StrikeNoticeSettings';

type NotificationSettings = {
  options_title: string;
  type: number;
  props: string;
  status: number;
};

interface StrikeNoticeSettingsProps {
  f7router: Router.Router;
  settings: NotificationSettings[];
}

export const StrikeNoticeSettingsPage: FC<StrikeNoticeSettingsProps> = ({
  f7router,
  settings,
}) => {
  return <StrikeNoticeSettings f7router={f7router} settings={settings} />;
};
