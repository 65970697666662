import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchCarOrderApi } from '@/api/carApi';
import {
  PurchaseOrder,
  PurchaseOrderOptionItem,
  Remark,
} from '@/types/api/carApi';

export const useFetchCarOrder = (id: number) => {
  const [data, setData] = useState<{
    purchaseOrder: PurchaseOrder;
    optionItemList: PurchaseOrderOptionItem[];
    remarks: Remark[];
  }>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchCarOrderApi({ t_order_id: id });
        if (!res?.data?.purchase_order) throw new Error('Data is empty');
        setData({
          purchaseOrder: res.data.purchase_order,
          optionItemList: res.data.option_item_list,
          remarks: res.data.remarks || [],
        });
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [id]);

  return {
    data,
    isLoading,
    error,
  };
};
