import { Page } from 'framework7-react';
import { FC } from 'react';

export const StopReservingsCompletePage: FC = () => {
  return (
    <Page name="StopReservingsComplete">
      <h3>積立プラン停止完了</h3>
    </Page>
  );
};
