import { baseApi } from '@/config/axios';
import {
  CancelImpactNotificationApiParams,
  FetchImpactInfoApiParams,
  FetchImpactInfoApiResponse,
  LinkDriveOwnerApiParams,
  LinkDriveOwnerApiResponse,
  MAffiliateSettingsApiParams,
  MAffiliateSettingsApiResponse,
} from '@/types/api/infoApi';

// オーナー情報取得
export const linkDriveOwnerApi = (
  params: LinkDriveOwnerApiParams,
): Promise<LinkDriveOwnerApiResponse> =>
  baseApi.get('/v1/customer/link_drive_owner', { ...params });

// 76. 加盟法人マスタ取得
export const fetchMAffiliateSettingsApi = (
  params: MAffiliateSettingsApiParams,
): Promise<MAffiliateSettingsApiResponse> =>
  baseApi.get(`v1/customer/get-m-affiliate-settings`, { params });

// 77. 事故情報取得
export const fetchImpactInfoApi = (
  params: FetchImpactInfoApiParams,
): Promise<FetchImpactInfoApiResponse> =>
  baseApi.get(`v1/customer/impact_info`, { params });

// 緊急自動通報キャンセル
export const cancelImpactNotificationApi = (
  params: CancelImpactNotificationApiParams,
) => baseApi.post(`rest/linkdrive/impactEnd`, params);
