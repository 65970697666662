import { FC, ReactNode } from 'react';
import style from './ListBoxStyle.module.css';

interface ListBoxProps {
  children: ReactNode;
}
export const ListBox: FC<ListBoxProps> = ({ children }) => {
  return (
    <div className={style['list-box']}>
      <span>●</span>
      {children}
    </div>
  );
};
