import { Router } from 'framework7/types';
import { ContractConfirmPage, ContractSignaturePage } from '@/components/pages';
import { paths } from '@/config/paths';

export const contractRoutes: Router.RouteParameters[] = [
  {
    path: paths.contractConfirm(':accessToken', ':websocketToken'),
    component: ContractConfirmPage,
    name: '契約内容の確認',
  },
  {
    path: paths.contractSignature,
    popup: {
      component: ContractSignaturePage,
    },
    name: '電子署名_端末回転モーダル',
  },
];
