import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  firstName: yup.string().required('お名前は必須です'),
  lastName: yup.string().required('お名前は必須です'),
  kanaLastName: yup
    .string()
    .required('フリガナは必須です')
    .matches(/^([ァ-ン]|ー)+$/, { message: '全角カタカナで入力してください' }),
  kanaFirstName: yup
    .string()
    .required('フリガナは必須です')
    .matches(/^([ァ-ン]|ー)+$/, { message: '全角カタカナで入力してください' }),
  oldPhoneNumber: yup
    .string()
    .required('以前利用されていた電話番号は必須です')
    .matches(/^0\d{9,10}$/, { message: '正しい電話番号を入力してください' }),
  newPhoneNumber: yup
    .string()
    .required('現在ご利用中の電話番号は必須です')
    .matches(/^0\d{9,10}$/, { message: '正しい電話番号を入力してください' }),
  email: yup
    .string()
    .required('メールアドレスは必須です')
    .email('正しいメールアドレスを入力してください'),
  birthYear: yup
    .string()
    .required('生年月日は必須です')
    .matches(/[0-9]{4}/, { message: '正しい生年月日を入力してください' }),
  birthMonth: yup
    .string()
    .required('生年月日は必須です')
    .matches(/[1-9]|[1][0-2]/, {
      message: '正しい生年月日を入力してください',
    }),
  birthDay: yup
    .string()
    .required('生年月日は必須です')
    .matches(/[1-9]|[12][0-9]|3[01]/, {
      message: '正しい生年月日を入力してください',
    }),
});
