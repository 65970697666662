import { AxiosError } from 'axios';
import { useState } from 'react';
import { deleteWatcherApi } from '@/api/linkDriveApi';
import { useToast } from '@/hooks/useToast';
import { DeleteWatcherApiParams } from '@/types/api/linkDriveApi';

interface Args {
  params: DeleteWatcherApiParams;
  onSuccess: () => void;
}

export const useDeleteWatcher = () => {
  const { openToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const deleteWatcher = async (args: Args) => {
    try {
      setIsLoading(true);
      const { data } = await deleteWatcherApi(args.params);
      if (data.success) {
        openToast('見守り者を削除しました');
        args.onSuccess();
      }
      if (!data.success) openToast('削除に失敗しました', 'toast-failed');
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    deleteWatcher,
    error,
  };
};
