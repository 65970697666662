import { List, ListItem } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useMemo } from 'react';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { store } from '@/config/store';
import './CarDetailStyles.scss';
import { hyphenToDayNoPadding } from '@/utils/date';
interface CarDetailProps {
  f7router?: Router.Router;
  id?: string;
}
export const CarDetail: FC<CarDetailProps> = () => {
  const carDetailInfo: any = useMemo(() => {
    return store.state.carItemInfo;
  }, []);

  const basicInfoLabelList = [
    { label: 'メーカー', props: 'maker_nm' },
    { label: '車種名', props: 'car_nm' },
    { label: '初年度登録', props: 'registration_date' },
    { label: 'グレード', props: 'grade' },
    { label: '車台番号', props: 'vin' },
    // { label: '排気量', props: 'haiki', unit: ' cc' },
  ];
  const specInfoLabelList = [
    { label: 'ボディタイプ', props: 'keijo_nm' },
    { label: '駆動方式', props: 'kudo_nm' },
    { label: '色', props: 'color_nm' },
    { label: 'エンジン種別', props: 'fuel_nm' },
    { label: '排気量', props: 'haiki', unit: ' cc' },
    { label: '乗車定員', props: 'teiin', unit: ' 人' },
    { label: 'ドア数', props: 'door_cd' },
    { label: '全高', props: 'carsize_h', unit: ' mm' },
    { label: '全幅', props: 'carsize_w', unit: ' mm' },
    { label: '全長', props: 'carsize_l', unit: ' mm' },
  ];
  const accessoriesList: any = [];

  const groupData = (list: any, fun: any) => {
    const groups: any = {};
    list.forEach((el: any) => {
      const group = fun(el);
      groups[group] = groups[group] || [];
      groups[group].push(el);
    });
    return Object.keys(groups).map((group) => {
      let data = {
        m_accessory_category_name: group,
        subItems: groups[group],
      };
      return data;
    });
  };

  const getNewData = (oldList: any, prop: any) => {
    const newData = groupData(oldList, (item: any) => {
      return item[prop];
    });
    return newData;
  };
  return (
    <PageTemplate pageName="CarDetail" title="車両詳細情報" showNavBack>
      <div className="container">
        <RoundSheet>
          <List className="u-font-bold">
            <ListItem className="heading-with-button u-font-size-lg u-font-bold ">
              <span className="icon-info" style={{ marginRight: '8px' }} />
              車両基本情報
            </ListItem>
            {basicInfoLabelList.map((item, index) => (
              <ListItem key={index}>
                <div className="item-term" style={{ width: '100px' }}>
                  {item.label}
                </div>
                {item.props === 'registration_date' ? (
                  <div className="item-desc">
                    {hyphenToDayNoPadding(carDetailInfo[item.props], true)}
                  </div>
                ) : (
                  <div className="item-desc">{carDetailInfo[item.props]}</div>
                )}
              </ListItem>
            ))}
          </List>
        </RoundSheet>
        <RoundSheet>
          <div className="heading-with-button u-font-size-lg u-font-bold icon-car-small">
            基本スペック
          </div>
          <List>
            {specInfoLabelList.map((item, index) => (
              <ListItem key={index}>
                <div className="item-term" style={{ width: '100px' }}>
                  {item.label}
                </div>
                <div className="item-desc">
                  {carDetailInfo[item.props]}
                  {item.unit ? item.unit : ''}
                </div>
              </ListItem>
            ))}
          </List>
        </RoundSheet>
        <RoundSheet>
          <div className="heading-with-button u-font-size-lg u-font-bold icon-settings">
            装備仕様
          </div>
          <div className="list">
            <ul>
              {accessoriesList.map(
                (item: any, m_accessory_category_id: any) => (
                  <li key={m_accessory_category_id}>
                    <a className="item-content item-link" href="#">
                      <div className="item-inner">
                        <div className="item-title u-font-bold">
                          {item.m_accessory_category_name}
                        </div>
                      </div>
                    </a>
                    <div className="accordion-item-content">
                      <div className="block">
                        <List>
                          {item.subItems.map((subItem: any, index: any) => (
                            <ListItem
                              key={index}
                              title={subItem.m_accessory_name}
                            />
                          ))}
                        </List>
                      </div>
                    </div>
                  </li>
                ),
              )}
            </ul>
          </div>
        </RoundSheet>
      </div>
    </PageTemplate>
  );
};
