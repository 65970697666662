import parse from 'html-react-parser';
import { FC } from 'react';
import style from './terms.module.css';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';

interface TermsProps {
  html?: string;
  height?: string;
  title?: string;
}
export const Terms: FC<TermsProps> = ({
  html = '',
  height = undefined,
  title = '',
}) => {
  if (!html) return <></>;
  return (
    <RoundSheet height={height}>
      {title && <h3 className={style['title']}>{title}</h3>}
      <div className={style['container']}>{parse(html)}</div>
    </RoundSheet>
  );
};
