import { Col, Page, Row } from 'framework7-react';
import parse from 'html-react-parser';
import { FC } from 'react';
import { Button } from '../Button';
import RotatePhone from '@/assets/images/rotate-phone.png';
import './ContractDialog.scss';

type ContractDialogProps = {
  title: string;
  handleClose: () => void;
};

export const ContractDialog: FC<ContractDialogProps> = ({
  title,
  handleClose,
}) => {
  return (
    <Page name="ContractDialog">
      <div>
        <div className="container">
          <Row>
            <Col width="15" />
            <Col width="70">
              <img
                style={{ width: '100%', maxWidth: '200px' }}
                src={RotatePhone}
                alt="スマートフォンを横向きにして署名をしてください"
              />
              <div className="u-margin-top-xs" style={{ fontSize: '1.6rem' }}>
                {parse(title)}
              </div>
            </Col>
            <Col width="15" />
          </Row>
          <Row>
            <Col width="10" />
            <Col width="80">
              <Button
                style={{ backgroundColor: '#EFEFEF', color: '#323232' }}
                text="閉じる"
                handleClickAction={handleClose}
              />
            </Col>
            <Col width="10" />
          </Row>
        </div>
      </div>
    </Page>
  );
};
