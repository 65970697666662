import { AxiosError } from 'axios';
import { useState } from 'react';
import { updateWatcherApi } from '@/api/linkDriveApi';
import { useToast } from '@/hooks/useToast';
import { UpdateWatcherApiParams } from '@/types/api/linkDriveApi';
import { f7CustomBack } from '@/utils/utils';

interface Args {
  params: UpdateWatcherApiParams;
  onSuccess: () => void;
}

export const useUpdateWatcher = () => {
  const { openToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const updateWatcher = async (args: Args) => {
    try {
      setIsLoading(true);
      const { data } = await updateWatcherApi(args.params);
      if (!data.success) openToast('変更に失敗しました', 'toast-failed');
      if (data.success) {
        openToast('見守り者を変更しました');
        f7CustomBack(['#WatchingServiceSettings']);
        args.onSuccess();
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    updateWatcher,
    error,
  };
};
