import { Block, List, ListItem, f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { OUTPUT_FLAG, noPayFlg } from '@/consts/payment';
import { chargeFlg, warrantyAlignmentFlg } from '@/consts/prepaid';
import { PaymentMethod, Prepaid } from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';
import { formatDateISO, formatToJaDate, hyphenDate } from '@/utils/date';
import {
  isBankOrPayEasy,
  switchPaymentIcon,
  switchPaymentMethodDesc,
} from '@/utils/payment';
import { PrepaidSettingsBill } from '@/utils/prepaid';
import { generatePrepaidName } from '@/utils/shop';
import './PrepaidSettings.scss';
import { f7CustomBack } from '@/utils/utils';

type PrepaidSettingsProps = {
  f7router: Router.Router;
  isLoading: boolean;
  status?: string;
  bill?: PrepaidSettingsBill;
  prepaid?: Prepaid;
  payment?: PaymentMethod;
  prevPage?: string;
  handleIsUpdating: () => void;
};

export const PrepaidSettings: FC<PrepaidSettingsProps> = ({
  f7router,
  isLoading,
  status,
  bill,
  prepaid,
  payment,
  prevPage,
  handleIsUpdating,
}) => {
  const prepaidName = store.state.advancePaymentsInfo?.[0]?.name;
  const isPrevPaymentError =
    !!bill?.prevBill &&
    bill.prevBill?.no_pay_flg === noPayFlg.BEFORE_PAYMENT &&
    (bill.prevBill?.output_flg === OUTPUT_FLAG.invoice ||
      bill.prevBill?.output_flg === OUTPUT_FLAG.noSettlement);

  const testData = {
    isContract: Boolean(prepaid),
  };

  const helpList = [
    {
      text: 'プリペイドの使い方',
      href: 'https://tayori.com/q/maikuru/',
    },
    {
      text: 'プリペイドの有効期限',
      href: 'https://tayori.com/q/maikuru/',
    },
  ];

  const termsList = [
    {
      text: 'プリペイドサービス利用規約',
      href: paths.prepaidTerms,
    },
    {
      text: '資金決済法に基づく表示',
      href: paths.prepaidLaws,
    },
  ];

  const handleClickHref = (href: string) => {
    f7.view.main.router.navigate(href);
  };

  const handleClickHistory = () => {
    f7.view.main.router.navigate(paths.prepaidHistory, {
      props: { selected: 'history' },
    });
  };

  const handleShowChangePaymentMethod = async () => {
    window.localStorage.setItem(
      'm_prepaid_id',
      JSON.stringify(bill?.mPrepaidId),
    );
    window.localStorage.setItem('prevPage', prevPage ?? 'prepaid');
    f7.view.main.router.navigate(
      paths.registerPayment('prepaid', String(bill?.mPrepaidId)),
      {
        props: {
          redirectPage: 'prepaid_settings',
          nextBillInfo: bill?.nextBill,
          event: handleIsUpdating,
        },
      },
    );
  };

  const handleClickLocationHref = (menu: string) => {
    window.open(menu, '_blank');
  };

  //gmoから遷移してきたかで戻る画面を判断している
  const handleClickBack = () => {
    window.localStorage.removeItem('prevPage');
    if (status == 'gmo_success' || status === 'gmo_cancel') {
      f7.view.main.router.navigate(`/${prevPage}/`, {
        transition: 'push-reverse',
      });
    } else if (prevPage === 'account') {
      f7.view.main.router.navigate('/account/', { transition: 'push-reverse' });
    } else if (prevPage === 'prepaid') {
      f7.view.main.router.navigate('/prepaid/', { transition: 'push-reverse' });
    } else {
      f7CustomBack();
    }
  };

  // 積み立てを停止できるかどうか:
  // 停止中ではなくて、かつ、保証連動ではない
  const canStopPrepaid =
    prepaid?.charge_flg != chargeFlg.STOP &&
    prepaid?.warranty_alignment_flg != warrantyAlignmentFlg.LINK_WARRANTY;

  const isPrepaidStopped = prepaid?.charge_flg == chargeFlg.STOP;

  return (
    <PageTemplate
      pageName="PrepaidSettings"
      title={`${generatePrepaidName(prepaidName)}設定`}
      showNavBack={true}
      handleClickBack={handleClickBack}
    >
      {!isLoading && (
        <div className="home__wrapper">
          {/* 申し込みプラン情報 */}
          <Block>
            <div className="prepaid-plan-info info-card info-card-elevated">
              <div className="head display-flex u-margin-top-sm u-margin-bottom-sm">
                <div className="u-font-bold">積み立て金額</div>
                {!isPrepaidStopped ? (
                  <div className="display-flex u-margin-bottom-sm background-white">
                    <Button
                      className="u-padding-left-sm u-padding-right-sm"
                      handleClickAction={() =>
                        f7.view.main.router.navigate(paths.prepaidChangeMoney, {
                          props: {
                            event: handleIsUpdating,
                          },
                        })
                      }
                      height="30px"
                      colorTheme="gray"
                    >
                      金額を変更
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="u-padding-left-sm u-padding-right-sm"
                    handleClickAction={() =>
                      f7.view.main.router.navigate(paths.prepaidRestart, {
                        props: {
                          event: handleIsUpdating,
                        },
                      })
                    }
                    height="30px"
                    colorTheme="gray"
                  >
                    積み立てを再開
                  </Button>
                )}
              </div>
              <hr className="u-margin-bottom-sm border" />
              {testData.isContract && !isPrepaidStopped && (
                <div>
                  <div className="reserve-amount">
                    {prepaid?.monthly_charge?.toLocaleString() ?? ''}{' '}
                    円/月を積み立て
                  </div>
                  <div className="next-payment-day light-color">
                    次回の支払い
                    {bill != null &&
                      Object.keys(bill).length > 0 &&
                      hyphenDate(bill.currentBill?.billing_date ?? '')}
                  </div>
                </div>
              )}
              {isPrepaidStopped && (
                <div className="reserve-amount">積み立て停止中です</div>
              )}
            </div>
          </Block>
          {/* 積み立ての決済方法  */}
          <>
            <Block>
              <div className="payment-info info-card info-card-elevated">
                <div className="head display-flex u-margin-top-sm u-margin-bottom-sm">
                  <div className="u-font-bold">積み立ての決済方法</div>
                  <Button
                    className="u-padding-left-sm u-padding-right-sm"
                    handleClickAction={handleShowChangePaymentMethod}
                    height="30px"
                    colorTheme="gray"
                  >
                    {payment && !payment.m_payment_type_id
                      ? '決済方法を追加'
                      : '決済方法を変更'}
                  </Button>
                </div>
                <hr className="u-margin-bottom-sm border" />
                {payment && (
                  <div className="payment-info--method u-margin-bottom-sm">
                    {payment.m_payment_type_id && (
                      <div>
                        <i
                          className={`building-icon ${switchPaymentIcon(
                            payment?.m_payment_type_id as PaymentTypeId,
                          )}`}
                        />
                      </div>
                    )}
                    <div className="payment-info--method--child u-padding-left-">
                      {isBankOrPayEasy(payment?.m_payment_type_id) ? (
                        <div>
                          <div>
                            {switchPaymentMethodDesc(
                              payment?.m_payment_type_id ?? 0,
                              payment.bank_name,
                            )}
                          </div>
                          <div className="light-color">
                            {payment.account_number}
                          </div>
                          <div className="light-color">
                            登録日:{' '}
                            {formatToJaDate(
                              formatDateISO(
                                payment?.register || new Date().toISOString(),
                              ),
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>{payment.payment_type_name}</div>
                          <div className="light-color">{payment.card_no}</div>
                          <div className="light-color">
                            登録日:{' '}
                            {formatToJaDate(
                              formatDateISO(
                                payment?.register || new Date().toISOString(),
                              ),
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {isPrevPaymentError && (
                  <div className="u-font-size-lg u-margin-bottom-xs">
                    <hr className="u-margin-bottom-sm border" />
                    <InfoCard
                      className="danger-infoCard"
                      bgColor="danger"
                      text="引き落としが確認できませんでした"
                      textSub={bill.billMonth}
                      iconType="exclamationmark_circle"
                      isElevated
                      url={'/payments_history/prepaid/' + prepaid?.id + '/'}
                    />
                  </div>
                )}
                {testData.isContract && bill?.currentBill?.bill_price && (
                  <div>
                    <hr className="u-margin-bottom-sm border" />
                    <div className="payment-info--billing-schedule u-margin-bottom-sm">
                      <div className="u-font-size-lg weight-bold">
                        次回請求予定
                      </div>
                      <div>
                        <span>請求予定日：</span>
                        <span>
                          {hyphenDate(bill.currentBill?.billing_date)}
                        </span>
                      </div>
                      <div>
                        <span>請求金額：</span>
                        <span>
                          {`${bill.currentBill?.bill_price?.toLocaleString()} 円`}
                        </span>
                      </div>
                    </div>
                    <hr className="u-margin-bottom-sm border" />
                    <div
                      onClick={handleClickHistory}
                      className="payment-info--payment_history u-margin-bottom-0 color-black"
                    >
                      <div className="u-font-size-lg weight-bold">
                        お支払い履歴
                        {isPrevPaymentError && (
                          <div className="not-comfirm">
                            前回の引き落としが確認できませんでした
                          </div>
                        )}
                      </div>
                      <span>
                        <i className="icon icon-right" />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Block>
            <Block>
              <div className="info-card info-card-elevated">
                <div className="head display-flex u-margin-top-sm u-margin-bottom-sm">
                  <div className="u-font-bold">ヘルプ</div>
                </div>
                <List simple-list>
                  {helpList.map((item, index) => (
                    <ListItem
                      key={index}
                      onClick={() => handleClickLocationHref(item.href)}
                    >
                      <span className="u-font-size-lg color-black">
                        {item.text}
                      </span>
                      <i className="icon-right" />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Block>
            <Block>
              <div className="info-card info-card-elevated">
                <div className="head display-flex u-margin-top-sm u-margin-bottom-sm">
                  <div className="u-font-bold">{`${generatePrepaidName(
                    prepaidName,
                  )}に関する規約`}</div>
                </div>
                <List simple-list>
                  {termsList.map((item, index) => (
                    <ListItem
                      key={index}
                      onClick={() => handleClickHref(item.href)}
                    >
                      <span className="u-font-size-lg color-black">
                        {item.text}
                      </span>
                      <i className="icon-right" />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Block>
            {canStopPrepaid && (
              <Block>
                <div
                  className="stop-box"
                  onClick={() =>
                    f7.view.main.router.navigate(paths.prepaidStop, {
                      props: {
                        event: handleIsUpdating,
                      },
                    })
                  }
                >
                  <div className="stop-box-icon-title">
                    <div className="stop-box__info-title">
                      積み立てを停止する
                    </div>
                  </div>
                  <i className="icon-right" />
                </div>
              </Block>
            )}
          </>
        </div>
      )}
    </PageTemplate>
  );
};
