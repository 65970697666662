import { FC, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './Partial.module.scss';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: string;
  required?: boolean;
  type?: 'input' | 'textarea';
  placeholder?: string;
}

export const TextInput: FC<TextInputProps> = ({
  name,
  label,
  error = '',
  required = false,
  type = 'input',
  placeholder,
  ...rest
}) => {
  const { register } = useFormContext();
  return (
    <div
      className={`${style['input-text']} ${
        !!error ? style['input-text-error'] : ''
      }`}
    >
      {label && (
        <label>
          {label}
          <span
            className={`${style['input-label-tips']} ${
              required && style['required']
            }`}
          >
            {required ? '必須' : '任意'}
          </span>
        </label>
      )}
      {type === 'textarea' ? (
        <textarea
          {...register(name)}
          placeholder={placeholder || ''}
          rows={4}
        />
      ) : (
        <input {...rest} {...register(name)} placeholder={placeholder || ''} />
      )}
      {!!error && <p className={style['input-error-message']}>{error}</p>}
    </div>
  );
};
