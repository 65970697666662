import { FC } from 'react';
import './TipsCardStyle.scss';

interface TitlesType {
  title: string;
  subTitle: string;
}

interface TipsCardProps {
  imgUrl: string;
  titles: TitlesType;
  onClick?: () => void;
}

export const TipsCard: FC<TipsCardProps> = ({ imgUrl, titles, onClick }) => {
  return (
    <div className="brakes-tips-card u-margin-bottom-sm" onClick={onClick}>
      <div>
        <img src={imgUrl} alt="" />
      </div>
      <div style={{ backgroundColor: 'white' }}>
        <span className="u-font-bold u-font-size-xl">{titles.title}</span>
        <span className="u-font-size-lg pre-wrap">{titles.subTitle}</span>
      </div>
    </div>
  );
};
