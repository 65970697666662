import { useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { UpdateNumber } from './UpdateNumber/UpdateNumber';
import { store } from '@/config/store';
import { Car } from '@/types/api/customerApi';

type UpdateNumberPageProps = {
  f7router: Router.Router;
};
export const UpdateNumberPage: FC<UpdateNumberPageProps> = ({ f7router }) => {
  const { m_customer_id: mCustomerId } = store.state.authInfo;
  const car = useStore(store, 'carItemInfo') as Required<Car>;
  return (
    <UpdateNumber f7router={f7router} mCustomerId={mCustomerId} car={car} />
  );
};
