import { Popup } from 'framework7-react';
import { FC } from 'react';
import style from './ConfirmCancelUploadPopup.module.scss';
import exclamationMarkIcon from '@/assets/images/icon-exclamationmark_circle-big.svg';
import { Button } from '@/components/uiParts/Button';
interface ConfirmCancelUploadPopupProps {
  isOpen: boolean;
  onPopupClose: (value: boolean) => void;
  backdropEl: any;
}

export const ConfirmCancelUploadPopup: FC<ConfirmCancelUploadPopupProps> = ({
  isOpen = false,
  onPopupClose,
  backdropEl,
}) => {
  return (
    <Popup
      className={style['confirm-cancel-upload-popup']}
      opened={isOpen}
      onPopupClosed={() => onPopupClose(false)}
      backdrop
      backdropEl={backdropEl}
      animate={false}
    >
      <h3 className={style['title']}>
        <img src={exclamationMarkIcon} />
        終了しますか？
      </h3>
      <div className={style['body']}>
        <p>登録内容は保存されません。</p>
        <p>本当に終了しますか？</p>
      </div>
      <div className={style['footer']}>
        <Button
          onClick={() => onPopupClose(false)}
          className={style['btn-cancel']}
          color="gray"
        >
          キャンセル
        </Button>
        <Button
          onClick={() => onPopupClose(true)}
          className={style['btn-finish']}
        >
          終了する
        </Button>
      </div>
    </Popup>
  );
};
