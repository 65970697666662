import { baseApi } from '@/config/axios';
import { url } from '@/consts/url';
import {
  GetPrivacyApiResponse,
  GetTermsApiParams,
  GetTermsApiResponse,
} from '@/types/api/termsApi';

export const getTermsApi = (
  params: GetTermsApiParams,
): Promise<GetTermsApiResponse> => baseApi.get('v1/customer/term', { params });

export const getPrivacyApi = (): Promise<GetPrivacyApiResponse> =>
  baseApi.get(`${url.MAIKURU_BASE_URL}/files/privacy.txt`);
