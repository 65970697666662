/* eslint-disable no-magic-numbers */
import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChargePrepaid } from './ChargePrepaid/ChargePrepaid';
import {
  ChargePrepaidBillInfo,
  getChargePrepaidBillInfo,
} from './ChargePrepaidHelper';
import { PostChargeDataToGmoForm } from './PostChargeDataToGmoForm/PostChargeDataToGmoForm';
import { useChargePrepaid } from './useChargePrepaid';
import { AuthInfoState, PaymentDetail } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { paymentTypeId } from '@/consts/payment';
import { useHouseprepaidApplication } from '@/hooks/api/prepaid/useHouseprepaidApplication';
import { Prepaid } from '@/types/api/customerApi';
import { ChangePaymentInfo } from '@/types/payment';
import { storeDispatch } from '@/utils/store';
import { f7CustomBack } from '@/utils/utils';

interface ChargePrepaidProps {
  f7router: Router.Router;
  previousPage: string;
  redirectPage: string;
  status: string;
  handleChangeBalanceTotal: (charging: number) => void;
}

export const ChargePrepaidPage: FC<ChargePrepaidProps> = ({
  f7router,
  previousPage = '',
  redirectPage = '',
  status,
  handleChangeBalanceTotal,
}) => {
  const { m_customer_id } = useStore(store, 'authInfo') as AuthInfoState;
  // store
  const prepaidsInfo = useStore(store, 'getFirstAdvancePaymentsInfo');
  const billsInfo = useStore(store, 'getBillInfo');
  const displayPayment = useStore(
    store,
    'getPrepaidChargePayment',
  ) as PaymentDetail;
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Required<Prepaid>
    | undefined;

  const housePrepaidParams = useMemo(
    () => ({ m_customer_id }),
    [m_customer_id],
  );
  const { data } = useHouseprepaidApplication(housePrepaidParams);

  const currentPlan = data?.houseprepaids
    ? (data?.houseprepaids).find(
        (plan) => plan.m_prepaid_plan_id === prepaid?.m_prepaid_plan_id,
      )
    : undefined;

  // state
  const [billInfo, setBillInfo] = useState<ChargePrepaidBillInfo>(
    getChargePrepaidBillInfo(billsInfo, prepaidsInfo),
  );
  const [amount, setAmount] = useState<number>(0);
  const [balanceAfterCharging, setBalanceAfterCharging] = useState(
    prepaidsInfo?.balance_total ?? 0,
  );

  // const
  const maxChargeAmount = useMemo(
    () => currentPlan?.monthly_charge_max ?? 1990000,
    [currentPlan],
  );

  const monthlyChargeUnit = 100;
  const currentBalance = prepaidsInfo?.balance_total ?? 0;

  const isActive = () => {
    if (!amount || !displayPayment.paymentMethodId) return false;
    return amount > 0;
  };

  const handleAddChargeAmountClick = (value: number) => {
    setAmount((amount ?? 0) + value);
  };

  const handleSetPaymentInfo = useCallback(
    (paymentInfo: ChangePaymentInfo) => {
      billInfo &&
        setBillInfo({
          ...billInfo,
          bank_name: paymentInfo.bankName,
          account_number: paymentInfo.accountNumber,
          card_no: paymentInfo.cardNo,
          m_payment_type_id: paymentInfo.paymentTypeId,
        });
    },
    [billInfo],
  );
  const handleBack = () => {
    window.localStorage.removeItem('source_page');
    window.localStorage.removeItem('charge_amount');
    if (previousPage == 'charge_prepaid') {
      f7.view.main.router.navigate(paths.usePrepaid, {
        props: {
          f7router: f7router,
          handleChangeBalanceTotal: handleChangeBalanceTotal,
        },
      });
    }
    f7CustomBack();
  };

  const { GMOParameters, generateGMOParameters } =
    useChargePrepaid(previousPage);
  const handleClickChargeAction = async () => {
    if (!amount) return;

    if (amount % monthlyChargeUnit !== 0) {
      f7.dialog.alert(
        '',
        `${monthlyChargeUnit.toLocaleString()}円単位で金額を指定してください。`,
      );
      return;
    }

    if (displayPayment.paymentTypeId != paymentTypeId.CREDIT) {
      f7.dialog.alert('', '決済はクレジッドカードのみです。');
      return;
    }

    await generateGMOParameters(amount);
  };

  //payment method
  const { paymentMethodsInfo } = store.state;
  let paymentList: PaymentDetail[] = [];

  paymentMethodsInfo.map((item) => {
    paymentList.push({
      accountNumber: item.account_number,
      bankName: item.bank_name,
      cardNo: item.card_no,
      isSelected: false,
      noPayFlg: 0,
      paySubject: '',
      paymentMethodId: item.m_payment_method_id,
      paymentTypeId: item.m_payment_type_id,
      paymentTypeName: item.payment_type_name,
      warrantyNo: item.warranty_no,
      register: item.register,
    });
  });

  const handleClickChangePaymentMethod = async (item: PaymentDetail) => {
    if (displayPayment?.paymentMethodId == item?.paymentMethodId) return;
    await storeDispatch(
      'setPrepaidChargePayment',
      'prepaidChargePayment',
      item,
    );
    handleSetPaymentInfo({
      bankName: item.bankName,
      accountNumber: item.accountNumber,
      cardNo: item.cardNo,
      paymentTypeId: item.paymentTypeId,
    });
  };
  const handleClickAddMethod = () => {
    window.localStorage.setItem('charge_amount', amount.toString());
    f7.view.main.router.navigate(paths.registerPaymentList, {
      props: {
        redirectPage: redirectPage ?? 'home',
      },
    });
  };

  const postChargeDataToGmoFormRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const {
      MemberID,
      MemberPassString,
      ShopID,
      ShopPass,
      ShopPassString,
      OrderID,
      SiteID,
      SitePass,
      Amount,
    } = GMOParameters;
    if (
      MemberID &&
      MemberPassString &&
      ShopID &&
      ShopPass &&
      ShopPassString &&
      OrderID &&
      SiteID &&
      SitePass &&
      Amount
    ) {
      postChargeDataToGmoFormRef.current?.submit();
    }
  }, [GMOParameters]);

  useEffect(() => {
    const chargePrice =
      Number(window.localStorage.getItem('charge_amount')) || 0;
    if (!!chargePrice) {
      setAmount(chargePrice);
      window.localStorage.removeItem('charge_amount');
    }
  }, []);

  useEffect(() => {
    if (displayPayment?.paymentMethodId || !paymentList?.length) {
      return;
    }
    const lastRegisteredPayment = paymentList.reduce((max, cur) => {
      const maxDate = new Date(max.register);
      const curDate = new Date(cur.register);
      return curDate > maxDate ? cur : max;
    }, paymentList[0]);

    handleClickChangePaymentMethod(lastRegisteredPayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodsInfo]);

  return (
    <ChargePrepaid
      amount={amount}
      changeAmount={setAmount}
      currentBalance={currentBalance}
      handleAddChargeAmountClick={handleAddChargeAmountClick}
      handleClickChargeAction={handleClickChargeAction}
      isActive={isActive}
      handleBack={handleBack}
      displayPayment={displayPayment}
      paymentList={paymentList}
      handleClickChangePaymentMethod={handleClickChangePaymentMethod}
      handleClickAddMethod={handleClickAddMethod}
      PostChargeDataToGmoForm={() => (
        // 非表示フォーム: GMOテンプレート画面に遷移する
        <PostChargeDataToGmoForm
          GmoChargeParams={GMOParameters}
          ref={postChargeDataToGmoFormRef}
        />
      )}
    />
  );
};
