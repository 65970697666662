import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './phoneChangedStyle.module.css';
import { ReactComponent as DownArrowIcon } from '@/assets/images/login/icon_prev.svg';
import { ReactComponent as SupportIcon } from '@/assets/images/login/icon_support.svg';
import { HelpButtonsBox } from '@/components/projects/HelpButtonsBox/HelpButtonsBox';
import { RequestCustomerList } from '@/components/projects/RequestCustomerList/RequestCustomerList';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';

interface PhoneChangedProps {
  f7router: Router.Router;
}
export const PhoneChanged: FC<PhoneChangedProps> = ({ f7router }) => {
  return (
    <PopupPageTemplate
      pageName="PhoneChanged"
      title={`電話番号を変更したことにより\nログインできなくなった方`}
      className={style['page']}
    >
      <div className={style['container']}>
        <div className={style['down-arrow-icon-wrapper']}>
          <DownArrowIcon />
        </div>
        <div className={style['support-container']}>
          <div>
            <SupportIcon width="80px" height="80px" />
          </div>
          <p>
            サポートセンターに連絡をして電話番号の変更手続きを行ってください
          </p>
        </div>
        <RequestCustomerList />
        <HelpButtonsBox
          title="「電話番号の変更依頼」とお伝えください"
          isSecondButton
          path={paths.contactNumber}
        />
      </div>
    </PopupPageTemplate>
  );
};
