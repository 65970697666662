import { f7 } from 'framework7-react';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput } from '../Inputs/TextInput';
import { Step2FormData } from '@/hooks/api/carWash/useCarWashHandStep2';
import { useSearchAddress } from '@/hooks/api/yahoo/useSearchAddress';

export const ZipCodeInput: FC = () => {
  const {
    watch,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = useFormContext<Step2FormData>();
  const { searchAddressByZip, data } = useSearchAddress();
  const zipCode = watch('workspace.zipCode');
  useEffect(() => {
    if (!zipCode || !/^[0-9]{3}-[0-9]{4}$/.test(zipCode)) return;
    if (!isDirty) return;
    f7.preloader.show();
    searchAddressByZip({
      zipCode,
      onSuccess: (prefectures = '', municipalities = '', street = '') => {
        setValue('workspace.prefectures', prefectures);
        setValue('workspace.municipalities', municipalities);
        setValue('workspace.street', street);
        trigger('workspace.prefectures');
        trigger('workspace.municipalities');
        trigger('workspace.street');
      },
    }).finally(() => {
      f7.preloader.hide();
    });
  }, [zipCode]);

  return (
    <>
      <TextInput
        label="郵便番号（000-0000）"
        name="workspace.zipCode"
        placeholder="〒123-4567"
        error={errors.workspace?.zipCode?.message}
        required
      />
      <TextInput
        label="都道府県"
        name="workspace.prefectures"
        placeholder="東京都"
        error={errors.workspace?.prefectures?.message}
        required
      />
      <TextInput
        label="市区町村"
        name="workspace.municipalities"
        placeholder="府中市"
        error={errors.workspace?.municipalities?.message}
        required
      />
      <TextInput
        label="町名番地・建物・部屋番号"
        name="workspace.street"
        placeholder="朝日町1-1-1青空ビル101"
        error={errors.workspace?.street?.message}
        required
      />
    </>
  );
};
