export const historyType = {
  WARRANTY: 'warranty',
  PREPAID: 'prepaid',
} as const;

export const paymentFlg = {
  WARRANTY: 0,
  PREPAID: 1,
} as const;

export const paymentTypeId = {
  CASH: 2,
  CREDIT: 3,
  BANK: 4,
  PAY_EASY: 5,
} as const;

export const noPayFlg = {
  UNTREATED: 0,
  BEFORE_PAYMENT: 1,
  PAYMENT_CONFIRMED: 2,
} as const;

export const prepaidFlg = {
  NO_HOUSE_PREPAID_EXISTS: 0,
  HOUSE_PREPAID_EXISTS: 1,
} as const;

export const OUTPUT_FLAG = {
  invoice: 1, //(請求実施) or
  noSettlement: 9, //(請求実施決済情報なし)
};

export const chargeFlg = 1;

export const invalidateFlag = {
  EXPIRED: 1,
  NOT_EXPIRED: 0,
} as const;
