import { f7 } from 'framework7-react';
import { FC } from 'react';
import connectorImg from '../../../../assets/images/connector.png';
import myCarIMg from '../../../../assets/images/myCar.png';
import orignalNoImg from '../../../../assets/images/serialNo.png';
import { TextImage } from '../TextImage/TextImage';
import style from './LinkDriveSetupListStyle.module.css';
import { ReactComponent as Step1Icon } from '@/assets/images/menu/link-drive-setup-step-1.svg';
import { ReactComponent as Step2Icon } from '@/assets/images/menu/link-drive-setup-step-2.svg';
import { ReactComponent as Step3Icon } from '@/assets/images/menu/link-drive-setup-step-3.svg';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { f7CustomBack } from '@/utils/utils';

export const LinkDriveSetupList: FC = () => {
  const data = [
    {
      imgUrl: connectorImg,
      title: 'コネクター',
      description: 'コネクターを車両に挿してセットアップを進めます。',
    },
    {
      imgUrl: orignalNoImg,
      title: 'シリアルナンバー',
      description:
        'コネクターの側面、もしくはLINKDriveのパッケージの箱に記載されています。\n認証のために必要です。',
    },
    {
      imgUrl: myCarIMg,
      title: 'ご自身の車両',
      description: 'セットアップは、お車のエンジンをかけて行う必要があります。',
    },
  ];

  const handleClickNavigation = () => {
    store.dispatch('setActiveTabId', { activeTabId: 0 });
    f7.view.main.router.navigate(paths.linkDriveSetupSteps, { animate: true });
  };

  return (
    <PopupPageTemplate
      id="LinkDriveSetupList"
      pageName="LinkDriveSetupList"
      title="LinkDriveセットアップ"
      isCloseIcon={false}
      closeByBackdropClick={false}
    >
      <nav className={`${style.nav} ${style['nav-icon-wrapper']}`}>
        <div className={style['nav-icon']}>
          <a
            onClick={() => f7CustomBack()}
            className={style['nav-icon']}
            href="#"
          >
            <i className={`${style['icon-close']} icon-close`} />
          </a>
        </div>
      </nav>
      <div className={style['container']}>
        <div className={style['guide-step']}>
          <h3>セットアップ手順</h3>
          <p>セットアップの所要時間は1時間程度です。</p>
          <span>
            ※プログラムの更新が必要な場合、1時間30分程度かかることがあります。
          </span>
        </div>
        <div>
          <ol className={style.ol}>
            <li className={style.list}>
              <h4>STEP1</h4>
              <div>
                <Step1Icon />
              </div>
              <span>シリアルナンバーの入力orスキャン</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="15"
                viewBox="0 0 13 15"
                fill="none"
              >
                <path d="M0.332031 0L12.332 8L0.332031 15V0Z" fill="#1E55E6" />
              </svg>
            </li>
            <li className={style.list}>
              <h4>STEP2</h4>
              <div>
                <Step2Icon />
              </div>
              <span>コネクタを挿してエンジン始動</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="15"
                viewBox="0 0 13 15"
                fill="none"
              >
                <path d="M0.332031 0L12.332 8L0.332031 15V0Z" fill="#1E55E6" />
              </svg>
            </li>
            <li className={style.list}>
              <h4>STEP3</h4>
              <div>
                <Step3Icon />
              </div>
              <span>セットアップ完了</span>
            </li>
          </ol>
        </div>
        <h3>セットアップに必要なもの</h3>
        <div className="u-padding-top-md u-padding-bottom-xs text-image__wrap">
          <TextImage data={data[1]} />
          <TextImage data={data[0]} />
          <TextImage data={data[2]} />
        </div>

        <div className={style['button-wrap']}>
          <Button
            text="次へ"
            isActive
            isFixed
            type="submit"
            handleClickAction={handleClickNavigation}
          />
        </div>
      </div>
    </PopupPageTemplate>
  );
};
