import { Link, Toolbar } from 'framework7-react';
import { ToolbarProps } from 'framework7-react/components/toolbar';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './TroubleTabBarTextStyle.module.css';
import './TroubleTabBarText.scss';

interface TabBarTextProps extends ToolbarProps {
  tabs: Router.RouteParameters[];
}

export const TroubleTabBarText: FC<TabBarTextProps> = ({ tabs, ...props }) => {
  return (
    <Toolbar
      className={`${style['toolbar']} tabbar-text`}
      tabbar
      labels
      noHairline
      {...props}
    >
      <div className={style['tabs-wrapper']}>
        {tabs.map((tab: any) => (
          <Link
            className="no-ripple"
            tabLink={`#${tab.id}`}
            animate
            key={tab.id}
            href={`.${tab.path}`}
            icon={tab.options.props.icon}
            routeTabId={tab.id}
          >
            <span
              style={{
                fontSize: tab.options.props.fontSize,
                whiteSpace: 'pre',
                textAlign: 'center',
              }}
            >
              {tab.options.props.tabText}
            </span>
          </Link>
        ))}
      </div>
    </Toolbar>
  );
};
