import Echo from 'laravel-echo';
import io from 'socket.io-client';
import { url } from '@/consts/url';

//for Echo
if (!window.io) {
  window.io = io;
}

if (!window.Echo) {
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: url.MOBICON,
  });
}

export function listenToPrivate<T = any>(
  channel: string,
  callBack: (payload: T) => void,
) {
  window.Echo.private(channel).listenForWhisper(
    'NotificationEvent',
    (payload: T) => {
      callBack(payload);
    },
  );

  return function cleanUp() {
    window.Echo.leaveChannel(channel);
  };
}

export function whisperToPrivate<T = any>(channel: string, data: T) {
  window.Echo.private(channel).whisper('NotificationEvent', data);
}

export function leaveChannel<T = any>(channel: string) {
  window.Echo.leaveChannel(channel);
}
