import { Router } from 'framework7/types';
import { FC } from 'react';
import { PaymentsSchedule } from './PaymentsSchedule/PaymentsSchedule';
import { store } from '@/config/store';
import { makeBillDetailsGroupByBillMonth } from '@/utils/payment';

type PaymentSchedulePageProps = {
  f7router: Router.Router;
};

export const PaymentsSchedulePage: FC<PaymentSchedulePageProps> = ({
  f7router,
}) => {
  const bills = store.state.billsInfo;
  const billDetails = bills
    .map((bill) => bill.bill_infomation_details)
    .flat()
    .filter((item) => !!item);

  return (
    <PaymentsSchedule
      f7router={f7router}
      billDetailsGroupByMonth={makeBillDetailsGroupByBillMonth(billDetails)}
    />
  );
};
