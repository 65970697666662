import {
  add,
  addHours,
  addMonths,
  addMinutes,
  compareDesc,
  endOfMonth,
  format,
  getDate,
  getDaysInMonth,
  getMonth,
  parse,
  parseISO,
  setDate,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { ja } from 'date-fns/locale';
import { weekNumber } from '@/consts/day';
import { YYYYMM } from '@/types/date';

export const DATE_MONTH_INCREMENTER = 1;
export const isInvalidDate = (date: Date) => Number.isNaN(date.getTime());

// 2つの日付を比較し第二引数の日付の方が新しい場合にtrueを返す
// それ以外はfalseを返す（日付が等しい場合もfalse）
export const compareDate = (leftDate: Date, rightDate: Date) => {
  return compareDesc(leftDate, rightDate) > 0;
};

// Date型をyyyy年MM月dd日に変換する関数
export const formatToJaDate = (date: Date) => {
  return format(date, 'yyyy年M月d日', { locale: ja });
};

// Date型をyyyy年MM月dd日 hh:mmに変換する関数
export const formatToJaDateTime = (
  date: Date,
  formatOpt = 'yyyy年M月d日 p',
) => {
  return format(date, formatOpt, { locale: ja });
};

// Date型をyyyy-MM-ddに変換する関数
export const dateToHyphenConnection = (date: Date) => {
  return format(date, 'yyyy-M-d');
};

// Date型をyyyyMに変換する関数
export const dateToYearMonth = (date: Date) => {
  return format(date, 'yyyyM') as YYYYMM;
};

// Date型をyyyyMMに変換する関数
export const dateToFullYearMonth = (date: Date) => {
  return format(date, 'yyyyMM') as YYYYMM;
};

// Date型をyyyyMMddHHmmssに変換する
export const dateToFormattedDateInSeconds = (date: Date) => {
  return format(date, 'yyyyMdHHmmss', { locale: ja });
};

// Date型をyyyyMMddに変換する
export const dateToFormattedDateInDays = (date: Date) => {
  return format(date, 'yyyyMd', { locale: ja });
};

// Date型をyyyy年M月d日（E）に変換する
export const formatDateWithDay = (date: Date) => {
  return format(date, 'yyyy年M月d日（E）', { locale: ja });
};

// Date型をyyyy年M月d日（E）HH:mmに変換する
export const formatDate = (date: Date) => {
  return format(date, 'yyyy年M月d日（E）HH:mm', { locale: ja });
};

// Date型をyyyy年M月d日（E）HH:mmに変換する
export const formatDateSpaceDay = (dateStr: string | undefined) => {
  if (!dateStr) return '';
  const formatStr = 'yyyy年M月d日 (E)';
  try {
    const date = parse(dateStr, 'yyyy-M-d', new Date());
    const newDate = format(date, formatStr, { locale: ja });
    return newDate;
  } catch (e) {
    if (e instanceof Error) {
      console.error(`日付の形式が不正:${dateStr}`);
    }
    return dateStr;
  }
};

// YYYYMMを年月に変換する関数
export const formatYearMonthToJa = (dateStr: string | undefined) => {
  if (!dateStr) return '';
  try {
    const date = parse(dateStr, 'yyyyMM', new Date());
    const newDate = format(date, 'yyyy年M月', { locale: ja });
    return newDate;
  } catch (e) {
    if (e instanceof Error) {
      console.error(`日付の形式が不正:${dateStr}`);
    }
    return dateStr;
  }
};

// YYYY/MM/DDを年月日に変換する関数
export const slashToYear = (dateStr: string | undefined) => {
  if (!dateStr) return '';
  try {
    const date = parse(dateStr, 'yyyy/MM/dd', new Date());
    const newDate = format(date, 'yyyy年M月d日', { locale: ja });
    return newDate;
  } catch (e) {
    if (e instanceof Error) {
      console.error(`日付の形式が不正:${dateStr}`);
    }
    return dateStr;
  }
};

// YYYY-MM-DD HH:mm:ss を年月日に変換
export const hyphenToYear = (
  dateStr: string | undefined,
  isHms: boolean = true,
) => {
  if (!dateStr) return '';
  const formatString = isHms ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';
  try {
    const date = parse(dateStr, formatString, new Date());
    const newDate = format(date, 'yyyy年M月d日', { locale: ja });
    return newDate;
  } catch (e) {
    if (e instanceof Error) {
      console.error(`日付の形式が不正:${dateStr}`);
    }
    return dateStr;
  }
};

// YYYY-MM-DD をYYYY年MM月DD日(E),または,YYYY年MM月DD日に変換
export const hyphenToDay = (
  dateStr: string | undefined,
  isDayOfWeek?: boolean,
) => {
  if (!dateStr) return '';
  const formatStr = isDayOfWeek ? 'yyyy年M月d日' : 'yyyy年M月d日(E)';
  try {
    const date = parse(dateStr, 'yyyy-M-d', new Date());
    const newDate = format(date, formatStr, { locale: ja });
    return newDate;
  } catch (e) {
    if (e instanceof Error) {
      console.error(`日付の形式が不正:${dateStr}`);
    }
    return dateStr;
  }
};

export const hyphenToDayNoPadding = (
  dateStr: string | undefined,
  isDayOfWeek?: boolean,
) => {
  if (!dateStr) return '';
  const formatStr = isDayOfWeek ? 'yyyy年M月d日' : 'yyyy年M月d日(E)';
  try {
    const date = parse(dateStr, 'yyyy-MM-dd', new Date());
    const newDate = format(date, formatStr, { locale: ja });
    return newDate;
  } catch (e) {
    if (e instanceof Error) {
      console.error(`日付の形式が不正:${dateStr}`);
    }
    return dateStr;
  }
};

// 運転記録ーデータグラフ用
export const getXAxis = (date: string, day: number) => {
  const today = new Date(Date.parse(date));
  //指定した日にち分プラスする
  const targetDayMilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetDayMilliseconds);
  let tMonth = today.getMonth();
  let tDate = today.getDate();
  let tYear = today.getFullYear();
  let tDay = '';
  // monthは0から始まるため+1する
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  switch (today.getDay()) {
    case weekNumber.SUNDAY:
      tDay = '日';
      break;
    case weekNumber.MONDAY:
      tDay = '月';
      break;
    case weekNumber.TUESDAY:
      tDay = '火';
      break;
    case weekNumber.WEDNESDAY:
      tDay = '水';
      break;
    case weekNumber.THURSDAY:
      tDay = '木';
      break;
    case weekNumber.FRIDAY:
      tDay = '金';
      break;
    case weekNumber.SATURDAY:
      tDay = '土';
      break;
    default:
      tDay = '未知';
      break;
  }
  return tDay + '\n' + tMonth + '/' + tDate;
};

export const doHandleMonth = (month: any) => {
  let m = month;
  if (month.toString().length == 1) {
    m = '0' + month;
  }
  return m;
};

//秒数から時間、分、秒を返す
export const formatSeconds = (sec: number) => {
  if (!sec) {
    return {
      tripHours: 0,
      tripMinutes: 0,
      tripSeconds: 0,
    };
  }

  // Seconds in hour
  const secInHour = 3600;
  const secInMin = 60;
  const min = Math.floor(sec % secInHour); // 分
  return {
    tripHours: Math.floor(sec / secInHour),
    tripMinutes: Math.floor(min / secInMin),
    tripSeconds: sec % secInMin,
    tripHMS:
      Math.floor(sec / secInHour) +
      ':' +
      Math.floor(min / secInMin) +
      ':' +
      (sec % secInMin),
  };
};

// 期間計算
export const getTimes = (fromTime: string, sec: number) => {
  fromTime = fromTime.replace(new RegExp('-', 'gm'), '/');
  const startDate = new Date(fromTime).getTime();
  const d3 = startDate + sec * 1000;
  const endDate = timestampToTime(Number(d3));
  return endDate;
};

// timestamp転換
export const timestampToTime = (timestamp: any) => {
  const date = new Date(timestamp);
  const Y = date.getFullYear() + '-';
  const M = timeAdd0((date.getMonth() + 1).toString()) + '-';
  const D = timeAdd0(date.getDate().toString()) + ' ';
  const h = timeAdd0(date.getHours().toString()) + ':';
  const m = timeAdd0(date.getMinutes().toString()) + ':';
  const s = timeAdd0(date.getSeconds().toString());
  return Y + M + D + h + m + s;
};

//10桁のように補足
export const timeAdd0 = (str: string) => {
  if (str.length <= 1) {
    str = '0' + str;
  }
  return str;
};

//分から時間、分を返す
export const formatMinutes = (min: number) => {
  if (!min) {
    return {
      hours: 0,
      minutes: 0,
    };
  }
  return {
    hours: Math.floor(min / 60),
    minutes: min % 60,
  };
};

// YYYY/MM/ddをYYYY年MM月dd日に変換
export const date = (str: string) => {
  if (!str) return;
  const formDate = format(
    parse(str, 'yyyy/MM/dd', new Date()),
    'yyyy年M月d日',
    {
      locale: ja,
    },
  );
  return formDate;
};

// YYYY-MM-ddをYYYY年MM月dd日に変換
export const hyphenDate = (str: string | undefined) => {
  if (!str) return;
  const dateFormat = str.includes('-') ? 'yyyy-MM-dd' : 'yyyy/MM/dd';
  const formDate = format(parse(str, dateFormat, new Date()), 'yyyy年M月d日', {
    locale: ja,
  });
  return formDate;
};

//年月からその月の日数を配列にして返す
export const getMonthDays = (year: number, month: number) => {
  const options: string[] = [];

  //datefnsのgetDaysInMonthは次の月の日数を持ってくるため調整
  if (month == 1) {
    year -= 1;
    month = 12;
  } else {
    month -= 1;
  }
  const daysOfMonth = getDaysInMonth(new Date(year, month));
  for (let i = 1; i <= daysOfMonth; i++) {
    const option = i < 10 ? '0' + i : String(i);
    options.push(option);
  }
  return options;
};

export const getAddMonth = (date: string, AddMonth: number) => {
  const currentDate = parse(date, 'yyyy/MM/dd', new Date());

  const newDate = add(currentDate, {
    months: AddMonth,
  });
  return format(newDate, 'yyyy/M/d');
};
const today = new Date();

//nヶ月前の最初のDateを返す
export const getStartDateSomeMonthsAgo = (
  month: number,
  date = today,
): Date => {
  return startOfMonth(subMonths(date, month));
};

//nヶ月前の最後のDateを返す
export const getEndDateSomeMonthsAgo = (month: number, date = today): Date => {
  return endOfMonth(subMonths(date, month));
};

//現在から半年分の月を返す
export const createMonths = (value: any) => {
  const monthList = [];
  let Y = value.split('/')[0];
  const curY = Y;
  let M = value.split('/')[1];
  for (let i = 0; i < 6; i++) {
    let dateoption = '';
    if (M - 1 == 0) {
      M = 12;
      Y = Y - 1;
    }
    let m = '';
    if (String(M).length < 2) {
      m = '0' + M;
    } else {
      m = String(M);
    }
    dateoption = m + '月';
    M--;
    monthList.push(dateoption);
  }
  return monthList;
};

// YYYY/M/dの日付の月をMMの形で返す
export const getDoubleDigitMonth = (date: string) => {
  const month = parse(date, 'yyyy/M/dd', new Date()).getMonth() + 1;
  const MM = ('0' + month).slice(-2);
  return MM;
};

// 1桁の数値を0が先頭の2桁の文字列で返す
// 3 → 03
export const createTwoDigitString = (numericChar: string) => {
  if (numericChar.length !== 1) return numericChar;
  return `0${numericChar}`;
};

//今日から１週間前の分の7日のデータを返す
export const createOneWeek = () => {
  const currentWeekDate = new Date();
  const currentWeekYear = currentWeekDate.getFullYear();
  const currentWeekMonth =
    currentWeekDate.getMonth() + 1 < 10
      ? '0' + (currentWeekDate.getMonth() + 1)
      : currentWeekDate.getMonth() + 1;
  const currentWeekDay = currentWeekDate.getDate();
  const weekDate =
    currentWeekYear + '/' + currentWeekMonth + '/' + currentWeekDay;

  const weeklyDateList = [];
  for (let i = 0; i < 7; i++) {
    let year = getXAxis(weekDate, 0).substring(-4, 4);
    if (getXAxis(weekDate, -i).substring(-4, 4) === year && i != 0) {
      weeklyDateList.unshift(getXAxis(weekDate, -i).substring(0, 7));
    } else {
      weeklyDateList.unshift(getXAxis(weekDate, -i));
    }
  }
  return weeklyDateList;
};

//YYYY/mm/ddをDate型に変える
export const stringToDate = (dateString: string) => {
  const date = parse(dateString, 'yyyy/MM/dd', new Date());
  return date;
};

export const isAfterMonthDay = (dateValue: Date, type: number) => {
  const after18Month = GetAfterMonthDay(dateValue, 17);
  const system = getNowTime();
  if (type == 1) {
    if (system < after18Month) {
      return true;
    } else {
      return false;
    }
  } else {
    if (after18Month <= system) {
      return true;
    } else {
      return false;
    }
  }
};

//num分動かした年月日を/表記で返す(例:2022/02/28)
export const GetAfterMonthDay = (date: Date, num: number) => {
  const addDate = addMonths(date, num);
  const daysOfMonth = getDaysInMonth(new Date(addDate));
  const newDate = setDate(addDate, daysOfMonth);
  const dateStr = format(newDate, 'yyyy/M/d');
  return dateStr;
};

//今の年月日を/表記で返す
export const getNowTime = (type = '/') => {
  return format(new Date(), `yyyy${type}M${type}d`);
};

export const rTime = (date: Date) => {
  return new Date(
    new Date(+new Date(date) + 9 * 3600 * 1000)
      .toISOString()
      .replace(/T/g, ' ')
      .replace(/-/g, '/')
      .replace(/\.[\d]{3}Z/, ''),
  );
};

//現在の月日を返す
export const nowMonthDay = () => {
  const now = new Date();
  const month = createTwoDigitString(String(getMonth(now) + 1));
  const day = createTwoDigitString(String(getDate(now)));
  return month + day;
};

//次月の年月日を返す
export const nextMonthDay = (type = '/') => {
  const nowDay = new Date();
  const next = 1;
  const prev = addMonths(nowDay, next);
  return format(prev, `yyyy${type}M${type}d`);
};

export const hyphenToTime = (dateStr: string | undefined) => {
  if (!dateStr) return '';
  const formatString = 'yyyy-MM-dd HH:mm:ss';
  try {
    const date = parse(dateStr, formatString, new Date());
    const newDate = format(date, 'HH:mm', { locale: ja });
    return newDate;
  } catch (e) {
    if (e instanceof Error) {
      console.error(`日付の形式が不正:${dateStr}`);
    }
    return dateStr;
  }
};

export const getYearOptions = (from: number, length: number) => {
  const options = [];
  for (let i = from; i <= from + length; i++) {
    options.push(String(i));
  }
  return options;
};

export const getMonthOptions = () => {
  const options = [];
  const months = 12;
  for (let i = 1; i <= months; i++) {
    const option = i < 10 ? '0' + i : String(i);
    options.push(option);
  }
  return options;
};

export const getDayInMonthOptions = (year?: number, month?: number) => {
  const options = [];
  let daysOfMonth = 28;
  if (year && month) {
    //datefnsのgetDaysInMonthは次の月の日数を持ってくるため調整
    if (month == 1) {
      year -= 1;
      month = 12;
    } else {
      month -= 1;
    }
    daysOfMonth = getDaysInMonth(new Date(year, month));
  }
  for (let i = 1; i <= daysOfMonth; i++) {
    const option = i < 10 ? '0' + i : String(i);
    options.push(option);
  }
  return options;
};

export const formatDateISO = (date: string) => {
  return parseISO(date);
};

export const convertToDay = (
  dateStr: string | undefined,
  isDayOfWeek?: boolean,
) => {
  if (!dateStr) return '';
  const formatStr = isDayOfWeek ? 'yyyy年M月d日' : 'yyyy年M月d日(E)';
  try {
    const date = parse(dateStr, 'yyyy-MM-dd HH:mm:ss', new Date());
    const newDate = format(date, formatStr, { locale: ja });
    return newDate;
  } catch (e) {
    if (e instanceof Error) {
      console.error(`日付の形式が不正:${dateStr}`);
    }
    return dateStr;
  }
};

export const convertToDayByFormat = (
  dateStr: string,
  parseFormat: string = 'yyyy-MM-dd HH:mm:ss',
  isDayOfWeek?: boolean,
) => {
  const formatStr = isDayOfWeek ? 'yyyy年M月d日' : 'yyyy年M月d日(E)';
  try {
    const date = parse(dateStr, parseFormat, new Date());
    const newDate = format(date, formatStr, { locale: ja });
    return newDate;
  } catch (e) {
    if (e instanceof Error) {
      console.error(`日付の形式が不正:${dateStr}`);
    }
    return dateStr;
  }
};

export const convertUtcJp = (date: string) => {
  if (!date) return;

  const newDate = parseISO(date);
  const timeUtc = addMinutes(newDate, newDate.getTimezoneOffset());

  return formatDate(addHours(timeUtc, 9));
};

// Date型をyyyy/MM/ddに変換する関数
export const dateToSlashConnection = (date: Date) => {
  return format(date, 'yyyy/M/d');
};

export const getCurrentTimeInTokyo = () => {
  // for IE
  const date = new Date();
  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  const utc = date.getTime() + date.getTimezoneOffset() * 60 * 1000;

  // create new Date object for different city
  // using supplied offset
  const offset = 9; // Tokyo
  const tokyoDate = new Date(utc + 60 * 60 * 1000 * offset);

  return tokyoDate;
};

export const removeTimeFromDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
