import { FC } from 'react';
import { AlertCount } from '../AlertCount/AlertCount';
import { AlertGraph } from '../AlertGraph/AlertGraph';
import style from './AlertItemStyle.module.css';
import './AlertItemStyle.scss';

interface AlertItemProps {
  title: string;
  total: number;
  average: number;
  dayList?: string[];
  serialDataList?: number[];
  piecesColor?: string;
  isGraph?: boolean;
  unitXLabel?: string;
  unitYLabel?: string;
  width?: number;
}

export const AlertItem: FC<AlertItemProps> = ({
  title,
  dayList,
  total,
  average,
  serialDataList,
  piecesColor,
  isGraph = false,
  unitXLabel = '',
  unitYLabel = '',
  width = 100,
}) => {
  return (
    <div style={{ width: '100%' }}>
      <h4 className={style.heading4}>{title}</h4>
      <AlertCount total={total} average={average} />

      {isGraph && (
        <div
          className={style.graph}
          style={{
            width: width + '%',
            height: '160px',
            marginLeft: 100 - width + '%',
          }}
        >
          <AlertGraph
            xAxisData={dayList}
            seriesDataList={serialDataList}
            axisLabelInterval={5}
            axisLabelIntervalY={2}
            piecesColor={piecesColor}
            unitXLabel={unitXLabel}
            unitYLabel={unitYLabel}
          />
        </div>
      )}
    </div>
  );
};
