import { getKeyByValue } from './utils';
import {
  affiliateId as AFFILIATE_ID,
  AffiliateId,
  prepaidName,
  Shop,
  shopTheme,
  themeId as THEME_ID,
  ThemeId,
} from '@/consts/shop';
import { ApiParamsWithMAffiliateId } from '@/types/api/authApi';

export const switchShopTheme = (id: ThemeId) => {
  switch (id) {
    case THEME_ID.CARINC:
      return shopTheme.carinc;
    case THEME_ID.CARMINIKU:
      return shopTheme.carminiku;
    case THEME_ID.KUSUNAGA:
      return shopTheme.kusunaga;
    case THEME_ID.SANWA:
      return shopTheme.sanwa;
    case THEME_ID.YOSHIDA:
      return shopTheme.yoshida;
    case THEME_ID.JNET:
      return shopTheme.jnet;
    case THEME_ID.FLEX:
      return shopTheme.flex;
    case THEME_ID.ROYAL:
      return shopTheme.royal;
    case THEME_ID.CARCHS:
      return shopTheme.carchs;
    case THEME_ID.PROUD:
      return shopTheme.proud;
    case THEME_ID.TSUCHIYA:
      return shopTheme.tsuchiya;
    case THEME_ID.ACTION1:
      return shopTheme.action1;
    case THEME_ID.YOSHIDA_COLTD:
      return shopTheme.yoshida_coltd;
    case THEME_ID.GTNET:
      return shopTheme.gtnet;
    case THEME_ID.KIND_OUTLET:
      return shopTheme.kind_outlet;
    case THEME_ID.LIBERALA:
      return shopTheme.liberala;
    case THEME_ID.YAMADA:
      return shopTheme.yamada;
    default:
      return shopTheme.default;
  }
};

export const switchPrepaidName = (id: ThemeId) => {
  switch (id) {
    case THEME_ID.YOSHIDA:
      return prepaidName.USAGI_PREPAID;
    default:
      return prepaidName.DEFAULT;
  }
};

export const generatePrepaidName = (prepaidName?: string) => {
  return prepaidName || 'マイくるPay';
};

export const getThemeIdFromAffiliateId = (affiliateId: number) => {
  return THEME_ID[
    (getKeyByValue(AFFILIATE_ID, affiliateId) ?? 'DEFAULT') as Uppercase<Shop>
  ];
};

export const getAffiliateIdFromThemeId = (themeId: ThemeId) => {
  const shopName = getKeyByValue(THEME_ID, themeId) ?? 'DEFAULT';
  return AFFILIATE_ID[shopName as Uppercase<Shop>];
};

export const addAffiliateIdToRequestParams = (
  affiliateId: AffiliateId,
  requestParams: ApiParamsWithMAffiliateId,
  shouldConvertToString: boolean = true,
): void => {
  if (affiliateId === AFFILIATE_ID.DEFAULT) return;
  requestParams.m_affiliate_id = shouldConvertToString
    ? String(affiliateId)
    : affiliateId;
  return;
};
