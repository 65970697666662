import { FC } from 'react';
import style from './amountPayStyle.module.css';

interface AmountPayProps {
  title: string;
  bodyPrice: number;
  totalOptionCost: number;
  totalExpenses: number;
  totalTrade: number;
  totalPayment: number;
}
export const AmountPay: FC<AmountPayProps> = ({
  title,
  bodyPrice,
  totalOptionCost,
  totalExpenses,
  totalTrade,
  totalPayment,
}) => {
  return (
    <div className={style['amount-pay-info']}>
      <div className="heading-with-button u-font-size-xl u-font-bold">
        {title}
      </div>
      <div className={style['pay-item']}>
        <span>車体本体価格</span>
        <span>{bodyPrice.toLocaleString()}円</span>
      </div>
      <div className={style['pay-item']}>
        <span>オプション費用合計</span>
        <span>{totalOptionCost.toLocaleString()}円</span>
      </div>
      <div className={style['pay-item']}>
        <span>諸費用合計</span>
        <span>{totalExpenses.toLocaleString()}円</span>
      </div>
      <div className={style['pay-item']}>
        <span>下取り額合計</span>
        <span className={style['pay-item-minus']}>
          {totalTrade?.toLocaleString?.()}円
        </span>
      </div>
      <div className={style['pay-total']}>
        <div className={style['pay-item-title']}>
          <span>お支払い総額</span>
          <br />
          <span className={style['item-note']}>税・工賃料込み</span>
        </div>
        <span className="u-font-size-xl u-font-bold">
          {totalPayment.toLocaleString()}円
        </span>
      </div>
    </div>
  );
};
