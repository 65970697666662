import { Block } from 'framework7-react';
import { FC } from 'react';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import './updateReservingsStyle.scss';

export const UpdateReservings: FC = () => {
  const handlePlanSelected = () => {
    return;
  };

  const planList = [
    {
      plan_name: 'test',
      reserve_amount: 1000,
      comment: 'test',
      benefits_comment1: 'test',
      benefits_comment2: 'test',
      plan_id: '0000',
    },
  ];

  const isContract = true;

  const myPlan = {
    plan_id: '0000',
  };

  return (
    <PopupPageTemplate title="積み立てを再開" pageName="registerPayment">
      {/* プラン情報 */}
      <Block>
        <div className="u-margin-bottom-sm">
          <span className="u-font-bold u-font-size-xl">
            プランを選択してください
          </span>
        </div>
        {planList.map((item, index) => (
          <div
            key={index}
            className="prepaid-plan-info u-margin-bottom-lg"
            style={{ height: 'auto' }}
          >
            <div className="u-padding-top-sm u-padding-right-sm u-padding-bottom-sm u-padding-left-sm">
              <div className="u-font-size-xl u-font-bold">{item.plan_name}</div>
              <div className="u-font-size-lg">
                {item.reserve_amount} 円 / 月を積み立て
              </div>
              <div className="u-font-size-lg" style={{ color: 'orange' }}>
                {item.comment}
              </div>
              {item.benefits_comment1 && (
                <div>
                  <hr
                    className="u-margin-top-sm u-margin-bottom-sm"
                    style={{
                      height: '1px',
                      border: 'none',
                      backgroundColor: '#ebeced',
                    }}
                  />
                  <div className="u-font-size-xl u-font-bold u-margin-bottom-xs">
                    さらに！このプランだけの特典
                  </div>
                  <div className="u-font-size-lg">{item.benefits_comment1}</div>
                  <div className="u-font-size-lg" style={{ color: 'orange' }}>
                    {item.benefits_comment2}
                  </div>
                </div>
              )}
              <div className="prepaid-plan-info--footer u-margin-top-sm">
                {isContract && item.plan_id === myPlan.plan_id && (
                  <div className="button button-round button-outline color-theme-gray button-large">
                    選択中のプランです
                  </div>
                )}
                <Button
                  v-else
                  text="このプランにする"
                  handleClickAction={handlePlanSelected}
                />
              </div>
            </div>
          </div>
        ))}
      </Block>
    </PopupPageTemplate>
  );
};
