import { FC } from 'react';
import { CarIconProps } from './CarIcon';

export const CarIcon5: FC<CarIconProps> = ({ color, reference }) => {
  return (
    <svg
      ref={reference}
      width="728"
      height="536"
      viewBox="0 0 90 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_24640_96989)">
        <rect
          x="0.75"
          y="0.75"
          width="88.5"
          height="65.5"
          rx="15.25"
          fill="#F3F5F6"
          stroke="#EFEFEF"
          strokeWidth="1.5"
        />
        <mask
          id="mask0_24640_96989"
          maskUnits="userSpaceOnUse"
          x="12"
          y="13"
          width="65"
          height="41"
        >
          <path d="M76.5 13H12V53.5H76.5V13Z" fill="white" />
        </mask>
        <g mask="url(#mask0_24640_96989)">
          <path
            d="M12 38.5C12 31.8726 17.3726 26.5 24 26.5L28.5141 18.8824C30.675 15.236 34.5991 13 38.8376 13H64.5131C68.387 13 71.8263 15.4789 73.0513 19.154L76.5 29.5V43.4998C76.5 45.7089 74.7091 47.4998 72.5 47.4998H16C13.7909 47.4998 12 45.7089 12 43.4998V38.5Z"
            fill={color}
          />
          <path
            d="M24 27.75C24.4415 27.75 24.8503 27.5171 25.0754 27.1373L29.5895 19.5196C31.5252 16.2531 35.0406 14.25 38.8376 14.25H64.5131C67.849 14.25 70.8106 16.3846 71.8655 19.5493L75.25 29.7028V43.4998C75.25 45.0186 74.0187 46.2498 72.5 46.2498H16C14.4812 46.2498 13.25 45.0186 13.25 43.4998V38.5C13.25 32.5629 18.0629 27.75 24 27.75Z"
            stroke="white"
            strokeWidth="2.5"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.2904 27.2647C25.0203 27.7205 24.5298 28 24 28C18.201 28 13.5 32.701 13.5 38.5V43.4998C13.5 44.8805 14.6193 45.9998 16 45.9998H72.5C73.8807 45.9998 75 44.8805 75 43.4998V29.7434L71.6283 19.6283C70.6074 16.5657 67.7414 14.5 64.5131 14.5H38.8376C35.1289 14.5 31.6953 16.4565 29.8046 19.6471L25.2904 27.2647ZM76.5 29.5L73.0513 19.154C71.8263 15.4789 68.387 13 64.5131 13H38.8376C34.5991 13 30.675 15.236 28.5141 18.8824L24 26.5C17.3726 26.5 12 31.8726 12 38.5V43.4998C12 45.7089 13.7909 47.4998 16 47.4998H72.5C74.7091 47.4998 76.5 45.7089 76.5 43.4998V29.5Z"
            fill="#323232"
          />
          <path
            d="M33.0031 21.6692C34.3867 19.0991 37.0454 17.5 39.9348 17.5H53.013C53.5581 17.5 54 17.9477 54 18.5V27.5C54 28.0523 53.5581 28.5 53.013 28.5H30.9884C30.2392 28.5 29.7632 27.6875 30.1219 27.0211L33.0031 21.6692Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.5005 19H39.7495C37.3709 19 35.1822 20.2993 34.0432 22.3875L31.5273 27H52.5005V19ZM39.7495 17.5C36.822 17.5 34.1282 19.0991 32.7264 21.6692L29.8071 27.0211C29.4436 27.6875 29.9259 28.5 30.685 28.5H53.0005C53.5527 28.5 54.0005 28.0523 54.0005 27.5V18.5C54.0005 17.9477 53.5527 17.5 53.0005 17.5H39.7495Z"
            fill="#323232"
          />
          <path
            d="M67.4911 20.3305C67.0856 18.6749 65.4993 17.5 63.6694 17.5H57.9798C57.4387 17.5 57 17.907 57 18.4091V26.5909C57 27.093 57.4387 27.5 57.9798 27.5H68.0198C68.6486 27.5 69.1146 26.9583 68.9752 26.3894L67.4911 20.3305Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.5005 19H63.8076C64.9748 19 65.9867 19.8077 66.2454 20.946L67.6213 27H58.5005V19ZM63.8076 17.5C65.6752 17.5 67.2942 18.7924 67.7081 20.6135L69.2229 27.2784C69.3651 27.9041 68.8894 28.5 68.2477 28.5H58.0005C57.4482 28.5 57.0005 28.0523 57.0005 27.5V18.5C57.0005 17.9477 57.4482 17.5 58.0005 17.5H63.8076Z"
            fill="#323232"
          />
          <path
            d="M37 45C37 49.6944 33.1944 53.5 28.5 53.5C23.8056 53.5 20 49.6944 20 45C20 40.3056 23.8056 36.5 28.5 36.5C33.1944 36.5 37 40.3056 37 45Z"
            fill="#C9C9C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.5 52C32.366 52 35.5 48.866 35.5 45C35.5 41.134 32.366 38 28.5 38C24.634 38 21.5 41.134 21.5 45C21.5 48.866 24.634 52 28.5 52ZM28.5 53.5C33.1944 53.5 37 49.6944 37 45C37 40.3056 33.1944 36.5 28.5 36.5C23.8056 36.5 20 40.3056 20 45C20 49.6944 23.8056 53.5 28.5 53.5Z"
            fill="#323232"
          />
          <path
            d="M71 45C71 49.6944 67.1944 53.5 62.5 53.5C57.8056 53.5 54 49.6944 54 45C54 40.3056 57.8056 36.5 62.5 36.5C67.1944 36.5 71 40.3056 71 45Z"
            fill="#C9C9C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.5 52C66.366 52 69.5 48.866 69.5 45C69.5 41.134 66.366 38 62.5 38C58.634 38 55.5 41.134 55.5 45C55.5 48.866 58.634 52 62.5 52ZM62.5 53.5C67.1944 53.5 71 49.6944 71 45C71 40.3056 67.1944 36.5 62.5 36.5C57.8056 36.5 54 40.3056 54 45C54 49.6944 57.8056 53.5 62.5 53.5Z"
            fill="#323232"
          />
          <path
            d="M33 45C33 47.4853 30.9853 49.5 28.5 49.5C26.0147 49.5 24 47.4853 24 45C24 42.5147 26.0147 40.5 28.5 40.5C30.9853 40.5 33 42.5147 33 45Z"
            fill="#F7F7F7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.5 48C30.1569 48 31.5 46.6569 31.5 45C31.5 43.3431 30.1569 42 28.5 42C26.8431 42 25.5 43.3431 25.5 45C25.5 46.6569 26.8431 48 28.5 48ZM28.5 49.5C30.9853 49.5 33 47.4853 33 45C33 42.5147 30.9853 40.5 28.5 40.5C26.0147 40.5 24 42.5147 24 45C24 47.4853 26.0147 49.5 28.5 49.5Z"
            fill="#323232"
          />
          <path
            d="M67 45C67 47.4853 64.9853 49.5 62.5 49.5C60.0147 49.5 58 47.4853 58 45C58 42.5147 60.0147 40.5 62.5 40.5C64.9853 40.5 67 42.5147 67 45Z"
            fill="#F7F7F7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.5 48C64.1569 48 65.5 46.6569 65.5 45C65.5 43.3431 64.1569 42 62.5 42C60.8431 42 59.5 43.3431 59.5 45C59.5 46.6569 60.8431 48 62.5 48ZM62.5 49.5C64.9853 49.5 67 47.4853 67 45C67 42.5147 64.9853 40.5 62.5 40.5C60.0147 40.5 58 42.5147 58 45C58 47.4853 60.0147 49.5 62.5 49.5Z"
            fill="#323232"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_24640_96989">
          <rect width="90" height="67" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
