import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useCallback, useEffect, useState } from 'react';
import { getDealWarrantyInfoApi } from '@/api/warrantyApi';
import {
  GetDealWarrantyInfoApiParams,
  GetDealWarrantyInfoApiResponse,
} from '@/types/api/warrantyApi';

export const useGetDealWarrantyInfo = ({
  m_customer_id,
  t_stock_car_id,
  warranty_no,
  branch_no,
  initialData,
}: {
  m_customer_id: number;
  t_stock_car_id: number;
  warranty_no: string;
  branch_no: number;
  initialData?: GetDealWarrantyInfoApiResponse['data'];
}) => {
  const [data, setData] = useState<
    GetDealWarrantyInfoApiResponse['data'] | undefined
  >(initialData);
  const [error, setError] = useState<AxiosError>();

  const getData = useCallback(async () => {
    try {
      const params: GetDealWarrantyInfoApiParams = initialData
        ? {
            t_repair_recept_id: initialData.repair_recept_id,
          }
        : {
            m_customer_id,
            t_stock_car_id,
            warranty_no,
            branch_no,
          };
      const res = await getDealWarrantyInfoApi(params);
      setData(res.data);
    } catch (err) {
      setError(err as AxiosError);
    }
  }, [branch_no, initialData, m_customer_id, t_stock_car_id, warranty_no]);

  const refetch = () => {
    f7.preloader.show();
    return getData().finally(() => {
      f7.preloader.hide();
    });
  };

  useEffect(() => {
    f7.preloader.show();
    getData().finally(() => {
      f7.preloader.hide();
    });
  }, [getData]);

  return { data, error, refetch };
};
