import { f7 } from 'framework7-react';
import { useState } from 'react';
import { sendInquiryAboutTelApi } from '@/api/inquiryApi';
import { useToast } from '@/hooks/useToast';
import { SendInquiryAboutTelApiParams } from '@/types/api/queryApi';

export const useSendInquiryAboutTel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const { openToast } = useToast();
  const sendInquiry = async (params: SendInquiryAboutTelApiParams) => {
    let isSuccess = false;
    try {
      f7.preloader.show();
      setIsLoading(true);
      const { data } = await sendInquiryAboutTelApi(params);
      if (!data.success) {
        throw new Error(data.error?.message || '送信に失敗しました。');
      }
      isSuccess = true;
    } catch (err) {
      if (err instanceof Error) {
        openToast(err.message, 'toast-failed');
        setError(err);
      }
      isSuccess = false;
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
      return isSuccess;
    }
  };

  return {
    sendInquiry,
    isLoading,
    error,
  };
};
