import { Link, Page, Popup } from 'framework7-react';
import { PopupProps } from 'framework7-react/components/popup';
import { FC, ReactNode } from 'react';
import style from './popupPageTemplateStyle.module.css';
import { f7CustomBack } from '@/utils/utils';

interface PopupPageTemplateProps extends PopupProps {
  pageName: string;
  title: string | ReactNode;
  iconType?: string;
  children: ReactNode;
  isCloseIcon?: boolean;
  containerClass?: string;
  handleBack?: () => void;
  closeWrapperClass?: string;
}

export const PopupPageTemplate: FC<PopupPageTemplateProps> = ({
  pageName,
  title,
  iconType = 'close',
  isCloseIcon = true,
  children,
  containerClass = '',
  handleBack = undefined,
  closeWrapperClass = '',
  ...rest
}) => {
  const handleCustomClickBack = () => {
    if (handleBack) {
      return handleBack();
    }

    f7CustomBack();
  };

  return (
    <Popup {...rest} closeByBackdropClick={false}>
      <Page name={pageName}>
        {isCloseIcon && (
          <div className={`${style['close-wrapper']} ${closeWrapperClass}`}>
            <Link
              onClick={handleCustomClickBack}
              animate
              className={style['close']}
            >
              <i className={`icon-${iconType}`} style={{ color: '#232323' }} />
            </Link>
          </div>
        )}
        <div className={`${style['popup-container']} ${containerClass}`}>
          <h1 className={style['title']}>{title}</h1>
          {children}
        </div>
      </Page>
    </Popup>
  );
};
