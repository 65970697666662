import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import style from './textReadStyle.module.css';
import { PopupTerms } from '@/components/projects/PopupTerms/PopupTerms';
import { Button } from '@/components/uiParts/Button';
import { TermType } from '@/types/api/termsApi';

interface TextReadProps {
  showButton?: boolean;
  buttonText?: string;
  title: string;
  children: React.ReactNode;
  path?: string;
  f7router: Router.Router;
  backgroundColor?: string;
  termType?: TermType;
  html?: string;
}

export const TextRead: FC<TextReadProps> = ({
  showButton = true,
  buttonText = '続きを読む',
  title,
  path = '',
  children,
  backgroundColor = 'white',
  termType = undefined,
  html = undefined,
}) => {
  const handleClickButton = () => {
    if (path) {
      f7.views.main.router.navigate(path, {
        props: {
          termType,
          title,
          html,
        },
      });
      return;
    }
    setIsShowTerm(true);
  };

  const handleClose = () => {
    setIsShowTerm(false);
  };

  const [isShowTerm, setIsShowTerm] = useState(false);

  return (
    <div className={style[`container-${backgroundColor}`]}>
      <h1 className={style['title']}>{title}</h1>
      <div className={style['content-wrapper']}>
        <div className={style['content']}>{children}</div>
        <div className={style[`mask-${backgroundColor}`]} />
      </div>
      {showButton && (
        <div className={style['bottom-box']}>
          <div className={style['button-wrapper']}>
            <Button
              handleClickAction={handleClickButton}
              className={style[`button-${backgroundColor}`]}
              colorTheme="gray"
            >
              {buttonText}
            </Button>
          </div>
        </div>
      )}
      <PopupTerms
        title={title}
        html={children}
        open={isShowTerm}
        handleClose={handleClose}
      />
    </div>
  );
};
