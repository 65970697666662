import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './smsShouldBeAvailableStyle.module.css';
import { ReactComponent as SupportIcon } from '@/assets/images/login/icon_support.svg';
import { CustomerPinInquiryBox } from '@/components/projects/CustomerPinInquiryBox/CustomerPinInquiryBox';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { supportCenter } from '@/consts/inquiry';

interface SmsShouldBeAvailableProps {
  f7router: Router.Router;
}
const tel = supportCenter.TEL;

export const SmsShouldBeAvailable: FC<SmsShouldBeAvailableProps> = ({
  f7router,
}) => {
  const handleClickContact = () => {
    // TODO: サポートセンターに電話するボタン押下時の処理
    window.location.href = `tel://${tel}`;
  };
  return (
    <PopupPageTemplate title="" pageName="SmsDisable2">
      <div className={style['container']}>
        <h3 className={style['title']}>SMS を受け取れない番号をお使いの方</h3>
        <SupportIcon width="75px" />
        <p
          className={style['pre-wrap']}
        >{`メールアドレスでログインいただけるよう\n設定をさせていただきます`}</p>

        <div className={style['support-box']}>
          <p>
            大変お手数ですが、
            サポートセンターにご連絡の上メールアドレスの登録手続きをお願いいたします
          </p>
        </div>
        <CustomerPinInquiryBox
          infoList={[
            'お客様のお名前',
            `お客様のお電話番号`,
            'お客様の生年月日',
          ]}
        />
        <div>
          <p className={style['text-sub']}>
            「メールアドレスでの利用登録」 とお伝えください
          </p>
          <div className={style['buttons-box']}>
            <Button
              icon="icon-telephone"
              bold={false}
              handleClickAction={handleClickContact}
            >
              サポートセンターに電話する
            </Button>
          </div>
        </div>
      </div>
    </PopupPageTemplate>
  );
};
