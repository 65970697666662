import { Button, Card, Link, f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { ProblemGuide } from './ProblemGuide';
import { ProblemInsurance } from './ProblemInsurance';
import { ProblemNotes } from './ProblemNotes';
import { ProblemTerms } from './ProblemTerms';
import { CheckBox } from '@/components/uiParts/Form';
import { ShieldMaintenanceIcon } from '@/components/uiParts/Icon';
import { NotifyByDate } from '@/components/uiParts/NotifyByDate/NotifyByPeriod';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useMAffiliateSettings } from '@/hooks/api/info/useFetchMAffiliateSettings';
import './ProblemStyles.scss';
import { f7CustomBack } from '@/utils/utils';

type ProblemProps = {
  f7router: Router.Router;
  supportCenterTel: string;
  isFromContact: boolean;
};

export const Problem: FC<ProblemProps> = ({
  f7router,
  supportCenterTel,
  isFromContact,
}) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const { customerInfo } = store.state;
  const mAffiliateId = customerInfo.m_affiliate_id;
  const { data } = useMAffiliateSettings({ m_affiliate_id: mAffiliateId });

  const handleChangeValid = () => {
    setIsValid(!isValid);
  };

  const handleClickContact = () => {
    window.location.href = 'tel://' + data?.data.callcenter_tel;
  };
  return (
    <PageTemplate
      pageName="Problem"
      title="車が不調な気がする"
      showNavBack
      handleClickBack={() => {
        if (isFromContact) {
          const history = f7.view.main.history.slice(-3)[0];
          f7.view.main.router.navigate(`${history}`);
          f7.view.main.router.navigate('/contact/');
          return;
        }
        f7CustomBack();
      }}
    >
      <NotifyByDate
        displayStartDate="2023/12/25"
        displayEndDate="2024/01/04"
        title="サポートセンター冬季休業につきまして"
        description={`2023年12月28日～2024年1月3日の間、サポートセンター冬季休業とさせていただきます。
休業期間中にいただきましたお問い合わせにつきましては、2024年1月4日（木）より順次ご連絡させていただきます。
ご迷惑をお掛けいたしますが、何卒ご了承いただきますようお願い申し上げます。

※ ロードサービスは休業期間中もご利用いただくことができます。`}
      />
      <div className="u-text-center u-margin-bottom-0">
        <Card className="u-margin-bottom-0">
          <ShieldMaintenanceIcon />
          <div className="u-margin-bottom-md u-margin-top-sm banner-title">
            以下に記載されております内容をご確認の上、
            <br />
            「修理を依頼する」より修理受付を
            <br />
            行ってください。
          </div>
        </Card>
      </div>
      <ProblemGuide />
      <ProblemInsurance />
      <ProblemTerms />
      <ProblemNotes />
      <CheckBox
        label="上記の内容を確認しました。"
        className="checkbox u-margin-top-md"
        onChange={handleChangeValid}
      />
      <div>
        <Link
          className="u-margin-top-md"
          style={{ textDecoration: 'underline' }}
          onClick={handleClickContact}
        >
          サポートセンターに電話する
        </Link>
      </div>
      <Button
        disabled={!isValid}
        fill
        round
        color={isValid ? 'theme' : 'gray'}
        style={{ marginTop: '6.4rem', marginBottom: '3.5rem' }}
        onClick={() => f7.view.main.router.navigate(paths.carRepair)}
      >
        修理を依頼する
      </Button>
    </PageTemplate>
  );
};
