import { FC } from 'react';

interface LocationBadgeProps {
  value: string;
}
export const LocationBadge: FC<LocationBadgeProps> = ({ value }) => {
  return (
    <span
      style={{
        padding: '4px 8px',
        borderRadius: 4,
        background: 'rgba(30, 85, 230, 0.1)',
        color: '#1E55E6',
        fontSize: 10,
        fontWeight: 600,
        marginRight: 8,
      }}
    >
      {value}
    </span>
  );
};
