import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import style from './UpdatePasswordStyle.module.css';
import { Button } from '@/components/uiParts/Button';
import { TextInput } from '@/components/uiParts/Input/TextInput';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { store } from '@/config/store';
import { useUpdatePassword } from '@/hooks/api/customer/useUpdatePassword';
import { f7CustomBack } from '@/utils/utils';

interface FormInput {
  password: string;
  new_password: string;
  new_confirm_password: string;
}

export const UpdatePassword: FC = () => {
  const mCustomerId = store.state.authInfo.m_customer_id;

  const { updatePassword } = useUpdatePassword();
  const passwordSchema = yup.object().shape({
    password: yup.string().required('入力必須です'),
    new_password: yup.string().required('入力必須です'),
    new_confirm_password: yup
      .string()
      .required('入力必須です')
      .oneOf([yup.ref('new_password')], 'パスワードが一致していません'),
  });
  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: { password: '', new_password: '', new_confirm_password: '' },
    resolver: yupResolver(passwordSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  // パスワード更新処理
  const onSubmit: SubmitHandler<FormInput> = async ({
    password,
    new_password,
  }) => {
    const result = await updatePassword({
      m_customer_id: mCustomerId,
      old_password: password,
      new_password,
    });

    if (result) {
      f7CustomBack(['#updatePasswordPopUp']);
    }
  };

  return (
    <PopupPageTemplate
      id="updatePasswordPopUp"
      pageName="UpdatePassword"
      title="ログインパスワードの変更"
    >
      <FormProvider {...formMethods}>
        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="現在のパスワード"
            inputName="password"
            isCheckIcon
            isCheckIconValid={false}
            error={errors.password?.message}
            inputType="password"
            placeholder="********"
          />
          <TextInput
            label="新しいパスワード"
            inputName="new_password"
            isCheckIcon
            isCheckIconValid={false}
            error={errors.new_password?.message}
            inputType="password"
            placeholder="********"
          />
          <TextInput
            label="新しいパスワード（確認用）"
            inputName="new_confirm_password"
            isCheckIcon
            isCheckIconValid={false}
            error={errors.new_confirm_password?.message}
            inputType="password"
            placeholder="********"
          />
          <div className={style['button-wrap']}>
            <Button text="変更する" type="submit" />
          </div>
        </form>
      </FormProvider>
    </PopupPageTemplate>
  );
};
