import { Router } from 'framework7/types';
import { RankPage } from '@/components/pages';
import { paths } from '@/config/paths';

export const rankRoutes: Router.RouteParameters[] = [
  {
    path: paths.rank,
    name: '会員ランクについて',
    popup: {
      component: RankPage,
    },
    options: {
      animate: true,
    },
  },
];
