import { Fab, List, ListItem } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useState } from 'react';
import { Dialog } from '@/components/uiParts/Dialog/Dialog';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import { useCancelReason } from '@/hooks/api/warranty/useCancelReason';
import { useStopUpdateNextYearWarranty } from '@/hooks/api/warranty/useStopUpdateNextYear';
import { Warranty } from '@/types/api/customerApi';
import { hyphenToDay } from '@/utils/date';
import './CancelWarrantyStyles.scss';

type CancelWarrantyProps = {
  f7router: Router.Router;
  warranty: Warranty | undefined;
};

export const CancelWarranty: FC<CancelWarrantyProps> = ({ warranty }) => {
  const [cancelComment, setCancelComment] = useState('');
  const [reasonCd, setReasonCd] = useState<number>(1);
  const [showDialog, setShowDialog] = useState(false);

  const warrantyNo = warranty?.warranty_no ?? '';
  const warrantyEndDate = warranty?.append_warranties?.[0]?.warranty_end_date;

  const changeReason = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReasonCd(Number(e.target.value));
  };

  const cancelConfirmText =
    '解約手続きが完了すると、<br />保証期間満了をもって保証サービスを<br />ご利用いただけなくなります。また、再度保証に<br />加入することができなくなります。<br />よろしいですか？';

  const { data: cancelReasonLists } = useCancelReason();

  const { stopUpdateNextYearWarranty } = useStopUpdateNextYearWarranty();

  const handleCancelWarranty = () => {
    const params = {
      t_base_warranty_id: warranty?.t_base_waranty_id ?? 0,
      branch_no: warranty?.branch_no ?? 0,
      warranty_type:
        warranty?.append_warranties?.slice(-1)[0].warranty_type ?? 0,
      apply_reason_cd: reasonCd ?? 0,
      apply_text: cancelComment,
      apply_type: 2,
    };
    stopUpdateNextYearWarranty(params, warrantyNo);
  };

  const handleOpen = () => {
    setShowDialog(true);
  };

  const handleClosed = () => {
    setShowDialog(false);
  };

  return (
    <PopupPageTemplate
      id="CancelWarranty"
      pageName="CancelWarranty"
      title="保証の解約"
    >
      <div className="notes">
        <div
          className="u-padding-bottom-sm"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div>
            <i
              className="icon-info"
              style={{ fontSize: '40px', color: 'blueviolet' }}
            />
          </div>
          <div className="note-title u-padding-left-sm u-padding-top-xs">
            注意事項
          </div>
        </div>
        <hr color="#FFBCC1" />
        <div className="note-text">
          解約手続きが完了すると、保証期間満了をもって保証サービスをご利用いただけなくなります。
          <br />
          また、再度保証に加入することができなくなります。
        </div>
      </div>
      <div className="title">保証満了日</div>
      <div>
        <div className="date">{hyphenToDay(warrantyEndDate ?? '', true)}</div>
      </div>
      <div className="title">ご利用いただけなくなるサービス</div>
      <div>
        <List simpleList>
          <ListItem className="content-list" title="修理保証" />
          <ListItem className="content-list" title="ロードサービス" />
          <ListItem className="content-list" title="LINK Drive" />
          <ListItem className="content-list" title="未使用のクーポンのご利用" />
        </List>
      </div>
      <div className="title">引き続きご利用いただけるサービス</div>
      <div>
        <div className="subtitle">
          下記のサービス・機能は引き続きアプリからご利用いただけます。
        </div>
        <List simpleList>
          <ListItem className="content-list" title="整備のご依頼" />
          <ListItem className="content-list" title="車両状態・整備履歴の確認" />
          <ListItem className="content-list" title="プリペイドカード" />
        </List>
      </div>
      <div className="title">その他の注意事項</div>
      <div>
        <List simpleList>
          <ListItem
            className="content-list"
            title="解約手続き完了後の手続きの取消はできません"
          />
          <ListItem
            className="content-list other-notes"
            title="解約後の同車両ならびに同契約者様における再加入は不可となっております"
          />
        </List>
      </div>
      <div className="title">解約手続き</div>
      <div style={{ height: '480px' }}>
        <div className="subtitle">
          今後のサービスの向上のため、下記のアンケートにお答えください
        </div>
        <div className="input input-dropdown">
          <div className="title">
            解約理由 <span className="label-tips required">必須</span>
          </div>
          <div
            className={`cancel-select cancel-select-not-value ${
              cancelReasonLists?.data.length && reasonCd
                ? 'cancel-select-with-value'
                : ''
            }`}
          >
            <select onChange={changeReason}>
              {cancelReasonLists?.data.map((item, index) => (
                <option key={index} value={item.code_key}>
                  {item.code_value}
                </option>
              ))}
            </select>
          </div>
        </div>
        {reasonCd === 99 && (
          <div>
            <div
              className="cancel-procedure u-font-bold u-margin-top-md"
              style={{ fontSize: '1.6rem' }}
            >
              その他ご意見ありましたら
              <span className="textarea-garge">任意</span>
              <br />
              お伝えください
            </div>
            <div
              className={`item-input-wrap ${
                cancelComment.length > 0 ? 'item-input-wrap' : ''
              }`}
            >
              <textarea
                className="comment"
                value={cancelComment}
                onChange={(e) => setCancelComment(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      <Fab
        position="center-bottom"
        onClick={handleOpen}
        text="解約手続きを進める"
        color="red"
        style={{ marginBottom: '2rem', position: 'fixed' }}
      />
      {showDialog && (
        <Dialog
          title="最終確認"
          subTitle={cancelConfirmText}
          buttonOkText="解約する"
          handleOk={handleCancelWarranty}
          handleCancel={handleClosed}
        />
      )}
    </PopupPageTemplate>
  );
};
