import { Router } from 'framework7/types';
import { FC } from 'react';
import { WarrantyCancel } from '../../WarrantyManagement/WarrantyCancel/WarrantyCancel';
import style from './UpdateWarrantyDetailComponent.module.css';
import Accordion from '@/components/uiParts/Accordion/Accordion';
import SubItemList from '@/components/uiParts/Accordion/SubItemList/SubItemList';
import { Button } from '@/components/uiParts/Button';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { ListRoundSheet } from '@/components/uiParts/Sheet/ListRoundSheet/ListRoundSheet';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { UpdateWarrantyDetailCustomer } from '@/types/warranty';
import { hyphenToYear } from '@/utils/date';
import { isBankOrPayEasy } from '@/utils/payment';
import { switchWarrantyPartsTitleByCoverageType } from '@/utils/warranty';

interface UpdateWarrantyDetailComponentProps {
  f7router: Router.Router;
  customer: UpdateWarrantyDetailCustomer;
  isAllowToChangePlan: boolean;
  isUpdatable: boolean;
  handleClickCancel: () => void;
  handleClickButton: () => void;
}
export const UpdateWarrantyDetailComponent: FC<
  UpdateWarrantyDetailComponentProps
> = ({
  f7router,
  customer,
  isAllowToChangePlan,
  isUpdatable,
  handleClickCancel,
  handleClickButton,
}) => {
  const LivingInfoValue = () => (
    <div className={style['living-info-wrapper']}>
      <p>{customer.zipCode}</p>
      <p>{customer.address1}</p>
      <p>{customer.address2}</p>
    </div>
  );

  const planList = [
    { label: '保証名', value: customer.warrantyName },
    { label: '保証期間', value: `${customer.deductibleTerm}ヶ月` },
    {
      label: '保証限度額',
      value: customer?.amountLimit
        ? `${customer.amountLimit.toLocaleString()}円`
        : '無制限',
    },
    {
      label: '保証料総額',
      value: `${(customer?.warrantyFee ?? 0).toLocaleString()}円`,
    },
  ];

  const contractorList = [
    { label: '契約者様氏名', value: customer.contractorName },
    { label: 'フリガナ', value: customer.contractorNameKana },
    // { label: '法人名', value: customer.corporationName },
    { label: '生年月日', value: customer.birthDay },
    { label: '携帯番号', value: customer.mobilePhone },
    { label: '自宅電話番号', value: customer.homePhone },
  ];

  const livingInfoList = [{ label: '住所', value: <LivingInfoValue /> }];

  const carNumberList = [
    {
      label: 'ナンバープレート',
      value: `${customer.transportName} ${customer.classNo} ${customer.hiragana} ${customer.registryNo}`,
    },
  ];

  const paymentMethodValue = (numberOfPayment: number) => {
    // 支払い回数が１回のとき
    // eslint-disable-next-line no-magic-numbers
    if (numberOfPayment === 1) {
      return '一括払い';
    }
    return `分割(${numberOfPayment}回払い)`;
  };

  const monthlyAmountValue = (
    <div className={style['monthly-amount-value']}>
      <span>{customer.loanNumF.toLocaleString()}円</span>
      <span>初月のみ {customer.loanNumS.toLocaleString()}円</span>
    </div>
  );

  const payList = [
    { label: '支払い方法', value: paymentMethodValue(customer.loanNum) },
    { label: '月額', value: monthlyAmountValue },
    {
      label: '総額',
      value: `${(customer?.warrantyFee || 0).toLocaleString()}円`,
    },
  ];

  const getPaymentMethodObj = () => {
    const paymentObj = {
      iconName: 'icon-credit-card',
      label: 'カードNo',
      number: customer.cardNo,
    };
    if (isBankOrPayEasy(customer.paymentTypeId)) {
      paymentObj.iconName = 'icon-bank';
      paymentObj.label = '口座番号';
      paymentObj.number = customer.accountNumber;
    }
    return paymentObj;
  };
  const paymentMethodObj = getPaymentMethodObj();

  return (
    <PageTemplate
      pageName="UpdateWarrantyDetail"
      title={customer.warrantyName}
      showNavBack
    >
      <div className={style['container']}>
        <RoundSheet>
          <>
            <div className={style['top-sheet-upper']}>
              <InfoCard
                bgColor="warning"
                text={
                  '適用開始前の保証です\nプランの変更は下記日程から適用されます'
                }
                textSub={`変更適用日：${hyphenToYear(
                  customer.warrantyStartDate,
                  false,
                )}`}
                iconType="confirm-notification"
              />
            </div>
            {isAllowToChangePlan && (
              <div className={style['top-sheet-down']}>
                {/* <p>こちらの内容で変更を承っております。</p> */}
                <p className={style['red']}>
                  変更内容の修正可能期間は
                  {hyphenToYear(customer.cancellationPeriodTo, false)}
                  までです。
                </p>
                <p>
                  修正可能期間を過ぎると、その時点の変更内容が適用されます。
                </p>
              </div>
            )}
          </>
        </RoundSheet>
        <ListRoundSheet
          labelWidth="150px"
          title="お申し込みプラン"
          list={planList}
        />
        <ListRoundSheet
          title={switchWarrantyPartsTitleByCoverageType(customer.coveringType)}
        >
          {customer.coveringType !== null && (
            <Accordion
              items={customer.partsList}
              renderTitle={(item) => item.m_accessory_category_name}
              renderContent={(item, index) => (
                <SubItemList
                  subItems={item.subItems}
                  key={index}
                  renderSubItem={(subItem) => subItem.parts_name || ''}
                />
              )}
            />
          )}
        </ListRoundSheet>
        <ListRoundSheet
          labelWidth="150px"
          title="契約者情報"
          list={contractorList}
        />
        <ListRoundSheet
          labelWidth="150px"
          title="契約者様お住まい情報"
          list={livingInfoList}
        />
        <ListRoundSheet
          labelWidth="150px"
          title="対象車両ナンバー"
          list={carNumberList}
        />
        <ListRoundSheet
          labelWidth="150px"
          title="お支払い情報"
          list={payList}
        />
        {/* 保証を解約する */}
        <WarrantyCancel
          f7router={f7router}
          handleClickLink={handleClickCancel}
          isCancelable={customer.cancelEnableTerm}
          isUpdatable={isUpdatable}
          cancelableTerm={customer.cancelEnableTermEnd}
        />
        {isAllowToChangePlan && (
          <>
            <div className={style['button-wrapper']}>
              <div>
                <Button handleClickAction={handleClickButton}>
                  変更内容を修正する
                </Button>
              </div>
            </div>
            <div className={style['modifiable-periods']}>
              修正可能期間：
              {hyphenToYear(customer.cancellationPeriodTo, false)}
            </div>
          </>
        )}
      </div>
    </PageTemplate>
  );
};
