import { Toolbar } from 'framework7-react';
import React, { FC } from 'react';
import style from './DriveHistoryToolbarStyle.module.css';

interface DriveHistoryToolbarProps {
  date: string;
  time: string;
}

export const DriveHistoryToolbar: FC<DriveHistoryToolbarProps> = ({
  date,
  time,
}) => {
  return (
    <Toolbar
      className={`tab-bar u-font-size-lg u-font-bold ${style['toolbar']}`}
      labels
      no-hairline
    >
      {/* Todo: ページ遷移はvueで未実装のためのちに実装 */}
      {/* <div className={`${style['link']} ${style['left-link']}`}>
        <i className={`icon-left ${style['icon-left']}`} />
        <span className={`${style['left-link-text']}`}>前</span>
      </div> */}
      <div className={`${style['date-time']}`}>
        <span className={`${style['date']}`}>{date}</span>
        <br />
        <span className={`${style['time']}`}>{time}</span>
      </div>
      {/* <div className={`${style['link']} ${style['right-link']}`}>
        <span className={`${style['right-link-text']}`}>次</span>
        <i className={`icon-right ${style['icon-right']}`} />
      </div> */}
    </Toolbar>
  );
};
