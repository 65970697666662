import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { updateCarApi } from '@/api/carApi';
import { UpdateCarParams, UpdateCarResponse } from '@/types/api/carApi';

interface Args {
  tStockCarId: string;
  params: UpdateCarParams;
  onSuccess?: () => void;
}

export const useUpdateCar = () => {
  const [data, setData] = useState<UpdateCarResponse['data']>();
  const [error, setError] = useState<AxiosError>();

  const updateCar = async (args: Args) => {
    try {
      f7.preloader.show();
      const { data } = await updateCarApi(args.tStockCarId, args.params);
      setData(data);
      if (data.success) args.onSuccess?.();
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    updateCar,
    data,
    error,
  };
};
