import { Checkbox as F7Checkbox } from 'framework7-react';
import { CheckboxProps as f7CheckBoxProps } from 'framework7-react/components/checkbox';
import { FC } from 'react';
import style from './CheckboxStyle.module.css';
interface CheckBoxProps extends f7CheckBoxProps {
  label: string;
}

export const CheckBox: FC<CheckBoxProps> = ({ label, className, ...props }) => {
  return (
    <F7Checkbox
      className={`${style['checkbox-wrapper']} ${className}`}
      {...props}
    >
      {label}
    </F7Checkbox>
  );
};
