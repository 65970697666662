import * as Sentry from '@sentry/react';
import { f7 } from 'framework7-react';
import { storeDispatch } from './store';
import { isApp } from '@/config/device';
import { initialState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { setupProcessingStatus } from '@/consts/linkDrive';
import { themeId } from '@/consts/shop';
import { url } from '@/consts/url';

const {
  adTelemileageContent,
  loginInfo,
  authInfo,
  customerInfo,
  carItemInfo,
  carsInfo,
  billsInfo,
  paymentMethodsInfo,
  advancePaymentsInfo,
  notificationsInfo,
  campaignsInfo,
  affiliatesInfo,
  isInfoLoaded,
  ownerId,
  activeTabId,
  linkDriveOwnerInfo,
  recordDetailItem,
  serialNumberInput,
  linkDriveSetupStatusCompleted,
  calibrationInterruptionStep,
  updateWarrantyInfo,
  updateLoan,
  fromPage,
  coupons,
  serialNumberDb,
  currentWarranty,
  nextWarranty,
  warranties,
  nextWarranties,
  updateWarranties,
  settingsPrevPage,
  isImpactInfoChecked,
  prepaidChargePayment,
  menu,
} = initialState;

export const logout = async () => {
  try {
    f7.preloader.show();
    store.state.linkDriveAbortController.abort();
    window.localStorage.clear();
    await Promise.all([
      storeDispatch(
        'setAdTelemileageContent',
        'adTelemileageContent',
        adTelemileageContent,
      ),
      storeDispatch('setLoginInfo', 'loginInfo', loginInfo),
      storeDispatch('setCustomerInfo', 'customerInfo', customerInfo),
      storeDispatch('setAuthInfo', 'authInfo', authInfo),
      storeDispatch('setCarItemInfo', 'carItemInfo', carItemInfo),
      storeDispatch('setCarsInfo', 'carsInfo', carsInfo),
      storeDispatch('setBillsInfo', 'billsInfo', billsInfo),
      storeDispatch(
        'setPaymentMethodsInfo',
        'paymentMethodsInfo',
        paymentMethodsInfo,
      ),
      storeDispatch(
        'setAdvancePaymentsInfo',
        'advancePaymentsInfo',
        advancePaymentsInfo,
      ),
      storeDispatch(
        'setNotificationsInfo',
        'notificationsInfo',
        notificationsInfo,
      ),
      storeDispatch(
        'setIsImpactInfoChecked',
        'isImpactInfoChecked',
        isImpactInfoChecked,
      ),
      storeDispatch('setAffiliatesInfo', 'affiliatesInfo', affiliatesInfo),
      storeDispatch('setCampaignsInfo', 'campaignsInfo', campaignsInfo),
      storeDispatch('setIsInfoLoaded', 'isInfoLoaded', isInfoLoaded),
      storeDispatch('setOwnerId', 'ownerId', ownerId),
      storeDispatch('setActiveTabId', 'activeTabId', activeTabId),
      storeDispatch(
        'setLinkDriveOwnerInfo',
        'linkDriveOwnerInfo',
        linkDriveOwnerInfo,
      ),
      storeDispatch(
        'setRecordDetailItem',
        'recordDetailItem',
        recordDetailItem,
      ),
      storeDispatch(
        'setSerialNumberInput',
        'serialNumberInput',
        serialNumberInput,
      ),
      storeDispatch(
        'setLinkDriveAbortController',
        'linkDriveAbortController',
        new AbortController(),
      ),
      storeDispatch(
        'setLinkDriveUpdating',
        'linkDriveUpdating',
        setupProcessingStatus.INACTIVE,
      ),
      storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0),
      storeDispatch(
        'setLinkDriveSetupStatusCompleted',
        'linkDriveSetupStatusCompleted',
        linkDriveSetupStatusCompleted,
      ),
      storeDispatch(
        'setCalibrationInterruptionStep',
        'calibrationInterruptionStep',
        calibrationInterruptionStep,
      ),
      storeDispatch(
        'setUpdateWarrantyInfo',
        'updateWarrantyInfo',
        updateWarrantyInfo,
      ),
      storeDispatch('setUpdateLoan', 'updateLoan', updateLoan),
      storeDispatch('setFromPage', 'fromPage', fromPage),
      storeDispatch('setCoupons', 'coupons', coupons),
      storeDispatch('setSerialNumberDb', 'serialNumberDb', serialNumberDb),
      storeDispatch('setWarranties', 'warranties', warranties),
      storeDispatch('setNextWarranties', 'nextWarranties', nextWarranties),
      storeDispatch('setCurrentWarranty', 'currentWarranty', currentWarranty),
      storeDispatch('setNextWarranty', 'nextWarranty', nextWarranty),
      storeDispatch(
        'setUpdateWarranties',
        'updateWarranties',
        updateWarranties,
      ),
      storeDispatch(
        'setPrepaidChargePayment',
        'prepaidChargePayment',
        prepaidChargePayment,
      ),
    ]);
    storeDispatch('setSettingsPrevPage', 'settingsPrevPage', settingsPrevPage);
    storeDispatch('setMenu', 'menu', menu);
    // 加盟店ではなくマイくるに対してログインした場合は、ログアウト時にthemeIdをデフォルトに戻す
    if (store.state.isLoggedInToMaikuru) {
      await storeDispatch('setGlobalThemeId', 'globalTheme', null);
      await storeDispatch('setThemeId', 'themeId', themeId.DEFAULT);
    }
    // ログアウトをネイティブアプリに知らせる
    if (isApp) {
      window.location.href = url.NATIVE_BASE_URL + paths.logout;
    }

    Sentry.setUser(null);
    f7.views.main.router.navigate(paths.login, {
      reloadAll: true,
    });
  } catch (err) {
    console.error(err);
    f7.dialog.alert('ログアウトに失敗しました', 'エラー', () => {
      f7.dialog.close();
    });
    throw new Error('logout error');
  } finally {
    f7.preloader.hide();
  }
};
