import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { registerBankRequestApi } from '@/api/payment';
import { RegisterBankRequestParams } from '@/types/api/payment';

export const useResiterBankRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const registerBankRequest = async (params: RegisterBankRequestParams) => {
    try {
      f7.preloader.show();
      setIsLoading(true);
      const response = await registerBankRequestApi(params);
      if (response.data.data.errorMessages == '') {
        window.location.href = response.data.data.bankUrl;
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    registerBankRequest,
    isLoading,
    error,
  };
};
