import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { MarginType, SecuritySystem } from '@/types/api/reservationApi';

const validationSchema = yup.object().shape({
  engineStart: yup.bool().required().oneOf([true]),
  dateAnnotation: yup.bool().required().oneOf([true]),
  badWeather: yup.bool().required().oneOf([true]),
  cancelFee: yup.bool().required().oneOf([true]),
  workspace: yup.object().shape({
    marginType: yup.number().required(),
    securitySystem: yup.number().required(),
  }),
});

export interface Step3FormData {
  engineStart: boolean;
  dateAnnotation: boolean;
  badWeather: boolean;
  cancelFee: boolean;
  workspace: {
    marginType?: MarginType;
    securitySystem?: SecuritySystem;
  };
}

export const useCarWashConditionerStep3 = (initialState: Step3FormData) => {
  const formMethods = useForm<Step3FormData>({
    defaultValues: initialState,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  return {
    formMethods,
  };
};
