import { FC } from 'react';
import { CarIconProps } from './CarIcon';

export const CarIcon6: FC<CarIconProps> = ({ color, reference }) => {
  return (
    <svg
      ref={reference}
      width="728"
      height="536"
      viewBox="0 0 90 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_24640_96990)">
        <rect
          x="0.75"
          y="0.75"
          width="88.5"
          height="65.5"
          rx="15.25"
          fill="#F3F5F6"
          stroke="#EFEFEF"
          strokeWidth="1.5"
        />
        <g clipPath="url(#clip1_24640_96990)">
          <mask
            id="mask0_24640_96990"
            maskUnits="userSpaceOnUse"
            x="9"
            y="14"
            width="72"
            height="39"
          >
            <path d="M81 14H9V53H81V14Z" fill="white" />
          </mask>
          <g mask="url(#mask0_24640_96990)">
            <path
              d="M9.22339 36.5138C9.09534 31.3919 12.8592 27.0018 17.9405 26.3461L21.3246 25.9095C23.3581 25.6471 25.2065 24.5948 26.47 22.9802C30.9061 17.3119 37.7036 14 44.9014 14H67.372C72.9964 14 77.8661 17.9064 79.0862 23.3968L80.8075 31.1427C80.9342 31.7125 80.998 32.2945 80.998 32.8782V43.5C80.998 45.7091 79.2072 47.5 76.998 47.5H13.3993C11.2291 47.5 9.45478 45.7695 9.40054 43.6L9.22339 36.5138Z"
              fill={color}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M78.5005 43.5V32.8782C78.5005 32.4769 78.4566 32.0768 78.3695 31.6851L76.6482 23.9392C75.6823 19.5926 71.8271 16.5 67.3744 16.5H44.9039C38.4749 16.5 32.4035 19.4581 28.4413 24.521C26.7727 26.653 24.332 28.0425 21.647 28.3889L18.2629 28.8256C14.4519 29.3173 11.629 32.6099 11.725 36.4514L11.9022 43.5375C11.9225 44.3511 12.5879 45 13.4017 45H77.0005C77.8289 45 78.5005 44.3284 78.5005 43.5ZM17.943 26.3461C12.8616 27.0018 9.09778 31.3919 9.22583 36.5138L9.40298 43.6C9.45722 45.7695 11.2315 47.5 13.4017 47.5H77.0005C79.2096 47.5 81.0005 45.7091 81.0005 43.5V32.8782C81.0005 32.2945 80.9366 31.7125 80.81 31.1427L79.0887 23.3968C77.8686 17.9064 72.9988 14 67.3744 14H44.9039C37.706 14 30.9086 17.3119 26.4725 22.9802C25.2089 24.5948 23.3605 25.6471 21.3271 25.9095L17.943 26.3461Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M79.5005 43.5V32.8782C79.5005 32.4039 79.4486 31.9311 79.3457 31.4681L77.6244 23.7222C76.5568 18.9181 72.2958 15.5 67.3744 15.5H44.9039C38.1674 15.5 31.8055 18.5996 27.6537 23.9047C26.1472 25.8297 23.9434 27.0843 21.519 27.3971L18.1349 27.8338C13.8158 28.3911 10.6165 32.1227 10.7254 36.4763L10.9025 43.5625C10.9364 44.9184 12.0454 46 13.4017 46H77.0005C78.3812 46 79.5005 44.8807 79.5005 43.5ZM17.943 26.3461C12.8616 27.0018 9.09778 31.3919 9.22583 36.5138L9.40298 43.6C9.45722 45.7695 11.2315 47.5 13.4017 47.5H77.0005C79.2096 47.5 81.0005 45.7091 81.0005 43.5V32.8782C81.0005 32.2945 80.9366 31.7125 80.81 31.1427L79.0887 23.3968C77.8686 17.9064 72.9988 14 67.3744 14H44.9039C37.706 14 30.9086 17.3119 26.4725 22.9802C25.2089 24.5948 23.3605 25.6471 21.3271 25.9095L17.943 26.3461Z"
              fill="#323232"
            />
            <path
              d="M32 45C32 49.4183 28.4183 53 24 53C19.5817 53 16 49.4183 16 45C16 40.5817 19.5817 37 24 37C28.4183 37 32 40.5817 32 45Z"
              fill="#C9C9C9"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 51.5C27.5899 51.5 30.5 48.5899 30.5 45C30.5 41.4101 27.5899 38.5 24 38.5C20.4101 38.5 17.5 41.4101 17.5 45C17.5 48.5899 20.4101 51.5 24 51.5ZM24 53C28.4183 53 32 49.4183 32 45C32 40.5817 28.4183 37 24 37C19.5817 37 16 40.5817 16 45C16 49.4183 19.5817 53 24 53Z"
              fill="#323232"
            />
            <path
              d="M28 45C28 47.2091 26.2091 49 24 49C21.7909 49 20 47.2091 20 45C20 42.7909 21.7909 41 24 41C26.2091 41 28 42.7909 28 45Z"
              fill="#F7F7F7"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 47.5C25.3807 47.5 26.5 46.3807 26.5 45C26.5 43.6193 25.3807 42.5 24 42.5C22.6193 42.5 21.5 43.6193 21.5 45C21.5 46.3807 22.6193 47.5 24 47.5ZM24 49C26.2091 49 28 47.2091 28 45C28 42.7909 26.2091 41 24 41C21.7909 41 20 42.7909 20 45C20 47.2091 21.7909 49 24 49Z"
              fill="#323232"
            />
            <path
              d="M76 45C76 49.4183 72.4183 53 68 53C63.5817 53 60 49.4183 60 45C60 40.5817 63.5817 37 68 37C72.4183 37 76 40.5817 76 45Z"
              fill="#C9C9C9"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M68 51.5C71.5899 51.5 74.5 48.5899 74.5 45C74.5 41.4101 71.5899 38.5 68 38.5C64.4101 38.5 61.5 41.4101 61.5 45C61.5 48.5899 64.4101 51.5 68 51.5ZM68 53C72.4183 53 76 49.4183 76 45C76 40.5817 72.4183 37 68 37C63.5817 37 60 40.5817 60 45C60 49.4183 63.5817 53 68 53Z"
              fill="#323232"
            />
            <path
              d="M72 45C72 47.2091 70.2091 49 68 49C65.7909 49 64 47.2091 64 45C64 42.7909 65.7909 41 68 41C70.2091 41 72 42.7909 72 45Z"
              fill="#F7F7F7"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M68 47.5C69.3807 47.5 70.5 46.3807 70.5 45C70.5 43.6193 69.3807 42.5 68 42.5C66.6193 42.5 65.5 43.6193 65.5 45C65.5 46.3807 66.6193 47.5 68 47.5ZM68 49C70.2091 49 72 47.2091 72 45C72 42.7909 70.2091 41 68 41C65.7909 41 64 42.7909 64 45C64 47.2091 65.7909 49 68 49Z"
              fill="#323232"
            />
            <path
              d="M33.3287 24.0463C35.7831 21.4628 39.1903 20 42.7537 20H49.1727C50.2772 20 51.1727 20.8954 51.1727 22V29C51.1727 29.5523 50.725 30 50.1727 30H30.002C29.1234 30 28.6719 28.9482 29.277 28.3113L33.3287 24.0463Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M49.0156 21.5H42.6466C39.5189 21.5 36.5283 22.794 34.3741 25.0794L31.1498 28.5H49.5117V22C49.5117 21.7239 49.2896 21.5 49.0156 21.5ZM42.6466 20C39.1109 20 35.7302 21.4628 33.295 24.0463L29.2748 28.3113C28.6744 28.9482 29.1224 30 29.9942 30H50.0078C50.5558 30 51 29.5523 51 29V22C51 20.8954 50.1115 20 49.0156 20H42.6466Z"
              fill="#323232"
            />
            <path
              d="M74.5408 25.403C74.2341 22.3358 71.6531 20 68.5706 20H54.0005C53.4482 20 53.0005 20.4477 53.0005 21V29C53.0005 29.5523 53.4482 30 54.0005 30H73.8955C74.4871 30 74.9494 29.4892 74.8905 28.9005L74.5408 25.403Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M54.5072 21.5H68.6447C70.9676 21.5 72.9126 23.2518 73.1438 25.5522L73.44 28.5H54.5072V21.5ZM68.6447 20C71.7419 20 74.3353 22.3358 74.6435 25.403L74.9949 28.9005C75.0541 29.4892 74.5896 30 73.9951 30H54.0048C53.4499 30 53 29.5523 53 29V21C53 20.4477 53.4499 20 54.0048 20H68.6447Z"
              fill="#323232"
            />
            <path
              d="M34 41.75C34 41.3358 34.3358 41 34.75 41H47.25C47.6642 41 48 41.3358 48 41.75C48 42.1642 47.6642 42.5 47.25 42.5H34.75C34.3358 42.5 34 42.1642 34 41.75Z"
              fill="#323232"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_24640_96990">
          <rect width="90" height="67" fill="white" />
        </clipPath>
        <clipPath id="clip1_24640_96990">
          <rect
            width="72"
            height="39"
            fill="white"
            transform="translate(9 14)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
