import { List, ListItem } from 'framework7-react';
import { FC } from 'react';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { Warranty } from '@/types/api/customerApi';
import { hyphenToDay } from '@/utils/date';
import './cancelWarrantyDetailStyle.scss';

type CancelWarrantyDetailProps = {
  warranty: Warranty | undefined;
};
export const CancelWarrantyDetail: FC<CancelWarrantyDetailProps> = ({
  warranty,
}) => {
  const warrantyEndDate = warranty?.append_warranties?.[0]?.warranty_end_date;

  return (
    <PageTemplate
      pageName="CancelWarrantyDetail"
      title="保証の解約"
      showNavBack
    >
      <div className="home__wrapper">
        <div style={{ marginTop: '30px', marginBottom: '30px' }}>
          <RoundSheet>
            <h2 className="u-padding-top-sm u-padding-bottom-sm">
              ご契約中の保証プラン
            </h2>
            <div className="notes">
              <div
                className="u-padding-bottom-sm"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <i
                    className="icon-info"
                    style={{ fontSize: '40px', color: '#FF4655' }}
                  />
                </div>
                <div className="note-title u-padding-left-sm u-padding-top-xs">
                  注意事項
                </div>
              </div>
              <hr color="#FFBCC!" />
              <div className="note-content">
                保証期間満了をもって保証サービスをご利用いただけなくなります。
                <br />
                また、再度保証に加入することができなくなります。
              </div>
            </div>

            <div className="block-inner">
              <h2>保証満了日</h2>
              <div className="content-list">
                {hyphenToDay(warrantyEndDate ?? '', true)}
              </div>
            </div>

            <div className="block-inner">
              <h2>ご利用いただけなくなるサービス</h2>
              <List simple-list>
                <ListItem title="修理保証" />
                <ListItem title="ロードサービス" />
                <ListItem title="LINK Drive" />
                <ListItem title="未使用のクーポンのご利用" />
              </List>
            </div>

            <div className="block-inner">
              <h2>引き続きご利用いただけるサービス</h2>
              <div
                className="u-padding-top-xs u-padding-bottom-xs"
                style={{ fontSize: '1.6rem', fontWeight: 'normal' }}
              >
                下記のサービス・機能は引き続きアプリからご利用いただけます
              </div>
              <List simple-list>
                <ListItem className="content-list" title="整備のご依頼" />
                <ListItem
                  className="content-list"
                  title="車両状態・整備履歴の確認"
                />
                <ListItem className="content-list" title="プリペイドカード" />
              </List>
            </div>

            <div className="block-inner">
              <h2>その他の注意事項</h2>
              <List simple-list>
                <ListItem
                  className="content-list"
                  title="解約手続き完了後の手続きの取消はできません"
                />
                <ListItem
                  className="content-list"
                  title="解約後の同車両ならびに同契約者様における再加入は不可となっております"
                />
              </List>
            </div>
          </RoundSheet>
        </div>
      </div>
    </PageTemplate>
  );
};
