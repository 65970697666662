import { f7, Icon } from 'framework7-react';
import { FC, useRef, useState } from 'react';
import { TRADE_GUARANTEE_STEPS } from '../hooks/useTradeGuarantee';
import { ImageZoom } from './ImageZoom';
import style from './Upload.module.css';
import { ReactComponent as IconPlus } from '@/assets/images/menu/icon-plus-circle-2.svg';
import { UploadConfirmDialog } from '@/components/projects/UploadConfirmDialog/UploadConfirmDialog';
import { Button } from '@/components/uiParts/Button';
import { SuccessDialog } from '@/components/uiParts/SuccessDialog/SuccessDialog';
import { Documents, WarrantyDocument } from '@/types/api/warrantyApi';
import {
  getDescriptionDocument,
  renderDescriptionDocument,
} from '@/utils/warranty';

interface UploadProps {
  initialFiles?: File[];
  selectedDocument?: WarrantyDocument;
  handleUpload: (files: File[]) => Promise<void>;
  setStep: (step: number) => void;
  uploadedDocument: Record<number, Documents>;
}

export const Upload: FC<UploadProps> = ({
  initialFiles,
  selectedDocument,
  handleUpload,
  setStep,
  uploadedDocument,
}) => {
  const [files, setFiles] = useState<File[]>(initialFiles || []);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [zoomTarget, setZoomTarget] = useState<string | null>(null);

  const indexSelectedDocument = Number(
    Object.keys(uploadedDocument).find(
      (el) => selectedDocument?.document_type === Number(el),
    ),
  );
  const filesUploaded = uploadedDocument[indexSelectedDocument];

  const onClickUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles([...files, ...Array.from(e.target.files)]);
    }
  };

  const onZoomClick = (file: File) => {
    setZoomTarget(URL.createObjectURL(file));
  };

  const onDeleteClick = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  const onUpload = () => {
    if (!files.length) return;
    f7.preloader.show();
    handleUpload(files)
      .then(() => {
        setIsSuccessDialogOpen(true);
      })
      .catch(() => {
        f7.dialog.alert('', 'アップロードに失敗しました。');
      })
      .finally(() => {
        setIsConfirmDialogOpen(false);
        f7.preloader.hide();
      });
  };

  const FileUploaded = () => {
    return (
      <>
        {filesUploaded?.files?.map((file, index) => (
          <div className={style['section']} key={index}>
            <h3>アップロード済みの書類</h3>
            <div className={style['block']}>
              <div className={style['image-list-item']}>
                <img src={file.url} alt="uploaded" />
                <Button
                  outline
                  style={{
                    color: '#707070',
                    backgroundColor: '#FFFFFF',
                    borderColor: '#707070',
                    fontWeight: 700,
                  }}
                  onClick={() => setZoomTarget(file.url)}
                >
                  <Icon f7="search" size={20} />
                  拡大表示
                </Button>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={style['container']}>
      <div className={style['upload-head']}>
        {getDescriptionDocument(selectedDocument?.document_type) && (
          <p className={style['description']}>
            {renderDescriptionDocument(
              selectedDocument?.name,
              selectedDocument?.document_type,
            )}
          </p>
        )}
        <Button
          outline
          className={style['upload-button']}
          onClick={onClickUpload}
        >
          <IconPlus />
          ファイルを追加する
        </Button>
        <input
          ref={inputRef}
          type="file"
          className={style['upload-input-hidden']}
          accept="image/*"
          onChange={onInputChange}
        />
      </div>

      {files.length > 0 ? (
        <div className={style['section']}>
          <h3>アップロード済みの書類</h3>
          <div className={style['block']}>
            {files.map((file, index) => {
              const src = URL.createObjectURL(file);
              return (
                <div className={style['image-list-item']} key={index}>
                  <img src={src} alt="uploaded" />
                  <div className={style['image-list-button']}>
                    <Button
                      outline
                      style={{
                        color: '#707070',
                        backgroundColor: '#FFFFFF',
                        borderColor: '#707070',
                        fontWeight: 700,
                      }}
                      onClick={() => onZoomClick(file)}
                    >
                      <Icon f7="search" size={20} />
                      拡大表示
                    </Button>
                    <Button
                      outline
                      style={{
                        color: '#FF4655',
                        backgroundColor: '#FFFFFF',
                        borderColor: '#FF4655',
                        fontWeight: 700,
                      }}
                      onClick={() => onDeleteClick(index)}
                    >
                      <Icon f7="trash" size={20} />
                      削除
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <FileUploaded />
      )}
      <div className={style['footer-button']}>
        <Button
          disabled={!files.length}
          onClick={() => setIsConfirmDialogOpen(true)}
        >
          書類をアップロードする
        </Button>
      </div>
      <ImageZoom src={zoomTarget || ''} onClose={() => setZoomTarget(null)} />
      <UploadConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onUpload={onUpload}
      />
      <SuccessDialog
        open={isSuccessDialogOpen}
        onClose={() => {
          setIsSuccessDialogOpen(false);
          setTimeout(() => {
            setStep(TRADE_GUARANTEE_STEPS.UPLOAD_DOCUMENTS);
          });
        }}
      />
    </div>
  );
};
