import { FC, useMemo } from 'react';
import { TRADE_GUARANTEE_DOCUMENTS_STEPS } from '../TradeGuaranteeDocumentsPreviewPage';
import { Upload } from '../Upload/Upload';
import { UploadDocuments } from '../UploadDocuments/UploadDocuments';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { Documents } from '@/types/api/warrantyApi';

interface TradeGuaranteeDocumentsPreviewProps {
  step: number;
  uploadedDocument: Record<number, Documents>;
  selectedDocument?: Documents;
  setStep: (step: number) => void;
  handleClickDocument: (document: Documents) => void;
  handleClickBack: () => void;
}

export const TradeGuaranteeDocumentsPreview: FC<
  TradeGuaranteeDocumentsPreviewProps
> = ({
  step,
  uploadedDocument,
  selectedDocument,
  setStep,
  handleClickDocument,
  handleClickBack,
}) => {
  const title = useMemo(() => {
    if (step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD_DOCUMENTS)
      return '各種書類';
    if (step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD)
      return selectedDocument?.name || '';
    return '';
  }, [selectedDocument?.name, step]);

  return (
    <PageTemplateV2
      pageName="TradeGuaranteeDocumentsPreview"
      title={title}
      showNavBack={true}
      handleClickBack={handleClickBack}
    >
      {step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD_DOCUMENTS && (
        <UploadDocuments
          uploadDocument={uploadedDocument}
          handleClickDocument={handleClickDocument}
        />
      )}
      {step === TRADE_GUARANTEE_DOCUMENTS_STEPS.UPLOAD && (
        <Upload selectedDocument={selectedDocument} />
      )}
    </PageTemplateV2>
  );
};
