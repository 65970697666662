import { FC } from 'react';
import style from './ArrowIconLabelButtonStyle.module.css';

interface ArrowIconLabelButton {
  type: 'prev' | 'next';
  disabled?: boolean;
  label?: string;
  labelSize?: string;
  fontWeight?: string;
  iconSize?: string;
  onClick: () => void;
}
export const ArrowIconLabelButton: FC<ArrowIconLabelButton> = ({
  type,
  disabled = false,
  label = '戻る',
  labelSize = '14px',
  fontWeight = 'bold',
  iconSize = '20px',
  onClick,
}) => {
  const handleClick = disabled ? undefined : onClick;
  const labelStyle = { fontSize: labelSize, fontWeight };
  const iconStyle = { fontSize: iconSize };
  return (
    <div
      className={`${style.wrapper} ${disabled && style.disabled}`}
      onClick={handleClick}
    >
      {type === 'prev' ? (
        <>
          <i className="icon-left" style={iconStyle} />
          <span style={labelStyle}>{label}</span>
        </>
      ) : (
        <>
          <span style={labelStyle}>{label}</span>
          <i className="icon-right" style={iconStyle} />
        </>
      )}
    </div>
  );
};
