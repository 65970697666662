import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { getShownWarranties } from '../../WarrantyManagement/WarrantyManagementHelper';
import style from './accountStyle.module.css';
import { ReactComponent as ExclaminationIcon } from '@/assets/images/icon-exclamationmark_circle_inherit.svg';
import { ReactComponent as CreditIcon } from '@/assets/images/menu/icon-credit.svg';
import { ReactComponent as FileIcon } from '@/assets/images/menu/icon-file.svg';
import { ReactComponent as GuaranteeIcon } from '@/assets/images/menu/icon-guarantee.svg';
import { ReactComponent as HelpIcon } from '@/assets/images/menu/icon-help.svg';
import { ReactComponent as PaidHistoryIcon } from '@/assets/images/menu/icon-history-color.svg';
import { ReactComponent as KeyIcon } from '@/assets/images/menu/icon-key.svg';
import { ReactComponent as LogoutIcon } from '@/assets/images/menu/icon-logout.svg';
import { ReactComponent as NotificationIcon } from '@/assets/images/menu/icon-notification.svg';
import { ReactComponent as PaymentIcon } from '@/assets/images/menu/icon-payment.svg';
import { ReactComponent as SettingIcon } from '@/assets/images/menu/icon-settings.svg';
import { RankBanner } from '@/components/projects/RankBanner/RankBanner';
import { Button } from '@/components/uiParts/Button';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { CustomList } from '@/components/uiParts/List/CustomList';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { isApp } from '@/config/device';
import { LoginInfoState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { loginType } from '@/consts/login';
import { useCustomerInfo } from '@/hooks/api/customer/useCustomerInfo';
import { useToast } from '@/hooks/useToast';
import {
  Car,
  Customer,
  NextWarranty,
  PaymentMethod,
  Prepaid,
  Warranty,
} from '@/types/api/customerApi';
import { LoginType } from '@/types/login';
import { logout } from '@/utils/logout';
import { generatePrepaidName } from '@/utils/shop';
import { storeDispatch } from '@/utils/store';

interface AccountProps {
  f7router: Router.Router;
  themeId: number;
  status: string;
  customerInfo: Required<Customer>;
  paymentMethodsInfo: Required<PaymentMethod>[];
  prepaidInfo: Required<Prepaid>[];
  loginInfo: LoginInfoState;
  currentWarranty: Warranty | undefined;
  nextWarranty: NextWarranty | undefined;
  carItemInfo: Required<Car>;
  carsInfo: Required<Car>[];
}
export const Account: FC<AccountProps> = ({
  f7router,
  themeId,
  status,
  customerInfo,
  paymentMethodsInfo,
  prepaidInfo,
  loginInfo,
  currentWarranty,
  nextWarranty,
  carItemInfo,
  carsInfo,
}) => {
  const { openToast } = useToast();
  const prepaidName = prepaidInfo[0]?.name;
  const { pushPermission } = useStore(store, 'getPermission');
  const getIsInfoCard = () => {
    return customerInfo.phone_no1 || customerInfo.phone_no2 ? false : true;
  };

  const switchLoginInfo = (type: LoginType) => {
    const loginInfo = {
      icon: <KeyIcon {...iconStyle} />,
      text: 'ログイン情報',
      path: '',
      subText: '',
    };
    switch (type) {
      case loginType.TELEPHONE:
        return { ...loginInfo, subText: '電話番号でログイン中' };
      case loginType.EMAIL:
        return {
          ...loginInfo,
          subText: 'メールアドレスでログイン中',
          path: paths.singIn,
        };
      case loginType.FACEBOOK:
        return { ...loginInfo, subText: 'Facebookでログイン中' };
      default:
        return loginInfo;
    }
  };

  const handleClickMenuUseStore = async (
    path: string,
    f7router: Router.Router,
    car?: Required<Car>,
  ) => {
    if (path == paths.prepaidSetting) {
      storeDispatch('setSettingsPrevPage', 'settingsPrevPage', 'account');
    }
    path += car?.m_car_id ? '?car=' + car?.m_car_id : '';
    f7.view.main.router.navigate(path);
  };

  const handleClickLogout = async () => {
    try {
      f7.dialog.confirm(
        'ログアウトしますか?',
        '',
        async () => {
          await logout();
          openToast('ログアウトしました');
        },
        () => {},
      );
    } catch (e) {
      // logout関数内のcatch内でエラーダイアログ表示後,throwされたErrorを受け取る
      if (e instanceof Error) console.error(e.message);
    }
  };

  useCustomerInfo(
    {
      m_customer_id: store.state.authInfo.m_customer_id,
    },
    !status,
  );
  const isInfoCard = getIsInfoCard();
  const iconStyle = { width: 24, height: 24 };
  const customerInfoList = [switchLoginInfo(loginInfo.loginType)];

  const applicationSettingLabelList = [
    {
      icon: <SettingIcon {...iconStyle} />,
      text: 'アプリ設定',
      path: paths.appSettings,
    },
    {
      icon: <NotificationIcon {...iconStyle} />,
      text: '通知設定',
      path: paths.pushSettings,
    },
  ];

  const billingOrPaymentLabelList = [
    {
      icon: <PaymentIcon {...iconStyle} />,
      text: '請求予定',
      path: paths.paymentsSchedule,
    },
    {
      icon: <PaidHistoryIcon {...iconStyle} />,
      text: '支払い履歴',
      path: paths.payments2History,
    },
  ];

  const PaymentInfoLabelList = [
    {
      icon: <PaymentIcon {...iconStyle} />,
      text: 'ご登録の決済方法',
      subText: paymentMethodsInfo.length + '個設定中',
      path: paths.payments,
    },
  ];

  const useServiceLabelList = [
    {
      icon: <PaymentIcon {...iconStyle} />,
      text: prepaidName
        ? generatePrepaidName(prepaidName)
        : `${generatePrepaidName(prepaidName)}(未登録)`,
      path: prepaidInfo[0]?.name ? paths.prepaidSetting : '',
      isRegisteredPrepaid: !!(prepaidInfo[0] && prepaidInfo[0].name),
    },
    ...carsInfo
      .filter(
        (car) =>
          getShownWarranties(car.delivery_complete_flg, car.warranties).length >
          0,
      )
      .map((car) => ({
        icon: <GuaranteeIcon {...iconStyle} />,
        text: '保証',
        subText: car?.car_nm,
        path: paths.warranty('false'),
        car: car,
      })),
  ];

  const serviceOrApplicationLabelList = [
    {
      icon: <CreditIcon {...iconStyle} />,
      text: 'このアプリについて',
      path: paths.version,
    },
    {
      icon: <FileIcon {...iconStyle} />,
      text: '利用規約',
      path: paths.termsOfUse,
    },
    {
      icon: <FileIcon {...iconStyle} />,
      text: 'プライバシーポリシー',
      path: paths.privacy,
    },
    {
      icon: <HelpIcon {...iconStyle} />,
      text: 'ヘルプ',
      path: paths.helpTop,
    },
  ];

  const logoutLabelList = [
    {
      icon: <LogoutIcon {...iconStyle} />,
      text: 'ログアウト',
      path: '',
    },
  ];

  return (
    <PageTemplate pageName="Account" title="アカウント">
      <div className={style['container']}>
        <RoundSheet>
          <div className={style['list-container']}>
            <h3 className={style.heading}>アカウント情報</h3>
            <CustomList
              path={paths.customer}
              text={'お客様情報'}
              isTopBorder={false}
              isBottom={isInfoCard}
              f7router={f7.view.main.router}
            />
            {isInfoCard && (
              <div className={style['icon-card']}>
                <IconCard
                  heading={'電話番号が登録されていません'}
                  iconType="bulb"
                  iconColor="#1E55E6"
                  iconFontSize="30px"
                  color="gray"
                  isTransition
                  path={paths.registerPhone}
                >
                  <p className={style['heading-sub-title']}>
                    電話番号を登録すると、SMS認証で安全にログインできるようになります
                  </p>
                </IconCard>
              </div>
            )}
            {customerInfoList.map((item) => (
              <CustomList
                key={item.text}
                text={item.text}
                subText={item?.subText}
                subColor={'#A5A5A5'}
                path={item.path}
                icon={item.icon}
                f7router={f7.view.main.router}
              />
            ))}
          </div>
        </RoundSheet>
        <RankBanner
          className={style['user-rank']}
          redirectPage={paths.account}
        />
        {isApp && (
          <RoundSheet>
            <div className={style['list-container']}>
              <h3 className={style.heading}>アプリ設定</h3>
              {applicationSettingLabelList.map((item) => (
                <CustomList
                  key={item.text}
                  subText={
                    !pushPermission && item.text === '通知設定'
                      ? '通知の許可がされていません'
                      : ''
                  }
                  subColor={'#f8d573'}
                  text={item.text}
                  isTopBorder={false}
                  path={item.path}
                  icon={item.icon}
                  f7router={f7.view.main.router}
                />
              ))}
            </div>
          </RoundSheet>
        )}
        <RoundSheet>
          <div className={style['list-container']}>
            <h3 className={style.heading}>請求・支払情報</h3>
            {billingOrPaymentLabelList.map((item) => (
              <CustomList
                key={item.text}
                text={item.text}
                path={item.path}
                isTopBorder={false}
                icon={item.icon}
                f7router={f7.view.main.router}
              />
            ))}
          </div>
        </RoundSheet>
        <RoundSheet>
          <div className={style['list-container']}>
            <h3 className={style.heading}>決済情報</h3>
            {paymentMethodsInfo.length ? (
              <>
                {PaymentInfoLabelList.map((item, i) => (
                  <CustomList
                    key={item.text}
                    text={item.text}
                    subText={item.subText}
                    subColor={'#A5A5A5'}
                    isTopBorder={false}
                    path={item.path}
                    icon={item.icon}
                    // eslint-disable-next-line no-magic-numbers
                    isBottom={PaymentInfoLabelList.length - 1 === i}
                    f7router={f7.view.main.router}
                  />
                ))}
                <h3 className={style['using-service']}>ご利用中のサービス</h3>
                {useServiceLabelList.map((item: any, index: number) => (
                  <CustomList
                    key={item.text + index}
                    text={item.text}
                    subText={item.subText}
                    subColor={'#A5A5A5'}
                    path={item.path}
                    icon={item.icon}
                    isRightArrow={item.isRegisteredPrepaid ?? true}
                    f7router={f7.view.main.router}
                    handleClickList={(path, f7router) =>
                      handleClickMenuUseStore(path, f7router, item?.car)
                    }
                  />
                ))}
              </>
            ) : (
              <div className={style['notice']}>
                <div className={style['notice-box']}>
                  <div className={style['notice-box-title']}>
                    <ExclaminationIcon width={20} height={20} fill="#1E55E6" />
                    決済情報を登録しましょう
                  </div>
                  <p>
                    いつでも簡単・安全なお支払いを実現するために、決済情報を登録してください。
                  </p>
                </div>
                <Button
                  height="40px"
                  text="決済方法を登録"
                  outline
                  handleClickAction={() => {
                    f7.view.main.router.navigate(paths.accountRegistPayment());
                  }}
                />
              </div>
            )}
          </div>
        </RoundSheet>
        <RoundSheet>
          <div className={style['list-container']}>
            <h3 className={style.heading}>サービス・アプリについて</h3>
            {serviceOrApplicationLabelList.map((item, i) => (
              <CustomList
                key={item.text}
                text={item.text}
                isTopBorder={false}
                path={item.path}
                icon={item.icon}
                f7router={f7.view.main.router}
              />
            ))}
          </div>
        </RoundSheet>
        <RoundSheet>
          <div
            className={style['logout']}
            onClick={async () => await handleClickLogout()}
          >
            {logoutLabelList.map((item, i) => (
              <CustomList
                key={item.text}
                text={item.text}
                path={item.path}
                icon={item.icon}
                f7router={f7.view.main.router}
              />
            ))}
          </div>
        </RoundSheet>
      </div>
    </PageTemplate>
  );
};
