import { FC } from 'react';
import style from './licensePlateStyle.module.css';

interface LicensePlateProps {
  area: string;
  classNumber: string;
  kana: string;
  consecutiveNumber: string;
}
export const LicensePlate: FC<LicensePlateProps> = ({
  area,
  classNumber,
  kana,
  consecutiveNumber,
}) => {
  return (
    <div className={style['license-plate']}>
      <div className={style['license-plate-upper']}>
        <span>{area}</span>
        <span className={style['class-number']}>{classNumber}</span>
      </div>
      <div className={style['license-plate-bottom']}>
        <span className={style['kana']}>{kana}</span>
        <span className={style['number']}>{consecutiveNumber}</span>
      </div>
    </div>
  );
};
