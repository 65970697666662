import { FC } from 'react';
import style from './SelectForm.module.css';
import { Label } from '@/components/uiParts/Label/Label';
import { getCarPhoto } from '@/utils/utils';

interface SelectCarFormProps {
  carsInfo: any[];
  carSelected: number;
  selectCar: (stockCarId: number) => void;
}

export const SelectCarForm: FC<SelectCarFormProps> = ({
  carsInfo,
  carSelected,
  selectCar,
}) => {
  return (
    <div>
      <div className={style['label-wrapper']}>
        <span className={style['label']}>対象車両を選択してください</span>
        <Label />
      </div>
      <div>
        <div onClick={() => selectCar(0)}>
          <div className={style['selectCar-title']}>
            <i
              className={
                carSelected == 0
                  ? style['radio-icon-active']
                  : style['radio-icon']
              }
            />
            <div className={style['selectCar-label']}>
              <p>指定無し</p>
            </div>
          </div>
        </div>
        {carsInfo
          .filter((c) => !!c.t_stock_car_id)
          .map((c) => (
            <div
              key={c.t_stock_car_id}
              onClick={() => selectCar(c.t_stock_car_id)}
            >
              <div className={style['selectCar-title']}>
                <div>
                  <i
                    className={
                      carSelected == c.t_stock_car_id
                        ? style['radio-icon-active']
                        : style['radio-icon']
                    }
                  />
                </div>
                <div className={style['selectCar-label']}>
                  <p>{c.car_nm}</p>
                  <div className={style['car-content']}>
                    <div className={style['car-img']}>
                      <img src={getCarPhoto(c)} alt={''} />
                    </div>
                    <div className={style['car-subContent']}>
                      <p>{c.maker_nm}</p>
                      <p>{c.registry_no}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
