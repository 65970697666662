import { CSSProperties, FC, ReactNode } from 'react';
import { RoundSheet, RoundSheetProps } from '../RoundSheet/RoundSheet';
import style from './listRoundSheetStyle.module.css';

interface ListRoundSheetProps extends RoundSheetProps {
  title: string;
  button?: ReactNode;
  list?: {
    label: string;
    value: string | ReactNode;
  }[];
  labelWidth?: string;
  isValueEnd?: boolean;
  contents?: ReactNode;
  titleStyle?: CSSProperties;
  listStyle?: CSSProperties;
}
export const ListRoundSheet: FC<ListRoundSheetProps> = ({
  title,
  button = undefined,
  list,
  labelWidth = '100px',
  isValueEnd = true,
  children,
  titleStyle = undefined,
  listStyle = undefined,
  ...rest
}) => {
  return (
    <RoundSheet {...rest}>
      <div className={style['list-wrapper']}>
        <div className={style['title-wrapper']}>
          <h3 style={titleStyle}>{title}</h3>
          {button}
        </div>
        {list
          ? list.map((e) => (
              <div className={style['list']} style={listStyle} key={e.label}>
                <div style={{ width: labelWidth }}>
                  <span className={style['list-label']}>{e.label}</span>
                </div>
                <div
                  className={`${style['list-value-wrapper']} ${
                    isValueEnd && style['value-end']
                  }`}
                >
                  <span className={style['list-value']}>{e.value}</span>
                </div>
              </div>
            ))
          : children}
      </div>
    </RoundSheet>
  );
};
