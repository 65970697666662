import { getUnixTime } from 'date-fns';
import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import style from './NewsListStyle.module.css';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { IconList } from '@/components/uiParts/List/IconList/IconList';
import { isApp } from '@/config/device';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { url } from '@/consts/url';
import { Notification } from '@/types/api/customerApi';
import { hyphenToDay } from '@/utils/date';

type NewsListProps = {
  f7router: Router.Router;
};

export const NewsList: FC<NewsListProps> = ({ f7router }) => {
  const { notificationsInfo, permission } = store.state;
  const timestamp = getUnixTime(new Date());
  const newsList = notificationsInfo.filter((item) => {
    return (
      getUnixTime(new Date(item.start_date)) <= timestamp &&
      getUnixTime(new Date(item.end_date)) >= timestamp
    );
  });

  const handleClickNews = (item: Notification) => {
    f7.view.main.router.navigate(paths.noticeDetail(String(item.id)), {
      props: { item },
    });
  };

  const handleClickPushSettingCard = () => {
    if (isApp) {
      location.href = url.NATIVE_BASE_URL + '/on_go_app_setting';
    }
  };

  return (
    <div>
      <>
        {isApp && !permission.pushPermission && (
          <IconCard
            color="gray"
            headingFontSize="14px"
            iconFontSize="3.6rem"
            iconColor="#1e55e6"
            iconType="bulb"
            isTransition
            heading={'通知の設定を行うと、大事なお知らせの見逃しがなくなります'}
            onClick={handleClickPushSettingCard}
          />
        )}
        <ul className={style['list-wrap']}>
          {!newsList || newsList.length === 0 ? (
            <p className="u-text-center">
              現在、該当の「お知らせ」はございません
            </p>
          ) : (
            newsList.map((item: Notification, idx: number) => (
              <div onClick={() => handleClickNews(item)} key={idx}>
                <IconList
                  key={idx}
                  iconType={'notification'}
                  text={item.title ?? ''}
                  date={hyphenToDay(item.start_date ?? '', true)}
                />
              </div>
            ))
          )}
        </ul>
      </>
    </div>
  );
};
