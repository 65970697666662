import { Page } from 'framework7-react';
import { FC } from 'react';
import CarIcon from '@/assets/images/carIcon.png';
import { Button } from '@/components/uiParts/Button';
import './CalibrationDialog.scss';

type CalibrationDialogProps = {
  handleOk: () => void;
};

export const CalibrationDialog: FC<CalibrationDialogProps> = ({ handleOk }) => {
  return (
    <Page name="CalibrationDialog">
      <div>
        <div className="container">
          <div className="container-title">
            <img src={CarIcon} />
            <p>キャリブレーションを実施中です</p>
          </div>
          <div style={{ fontSize: '14px' }}>
            運転に集中してください。
            <br />
            アプリは閉じても大丈夫です。
          </div>
          <Button
            style={{ backgroundColor: '#EFEFEF', color: '#000' }}
            text="閉じる"
            handleClickAction={handleOk}
          />
        </div>
      </div>
    </Page>
  );
};
