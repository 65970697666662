import { IconProps } from './type';

export const CircularArrowIcon = ({
  width = '27',
  height = '6',
  color = '#4F71BE',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27 6"
      fill="none"
    >
      <path
        d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM3 3.5L27 3.5V2.5L3 2.5V3.5Z"
        fill={color}
      />
    </svg>
  );
};
