import { FC } from 'react';
import style from './ProgressCircleCarStyle.module.css';
import CarCheckIcon from '@/assets/images/car-check-light.svg';
import CarIcon from '@/assets/images/car-light.svg';

interface ProgressCircleCar {
  id: string;
  progress: number;
  flip?: boolean;
}

export const ProgressCircleCar: FC<ProgressCircleCar> = ({
  id,
  progress = 0,
  flip = false,
}) => {
  return (
    <>
      <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" width={120}>
        <path
          d="M67 3 A58 58 0 1 1 61 125 A58 58 0 1 1 67 3"
          stroke="#ccc"
          strokeWidth="6"
          fill="none"
        />
      </svg>
      <svg
        viewBox="0 0 128 128"
        xmlns="http://www.w3.org/2000/svg"
        width={120}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          transform: `scaleX(${flip ? '-1' : '1'})`,
        }}
        className={style.svg}
      >
        <path
          d="M67 3 A58 58 0 1 1 61 125 A58 58 0 1 1 67 3"
          stroke="#1e55e6"
          strokeWidth="6"
          fill="none"
          className="path"
          id={id}
        />
      </svg>
      <div className={style.progress}>
        {progress >= 100 ? <img src={CarCheckIcon} /> : <img src={CarIcon} />}
      </div>
    </>
  );
};
