import { FC } from 'react';
import { CreditCardPayInfo } from 'src/components/pages/PaymentsSchedule/CreditCardPayInfo/CreditCardPayInfo';
import { OfficeFeeInfo } from 'src/components/pages/PaymentsSchedule/OfficeFeeInfo/OfficeFeeInfo';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import './PaymentsSchedule2Style.scss';
import { BillInformationDetail } from '@/types/api/customerApi';
import { formatYearMonthToJa, hyphenToYear } from '@/utils/date';

interface PaymentsSchedule2Props {
  billDetails: BillInformationDetail[];
}

export const PaymentsSchedule2: FC<PaymentsSchedule2Props> = ({
  billDetails,
}) => {
  const renderBillDetails = () => {
    if (billDetails && billDetails.length === 0) {
      return (
        <div
          id="empty_schedule_container"
          className="info-card info-card-elevated schedule-no-content"
          style={{ textAlign: 'center' }}
        >
          請求予定はありません
        </div>
      );
    }
    return billDetails.map((item, index) => (
      <div key={index} className="sheet-wrapper">
        <RoundSheet>
          <div className="content">
            <div className="u-padding-bottom-xs">
              <h2>{formatYearMonthToJa(item.bill_month)}分</h2>
            </div>
            {item.bill_month && (
              <div className="list-item display-flex">
                <span className="u-font-bold">請求金額</span>
                <span>{item.bill_price?.toLocaleString()}円</span>
              </div>
            )}
            {item.billing_date && (
              <div className="list-item display-flex border-top-none">
                <span className="u-font-bold">請求予定日</span>
                <span>{hyphenToYear(item.billing_date, false)}</span>
              </div>
            )}
            {item.memo && (
              <div className="list-item display-flex border-top-none">
                <span className="u-font-bold">備考</span>
                <span>{item.memo}</span>
              </div>
            )}
          </div>
        </RoundSheet>
      </div>
    ));
  };

  return (
    <PageTemplate
      pageName="PaymentsSchedule"
      title="請求予定"
      showNavBack={true}
    >
      <CreditCardPayInfo textContent="各履歴に記載されている請求日とクレジットカードの引き落とし日は異なる場合があります。" />
      <div className="u-margin-bottom-md" />
      <OfficeFeeInfo />
      <div className="u-margin-bottom-md" />

      {renderBillDetails()}
    </PageTemplate>
  );
};
