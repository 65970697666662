import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { FC } from 'react';
import './TransitionChartStyle.scss';

interface PieceType {
  gt: number;
  lte: number;
  color: string;
}

interface Props {
  id?: string;
  title?: string;
  createMonthly?: string[];
  yearScoreList?: number[];
  seriesType?: string;
  showSplitLineX?: boolean;
  showSplitLineY?: boolean;
  isAlignWithLabelX?: boolean;
  showLabel?: boolean;
  labelColor?: string;
  labelPosition?: string;
  labelSize?: string;
  gridLeft?: string;
  minNumber?: number;
  maxNumber?: number;
  axisLabelUnit?: string;
  axisLabelInterval?: number;
  axisLabelIntervalY?: number;
  axisLabelIntervalX?: number;
  pieces?: PieceType[];
  minInterval?: number;
  chartTopPosition?: string;
}

export const TransitionChart: FC<Props> = ({
  id = 'transitionChart',
  title = '',
  createMonthly = [],
  yearScoreList = [],
  seriesType = 'bar',
  showSplitLineY = true,
  showLabel = true,
  labelColor = '#5470c6',
  labelPosition = 'top',
  labelSize = '12',
  minNumber = 0,
  maxNumber,
  axisLabelUnit = '点',
  axisLabelIntervalY = undefined,
  axisLabelIntervalX = 0,
  pieces = [{ gt: 0, lte: 100, color: '#1E55E6' }],
  chartTopPosition = title ? '25%' : '10%',
}) => {
  const options = {
    visualMap: {
      show: false,
      pieces: pieces,
    },
    grid: {
      left: '0',
      right: '4%',
      bottom: '3%',
      top: chartTopPosition,
      containLabel: true,
    },
    title: {
      text: title,
      subtext: '',
    },
    xAxis: {
      type: 'category',
      data: createMonthly,
      axisLabel: {
        interval: axisLabelIntervalX,
        fontSize: '11',
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: showSplitLineY,
      },
      scale: true,
      min: function (value: { min: number; max: number }) {
        if (minNumber !== undefined) return minNumber;
        return Math.floor(value.min);
      },
      max: function (value: { min: number; max: number }) {
        if (maxNumber) {
          return maxNumber;
        } else {
          return Math.ceil(value.max ?? 100);
        }
      },
      interval: axisLabelIntervalY,
      minInterval: 10,
      maxInterval: 50,
      axisLabel: {
        fontSize: '11',
        formatter: `{value}${axisLabelUnit}`,
      },
    },
    series: [
      {
        type: seriesType,
        data: yearScoreList,
        label: {
          show: showLabel,
          position: labelPosition,
          fontSize: labelSize,
          color: labelColor,
        },
        barWidth: 14,
        itemStyle: {
          borderRadius: [4, 4, 0, 0],
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(85, 163, 210,0.8)',
            },
            {
              offset: 1,
              color: 'rgba(85, 163, 210,0.3)',
            },
          ]),
        },
      },
    ],
  };

  return (
    <ReactEcharts option={options} style={{ width: '100%', height: '100%' }} />
  );
};
