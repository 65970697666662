import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchAvailableRegisterBankListApi } from '@/api/prepaidApi';
import {
  PaymentBankListParams,
  PaymentBankListResponse,
} from '@/types/api/prepaidApi';

export const useAvailableRegisterBankList = ({
  site_id,
  m_customer_id,
  with_trashed,
}: PaymentBankListParams) => {
  const [data, setData] = useState<PaymentBankListResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    if (!site_id) {
      return;
    }

    f7.preloader.show();
    const fn = async () => {
      try {
        setIsLoading(true);
        const res = await fetchAvailableRegisterBankListApi({
          site_id,
          m_customer_id,
          with_trashed,
        });
        setData(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setIsLoading(false);
        f7.preloader.hide();
      }
    };
    fn();
  }, [site_id, m_customer_id, with_trashed]);

  return {
    data,
    isLoading,
    error,
  };
};
