import { FC, useMemo, useState } from 'react';
import { UserTasksPageProps } from '../UserTasksPage';
import { TaskItem } from './TaskItem/TaskItem';
import style from './UserTasksStyle.module.scss';
import {
  TasksOptionType,
  drivingFunction,
  engineRoomInspection,
  equipmentInspection,
  exteriorInspection,
  insideVehicleInspection,
} from './mockData';
import { Button } from '@/components/uiParts/Button';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
export const UserTasks: FC<UserTasksPageProps> = ({
  options,
  idx,
  setValue,
  watch,
}) => {
  const [currentId, setCurrentId] = useState(idx);
  const { check1, check2, check3, check4, check5 } = watch();
  const checkList = [!!check1, !!check2, !!check3, !!check4, !!check5];
  const listChecked = checkList.filter((check) => !!check);
  const { name, title, id } = options[currentId];
  const lastOptions = options.length - 1;

  const isPopupShouldClose =
    (listChecked.length === 4 && !checkList[currentId]) ||
    listChecked.length === 5;
  const tasksOption: TasksOptionType[] = useMemo(() => {
    if (id === 5) {
      return drivingFunction;
    } else if (id === 4) {
      return insideVehicleInspection;
    } else if (id === 3) {
      return engineRoomInspection;
    } else if (id === 2) {
      return equipmentInspection;
    } else if (id === 1) {
      return exteriorInspection;
    }
    return [];
  }, [id]);
  const handleChangePopup = () => {
    setValue(name, true, { shouldTouch: true, shouldDirty: true });
    if (currentId <= lastOptions) {
      checkList[currentId] = true;
      const nextCurrentId = checkList.indexOf(false);

      if (nextCurrentId >= 0) {
        setCurrentId(nextCurrentId);
        setTimeout(() => {
          document.querySelector('#UserTasksWrraper')?.scrollTo(0, 0);
        }, 0);
      }
    }
  };

  return (
    <PopupPageTemplate
      id="userTasksPopup"
      pageName="UserTasks"
      title={title}
      containerClass={style['container']}
    >
      <div className={style['wrraper']} id="UserTasksWrraper">
        {tasksOption.map((option, idx) => (
          <TaskItem key={idx} {...option} />
        ))}
        <Button
          type="submit"
          className="u-margin-top-sm"
          onClick={handleChangePopup}
          popupClose={isPopupShouldClose}
        >
          完了
        </Button>
      </div>
    </PopupPageTemplate>
  );
};
