import { Block, Link } from 'framework7-react';
import React from 'react';
import style from './Co2InformationStyle.module.css';
import informationIcon from '@/assets/images/information.svg';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';
import './Co2InformationStyle.scss';

const Co2Information = () => {
  const title = (
    <div className={style['page-title']}>
      <img src={informationIcon} alt="" />
      <span>安全運転によるCO2排出削減量</span>
    </div>
  );

  return (
    <PopupPageTemplate
      isCloseIcon={false}
      pageName="co2Information"
      title={title}
    >
      <Block>
        <div className={style['content']}>
          <p>
            安全運転によるCO2排出削減量の算出に使用するためのCO2排出量の計算は、「ロジスティクス分野におけるCO2
            排出量算定方法共同ガイドラインVer.
            ３.１（出展：経済産業省）」を用いています。
          </p>
          <p className={style['mt2']}>＜注意事項等＞</p>
          <ul>
            <li>
              同じ走行距離で平均的な運転を行った場合と比較して、安全運転により削減されたCO2排出量を表示しています。
            </li>
            <li>
              安全運転によるCO2排出削減量は当社が算出した参考値であり、GHGプロトコル等の国際的な基準や実際のCO2排出削減量とは異なる場合がございます。また、安全運転によるCO2排出削減量の計算方法については、予告なく変更となる場合があります。変更した場合でも、原則それ以前の数値は変化しません。
            </li>
            <li>ガソリン車、ハイブリッド車が表示対象となります。</li>
            <li>エアコン等のお車の使用状況は考慮していません。</li>
            <li>
              当社が定めた平均的な運転と比較してCO2排出量が削減できなかった場合は、表示されません。
            </li>
            <li>
              ご契約内容等によっては、安全運転によるCO2排出削減量を計算できない場合があります。
            </li>
          </ul>
        </div>
        <div className={style['close-wrapper']}>
          <Link
            popupClose={true}
            onClick={undefined}
            animate
            className={style['close-information']}
          >
            閉じる
          </Link>
        </div>
      </Block>
    </PopupPageTemplate>
  );
};

export default Co2Information;
